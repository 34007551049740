import {
  Button,
  Card,
  GridTable,
  NumberStat,
  OpenLink,
  OrderCount,
  OrderListTooltip,
  Radio,
  ReInput,
  ReMessageModal,
  RePagination,
  Select,
  Textarea,
  UpdateInvoiceModal,
} from 'components';
import React, { useMemo } from 'react';
import {
  ORDER_DATE_LIST,
  ORDER_SEARCH_TYPE,
  request,
  useOrderTable,
  INFLOW_ORIGIN_CAMPAIGN,
  PAY_METHOD,
  PATH,
  USER_TYPE,
  SHOP_ORDER_STATUS,
  enumToList,
  ORDER_PAY_STATUS,
  ORDER_CANCEL_STATUS,
  DELIVERY_STATUS,
  ORDER_ADDITIONAL_PAY_TYPE,
  useComponentDidUpdate,
  useObject,
  dayjsFormat,
  usePriceRule,
  useShop,
  ORDER_SORT,
  useCopyText,
  useOrderTableDeliveryMethod,
} from 'services';
import type { ICardItem, IPriceRule, IShop, NOrder } from 'types';
import _ from 'lodash';
import dayjs from 'dayjs';
import { priceFormat } from 'utils';
import { toast } from 'react-toastify';
import Fuse from 'fuse.js';
import classnames from 'classnames';
import { Checkbox } from 'antd';

interface State {
  minOrderDate: string;
  maxOrderDate: string;
  shopSearchText: string;
  isChangeInvoiceOpen: boolean;
  itemOrderNumber: string;
  invoice: string;
  shippingCode: string;
  isWaypoint: boolean;
}

const OrdersPage = () => {
  const [
    {
      maxOrderDate,
      minOrderDate,
      shopSearchText,
      isChangeInvoiceOpen,
      itemOrderNumber,
      invoice,
      shippingCode,
      isWaypoint,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    minOrderDate: dayjs().add(-1, 'month').format('YYYY-MM-DD'),
    maxOrderDate: dayjs().format('YYYY-MM-DD'),
    shopSearchText: '',
    isChangeInvoiceOpen: false,
    itemOrderNumber: '',
    invoice: '',
    shippingCode: '',
    isWaypoint: false,
  });
  const [shopList] = useShop();
  const [priceRuleList] = usePriceRule();
  const copyText = useCopyText();

  const {
    searchText,
    searchType,
    onChangeOrder,
    orderStatus,
    setOrderState,
    orderPath,
    userType,
    inflowCampaign,
    inflowChannel,
    limit,
    onChangeCheckbox,
    selectedIdx,
    isLoading,
    total,
    page,
    payMethod,
    sort,
    resetOrderState,
    isDetailOpen,
    list,
    memoList,
    isMemoLoading,
    isMessageOpen,
    phoneNumber,
    getMemo,
    showAll,
    shopIdList,
    priceRuleIdList,
    deliveryMethodIdList,
  } = useOrderTable();
  const [deliveryMethodIdOptions] = useOrderTableDeliveryMethod();
  const items: ICardItem[] = [
    {
      name: '검색어',
      renderItem: () => (
        <div className="flex items-center gap-2">
          <Select
            size="xs"
            value={searchType}
            disabled={isLoading}
            name="searchType"
            onChange={onChangeOrder}
          >
            <option value="">전체</option>
            {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
              <option key={key} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
          <Textarea.Autosize
            value={searchText}
            name="searchText"
            onChange={onChangeOrder}
            disabled={isLoading}
            rows={1}
            className="w-64"
            placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
            onEnter={get}
          />
        </div>
      ),
      stretch: true,
    },
    {
      name: '편집샵/국가별',
      renderItem: () => (
        <div className="flex gap-2">
          <div className="w-56 p-1 rounded border">
            <div className="p-1">
              <input
                className="w-full border-gray-300 rounded p-1 text-xs"
                placeholder="편집샵 검색"
                value={shopSearchText}
                name="shopSearchText"
                onChange={onChange}
              />
            </div>
            <div className="overflow-auto pl-1" style={{ height: 144 }}>
              {searchShopList.map((item, key) => (
                <label key={key} className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={shopIdList.indexOf(item.shopId) !== -1}
                    onChange={() =>
                      setOrderState({
                        shopIdList:
                          shopIdList.indexOf(item.shopId) === -1
                            ? [...shopIdList, item.shopId]
                            : shopIdList.filter((id) => id !== item.shopId),
                        priceRuleIdList:
                          shopIdList.indexOf(item.shopId) === -1
                            ? [
                                ...priceRuleIdList,
                                ...priceRuleList
                                  .filter((priceRule) => priceRule.shopId === item.shopId)
                                  .map((priceRule) => priceRule.priceRuleId),
                              ]
                            : priceRuleIdList.filter(
                                (id) =>
                                  priceRuleList
                                    .filter(
                                      (priceRule) => priceRule.shopId === item.shopId
                                    )
                                    .map((priceRule) => priceRule.priceRuleId)
                                    .indexOf(id) === -1
                              ),
                      })
                    }
                  />
                  <span className="text-gray-600 text-xs">{item.name}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="w-40 p-1 rounded border h-full">
            <div className="p-1 text-xs text-gray-600">편집샵별 국가 선택</div>
            <div className="overflow-auto pl-1 mt-1">
              {searchPriceRuleList.map((item, key) => (
                <label key={key} className="flex items-center gap-2 cursor-pointer">
                  <input
                    type="checkbox"
                    checked={priceRuleIdList.indexOf(item.priceRuleId) !== -1}
                    onChange={() =>
                      setOrderState({
                        priceRuleIdList:
                          priceRuleIdList.indexOf(item.priceRuleId) === -1
                            ? [...priceRuleIdList, item.priceRuleId]
                            : priceRuleIdList.filter((id) => id !== item.priceRuleId),
                      })
                    }
                  />
                  <span className="text-gray-600 text-xs">
                    {item.name} ({item.country})
                  </span>
                </label>
              ))}
            </div>
          </div>
        </div>
      ),
      stretch: true,
    },
    {
      name: '주문일',
      renderItem: () => (
        <div className="flex items-center gap-2">
          {ORDER_DATE_LIST.map((item, key) => (
            <Button
              disabled={isLoading}
              size="xs"
              theme="ghost"
              onClick={() => onDateClick(item)}
              key={key}
            >
              {item}
            </Button>
          ))}
          <ReInput
            size="xs"
            type="date"
            value={minOrderDate}
            name="minOrderDate"
            disabled={isLoading}
            onChange={onChange}
          />
          {' ~ '}
          <ReInput
            size="xs"
            type="date"
            name="maxOrderDate"
            disabled={isLoading}
            onChange={onChange}
            value={maxOrderDate}
          />
        </div>
      ),
      stretch: true,
    },
    {
      name: '취소 포함 여부',
      renderItem: () => (
        <Radio
          id="showAll"
          value={showAll}
          disabled={isLoading}
          onChange={(showAll) => setOrderState({ showAll })}
          options={[
            { name: '포함', value: true },
            { name: '미포함', value: false },
          ]}
        />
      ),
      stretch: true,
    },
    {
      name: '주문상태',
      renderItem: () => (
        <Radio
          id="orderStatus"
          isInitialExisted
          value={orderStatus}
          disabled={isLoading}
          onChange={(orderStatus) => setOrderState({ orderStatus })}
          options={enumToList(SHOP_ORDER_STATUS)}
        />
      ),
      stretch: true,
    },
    {
      name: '배송 방식',
      renderItem: () => (
        <Checkbox.Group
          disabled={isLoading}
          options={deliveryMethodIdOptions}
          value={deliveryMethodIdList}
          onChange={(deliveryMethodIdList) =>
            setOrderState({ deliveryMethodIdList: deliveryMethodIdList as number[] })
          }
        />
      ),
      stretch: true,
    },
  ];
  const detailItems: ICardItem[] = [
    {
      name: '주문경로',
      renderItem: () => (
        <Radio
          id="orderPath"
          value={orderPath}
          isInitialExisted
          disabled={isLoading}
          options={enumToList(PATH)}
          onChange={(orderPath) => setOrderState({ orderPath })}
        />
      ),
      stretch: true,
    },
    {
      name: '결제수단',
      renderItem: () => (
        <Radio
          id="payMethod"
          value={payMethod}
          isInitialExisted
          disabled={isLoading}
          onChange={(payMethod) => setOrderState({ payMethod })}
          options={enumToList(PAY_METHOD)}
        />
      ),
      stretch: true,
    },
    {
      name: '회원등급',
      renderItem: () => (
        <Radio
          id="userType"
          value={userType}
          isInitialExisted
          disabled={isLoading}
          onChange={(userType) => setOrderState({ userType })}
          options={enumToList(USER_TYPE)}
        />
      ),
      stretch: true,
    },
    {
      name: '유입경로',
      renderItem: () => (
        <div className="flex items-center gap-2">
          <Select
            size="xs"
            value={inflowChannel}
            name="inflowChannel"
            onChange={onChangeOrder}
            disabled={isLoading}
          >
            <option value="">유입채널</option>
          </Select>
          <Select
            size="xs"
            value={inflowCampaign}
            name="inflowCampaign"
            onChange={onChangeOrder}
            disabled={isLoading}
          >
            <option value="">캠페인</option>
            {enumToList(INFLOW_ORIGIN_CAMPAIGN).map((item, key) => (
              <option key={key} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </div>
      ),
      stretch: true,
    },
  ];
  const get = async () => {
    setOrderState({ isLoading: true });
    try {
      let params: any = {
        isAscending: sort === 'ORDER_DATE_ASC',
        limit,
        page,
        orderBy: 'ORDER_DATE',
        minOrderDate: `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
        maxOrderDate: `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`,
      };
      if (!!inflowCampaign) params.inflowCampaign = inflowCampaign;
      if (!!inflowChannel) params.inflowChannel = inflowChannel;
      if (!!orderPath) params.orderPath = orderPath;
      if (!!orderStatus) params.orderStatus = orderStatus;
      if (!!searchType) params.searchType = searchType;
      if (!!searchText) params.searchText = searchText;
      if (!!userType) params.userType = userType;
      if (showAll) params.showAll = showAll;
      if (!!shopIdList.length) params.shopIdList = shopIdList;
      if (!!priceRuleIdList.length) params.priceRuleIdList = priceRuleIdList;
      if (!!deliveryMethodIdList.length)
        params.deliveryMethodIdList = deliveryMethodIdList;
      const { count, data } = await request.get<any, { count: number; data: any[] }>(
        '/commerce/order',
        {
          params,
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setOrderState({ total: count, list: data, isLoading: false });
    } catch (err) {
      setOrderState({ isLoading: false, total: 0, list: [] });
      console.dir(err);
    }
  };

  const onBatchCopyOrderNumber = async () => {
    if (!selectedIdx.length) {
      toast.info('최소 1개의 주문을 선택해주세요.');
      return;
    }
    await copyText(selectedIdx.join('\n'));
  };

  const onDateClick = (date: string) => {
    let newOrderedAt = '';
    if (date === '전체') newOrderedAt = dayjsFormat(dayjs().add(-100, 'year'));
    else if (date === '오늘') newOrderedAt = dayjsFormat(dayjs());
    else if (date === '어제') newOrderedAt = dayjsFormat(dayjs().add(-1, 'day'));
    else if (date === '3일') newOrderedAt = dayjsFormat(dayjs().add(-3, 'day'));
    else if (date === '7일') newOrderedAt = dayjsFormat(dayjs().add(-7, 'day'));
    else if (date === '15일') newOrderedAt = dayjsFormat(dayjs().add(-15, 'day'));
    else if (date === '1개월') newOrderedAt = dayjsFormat(dayjs().add(-1, 'month'));
    else if (date === '3개월') newOrderedAt = dayjsFormat(dayjs().add(-3, 'month'));
    else if (date === '6개월') newOrderedAt = dayjsFormat(dayjs().add(-6, 'month'));

    setState({
      minOrderDate: newOrderedAt,
      maxOrderDate: dayjsFormat(dayjs()),
    });
  };

  const orderLink = (orderIndex: number) => {
    const order = list[orderIndex];
    const shopOrderList: NOrder.Detail['shopOrderList'] = order.shopOrderList;

    let url = `/order/${order.fetchingOrderNumber}`;

    const tab0: Array<keyof typeof SHOP_ORDER_STATUS> = [
      'BEFORE_DEPOSIT',
      'ORDER_AVAILABLE',
      'ORDER_WAITING',
      'PRE_ORDER_REQUIRED',
      'ORDER_DELAY',
    ];
    const tab1: Array<keyof typeof SHOP_ORDER_STATUS> = [
      'ORDER_COMPLETE',
      'ORDER_DELAY_IN_SHOP',
      'PRODUCT_PREPARE',
      'IN_WAYPOINT_SHIPPING',
      'IN_DOMESTIC_SHIPPING',
      'WAYPOINT_ARRIVAL',
      'DOMESTIC_CUSTOMS_CLEARANCE',
      'SHIPPING_COMPLETE',
      'SHIPPING_START',
    ];
    if (shopOrderList.some((shop) => tab0.indexOf(shop.orderStatus) !== -1))
      url += '?tab=0';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.cancelInfo))
    )
      url += '?status=2&tab=1';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.returnInfo))
    )
      url += '?status=3&tab=1';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.refundInfo))
    )
      url += '?status=4&tab=1';
    else if (shopOrderList.some((shop) => tab1.indexOf(shop.orderStatus) !== -1))
      url += '?status=0&tab=1';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.cancelInfo))
    )
      url += '?status=1&tab=1';

    window.open(url);
  };

  // 상태
  const paymentStatus = (
    orderIndex: number,
    shopIndex: number,
    itemIndex: number
  ): string => {
    const order = list[orderIndex];
    const shop = order.shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];

    // 취소 상태
    if (!!item.cancelInfo)
      return `주문취소(${ORDER_CANCEL_STATUS[item.cancelInfo.cancelStatus]})`;
    // 환불 상태
    if (!!item.refundInfo) return `환불${ORDER_PAY_STATUS[item.refundInfo.refundStatus]}`;
    // 반품 상태
    if (!!item.returnInfo) return `반품${ORDER_PAY_STATUS[item.returnInfo.returnStatus]}`;
    // 교환 상태
    if (!!item.exchangeInfo)
      return `교환${ORDER_PAY_STATUS[item.exchangeInfo.exchangeStatus]}`;

    // 발주 가능, 발주 대기, 선 발주 필요, 발주 지연
    if (
      shop.orderStatus === 'ORDER_AVAILABLE' ||
      shop.orderStatus === 'ORDER_WAITING' ||
      shop.orderStatus === 'PRE_ORDER_REQUIRED' ||
      shop.orderStatus === 'ORDER_DELAY'
    ) {
      // 차액 발생
      if (
        !!item.additionalPayInfo &&
        item.additionalPayInfo.additionalPayStatus !== 'PAID'
      ) {
        // 안내 필요
        if (item.additionalPayInfo.needGuide) {
          return item.additionalPayInfo.type === 'PRODUCT_DIFFERENCE'
            ? '차액안내필요'
            : `차액안내필요(${ORDER_ADDITIONAL_PAY_TYPE[item.additionalPayInfo.type]})`;
        } else {
          return item.additionalPayInfo.type === 'PRODUCT_DIFFERENCE'
            ? '차액안내완료'
            : `차액안내완료(${ORDER_ADDITIONAL_PAY_TYPE[item.additionalPayInfo.type]})`;
        }
        // 주문취소
      } else if (!!item.cancelInfo)
        return `주문취소(${ORDER_CANCEL_STATUS[item.cancelInfo.cancelStatus]})`;
    }

    // 배송 상황
    if (!!DELIVERY_STATUS[shop.orderStatus]) {
    }

    return SHOP_ORDER_STATUS[shop.orderStatus];
  };

  const searchShopList: IShop[] = useMemo(() => {
    if (!shopSearchText) return shopList;

    const fuse = new Fuse(shopList, {
      keys: ['name'],
      includeScore: true,
    });
    return fuse
      .search(shopSearchText)
      .filter((item) => item.score! <= 0.33)
      .map(({ item }) => item);
  }, [shopSearchText, shopList.length]);

  const searchPriceRuleList: IPriceRule[] = useMemo(() => {
    if (!shopIdList.length) return [];

    return priceRuleList.filter((item) => shopIdList.indexOf(item.shopId) !== -1);
  }, [shopIdList]);

  useComponentDidUpdate(() => {
    get();
  }, [
    page,
    inflowChannel,
    inflowCampaign,
    sort,
    limit,
    minOrderDate,
    maxOrderDate,
    orderPath,
    orderStatus,
    userType,
    payMethod,
    showAll,
    shopIdList.length,
    priceRuleIdList.length,
  ]);

  return (
    <OrderCount>
      {/* <MenuTitle title="전체 주문 조회" /> */}
      <div className="wrapper">
        <div className="wrapper-title">전체주문</div>
        <Card items={isDetailOpen ? [...items, ...detailItems] : items}>
          <Button
            size="sm"
            theme="secondary"
            onClick={() => setOrderState({ isDetailOpen: !isDetailOpen })}
          >
            상세조건 {isDetailOpen ? '닫기' : '열기'}
          </Button>
        </Card>
        <div className="mt-7 flex justify-center gap-3 text-lg">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button
            theme="secondary"
            onClick={() => {
              resetOrderState();
              resetState();
            }}
          >
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          maxHeight=""
          columns={[
            '주문번호',
            '주문일 (결제일)',
            '주문자',
            '판매처 (국가 / 관세조건 / 배송방식)',
            '주문상품',
            '수량',
            '상품금액',
            '편집샵 결제금액',
            '총 결제금액',
            '결제수단',
            '주문상태',
            '관리자기능',
          ]}
          isLoading={isLoading}
          header={
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <Button size="sm" onClick={onBatchCopyOrderNumber}>
                  주문번호 일괄 복사
                </Button>
              </div>
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          renderItem={(order, orderIndex) => (
            <div
              key={orderIndex}
              onClick={() => onChangeCheckbox(order.fetchingOrderNumber)}
            >
              <div aria-label="checkbox">
                <input
                  type="checkbox"
                  checked={selectedIdx.indexOf(order.fetchingOrderNumber) !== -1}
                  onChange={() => {}}
                />
              </div>
              <div aria-label="주문번호">
                <span
                  className="link"
                  onClick={(e) => {
                    e.stopPropagation();
                    orderLink(orderIndex);
                  }}
                >
                  {order.fetchingOrderNumber}
                </span>
              </div>
              <div aria-label="주문일 (결제일)">
                <div>{dayjs(order.orderedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                {!!order.paidAt && (
                  <div className="text-gray-400">
                    ({dayjs(order.paidAt).format('YYYY.MM.DD HH:mm:ss')})
                  </div>
                )}
              </div>
              <div aria-label="주문자">
                <span>{order.userName}</span>
                <div>{USER_TYPE[order.userType]}</div>
              </div>
              <div aria-label="판매처 (국가 / 관세조건 / 배송방식)">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div key={shopIndex} style={{ flex: shop.itemOrderList.length }}>
                      <div>
                        {shop.shopName} ({shop.shopCountry} /{' '}
                        <span>
                          {shop.isDdp === true && 'DDP'}
                          {shop.isDdp === false && 'DDU'}
                        </span>
                        {' / '}
                        <span
                          className={classnames({
                            'text-fassto': shop.deliveryMethodName === '파스토',
                            'text-eldex': shop.deliveryMethodName === '엘덱스',
                          })}
                        >
                          {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                        </span>
                        )
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="주문상품">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemOrderList.length }}
                    >
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex gap-2">
                          <div>
                            <img
                              src={item.itemImageUrl}
                              alt={item.itemName}
                              className="max-w-[3.5rem] object-contain"
                            />
                          </div>
                          <div className="flex-1">
                            <div>{`${shop.shopName}, ${item.brandName}`}</div>
                            <OpenLink
                              url={`/product/productDetail/v2/${Number(
                                item.itemId
                              )}?mode=update`}
                            >
                              {item.itemName}
                            </OpenLink>
                            <div className="text-red-400">{item.sizeName}</div>
                            {(!!item.shippingCompanyName ||
                              !!item.waypointShippingCompanyName) && (
                              <div>
                                택배사:{' '}
                                {item.shippingCompanyName ||
                                  item.waypointShippingCompanyName}
                              </div>
                            )}
                            {(!!item.invoice || !!item.waypointInvoice) && (
                              <div>
                                운송장번호: {item.invoice || item.waypointInvoice}
                              </div>
                            )}
                            <div>
                              <Button
                                size="xs"
                                onClick={() =>
                                  setState({
                                    isChangeInvoiceOpen: true,
                                    itemOrderNumber:
                                      shop.itemOrderList[0].itemOrderNumber,
                                    invoice:
                                      shop.itemOrderList[0].invoice ||
                                      shop.itemOrderList[0].waypointInvoice,
                                    shippingCode:
                                      shop.itemOrderList[0].shippingCode ||
                                      shop.itemOrderList[0].waypointShippingCode,
                                    isWaypoint: !!shop.itemOrderList[0].waypointInvoice,
                                  })
                                }
                              >
                                {!!shop.itemOrderList[0].invoice ||
                                !!shop.itemOrderList[0].waypointInvoice
                                  ? '송장변경'
                                  : '송장등록'}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="수량">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemOrderList.length }}
                    >
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1 flex">
                          {item.quantity}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="상품금액">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemOrderList.length }}
                    >
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1 flex">
                          {priceFormat(item.itemAmount)}원
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="편집샵 결제금액">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div key={shopIndex} style={{ flex: shop.itemOrderList.length }}>
                      <div>{priceFormat(shop.payAmount)} 원</div>
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="총 결제금액">
                <NumberStat value={order.payAmount} />
              </div>
              <div aria-label="결제수단">{PAY_METHOD[order.payMethod]}</div>
              <div aria-label="주문상태">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemOrderList.length }}
                    >
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div className="flex-1 font-medium text-black" key={itemIndex}>
                          {paymentStatus(orderIndex, shopIndex, itemIndex)}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="관리자기능">
                <div className="flex flex-col h-full items-center gap-1">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      orderLink(orderIndex);
                    }}
                    className="w-full"
                    size="xs"
                    theme="primary"
                  >
                    관리
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setOrderState({
                        isMessageOpen: true,
                        phoneNumber: order.phoneNumber,
                      });
                    }}
                    className="w-full"
                    size="xs"
                  >
                    메시지
                  </Button>
                  <OrderListTooltip
                    hasMemo={order.hasMemo}
                    isLoading={isMemoLoading}
                    list={memoList}
                    fetchingOrderNumber={order.fetchingOrderNumber}
                    onMouseLeave={() =>
                      setOrderState({ memoList: [], isMemoLoading: false })
                    }
                    onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                  />
                </div>
              </div>
            </div>
          )}
          list={list}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setOrderState({
              selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
            })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
      <ReMessageModal
        isOpen={isMessageOpen}
        onClose={() => setOrderState({ isMessageOpen: false, phoneNumber: '' })}
        phoneNumber={phoneNumber}
      />
      <UpdateInvoiceModal
        isOpen={isChangeInvoiceOpen}
        onSuccess={get}
        onClose={() =>
          setState({
            isChangeInvoiceOpen: false,
            itemOrderNumber: '',
            invoice: '',
            shippingCode: '',
            isWaypoint: false,
          })
        }
        apiUrl={`/commerce/order/item-order/${itemOrderNumber}/invoice`}
        invoice={invoice}
        shippingCode={shippingCode}
        isWaypoint={isWaypoint}
      />
    </OrderCount>
  );
};

export default OrdersPage;
