import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

export interface Props {
  options: Array<{
    name: string;
    value: any;
  }>;
  onChange: (value: any) => void;
  value?: any;
  mode?: 'horizontal' | 'vertical';
  id: any;
  isInitialExisted?: boolean;
  disabled?: boolean;
}
interface State {}

const Radio: FunctionComponent<Props> = ({
  options,
  onChange,
  value,
  mode,
  id,
  isInitialExisted,
  disabled,
}) => {
  return (
    <div
      className={classnames('flex flex-wrap gap-2', {
        'flex-col': mode === 'vertical',
        'items-center': mode === 'horizontal',
      })}
    >
      {isInitialExisted && (
        <div className="flex items-center">
          <input
            type="radio"
            id={id + 'all'}
            value=""
            onChange={() => onChange('')}
            checked={value === ''}
            disabled={disabled}
          />
          <label htmlFor={id + 'all'} className="ml-1 mb-0 text-sm cursor-pointer">
            전체
          </label>
        </div>
      )}
      {options.map((item, index) => (
        <div key={index} className="flex items-center">
          <input
            type="radio"
            id={item.value + id}
            value={item.value}
            onChange={() => onChange(item.value)}
            checked={value === item.value}
            disabled={disabled}
          />
          <label htmlFor={item.value + id} className="ml-1 mb-0 text-sm cursor-pointer">
            {item.name}
          </label>
        </div>
      ))}
    </div>
  );
};

Radio.defaultProps = {
  mode: 'horizontal',
};

export default Radio;
