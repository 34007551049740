import React from 'react';
import * as style from './style';

const CustomTh = ({ children }) => (
  <th style={{ width: '100px', background: '#e4e4e4', textAlign: 'center' }}>
    {children}
  </th>
);

const BannerDetailInfo = ({ platformInfo }) => {
  return (
    <style.TableWrapper style={{ marginTop: '10px' }}>
      <style.Table>
        <tbody>
          <tr>
            <CustomTh>서비스</CustomTh>
            <th>{platformInfo.service}</th>
          </tr>
          <tr>
            <CustomTh>플랫폼</CustomTh>

            <th>{platformInfo.platform}</th>
          </tr>
          <tr>
            <CustomTh>성별</CustomTh>
            <th>{platformInfo.gender}</th>
          </tr>
          <tr>
            <CustomTh>페이지</CustomTh>
            <th>{platformInfo.page}</th>
          </tr>
        </tbody>
      </style.Table>
    </style.TableWrapper>
  );
};

export default BannerDetailInfo;
