import React, { useEffect, useState, useContext } from 'react';
import {
  ShopBasicInfo,
  ShopCustomsInfo,
  ShopExposureSet,
  ShopRenderInfo,
  ShopShippingInfo,
  MoonLoading,
  DropZoneImage,
} from 'components';
import { LoadingContext } from 'context';
import * as style from './style';
import axios from 'api/axios';
import { fetchingAPI } from 'api/fetchingAPI';
import * as TableStyle from 'components/UI/organisms/ShopRenderInfo/style.js';

export default () => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const pathNmae = window.location.pathname.split('/');
  const shopId = pathNmae[pathNmae.length - 1];

  const [clientHeight, setClinentHeight] = useState({
    '노출 설정': 0,
    '기본 정보': 0,
    '표시 정보': 0,
    '관부가세 정보': 0,
    '배송 정보': 0,
    '콘텐츠 정보': 0,
  });

  const [shop, setShop] = useState(null);

  const [modifyShop, setModifyShop] = useState({
    krName: '',
    name: '',
    description: '',
    imgUrl: '',
    url: '',
    deliveryUrl: '',
    type: '',
    mobileImgUrl: '',
  });

  const handleModifyShop = async () => {
    try {
      handleIsLoading(true);
      await fetchingAPI.put(`/shop/${shopId}`, modifyShop);
    } catch (err) {
      handleIsLoading(false);
      alert(err);
    } finally {
      handleIsLoading(false);
      window.location.reload();
    }
  };

  const handleOnDrop = (files) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post('/api/util/image', {
          path: 'shops',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setModifyShop({
              ...modifyShop,
              imageUrl: imgUrl,
            });
          }
        })
        .catch((err) => {
          alert(`
          📌 에러가 발생했습니다.! 
          아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    
          API 에러 발생 
          POST - /api/util/image
          ${err}`);
          console.log(err);
        });
    };

    const getBase64 = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result.split(',');
        cb(result[1]);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };

  const handleClinentHeight = (key, value) => {
    clientHeight[key] = value;
  };

  useEffect(() => {
    let initial = true;
    fetchingAPI
      .get(`/shop/${shopId}`)
      .then((res) => {
        if (initial) {
          setShop(res.data);
        }
      })
      .catch((err) => console.log(err));
    alert(`개선 중 입니다.\n 샵 이미지 수정용으로만 사용해주세요.`);
    return () => {
      initial = false;
    };
  }, []);

  useEffect(() => {
    setModifyShop({
      krName: shop?.krName || '',
      name: shop?.name || '',
      description: shop?.description || '',
      imgUrl: shop?.imgUrl || '',
      url: shop?.url || '',
      deliveryUrl: shop?.deliveryUrl || '',
      type: shop?.type || '',
      mobileImgUrl: shop?.mobileImgUrl ?? '',
    });
  }, [shop]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <style.Header>
        <style.Title>편집샵 정보</style.Title>
        <style.ShopName>
          {shop?.krName}({shop?.name})
        </style.ShopName>
        <style.TitleBar>
          <style.TitleItem
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            노출 설정
          </style.TitleItem>
          <style.TitleItem
            onClick={() => {
              window.scrollTo(0, clientHeight['노출 설정']);
            }}
          >
            기본 정보
          </style.TitleItem>
          <style.TitleItem
            onClick={() => {
              const result = clientHeight['노출 설정'] + clientHeight['기본 정보'];
              window.scrollTo(0, result);
            }}
          >
            표시 정보
          </style.TitleItem>
          <style.TitleItem
            onClick={() => {
              const result =
                clientHeight['노출 설정'] +
                clientHeight['기본 정보'] +
                clientHeight['표시 정보'];
              window.scrollTo(0, result);
            }}
          >
            관부가세 정보
          </style.TitleItem>
          <style.TitleItem
            onClick={() => {
              const result =
                clientHeight['노출 설정'] +
                clientHeight['기본 정보'] +
                clientHeight['표시 정보'] +
                clientHeight['관부가세 정보'];
              window.scrollTo(0, result);
            }}
          >
            배송 정보
          </style.TitleItem>
        </style.TitleBar>
      </style.Header>
      <style.Content>
        <ShopExposureSet shop={shop} handleClinentHeight={handleClinentHeight} />
        <ShopBasicInfo
          shop={shop}
          modifyShop={modifyShop}
          setModifyShop={(val) => setModifyShop(val)}
          handleClinentHeight={handleClinentHeight}
        />
        <ShopRenderInfo
          shop={shop}
          modifyShop={modifyShop}
          setModifyShop={(val) => setModifyShop(val)}
          handleOnDrop={handleOnDrop}
          handleClinentHeight={handleClinentHeight}
        />
        <ShopCustomsInfo shop={shop} handleClinentHeight={handleClinentHeight} />
        <ShopShippingInfo shop={shop} handleClinentHeight={handleClinentHeight} />
        <ManageContent useModifyShop={[modifyShop, setModifyShop]} />
      </style.Content>
      <style.Footer>
        <style.Button BC={'#FF6F61'} onClick={() => window.close()}>
          닫기
        </style.Button>
        <style.Button BC={'#0D9AE9'} onClick={handleModifyShop}>
          수정
        </style.Button>
      </style.Footer>
    </style.Container>
  );
};

const ManageContent = ({ useModifyShop: [modifyShop, setModifyShop] }) => {
  return (
    <>
      <TableStyle.Wrapper style={{ padding: 25 }}>
        <h5>콘텐츠 정보</h5>
        <TableStyle.TableWrapper>
          <TableStyle.TableTitle> 편집샵 이미지 </TableStyle.TableTitle>
          <TableStyle.TableValue>
            <DropZoneImage
              imageUrl={modifyShop.mobileImgUrl}
              setImageUrl={(mobileImgUrl) =>
                setModifyShop((p) => ({ ...p, mobileImgUrl }))
              }
            />
          </TableStyle.TableValue>
        </TableStyle.TableWrapper>
      </TableStyle.Wrapper>
    </>
  );
};
