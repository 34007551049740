import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import * as style from './style';

const DateBox = ({ setDate }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [year, setYear] = useState('--년도--');
  const [quater, setQuater] = useState('--분기별 선택--');
  const [month, setMonth] = useState('--월 선택--');
  const [monthList, setMonthList] = useState(['--월 선택--']);
  const [yearList, setYearList] = useState(['--년도--', '2020 년', '2021 년']);
  const [quaterList, setQuaterList] = useState([
    '--분기별 선택--',
    '1분기(1~3월)',
    '2분기(4~6월)',
    '3분기(7~9월)',
    '4분기(9~12월)',
  ]);

  const getMonthList = () => {
    const month_list = [];
    for (let i = 1; i <= 12; i++) {
      month_list.push(i + '월');
    }
    setMonthList([...monthList, ...month_list]);
  };

  const getResultDate = (year, month, date) => {
    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  const getToday = () => {
    let today = new Date().toISOString();
    today = today.slice(0, 10);
    setStartDate(today);
    setEndDate(today);
  };

  const getThisWeek = () => {
    let today = new Date();
    let week = today.getDay();
    let firstDay = new Date(today.getTime() - week * 24 * 60 * 60 * 1000);
    let lastDay = new Date(today.getTime() + (6 - week) * 24 * 60 * 60 * 1000);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    lastDay = getResultDate(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      lastDay.getDate(),
    );
    setStartDate(firstDay);
    setEndDate(lastDay);
  };

  const getThisMonth = () => {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    lastDay = getResultDate(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      lastDay.getDate(),
    );
    setStartDate(firstDay);
    setEndDate(lastDay);
  };

  const setFirstandEndDate = (
    year,
    startMonth,
    startDate,
    endMonth,
    endDate,
  ) => {
    let firstDay = new Date(year, startMonth, startDate);
    let endDay = new Date(year, endMonth, endDate);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    endDay = getResultDate(
      endDay.getFullYear(),
      endDay.getMonth() + 1,
      endDay.getDate(),
    );
    setStartDate(firstDay);
    setEndDate(endDay);
  };

  const setReset = () => {
    setYear('--년도--');
    setQuater('--분기별 선택--');
    setMonth('--월 선택--');
  };

  useEffect(() => {
    getMonthList();
    getThisMonth();
  }, []);

  useEffect(() => {
    if (
      year !== '--년도--' &&
      quater === '--분기별 선택--' &&
      month === '--월 선택--'
    ) {
      let resultYear = year.split(' ')[0];
      setFirstandEndDate(resultYear, 0, 1, 12, 0);
    } else if (year !== '--년도--' && quater !== '--분기별 선택--') {
      let resultYear = year.split(' ')[0];
      let resultQuater = quater.split('(')[0];
      if (resultQuater === '1분기') {
        setFirstandEndDate(resultYear, 0, 1, 3, 0);
      } else if (resultQuater === '2분기') {
        setFirstandEndDate(resultYear, 3, 1, 6, 0);
      } else if (resultQuater === '3분기') {
        setFirstandEndDate(resultYear, 6, 1, 9, 0);
      } else {
        setFirstandEndDate(resultYear, 9, 1, 12, 0);
      }
    } else if (year !== '--년도--' && month !== '--월 선택--') {
      let resultYear = year.split(' ')[0];
      let resultMonth = month.split('월')[0];
      setFirstandEndDate(resultYear, resultMonth - 1, 1, resultMonth, 0);
    }
  }, [year, quater, month]);

  useEffect(() => {
    setDate(startDate, endDate);
  }, [startDate, endDate]);

  return (
    <style.Container>
      <style.FormWrapper>
        <style.Select
          value={year}
          onChange={(e) => {
            if (e.target.value !== '--년도--') {
              setQuater('--분기별 선택--');
              setMonth('--월 선택--');
            }
            setYear(e.target.value);
          }}
        >
          {yearList.map((year) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </style.Select>
        <style.Select
          value={quater}
          onChange={(e) => {
            if (e.target.value !== '--분기별 선택--') {
              setMonth('--월 선택--');
            }
            setQuater(e.target.value);
          }}
        >
          {quaterList.map((quater) => {
            return (
              <option key={quater} value={quater}>
                {quater}
              </option>
            );
          })}
        </style.Select>
        <style.Select
          value={month}
          onChange={(e) => {
            if (e.target.value !== '--월 선택--') {
              setQuater('--분기별 선택--');
            }
            setMonth(e.target.value);
          }}
        >
          {monthList.map((month) => {
            return (
              <option key={month} value={month}>
                {month}
              </option>
            );
          })}
        </style.Select>
      </style.FormWrapper>
      <style.DateWrapper>
        <style.DateInput
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />
        <div>~</div>
        <style.DateInput
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
        <style.ButtonWrapper>
          <Button
            variant="secondary"
            onClick={() => {
              getToday();
              setReset();
            }}
          >
            오늘
          </Button>{' '}
          <Button
            variant="secondary"
            onClick={() => {
              getThisWeek();
              setReset();
            }}
          >
            이번주
          </Button>{' '}
          <Button
            variant="secondary"
            onClick={() => {
              getThisMonth();
              setReset();
            }}
          >
            이번 달
          </Button>
        </style.ButtonWrapper>
      </style.DateWrapper>
    </style.Container>
  );
};

export default DateBox;
