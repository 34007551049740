import styled from 'styled-components';
import * as style from '../../UI/atoms/SimpleInput/style';
import React from 'react';

export const Wrapper = styled.div`
  background-color: white;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
  font-size: 1.2rem;
  color: #4f4e4e;
`;

export const Title = styled.strong`
  margin-right: auto;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  background-color: #e5e5e547;
  font-weight: bold;
  padding: 10px 15px;
  overflow: auto;
  border-right: 1px solid #ddd;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  overflow: auto;
  padding: 5px 10px;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
  margin: 0 10px;
  cursor: pointer;
`;

export const Text = styled.p`
  margin: 0;
`;

export const Icon = {
  width: '30px',
  height: '30px',
  cursor: 'pointer',
};

export const SizeWrapper = styled.div`
  margin: 12px 0;
`;
export const SizeComp = styled.div`
  margin-top: 4px;
  display: grid;
  grid-template-columns: 200px 150px 150px;
  grid-column-gap: 5px;
  padding: 8px 4px;
  border-bottom: 1px solid #d1d1d1;
  text-align: center;
`;

export const Button = styled.div`
  padding: 4px;
  border-radius: 4px;
  background-color: ${({ color }) => (color ? color : '#000')};
  color: #fff;
  cursor: pointer;
  text-align: center;
  padding: 4px 8px;
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: 425px 21px;
  width: 100%;
`;

export const Input = styled.input.attrs({ type: 'text' })``;
