import React from 'react';
import styled from 'styled-components';
import { TableElement } from '../Containers';

const Container = styled(TableElement)`
background-color:#201c1c ;
color:white;
`;

const ListHead = () => {

    return (
        <Container>
            <span>위치고정</span>
            <span>삭제</span>
            <span>순서변경</span>
            <span>활성화</span>
            <span>컴포넌트 명</span>
            <span>컴포넌트 타입</span>
        </Container>
    )
}

export default ListHead;