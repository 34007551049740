import styled from 'styled-components';

export const Select = styled.select`
  font-size: 16px;
  height: 28px;
  margin-right: 8px;
  border-radius: 20px;
  outline: none;
  padding: 0 5px;
`;

export const SaveButton = styled.button`
  border: none;
  background: #dddddd;
  border-radius: 20px;
  font-family: AppleSDGothicNeoB00;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
  color: #000000;
`;
export const DeleteButton = styled.button`
  margin-left: 5px;
  border: none;
  background: red;
  border-radius: 20px;
  font-family: AppleSDGothicNeoB00;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  padding: 5px 10px;
  color: white;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
`;
