import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingContext } from 'context';
import axios from 'api/axios';
import { fetchingSymbol } from 'assets/images';
import View from './View';
import * as style from './style';
import { headerAction } from 'actions/action';
import { Loading } from 'components';

const ModifyExhibition = () => {
  const dispatch = useDispatch();
  const exhibitionsItem = useSelector((state) => state.exhibition.selectItem);

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [state, setState] = useState({
    exhibitionId: exhibitionsItem.exhibitionId,
    cafe24CategoryId: Number(exhibitionsItem.cafe24CategoryId),
    name: exhibitionsItem.name,
    topBannerImageUrl: exhibitionsItem.topBannerImageUrl || '',
    bannerImageUrl: exhibitionsItem.bannerImageUrl || '',
    landingImageUrl: exhibitionsItem.landingImageUrl || '',
    gender: exhibitionsItem.gender,
    isActive: String(exhibitionsItem.isActive),
    isActiveOnApp: 0,
    isVisibleToCafe24: String(exhibitionsItem.isVisibleToCafe24),
    isVisibleToAppMain: String(exhibitionsItem.isVisibleToAppMain),
    isVisibleToAppCategory: String(exhibitionsItem.isVisibleToAppCategory),
    startedAt: exhibitionsItem.startedAt
      ? exhibitionsItem.startedAt.slice(0, 10)
      : '',
    endedAt: exhibitionsItem.endedAt
      ? exhibitionsItem.endedAt.slice(0, 10)
      : '',
    startTime: exhibitionsItem.startedAt
      ? exhibitionsItem.startedAt.slice(11, 19)
      : '',
    endTime: exhibitionsItem.endedAt
      ? exhibitionsItem.endedAt.slice(11, 19)
      : '',
  });

  const handleTitle = (e) => {
    setState({
      ...state,
      name: e.target.value,
    });
  };

  const handleGender = (e, result) => {
    if (e.target.checked) {
      setState({
        ...state,
        gender: result,
      });
    } else {
      setState({
        ...state,
        gender: '',
      });
    }
  };

  const handleIsActive = (e, result) => {
    if (e.target.checked) {
      setState({
        ...state,
        isActive: result,
      });
    } else {
      setState({
        ...state,
        isActive: '',
      });
    }
  };

  const handleIsVisibleToAppMain = (e) => {
    if (e.target.checked) {
      setState({
        ...state,
        isVisibleToAppMain: '1',
      });
    } else {
      setState({
        ...state,
        isVisibleToAppMain: '0',
      });
    }
  };

  const handleIsVisibleToAppCategory = (e) => {
    if (e.target.checked) {
      setState({
        ...state,
        isVisibleToAppCategory: '1',
      });
    } else {
      setState({
        ...state,
        isVisibleToAppCategory: '0',
      });
    }
  };

  const handleIsVisibleToCafe24 = (e) => {
    if (e.target.checked) {
      setState({
        ...state,
        isVisibleToCafe24: '1',
      });
    } else {
      setState({
        ...state,
        isVisibleToCafe24: '0',
      });
    }
  };

  const handleUnlimited = () => {
    setState({
      ...state,
      startedAt: '',
      startTime: '',
      endedAt: '',
      endTime: '',
    });
  };

  const handleDate = (e, target) => {
    if (target === 'start') {
      setState({
        ...state,
        startedAt: e.target.value,
      });
    } else {
      setState({
        ...state,
        endedAt: e.target.value,
      });
    }
  };

  const handleTime = (e, target) => {
    if (target === 'start') {
      setState({
        ...state,
        startTime: e.target.value,
      });
    } else {
      setState({
        ...state,
        endTime: e.target.value,
      });
    }
  };

  const handleImage = (title, value) => {
    if (title === '목록 배너') {
      setState({
        ...state,
        bannerImageUrl: value,
      });
    } else if (title === '상단 고정 배너') {
      setState({
        ...state,
        topBannerImageUrl: value,
      });
    } else {
      setState({
        ...state,
        landingImageUrl: value,
      });
    }
  };

  const handleModifyExhibition = async () => {
    try {
      handleIsLoading(true);
      const body = {
        name: state.name,
        gender: state.gender,
        topBannerImageUrl: state.topBannerImageUrl,
        bannerImageUrl: state.bannerImageUrl,
        landingImageUrl: state.landingImageUrl,
        isActive: Number(state.isActive),
        isActiveOnApp: state.isActiveOnApp,
        isVisibleToCafe24: Number(state.isVisibleToCafe24),
        isVisibleToAppMain: Number(state.isVisibleToAppMain),
        isVisibleToAppCategory: Number(state.isVisibleToAppCategory),
        cafe24CategoryId: state.cafe24CategoryId,
        exhibitionId: state.exhibitionId,
      };

      if (state.startedAt.length) {
        if (state.startTime.length) {
          const date = new Date(`${state.startedAt}T${state.startTime}`);
          date.setHours(date.getHours() + 9);
          const result = new Date(date).toISOString();
          const resultTime = result.slice(11, 19);
          body.startedAt = `${state.startedAt} ${resultTime}`;
        } else {
          body.startedAt = `${state.startedAt} 00:00:00`;
        }
      } else {
        body.startedAt = null;
      }

      if (state.endedAt.length) {
        if (state.endTime.length) {
          const date = new Date(`${state.endedAt}T${state.endTime}`);
          date.setHours(date.getHours() + 9);
          const result = new Date(date).toISOString();
          const resultTime = result.slice(11, 19);
          body.endedAt = `${state.endedAt} ${resultTime}`;
        } else {
          body.endedAt = `${state.endedAt} 00:00:00`;
        }
      } else {
        body.endedAt = null;
      }

      await axios({
        method: 'PUT',
        url: '/api/exhibitions',
        data: [body],
      });
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      PUT - /api/exhibitions
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
      dispatch(headerAction(true));
      window.opener.parent.location = '/product/exhibitionManagement';
      window.close();
    }
  };

  useEffect(() => {
    dispatch(headerAction(false));
  }, []);

  return (
    <style.Wrapper>
      <style.HeaderOuter>
        <style.HeaderItem>
          <img src={fetchingSymbol} alt="fetchingSymbol" width={50} />
          <style.Title>
            <div>기획전 수정</div>
          </style.Title>
        </style.HeaderItem>
        <style.HeaderItem></style.HeaderItem>
      </style.HeaderOuter>
      {isLoading && <Loading />}
      <style.Outer>
        <View
          state={state}
          handleTitle={handleTitle}
          handleGender={handleGender}
          handleIsActive={handleIsActive}
          handleIsVisibleToAppMain={handleIsVisibleToAppMain}
          handleIsVisibleToAppCategory={handleIsVisibleToAppCategory}
          handleIsVisibleToCafe24={handleIsVisibleToCafe24}
          handleUnlimited={handleUnlimited}
          handleDate={handleDate}
          handleTime={handleTime}
          handleImage={handleImage}
        />
      </style.Outer>
      <style.BottomOuter>
        <style.Button background="#69ABF9" onClick={handleModifyExhibition}>
          수정
        </style.Button>
        <style.Button
          background="#FF6F61"
          onClick={() => {
            dispatch(headerAction(true));
            window.close();
          }}
        >
          취소
        </style.Button>
      </style.BottomOuter>
    </style.Wrapper>
  );
};

export default ModifyExhibition;
