import React from 'react';

import * as style from './style';

const DateGroup = ({ searchOption, setSearchOption }) => (
  <style.DateBoxWrapper>
    {/* <style.FormWrapper>
      <style.Select
        onChange={(e) => {
          setSearchOption({
            ...searchOption,
            periodType: e.target.value,
          });
        }}
      >
        <option value="">날짜기준선택</option>
        <option value="1">업데이트날짜</option>
        <option value="0">상품생성날짜</option>
      </style.Select>
    </style.FormWrapper> */}
    <style.FormWrapper>
      <div>
        <style.Input
          type="date"
          value={searchOption.firstDate}
          onChange={(e) => {
            setSearchOption({
              ...searchOption,
              firstDate: e.target.value,
            });
          }}
        />
      </div>
      <div>~</div>
      <div>
        <style.Input
          type="date"
          value={searchOption.lastDate}
          onChange={(e) => {
            setSearchOption({
              ...searchOption,
              lastDate: e.target.value,
            });
          }}
        />
      </div>
      {[
        { value: '1', label: '일간' },
        { value: '7', label: '주간' },
        { value: '40', label: '월간' },
      ].map((el) => (
        <style.EventDurationWrapper key={el.value}>
          <style.Input
            type="radio"
            name="eventDuration"
            value={el.value}
            onChange={(e) => {
              setSearchOption({
                ...searchOption,
                eventDuration: e.target.value,
              });
            }}
          />
          <style.EventDurationText>{el.label}</style.EventDurationText>
        </style.EventDurationWrapper>
      ))}
    </style.FormWrapper>
  </style.DateBoxWrapper>
);

export default DateGroup;
