import { Standard } from './types';

export const standardOptions: { value: Standard; name: string }[] = [
  {
    value: Standard.AGENCY_FEE,
    name: '발생 수익 기준',
  },
  {
    value: Standard.SALES,
    name: '매출액 기준',
  },
  {
    value: Standard.TRANSACTION,
    name: '거래액 기준',
  },
];
