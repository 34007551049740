import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  padding: 200px 0;
`;

export const Inner = styled.div`
  width: 80%;
  @media all and (min-width: 900px) {
    width: 20%;
  }
`;
