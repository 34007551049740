import styled from 'styled-components';

export const ImgWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ImgInner = styled.div`
  width: 50%;
  height: 50%;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const Text = styled.p`
  text-align: center;
`;

export const LoadOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Body = {
  overflow: 'auto',
  height: '700px',
};
