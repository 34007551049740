import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  position: relative;
`;

export const Bar = styled.div`
  width: 100%;
  background-color: #1a1a1a;
  padding: 15px 20px;
  text-align: right;
`;

export const Select = styled.select`
  color: white;
  background-color: #1a1a1a;
  &:focus {
    outline: none;
  }
`;

export const ButtonListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;
