import React, { useEffect, useState, useCallback, useContext } from 'react';
import CanvasJSReact from 'assets/canvasjs.react';
import { LoadingContext } from 'context';
import { inflowRate } from 'chart';
import fetchingLog from 'api/fetchingLog';
import * as style from './style';
import {
  Title,
  DateBox,
  Input,
  PlatformCheckBox,
  SearchButtonBox,
  MoonLoading,
} from 'components';

const InflowRate = () => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [options, setOptions] = useState(inflowRate);

  const [initial, setInitial] = useState(false);
  const [term, setTerm] = useState('7');
  const [startDate, setStartDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [platform, setPlatfrom] = useState([
    'pc_web',
    'mobile_web',
    'ios',
    'android',
  ]);

  const handlePlatformCheck = (e) => {
    if (e.target.checked) {
      const find = platform.find((el) => el === e.target.value);
      if (!find) {
        setPlatfrom([...platform, e.target.value]);
      }
    } else {
      const resultPlatform = platform.filter((el) => el !== e.target.value);
      setPlatfrom(resultPlatform);
    }
  };

  const onChange = (e) => {
    setTerm(e.target.value);
  };

  const setDate = (start, last) => {
    setStartDate(start);
    setLastDate(last);
  };

  const getResultDate = (year, month, date) => {
    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  const getThisMonth = () => {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    lastDay = getResultDate(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      lastDay.getDate(),
    );
    setStartDate(firstDay);
    setLastDate(lastDay);
    setInitial(true);
  };

  const handleSearch = useCallback(async () => {
    try {
      handleIsLoading(true);
      let isFirst = [];
      let isReturn = [];
      let isNotReturn = [];

      const params = {
        start: startDate,
        end: lastDate,
        platforms: platform,
        within: term,
      };

      const res = await fetchingLog.get('/api/inflow/ratio/is-first', {
        params,
      });

      const { data } = res;

      isFirst = data.map((el) => {
        return { label: el.date, y: el.first };
      });

      isReturn = data.map((el) => {
        return { label: el.date, y: el.return };
      });

      isNotReturn = data.map((el) => {
        return { label: el.date, y: el.not_return };
      });

      handleIsLoading(false);

      setOptions({
        ...options,
        data: [
          {
            type: 'stackedColumn100',
            name: '신규 방문',
            showInLegend: true,
            color: '#D4AF37',
            dataPoints: isFirst,
          },
          {
            type: 'stackedColumn100',
            name: '기간 내 방문',
            showInLegend: true,
            color: '#C0C0C0',
            dataPoints: isReturn,
          },
          {
            type: 'stackedColumn100',
            name: '기간 외 방문',
            showInLegend: true,
            color: '#CD7F32',
            dataPoints: isNotReturn,
          },
        ],
      });
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/inflow/ratio/is-first
      ${err}`);
      console.error(err);
    }
  }, [options, startDate, lastDate, platform]);

  useEffect(() => {
    getThisMonth();
  }, []);

  useEffect(() => {
    if (initial) {
      handleSearch();
    }
  }, [initial]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <Title title={'신규 및 재유입 비율'} />
      <style.TitleDescrip>
        선택 기간 내의 총 유입수 중 신규 유입과 재유입 비율
      </style.TitleDescrip>
      <Input title="재유입 기준" value={term} onChange={onChange} />
      <DateBox setDate={setDate} />
      <PlatformCheckBox
        platform={platform}
        handlePlatformCheck={(e) => handlePlatformCheck(e)}
      />
      <SearchButtonBox handleSearch={handleSearch} />
      <CanvasJSChart options={options} />
    </style.Container>
  );
};
export default InflowRate;
