import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #ddd;
`;

export const Inner = styled.div`
  display: flex;
  width: 100%;
  ${(props) => props.BB && `border-bottom: ${props.BB};`}
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  background-color: #9a949421;
  border-right: 1px solid #ddd;
  width: 30%;
  padding: 25px 0;
`;

export const CheckboxOuter = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  display: flex;
  padding: 15px 20px;
  @media all and (min-width: 1000px) {
    flex-direction: row;
  }
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 30px;
  height: 30px;
  margin: 0 10px;
`;

export const Text = styled.p`
  margin: 0;
  margin-right: 20px;
`;

export const SelectOuter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  padding: 15px 20px;
`;

export const Select = styled.select`
  width: 100%;
  padding: 10px 20px;
  &:focus {
    outline: none;
  }
  @media all and (min-width: 1000px) {
    width: 70%;
  }
`;

export const InputText = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  &:focus {
    outline: none;
  }
  padding: 5px 20px;
  @media all and (min-width: 1000px) {
    width: 70%;
  }
`;
