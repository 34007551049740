import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 25px;
`;

export const Title = styled.h5``;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  background-color: #E7E7E7;
  overflow-x: auto;
`;

export const Input = styled.input.attrs({
  type: 'text'
})`
  padding: 5px 10px;
  width: 70%;
  margin-right: 5px;
  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 5px 20px;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  &:focus {
    outline: none;
  }
`;

export const P = styled.p`
  margin: 0;
  margin-right: 10px;
  white-space: nowrap;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }
`
