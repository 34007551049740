import { GridTable, RePagination, Tabs } from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { request, useObject } from 'services';

interface ILog {
  idx: number;
  ip: string;
  loginAt?: string;
  editor?: string;
  updatedAt?: string;
}

export interface Props {}
interface State {
  tab: number;
  isFetching: boolean;
  list: ILog[];
  page: number;
  total: number;
  isLoading: boolean;
  pageSize: number;
}

const UserLog: FunctionComponent<Props> = () => {
  const [{ tab, isFetching, list, page, total, isLoading, pageSize }, setState] =
    useObject<State>({
      tab: 0,
      isFetching: true,
      list: [],
      page: 1,
      total: 0,
      isLoading: false,
      pageSize: 10,
    });
  const onTabChange = async () => {
    if (tab === 0) getLoginLog();
    else if (tab === 1) getInfoUpdateLog();
  };
  const { idx } = useParams<{ idx: string }>();
  const getLoginLog = async () => {
    try {
      const { count, data } = await request.get<any, { data: ILog[]; count: number }>(
        `/commerce/user/${idx}/login-history`,
        {
          params: {
            limit: pageSize,
            page,
          },
        }
      );
      setState({ list: data, total: count });
    } catch (err) {
      console.log(err);
    }
  };
  const getInfoUpdateLog = async () => {
    try {
      const { count, data } = await request.get<any, { data: ILog[]; count: number }>(
        `/commerce/user/${idx}/info-update-history`,
        { params: { limit: pageSize, page } }
      );
      setState({ list: data, total: count });
    } catch (err) {
      console.log(err);
    }
  };
  const heads = useMemo(() => {
    if (tab === 0) return ['번호', '일시', 'IP'];
    else if (tab === 1) return ['번호', '일시', 'IP', '관리자'];
    else return [];
  }, [tab]);

  useEffect(() => {
    onTabChange();
  }, [tab, page, pageSize]);
  return (
    <div className="p-4">
      <div style={{ marginBottom: 30 }}>
        <Tabs
          onTabChange={(tab) => setState({ tab, pageSize: 10, page: 1 })}
          value={tab}
          options={['로그인 로그', '회원정보 수정 로그']}
        />
      </div>
      <GridTable
        columns={heads}
        list={list}
        noSelection
        renderItem={(item, key) => (
          <div key={key}>
            <div>{item.idx}</div>
            <div>
              {dayjs(item.loginAt || item.updatedAt).format('YYYY.MM.DD HH:mm:ss')}
            </div>
            <div>{item.ip}</div>
            {!!item.editor && <div>{item.editor}</div>}
          </div>
        )}
      />
      <div className="flex justify-between items-center">
        <div className="pl-2 text-gray-500 tracking-widest">
          <span>총</span>
          <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
          <span>개</span>
        </div>
        <RePagination
          currentPage={page}
          onPageChange={(page) => setState({ page })}
          totalCount={total}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default UserLog;
