import styled from "styled-components";

export const Background = styled.div`
position: fixed;
left: 0;
right: 0;
top: 0;
bottom: 0;
display: flex;
align-items: center;
justify-content: center;
background-color:rgba(32, 32, 32, 0.5); 
z-index:101;
`;

export const Modal = styled.div`
width: 90vw;
height: 90vh;
background: #FFFFFF;
border-radius: 15px;
overflow: hidden;
`;

export const ModalHeader = styled.div`
background: #000000;
text-align: center;
color: #FF6F61;
padding: 24px;
font-size: 18px;
margin-bottom: 10px;
`;