import styled from "styled-components";

export const Table = styled.div`
display: flex;
flex-direction: column;
border:0.5px solid #B4B4B4;
border-bottom:none;`;
export const Tr = styled.div`
border-bottom:0.5px solid #B4B4B4;
display:flex;
// flex-wrap: wrap;
justify-content: space-between;
`;

export const flexibleTd = styled.div`
width:${({ widthPcnt }) => widthPcnt}%;
${({ noBorder }) => noBorder ? '' : 'border-right:0.5px solid #B4B4B4;'}

overflow:auto;

display: flex;
flex-direction:column;
align-items: center;
justify-content: center;

text-align:center;
font-size:12px;

`;

export const MiddleTd = styled.div`
width:6%;
border-right:0.5px solid #B4B4B4;
overflow:auto;

display: flex;
flex-direction:column;
align-items: center;
justify-content: center;

`;

export const WideTd = styled.div`
width:9%;
border-right:0.5px solid #B4B4B4;

display: flex;
flex-direction:column;
align-items: center;
justify-content: center;

`;

export const NarrowTd = styled.div`
width:2.5%;

display: flex;
flex-direction:column;
align-items: center;
justify-content: center;

${({ noBorder }) => noBorder ? '' : 'border-right:0.5px solid #B4B4B4;'}`;

export const Img = styled.img`height:110px;`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;

export const Button = styled.button`
margin-right:10px;
border:none;
color:white;
padding:3px 10px;
border-radius: 2px;
`;
export const Input = styled.select``;

export const CatalogIdInput = styled.input`
width:900%;`;

export const GoNaverShopping = styled.a`
${({ show }) => show ? '' : 'display:none;'}
width:100%;`;

export const SaveCatalogId = styled.img`
${({ show }) => show ? '' : 'display:none;'}
width:10%;`;

export const CatalogIdContainer = styled.div`
display:flex;
`;

export const SelcetDelivery = styled.select`
`;