import { CallCatetoryMapModalButtons, CategoryMapTableTr } from 'components/UI';
import React, { useEffect, useState } from 'react';
import * as style from './style';

const CategoryMapTable = ({
  maps,
  totalCount,
  fetchItems,
  categoryMap,
  searchOptionTableState,
  setSearchOptionTableState,
  currentPage,
}) => {
  const [checkedMaps, setCheckedMaps] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [payload, setPayload] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setCheckedMaps([]);
    setPayload({});
  }, [maps]);

  return (
    <>
      <span style={{ fontSize: '12px', paddingBottom: '7px' }}>총 {totalCount}개</span>
      <style.Table>
        <style.Tr style={{ background: '#BEBEBE', padding: '13px 22px' }}>
          입력 완료하는 즉시 저장됩니다.
          <CallCatetoryMapModalButtons
            searchOptionTableState={searchOptionTableState}
            setSearchOptionTableState={setSearchOptionTableState}
            showModal={showModal}
            setShowModal={setShowModal}
            checkedMaps={checkedMaps}
            checkedIds={checkedIds}
            fetchItems={fetchItems}
            currentPage={currentPage}
          />
        </style.Tr>
        <style.Tr style={{ background: '#E4E4E4' }}>
          <style.flexibleTd
            widthPcnt={10}
            onClick={() => {
              if (checkedMaps.length === maps.length) {
                setCheckedMaps([]);
                setCheckedIds([]);
              } else {
                setCheckedMaps(
                  maps.map((obj) => `${obj.shopId} - ${obj.gender} - ${obj.categoryName}`)
                );
                setCheckedIds(maps);
              }
            }}
          >
            <style.Checkbox
              onChange={() => {
                if (checkedMaps.length === maps.length) {
                  setCheckedMaps([]);
                } else {
                  setCheckedMaps(maps.map((obj) => obj.keyword));
                }
              }}
              checked={checkedMaps.length === maps.length}
            />
          </style.flexibleTd>
          <style.flexibleTd widthPcnt={25}>매핑여부</style.flexibleTd>
          <style.flexibleTd widthPcnt={25}>편집샵</style.flexibleTd>
          <style.flexibleTd widthPcnt={35}>편집샵 카테고리 성별</style.flexibleTd>
          <style.flexibleTd widthPcnt={100}>편집샵 카테고리명</style.flexibleTd>
          <style.flexibleTd widthPcnt={20}>페칭 카테고리 ID</style.flexibleTd>
          <style.flexibleTd widthPcnt={100}>페칭 카테고리</style.flexibleTd>
          <style.flexibleTd widthPcnt={30}>수정정보</style.flexibleTd>
        </style.Tr>
        {maps.map((map, i) => (
          <CategoryMapTableTr
            key={`${map.shopId} - ${map.gender} - ${map.categoryName}`}
            item={map}
            i={i}
            categoryMap={categoryMap}
            checkedMaps={checkedMaps}
            setCheckedMaps={setCheckedMaps}
            checkedIds={checkedIds}
            setCheckedIds={setCheckedIds}
            payload={payload}
            setPayload={setPayload}
          />
        ))}
      </style.Table>
    </>
  );
};

export default CategoryMapTable;
