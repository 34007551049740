import React from 'react';
import * as style from './style';
import { reset } from '../../../../assets/images';

const OptionCheckboxListMCategories = ({
  mCategories,
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const dict = [
    { name: '의류', id: 18 },
    { name: '가방', id: 19 },
    { name: '신발', id: 20 },
    { name: '악세서리', id: 21 },
  ];

  const categoryIdList = searchOptionTableState.categoryIdList;
  const setCategoryIdList = (categoryId) => {
    const loc = categoryIdList.indexOf(categoryId);
    let newCategoryIdList = [];
    if (loc === -1) {
      newCategoryIdList = [...categoryIdList, categoryId];
    } else {
      newCategoryIdList = [
        ...categoryIdList.slice(0, loc),
        ...categoryIdList.slice(loc + 1),
      ];
    }
    setSearchOptionTableState({
      ...searchOptionTableState,
      categoryIdList: newCategoryIdList,
    });
  };

  return (
    <style.Container>
      <style.Title>
        <style.TitleSpan>남성 카테고리</style.TitleSpan>
        <style.ResetButton
          src={reset}
          onClick={() => {
            const mCatSet = new Set(categoryIdList);
            mCategories.forEach(({ id }) => mCatSet.delete(id));
            setSearchOptionTableState({
              ...searchOptionTableState,
              categoryIdList: [...mCatSet.values()],
            });
          }}
        />
      </style.Title>
      <style.Content>
        <style.CheckboxContainer>
          <style.Checkbox
            onClick={() => {
              const mCatSet = new Set(categoryIdList);
              if (mCategories.every(({ id }) => mCatSet.has(id))) {
                mCategories.forEach(({ id }) => mCatSet.delete(id));
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  categoryIdList: [...mCatSet.values()],
                });
              } else {
                mCategories.forEach(({ id }) => mCatSet.add(id));
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  categoryIdList: [...mCatSet.values()],
                });
              }
            }}
            checked={mCategories.every(({ id }) => categoryIdList.includes(id))}
          />
          <style.Label>전체선택</style.Label>
        </style.CheckboxContainer>
        {dict.map((subCategory) => (
          <>
            <style.CheckboxContainer>
              <style.Checkbox
                onClick={() => {
                  const allSubCatIds = mCategories
                    .filter((obj) => obj.parentId === subCategory.id)
                    .map((obj) => obj.id);
                  const mCatSet = new Set(categoryIdList);
                  if (allSubCatIds.every((id) => mCatSet.has(id))) {
                    allSubCatIds.forEach((id) => mCatSet.delete(id));
                    setSearchOptionTableState({
                      ...searchOptionTableState,
                      categoryIdList: [...mCatSet.values()],
                    });
                  } else {
                    allSubCatIds.forEach((id) => mCatSet.add(id));
                    setSearchOptionTableState({
                      ...searchOptionTableState,
                      categoryIdList: [...mCatSet.values()],
                    });
                  }
                }}
                checked={mCategories
                  .filter((obj) => obj.parentId === subCategory.id)
                  .map((obj) => obj.id)
                  .every((id) => categoryIdList.includes(id))}
              />
              <style.Initial>{subCategory.name}</style.Initial>
            </style.CheckboxContainer>
            {mCategories
              .filter((obj) => obj.parentId === subCategory.id)
              .map((obj, index) => (
                <style.CheckboxContainer key={index}>
                  <style.Checkbox
                    onClick={() => setCategoryIdList(obj.id)}
                    checked={categoryIdList.includes(obj.id)}
                  />
                  <style.Label>{obj.name}</style.Label>
                </style.CheckboxContainer>
              ))}
          </>
        ))}
      </style.Content>
    </style.Container>
  );
};

export default OptionCheckboxListMCategories;
