import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingContext } from 'context';
import {
  detailNoticeAction,
  addSelectNoticeAction,
  removeSelectNoticeAction,
  removeSelectNoticeAllAction,
} from 'actions/action';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import { Progress, Loading } from 'components';
import axios, { fetchingAPI } from 'api/axios';
import * as style from './style';

const NoticeList = (props) => {
  const dispatch = useDispatch();
  const noticeIeds = useSelector((state) => state.selectNotice.noticeId);

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [noticeList, setNoticeList] = useState([]);

  const load = async (cleanUp) =>
    await fetchingAPI
      .get('/notice')
      .then((res) => {
        if (!cleanUp) {
          setNoticeList(res.data.data);
        }
      })
      .catch((err) => {
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        GET - /api/notice
        ${err}`);
        console.error(err);
      });

  useEffect(() => {
    dispatch(removeSelectNoticeAllAction([]));
  }, [dispatch]);

  useEffect(() => {
    let cleanUp = false;

    load(cleanUp);

    return () => {
      cleanUp = true;
    };
  }, []);

  const onActivate = async () => {
    if (noticeIeds.length === 0) {
      alert('하나 이상의 공지를 선택해주세요.');
    }
    if (!window.confirm('선택한 공지사항의 활성화 상태를 변경하시겠습니까?')) {
      return;
    }
    try {
      handleIsLoading(true);
      await Promise.all(
        noticeIeds.map((idx) =>
          fetchingAPI.put(`/notice/${idx}`, {
            isActive: !noticeList.find((notice) => notice.idx === idx).isActive,
          })
        )
      );
      await load();
    } catch (error) {
      alert(`실패 : error ${JSON.stringify(error)}`);
    } finally {
      handleIsLoading(false);
    }
  };

  console.log(noticeIeds);

  return (
    <style.Outer>
      {isLoading && <Loading />}
      <style.ButtonOuter>
        <Button
          style={style.ButtonItem}
          variant="dark"
          onClick={() => props.history.push('/content/noticeList/addNotice')}
        >
          + 추가
        </Button>
        <Button
          style={style.ButtonItem}
          variant="secondary"
          onClick={() => alert('기능 없음')}
        >
          삭제
        </Button>
        <Button
          style={style.ButtonItem}
          variant="danger"
          onClick={() => alert('기능 없음')}
        >
          중요처리
        </Button>
        <Button style={style.ButtonStyle} variant="success" onClick={onActivate}>
          활성화 처리
        </Button>
      </style.ButtonOuter>
      <h3 style={{ textAlign: 'center' }}>공지사항 목록</h3>
      <style.NoticeListOuter>
        <style.NoticeListInner>
          {noticeList.map((el) => {
            return (
              <ListGroup key={el.idx} style={style.marginTop20}>
                <ListGroup.Item
                  action
                  onClick={() => {
                    props.history.push(`/content/noticeList/${el.idx}`);
                    dispatch(detailNoticeAction(el));
                  }}
                >
                  <style.ListGroup>
                    <style.ListGroupItem item="1">
                      <Form.Check
                        type="checkbox"
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                          if (e.target.checked) {
                            dispatch(addSelectNoticeAction(el.idx));
                          } else {
                            dispatch(removeSelectNoticeAction(el.idx));
                          }
                        }}
                      />
                    </style.ListGroupItem>
                    <style.ListGroupItem item="2">
                      <style.NoticeTitle>{el.title}</style.NoticeTitle>
                      <Progress progressData={el} />
                      <style.Date>{el.updatedAt}</style.Date>
                    </style.ListGroupItem>
                  </style.ListGroup>
                </ListGroup.Item>
              </ListGroup>
            );
          })}
        </style.NoticeListInner>
      </style.NoticeListOuter>
    </style.Outer>
  );
};

export default withRouter(NoticeList);
