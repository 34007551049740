import { Image } from 'antd';
import {
  Button,
  Card,
  Check,
  Checkbox,
  GridTable,
  InputNumber,
  NumberStat,
  OpenLink,
  RefundAccountModal,
  Select,
  Spinner,
  Textarea,
} from 'components';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  fileUpload,
  ORDER_RETURN_STATUS,
  PAY_METHOD,
  POINT_REFUND_STATUS,
  request,
  TAX_REFUND_STATUS,
  useObject,
} from 'services';
import useSWR from 'swr';
import { copyText, priceFormat } from 'utils';
import classnames from 'classnames';
import { ArrowNarrowRightIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';

const TaxItem: FunctionComponent<{
  url: string;
  name: string;
  onUpload: () => void;
  isLoading: boolean;
}> = ({ url, name, onUpload, isLoading }) => {
  return (
    <div className="flex">
      <div className="w-64">
        <Check checked={!!url} />
        <span className="text-sm ml-1">{name}</span>
      </div>
      <div className="flex gap-5">
        <Button size="xs" theme="secondary" isLoading={isLoading} onClick={onUpload}>
          업로드
        </Button>
        {!!url && (
          <OpenLink url={url}>
            {url.length > 40 ? `${url.slice(0, 40)}...` : url}
          </OpenLink>
        )}
        {!!url && !url.includes('.pdf') && (
          <Image src={url} style={{ width: 40, height: 40 }} />
        )}
      </div>
    </div>
  );
};

interface IOrderRefund {
  dutyRefundInfo: {
    bankbookCopyFileUrl: string;
    invoiceFileUrl: string;
    refundMemo: string;
    residentRegistrationNumber: string;
    shopCancelFileUrl: string;
    shopDeliveryPage: string;
    importDeclarationFileUrl: string;
    taxRefundStatus: keyof typeof ORDER_RETURN_STATUS;
  };
  orderReturnNumber: string;
  refundInfo: {
    couponDiscountAmount: number;
    refundStatus: keyof typeof ORDER_RETURN_STATUS;
    orderedAt: string;
    needTaxRefund: boolean;
    requestedAt: string;
    refundAmount: number;
    refundPoint: number;
    totalTax: number;
    fetchingOrderNumber: string;
    orderRefundNumber: string;
    orderPhoneNumber: string;
    orderUserName: string;
    recipientName: string;
    recipientPhoneNumber: string;
  };
  refundItemList: any[];
  refundDetailInfo: {
    additionalPayAmount: number;
    couponDiscount: number;
    deliveryFee: number;
    itemAmount: number;
    naverCash: number;
    naverMileage: number;
    payMethod: keyof typeof PAY_METHOD;
    paymentFee: number;
    productAdditionalDiscount: number;
    refundAmount: number;
    userTypeDiscount: number;
    refundPoint: number;
  };
  refundUpdateHistory: any[];
  memoList: Array<{
    adminName: string;
    content: string;
    createdAt: string;
    extraInfo: {
      noSendSms: boolean;
      noSendEmail: boolean;
    };
    fromStatus: keyof typeof ORDER_RETURN_STATUS;
    toStatus: keyof typeof ORDER_RETURN_STATUS;
  }>;
  shopShippingList: Array<{
    invoice: string;
    invoiceFileUrl: string;
    returnShopNumber: string;
    shippingCode: string;
    shippingCompanyName: string;
    shopCancelFileUrl: string;
    shopDeliveryPage: string;
    shopId: number;
    shopName: string;
  }>;
}

interface State {
  selectedIdx: string[];
  data: IOrderRefund | undefined;
  isLoading: boolean;
  reason: string;
  changePriceType: 'plus' | 'minus';
  refundAmount: string;
  isPriceUpdating: boolean;
  memoContent: string;
  isUploading: boolean;
  isRefundCompleted: boolean;
  isRefundChanging: boolean;
  fetchingOrderNumber: string;
  isRefundAccountOpen: boolean;
  bankName: string;
  account: string;
  accountHolder: string;
}

const OrderRefundDetailPage = () => {
  const { orderRefundNumber } = useParams<{ orderRefundNumber: string }>();
  const swr = useSWR(
    `/commerce/order/order-refund/${orderRefundNumber}`,
    (url) =>
      request.get<any, IOrderRefund>(url, {
        headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
      }),
    { refreshInterval: 1000 * 60 * 60 }
  );
  const [
    {
      selectedIdx,
      data,
      isLoading,
      reason,
      changePriceType,
      refundAmount,
      isPriceUpdating,
      memoContent,
      isUploading,
      isRefundCompleted,
      isRefundChanging,
      fetchingOrderNumber,
      isRefundAccountOpen,
      bankName,
      account,
      accountHolder,
    },
    setState,
    onChange,
  ] = useObject<State>({
    selectedIdx: [],
    data: swr.data,
    isLoading: false,
    reason: '',
    changePriceType: 'plus',
    refundAmount: '0',
    isPriceUpdating: false,
    memoContent: '',
    isUploading: false,
    isRefundCompleted: false,
    isRefundChanging: false,
    fetchingOrderNumber: '',
    isRefundAccountOpen: false,
    bankName: '',
    accountHolder: '',
    account: '',
  });

  const refresh = async () => {
    try {
      const data = await request.get<any, IOrderRefund>(
        `/commerce/order/order-refund/${orderRefundNumber}`
      );
      setState({
        data,
        isRefundCompleted: data.dutyRefundInfo.taxRefundStatus === 'ACCEPT',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateStatus = async (status: keyof typeof ORDER_RETURN_STATUS) => {
    let message = '환불 상태를 전환하시겠습니까?';
    if (status === 'ACCEPT') message = '환불 접수로 전환하시겠습니까?';
    else if (status === 'HOLD') message = '환불 보류로 전환하시겠습니까?';
    else if (status === 'REJECT') message = '환불 접수거부로 전환하시겠습니까?';

    if (!window.confirm(message)) return;

    setState({ isLoading: true });
    try {
      await request.put(`/commerce/order/order-refund/status/${orderRefundNumber}`, {
        status,
        memoContent,
        noSendEmail: true,
        noSendSms: true,
      });
      toast.success('전환되었습니다.');
      refresh();
      setState({ isLoading: false, memoContent: '' });
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };

  const onUpdatePrice = async () => {
    if (!data) return;
    if (!window.confirm('환불 금액을 변경하시겠습니까?')) return;

    setState({ isPriceUpdating: true });
    try {
      await request.put(`/commerce/order/order-refund/amount/${orderRefundNumber}`, {
        reason,
        refundAmount:
          changePriceType === 'plus'
            ? data.refundDetailInfo.refundAmount + Number(refundAmount)
            : data.refundDetailInfo.refundAmount - Number(refundAmount),
      });
      toast.success('변경되었습니다.');
      setState({ isPriceUpdating: false, reason: '', refundAmount: '0' });
    } catch (err) {
      console.log(err);
      setState({ isPriceUpdating: false });
    }
  };

  const onTaxChangeComplete = async (checked: boolean) => {
    if (!data) return;

    if (
      !data.dutyRefundInfo.importDeclarationFileUrl ||
      (!data.dutyRefundInfo.bankbookCopyFileUrl &&
        !data.shopShippingList.every(
          (item) =>
            !!item.invoiceFileUrl && !!item.shopCancelFileUrl && !!item.shopDeliveryPage
        ))
    ) {
      toast.info('모든 서류를 업로드하고 환급 완료를 눌러주세요.');
      return;
    }

    if (
      !window.confirm(
        checked ? '환급 완료로 전환하시겠습니까?' : '환급 완료를 비활성화하시겠습니까?'
      )
    )
      return;

    if (isRefundChanging) return;
    setState({ isRefundChanging: true });
    try {
      await request.put(`/commerce/order/order-refund/tax/${orderRefundNumber}`, {
        bankbookCopyFileUrl: data.dutyRefundInfo.bankbookCopyFileUrl || null,
        importDeclarationFileUrl: data.dutyRefundInfo.importDeclarationFileUrl || null,
        invoiceFileUrl: data.dutyRefundInfo.invoiceFileUrl || null,
        refundedAt: checked ? `${dayjs().format('YYYY-MM-DDTHH:mm:ss')}.000Z` : null,
        refundMemo: '',
        taxRefundStatus: checked ? 'ACCEPT' : 'IN_PROGRESS',
      });
      toast.success(
        checked ? '환급 완료로 전환되었습니다.' : '환급 완료를 비활성화했습니다.'
      );
      setState({ isRefundChanging: false });
      refresh();
    } catch (err) {
      console.log(err);
      setState({ isRefundChanging: false });
    }
  };

  const onTaxFileUpload = (
    state: keyof IOrderRefund['dutyRefundInfo'],
    accept: string
  ) => {
    if (!data) return;

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.onchange = async () => {
      if (!input.files) return;
      const file = input.files[0];
      setState({ isUploading: true });
      try {
        const url = await fileUpload(file);
        let payload: any = {
          bankbookCopyFileUrl: data.dutyRefundInfo.bankbookCopyFileUrl || null,
          importDeclarationFileUrl: data.dutyRefundInfo.importDeclarationFileUrl || null,
          refundedAt: null,
          refundMemo: '',
          residentRegistrationNumber:
            data.dutyRefundInfo.residentRegistrationNumber || null,
        };
        payload[state] = url;
        await request.put(
          `/commerce/order/order-refund/tax/${orderRefundNumber}`,
          payload
        );
        setState({ isRefundCompleted: false, isUploading: false });
        refresh();
      } catch (err) {
        console.log(err);
        setState({ isUploading: false });
      }
    };
    input.click();
  };

  const getRefundAccount = async (fetchingOrderNumber: string) => {
    try {
      const { accountInfo } = await request.get<
        any,
        { accountInfo: { bankName: string; accountHolder: string; account: string } }
      >(`/commerce/order/fetching-order/${fetchingOrderNumber}/refund-account`);
      setState({
        account: accountInfo.account,
        bankName: accountInfo.bankName,
        accountHolder: accountInfo.accountHolder,
        isRefundAccountOpen: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onShopTaxFileUpload = (
    returnShopNumber: string,
    state: 'invoiceFileUrl' | 'shopCancelFileUrl' | 'shopDeliveryPage'
  ) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async () => {
      if (!input.files) return;
      const file = input.files[0];
      setState({ isUploading: true });
      try {
        const url = await fileUpload(file);
        await request.put(
          `/commerce/order/order-return/shop/${returnShopNumber}/shipping`,
          { [state]: url }
        );
        refresh();
      } catch (err) {
        console.log(err);
      }
      setState({ isUploading: false });
    };
    input.click();
  };

  useEffect(() => {
    if (swr.data) {
      setState({
        data: swr.data,
        isRefundCompleted: swr.data.dutyRefundInfo.taxRefundStatus === 'ACCEPT',
      });
      if (swr.data.refundDetailInfo.payMethod === 'DEPOSIT')
        getRefundAccount(swr.data.refundInfo.fetchingOrderNumber);
    }
  }, [swr.data]);

  if (!data) {
    return (
      <div className="min-h-screen bg-black p-4">
        <div
          style={{ minHeight: 'calc(100vh - 42px)' }}
          className="bg-white rounded p-4 flex items-center justify-center"
        >
          <Spinner className="h-5 w-5 text-black" />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="min-h-screen bg-black p-4">
        <div style={{ minHeight: 'calc(100vh - 42px)' }} className="bg-white rounded p-4">
          <div className="wrapper-title">
            환불처리 - {ORDER_RETURN_STATUS[data.refundInfo.refundStatus]}
          </div>
          <Card
            items={[
              {
                name: '주문자명',
                renderItem: () =>
                  `${data.refundInfo.orderUserName} (${data.refundInfo.orderPhoneNumber})`,
              },
              {
                name: '수령자명',
                renderItem: () =>
                  `${data.refundInfo.recipientName} (${data.refundInfo.recipientPhoneNumber})`,
              },
              {
                name: '주문번호',
                renderItem: () => (
                  <OpenLink url={`/order/${data.refundInfo.fetchingOrderNumber}`}>
                    {data.refundInfo.fetchingOrderNumber}
                  </OpenLink>
                ),
              },
              {
                name: '반품번호',
                renderItem: () => data.refundInfo.orderRefundNumber,
              },
              {
                name: '주문일자',
                renderItem: () =>
                  dayjs(data.refundInfo.orderedAt).format('YYYY.MM.DD HH:mm:ss'),
              },
              {
                name: '신청일자',
                renderItem: () =>
                  dayjs(data.refundInfo.requestedAt).format('YYYY.MM.DD HH:mm:ss'),
              },
            ]}
          />
          <div className="mt-14">
            <div className="wrapper-title">환불상품</div>
            <div className="my-3">
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('REJECT')}
              >
                환불철회
              </Button>
            </div>
            <GridTable
              list={data.refundItemList}
              columns={[
                '상품별 주문번호',
                '판매처 (국가)',
                '상품명 / 옵션',
                '수량',
                '상품구매금액',
                '처리상태',
                '적립금반환',
                '네이버 마일리지 / 캐쉬 반환',
                '관세환급',
              ]}
              renderItem={(item, key) => (
                <div
                  key={key}
                  onClick={() =>
                    setState({
                      selectedIdx:
                        selectedIdx.indexOf(item.orderRefundNumber) !== -1
                          ? selectedIdx.filter(
                              (orderRefundNumber) =>
                                orderRefundNumber !== item.orderRefundNumber
                            )
                          : [...selectedIdx, item.orderRefundNumber],
                    })
                  }
                >
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={selectedIdx.indexOf(item.orderRefundNumber) !== -1}
                    />
                  </div>
                  <div>
                    <OpenLink url={`/order/${item.fetchingOrderNumber}`}>
                      {item.itemOrderNumber}
                    </OpenLink>
                  </div>
                  <div>
                    <div>{item.shopName}</div>
                    <div>({item.shopCountry})</div>
                  </div>
                  <div>
                    <div className="flex gap-3">
                      <img
                        className="max-w-[3.5rem] object-contain"
                        src={item.itemImageUrl}
                        alt={item.itemName}
                      />
                      <div className="flex-1">
                        <div>{item.brandName}</div>
                        <OpenLink
                          url={`/product/productDetail/v2/${Number(
                            item.itemId
                          )}?mode=update`}
                        >
                          {item.itemName}
                        </OpenLink>
                        <div className="text-red-400 mt-4">{item.sizeName}</div>
                      </div>
                    </div>
                  </div>
                  <div>{item.quantity}</div>
                  <div>
                    <NumberStat value={item.payAmount} />
                  </div>
                  <div>
                    <span className="cursor-pointer">
                      {ORDER_RETURN_STATUS[item.refundStatus]}
                    </span>
                  </div>
                  <div>
                    <span className="cursor-pointer">
                      {POINT_REFUND_STATUS[item.pointRefundStatus]}
                    </span>
                  </div>
                  <div></div>
                  <div>
                    {item.needTaxRefund && (
                      <>
                        <div className="link">
                          {TAX_REFUND_STATUS[item.taxRefundStatus]}
                        </div>
                        <Button
                          size="xs"
                          theme="secondary"
                          onClick={() => window.open('/orders/customs-refund')}
                        >
                          처리현황
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              )}
              isAllSelected={
                !!selectedIdx.length && selectedIdx.length === data.refundItemList.length
              }
              onSelectAll={(checked) =>
                setState({
                  selectedIdx: checked
                    ? data.refundItemList.map((item) => item.orderRefundNumber)
                    : [],
                })
              }
            />
          </div>

          <div className="mt-14 flex gap-4 items-baseline">
            <div className="flex-1">
              <div className="wrapper-title mb-2">환불 금액</div>
              <Card
                items={[
                  {
                    name: '환불 금액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundDetailInfo.refundAmount} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '환불 수단',
                    renderItem: () =>
                      data.refundDetailInfo.payMethod === 'DEPOSIT' ? (
                        <div className="flex items-center gap-1">
                          <div>{bankName}</div>
                          <div>{accountHolder}</div>
                          <div>{account}</div>
                          <Button
                            size="xs"
                            onClick={() =>
                              setState({
                                isRefundAccountOpen: true,
                                fetchingOrderNumber: data.refundInfo.fetchingOrderNumber,
                              })
                            }
                          >
                            환불계좌 {!!account ? '수정' : '등록'}
                          </Button>
                        </div>
                      ) : (
                        PAY_METHOD[data.refundDetailInfo.payMethod]
                      ),
                    stretch: true,
                  },
                  {
                    name: '환불 금액 상세',
                    renderItem: () => (
                      <div className="flex w-full flex-col gap-1 text-gray-500">
                        <div className="flex justify-between">
                          <span>상품구매금액</span>
                          <span>{priceFormat(data.refundDetailInfo.itemAmount)} 원</span>
                        </div>
                        <div className="flex justify-between">
                          <span>쿠폰할인</span>
                          <span>
                            - {priceFormat(data.refundDetailInfo.couponDiscount)} 원
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>회원등급 할인</span>
                          <span>
                            - {priceFormat(data.refundDetailInfo.userTypeDiscount)} 원
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>반품 배송비</span>
                          <span>
                            - {priceFormat(data.refundDetailInfo.deliveryFee)} 원
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>결제 수수료</span>
                          <span>
                            - {priceFormat(data.refundDetailInfo.paymentFee)} 원
                          </span>
                        </div>
                        <div className="flex justify-between">
                          <span>상품별 할인</span>
                          <span>
                            -{' '}
                            {priceFormat(data.refundDetailInfo.productAdditionalDiscount)}{' '}
                            원
                          </span>
                        </div>
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '적립금 반환액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundDetailInfo.refundPoint} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '네이버 마일리지/캐쉬 반환액',
                    renderItem: () => (
                      <div>
                        <NumberStat
                          value={
                            data.refundDetailInfo.naverCash +
                            data.refundDetailInfo.naverMileage
                          }
                        />
                        <span className="text-gray-500"> (네이버마일리지 </span>
                        <NumberStat value={data.refundDetailInfo.naverMileage} />
                        <span className="text-gray-500"> / 네이버 캐쉬: </span>
                        <NumberStat value={data.refundDetailInfo.naverCash} />
                        <span className="text-gray-500">)</span>
                      </div>
                    ),
                    stretch: true,
                  },
                ]}
              />
            </div>
            <div className="flex-1">
              {data.refundInfo.refundStatus !== 'ACCEPT' && (
                <div>
                  <div className="wrapper-title mb-2">환불 금액 변경</div>
                  <Card
                    items={[
                      {
                        name: '변경 금액',
                        renderItem: () => (
                          <div className="flex items-center gap-2">
                            <Select
                              size="xs"
                              value={changePriceType}
                              name="changePriceType"
                              onChange={onChange}
                            >
                              <option value="plus">환불액증가</option>
                              <option value="minus">환불액감소</option>
                            </Select>
                            <InputNumber
                              size="xs"
                              prefix={changePriceType === 'plus' ? '+' : '-'}
                              value={refundAmount}
                              onChange={onChange}
                              suffix="원"
                              align="right"
                              name="refundAmount"
                            />
                          </div>
                        ),
                        stretch: true,
                      },
                      {
                        name: '사유',
                        renderItem: () => (
                          <div className="w-full">
                            <Textarea
                              className="w-full"
                              rows={4}
                              value={reason}
                              name="reason"
                              onChange={onChange}
                            />
                            <div className="text-right">
                              <Button
                                size="sm"
                                isLoading={isPriceUpdating}
                                onClick={onUpdatePrice}
                              >
                                저장
                              </Button>
                            </div>
                          </div>
                        ),
                        stretch: true,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="mt-14">
            <div className="wrapper-title mb-3">할인 정보</div>
            <GridTable
              columns={['내역', '금액', '반환예정여부', '수동설정']}
              renderItem={(item, key) => (
                <div key={key}>
                  <div>사용 적립금</div>
                  <div>n,nnn,nnn원</div>
                  <div>
                    <Check checked />
                  </div>
                  <div>
                    <Button size="xs">미반환으로 변경</Button>
                  </div>
                </div>
              )}
              noSelection
              list={[{}]}
            />
          </div>

          <div className="mt-14">
            <div className="wrapper-title mb-3">환불 금액 변경 로그</div>
            <GridTable
              noSelection
              columns={['No', '작성일', '작성자', '증감', '변경 금액']}
              list={data.refundUpdateHistory}
              renderItem={(item, key) => (
                <div key={key}>
                  <div>{item.orderRefundHistoryId}</div>
                  <div>{dayjs(item.createdAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                  <div>{item.adminName}</div>
                  <div>
                    <span
                      className={classnames({
                        'text-green-400': item.difference > 0,
                        'text-red-400': item.difference < 0,
                      })}
                    >
                      {priceFormat(item.difference)}
                    </span>
                  </div>
                  <div>
                    <div className="flex items-center gap-1">
                      <div>{priceFormat(item.fromAmount)} 원</div>
                      <ArrowNarrowRightIcon className="h-4 w-4" />
                      <div>
                        <NumberStat value={item.toAmount} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>

          {data.refundInfo.needTaxRefund && (
            <div className="mt-14 flex gap-4 items-baseline">
              <div className="flex-1 flex mt-14 gap-20">
                <div>
                  <div>
                    <span className="wrapper-title">관세 환급 서류</span>
                    <a
                      href="https://unipass.customs.go.kr/csp/index.do"
                      target="_blank"
                      className="ml-4 text-sm"
                    >
                      유니패스로 이동
                    </a>
                  </div>
                  <div className="mt-3">
                    <div className="flex flex-col">
                      <div className="flex">
                        <div className="w-64">
                          <Check
                            checked={!!data.dutyRefundInfo.residentRegistrationNumber}
                          />
                          <span className="text-sm ml-1">주민등록번호</span>
                        </div>
                        <Button
                          size="xs"
                          theme="ghost"
                          onClick={() =>
                            copyText(data.dutyRefundInfo.residentRegistrationNumber)
                          }
                        >
                          복사
                        </Button>
                      </div>

                      <TaxItem
                        name="수입 신고서"
                        url={data.dutyRefundInfo.importDeclarationFileUrl}
                        isLoading={isUploading}
                        onUpload={() =>
                          onTaxFileUpload('importDeclarationFileUrl', '.pdf')
                        }
                      />
                      <TaxItem
                        name="통장사본"
                        isLoading={isUploading}
                        onUpload={() => onTaxFileUpload('bankbookCopyFileUrl', 'image/*')}
                        url={data.dutyRefundInfo.bankbookCopyFileUrl}
                      />
                      {data.shopShippingList.map((shop, shopIndex) => (
                        <Fragment key={shopIndex}>
                          <TaxItem
                            url={shop.invoiceFileUrl}
                            name={`${shop.shopName} 반송장 및 인보이스 스캔`}
                            isLoading={isUploading}
                            onUpload={() =>
                              onShopTaxFileUpload(shop.returnShopNumber, 'invoiceFileUrl')
                            }
                          />
                          <TaxItem
                            url={shop.shopDeliveryPage}
                            name={`${shop.shopName} 배송 조회 페이지`}
                            isLoading={isUploading}
                            onUpload={() =>
                              onShopTaxFileUpload(
                                shop.returnShopNumber,
                                'shopDeliveryPage'
                              )
                            }
                          />
                          <TaxItem
                            url={shop.shopCancelFileUrl}
                            name={`${shop.shopName} 승인 취소 캡쳐`}
                            isLoading={isUploading}
                            onUpload={() =>
                              onShopTaxFileUpload(
                                shop.returnShopNumber,
                                'shopCancelFileUrl'
                              )
                            }
                          />
                        </Fragment>
                      ))}
                      <Checkbox
                        checked={isRefundCompleted}
                        onChange={onTaxChangeComplete}
                        label="환급완료"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex-1">
                <div className="wrapper-title">관세 환급</div>
                <Card
                  items={[
                    {
                      name: '관세환급액',
                      renderItem: () => (
                        <div className="flex items-center gap-2">
                          <div>
                            <NumberStat value={data.refundInfo.totalTax} />
                          </div>
                          <span className="text-gray-500">
                            {' '}
                            ({ORDER_RETURN_STATUS[data.dutyRefundInfo.taxRefundStatus]})
                          </span>
                        </div>
                      ),
                      stretch: true,
                    },
                    {
                      name: '관부가세',
                      renderItem: () => <NumberStat value={0} />,
                      stretch: true,
                    },
                    {
                      name: '특소세',
                      renderItem: () => <NumberStat value={0} />,
                      stretch: true,
                    },
                    {
                      name: '교육세',
                      renderItem: () => <NumberStat value={0} />,
                      stretch: true,
                    },
                  ]}
                />
              </div>
            </div>
          )}

          <div className="mt-14">
            <div className="wrapper-title">환불 처리</div>
            <GridTable
              list={data.memoList}
              noSelection
              columns={[
                '환불상태값',
                '메모',
                '관리자명 (처리일)',
                '메시지발송여부',
                '메일발송여부',
              ]}
              renderItem={(item, key) => (
                <div key={key}>
                  <div>{`${ORDER_RETURN_STATUS[item.fromStatus]} -> ${
                    ORDER_RETURN_STATUS[item.toStatus]
                  }`}</div>
                  <div>{item.content}</div>
                  <div>
                    {item.adminName} (
                    {dayjs(item.createdAt).format('YYYY.MM.DD HH:mm:ss')})
                  </div>
                  <div>
                    <Check checked={item.extraInfo.noSendSms} />
                  </div>
                  <div>
                    <Check checked={item.extraInfo.noSendEmail} />
                  </div>
                </div>
              )}
            />
            {data.refundInfo.refundStatus !== 'ACCEPT' && (
              <div className="mt-4">
                <Card
                  items={[
                    {
                      name: '메모',
                      renderItem: () => (
                        <div className="w-full">
                          <Textarea
                            maxLength={2000}
                            rows={4}
                            className="w-full"
                            value={memoContent}
                            name="memoContent"
                            onChange={onChange}
                          />
                          <div className="text-gray-400">
                            [ <span className="text-black">{memoContent.length}</span> /
                            2000 ]
                          </div>
                        </div>
                      ),
                      stretch: true,
                    },
                    {
                      name: '발송 제외 설정',
                      renderItem: () => (
                        <div className="flex gap-2">
                          <Checkbox
                            label="환불완료 SMS 발송 안함"
                            checked
                            onChange={() => {}}
                          />
                          <Checkbox
                            label="환불완료 자동메일 발송 안함"
                            checked
                            onChange={() => {}}
                          />
                        </div>
                      ),
                      stretch: true,
                    },
                  ]}
                />
              </div>
            )}
          </div>

          {(data.refundInfo.refundStatus === 'REQUEST' ||
            data.refundInfo.refundStatus === 'REJECT') && (
            <div className="flex justify-center gap-2 mt-4">
              <Button isLoading={isLoading} onClick={() => onUpdateStatus('ACCEPT')}>
                환불완료처리
              </Button>
              <Button isLoading={isLoading} onClick={() => onUpdateStatus('HOLD')}>
                환불보류이동
              </Button>
            </div>
          )}
        </div>
      </div>
      <RefundAccountModal
        isOpen={isRefundAccountOpen}
        onClose={() => setState({ isRefundAccountOpen: false, fetchingOrderNumber: '' })}
        fetchingOrderNumber={fetchingOrderNumber}
        onSuccess={() => getRefundAccount(fetchingOrderNumber)}
      />
    </>
  );
};

export default OrderRefundDetailPage;
