// @ts-nocheck
import fetchingAPI from 'api/fetchingAPI';
import { DropZoneImage } from 'components';
import { MakeTable } from 'components/UI/atoms/Table';
import { triggerState } from 'pages/ManageMainContent';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import * as S from '../style';

type Props = {
  mode: 'm' | 'w';
  layout: {
    idx?: number;
    mobileImageUrl: string | null;
    pcImageUrl: string | null;
  };
};
const ManageFetchingDeal = ({ mode, layout }: Props) => {
  const layoutIdx = layout.idx;
  const setTrigger = useSetRecoilState(triggerState);
  //리팩토링 필요
  const [data, setData] = useState<{
    mobileImageUrl: string | null;
    pcImageUrl: string | null;
  }>({
    mobileImageUrl: '',
    pcImageUrl: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const initData = () => {
    const { mobileImageUrl, pcImageUrl } = layout;
    setData({ mobileImageUrl, pcImageUrl });
  };
  const saveChanges = () => {
    //리팩터링 필요
    if (Object.values(data).filter((v) => v === '').length) {
      alert('필수 항목을 모두 입력해주세요.');
    } else {
      const gender = mode === 'm' ? 'men' : 'women';
      setIsLoading(true);
      fetchingAPI
        .postUIComponents(gender, [
          {
            idx: layoutIdx,
            requestType: 'UPDATE',
            ...data,
          },
        ])
        .then(({ data }) => {
          data?.success && alert('수정에 성공했습니다.');
          setTrigger((p) => p + 1);
        })
        .catch((e) => alert(`err in saveChanges, ${e.message}`))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => initData(), []);

  const tables = [
    {
      title: '상세 설정',
      content: [
        {
          left: '모바일 이미지',
          right: (
            <>
              <DropZoneImage
                imageUrl={data.mobileImageUrl}
                setImageUrl={(v: string) => setData((p) => ({ ...p, mobileImageUrl: v }))}
              />
            </>
          ),
        },
        {
          left: 'pc 이미지',
          right: (
            <>
              <DropZoneImage
                imageUrl={data.pcImageUrl}
                setImageUrl={(v: string) => setData((p) => ({ ...p, pcImageUrl: v }))}
              />
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <S.ButtonContainer>
        <Button variant="warning" onClick={initData}>
          초기화
        </Button>{' '}
        <Button onClick={saveChanges}>
          {isLoading ? <Spinner animation="border" /> : '저장하기'}
        </Button>
      </S.ButtonContainer>
      <MakeTable data={tables[0]} tableRef={undefined} />
    </>
  );
};
export default ManageFetchingDeal;
