import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as style from './style';

const MenuList = ({ items }) => {
  const location = useLocation();

  return (
    <style.Wrapper>
      {items.map((item, idx) => (
        <Link key={idx} to={item.link} style={style.LinkStyle}>
          <style.Menu
            color={location.pathname.includes(item.link) ? 'black' : 'gray'}
          >
            {item.title}
          </style.Menu>
        </Link>
      ))}
    </style.Wrapper>
  );
};

export default MenuList;
