import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  width: 100%;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  min-width: 30px;
  min-height: 30px;
  margin: 0 10px;
`;

export const Text = styled.p`
  margin: 0;
  margin-right: 20px;
`;
