import { MainLayout } from 'pages/ManageMainLayout/utils';
import React from 'react';
import ManageBigBanner from './ManageBigBanner';
import ManageDesignComponentWithImage from './ManageDesignComponentWithImage';
import ManageDesignComponentWithoutImage from './ManageDesignComponentWithoutImage';
import ManageFetchingDeal from './ManageFetchingDeal';
import ManageSelectShops from './ManageSelectShops';
import ManageTextBandBanner from './ManageTextBandBanner';

const dict: any = {
  BIG_BANNER: '대배너',
  TEXT_BAND_BANNER: '텍스트 띠 배너',
  IMAGE_BAND_BANNER: '이미지 띠 배너',
  DESIGN_COMPONENT_WITH_IMAGE: '이미지 컴포넌트',
  DESIGN_COMPONENT_WITHOUT_IMAGE: '텍스트 컴포넌트',
  DESIGN_COMPONENT_WITH_IMAGE_CATEGORY: '이미지 탭 컴포넌트',
  DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY: '텍스트 탭 컴포넌트',
  FETCHING_DEAL: '페칭딜 컴포넌트',
  SELECT_SHOPS: '편집샵 프로모션',
};
type Props = {
  mode: 'm' | 'w';
  layout: MainLayout;
};
const ManageComponentContent = ({ mode, layout }: Props) => {
  const LayoutMap = ({ mode, layout }: Props) => {
    const { type } = layout;

    switch (type) {
      case 'BIG_BANNER':
        return <ManageBigBanner mode={mode} layout={layout} />;
      case 'TEXT_BAND_BANNER':
        return <ManageTextBandBanner mode={mode} layout={layout} />;
      case 'IMAGE_BAND_BANNER':
        return <></>;
      case 'DESIGN_COMPONENT_WITH_IMAGE':
        return <ManageDesignComponentWithImage mode={mode} layout={layout} />;
      case 'DESIGN_COMPONENT_WITHOUT_IMAGE':
        return <ManageDesignComponentWithoutImage mode={mode} layout={layout} />;
      case 'FETCHING_DEAL':
        return <ManageFetchingDeal mode={mode} layout={layout} />;
      case 'SELECT_SHOPS':
        return <ManageSelectShops mode={mode} layout={layout} />;
      case 'DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY':
        return <ManageDesignComponentWithoutImage.Category mode={mode} layout={layout} />;
      case 'DESIGN_COMPONENT_WITH_IMAGE_CATEGORY':
        return <ManageDesignComponentWithImage.Category mode={mode} layout={layout} />;
      default:
        return <h5>운영이 필요 없는 컴포넌트 입니다.</h5>;
    }
  };

  return (
    <>
      <h4 className=" text-xl">
        {dict[layout.type] && `${layout.name} [${dict[layout.type]}]`}
      </h4>
      <LayoutMap mode={mode} layout={layout} />
    </>
  );
};

export default ManageComponentContent;
