import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';
import { MenuList } from 'components';

const menu = [
  { link: '/product/exhibitionManagement', title: '기획전 관리' },
  { link: '/product/banner', title: '전시관리' },
];

const RouteList = [
  {
    path: '/product/exhibitionManagement',
    component: pages.ExhibitionList,
    exact: true,
  },
  {
    path: '/product/addExhibition',
    component: pages.AddExhibition,
  },
  {
    path: '/product/modify_exhibition_detail/:exhibitionId',
    component: pages.ModifyExhibitionDetail,
  },
  {
    path: '/product/exhibitionDetail',
    component: pages.ExhibitionDetail,
  },
  {
    path: '/product/modifyExhibition',
    component: pages.ModifyExhibition,
  },
  {
    path: '/product/banner',
    component: pages.BannerManagement,
  },
];

const Exhibition = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => (
          <>
            <MenuList items={menu} />
            <route.component />
          </>
        )}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Exhibition;
