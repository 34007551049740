import Partner from 'pages/Partner';
import PartnerItem from 'pages/PartnerItem';
import PartnerShopItem from 'pages/PartnerShopItem';
import RegisterPartner from 'pages/RegisterPartner';
import React from 'react';
import { Route, Switch } from 'react-router';

type Props = {};

const ManagePartner: React.FC<Props> = ({}) => {
  return (
    <>
      <Switch>
        <Route path="/partner/register">
          <RegisterPartner />
        </Route>
        <Route path="/partner/confirm/shop">
          <PartnerItem />
        </Route>
        <Route path="/partner/confirm/item">
          <PartnerShopItem />
        </Route>
        <Route path="/partner/:partnerShopId">
          <Partner />
        </Route>
      </Switch>
    </>
  );
};

export default ManagePartner;
