import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { IModal } from 'types';
import { Button, FormItem, ReInput, ReModal, Select } from 'components';
import { request, useObject } from 'services';
import { toast } from 'react-toastify';

interface IBank {
  idx: number;
  name: string;
  noBankbookSupport: boolean;
}

export interface Props extends IModal {
  fetchingOrderNumber: string;
  onSuccess: () => void;
}
interface State {
  isLoading: boolean;
  bankList: IBank[];
  account: string;
  accountHolder: string;
  bankId: number;
  isAlreadyExisted: boolean;
}

const RefundAccountModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  fetchingOrderNumber,
  onSuccess,
}) => {
  if (!isOpen) return null;
  const [
    { isLoading, bankList, accountHolder, account, bankId, isAlreadyExisted },
    setState,
    onChange,
  ] = useObject<State>({
    isLoading: false,
    bankList: [],
    account: '',
    accountHolder: '',
    bankId: 0,
    isAlreadyExisted: false,
  });

  const get = async () => {
    try {
      const [bankList, { accountInfo }] = await Promise.all([
        request.get<any, { data: IBank[] }>('/commerce/order/banks'),
        request.get<
          any,
          {
            accountInfo: {
              bankId: number;
              accountHolder: string;
              account: string;
            } | null;
          }
        >(`/commerce/order/fetching-order/${fetchingOrderNumber}/refund-account`),
      ]);
      let state: Partial<State> = {};
      if (bankList.data) state.bankList = bankList.data;
      if (accountInfo) {
        state.bankId = accountInfo.bankId;
        state.account = accountInfo.account;
        state.accountHolder = accountInfo.accountHolder;
        state.isAlreadyExisted = !!accountInfo.bankId;
      }
      setState(state);
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    if (!window.confirm(`환불계좌를 ${isAlreadyExisted ? '수정' : '등록'}하시겠습니까?`))
      return;

    setState({ isLoading: true });
    try {
      await request.post(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/refund-account`,
        { bankId, account, accountHolder }
      );
      toast.success(`환불계좌가 ${isAlreadyExisted ? '수정' : '등록'}되었습니다.`);
      onSuccess();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <ReModal
      isOpen={isOpen}
      onClose={onClose}
      title={`환불계좌 ${isAlreadyExisted ? '수정' : '등록'}`}
      description="고객에게 환불할 계좌를 입력해주세요."
    >
      <div className="space-y-4">
        <FormItem label="은행">
          <Select value={bankId} name="bankId" onChange={onChange}>
            <option value={0} selected disabled>
              선택
            </option>
            {bankList.map((item, key) => (
              <option key={key} value={item.idx}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="예금주">
          <ReInput value={accountHolder} name="accountHolder" onChange={onChange} />
        </FormItem>
        <FormItem label="계좌번호">
          <ReInput value={account} name="account" onChange={onChange} />
        </FormItem>
        <div className="flex justify-center">
          <Button isLoading={isLoading} onClick={onSubmit}>
            환불계좌 {isAlreadyExisted ? '수정' : '등록'}
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default RefundAccountModal;
