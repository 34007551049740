import { GroupModifyOptionTable, Loading, Spinner } from 'components';
import React, { useEffect, useState } from 'react';
import * as S from './style';
import { automationDefaultOption } from '../ProductList/initState';
import { trimAutomationParams } from 'pages/ProductList/utils';
import fetchingAPI from 'api/fetchingAPI';
import { ProductGroupOptionTable } from 'components/templates/SearchOptionTable';
import ProductGroupProductList from './ProductGroupProductList';
import { SaveButtonGroup } from 'components/UI/molecules/SearchButtonGroup';
import { toast } from 'react-toastify';

/////mode : 'add' | 'modify'
const ModifyGroup = ({ mode = 'add' }) => {
  const [options, setOptions] = useState(initialOption);
  const [automationOptions, setAutomationOptions] = useState(automationDefaultOption);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);

  const fetchSaveGroup = async (options) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await fetchingAPI.postNewProuctGroup(options);
      alert(`"${options.name}" 상품 그룹 생성에 성공했습니다.`);
      window.close();
    } catch (error) {
      toast.error('생성 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGroupInfo = async (id) => {
    try {
      const { data } = await fetchingAPI.getProductGroupInfo(id);
      setOptions((p) => ({ ...p, ...data }));
    } catch (error) {
      toast.error('수정 실패');
    }
  };

  const patchGroupInfo = async (id, options, automation) => {
    // 상품 그룹의 정보를 업데이트하고, 자동화 옵션을 설정함.
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      await fetchingAPI.putProductGroupInfo(id, trimModifyParams(options));
      options.automation
        ? await fetchingAPI.putProductGroupAutomation(id, automation)
        : await fetchingAPI.deleteProductGroupAutomation(id);
      alert(`"${options.name}" 상품 그룹 수정에 성공했습니다.`);
      load();
    } catch (error) {
      toast.error('실패');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchGroupAutomation = async (id) => {
    try {
      const { data } = await fetchingAPI.getProductGroupAutomation(id);
      setOptions((p) => ({ ...p, automation: true }));
      (data.minDiscountedPrice || data.maxDiscountedPrice) &&
        (data.priceMode = 'basicPrice');
      (data.minTotalPrice || data.maxTotalPrice) && (data.priceMode = 'shopPrice');
      (data.minFinalPrice || data.maxFinalPrice) && (data.priceMode = 'fetchingPrice');
      setAutomationOptions((p) => ({ ...p, ...data }));
    } catch (error) {
      toast.error('정보 가져오기 실패');
    }
  };

  const load = async () => {
    try {
      setIsSpinner(true);
      const { pathname } = window.location;
      const result = pathname.split('/');
      const id = Number(result[result.length - 1]);
      await Promise.all([fetchGroupInfo(id), fetchGroupAutomation(id)]);
    } catch (error) {
      toast.error('데이터 로드 실페.');
    } finally {
      setIsSpinner(false);
    }
  };

  useEffect(() => {
    mode === 'modify' && load();
  }, []);

  const onClickSave = () => {
    options.name
      ? options.assignee
        ? fetchSaveGroup(trimSaveParams(options, trimAutomationParams(automationOptions)))
        : alert('관리자를 설정하세요.')
      : alert('그룹명을 입력하세요.');
  };

  const onClickModify = () => {
    options.name
      ? options.assignee
        ? patchGroupInfo(options.id, options, trimAutomationParams(automationOptions))
        : alert('관리자를 설정하세요.')
      : alert('그룹명을 입력하세요.');
  };

  return (
    <S.Outer>
      {isSpinner && <Loading />}
      <S.ShadowContainer>
        <h3>그룹 정보</h3>
        <GroupModifyOptionTable options={options} setOptions={setOptions} />
        {options.automation ? (
          <AddProductAutomatically
            options={automationOptions}
            setOptions={setAutomationOptions}
          />
        ) : (
          <AddProductManually />
        )}
        <br />
        {mode === 'modify' ? (
          <SaveButtonGroup
            mode={'수정'}
            loading={isLoading}
            primaryCallback={onClickModify}
            secondaryCallback={() => {
              load();
            }}
          />
        ) : (
          <SaveButtonGroup
            mode={'저장'}
            loading={isLoading}
            primaryCallback={onClickSave}
            secondaryCallback={() => {
              setOptions(initialOption);
              setAutomationOptions(automationDefaultOption);
            }}
          />
        )}
      </S.ShadowContainer>
      {mode === 'modify' && options.id && (
        <S.ShadowContainer>
          <ProductGroupProductList id={options.id} />
        </S.ShadowContainer>
      )}
    </S.Outer>
  );
};

const initialOption = {
  name: null,
  description: null,
  assignee: null,
  gender: 'MW',
  automation: false,
};

const AddProductManually = () => (
  <>
    <h4 style={{ marginTop: '60px' }}>수동 상품분류</h4>
    <a href="/product/productList" target="_blank">
      상품 추가
    </a>
  </>
);

const AddProductAutomatically = ({ options, setOptions }) => {
  return (
    <>
      <h4 style={{ marginTop: '60px' }}>자동 상품분류 조건</h4>
      <ProductGroupOptionTable
        searchOptionTableState={options}
        setSearchOptionTableState={setOptions}
      />
    </>
  );
};

const trimSaveParams = (options, automationOptions) => {
  const result = { ...options };

  options.automation ? (result.automation = automationOptions) : delete result.automation;

  return result;
};

const trimModifyParams = (options) => {
  const result = { ...options };

  delete result.automation;
  delete result.createdAt;
  delete result.id;
  delete result.updatedAt;

  return result;
};

export const AddProductGroup = () => <ModifyGroup mode="add" />;
export const ModifyProductGroup = () => <ModifyGroup mode="modify" />;
