import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Button, Checkbox, Select } from 'components';
import { InputGroup, FormControl } from 'react-bootstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';
import axios from 'api/axios';
import { copyText } from 'utils';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

function ProductList({ items, tabs, onSave, isVerify, setIsVerify, onRefresh }) {
  const [products, setProducts] = useState([]);
  const [selectedList, setSelect] = useState([]);
  const [inputOrder, setInputOrder] = useState(null);
  const [isAllDeleting, setIsAllDeleting] = useState(false);
  const [sort, setSort] = useState('CREATED_AT_DESC');
  const pathname = window.location.pathname.split('/');
  const exhibitionId = pathname[pathname.length - 1];

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? '#f1f1f1' : 'white',
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const newItems = reorder(products, result.source.index, result.destination.index);

    setProducts(newItems || []);
  };

  const onSelect = (item) => {
    const index = _.findIndex(selectedList, { id: item.id });
    if (index > -1) {
      selectedList.splice(index, 1);
      setSelect([...selectedList]);
    } else {
      setSelect([
        ...selectedList,
        {
          ...item,
          order: selectedList.length,
        },
      ]);
    }
  };

  const onSelectAll = () => {
    if (selectedList.length === products.length) {
      setSelect([]);
    } else {
      setSelect(products.map((item, i) => ({ ...item, order: i })));
    }
  };

  const checkSelectedList = () => selectedList.length > 0;

  const updateOrder = (order) => {
    if (!order && order !== 0) {
      toast.info('우선순위를 입력해주세요.');
      return;
    }
    if (!checkSelectedList()) {
      toast.info('선택된 상품이 없습니다.');
      return;
    }

    const sortSelected = _.sortBy(selectedList, 'order');

    // 1. 우선 기존리스트에서 sortSelected 의 데이터를 삭제
    sortSelected.forEach((item) => {
      const index = _.findIndex(products, { id: item.id });
      products.splice(index, 1);
    });

    if (order === -1) {
      setProducts([...products, ...sortSelected]);
    } else {
      // 2. sortSelected 에 있는 데이터를 전달된 order 뒤에 넣어야함
      products.splice(order, 0, ...sortSelected);
      setProducts(products);
    }
    setSelect([]);
  };

  const handleInputOrder = (e) => {
    // FIXME: 입력값 확인 필요 (정규식으로 숫자 아닌거 있을땐 alert 띄움)
    setInputOrder(e.target.value);
  };

  const getCustomCategoryName = (customCategoryId) => {
    const index = _.findIndex(tabs, { id: customCategoryId });
    return index !== -1 ? tabs[index].name : '';
  };

  const categoryIdToCategoryName = (categoryIds) => {
    return categoryIds.map((id) => getCustomCategoryName(id));
  };

  useEffect(() => {
    setProducts(items);
    setSelect([]);
  }, [items]);

  const getFetchingPrice = (price) => {
    let fullyDiscountedFinalPrice = price * 0.97; ///무통장입금 3%
    let discount = 0;
    if (fullyDiscountedFinalPrice > 1000000) {
      /////100만원 이상 쿠폰
      discount = 30000;
    } else if (fullyDiscountedFinalPrice > 300000) {
      /////30만원 이상 쿠폰
      discount = 20000;
    } else if (fullyDiscountedFinalPrice > 200000) {
      /////아마 회원가입쿠폰??
      discount = 5000;
    }
    return (fullyDiscountedFinalPrice -= discount);
  };

  const removeItem = async (index) => {
    const item = products[index];
    if (!window.confirm('삭제하시겠습니까?')) return;
    setProducts([
      ...products.slice(0, index),
      { ...products[index], isDeleting: true },
      ...products.slice(index + 1),
    ]);

    try {
      await axios.delete('/api/item/exhibition', {
        data: {
          itemIDs: [Number(item.id)],
          exhibitionID: Number(exhibitionId),
        },
      });
      onRefresh();
      toast.success('삭제되었습니다.');
    } catch (err) {
      console.log(err);
      toast.error('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const removeAllItem = async () => {
    if (!selectedList.length) {
      toast.info('삭제할 상품을 선택해주세요.');
      return;
    }
    if (!window.confirm('모두 삭제하시겠습니까?')) return;

    setIsAllDeleting(true);
    try {
      await axios.delete('/api/item/exhibition', {
        data: {
          itemIDs: selectedList.map((item) => Number(item.id)),
          exhibitionID: Number(exhibitionId),
        },
      });
      onRefresh();
      toast.success('삭제되었습니다.');
    } catch (err) {
      console.log(err);
      toast.error('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setIsAllDeleting(false);
    }
  };

  const onSortChange = (value) => {
    switch (value) {
      case 'CREATED_AT_ASC':
        setProducts(
          products.sort(
            (a, b) =>
              new Date(a.exhibitionMapCreatedAt).getTime() -
              new Date(b.exhibitionMapCreatedAt).getTime()
          )
        );
        break;
      case 'CREATED_AT_DESC':
        setProducts(
          products.sort(
            (a, b) =>
              new Date(b.exhibitionMapCreatedAt).getTime() -
              new Date(a.exhibitionMapCreatedAt).getTime()
          )
        );
        break;
      case 'BRAND_NAME_ASC':
        setProducts(
          products.sort((a, b) => (a.brand > b.brand ? 1 : a.brand < b.brand ? -1 : 0))
        );
        break;
      case 'BRAND_NAME_DESC':
        setProducts(
          products.sort((a, b) => (a.brand < b.brand ? 1 : a.brand > b.brand ? -1 : 0))
        );
        break;
      case 'PRICE_ASC':
        setProducts(
          products.sort(
            (a, b) => (a.finalPrice || a.showPrice) - (b.finalPrice || b.showPrice)
          )
        );
        break;
      case 'PRICE_DESC':
        setProducts(
          products.sort(
            (a, b) => (b.finalPrice || b.showPrice) - (a.finalPrice || a.showPrice)
          )
        );
        break;
      default:
        onRefresh();
    }
    setSort(value);
  };
  return (
    <div>
      <StickyMenu>
        <ActionGroup>
          <InputGroup>
            <FormControl
              placeholder="선택상품 일괄변경"
              aria-label="선택상품 일괄변경"
              aria-describedby="basic-addon2"
              onChange={handleInputOrder}
            />
            <Button size="sm" onClick={() => updateOrder(inputOrder)}>
              변경
            </Button>
          </InputGroup>

          <div className="flex gap-3">
            <Checkbox
              checked={isVerify}
              onChange={setIsVerify}
              label="활성화 상품만 보기"
            />
            <Select value={sort} onChange={(e) => onSortChange(e.target.value)}>
              <option value="CREATED_AT_ASC">등록일 오름차순</option>
              <option value="CREATED_AT_DESC">등록일 내림차순</option>
              <option value="BRAND_NAME_ASC">브랜드명 오름차순</option>
              <option value="BRAND_NAME_DESC">브랜드명 내림차순</option>
              <option value="PRICE_ASC">가격 오름차순</option>
              <option value="PRICE_DESC">가격 내림차순</option>
            </Select>
            <Button
              size="sm"
              isLoading={isAllDeleting}
              onClick={removeAllItem}
              theme="danger"
            >
              일괄 삭제
            </Button>
            <Button size="sm" onClick={() => onSave(products)}>
              현재 정렬 저장
            </Button>
            <Button size="sm" onClick={() => updateOrder(0)}>
              일괄 최상으로
            </Button>
            <Button size="sm" onClick={() => updateOrder(-1)}>
              일괄 최하단
            </Button>
          </div>
        </ActionGroup>
      </StickyMenu>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              id="qqqfwqf"
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              <Row style={{ background: '#f0f0f0' }}>
                <Column>
                  <input
                    type="checkbox"
                    aria-label={'check all'}
                    checked={selectedList.length === products.length}
                    onChange={onSelectAll}
                    style={{ width: 30, height: 30 }}
                  />
                </Column>
                <Column>우선순위</Column>
                <Column>페칭코드</Column>
                <Column>상품이미지</Column>
                <Column>상품정보</Column>
                <Column>페칭판매가</Column>
                <Column>활성화 여부</Column>
                <Column>등록일</Column>
                <Column>탭</Column>
              </Row>
              {products.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {(provided, snapshot) => (
                      <Row
                        align={'center'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          userSelect: 'none',
                          background: 'white',
                          width: '100%',
                          textAlign: 'center',
                          ...provided.draggableProps.style,
                        }}
                        onClick={() => onSelect(item)}
                      >
                        <Column>
                          <input
                            type="checkbox"
                            aria-label={index}
                            checked={
                              selectedList.map((item) => item.id).indexOf(item.id) !== -1
                            }
                            style={{ width: 30, height: 30 }}
                          />
                        </Column>
                        <Column>{index}</Column>
                        <Column>
                          <div>{item.id}</div>
                          <Button
                            size="xs"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyText(item.id);
                            }}
                          >
                            복사
                          </Button>
                        </Column>
                        <Column>
                          <img
                            style={{
                              width: '120px',
                              height: '120px',
                              objectFit: 'contain',
                            }}
                            src={item.imageUrl}
                            alt="상품 이미지"
                          />
                        </Column>
                        <Column align="left">
                          <LabelText weight={700}>{item.brand}</LabelText>
                          <LabelText>{item.name}</LabelText>
                          <LabelText>{item.color}</LabelText>
                        </Column>
                        <Column>
                          <LabelText>
                            {item.fetchingPrice && item.fetchingPrice.toLocaleString()}
                          </LabelText>
                          <LabelText color={'#ff6860'}>
                            {item.cafe24Price &&
                              getFetchingPrice(item.cafe24Price).toLocaleString()}
                          </LabelText>
                        </Column>
                        <Column>
                          <div className="flex justify-center">
                            {item.isVerified ? (
                              <CheckCircleIcon className="w-7 h-7 text-green-500" />
                            ) : (
                              <XCircleIcon className="w-7 h-7 text-red-500" />
                            )}
                          </div>
                        </Column>
                        <Column>
                          {!!item.exhibitionMapCreatedAt &&
                            dayjs(item.exhibitionMapCreatedAt).format('YYYY-MM-DD HH:mm')}
                        </Column>
                        <Column>
                          <p>
                            {item.exhibition_custom_category_ids &&
                              categoryIdToCategoryName(
                                item.exhibition_custom_category_ids
                              ).join(' / ')}
                          </p>
                          <Button
                            size="xs"
                            theme="danger"
                            onClick={(e) => {
                              e.stopPropagation();
                              removeItem(index);
                            }}
                            disabled={item.isDeleting}
                          >
                            {item.isDeleting ? '삭제 중...' : '삭제'}
                          </Button>
                        </Column>
                      </Row>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default ProductList;

const Column = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: ${({ align }) => (align ? align : 'center')};
  border-right: 1px solid #d1d1d1;
  padding: 12px;
  &:first-child {
    border-left: 1px solid #d1d1d1;
  }
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 5% 7% 9% 10% 34% 9% 8% 13% 5%;
  width: 100%;
  text-align: ${(props) => props.align};
  border-bottom: 1px solid #d1d1d1;
  &:first-child {
    border-top: 1px solid #d1d1d1;
  }
`;

const StickyMenu = styled.div`
  position: sticky;
  top: 48px;
  z-index: 999;
`;

const ActionGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  padding: 12px;
  background: #bebebe;
`;

const LabelText = styled.p`
  color: ${({ color }) => color || '#555'};
  font-weight: ${({ weight }) => weight || 500};
`;
