import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { fetchingAPI } from 'api/axios';

const DeleteUserModal = ({
  show,
  onHide,
  targetIds,
  userNames,
  fetchItems,
}) => {
  const [isSucceed, setIsSucceed] = useState(false);
  const deleteUsers = async () => {
    if (isSucceed) {
      fetchItems();
      onHide();
    } else {
      try {
        await Promise.all(
          targetIds.map((id) => fetchingAPI.delete(`/commerce/user/${id}`))
        );
        setIsSucceed(true);
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        style={{
          background: '#000',
          padding: '8px 0',
          textAlign: 'center',
          color: '#FF6F61',
          height: 40,
          fontWeight: 'bold',
          fontSize: 16,
        }}
      >
        회원탈퇴
      </div>
      <div style={{ padding: '16px 32px', textAlign: 'center' }}>
        {isSucceed ? (
          <div>{userNames.join(', ')}님이 탈퇴처리 되었습니다.</div>
        ) : (
          <>
            <div>{userNames.join(', ')}님을 탈퇴처리 하시겠습니까?</div>
            <div>탈퇴된 회원정되보는 절대 복구 불가합니다.</div>
          </>
        )}
      </div>
      <div style={{ textAlign: 'center', marginBottom: 16 }}>
        <button
          style={{
            width: 50,
            background: '#000',
            color: '#fff',
            borderRadius: 5,
            border: 'none',
            outline: 'none',
          }}
          onClick={deleteUsers}
        >
          확인
        </button>
      </div>
    </Modal>
  );
};

export default DeleteUserModal;
