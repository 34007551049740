import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSelectCategoryItemAction,
  removeSelelctCategoryItemAction,
} from 'actions/action';

const CategoryProduct = ({ item, mappingStatus, index, selectPage, count }) => {
  const dispatch = useDispatch();
  const itemIds = useSelector((state) => state.selectCategory.itemIds);

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const result = itemIds.indexOf(String(item.idx));
    if (result === -1) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [itemIds, item.idx]);

  return (
    <tr key={item.idx}>
      <td>
        <input
          type="checkbox"
          value={item.idx}
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            if (e.target.checked) {
              dispatch(addSelectCategoryItemAction(e.target.value));
            } else {
              dispatch(removeSelelctCategoryItemAction(e.target.value));
            }
          }}
        />
        <p>{Number(selectPage) === 1 ? index + 1 : count + index + 1}</p>
      </td>
      <td>
        <img
          src={item.image_url}
          style={{ width: '250px' }}
          onClick={() => {
            window.open(item.item_url);
          }}
        />
        <p>페칭 코드: {item.idx}</p>
      </td>
      <td>
        {item.gender === 'M' ? (
          <p style={{ color: '#4494d8' }}>{'남성'}</p>
        ) : (
          <p style={{ color: '#e8a4b0' }}>{'여성'}</p>
        )}
        <p>{item.brand_name}</p>
        <p>{item.item_name}</p>
      </td>
      <td>
        {mappingStatus.map((mapping, i) => {
          return <p key={i}>{mapping.name}</p>;
        })}
      </td>
    </tr>
  );
};

export default CategoryProduct;
