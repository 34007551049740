import {
  GroupSearchOptionTable,
  GroupTable,
  Loading,
  SearchButtonGroup,
} from 'components';
import React, { useEffect, useState } from 'react';
import * as S from './style';
import fetchingAPI from 'api/fetchingAPI';
import { PaginationSetOption } from 'pages/ManageCatalog/CatalogSearchOptionTable/Pagination';

const initialOption = {
  assignee: null,
  gender: null,
  isAutomated: null,
  limit: 50,
  name: null,
  page: 1,
  sort: 'NAME',
};

const getParams = (options) => {
  const result = {};
  for (let [key, value] of Object.entries(options)) {
    if (value !== null) {
      result[key] = value;
    }
  }
  return result;
};

const ManageGroup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(initialOption);
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);

  const fetchItems = async (options) => {
    try {
      setIsLoading(true);
      const { data } = await fetchingAPI.getProductGroups(getParams(options));
      setItems(data.data);
      setCount(data.count);
    } catch (error) {
      alert(`error in fetchItems(), <ManageGroup/>, ${error.messaage}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems(options);
  }, [options.page]);

  return (
    <>
      {isLoading && <Loading />}
      <S.Outer>
        <S.ShadowContainer>
          <S.PageTitle>그룹 관리</S.PageTitle>
          <GroupSearchOptionTable options={options} setOptions={setOptions} />
          <SearchButtonGroup
            fetchItems={() => fetchItems(options)}
            setSearchOptionTableState={setOptions}
            initialState={initialOption}
            setItems={setItems}
          />
        </S.ShadowContainer>
        <S.ShadowContainer>
          총 {count}개
          <GroupTable
            items={items}
            reload={fetchItems}
            options={options}
            setOptions={setOptions}
          />
          <PaginationSetOption options={options} setOptions={setOptions} />
        </S.ShadowContainer>
      </S.Outer>
    </>
  );
};

export default ManageGroup;
