import styled from 'styled-components';

export const Outer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  padding: 100px 0;
`;

export const Inner = styled.div`
  width: '80%';
  @media all and (min-width: 1000px) {
    width: 50%;
  }
`;

export const MaginTop20 = {
  marginTop: '20px',
};

export const DateBoxOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin-left: 10px;
`;

export const DateInput = styled.input.attrs({
  type: 'date',
})`
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
`;

// @media (min-width:1000px) {
//   .modifySalePageInner {
//     width: 50%;
//   }
// }
