import styled from 'styled-components';

export const ItemOuter = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #d4cfcf;
`;

export const Item = styled.div`
  ${(props) => props.title && 'display: flex'};
  ${(props) => props.title && 'justify-content: center'};
  ${(props) => props.title && 'font-weight: bold'};
  width: ${(props) => (props.title ? '20' : '80')}%;
  background-color: ${(props) => (props.title ? '#F0F0F0' : '#FFFFFF')};
  padding: 15px 20px;
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  max-width: 800px;
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
`;

export const Text = styled.div`
  margin-left: 10px;
  margin-right: 30px;
`;

export const Flex = styled.div`
  display: flex;
`;

export const Date = styled.input.attrs({
  type: 'date',
})``;

export const Time = styled.input.attrs({
  type: 'time',
})`
  margin-left: 10px;
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4f4e4e;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  margin-left: 10px;
  cursor: pointer;
`;

export const Padding = {
  padding: '15px 20px',
};

export const Img = styled.img`
  max-width: 250px;
  @media all and (min-width: 700px) {
    max-width: 500px;
  }
`;

export const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  background-color: #1a1a1a;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  margin-left: 10px;
`;
