import React from 'react';
import * as style from './style';

const PlatformCheckBox = ({ platform, handlePlatformCheck }) => {
  return (
    <style.Wrapper>
      <style.Item BC="#8080806b">
        <strong>디바이스</strong>
      </style.Item>
      {['pc_web', 'mobile_web', 'ios', 'android'].map((value, i) => {
        const index = platform.findIndex((el) => {
          return el === value;
        });
        return (
          <style.Item key={i}>
            <style.CheckBox
              type="checkbox"
              value={value}
              checked={index !== -1}
              onChange={handlePlatformCheck}
            />
            <style.Text>{value}</style.Text>
          </style.Item>
        );
      })}
    </style.Wrapper>
  );
};

export default PlatformCheckBox;
