import React from 'react';

const GoogleAnalytics = () => {
  return (
    <div>
      <iframe
        src="https://datastudio.google.com/embed/reporting/238cbc92-dd24-4174-b8c7-8ceeb75c27ee/page/TfyeC"
        className="w-full"
        allowFullScreen
        title="datastudio"
        style={{ height: 'calc(100vh - 48px)' }}
      ></iframe>
    </div>
  );
};

export default GoogleAnalytics;
