import { getUserList } from 'api/axios';
import React, { useEffect, useState } from 'react';
import * as S from './style';

const GroupModifyOptionTable = ({ options = {}, setOptions = () => {} }) => {
  const { name, gender, assignee, automation, description } = options;

  return (
    <S.Table>
      <S.Tbody>
        <ModifyName
          value={name}
          onChange={(value) => setOptions((p) => ({ ...p, name: value }))}
        />
        <ModifyDescription
          value={description}
          onChange={(value) =>
            setOptions((p) => ({ ...p, description: value }))
          }
        />
        <ModifyAdmin
          value={assignee}
          onChange={(value) => setOptions((p) => ({ ...p, assignee: value }))}
        />
        <SearchByGender
          value={gender}
          onChange={(value) => setOptions((p) => ({ ...p, gender: value }))}
        />
        <SearchByClass
          value={automation}
          onChange={(value) => setOptions((p) => ({ ...p, automation: value }))}
        />
      </S.Tbody>
    </S.Table>
  );
};

export default GroupModifyOptionTable;

const SearchByGender = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>성별</S.LeftTd>
      <S.Td>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === 'MW'}
            onChange={() => onChange('MW')}
          />
          전체
        </S.Label>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === 'M'}
            onChange={() => onChange('M')}
          />
          남성
        </S.Label>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === 'W'}
            onChange={() => onChange('W')}
          />
          여성
        </S.Label>
      </S.Td>
    </S.Tr>
  );
};
const ModifyName = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>그룹명</S.LeftTd>
      <S.Td>
        <S.TextArea
          value={value || ''}
          onChange={(e) => onChange(e.target.value || null)}
        />
      </S.Td>
    </S.Tr>
  );
};
const SearchByClass = ({ value = true, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>상품분류 방식</S.LeftTd>
      <S.Td>
        <S.Label>
          <S.Radio
            name="automation"
            checked={value === false}
            onChange={() => onChange(false)}
          />
          수동
        </S.Label>
        <S.Label>
          <S.Radio
            name="automation"
            checked={value === true}
            onChange={() => onChange(true)}
          />
          자동
        </S.Label>
      </S.Td>
    </S.Tr>
  );
};
const ModifyDescription = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>설명</S.LeftTd>
      <S.Td>
        <S.TextArea
          value={value || ''}
          onChange={(e) => onChange(e.target.value || null)}
          style={{ height: '78px' }}
        />
      </S.Td>
    </S.Tr>
  );
};
const ModifyAdmin = ({ value = null, onChange = () => {} }) => {
  const [adminNames, setAdminNames] = useState([]);

  const fetchAdminNames = async () => {
    const data = await getUserList();
    setAdminNames(data);
  };

  useEffect(() => {
    fetchAdminNames();
  }, []);

  return (
    <S.Tr>
      <S.LeftTd>관리자</S.LeftTd>
      <S.Td>
        <S.Select
          value={value || 'none'}
          onChange={(e) =>
            onChange(e.target.value !== 'none' ? e.target.value : null)
          }
        >
          <option value={'none'}>---</option>
          {adminNames.map(({ id, admin_name }) => (
            <option value={id} key={id}>
              {admin_name}
            </option>
          ))}
        </S.Select>
      </S.Td>
    </S.Tr>
  );
};
