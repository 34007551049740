import React from 'react';
import * as pages from 'pages';

const ProductTab = () => {
  return (
    <>
      <pages.Product />
      <pages.Exhibition />
      <pages.Promotion />
      <pages.Category />
      <pages.Brand />
      <pages.Shop />
    </>
  );
};

export default ProductTab;
