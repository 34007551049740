import { StatusList } from 'pages/PartnerItem/types';

export enum SearchType {
  ITEM_NAME = 'ITEM_NAME',
}

export enum Action {
  ACCEPT,
  REJECT,
  TARGET_ACCEPT,
  TARGET_REJECT,
}

export enum Modal {
  REJECT,
  REJECT_REASON,
  OPTION,
}

export type Shop = {
  brandName: string;
  categoryList: {
    fetchingCategoryId: number;
    fetchingCategoryName: string;
  }[];
  createdAt: string;
  fetchingPrice: number;
  imageUrl: string;
  isSellable: boolean;
  isShow: boolean;
  itemId: number;
  itemName: string;
  originPrice: number;
  partnerShopId: number;
  processDate: string;
  productNo: number;
  rejectReason: string;
  requestDate: string;
  sellEndDate: string;
  sellStartDate: string;
  shopName: string;
  shopPrice: number;
  sizeList: {
    priceRuleId: number;
    quantity: number;
    sizeName: string;
  }[];
  sizeQuantity: number;
  status: StatusList;
  updatedAt: string;
  updateUserName: string;
  rejectReasonType: PartnerItemRejectReasonType;
};

export enum PartnerItemRejectReasonType {
  CATEGORY = 'CATEGORY', // 카테고리 수정 필요
  BRAND = 'BRAND', // 브랜드 수정 필요
  IMAGE = 'IMAGE', // 이미지 수정 필요
  ITEM_NAME = 'ITEM_NAME', // 상품명 수정 필요
  OPTION = 'OPTION', // 옵션명 수정 필요
  QUANTITY = 'QUANTITY', // 재고 수정 필요
  ETC = 'ETC', // 기타
}

export type PartnerItemSizeList = {
  sizeList: {
    forceIsActive: boolean;
    forcePrice: number;
    forceSizeName: string;
    isActive: boolean;
    price: number;
    priceRuleId: number;
    quantity: number;
    sizeName: string;
  }[];
  sizePriceType: 'OPTIONAL_PRICE' | 'SAME_PRICE';
};
