import { fetchingAPI } from 'api/axios';
import { LoadingContext } from 'context';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import * as style from './style';

const ChangeProductInfoGroup = ({
  showModal,
  setShowModal,
  checkedItemIds,
  searchOptionTableState,
  trimParams,
}) => {
  const [target, setTarget] = useState('checkedItemIds');
  const [modal, setModal] = useState('PriorityChange');

  const { handleIsLoading } = useContext(LoadingContext);

  const onSubmit = async () => {
    try {
      if (target === 'checkedItemIds') {
        if (checkedItemIds.length === 0) {
          alert('하나 이상의 상품을 선택하세요.');
        } else {
          setShowModal({
            ...showModal,
            [modal]: true,
            targetIds: checkedItemIds.map((id) => String(id)),
          });
        }
      } else if (target === 'allItemIds') {
        handleIsLoading(true);
        const pathname = window.location.pathname.split('/');
        const id = Number(pathname[pathname.length - 1]);
        const data = await fetchingAPI.get(
          id > 0 ? `/group/${id}/item/ids` : '/item/ids',
          {
            params: {
              ...trimParams(searchOptionTableState),
            },
          }
        );
        handleIsLoading(false);
        setShowModal({
          ...showModal,
          [modal]: true,
          targetIds: data.data,
        });
      }
    } catch (err) {
      toast.error('에러가 발생했습니다. 계속 발생한다면 개발팀에게 문의바랍니다.');
      handleIsLoading(false);
      console.log(err);
    }
  };

  return (
    <style.Container>
      <style.Input
        onChange={(e) => {
          setTarget(e.target.value);
        }}
      >
        <option value="checkedItemIds">선택한 상품의</option>
        <option value="allItemIds">검색 결과 전체 상품의</option>
      </style.Input>
      <style.Input
        onChange={(e) => {
          setModal(e.target.value);
        }}
      >
        <option value="PriorityChange">우선순위를</option>
        <option value="ItemShow">앱 노출 상태를</option>
        <option value="ExhibitionTab">기획전 정보를</option>
        <option value="ColorChange">색상을</option>
        <option value="Promotion">프로모션 정보를</option>
        <option value="OriginChange">원산지를</option>
        <option value="ProductGroup">상품 그룹을</option>
      </style.Input>
      <style.Button onClick={onSubmit}>일괄변경</style.Button>
    </style.Container>
  );
};

export default ChangeProductInfoGroup;
