import React, { useEffect, useState } from 'react';
import * as style from './style';
import { Input } from '../../UI/atoms/SimpleInput/style';
import * as S from '../../UI/organisms/GroupSearchOptionTable/style';
import { getUserList } from '../../../api/axios';

const KeywordSearchOptionTable = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const [adminNames, setAdminNames] = useState([]);

  const fetchAdminNames = async () => {
    const data = await getUserList();
    setAdminNames(data);
  };

  useEffect(() => {
    fetchAdminNames();
  }, []);

  const tableData = [
    {
      Left: '키워드',
      Td: (
        <Input
          onChange={(e) => {
            setSearchOptionTableState({
              ...searchOptionTableState,
              keyword: e.target.value,
            });
          }}
        />
      ),
    },
    {
      Left: '바꿀 문장',
      Td: (
        <Input
          onChange={(e) => {
            setSearchOptionTableState({
              ...searchOptionTableState,
              alias: e.target.value,
            });
          }}
        />
      ),
    },
    {
      Left: '생성자',
      Td: (
        <>
          <S.Select
            value={searchOptionTableState.createdBy || 'none'}
            onChange={(e) => {
              if (e.target.value === 'none') {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  createdBy: undefined,
                });
              } else {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  createdBy: e.target.value,
                });
              }
              console.log(searchOptionTableState);
            }}
          >
            <option value={'none'}>---</option>
            {adminNames.map(({ id, admin_name }) => (
              <option value={id} key={id}>
                {admin_name}
              </option>
            ))}
          </S.Select>
        </>
      ),
    },
  ];

  return (
    <>
      <style.Table>
        {tableData.map((data, index) => (
          <style.Tr key={index}>
            <style.Left>{data.Left}</style.Left>
            <style.Td>{data.Td}</style.Td>
          </style.Tr>
        ))}
      </style.Table>
    </>
  );
};

export default KeywordSearchOptionTable;
