import {
  Button,
  Card,
  GridTable,
  NumberStat,
  OpenLink,
  Radio,
  ReInput,
  ReMessageModal,
  RePagination,
  Select,
  Spinner,
  Tooltip,
} from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ORDER_DATE_LIST,
  PATH,
  PAY_METHOD,
  request,
  useOrderTable,
  USER_TYPE,
  SHOP_ORDER_STATUS,
  enumToList,
  ORDER_SORT,
} from 'services';
import type { ICardItem } from 'types';
import { priceFormat } from 'utils';

export interface Props {}
interface State {}

const UserOrder: FunctionComponent<Props> = () => {
  const {
    minOrderDate,
    maxOrderDate,
    onChangeCheckbox,
    onChangeOrder,
    onDateClick,
    setOrderState,
    orderStatus,
    orderPath,
    payMethod,
    userType,
    inflowCampaign,
    inflowChannel,
    isMemoLoading,
    memoList,
    limit,
    resetOrderState,
    isMessageOpen,
    phoneNumber,
    minCancelDate,
    maxCancelDate,
    showAll,
    isLoading,
    list,
    selectedIdx,
    total,
    page,
    sort,
    isDetailOpen,
  } = useOrderTable();
  const { idx } = useParams<{ idx: string }>();
  const items: ICardItem[] = [
    {
      name: '주문일',
      renderItem: () => (
        <div className="flex items-center gap-2">
          {ORDER_DATE_LIST.map((item, key) => (
            <Button size="xs" theme="ghost" onClick={() => onDateClick(item)} key={key}>
              {item}
            </Button>
          ))}
          <ReInput
            size="xs"
            type="date"
            value={minOrderDate}
            name="minOrderDate"
            onChange={onChangeOrder}
          />
          {' ~ '}
          <ReInput
            size="xs"
            type="date"
            name="maxOrderDate"
            value={maxOrderDate}
            onChange={onChangeOrder}
          />
        </div>
      ),
      stretch: true,
    },
    {
      name: '취소 포함 여부',
      renderItem: () => (
        <Radio
          id="showAll"
          value={showAll}
          onChange={(showAll) => setOrderState({ showAll })}
          options={[
            { name: '포함', value: true },
            { name: '미포함', value: false },
          ]}
        />
      ),
      stretch: true,
    },
    {
      name: '주문상태',
      renderItem: () => (
        <Radio
          id="orderStatus"
          value={orderStatus}
          onChange={(orderStatus) => setOrderState({ orderStatus })}
          options={enumToList(SHOP_ORDER_STATUS)}
        />
      ),
      stretch: true,
    },
    {
      name: '주문경로',
      renderItem: () => (
        <Radio
          id="orderPath"
          value={orderPath}
          options={enumToList(PATH)}
          isInitialExisted
          onChange={(orderPath) => setOrderState({ orderPath })}
        />
      ),
      stretch: true,
    },
  ];
  const detailItems: ICardItem[] = [
    {
      name: '결제수단',
      renderItem: () => (
        <Radio
          id="payMethod"
          value={payMethod}
          isInitialExisted
          onChange={(payMethod) => setOrderState({ payMethod })}
          options={enumToList(PAY_METHOD)}
        />
      ),
      stretch: true,
    },
    {
      name: '회원등급',
      renderItem: () => (
        <Radio
          id="userType"
          value={userType}
          isInitialExisted
          onChange={(userType) => setOrderState({ userType })}
          options={enumToList(USER_TYPE)}
        />
      ),
      stretch: true,
    },
    {
      name: '유입경로',
      renderItem: () => (
        <div className="flex items-center gap-2">
          <Select
            size="xs"
            value={inflowChannel}
            name="inflowChannel"
            onChange={onChangeOrder}
          >
            <option value="">유입채널</option>
          </Select>
          <Select
            size="xs"
            value={inflowCampaign}
            name="inflowCampaign"
            onChange={onChangeOrder}
          >
            <option value="">캠페인</option>
          </Select>
        </div>
      ),
      stretch: true,
    },
  ];
  const get = async () => {
    setOrderState({ isLoading: true });
    try {
      let params: any = {
        isAscending: sort === 'ORDER_DATE_ASC',
        limit,
        page,
        orderBy: 'ORDER_DATE',
        userIdList: [idx],
        minOrderDate: !!minOrderDate
          ? `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`
          : null,
        maxOrderDate: !!maxOrderDate
          ? `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`
          : null,
      };
      if (!!inflowCampaign) params.inflowCampaign = inflowCampaign;
      if (!!inflowChannel) params.inflowChannel = inflowChannel;
      if (!!orderPath) params.orderPath = orderPath;
      if (!!orderStatus) params.orderStatus = orderStatus;
      if (!!userType) params.userType = userType;
      if (showAll) params.showAll = showAll;
      const { count, data } = await request.get<any, { count: number; data: any[] }>(
        '/commerce/order',
        { params }
      );
      setOrderState({ total: count, list: data, isLoading: false });
    } catch (err) {
      setOrderState({ isLoading: false });
      console.dir(err);
    }
  };
  const getMemo = async (fetchingOrderNumber: string) => {
    if (!fetchingOrderNumber || isMemoLoading) return;

    setOrderState({ isMemoLoading: true });
    try {
      const { count, data } = await request.get<any, { count: number; data: any[] }>(
        `/commerce/order/fetching-order/memo`,
        { params: { fetchingOrderNumber } }
      );
      setOrderState({ memoList: data, isMemoLoading: false });
    } catch (err) {
      console.dir(err);
      setOrderState({ memoList: [], isMemoLoading: false });
    }
  };
  useEffect(() => {
    get();
  }, [
    page,
    inflowCampaign,
    inflowChannel,
    sort,
    limit,
    minOrderDate,
    maxOrderDate,
    orderPath,
    orderStatus,
    userType,
    minCancelDate,
    maxCancelDate,
    showAll,
  ]);
  return (
    <>
      <div className="p-4">
        <Card items={isDetailOpen ? [...items, ...detailItems] : items}>
          <Button
            theme="secondary"
            size="sm"
            onClick={() => setOrderState({ isDetailOpen: !isDetailOpen })}
          >
            상세조건 {isDetailOpen ? '닫기' : '열기'}
          </Button>
        </Card>
        <div className="my-5 flex justify-center gap-3 text-lg">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>

        <GridTable
          maxHeight=""
          columns={[
            '주문번호',
            '주문일 (결제일)',
            '주문자',
            '판매처 (국가) / 판매처 주문번호',
            '주문상품',
            '수량',
            '상품금액',
            '편집샵 결제금액',
            '총 결제금액',
            '결제수단',
            '주문상태',
            '관리자기능',
          ]}
          isLoading={isLoading}
          header={
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <Select size="sm">
                  <option value="checkedOrderIds">선택한 주문의</option>
                </Select>
                <Select size="sm">
                  <option value="ChangeOrderState">주문상태를</option>
                </Select>
                <Button size="sm" onClick={() => toast.info('준비 중입니다.')}>
                  {'일괄처리 >'}
                </Button>
              </div>
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          renderItem={(order, key) => (
            <div key={key} onClick={() => onChangeCheckbox(order.fetchingOrderNumber)}>
              <div aria-label="checkbox">
                <input
                  type="checkbox"
                  checked={selectedIdx.indexOf(order.fetchingOrderNumber) !== -1}
                  onChange={() => {}}
                />
              </div>
              <div aria-label="주문번호">
                <OpenLink url={`/order/${order.fetchingOrderNumber}`}>
                  {order.fetchingOrderNumber}
                </OpenLink>
              </div>
              <div aria-label="주문일 (결제일)">
                <div>{dayjs(order.orderedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                {!!order.paidAt && (
                  <div className="text-gray-400">
                    ({dayjs(order.paidAt).format('YYYY.MM.DD HH:mm:ss')})
                  </div>
                )}
              </div>
              <div aria-label="주문자">
                <OpenLink url={`/user/${order.userId}`}>{order.userName}</OpenLink>
                <div>{USER_TYPE[order.userType]}</div>
              </div>
              <div aria-label="판매처 (국가) / 판매처 주문번호">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div key={shopIndex} className="flex-1">
                      <div>
                        {shop.shopName} ({shop.shopCountry})
                      </div>
                      <div>{shop.shopOrderNumber}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="주문상품">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemOrderList.length }}
                    >
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex gap-2">
                          <div>
                            <img
                              src={item.itemImageUrl}
                              alt={item.itemName}
                              className="max-w-[3.5rem] object-contain"
                            />
                          </div>
                          <div className="flex-1">
                            <div>{`${shop.shopName}, ${item.brandName}`}</div>
                            <OpenLink
                              url={`/product/productDetail/v2/${Number(
                                item.itemId
                              )}?mode=update`}
                            >
                              {item.itemName}
                            </OpenLink>
                            <div className="text-red-400">{item.sizeName}</div>
                            {!!item.shippingCompanyName && (
                              <div>택배사: {item.shippingCompanyName}</div>
                            )}
                            {!!item.invoice && <div>운송장번호: {item.invoice}</div>}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="수량">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemOrderList.length }}
                    >
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1 flex">
                          {item.quantity}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="상품금액">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemOrderList.length }}
                    >
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1 flex">
                          {priceFormat(item.itemAmount)}원
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="편집샵 결제금액">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div key={shopIndex} className="flex-1">
                      <div>{priceFormat(shop.payAmount)} 원</div>
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="총 결제금액">
                <NumberStat value={order.payAmount} />
              </div>
              <div aria-label="결제수단">{PAY_METHOD[order.payMethod]}</div>
              <div aria-label="주문상태">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div key={shopIndex} className="flex-1">
                      {SHOP_ORDER_STATUS[shop.orderStatus]}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="관리자기능">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopOrderList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      className="flex-1 flex flex-col items-center gap-1"
                    >
                      <Button
                        size="xs"
                        theme="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(`/order/${order.fetchingOrderNumber}`, '_blank');
                        }}
                      >
                        관리
                      </Button>
                      <Button
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOrderState({
                            isMessageOpen: true,
                            phoneNumber: order.phoneNumber,
                          });
                        }}
                      >
                        메시지
                      </Button>
                      <Tooltip
                        onMouseLeave={() =>
                          setOrderState({ memoList: [], isMemoLoading: false })
                        }
                        onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                        padding={false}
                        content={
                          <div>
                            {isMemoLoading ? (
                              <Spinner className="text-black h-5 w-5 m-3" />
                            ) : memoList.length ? (
                              <GridTable
                                columns={['작성일 (수정일)', '작성자', '내용']}
                                list={memoList}
                                noSelection
                                renderItem={(item, key) => (
                                  <div key={key}>
                                    <div>
                                      <div>
                                        {dayjs(item.createdAt).format(
                                          'YYYY.MM.DD. HH:mm:ss'
                                        )}
                                      </div>
                                      <div className="text-gray-300">
                                        (
                                        {dayjs(item.updatedAt).format(
                                          'YYYY.MM.DD. HH:MM:SS'
                                        )}
                                        )
                                      </div>
                                    </div>
                                    <div>{item.adminName}</div>
                                    <div>{item.content}</div>
                                  </div>
                                )}
                              />
                            ) : (
                              <div className="text-gray-500 py-4 px-6">
                                메모 내역이 없습니다.
                              </div>
                            )}
                          </div>
                        }
                        position="top"
                      >
                        <Button
                          size="xs"
                          onClick={() =>
                            window.open(
                              `/order/${order.fetchingOrderNumber}?tab=8`,
                              '_blank'
                            )
                          }
                        >
                          메모
                        </Button>
                      </Tooltip>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          list={list}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setOrderState({
              selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
            })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
      <ReMessageModal
        isOpen={isMessageOpen}
        onClose={() => setOrderState({ isMessageOpen: false, phoneNumber })}
        phoneNumber={phoneNumber}
      />
    </>
  );
};

export default UserOrder;
