import React from 'react';
import * as style from './style';

export default ({ platform, handlePlatformCheck }) => {
  const way = [
    {
      name: '전체',
      value: [
        'SMARTSTORE',
        'NAVERPAY',
        'CAFE24_PC',
        'CAFE24_MOBILE',
        'APP_SHOP',
        'APP_FETCHING',
        'APP_NAVERPAY',
      ],
    },
    { name: 'App 편집샵 결제', value: 'APP_SHOP' },
    { name: 'App 페칭 결제', value: 'APP_FETCHING' },
    { name: 'App 네이버페이', value: 'APP_NAVERPAY' },
    { name: '카페24 PC', value: 'CAFE24_PC' },
    { name: '카페24 모바일', value: 'CAFE24_MOBILE' },
    { name: '네이버페이', value: 'NAVERPAY' },
    { name: '스마트스토어', value: 'SMARTSTORE' },
  ];

  return (
    <style.Wrapper>
      <style.Item BC="#8080806b">
        <strong>결제 수단</strong>
      </style.Item>
      {way.map((value, i) => {
        const index = platform.findIndex((el) => {
          return el.name === value.name;
        });
        return (
          <style.Item key={i}>
            <style.CheckBox
              type="checkbox"
              value={JSON.stringify(value)}
              checked={index !== -1}
              onChange={handlePlatformCheck}
            />
            <style.Text>{value.name}</style.Text>
          </style.Item>
        );
      })}
    </style.Wrapper>
  );
};
