import React from 'react';
import * as style from './style';

const SimpleInput = ({ placeholder, value, onChange }) => {
  return (
    <style.Input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
};
export default SimpleInput;
