import React from 'react';
import type { FunctionComponent } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid';

export interface Props {
  checked: boolean;
}
interface State {}

const Check: FunctionComponent<Props> = ({ checked }) => {
  return checked ? (
    <CheckCircleIcon className="h-5 w-5 text-green-400 inline-block" />
  ) : (
    <XCircleIcon className="h-5 w-5 text-red-400 inline-block" />
  );
};

export default Check;
