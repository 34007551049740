import React from 'react';
import * as style from './style';

const CountyApplied = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const { country } = searchOptionTableState;

  return (
    <style.Container>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            country: e.target.value,
          });
        }}
        widthPcnt={22}
        noBorder
      >
        <style.Label>
          <style.Radio
            name="country"
            value="null"
            checked={country === 'null'}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            name="country"
            value="NONE"
            checked={country === 'NONE'}
            onChange={() => {}}
          />
          미적용
        </style.Label>
        {/* <style.Radio name='country' value='false' checked={country === 'false'} /><style.Label>미적용</style.Label> */}
      </style.Div>
    </style.Container>
  );
};

export default CountyApplied;
