import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { fetchingAPI } from 'api/axios';
import { LoadingContext } from 'context';
import * as style from './style';
import { Spinner } from 'components';

export default (props) => {
  const { onHide, targetIds } = props;

  const { isLoading, handleIsLoading } = useContext(LoadingContext);
  // const itemIds = useSelector((state) => state.selectItems.itemsId);
  const itemIds = targetIds;
  const [rate, setRate] = useState(5);

  const handleRateChange = async () => {
    try {
      handleIsLoading(true);

      console.log(
        itemIds.map((itemId) => ({
          id: itemId,
          agencyFeeRate: rate,
        }))
      );

      await fetchingAPI.put(
        '/item',
        itemIds.map((itemId) => ({
          id: itemId,
          agencyFeeRate: rate,
        }))
      );
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      PUT - /item
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
      setRate(0);
      onHide();
    }
  };

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>페칭 수수료 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <style.LoadOuter>
            <Spinner className="w-10 h-10 text-black" />
            <p>loading ... </p>
          </style.LoadOuter>
        )}
        <style.Description>
          선택한 상품을 아래 입력한 요율로 변경합니다.🎈
        </style.Description>
        <style.TextInput
          placeholder={'예) 5'}
          value={rate}
          onChange={(e) => {
            setRate(parseInt(e.target.value, 10) || 0);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleRateChange}>
          변경
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
