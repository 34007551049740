import React, { useEffect, useState, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'api/axios';
import { DuplicateOption, AddExhibition } from '../../UI';

export default function ClassificationModal(props) {
  const { title, onHide } = props;

  const [categories, setCategories] = useState([]);
  const [addCategories, setAddCategories] = useState([]);

  const [promotions, setPromotions] = useState([]);
  const [addPromotions, setAddPromotions] = useState([]);

  const [exhibitions, setExhibitions] = useState([]);
  const [addExhibitions, setAddExhibitions] = useState([]);

  const [duplicateOption, setDuplicateOption] = useState({
    categoryIdList: [],
    promotionIdList: [],
  });

  const getCategories = async () => {
    try {
      const res = await axios.get('/api/categories');
      setCategories(res.data);
    } catch (err) {
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/categories
      ${err}`);
      console.error(err);
    }
  };

  const getPromotions = useCallback(async () => {
    try {
      const res = await axios.get('/api/promotions');
      setPromotions(res.data);
    } catch (err) {
      if (err.response.status !== 401) {
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        GET - '/api/promotions
        ${err}`);
        console.error(err);
      }
    }
  }, [promotions]);

  const getExhibitions = async () => {
    try {
      const res = await axios.get('/api/category/exhibition');
      setExhibitions(res.data.data);
    } catch (err) {
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/category/exhibition
      ${err}`);
      console.error(err);
    }
  };

  const handleAddCategory = (e, pathname) => {
    if (e.target.checked) {
      const result = addCategories.filter((el) => {
        return el.fetchingCategoryId !== Number(e.target.value);
      });
      setAddCategories([
        ...result,
        {
          fetchingCategoryId: Number(e.target.value),
          fetchingCategoryPath: pathname.trim(),
        },
      ]);
      const categoryIdList = duplicateOption.categoryIdList.filter((el) => {
        return el !== Number(e.target.value);
      });
      categoryIdList.push(Number(e.target.value));
      setDuplicateOption({
        ...duplicateOption,
        categoryIdList,
      });
    } else {
      const result = addCategories.filter((el) => {
        return el.fetchingCategoryId !== Number(e.target.value);
      });
      setAddCategories(result);
    }
  };

  const handleAddPromotions = (e, item) => {
    if (e.target.checked) {
      const result = addPromotions.filter((el) => {
        return el.id !== Number(e.target.value);
      });
      setAddPromotions([...result, item]);
      const promotionIdList = duplicateOption.promotionIdList.filter((el) => {
        return el !== Number(e.target.value);
      });
      promotionIdList.push(Number(e.target.value));
      setDuplicateOption({
        ...duplicateOption,
        promotionIdList,
      });
    } else {
      const result = addPromotions.filter((el) => {
        return el.id !== Number(e.target.value);
      });
      setAddPromotions(result);
    }
  };

  const handleAddExhibition = (id, gender) => {
    const addItem = exhibitions.find((el) => el.exhibition_id === Number(id));
    setAddExhibitions([
      {
        exhibitionId: Number(id),
        gender: gender === 'MW' ? '전체' : gender === 'M' ? '남성' : '여성',
        name: addItem.name,
      },
    ]);
  };

  const getParentCategory = (target) => {
    return categories.find((item) => {
      return item.id === target;
    });
  };

  const handleAllCheckedCategories = (e) => {
    const result = categories.map((category) => category.id);
    if (e.target.checked) {
      setDuplicateOption({
        ...duplicateOption,
        categoryIdList: result,
      });

      const allAddCategories = categories.map((el) => {
        const parent = getParentCategory(el.parent);

        let parentResult;
        if (parent) {
          parentResult = getParentCategory(el.parent);
        }

        const pathname = parent
          ? ` ${parentResult ? parentResult.name + ' > ' : ' '} ${parent.name} > ${
              el.name
            }`
          : el.name;

        return {
          fetchingCategoryId: Number(el.id),
          fetchingCategoryPath: pathname.trim(),
        };
      });

      setAddCategories(allAddCategories);
    } else {
      setDuplicateOption({
        ...duplicateOption,
        categoryIdList: [],
      });
      setAddCategories([]);
    }
  };

  const handleAllCheckedPromotions = (e) => {
    const result = promotions.map((promotion) => promotion.id);
    if (e.target.checked) {
      setDuplicateOption({
        ...duplicateOption,
        promotionIdList: result,
      });
      setAddPromotions(promotions);
    } else {
      setDuplicateOption({
        ...duplicateOption,
        promotionIdList: [],
      });
      setAddPromotions([]);
    }
  };

  const handleGetList = () => {
    switch (title) {
      case '카테고리':
        return getCategories();
      case '프로모션':
        return getPromotions();
      case '기획전':
        return getExhibitions();
      default:
        throw new Error('ClassificationModal handleGetList error');
    }
  };

  useEffect(() => {
    handleGetList();
  }, []);

  useEffect(() => {
    setAddCategories([]);
    setAddPromotions([]);
    setAddExhibitions([]);
    setDuplicateOption({
      categoryIdList: [],
      promotionIdList: [],
    });
  }, [onHide]);

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
        {title === '카테고리' && (
          <DuplicateOption
            title="카테고리"
            items={categories}
            onChange={handleAddCategory}
            duplicateOption={duplicateOption}
            handleAllChecked={handleAllCheckedCategories}
          />
        )}
        {title === '프로모션' && (
          <DuplicateOption
            title="프로모션"
            items={promotions}
            duplicateOption={duplicateOption}
            onChange={handleAddPromotions}
            handleAllChecked={handleAllCheckedPromotions}
          />
        )}
        {title === '기획전' && (
          <AddExhibition items={exhibitions} onChange={handleAddExhibition} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (title === '카테고리') {
              onHide(addCategories);
            } else if (title === '프로모션') {
              onHide(addPromotions);
            } else {
              onHide(addExhibitions);
            }
          }}
        >
          추가 적용
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
