import { Alert, Button, FormItem, ReInput, ReModal, Select } from 'components';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';
import { IModal, IShippingCompany } from 'types';
import Switch from 'react-switch';
import useSWR from 'swr/immutable';

export interface Props extends IModal {
  apiUrl: string;
  onSuccess: () => void;
  type?: 'fetching' | 'shop';
  invoice?: string;
  shippingCode?: string;
  isWaypoint?: boolean;
  alert?: React.ReactNode[];
}
interface State {
  invoice: string;
  shippingCode: string;
  options: IShippingCompany[];
  isLoading: boolean;
  isWaypoint: boolean;
}

const UpdateInvoiceModal: FunctionComponent<Props> = (props) => {
  const { isOpen, onClose, apiUrl, onSuccess, type, alert } = props;
  if (!isOpen) return null;
  const [{ invoice, shippingCode, isLoading, isWaypoint }, setState, onChange] =
    useObject<State>({
      invoice: props.invoice || '',
      shippingCode: props.shippingCode || '',
      options: [],
      isLoading: false,
      isWaypoint: props.isWaypoint || false,
    });

  const { data } = useSWR<{ data: IShippingCompany[]; count: number }>(
    `/commerce/order/shipping-company`,
    request.get
  );

  const onSubmit = async () => {
    if (!invoice || !shippingCode) {
      toast.info('배송사를 선택하고 송장번호를 입력해주세요.');
      return;
    }
    if (!window.confirm(`송장번호를 ${submitType}하시겠습니까?`)) return;

    let data: any = {
      isWaypoint,
    };
    if (apiUrl.indexOf('/commerce/order/order-exchange/shipping/') !== -1) {
      if (type === 'fetching') {
        data.fetchingInvoice = invoice;
        data.fetchingShippingCode = shippingCode;
      } else {
        data.shopInvoice = invoice;
        data.shopShippingCode = shippingCode;
      }
    } else {
      data.invoice = invoice;
      data.shippingCode = shippingCode;
    }
    setState({ isLoading: true });
    try {
      await request.put(apiUrl, data);
      toast.success('송장번호가 변경되었습니다.');
      onClose();
      onSuccess();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isLoading: false });
    }
  };

  const submitType: '변경' | '등록' = useMemo(() => {
    return !!props.invoice ? '변경' : '등록';
  }, [props.invoice]);

  return (
    <ReModal
      title={`송장번호 ${submitType}`}
      description={
        submitType === '등록'
          ? '송장번호 등록 시 상품준비 중 또는 출고 중으로 전환됩니다.'
          : '송장번호를 변경합니다.'
      }
      isOpen={isOpen}
      onClose={onClose}
    >
      {submitType === '변경' && (
        <div className="mb-4">
          <Alert
            type="info"
            list={['고객에게 노출되는 송장번호도 변경되오니 유의해주세요.']}
          />
        </div>
      )}
      {alert && (
        <div className="mb-4">
          <Alert type="info" list={alert} />
        </div>
      )}
      <div className="space-y-4">
        <FormItem label="배송사">
          <Select value={shippingCode} name="shippingCode" onChange={onChange}>
            <option value="" disabled selected>
              선택
            </option>
            {data?.data.map((item, key) => (
              <option key={key} value={item.code}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="송장번호 입력">
          <ReInput
            id="송장번호 입력"
            className="w-full"
            value={invoice}
            name="invoice"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="배대지 여부">
          <Switch
            checked={isWaypoint}
            onChange={(isWaypoint) => setState({ isWaypoint })}
          />
        </FormItem>
        <div className="flex justify-center">
          <Button isLoading={isLoading} onClick={onSubmit}>
            송장 {submitType}하기
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default UpdateInvoiceModal;
