import { ShopTableItem } from 'components/UI/molecules';
import React from 'react';
import * as style from './style';

const ShopTable = ({ shops }) => {
  return (
    <style.Container>
      <style.TitleWrapper>
        <style.Title>편집샵 ID</style.Title>
        <style.Title>편집샵명</style.Title>
        <style.Title>우선순위</style.Title>
        <style.Title>앱 노출여부</style.Title>
        <style.Title>카페24 업로드</style.Title>
        <style.Title>페칭결제 지원</style.Title>
        <style.Title>네이버 피드 업로드</style.Title>
      </style.TitleWrapper>
      <style.Inner>
        {shops.map((shop) => {
          return <ShopTableItem key={shop.id} shop={shop} />;
        })}
      </style.Inner>
    </style.Container>
  );
};

export default ShopTable;
