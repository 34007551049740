import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { IModal } from 'types';
import { FormItem, GridTable, ReModal } from 'components';
import { DELIVERY_STATUS, enumToList, request, useObject } from 'services';
import { toast } from 'react-toastify';
import { CheckCircleIcon } from '@heroicons/react/solid';
import classnames from 'classnames';

export interface Props extends IModal {
  invoice: string;
  shippingCode: string;
}
interface State {
  deliveryStatus: keyof typeof DELIVERY_STATUS | '';
  isDomestic: boolean;
  shippingCompanyName: string;
  invoiceNo: string;
  level: number;
  trackingDetails: Array<{
    where: string;
    kind: string;
    timeString: string;
  }>;
  errorMsg: string;
}

const ShippingTrackingModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  invoice,
  shippingCode,
}) => {
  if (!isOpen) return null;
  const [
    {
      deliveryStatus,
      isDomestic,
      shippingCompanyName,
      invoiceNo,
      level,
      trackingDetails,
      errorMsg,
    },
    setState,
  ] = useObject<State>({
    deliveryStatus: '',
    isDomestic: false,
    shippingCompanyName: '',
    invoiceNo: '',
    level: 0,
    trackingDetails: [],
    errorMsg: '',
  });

  const get = async () => {
    if (!invoice || !shippingCode) {
      toast.info('운송장번호와 배송사코드가 필요합니다.');
      return;
    }
    try {
      const data = await request.get<
        any,
        {
          deliveryStatus: keyof typeof DELIVERY_STATUS;
          isDomestic: boolean;
          shippingCompanyName: string;
          trackingInfo: {
            level?: number;
            invoiceNo?: string;
            trackingDetails?: Array<{
              where: string;
              kind: string;
              timeString: string;
            }>;
            code?: string;
            msg?: string;
            status?: boolean;
          };
        }
      >('/commerce/order/shipping-tracking', {
        params: { invoice, shippingCode },
      });
      setState({
        deliveryStatus: data.deliveryStatus,
        isDomestic: data.isDomestic,
        shippingCompanyName: data.shippingCompanyName,
        level: data.trackingInfo.level,
        invoiceNo: data.trackingInfo.invoiceNo,
        trackingDetails: data.trackingInfo.trackingDetails,
        errorMsg: data.trackingInfo.msg,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <ReModal
      title="배송 추적"
      description="배송 현황을 조회합니다."
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-7xl"
    >
      {!!errorMsg ? (
        <div className="flex items-center justify-center text-xl">{errorMsg}</div>
      ) : (
        <div className="space-y-4">
          <FormItem label="배송 상태">
            <div className="flex items-center border border-gray-400 py-3">
              {enumToList(DELIVERY_STATUS).map((item, key) => (
                <div
                  key={key}
                  className={classnames(
                    'flex-1',
                    deliveryStatus === item.value
                      ? 'text-black font-bold'
                      : 'text-gray-400'
                  )}
                >
                  <div className="flex justify-center">
                    <CheckCircleIcon className="w-5 h-5" />
                  </div>
                  <div className="text-sm text-center">{item.name}</div>
                </div>
              ))}
            </div>
          </FormItem>
          <FormItem label="택배사" readOnly>
            <span className="text-xl">{shippingCompanyName}</span>
          </FormItem>
          <FormItem label="송장번호" readOnly>
            {invoiceNo}
          </FormItem>
          <FormItem label="배송 로그">
            <GridTable
              list={trackingDetails}
              noSelection
              renderItem={(item, key) => (
                <div key={key}>
                  <div>{item.timeString}</div>
                  <div>{item.where}</div>
                  <div>{item.kind}</div>
                </div>
              )}
              columns={['시간', '현재 위치', '상세 정보']}
            />
          </FormItem>
        </div>
      )}
    </ReModal>
  );
};

export default ShippingTrackingModal;
