import { fetchingAPI } from 'api/axios';
import { Loading, OrderItemTable } from 'components';
import { LoadingContext } from 'context';
import React, { useContext, useState } from 'react';
import * as S from './style';

const OrderOld = () => {
  ////로딩 콘텍스트
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  /////검색 옵션, 결과 상태
  const initialState = {
    searchType: 'FETCHING_CODE',
    searchKey: '',
  };
  const [searchOption, setSearchOption] = useState(initialState);
  const [items, setItems] = useState([]);

  ///옵션 변경 이벤트 리스너
  const onChangeSearchType = (e) => {
    const { value } = e.target;
    setSearchOption((prev) => ({
      ...prev,
      searchType: value,
    }));
  };
  const onChangeSearchKey = (e) => {
    const { value } = e.target;
    setSearchOption((prev) => ({
      ...prev,
      searchKey: value,
    }));
  };
  /////검색/초기화 버튼 이벤트 리스너
  const onClickSearchButton = () => {
    const fetchData = async () => {
      try {
        handleIsLoading(true);
        const { data } = await fetchingAPI.get('/item', {
          params: {
            isExtended: true,
            searchType: searchOption.searchType,
            searchKey: searchOption.searchKey,
          },
        });
        setItems(data.data);
      } catch (error) {
        alert(`err in fetchData(), <OrderOld />, ${error.message}`);
      } finally {
        handleIsLoading(false);
      }
    };
    if (searchOption.searchKey === '') {
      alert('검색한 상품의 코드를 입력하세요');
    } else {
      fetchData();
    }
  };
  const onClickResetButton = () => {
    setSearchOption(initialState);
    setItems([]);
  };

  return (
    <S.Outer>
      {isLoading && <Loading />}
      <S.ShadowContainer>
        <h1 className="text-3xl font-bold mb-2">발주</h1>
        <S.Table>
          <S.Tbody>
            <S.Tr>
              <S.LeftTd>검색 기준</S.LeftTd>
              <S.Td>
                <select value={searchOption.searchType} onChange={onChangeSearchType}>
                  <option value="FETCHING_CODE">페칭코드</option>
                  <option value="CAFE24_CODE">카페24 ID</option>
                  <option value="CAFE24_CODE_ALT">카페24 코드</option>
                </select>
                <S.TextArea value={searchOption.searchKey} onChange={onChangeSearchKey} />
              </S.Td>
            </S.Tr>
          </S.Tbody>
        </S.Table>
        <S.ButtonsContainer>
          <S.ModifyButton onClick={onClickSearchButton}>검색</S.ModifyButton>
          <S.ResetButton onClick={onClickResetButton}>초기화</S.ResetButton>
        </S.ButtonsContainer>
      </S.ShadowContainer>
      <S.ShadowContainer>
        <OrderItemTable items={items} />
      </S.ShadowContainer>
    </S.Outer>
  );
};

export default OrderOld;
