import {
  UserAdminMemo,
  UserCoupon,
  UserInterest,
  UserLog,
  UserMail,
  UserMessage,
  UserOrder,
  UserProfile,
  UserPush,
  UserReserve,
  UserShopping,
} from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { request, useObject, useQuery, USER_DETAIL_MENU_LIST, useUser } from 'services';

export interface Props {}
interface State {
  title: keyof typeof USER_DETAIL_MENU_LIST;
  couponTotal: number;
  deletedCouponCount: number;
  availableCouponCount: number;
  unusedCouponCount: number;
  pointTotal: number;
  usedPointCount: number;
  availablePointCount: number;
  unavailablePointCount: number;
  orderCount: number;
  id: string;
  name: string;
}

const UserIdxPage: FunctionComponent<Props> = () => {
  const { user } = useUser();
  const { query, updateQuery } = useQuery<{ tab: keyof typeof USER_DETAIL_MENU_LIST }>();
  const { idx } = useParams<{ idx: string }>();
  const [
    {
      title,
      couponTotal,
      unusedCouponCount,
      deletedCouponCount,
      availableCouponCount,
      pointTotal,
      usedPointCount,
      availablePointCount,
      unavailablePointCount,
      id,
      name,
      orderCount,
    },
    setState,
  ] = useObject<State>({
    title: query.tab || 'profile',
    couponTotal: 0,
    unusedCouponCount: 0,
    deletedCouponCount: 0,
    availableCouponCount: 0,
    pointTotal: 0,
    unavailablePointCount: 0,
    usedPointCount: 0,
    availablePointCount: 0,
    id: '',
    name: '',
    orderCount: 0,
  });

  const get = async () => {
    try {
      const [data, { total }] = await Promise.all([
        request.get<any, { id: string; name: string }>(`/commerce/user/${idx}`),
        request.get<any, { total: number }>(`/commerce/user/${idx}/order/count`),
      ]);
      setState({ id: data.id || '', name: data.name || '', orderCount: total });
    } catch (err) {
      console.dir(err);
    }
  };
  const getCoupon = async () => {
    try {
      const data = await request.get<
        any,
        { total: number; deleted: number; available: number; unused: number }
      >(`/commerce/user/${idx}/coupons/count`);
      setState({
        couponTotal: Number(data.total || 0),
        deletedCouponCount: Number(data.deleted || 0),
        availableCouponCount: Number(data.available || 0),
        unusedCouponCount: Number(data.unused || 0),
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getPoint = async () => {
    try {
      const data = await request.get<
        any,
        { total: number; available: number; unavailable: number; used: number }
      >(`/commerce/user/${idx}/point/total`);
      setState({
        pointTotal: Number(data.total || 0),
        availablePointCount: Number(data.available || 0),
        unavailablePointCount: Number(data.unavailable || 0),
        usedPointCount: Number(data.used || 0),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const setTitle = (value: keyof typeof USER_DETAIL_MENU_LIST) => {
    setState({ title: value });
    updateQuery({ tab: value });
  };

  useEffect(() => {
    get();
    getCoupon();
    getPoint();
  }, [user]);
  return (
    <div className="min-h-screen bg-black relative overflow-scroll">
      <div
        className="bg-white rounded p-4 sticky left-5 top-5 text-xs"
        style={{
          width: 176,
          height: 'calc(100vh - 40px)',
        }}
      >
        <div className="font-bold text-lg">{name}</div>
        <div className="text-sm break-words">({id})</div>

        <div className="mt-4">
          <div className="flex justify-between">
            <div>주문건수</div>
            <div>
              <span onClick={() => setTitle('order')} className="link mr-1">
                {orderCount}
              </span>
              건
            </div>
          </div>
          <div className="flex justify-between">
            <div>적립금</div>
            <div>
              <span onClick={() => setTitle('point')} className="link mr-1">
                {pointTotal}
              </span>
              원
            </div>
          </div>
          <div className="flex justify-between">
            <div>쿠폰</div>
            <div>
              <span onClick={() => setTitle('coupon')} className="link mr-1">
                {couponTotal}
              </span>
              개
            </div>
          </div>
        </div>

        <ul className="mt-10 flex flex-col gap-3">
          {Object.keys(USER_DETAIL_MENU_LIST).map((item: any) => (
            <li
              className={`cursor-pointer hover:text-black ${
                title === item ? 'text-black font-bold' : 'text-gray-500 font-normal'
              }`}
              onClick={() => setTitle(item)}
              key={item}
            >
              {USER_DETAIL_MENU_LIST[item]}
            </li>
          ))}
        </ul>
      </div>

      <div className="absolute left-[216px] top-5">
        <div
          style={{
            borderRadius: 4,
            width: 'calc(100vw - 236px)',
            background: '#fff',
            marginBottom: 20,
            minHeight: 'calc(100vh - 40px)',
          }}
        >
          <div
            style={{
              fontSize: 18,
              fontWeight: 700,
              lineHeight: '22px',
              marginBottom: 10,
              padding: 20,
            }}
          >
            {USER_DETAIL_MENU_LIST[title]}
          </div>
          {title === 'profile' && <UserProfile />}
          {title === 'order' && <UserOrder />}
          {title === 'point' && (
            <UserReserve
              available={availablePointCount}
              unavailable={unavailablePointCount}
              used={usedPointCount}
              total={pointTotal}
              onRefresh={getPoint}
            />
          )}
          {title === 'coupon' && (
            <UserCoupon
              deleted={deletedCouponCount}
              total={couponTotal}
              available={availableCouponCount}
              unused={unusedCouponCount}
              onRefresh={getCoupon}
            />
          )}
          {title === 'basket' && <UserShopping />}
          {title === 'interest' && <UserInterest />}
          {title === 'message' && <UserMessage />}
          {/* {title === '메일 발송 내역' && <UserMail />} */}
          {title === 'push' && <UserPush />}
          {title === 'memo' && <UserAdminMemo />}
          {title === 'log' && <UserLog />}
        </div>
      </div>
    </div>
  );
};

export default UserIdxPage;
