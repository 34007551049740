import React from 'react';
import * as style from './style';

const DiscountStatusTd = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const { isShopDiscount, hasPromotion, hasExhibition } =
    searchOptionTableState;

  return (
    <style.Container>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            isShopDiscount: e.target.value,
          });
        }}
        widthPcnt={22}
      >
        <style.Label>
          <style.Radio
            value="null"
            checked={isShopDiscount === 'null'}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="true"
            checked={isShopDiscount === 'true'}
            onChange={() => {}}
          />
          자체할인 있음
        </style.Label>
        <style.Label>
          <style.Radio
            value="false"
            checked={isShopDiscount === 'false'}
            onChange={() => {}}
          />
          자체할인 없음
        </style.Label>
      </style.Div>

      <style.Left widthPcnt={11}>프로모션 적용</style.Left>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            hasPromotion: e.target.value,
          });
        }}
        widthPcnt={22}
      >
        <style.Margin9px />
        <style.Label>
          <style.Radio
            value="null"
            checked={hasPromotion === 'null'}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="true"
            checked={hasPromotion === 'true'}
            onChange={() => {}}
          />
          프로모션 적용됨
        </style.Label>
        <style.Label>
          <style.Radio
            value="false"
            checked={hasPromotion === 'false'}
            onChange={() => {}}
          />
          프로모션 적용안됨
        </style.Label>
      </style.Div>

      <style.Left widthPcnt={11}>기획전</style.Left>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            hasExhibition: e.target.value,
          });
        }}
        widthPcnt={22}
        noBorder
      >
        <style.Margin9px />
        <style.Label>
          <style.Radio
            value="null"
            checked={hasExhibition === 'null'}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="true"
            checked={hasExhibition === 'true'}
            onChange={() => {}}
          />
          기획전 있음
        </style.Label>
        <style.Label>
          <style.Radio
            value="false"
            checked={hasExhibition === 'false'}
            onChange={() => {}}
          />
          기획전 없음
        </style.Label>
      </style.Div>
    </style.Container>
  );
};

export default DiscountStatusTd;
