import { Alert } from 'antd';
import { getBrandList } from 'api/fetchingAPI';
import { ListHead, Outer, ShadowContainer, TableContainer } from 'components';
import { TableElement } from 'components/UI/atoms/Containers';
import { isManagedOnAdmin } from 'pages/ManageMainComponent/utils';
import { getGender, useMainLayout } from 'pages/ManageMainLayout/utils';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled(TableElement)`
  border-bottom: 1px solid #201c1c;
  color: black;
  text-decoration: none;
  ${({ fixed = false }: { fixed?: boolean }) => fixed && 'background:#C4C4C4;'}
`;

const SecondaryButton = styled(Button)`
  outline: none;
  &:focus {
    outline: none;
    box-shadow: none;
    background: #6c757d;
  }
`;

type Props = { mode: 'm' | 'w' };

const ManageLayout: React.FC<Props> = ({ mode }) => {
  const [brands, isBrandsLoading] = useBrands(mode);
  const [id, onChange] = usePickId();
  const {
    state: [layouts, setLayouts],
    reload,
    isLoading,
  } = useMainLayout(mode);
  const [isPending, setIsPending] = useState(false);

  const goUp = (index: number) => {
    const diff =
      layouts
        .slice(0, index)
        .reverse()
        .findIndex((layout) => !layout.immutable && !layout.fixed) + 1;
    if (diff > 0) {
      setLayouts((p) => {
        [p[index - diff], p[index]] = [p[index], p[index - diff]];
        return p.map((layout, order) => ({ ...layout, order }));
      });
    }
  };

  const goDown = (index: number) => {
    const diff =
      layouts.slice(index + 1).findIndex((layout) => !layout.immutable && !layout.fixed) +
      1;
    if (diff > 0) {
      setLayouts((p) => {
        [p[index + diff], p[index]] = [p[index], p[index + diff]];
        return p.map((layout, order) => ({ ...layout, order }));
      });
    }
  };

  if (isLoading)
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner animation="border" />
      </div>
    );
  else
    return (
      <Outer>
        <ShadowContainer>
          <Alert
            message={
              <>
                <a href="https://admin.fetching.co.kr/products/category?tab=1">
                  새 어드민
                </a>
                에서 개선된 카테고리 매핑을 사용하세요.
              </>
            }
            type="warning"
            closable
          />
          <div className="flex items-center justify-between mb-5">
            <div className="flex items-center gap-3">
              <h1 className="text-xl">{getGender(mode)} 레이아웃 관리</h1>
              <select onChange={onChange}>
                <option value="">브랜드를 선택해주세요.</option>
                {brands.map((brand) => (
                  <option key={brand.id} value={brand.id}>
                    {brand.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex gap-3">
              <Button variant="warning">초기화</Button>
              <Button>{isPending ? <Spinner animation="border" /> : '저장하기'}</Button>
              <Link to={`/content/brand/${mode}/layout/add`}>
                <Button variant="outline-dark">컴포넌트 추가</Button>
              </Link>
            </div>
          </div>
          <TableContainer>
            <ListHead />
            {layouts.map((layout, i) => (
              <Container fixed={layout.immutable || layout.fixed}>
                <div>
                  {!layout.immutable && (
                    <Button
                      variant={layout.fixed ? 'dark' : 'light'}
                      // onClick={() => toggleFix(layout.order)}
                    >
                      {layout.fixed ? '해제' : '고정'}
                    </Button>
                  )}
                </div>
                <div>
                  {!(layout.immutable || layout.fixed) && (
                    <Button
                      variant="danger"
                      // onClick={() => deleteLayout(layout.idx, layout)}
                    >
                      삭제
                    </Button>
                  )}
                </div>
                <div>
                  {!(layout.immutable || layout.fixed) && (
                    <>
                      <SecondaryButton
                        variant="secondary"
                        className="focus:outline-none focus:shadow-none"
                        onClick={() => goUp(layout.order)}
                      >
                        👆
                      </SecondaryButton>{' '}
                      <SecondaryButton
                        variant="secondary"
                        onClick={() => goDown(layout.order)}
                      >
                        👇
                      </SecondaryButton>
                    </>
                  )}
                </div>
                <div>
                  <Button
                    variant={layout.isActive ? 'outline-success' : 'outline-danger'}
                    // onClick={() => toggleActive(layout.order, layout)}
                  >
                    {layout.isActive ? '켜짐' : '꺼짐'}
                  </Button>
                </div>
                <div>
                  {isManagedOnAdmin(layout.type) ? (
                    <Link to={`/content/main/${mode}/component/${layout.idx}`}>
                      {layout.name}
                    </Link>
                  ) : (
                    <span>{layout.name}</span>
                  )}
                </div>
                <div>
                  <p>{layout.type}</p>
                </div>
              </Container>
            ))}
          </TableContainer>
        </ShadowContainer>
      </Outer>
    );
};

export default ManageLayout;

const useBrands = (mode: 'm' | 'w'): [any[], boolean] => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    getBrandList()
      .then((data) => setData(data?.data ?? []))
      .finally(() => setIsLoading(false));
  }, []);
  return [data, isLoading];
};

const usePickId = (): [number | null, (e: any) => void] => {
  const [id, setId] = useState<number | null>(null);
  const onChange = (e: any) => {
    const { name, value } = e.target;
    setId(Number(value));
  };

  return [id, onChange];
};
