import styled from 'styled-components';

export const EditorContainer = styled.div`
  flex-grow: 1;
  padding: 20px 60px;
`;

export const ComponentCard = styled.div<{
  disabled: boolean;
  inActive?: boolean;
  selected?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 218px;
  height: 60px;
  font-size: 16px;
  ${({ disabled }) =>
    disabled
      ? `
border:1px solid black;
cursor:pointer;
`
      : `
background:#C4C4C4;
`}
  ${({ inActive }) => inActive && 'color:red'}
  ${({ selected }) => selected && 'border-width:2px;font-weight:bold;'}
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ContainerRule = {
  display: 'flex',
  gap: '22px',
};
