import React, { useState, useEffect } from 'react';
import * as style from './style';

const BrandMapTableItem = ({ id, item, selectedItems, onChangeIsChecked }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChecked = () => {
    if (item.mapId) {
      return selectedItems.findIndex((el) => {
        return el.mapId === id;
      });
    } else {
      return selectedItems.findIndex((el) => {
        return el.semiName === id;
      });
    }
  };

  useEffect(() => {
    setIsChecked(handleChecked());
  }, [selectedItems]);

  return (
    <style.Container>
      <style.Checkbox
        checked={isChecked !== -1}
        value={id}
        onChange={onChangeIsChecked}
      />
      <style.Item>
        {item.mapId ? (
          <style.Text color="black">매핑됨</style.Text>
        ) : (
          <style.Text color="red">매핑안됨</style.Text>
        )}
      </style.Item>
      <style.Item>{item.semiName}</style.Item>
      <style.Item>{item.brandName}</style.Item>
    </style.Container>
  );
};
export default BrandMapTableItem;
