import { axios as backend } from 'api/axios';

export async function getSmartStoreFormat(itemIds) {
  const { data } = await backend.post('/api/items/smart-store', { itemIds });
  return data;
}

export async function getItemImage(imagesUrl) {
  const dataList = await Promise.all(
    imagesUrl.map((imageUrl) => fetch(imageUrl, { cache: 'no-cache' }))
  );
  const blobs = await Promise.all(dataList.map((res) => res.blob()));

  const images = dataList.map((res, i) => {
    const requestUrl = res.url;
    const splits = requestUrl.split('/');
    const escapedImageUrl = splits[splits.length - 1];
    const imageUrl = decodeURIComponent(escapedImageUrl);
    const data = blobs[i];
    return { data, imageUrl };
  });
  return images;
}
