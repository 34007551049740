import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
export const ResetButton = styled.button`
  border: none;
  margin: 10px;
  background: #000000;
  border-radius: 5px;
  font-family: AppleSDGothicNeoB00;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 10px 30px;
  color: black;

  background: #dddddd;
  border-radius: 5px;
`;
export const SearchButton = styled.button`
  border: none;
  margin: 10px;
  background: #000000;
  border-radius: 5px;
  font-family: AppleSDGothicNeoB00;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 10px 30px;
  color: #ffffff;
`;
