import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import axios from 'api/axios';
import { addExhibitionOptionAction } from 'actions/action';
import * as style from './style';

const ExhibitionOptionBox = (props) => {
  const { exhibition, index } = props;

  const dispatch = useDispatch();

  const [exhibitionList, setExhibitionList] = useState(exhibition);
  const [operation, setOperation] = useState('add');
  const [gender, setGender] = useState('null');
  const [exhibitionID, setExhibitionID] = useState(exhibition[0].exhibition_id);

  useEffect(() => {
    dispatch(
      addExhibitionOptionAction({
        id: index,
        operation,
        gender,
        exhibitionID,
      }),
    );
  }, [operation, gender, exhibitionID, dispatch, index]);

  useEffect(() => {
    let params = {};

    if (gender !== 'null') {
      params = {
        gender,
      };
    } else {
      params = {};
    }

    axios({
      url: '/api/category/exhibition',
      method: 'GET',
      params,
    })
      .then((res) => {
        if (res.data.success) {
          setExhibitionList(res.data.data);
        }
      })
      .catch((err) => console.error(err));
  }, [gender]);

  useEffect(() => {
    setExhibitionID(exhibitionList[0].exhibition_id);
  }, [exhibitionList]);

  return (
    <style.Wrapper>
      <Form.Control
        as="select"
        style={style.Item}
        value={operation}
        onChange={(e) => setOperation(e.target.value)}
      >
        <option value="add">추가</option>
        <option value="delete">삭제</option>
      </Form.Control>
      <Form.Control
        as="select"
        style={style.Item}
        value={gender}
        onChange={(e) => setGender(e.target.value)}
      >
        <option value="null">성별 전체</option>
        <option value="M">남성</option>
        <option value="W">여성</option>
      </Form.Control>
      <Form.Control
        as="select"
        style={style.Item}
        value={exhibitionID}
        onChange={(e) => {
          if (e.target.value === '기획전 세부 컨텐츠') {
            alert('기획전 세부 컨텐츠를 선택하세요');
          } else {
            setExhibitionID(e.target.value);
          }
        }}
      >
        {exhibitionList.map((el, i) => (
          <option key={i} value={el.exhibition_id}>
            {el.name}
          </option>
        ))}
      </Form.Control>
    </style.Wrapper>
  );
};

export default ExhibitionOptionBox;
