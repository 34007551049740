import React from 'react';
import * as style from './style';

const SearchByTd = ({ searchOptionTableState, setSearchOptionTableState }) => {
  return (
    <style.Container>
      <style.Select
        onChange={(e) => {
          const [sort, sortWay] = e.target.value.split(',');
          setSearchOptionTableState({
            ...searchOptionTableState,
            sort: Number(sort),
            sortWay: Number(sortWay),
          });
        }}
        value={searchOptionTableState.sort + ',' + searchOptionTableState.sortWay}
      >
        <option value="99,0">정렬 없음(빠름)</option>
        <option value="8,0">가격 낮은순</option>
        <option value="8,1">가격 높은순</option>
        <option value="7,0">우선순위 높은순</option>
        <option value="13,1">조회수 높은순</option>
        <option value="14,1">좋아요 높은순</option>
        <option value="15,1">장바구니 높은순</option>
        <option value="16,1">판매수 높은순</option>
      </style.Select>
      {[13, 14, 15, 16].includes(searchOptionTableState.sort) && (
        <>
          <style.Date />
          ~
          <style.Date />
          <style.Div
            onChange={(e) => {
              setSearchOptionTableState({
                ...searchOptionTableState,
                eventDuration: e.target.value ? Number(e.target.value) : null,
              });
            }}
          >
            <style.Margin9px />
            <style.Radio
              name="eventDuration"
              value={null}
              checked={searchOptionTableState.eventDuration === null}
            />
            <style.Label>전체</style.Label>
            <style.Radio name="eventDuration" value={1} />
            <style.Label>일간</style.Label>
            <style.Radio name="eventDuration" value={7} />
            <style.Label>주간</style.Label>
            <style.Radio name="eventDuration" value={30} />
            <style.Label>월간</style.Label>
          </style.Div>
        </>
      )}
    </style.Container>
  );
};

export default SearchByTd;
