// @ts-nocheck
import fetchingAPI from 'api/fetchingAPI';
import { DropZoneImage, PickIdModal, Table } from 'components';
import { MakeTable } from 'components/UI/atoms/Table';
import { triggerState } from 'pages/ManageMainContent';
import { Item } from 'pages/ManageMainLayout/utils';
import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as S from '../style';
const dict: any = {
  imageUrl: '이미지',
  title: '타이틀',
  subtitle: '본문 텍스트',
  href: '링크',
  itemList: '상품 목록',
  startAt: '게시 시작 일시',
  expireAt: '게시 마감 일시',
};
type DesignComponentWithImage = {
  // imageUrl: string | null,
  mobileImageUrl: string | null;
  pcImageUrl: string | null;
  title: string | null;
  subtitle: string | null; // 개행은 문자열에 [개행] 포함 (여러 줄 대응) "어쩌구[개행]저쩌구"
  href: string | null;
  itemList?: Item[];
  startAt?: string | null;
  expireAt?: string | null;
  promotionId: number | null;
  exhibitionId: number | null;
};
type Props = {
  mode: 'm' | 'w';
  layout: DesignComponentWithImage;
};

const Category = ({ mode, layout }: Props) => {
  const history = useHistory();
  const layoutIdx = Number(history.location.pathname.split('/').pop());
  const setTrigger = useSetRecoilState(triggerState);

  const [finite, setFinite] = useState(true);
  const [data, setData] = useState<DesignComponentWithImage>({
    mobileImageUrl: '',
    pcImageUrl: '',
    title: '',
    subtitle: '',
    href: '',
    itemList: [],
    startAt: '',
    expireAt: '',
    promotionId: null,
    exhibitionId: null,
  });

  const modals = PickIdModal.usePickIdModal({
    onSubmitExhibition: (value) => {
      setData((p) => ({ ...p, promotionId: null, exhibitionId: value }));
    },
    onSubmitPromotion: (value) => {
      setData((p) => ({ ...p, exhibitionId: null, promotionId: value }));
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const initData = () => {
    const {
      mobileImageUrl,
      pcImageUrl,
      title,
      subtitle,
      href,
      itemList,
      startAt,
      expireAt,
      promotionId,
      exhibitionId,
    } = layout;
    setData({
      mobileImageUrl,
      pcImageUrl,
      title,
      subtitle,
      href,
      itemList,
      startAt,
      expireAt,
      promotionId,
      exhibitionId,
    });
    !startAt && !expireAt ? setFinite(false) : setFinite(true);
  };
  const saveChanges = () => {
    const emptyFields = Object.entries(data)
      .filter(([k, v]) =>
        ((k === 'startAt' || k === 'expireAt') && !finite) ||
        k === 'promotionId' ||
        k === 'exhibitionId'
          ? false
          : v === '' || v === null
      )
      .reduce((acc: string[], [k]) => {
        acc.push(dict[k]);
        return acc;
      }, []);

    if (emptyFields.length) {
      alert(`${emptyFields.join(`\n`)}\n\n위 항목을 입력해주세요!`);
    } else {
      const gender = mode === 'm' ? 'men' : 'women';
      setIsLoading(false);
      fetchingAPI
        .postUIComponents(gender, [
          {
            idx: layoutIdx,
            requestType: 'UPDATE',
            ...data,
          },
        ])
        .then(({ data }) => {
          data?.success && alert('수정에 성공했습니다.');
          setTrigger((p) => p + 1);
        })
        .catch((e) => alert(`err in addCopmonent, ${e}`))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => initData(), []);

  const tables = [
    {
      title: '상품 운영 방식 설정',
      content: [
        {
          left: '상품 운영 방식 설정',
          right: (
            <>
              <Table.Label>
                <Button onClick={() => modals.exhibition.toggle()}>기획전 선택</Button>
                <div style={{ width: '20px' }}></div>
              </Table.Label>
              {!!data.exhibitionId && `/exhibition/${data.exhibitionId}`}
            </>
          ),
        },
        {
          left: '상품 목록',
          right: (
            <>
              {data.exhibitionId && (
                <>
                  <Link to={`/product/modify_exhibition_detail/${data.exhibitionId}`}>
                    기획전 상세 보기
                  </Link>
                  <div style={{ width: '20px' }}></div>
                  <Button
                    onClick={() => {
                      setSyncLoading(true);
                      fetchingAPI
                        .getExhibition(data.exhibitionId)
                        .then(({ data }) => {
                          setData((p) => ({
                            ...p,
                            mobileImageUrl: data.landingImageUrl ?? p.mobileImageUrl,
                            pcImageUrl: data.mainImageUrl ?? p.pcImageUrl,
                          }));
                        })
                        .catch((e) => alert(`err in sync exhibition imgs, ${e.message}`))
                        .finally(() => {
                          setSyncLoading(false);
                        });
                    }}
                  >
                    {syncLoading ? (
                      <Spinner animation="border" />
                    ) : (
                      '기획전에서 이미지 가져오기'
                    )}
                  </Button>
                </>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: '상세 설정',
      content: [
        {
          left: '게시 기간 설정',
          right: (
            <>
              <Table.Label>
                <Table.Radio
                  checked={!finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                영구
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                설정
              </Table.Label>
            </>
          ),
        },
        {
          left: '게시 일시',
          right: (
            <>
              {finite && (
                <>
                  <Table.InputTime
                    value={data.startAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, startAt: value }))
                    }
                  />
                  {' ~ '}
                  <Table.InputTime
                    style={{ marginLeft: '10px' }}
                    value={data.expireAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, expireAt: value }))
                    }
                  />
                </>
              )}
            </>
          ),
        },
        {
          left: '타이틀',
          right: (
            <>
              <Table.TextArea
                placeholder="최대 영문 14바이트 입력 가능합니다."
                value={data.title ?? ''}
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, title: value }))
                }
              />
            </>
          ),
        },
        {
          left: '본문 텍스트',
          right: (
            <>
              <Table.TextArea
                placeholder="최대 한글 30글자 [개행]으로 줄넘김 가능합니다."
                value={data.subtitle ?? ''}
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, subtitle: value }))
                }
              />
            </>
          ),
        },
        {
          left: '모바일 이미지',
          right: (
            <>
              <DropZoneImage
                imageUrl={data.mobileImageUrl}
                setImageUrl={(mobileImageUrl: string) =>
                  setData((p) => ({ ...p, mobileImageUrl }))
                }
              />
            </>
          ),
        },
        {
          left: 'pc 이미지',
          right: (
            <>
              <DropZoneImage
                imageUrl={data.pcImageUrl}
                setImageUrl={(pcImageUrl: string) =>
                  setData((p) => ({ ...p, pcImageUrl }))
                }
              />
            </>
          ),
        },
        {
          left: '링크',
          right: (
            <>
              <Table.TextArea
                value={data.href ?? ''}
                placeholder="/index.html 형식으로 fetching.co.kr를 제외해주세요"
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, href: value }))
                }
              />
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <PickIdModal.Exhibition {...modals.exhibition} />
      <PickIdModal.Promotion {...modals.promotion} />
      <S.ButtonContainer>
        <Button variant="warning" onClick={initData}>
          초기화
        </Button>{' '}
        <Button onClick={saveChanges}>
          {isLoading ? <Spinner animation="border" /> : '저장하기'}
        </Button>
      </S.ButtonContainer>
      {tables.map((table) => (
        <MakeTable data={table} key={table.title} tableRef={null} />
      ))}
    </>
  );
};

export default Category;
