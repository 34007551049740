/* eslint-disable no-prototype-builtins */
import { fetchingAPI } from 'api/axios';
import { goNaverShopping } from 'assets/images';
import { LoadingContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import { ChangeIsImportFlagModal, ChangeIsImportFlagFinModal } from './Modals';
import * as style from './style';

const CatalogTable = ({
  catalogs,
  searchOptionTableState,
  setSearchOptionTableState,
  fetchItems,
  currentPage,
  totalCount,
}) => {
  const [checkedItemIds, setCheckedItemIds] = useState([]);

  const [payload, setPayLoad] = useState({});
  const [colorandNamePayload, setColorandNamePayload] = useState({});
  const initialMoadalsState = {
    SaveFinModal: false,
    DeleteCatalogModal: false,
    DeleteCatalogFinModal: false,
    ChangeIsImportFlagModal: false,
    ChangeIsImportFlagFinModal: false,
  };
  const [modals, setModals] = useState(initialMoadalsState);

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const fetchSaveAll = async () => {
    handleIsLoading(true);
    try {
      const list = Object.values(payload);
      const dataPayload = Object.values(colorandNamePayload);
      if (list.length > 0) {
        await fetchingAPI.post('/catalogue/save-all', { list: list });
      }
      if (dataPayload.length > 0) {
        const { data } = await fetchingAPI.put('/item', dataPayload);
        if (data.failed.length) {
          alert(`${data.failed.length}개의 항목에 적용 실패했습니다!..`);
        }
      }
      fetchItems(currentPage);
    } catch (err) {
      alert(`err in fetchsaveall in cataalog table, ${err.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  const fetchDeleteCatalog = () => {
    handleIsLoading(true);
    fetchingAPI
      .delete('/catalogue', {
        params: { idxes: checkedItemIds.map((id) => String(id)) },
      })
      .then(() => {
        handleIsLoading(false);
      })
      .then(() => {
        fetchItems(currentPage);
      })
      .catch((err) => {
        alert(`err in fetchdeletecatalog in catalog table ${err.message}`);
        handleIsLoading(false);
      });
  };

  const fetchImprotFlag = (is_impmort_flag) => {
    handleIsLoading(true);
    fetchingAPI
      .put('/catalogue/import-flag', {
        idxes: checkedItemIds.map((id) => String(id)),
        is_impmort_flag,
      })
      .then(() => {
        handleIsLoading(false);
      })
      .then(() => {
        fetchItems(currentPage);
      })
      .catch((err) => {
        alert(`err in fetchImprotFlag in Catalogtable ${err.message}`);
        handleIsLoading(false);
      });
  };

  useEffect(() => {
    setCheckedItemIds([]);
    setPayLoad({});
    setColorandNamePayload({});
  }, [catalogs]);

  return (
    <>
      {/* <SaveFinModal show={modals.SaveFinModal} handleClose={() => {
                setModals(initialMoadalsState);
            }} />
            <DeleteCatalogModal show={modals.DeleteCatalogModal} />
            <DeleteCatalogFinModal show={modals.DeleteCatalogFinModal} />*/}
      <ChangeIsImportFlagModal
        show={modals.ChangeIsImportFlagModal}
        fetchImprotFlag={fetchImprotFlag}
        setModals={setModals}
        handleClose={() => {
          setModals(initialMoadalsState);
        }}
        checkedItemIdsLength={checkedItemIds.length}
      />
      <ChangeIsImportFlagFinModal
        show={modals.ChangeIsImportFlagFinModal}
        handleClose={() => {
          setModals(initialMoadalsState);
        }}
      />
      <span>총 개수 : {totalCount}</span>
      <style.Table>
        <style.Tr style={{ background: '#BEBEBE', padding: '13px 22px' }}>
          <div />
          <div>
            <style.Button
              onClick={() => {
                const payloadsKeySet = new Set(Object.keys(payload));
                Object.keys(colorandNamePayload).forEach((v) => payloadsKeySet.add(v));
                if (![...payloadsKeySet.keys()].length > 0) {
                  alert('변경된 항목이 없습니다.');
                } else if (
                  window.confirm(
                    `${[...payloadsKeySet.keys()].length}개 항목을 일괄저장 할까요?`
                  )
                ) {
                  fetchSaveAll();
                }
              }}
              style={{ background: '#0D9AE9' }}
            >
              일괄저장
            </style.Button>
            <style.Button
              onClick={() => {
                if (checkedItemIds.length === 0) {
                  alert('1개 이상의 아이템을 선택하세요.');
                } else if (
                  window.confirm(`선택한 ${checkedItemIds.length}개의 항목을 삭제할까요?`)
                ) {
                  fetchDeleteCatalog();
                }
              }}
              style={{ background: '#FF6F61' }}
            >
              카탈로그 삭제
            </style.Button>
            <style.Button
              onClick={() => {
                setModals({
                  ...modals,
                  ChangeIsImportFlagModal: true,
                });
              }}
              style={{ background: '#498935' }}
            >
              배송유형 변경
            </style.Button>
            <style.Input
              onChange={(e) => {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  limit: e.target.value,
                });
              }}
            >
              {[100, 200, 300, 500, 1000, 3000, 5000].map((num) => (
                <option key={num} value={num}>
                  {num}개씩 보기
                </option>
              ))}
            </style.Input>
          </div>
        </style.Tr>
        <style.Tr style={{ background: '#E4E4E4' }}>
          <style.flexibleTd
            widthPcnt={38}
            onClick={() => {
              if (checkedItemIds.length === catalogs.length) {
                setCheckedItemIds([]);
              } else {
                setCheckedItemIds(catalogs.map((obj) => obj.id));
              }
            }}
          >
            <style.Checkbox
              onChange={() => {
                if (checkedItemIds.length === catalogs.length) {
                  setCheckedItemIds([]);
                } else {
                  setCheckedItemIds(catalogs.map((obj) => obj.id));
                }
              }}
              checked={checkedItemIds.length === catalogs.length}
            />
          </style.flexibleTd>
          <style.flexibleTd widthPcnt={135}>페칭코드</style.flexibleTd>
          <style.flexibleTd widthPcnt={145}>카페24코드</style.flexibleTd>
          <style.flexibleTd widthPcnt={198}>이미지</style.flexibleTd>
          <style.flexibleTd widthPcnt={109}>브랜드</style.flexibleTd>
          <style.flexibleTd widthPcnt={170}>상품명</style.flexibleTd>
          {/* <style.flexibleTd widthPcnt={49}>재고</style.flexibleTd> */}
          <style.flexibleTd widthPcnt={70}>활성상태</style.flexibleTd>
          <style.flexibleTd widthPcnt={95}>페칭 판매가</style.flexibleTd>
          <style.flexibleTd widthPcnt={193}>카탈로그 ID</style.flexibleTd>
          <style.flexibleTd widthPcnt={120}>배송유형</style.flexibleTd>
          {/* <style.flexibleTd widthPcnt={82}>카페24 판매가능 여부</style.flexibleTd> */}
          <style.flexibleTd widthPcnt={63}>우선순위</style.flexibleTd>
          <style.flexibleTd widthPcnt={53}>조회수</style.flexibleTd>
          <style.flexibleTd widthPcnt={50}>좋아요</style.flexibleTd>
          <style.flexibleTd widthPcnt={61}>장바구니</style.flexibleTd>
          <style.flexibleTd widthPcnt={52} noBorder>
            판매수
          </style.flexibleTd>
        </style.Tr>
        {catalogs.map((catalog) => {
          let {
            id,
            cafe24Id,
            imageUrl,
            brand,
            name,
            customColor: color,
            isVerified,
            isInStock,
            finalPrice,
            naverProductId, //캍ㄹ로그ID
            isImportFlag, //true면 국내배송
            isCafe24Active,
            cafe24Updateable, //카페24 판매가능
            priority, //=우선순위.
            viewCount, //=조회수.
            likeCount, //=좋아요.
            cartCount, //=장바구니.
            salesCount, //=판매수
          } = catalog;

          isCafe24Active = Number(isCafe24Active);
          isImportFlag = Number(isImportFlag);
          isInStock = Number(isInStock);

          const cafe24Link = 'https://fetching.co.kr/product/detail.html?product_no=';
          const naverLink = 'https://search.shopping.naver.com/catalog/';
          const productDetailLink =
            'https://admin.fetching.work/product/productDetail/v2/';
          let fullyDiscountedFinalPrice = finalPrice * 0.97;
          if (fullyDiscountedFinalPrice > 300000) {
            fullyDiscountedFinalPrice = fullyDiscountedFinalPrice - 10000;
          }

          return (
            <style.Tr key={id}>
              <style.flexibleTd
                widthPcnt={38}
                onClick={() => {
                  const checkedItemIdsSet = new Set(checkedItemIds);
                  if (checkedItemIdsSet.has(id)) {
                    checkedItemIdsSet.delete(id);
                    setCheckedItemIds([...checkedItemIdsSet.values()]);
                  } else {
                    checkedItemIdsSet.add(id);
                    setCheckedItemIds([...checkedItemIdsSet.values()]);
                  }
                }}
              >
                <style.Checkbox
                  onChange={() => {
                    const checkedItemIdsSet = new Set(checkedItemIds);
                    if (checkedItemIdsSet.has(id)) {
                      checkedItemIdsSet.delete(id);
                      setCheckedItemIds([...checkedItemIdsSet.values()]);
                    } else {
                      checkedItemIdsSet.add(id);
                      setCheckedItemIds([...checkedItemIdsSet.values()]);
                    }
                  }}
                  checked={checkedItemIds.includes(id)}
                />
              </style.flexibleTd>
              <style.flexibleTd widthPcnt={135}>{id}</style.flexibleTd>
              <style.flexibleTd widthPcnt={145}>
                {cafe24Id}
                {cafe24Id && isCafe24Active && (
                  <a href={cafe24Link + cafe24Id} target="_blank" rel="noreferrer">
                    카페24가기
                  </a>
                )}
              </style.flexibleTd>
              <style.flexibleTd widthPcnt={198}>
                <style.Img src={imageUrl} />
              </style.flexibleTd>
              <style.flexibleTd widthPcnt={109}>{brand}</style.flexibleTd>
              <style.flexibleTd widthPcnt={170}>
                <textarea
                  style={{ width: '90%' }}
                  value={colorandNamePayload[id] ? colorandNamePayload[id].name : name}
                  onChange={(e) =>
                    onChangeItemInfo(e, id, 'name', setColorandNamePayload)
                  }
                />
                <input
                  style={{ width: '90%' }}
                  value={colorandNamePayload[id] ? colorandNamePayload[id].color : color}
                  onChange={(e) =>
                    onChangeItemInfo(e, id, 'color', setColorandNamePayload)
                  }
                  placeholder="컬러명"
                />
                <a href={productDetailLink + id} target="_blank" rel="noreferrer">
                  상품상세
                </a>
              </style.flexibleTd>
              {/* <style.flexibleTd widthPcnt={49} style={{ color: `${isInStock ? '#498935' : '#FF0707'}` }}>{isInStock ? '보유' : '품절'}</style.flexibleTd> */}
              <style.flexibleTd
                widthPcnt={70}
                style={{ color: `${isVerified ? '#498935' : '#FF0707'}` }}
              >
                {isVerified ? '활성화' : '비활성화'}
              </style.flexibleTd>
              <style.flexibleTd widthPcnt={95}>
                <span>{finalPrice.toLocaleString('ko-KR')}</span>
                <span style={{ color: '#FF6F61' }}>
                  {fullyDiscountedFinalPrice.toLocaleString('ko-KR')}
                </span>
              </style.flexibleTd>

              <style.flexibleTd widthPcnt={193}>
                <style.CatalogIdContainer>
                  <style.CatalogIdInput
                    onChange={(e) => {
                      // const changedSet = new Set(naverProductIdChangedItems);
                      // changedSet.add(id);
                      // setNaverProductIdChangedItems([...changedSet.values()]);
                      setPayLoad({
                        ...payload,
                        [id]: {
                          ...payload[id],
                          idx: Number(id),
                          naver_product_id: String(e.target.value),
                        },
                      });
                    }}
                    value={
                      payload.hasOwnProperty(id) &&
                      payload[id].hasOwnProperty('naver_product_id')
                        ? payload[id].naver_product_id
                        : naverProductId
                        ? naverProductId
                        : ''
                    }
                  />
                  {/* <style.SaveCatalogId
                                            src={saveCatalogId}
                                            show={naverProductIdChangedItems.includes(id)}
                                            onClick={() => {
                                                const changedSet = new Set(naverProductIdChangedItems);
                                                changedSet.delete(id);
                                                setNaverProductIdChangedItems([...changedSet.values()]);
                                            }} /> */}
                  <style.GoNaverShopping
                    href={naverLink + naverProductId}
                    target="_blank"
                    show={naverProductId}
                  >
                    <img style={{ width: '100%' }} src={goNaverShopping} />
                  </style.GoNaverShopping>
                </style.CatalogIdContainer>
              </style.flexibleTd>

              <style.flexibleTd widthPcnt={120}>
                <style.SelcetDelivery
                  onChange={(e) => {
                    // changeIsImportFlag(id, Boolean(e.target.value));
                    setPayLoad({
                      ...payload,
                      [id]: {
                        ...payload[id],
                        idx: id,
                        is_import_flag: Boolean(e.target.value),
                      },
                    });
                  }}
                >
                  <option selected={isImportFlag} value="true">
                    국내배송
                  </option>
                  <option selected={!isImportFlag} value="">
                    해외배송
                  </option>
                </style.SelcetDelivery>
              </style.flexibleTd>
              {/* <style.flexibleTd widthPcnt={82}>
                                    <span style={{ color: `${isCafe24Active ? '#498935' : '#FF0707'}` }}>{isCafe24Active ? '노출' : '미노출'}</span>
                                    <span style={{ color: `${cafe24Updateable ? '#498935' : '#FF0707'}` }}>{cafe24Updateable ? '판매가능상품' : '판매불가상품'}</span>
                                </style.flexibleTd> */}
              <style.flexibleTd widthPcnt={63}>{priority}</style.flexibleTd>
              <style.flexibleTd widthPcnt={53}>{viewCount}</style.flexibleTd>
              <style.flexibleTd widthPcnt={50}>{likeCount}</style.flexibleTd>
              <style.flexibleTd widthPcnt={61}>{cartCount}</style.flexibleTd>
              <style.flexibleTd widthPcnt={52} noBorder>
                {salesCount}
              </style.flexibleTd>
            </style.Tr>
          );
        })}
      </style.Table>
    </>
  );
};

export default CatalogTable;

////////상품명, 컬러 변경 이벤트
const onChangeItemInfo = (e, id, target, setPayload) => {
  const { value } = e.target;

  setPayload((prev) => {
    if (!(id.toString() in prev)) {
      prev[id] = { id: id };
    }
    prev[id][target] = value;
    return { ...prev };
  });
};
////////
