import styled from 'styled-components';

export const Description = styled.p`
  text-align: center;
`;

export const TextInput = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  padding: 15px 20px;
  &:focus {
    outline: none;
  }
`;

export const LoadOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
