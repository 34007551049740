import React, { useState } from 'react';
import * as style from './style';

const ModifyActionBar = ({ onModify }) => {
  const [modifyOption, setModifyOption] = useState({
    items: 'checked',
    action: 'isActive',
  });

  const handleModify = (field, value) => {
    setModifyOption({
      ...modifyOption,
      [field]: value,
    });
  };

  return (
    <div>
      <style.Select onChange={(e) => handleModify('items', e.target.value)}>
        <option value="checked">선택한 프로모션의</option>
        <option value="entire">검색결과 전체 프로모션의</option>
      </style.Select>
      <style.Select onChange={(e) => handleModify('action', e.target.value)}>
        <option value="isActive">활성상태를</option>
        <option value="isPrioritized">중요도 상태를</option>
        <option value="assigneeId">담당자를</option>
        <option value="applyStatus">업데이트 현황을</option>
      </style.Select>
      <style.ManualButton
        style={{ background: 'black' }}
        onClick={() => onModify(modifyOption)}
      >
        일괄변경
      </style.ManualButton>
    </div>
  );
};

const EtcActionBar = ({ onDeletePromotion, setSortOption, sortOption }) => {
  return (
    <div>
      <a href="/product/addPromotion" target="_blank">
        <style.ManualButton style={{ background: '#0D9AE9' }}>
          프로모션 추가
        </style.ManualButton>
      </a>
      <style.ManualButton style={{ background: '#FF6F61' }} onClick={onDeletePromotion}>
        삭제
      </style.ManualButton>
      <style.Select onChange={(e) => setSortOption(e.target.value)} value={sortOption}>
        <option value="shopName,0"> 편집샵 이름순</option>
        {/* <option value="adminName,0">할인율 높은순</option> */}
        <option value="assignee,0">담당자순</option>
        <option value="statusUpdatedAt,1">업데이트 최신순</option>
        <option value="verifiedItemCount,1">상품수 많은순</option>
        {/* <option value=','>거래액 높은순</option> */}
        <option value="view,1">조회수 높은순</option>
        <option value="like,1">좋아요 높은순</option>
        <option value="cart,1">장바구니 높은순</option>
        <option value="sales,1">판매수 높은순</option>
      </style.Select>
    </div>
  );
};

const TableActionBar = ({ onDeletePromotion, setSortOption, sortOption, onModify }) => {
  return (
    <>
      <ModifyActionBar onModify={onModify} />
      <EtcActionBar
        onDeletePromotion={onDeletePromotion}
        sortOption={sortOption}
        setSortOption={setSortOption}
      />
    </>
  );
};

export default TableActionBar;
