import React, { useRef } from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { useObject, useOnClickOutside } from 'services';

export interface Props {
  position: 'left' | 'right' | 'top' | 'bottom';
  content: ReactNode;
  theme?: 'dark' | 'light';
  onOpen: (isOpen: boolean) => void;
  border?: boolean;
}
interface State {
  isOpen: boolean;
}

const Popover: FunctionComponent<Props> = ({
  children,
  position,
  theme = 'light',
  content,
  onOpen,
  border = true,
}) => {
  const [{ isOpen }, setState] = useObject<State>({ isOpen: false });
  const ref = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(ref, () => setState({ isOpen: false }), 'popover');
  return (
    <div className="relative group inline-block" ref={ref}>
      <div
        className={classnames(
          'absolute bg-white rounded z-20',
          { 'top-[calc(100%+10px)]': position === 'bottom' },
          { 'left-[calc(100%+10px)] -bottom-1/2': position === 'right' },
          { 'right-[calc(100%+10px)]': position === 'left' },
          { 'bottom-[calc(100%+10px)]': position === 'top' },
          { 'bg-white': theme === 'light' },
          { 'border border-gray-400': border },
          { 'bg-black text-white': theme === 'dark' },
          isOpen ? 'block' : 'hidden'
        )}
      >
        {content}
      </div>
      <div
        id="popover"
        className="cursor-pointer"
        onClick={() => setState({ isOpen: !isOpen }, (state) => onOpen(state.isOpen))}
      >
        {children}
      </div>
    </div>
  );
};

export default Popover;
