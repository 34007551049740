import React, { useState, useRef, useEffect } from 'react';
import * as style from './style';
import { Item } from 'components';

const BrandNameInfo = ({
  modifyBrand,
  setModifyBrand,
  handleSemiNameDelete,
  handleAddSemiName,
  handleClinentHeight,
}) => {
  const [semiBrandName, setSemiBrandName] = useState('');

  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('브랜드명', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('브랜드명', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>브랜드명</style.Title>
      <style.TableWrapper>
        <style.TableTitle>브랜드 기본명</style.TableTitle>
        <style.TableValue>
          <style.Input
            value={modifyBrand.name}
            onChange={(e) => {
              setModifyBrand({
                ...modifyBrand,
                name: e.target.value,
              });
            }}
          />
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle>브랜드 영문명</style.TableTitle>
        <style.TableValue>
          <style.Input
            value={modifyBrand.enName}
            onChange={(e) => {
              setModifyBrand({
                ...modifyBrand,
                enName: e.target.value.toLocaleUpperCase(),
              });
            }}
          />{' '}
          (영어 대문자로만 작성해주세요.)
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle>브랜드 한글명</style.TableTitle>
        <style.TableValue>
          <style.Input
            value={modifyBrand.krName}
            onChange={(e) => {
              setModifyBrand({
                ...modifyBrand,
                krName: e.target.value,
              });
            }}
          />{' '}
          (영어 발음 그대로 한국어로 풀어서 써주세요.)
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle>브랜드 검색명</style.TableTitle>
        <style.TableValue>
          <style.Input
            value={semiBrandName}
            onChange={(e) => {
              setSemiBrandName(e.target.value);
            }}
          />{' '}
          <style.Button
            BC={'#ff6f61e0'}
            onClick={() => {
              handleAddSemiName(semiBrandName);
              setSemiBrandName('');
            }}
          >
            입력
          </style.Button>
          {modifyBrand?.semiName.map((el) => {
            return (
              <Item
                key={el.idx}
                id={el.idx}
                name={el.semi_name}
                handleDelete={handleSemiNameDelete}
              />
            );
          })}
        </style.TableValue>
      </style.TableWrapper>
    </style.Wrapper>
  );
};
export default BrandNameInfo;
