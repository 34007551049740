import React from 'react';
import type { FunctionComponent } from 'react';
import { Button, Tooltip } from 'components';
import dayjs from 'dayjs';

export interface Props
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  isLoading: boolean;
  list: any[];
  fetchingOrderNumber: string;
  hasMemo?: boolean;
}
interface State {}

const OrderListTooltip: FunctionComponent<Props> = ({
  isLoading,
  list,
  fetchingOrderNumber,
  hasMemo = true,
  ...props
}) => {
  if (!hasMemo)
    return (
      <Button
        size="xs"
        className="w-full"
        theme="secondary"
        onClick={(e) => {
          e.stopPropagation();
          window.open(`/order/${fetchingOrderNumber}?tab=8`, '_blank');
        }}
      >
        메모
      </Button>
    );
  return (
    <Tooltip
      {...props}
      padding={false}
      content={
        <div>
          <table>
            <thead className="bg-gray-100 text-gray-500">
              <tr>
                <th>작성일 (수정일)</th>
                <th>작성자</th>
                <th>내용</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={3}>
                    <div>불러오는 중...</div>
                  </td>
                </tr>
              ) : list.length ? (
                list.map((memo, memoIndex) => (
                  <tr key={memoIndex}>
                    <td>
                      <div>{dayjs(memo.createdAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
                      {!!memo.updatedAt && (
                        <div className="text-gray-300">
                          ({dayjs(memo.updatedAt).format('YYYY.MM.DD. HH:MM:SS')})
                        </div>
                      )}
                    </td>
                    <td>{memo.adminName}</td>
                    <td>
                      {memo.extraStatus === 'ORDER_CANCEL' && '[주문 취소] '}
                      {memo.content}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3}>메모 목록이 없습니다.</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      }
      position="left"
      className="w-full"
    >
      <Button
        size="xs"
        className="w-full"
        theme="primary"
        onClick={(e) => {
          e.stopPropagation();
          window.open(`/order/${fetchingOrderNumber}?tab=8`, '_blank');
        }}
      >
        메모
      </Button>
    </Tooltip>
  );
};

export default OrderListTooltip;
