import React from 'react';
import * as style from './style';

const PriceByTd = ({ searchOptionTableState, setSearchOptionTableState }) => {
  const { priceMode } = searchOptionTableState;

  const priceModes = {
    null: ['o', 'loooooooooo'],
    basicPrice: ['minDiscountedPrice', 'maxDiscountedPrice'],
    shopPrice: ['minTotalPrice', 'maxTotalPrice'],
    fetchingPrice: ['minFinalPrice', 'maxFinalPrice'],
  };

  return (
    <style.Container>
      <style.Select
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            priceMode: e.target.value,
            minDiscountedPrice: 0,
            maxDiscountedPrice: 10000000000,
            //
            minTotalPrice: 0,
            maxTotalPrice: 10000000000,
            //
            minFinalPrice: 0,
            maxFinalPrice: 10000000000,
          });
        }}
        value={searchOptionTableState.priceMode}
      >
        <option value="null" >가격 기준을 선택하세요</option>
        <option value="basicPrice">상품 기본가</option>
        <option value="shopPrice">편집샵 판매가</option>
        <option value="fetchingPrice">페칭 판매가</option>
      </style.Select>
      <style.Input
        disabled={priceMode === 'null'}
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            [priceModes[priceMode][0]]: Number(e.target.value),
          });
        }}
        value={searchOptionTableState[priceModes[priceMode][0]]}
      />
      ~
      <style.Input
        disabled={priceMode === 'null'}
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            [priceModes[priceMode][1]]: Number(e.target.value),
          });
        }}
        value={searchOptionTableState[priceModes[priceMode][1]]}
      />
    </style.Container>
  );
};

export default PriceByTd;
