import React, { useEffect, useState } from 'react';
import * as S from './style';
import { Button, Modal } from 'react-bootstrap';
import { getUserList } from 'api/axios';
import fetchingAPI from 'api/fetchingAPI';

export const ChangeGender = ({ show, onHide, items }) => {
  const [value, setValue] = useState(null);

  useEffect(() => setValue(null), [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>상품 그룹 성별 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{items.length}개 상품 그룹의 성별를 변경합니다.</h5>
        {items.map(({ gender, name }) => (
          <p key={name}>
            {gender} {name}
          </p>
        ))}
        <S.Label>
          <S.Radio checked={value === 'MW'} onChange={() => setValue('MW')} />
          전체
        </S.Label>
        <S.Label>
          <S.Radio checked={value === 'M'} onChange={() => setValue('M')} />
          남성
        </S.Label>
        <S.Label>
          <S.Radio checked={value === 'W'} onChange={() => setValue('W')} />
          여성
        </S.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            value === null
              ? alert('성별을 선택하세요')
              : fetchProductGroupInfo(
                  modifyProductGroupInfo(items, 'gender', value),
                ).then(() => onHide());
          }}
        >
          변경하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ChangeAssignee = ({ show, onHide, items }) => {
  const [value, setValue] = useState(null);

  const [adminNames, setAdminNames] = useState([]);

  const fetchAdminNames = async () => {
    const data = await getUserList();
    setAdminNames(data);
  };

  useEffect(() => {
    fetchAdminNames();
  }, []);

  useEffect(() => setValue(null), [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>상품 그룹 담당자 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{items.length}개 상품 그룹의 담당자를 변경합니다.</h5>
        {items.map(({ gender, name }) => (
          <p key={name}>
            {gender} {name}
          </p>
        ))}
        <select
          value={value || 'none'}
          onChange={(e) =>
            setValue(e.target.value !== 'none' ? e.target.value : null)
          }
        >
          <option value={'none'}>---</option>
          {adminNames.map(({ id, admin_name }) => (
            <option value={id} key={id}>
              {admin_name}
            </option>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            value === null
              ? alert('담당자를 선택하세요')
              : fetchProductGroupInfo(
                  modifyProductGroupInfo(items, 'assignee', Number(value)),
                ).then(() => onHide());
          }}
        >
          변경하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const modifyProductGroupInfo = (items = [], target = '', value = 0) => {
  items = deleteProps(items);
  return items.map((item) => {
    item[target] = value;
    return item;
  });
};
const deleteProps = (items = []) => {
  return items.map((item) => {
    delete item.assignee;
    delete item.count;
    delete item.gender;
    delete item.isAutomated;
    delete item.updatedAt;
    return item;
  });
};
const fetchProductGroupInfo = async (ModifiedItems) => {
  try {
    await fetchingAPI.putProductGroupsInfo(ModifiedItems);
  } catch (error) {
    alert(
      `err in fetchProductGroupInfo(), in <ChangeActivity/>,${error.message}`,
    );
  }
};
