import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  @media all and (min-width: 1100px) {
    flex-direction: row;
  }
`;
