import {
  Button,
  Card,
  GridTable,
  MenuTitle,
  ReInput,
  RePagination,
  Select,
  Tabs,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { dayjsFormat, request, useObject, USER_TYPE } from 'services';
import classnames from 'classnames';

interface IPoint {
  createdAt: string;
  userId: number;
  userName: string;
  userEnglishFirstName: string;
  userEnglishLastName: string;
  typeName: string;
  typeDescription: string;
  userType: 'USER' | 'VIP';
  idx: number;
  pointAdd: number;
  pointDeduct: number;
  pointRemain: string;
  auId: {
    KAKAO: string;
    APPLE: string;
    NAVER: string;
  };
  orderId: string;
  userStringId: string;
  adminId: number;
  expireAt: string;
}
interface IPointType {
  name: string;
  type: string;
}

interface State {
  tab: number;
  list: IPoint[];
  page: number;
  pageSize: number;
  userType: string;
  id: string;
  minDate: string;
  maxDate: string;
  total: number;
  pointType: string;
  pointTypeList: IPointType[];
  availablePointAdd: string;
  pointDeduct: string;
  pointTotal: string;
  unavailablePointAdd: string;
  isLoading: boolean;
}

const Td = ({ children }) => (
  <td className="py-2 px-0 text-center" style={{ borderRight: '1px solid #b4b4b4' }}>
    {children}
  </td>
);

const UsersReservePage = () => {
  const [
    {
      tab,
      list,
      page,
      pageSize,
      userType,
      id,
      maxDate,
      minDate,
      total,
      pointType,
      pointTypeList,
      availablePointAdd,
      unavailablePointAdd,
      pointDeduct,
      pointTotal,
      isLoading,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    tab: 0,
    list: [],
    page: 1,
    pageSize: 20,
    userType: '',
    id: '',
    minDate: dayjsFormat(dayjs().add(-7, 'day')),
    maxDate: dayjsFormat(dayjs()),
    total: 0,
    pointType: '',
    pointTypeList: [],
    availablePointAdd: '',
    unavailablePointAdd: '',
    pointDeduct: '',
    pointTotal: '',
    isLoading: false,
  });
  const get = async () => {
    setState({ isLoading: true });
    let params: any = {
      available: !tab,
      limit: pageSize,
      page,
      minDate: `${dayjs(minDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
      maxDate: `${dayjs(maxDate).format('YYYY-MM-DDT23:59:59')}.000Z`,
    };
    if (!!userType) params.userType = userType;
    if (!!id) params.id = id;
    if (!!pointType) params.pointType = pointType;
    try {
      const { data, count } = await request.get<any, { count: number; data: IPoint[] }>(
        '/commerce/point',
        { params }
      );
      setState({ list: data, total: count });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isLoading: false });
    }
  };
  const getStatistic = async () => {
    try {
      const { available, unavailable } = await request.get<
        any,
        {
          available: { pointAdd: string; pointDeduct: string; pointTotal: string };
          unavailable: { pointAdd: string };
        }
      >('/commerce/point/statistic', {
        params: {
          id,
          maxDate: `${dayjs(maxDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
          minDate: `${dayjs(minDate).format('YYYY-MM-DDT23:59:59')}.000Z`,
          pointType,
          userType: userType || null,
        },
      });
      setState({
        availablePointAdd: available.pointAdd || '',
        pointDeduct: available.pointDeduct || '',
        pointTotal: available.pointTotal || '',
        unavailablePointAdd: unavailable.pointAdd || '',
      });
    } catch (err) {
      console.dir(err);
    }
  };
  const getType = async () => {
    try {
      const { data } = await request.get<any, { data: IPointType[] }>(
        '/commerce/point/type'
      );
      setState({ pointTypeList: data });
    } catch (err) {
      console.dir(err);
    }
  };
  const onDateClick = (date: string) => {
    let newMinDate = '';
    if (date === '오늘') newMinDate = dayjsFormat(dayjs());
    else if (date === '3일') newMinDate = dayjsFormat(dayjs().add(-3, 'day'));
    else if (date === '7일') newMinDate = dayjsFormat(dayjs().add(-7, 'day'));
    else if (date === '1개월') newMinDate = dayjsFormat(dayjs().add(-1, 'month'));
    else if (date === '3개월') newMinDate = dayjsFormat(dayjs().add(-3, 'month'));
    else if (date === '1년') newMinDate = dayjsFormat(dayjs().add(-1, 'year'));

    setState({ minDate: newMinDate, maxDate: dayjsFormat(dayjs()) });
  };
  const columns: string[] = useMemo(() => {
    if (tab === 0)
      return [
        '일자',
        '이름(영문)',
        '아이디',
        '등급',
        '가용 적립금 증가',
        '가용 적립금 차감',
        '가용 적립금 잔액',
        '관련주문',
        '추천인',
        '처리자',
        '적립금 내용',
      ];
    else if (tab === 1)
      return [
        '일자',
        '이름(영문)',
        '아이디',
        '등급',
        '미가용 적립금',
        '사용가능예정일',
        '관련주문',
        '적립금 내용',
      ];
    else return [];
  }, [tab]);

  useEffect(() => {
    get();
  }, [page, pageSize, userType, minDate, maxDate, pointType]);
  useEffect(() => {
    getStatistic();
  }, [minDate, maxDate, pointType, userType]);
  useEffect(() => {
    getType();
  }, []);
  return (
    <>
      <MenuTitle title="회원 적립금 관리" />
      <div className="wrapper">
        <div className="wrapper-title">회원 적립금 내역</div>
        <Card
          items={[
            {
              name: '등급',
              renderItem: () => (
                <Select
                  size="xs"
                  value={userType}
                  className="w-20"
                  name="userType"
                  onChange={onChange}
                >
                  <option value="">전체</option>
                  <option value="USER">일반</option>
                  <option value="VIP">VIP</option>
                </Select>
              ),
              stretch: true,
            },
            {
              name: '아이디',
              renderItem: () => (
                <ReInput
                  size="xs"
                  className="w-40"
                  value={id}
                  name="id"
                  onChange={onChange}
                  onEnter={get}
                />
              ),
              stretch: true,
            },
            {
              name: '기간',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {['오늘', '3일', '7일', '1개월', '3개월', '1년'].map((item, key) => (
                    <Button
                      theme="ghost"
                      size="xs"
                      onClick={() => onDateClick(item)}
                      key={key}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={minDate}
                    name="minDate"
                    onChange={onChange}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={maxDate}
                    name="maxDate"
                    onChange={onChange}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '적립금 타입',
              renderItem: () => (
                <Select
                  className="w-56"
                  size="xs"
                  value={pointType}
                  name="pointType"
                  onChange={onChange}
                >
                  <option value="" selected disabled>
                    적립금 타입
                  </option>
                  {pointTypeList.map((item, key) => (
                    <option key={key} value={item.type}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-5 flex justify-center gap-3">
          <Button isLoading={isLoading} onClick={get}>
            검색
          </Button>
          <Button
            theme="secondary"
            onClick={() =>
              resetState([
                'id',
                'maxDate',
                'minDate',
                'page',
                'pageSize',
                'tab',
                'userType',
                'pointType',
              ])
            }
          >
            초기화
          </Button>
        </div>
      </div>

      <div className="wrapper">
        <div className="text-2xl font-bold">조회 기간 내 적립금 내역 통계</div>
        <table className="w-full mt-7" style={{ border: '1px solid #b4b4b4' }}>
          <thead style={{ background: '#f0f0f0' }}>
            <tr>
              <td
                colSpan={3}
                className="w-3/4 text-center py-2"
                style={{ borderRight: '1px solid #b4b4b4' }}
              >
                가용 적립금
              </td>
              <td className="py-2 text-center">미가용 적립금</td>
            </tr>
          </thead>
          <tbody>
            <tr style={{ borderTop: '1px solid #b4b4b4' }}>
              <Td>증가</Td>
              <Td>차감</Td>
              <Td>합계</Td>
              <Td>증가</Td>
            </tr>
            <tr style={{ borderTop: '1px solid #b4b4b4' }}>
              <Td>{availablePointAdd || 0}</Td>
              <Td>{pointDeduct || 0}</Td>
              <Td>{pointTotal || 0}</Td>
              <Td>{unavailablePointAdd || 0}</Td>
            </tr>
          </tbody>
        </table>
        <div className="text-2xl font-bold mt-14">회원 적립금 내역</div>
        <div className="mt-7 mb-3">
          <Tabs
            value={tab}
            onTabChange={(tab) => setState({ tab, pageSize: 20, page: 1, pointType: '' })}
            options={['가용 적립금', '미가용 적립금']}
          />
        </div>
        <GridTable
          columns={columns}
          list={list}
          header={
            <div
              className={classnames(
                'flex items-center',
                tab === 0 ? 'justify-between' : 'justify-end'
              )}
            >
              {tab === 0 && (
                <Button
                  size="sm"
                  onClick={() => toast.info('현재 버전에서 지원하지 않는 기능입니다.')}
                >
                  {'적립금 일괄처리 >'}
                </Button>
              )}
              <Select size="sm" value={pageSize} name="pageSize" onChange={onChange}>
                <option value={10}>10명씩 보기</option>
                <option value={20}>20명씩 보기</option>
              </Select>
            </div>
          }
          noSelection
          renderItem={(item, key) => (
            <div key={key}>
              <div>{dayjs(item.createdAt).format('YYYY. MM. DD. HH:mm:ss')}</div>
              <div>
                <div
                  onClick={() => window.open(`/user/${item.userId}`, '_blank')}
                  className="link"
                >
                  {item.userName}
                </div>
                <div>({item.userEnglishFirstName + item.userEnglishLastName})</div>
              </div>
              <div>
                <div className="link">{item.userStringId}</div>
                <div className="flex gap-1 text-xs">
                  <span
                    className={!!item.auId.KAKAO ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    KAKAO
                  </span>
                  <span
                    className={!!item.auId.NAVER ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    NAVER
                  </span>
                  <span
                    className={!!item.auId.APPLE ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    APPLE
                  </span>
                </div>
              </div>
              <div>{USER_TYPE[item.userType]}</div>
              {tab === 0 && (
                <>
                  <div>{!!item.pointAdd && item.pointAdd}</div>
                  <div>{!!item.pointDeduct && item.pointDeduct}</div>
                  <div>{item.pointRemain}</div>
                  <div>
                    <span className="link">{item.orderId}</span>
                  </div>
                  <div>추천인?</div>
                  <div>{item.adminId}</div>
                  <div>{item.typeName}</div>
                </>
              )}
              {tab === 1 && (
                <>
                  <div>{item.pointRemain}</div>
                  <div>
                    {!!item.expireAt &&
                      dayjs(item.expireAt).format('YYYY. MM. DD HH:mm:ss')}
                  </div>
                  <div>
                    <span className="link">{item.orderId}</span>
                  </div>
                  <div>{item.typeName}</div>
                </>
              )}
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={total}
            pageSize={pageSize}
          />
        </div>
      </div>
    </>
  );
};

export default UsersReservePage;
