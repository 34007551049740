import { Alert, Button, InputNumber, ReModal } from 'components';
import React, { FunctionComponent } from 'react';
import { useObject } from 'services';
import { IModal } from 'types';

export interface Props extends IModal {
  onComplete: () => void;
}
interface State {
  isLoading: boolean;
}

const ResidentNumberModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onComplete,
}) => {
  if (!isOpen) return null;
  const [{ isLoading }, setState] = useObject<State>({ isLoading: false });
  const onSubmit = async () => {
    onClose();
    onComplete();
  };
  return (
    <ReModal
      isOpen={isOpen}
      maxWidth="max-w-xl"
      onClose={onClose}
      title="주민등록번호 등록"
    >
      <div className="space-y-4">
        <Alert
          list={['개인정보보호에 유의해주시고, 입력된 정보는 3개월이후 자동 삭제됩니다.']}
          type="info"
        />
        <InputNumber
          placeholder="주민등록번호 입력"
          format="######-#######"
          className="w-full"
        />
        <div className="flex justify-center">
          <Button isLoading={isLoading} onClick={onSubmit}>
            등록
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default ResidentNumberModal;
