import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { LoadingContext } from 'context';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Loading } from 'components';
import { fetchingAPI } from 'api/axios';
import * as style from './style';

const AddNotice = ({ history }) => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [title, setTitle] = useState('');
  const [isActive, setIsActive] = useState('활성화');
  const [importance, setImportance] = useState('보통');
  const [content, setContent] = useState('');

  const handleAddNotice = async () => {
    try {
      handleIsLoading(true);
      const res = await fetchingAPI.post('/notice', {
        title,
        contents: content,
        isFixedAtTop: importance === '보통' ? 0 : 1,
        isActive: isActive === '활성화',
      });
      if (res.data.success) {
        history.push('/content/noticeList');
      }
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      POST - /api/notice
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
    }
  };

  return (
    <style.Outer>
      {isLoading && <Loading />}
      <div>
        <h3>공지사항</h3>
      </div>
      <style.FormOuter>
        <style.FormBox>
          <Form.Group>
            <Form.Control
              style={style.TextAlign}
              type="text"
              placeholder="제목"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={style.BoxStyle}>
            <div style={style.ItemWidth}>
              <Form.Label>활성화여부</Form.Label>
              <Form.Control
                style={style.TextAlign}
                as="select"
                onChange={(e) => setIsActive(e.target.value)}
                value={isActive}
              >
                <option value="활성화">활성화</option>
                <option value="비활성화">비활성화</option>
              </Form.Control>
            </div>
            <div style={style.ItemWidth}>
              <Form.Label>중요여부</Form.Label>
              <Form.Control
                style={style.TextAlign}
                as="select"
                onChange={(e) => setImportance(e.target.value)}
                value={importance}
              >
                <option value="보통">보통</option>
                <option value="중요">중요</option>
              </Form.Control>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>내용</Form.Label>
            <Form.Control
              style={style.TextAlign}
              as="textarea"
              rows="10"
              onChange={(e) => setContent(e.target.value)}
            />
          </Form.Group>
          <Button variant="dark" onClick={handleAddNotice}>
            추가
          </Button>
        </style.FormBox>
      </style.FormOuter>
    </style.Outer>
  );
};

export default withRouter(AddNotice);
