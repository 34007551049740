import { request } from 'services';
import useSWR from 'swr';
import { IAdminMemo } from '.';

export const useGetAdminMemo = (params?: {
  fetchingOrderNumber?: string;
  itemOrderNumber?: string;
  shopOrderNumber?: string;
}) => {
  const { data, mutate } = useSWR(JSON.stringify(params), () =>
    request.get<any, { data: IAdminMemo[] }>('/commerce/order/fetching-order/memo/list', {
      params,
      headers: {
        Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
      },
    })
  );
  return { data: data?.data, mutate };
};
