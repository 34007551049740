import styled from 'styled-components';

export const Outer = styled.div`
  width: 100%;
  padding: 0 22px 100px 22px;
`;

export const ShadowContainer = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 22px 0;
  padding: 22px;
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  font-weight: 400;
  font-size: 24px;
`;
