import _ from 'lodash';

export const itemFilter = (item, params) => {
  for (const key of Object.keys(params)) {
    if (!verifyParams(key, item, params)) {
      return false;
    }
  }
  return true;
};

export const compareDate = (aDate, bDate) => {
  const a = new Date(aDate);
  const b = new Date(bDate);
  b.setHours(b.getHours() - 9);
  return a < b || a === b;
};

const getItemValue = (item, params, key) => {
  switch (key) {
    case 'title':
      return item.name;
    case 'isActive':
      return Boolean(item.isActive);
    case 'displayedAt':
      return Boolean(item[params.displayedAt]);
    default:
      return item[key];
  }
};

const getParamsValue = (params, key) => {
  switch (key) {
    case 'displayedAt':
      return true;
    default:
      return params[key];
  }
};

const actions = {
  default: (item, params, key) =>
    getItemValue(item, params, key) === getParamsValue(params, key),
  title: (item, params) =>
    item.name.toLowerCase().includes(params.title.toLowerCase()),
  start: (item, params) =>
    item.startedAt ? !compareDate(item.endedAt, params.start) : true,
  end: (item, params) =>
    item.endedAt ? compareDate(item.startedAt, params.end) : true,
};

const specificFilterOption = [
  { key: 'title', verify: actions.title },
  { key: 'start', verify: actions.start },
  { key: 'end', verify: actions.end },
];

const checkSpecificFilter = (key) => _.find(specificFilterOption, { key });

const verifyParams = (key, item, params) => {
  const fn = checkSpecificFilter(key);
  return fn ? fn.verify(item, params) : actions.default(item, params, key);
};

export const getParams = (options) => {
  const params = {};
  options.title !== null && (params.title = options.title);
  options.gender !== null && (params.gender = options.gender);
  options.isActive !== null && (params.isActive = options.isActive);
  options.adminName !== null && (params.adminName = options.adminName);
  options.displayedAt !== null && (params.displayedAt = options.displayedAt);
  options.start !== null && (params.start = options.start);
  options.end !== null && (params.end = options.end);
  return params;
};
