import React from 'react';
import { Title } from 'components';

import * as style from './style';

export default function SalesAnalysis() {
  return (
    <style.Container>
      <Title description="아래에 화면이 보이지 않는다면 회사 계정(브라우저)으로 로그인 후 다시 시도해 주세요" />
      <iframe
        title="매출분석"
        src="https://datastudio.google.com/embed/reporting/64d6ca32-774e-4324-b316-eaf99039961f/page/lFvEC"
        allowFullScreen
        style={{ width: '100%', height: 1000 }}
      />
    </style.Container>
  );
}
