import React from 'react';
import { CheckBox } from '../../atoms';
import * as style from './style';

const MappingFilterBox = ({ type, options, setOptions }) => {
  const handleShopAllChecked = (e) => {
    if (e.target.checked) {
      setOptions({
        ...options,
        isShopAll: e.target.checked,
        isCafe24Upload: !e.target.checked,
        appIsActive: !e.target.checked,
      });
    }
  };

  const handleCafeUploadChecked = (e) => {
    if (e.target.checked) {
      setOptions({
        ...options,
        isCafe24Upload: e.target.checked,
        isShopAll: !e.target.checked,
        appIsActive: !e.target.checked,
      });
    }
  };

  const handleAppIsActive = (e) => {
    if (e.target.checked) {
      setOptions({
        ...options,
        appIsActive: e.target.checked,
        isCafe24Upload: !e.target.checked,
        isShopAll: !e.target.checked,
      });
    }
  };

  const handleIsMappingAll = (e) => {
    if (e.target.checked) {
      setOptions({
        ...options,
        isMappingAll: e.target.checked,
        isMapping: !e.target.checked,
        isNotMapping: !e.target.checked,
      });
    }
  };

  const handleIsMapping = (e) => {
    if (e.target.checked) {
      setOptions({
        ...options,
        isMapping: e.target.checked,
        isMappingAll: !e.target.checked,
        isNotMapping: !e.target.checked,
      });
    }
  };

  const handleIsNotMapping = (e) => {
    if (e.target.checked) {
      setOptions({
        ...options,
        isNotMapping: e.target.checked,
        isMapping: !e.target.checked,
        isMappingAll: !e.target.checked,
      });
    }
  };

  const handleLimit = (e) => {
    setOptions({
      ...options,
      limit: e.target.value,
    });
  };

  const onChangeInputText = (e) => {
    setOptions({
      ...options,
      searchText: e.target.value,
    });
  };

  return (
    <style.Container>
      {type === '카테고리 매핑' ? (
        <style.Inner BB="1px solid #ddd">
          <style.Title>편집샵</style.Title>
          <style.CheckboxOuter>
            <CheckBox
              text="전체"
              onChange={handleShopAllChecked}
              isChecked={options.isShopAll}
            />
            <CheckBox
              text="카페 24 업로드 편집샵만"
              onChange={handleCafeUploadChecked}
              isChecked={options.isCafe24Upload}
            />
            <CheckBox
              text="앱에 노출중인 편집샵만"
              onChange={handleAppIsActive}
              isChecked={options.appIsActive}
            />
          </style.CheckboxOuter>
        </style.Inner>
      ) : (
        <style.Inner BB="1px solid #ddd">
          <style.Title>검색어</style.Title>
          <style.CheckboxOuter>
            <style.InputText
              value={options.searchText}
              onChange={onChangeInputText}
            />
          </style.CheckboxOuter>
        </style.Inner>
      )}
      <style.Inner BB="1px solid #ddd">
        <style.Title>매핑 여부</style.Title>
        <style.CheckboxOuter>
          <CheckBox
            text="전체"
            onChange={handleIsMappingAll}
            isChecked={options.isMappingAll}
          />
          <CheckBox
            text="매핑됨"
            onChange={handleIsMapping}
            isChecked={options.isMapping}
          />
          <CheckBox
            text="매핑 안됨"
            onChange={handleIsNotMapping}
            isChecked={options.isNotMapping}
          />
        </style.CheckboxOuter>
      </style.Inner>
      <style.Inner>
        <style.Title>개수</style.Title>
        <style.SelectOuter>
          <style.Select value={options.limit} onChange={handleLimit}>
            <option value="10">10개</option>
            <option value="50">50개</option>
            <option value="100">100개</option>
            <option value="500">500개</option>
          </style.Select>
        </style.SelectOuter>
      </style.Inner>
    </style.Container>
  );
};
export default MappingFilterBox;
