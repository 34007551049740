import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { MenuList, ModifyBigBanner } from 'components';
import ManageMainLayout from 'pages/ManageMainLayout/commerce';
import ManageMainComponent from 'pages/ManageMainComponent/commerce';
import TestPageAnchor from 'components/UI/atoms/TestPageAnchor/commerce';
import { AddComponent } from 'pages';
import AddBigBanner from 'components/templates/ManageComponentContent/ManageBigBanner/AddBigBanner';
import { AddBandBanner } from 'pages/AddComponent';
import ManageLayout from 'components/templates/ManageLayout';

type Props = {};

const ManageContent: React.FC<Props> = ({}) => {
  const location = useLocation();
  const mode = location.pathname.split('/').includes('m') ? 'm' : 'w';
  const mainMenu = [
    { link: `/content/main/${mode}/layout`, title: '레이아웃 관리' },
    { link: `/content/main/${mode}/component`, title: '컴포넌트 관리' },
  ];
  const brandMenu = [
    { link: `/content/brand/${mode}/layout`, title: '레이아웃 관리' },
    { link: `/content/brand/${mode}/component`, title: '컴포넌트 관리' },
  ];
  const shopMenu = [
    { link: `/content/shop/${mode}/layout`, title: '레이아웃 관리' },
    { link: `/content/shop/${mode}/component`, title: '컴포넌트 관리' },
  ];
  return (
    <>
      {/* 메인 콘텐츠 관리 */}
      <Route path="/content/main/:gender">
        <MenuList items={mainMenu} />
        <TestPageAnchor mode={mode} />
      </Route>
      <Switch>
        <Route path="/content/main/:gender/layout/add/bandbanner">
          <AddBandBanner mode={mode} />
        </Route>
        <Route path="/content/main/:gender/layout/add">
          <AddComponent />
        </Route>
        <Route path="/content/main/:gender/layout">
          <ManageMainLayout mode={mode} />
        </Route>
      </Switch>
      <Switch>
        <Route path="/content/main/:gender/component/:layoutIdx/modify/:itemIdx">
          <ModifyBigBanner mode={mode} />
        </Route>
        <Route path="/content/main/:gender/component/:layoutIdx/add">
          <AddBigBanner mode={mode} />
        </Route>
        <Route path="/content/main/:gender/component">
          <ManageMainComponent mode={mode} />
        </Route>
      </Switch>
      {/* 브랜드 메인 관리 */}
      <Route path="/content/brand/:gender">
        <MenuList items={brandMenu} />
        <TestPageAnchor mode={mode} />
      </Route>
      <Route path="/content/brand/:gender/layout">
        <ManageLayout mode={mode} />
      </Route>
      {/* 편집샵 메인 관리 */}
      <Route path="/content/shop/:gender">
        <MenuList items={shopMenu} />
        <TestPageAnchor mode={mode} />
      </Route>
    </>
  );
};
export default ManageContent;
