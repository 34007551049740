import React, { useEffect, useState } from 'react';
import * as S from './style';
import { Button, Modal } from 'react-bootstrap';
import { getUserList, modifyExhibition } from 'api/axios';

export const ChangeActivity = ({ show, onHide, items }) => {
  const [value, setValue] = useState(0);

  useEffect(() => setValue(0), [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>기획전 활성상태 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{items.length}개 기획전의 활성상태를 변경합니다.</h5>
        {items.map(({ gender, name }) => (
          <p key={name}>
            {gender} {name}
          </p>
        ))}
        <S.Label>
          <S.Radio
            name="isActiveModal"
            checked={value === 1}
            onChange={() => setValue(1)}
          />
          활성화
        </S.Label>
        <S.Label>
          <S.Radio
            name="isActiveModal"
            checked={value === 0}
            onChange={() => setValue(0)}
          />
          비활성화
        </S.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            fetchExhibitionInfo(
              modifyExhibitionInfo(items, 'isActive', value),
            ).then(() => onHide());
          }}
        >
          변경하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ChangeVisible = ({ show, onHide, items }) => {
  const [value, setValue] = useState({
    isVisibleToAppMain: 0,
    isVisibleToAppCategory: 0,
    isVisibleToCafe24: 0,
    isVisibleToCafe24Mobile: 0,
  });
  const onChangeValue = (target) =>
    setValue((p) => ({ ...p, [target]: (p[target] + 1) % 2 }));
  const onClickChange = () => {
    fetchExhibitionInfo(
      modifyExhibitionInfo(
        modifyExhibitionInfo(
          modifyExhibitionInfo(
            modifyExhibitionInfo(
              items,
              'isVisibleToAppMain',
              value.isVisibleToAppCategory,
            ),
            'isVisibleToAppCategory',
            value.isVisibleToAppCategory,
          ),
          'isVisibleToCafe24',
          value.isVisibleToCafe24,
        ),
        'isVisibleToCafe24Mobile',
        value.isVisibleToCafe24Mobile,
      ),
    ).then(() => onHide());
  };

  useEffect(
    () =>
      setValue({
        isVisibleToAppMain: 0,
        isVisibleToAppCategory: 0,
        isVisibleToCafe24: 0,
        isVisibleToCafe24Mobile: 0,
      }),
    [show],
  );

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>기획전 노출위치 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{items.length}개 기획전의 노출위치를 변경합니다.</h5>
        {items.map(({ gender, name }) => (
          <p key={name}>
            {gender} {name}
          </p>
        ))}
        <S.Label>
          <S.Checkbox
            checked={value.isVisibleToAppMain === 1}
            onChange={() => onChangeValue('isVisibleToAppMain')}
          />
          앱 메인
        </S.Label>
        <S.Label>
          <S.Checkbox
            checked={value.isVisibleToAppCategory === 1}
            onChange={() => onChangeValue('isVisibleToAppCategory')}
          />
          앱 모아보기
        </S.Label>
        <S.Label>
          <S.Checkbox
            checked={value.isVisibleToCafe24 === 1}
            onChange={() => onChangeValue('isVisibleToCafe24')}
          />
          웹 pc
        </S.Label>
        <S.Label>
          <S.Checkbox
            checked={value.isVisibleToCafe24Mobile === 1}
            onChange={() => onChangeValue('isVisibleToCafe24Mobile')}
          />
          웹 모바일
        </S.Label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
        <Button variant="primary" onClick={onClickChange}>
          변경하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const ChangeAssignee = ({ show, onHide, items }) => {
  const [value, setValue] = useState(null);

  const [adminNames, setAdminNames] = useState([]);

  const fetchAdminNames = async () => {
    const data = await getUserList();
    setAdminNames(data);
  };

  useEffect(() => {
    fetchAdminNames();
  }, []);

  useEffect(() => setValue(null), [show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>기획전 담당자 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{items.length}개 기획전의 담당자를 변경합니다.</h5>
        {items.map(({ gender, name }) => (
          <p key={name}>
            {gender} {name}
          </p>
        ))}
        <select
          value={value || 'none'}
          onChange={(e) =>
            setValue(e.target.value !== 'none' ? e.target.value : null)
          }
        >
          <option value={'none'}>---</option>
          {adminNames.map(({ id, admin_name }) => (
            <option value={id} key={id}>
              {admin_name}
            </option>
          ))}
        </select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            fetchExhibitionInfo(
              modifyExhibitionInfo(items, 'assigneeId', Number(value)),
            ).then(() => onHide());
          }}
        >
          변경하기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const modifyExhibitionInfo = (items = [], target = '', value = 0) => {
  items = deleteAdminName(items);
  return items.map((item) => {
    item[target] = value;
    return item;
  });
};
const deleteAdminName = (items = []) => {
  return items.map((item) => {
    delete item.adminName;
    return item;
  });
};
const fetchExhibitionInfo = async (ModifiedItems) => {
  try {
    const { data } = await modifyExhibition(ModifiedItems);
    if (data.failed.length) {
      alert(`${data.failed.length}개 항목을 변경하는데 실패했습니다.`);
    } else {
      alert(`${data.success.length}개 항목을 변경했습니다.`);
    }
  } catch (error) {
    alert(
      `err in fetchExhibitionInfo(), in <ChangeActivity/>,${error.message}`,
    );
  }
};
