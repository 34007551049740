import styled from 'styled-components';

export const Outer = styled.div``;

export const ButtonOuter = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  margin-bottom: 40px;
`;

export const NoticeListOuter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const NoticeListInner = styled.div`
  width: 70%;
  margin-bottom: 100px;
`;

export const ListGroup = styled.div`
  display: flex;
`;

export const ListGroupItem = styled.div`
  ${(props) =>
    props.item === '1'
      ? 'width: 20%; display: flex; justify-content: center; align-items: center;'
      : 'width: 80%;'}
`;

export const NoticeTitle = styled.p`
  font-weight: bold;
  font-size: 1.1rem;
`;

export const Date = styled.p`
  color: lightgrey;
`;

export const ButtonItem = {
  marginTop: '40px',
  marginRight: '20px',
  flexShrink: 0,
};

export const marginTop20 = {
  marginTop: '20px',
};

export const ButtonStyle = {
  marginTop: '40px',
  marginRight: '20px',
  flexShrink: 0,
  marginLeft: '20px',
};
