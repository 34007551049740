import React, { FC } from 'react';

export interface Props {
  url: string;
}
interface State {}

const OpenLink: FC<Props> = ({ children, url }) => {
  return (
    <a
      href={url}
      target="_blank"
      className="text-gray-500 underline"
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </a>
  );
};

export default OpenLink;
