import fetchingAPI from 'api/fetchingAPI';
import React, { useState } from 'react';
import styled from 'styled-components';
import Loading, { Spinner } from 'react-bootstrap';
import { useSetRecoilState } from 'recoil';
import { triggerState } from 'pages/ManageMainContent';
const Container = styled.div`
  position: absolute;
  right: 44px;
  height: 54px;
  top: 50px;
  width: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Anchor = styled.button`
  all: unset;
  font-size: 16px;
  height: 36px;
  border-radius: 18px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid black;
  color: black;
`;

const publishToLiveConfirm = `테스트 서버의 메인 레이아웃 그대로 실서버에 반영됩니다.
미리보기 후 진행해주세요.`;

const TestPageAnchor = ({ mode = 'm' }) => {
  const [loadings, setLoadings] = useState({
    sync: false,
    publish: false,
  });

  const setTrigger = useSetRecoilState(triggerState);

  return (
    <Container>
      <Anchor
        onClick={() => {
          if (
            window.confirm('테스트 서버의 메인 레이아웃이 라이브 서버와 동기화 됩니다.')
          ) {
            setLoadings((p) => ({ ...p, sync: true }));
            fetchingAPI
              .mergeMainIntoTest(mode === 'm' ? 'men' : 'women')
              .then(() => {
                setTrigger((p) => p + 1);
              })
              .then(() => {
                setLoadings((p) => ({ ...p, sync: false }));
                alert('테스트 서버의 메인 레이아웃이 라이브 서버와 동기화 되었습니다.');
              })
              .catch((e) => alert(`err in mergeMainIntoTest, ${e}`));
          }
        }}
      >
        {loadings.sync ? <Spinner animation="border" /> : '현재버전 가져오기'}
      </Anchor>
      <Anchor
        onClick={() => {
          window.open(
            `https://m.fetching.co.kr/test/view/index_main.html?gender=${mode.toUpperCase()}`,
            '미리보기',
            'width=400,height=812,menubar=no,status=no,toolbar=no,menubar=no,location=no,scrollbars=no,left=100,right=100'
          );
        }}
      >
        미리보기
      </Anchor>
      <Anchor
        onClick={() => {
          if (window.confirm(publishToLiveConfirm)) {
            setLoadings((p) => ({ ...p, publish: true }));
            fetchingAPI
              .mergeTestIntoMain(mode === 'm' ? 'men' : 'women')
              .then(() => {
                setLoadings((p) => ({ ...p, publish: false }));
                alert('실서버에 배포되었습니다.');
              })
              .catch((e) => alert(`err in mergeTEstIntoMain, ${e.message}`));
          }
        }}
        style={{ color: 'red', borderColor: 'red' }}
      >
        {loadings.publish ? <Spinner animation="border" /> : '실서버에 배포하기'}
      </Anchor>
    </Container>
  );
};

export default TestPageAnchor;
