import { Card, Radio, ReModal, Button } from 'components';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { FETCHING_TEL, request, useObject } from 'services';
import { IModal } from 'types';
import Switch from 'react-switch';

export interface Props extends IModal {
  phoneNumber: string;
}
interface State {
  messageType: 'SMS' | 'MMS';
  content: string;
  isAd: boolean;
  isLoading: boolean;
  title: string;
}

const ReMessageModal: FunctionComponent<Props> = ({ isOpen, onClose, phoneNumber }) => {
  if (!isOpen) return null;
  const [{ messageType, content, isAd, isLoading, title }, setState, onChange] =
    useObject<State>({
      messageType: 'SMS',
      content: '',
      isAd: false,
      isLoading: false,
      title: '',
    });
  const onSubmit = async () => {
    if (!content) {
      toast.info('메시지를 입력해주세요.');
      return;
    }
    if (messageType === 'SMS' && content.length > 90) {
      toast.info('SMS는 90자 이하로 보낼 수 있습니다.');
    }
    if (!window.confirm('전송하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      let data: any = {
        messageType,
        content,
        sender: FETCHING_TEL,
        targetType: 'MANUAL',
        targetPhoneNumbers: [phoneNumber],
        isAd,
      };
      if (messageType === 'MMS') data.title = title;
      await request.post('/commerce/user/message', data);
      toast.success('메시지가 발송되었습니다.');
      onClose();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <ReModal
      title="메시지 작성"
      maxWidth="max-w-3xl"
      description="메시지 방식 선택 및 메시지 작성 후 [전송하기] 버튼을 클릭해 주세요."
      isOpen={isOpen}
      onClose={() => {
        if (!!content.length) {
          if (!window.confirm('작업을 중단하시겠습니까?')) return;
        }
        onClose();
      }}
    >
      <div className="text-right mt-8 mb-1">
        <span
          className="link"
          onClick={() =>
            window.open('https://www.notion.so/fetching/326f32273889421a80f8c916f54e15d1')
          }
        >
          메시지용 변수 보기
        </span>
      </div>
      <div className="flex gap-4">
        <div className="w-80">
          <Card
            items={[
              {
                name: '메시지 방식',
                renderItem: () => (
                  <Radio
                    id="messageType"
                    value={messageType}
                    onChange={(messageType) => {
                      if (!!content.length) {
                        if (
                          !window.confirm(
                            '작성한 메시지가 사라집니다. 메시지 방식을 바꾸시겠습니까?'
                          )
                        )
                          return;
                        setState({ messageType, content: '' });
                      } else setState({ messageType });
                    }}
                    options={[
                      { name: 'SMS', value: 'SMS' },
                      { name: 'MMS', value: 'MMS' },
                    ]}
                  />
                ),
                stretch: true,
              },
              {
                name: '발신번호',
                renderItem: () => FETCHING_TEL,
                stretch: true,
              },
              {
                name: '발송 대상',
                renderItem: () => phoneNumber,
                stretch: true,
              },
              {
                name: '광고성 정보',
                renderItem: () => (
                  <Switch checked={isAd} onChange={(isAd) => setState({ isAd })} />
                ),
                stretch: true,
              },
            ]}
          />
        </div>
        <div className="flex-1 w-full">
          <textarea
            spellCheck={false}
            className="p-3 w-full"
            value={content}
            onChange={onChange}
            name="content"
            rows={messageType === 'SMS' ? 8 : 20}
            placeholder="메시지를 입력하세요."
            maxLength={messageType === 'SMS' ? 90 : 2000}
          />
          <div className="text-right">
            {content.length}/{messageType === 'SMS' ? 90 : 2000}bytes
          </div>
        </div>
      </div>
      <div className="text-center mt-3">
        <Button isLoading={isLoading} onClick={onSubmit}>
          전송하기
        </Button>
      </div>
    </ReModal>
  );
};

export default ReMessageModal;
