import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import type { SetterOrUpdater } from 'recoil';
import { dayjsFormat, request, useObject, useQuery } from 'services';
import { orderDetailState, priceRuleState, shopListState } from 'store';
import type { IPriceRule, IShop, NOrder } from 'types';
import useSWR from 'swr/immutable';

export const useOrderTable = () => {
  const [state, setOrderState, onChangeOrder, resetOrderState] = useObject<NOrder.Table>({
    searchText: '',
    searchType: '',
    userType: '',
    limit: 50,
    minOrderDate: '',
    maxOrderDate: '',
    orderBy: '',
    orderPath: '',
    orderStatus: '',
    orderStatuses: '',
    page: 1,
    inflowCampaign: '',
    inflowChannel: '',
    total: 0,
    selectedIdx: [],
    isMemoLoading: false,
    isLoading: false,
    payMethod: '',
    sort: 'ORDER_DATE_DESC',
    list: [],
    isDetailOpen: false,
    memoList: [],
    isAscending: 'false',
    isMessageOpen: false,
    phoneNumber: '',
    minCancelDate: '',
    maxCancelDate: '',
    showAll: true,
    priceRuleIdList: [],
    shopIdList: [],
    deliveryMethodIdList: [],
  });
  const { location } = useHistory();

  const onDateClick = (date: string, type?: 'cancel') => {
    let newOrderedAt = '';
    if (date === '전체') newOrderedAt = dayjsFormat(dayjs().add(-100, 'year'));
    else if (date === '오늘') newOrderedAt = dayjsFormat(dayjs());
    else if (date === '어제') newOrderedAt = dayjsFormat(dayjs().add(-1, 'day'));
    else if (date === '3일') newOrderedAt = dayjsFormat(dayjs().add(-3, 'day'));
    else if (date === '7일') newOrderedAt = dayjsFormat(dayjs().add(-7, 'day'));
    else if (date === '15일') newOrderedAt = dayjsFormat(dayjs().add(-15, 'day'));
    else if (date === '1개월') newOrderedAt = dayjsFormat(dayjs().add(-1, 'month'));
    else if (date === '3개월') newOrderedAt = dayjsFormat(dayjs().add(-3, 'month'));
    else if (date === '6개월') newOrderedAt = dayjsFormat(dayjs().add(-6, 'month'));

    if (type === 'cancel')
      setOrderState({ minCancelDate: newOrderedAt, maxOrderDate: dayjsFormat(dayjs()) });
    else
      setOrderState({
        minOrderDate: newOrderedAt,
        maxOrderDate: dayjsFormat(dayjs()),
      });
  };

  const onChangeCheckbox = (idx: string) => {
    const { selectedIdx } = state;
    setOrderState({
      selectedIdx:
        selectedIdx.indexOf(idx) !== -1
          ? selectedIdx.filter((index) => index !== idx)
          : [...selectedIdx, idx],
    });
  };

  const getMemo = async (fetchingOrderNumber: string) => {
    if (!fetchingOrderNumber || state.isMemoLoading) return;

    setOrderState({ isMemoLoading: true });
    try {
      const { count, data } = await request.get<any, { count: number; data: any[] }>(
        `/commerce/order/fetching-order/memo/list`,
        { params: { fetchingOrderNumber } }
      );
      setOrderState({ memoList: data, isMemoLoading: false });
    } catch (err) {
      console.dir(err);
      setOrderState({ memoList: [], isMemoLoading: false });
    }
  };

  useEffect(() => {
    resetOrderState();
  }, [location.pathname, location.search]);
  return {
    ...state,
    onChangeOrder,
    onDateClick,
    setOrderState,
    onChangeCheckbox,
    resetOrderState,
    getMemo,
  };
};

interface IUpdateRealPrice {
  shopIndex: number;
  itemIndex: number;
  e: React.ChangeEvent<HTMLInputElement>;
}

export const getPercent = (son: number, mother: number) =>
  Math.round((son * 100) / mother);

export const useOrderDetail = (): [
  NOrder.Detail,
  (newState: Partial<NOrder.Detail>) => void,
  {
    setRealPrice: ({ shopIndex, itemIndex, e }: IUpdateRealPrice) => void;
    refreshOrderState: (orderMode?: boolean) => Promise<void>;
  }
] => {
  const [state, setState] = useRecoilState(orderDetailState);
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const { query } = useQuery<{ tab: string }>();

  const setOrderState = (newState: Partial<NOrder.Detail>) =>
    setState((state) => ({ ...state, ...newState }));

  const setRealPrice = ({ shopIndex, itemIndex, e }: IUpdateRealPrice) => {
    const { shopOrderList } = state;

    if (isNaN(Number(e.target.value))) return;

    let updateState = {
      ...shopOrderList[shopIndex].itemOrderList[itemIndex],
      isAdditionalUpdate: true,
      [e.target.name]: Number(e.target.value),
    };

    setOrderState({
      shopOrderList: [
        ...shopOrderList.slice(0, shopIndex),
        {
          ...shopOrderList[shopIndex],
          itemOrderList: [
            ...shopOrderList[shopIndex].itemOrderList.slice(0, itemIndex),
            updateState,
            ...shopOrderList[shopIndex].itemOrderList.slice(itemIndex + 1),
          ],
        },
        ...shopOrderList.slice(shopIndex + 1),
      ],
    });
  };

  const refreshOrderState = async (orderMode?: Boolean) => {
    try {
      const { fetchingOrder: data } = await request.get<
        any,
        { fetchingOrder: NOrder.Detail }
      >(`/commerce/order/fetching-order/${fetchingOrderNumber}`, {
        params: {
          orderMode:
            orderMode !== undefined
              ? orderMode
              : !query.tab || query.tab === '0'
              ? true
              : query.tab === '1'
              ? false
              : undefined,
        },
        headers: {
          Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
        },
      });

      setOrderState({
        cancelInfo: data.cancelInfo,
        address: data.address || '',
        addressDetail: data.addressDetail || '',
        customsClearanceNumber: data.customsClearanceNumber || '',
        orderPath: data.orderPath,
        orderStatus: data.orderStatus,
        orderUserName: data.orderUserName,
        orderUserEmail: data.orderUserEmail,
        orderUserPhoneNumber: data.orderUserPhoneNumber,
        shopOrderList: data.shopOrderList.map((shop) => ({
          ...shop,
          itemOrderList: shop.itemOrderList.map((item) => ({
            ...item,
            originPrice: item.realtimePrice[shop.shopCountry][0].rawValue,
            shopDiscount: item.realtimePrice[shop.shopCountry][1].rawValue,
            promotionDiscount: item.realtimePrice[shop.shopCountry][2].rawValue,
            promotionDiscountPercent:
              item.realtimePrice[shop.shopCountry][1].rawValue === 0
                ? 0
                : Math.floor(
                    (item.realtimePrice[shop.shopCountry][2].rawValue /
                      item.realtimePrice[shop.shopCountry][1].rawValue) *
                      100
                  ),
            fetchingFee: item.realtimePrice[shop.shopCountry][7].rawValue,
            fetchingFeePercent: item.realtimePrice[shop.shopCountry][7].percent || 0,
            deliveryFee: item.realtimePrice[shop.shopCountry][6].rawValue,
            additionalFee: item.realtimePrice[shop.shopCountry][8].rawValue,
            currentShopCountry: shop.shopCountry,
            isAdditionalUpdate: false,
          })),
        })),
        userType: data.userType,
        userId: data.userId,
        userName: data.userName,
        recipientName: data.recipientName || '',
        payAmount: data.payAmount,
        payMethod: data.payMethod,
        phoneNumber: data.phoneNumber,
        orderedAt: dayjs(data.orderedAt).format('YYYY.MM.DD HH:mm:ss'),
        itemTotalAmount: data.itemTotalAmount,
        expectedPayAmount: data.expectedPayAmount,
        expectedPoint: data.expectedPoint,
        totalDeliveryFee: data.totalDeliveryFee,
        totalDiscount: data.totalDiscount,
        totalDiscountDetail: data.totalDiscountDetail,
        zipCode: data.zipCode || '',
        deliveryMemo: data.deliveryMemo || '',
        totalDutyAndTax: data.totalDutyAndTax,
        englishAddress: data.englishAddress || '',
        englishAddressDetail: data.englishAddressDetail || '',
        englishDeliveryMemo: data.englishDeliveryMemo || '',
        englishRecipientName: data.englishRecipientName || '',
        totalAdditionalFee: data.totalAdditionalFee,
      });
    } catch (err) {
      console.dir(err);
    }
  };

  return [state, setOrderState, { setRealPrice, refreshOrderState }];
};

export const useShop = (): [IShop[], SetterOrUpdater<IShop[]>] => {
  const [shopList, setShopList] = useRecoilState(shopListState);

  const get = async () => {
    if (!!shopList.length) return;
    try {
      const { data } = await request.get<any, { data: IShop[] }>('/shop', {
        params: { isExtended: true, orderBy: 'ENGLISH' },
      });
      setShopList(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return [shopList, setShopList];
};

export const usePriceRule = (): [IPriceRule[], SetterOrUpdater<IPriceRule[]>] => {
  const [priceRuleList, setPriceRuleList] = useRecoilState(priceRuleState);

  const get = async () => {
    if (!!priceRuleList.length) return;
    try {
      const { data } = await request.get<any, { data: IPriceRule[] }>(
        '/shop/price-rules',
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setPriceRuleList(data.sort((a, b) => a.name.localeCompare(b.name)));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return [priceRuleList, setPriceRuleList];
};

export const useOrderTableDeliveryMethod = () => {
  const { data } = useSWR<{
    data: { id: number; name: string; country: string }[];
  }>(`/delivery-method`, request.get);

  return [
    data?.data.map((data) => ({
      label: `${data.name} (${data.country})`,
      value: data.id,
    })),
  ];
};
