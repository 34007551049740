import { Card, FormItem, GridTable, NumberStat } from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { PAY_METHOD, request, useObject } from 'services';
import { priceFormat } from 'utils';

export interface Props {}
interface State {
  buyerName: string;
  naverPayDiscount: number;
  payLogList: Array<{
    Tid?: string;
    payMethod: keyof typeof PAY_METHOD;
    type: 'SMARTRO';
    createdAt: string;
    payAmount: number;
  }>;
  payMethodList: string[];
}

const OrderPayMethod: FunctionComponent<Props> = () => {
  const [{ buyerName, naverPayDiscount, payLogList, payMethodList }, setState] =
    useObject<State>({
      buyerName: '',
      naverPayDiscount: 0,
      payLogList: [],
      payMethodList: [],
    });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();

  const get = async () => {
    try {
      const data = await request.get<any, State>(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/pay-method`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setState({
        buyerName: data.buyerName,
        naverPayDiscount: data.naverPayDiscount,
        payLogList: data.payLogList,
        payMethodList: data.payMethodList,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <Card
      title="결제수단"
      items={[
        {
          name: '결제(입금)자',
          renderItem: () => <span className="font-bold">{buyerName}</span>,
          stretch: true,
        },
        {
          name: '결제수단',
          renderItem: () => (
            <span className="font-bold">
              {payMethodList.map((item) => PAY_METHOD[item]).join(', ')}
            </span>
          ),
          stretch: true,
        },
        {
          name: '결제정보',
          renderItem: () => (
            <GridTable
              noSelection
              maxHeight=""
              renderItem={(item, key) => (
                <div key={key}>
                  <div>{PAY_METHOD[item.payMethod]}</div>
                  <div>{item.Tid}</div>
                  <div>{dayjs(item.createdAt).format('YYYY. MM. DD. HH:mm:ss')}</div>
                  <div>
                    <NumberStat value={item.payAmount} />
                  </div>
                </div>
              )}
              list={payLogList}
              columns={[
                '카드사',
                'TID (PG사 승인번호)',
                '신용카드 결제일',
                '최종결제금액',
              ]}
            />
          ),
          stretch: true,
        },
        {
          name: '네이버페이할인상세',
          renderItem: () => (
            <span>
              네이버페이 포인트:{' '}
              <span className="font-bold">{priceFormat(naverPayDiscount)}</span>
            </span>
          ),
          stretch: true,
        },
      ]}
    />
  );
};

export default OrderPayMethod;
