import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import * as style from './style';

const Form = ({ title, children, grid, color }) => (
  <style.ItemWrapper>
    <style.ItemTitle color={color}>{title}</style.ItemTitle>
    <style.Item column={grid}>{children}</style.Item>
  </style.ItemWrapper>
);

export default function PriceInfo({
  product,
  prices,
  agencyFeeRate,
  setPrices,
  setAgencyFeeRate,
  scrollRef,
  mode,
}) {
  const [isActive, setIsActive] = useState(true);
  const [contentIndex, contentChange] = useState(0);
  const [price, setPrice] = useState(prices[contentIndex]);

  const handleShowContent = () => {
    isActive ? setIsActive(false) : setIsActive(true);
  };

  const setValue = (field, value) => {
    if (isNaN(Number(value))) {
      return;
    }
    const newPrice = {
      ...price,
      [field]: value,
    };
    prices.splice(contentIndex, 1, newPrice);
    setPrice(newPrice);
    setPrices(prices);
  };

  const formList = [
    {
      title: '상품 원가',
      mode: ['update', 'create'],
      component: (
        <>
          {mode === 'create' ? (
            <input
              value={price.originPrice}
              type="text"
              onChange={(e) => setValue('originPrice', e.target.value)}
              style={{ marginRight: '5px' }}
            />
          ) : (
            `${Math.round(price?.originPrice)} ${price?.currency}`
          )}
        </>
      ),
    },
    {
      title: '편집샵 할인',
      mode: ['update'],
      component: (
        <>
          -{' '}
          {(price && Math.round(price.originPrice - price.discountedPrice)) ||
            0}{' '}
          {price?.currency}(
          {price?.discountRate && Math.round(price.discountRate * 100)}%)
        </>
      ),
    },
    {
      title: '편집샵 프로모션',
      mode: ['update'],
      component: (
        <>
          - {Math.round(price.discountedPrice - price.fixedPrice)}{' '}
          {price?.currency}
          {price?.promotions ? (
            <style.PromotionBox>
              {price?.promotions?.map((el) => {
                const name = `${el.isAppliedWith}-${el.code}-${el.name}`;
                return <div key={el.shopPromotionId}>{name}</div>;
              })}
            </style.PromotionBox>
          ) : null}
        </>
      ),
    },
    {
      title: '편집샵 상품가',
      mode: ['update'],
      component: <>= {`${Math.round(price?.fixedPrice)} ${price?.currency}`}</>,
    },
    {
      title: '환율 계산',
      mode: ['update'],
      component: <>= {Math.round(price?.fixedPriceKor) || '없음'} KRW</>,
    },
    {
      title: '관부가세',
      mode: ['update'],
      component: <>+ {Math.round(price?.dutyAndTax) || '없음'} KRW</>,
    },
    {
      title: '배송비',
      mode: ['update'],
      component: <>+ {Math.round(price?.deliveryFee) || '없음'} KRW</>,
    },
    {
      title: '편집샵 판매가',
      mode: ['update', 'create'],
      color: 'true',
      component: (
        <>
          {mode === 'create' ? (
            <input
              value={price.discountedPrice}
              type="text"
              onChange={(e) => setValue('discountedPrice', e.target.value)}
            />
          ) : (
            `${Math.round(price?.totalPrice)} KRW` || '없음'
          )}
        </>
      ),
    },
    {
      title: '페칭 수수료',
      mode: ['update'],
      component: (
        <>
          + {price?.agencyFee || '없음'} KRW (
          <input
            value={Math.round(
              agencyFeeRate ? agencyFeeRate * 100 : price?.agencyFeeRate * 100,
            )}
            onChange={(e) => {
              const rate = parseInt(e.target.value, 10) / 100 || 0;
              setPrices(
                prices.map((price) => ({ ...price, agencyFeeRate: rate })),
              );
              setAgencyFeeRate(rate);
            }}
          />
          %
          {agencyFeeRate != null
            ? `, 변경 시 예상 수수료: ${
                price?.totalPrice * agencyFeeRate
                  ? `${
                      Math.ceil((price?.totalPrice * agencyFeeRate) / 100) * 100
                    } KRW`
                  : '없음'
              }, 실제 반영까지는 시간이 소요될 수 있습니다`
            : ''}
          ){' '}
        </>
      ),
    },
    {
      title: '추가 수수료 (배대지 비용 등)',
      mode: ['update'],
      component: <>+ {price?.additionalPrice || '없음'} KRW</>,
    },
    {
      title: '페칭 판매가',
      mode: ['update'],
      color: 'true',
      component: <>{price?.finalPrice || '없음'} KRW</>,
    },
  ];

  return (
    <style.Wrapper>
      <style.TitleWrapper ref={scrollRef}>
        <style.Title>가격 정보</style.Title>
        {isActive ? (
          <FiChevronUp style={style.Icon} onClick={handleShowContent} />
        ) : (
          <FiChevronDown style={style.Icon} onClick={handleShowContent} />
        )}
      </style.TitleWrapper>
      <style.Content>
        {mode === 'update' &&
          prices?.map((section, index) => {
            return section.currency === product.shopCurrency &&
              section.finalPrice === product.finalPrice ? (
              <style.HighlightedButton
                key={index}
                onClick={() => {
                  contentChange(index);
                  setPrice(prices[index]);
                }}
              >
                {section.shopCountry}
              </style.HighlightedButton>
            ) : (
              <style.Button
                key={index}
                onClick={() => {
                  contentChange(index);
                  setPrice(prices[index]);
                }}
              >
                {section.shopCountry}
              </style.Button>
            );
          })}
      </style.Content>
      {isActive && (
        <style.Content>
          {formList
            .filter((list) => list.mode.indexOf(mode) > -1)
            .map(({ title, grid, component, color }, index) => (
              <Form key={index} title={title} grid={grid} color={color}>
                {component}
              </Form>
            ))}
        </style.Content>
      )}
    </style.Wrapper>
  );
}
