import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import axios from 'api/axios';
import * as style from './style';

const ModifyShopSaleInfo = ({ history }) => {
  const selectShopInfoData = useSelector(
    (state) => state.selectShopData.selectShopBasicData,
  );

  const [isSale, setIsSale] = useState('');
  const [saleName, setSaleName] = useState('');
  const [saleDescription, setSaleDescription] = useState('');
  const [saleRateMinimum, setSaleRateMininum] = useState('');
  const [saleRateMaximum, setSaleRateMaximum] = useState('');
  const [saleStartDate, setSaleStartDate] = useState('');
  const [saleEndDate, setSaleEndDate] = useState('');

  const getToday = () => {
    return new Date().toJSON().slice(0, 10);
  };

  const getDate = (inputDate) => {
    const result = new Date(inputDate);
    const year = result.getFullYear();
    const month = result.getMonth() + 1;
    const date = result.getDate();

    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  useEffect(() => {
    axios
      .get(`/api/shop/${selectShopInfoData.id}/sale`)
      .then((res) => {
        setIsSale(res.data.data[0].is_sale);
        setSaleName(res.data.data[0].sale_name);
        setSaleDescription(res.data.data[0].sale_description);
        setSaleRateMininum(res.data.data[0].sale_rate_minimum * 100);
        setSaleRateMaximum(res.data.data[0].sale_rate_maximum * 100);
        if (
          !res.data.data[0].sale_start_date &&
          !res.data.data[0].sale_end_date
        ) {
          setSaleStartDate(getToday());
          setSaleEndDate(getToday());
        } else {
          setSaleStartDate(getDate(res.data.data[0].sale_start_date));
          setSaleEndDate(getDate(res.data.data[0].sale_end_date));
        }
      })
      .catch((err) => console.error(err));
  }, [selectShopInfoData.shop_id]);

  const handleModifyShopSaleInfo = () => {
    axios
      .put(`/api/shop/${selectShopInfoData.id}/sale`, {
        is_sale: Number(isSale),
        sale_name: saleName,
        sale_description: saleDescription,
        sale_rate_minimum: saleRateMinimum / 100,
        sale_rate_maximum: saleRateMaximum / 100,
        sale_start_date: saleStartDate,
        sale_end_date: saleEndDate,
      })
      .then((res) => {
        if (res.data.success) {
          history.push(
            `/storeInfoManagement/saleInfo/${selectShopInfoData.id}`,
          );
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <style.Outer>
      <style.Inner>
        <Card className="text-center">
          <Card.Header>{selectShopInfoData.shop_name}</Card.Header>
          <Card.Body>
            <InputGroup style={style.MaginTop20}>
              <InputGroup.Prepend>
                <InputGroup.Text>세일 상태</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                as="select"
                defaultValue={isSale}
                onChange={(e) => {
                  setIsSale(e.target.value);
                }}
              >
                <option value={isSale}>
                  (현재) {isSale === 0 ? '세일 안함' : '세일 중'}
                </option>
                <option value={1}>세일 중</option>
                <option value={0}>세일 안함</option>
              </Form.Control>
            </InputGroup>
            <InputGroup style={style.MaginTop20}>
              <InputGroup.Prepend>
                <InputGroup.Text>세일 이름</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                defaultValue={saleName}
                onChange={(e) => {
                  setSaleName(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup style={style.MaginTop20}>
              <InputGroup.Prepend>
                <InputGroup.Text>세일 설명</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="textarea"
                defaultValue={saleDescription}
                onChange={(e) => {
                  setSaleDescription(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup style={style.MaginTop20}>
              <InputGroup.Prepend>
                <InputGroup.Text>최소 세일률</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="최소 세일률"
                defaultValue={saleRateMinimum}
                onChange={(e) => {
                  setSaleRateMininum(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup style={style.MaginTop20}>
              <InputGroup.Prepend>
                <InputGroup.Text>최대 세일률</InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                placeholder="최대 세일률"
                defaultValue={saleRateMaximum}
                onChange={(e) => {
                  setSaleRateMaximum(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup style={style.MaginTop20}>
              <InputGroup.Prepend>
                <InputGroup.Text>세일 시작일</InputGroup.Text>
                <style.DateBoxOuter>
                  <style.DateInput
                    defaultValue={saleStartDate}
                    onChange={(e) => {
                      setSaleStartDate(e.target.value);
                    }}
                  />
                </style.DateBoxOuter>
              </InputGroup.Prepend>
            </InputGroup>
            <InputGroup style={style.MaginTop20}>
              <InputGroup.Prepend>
                <InputGroup.Text>세일 종료일</InputGroup.Text>
                <style.DateBoxOuter>
                  <style.DateInput
                    defaultValue={saleEndDate}
                    onChange={(e) => {
                      setSaleEndDate(e.target.value);
                    }}
                  />
                </style.DateBoxOuter>
              </InputGroup.Prepend>
            </InputGroup>
          </Card.Body>
          <Card.Footer className="text-muted">
            <Button variant="dark" onClick={handleModifyShopSaleInfo}>
              수정
            </Button>
          </Card.Footer>
        </Card>
      </style.Inner>
    </style.Outer>
  );
};

export default withRouter(ModifyShopSaleInfo);
