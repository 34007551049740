import React, { useState, useEffect, useContext } from 'react';
import {
  PageButtonList,
  MappingButtons,
  MappingFilterBox,
  SearchButtonBox,
  BrandMappingTable,
  MoonLoading,
} from 'components';
import { LoadingContext } from 'context';
import * as style from './style';
import { axios, getBrandList } from 'api/axios';

export default () => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [initialState, setInitial] = useState(true);
  const [items, setItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [buttonList, setButtonList] = useState(['<<', 1, 2, 3, 4, 5, '>>']);
  const [mappingFilterOption, setMappingFilterOption] = useState({
    page: 1,
    searchText: '',
    isMappingAll: true,
    isMapping: false,
    isNotMapping: false,
    limit: '10',
  });

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [brandName, setBrnadName] = useState('');

  const handleSelecedBrandId = (e) => {
    setSelectedBrandId(e.target.value);
  };

  const handleIsAllChecked = (e) => {
    if (e.target.checked) {
      setSelectedItems([...selectedItems, ...items]);
    } else {
      let result = selectedItems;
      items.forEach((item) => {
        if (item.mapId) {
          result = result.filter((selectedItem) => {
            return item.mapId !== selectedItem.mapId;
          });
        } else {
          result = result.filter((el) => {
            return el.semiName !== item.semiName;
          });
        }
      });
      setSelectedItems(result);
    }
  };

  const handleCheckedItem = (e) => {
    if (isNaN(Number(e.target.value))) {
      const result = selectedItems.filter((el) => {
        return el.semiName !== e.target.value;
      });

      if (e.target.checked) {
        const resultItem = items.find((el) => {
          return el.semiName === e.target.value;
        });
        result.push(resultItem);
        setSelectedItems(result);
      } else {
        setSelectedItems(result);
      }
    } else {
      const result = selectedItems.filter((el) => {
        return el.mapId !== Number(e.target.value);
      });
      if (e.target.checked) {
        const resultItem = items.find((el) => {
          return el.mapId === Number(e.target.value);
        });
        result.push(resultItem);
        setSelectedItems(result);
      } else {
        setSelectedItems(result);
      }
    }
  };

  const setPage = (val) => {
    if (val === '<<') {
      if (buttonList[1] === 1) {
        alert('첫 페이지 입니다 😭');
      } else {
        const target = buttonList[1];
        setMappingFilterOption({
          ...mappingFilterOption,
          page: target - 1,
        });
        const result = [];
        for (let i = 0; i < buttonList.length; i++) {
          if (i === 0) {
            result.push('>>');
          } else if (i === buttonList.length - 1) {
            result.push('<<');
          } else {
            result.push(target - i);
          }
        }
        result.reverse();
        setButtonList(result);
      }
    } else if (val === '>>') {
      const target = buttonList[buttonList.length - 2];
      setMappingFilterOption({
        ...mappingFilterOption,
        page: target + 1,
      });
      const result = [];
      for (let i = 0; i < buttonList.length; i++) {
        if (i === 0) {
          result.push('<<');
        } else if (i === buttonList.length - 1) {
          result.push('>>');
        } else {
          result.push(target + i);
        }
      }
      setButtonList(result);
    } else {
      setMappingFilterOption({
        ...mappingFilterOption,
        page: Number(val),
      });
    }
  };

  const handleSearch = async () => {
    try {
      handleIsLoading(true);
      const params = {
        page: mappingFilterOption.page,
        limit: Number(mappingFilterOption.limit),
      };

      if (mappingFilterOption.searchText) {
        params.search = mappingFilterOption.searchText;
      }
      if (!mappingFilterOption.isMappingAll) {
        params.isMapped = mappingFilterOption.isMapping;
      }
      const res = await axios.get('/api/brand/map', {
        params,
      });

      const { data } = res.data;

      setItems(data);
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/brand/map
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
    }
  };

  const handleMapping = async () => {
    try {
      handleIsLoading(true);
      const data = selectedItems.map((item) => {
        return {
          brandId: Number(selectedBrandId),
          semiName: item.semiName,
        };
      });

      if (brandName) {
        data.push({
          brandId: Number(selectedBrandId),
          semiName: brandName,
        });
      }

      if (data.length) {
        await axios.post('/api/brand/map', {
          data,
        });
      } else {
        alert('✅ 매핑할 아이템을 선택하지 않았습니다! 매핑할 아이템을 선택해 주세요');
      }
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      POST - /api/brand/map
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
      setSelectedItems([]);
      handleSearch();
    }
  };

  const handleDeleteMapping = async () => {
    try {
      handleIsLoading(true);
      const data = selectedItems
        .map((item) => {
          return item.mapId;
        })
        .filter((el) => el !== null);

      if (data.length) {
        await axios.delete('/api/brand/map', {
          data: {
            data,
          },
        });
      } else {
        alert('✅ 삭제할 아이템을 선택하지 않았습니다! 삭제할 아이템을 선택해 주세요');
      }
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      DELETE - /api/brand/map
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
      setSelectedItems([]);
      handleSearch();
    }
  };

  useEffect(() => {
    let initial = true;

    const fetchData = async () => {
      const resultBrands = await getBrandList();
      if (initial) {
        setBrands(resultBrands);
        setSelectedBrandId(resultBrands[0].brand_id);
        await handleSearch();
        setInitial(false);
      }
    };

    fetchData();

    return () => {
      initial = false;
    };
  }, []);

  useEffect(() => {
    if (!initialState) {
      handleSearch();
    }
  }, [mappingFilterOption.page]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <style.Title>브랜드 매핑</style.Title>
      <MappingFilterBox
        type="브랜드 매핑"
        options={mappingFilterOption}
        setOptions={(val) => setMappingFilterOption(val)}
      />
      <SearchButtonBox handleSearch={handleSearch} />
      <style.MappingWrapper>
        <style.Outer width="20%">
          <style.BrandSelect onChange={handleSelecedBrandId}>
            {brands.map((brand) => {
              return (
                <style.BrandOption key={brand.brand_id} value={brand.brand_id}>
                  {brand.brand_name}
                </style.BrandOption>
              );
            })}
          </style.BrandSelect>
        </style.Outer>
        <style.Outer width="10%">
          <MappingButtons
            handleMapping={handleMapping}
            handleDeleteMapping={handleDeleteMapping}
          />
        </style.Outer>
        <style.Outer width="70%">
          <style.TextInput
            type="text"
            value={brandName}
            onChange={(e) => {
              setBrnadName(e.target.value);
            }}
            placeholder="매핑할 브랜드를 직접 입력하세요."
          />
          <p>
            📌 아래 목록에 나와있지 않는 브랜드를 직접 입력하여 페칭브랜드와 함께
            매핑합니다.
          </p>
          <BrandMappingTable
            items={items}
            selectedItems={selectedItems}
            handleCheckedItem={handleCheckedItem}
            handleIsAllChecked={handleIsAllChecked}
          />
        </style.Outer>
      </style.MappingWrapper>
      <style.ButtonListWrapper>
        <PageButtonList
          page={mappingFilterOption.page}
          buttonList={buttonList}
          setPage={setPage}
        />
      </style.ButtonListWrapper>
    </style.Container>
  );
};
