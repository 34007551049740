export enum SHOP_ORDER_STATUS {
  'BEFORE_DEPOSIT' = '입금 전 (무통장)',
  'ORDER_AVAILABLE' = '발주 가능',
  'ORDER_WAITING' = '발주 대기',
  'PRE_ORDER_REQUIRED' = '선발주 필요',
  'ORDER_DELAY' = '발주 지연',
  'ORDER_COMPLETE' = '발주 완료',
  'ORDER_DELAY_IN_SHOP' = '발송 지연 (편집샵 과실)',
  'PRODUCT_PREPARE' = '상품 준비중',
  'SHIPPING_START' = '배송 시작',
  'IN_WAYPOINT_SHIPPING' = '경유지 배송 중',
  'WAYPOINT_ARRIVAL' = '경유지 도착',
  'DOMESTIC_CUSTOMS_CLEARANCE' = '국내 통관 중',
  'CUSTOMS_CLEARANCE_DELAY' = '통관 지연',
  'IN_DOMESTIC_SHIPPING' = '국내 배송 중',
  'SHIPPING_COMPLETE' = '배송 완료',
  'ORDER_CONFIRM' = '구매 확정',
}

export const shopOrderStautsList = Object.entries(SHOP_ORDER_STATUS).map(
  ([value, name]) => value
);

export enum ORDER_PAY_STATUS {
  'REQUEST' = '요청',
  'REJECT' = '거부&철회',
  'ACCEPT' = '승인',
  'HOLD' = '보류',
  'IN_PROGRESS' = '처리중',
}

// 환불도 동일
export enum ORDER_RETURN_STATUS {
  'REQUEST' = '요청',
  'REJECT' = '거부&철회',
  'ACCEPT' = '완료',
  'HOLD' = '보류',
  'IN_PROGRESS' = '접수',
}

export enum ORDER_SEARCH_TYPE {
  'FETCHING_ORDER_NUMBER' = '페칭 주문번호',
  'SHOP_ORDER_NUMBER' = '편집샵 주문번호',
  'ITEM_ORDER_NUMBER' = '상품 주문번호',
  'INVOICE' = '운송장번호',
  'ORDER_USER_NAME' = '주문자명',
  'ORDER_USER_STRING_ID' = '주문자 ID',
  'ORDER_USER_PHONE_NUMBER' = '주문자 전화번호',
  'RECIPIENT_NAME' = '수령자명',
  'VENDOR_ORDER_NUMBER' = '편집샵 판매처 주문번호',
  'CARD_APPROVAL_NUMBER' = '카드사 승인번호',
  'ITEM_ID' = '페칭코드',
}

export enum ORDER_CANCEL_STATUS {
  'REQUEST' = '요청',
  'REJECT' = '거절',
  'ACCEPT' = '취소 완료',
}

export enum PAY_METHOD {
  'CARD' = '카드결제',
  'BANK' = '계좌이체',
  'VBANK' = '가상계좌',
  'CELLPHONE' = '휴대폰결제',
  'NAVER' = '네이버페이',
  'KAKAO' = '카카오페이',
  'DEPOSIT' = '무통장입금',
}

export const ORDER_DATE_LIST: string[] = [
  '전체',
  '오늘',
  '어제',
  '3일',
  '7일',
  '15일',
  '1개월',
  '3개월',
  '6개월',
];

export enum USER_TYPE {
  'USER' = '일반',
  'VIP' = 'VIP',
  'ANONYMOUS' = '익명',
}

export enum ORDER_STATUS {
  'SHIPPING_COMPLETE' = '배송완료',
  'ORDER_CONFIRM' = '구매확정',
}

export enum ORDER_CANCEL_REASON {
  'SIMPLE_CHANGE_MIND' = '단순변심',
  'SOLD_OUT' = '품절',
  'DELIVERY_DELAY' = '배송지연',
  'DIFFERENCE_COST' = '차액 발생',
  'BENEFIT_UNUSED' = '혜택 미사용',
  'DIRECT_INPUT' = '직접입력',
  // 어드민단
  'OUT_OF_STOCK' = '재고부족',
  'CUSTOMER_REQUEST' = '고객요청',
  'SHOP_ISSUE' = '편집샵이슈',
  'SYSTEM_ISSUE' = '시스템이슈',
  'RE_ORDER' = '재주문',
  'NOT_DEPOSITED' = '무통장 미입금',
  'ETC' = '기타',
}

export enum ORDER_ADDITIONAL_PAY_STATUS {
  'PAID' = '차액결제완료',
  'PENDING_PAY' = '차액결제대기',
  'REJECT' = '차액결제거부&철회',
}

export enum ORDER_ADDITIONAL_PAY_TYPE {
  'PRODUCT_ADD' = '상품추가',
  'PRODUCT_DIFFERENCE' = '차액발생',
  'EXCHANGE_FEE' = '교환수수료',
  'OPTION_CHANGE' = '옵션변경',
}

export enum DELIVERY_STATUS {
  'PRODUCT_PREPARE' = '상품 준비 중',
  'SHIPPING_START' = '배송 시작',
  'IN_WAYPOINT_SHIPPING' = '경유지 배송 중',
  'WAYPOINT_ARRIVAL' = '경유지 도착',
  'DOMESTIC_CUSTOMS_CLEARANCE' = '국내 통관 중',
  'CUSTOMS_CLEARANCE_DELAY' = '통관 지연',
  'IN_DOMESTIC_SHIPPING' = '국내 배송 중',
  'SHIPPING_COMPLETE' = '배송 완료',
  'ORDER_CONFIRM' = '구매 확정',
}

// 반품 사유도 동일.
export enum ORDER_REFUND_REASON {
  'SIMPLE_CHANGE_MIND' = '단순변심',
  'WRONG_DELIVERY' = '오배송',
  'DEFECTIVE_PRODUCT' = '제품하자',
  'TOO_EXPENSIVE' = '너무비쌈',
  'DIRECT_INPUT' = '직접입력',
}

export enum ORDER_EXCHANGE_REASON {
  'DEFECTIVE_PRODUCT' = '제품하자',
  'WRONG_DELIVERY' = '오배송',
  'OPTION_CHANGE' = '옵션변경 (국내샵만)',
  'DIRECT_INPUT' = '직접입력 (국내샵만)',
}

export enum ORDER_WAIT_REASON {
  'DIFFERENCE_COST' = '차액 발생',
  'ETC' = '기타',
}

export enum ORDER_PAY_INFO_STATUS {
  'FIRST' = '최초결제',
  'ADDITIONAL' = '차액결제',
  'EXCHANGE_FEE' = '교환수수료',
  'REFUND' = '환불',
  'PARTIAL_REFUND' = '부분환불',
}

export enum ORDER_DISCOUNT_TYPE {
  'COUPON_DISCOUNT' = '쿠폰할인',
  'POINT_DISCOUNT' = '포인트할인',
  'ETC' = '기타',
}

export enum TAX_PAY_ISSUE {
  'NONE' = '이슈없음',
  'ALREADY_PAID' = '기납부내역',
  'ETC' = '기타',
}

export enum POINT_REFUND_STATUS {
  'NONE' = '반환불가',
  'IN_PROGRESS' = '처리 중',
  'DONE' = '반환완료',
}

export enum TAX_REFUND_STATUS {
  'REQUEST' = '요청',
  'REJECT' = '거부&철회',
  'ACCEPT' = '납부완료',
  'HOLD' = '보류',
  'IN_PROGRESS' = '처리중',
  'NO_DOCUMENT' = '서류준비중',
}

export enum ORDER_SORT {
  'ORDER_DATE_DESC' = '주문일 최신순',
  'ORDER_DATE_ASC' = '주문일 나중순',
}
