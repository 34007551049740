import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { axios, fetchingAPI } from 'api/axios';
import { logoutAction } from 'actions/action';
import { userProfile, logout } from 'assets/images';
import * as style from './style';
import { request, useUser } from 'services';
import { toast } from 'react-toastify';

const UserMenu = (props) => {
  const dispatch = useDispatch();
  const { resetUser } = useUser();
  const { push } = useHistory();

  return (
    <style.Wrapper>
      {/* <style.Item
        color="white"
        onClick={() => {
          // props.history.push('/changePassword');
          alert('마이페이지 준비 중입니다. 🌱');
        }}
      >
        <style.IconImg src={userProfile} />
      </style.Item> */}
      <style.Margin />
      <style.Item
        onClick={() => {
          delete request.defaults.headers.common['Authorization'];
          delete axios.defaults.headers.common['Authorization'];
          delete fetchingAPI.defaults.headers.common['Authorization'];
          localStorage.removeItem('fetchingAPI_AUTH_TOKEN');
          dispatch(logoutAction());
          resetUser();
          push('/login?isLogout=true');
        }}
      >
        <style.IconImg src={logout} />
      </style.Item>
    </style.Wrapper>
  );
};

export default withRouter(UserMenu);
