import React, { useEffect, useState } from 'react';
import querystring from 'querystring';
import { useHistory } from 'react-router-dom';
import url from 'url';
import _ from 'lodash';
import * as style from './style';
import BannerList from './BannerList';
import BannerDetail from './BannerDetail';

const getUrlQuery = () =>
  querystring.parse(url.parse(window.location.href).query);

const MODE = {
  LIST: 'list',
  MANAGE: 'manage',
};

const BannerManagement = () => {
  const [mode, setMode] = useState(MODE.LIST);
  const [platformInfo, setPlatformInfo] = useState(null);
  const { platform, gender } = getUrlQuery();

  const history = useHistory();

  const platformList = [
    {
      id: 1,
      platform: 'PC',
      gender: 'M',
      page: '메인페이지',
      service: '웹사이트',
    },
    {
      id: 2,
      platform: 'PC',
      gender: 'W',
      page: '메인페이지',
      service: '웹사이트',
    },
    {
      id: 3,
      platform: 'MOBILE',
      gender: 'M',
      page: '메인페이지',
      service: '웹사이트',
    },
    {
      id: 4,
      platform: 'MOBILE',
      gender: 'W',
      page: '메인페이지',
      service: '웹사이트',
    },
    {
      id: 5,
      platform: 'PC_TEST',
      gender: 'M',
      page: '메인페이지',
      service: '웹사이트 테스트',
    },
    {
      id: 6,
      platform: 'PC_TEST',
      gender: 'W',
      page: '메인페이지',
      service: '웹사이트 테스트',
    },
    {
      id: 7,
      platform: 'MOBILE_TEST',
      gender: 'M',
      page: '메인페이지',
      service: '웹사이트 테스트',
    },
    {
      id: 8,
      platform: 'MOBILE_TEST',
      gender: 'W',
      page: '메인페이지',
      service: '웹사이트 테스트',
    },
  ];

  const getPlatform = () => {
    const platformInfo = _.find(platformList, { platform, gender });
    if (platformInfo) {
      setPlatformInfo(platformInfo);
    }
  };

  const goPage = (platform, gender) => {
    history.push(`/product/banner?platform=${platform}&gender=${gender}`);
  };

  useEffect(() => {
    if (platform && gender) {
      setMode(MODE.MANAGE);
      getPlatform();
    } else {
      setMode(MODE.LIST);
      setPlatformInfo(null);
    }
  }, [platform, gender]);

  return mode === MODE.LIST ? (
    <style.Container style={{ width: '100%' }}>
      <style.Title>전시관리</style.Title>
      <style.SubTitle>기획전 전시를 설정할 플랫폼을 선택하세요.</style.SubTitle>
      <BannerList items={platformList} goPage={goPage} />
    </style.Container>
  ) : (
    <BannerDetail platformInfo={platformInfo} />
  );
};

export default BannerManagement;
