import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  min-width: 1000px;
  width: 100%;
  height: 100%;
  background-color: #efefef;
`;

export const Inner = styled.div``;
