import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import * as action from 'actions/action';
import { searchTemplate } from './templates';
import { fetchingAPI } from 'api/axios';
import * as style from './style';
import { Alert } from 'antd';

import SearchOption from './SearchOption';
import PromotionList from './PromotionList';
import { Loading, TopBottomButtons } from 'components';
import { compareDate } from 'pages/ExhibitionList/utils';

const PromotionManagement = () => {
  const [loading, setLoading] = useState(false);
  const [originPromotions, setOriginPromotions] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const searchOption = useSelector((store) => store.promotion.searchOption);
  const dispatch = useDispatch();

  const onChange = (key, value) => {
    dispatch(action.updateSearchOption(key, value));
  };

  const initSearchOption = () => {
    dispatch(action.initSearchOption());
    setPromotions(originPromotions);
  };

  const getShopPromotionPriority = async () => {
    try {
      return fetchingAPI.get('/admin/shop-promotion-priority');
    } catch (err) {
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /admin/shop-promotion-priority
      ${err}`);
      console.error(err);
    }
  };

  const combinePromotionsWithPriority = async (list) => {
    const promotionPriorityRes = await getShopPromotionPriority();

    return list.map((d) => {
      const obj = _.find(promotionPriorityRes.data, {
        shopPromotionId: d.id,
      });
      return {
        ...d,
        isPriority: obj ? 1 : 0,
      };
    });
  };

  const getPromotions = async (params = {}) => {
    let result = undefined;
    try {
      setLoading(true);
      const { data } = await fetchingAPI.get('/promotion/v2?isExtended=true', {
        params,
      });

      result = await combinePromotionsWithPriority(data);
      setPromotions(result);
      setOriginPromotions(result);
    } catch (error) {
    } finally {
      setLoading(false);
    }
    return await result;
  };

  const orderPromotion = (items) => {
    setPromotions([...items]);
  };

  const onSearch = ({ targetPromotions = undefined }) => {
    let newArr = [...originPromotions];
    targetPromotions && (newArr = [...targetPromotions]);
    for (const [key, value] of Object.entries(deleteSearchAll(searchOption))) {
      if (key === 'title') {
        newArr = newArr.filter((promotion) => promotion.name.indexOf(value) > -1);
      } else if (key === 'isActive') {
        newArr = newArr.filter((promotion) =>
          promotion.isActive === value ? true : false
        );
      } else if (key === 'updateType') {
        newArr = newArr.filter((promotion) =>
          promotion.isAutomated === value ? true : false
        );
      } else if (key === 'isVIP') {
        newArr = newArr.filter((promotion) => promotion.isVip === value);
      } else if (key === 'appliedAt') {
        newArr = newArr.filter((promotion) => promotion[value] === 1);
      } else if (key === 'shopId') {
        newArr = newArr.filter((promotion) => promotion.shopId === Number(value));
      } else if (key === 'discountType') {
        newArr = newArr.filter((promotion) => promotion.type === value);
      } else if (key === 'assigneeId') {
        newArr = newArr.filter((promotion) => promotion.assigneeId === Number(value));
      } else if (key === 'startedAt') {
        newArr = newArr.filter((promotion) =>
          promotion.endedAt ? compareDate(value, promotion.endedAt) : true
        );
      } else if (key === 'endedAt') {
        newArr = newArr.filter((promotion) =>
          promotion.startedAt ? !compareDate(value, promotion.startedAt) : true
        );
      } else if (key === 'applyStatus') {
        newArr = newArr.filter((promotion) => promotion.applyStatus === value);
      } else if (key === 'displayedAt') {
        newArr = newArr.filter((promotion) => promotion[value] === 1);
      } else if (key == 'isPrioritized') {
        console.log(key, value, 'on Sraetch');
        newArr = newArr.filter((promotion) => promotion[key] === value);
      }
    }
    setPromotions([...newArr]);
  };

  const handleRefresh = async () => {
    const result = await getPromotions();
    onSearch({ targetPromotions: result });
  };

  useEffect(() => {
    getPromotions();
  }, []);
  return (
    <style.Outer>
      {loading && <Loading />}
      <TopBottomButtons />
      <style.ShadowContainer>
        <Alert
          message={
            <>
              <a href="https://admin.fetching.co.kr/products/promotion">새 어드민</a>에서
              개선된 프로모션을 사용하세요.
            </>
          }
          type="warning"
          closable
        />
        <style.PageTitle>프로모션 목록</style.PageTitle>
        <SearchOption
          template={searchTemplate}
          onChange={onChange}
          state={searchOption}
        />
        <style.ButtonContainer>
          <style.SearchButton onClick={onSearch}>검색</style.SearchButton>
          <style.ResetButton onClick={initSearchOption}>초기화</style.ResetButton>
          <style.ResetButton onClick={handleRefresh}>새로고침</style.ResetButton>
        </style.ButtonContainer>
      </style.ShadowContainer>

      <style.ShadowContainer>
        <span>총 {promotions.length} 개</span>
        <PromotionList
          items={promotions}
          orderPromotion={orderPromotion}
          handleRefresh={handleRefresh}
        />
      </style.ShadowContainer>
    </style.Outer>
  );
};

export default PromotionManagement;

const deleteSearchAll = (searchOption) => {
  const copy = { ...searchOption };
  Object.keys(copy).forEach((key) => {
    if (copy[key] === 'all' || copy[key] === '') delete copy[key];
  });
  console.log(copy);
  return copy;
};
