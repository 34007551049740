// @ts-nocheck
import { DropZoneImage, Loading, Outer, ShadowContainer, Table } from 'components';
import { useGetLayouts } from 'pages/ManageMainLayout/utils';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';
import * as S from './style';
import { BigBannerSetType } from './types';
import fetchingAPI from 'api/fetchingAPI';

const dict: any = {
  title: '타이틀',
  subtitle: '서브 텍스트',
  mobileImageUrl: '모바일 이미지',
  pcImageUrl: 'pc 이미지',
  href: '링크',
  startAt: '게시 시작 일시',
  expireAt: '게시 마감 일시',
};

const ModifyBigBanner = ({ mode }: { mode: 'w' | 'm' }) => {
  const layouts = useGetLayouts(mode);
  const history = useHistory();
  const layoutIdx =
    history.location.pathname.split('/')[
      history.location.pathname.split('/').indexOf('modify') - 1
    ];
  const bannerIdx =
    history.location.pathname.split('/')[
      history.location.pathname.split('/').indexOf('modify') + 1
    ];

  const [data, setData] = useState<BigBannerSetType>({
    idx: 0,
    title: '',
    subtitle: '',
    mobileImageUrl: '',
    pcImageUrl: '',
    href: '',
    startAt: '',
    expireAt: '',
  });
  const [finite, setFinite] = useState(true);

  const saveChanges = () => {
    const emptyFields = Object.entries(data)
      .filter(([k, v]) =>
        (k === 'startAt' || k === 'expireAt') && !finite ? false : v === '' || v === null
      )
      .reduce((acc: string[], [k]) => {
        acc.push(dict[k]);
        return acc;
      }, []);

    if (emptyFields.length) {
      alert(`${emptyFields.join(`\n`)}\n\n위 항목을 입력해주세요!`);
    } else {
      const gender = history.location.pathname.includes('/m/') ? 'men' : 'women';
      fetchingAPI
        .postUIComponents(gender, [
          {
            idx: layoutIdx,
            bigBannerList: [
              {
                ...data,
                requestType: 'UPDATE',
              },
            ],
            requestType: 'UPDATE',
          },
        ])
        .then(() => history.goBack())
        .catch((e) => alert(`err in addCopmonent, ${e}`));
    }
  };

  const initData = () => {
    const targetBanner = layouts
      .find((layout) => `${layout.idx}` === layoutIdx)
      ?.bigBannerList?.find((banner) => `${banner.idx}` === bannerIdx);
    if (targetBanner) {
      const {
        idx,
        title,
        subtitle,
        mobileImageUrl,
        pcImageUrl,
        href,
        startAt,
        expireAt,
      } = targetBanner;
      setData({
        idx,
        title,
        subtitle,
        mobileImageUrl,
        pcImageUrl,
        href,
        startAt,
        expireAt,
      });
      !targetBanner.startAt && !targetBanner.expireAt
        ? setFinite(false)
        : setFinite(true);
    }
  };

  useEffect(() => {
    initData();
  }, [layouts]);

  const tables = [
    {
      title: '상세 설정',
      content: [
        {
          left: '게시 기간 설정',
          right: (
            <>
              <Table.Label>
                <Table.Radio
                  checked={!finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                영구
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                설정
              </Table.Label>
            </>
          ),
        },
        {
          left: '게시 일시',
          right: (
            <>
              {finite && (
                <>
                  <Table.InputTime
                    value={data.startAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, startAt: value }))
                    }
                  />
                  {' ~ '}
                  <Table.InputTime
                    style={{ marginLeft: '10px' }}
                    value={data.expireAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, expireAt: value }))
                    }
                  />
                </>
              )}
            </>
          ),
        },
        {
          left: '타이틀',
          right: (
            <Table.TextArea
              placeholder="개행불가"
              value={data.title}
              onChange={({ target: { value } }) =>
                setData((p) => ({ ...p, title: value }))
              }
            />
          ),
        },
        {
          left: '서브 텍스트',
          right: (
            <Table.TextArea
              placeholder="개행불가"
              value={data.subtitle}
              onChange={({ target: { value } }) =>
                setData((p) => ({ ...p, subtitle: value }))
              }
            />
          ),
        },
        {
          left: '링크',
          right: (
            <>
              <Table.TextArea
                value={data.href}
                placeholder="/index.html 형식으로 fetching.co.kr를 제외해주세요"
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, href: value }))
                }
              />
            </>
          ),
        },
      ],
    },
    {
      title: '이미지',
      content: [
        {
          left: '모바일 이미지',
          right: (
            <DropZoneImage
              imageUrl={data.mobileImageUrl}
              setImageUrl={(v: string) => setData((p) => ({ ...p, mobileImageUrl: v }))}
            />
          ),
        },
        {
          left: 'PC 이미지',
          right: (
            <DropZoneImage
              imageUrl={data.pcImageUrl}
              setImageUrl={(v: string) => setData((p) => ({ ...p, pcImageUrl: v }))}
            />
          ),
        },
      ],
    },
  ];

  if (layouts.length === 0) return <Loading />;
  else
    return (
      <Outer style={S.OuterStyle}>
        <ShadowContainer>
          <h4>대배너 상세 설정</h4>
          {tables.map((data) => (
            <Table.MakeTable data={data} key={data.title} tableRef={null} />
          ))}
        </ShadowContainer>
        <ShadowContainer>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
            <Button onClick={saveChanges}>저장하기</Button>
            <Button variant="warning" onClick={initData}>
              초기화
            </Button>
            <Button variant="danger" onClick={() => history.goBack()}>
              닫기
            </Button>
          </div>
        </ShadowContainer>
      </Outer>
    );
};

export default ModifyBigBanner;
