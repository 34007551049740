import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const TotalItem = styled.div`
  border: 1px solid #e9ecef;
  border-radius: 5px;
  text-align: center;
  padding: 5px 10px;
`;

export const TotalTitle = styled.p`
  font-weight: bold;
  color: gray;
  margin-top: 15px;
`;

export const TotalCount = styled.p`
  font-weight: bold;
  color: #968989;
`;
