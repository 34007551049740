import { TableItem } from 'components/UI/molecules';
import React, { useRef, useEffect } from 'react';
import * as style from './style';

const ShopCustomsInfo = ({ shop, handleClinentHeight }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('관부가세 정보', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('관부가세 정보', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>관부가세 정보</style.Title>
      <style.BoxWrapper>
        <TableItem
          title="DDP"
          value={shop?.isDdpService === 1 ? '지원' : '미지원'}
          valueColor={shop?.isDdpService === 1 ? 'green' : 'red'}
        />
        <TableItem
          title="DDP 지원 방식"
          value={
            shop?.isDdpService === 1 && shop?.dutyAndTaxCalculationWay === 0
              ? '상품가 포함'
              : '체크아웃시 합산 결제'
          }
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem
          title="관부가세 통합"
          value={
            shop?.isDutyAndTaxSeparately === 1
              ? '통합(관세 + 부가세 합산 가격으로 계산)'
              : 'x'
          }
        />
        <TableItem
          title="관부가세 계산 방식"
          value={
            shop?.dutyAndTaxCalculationWay === 1
              ? '상품가에 포함'
              : '상품가에 미포함'
          }
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem title="면세조건" value={`${shop?.minimumTaxUSD} 이하`} />
        <TableItem
          title="원산지 증명"
          value={shop?.proofOrigin === 1 ? '지원' : '미지원'}
          valueColor={shop?.proofOrigin === 1 ? 'green' : 'red'}
        />
      </style.BoxWrapper>
    </style.Wrapper>
  );
};
export default ShopCustomsInfo;
