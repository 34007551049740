import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: 20px;
`;

export const Button = styled.button`
  border: none;
  border-radius: 4px;
  padding: 7px 12px;
  color: white;
  background-color: ${(props) => (props.BC ? props.BC : '#343a40')};
  margin-top: 10px;
  margin-right: 10px;
  &:focus {
    outline: none;
  }
`;
