import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 5px;
`;

export const Total = styled.div`
  background-color: #1a1a1a;
  text-align: center;
  padding: 10px;
`;

export const CountWrapper = styled.div`
  display: flex;
  background-color: #cfcfcf;
  padding: 5px;
`;

export const Count = styled.div`
  width: 50%;
  text-align: center;
  padding: 10px;
`;

export const Pin = styled.div`
  width: 1px;
  background-color: gray;
`;

export const Text = styled.p`
  color: ${(props) => props.color};
  margin: 0;
`;
