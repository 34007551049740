import { Main } from 'pages/ManageMainLayout/utils';

export const isManagedOnAdmin = (type: Main.Type): boolean => {
  const dict: { [key in Main.Type]: boolean } = {
    BIG_BANNER: true,
    MAIN_CATEGORIES: false,
    RECOMMEND_CATEGORY: false,
    TEXT_BAND_BANNER: true,
    IMAGE_BAND_BANNER: true,
    PROMOTION_INFO: false,
    FETCHING_DEAL: true,
    DESIGN_COMPONENT_WITH_IMAGE: true,
    DESIGN_COMPONENT_WITHOUT_IMAGE: true,
    RECOMMEND_BRAND: false,
    SELECT_SHOPS: true,
    FETCHING_RANKING: false,
    BRAND_RANKING: false,
    TIME_DEAL: false,
    POPULAR_BRANDS: false,
    SHOWCASE: false,
    DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY: true,
    DESIGN_COMPONENT_WITH_IMAGE_CATEGORY: true,
    NEW_EXHIBITION: false,
    NEW_IN: false,
    REVIEW: false,
  };
  return dict[type];
};
