import React, { useContext, useState } from 'react';
import { LoadingContext } from 'context';
import { fetchingAPI } from 'api/axios';
import {
  ItemTable,
  Loading,
  SavedOption,
  SearchButtonGroup,
  SearchOptionTable,
  TopBottomButtons,
} from 'components';
import Pagination from '../ManageCatalog/CatalogSearchOptionTable/Pagination';
import * as style from './style';
import initialState from './initState';
import { trimParams } from './utils';
import { Alert } from 'antd';

const ProductList = () => {
  const [searchOptionTableState, setSearchOptionTableState] = useState(initialState);
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, handleIsLoading } = useContext(LoadingContext);
  const fetchItems = async (page = 1, callback = () => {}) => {
    handleIsLoading(true);

    const params = trimParams(searchOptionTableState);
    params.page = page;

    if ('needCount' in params) {
      delete params.needCount;
      try {
        const { data } = await fetchingAPI.get('/item/count', { params });
        setTotalCount(Number(data.data));
      } catch (err) {
        alert(`error in fetchItems(), getCount, /productList, ${err.message}`);
      }
    }

    try {
      const { data } = await fetchingAPI.get('/item/v2', { params });
      setItems(data.data);
      setCurrentPage(page);
    } catch (err) {
      alert(`error in fetchItems(), /productList, ${err.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  return (
    <style.Outer>
      {isLoading && <Loading />}
      <TopBottomButtons />
      <style.ShadowContainer>
        <Alert
          message={
            <>
              <a href="https://admin.fetching.co.kr/products/item?tab=1">새 어드민</a>에서
              개선된 상품 검색을 사용하세요.
            </>
          }
          type="warning"
          closable
        />
        <style.PageTitle>상품목록</style.PageTitle>
        <SearchOptionTable
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
        <SearchButtonGroup
          fetchItems={fetchItems}
          setSearchOptionTableState={setSearchOptionTableState}
          initialState={initialState}
          setItems={setItems}
        />
      </style.ShadowContainer>
      <style.ShadowContainer>
        <ItemTable
          items={items}
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
          fetchItems={fetchItems}
          currentPage={currentPage}
          totalCount={totalCount}
          trimParams={trimParams}
        />
        <Pagination fetchItems={fetchItems} currentPage={currentPage} />
      </style.ShadowContainer>
    </style.Outer>
  );
};
export default ProductList;
