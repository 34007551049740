import React, { useState } from "react";
import * as style from "./style";

const Pagination = ({ fetchItems, currentPage }) => {

  const [base, setBase] = useState(Math.floor((currentPage - 1) / 10) * 10);

  return (
    <style.Conatiner>
      <style.Wrapper>
        <style.PageButton
          onClick={() => {
            if (base > 0) {
              setBase(base - 10);
            }
          }}
        >
          {"<"}
        </style.PageButton>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
          <style.NumberButton
            key={num}
            onClick={(e) => {
              fetchItems(base + num);
              window.scrollTo(0, 0);
            }}
            current={currentPage === base + num}
          >
            {base + num}
          </style.NumberButton>
        ))}
        <style.PageButton
          onClick={(e) => {
            setBase(base + 10);
          }}
        >
          {">"}
        </style.PageButton>
      </style.Wrapper>
    </style.Conatiner>
  );
};

export default Pagination;


export const PaginationSetOption = ({ options = {}, setOptions = () => { } }) => {

  const [base, setBase] = useState(Math.floor((options.page - 1) / 10) * 10);

  return (
    <style.Conatiner>
      <style.Wrapper>
        <style.PageButton
          onClick={() => {
            if (base > 0) {
              setBase(base - 10);
            }
          }}
        >
          {"<"}
        </style.PageButton>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
          <style.NumberButton
            key={num}
            onClick={(e) => {
              setOptions(p => ({ ...p, page: base + num }))
              window.scrollTo(0, 0);
            }}
            current={options.page === base + num}
          >
            {base + num}
          </style.NumberButton>
        ))}
        <style.PageButton
          onClick={(e) => {
            setBase(base + 10);
          }}
        >
          {">"}
        </style.PageButton>
      </style.Wrapper>
    </style.Conatiner>
  );
}