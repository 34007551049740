import styled from 'styled-components';

export const Container = styled.div``;
export const Input = styled.select`
  margin-right: 10px;
`;
export const Button = styled.button`
  border: none;
  color: #ff6f61;
  padding: 3px 10px;
  background: #3d3d3d;
  border-radius: 2px;
`;
