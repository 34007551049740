import React from 'react';
import * as style from './style';
import { reset } from '../../../../assets/images';

const OptionCheckboxListBrands = ({
  brands,
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const dict = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'N',
    'M',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const brandIdList = searchOptionTableState.brandIdList;
  const setBrandIdList = (brandId) => {
    const loc = brandIdList.indexOf(brandId);
    let newbrandIdList = [];
    if (loc === -1) {
      newbrandIdList = [...brandIdList, brandId];
    } else {
      newbrandIdList = [
        ...brandIdList.slice(0, loc),
        ...brandIdList.slice(loc + 1),
      ];
    }
    setSearchOptionTableState({
      ...searchOptionTableState,
      brandIdList: newbrandIdList,
    });
  };

  return (
    <style.Container>
      <style.Title>
        <style.TitleSpan>브랜드</style.TitleSpan>
        <style.ResetButton
          src={reset}
          onClick={() => {
            setSearchOptionTableState({
              ...searchOptionTableState,
              brandIdList: [],
            });
          }}
        />
      </style.Title>
      <style.Content>
        <style.CheckboxContainer>
          <style.Checkbox
            onClick={() => {
              if (brandIdList.length === brands.length) {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  brandIdList: [],
                });
              } else {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  brandIdList: brands.map((obj) => obj.id),
                });
              }
            }}
            checked={brandIdList.length === brands.length}
          />
          <style.Label>전체선택</style.Label>
        </style.CheckboxContainer>
        {brands.filter((obj) => !dict.includes(obj.name[0])).length > 0 && (
          <style.Initial>123</style.Initial>
        )}
        {brands
          .filter((obj) => !dict.includes(obj.name[0]))
          .map((obj) => (
            <style.CheckboxContainer key={`brandsContainer${obj.name}`}>
              <style.Checkbox
                onClick={() => setBrandIdList(obj.id)}
                checked={brandIdList.includes(obj.id)}
              />
              <style.Label>{obj.name}</style.Label>
            </style.CheckboxContainer>
          ))}
        {dict.map(
          (initial) =>
            brands.filter((obj) => obj.name[0] === initial).length > 0 && (
              <>
                <style.Initial key={`brandsInitial${initial}`}>
                  {initial}
                </style.Initial>
                {brands
                  .filter((obj) => obj.name[0] === initial)
                  .map((obj) => (
                    <style.CheckboxContainer key={`brandsContainer${obj.name}`}>
                      <style.Checkbox
                        onClick={() => setBrandIdList(obj.id)}
                        checked={brandIdList.includes(obj.id)}
                      />
                      <style.Label>{obj.name}</style.Label>
                    </style.CheckboxContainer>
                  ))}
              </>
            ),
        )}
      </style.Content>
    </style.Container>
  );
};

export default OptionCheckboxListBrands;
