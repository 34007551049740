import dayjs from 'dayjs';
import { request } from 'services';

export const dayjsFormat = (date: dayjs.Dayjs) => date.format('YYYY-MM-DD');

export const range = (start: any, end: any) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const isNumber = (data: any): boolean => !isNaN(Number(data));

export const getAge = (birth: string): string | number => {
  if (typeof birth !== 'number') return '';
  return dayjs().diff(dayjsFormat(dayjs(birth)), 'year') + 1;
};

export const fileUpload = async (file: File): Promise<string | undefined> => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const { fileUrl } = await request.post<any, { fileUrl: string }>(
      '/util/file-upload',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return fileUrl;
  } catch (err) {
    console.log(err);
  }
};

export const enumToList = (enumObj: any) =>
  Object.entries<string>(enumObj).map(([value, name]) => ({
    value,
    name,
  }));

export const getKey = (path: string, params: object) => {
  const search = new URLSearchParams();
  Object.entries(params)
    .filter(([k, v]) => v !== null && v !== false && v !== '' && v !== undefined)
    .forEach(([k, v]) => {
      typeof v === 'object' && 'length' in v
        ? v.forEach((v) => search.append(`${k}[]`, v))
        : search.set(k, v);
    });
  return path + '?' + search;
};
