import React, { useState, useContext } from 'react';
import { ModalContext } from 'context';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Item } from 'components';
import * as style from './style';

const Form = ({ title, children, grid, color }) => (
  <style.ItemWrapper>
    <style.ItemTitle color={color}>{title}</style.ItemTitle>
    <style.Item column={grid}>{children}</style.Item>
  </style.ItemWrapper>
);

export default function Classification({
  product,
  scrollRef,
  category,
  exhibitions,
  shopPromotions,
  handleDeleteCategory,
  handleDeleteExhibition,
  handleDeleteShopPromotion,
  mode,
}) {
  const { handleModalShow } = useContext(ModalContext);
  const [isActive, setIsActive] = useState(true);

  const handleShowContent = () => {
    isActive ? setIsActive(false) : setIsActive(true);
  };

  const formList = [
    {
      title: '편집샵 카테고리',
      component: <style.Text>{product?.shopCategoryName}</style.Text>,
      mode: ['update'],
    },
    {
      title: '페칭 카테고리',
      grid: 'column',
      mode: ['update', 'create'],
      component: (
        <>
          <style.AddButton
            onClick={() => {
              handleModalShow('카테고리', true);
            }}
          >
            + 추가
          </style.AddButton>
          {category &&
            category.map((el) => {
              return (
                <Item
                  key={el.fetchingCategoryId}
                  id={el.fetchingCategoryId}
                  name={el.fetchingCategoryPath}
                  handleDelete={handleDeleteCategory}
                />
              );
            })}
        </>
      ),
    },
    {
      title: '기획전',
      grid: 'column',
      mode: ['update'],
      component: (
        <>
          <style.AddButton
            onClick={() => {
              handleModalShow('기획전', true);
            }}
          >
            + 추가
          </style.AddButton>
          {exhibitions &&
            exhibitions.map((el) => {
              const name = el.gender + '>' + el.name;
              return (
                <Item
                  key={el.exhibitionId}
                  id={el.exhibitionId}
                  name={name}
                  handleDelete={handleDeleteExhibition}
                />
              );
            })}
        </>
      ),
    },
    {
      title: '편집샵 프로모션',
      grid: 'column',
      mode: ['update'],
      component: (
        <>
          <style.AddButton
            onClick={() => {
              handleModalShow('프로모션', true);
            }}
          >
            + 추가
          </style.AddButton>
          {shopPromotions &&
            shopPromotions.map((el) => {
              const name = [
                el.isActive ? 'ON' : 'OFF',
                el.isAppliedWith,
                el.code,
                el.name,
                el.typeValue > 1 ? el.typeValue : `${Math.round(el.typeValue * 100)}%`,
              ];
              if (el.startedAt || el.endedAt) {
                name.push(
                  `(${
                    el.startedAt
                      ? new Date(el.startedAt).toLocaleString('ko-KR', {
                          timeZone: 'utc',
                        })
                      : ''
                  } ~ ${
                    el.endedAt
                      ? new Date(el.endedAt).toLocaleString('ko-KR', {
                          timeZone: 'utc',
                        })
                      : ''
                  })`
                );
              }
              return (
                <Item
                  key={el.shopPromotionId}
                  id={el.shopPromotionId}
                  name={name.filter((str) => str).join(' - ')}
                  url={el.url}
                  handleDelete={handleDeleteShopPromotion}
                />
              );
            })}
        </>
      ),
    },
  ];

  return (
    <style.Wrapper>
      <style.TitleWrapper ref={scrollRef}>
        <style.Title>분류 정보</style.Title>
        {isActive ? (
          <FiChevronUp style={style.Icon} onClick={handleShowContent} />
        ) : (
          <FiChevronDown style={style.Icon} onClick={handleShowContent} />
        )}
      </style.TitleWrapper>
      {isActive && (
        <style.Content>
          {formList
            .filter((list) => list.mode.indexOf(mode) > -1)
            .map(({ title, grid, component, color }, index) => (
              <Form key={index} title={title} grid={grid} color={color}>
                {component}
              </Form>
            ))}
        </style.Content>
      )}
    </style.Wrapper>
  );
}
