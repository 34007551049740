import React from 'react';
import * as style from './style';
import { reset } from '../../../../assets/images';

const OptionCheckboxListExhibitions = ({
  exhibitions,
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const { exhibitionIdList } = searchOptionTableState;

  const dict = ['W', 'M'];
  return (
    <style.Container>
      <style.Title>
        <style.TitleSpan>기획전</style.TitleSpan>
        <style.ResetButton
          src={reset}
          onClick={() => {
            setSearchOptionTableState({
              ...searchOptionTableState,
              exhibitionIdList: [],
            });
          }}
        />
      </style.Title>
      <style.Content>
        <style.CheckboxContainer>
          <style.Checkbox
            onClick={() => {
              if (exhibitions.length === exhibitionIdList.length) {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  exhibitionIdList: [],
                });
              } else {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  exhibitionIdList: exhibitions.map(({ id }) => id),
                });
              }
            }}
            checked={exhibitions.length === exhibitionIdList.length}
          />
          <style.Label>전체선택</style.Label>
        </style.CheckboxContainer>
        {dict.map((gender) => (
          <>
            <style.Initial>{gender}</style.Initial>
            {exhibitions
              .filter((obj) => obj.gender === gender)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((obj, index) => (
                <style.CheckboxContainer key={index}>
                  <style.Checkbox
                    onClick={() => {
                      const exhibitionSet = new Set(exhibitionIdList);
                      if (exhibitionSet.has(obj.id)) {
                        exhibitionSet.delete(obj.id);
                        setSearchOptionTableState({
                          ...searchOptionTableState,
                          exhibitionIdList: [...exhibitionSet.values()],
                        });
                      } else {
                        setSearchOptionTableState({
                          ...searchOptionTableState,
                          exhibitionIdList: [
                            ...exhibitionSet.add(obj.id).values(),
                          ],
                        });
                      }
                    }}
                    checked={exhibitionIdList.includes(obj.id)}
                  />
                  <style.Label>{obj.name}</style.Label>
                </style.CheckboxContainer>
              ))}
          </>
        ))}
      </style.Content>
    </style.Container>
  );
};

export default OptionCheckboxListExhibitions;
