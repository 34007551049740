import React, { useCallback } from 'react';
import {
  PriorityChangeModal,
  ItemShowModal,
  Cafe24UploadModal,
  ColorChangeModal,
  OriginChangeModal,
  ExhibitionTabModal,
  AgencyFeeRateChangeModal,
  DeleteUserModal,
  ChangeUserLevelModal,
  AdminMemoModal,
  PromotionModal,
} from 'components';
import { allDeletePromotionOptionAction } from 'actions/action';
import { useDispatch } from 'react-redux';
import * as style from './style';
import DownloadInfoModal from '../DownloadInfoModal';
import ProductGroupModal from '../ProductGroupModal';

const Modals = ({
  showModal,
  setShowModal,
  fetchItems,
  currentPage,
  checkedItemIds,
  searchOptionTableState,
  trimParams,
  checkedItemCafe24Ids,
  setCheckedItemIds,
  setCheckedItemCafe24Ids,
  userNames,
}) => {
  const dispatch = useDispatch();
  const {
    PriorityChange,
    ItemShow,
    Exhibition,
    ColorChange,
    Promotion,
    AgencyFeeRateChange,
    OriginChange,
    Download,
    Cafe24Upload,
    ExhibitionTab,
    targetIds,
    ProductGroup,
    DeleteUser,
    ChangeUserLevel,
    AdminMemo,
    targetId,
  } = showModal;

  const containChecked = useCallback(() => {
    setCheckedItemIds(checkedItemIds);
    setCheckedItemCafe24Ids(checkedItemCafe24Ids);
  }, [setCheckedItemIds, setCheckedItemCafe24Ids, checkedItemIds, checkedItemCafe24Ids]);

  return (
    <style.Container>
      <PriorityChangeModal
        show={PriorityChange}
        onHide={() => {
          setShowModal({
            ...showModal,
            PriorityChange: false,
          });
          fetchItems(currentPage, containChecked);
        }}
        targetIds={targetIds}
      />
      <ItemShowModal
        show={ItemShow}
        onHide={() => {
          setShowModal({
            ...showModal,
            ItemShow: false,
          });
          fetchItems(currentPage, containChecked);
        }}
        targetIds={targetIds}
      />
      <ColorChangeModal
        show={ColorChange}
        onHide={() => {
          setShowModal({
            ...showModal,
            ColorChange: false,
          });
        }}
        targetIds={targetIds}
      />
      <AgencyFeeRateChangeModal
        show={AgencyFeeRateChange}
        onHide={() => {
          setShowModal({
            ...showModal,
            AgencyFeeRateChange: false,
          });
        }}
        targetIds={targetIds}
      />
      <PromotionModal
        isOpen={Promotion}
        onClose={() => {
          setShowModal({
            ...showModal,
            Promotion: false,
          });
          dispatch(allDeletePromotionOptionAction());
          fetchItems(currentPage, containChecked);
        }}
        targetIds={targetIds}
      />
      <Cafe24UploadModal
        show={Cafe24Upload}
        onHide={() => {
          setShowModal({
            ...showModal,
            Cafe24Upload: false,
          });
          // fetchItems(currentPage, containChecked);
        }}
        targetIds={targetIds}
      />
      <DownloadInfoModal
        show={Download}
        onHide={() => {
          setShowModal({
            ...showModal,
            Download: false,
          });
        }}
        checkedItemIds={checkedItemIds}
        checkedItemCafe24Ids={checkedItemCafe24Ids}
        searchOptionTableState={searchOptionTableState}
        trimParams={trimParams}
      />
      {OriginChange && (
        <OriginChangeModal
          onHide={() => {
            setShowModal({
              ...showModal,
              OriginChange: false,
            });
          }}
          targetIds={targetIds}
        />
      )}
      <ExhibitionTabModal
        show={ExhibitionTab}
        onHide={() => {
          setShowModal({
            ...showModal,
            ExhibitionTab: false,
          });
        }}
        targetIds={targetIds}
      />
      <ProductGroupModal
        show={ProductGroup}
        onHide={() => {
          setShowModal({
            ...showModal,
            ProductGroup: false,
          });
        }}
        targetIds={targetIds}
      />
      {!!DeleteUser && (
        <DeleteUserModal
          show={DeleteUser}
          onHide={() => {
            setShowModal({
              ...showModal,
              DeleteUser: false,
            });
          }}
          targetIds={targetIds}
          userNames={userNames}
          fetchItems={fetchItems}
        />
      )}
      {!!ChangeUserLevel && (
        <ChangeUserLevelModal
          show={ChangeUserLevel}
          onHide={() => {
            setShowModal({
              ...showModal,
              ChangeUserLevel: false,
            });
          }}
          fetchItems={fetchItems}
          targetIds={targetIds}
        />
      )}
      {!!AdminMemo && (
        <AdminMemoModal
          show={AdminMemo}
          onHide={() => {
            setShowModal({
              ...showModal,
              AdminMemo: false,
            });
          }}
          targetId={targetId}
        />
      )}
    </style.Container>
  );
};
export default Modals;
