import React from 'react';
import Button from 'react-bootstrap/Button';
import { IoIosSync, IoIosTrash } from 'react-icons/io';
import * as style from './style';

const MappingButtons = ({ handleMapping, handleDeleteMapping }) => {
  return (
    <style.Container>
      <Button
        style={style.MappingButton}
        variant="outline-secondary"
        onClick={handleMapping}
      >
        <IoIosSync /> 매핑 실행
      </Button>
      <Button variant="outline-secondary" onClick={handleDeleteMapping}>
        <IoIosTrash /> 매핑 삭제
      </Button>
    </style.Container>
  );
};
export default MappingButtons;
