import { fetchingAPI } from 'api/axios';
import { LoadingContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import * as S from './style';

const OrderItemTable = ({ items }) => {
  const csManual =
    'https://docs.google.com/spreadsheets/d/1cZrfPXaaE2wsxNr2tLeh08Nwe0h4Ui9mSWT__BIr0aY/edit?usp=sharing';

  return (
    <S.Table>
      <S.Thead style={{ fontSize: '12px' }}>
        <S.Tr>
          <th colSpan={9}>
            <a href={csManual} target="_blank" rel="noreferrer">
              <S.ManualButton>응대 매뉴얼 바로가기</S.ManualButton>
            </a>
          </th>
        </S.Tr>
      </S.Thead>
      <S.Tbody>
        <S.Tr style={{ background: '#E4E4E4' }}>
          <S.Td w={30}>
            <S.Checkbox />
          </S.Td>
          <S.Td w={120}>페칭 코드</S.Td>
          <S.Td w={120}>편집샵 코드</S.Td>
          <S.Td w={120}>카페24 ID</S.Td>
          <S.Td w={270}>상품정보</S.Td>
          <S.Td w={230}>고객 주문 가격</S.Td>
          <S.Td w={350}>실시간 가격 계산기</S.Td>
          <S.Td w={170}>차액이 발생했나요?</S.Td>
        </S.Tr>
        {items.map((item) => (
          <OrderItemTableItem key={item.id} item={item} />
        ))}
      </S.Tbody>
    </S.Table>
  );
};

export default OrderItemTable;

const OrderItemTableItem = ({ item }) => {
  const [price, setPrice] = useState({});
  const { isloading, handleIsLoading } = useContext(LoadingContext);

  ///// 내부 상태 관리
  const initialState = {
    excludedPromotions: null,
    price: '',
  };
  const [checkParams, setCheckParams] = useState(initialState);

  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [promotionType, setPromotionType] = useState('RATE');
  const [fixedRate, setFixedRate] = useState(0);
  const [fixedAmount, setFixedAmount] = useState(0);
  const [fixedPrice, setFixedPrice] = useState(0);
  const [isFreeDelivery, setIsFreeDelivery] = useState(false);
  const [dutyAndTax, setDutyAndTax] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [agencyFee, setAgencyFee] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [ruleId, setRuleId] = useState(null);

  ///// 이벤트 리스너
  const onChangeInput = (e) => {
    setDiscountedPrice(parseInt(e.target.value || '0'));
  };

  const fetchData = async () => {
    const { data } = await fetchingAPI.get(`/item/${id}/prices`, {
      params: { isActive: true },
    });
    setPrice(data[0]);
    setDiscountedPrice(data[0].discountedPrice);
    setFixedRate(Math.round((1 - data[0].fixedPrice / data[0].discountedPrice) * 100));
    setFixedAmount(data[0].discountedPrice - data[0].fixedPrice);
    setFixedAmount(data[0].discountedPrice - data[0].fixedPrice);
    setRuleId(data[0].ruleId);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let newFixedPrice;
    if (promotionType === 'RATE') {
      newFixedPrice = discountedPrice * (1 - fixedRate / 100);
    } else {
      newFixedPrice = discountedPrice - fixedAmount;
    }
    setFixedPrice(newFixedPrice);
    const newFixedPriceKor =
      Math.ceil((newFixedPrice * (price.fixedPriceKor / price.fixedPrice || 1)) / 100) *
      100;
    const newDutyAndTax =
      Math.ceil(
        (newFixedPriceKor * (price.dutyAndTax / price.fixedPriceKor || 0)) / 100
      ) * 100;
    console.log(newDutyAndTax);
    setDutyAndTax(newDutyAndTax);
    const newTotalPrice =
      newFixedPriceKor + newDutyAndTax + (isFreeDelivery ? 0 : price.deliveryFee || 0);
    setTotalPrice(newTotalPrice);
    const newAgencyFee = Math.ceil((newTotalPrice * price.agencyFeeRate) / 100) * 100;
    setAgencyFee(newAgencyFee);
    const newFinalPrice = newTotalPrice + newAgencyFee + (price.additionalPrice || 0);
    setFinalPrice(newFinalPrice);
  }, [discountedPrice, promotionType, fixedRate, fixedAmount, isFreeDelivery]);

  const {
    code,
    url,
    cafe24Id,
    imageUrl,
    shopId,
    shopName,
    shopCountry,
    brand,
    name,
    id,
    cafe24Price,
    currency,
    updatedAt,
    fetchingPromotion,
  } = item;

  const pricePromotion =
    fetchingPromotion?.filter(({ promotionType }) => promotionType !== 'FREE_DELIVERY') ||
    [];
  const deliveryPromotion =
    fetchingPromotion?.filter(({ promotionType }) => promotionType === 'FREE_DELIVERY') ||
    [];
  const referenceDate = new Date();
  referenceDate.setDate(referenceDate.getDate() - 1);

  const cafe24Link = 'https://fetching.co.kr/product/detail.html?product_no=';
  const productDetailLink = 'https://admin.fetching.work/product/productDetail/v2/';

  return (
    <S.Tr>
      <S.Td w={30}>
        <S.Checkbox />
      </S.Td>
      <S.Td w={120}>
        <span>{id}</span>
        <S.Notice>
          {new Date(updatedAt) < referenceDate && (
            <>
              상품이 업데이트 된지 24시간이 지났어요!
              <br />
              슬랙 긴급제보에 페칭코드와 함께 공유해주세요.
            </>
          )}
        </S.Notice>
      </S.Td>
      <S.Td w={120}>
        <span>{code}</span>
        {url && (
          <a href={url} target="_blank" rel="noreferrer">
            재고확인
          </a>
        )}
        {ruleId && ruleId !== shopId && (
          <S.Notice>
            국가 설정 {shopCountry}로 하세요!
            {price.additionalFee && (
              <>
                <br />
                엘덱스 배대지 주문입니다.
              </>
            )}
          </S.Notice>
        )}
      </S.Td>
      <S.Td w={120}>
        <span>{cafe24Id}</span>
        <a href={cafe24Link + cafe24Id} target="_blank" rel="noreferrer">
          카페24 링크
        </a>
      </S.Td>
      <S.InfoTd w={270}>
        <S.Img src={imageUrl} />
        <div>
          <span>
            {shopName} {shopCountry}
          </span>
          <br />
          <span>{brand}</span>
          <br />
          <span>{name}</span>
          <br />
          <a href={productDetailLink + id} target="_blank" rel="noreferrer">
            상품상세
          </a>
        </div>
      </S.InfoTd>
      <S.Td w={230}>
        <S.Row>
          <S.Name>원가</S.Name>
          <S.Value>
            {Number(price.originPrice || 0).toLocaleString()} {price.currency}
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Name>편집샵 자체 할인가</S.Name>
          <S.Value>
            {Number(price.discountedPrice || 0).toLocaleString()} {price.currency} (
            {(price.discountRate || 0) * 100}%)
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Name>편집샵 프로모션</S.Name>
          <S.Value>
            - {Number(price.discountedPrice - price.fixedPrice || 0).toLocaleString()}{' '}
            {price.currency} (
            {Math.round((1 - price.fixedPrice / price.discountedPrice) * 100)}%)
          </S.Value>
        </S.Row>
        {pricePromotion?.length ? (
          <S.Row>
            <S.Value>
              {pricePromotion.map(
                ({ promotionId, promotionName, promotionType, promotionTypeValue }) => {
                  let typeValue;
                  if (promotionType === 'DISCOUNT_WITH_RATE') {
                    typeValue = `${Math.round(promotionTypeValue * 100)}%`;
                  } else {
                    typeValue = `${promotionTypeValue} ${currency}`;
                  }
                  return (
                    <a
                      href={`/product/modifyPromotion/${promotionId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${promotionName} (${typeValue})`}
                    </a>
                  );
                }
              )}
            </S.Value>
          </S.Row>
        ) : null}
        <S.Row>
          <S.Name>편집샵 상품가</S.Name>
          <S.Value>
            = {Number(price.fixedPrice || 0).toLocaleString()} {price.currency}
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Name>환율 계산</S.Name>
          <S.Value>= {Number(price.fixedPriceKor || 0).toLocaleString()}원</S.Value>
        </S.Row>
        <S.Row>
          <S.Name>관부가세</S.Name>
          <S.Value>+ {Number(price.dutyAndTax || 0).toLocaleString()}원</S.Value>
        </S.Row>
        <S.Row>
          <S.Name>배송비</S.Name>
          <S.Value>+ {Number(price.deliveryFee || 0).toLocaleString()}원</S.Value>
        </S.Row>
        {deliveryPromotion?.length ? (
          <S.Row>
            <S.Value>
              {deliveryPromotion.map(
                ({ promotionId, promotionName, promotionTypeValue }) => (
                  <a
                    href={`/product/modifyPromotion/${promotionId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {`${promotionName} (${promotionTypeValue}원 이상)`}
                  </a>
                )
              )}
            </S.Value>
          </S.Row>
        ) : null}
        <S.Row>
          <S.Name>편집샵 판매가</S.Name>
          <S.Value>= {Number(price.totalPrice || 0).toLocaleString()}원</S.Value>
        </S.Row>
        <S.Row />
        <S.Row>
          <S.Name>페칭 수수료</S.Name>
          <S.Value>
            + {Number(price.agencyFee || 0).toLocaleString()}원 (
            {(price.agencyFeeRate || 0) * 100}%)
          </S.Value>
        </S.Row>
        <S.Row>
          <S.Name>추가 비용 (배대지)</S.Name>
          <S.Value>+ {Number(price.additionalPrice || 0).toLocaleString()}원</S.Value>
        </S.Row>
        <S.Row>
          <S.Name>페칭 판매가</S.Name>
          <S.Value>{Number(price.finalPrice).toLocaleString()}원</S.Value>
        </S.Row>
        {finalPrice !== price.finalPrice && <S.Row />}
        {finalPrice !== cafe24Price && <S.Row />}
      </S.Td>
      <S.Td w={350}>
        <S.Row>
          <S.Description>원가가 아닌 자체 할인이 반영된 이후 가격을 입력</S.Description>
        </S.Row>
        <S.Row>
          <S.CalculatorValue>
            <S.OptionAndTextGroup>
              <S.Input onChange={onChangeInput} value={discountedPrice} /> {currency}
            </S.OptionAndTextGroup>
          </S.CalculatorValue>
        </S.Row>
        <S.Row>
          <S.CalculatorValue>
            - {Number(discountedPrice - fixedPrice).toLocaleString()} {price.currency}
          </S.CalculatorValue>
          <S.CalculatorOption>
            <select
              onChange={(e) => setPromotionType(e.target.value)}
              value={promotionType}
            >
              <option value="RATE">% 할인</option>
              <option value="AMOUNT">금액 할인</option>
            </select>
            <S.PromotionInput
              onChange={(e) => {
                const value = parseFloat(e.target.value) || 0;
                promotionType === 'RATE' ? setFixedRate(value) : setFixedAmount(value);
              }}
              value={promotionType === 'RATE' ? fixedRate : fixedAmount}
            />{' '}
          </S.CalculatorOption>
        </S.Row>
        {pricePromotion?.length ? <S.Row /> : null}
        <S.Row>
          <S.CalculatorValue>
            = {Number(fixedPrice).toLocaleString()} {currency}
          </S.CalculatorValue>
        </S.Row>
        <S.Row>
          <S.CalculatorValue>
            ={' '}
            {Number(
              Math.ceil(
                (fixedPrice * (price.fixedPriceKor / price.fixedPrice || 1)) / 100
              ) * 100
            ).toLocaleString()}
            원
          </S.CalculatorValue>
        </S.Row>
        <S.Row>
          <S.CalculatorValue>+ {Number(dutyAndTax).toLocaleString()}원</S.CalculatorValue>
        </S.Row>
        <S.Row>
          <S.CalculatorValue>
            + {Number(isFreeDelivery ? 0 : price.deliveryFee || 0).toLocaleString()}원
          </S.CalculatorValue>
          <S.CalculatorOption>
            <select
              onChange={(e) => setIsFreeDelivery(e.target.value === 'true')}
              value={`${isFreeDelivery}`}
            >
              <option value="true">무료 배송</option>
              <option value="false">유료 배송</option>
            </select>
          </S.CalculatorOption>
        </S.Row>
        {deliveryPromotion?.length ? <S.Row /> : null}
        <S.Row>
          <S.CalculatorValue>= {Number(totalPrice).toLocaleString()}원</S.CalculatorValue>
        </S.Row>
        <S.Row />
        <S.Row>
          <S.CalculatorValue>
            + {Number(agencyFee).toLocaleString()}원 ({(price.agencyFeeRate || 0) * 100}%)
          </S.CalculatorValue>
        </S.Row>
        <S.Row>
          <S.CalculatorValue>
            + {Number(price.additionalPrice || 0).toLocaleString()}원
          </S.CalculatorValue>
        </S.Row>
        <S.Row>
          <S.CalculatorValue>{Number(finalPrice).toLocaleString()}원</S.CalculatorValue>
        </S.Row>
        {finalPrice !== price.finalPrice && (
          <S.Row>
            <S.CalculatorValue>
              <S.Notice>
                {Number(finalPrice - (price.finalPrice || 0)).toLocaleString()}원 차액
                발생
              </S.Notice>
            </S.CalculatorValue>
          </S.Row>
        )}
        {finalPrice !== cafe24Price && (
          <S.Row>
            <S.CalculatorValue>
              <S.Notice>
                카페24 현재 가격({Number(cafe24Price).toLocaleString()}원)과
              </S.Notice>
            </S.CalculatorValue>
            <S.CalculatorOption>
              <S.Notice>
                {Number(finalPrice - (cafe24Price || 0)).toLocaleString()}원 차액 발생
              </S.Notice>
            </S.CalculatorOption>
          </S.Row>
        )}
      </S.Td>
      <S.Td w={170}>
        {fetchingPromotion?.length ? (
          <>
            <span>아래 프로모션이 적용되는지 확인해보세요.</span>
            <br />
            {fetchingPromotion?.map(
              ({ promotionId, promotionName, promotionType, promotionTypeValue }, i) => {
                let typeValue;
                if (promotionType === 'FREE_DELIVERY') {
                  typeValue = `${promotionTypeValue}원 이상`;
                } else if (promotionType === 'DISCOUNT_WITH_RATE') {
                  typeValue = `${Math.round(promotionTypeValue * 100)}%`;
                } else {
                  typeValue = `${promotionTypeValue} ${currency}`;
                }
                return (
                  <span key={i}>
                    <a
                      href={`/product/modifyPromotion/${promotionId}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {`${promotionName} (${typeValue})`}
                    </a>
                  </span>
                );
              }
            )}
            <br />
            <br />
          </>
        ) : null}

        <span>원산지가 EU 국가라면 독일 배대지 주문 가격을 비교해보세요.</span>
      </S.Td>
    </S.Tr>
  );
};
