import { CardItem } from 'components';
import React, { FunctionComponent } from 'react';
import { ICardItem } from 'types';

export interface Props {
  title?: string;
  items?: ICardItem[];
}
interface State {}

const Card: FunctionComponent<Props> = ({ title, items, children }) => {
  return (
    <div className="mb-10 last:mb-0">
      {!!title && <h3 className="text-base font-semibold mb-3">{title}</h3>}
      {!!items && (
        <div className="flex flex-wrap divide-y divide-gray-100 border border-gray-500">
          {items.map((item, i) => (
            <CardItem
              key={i}
              name={item.name}
              renderItem={item.renderItem}
              stretch={item.stretch}
            />
          ))}
        </div>
      )}
      {children}
    </div>
  );
};

export default Card;
