import { CheckIcon, ExternalLinkIcon, XIcon } from '@heroicons/react/solid';
import {
  BrandShopFilterOptions,
  Title,
  Button,
  BrandModal,
  RePagination,
  GridTable,
  Select,
} from 'components';
import React, { useEffect, useState } from 'react';
import { request } from 'services';
import { ExportToCsv } from 'export-to-csv';

export default () => {
  const [totalCount, setTotalCount] = useState(0);

  const [brands, setBrands] = useState([]);
  const [searchOptions, setSearchOptions] = useState({
    nameType: 'K',
    name: '',
    isApp: '전체',
    isCafe24Upload: '전체',
    isNaverFeed: '전체',
    limit: 10,
    page: 1,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [brandId, setBrandId] = useState(0);
  const [isExporting, setIsExporting] = useState(false);

  const handleNameType = (e) => {
    setSearchOptions({
      ...searchOptions,
      nameType: e.target.value,
    });
  };

  const handleBrandName = (e) => {
    setSearchOptions({
      ...searchOptions,
      name: e.target.value,
    });
  };

  const handleIsAppChecked = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        isApp: e.target.value,
      });
    }
  };

  const handleIsCafe24Upload = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        isCafe24Upload: e.target.value,
      });
    }
  };

  const handleIsNaverFeed = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        isNaverFeed: e.target.value,
      });
    }
  };

  const handleParams = () => {
    const params = {
      isExtended: true,
      limit: searchOptions.limit,
      page: searchOptions.page,
    };
    if (searchOptions.name) {
      params.searchType = searchOptions.nameType === 'K' ? 'KOREAN' : 'ENGLISH';
      params.searchKey = searchOptions.name;
    }
    searchOptions.isApp !== '전체' &&
      (params.isActive = Boolean(Number(searchOptions.isApp)));
    searchOptions.isCafe24Upload !== '전체' &&
      (params.isCafe24Active = Boolean(Number(searchOptions.isCafe24Upload)));
    searchOptions.isNaverFeed !== '전체' &&
      (params.isNaverUpload = Boolean(Number(searchOptions.isNaverFeed)));

    return params;
  };

  const get = async () => {
    const params = handleParams();

    try {
      const { data, count } = await request.get('/brand', { params });
      setBrands(data);
      setTotalCount(count);
    } catch (err) {
      console.log(err);
    }
  };

  const onExportCsv = async () => {
    if (!window.confirm('다운로드하시겠습니까?')) return;

    setIsExporting(true);
    try {
      const { data } = await request.get('/brand', {
        params: { isExtended: true, page: null },
      });
      const csvExporter = new ExportToCsv({
        showLabels: true,
        filename: '브랜드 목록',
        headers: [
          '브랜드 영어명',
          '브랜드 한글명',
          '앱 노출 여부',
          '카페24 업로드 여부',
          '네이버 피드 업로드 여부',
        ],
      });
      csvExporter.generateCsv(
        data.map((item) => ({
          name: item.name,
          krName: item.krName,
          isActive: item.isActive,
          cafe24BrandCode: !!item.cafe24BrandCode,
          isNaverUpload: item.isNaverUpload,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsExporting(false);
    }
  };

  const onClose = () => {
    if (!window.confirm('작업을 중단하겠습니까?')) return;

    setIsOpen(false);
    setBrandId(0);
  };

  useEffect(() => {
    get();
  }, [searchOptions]);

  return (
    <>
      <div className="wrapper">
        <Title
          title={'브랜드 목록'}
          description={'페칭이 취급하는 전체 브랜드 목록입니다.'}
        />
        <BrandShopFilterOptions
          target="브랜드"
          options={searchOptions}
          handleIsAppChecked={handleIsAppChecked}
          handleIsCafe24Upload={handleIsCafe24Upload}
          handleIsNaverFeed={handleIsNaverFeed}
          handleNameType={handleNameType}
          handleName={handleBrandName}
        />
      </div>
      <div className="wrapper">
        <GridTable
          columns={[
            'ID',
            '기본명',
            '영문명',
            '우선순위',
            '앱 노출여부',
            '카페24 업로드 대상 여부',
            '네이버 피드 업로드 가능 여부',
            '편집샵 수',
          ]}
          header={
            <div className="flex gap-2 items-center justify-end">
              <Button
                isLoading={isExporting}
                disabled={isExporting}
                onClick={onExportCsv}
                size="sm"
                theme="ghost"
              >
                액셀로 추출
              </Button>
              <Button size="sm" onClick={() => setIsOpen(true)}>
                브랜드 추가
              </Button>
              <Select
                size="sm"
                value={searchOptions.limit}
                onChange={(e) => {
                  setSearchOptions({
                    ...searchOptions,
                    page: 1,
                    limit: e.target.value,
                  });
                }}
              >
                <option value={10}>10명씩 보기</option>
                <option value={50}>50명씩 보기</option>
                <option value={100}>100명씩 보기</option>
              </Select>
            </div>
          }
          noSelection
          renderItem={(item, key) => (
            <div
              key={key}
              onClick={() => {
                // setBrandId(item.id);
                // setIsOpen(true);
                window.open(`/product/brand/brandDetail/${item.id}`);
              }}
            >
              <div>{item.id}</div>
              <div>{item.krName}</div>
              <div>{item.name}</div>
              <div>{item.priority}</div>
              <div>
                {item.isActive ? (
                  <CheckIcon className="w-6 h-6 text-green-400" />
                ) : (
                  <XIcon className="w-6 h-6 text-red-400" />
                )}
              </div>
              <div>
                {!!item.cafe24BrandCode ? (
                  <CheckIcon className="w-6 h-6 text-green-400" />
                ) : (
                  <XIcon className="w-6 h-6 text-red-400" />
                )}
              </div>
              <div>
                {item.isNaverUpload ? (
                  <CheckIcon className="w-6 h-6 text-green-400" />
                ) : (
                  <XIcon className="w-6 h-6 text-red-400" />
                )}
              </div>
              <div>{item.shopCount}</div>
              {/* <div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(`/product/brand/brandDetail/${item.id}`);
                  }}
                  className="p-1 hover:bg-gray-300 rounded-full"
                >
                  <ExternalLinkIcon className="w-5 h-5" />
                </button>
              </div> */}
            </div>
          )}
          list={brands}
        />
        <div className="mt-4 flex items-center justify-between">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{totalCount}</span>
            <span>개</span>
          </div>
          <RePagination
            pageSize={searchOptions.limit}
            currentPage={searchOptions.page}
            onPageChange={(page) => setSearchOptions({ ...searchOptions, page })}
            totalCount={totalCount}
          />
        </div>
      </div>
      <BrandModal isOpen={isOpen} onClose={onClose} id={brandId} />
    </>
  );
};
