import { XIcon } from '@heroicons/react/solid';
import {
  Alert,
  Button,
  FormItem,
  InputNumber,
  NumberStat,
  ReInput,
  ReModal,
  SearchItemModal,
  Select,
} from 'components';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  request,
  shopOrderStautsList,
  SHOP_ORDER_STATUS,
  useObject,
  useOrderDetail,
} from 'services';
import { IItem, IModal } from 'types';

export interface Props extends IModal {
  orderStatus: keyof typeof SHOP_ORDER_STATUS | '';
}
interface State {
  isSearchItemOpen: boolean;
  itemList: IItem[];
  isLoading: boolean;
}

const AddItemModal: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;
  const [{ itemList, isSearchItemOpen, isLoading }, setState, onChange] =
    useObject<State>({
      isSearchItemOpen: false,
      itemList: [],
      isLoading: false,
    });
  const [{ shopOrderList }, setOrderState, { refreshOrderState }] = useOrderDetail();

  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const removeItem = (index: number) => {
    if (!window.confirm('선택한 상품을 삭제하시겠습니까?')) return;

    setState({ itemList: [...itemList.slice(0, index), ...itemList.slice(index + 1)] });
  };
  const onSubmit = async () => {
    if (itemList.some((item) => !item.sizeName)) {
      toast.info('옵션을 모두 선택해주세요.');
      return;
    }
    if (itemList.some((item) => !item.quantity)) {
      toast.info('수량을 모두 입력해주세요.');
      return;
    }
    let orderedShopList: string[] = [];
    itemList.forEach((item) => {
      const shopOrder = shopOrderList.find((shop) => shop.shopId === item.shopId);
      if (!shopOrder) return;

      if (shopOrderStautsList.indexOf(shopOrder.orderStatus) >= 6)
        orderedShopList.push(shopOrder.shopName);
    });
    if (!!orderedShopList.length) {
      toast.info(`이미 발주 완료된 편집샵이 있습니다. (${orderedShopList.join(', ')})`);
      return;
    }
    if (!window.confirm('상품을 추가하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      await request.post(`/commerce/order/fetching-order/${fetchingOrderNumber}/item`, {
        itemList: itemList.map((item) => ({
          itemId: item.id,
          quantity: item.quantity,
          sizeName: item.sizeName,
          additionalPrice: item.additionalPrice,
        })),
        orderStatus: 'ORDER_WAITING',
      });
      toast.success('상품이 추가되었습니다.');
      onClose();
      refreshOrderState();
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };
  return (
    <>
      <ReModal
        title="상품 추가"
        description="주문에 상품을 추가합니다."
        maxWidth="max-w-xl"
        isOpen={isOpen}
        onClose={() => {
          if (window.confirm('작업을 중단하시겠습니까?')) onClose();
        }}
      >
        <div className="mb-3">
          <Alert
            list={[
              '상품 추가 시 차액결제가 반드시 필요합니다.',
              '차액안내를 반드시 진행해주세요.',
            ]}
          />
        </div>
        <div className="space-y-4">
          <FormItem
            label="추가할 상품"
            action={
              <Button
                onClick={() => setState({ isSearchItemOpen: true })}
                size="xs"
                theme="secondary"
                className="ml-1"
              >
                {`${!!itemList.length ? '추가' : ''} 검색`}
              </Button>
            }
          >
            {itemList.map((item, key) => (
              <div key={key} className="border p-4 mb-1.5 rounded">
                <div className="flex gap-4">
                  <img
                    src={item.imageUrl}
                    alt="item"
                    className="max-w-[3.5rem] object-contain"
                  />
                  <div className="flex-1 text-sm">
                    <div>
                      {item.shopName} ({item.shopCountry})
                    </div>
                    <div>{item.name}</div>
                    <div>{item.brand}</div>
                  </div>
                  <div className="text-sm">
                    <div>
                      <NumberStat value={item.finalPrice} />
                    </div>
                    {!!item.fetchingPromotion &&
                      item.fetchingPromotion.map(
                        ({ promotionType, promotionTypeValue, promotionId }, key) => (
                          <div
                            key={key}
                            className="link text-sm"
                            onClick={(e) => {
                              e.stopPropagation();
                              window.open(`/product/modifyPromotion/${promotionId}`);
                            }}
                          >
                            <span>
                              {promotionType === 'DISCOUNT_WITH_CONST' &&
                                promotionTypeValue}
                              {promotionType === 'DISCOUNT_WITH_RATE' &&
                                `${Math.round(promotionTypeValue * 100)}%`}
                              {promotionType === 'FREE_DELIVERY' && '무료배송'}
                            </span>
                            <span> 프로모션</span>
                          </div>
                        )
                      )}
                  </div>
                  <div className="flex justify-end items-center mr-2">
                    <button className="rounded-full p-2" onClick={() => removeItem(key)}>
                      <XIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
                <div className="flex items-center gap-9 py-2">
                  <span className="text-sm">옵션</span>
                  <Select
                    size="xs"
                    className="w-40"
                    onChange={(e) =>
                      setState({
                        itemList: [
                          ...itemList.slice(0, key),
                          { ...item, sizeName: e.target.value, quantity: 0 },
                          ...itemList.slice(key + 1),
                        ],
                      })
                    }
                    value={item.sizeName}
                  >
                    <option value="" disabled selected>
                      선택
                    </option>
                    {item.itemSizeList.map((size, sizeIndex) => (
                      <option
                        key={sizeIndex}
                        value={size.sizeName}
                        disabled={!size.quantity}
                      >
                        {size.sizeName} (
                        {size.quantity ? `${size.quantity}개` : '재고 없음'})
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="flex items-center gap-9 pt-2">
                  <span className="text-sm">수량</span>
                  <ReInput
                    type="number"
                    min={1}
                    size="xs"
                    suffix="개"
                    value={item.quantity}
                    align="right"
                    onChange={(e) =>
                      setState({
                        itemList: [
                          ...itemList.slice(0, key),
                          { ...item, quantity: Number(e.target.value) },
                          ...itemList.slice(key + 1),
                        ],
                      })
                    }
                  />
                </div>
                <div className="flex items-center gap-9 pt-2">
                  <span className="text-sm">추가금액 (관세포함)</span>
                  <InputNumber
                    size="xs"
                    align="right"
                    suffix="원"
                    value={item.additionalPrice}
                    onChange={(e) =>
                      setState({
                        itemList: [
                          ...itemList.slice(0, key),
                          { ...item, additionalPrice: Number(e.target.value) },
                          ...itemList.slice(key + 1),
                        ],
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </FormItem>
          {!!itemList.length && (
            <div className="flex justify-center">
              <Button isLoading={isLoading} onClick={onSubmit}>
                상품 추가하기
              </Button>
            </div>
          )}
        </div>
      </ReModal>
      <SearchItemModal
        isOpen={isSearchItemOpen}
        onClose={() => setState({ isSearchItemOpen: false })}
        onSelect={(newList) => setState({ itemList: [...itemList, ...newList] })}
      />
    </>
  );
};

export default AddItemModal;
