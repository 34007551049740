/* eslint-disable no-prototype-builtins */
import React from 'react';
import dayjs from 'dayjs';

import * as style from './style';

const CommerceUserTableTr = ({
  item,
  i,
  checkedUserIds,
  setCheckedUserIds,
  userNames,
  viewAdminMemo,
  setUserNames,
}) => {
  let {
    birthDate,
    createdAt,
    deletedAt,
    email,
    emailAllowed,
    gender,
    id,
    idx,
    marketingPushAllowed,
    name,
    nightlyPushAllowed,
    phoneNumber,
    profileImage,
    pushAllowed,
    smsAllowed,
    type,
    englishFirstName,
    englishLastName,
  } = item;
  const auID = item.auID || {};

  return (
    <style.Tr>
      <style.flexibleTd
        widthPcnt={39}
        onClick={() => {
          const checkedItemIdsSet = new Set(checkedUserIds);
          const checkedUserNameSet = new Set(userNames);
          if (checkedItemIdsSet.has(idx)) {
            checkedItemIdsSet.delete(idx);
            setCheckedUserIds([...checkedItemIdsSet.values()]);
          } else {
            checkedItemIdsSet.add(idx);
            setCheckedUserIds([...checkedItemIdsSet.values()]);
          }
          if (checkedUserNameSet.has(name)) {
            checkedUserNameSet.delete(name);
            setUserNames([...checkedUserNameSet.values()]);
          } else {
            checkedUserNameSet.add(name);
            setUserNames([...checkedUserNameSet.values()]);
          }
        }}
      >
        <style.Checkbox
          onChange={() => {
            const checkedItemIdsSet = new Set(checkedUserIds);
            const checkedUserNameSet = new Set(userNames);
            if (checkedItemIdsSet.has(idx)) {
              checkedItemIdsSet.delete(idx);
              setCheckedUserIds([...checkedItemIdsSet.values()]);
            } else {
              checkedItemIdsSet.add(idx);
              setCheckedUserIds([...checkedItemIdsSet.values()]);
            }
            if (checkedUserNameSet.has(name)) {
              checkedUserNameSet.delete(name);
              setUserNames([...checkedUserNameSet.values()]);
            } else {
              checkedUserNameSet.add(name);
              setUserNames([...checkedUserNameSet.values()]);
            }
          }}
          checked={checkedUserIds.includes(idx)}
        />
        {i + 1}
      </style.flexibleTd>

      <style.flexibleTd widthPcnt={105}>{idx}</style.flexibleTd>
      <style.flexibleTd widthPcnt={86}>
        <img src={profileImage} />
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={115}>
        {name ? (
          <>
            <a href={`/user/${idx}`} target="_blank">
              {name}
            </a>
            <span>({englishFirstName + englishLastName})</span>
          </>
        ) : (
          '탈퇴회원'
        )}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={89}>
        <span>ID: {id}</span>
        <span>KAKAO: {auID.KAKAO}</span>
      </style.flexibleTd>
      <style.flexibleTd
        style={{ color: type === 'VIP' ? '#FF9900' : 'unset' }}
        widthPcnt={89}
      >
        {type !== null && (type === 'USER' ? '일반' : 'VIP')}
      </style.flexibleTd>

      <style.flexibleTd widthPcnt={89}>{phoneNumber}</style.flexibleTd>

      <style.flexibleTd widthPcnt={89}>{email}</style.flexibleTd>

      <style.flexibleTd widthPcnt={53}>
        {gender !== null && (gender === 'W' ? '여성 ' : '남성 ')}
        {`(${birthDate && dayjs(new Date()).diff(birthDate, 'years')})`}
      </style.flexibleTd>

      {/* <style.flexibleTd widthPcnt={53}>
        {birthDate && dayjs(new Date()).diff(birthDate, 'years')}
      </style.flexibleTd> */}

      <style.flexibleTd widthPcnt={97}>
        {dayjs(createdAt).format('YYYY.MM.DD HH:mm')}
        {deletedAt && (
          <>
            <br />
            <style.NegativeString>
              {dayjs(deletedAt).format('YYYY.MM.DD HH:mm')}
            </style.NegativeString>
          </>
        )}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={105}>
        <div>
          이메일{' '}
          {emailAllowed !== null &&
            (emailAllowed ? (
              <style.PositiveString>동의함</style.PositiveString>
            ) : (
              <style.NegativeString>동의안함</style.NegativeString>
            ))}
        </div>
        <div>
          SMS{' '}
          {smsAllowed !== null &&
            (smsAllowed ? (
              <style.PositiveString>동의함</style.PositiveString>
            ) : (
              <style.NegativeString>동의안함</style.NegativeString>
            ))}
        </div>
        <div>
          마케팅{' '}
          {marketingPushAllowed !== null &&
            (marketingPushAllowed ? (
              <style.PositiveString>동의함</style.PositiveString>
            ) : (
              <style.NegativeString>동의안함</style.NegativeString>
            ))}
        </div>
        <div>
          푸시{' '}
          {pushAllowed !== null &&
            (pushAllowed ? (
              <style.PositiveString>동의함</style.PositiveString>
            ) : (
              <style.NegativeString>동의안함</style.NegativeString>
            ))}
        </div>
        <div>
          야간푸시{' '}
          {nightlyPushAllowed !== null &&
            (nightlyPushAllowed ? (
              <style.PositiveString>동의함</style.PositiveString>
            ) : (
              <style.NegativeString>동의안함</style.NegativeString>
            ))}
        </div>
      </style.flexibleTd>

      <style.flexibleTd widthPcnt={83} noBorder>
        <button
          style={{
            border: 'none',
            color: '#ff6f61',
            padding: '3px 10px',
            background: '#3d3d3d',
          }}
          onClick={() => viewAdminMemo(idx)}
        >
          보기
        </button>
      </style.flexibleTd>
    </style.Tr>
  );
};

export default CommerceUserTableTr;
