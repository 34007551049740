import React from 'react';
import type { FunctionComponent } from 'react';
import { priceFormat } from 'utils';
import classnames from 'classnames';

export interface Props {
  unit?: string;
  value?: number | string;
}
interface State {}

const NumberStat: FunctionComponent<Props> = ({ unit = '원', value }) => {
  return (
    <>
      <span
        className={classnames(
          'font-medium text-2xl',
          !!value ? 'text-black' : 'text-gray-400'
        )}
      >
        {priceFormat(value)}
      </span>
      <span className={classnames('ml-1', { 'text-gray-400': !value })}>{unit}</span>
    </>
  );
};

export default NumberStat;
