import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 20px;
`;

export const DateWrapper = styled.div`
  padding: 20px 0;
  @media all and (min-width: 1000px) {
    display: flex;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 20px;
  @media all and (min-width: 1000px) {
    padding: 0 20px;
  }
`;

export const DateInput = styled.input.attrs({
  type: 'date',
})``;

export const FormWrapper = styled.div`
  @media all and (min-width: 1000px) {
    display: flex;
  }
`;

export const Select = styled.select`
  width: 100%;
  margin-top: 20px;
  height: 35px;
  @media all and (min-width: 1000px) {
    width: 30%;
    margin: 20px 10px;
    margin-left: 0;
  }
`;

export const MarginTop20 = {
  marginTop: '20px',
};
