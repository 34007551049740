import styled from 'styled-components';

export const Container = styled.div``;

export const Input = styled.input.attrs({
  type: 'text',
})`
  display: block;
  height: 20px;
  padding: 12px 4px;
  border: 1px darkgray solid;
  width: 60%;
`;
