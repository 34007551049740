import { getShopPriceList, getUserList } from 'api/axios';
import { SaveButtonGroup } from 'components/UI/molecules/SearchButtonGroup';
import React, { createRef, useEffect, useRef, useState } from 'react';
import * as S from './AddPromotionStyle';
import {
  AutomationRule,
  DiscountRule,
  DropZoneImage,
  PromotionProductList,
  ScrollTitleBar,
} from 'components';
import {
  addPromotion,
  getPromotionInfo,
  MakeTable,
  mapDiscountType,
  modifyPromotion,
} from './utils';
import { initialInfoState } from './initialState';
import Switch from 'react-switch';
import { toast } from 'react-toastify';

const SetPromotionInfo = ({ mode = 'add' }) => {
  // mode : 'add' | 'modify'

  const id = document.location.href.split('/modifyPromotion/')[1];

  const [basicInfo, setBasicInfo] = useState(initialInfoState.basic);
  const setBasicInfoKeyValue = (key, value) =>
    setBasicInfo((p) => ({ ...p, [key]: value }));
  const [discountInfo, setDiscountInfo] = useState(initialInfoState.discount.state);
  const setDiscountInfoKeyValue = (key, value) =>
    setDiscountInfo((p) => ({ ...p, [key]: value }));
  const [adminInfo, setAdminInfo] = useState(initialInfoState.admin);
  const setAdminInfoKeyValue = (key, value) =>
    setAdminInfo((p) => ({ ...p, [key]: value }));
  const [manageBanner, setManageBanner] = useState(initialInfoState.banner);
  const setMangeBannerKeyValue = (key, value) =>
    setManageBanner((p) => ({ ...p, [key]: value }));

  const [rules, setRules] = useState([]);
  const [salesInfo, setSalesInfo] = useState(initialInfoState.sales);
  const [applyWithDiscountedPrice, setApplyWithDiscountedPrice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveState = async () => {
    if (mode === 'add') {
      setIsLoading(true);
      await addPromotion(
        basicInfo,
        discountInfo,
        adminInfo,
        manageBanner,
        rules,
        applyWithDiscountedPrice
      );
      setIsLoading(false);
    } else if (mode === 'modify') {
      if (
        await modifyPromotion(
          id,
          basicInfo,
          discountInfo,
          adminInfo,
          manageBanner,
          rules,
          applyWithDiscountedPrice
        )
      ) {
        toast.success('프로모션이 수정되었습니다.');
        initPromotionInfo();
      }
    } else {
      alert('잘못된 접근입니다.');
    }
  };
  const resetState = () => {
    if (mode === 'add') {
      setBasicInfo(initialInfoState.basic);
      setDiscountInfo(initialInfoState.discount.state);
      setAdminInfo(initialInfoState.admin);
      setManageBanner(initialInfoState.banner);
      setRules([]);
    } else if (mode === 'modify') {
      initPromotionInfo();
    } else {
      alert('잘못된 접근입니다.');
    }
  };

  const [shops, setShops] = useState([]);
  const fetchShops = async () => {
    const { data } = await getShopPriceList();
    setShops(
      data
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(({ shopId, priceRuleId, name, country, currency }) => ({
          id: priceRuleId,
          shopId,
          priceRuleId,
          name: `${name}${shopId === priceRuleId ? '' : ` ${country}`} (${currency})`,
        }))
    );
  };
  const [admins, setAdmins] = useState([]);
  const fetchAdmins = async () => {
    const data = await getUserList();
    setAdmins(
      data
        .sort((a, b) => (a.admin_name > b.admin_name ? 1 : -1))
        .map(({ id, admin_name }) => ({ id, admin_name }))
    );
  };

  const initPromotionInfo = async () => {
    const fetchedInfo = await getPromotionInfo(id);
    console.log('fetchedInfo', fetchedInfo);
    setBasicInfo(fetchedInfo.basicInfo);
    setDiscountInfo(fetchedInfo.discountInfo);
    setAdminInfo(fetchedInfo.adminInfo);
    setManageBanner(fetchedInfo.manageBanner);
    setRules(fetchedInfo.rules);
    setSalesInfo(fetchedInfo.salesInfo);
    setApplyWithDiscountedPrice(fetchedInfo.applyWithDiscountedPrice);
  };

  useEffect(() => {
    fetchShops();
    fetchAdmins();
    mode === 'modify' && initPromotionInfo();
  }, []);

  /////스크롤바 데이터
  const targets = ['기본정보', '할인정보', '관리정보', '배너관리'];
  mode === 'modify' && targets.push('판매성과');
  const tableRefs = useRef(targets.map(() => createRef(null)));
  const onScroll = (target) => {
    const refList = tableRefs.current;
    const find = refList.find((el) => el.current?.innerHTML === target);

    if (find) {
      window.scrollTo(0, find.current.offsetTop - 130);
    }
  };

  /////표 데이터
  const TableData = {
    basicInfo: [
      {
        left: '활성상태 *',
        right: (
          <>
            <S.Label>
              <S.Radio
                checked={basicInfo.isActive === true}
                onChange={() => setBasicInfoKeyValue('isActive', true)}
              />
              ON
            </S.Label>
            <S.Label>
              <S.Radio
                checked={basicInfo.isActive === false}
                onChange={() => setBasicInfoKeyValue('isActive', false)}
              />
              OFF
            </S.Label>
          </>
        ),
      },
      {
        left: '편집샵 *',
        right: (
          <>
            <S.Select
              value={basicInfo.priceRuleId || 'null'}
              onChange={(e) => {
                const priceRuleId = e.target.value;
                setBasicInfoKeyValue(
                  'priceRuleId',
                  priceRuleId === 'null' ? null : Number(priceRuleId)
                );
              }}
            >
              <option value="null">편집샵을 선택하세요</option>
              {shops.map(({ priceRuleId, name }) => (
                <option value={priceRuleId} key={priceRuleId}>
                  {name}
                </option>
              ))}
            </S.Select>
          </>
        ),
      },
      {
        left: 'VIP여부 *',
        right: (
          <>
            <S.Label>
              <S.Radio
                checked={basicInfo.isVip === false}
                onChange={() => setBasicInfoKeyValue('isVip', false)}
              />
              일반
            </S.Label>
            <S.Label>
              <S.Radio
                checked={basicInfo.isVip === true}
                onChange={() => setBasicInfoKeyValue('isVip', true)}
              />
              VIP
            </S.Label>
          </>
        ),
      },
      {
        left: '타이틀 *',
        right: (
          <>
            <S.TextArea
              value={basicInfo.name || ''}
              onChange={(e) => setBasicInfoKeyValue('name', e.target.value)}
            />
          </>
        ),
      },
      {
        left: '설명 *',
        right: (
          <>
            <S.TextArea
              maxLength="80"
              placeholder="최대 80자"
              value={basicInfo.description || ''}
              onChange={(e) => setBasicInfoKeyValue('description', e.target.value)}
              style={{ height: '60px' }}
            />
          </>
        ),
      },
      {
        left: '고객 적용 방법',
        right: (
          <>
            <S.TextArea
              maxLength="60"
              placeholder="최대 60자"
              value={basicInfo.applyDescription || ''}
              onChange={(e) => setBasicInfoKeyValue('applyDescription', e.target.value)}
              style={{ height: '60px' }}
            />
          </>
        ),
      },
      {
        left: '기간 *',
        right: (
          <>
            <S.Label>
              <S.Radio
                checked={basicInfo.period === false}
                onChange={() => {
                  setBasicInfoKeyValue('period', false);
                  setBasicInfoKeyValue('startedAt', null);
                  setBasicInfoKeyValue('endedAt', null);
                }}
              />
              상시진행
            </S.Label>
            <S.Label>
              <S.Radio
                checked={basicInfo.period === true}
                onChange={() => setBasicInfoKeyValue('period', true)}
              />
              기간설정
            </S.Label>
            <S.InputDate
              disabled={!basicInfo.period}
              value={basicInfo.startedAt || ''}
              onChange={(e) => setBasicInfoKeyValue('startedAt', e.target.value)}
            />
            ~
            <S.InputDate
              disabled={!basicInfo.period}
              value={basicInfo.endedAt || ''}
              onChange={(e) => setBasicInfoKeyValue('endedAt', e.target.value)}
            />
            ,
          </>
        ),
      },
      {
        left: '여성링크',
        right: (
          <>
            <S.TextArea
              value={basicInfo.urlWomen || ''}
              onChange={(e) => setBasicInfoKeyValue('urlWomen', e.target.value)}
              style={{ height: '60px' }}
            />
          </>
        ),
      },
      {
        left: '남성링크',
        right: (
          <>
            <S.TextArea
              value={basicInfo.urlMen || ''}
              onChange={(e) => setBasicInfoKeyValue('urlMen', e.target.value)}
              style={{ height: '60px' }}
            />
          </>
        ),
      },
    ],
    discountInfo: [
      {
        left: '가격반영 대상 *',
        right: (
          <>
            <S.Label>
              <S.Checkbox
                checked={discountInfo.isUserApplicable}
                onChange={() =>
                  setDiscountInfoKeyValue(
                    'isUserApplicable',
                    !discountInfo.isUserApplicable
                  )
                }
              />
              편집샵판매가
            </S.Label>
            <S.Label>
              <S.Checkbox
                checked={discountInfo.isFetchingApplicable}
                onChange={() => {
                  setDiscountInfoKeyValue(
                    'isFetchingApplicable',
                    !discountInfo.isFetchingApplicable
                  );
                  setDiscountInfoKeyValue('agencyFeeType', null);
                }}
              />
              페칭판매가
            </S.Label>
            {/* <input
                        value={discountInfo.agencyFeeType || ''}
                        onChange={(e) => setDiscountInfoKeyValue('agencyFeeType', Number(e.target.value))}
                        type='number'
                        placeholder='페칭수수료를 % 없이 숫자로 입력하세요.'
                        disabled={!discountInfo.isFetchingApplicable}
                        style={{ width: '250px', paddingLeft: '5px' }}
                    /> */}
            <S.Select
              value={
                discountInfo.agencyFeeType === null
                  ? 'null'
                  : String(discountInfo.agencyFeeType)
              }
              onChange={(e) =>
                setDiscountInfoKeyValue(
                  'agencyFeeType',
                  e.target.value === 'null' ? null : Number(e.target.value)
                )
              }
              disabled={!discountInfo.isFetchingApplicable}
            >
              <option value="null">페칭 수수료</option>
              <option value="0">5%</option>
              <option value="1">10%</option>
              <option value="2">15%</option>
            </S.Select>
          </>
        ),
      },
      {
        left: '편집샵 자체할인 중복적용 가능 여부',
        right: (
          <Switch
            checked={applyWithDiscountedPrice}
            onChange={(checked) => setApplyWithDiscountedPrice(checked)}
          />
        ),
      },
      {
        left: '중복적용 가능',
        right: (
          <>
            <S.Label>
              <S.Checkbox
                checked={discountInfo.canBeOverlapped}
                onChange={() =>
                  setDiscountInfoKeyValue(
                    'canBeOverlapped',
                    !discountInfo.canBeOverlapped
                  )
                }
              />
              일반 프로모션 (%, 고정가격 할인)
            </S.Label>
            <S.Label>
              <S.Checkbox
                checked={discountInfo.canBeOverlappedWithFreeDelivery}
                onChange={() =>
                  setDiscountInfoKeyValue(
                    'canBeOverlappedWithFreeDelivery',
                    !discountInfo.canBeOverlappedWithFreeDelivery
                  )
                }
              />
              일반 무료배송
            </S.Label>
            <S.Label>
              <S.Checkbox
                checked={discountInfo.canBeOverlappedWithVip}
                onChange={() =>
                  setDiscountInfoKeyValue(
                    'canBeOverlappedWithVip',
                    !discountInfo.canBeOverlappedWithVip
                  )
                }
              />
              VIP 프로모션 (%, 고정가격 할인)
            </S.Label>
            <S.Label>
              <S.Checkbox
                checked={discountInfo.canBeOverlappedWithVipFreeDelivery}
                onChange={() =>
                  setDiscountInfoKeyValue(
                    'canBeOverlappedWithVipFreeDelivery',
                    !discountInfo.canBeOverlappedWithVipFreeDelivery
                  )
                }
              />
              VIP 무료배송
            </S.Label>
          </>
        ),
      },
      {
        left: '할인 타입 *',
        right:
          mode === 'add' ? (
            <>
              <S.Select
                value={discountInfo.type || 'null'}
                onChange={(e) => {
                  setDiscountInfoKeyValue(
                    'type',
                    e.target.value === 'null' ? null : e.target.value
                  );
                  e.target.value === 'FREE_DELIVERY'
                    ? setRules([initialInfoState.discount.rules.state])
                    : setRules([]);
                }}
              >
                <option value="null">할인 타입을 선택하세요</option>
                <option value="DISCOUNT_WITH_RATE">% 할인</option>
                <option value="DISCOUNT_WITH_CONST">고정가격 할인</option>
                <option value="FREE_DELIVERY">무료배송</option>
              </S.Select>
            </>
          ) : (
            mapDiscountType(discountInfo.type)
          ),
      },
      {
        left: '할인율 *',
        right: (
          <>
            {['DISCOUNT_WITH_RATE', 'DISCOUNT_WITH_CONST'].includes(discountInfo.type) ? (
              <div>
                {rules.map((rule, i) => (
                  <DiscountRule
                    type={discountInfo.type}
                    rule={rule}
                    setRuleKeyValue={(key, value) =>
                      setRules((p) => {
                        const copy = [...p];
                        copy.splice(i, 1, { ...rule, [key]: value });
                        return copy;
                      })
                    }
                    onDelete={() =>
                      setRules((p) => {
                        const copy = [...p];
                        copy.splice(i, 1);
                        return copy;
                      })
                    }
                    key={i + 'rule'}
                  />
                ))}
                <S.ResetButton
                  onClick={() =>
                    setRules((p) => [...p, initialInfoState.discount.rules.state])
                  }
                >
                  +
                </S.ResetButton>
              </div>
            ) : discountInfo.type === 'FREE_DELIVERY' ? (
              <AutomationRule
                rule={rules[0] ? rules[0] : initialInfoState.discount.rules.state}
                setRuleKeyValue={(key, value) =>
                  setRules((p) => [{ ...p[0], [key]: value }])
                }
              />
            ) : (
              '해당없음'
            )}
          </>
        ),
      },
    ],
    adminInfo: [
      {
        left: '담당자 *',
        right: (
          <>
            <S.Select
              value={adminInfo.assigneeId || 'null'}
              onChange={(e) =>
                setAdminInfoKeyValue(
                  'assigneeId',
                  e.target.value === 'null' ? null : Number(e.target.value)
                )
              }
            >
              <option value="null">담당자를 선택하세요</option>
              {admins.map(({ id, admin_name }) => (
                <option value={id} key={id + 'assigneeId'}>
                  {admin_name}
                </option>
              ))}
            </S.Select>
          </>
        ),
      },
      {
        left: '업데이트 현황 *',
        right: (
          <>
            <S.Label>
              <S.Radio
                checked={adminInfo.applyStatus === 'NEW'}
                onChange={() => setAdminInfoKeyValue('applyStatus', 'NEW')}
              />
              신규
            </S.Label>
            <S.Label>
              <S.Radio
                checked={adminInfo.applyStatus === 'UPDATING'}
                onChange={() => setAdminInfoKeyValue('applyStatus', 'UPDATING')}
              />
              업데이트 예정
            </S.Label>
            <S.Label>
              <S.Radio
                checked={adminInfo.applyStatus === 'UPDATED'}
                onChange={() => setAdminInfoKeyValue('applyStatus', 'UPDATED')}
              />
              업데이트 완료
            </S.Label>
            <S.Label>
              <S.Radio
                checked={adminInfo.applyStatus === 'NO_UPDATE_NEEDED'}
                onChange={() => setAdminInfoKeyValue('applyStatus', 'NO_UPDATE_NEEDED')}
              />
              업데이트 불필요
            </S.Label>
            <S.Label>
              <S.Radio
                checked={adminInfo.applyStatus === 'TO_BE_TERMINATED'}
                onChange={() => setAdminInfoKeyValue('applyStatus', 'TO_BE_TERMINATED')}
              />
              종료확인 예정
            </S.Label>
            <S.Label>
              <S.Radio
                checked={adminInfo.applyStatus === 'TERMINATED'}
                onChange={() => setAdminInfoKeyValue('applyStatus', 'TERMINATED')}
              />
              종료확인 완료
            </S.Label>
          </>
        ),
      },
      {
        left: '관리자메모',
        right: (
          <>
            <S.TextArea
              value={adminInfo.adminMemo || ''}
              onChange={(e) => setAdminInfoKeyValue('adminMemo', e.target.value)}
              style={{ height: '120px' }}
            />
          </>
        ),
      },
    ],
    manageBanner: [
      {
        left: '노출 위치',
        right: (
          <>
            <S.Label>
              <S.Checkbox
                checked={manageBanner.isVisibleToAppCategory}
                onChange={() =>
                  setMangeBannerKeyValue(
                    'isVisibleToAppCategory',
                    !manageBanner.isVisibleToAppCategory
                  )
                }
              />
              앱 카테고리
            </S.Label>
            <S.Label>
              <S.Checkbox
                checked={manageBanner.isVisibleToAppItemPage}
                onChange={() =>
                  setMangeBannerKeyValue(
                    'isVisibleToAppItemPage',
                    !manageBanner.isVisibleToAppItemPage
                  )
                }
              />
              앱 상품상세
            </S.Label>
            <S.Label>
              <S.Checkbox
                checked={manageBanner.isVisibleToAppMain}
                onChange={() =>
                  setMangeBannerKeyValue(
                    'isVisibleToAppMain',
                    !manageBanner.isVisibleToAppMain
                  )
                }
              />
              앱 메인
            </S.Label>
            <S.Label>
              <S.Checkbox
                checked={manageBanner.isVisibleToAppShopLink}
                onChange={() =>
                  setMangeBannerKeyValue(
                    'isVisibleToAppShopLink',
                    !manageBanner.isVisibleToAppShopLink
                  )
                }
              />
              앱 편집샵 이동
            </S.Label>
          </>
        ),
      },
      {
        left: '앱 메인 가로형',
        right: (
          <DropZoneImage
            imageUrl={manageBanner.topBannerImageUrl}
            setImageUrl={(value) => setMangeBannerKeyValue('topBannerImageUrl', value)}
          />
        ),
      },
      {
        id: '앱 기획전 목록',
        left: (
          <>
            앱 기획전 목록 <br />
            (모바일 웹 기획전 목록)
          </>
        ),
        right: (
          <DropZoneImage
            imageUrl={manageBanner.bannerImageUrl}
            setImageUrl={(value) => setMangeBannerKeyValue('bannerImageUrl', value)}
          />
        ),
      },
      {
        id: '앱 기획전 상세',
        left: (
          <>
            앱 기획전 상세 <br />
            (모바일 웹 기획전 상세)
          </>
        ),
        right: (
          <DropZoneImage
            imageUrl={manageBanner.landingImageUrl}
            setImageUrl={(value) => setMangeBannerKeyValue('landingImageUrl', value)}
          />
        ),
      },
      // {
      //     left: 'PC 웹 기획전 목록',
      //     right: <DropZoneImage
      //         imageUrl={manageBanner.mainImageUrl}
      //         setImageUrl={(value) => setMangeBannerKeyValue('mainImageUrl', value)}
      //     />
      // },
      {
        left: 'PC 웹 기획전 상세',
        right: (
          <DropZoneImage
            imageUrl={manageBanner.mainImageUrl}
            setImageUrl={(value) => setMangeBannerKeyValue('mainImageUrl', value)}
          />
        ),
      },
    ],
    salesAccomplishment: [
      {
        left: '거래액',
        right: <></>,
      },
      {
        left: '성과',
        right: (
          <>
            <S.Label>조회수 : {salesInfo.sales || '정보없음'}</S.Label>
            <S.Label>좋아요 : {salesInfo.like || '정보없음'}</S.Label>
            <S.Label>장바구니 : {salesInfo.cart || '정보없음'}</S.Label>
            <S.Label>판매수 : {salesInfo.sales || '정보없음'}</S.Label>
          </>
        ),
      },
    ],
  };
  const tables = [
    {
      title: '기본정보',
      content: TableData.basicInfo,
    },
    {
      title: '할인정보',
      content: TableData.discountInfo,
    },
    {
      title: '관리정보',
      content: TableData.adminInfo,
    },
    {
      title: '배너관리',
      content: TableData.manageBanner,
    },
  ];

  const salesTable = {
    title: '판매성과',
    content: TableData.salesAccomplishment,
  };

  // console.log('basicInfo', basicInfo);
  // console.log('discountInfo', discountInfo);
  // console.log('adminInfo', adminInfo);
  // console.log('manageBanner', manageBanner);
  // console.log('rules', rules);

  return (
    <S.Outer>
      <S.ShadowContainer>
        <h2 style={{ marginBottom: '24px' }}>프로모션 정보</h2>
        <ScrollTitleBar data={targets} onScroll={onScroll} />
        {tables.map((data, i) => (
          <MakeTable data={data} tableRef={tableRefs.current[i]} key={data.title} />
        ))}
        {mode === 'modify' && (
          <MakeTable data={salesTable} tableRef={tableRefs.current[4]} />
        )}
        <SaveButtonGroup
          mode={'저장'}
          loading={isLoading}
          primaryCallback={saveState}
          secondaryCallback={resetState}
        />
      </S.ShadowContainer>
      {mode === 'modify' && (
        <S.ShadowContainer>
          <h2>노출 관리</h2>
          <PromotionProductList id={id} />
        </S.ShadowContainer>
      )}
    </S.Outer>
  );
};

export const AddPromotion = () => <SetPromotionInfo mode="add" />;
export const ModifyPromotion = () => <SetPromotionInfo mode="modify" />;
