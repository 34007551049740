import styled from 'styled-components';

export const Outer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px;
`;

export const Inner = styled.div`
  width: 100%;
  @media all and (min-width: 1400px) {
    width: 95%;
  }
`;

export const CategoryMappingBox = styled.div`
  width: 100%;
  margin-bottom: 50px;
  @media all and (min-width: 1400px) {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-around;
  }
`;

export const CategoryTable = styled.div`
  width: 100%;
  border: 1px solid #e9ecef;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  overflow: auto;
  @media all and (min-width: 1400px) {
    width: 60%;
  }
`;

export const FormFilter = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 500px;
  @media all and (min-width: 1400px) {
    flex-wrap: nowrap;
  }
`;

export const ItemTable = styled.div`
  margin-top: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  max-height: 700px;
  min-width: 500px;
`;

export const CategoryMappingButtonOuter = styled.div`
  margin: 10px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 30px;
  @media all and (min-width: 1400px) {
    width: 10%;
    height: 500px;
  }
`;

export const CategoryBox = styled.div`
  width: 100%;
  top: 30px;
  margin: 10px;
  @media all and (min-width: 1400px) {
    width: 20%;
    height: 700px;
  }
`;

export const Gender = styled.p`
  font-weight: bold;
  text-align: center;
  font-size: large;
`;

export const ShopSelect = styled.select`
  padding: 15px 20px;
  width: 50%;
  cursor: pointer;
  margin: 10px;
  font-weight: bold;
  &:focus {
    outline: none;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const Button = styled.button`
  color: ${(props) => props.color || 'black'};
  background-color: ${(props) => props.BC || 'white'};
  padding: 5px 10px;
  border: none;
  border-radius: 2px;
  &:focus {
    outline: none;
  }
`;

export const PageButton = styled.button`
  padding: 10px 15px;
  border: none;
  background-color: ${(props) => props.BC};
  color: white;
  margin: 10px;
  &:active,
  &:hover {
    background-color: #464141;
  }
  &:focus {
    outline: none;
  }
`;

export const P = styled.p`
  margin: 0 5px;
  color: ${(props) => props.color || 'black'};
`;

export const PageButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 500px;
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  padding: 15px 20px;
  width: 100%;
  margin: 5px 0;
  &:focus {
    outline: none;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MappingButton = {
  marginBottom: '10px',
  color: '#0085FF',
  fontSize: 50,
};

export const FormFilterItem = {
  margin: '5px',
};

export const MarginTop20 = {
  MarginTop: '20px',
};

export const Margin10 = {
  margin: '10px',
};
