import React from 'react';
import type { FunctionComponent } from 'react';
import { IModal } from 'types';
import { Button, FormItem, ReModal, Select } from 'components';
import { DELIVERY_STATUS, enumToList, request, useObject } from 'services';
import { toast } from 'react-toastify';

export interface Props extends IModal {
  orderStatus: keyof typeof DELIVERY_STATUS;
  shopOrderNumber: string;
  onSuccess: () => void;
}
interface State {
  status: keyof typeof DELIVERY_STATUS;
  isLoading: boolean;
}

const UpdateShippingStatusModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  orderStatus,
  shopOrderNumber,
  onSuccess,
}) => {
  if (!isOpen) return null;
  const [{ status, isLoading }, setState, onChange] = useObject<State>({
    status: orderStatus,
    isLoading: false,
  });

  const onSubmit = async () => {
    if (!window.confirm('배송상태를 변경하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      await request.put(`/commerce/order/shop-order/status/${shopOrderNumber}`, {
        orderStatus: status,
      });
      toast.success('배송상태가 변경되었습니다.');
      onClose();
      onSuccess();
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };
  return (
    <ReModal
      title="배송상태 변경"
      isOpen={isOpen}
      onClose={onClose}
      description={
        <>
          배송상태를 수동으로 변경합니다.
          <br />
          역순으로 배송상태를 변경하면, 택배사 자동연동이 해제됩니다.
        </>
      }
    >
      <div className="space-y-4">
        <FormItem label="현재 배송상태" readOnly>
          {DELIVERY_STATUS[orderStatus]}
        </FormItem>
        <FormItem label="변경할 배송상태">
          <Select value={status} name="status" onChange={onChange}>
            {enumToList(DELIVERY_STATUS).map((item, key) => (
              <option key={key} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormItem>
        <div className="flex justify-center">
          <Button isLoading={isLoading} onClick={onSubmit}>
            배송상태 변경하기
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default UpdateShippingStatusModal;
