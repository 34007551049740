import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchingAPI } from '../../../../api/axios';
import * as S from './style';

const SavedOption = ({ searchOptionTableState, setSearchOptionTableState }) => {
  const [savedOptions, setSavedOptions] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState('none');

  const fetchSavedOptions = () => {
    fetchingAPI
      .get('/filter')
      .then(({ data }) => setSavedOptions(data))
      .catch((err) => console.log('err in fetchSavedOption() in', err));
    setSelectedOptionId('none');
  };

  const fetchOption = (name, filter) => {
    fetchingAPI
      .post('/filter', { name, filter })
      .then(() => fetchSavedOptions())
      .catch((err) => console.log('err in fetchOption() in <SavedOption/>', err));
  };

  const fetchDelete = (id) => {
    fetchingAPI
      .delete(`/filter/${id}`)
      .then(() => toast.success('삭제되었습니다.'))
      .then(() => fetchSavedOptions())
      .catch((err) => {
        toast.error('오류가 발생했습니다. 나중에 다시 시도해주세요.');
        console.log('err in fetchDelete() in <SavedOption/>', err);
      });
  };

  // const fetchDeleteAll = () => {
  //   fetchingAPI.delete('/filter').then(() => fetchSavedOptions()).catch(() => alert('err in fetchDeleteAll() in <SavedOption/>'));
  // }

  const onClickSave = () => {
    const name = window.prompt('저장할 검색 옵션의 이름을 입력해주세요.');
    if (name) {
      fetchOption(name, JSON.stringify(searchOptionTableState));
    }
  };

  const onClickDelete = (id) => {
    if (id === 'none') {
      alert('삭제할 옵션을 선택하세요!');
    } else {
      fetchDelete(id);
    }
  };

  // const onClickDeleteAll = () => {
  //   if (window.confirm('모든 저장된 옵션을 삭제하시겠습니까?')) {
  //     fetchDeleteAll();
  //   }
  // }

  const onChangeOption = (e) => {
    if (e.target.value === 'none') {
      setSelectedOptionId('none');
    } else {
      const json = JSON.parse(e.target.value);
      setSelectedOptionId(json.id);
      try {
        const savedOption = JSON.parse(json.filter);
        if (savedOption === null) {
          throw new Error();
        }
        setSearchOptionTableState(savedOption);
      } catch (error) {
        alert('다른 옵션을 사용해주세요');
      }
    }
  };

  useEffect(() => {
    fetchSavedOptions();
  }, []);

  return (
    <S.Container>
      <S.Select
        onChange={onChangeOption}
        value={
          JSON.stringify(savedOptions.find(({ id }) => id === selectedOptionId)) || 'none'
        }
      >
        <option value="none">옵션을 선택하세요</option>
        {savedOptions.map((data) => (
          <option value={JSON.stringify(data)} key={data.id}>
            {data.name}
          </option>
        ))}
      </S.Select>
      <S.SaveButton onClick={onClickSave}>현재 검색 옵션 저장</S.SaveButton>
      <S.DeleteButton onClick={() => onClickDelete(selectedOptionId)}>Del</S.DeleteButton>
      {/* <S.DeleteButton onClick={() => onClickDeleteAll()}>Del All</S.DeleteButton> */}
    </S.Container>
  );
};
export default SavedOption;
