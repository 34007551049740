import {
  Button,
  Card,
  MenuTabs,
  Radio,
  ReInput,
  Select,
  Spinner,
  Textarea,
} from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { FETCHING_TEL, request, useObject } from 'services';
import Switch from 'react-switch';
import { priceFormat } from 'utils';
import { toast } from 'react-toastify';
import useSWR from 'swr';
import { ArrowNarrowRightIcon, XCircleIcon } from '@heroicons/react/solid';

interface BillingInfo {
  [date: number]: {
    APIGW: any;
    CF: any;
    CLA: any;
    EMAIL: any;
    NOTIF: any;
    OPAPI: any;
  };
}

interface State {
  content: string;
  isSubmitting: boolean;
  messageType: 'SMS' | 'MMS';
  includeNotAllowed: boolean;
  isAd: boolean;
  sendAt: string;
  count: number;
  smsAllowedUserCount: number;
  title: string;
  type: 'ALL' | 'USER_TYPE' | 'GROUP' | 'DIRECT_UPLOAD' | 'DIRECT_INPUT';
  phoneNumbers: string;
  sendType: 'DIRECT' | 'RESERVE';
  userType: 'USER' | 'VIP';
  excelFile: File | null;
  formatFile: File | null;
}

const UserSendMessagePage = () => {
  const [
    {
      content,
      isSubmitting,
      messageType,
      includeNotAllowed,
      isAd,
      sendAt,
      count,
      smsAllowedUserCount,
      title,
      type,
      phoneNumbers,
      sendType,
      userType,
      excelFile,
      formatFile,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    content: '',
    isSubmitting: false,
    messageType: 'SMS',
    includeNotAllowed: false,
    isAd: false,
    sendAt: '',
    count: 0,
    smsAllowedUserCount: 0,
    title: '',
    type: 'ALL',
    phoneNumbers: '',
    sendType: 'DIRECT',
    userType: 'USER',
    excelFile: null,
    formatFile: null,
  });
  const { data: billingInfo } = useSWR('/util/billing/naver', (url) =>
    request.get<any, BillingInfo>(url)
  );

  const get = async () => {
    try {
      const { count, smsAllowedUserCount } = await request.get<
        any,
        { count: number; smsAllowedUserCount: number }
      >('/commerce/user/statistics');
      setState({ count, smsAllowedUserCount });
    } catch (err) {
      console.log(err);
    }
  };

  const onManageGrade = async () => {};

  const onManageGroup = async () => {};

  const onUploadExcelFile = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept =
      'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    input.onchange = () => {
      if (!input.files) return;

      const file = input.files[0];
      setState({ excelFile: file });
    };
    input.click();
  };

  const onDownloadFormat = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.csv';
    input.onchange = () => {
      if (!input.files) return;
      const file = input.files[0];
      setState({ formatFile: file });
    };
    input.click();
  };

  const onSubmit = async () => {
    let data: any = {
      messageType,
      sender: FETCHING_TEL,
      includeNotAllowed,
      isAd,
      sendAt,
      content,
    };
    if (messageType === 'MMS') {
      if (!title) {
        toast.info('제목을 입력하세요.');
        return;
      }
      data.title = title;
    }
    if (type === 'DIRECT_INPUT') data.targetPhoneNumbers = phoneNumbers.split(', ');
    if (type === 'ALL') data.targetType = 'ALL';
    if (type === 'USER_TYPE') data.targetType = userType;
    if (type === 'DIRECT_INPUT' || type === 'DIRECT_UPLOAD') data.targetType = 'MANUAL';
    if (sendType === 'RESERVE') {
      if (!sendAt) {
        toast.info('예약발송시간을 선택해주세요.');
        return;
      }
      if (dayjs(sendAt).isBefore(dayjs())) {
        toast.info('예약발송시간이 현재보다 이전입니다.');
        return;
      }
      data.sendAt = sendAt;
    }
    if (type === 'DIRECT_UPLOAD') {
      if (!excelFile && !formatFile) {
        toast.info('최소 한 개의 파일을 업로드해주세요.');
        return;
      }
    }
    if (!content) {
      toast.info('메시지를 입력하세요.');
      return;
    }

    if (!window.confirm('메시지를 전송하시겠습니까?')) return;

    setState({ isSubmitting: true });
    try {
      if (type === 'DIRECT_UPLOAD') {
        const formData = new FormData();
        if (excelFile) formData.append('file', excelFile);
        if (formatFile) formData.append('file', formatFile);
        formData.append('request', JSON.stringify(data));
        await request.post('/commerce/user/message', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        await request.post('/commerce/user/message', data);
      }
      toast.success('성공적으로 전송되었습니다.');
      resetState();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isSubmitting: false });
    }
  };

  const Statistic: FunctionComponent<{ unit: string }> = ({ unit, children }) => {
    return (
      <div>
        <span className="mx-1 font-medium text-2xl text-black">{children}</span>
        <span>{unit}</span>
      </div>
    );
  };

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    setState({ title: '', content: '' });
  }, [messageType]);
  return (
    <>
      <MenuTabs
        options={[
          { name: '메시지 발송', to: '/users/send-message' },
          { name: '메시지 발송 내역', to: '/users/message' },
        ]}
      />
      <div className="wrapper">
        <div className="wrapper-title">
          통계{' '}
          <span className="text-sm text-gray-400">
            ({dayjs().add(-1, 'month').format('MM')}월 / {dayjs().format('MM')}월)
          </span>
        </div>
        <div className="mt-4">
          {!!billingInfo ? (
            <div className="flex text-gray-500">
              <div className="flex-1">
                <div className="text-lg mb-2">SMS</div>
                <div className="flex items-center gap-2">
                  <Statistic unit="건">
                    {priceFormat(
                      billingInfo[Number(dayjs().add(-1, 'month').format('YYYYMM'))][
                        'NOTIF'
                      ].SMS.usage
                    )}
                  </Statistic>
                  <ArrowNarrowRightIcon className="h-5 w-5" />
                  <Statistic unit="건">
                    {priceFormat(
                      billingInfo[Number(dayjs().format('YYYYMM'))]['NOTIF'].SMS.usage
                    )}
                  </Statistic>
                </div>
                <div className="flex items-center gap-2">
                  <Statistic unit="원">
                    {priceFormat(
                      billingInfo[Number(dayjs().add(-1, 'month').format('YYYYMM'))][
                        'NOTIF'
                      ].SMS.cost
                    )}
                  </Statistic>
                  <ArrowNarrowRightIcon className="h-5 w-5" />
                  <Statistic unit="원">
                    {priceFormat(
                      billingInfo[Number(dayjs().format('YYYYMM'))]['NOTIF'].SMS.cost
                    )}
                  </Statistic>
                </div>
              </div>
              <div className="flex-1">
                <div className="text-lg mb-2">LMS</div>
                <div className="flex items-center gap-2">
                  <Statistic unit="건">
                    {priceFormat(
                      billingInfo[Number(dayjs().add(-1, 'month').format('YYYYMM'))][
                        'NOTIF'
                      ].LMS.usage
                    )}
                  </Statistic>
                  <ArrowNarrowRightIcon className="h-5 w-5" />
                  <Statistic unit="건">
                    {priceFormat(
                      billingInfo[Number(dayjs().format('YYYYMM'))]['NOTIF'].LMS.usage
                    )}
                  </Statistic>
                </div>
                <div className="flex items-center gap-2">
                  <Statistic unit="원">
                    {priceFormat(
                      billingInfo[Number(dayjs().add(-1, 'month').format('YYYYMM'))][
                        'NOTIF'
                      ].LMS.cost
                    )}
                  </Statistic>
                  <ArrowNarrowRightIcon className="h-5 w-5" />
                  <Statistic unit="원">
                    {priceFormat(
                      billingInfo[Number(dayjs().format('YYYYMM'))]['NOTIF'].LMS.cost
                    )}
                  </Statistic>
                </div>
              </div>
            </div>
          ) : (
            <Spinner className="h-5 w-5 text-black" />
          )}
        </div>
      </div>
      <div className="wrapper">
        <div className="wrapper-title">메시지 작성</div>
        <div className="mt-1">
          - 카카오친구톡 전송은 현재 지원하지 않습니다. 카카오채널로 이동하여 직접
          전송해주세요.{' '}
          <a href="https://center-pf.kakao.com/_YtxmpT/dashboard" target="_blank">
            카카오채널로 이동하기
          </a>
        </div>

        <div className="mt-3 flex gap-10">
          <div className="w-[1000px] flex-1">
            <Card
              items={[
                {
                  name: '메시지 방식',
                  renderItem: () => (
                    <Radio
                      id="messageType"
                      onChange={(messageType) => {
                        if (!!content.length) {
                          if (
                            !window.confirm(
                              '작성한 메시지가 사라집니다. 메시지 방식을 바꾸시겠습니까?'
                            )
                          )
                            return;
                          setState({ messageType, content: '' });
                        } else setState({ messageType });
                      }}
                      options={[
                        { value: 'SMS', name: 'SMS' },
                        { value: 'MMS', name: 'MMS' },
                      ]}
                      value={messageType}
                    />
                  ),
                  stretch: true,
                },
                {
                  name: '발신번호',
                  renderItem: () => FETCHING_TEL,
                  stretch: true,
                },
                {
                  name: '발송 대상',
                  renderItem: () => (
                    <div>
                      <div className="mb-2">
                        <Select value={type} size="sm" name="type" onChange={onChange}>
                          <option value="ALL">전체 회원</option>
                          <option value="USER_TYPE">회원등급 선택 (미구현)</option>
                          <option value="GROUP">대량 발송 그룹 선택 (미구현)</option>
                          <option value="DIRECT_UPLOAD">직접 첨부</option>
                          <option value="DIRECT_INPUT">직접 입력</option>
                        </Select>
                      </div>
                      {type === 'ALL' && (
                        <div className="text-sm text-gray-400">
                          발송가능한 회원수: {priceFormat(smsAllowedUserCount)}명 /{' '}
                          {priceFormat(count)}명
                        </div>
                      )}
                      {type === 'USER_TYPE' && (
                        <div className="flex items-center gap-2">
                          <Select
                            value={userType}
                            name="userType"
                            onChange={onChange}
                            className="w-24"
                            size="xs"
                          >
                            <option value="USER">일반</option>
                            <option value="VIP">VIP</option>
                          </Select>
                          <Button size="xs" theme="secondary" onClick={onManageGrade}>
                            회원등급관리
                          </Button>
                        </div>
                      )}
                      {type === 'GROUP' && (
                        <div>
                          <div className="flex items-center gap-2"></div>
                          <Select className="w-24" size="xs">
                            <option value="" selected disabled>
                              그룹명1
                            </option>
                          </Select>
                          <Button size="xs" theme="secondary" onClick={onManageGroup}>
                            발송그룹관리
                          </Button>
                        </div>
                      )}
                      {type === 'DIRECT_UPLOAD' && (
                        <div className="space-y-2">
                          <div className="flex items-center gap-2">
                            <Button
                              size="xs"
                              theme="secondary"
                              onClick={onUploadExcelFile}
                            >
                              액셀파일업로드
                            </Button>
                            {excelFile && (
                              <>
                                <span>{excelFile.name}</span>
                                <XCircleIcon
                                  className="h-5 w-5 text-gray-400 cursor-pointer"
                                  onClick={() => setState({ excelFile: null })}
                                />
                              </>
                            )}
                          </div>
                          <div className="flex items-center gap-2">
                            <Button
                              size="xs"
                              theme="secondary"
                              onClick={onDownloadFormat}
                            >
                              포맷다운로드
                            </Button>
                            {formatFile && (
                              <>
                                <span>{formatFile.name}</span>
                                <XCircleIcon
                                  className="h-5 w-5 text-gray-400 cursor-pointer"
                                  onClick={() => setState({ formatFile: null })}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {type === 'DIRECT_INPUT' && (
                        <div>
                          <ReInput
                            size="xs"
                            className="w-56"
                            value={phoneNumbers}
                            name="phoneNumbers"
                            onChange={onChange}
                            placeholder="휴대폰번호 입력. 콤마(,)로 구분 가능"
                          />
                        </div>
                      )}
                    </div>
                  ),
                  stretch: true,
                },
                {
                  name: '수신거부자 포함 여부',
                  renderItem: () => (
                    <Switch
                      checked={includeNotAllowed}
                      onChange={(includeNotAllowed) => setState({ includeNotAllowed })}
                    />
                  ),
                  stretch: true,
                },
                {
                  name: '광고성 정보 여부',
                  renderItem: () => (
                    <Switch checked={isAd} onChange={(isAd) => setState({ isAd })} />
                  ),
                  stretch: true,
                },
                {
                  name: '발송타입',
                  renderItem: () => (
                    <div className="flex items-center gap-3">
                      <Radio
                        id="sendType"
                        value={sendType}
                        onChange={(sendType) => setState({ sendType })}
                        options={[
                          { name: '즉시발송', value: 'DIRECT' },
                          { name: '예약발송', value: 'RESERVE' },
                        ]}
                      />
                      <ReInput
                        size="xs"
                        className="w-56"
                        value={sendAt}
                        name="sendAt"
                        type="datetime-local"
                        onChange={onChange}
                        min={dayjs().format('YYYY-MM-DDTHH:mm')}
                      />
                    </div>
                  ),
                  stretch: true,
                },
              ]}
            />
            <div className="text-right">
              <Button isLoading={isSubmitting} onClick={onSubmit}>
                전송하기
              </Button>
            </div>
          </div>
          <div className="space-y-2 flex-1">
            {messageType === 'MMS' && (
              <ReInput
                value={title}
                name="title"
                onChange={onChange}
                placeholder="제목"
                className="w-full"
              />
            )}
            <Textarea
              onChange={onChange}
              spellCheck={false}
              value={content}
              name="content"
              rows={20}
              placeholder="메시지를 입력하세요."
              maxLength={messageType === 'SMS' ? 90 : 2000}
            />
            <div className="text-right">
              {content.length}/{messageType === 'SMS' ? 90 : 2000}bytes
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSendMessagePage;
