import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';
import { MenuList } from 'components';

const menu = [
  { link: '/product/brand/brandList', title: '브랜드 목록' },
  { link: '/product/brand/brandMapping', title: '브랜드 매핑' },
];

const RouteList = [
  {
    path: '/product/brand/brandList',
    component: pages.BrandList,
    exact: true,
  },
  {
    path: '/product/brand/brandDetail',
    component: pages.BrandDetail,
  },
  {
    path: '/product/brand/brandMapping',
    component: pages.brandMapping,
  },
];

const Brand = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => (
          <>
            <MenuList items={menu} />
            <route.component />
          </>
        )}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Brand;
