import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  row-gap: 10px;
`;

export const Preview = styled.div``;

export const OuterStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 400px',
  columnGap: '20px',
};

export const H4 = styled.h4`
  margin-top: 50px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  border: 0.5px solid #b4b4b4;
`;

export const LeftTd = styled.td`
  background: #f0f0f0;
  width: 160px;
  padding: 13px 11px;
`;
export const Td = styled.td`
  padding: 13px 15px;
  display: flex;
  align-items: center;
`;

export const TextArea = styled.textarea`
  height: 32px;
  padding: 5px;
  width: 100%;
  max-width: 900px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin: 0 5px 0 0;
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 5px 0 0;
`;
export const Select = styled.select`
  padding: 3px 5px 3px 0;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
  user-select: none;
`;

export const DescSpan = styled.span`
  color: #a8a8a8;
  font-size: 12px;
`;

export const InputDate = styled.input.attrs({ type: 'date' })`
  margin: 0 10px;
`;

export const InfoSpan = styled.span`
  margin-right: 20px;
`;

export const SetTabContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
export const ModifyButton = styled.button`
  margin: 10px;
  border: none;
  color: #ff6f61;
  width: 110px;
  height: 45px;
  background: #000000;
  border-radius: 5px;
`;

export const ResetButton = styled.button`
  border: none;
  background: #dddddd;
  border-radius: 5px;
  padding: 5px 10px;
`;

export const Button = styled.button`
  border: none;
  height: 25px;
  background: #3d3d3d;
  color: #ff6f61;
  padding: 0 5px;
`;

export const BigTextArea = styled.textarea.attrs({
  placeholder: '페칭코드를 쉼표 또는 엔터로 구분하여 입력해주세요.',
})`
  margin-top: 10px;
  padding: 5px;
  width: 100%;
  height: 300px;
`;
