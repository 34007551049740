import { combineReducers } from 'redux';
import user from './user';
import detailNotice from './detailNotice';
import selectShopData from './selectShopData';
import selectItems from './selectItems';
import exhibition from './exhibition';
import selectNotice from './selectNotice';
import selectCategory from './selectCategory';
import promotion from './promotion';
import main from './main';
import invalidUrls from './invalidUrls';

export default combineReducers({
  user,
  detailNotice,
  selectShopData,
  selectItems,
  exhibition,
  selectNotice,
  selectCategory,
  promotion,
  main,
  invalidUrls,
});
