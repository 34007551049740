import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loginAction, logoutAction } from 'actions/action';
import Form from 'react-bootstrap/Form';
import { axios, fetchingAPI } from 'api/axios';
import { request, useObject, useQuery, useUser } from 'services';
import * as style from './style';
import { Button } from 'components';
import { toast } from 'react-toastify';
import { IAdminUser } from 'types';

interface State {
  userID: string;
  userPW: string;
  isLoading: boolean;
}

const LoginPage = ({}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { query } = useQuery<{ redirectUrl: string }>();
  const { pathname } = useLocation();
  const { setUser, resetUser } = useUser();
  const [{ userPW, userID, isLoading }, setState, onChange] = useObject<State>({
    userID: '',
    userPW: '',
    isLoading: false,
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!userID || !userPW) {
      toast.info('빈칸을 모두 작성하세요');
      return;
    }
    setState({ isLoading: true });
    try {
      const {
        data: { data: accessToken },
      } = await axios.post<any, { data: { data: string } }>('/api/auth/login', {
        userID,
        userPW,
        needAdminAuthority: true,
      });
      request.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      fetchingAPI.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      localStorage.setItem('fetchingAPI_AUTH_TOKEN', `Bearer ${accessToken}`);

      const { info } = await request.get<any, { info: IAdminUser }>('/admin/my-info', {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      dispatch(loginAction(info));
      setUser(info);
      request.defaults.validateStatus = (status: number) => {
        if (status === 401) {
          delete request.defaults.headers.common['Authorization'];
          delete axios.defaults.headers.common['Authorization'];
          delete fetchingAPI.defaults.headers.common['Authorization'];
          localStorage.removeItem('fetchingAPI_AUTH_TOKEN');
          dispatch(logoutAction());
          resetUser();
          if (pathname !== '/login') push(`/login?redirectUrl=${pathname}`);
        }
        return status >= 200 && status < 300;
      };
      push(query.redirectUrl || '/');
    } catch (err) {
      console.error(err);
    } finally {
      setState({ isLoading: false });
    }
  };

  return (
    <style.Outer>
      <style.Inner>
        <Form onSubmit={handleLogin}>
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="text"
            placeholder="ID"
            value={userID}
            name="userID"
            onChange={onChange}
          />
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="password"
            placeholder="password"
            value={userPW}
            name="userPW"
            onChange={onChange}
          />
          <Button
            isLoading={isLoading}
            type="submit"
            className="w-full mt-3 mb-2"
            onSubmit={handleLogin}
          >
            로그인
          </Button>
        </Form>
      </style.Inner>
    </style.Outer>
  );
};

export default LoginPage;
