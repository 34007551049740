import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as style from './style';
import fetchingAPI from 'api/fetchingAPI';
import { toast } from 'react-toastify';
import { fetchingAPI as fetchingAPIInsstance } from 'api/axios';

const SetTabItemModal = ({ show, onHide, handleIsLoading, data }) => {
  const [value, setValue] = useState('');
  const { showModal, exhibitionId, id, name: tabName } = show;
  const { gender, name } = data;

  const postTab = async (string) => {
    const commaArr = string.split(',');
    const brArr = string.split('\n');
    let payload = commaArr.length > brArr.length ? commaArr : brArr;
    payload = payload.filter((v) => v !== '');

    try {
      handleIsLoading(true);
      await fetchingAPIInsstance.put(
        `/exhibition/${exhibitionId}/categories/${id}/item`,
        payload
      );
      toast.success('저장되었습니다.');
    } catch (error) {
      alert(`err in postTab in SetTabItemModal, ${error.message}`);
    } finally {
      handleIsLoading(false);
      onHide();
    }
  };

  const fetchData = async () => {
    const { data } = await fetchingAPI.getItemsInExhibitionCategory(exhibitionId, id);
    setValue(data.join(', '));
  };

  useEffect(() => {
    if (showModal) {
      fetchData();
    }
  }, [showModal]);

  const onInputTextArea = (e) => {
    if (e.target.value) {
      setValue(e.target.value);
    }
  };

  return (
    <Modal show={showModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>하부 카테고리 상품 추가</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        아래 상품을 [{gender === 'M' ? '남성' : '여성'}] [{name}]의 [{tabName}] 탭에
        추가합니다.
        <style.BigTextArea value={value} onChange={onInputTextArea} />
      </Modal.Body>
      <Modal.Footer>
        <style.Button
          onClick={() => {
            postTab(value);
          }}
        >
          저장
        </style.Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetTabItemModal;
