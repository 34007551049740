import styled from 'styled-components';

export const Outer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0,0,0,0.16);
`;

export const Inner = styled.div`
  position: relative;
  text-align: center;
  width: 500px;
  height: 500px;
  overflow-y: auto;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
`;

export const Header = styled.div`
  background-color: #1A1A1A;
  color: #FFFFFF;
  font-weight: bold;
  padding: 15px 20px;
`;

export const ButtonOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items; center;
  padding: 20px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items; center;
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  background-color: ${props => props.name === '취소' ? '#E5E5E5' : '#1A1A1A'};
  color: ${props => props.name === '취소' ? '#1A1A1A' : '#FFFFFF'};
`;

export const Content = styled.div`
  font-size: 18px;
`;

export const Text = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const CheckBoxOuter = styled.div`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: center;
  align-items: center;
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox'
})``;

export const Span = styled.span`
  margin-left: 10px;
  margin-right: 20px;
`;
