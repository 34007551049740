import { Button, Segmented, Switch } from 'antd';
import { ReInput } from 'components';
import { DeliveryMethod } from 'pages/ProductDetail/utils';
import React, { FC, useEffect, useState } from 'react';
import { request } from 'services';
import useSWR from 'swr';
import { NOrder } from 'types';
import {
  getCouponDescription,
  getCouponDiscountedAmount,
  toNOrderItemOrderDetail,
  toRealTimePricePayload,
  useRealTimePrice,
} from './helper';

export const RealtimePrice: FC<{
  realCurrency: string;
  onChange: any;
  item: NOrder.ItemOrderDetail;
  shop: NOrder.ShopOrderDetail;
  setItem: (s: any) => any;
  orderAmount: number;
  couponDiscounted: number;
  pointDiscounted: number;
  couponDescription: string;
}> = ({
  realCurrency,
  onChange,
  item,
  shop,
  setItem,
  pointDiscounted,
  couponDiscounted,
  couponDescription,
}) => {
  const [mode, setMode] = useState<'VALUE' | 'RATE'>('RATE');
  const shopId = shop.shopId;
  const priceRuleId = item.priceRuleId || shop.priceRuleId || 0;
  const [deliveryMethodId, setDeliveryMethodId] = useState<number>(); //item.deliveryMethod 로 수정 필요
  const { data, isLoading, reload } = useRealTimePrice(
    toRealTimePricePayload(
      item,
      priceRuleId,
      item.isAdditionalUpdate ? 'update' : 'init',
      mode,
      deliveryMethodId
    )
  );
  const { data: availableDeliveryMethods } = useSWR<{
    data: DeliveryMethod[];
  }>(`/shop/${shopId}/price-rule/${priceRuleId}/delivery-methods`, request.get);

  useEffect(() => {
    if (data) {
      setItem({
        ...toNOrderItemOrderDetail(data),
        isAdditionalUpdate: !!item.isAdditionalUpdate,
      });
    }
  }, [data]);

  useEffect(() => {
    setDeliveryMethodId(undefined);
  }, [priceRuleId]);

  useEffect(() => {
    if (typeof item.fixedPriceKor == 'undefined' && data) {
      // useOrderDetail() 안에 리코일 orderDetailState 객체의 깊이 때문에 처음 로드시 여러 상품을 동시에 업데이트 할때 업데이트가 안됨.
      // 일단은 undefined일때 다시 렌더링 하는 식으로 해결
      const timer = setTimeout(() => {
        setItem({
          ...toNOrderItemOrderDetail(data),
          isAdditionalUpdate: !!item.isAdditionalUpdate,
        });
        console.error('unexpected rerender .. . . . .');
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [item.fixedPriceKor]);

  return (
    <div>
      <div className="flex justify-end mb-2">
        <Segmented
          options={
            availableDeliveryMethods?.data?.map((method) => ({
              value: method.id,
              label: method.name,
            })) ?? []
          }
          onChange={(v) => setDeliveryMethodId(v as number)}
          value={deliveryMethodId}
        />
      </div>
      <div className="flex justify-end mb-2 gap-1">
        <Switch
          unCheckedChildren="값 수정"
          checkedChildren="퍼센트 수정"
          checked={mode == 'RATE'}
          onChange={(rate) => {
            setMode(rate ? 'RATE' : 'VALUE');
            setItem({ isAdditionalUpdate: false });
          }}
        />
        <Button size="small" onClick={() => reload()}>
          재계산
        </Button>
      </div>
      <div className="flex justify-end">
        <div className="text-right text-sm">
          <div className="h-[26px]">
            {/* 상품 원가 */}
            <ReInput
              width={196}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              name="originPrice"
              suffix={realCurrency}
              onChange={onChange}
              align="right"
              value={item.originPrice}
              disabled={isLoading || shop.orderStatus === 'ORDER_COMPLETE'}
            />
          </div>
          <div>
            {/* 편집샵 할인 */}
            <ReInput
              width={196}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              prefix="-"
              suffix={realCurrency}
              name="shopDiscount"
              onChange={onChange}
              align="right"
              value={item.shopDiscount}
              disabled={isLoading || shop.orderStatus === 'ORDER_COMPLETE'}
            />
          </div>
          <div className="inline-flex items-center">
            {/* 편집샵 프로모션 */}
            <ReInput
              width={148}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              prefix="-"
              suffix={realCurrency}
              className="w-32"
              align="right"
              name="promotionDiscount"
              onChange={onChange}
              value={item.promotionDiscount}
              disabled={
                isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'RATE'
              }
            />
            <ReInput
              width={48}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              align="right"
              suffix="%"
              className="w-12 input-number-hide-buttons"
              type="number"
              name="promotionDiscountPercent"
              onChange={onChange}
              value={item.promotionDiscountPercent}
              disabled={
                isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'VALUE'
              }
            />
          </div>
          <div className="h-[26px]">
            = {item.fixedPrice} {realCurrency}
          </div>
          <div className="h-[26px]">= {item.fixedPriceKor} KRW</div>
          <div className="h-[26px]">- {item.deductedVat} KRW</div>
          <div className="h-[26px]">
            <div className="inline-flex items-center">
              <ReInput
                width={100}
                debounce
                onClick={(e) => e.stopPropagation()}
                size="xs"
                align="right"
                suffix="KRW"
                prefix="+"
                className="input-number-hide-buttons"
                type="number"
                name="dutyAndTax"
                onChange={onChange}
                value={item.dutyAndTax}
                disabled={
                  isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'RATE'
                }
              />
              {!item.isDutyAndTaxSeparately && (
                <ReInput
                  width={96}
                  debounce
                  onClick={(e) => e.stopPropagation()}
                  size="xs"
                  align="right"
                  suffix="%"
                  className="input-number-hide-buttons"
                  type="number"
                  name="dutyAndTaxPercent"
                  onChange={onChange}
                  value={item.dutyAndTaxPercent}
                  disabled={
                    isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'VALUE'
                  }
                />
              )}
              {item.isDutyAndTaxSeparately && (
                <>
                  <ReInput
                    width={48}
                    debounce
                    onClick={(e) => e.stopPropagation()}
                    size="xs"
                    align="right"
                    suffix="%"
                    className="input-number-hide-buttons"
                    type="number"
                    name="dutyPercent"
                    onChange={onChange}
                    value={item.dutyPercent}
                    disabled={
                      isLoading ||
                      shop.orderStatus === 'ORDER_COMPLETE' ||
                      mode == 'VALUE'
                    }
                  />
                  <ReInput
                    width={48}
                    debounce
                    onClick={(e) => e.stopPropagation()}
                    size="xs"
                    align="right"
                    suffix="%"
                    className="input-number-hide-buttons"
                    type="number"
                    name="taxPercent"
                    onChange={onChange}
                    value={item.taxPercent}
                    disabled={
                      isLoading ||
                      shop.orderStatus === 'ORDER_COMPLETE' ||
                      mode == 'VALUE'
                    }
                  />
                </>
              )}
            </div>
          </div>
          <div>
            <div className="inline-flex items-center">
              {/* 특소세 */}
              <ReInput
                width={148}
                debounce
                onClick={(e) => e.stopPropagation()}
                size="xs"
                prefix="+"
                suffix="KRW"
                className="w-32"
                align="right"
                name="special"
                onChange={onChange}
                value={item.special}
                disabled={
                  isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'RATE'
                }
              />
              <ReInput
                width={48}
                debounce
                onClick={(e) => e.stopPropagation()}
                size="xs"
                suffix="%"
                type="number"
                className="w-12 input-number-hide-buttons"
                name="specialPercent"
                align="right"
                onChange={onChange}
                value={item.specialPercent}
                disabled={
                  isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'VALUE'
                }
              />
            </div>
          </div>
          <div>
            <div className="inline-flex items-center">
              {/* 교육세 */}
              <ReInput
                debounce
                width={148}
                onClick={(e) => e.stopPropagation()}
                size="xs"
                prefix="+"
                suffix="KRW"
                className="w-32"
                align="right"
                name="educational"
                onChange={onChange}
                value={item.educational}
                disabled={
                  isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'RATE'
                }
              />
              <ReInput
                width={48}
                debounce
                onClick={(e) => e.stopPropagation()}
                size="xs"
                suffix="%"
                type="number"
                className="w-12 input-number-hide-buttons"
                name="educationalPercent"
                align="right"
                onChange={onChange}
                value={item.educationalPercent}
                disabled={
                  isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'VALUE'
                }
              />
            </div>
          </div>
          <div>
            {/* 배송비 */}
            <ReInput
              width={196}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              prefix="+"
              suffix="KRW"
              align="right"
              name="deliveryFee"
              onChange={onChange}
              value={item.deliveryFee}
              disabled={isLoading || shop.orderStatus === 'ORDER_COMPLETE'}
            />
          </div>
          <div>
            {/* 파스토 수수료 */}
            <ReInput
              width={196}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              prefix="+"
              suffix="KRW"
              align="right"
              name="waypointFee"
              onChange={onChange}
              value={item.waypointFee}
              disabled={isLoading || shop.orderStatus === 'ORDER_COMPLETE'}
            />
          </div>
          <div className="inline-flex items-center">
            {/* 페칭 수수료 */}
            <ReInput
              width={148}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              prefix="+"
              suffix="KRW"
              className="w-32"
              align="right"
              name="fetchingFee"
              onChange={onChange}
              value={item.fetchingFee}
              disabled={
                isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'RATE'
              }
            />
            <ReInput
              width={48}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              suffix="%"
              type="number"
              className="w-12 input-number-hide-buttons"
              name="fetchingFeePercent"
              align="right"
              onChange={onChange}
              value={item.fetchingFeePercent}
              disabled={
                isLoading || shop.orderStatus === 'ORDER_COMPLETE' || mode == 'VALUE'
              }
            />
          </div>
          <div>
            {/* 추가 수수료 */}
            <ReInput
              width={196}
              debounce
              onClick={(e) => e.stopPropagation()}
              size="xs"
              prefix="+"
              align="right"
              suffix="KRW"
              name="additionalFee"
              onChange={onChange}
              value={item.additionalFee}
              disabled={isLoading || shop.orderStatus === 'ORDER_COMPLETE'}
            />
          </div>
          <div className="h-[26px]">= {item.finalPrice} KRW</div>
          <div className="h-[26px]">
            - {couponDiscounted} KRW {couponDescription}
          </div>
          <div className="h-[26px]">- {pointDiscounted} KRW</div>
          <div className="h-[26px]">
            = {item.finalPrice - couponDiscounted - pointDiscounted} KRW
          </div>
          <div className="h-[26px]"></div>
        </div>
      </div>
    </div>
  );
};
