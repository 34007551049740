import React, { useState, useLayoutEffect, CSSProperties } from 'react';

export const useFetch = (fetcher: () => Promise<any>) => {
  const [data, setData] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  useLayoutEffect(() => {
    setIsLoading(true);
    fetcher()
      .then((data) => setData(data))
      .catch(() => setError(true))
      .finally(() => setIsLoading(false));
  }, [fetcher]);

  return [data, isLoading, error];
};

const dict = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'N',
  'M',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
export const groupBrandsByAlphabet = (brands: { name: string }[]) => {
  brands.sort();
  return brands.reduce(
    (acc, brand) => {
      const target = dict.findIndex(
        (v) => v.toLowerCase() === brand?.name?.[0]?.toLowerCase()
      );
      acc[target + 1][1]?.push(brand);
      return acc;
    },
    [['123', []], ...dict.map((al) => [al, []])] as [string, any[]][]
  );
};

export const getOptions = (data: [string, any[]][]) => {
  return [
    { label: '브랜드 전체', value: null },
    ...data.map(([label, options]) => ({
      label,
      options: options.map((option) => ({
        label: `${option.name} / ${option.krName}`,
        value: option.id,
      })),
    })),
  ];
};

export const benefitOptions = [
  {
    label: '적립금',
    value: 1,
  },
];

export const benefitValueOptions = [
  {
    label: '3,000원(텍스트리뷰)',
    value: 'POINT_TEXT',
  },
  {
    label: '5,000원(포토리뷰)',
    value: 'POINT_PHOTO',
  },
];

export const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const groupBadgeStyles: CSSProperties = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

export const formatGroupLabel = (data: any) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

export const filterOptions = [
  {
    label: '베스트리뷰',
    value: 'isBest',
  },
  {
    label: '포토리뷰',
    value: 'isPhotoReview',
  },
  {
    label: '지급 대기',
    value: 'isRewardPending',
  },
  {
    label: '지급 완료',
    value: 'isRewardCompleted',
  },
  {
    label: '미노출',
    value: 'isDeactivated',
  },
];

export const searchFilterOptions = [
  {
    label: '전체',
    value: 'ALL',
  },
  {
    label: '작성자이름',
    value: 'USER_NAME',
  },
  {
    label: '아이디',
    value: 'USER_ID',
  },
  {
    label: '전화번호',
    value: 'USER_PHONENUMBER',
  },
  {
    label: '리뷰내용',
    value: 'CONTENT',
  },
  {
    label: '상품명',
    value: 'ITEM_NAME',
  },
];

export const sortOptions = [
  {
    label: '최신순',
    value: 'RECENT',
  },
  {
    label: '도움순',
    value: 'LIKE',
  },
  {
    label: '조회순',
    value: 'VIEW',
  },
  {
    label: '평점순',
    value: 'RATING',
  },
];

export const selectStyle = {
  control: (style) => ({
    ...style,
    borderRadius: 0,
  }),
};

export const benefitNameMap = {
  LEGACY: '레거시 1000원',
  POINT_TEXT: '텍스트 리뷰 적립금 3,000원',
  POINT_PHOTO: '포토 리뷰 적립금 5,000원',
};

export const initialParams: Review.SearchParams = {
  brandId: null,
  endAt: null,
  isBest: null,
  isDeactivated: null,
  isRewardCompleted: null,
  isRewardPending: null,
  isPhotoReview: null,
  limit: 50,
  page: 1,
  searchQuery: null,
  searchType: 'ALL',
  sort: 'RECENT',
  startAt: null,
};
