import { PartnerShopRejectReasonType, PartnerShopStatus } from './types';

export const STATUS_OPTIONS: { value: PartnerShopStatus; name: string }[] = [
  { value: PartnerShopStatus.REQUEST, name: '가입 신청 상태' },
  { value: PartnerShopStatus.REJECT, name: '가입 거부 상태' },
  { value: PartnerShopStatus.ACTIVE, name: '샵 활성화 상태' },
  { value: PartnerShopStatus.STOP, name: '정지 상태' },
];

export const REJECT_OPTIONS: { value: PartnerShopRejectReasonType; name: string }[] = [
  {
    value: PartnerShopRejectReasonType.BUSINESS_ITEM_AND_TYPE,
    name: '업태, 업종 확인불가 ',
  },
  { value: PartnerShopRejectReasonType.ETC, name: '기타' },
  { value: PartnerShopRejectReasonType.IMAGE, name: '필수 이미지 미첨부' },
  { value: PartnerShopRejectReasonType.MAIL_ORDER_BUSINESS, name: '통신판매업정보 오류' },
  { value: PartnerShopRejectReasonType.TAXPAYER, name: '사업자정보 오류 ' },
];
