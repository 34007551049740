import styled from 'styled-components';

export const Container = styled.div`
  margin: 120px auto;
  padding: 20px;
  width: 90%;
  background-color: #fff;
`;

export const Title = styled.h2`
  color: #555555;
  font-weight: bold;
  font-size: 24px;
`;
export const SubTitle = styled.h5`
  color: #555555;
  font-weight: 500;
  font-size: 16px;
`;

export const TableWrapper = styled.section`
  margin: 24px 0;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 0.5px solid #989595;
  & th,
  td {
    border: 0.5px solid #989595;
    padding: 6px;
    font-size: 1em;
  }
`;

export const Thead = styled.thead`
  background-color: #e4e4e4;
  color: #989595;
  & th {
    text-align: center;
  }
`;

export const Td = styled.td`
  text-align: ${({ align }) => align || 'left'};
  width: ${({ width }) => width || 'auto'};
  color: ${({ color }) => color || '#555555'};
`;
export const SectionTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
`;
export const Tr = styled.tr``;

export const Input = styled.input`
  width: 100%;
`;
export const Button = styled.button`
  border: none;
  height: 25px;
  background: #3d3d3d;
  color: #ff6f61;
  padding: 0 5px;
`;
