import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  Title,
  DateBox,
  SearchButtonBox,
  CountBox,
  CountTotalBox,
  MoonLoading,
} from 'components';
import { LoadingContext } from 'context';
import fetchingLog from 'api/fetchingLog';
import * as style from './style';

const UserEvent = () => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [initial, setInitial] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [data, setData] = useState([]);

  const setDate = (start, last) => {
    setStartDate(start);
    setLastDate(last);
  };

  const getResultDate = (year, month, date) => {
    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  const getThisMonth = () => {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    lastDay = getResultDate(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      lastDay.getDate(),
    );
    setStartDate(firstDay);
    setLastDate(lastDay);
    setInitial(true);
  };

  const handleSearch = useCallback(async () => {
    try {
      handleIsLoading(true);
      const params = {
        start: startDate,
        end: lastDate,
      };

      const res = await fetchingLog.get('/api/action', {
        params,
      });

      setData(res.data);
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/action
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
    }
  }, [data, startDate, lastDate]);

  useEffect(() => {
    getThisMonth();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [initial]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <Title title={'유저 이벤트'} />
      <DateBox setDate={setDate} />
      <SearchButtonBox handleSearch={handleSearch} />
      <style.SubTitle>기간 내 이벤트 전체</style.SubTitle>
      <style.CountBoxWrapper flex={'row'}>
        {data.map((el, i) => {
          return <CountTotalBox key={i} data={el} />;
        })}
      </style.CountBoxWrapper>
      <style.SubTitle>APP</style.SubTitle>
      <style.CountBoxWrapper flex={'row'}>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            return (
              el.type === 'INFLOW' && (
                <CountBox key={i} title="유입" count={el.app} color="#1A1A1A" />
              )
            );
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'SEARCH') {
              return (
                <CountBox key={i} title="검색" count={el.app} color="#3E3E3E" />
              );
            }
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'BRAND') {
              return (
                  <CountBox
                      key={i}
                      title="브랜드 상세"
                      count={el.app}
                      color="#767676"
                  />
              );
            } else if (el.type === 'SHOP') {
              return (
                  <CountBox
                      key={i}
                      title="편집샵 상세"
                      count={el.app}
                      color="#767676"
                  />
              );
            } else if (el.type === 'PROMOTION') {
              return (
                  <CountBox
                      key={i}
                      title="프로모션 상세"
                      count={el.app}
                      color="#767676"
                  />
              );
            } else if (el.type === 'EXHIBITION') {
              return (
                  <CountBox
                      key={i}
                      title="기획전 상세"
                      count={el.app}
                      color="#767676"
                  />
              );
            }
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'LIKE') {
              return (
                <CountBox
                  key={i}
                  title="상품 좋아요"
                  count={el.app}
                  color="#767676"
                />
              );
            } else if (el.type === 'LIKE_BRAND') {
              return (
                <CountBox
                  key={i}
                  title="브랜드 좋아요"
                  count={el.app}
                  color="#767676"
                />
              );
            } else if (el.type === 'LIKE_SHOP') {
              return (
                <CountBox
                  key={i}
                  title="편집샵 좋아요"
                  count={el.app}
                  color="#767676"
                />
              );
            }
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'VIEW_APP') {
              return (
                <CountBox
                  key={i}
                  title="앱 상품 상세"
                  count={el.app}
                  color="#A7A7A7"
                  textColor="black"
                />
              );
            } else if (el.type === 'GO_TO_FETCHING_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="페칭결제 선택"
                  count={el.app}
                  color="#A7A7A7"
                  textColor="black"
                />
              );
            }
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'SHOP_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="편집샵 결제"
                  count={el.app}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            } else if (el.type === 'FETCHING_APP_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="페칭 결제"
                  count={el.app}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            } else if (el.type === 'NAVER_PAY_APP_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="네이버 페이 결제"
                  count={el.app}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            }
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'SHARE') {
              return (
                <CountBox
                  key={i}
                  title="공유"
                  count={el.app}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            } else if (el.type === 'SHARE_KAKAO') {
              return (
                <CountBox
                  key={i}
                  title="카카오톡 공유"
                  count={el.app}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            } else if (el.type === 'SHARE_KAKAO_ACTUAL') {
              return (
                <CountBox
                  key={i}
                  title="카카오톡 공유 완료"
                  count={el.app}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            }
          })}
        </style.CountBoxWrapper>
      </style.CountBoxWrapper>
      <style.SubTitle>WEB</style.SubTitle>
      <style.CountBoxWrapper flex={'row'}>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            return (
              el.type === 'INFLOW' && (
                <CountBox key={i} title="유입" count={el.web} color="#1A1A1A" />
              )
            );
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            return (
              el.type === 'VIEW' && (
                <CountBox
                  key={i}
                  title="상품 조회"
                  count={el.web}
                  color="#767676"
                />
              )
            );
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'LIKE') {
              return (
                <CountBox
                  key={i}
                  title="상품 좋아요"
                  count={el.web}
                  color="#A7A7A7"
                  textColor="black"
                />
              );
            } else if (el.type === 'CART') {
              return (
                <CountBox
                  key={i}
                  title="장바구니"
                  count={el.web}
                  color="#A7A7A7"
                  textColor="black"
                />
              );
            }
          })}
        </style.CountBoxWrapper>
        <style.CountBoxWrapper flex={'column'}>
          {data.map((el, i) => {
            if (el.type === 'FETCHING_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="페칭 결제"
                  count={el.web}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            } else if (el.type === 'FETCHING_MOBILE_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="모바일 페칭 결제"
                  count={el.web}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            } else if (el.type === 'NAVER_PAY_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="네이버 페이 결제"
                  count={el.web}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            } else if (el.type === 'SMARTSTORE_PURCHASE') {
              return (
                <CountBox
                  key={i}
                  title="스마트 스토어 결제"
                  count={el.web}
                  color="#CBCBCB"
                  textColor="black"
                />
              );
            }
          })}
        </style.CountBoxWrapper>
      </style.CountBoxWrapper>
    </style.Container>
  );
};

export default UserEvent;
