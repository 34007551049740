import { ILink } from 'types';

export * from './order';

export const IS_DEV = process.env.NODE_ENV === 'development';

export const IS_MAIN = window.location.origin === 'https://admin.fetching.work';

export const CONSOLE_COLOR = '#d9480f';

export const AUTH_LINKS = ['/login', '/signUp', '/changePassword'];

export const COMMERCE_URL = IS_MAIN
  ? 'https://fetching.co.kr'
  : 'https://summer.fetching.co.kr';

export const ADMIN_2_URL = IS_MAIN
  ? 'https://admin.fetching.co.kr'
  : 'https://winter.fetching.co.kr';

export const FETCHING_TEL = '02-566-8045';

export const HOME_MENU_LIST: ILink[] = [
  { to: '/dashboard/service', name: '서비스 지표' },
  { to: '/dashboard/marketing', name: '마케팅 지표' },
  { to: '/dashboard/md', name: 'MD 성과 지표' },
  { to: '/dashboard/google-analytics', name: '구글 애널리틱스' },
];

export const USER_MENU_LIST: ILink[] = [
  { to: '/users', name: '회원 조회' },
  { to: '/users/grade', name: '회원 등급 관리' },
  { to: '/users/reserve', name: '회원 적립금 관리' },
  { to: '/users/dormant', name: '휴면/탈퇴 회원 관리' },
  { to: '/users/auto-mailing', name: '자동 메일 발송 설정' },
  { to: '/users/auto-messaging', name: '자동 메시지 발송 설정' },
  { to: '/users/massive-mail', name: '대량 발송 그룹 관리' },
  { to: '/users/mail', name: '메일 발송 및 내역' },
  { to: '/users/send-message', name: '메시지 발송 및 내역' },
  { to: '/users/send-push', name: '푸시 발송 및 내역' },
  { to: '/users/register', name: '회원가입/탈퇴 설정' },
  { to: '/users/sns', name: 'SNS 로그인 설정' },
  { to: '/users/etc', name: '기타' },
];

export const HEADER_MENU_LIST: Array<ILink & { keyword: string }> = [
  { to: '/product/productList', name: '상품관리', keyword: 'product' },
  { to: '/orders/dashboard', name: '주문관리', keyword: 'orders' },
  { to: '/content/noticeList', name: '컨텐츠', keyword: 'content' },
  { to: '/users', name: '회원', keyword: 'users' },
  { to: '/dashboard/service', name: '지표', keyword: 'inflow' },
  { to: '/review', name: '리뷰관리', keyword: 'review' },
  { to: '/partner/register', name: '파트너센터 관리', keyword: 'partner' },
];

export const PRODUCT_MENU_LIST: ILink[] = [
  { to: '/product/productList', name: '상품' },
  { to: '/product/exhibitionManagement', name: '기획전' },
  { to: '/product/promotionManagement', name: '프로모션' },
  { to: '/product/category/categoryMapping', name: '카테고리' },
  { to: '/product/brand/brandList', name: '브랜드' },
  { to: '/product/shop/shopList', name: '편집샵' },
];

export const CONTENTS_MENU_LIST: ILink[] = [
  { to: '/content/noticeList', name: '공지 사항' },
  { to: '/content/main/m/layout', name: '남성 메인 관리' },
  { to: '/content/main/w/layout', name: '여성 메인 관리' },
  { to: '/content/brand/m/layout', name: '남성 브랜드 홈 관리' },
  { to: '/content/brand/w/layout', name: '여성 브랜드 홈 관리' },
  { to: '/content/shop/m/layout', name: '남성 편집샵 홈 관리' },
  { to: '/content/shop/w/layout', name: '여성 편집샵 홈 관리' },
];

export const DASHBOARD_MENU_LIST: ILink[] = [
  { to: '/dashboard/service', name: '서비스 지표' },
  { to: '/dashboard/marketing', name: '마케팅 지표' },
  { to: '/dashboard/md', name: 'MD 성과 지표' },
  { to: '/dashboard/google-analytics', name: '구글 애널리틱스' },
  { to: '/dashboard/inflow/inflowTotal', name: '유입' },
  { to: '/dashboard/sales/totalTransactionAmount', name: '매출' },
];

export const REVIEW_MENU_LIST: ILink[] = [{ to: '/review', name: '리뷰관리' }];

export const PARTNER_MENU_LIST: ILink[] = [
  { to: '/partner/register', name: '가입 승인 관리' },
  { to: '/partner/confirm/shop', name: '상품 승인 관리' },
];

export const PRODUCT_MENU_TITLE_LIST: ILink[] = [
  { to: '/products', name: '상품 목록' },
  { to: '/products/manage-group', name: '그룹 관리' },
  { to: '/products/crawler', name: '신상 가져오기' },
  { to: '/products/catalog', name: '카탈로그 관리' },
  { to: '/products/keyword', name: '상품 이름 키워드' },
];

export enum PATH {
  'APP' = '앱',
  'MOBILE_WEB' = '모바일웹',
  'PC_WEB' = 'PC웹',
}

export enum GENDER {
  'W' = '여성',
  'M' = '남성',
}

export enum INFLOW_ORIGIN_SOURCE {
  'facebook' = '페이스북',
  'instagram' = '인스타그램',
  'google' = '구글',
  'naver' = '네이버',
  'kakao' = '카카오',
  'daum' = '다음',
}

export enum INFLOW_ORIGIN_CAMPAIGN {
  'fb' = '페이스북',
  'ig' = '인스타그램',
  'gads' = '구글애즈',
  'gdn' = '구글 디스플레이 네트워크',
  'gfeed' = '구글 피드',
  'nsearch' = '네이버 파워링크',
  'nshopping' = '네이버 쇼핑 검색',
  'nbrand' = '네이버 브랜드 검색',
  'nfeed' = '네이버 피드',
  'nblog' = '네이버 블로그',
  'ncafe' = '네이버 카페',
  'shoppinghow' = '다음 쇼핑하우',
  'etc' = '웹사이트 url',
}

export enum USER_DETAIL_MENU_LIST {
  'profile' = '회원 기본 조회',
  'order' = '주문 내역 조회',
  'point' = '적립금 내역 조회',
  'coupon' = '쿠폰 내역 조회',
  'basket' = '장바구니 조회',
  'interest' = '관심 내역 조회',
  'message' = '메시지 발송 내역',
  'push' = '푸시 발송 내역',
  'memo' = '관리자 메모',
  'log' = '회원 로그',
}

export enum EXHIBITION_DISCOUNT_TYPE {
  DISCOUNT_WITH_CONST,
  DISCOUNT_WITH_RATE,
  FREE_DELIVERY,
}
