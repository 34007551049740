import React from 'react';
import { Spinner } from 'react-bootstrap';
import * as style from './style';

const SearchButtonGroup = ({
  fetchItems,
  setSearchOptionTableState,
  initialState,
  setItems,
}) => {
  const resetSearchOptionTableState = () => {
    setSearchOptionTableState(initialState);
    setItems([]);
    // window.location.reload();
  };

  return (
    <style.Container>
      <style.SearchButton onClick={() => fetchItems()}>검색</style.SearchButton>
      <style.ResetButton onClick={resetSearchOptionTableState}>초기화</style.ResetButton>
    </style.Container>
  );
};

export default SearchButtonGroup;

export const SaveButtonGroup = ({
  mode,
  secondaryMode = '초기화',
  primaryCallback,
  secondaryCallback,
  loading = false,
}) => (
  <style.Container>
    <style.SearchButton onClick={primaryCallback} disabled={loading}>
      {loading ? <Spinner animation="border" /> : mode}
    </style.SearchButton>
    <style.ResetButton onClick={secondaryCallback}>{secondaryMode}</style.ResetButton>
  </style.Container>
);
