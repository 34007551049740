import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';

export default (payload) => {
  const { type, query, err } = payload;
  Sentry.configureScope((scope) => {
    if (type) scope.setExtra('type', type);
    if (query) scope.setExtra('query', query);
    scope.setExtra('url', decodeURI(window.location.pathname));
    scope.setExtra('date', dayjs().format('YYYY년 MM월 DD일 HH:mm:ss'));
  });
  Sentry.captureException(err);
};
