import React, { useState } from 'react';
import * as S from './style';
import { promotionAutomationDefaultOption } from 'pages/ProductList/initState';
import { PromotionAutomationConfigTable } from '../SearchOptionTable';
import { SaveButtonGroup } from 'components/UI/molecules/SearchButtonGroup';
import { trimAutomationParams } from 'pages/ProductList/utils';

const PromotionAutomationModal = ({ setShow, setAutomation, data }) => {
  const [config, setConfig] = useState({ ...promotionAutomationDefaultOption, ...data });

  console.log(config);

  return (
    <S.Background onClick={() => setShow(false)}>
      <S.Modal onClick={(e) => e.stopPropagation()}>
        <S.ModalHeader>자동 업데이트 조건</S.ModalHeader>
        <PromotionAutomationConfigTable
          searchOptionTableState={config}
          setSearchOptionTableState={setConfig}
        />
        <SaveButtonGroup
          mode="저장"
          secondaryMode="닫기"
          primaryCallback={() => {
            setAutomation(trimAutomationParams(config));
            setShow(false);
          }}
          secondaryCallback={() => setShow(false)}
        />
      </S.Modal>
    </S.Background>
  );
};

export default PromotionAutomationModal;
