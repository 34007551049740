import React, { useCallback, useEffect, useState, useContext } from 'react';
import { inflowByChannelRate, inflowByChannelCount } from 'chart';
import CanvasJSReact from 'assets/canvasjs.react';
import { LoadingContext } from 'context';
import {
  Title,
  DateBox,
  PlatformCheckBox,
  MoonLoading,
  ChannelPlatformCheckbox,
  ResearchButton, 
  SearchButtonBox, 
  NumberDisplayTable
} from 'components';
import fetchingLog from 'api/fetchingLog';
import * as style from './style';

const InflowByChannelRate = () => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [optionsCount, setOptionsCount] = useState(inflowByChannelCount);
  const [optionsRate, setOptionsRate] = useState(inflowByChannelRate);
  const [pieOptions, setPieOptions] = useState({
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: '선택 기한 누적 비율',
    },
  });
  const [totalValues, setTotalValues] = useState([]);

  const [startDate, setStartDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [platform, setPlatfrom] = useState([
    'pc_web',
    'mobile_web',
    'ios',
    'android',
  ]);
  const [channelPlatform, setChannelPlatform] = useState([
    { name: '전체', value: 'TOTAL' },
    { name: '네이버', value: 'NAVER' },
    { name: '다음카카오', value: 'KAKAO' },
    { name: '구글', value: 'GOOGLE' },
    { name: '페이스북', value: 'FACEBOOK' },
    { name: '인스타그램', value: 'INSTAGRAM' },
    { name: '바이럴', value: 'VIRAL' },
    { name: '문자 & 메일', value: 'MESSAGE' },
    { name: '기타', value: 'ETC' },
  ]);

  const handlePlatformCheck = (e) => {
    if (e.target.checked) {
      const find = platform.find((el) => el === e.target.value);
      if (!find) {
        setPlatfrom([...platform, e.target.value]);
      }
    } else {
      const resultPlatform = platform.filter((el) => el !== e.target.value);
      setPlatfrom(resultPlatform);
    }
  };

  const handleChannelPlatformChecked = (e) => {
    const result = JSON.parse(e.target.value);
    if (e.target.checked) {
      if (result.name === '전체') {
        setChannelPlatform([
          { name: '전체', value: 'TOTAL' },
          { name: '네이버', value: 'NAVER' },
          { name: '다음카카오', value: 'KAKAO' },
          { name: '구글', value: 'GOOGLE' },
          { name: '페이스북', value: 'FACEBOOK' },
          { name: '인스타그램', value: 'INSTAGRAM' },
          { name: '바이럴', value: 'VIRAL' },
          { name: '문자 & 메일', value: 'MESSAGE' },
          { name: '기타', value: 'ETC' },
        ]);
      } else {
        const find = channelPlatform.find((el) => el.name === result.name);
        if (!find) {
          setChannelPlatform([...channelPlatform, result]);
        }
      }
    } else {
      if (result.name === '전체') {
        setChannelPlatform([]);
      } else {
        const resultChannelPlatform = channelPlatform.filter(
          (el) => el.name !== result.name && el.name !== '전체',
        );
        setChannelPlatform(resultChannelPlatform);
      }
    }
  };

  const setDate = (start, last) => {
    setStartDate(start);
    setLastDate(last);
  };

  const getResultDate = (year, month, date) => {
    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  const getThisMonth = () => {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    lastDay = getResultDate(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      lastDay.getDate(),
    );
    setStartDate(firstDay);
    setLastDate(lastDay);
  };

  const handleSearch = useCallback(async () => {
    try {
      handleIsLoading(true);
      const result = {};

      const channels = channelPlatform
        .filter((el) => el.name !== '전체')
        .map((el) => el.value);

      const params = {
        start: startDate,
        end: lastDate,
        platforms: platform,
        channels,
      };

      const res = await fetchingLog.get('/api/inflow/ratio/channel', {
        params,
      });

      const { byDate, total } = res.data;

      setTotalValues([{
        name: '전체',
        value: Object.keys(total.count).reduce((prevValue, value) => parseInt(prevValue || 0) + parseInt(total.count[value])),
      }, {
        name: '네이버',
        value: total.count.NAVER || 0,
      }, {
        name: '카카오',
        value: total.count.KAKAO || 0,
      }, {
        name: '구글',
        value: total.count.GOOGLE || 0,
      }, {
        name: '페이스북',
        value: total.count.FACEBOOK || 0,
      }, {
        name: '인스타그램',
        value: total.count.INSTAGRAM || 0,
      }, {
        name: '바이럴',
        value: total.count.VIRAL || 0,
      }, {
        name: '문자',
        value: total.count.MESSAGE || 0,
      }, {
        name: '기타',
        value: total.count.ETC || 0,
      }])

      result.naver = byDate.map((el) => {
        return { label: el.date, y: el.ratio.NAVER };
      });
      result.facebook = byDate.map((el) => {
        return { label: el.date, y: el.ratio.FACEBOOK };
      });
      result.google = byDate.map((el) => {
        return { label: el.date, y: el.ratio.GOOGLE };
      });
      result.instagram = byDate.map((el) => {
        return { label: el.date, y: el.ratio.INSTAGRAM };
      });
      result.kakao = byDate.map((el) => {
        return { label: el.date, y: el.ratio.KAKAO };
      });
      result.message = byDate.map((el) => {
        return { label: el.date, y: el.ratio.MESSAGE };
      });
      result.viral = byDate.map((el) => {
        return { label: el.date, y: el.ratio.VIRAL };
      });
      result.etc = byDate.map((el) => {
        return { label: el.date, y: el.ratio.ETC };
      });

      result.naverCount = byDate.map((el) => {
        return { label: el.date, y: el.count.NAVER };
      });
      result.facebookCount = byDate.map((el) => {
        return { label: el.date, y: el.count.FACEBOOK };
      });
      result.googleCount = byDate.map((el) => {
        return { label: el.date, y: el.count.GOOGLE };
      });
      result.instagramCount = byDate.map((el) => {
        return { label: el.date, y: el.count.INSTAGRAM };
      });
      result.kakaoCount = byDate.map((el) => {
        return { label: el.date, y: el.count.KAKAO };
      });
      result.messageCount = byDate.map((el) => {
        return { label: el.date, y: el.count.MESSAGE };
      });
      result.viralCount = byDate.map((el) => {
        return { label: el.date, y: el.count.VIRAL };
      });
      result.etcCount = byDate.map((el) => {
        return { label: el.date, y: el.count.ETC };
      });

      handleIsLoading(false);

      setOptionsRate({
        ...optionsRate,
        data: [
          {
            type: 'stackedColumn100',
            name: '네이버',
            showInLegend: true,
            color: '#95C199',
            dataPoints: result.naver,
          },
          {
            type: 'stackedColumn100',
            name: '인스타그램',
            showInLegend: true,
            color: '#C18186',
            dataPoints: result.instagram,
          },
          {
            type: 'stackedColumn100',
            name: '다음&카카오',
            showInLegend: true,
            color: '#C1AB81',
            dataPoints: result.kakao,
          },
          {
            type: 'stackedColumn100',
            name: '바이럴',
            showInLegend: true,
            color: '#9981C1',
            dataPoints: result.viral,
          },
          {
            type: 'stackedColumn100',
            name: '문자&메일',
            showInLegend: true,
            color: '#B0C181',
            dataPoints: result.message,
          },
          {
            type: 'stackedColumn100',
            name: '페이스북',
            showInLegend: true,
            color: '#818FC1',
            dataPoints: result.facebook,
          },
          {
            type: 'stackedColumn100',
            name: '구글',
            showInLegend: true,
            color: '#C19881',
            dataPoints: result.google,
          },
          {
            type: 'stackedColumn100',
            name: '기타',
            showInLegend: true,
            color: '#C7C7C7',
            dataPoints: result.etc,
          },
        ],
      });

      setOptionsCount({
        ...optionsCount,
        data: [
          {
            type: 'stackedColumn',
            name: '네이버',
            showInLegend: true,
            color: '#95C199',
            dataPoints: result.naverCount,
          },
          {
            type: 'stackedColumn',
            name: '인스타그램',
            showInLegend: true,
            color: '#C18186',
            dataPoints: result.instagramCount,
          },
          {
            type: 'stackedColumn',
            name: '다음&카카오',
            showInLegend: true,
            color: '#C1AB81',
            dataPoints: result.kakaoCount,
          },
          {
            type: 'stackedColumn',
            name: '바이럴',
            showInLegend: true,
            color: '#9981C1',
            dataPoints: result.viralCount,
          },
          {
            type: 'stackedColumn',
            name: '문자&메일',
            showInLegend: true,
            color: '#B0C181',
            dataPoints: result.messageCount,
          },
          {
            type: 'stackedColumn',
            name: '페이스북',
            showInLegend: true,
            color: '#818FC1',
            dataPoints: result.facebookCount,
          },
          {
            type: 'stackedColumn',
            name: '구글',
            showInLegend: true,
            color: '#C19881',
            dataPoints: result.googleCount,
          },
          {
            type: 'stackedColumn',
            name: '기타',
            showInLegend: true,
            color: '#C7C7C7',
            dataPoints: result.etcCount,
          },
        ],
      });

      const { count, ratio } = total;

      const pieData = [
        { y: ratio.NAVER, label: `네이버 (${count.NAVER})`, color: '#95C199' },
        {
          y: ratio.INSTAGRAM,
          label: `인스타그램 (${count.INSTAGRAM})`,
          color: '#C18186',
        },
        {
          y: ratio.KAKAO,
          label: `다음&카카오 (${count.KAKAO})`,
          color: '#C1AB81',
        },
        { y: ratio.VIRAL, label: `바이럴 (${count.VIRAL})`, color: '#9981C1' },
        {
          y: ratio.MESSAGE,
          label: `문자&메일 (${count.MESSAGE})`,
          color: '#B0C181',
        },
        {
          y: ratio.FACEBOOK,
          label: `페이스북 (${count.FACEBOOK})`,
          color: '#818FC1',
        },
        { y: ratio.GOOGLE, label: `구글 (${count.GOOGLE})`, color: '#C19881' },
        { y: ratio.ETC, label: `기타 (${count.ETC})`, color: '#C7C7C7' },
      ];

      const resultPieData = pieData.filter((el) => el.y !== undefined);

      setPieOptions({
        ...pieOptions,
        data: [
          {
            type: 'pie',
            startAngle: 75,
            toolTipContent: '<b>{label}</b>: {y}%',
            showInLegend: 'true',
            legendText: '{label}',
            indexLabelFontSize: 16,
            indexLabel: '{label} - {y}%',
            dataPoints: resultPieData,
          },
        ],
      });
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/inflow/ratio/channel
      ${err}`);
      console.error(err);
    }
  }, [startDate, lastDate, platform, channelPlatform]);

  useEffect(() => {
    getThisMonth();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [platform, channelPlatform, startDate, lastDate]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <Title title={'채널별 유입 비율'} />
      <DateBox setDate={setDate} />
      <PlatformCheckBox
        platform={platform}
        handlePlatformCheck={(e) => handlePlatformCheck(e)}
      />
      <ChannelPlatformCheckbox
        platform={channelPlatform}
        handlePlatformChecked={(e) => handleChannelPlatformChecked(e)}
      />
      <ResearchButton onClick={handleSearch}/>
      <NumberDisplayTable values={totalValues} />
      <style.ChartWrapper>
        <CanvasJSChart options={pieOptions} />
      </style.ChartWrapper>
      <style.ChartWrapper>
        <CanvasJSChart options={optionsRate} />
      </style.ChartWrapper>
      <style.ChartWrapper>
        <CanvasJSChart options={optionsCount} />
      </style.ChartWrapper>
    </style.Container>
  );
};
export default InflowByChannelRate;
