import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px;
  text-align: center;
`;

export const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 70%;
`;

export const Title = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
  textAlign: 'center',
  fontWeight: 'bold',
  overflow: 'hidden',
  overflowX: 'auto',
};

export const Item = {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  alignItems: 'center',
  width: '100%',
};
