import React from 'react';
import type { FunctionComponent } from 'react';
import { useOrderDetail } from 'services';
import { Tooltip } from 'components';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { priceFormat } from 'utils';

const InfoItem: FunctionComponent<{ name: string; value: any }> = ({ name, value }) => {
  return (
    <div className="flex divide-y">
      <div className="flex-1 flex items-center pl-4 py-3 bg-gray-100">{name}</div>
      <div className="flex-1 flex items-center justify-end pr-4 py-3 font-semibold">
        {value}
      </div>
    </div>
  );
};

export interface Props {}
interface State {}

const PlacingOrderInfoTotalPay: FunctionComponent<Props> = () => {
  const [
    {
      itemTotalAmount,
      totalDeliveryFee,
      totalDiscount,
      totalDiscountDetail,
      payAmount,
      expectedPayAmount,
      expectedPoint,
    },
  ] = useOrderDetail();
  return (
    <div
      className="grid grid-cols-3 mb-3 text-sm divide-y divide-x"
      style={{ border: '1px solid #b4b4b4' }}
    >
      <InfoItem name="총 상품구매금액" value={`${priceFormat(itemTotalAmount)}원`} />
      <InfoItem name="총 배송비" value={`${priceFormat(totalDeliveryFee)}원`} />
      <InfoItem
        name="총 할인혜택"
        value={
          <div className="flex items-center gap-1">
            <span>- {priceFormat(totalDiscount)}원</span>
            <Tooltip
              padding={false}
              content={
                <table className="whitespace-nowrap">
                  <thead className="bg-gray-100 text-gray-500 py-2 px-3">
                    <tr>
                      <th className="py-2 px-3">유형</th>
                      <th className="py-2 px-3">할인명</th>
                      <th className="py-2 px-3 text-right">할인 금액</th>
                    </tr>
                  </thead>
                  <tbody className="font-normal">
                    {!!totalDiscountDetail.length ? (
                      totalDiscountDetail.map((item, key) => (
                        <tr key={key}>
                          <td className="py-2 px-3">
                            {item.type === 'COUPON' && '쿠폰'}
                            {item.type === 'POINT' && '적립금'}
                          </td>
                          <td>
                            {Array.isArray(item.couponNameList)
                              ? item.couponNameList.join(', ')
                              : ''}
                          </td>
                          <td className="py-2 px-3 text-right">
                            - {priceFormat(item.value)}원
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="py-2 px-3">
                          목록이 없습니다.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              }
              position="left"
            >
              <InformationCircleIcon className="w-4 h-4" />
            </Tooltip>
          </div>
        }
      />
      <InfoItem name="총 실결제금액" value={`${priceFormat(payAmount)}원`} />
      <InfoItem name="결제예정금액" value={`${priceFormat(expectedPayAmount)}원`} />
      <InfoItem name="적립예정금액" value={`${priceFormat(expectedPoint)}원`} />
    </div>
  );
};

export default PlacingOrderInfoTotalPay;
