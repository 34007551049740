import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import { selectShopBasicDataAction } from 'actions/action';
import * as style from './style';

const Shop = ({ shopData, history }) => {
  const dispatch = useDispatch();

  const { name, id } = shopData;

  return (
    <style.Wrapper>
      <style.Inner>
        <ListGroup.Item variant="dark" style={style.Title}>
          {name}
        </ListGroup.Item>
        <ListGroup.Item
          style={style.Item}
          action
          onClick={() => {
            dispatch(selectShopBasicDataAction(shopData));
            history.push(`/shop/shopSaleInfo/${id}`);
          }}
        >
          세일 정보
        </ListGroup.Item>
      </style.Inner>
    </style.Wrapper>
  );
};

export default withRouter(Shop);
