import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';
import { MenuList } from 'components';

const menu = [
  { link: '/product/category/categoryMapping', title: '카테고리 매핑' },
  { link: '/product/category/shopCategoryMapping', title: '편집샵 개별 매핑' },
];

const RouteList = [
  {
    path: '/product/category/categoryMapping',
    component: pages.CategoryMapping,
    exact: true,
  },
  {
    path: '/product/category/shopCategoryMapping',
    component: pages.ShopCategoryMapping,
    exact: true,
  },
];

const Category = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => (
          <>
            <MenuList items={menu} />
            <route.component />
          </>
        )}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Category;
