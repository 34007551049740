import styled from 'styled-components';

export const Wrapper = styled.div`
  display: none;
  @media all and (min-width: 1000px) {
    display: flex;
    background-color: white;
    overflow-x: auto;
    padding: 0 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
  }
`;

export const Menu = styled.div`
  font-size: 16px;
  font-weight: normal;
  padding: 15px 20px;
  cursor: pointer;
  color: ${(props) => props.color};
  white-space: nowrap;
`;

export const LinkStyle = {
  textDecoration: 'none',
  color: 'black',
};
