import { Loading, Portal, Spinner } from 'components';
import { fetcher } from 'pages/DashboardService';
import {
  Table,
  TableLeft,
  TableRight,
  TableDivider,
} from 'pages/ProductDetail/components';
import { DeliveryInfo, Shop } from './types';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { REJECT_OPTIONS } from 'pages/RegisterPartner/helper';
import { request } from 'services';
import { toast } from 'react-toastify';
import { deliveryCriteria } from './helper';
import { Button, Modal } from 'react-bootstrap';
import { PartnerShopRejectReasonType } from 'pages/RegisterPartner/types';
import { Input, Select, Tag } from 'antd';

const Partner: FC = () => {
  const params = useParams<{ partnerShopId: string }>();

  const { data, error, mutate } = useSWR<{ data: Shop; deliveryInfo: DeliveryInfo[] }>(
    `/partner/shop/${params.partnerShopId}`,
    fetcher,
    {
      shouldRetryOnError: false,
    }
  );
  const { data: shop, deliveryInfo } = data ?? {};
  const { data: signSignature } = useSWR(
    `/partner/file-upload/private?url=${encodeURIComponent(shop?.signSignature as any)}`,
    fetcher
  );
  const { data: nameSignature } = useSWR(
    `/partner/file-upload/private?url=${encodeURIComponent(shop?.nameSignature as any)}`,
    fetcher
  );

  const [isLoading, setIsLoading] = useState<null | 'accept' | 'reject'>(null);
  const onAccept = async (partnerShopId: string) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading('accept');
      await request.post(`/partner/${partnerShopId}/accept`);
      await mutate();
      toast.success('승인되었습니다.');
      setModal(null);
    } catch (error) {
      toast.error('승인 실패했습니다. 새로고침 후 다시 확인하세요.');
    } finally {
      setIsLoading(null);
    }
  };
  const onReject = async (
    partnerShopId: string,
    rejectReasonType: PartnerShopRejectReasonType,
    rejectReason: string
  ) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading('reject');
      await request.post(`/partner/${partnerShopId}/reject`, {
        rejectReason,
        rejectReasonType,
      });
      await mutate();
      toast.success('거절되었습니다.');
      setModal(null);
    } catch (error) {
      toast.error('거절 실패했습니다. 새로고침 후 다시 확인하세요.');
    } finally {
      setIsLoading(null);
    }
  };

  const [modal, setModal] = useState<null | 'accept' | 'reject'>(null);
  const [rejectReasonType, setRejectReasonType] =
    useState<PartnerShopRejectReasonType | null>(null);
  const [rejectReason, setRejectReason] = useState('');

  useEffect(() => {
    if (modal == null) {
      setRejectReasonType(null);
      setRejectReason('');
    }
  }, [modal]);

  const [modalImageUrl, setModalImageUrl] = useState<string | null>(null);
  const [decoded, setDecoded] = useState<string | null>(null);

  useEffect(() => {
    if (!modalImageUrl) {
      setDecoded(null);
      return;
    }
    request
      .get(`/partner/file-upload/private`, { params: { url: modalImageUrl } })
      .then((data: any) => {
        setDecoded(`data:image/png;base64,${data}`);
      })
      .catch(() => {
        toast.error('이미지를 불러오는데 실패했습니다.');
      });
  }, [modalImageUrl]);

  return (
    <>
      {!data && !error && <Loading />}
      <div className="bg-white z-20 absolute inset-0" style={{ bottom: 'auto' }}>
        <div className="px-7">
          {/*  */}
          <div className="pt-12">
            <h1 className="text-2xl font-bold">가입 요청 상세</h1>
          </div>
          {/*  */}
          <div className="mt-10">
            <h2 className="font-bold">파트너 승인상태</h2>
            <Table>
              <TableLeft>승인상태</TableLeft>
              <TableRight>
                <div>
                  {shop?.status === 'REJECT' && <Card color="red">승인 거절</Card>}
                  {shop?.status === 'REQUEST' && <Card color="green">승인 대기</Card>}
                  {shop?.status === 'ACTIVE' && <Card color="blue">승인 완료</Card>}
                </div>
                {shop?.status === 'REJECT' && (
                  <div className="flex gap-4">
                    <div className="font-bold">거절사유</div>
                    <div>
                      {
                        REJECT_OPTIONS.find(
                          (option) => option.value === shop.rejectReasonType
                        )?.name
                      }
                    </div>
                    (<div>{shop?.rejectReason}</div>)
                  </div>
                )}
              </TableRight>
              <TableDivider />
              <TableLeft>승인 담당자</TableLeft>
              <TableRight>
                <div className="flex gap-4">
                  <div>{shop?.updateUserName}</div>
                  <div className="text-gray-500">
                    {dayjs(shop?.createdAt).format('YY/M/DD HH:mm')}
                  </div>
                </div>
              </TableRight>
            </Table>
          </div>
          {/*  */}
          <div className="mt-10">
            <h2 className="font-bold">파트너 입점방식</h2>
            <Table>
              <TableLeft>호스팅사</TableLeft>
              <TableRight>
                <p>{shop?.hostingType}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>결제방식</TableLeft>
              <TableRight>
                <div className="flex gap-4">
                  {shop?.isDirectPaySupport && '이동 후 결제'}
                  {shop?.isDirectPaySupport && shop.isFetchingPaySupport && ', '}
                  {shop?.isFetchingPaySupport && '페칭 결제'}
                </div>
              </TableRight>
              <TableDivider />
              <TableLeft>이동 후 결제 수수료 정산</TableLeft>
              <div>
                <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                  <TableRight>{shop?.directPaySettleMethod}</TableRight>
                  <TableLeft>페칭 결제 수수료 정산</TableLeft>
                  <TableRight>{shop?.fetchingPaySettleMethod}</TableRight>
                </div>
              </div>
            </Table>
          </div>
          {/*  */}
          <div className="mt-10">
            <h2 className="font-bold">파트너 상세정보</h2>
            <Table>
              <TableLeft>파트너 아이디</TableLeft>
              <TableRight>{shop?.partnerId}</TableRight>
              <TableDivider />
              <TableLeft>파트너 비밀번호</TableLeft>
              <TableRight>{'*********'}</TableRight>
              <TableDivider />
              <TableLeft>상호(법인)명</TableLeft>
              <TableRight>{shop?.companyName}</TableRight>
              <TableDivider />
              <TableLeft>대표자명</TableLeft>
              <TableRight>{shop?.representativeName}</TableRight>
              <TableDivider />
              <TableLeft>사업자 등록번호</TableLeft>
              <TableRight>
                {shop?.taxpayerIdentificationNumber}{' '}
                <button
                  className="text-sm underline"
                  onClick={() => {
                    setModalImageUrl(shop?.taxpayerIdentificationImageUrl ?? null);
                  }}
                >
                  이미지 확인
                </button>
              </TableRight>
              <TableDivider />
              <TableLeft>통신판매업 신고번호</TableLeft>
              <TableRight>
                {shop?.mailOrderBusinessReportNumber}{' '}
                <button
                  className="text-sm underline"
                  onClick={() => {
                    setModalImageUrl(shop?.mailOrderImage ?? null);
                  }}
                >
                  이미지 확인
                </button>
              </TableRight>
              <TableDivider />
              <TableLeft>사업장 소재지</TableLeft>
              <TableRight>{shop?.businessLocation}</TableRight>
              <TableDivider />
              <TableLeft>업태</TableLeft>
              <TableRight>{shop?.businessType}</TableRight>
              <TableDivider />
              <TableLeft>업종</TableLeft>
              <TableRight>{shop?.businessItem}</TableRight>
            </Table>
          </div>
          {/*  */}
          <div className="mt-10">
            <h2 className="font-bold">쇼핑몰 정보</h2>
            <Table>
              <TableLeft>쇼핑몰명 (국문)</TableLeft>
              <div>
                <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                  <TableRight>{shop?.shopName}</TableRight>
                  <TableLeft>쇼핑몰명 (영문)</TableLeft>
                  <TableRight>{shop?.shopNameEnglish}</TableRight>
                </div>
              </div>
              <TableDivider />
              <TableLeft>사이트URL</TableLeft>
              <TableRight>
                <a target="_blank" href={shop?.shopUrl}>
                  {shop?.shopUrl}
                </a>
              </TableRight>
              <TableDivider />
              <TableLeft>고객센터 번호</TableLeft>
              <TableRight>{shop?.customerCenter}</TableRight>
              <TableDivider />
              <TableLeft>운영시간</TableLeft>
              <TableRight>
                {shop?.operatingTimeStart} ~ {shop?.operatingTimeEnd}
              </TableRight>
              <TableDivider />
              <TableLeft>쇼핑몰 담당자</TableLeft>
              <div>
                <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                  <TableRight>{shop?.managerName}</TableRight>
                  <TableLeft>연락처</TableLeft>
                  <TableRight>{shop?.managerPhoneNumber}</TableRight>
                </div>
              </div>
              <TableDivider />
              <TableLeft>이메일</TableLeft>
              <TableRight>{shop?.managerEmail}</TableRight>
              <TableDivider />
              <TableLeft>CS 담당자</TableLeft>
              <div>
                <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                  <TableRight>{shop?.csManagerName}</TableRight>
                  <TableLeft>연락처</TableLeft>
                  <TableRight>{shop?.csManagerPhoneNumber}</TableRight>
                </div>
              </div>
              <TableDivider />
              <TableLeft>이메일</TableLeft>
              <TableRight>{shop?.csManagerEmail}</TableRight>
              {/* <TableDivider />
              <TableLeft>배송비 타입</TableLeft>
              <div>
                <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                  <TableRight>{shop?.deliveryType}</TableRight>
                  <TableLeft>배송비</TableLeft>
                  <TableRight>{shop?.deliveryFee?.toLocaleString()}원</TableRight>
                </div>
              </div>
              <TableDivider />
              <TableLeft>무료배송 최소금액</TableLeft>
              <TableRight>
                {shop?.shopMinimumPriceForFreeDelivery?.toLocaleString()}원 이상 무료배송
              </TableRight>
              <TableDivider />
              <TableLeft>교환/반품비</TableLeft>
              <div>
                <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                  <TableRight>{deliveryInfo?.returnFee}</TableRight>
                  <TableLeft>교환/반품 가능 여부</TableLeft>
                  <TableRight>
                    {deliveryInfo?.returnable ? (
                      <Tag color="green">가능</Tag>
                    ) : (
                      <Tag color="red">불가</Tag>
                    )}
                  </TableRight>
                </div>
              </div>
              <TableDivider />
              <TableLeft>배송비 청구 기준 </TableLeft>
              <TableRight>
                {deliveryCriteria?.[shop?.deliveryCriteria as any] ?? ''}
              </TableRight>
              <TableDivider />
              <TableLeft>통관 부호 수령 여부</TableLeft>
              <TableRight>
                {deliveryInfo?.needCustomsNumber ? (
                  <Tag color="green">수령</Tag>
                ) : (
                  <Tag color="red">미수령</Tag>
                )}
              </TableRight>
              <TableDivider />
              <TableLeft>유의사항</TableLeft>
              <TableRight>
                <div
                  dangerouslySetInnerHTML={{ __html: deliveryInfo?.deliveryNotice ?? '' }}
                ></div>
              </TableRight> */}
            </Table>
          </div>
          <div className="mt-10">
            <h2 className="font-bold">배송정보</h2>
            {deliveryInfo?.map((deliveryInfo) => (
              <Table key={deliveryInfo.idx}>
                <TableLeft>배송비 타입</TableLeft>
                <div>
                  <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                    <TableRight>{deliveryInfo?.type}</TableRight>
                    <TableLeft>배송비</TableLeft>
                    <TableRight>
                      {deliveryInfo?.deliveryFee?.toLocaleString()}원
                    </TableRight>
                  </div>
                </div>
                <TableDivider />
                <TableLeft>무료배송 최소금액</TableLeft>
                <TableRight>
                  {deliveryInfo?.shopMinimumPriceForFreeDelivery?.toLocaleString()}원 이상
                  무료배송
                </TableRight>
                <TableDivider />
                <TableLeft>교환/반품비</TableLeft>
                <div>
                  <div className="grid" style={{ gridTemplateColumns: '1fr 200px 1fr' }}>
                    <TableRight>{deliveryInfo?.returnFee}</TableRight>
                    <TableLeft>교환/반품 가능 여부</TableLeft>
                    <TableRight>
                      {deliveryInfo?.returnable ? (
                        <Tag color="green">가능</Tag>
                      ) : (
                        <Tag color="red">불가</Tag>
                      )}
                    </TableRight>
                  </div>
                </div>
                <TableDivider />
                <TableLeft>배송비 청구 기준 </TableLeft>
                <TableRight>
                  {deliveryCriteria?.[deliveryInfo?.deliveryCriteria as any] ?? ''}
                </TableRight>
                <TableDivider />
                <TableLeft>통관 부호 수령 여부</TableLeft>
                <TableRight>
                  {deliveryInfo?.needCustomsNumber ? (
                    <Tag color="green">수령</Tag>
                  ) : (
                    <Tag color="red">미수령</Tag>
                  )}
                </TableRight>
                <TableDivider />
                <TableLeft>유의사항</TableLeft>
                <TableRight>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: deliveryInfo?.deliveryNotice ?? '',
                    }}
                  ></div>
                </TableRight>
              </Table>
            ))}
          </div>
          <div className="mt-10">
            <h2 className="font-bold">가입서명</h2>
            <Table>
              <TableLeft>파트너 서명</TableLeft>
              <TableRight>
                <img src={`data:image/png;base64,${signSignature}`} alt="" />
                <img
                  src={`data:image/png;base64,${nameSignature}`}
                  alt=""
                  className="mt-3"
                />
              </TableRight>
            </Table>
          </div>
        </div>
        {/*  */}
        <div style={{ paddingTop: 300 }}></div>
        <div
          className="bg-gray-300 flex gap-4 items-center justify-center fixed"
          style={{ padding: 30, left: 0, right: 0, bottom: 0 }}
        >
          <button
            className="bg-black text-white "
            style={{ padding: '12px 40px', background: '#FF5747', color: 'white' }}
            onClick={() => {
              setModal('reject');
            }}
          >
            승인 거절
          </button>
          <button
            className="bg-black text-white "
            style={{ padding: '12px 40px', background: '#0D9AE9', color: 'white' }}
            onClick={() => {
              setModal('accept');
            }}
          >
            승인 완료
          </button>
        </div>
      </div>
      <Portal elementId="modal-root">
        <Modal
          show={!!modalImageUrl}
          onHide={() => {
            setModalImageUrl(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>이미지 보기</Modal.Title>
          </Modal.Header>
          <Modal.Body>{decoded ? <img src={decoded} alt="" /> : <Spinner />}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalImageUrl(null)}>닫기</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={modal === 'accept'}
          onHide={() => {
            setModal(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>승인 완료</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ margin: '20px 0', fontSize: '20px' }}>
              파트너 센터 승인을 진행할까요?
            </p>
            <table className="w-full border-table">
              <tbody>
                <tr>
                  <th>상호명</th>
                  <td>{shop?.companyName}</td>
                </tr>
                <tr>
                  <th>대표명</th>
                  <td>{shop?.representativeName}</td>
                </tr>
                <tr>
                  <th>사업자등록번호</th>
                  <td>{shop?.taxpayerIdentificationNumber}</td>
                </tr>
                <tr>
                  <th>통신판매업번호</th>
                  <td>{shop?.mailOrderBusinessReportNumber}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    '승인을 진행할까요? 진행시 파트너에게 메세지가 자동발송됩니다.'
                  )
                ) {
                  onAccept(shop?.partnerShopId + '');
                }
              }}
            >
              {isLoading === 'accept' ? <Spinner /> : '완료'}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={modal === 'reject'}
          onHide={() => {
            setModal(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>승인 거절</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ margin: '20px 0', fontSize: '20px' }}>승인 거절 사유</p>
            <div>
              <Select
                className="w-full"
                onChange={(rejectReasonType: PartnerShopRejectReasonType) => {
                  setRejectReasonType(rejectReasonType);
                }}
                value={rejectReasonType}
              >
                <Select.Option value={null}>
                  승인 거절 사유를 선택해 주세요.
                </Select.Option>
                {REJECT_OPTIONS.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
              <div className="mt-3"></div>
              <Input.TextArea
                placeholder="파트너에게 전달될 사유입니다. 상세히 작성해주세요."
                rows={4}
                value={rejectReason}
                onChange={({ target: { value } }) => {
                  setRejectReason(value);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                if (!rejectReasonType) {
                  alert('승인 거절 사유를 선택해 주세요.');
                  return;
                }
                if (!rejectReason) {
                  alert('파트너에게 전달될 사유입니다. 상세히 작성해주세요.');
                  return;
                }
                if (
                  window.confirm(
                    '승인 거절을 진행할까요? 진행시 파트너에게 메세지가 자동발송됩니다.'
                  )
                ) {
                  onReject(
                    shop?.partnerShopId + '',
                    rejectReasonType as PartnerShopRejectReasonType,
                    rejectReason
                  );
                }
              }}
            >
              {isLoading === 'reject' ? <Spinner /> : '완료'}
            </Button>
          </Modal.Footer>
        </Modal>
      </Portal>
    </>
  );
};

export default Partner;

const colorSet = {
  red: '#FF5747',
  blue: '#0D9AE9',
  green: '#498935',
};

const Card: FC<{ color: 'red' | 'blue' | 'green' }> = ({ children, color }) => (
  <div
    style={{
      border: `1px solid ${colorSet[color]}`,
      color: colorSet[color],
      width: 'fit-content',
      margin: '4px 0',
      padding: '4px 8px',
    }}
  >
    {children}
  </div>
);
