import React from 'react';
import * as style from './style';

const CountBox = ({ title, count, color, textColor }) => {
  return (
    <style.Container color={color}>
      <style.Title textColor={textColor}>{title}</style.Title>
      <style.Count textColor={textColor}>{count}</style.Count>
    </style.Container>
  );
};
export default CountBox;
