import styled from "styled-components";

export const Container = styled.div`
display:flex;
align-items: center;`;
export const Select = styled.select`
margin:0 3px;`;
export const Input = styled.input.attrs({ type: 'number' })`
margin:0 3px;`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;
export const Label = styled.label`
margin:0;    
margin-right: 5px;`;
