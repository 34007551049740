import {
  Loading,
  Outer,
  Portal,
  RePagination,
  ShadowContainer,
  Spinner,
} from 'components';
import React, { FC, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DebounceInput } from 'react-debounce-input';
import { Button, Modal } from 'react-bootstrap';
import { fetcher } from 'pages/DashboardService';
import useSWR from 'swr';
import { getKey, request } from 'services';
import { PartnerShopRejectReasonType, PartnerShopStatus, Shop } from './types';
import { Select, Input } from 'antd';
import { REJECT_OPTIONS, STATUS_OPTIONS } from './helper';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

type Props = {};

const limit = 50;

const getErrorDescription = (error: any) => {
  switch (error?.response?.status) {
    case 404:
      return `서버에 정보가 없습니다. (${error?.response?.status})`;
    case 401:
    case 403:
      return `권한이 부족합니다. (${error?.response?.status})`;
    case 500:
      return `서버 내부 에러 (${error?.response?.status})`;
    default:
      return `잠시 후 다시 시도해주세요. (${error?.response?.status})`;
  }
};

const RegisterPartner: React.FC<Props> = ({}) => {
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState<PartnerShopStatus | null>(
    PartnerShopStatus.REQUEST
  );

  const { data, mutate, error } = useSWR<{ count: number; data: Shop[] }>(
    getKey(`/partner/shop`, {
      limit,
      page,
      searchText,
      status,
    }),
    fetcher,
    { shouldRetryOnError: false }
  );

  useEffect(() => {
    error && toast.error(getErrorDescription(error));
  }, [error]);

  const [isLoading, setIsLoading] = useState<null | 'accept' | 'reject'>(null);
  const onAccept = async (partnerShopId: string) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading('accept');
      await request.post(`/partner/${partnerShopId}/accept`);
      await mutate();
      toast.success('승인되었습니다.');
      setModal(null);
    } catch (error) {
      toast.error('승인 실패했습니다. 새로고침 후 다시 확인하세요.');
    } finally {
      setIsLoading(null);
    }
  };
  const onReject = async (
    partnerShopId: string,
    rejectReasonType: PartnerShopRejectReasonType,
    rejectReason: string
  ) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading('reject');
      await request.post(`/partner/${partnerShopId}/reject`, {
        rejectReason,
        rejectReasonType,
      });
      await mutate();
      toast.success('거절되었습니다.');
      setModal(null);
    } catch (error) {
      toast.error('거절 실패했습니다. 새로고침 후 다시 확인하세요.');
    } finally {
      setIsLoading(null);
    }
  };

  const [modal, setModal] = useState<null | 'accept' | 'reject'>(null);
  const [modalTarget, setModalTarget] = useState<Shop | null>(null);
  const [rejectReasonType, setRejectReasonType] =
    useState<PartnerShopRejectReasonType | null>(null);
  const [rejectReason, setRejectReason] = useState('');

  useEffect(() => {
    if (modal == null) {
      setModalTarget(null);
      setRejectReasonType(null);
      setRejectReason('');
    }
  }, [modal]);

  const [modalImageUrl, setModalImageUrl] = useState<string | null>(null);
  const [decoded, setDecoded] = useState<string | null>(null);

  useEffect(() => {
    if (!modalImageUrl) {
      setDecoded(null);
      return;
    }
    request
      .get(`/partner/file-upload/private`, { params: { url: modalImageUrl } })
      .then((data: any) => {
        setDecoded(`data:image/png;base64,${data}`);
      })
      .catch(() => {
        toast.error('이미지를 불러오는데 실패했습니다.');
      });
  }, [modalImageUrl]);

  return (
    <>
      {!data && !error && <Loading />}
      <Outer>
        <ShadowContainer>
          <h2 className="text-2xl font-bold py-3 border-b">가입 승인 관리</h2>
          <div>
            <div className="flex justify-end items-center gap-5 mt-10">
              <div style={{ width: 250, display: 'flex' }}>
                <Select
                  className="w-full"
                  onChange={(status: PartnerShopStatus) => {
                    setStatus(status);
                    setPage(1);
                  }}
                  value={status}
                >
                  <Select.Option value={null}>전체</Select.Option>
                  {STATUS_OPTIONS.map(({ name, value }) => (
                    <Select.Option value={value}>{name}</Select.Option>
                  ))}
                </Select>
              </div>
              <DebounceInput
                type="text"
                value={searchText ?? ''}
                onChange={({ target: { value } }) => {
                  setSearchText(value);
                  setPage(1);
                }}
                style={{ width: 200 }}
                debounceTimeout={2000}
              />
              <button className="bg-black px-3 py-2 text-white">검색</button>
            </div>
            <div style={{ marginTop: 20, overflowX: 'scroll' }}>
              <table className="w-full border-table" style={{ minWidth: '1800px' }}>
                <thead>
                  <tr>
                    <th>요청일</th>
                    <th>상호명(쇼핑몰명)</th>
                    <th>사이트URL</th>
                    <th>대표명</th>
                    <th>사업자 등록번호 </th>
                    <th>통신 판매업 정보</th>
                    <th>계약 방식</th>
                    <th>승인버튼</th>
                    <th>상태</th>
                    <th>비고</th>
                    <th>승인 담당자</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((partner) => (
                    <tr>
                      <td>{dayjs(partner.createdAt).format('YYYY-M-D')}</td>
                      <td>
                        <a
                          href="#"
                          onClick={() => {
                            window.open(
                              `/partner/${partner.partnerShopId}`,
                              `파트너 상세`,
                              'width=800,height=1000,menubar=no,status=no,toolbar=no,menubar=no,location=no,scrollbars=no,left=10000'
                            );
                          }}
                        >
                          {partner.companyName}({partner.shopName})
                        </a>
                        <div style={{ marginTop: 5 }}>
                          <Button
                            size="sm"
                            onClick={() => {
                              window.open(
                                `/partner/${partner.partnerShopId}`,
                                `파트너 상세`,
                                'width=800,height=1000,menubar=no,status=no,toolbar=no,menubar=no,location=no,scrollbars=no,left=10000'
                              );
                            }}
                          >
                            상세 보기
                          </Button>
                        </div>
                      </td>
                      <td>
                        <a target="_blank" href={partner.shopUrl}>
                          {partner.shopUrl}
                        </a>
                      </td>
                      <td>{partner.representativeName}</td>
                      <td>
                        {partner.taxpayerIdentificationNumber}
                        <br />
                        <button
                          className="text-sm underline"
                          onClick={() => {
                            setModalImageUrl(partner.taxpayerIdentificationImageUrl);
                          }}
                        >
                          이미지 확인
                        </button>
                      </td>
                      <td>
                        {partner.mailOrderBusinessReportNumber}
                        <br />
                        <button
                          className="text-sm underline"
                          onClick={() => {
                            setModalImageUrl(partner.mailOrderImage);
                          }}
                        >
                          이미지 확인
                        </button>
                      </td>
                      <td>
                        {partner.hostingType}/
                        <br />
                        <span className="font-bold">
                          {partner.isDirectPaySupport &&
                            partner.isFetchingPaySupport &&
                            '모두 사용'}
                          {partner.isDirectPaySupport &&
                            !partner.isFetchingPaySupport &&
                            '이동 후 결제'}
                          {!partner.isDirectPaySupport &&
                            partner.isFetchingPaySupport &&
                            '페칭 결제'}
                        </span>
                        <br />
                        <span>
                          (
                          {partner.isDirectPaySupport &&
                            `이동 후 결제 : ${partner.directPaySettleMethod}`}
                          {partner.isDirectPaySupport &&
                            partner.isFetchingPaySupport &&
                            ' / '}
                          {partner.isFetchingPaySupport &&
                            `페칭 결제 : ${partner.fetchingPaySettleMethod}`}
                          )
                        </span>
                      </td>
                      <td>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => {
                            setModal('reject');
                            setModalTarget(partner);
                          }}
                        >
                          승인 거절
                        </Button>
                        <div className="mt-3"></div>
                        <Button
                          size="sm"
                          onClick={() => {
                            setModal('accept');
                            setModalTarget(partner);
                          }}
                        >
                          승인 완료
                        </Button>
                      </td>
                      <td>
                        {partner.status === 'REJECT' && (
                          <Card color="red">승인 거절</Card>
                        )}
                        {partner.status === 'REQUEST' && (
                          <Card color="green">승인 대기</Card>
                        )}
                        {partner.status === 'ACTIVE' && (
                          <Card color="blue">승인 완료</Card>
                        )}
                      </td>
                      <td className="text-red-500">
                        {!!partner.rejectReasonType && '거절 사유 : '}
                        {
                          REJECT_OPTIONS.find(
                            (option) => option.value === partner.rejectReasonType
                          )?.name
                        }
                        <br />
                        {partner.rejectReason}
                      </td>
                      <td>
                        {partner.updateUserName}
                        <br />
                        {dayjs(partner.updatedAt).format('(YY/M/D HH:mm)')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between mt-10">
              <p>총 {data?.count ?? 0}개</p>
              <RePagination
                currentPage={page}
                totalCount={data?.count ?? 0}
                pageSize={limit}
                onPageChange={setPage}
              />
            </div>
          </div>
        </ShadowContainer>
      </Outer>
      <Portal elementId="modal-root">
        <Modal
          show={!!modalImageUrl}
          onHide={() => {
            setModalImageUrl(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>이미지 보기</Modal.Title>
          </Modal.Header>
          <Modal.Body>{decoded ? <img src={decoded} alt="" /> : <Spinner />}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalImageUrl(null)}>닫기</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={modal === 'accept'}
          onHide={() => {
            setModal(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>승인 완료</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ margin: '20px 0', fontSize: '20px' }}>
              파트너 센터 승인을 진행할까요?
            </p>
            <table className="w-full border-table">
              <tbody>
                <tr>
                  <th style={{ width: '150px' }}>상호명</th>
                  <td>{modalTarget?.companyName}</td>
                </tr>
                <tr>
                  <th style={{ width: '150px' }}>대표명</th>
                  <td>{modalTarget?.representativeName}</td>
                </tr>
                <tr>
                  <th style={{ width: '150px' }}>사업자등록번호</th>
                  <td>{modalTarget?.taxpayerIdentificationNumber}</td>
                </tr>
                <tr>
                  <th style={{ width: '150px' }}>통신판매업번호</th>
                  <td>{modalTarget?.mailOrderBusinessReportNumber}</td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                if (
                  window.confirm(
                    '승인을 진행할까요? 진행시 파트너에게 메세지가 자동발송됩니다.'
                  )
                ) {
                  onAccept(modalTarget?.partnerShopId + '');
                }
              }}
            >
              {isLoading === 'accept' ? <Spinner /> : '완료'}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={modal === 'reject'}
          onHide={() => {
            setModal(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>승인 거절</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p style={{ margin: '20px 0', fontSize: '20px' }}>승인 거절 사유</p>
            <div>
              <Select
                className="w-full"
                onChange={(rejectReasonType: PartnerShopRejectReasonType) => {
                  setRejectReasonType(rejectReasonType);
                }}
                value={rejectReasonType}
              >
                <Select.Option value={null}>
                  승인 거절 사유를 선택해 주세요.
                </Select.Option>
                {REJECT_OPTIONS.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
              <div className="mt-3"></div>
              <Input.TextArea
                placeholder="파트너에게 전달될 사유입니다. 상세히 작성해주세요."
                rows={4}
                value={rejectReason}
                onChange={({ target: { value } }) => {
                  setRejectReason(value);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                if (!rejectReasonType) {
                  alert('승인 거절 사유를 선택해 주세요.');
                  return;
                }
                if (!rejectReason) {
                  alert('파트너에게 전달될 사유입니다. 상세히 작성해주세요.');
                  return;
                }
                if (
                  window.confirm(
                    '승인 거절을 진행할까요? 진행시 파트너에게 메세지가 자동발송됩니다.'
                  )
                ) {
                  onReject(
                    modalTarget?.partnerShopId + '',
                    rejectReasonType as PartnerShopRejectReasonType,
                    rejectReason
                  );
                }
              }}
            >
              {isLoading === 'reject' ? <Spinner /> : '완료'}
            </Button>
          </Modal.Footer>
        </Modal>
      </Portal>
    </>
  );
};

export default RegisterPartner;

const colorSet = {
  red: '#FF5747',
  blue: '#0D9AE9',
  green: '#498935',
};

const Card: FC<{ color: 'red' | 'blue' | 'green' }> = ({ children, color }) => (
  <div
    style={{
      border: `1px solid ${colorSet[color]}`,
      color: colorSet[color],
      width: 'fit-content',
      margin: '8px auto',
      padding: '4px 8px',
    }}
  >
    {children}
  </div>
);
