import React, { useEffect, useState, useContext } from 'react';
import { LoadingContext } from 'context';
import { fetchingLog } from 'api/fetchingLog';
import {
  Title,
  DateBox,
  MoonLoading,
  PaymentPlatformCheckbox,
  ResearchButton, SearchButtonBox, NumberDisplayTable,
} from 'components';
import { totalTransactionAmountRate, totalTransactionAmount } from 'chart';
import CanvasJSReact from 'assets/canvasjs.react';
import * as style from './style';
import * as utils from './utils';

const platformInitState = [
  {
    name: '전체',
    value: [
      'SMARTSTORE',
      'NAVERPAY',
      'CAFE24_PC',
      'CAFE24_MOBILE',
      'APP_SHOP',
      'APP_FETCHING',
      'APP_NAVERPAY',
    ],
  },
  { name: 'App 편집샵 결제', value: 'APP_SHOP' },
  { name: 'App 페칭 결제', value: 'APP_FETCHING' },
  { name: 'App 네이버페이', value: 'APP_NAVERPAY' },
  { name: '카페24 PC', value: 'CAFE24_PC' },
  { name: '카페24 모바일', value: 'CAFE24_MOBILE' },
  { name: '네이버페이', value: 'NAVERPAY' },
  { name: '스마트스토어', value: 'SMARTSTORE' },
];

export default () => {
  const { CanvasJSChart } = CanvasJSReact;

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [optionsRate, setOptionsRate] = useState(totalTransactionAmountRate);
  const [pieOptions, setPieOptions] = useState({
    exportEnabled: true,
    animationEnabled: true,
    title: {
      text: '선택 기한 누적 비율',
    },
  });
  const [optionsCount, setOptionsCount] = useState(totalTransactionAmount);
  const [startDate, setStartDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [platform, setPlatform] = useState(platformInitState);
  const [totalValues, setTotalValues] = useState([]);

  const setCurrentDate = () => {
    const [firstDay, lastDay] = utils.getThisMonth();
    setDate(firstDay, lastDay);
  };

  const setDate = (start, last) => {
    setStartDate(start);
    setLastDate(last);
  };

  const handlePlatformCheck = (e) => {
    const result = JSON.parse(e.target.value);

    if (e.target.checked) {
      if (result.name === '전체') {
        setPlatform(platformInitState);
      } else {
        const find = platform.find((el) => el.name === result.name);
        if (!find) {
          setPlatform([...platform, result]);
        }
      }
    } else if (result.name === '전체') {
      setPlatform([]);
    } else {
      const resultPlatform = platform.filter(
        (el) => el.name !== result.name && el.name !== '전체',
      );
      setPlatform(resultPlatform);
    }
  };

  const makeChartOptionsCount = (data) => {
    setOptionsCount({
      ...optionsCount,
      data: utils.convertChartData(utils.templateChartOptionsCount, data),
    });
  };

  const makeChartOptionsRate = (data) => {
    setOptionsRate({
      ...optionsRate,
      data: utils.convertChartData(utils.templateChartOptionsRate, data),
    });
  };

  const makeChartPie = (total) => {
    const { sum, ratio } = total;

    const pieData = [
      {
        y: ratio.APP_FETCHING,
        label: `App 페칭 결제 (${sum.APP_FETCHING})`,
        color: '#641884',
      },
      {
        y: ratio.APP_NAVERPAY,
        label: `App 네이버페이 (${sum.APP_NAVERPAY})`,
        color: '#23a089',
      },
      {
        y: ratio.APP_SHOP,
        label: `App 편집샵 결제 (${sum.APP_SHOP})`,
        color: '#2f2d2d',
      },
      {
        y: ratio.CAFE24_MOBILE,
        label: `카페24 모바일 (${sum.CAFE24_MOBILE})`,
        color: '#d67e11',
      },
      {
        y: ratio.CAFE24_PC,
        label: `카페24 PC (${sum.CAFE24_PC})`,
        color: '#204b79',
      },
      {
        y: ratio.NAVERPAY,
        label: `네이버페이 (${sum.NAVERPAY})`,
        color: '#409813',
      },
      {
        y: ratio.SMARTSTORE,
        label: `스마트스토어 (${sum.SMARTSTORE})`,
        color: '#C19881',
      },
    ];

    const resultPieData = pieData.filter((el) => el.y !== undefined);

    setPieOptions({
      ...pieOptions,
      data: [
        {
          ...utils.templateChartPie,
          dataPoints: resultPieData,
        },
      ],
    });
  };

  const makeChartData = (data) => {
    const { byDate, total } = data;
    let result = {};
    result = utils.parseChartData(result, byDate);

    const {
      APP_FETCHING_COUNT,
      APP_NAVERPAY_COUNT,
      APP_SHOP_COUNT,
      CAFE24_MOBILE_COUNT,
      CAFE24_PC_COUNT,
      NAVERPAY_COUNT,
      SMARTSTORE_COUNT,
      APP_FETCHING,
      APP_NAVERPAY,
      APP_SHOP,
      CAFE24_MOBILE,
      CAFE24_PC,
      NAVERPAY,
      SMARTSTORE,
    } = result;

    makeChartOptionsCount([
      APP_FETCHING_COUNT,
      APP_NAVERPAY_COUNT,
      APP_SHOP_COUNT,
      CAFE24_MOBILE_COUNT,
      CAFE24_PC_COUNT,
      NAVERPAY_COUNT,
      SMARTSTORE_COUNT,
    ]);

    makeChartOptionsRate([
      APP_FETCHING,
      APP_NAVERPAY,
      APP_SHOP,
      CAFE24_MOBILE,
      CAFE24_PC,
      NAVERPAY,
      SMARTSTORE,
    ]);

    makeChartPie(total);
  };

  const getData = async () => {
    try {
      handleIsLoading(true);

      const way = platform
        .filter((el) => el.name !== '전체')
        .map((el) => el.value);

      const params = {
        start: startDate,
        end: lastDate,
        way,
      };

      const { data } = await fetchingLog.get('/api/order', {
        params,
      });

      makeChartData(data);
    } catch (err) {
      console.log(err);
    } finally {
      handleIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [startDate, lastDate, platform]);

  useEffect(() => {
    setCurrentDate();
  }, []);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}

      <Title title="총 거래액" />
      <style.TitleDescrip>
        페칭에서 이루어진 거래의 총 금액입니다. "App 편집샵 결제"의 경우 정확한
        수치와 차이가 발생할 수 있습니다.
      </style.TitleDescrip>

      <DateBox setDate={setDate} />
      <PaymentPlatformCheckbox
        platform={platform}
        handlePlatformCheck={handlePlatformCheck}
      />

      <ResearchButton onClick={getData} />
      <NumberDisplayTable values={totalValues} />
      <style.ChartWrapper>
        <CanvasJSChart options={pieOptions} />
      </style.ChartWrapper>
      <style.ChartWrapper>
        <CanvasJSChart options={optionsRate} />
      </style.ChartWrapper>
      <style.ChartWrapper>
        <CanvasJSChart options={optionsCount} />
      </style.ChartWrapper>
    </style.Container>
  );
};
