import {
  Button,
  Card,
  GridTable,
  MenuTitle,
  OpenLink,
  OrderListTooltip,
  Radio,
  ReInput,
  RePagination,
  Select,
  Textarea,
  UpdateCustomDutyModal,
  UpdateShippingStatusModal,
} from 'components';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';
import {
  DELIVERY_STATUS,
  enumToList,
  ORDER_DATE_LIST,
  ORDER_SEARCH_TYPE,
  ORDER_SORT,
  ORDER_STATUS,
  PATH,
  PAY_METHOD,
  request,
  TAX_PAY_ISSUE,
  useObject,
  useOrderTable,
  useOrderTableDeliveryMethod,
  USER_TYPE,
} from 'services';
import { NOrder } from 'types';
import classnames from 'classnames';
import { Checkbox } from 'antd';

interface State {
  isUpdateShippingOpen: boolean;
  shopOrderNumber: string;
  orderStatus: keyof typeof DELIVERY_STATUS;
  isUpdateDutyOpen: boolean;
  itemOrderNumber: string;
}

const OrdersShippingCompletePage = () => {
  const {
    searchText,
    searchType,
    orderStatuses,
    setOrderState,
    selectedIdx,
    onDateClick,
    onChangeCheckbox,
    onChangeOrder,
    minOrderDate,
    maxOrderDate,
    page,
    total,
    limit,
    list,
    orderPath,
    userType,
    isLoading,
    payMethod,
    sort,
    resetOrderState,
    memoList,
    isMemoLoading,
    getMemo,
    deliveryMethodIdList,
  } = useOrderTable();

  const [deliveryMethodIdOptions] = useOrderTableDeliveryMethod();

  const [
    {
      orderStatus,
      shopOrderNumber,
      isUpdateShippingOpen,
      isUpdateDutyOpen,
      itemOrderNumber,
    },
    setState,
  ] = useObject<State>({
    isUpdateShippingOpen: false,
    shopOrderNumber: '',
    orderStatus: 'SHIPPING_COMPLETE',
    isUpdateDutyOpen: false,
    itemOrderNumber: '',
  });

  const get = async () => {
    setOrderState({ isLoading: true });
    try {
      let params: any = {
        isAscending: sort === 'ORDER_DATE_ASC',
        limit,
        page,
        orderBy: 'ORDER_DATE',
        orderStatuses: orderStatuses
          ? [orderStatuses]
          : ['SHIPPING_COMPLETE', 'ORDER_CONFIRM'],
      };
      if (!!searchText) {
        params.searchText = searchText;
        params.searchType = searchType;
      }
      if (!!orderPath) params.orderPath = orderPath;
      if (!!payMethod) params.payMethod = payMethod;
      if (!!userType) params.userType = userType;
      if (!!minOrderDate)
        params.minOrderDate = `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
      if (!!maxOrderDate)
        params.maxOrderDate = `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
      if (!!deliveryMethodIdList.length)
        params.deliveryMethodIdList = deliveryMethodIdList;
      try {
        const { count, data } = await request.get<any, { count: number; data: any[] }>(
          '/commerce/order',
          {
            params,
            headers: {
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          }
        );
        setOrderState({ total: count, list: data, isLoading: false });
      } catch (err) {
        console.log(err);
        setOrderState({ isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const orderLink = (orderIndex: number) => {
    const order = list[orderIndex];
    const shopOrderList: NOrder.Detail['shopOrderList'] = order.shopOrderList;

    let url = `/order/${order.fetchingOrderNumber}`;

    if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.cancelInfo))
    )
      url += '?status=2&tab=1';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.returnInfo))
    )
      url += '?status=3&tab=1';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.refundInfo))
    )
      url += '?status=4&tab=1';
    else url += '?status=0&tab=1';

    window.open(url);
  };

  useEffect(() => {
    get();
  }, [page, minOrderDate, maxOrderDate, limit, orderPath, userType, payMethod, sort]);
  return (
    <>
      {/* <MenuTitle title="배송 완료 관리" /> */}
      <div className="wrapper">
        <div className="wrapper-title">배송 완료/구매 확정</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Textarea.Autosize
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                    rows={1}
                    className="w-64"
                    placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문경로',
              renderItem: () => (
                <Radio
                  id="orderPath"
                  options={enumToList(PATH)}
                  isInitialExisted
                  value={orderPath}
                  onChange={(orderPath) => setOrderState({ orderPath })}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '결제수단',
              renderItem: () => (
                <Radio
                  id="payMethod"
                  value={payMethod}
                  isInitialExisted
                  onChange={(payMethod) => setOrderState({ payMethod })}
                  options={enumToList(PAY_METHOD)}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Radio
                  id="userType"
                  value={userType}
                  isInitialExisted
                  onChange={(userType) => setOrderState({ userType })}
                  options={enumToList(USER_TYPE)}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '주문상태',
              renderItem: () => (
                <Radio
                  id="orderStatuses"
                  value={orderStatuses}
                  isInitialExisted
                  onChange={(orderStatuses) => setOrderState({ orderStatuses })}
                  options={enumToList(ORDER_STATUS)}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '배송 방식',
              renderItem: () => (
                <Checkbox.Group
                  disabled={isLoading}
                  options={deliveryMethodIdOptions}
                  value={deliveryMethodIdList}
                  onChange={(deliveryMethodIdList) =>
                    setOrderState({
                      deliveryMethodIdList: deliveryMethodIdList as number[],
                    })
                  }
                />
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-7 flex justify-center gap-3 text-lg">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="ghost" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          isLoading={isLoading}
          columns={[
            '주문번호',
            '주문일 (결제일)',
            '주문자',
            '배송정보',
            '판매처 (국가 / 배송방식)',
            '운송장 정보',
            '주문상품',
            '수량',
            '관세업무',
            '주문상태',
            '관리자기능',
          ]}
          maxHeight=""
          list={list}
          header={
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <Select size="sm">
                  <option value="checkedOrderIds">선택한 주문의</option>
                </Select>
                <Select size="sm">
                  <option value="ChangeOrderState">주문상태를</option>
                </Select>
                <Button size="sm">{'일괄처리 >'}</Button>
              </div>
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          renderItem={(order, orderIndex) => (
            <Fragment key={orderIndex}>
              {order.shopOrderList.map((shop, shopIndex) => (
                <div
                  key={shopIndex}
                  onClick={() => onChangeCheckbox(shop.shopOrderNumber)}
                >
                  <div aria-label="checkbox">
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={selectedIdx.indexOf(shop.shopOrderNumber) !== -1}
                    />
                  </div>
                  <div aria-label="주문번호">
                    <span
                      className="link"
                      onClick={(e) => {
                        e.stopPropagation();
                        orderLink(orderIndex);
                      }}
                    >
                      {order.fetchingOrderNumber}
                    </span>
                  </div>
                  <div aria-label="주문일 (결제일)">
                    <div>{dayjs(order.orderedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                    {!!order.paidAt && (
                      <div className="text-gray-400">
                        ({dayjs(order.paidAt).format('YYYY.MM.DD HH:mm:ss')})
                      </div>
                    )}
                  </div>
                  <div aria-label="주문자">
                    <span>{order.userName}</span>
                    <div>{USER_TYPE[order.userType]}</div>
                  </div>
                  <div aria-label="배송정보">
                    <div>{order.recipientName}</div>
                    <div>{order.address}</div>
                    <div>{order.addressDetail}</div>
                    <div className="mt-2">{order.customsClearanceNumber}</div>
                    <div>{order.phoneNumber}</div>
                  </div>
                  <div aria-label="판매처 (국가 / 배송방식)">
                    {shop.shopName} ({shop.shopCountry} /{' '}
                    <span
                      className={classnames({
                        'text-fassto': shop.deliveryMethodName === '파스토',
                        'text-eldex': shop.deliveryMethodName === '엘덱스',
                      })}
                    >
                      {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                    </span>
                    )
                  </div>
                  <div aria-label="택배사 / 운송장번호">
                    <div>{shop.itemOrderList[0].shippingCompanyName} </div>
                    <div>{shop.itemOrderList[0].invoice}</div>
                    <div className="mt-1">
                      <Button
                        size="xs"
                        onClick={() =>
                          setState({
                            isUpdateShippingOpen: true,
                            shopOrderNumber: shop.shopOrderNumber,
                            orderStatus: shop.orderStatus,
                          })
                        }
                      >
                        배송상태 변경
                      </Button>
                    </div>
                  </div>
                  <div aria-label="주문상품">
                    <div className="flex flex-col h-full space-y-4">
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1 flex gap-3">
                          <img
                            className="max-w-[3.5rem] object-contain"
                            src={item.itemImageUrl}
                            alt={item.itemName}
                          />
                          <div className="flex-1">
                            <div>{item.brandName}</div>
                            <OpenLink
                              url={`/product/productDetail/v2/${Number(
                                item.itemId
                              )}?mode=update`}
                            >
                              {item.itemName}
                            </OpenLink>
                            <div>{item.sizeName}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div aria-label="수량">
                    <div className="flex flex-col h-full space-y-4">
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          {item.quantity}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div aria-label="관세업무">
                    <div className="flex flex-col h-full space-y-4">
                      {shop.itemOrderList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <div>전자납부번호: {item.taxPaymentNumber}</div>
                          <div>납부결과 : {!!item.taxPaid ? '납부완료' : '미납'}</div>
                          <div>납부이슈 : {TAX_PAY_ISSUE[item.taxPayIssue]}</div>
                          <div>
                            <Button
                              size="xs"
                              onClick={(e) => {
                                e.stopPropagation();
                                setState({
                                  isUpdateDutyOpen: true,
                                  itemOrderNumber: item.itemOrderNumber,
                                });
                              }}
                            >
                              납부정보 입력
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div aria-label="주문상태">{DELIVERY_STATUS[shop.orderStatus]}</div>
                  <div aria-label="관리자기능">
                    <div className="flex flex-col h-full gap-2 items-center">
                      <Button
                        size="xs"
                        className="w-full"
                        theme="primary"
                        onClick={(e) => {
                          e.stopPropagation();
                          orderLink(orderIndex);
                        }}
                      >
                        관리
                      </Button>
                      <Button
                        size="xs"
                        className="w-full"
                        onClick={(e) => {
                          e.stopPropagation();
                          setOrderState({
                            isMessageOpen: true,
                            phoneNumber: order.phoneNumber,
                          });
                        }}
                      >
                        메시지
                      </Button>
                      <OrderListTooltip
                        isLoading={isMemoLoading}
                        hasMemo={order.hasMemo}
                        list={memoList}
                        fetchingOrderNumber={order.fetchingOrderNumber}
                        onMouseLeave={() =>
                          setOrderState({ memoList: [], isMemoLoading: false })
                        }
                        onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Fragment>
          )}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setOrderState({
              selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
            })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
      <UpdateShippingStatusModal
        isOpen={isUpdateShippingOpen}
        onClose={() =>
          setState({
            isUpdateShippingOpen: false,
            shopOrderNumber: '',
            orderStatus: 'SHIPPING_COMPLETE',
          })
        }
        shopOrderNumber={shopOrderNumber}
        onSuccess={get}
        orderStatus={orderStatus}
      />
      <UpdateCustomDutyModal
        isOpen={isUpdateDutyOpen}
        onClose={() => setState({ isUpdateDutyOpen: false, itemOrderNumber: '' })}
        itemOrderNumber={itemOrderNumber}
        onComplete={get}
      />
    </>
  );
};

export default OrdersShippingCompletePage;
