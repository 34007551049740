import styled from 'styled-components';

export const ItemBox = styled.div`
  display: flex;
`;

export const Item = styled.p`
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.isActive === 'active' && 'color: green'};
  ${(props) => props.isActive === 'disable' && 'color: gray'};
  ${(props) => props.isActive === 'alert' && 'color: red'};
`;
