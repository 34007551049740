import { AddressModal, Button } from 'components';
import React, { FunctionComponent } from 'react';
import { useObject } from 'services';
import { useRecoilValue } from 'recoil';
import { addressListState } from 'store';

export interface Props {}
interface State {
  addressIndex: number;
  isOpen: boolean;
  isAddressMode: boolean;
  isUpdate: boolean;
}

const UserProfileAddress: FunctionComponent<Props> = () => {
  const [{ addressIndex, isOpen, isAddressMode, isUpdate }, setState] = useObject<State>({
    addressIndex: 0,
    isOpen: false,
    isAddressMode: false,
    isUpdate: false,
  });
  const addressList = useRecoilValue(addressListState);
  return (
    <>
      <div className="text-sm flex flex-col">
        {addressList
          .filter((item) => item.requestType !== 'DELETE')
          .map((item, key) => (
            <div
              key={key}
              onClick={() =>
                setState({
                  addressIndex: key,
                  isOpen: true,
                  isAddressMode: false,
                  isUpdate: true,
                })
              }
              className="group cursor-pointer mb-4"
            >
              <div className="mb-1">
                <span className="group-hover:underline">{item.name}</span>
                {item.isDefault && (
                  <span className="text-green-500 bg-green-100 ml-2 rounded py-1 px-2">
                    기본
                  </span>
                )}
              </div>
              <div className="group-hover:underline">
                {`(${item.zipCode}) ${item.streetNameAddress} ${item.detailAddress}`}
              </div>
              <div className="group-hover:underline">
                {`(${item.zipCode}) ${item.englishStreetNameAddress} ${item.detailAddress}`}
              </div>
            </div>
          ))}
      </div>
      <Button size="xs" onClick={() => setState({ isOpen: true, isAddressMode: true })}>
        주소지 추가
      </Button>
      {isOpen && (
        <AddressModal
          isOpen={isOpen}
          title={isUpdate ? '주소지 수정' : '주소지 추가'}
          onClose={() =>
            setState({
              isOpen: false,
              addressIndex: 0,
              isAddressMode: false,
              isUpdate: false,
            })
          }
          addressIndex={addressIndex}
          isAddressMode={isAddressMode}
          isUpdate={isUpdate}
        />
      )}
    </>
  );
};

export default UserProfileAddress;
