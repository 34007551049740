import React from 'react';
import * as style from './style';

const CommerceUserSearchByTd = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  return (
    <style.Container>
      <style.Select
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            searchType: e.target.value,
          });
        }}
        value={searchOptionTableState.searchType}
      >
        <option value="NAME">이름</option>
        <option value="ID">아이디</option>
        <option value="AUID">AUID</option>
        <option value="PHONE">전화번호</option>
        <option value="EMAIL">이메일</option>
        <option value="ENGLISH_NAME">영어 이름</option>
        <option value="">개인통관고유부호</option>
      </style.Select>
      <style.Input
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            searchQuery: e.target.value,
          });
        }}
        value={searchOptionTableState.searchQuery}
      />
    </style.Container>
  );
};

export default CommerceUserSearchByTd;
