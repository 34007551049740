import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

export interface Props {
  list: ReactNode[];
  type?: 'info' | 'danger';
}
interface State {}

const Alert: FunctionComponent<Props> = ({ list, type }) => {
  return (
    <div
      className={classnames('p-3 rounded text-sm', {
        'bg-red-100': type === 'danger',
        'bg-gray-300': type === 'info',
      })}
    >
      <ul
        className={classnames('list-disc ml-4', {
          'text-red-600': type === 'danger',
          'text-gray-600': type === 'info',
        })}
      >
        {list.map((item, key) => (
          <li key={key}>{item}</li>
        ))}
      </ul>
    </div>
  );
};

Alert.defaultProps = {
  type: 'danger',
};

export default Alert;
