import { Tabs } from 'components';
import React, { FunctionComponent } from 'react';
import { useObject, useQuery } from 'services';
import OrderInfoCancel from './Cancel';
import OrderInfoExchange from './Exchange';
import OrderInfoHistory from './History';
import OrderInfoRefund from './Refund';
import OrderInfoReturn from './Return';

export interface Props {}

interface State {
  status: number;
}

const OrderInfo: FunctionComponent<Props> = () => {
  const { query, updateQuery } = useQuery<{ status: string }>();
  const [{ status }, setState] = useObject<State>({
    status: query.status ? Number(query.status) : 0,
  });

  const onTabChange = (status: number) => {
    setState({ status });
    updateQuery({ status: String(status) });
  };

  return (
    <>
      <div className="mb-4">
        <Tabs
          value={status}
          onTabChange={onTabChange}
          options={[`주문내역`, `취소`, `교환`, `반품`, `환불`]}
        />
      </div>
      {status === 0 && <OrderInfoHistory />}
      {status === 1 && <OrderInfoCancel />}
      {status === 2 && <OrderInfoExchange />}
      {status === 3 && <OrderInfoReturn />}
      {status === 4 && <OrderInfoRefund />}
    </>
  );
};

OrderInfo.displayName = '주문정보';

export default OrderInfo;
