import React, { useEffect, useMemo } from 'react';
import type { FunctionComponent } from 'react';
import { Accordion, Button, ReInput, ReModal } from 'components';
import { DuplicateIcon } from '@heroicons/react/outline';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';
import { useParams } from 'react-router-dom';
import type { IDaum } from 'types';
import { copyText } from 'utils';

const ShippingInfo: FunctionComponent<{
  name: string;
}> = ({ name, children }) => {
  return (
    <div className="flex items-center text-sm">
      <div className="w-32 font-bold">{name}</div>
      <div className="flex-1 flex items-center">{children}</div>
    </div>
  );
};

export interface Props {}
interface State {
  isMemoUpdating: boolean;
  recipientName: string;
  zipCode: string;
  address: string;
  addressDetail: string;
  customsClearanceNumber: string;
  englishAddress: string;
  englishAddressDetail: string;
  englishDeliveryMemo: string;
  englishRecipientName: string;
  deliveryMemo: string;
  isAddressOpen: boolean;
  isTranslating: boolean;
  phoneNumber: string;
}

const PlacingOrderInfoDeliveryMemo: FunctionComponent<Props> = () => {
  const [
    {
      isMemoUpdating,
      isAddressOpen,
      isTranslating,
      address,
      addressDetail,
      englishAddress,
      englishAddressDetail,
      englishDeliveryMemo,
      englishRecipientName,
      zipCode,
      recipientName,
      customsClearanceNumber,
      deliveryMemo,
      phoneNumber,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    isMemoUpdating: false,
    address: '',
    addressDetail: '',
    englishAddress: '',
    englishAddressDetail: '',
    englishDeliveryMemo: '',
    englishRecipientName: '',
    zipCode: '',
    recipientName: '',
    customsClearanceNumber: '',
    deliveryMemo: '',
    isAddressOpen: false,
    isTranslating: false,
    phoneNumber: '',
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();

  const get = async () => {
    try {
      const { deliveryInfo } = await request.get<any, { deliveryInfo: any }>(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/delivery`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setState({
        address: deliveryInfo.address || '',
        addressDetail: deliveryInfo.addressDetail || '',
        customsClearanceNumber: deliveryInfo.customsClearanceNumber || '',
        deliveryMemo: deliveryInfo.deliveryMemo || '',
        englishAddress: deliveryInfo.englishAddress || '',
        englishAddressDetail: deliveryInfo.englishAddressDetail || '',
        englishDeliveryMemo: deliveryInfo.englishDeliveryMemo || '',
        englishRecipientName: deliveryInfo.englishRecipientName || '',
        recipientName: deliveryInfo.recipientName || '',
        zipCode: deliveryInfo.zipCode || '',
        phoneNumber: deliveryInfo.phoneNumber || '',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onMemoUpdate = async () => {
    setState({ isMemoUpdating: true });
    try {
      await request.put(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/delivery`,
        {
          address,
          addressDetail,
          englishAddress,
          englishAddressDetail,
          englishDeliveryMemo,
          englishRecipientName,
          zipCode,
          recipientName,
          customsClearanceNumber,
          deliveryMemo,
          phoneNumber,
        }
      );
      toast.success('배송정보가 수정되었습니다.');
    } catch (err) {
      console.dir(err);
    } finally {
      setState({ isMemoUpdating: false });
    }
  };

  const onTranslateAddress = async () => {
    if (!recipientName || !addressDetail) {
      toast.info('수령자명, 상세주소를 입력해주세요.');
      return;
    }

    setState({ isTranslating: true });
    let promises = [
      request.get('/util/translate/name', {
        params: { text: recipientName || '' },
      }),
      request.get('/util/translate', {
        params: { from: 'ko', to: 'en', text: addressDetail || '' },
      }),
    ];
    if (!!deliveryMemo)
      promises.push(
        request.get('/util/translate', {
          params: { from: 'ko', to: 'en', text: deliveryMemo || '' },
        })
      );
    try {
      const result = await Promise.all(promises);
      let state: Partial<State> = {
        englishRecipientName: result[0].data || '',
        englishAddressDetail: result[1].data || '',
      };
      if (!!result[2]) state.englishDeliveryMemo = result[2].data || '';
      setState(state);
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isTranslating: false });
    }
  };

  const onSearchAddress = () => {
    if (!isAddressOpen) return;

    const element_layer = document.getElementById('daum');
    // @ts-ignore
    new daum.Postcode({
      oncomplete: function (data: IDaum) {
        setState({
          isAddressOpen: false,
          englishAddress: data.jibunAddressEnglish,
          zipCode: data.zonecode,
          address: data.jibunAddress,
        });
      },
      autoMappingJibun: true,
      width: '100%',
    }).embed(element_layer);
  };

  /**
   * @example
   * englishAddress.split(', ')의 length에 따라 구분
   * - 5 : ['537-5', 'Sinsa-dong', 'Gangnam-gu', 'Seoul', 'Korea']
   * - 6 : ['578-9', 'Baekhyeon-dong', 'Bundang-gu', 'Seongnam-si', 'Gyeonggi-do', 'Korea']
   * - 7 : ['2615-9', '2(i)-ri', 'Hagwi', 'Aewol-eup', 'Jeju-si', 'Jeju-do', 'Korea']
   *
   * 첫 번쨰 인자는 우편번호, 마지막 인자는 Korea로 고정이라서 제외해서 계산.
   * @returns
   * 영문 행정구역
   */
  const englishDistrict: string = useMemo(() => {
    if (!englishAddress) return '';
    const split = englishAddress.split(', ');

    if (split.length === 5) return split[3];
    else if (split.length === 6) return split.slice(3, 5).join(', ');
    else if (split.length === 7) return split.slice(4, 6).join(', ');
    else return '';
  }, [englishAddress]);

  /**
   * @returns
   * 영문 시군구
   */
  const englishCity: string = useMemo(() => {
    if (!englishAddress) return '';
    const split = englishAddress.split(', ');

    if (split.length === 5 || split.length === 6) return split.slice(1, 3).join(', ');
    else if (split.length === 7) return split.slice(1, 4).join(', ');
    else return '';
  }, [englishAddress]);

  const CUSTOM_INFO = `
안녕하세요. 통관정보 회신드립니다.

1. 운송장 : __작업자_확인_후_직접_입력__
2. 국적 : 대한민국
3. 이름 : ${recipientName}
4. 이메일 : orderfetching@gmail.com
5. 전화번호 : ${phoneNumber}
6. 도로명 주소 : ${address} ${addressDetail}
7. 개인통관고유부호 : ${customsClearanceNumber}

감사합니다.
`;

  useEffect(() => {
    onSearchAddress();
  }, [isAddressOpen]);

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <Accordion title="배송정보 및 배송메모" defaultOpen>
        <div className="flex">
          <div className="flex-1 space-y-2">
            <ShippingInfo name="수령자명">
              <ReInput
                size="xs"
                value={recipientName}
                onChange={onChange}
                name="recipientName"
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => copyText(recipientName)}
                  />
                }
              />
            </ShippingInfo>
            <ShippingInfo name="우편번호">
              <ReInput
                size="xs"
                onClick={() => setState({ isAddressOpen: true })}
                value={zipCode}
                readOnly
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      copyText(zipCode);
                    }}
                  />
                }
              />
            </ShippingInfo>
            <ShippingInfo name="국문주소">
              <ReInput
                size="xs"
                onClick={() => setState({ isAddressOpen: true })}
                value={address}
                readOnly
                className="w-64"
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      copyText(address);
                    }}
                  />
                }
              />
            </ShippingInfo>
            <ShippingInfo name="상세주소">
              <ReInput
                size="xs"
                value={addressDetail}
                onChange={onChange}
                name="addressDetail"
                className="w-56"
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => copyText(addressDetail)}
                  />
                }
              />
            </ShippingInfo>
            <ShippingInfo name="배송메모">
              <ReInput
                size="xs"
                value={deliveryMemo}
                name="deliveryMemo"
                onChange={onChange}
                className="w-56"
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => copyText(deliveryMemo)}
                  />
                }
              />
            </ShippingInfo>
          </div>

          <div className="flex-1 space-y-2">
            <ShippingInfo name="영문 이름 번역">
              <ReInput
                size="xs"
                readOnly
                value={englishRecipientName}
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => copyText(englishRecipientName)}
                  />
                }
              />
            </ShippingInfo>
            <ShippingInfo name="개인통관고유부호">
              <ReInput
                size="xs"
                value={customsClearanceNumber}
                name="customsClearanceNumber"
                onChange={onChange}
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => copyText(customsClearanceNumber)}
                  />
                }
              />
            </ShippingInfo>
            <ShippingInfo name="휴대폰번호">
              <ReInput
                size="xs"
                value={phoneNumber}
                name="phoneNumber"
                onChange={onChange}
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => copyText(phoneNumber)}
                  />
                }
              />
            </ShippingInfo>
            <div className="flex text-sm">
              <div className="w-32 font-bold">영문 주소</div>
              <div className="flex-1 space-y-2">
                <ReInput
                  size="xs"
                  readOnly
                  value={englishAddress}
                  className="w-96"
                  suffix={
                    <DuplicateIcon
                      className="h-4 w-4 cursor-pointer"
                      onClick={() => copyText(englishAddress)}
                    />
                  }
                />
                <ReInput
                  size="xs"
                  readOnly
                  value={englishDistrict}
                  className="w-96"
                  suffix={
                    <DuplicateIcon
                      className="h-4 w-4 cursor-pointer"
                      onClick={() => copyText(englishDistrict)}
                    />
                  }
                />
                <ReInput
                  size="xs"
                  readOnly
                  value={englishCity}
                  className="w-96"
                  suffix={
                    <DuplicateIcon
                      className="h-4 w-4 cursor-pointer"
                      onClick={() => copyText(englishCity)}
                    />
                  }
                />
                <ReInput
                  size="xs"
                  value={englishAddressDetail}
                  readOnly
                  className="w-96"
                  placeholder="영문 상세주소"
                  suffix={
                    <DuplicateIcon
                      className="h-4 w-4 cursor-pointer"
                      onClick={() => copyText(englishAddressDetail)}
                    />
                  }
                />
                <br />
                <ReInput
                  size="xs"
                  value={zipCode}
                  readOnly
                  suffix={
                    <DuplicateIcon
                      className="h-4 w-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyText(zipCode);
                      }}
                    />
                  }
                />
              </div>
            </div>
            <ShippingInfo name="배송메모 번역">
              <ReInput
                size="xs"
                readOnly
                className="w-56"
                value={englishDeliveryMemo}
                suffix={
                  <DuplicateIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => copyText(englishDeliveryMemo)}
                  />
                }
              />
            </ShippingInfo>
          </div>
        </div>
        <div className="relative flex justify-center">
          <div className="mt-4 flex justify-center gap-2">
            <Button size="sm" isLoading={isMemoUpdating} onClick={onMemoUpdate}>
              저장
            </Button>
            <Button
              size="sm"
              theme="secondary"
              isLoading={isTranslating}
              onClick={onTranslateAddress}
            >
              영문 번역
            </Button>
            <Button size="sm" onClick={get} theme="secondary">
              초기화
            </Button>
          </div>
          <Button
            size="sm"
            onClick={() => {
              navigator.clipboard
                .writeText(CUSTOM_INFO)
                .then(() => toast.success('복사되었습니다.'))
                .catch(() => alert(CUSTOM_INFO));
            }}
            theme="secondary"
            className="absolute"
            style={{ right: 0, top: 24 }}
          >
            통관 메일 복사
          </Button>
        </div>
      </Accordion>
      <ReModal
        isOpen={isAddressOpen}
        title="배송정보 수정"
        isNotPadding
        onClose={() => setState({ isAddressOpen: false })}
      >
        <div id="daum"></div>
      </ReModal>
    </>
  );
};

export default PlacingOrderInfoDeliveryMemo;
