import { Button, Card, GridTable, Radio, Textarea } from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  ORDER_PAY_STATUS,
  request,
  SHOP_ORDER_STATUS,
  useObject,
  useOrderDetail,
} from 'services';
import { IOption } from 'types';
import { useGetAdminMemo } from './helper';

export interface IAdminMemo {
  createdAt: string;
  updatedAt: string;
  fromValue: string;
  toValue: string;
  ip: string;
  content: string;
  adminName: string;
  adminId: string;
  isUpdate: boolean;
  isUpdating: boolean;
  tempContent: string;
  fetchingOrderMemoId: number;
  valueType: 'NONE' | 'SHOP_ORDER_STATUS' | 'REFUND_STATUS';
  extraStatus: string;
  isAuto?: boolean;
}

export interface Props {}
interface State {
  list: IAdminMemo[];
  content: string;
  shopOrderNumber: string;
  itemOrderNumber: string;
  isSubmitting: boolean;
}

const OrderAdminMemo: FunctionComponent<Props> = () => {
  const [
    { list, content, shopOrderNumber, itemOrderNumber, isSubmitting },
    setState,
    onChange,
  ] = useObject<State>({
    list: [],
    content: '',
    shopOrderNumber: '',
    itemOrderNumber: '',
    isSubmitting: false,
  });
  const [{ shopOrderList }] = useOrderDetail();
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();

  const { data: memoList, mutate } = useGetAdminMemo({
    fetchingOrderNumber,
    itemOrderNumber,
    shopOrderNumber,
  });

  console.log('emmolist', memoList);

  const get = async () => {
    await mutate();
  };

  const create = async () => {
    if (!content) {
      toast.info('메모를 입력해 주세요.');
      return;
    }

    setState({ isSubmitting: true });
    try {
      let data: any = {
        content,
      };
      if (!!itemOrderNumber) data.itemOrderNumber = itemOrderNumber;
      if (!!shopOrderNumber) data.shopOrderNumber = shopOrderNumber;
      await request.post(
        `/commerce/order/fetching-order/memo/${fetchingOrderNumber}`,
        data,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      get();
      toast.success('메모가 등록되었습니다.');
      setState({ content: '' });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isSubmitting: false });
    }
  };

  const update = async (index: number) => {
    const item = list[index];

    if (!item.isUpdate) {
      setState({
        list: [
          ...list.slice(0, index),
          { ...item, isUpdate: true, tempContent: item.content },
          ...list.slice(index + 1),
        ],
      });
      return;
    }

    if (!item.tempContent) {
      toast.info('수정할 메모 내용을 입력해 주세요.');
      return;
    }

    if (!window.confirm('수정하시겠습니까?')) return;

    setState({
      list: [
        ...list.slice(0, index),
        { ...item, isUpdating: true },
        ...list.slice(index + 1),
      ],
    });
    try {
      await request.put(
        `/commerce/order/fetching-order/memo/${item.fetchingOrderMemoId}`,
        { content: item.tempContent },
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      toast.success('메모가 수정되었습니다.');
      setState({
        list: [
          ...list.slice(0, index),
          { ...item, isUpdating: false, content: item.tempContent, isUpdate: false },
          ...list.slice(index + 1),
        ],
      });
    } catch (err) {
      console.log(err);
      setState({
        list: [
          ...list.slice(0, index),
          { ...item, isUpdating: false },
          ...list.slice(index + 1),
        ],
      });
    }
  };

  const remove = async (index: number) => {
    if (!window.confirm('삭제하시겠습니까?')) return;

    const item = list[index];
    setState({
      list: [
        ...list.slice(0, index),
        { ...item, isUpdating: true },
        ...list.slice(index + 1),
      ],
    });
    try {
      await request.delete(
        `/commerce/order/fetching-order/memo/${item.fetchingOrderMemoId}`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      toast.success('메모가 삭제되었습니다.');
      await mutate();
      setState({
        list: list.filter(
          ({ fetchingOrderMemoId }) => fetchingOrderMemoId !== item.fetchingOrderMemoId
        ),
      });
    } catch (err) {
      console.log(err);
      setState({
        list: [
          ...list.slice(0, index),
          { ...item, isUpdating: false },
          ...list.slice(index + 1),
        ],
      });
    }
  };

  const itemOrderList: IOption[] = useMemo(() => {
    if (shopOrderNumber) {
      const shopOrder = shopOrderList.find(
        (shop) => shop.shopOrderNumber === shopOrderNumber
      );
      if (shopOrder) {
        return shopOrder.itemOrderList.map((item) => ({
          name: `${item.itemName} (${item.itemOrderNumber})`,
          value: item.itemOrderNumber,
        }));
      }
    }
    return shopOrderList
      .map((shop) =>
        shop.itemOrderList.map((item) => ({
          name: `${item.itemName} (${item.itemOrderNumber})`,
          value: item.itemOrderNumber,
        }))
      )
      .flat();
  }, [shopOrderNumber, shopOrderList]);

  const getMemoValue = (
    value: string,
    type: 'NONE' | 'SHOP_ORDER_STATUS' | 'REFUND_STATUS'
  ): string => {
    if (type === 'SHOP_ORDER_STATUS') return SHOP_ORDER_STATUS[value];
    else if (type === 'REFUND_STATUS') return ORDER_PAY_STATUS[value];
    return value;
  };

  useEffect(() => {
    setState({ list: memoList ?? [] });
  }, [memoList]);

  return (
    <>
      <div className="font-bold mb-3">관리자메모</div>
      <Card
        items={[
          {
            name: '등록기준 (편집샵)',
            renderItem: () => (
              <Radio
                id="shopOrderNumber"
                mode="vertical"
                value={shopOrderNumber}
                options={shopOrderList.map((shop) => ({
                  name: `${shop.shopName} (${shop.shopOrderNumber})`,
                  value: shop.shopOrderNumber,
                }))}
                isInitialExisted
                onChange={(shopOrderNumber) =>
                  setState({ shopOrderNumber, itemOrderNumber: '' })
                }
              />
            ),
            stretch: true,
          },
          {
            name: '등록기준 (상품)',
            renderItem: () => (
              <Radio
                mode="vertical"
                id="itemOrderList"
                options={itemOrderList}
                value={itemOrderNumber}
                isInitialExisted
                onChange={(itemOrderNumber) => setState({ itemOrderNumber })}
              />
            ),
            stretch: true,
          },
          {
            name: '메모내용',
            renderItem: () => (
              <div className="flex-1 flex gap-2">
                <Textarea value={content} name="content" onChange={onChange} rows={4} />
                <Button
                  isLoading={isSubmitting}
                  size="sm"
                  className="w-20"
                  onClick={create}
                >
                  등록
                </Button>
              </div>
            ),
            stretch: true,
          },
        ]}
      />
      <GridTable
        list={list}
        columns={['작성일(수정일)', '작성자', '작성자IP', '내용', '관리']}
        noSelection
        renderItem={(item, key) => (
          <div key={key}>
            <div>
              <div>{dayjs(item.createdAt).format('YYYY. MM. DD. HH:mm:ss')}</div>
              {!!item.updatedAt && (
                <div className="text-gray-400">
                  ({dayjs(item.updatedAt).format('YYYY. MM. DD. HH:mm:ss')})
                </div>
              )}
            </div>
            <div>
              {item.adminName} ({item.adminId})
            </div>
            <div>{item.ip}</div>
            <div>
              {!!item.fromValue && (
                <div>
                  [{getMemoValue(item.fromValue, item.valueType)}{' '}
                  {!!item.toValue && `-> ${getMemoValue(item.toValue, item.valueType)}`}]
                </div>
              )}
              <div>
                {item.isUpdate ? (
                  <Textarea
                    value={item.tempContent}
                    onChange={(e) =>
                      setState({
                        list: [
                          ...list.slice(0, key),
                          { ...item, tempContent: e.target.value },
                          ...list.slice(key + 1),
                        ],
                      })
                    }
                    rows={4}
                  />
                ) : (
                  <>
                    {item.extraStatus === 'ORDER_CANCEL' && '[주문 취소] '}
                    {item.content}
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="inline-flex flex-col gap-2">
                <Button size="xs" onClick={() => update(key)} isLoading={item.isUpdating}>
                  수정
                </Button>
                <Button
                  size="xs"
                  theme="danger"
                  onClick={() => remove(key)}
                  isLoading={item.isUpdating}
                >
                  삭제
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default OrderAdminMemo;
