import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';

const RouteList = [
  {
    path: '/content/noticeList',
    component: pages.NoticeList,
    exact: true,
  },
  {
    path: '/content/noticeList/addNotice',
    component: pages.AddNotice,
  },
  {
    path: '/content/noticeList/modifyNotice',
    component: pages.ModifyNotice,
  },
  {
    path: '/content/noticeList/:id',
    component: pages.DetailNotice,
  },
];

const Notice = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => (
          <>
            <route.component />
          </>
        )}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Notice;
