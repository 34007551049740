import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { CONSOLE_COLOR, AUTH_LINKS } from 'services';

const request = axios.create({
  baseURL:
    window.location.origin === 'https://admin.fetching.work'
      ? 'https://admin.fetchingapp.co.kr'
      : 'https://testapi.fetchingapp.co.kr',
  headers: {
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
  },
});

request.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
  };
  if (!!config.headers?.isCount) return config;
  // console.log(
  //   '%c%s',
  //   `color: ${CONSOLE_COLOR}; font-weight: bold`,
  //   `${config.method?.toUpperCase()} ${config.url}`
  // );
  // if (config.params) {
  //   console.log('%c%s', `color: ${CONSOLE_COLOR}`, 'req.query:');
  //   console.table(config.params);
  // }
  // if (config.data) {
  //   console.log('%c%s', `color: ${CONSOLE_COLOR}`, 'req.body:');
  //   console.table(config.data);
  // }
  return config;
});
request.interceptors.response.use(
  (res) => {
    const { data, config } = res;
    if (!!config.headers?.isCount) return res.data;
    console.log(
      '%c%s',
      `color: ${CONSOLE_COLOR}`,
      `${config.method!.toUpperCase()} ${config.url} | data:`
    );
    if (Array.isArray(data)) console.log(data);
    else console.table(data);
    return res.data;
  },
  (err: AxiosError) => {
    if (!!err.config.headers?.isCount) return Promise.reject(err);
    console.dir(err);
    if (err.response?.status !== 401)
      toast.error('에러가 발생했습니다. 잠시 후 다시 시도해 주세요.');
    if (
      !!err.response &&
      err.response.status === 401 &&
      AUTH_LINKS.indexOf(window.location.pathname) === -1
    ) {
      window.location.href = `/login?redirectUrl=${window.location.pathname}`;
      return;
    }
    return Promise.reject(err);
  }
);

export default request;
