import styled from 'styled-components';

export const Wrapper = styled.div``;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 15px 20px;
  font-size: 1.2rem;
  color: #4f4e4e;
`;

export const Title = styled.strong`
  margin-right: auto;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
`;

export const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  background-color: #e5e5e547;
  font-weight: bold;
  padding: 10px 15px;
  overflow: auto;
  border-right: 1px solid #ddd;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  width: 80%;
  overflow: auto;
  padding: 10px 15px;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
  margin: 0 10px;
  cursor: pointer;
`;

export const Text = styled.p`
  margin: 0;
`;

export const Icon = {
  width: '30px',
  height: '30px',
  cursor: 'pointer',
};
