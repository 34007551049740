import { Alert, Button, MenuTitle, Tabs } from 'components';
import React, { Fragment, FunctionComponent, useEffect, useMemo } from 'react';
import { request, useObject } from 'services';
import Switch from 'react-switch';

interface IAutoMessage {
  [key: string]: {
    category: string;
    content: string;
    description: string;
    messageId: number;
    name: string;
    title: string;
    type: string;
    isActive: boolean;
  };
}

interface State {
  tab: 'ORDER' | 'USER';
  isUpdating: boolean;
  updatedAt: string;
  other: IAutoMessage;
}

const MessageCell: FunctionComponent<{}> = ({ children }) => {
  return (
    <td className="w-1/5" style={{ borderRight: '1px solid #b4b4b4' }}>
      <div className="p-2 m-2 bg-gray-200 h-48 overflow-auto">{children}</div>
    </td>
  );
};

const HeaderCell: FunctionComponent<{
  isActive: boolean;
  onChange: (isActive: boolean) => void;
}> = ({ children, isActive, onChange }) => (
  <td
    className="font-semibold h-10 px-2"
    style={{
      borderRight: '1px solid #b4b4b4',
    }}
  >
    <div className="flex items-center justify-between">
      <span>{children}</span>
      <Switch checked={isActive} onChange={onChange} />
    </div>
  </td>
);

const UsersAutoMessagingPage = () => {
  const [{ tab, isUpdating, updatedAt, other }, setState] = useObject<State>({
    tab: 'ORDER',
    isUpdating: false,
    updatedAt: '',
    other: {},
  });
  const get = async () => {
    try {
      const data = await request.get<any, { OTHER: IAutoMessage }>(
        '/commerce/user/message/auto'
      );
      setState({
        other: data.OTHER,
        isUpdating: false,
      });
    } catch (err) {
      setState({ isUpdating: false });
    }
  };

  const getSync = async () => {
    setState({ isUpdating: true });
    try {
      await request.get('/commerce/user/message/auto/sync', { timeout: 100000 });
      get();
    } catch (err) {
      console.log(err);
      setState({ isUpdating: false });
    }
  };

  const update = async (type: string, isActive: boolean) => {
    if (!other[type]) return;

    try {
      await request.put(`/commerce/user/message/auto/${other[type].category}/${type}`, {
        autoDescription: other[type].description,
        autoName: other[type].name,
        content: other[type].content,
        isActive,
        title: other[type].title,
      });
      get();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <MenuTitle title="자동 메시지 발송 설정" />
      <div className="m-4 flex items-center gap-4">
        <Button size="lg" isLoading={isUpdating} onClick={getSync}>
          템플릿 재연동하기
        </Button>
        <Alert list={['템플릿 재연동에는 긴 시간이 걸릴 수 있습니다.']} type="info" />
        {/* <span>갱신일 : {updatedAt}</span> */}
      </div>
      <div className="wrapper">
        {/* <div className="mt-2">
          <Tabs
            onTabChange={(currentTab) =>
              setState({ tab: currentTab === 0 ? 'ORDER' : 'USER' })
            }
            value={tab === 'ORDER' ? 0 : 1}
            options={['주문 관련 메시지', '회원 관련 메시지']}
          />
        </div> */}
        <div>
          <table className="w-full text-xs" style={{ border: '1px solid #989595' }}>
            <tbody>
              {Array.from({ length: Math.ceil(Object.keys(other).length / 5) }).map(
                (_, rowIndex) => (
                  <Fragment key={rowIndex}>
                    <tr>
                      <td className="bg-gray-200 font-semibold py-3 px-2 w-44 whitespace-nowrap">
                        메시지 발송 상황
                      </td>
                      {Object.keys(other)
                        .slice(rowIndex * 5, (rowIndex + 1) * 5)
                        .map((property, key) => (
                          <HeaderCell
                            onChange={(isActive) => update(property, !!isActive)}
                            isActive={!!other[property].isActive}
                            key={key}
                          >
                            {other[property].name}
                          </HeaderCell>
                        ))}
                    </tr>
                    <tr
                      style={{
                        borderTop: '1px solid #b4b4b4',
                        borderBottom: '1px solid #b4b4b4',
                      }}
                    >
                      <td className="bg-gray-200 p-2 align-top">메시지 내용</td>
                      {Object.keys(other)
                        .slice(rowIndex * 5, (rowIndex + 1) * 5)
                        .map((property, key) => (
                          <MessageCell key={key}>
                            <div>{other[property].title}</div>
                            <div>{other[property].content}</div>
                          </MessageCell>
                        ))}
                    </tr>
                  </Fragment>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UsersAutoMessagingPage;
