import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
  margin: 0 10px;
  cursor: pointer;
`;

export const Text = styled.p`
  margin: 0;
`;
