import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`
export const Select = styled.select`
  margin: 0 8px 0px 0px;
`
export const Input = styled.input`
  width: 500px;
  height: 21px;
  margin-right: 20px;
`
export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 16px;
`
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin: 0 7px 0 0;
`
