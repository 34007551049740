import React, { useRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import * as style from './style';

const BrandCafe24RenderInfo = ({
  modifyBrand,
  setModifyBrand,
  handleImgOnDrop,
  handleClinentHeight,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('콘텐츠 정보', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('콘텐츠 정보', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>콘텐츠 정보</style.Title>
      <style.TableWrapper>
        <style.TableTitle>브랜드 이미지</style.TableTitle>
        <style.TableValue>
          <div>
            <style.Input
              value={modifyBrand.backgroundImageUrl}
              onChange={(e) => {
                setModifyBrand({
                  ...modifyBrand,
                  backgroundImageUrl: e.target.value,
                });
              }}
            />{' '}
            <Dropzone onDrop={handleImgOnDrop} multiple={false} maxSize={10000000}>
              {({ getRootProps, getInputProps }) => (
                <span {...getRootProps()}>
                  <input {...getInputProps()} />
                  <style.Button>찾아보기</style.Button>
                </span>
              )}
            </Dropzone>
          </div>
          <style.Img src={modifyBrand.backgroundImageUrl} />
        </style.TableValue>
      </style.TableWrapper>
    </style.Wrapper>
  );
};

export default BrandCafe24RenderInfo;
