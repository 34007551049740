import {
  AddItemModal,
  Button,
  UpdateInvoiceModal,
  ChangeItemOptionModal,
  GridTable,
  NumberStat,
  OrderCancelModal,
  RemoveItemModal,
  ShippingTrackingModal,
  Popover,
  UpdateCustomDutyModal,
  OpenLink,
  OrderReturnModal,
  Check,
  OrderExchangeModal,
} from 'components';
import React, { Fragment, FunctionComponent } from 'react';
import {
  useObject,
  useOrderDetail,
  SHOP_ORDER_STATUS,
  shopOrderStautsList,
  request,
  COMMERCE_URL,
} from 'services';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { NOrder } from 'types';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { message, Modal } from 'antd';
import dayjs from 'dayjs';

export interface Props {}
interface State {
  isAddItemOpen: boolean;
  isChangeInvoiceOpen: boolean;
  isRemoveItemOpen: boolean;
  isChangeItemOptionOpen: boolean;
  itemId: number;
  itemOrderNumber: string;
  orderStatus: keyof typeof SHOP_ORDER_STATUS | '';
  currentSizeName: string;
  shopPromotionList: Array<{
    promotionName: string;
    promotionCode: string;
    promotionTypeValue: number;
  }>;
  isShopPromotionLoading: boolean;
  isOrderCancelOpen: boolean;
  cancelOrderList: Array<{
    itemOrderNumber: string;
    orderCancelReason: string;
    quantity: number;
    sizeName: string;
    itemName: string;
    itemImageUrl: string;
  }>;
  isUpdateCustomDutyOpen: boolean;
  invoice: string;
  shippingCode: string;
  isShippingInfoOpen: boolean;
  isOrderReturnOpen: boolean;
  isOrderExchangeOpen: boolean;
  itemOrderList: Array<
    Pick<
      NOrder.ItemOrderDetail,
      'itemName' | 'itemOrderNumber' | 'sizeName' | 'itemImageUrl'
    >
  >;
  isWaypoint: boolean;
  itemName: string;
}

const OrderInfoHistory: FunctionComponent<Props> = () => {
  const [
    {
      isAddItemOpen,
      isChangeInvoiceOpen,
      isRemoveItemOpen,
      isChangeItemOptionOpen,
      itemOrderNumber,
      itemId,
      orderStatus,
      currentSizeName,
      shopPromotionList,
      isShopPromotionLoading,
      isOrderCancelOpen,
      cancelOrderList,
      isUpdateCustomDutyOpen,
      invoice,
      shippingCode,
      isShippingInfoOpen,
      isOrderReturnOpen,
      isOrderExchangeOpen,
      itemOrderList,
      isWaypoint,
      itemName,
    },
    setState,
  ] = useObject<State>({
    isAddItemOpen: false,
    isChangeInvoiceOpen: false,
    isRemoveItemOpen: false,
    isChangeItemOptionOpen: false,
    itemId: 0,
    itemOrderNumber: '',
    orderStatus: '',
    currentSizeName: '',
    shopPromotionList: [],
    isShopPromotionLoading: false,
    isOrderCancelOpen: false,
    cancelOrderList: [],
    isUpdateCustomDutyOpen: false,
    invoice: '',
    shippingCode: '',
    isShippingInfoOpen: false,
    isOrderReturnOpen: false,
    isOrderExchangeOpen: false,
    itemOrderList: [],
    isWaypoint: false,
    itemName: '',
  });
  const [{ shopOrderList }, setOrderState, { refreshOrderState }] = useOrderDetail();

  const getShopPromotionList = async (itemOrderNumber: string) => {
    if (!!shopPromotionList.length) return;
    try {
      const { promotionList } = await request.get<any, { promotionList: any[] }>(
        `/promotion/v2/item-order/${itemOrderNumber}/fetching-promotion`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setState({ shopPromotionList: promotionList });
    } catch (err) {
      console.dir(err);
    } finally {
      setState({ isShopPromotionLoading: false });
    }
  };

  /**
   * 옵션 변경
   */
  const changeOption = (shopIndex: number, itemIndex: number) => {
    const shop = shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];

    if (
      shop.orderStatus === 'ORDER_WAITING' &&
      !!item.additionalPayInfo &&
      item.additionalPayInfo.additionalPayStatus !== 'PAID'
    ) {
      toast.info(
        '차액안내중인 상품입니다. 차액결제 완료 후 상태값 변경이 가능합니다. 변경을 원하는 경우 상품 삭제 후 신규로 상품을 추가해주세요.',
        { autoClose: 15000 }
      );
      return;
    }
    setState({
      isChangeItemOptionOpen: true,
      itemId: Number(item.itemId),
      itemOrderNumber: item.itemOrderNumber,
      orderStatus: shop.orderStatus,
      currentSizeName: item.sizeName,
    });
  };

  const handleOrderConfirm = async (shopOrderNumber: string) => {
    try {
      await request.post(
        `/commerce/order/shop-order/${shopOrderNumber}/confirm`,
        undefined,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <GridTable
        columns={[
          '상품별 주문번호',
          '편집샵 계정',
          '판매처 (국가 / 배송방식 / 관세조건)',
          '상품명',
          '수량',
          '주문금액',
          '상태',
          '관리',
        ]}
        header={
          <div className="flex justify-end ">
            <Button size="sm" onClick={() => setState({ isAddItemOpen: true })}>
              상품 추가
            </Button>
          </div>
        }
        noSelection
        maxHeight="max-h-[512px]"
        list={shopOrderList}
        renderItem={(shop, shopIndex) => (
          <Fragment key={shopIndex}>
            {shop.itemOrderList.map((item, itemIndex) => (
              <div key={itemIndex}>
                <div aria-label="상품별 주문번호">
                  <div>{item.itemOrderNumber}</div>
                  <div>
                    {item.shippingCompanyName || item.waypointShippingCompanyName}
                  </div>
                  <div>{item.invoice || item.waypointInvoice}</div>
                  {(!!item.invoice || !!item.waypointInvoice) && (
                    <Button
                      size="xs"
                      theme="secondary"
                      onClick={() =>
                        setState({
                          isShippingInfoOpen: true,
                          invoice: item.invoice || item.waypointInvoice,
                          shippingCode: item.shippingCode || item.waypointShippingCode,
                        })
                      }
                    >
                      배송추적
                    </Button>
                  )}
                  <Button
                    size="xs"
                    onClick={() =>
                      setState({
                        isChangeInvoiceOpen: true,
                        itemOrderNumber: item.itemOrderNumber,
                        invoice: item.invoice || item.waypointInvoice,
                        shippingCode: item.shippingCode || item.waypointShippingCode,
                        isWaypoint: !!item.waypointInvoice,
                      })
                    }
                  >
                    {!!item.invoice || !!item.waypointInvoice ? '송장변경' : '송장등록'}
                  </Button>
                </div>
                <div aria-label="편집샵 계정">
                  <div>{shop.fetchingShopAccount}</div>
                  {!!shop.vendorOrderNumber && (
                    <div>편집샵 주문번호 : {shop.vendorOrderNumber}</div>
                  )}
                  {!!shop.cardApprovalNumber && (
                    <div>카드 승인번호 : {shop.cardApprovalNumber}</div>
                  )}
                </div>
                <div aria-label="판매처 (국가 / 배송방식 / 관세조건)">
                  {shop.shopName} ({shop.shopCountry} /{' '}
                  <span
                    className={classnames({
                      'text-fassto': shop.deliveryMethodName === '파스토',
                      'text-eldex': shop.deliveryMethodName === '엘덱스',
                    })}
                  >
                    {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                  </span>{' '}
                  /{' '}
                  <span>
                    {shop.isDdp === true && 'DDP'}
                    {shop.isDdp === false && 'DDU'}
                  </span>
                  )
                </div>
                <div aria-label="상품명">
                  <div className="flex gap-1">
                    <img
                      src={item.itemImageUrl}
                      className="w-20 h-20 object-contain"
                      alt={item.itemName}
                    />
                    <div>
                      <div>{item.itemCountry}</div>
                      <OpenLink
                        url={`/product/productDetail/v2/${Number(
                          item.itemId
                        )}?mode=update`}
                      >
                        {item.itemName}
                      </OpenLink>
                      <div>{item.sizeName}</div>
                      <div>
                        <OpenLink url={`${COMMERCE_URL}/product/${Number(item.itemId)}`}>
                          {'페칭 상품상세 >'}
                        </OpenLink>
                      </div>
                      <div>
                        <OpenLink url={item.itemUrl}>{'편집샵 상품상세 >'}</OpenLink>
                      </div>
                      <div>
                        <Button
                          size="xs"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(item.itemUrl)
                              .then(() => {
                                message.success('복사되었습니다.');
                              })
                              .catch(() => {
                                message.error(`복사 실패 ${item.itemUrl}`);
                              });
                          }}
                        >
                          상품 링크 복사하기
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div aria-label="수량">{item.quantity}</div>
                <div aria-label="주문금액">
                  <div className="flex flex-col h-full justify-between">
                    <div className="text-base mb-2 text-right">
                      <NumberStat value={item.payAmount} />
                    </div>
                    <div className="flex items-center justify-between mb-2">
                      <Button
                        onClick={() =>
                          setState({
                            isUpdateCustomDutyOpen: true,
                            itemOrderNumber: item.itemOrderNumber,
                          })
                        }
                        size="xs"
                        theme="secondary"
                      >
                        관부가세 업데이트
                      </Button>
                      {shop.orderStatus === 'DOMESTIC_CUSTOMS_CLEARANCE' &&
                        !item.taxUpdated && (
                          <span className="text-red-400 text-xs">
                            관부가세 업데이트 필요
                          </span>
                        )}
                      {!!item.taxUpdatedAt && (
                        <span className="text-gray-400">
                          ({dayjs(item.taxUpdatedAt).format('YYYY. MM. DD. HH:mm:ss')})
                        </span>
                      )}
                    </div>
                    <div className="text-sm space-y-1">
                      {Array.isArray(item.payAmountDetail) &&
                        item.payAmountDetail.map((payAmount, key) => (
                          <div key={key} className="flex justify-between">
                            {payAmount.type === 'PROMOTION_DISCOUNT' ? (
                              <>
                                <span className="flex items-center">
                                  <span>{payAmount.description}</span>
                                  <Popover
                                    content={
                                      <table>
                                        <thead className="bg-gray-100 text-gray-500">
                                          <tr>
                                            <th>프로모션명</th>
                                            <th>할인 내용</th>
                                            <th>코드</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {isShopPromotionLoading ? (
                                            <tr>
                                              <td colSpan={2}>
                                                <div>불러오는 중...</div>
                                              </td>
                                            </tr>
                                          ) : shopPromotionList.length ? (
                                            shopPromotionList.map((item) => (
                                              <tr key={key}>
                                                <td>{item.promotionName}</td>
                                                <td>
                                                  {item.promotionTypeValue > 1
                                                    ? item.promotionTypeValue
                                                    : `${Math.round(
                                                        item.promotionTypeValue * 100
                                                      )}%`}
                                                </td>
                                                <td>{item.promotionCode}</td>
                                              </tr>
                                            ))
                                          ) : (
                                            <tr>
                                              <td colSpan={2}>항목이 없습니다.</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    }
                                    position="bottom"
                                    onOpen={() =>
                                      getShopPromotionList(item.itemOrderNumber)
                                    }
                                  >
                                    <InformationCircleIcon className="ml-px w-4 h-4" />
                                  </Popover>
                                </span>
                                <span>{item.payAmountDetail[2].value}</span>
                              </>
                            ) : (
                              <>
                                <span>{payAmount.description}</span>
                                <span>{payAmount.value}</span>
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
                <div aria-label="상태">
                  <span className="font-bold text-black">
                    {SHOP_ORDER_STATUS[shop.orderStatus]}
                  </span>
                </div>
                <div aria-label="관리">
                  <div className="flex flex-col gap-1 items-center">
                    {shop.orderStatus === 'SHIPPING_COMPLETE' && (
                      <Button
                        size="xs"
                        onClick={() => {
                          if (
                            window.confirm(
                              `${shop.shopName} 주문을 수동으로 구매확정하시겠습니까? 구매확정 전환 시 고객에게 적립금 지급 처리 및 판매자 정산이 이루어집니다.`
                            )
                          ) {
                            handleOrderConfirm(shop.shopOrderNumber);
                          }
                        }}
                      >
                        구매확정
                      </Button>
                    )}
                    {shop.orderStatus === 'ORDER_CONFIRM' && (
                      <Button
                        size="xs"
                        onClick={() => {
                          /*
                          철회 선택 시 얼럿) {편집샵} 구매확정을 철회하시겠습니까? 철회 시 고객에게 지급된 적립금은 회수처리됩니다. [취소]/[확인]

                          실패 오류케이스 판매자 정산이 이루어진 경우 얼럿) 판매자 정산이 이루어진 상품입니다. [확인]
                          */
                          alert('미완성 기능입니다! 💦');
                        }}
                      >
                        철회
                      </Button>
                    )}
                    <Button size="xs">메모</Button>
                    {shopOrderStautsList.indexOf(shop.orderStatus) < 7 && (
                      <Button
                        size="xs"
                        onClick={() => changeOption(shopIndex, itemIndex)}
                      >
                        옵션변경
                      </Button>
                    )}
                    {shopOrderStautsList.indexOf(shop.orderStatus) < 7 && (
                      <Button
                        size="xs"
                        theme="danger"
                        onClick={() =>
                          setState({
                            isRemoveItemOpen: true,
                            itemOrderNumber: item.itemOrderNumber,
                            itemName: item.itemName,
                            orderStatus: shop.orderStatus,
                          })
                        }
                      >
                        상품삭제
                      </Button>
                    )}
                    {shop.orderStatus === 'SHIPPING_COMPLETE' && (
                      <>
                        <Button
                          size="xs"
                          onClick={() =>
                            setState({
                              isOrderExchangeOpen: true,
                              itemOrderList: shop.itemOrderList.map((item) => ({
                                itemName: item.itemName,
                                itemOrderNumber: item.itemOrderNumber,
                                sizeName: '',
                                itemImageUrl: '',
                              })),
                            })
                          }
                        >
                          상품교환
                        </Button>
                        <Button
                          size="xs"
                          onClick={() =>
                            setState({
                              isOrderReturnOpen: true,
                              itemOrderList: shop.itemOrderList.map((item) => ({
                                itemName: item.itemName,
                                itemOrderNumber: item.itemOrderNumber,
                                sizeName: item.sizeName,
                                itemImageUrl: item.itemImageUrl,
                              })),
                            })
                          }
                        >
                          상품반품
                        </Button>
                      </>
                    )}
                    {shopOrderStautsList.indexOf(shop.orderStatus) < 7 && (
                      <Button
                        size="xs"
                        onClick={() =>
                          setState({
                            isOrderCancelOpen: true,
                            cancelOrderList: shop.itemOrderList.map(
                              ({
                                itemOrderNumber,
                                sizeName,
                                itemName,
                                itemImageUrl,
                                quantity,
                              }) => ({
                                itemOrderNumber,
                                orderCancelReason: '',
                                quantity,
                                sizeName,
                                itemName,
                                itemImageUrl,
                              })
                            ),
                          })
                        }
                      >
                        주문취소
                      </Button>
                    )}
                    {shopOrderStautsList.indexOf(shop.orderStatus) < 7 && (
                      <Button
                        size="xs"
                        onClick={() => {
                          Modal.confirm({
                            content:
                              '주문을 발주 이전으로 되돌릴까요? 해당 편집샵의 모든 상품이 전부 발주 이전으로 돌아갑니다.',
                            onOk: async () => {
                              try {
                                await request.delete(
                                  `/commerce/order/shop-order/${shop.shopOrderNumber}/order-complete`
                                );
                                await refreshOrderState();
                                message.success(`주문취소가 복구되었습니다.`);
                              } catch (error) {}
                            },
                            okText: '확인',
                            cancelText: '취소',
                          });
                        }}
                      >
                        발주 완료 취소
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Fragment>
        )}
      />
      <AddItemModal
        isOpen={isAddItemOpen}
        onClose={() => setState({ isAddItemOpen: false, orderStatus: '' })}
        orderStatus={orderStatus}
      />
      <UpdateInvoiceModal
        isOpen={isChangeInvoiceOpen}
        onSuccess={refreshOrderState}
        onClose={() =>
          setState({
            isChangeInvoiceOpen: false,
            itemOrderNumber: '',
            invoice: '',
            shippingCode: '',
            isWaypoint: false,
          })
        }
        apiUrl={`/commerce/order/item-order/${itemOrderNumber}/invoice`}
        invoice={invoice}
        shippingCode={shippingCode}
        isWaypoint={isWaypoint}
      />
      <RemoveItemModal
        isOpen={isRemoveItemOpen}
        onClose={() =>
          setState({
            isRemoveItemOpen: false,
            itemOrderNumber: '',
            itemName: '',
            orderStatus: '',
          })
        }
        itemOrderNumber={itemOrderNumber}
        itemName={itemName}
        orderStatus={orderStatus}
      />
      <ChangeItemOptionModal
        isOpen={isChangeItemOptionOpen}
        onClose={() => setState({ isChangeItemOptionOpen: false, orderStatus: '' })}
        itemId={itemId}
        itemOrderNumber={itemOrderNumber}
        orderStatus={orderStatus}
        currentSizeName={currentSizeName}
        onMoveWait={() =>
          setState({
            isChangeItemOptionOpen: false,
            orderStatus: '',
          })
        }
      />
      <OrderCancelModal
        isOpen={isOrderCancelOpen}
        cancelOrderList={cancelOrderList}
        onClose={() =>
          setState({
            isOrderCancelOpen: false,
            cancelOrderList: [],
          })
        }
      />
      <UpdateCustomDutyModal
        isOpen={isUpdateCustomDutyOpen}
        onClose={() => setState({ isUpdateCustomDutyOpen: false, itemOrderNumber: '' })}
        itemOrderNumber={itemOrderNumber}
        onComplete={() => {}}
      />
      <ShippingTrackingModal
        isOpen={isShippingInfoOpen}
        onClose={() =>
          setState({ isShippingInfoOpen: false, invoice: '', shippingCode: '' })
        }
        invoice={invoice}
        shippingCode={shippingCode}
      />
      <OrderReturnModal
        isOpen={isOrderReturnOpen}
        onClose={() =>
          setState({
            isOrderReturnOpen: false,
            itemOrderList: [],
          })
        }
        returnOrderList={itemOrderList}
      />
      <OrderExchangeModal
        isOpen={isOrderExchangeOpen}
        onClose={() => setState({ isOrderExchangeOpen: false, itemOrderList: [] })}
        exchangeOrderList={itemOrderList}
      />
    </>
  );
};

export default OrderInfoHistory;
