import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  min-width: 900px;
`;

export const Item = styled.div`
  width: calc(100% / 7);
  text-align: center;
  padding: 15px 20px;
  border-right: 1px solid #ddd;
  ${(props) =>
    props.color &&
    `
    color: ${props.color}
  `}
`;

export const ShopName = styled.div`
  width: calc(100% / 7);
  text-align: center;
  padding: 15px 20px;
  border-right: 1px solid #ddd;
  cursor: pointer;
  &:hover {
    color: #6691bf;
  }
`;
