import styled from 'styled-components';

export const PromotionTableOuter = styled.div`
  display: grid;
  grid-template-columns: 5% 15% 10% 15% 5% 10% 15% 15% 5%;
  grid-column-gap: 6px;
  font-size: 12px;
  min-width: 1200px;
  text-align: center;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e9ecef;
  padding: 12px 0;
`;

export const CheckBoxTableItem = styled.div``;

export const PromotionTableItem = styled.div`
  overflow: auto;
  color: gray;
  font-weight: bold;
  ${(props) => props.shopName && 'font-weight: bold'};
  ${(props) => props.shopName && 'color: #3e3b3b'};
`;

export const Input = styled.input.attrs({
  type: 'checkbox',
})`
  width: 35px;
  height: 35px;
  cursor: pointer;
`;

export const LinkBox = styled.div`
  margin-top: 10px;
  border: solid 1px;
  border-radius: 5px;
  padding: 5px 10px;
  word-break: normal;
  overflow: auto;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
  color: #707bb3;
  &:active,
  :hover {
    background-color: #f4f5e5;
  }
`;

export const PrmotionImg = styled.img`
  width: auto;
  height: auto;
  max-width: 300px;
  max-height: 100px;
`;

export const IsActivePromotion = styled.p`
  ${(props) =>
    props.isActive
      ? 'color: green; font-weight: bold;'
      : 'color: #ffc107; font-weight: bold;'}
`;

export const Text = styled.p`
  color: ${(props) => props.color};
  ${(props) =>
    props.fontSizeUp &&
    `
    font-size: ${props.fontSizeUp}
  `};
  ${(props) =>
    props.hover &&
    `
    &:active,
    &:hover {
      color: #83C2E6;
    }
  `};
`;

export const Color = {
  color: '#968989',
};
