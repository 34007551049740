import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-left: 0.5px solid #b4b4b4;
  border-top: 0.5px solid #b4b4b4;
`;

export const Thead = styled.thead`
  background: #bebebe;
  font-size: 12px;
  align-items: center;
`;
export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  display: flex;
`;

export const Th = styled.th`
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
`;

export const Td = styled.td`
  border-right: 0.5px solid #b4b4b4;
  border-bottom: 0.5px solid #b4b4b4;
  width: ${({ w }) => w}%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 12px 0;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 80%;
  height: 80%;
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
  margin-right: 4px;
`;
export const Label = styled.label`
  margin: 0;
  display: flex;
  align-items: center;
`;

export const ManualButton = styled.button`
  font-weight: bold;
  color: white;
  padding: 4px 7px;
  margin: 13px 3px;
  border: none;
  border-radius: 2px;
`;

export const Select = styled.select`
  height: 26px;
  margin: 13px 3px;
`;

export const InfoTd = styled(Td)`
  flex-direction: row;
  justify-content: space-evenly;
`;

export const Img = styled.img`
  width: 30%;
`;

export const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const CalcButton = styled.button`
  padding: 5px 15px;
  font-weight: bold;
  color: #ff6f61;
  background: #000000;
  border: none;
  border-radius: 2px;
`;

export const Input = styled.input.attrs({
  placeholder: '편집샵에 표시된 가격',
})`
  width: 80%;
`;


export const alignLeft = { alignSelf: 'flex-start', paddingLeft: '8px' }