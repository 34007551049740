import { Button, Card, Checkbox, MenuTabs, Radio, ReInput, Select } from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { FETCHING_TEL, useObject } from 'services';

interface State {
  message: string;
  attachFiles: Array<{
    os: string;
    url: string;
  }>;
  isSubmitting: boolean;
}

const UserSendPushPage = () => {
  const [{ message, attachFiles, isSubmitting }, setState, onChange] = useObject<State>({
    message: '',
    attachFiles: [
      {
        os: '안드로이드',
        url: '이미지경로이미지경로이미지경로이미지경로...',
      },
      {
        os: 'iOS',
        url: '이미지경로이미지경로이미지경로이미지경로...',
      },
    ],
    isSubmitting: false,
  });
  const get = async () => {};
  const onManageGrade = async () => {};
  const onManageGroup = async () => {};
  const onSubmit = async () => {
    if (!window.confirm('메시지를 전송하시겠습니까?')) return;
  };
  const onDeleteFile = async (index: number) => {
    if (!window.confirm('첨부된 이미지를 삭제하시겠습니까?')) return;
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <MenuTabs
        options={[
          { name: '푸시 발송', to: '/users/send-push' },
          { name: '푸시 발송 내역', to: '/users/push' },
        ]}
      />
      <div className="wrapper">
        <div className="wrapper-title">푸시 발송</div>
        <div className="mt-4">
          <div>이번달/지난달 푸시 발송건수: nnn,nnn건 / nnn,nnn건</div>
        </div>
      </div>

      <div className="wrapper">
        <div className="wrapper-title">푸시 메시지 작성</div>

        <div className="mt-3 flex gap-5">
          <div style={{ width: 800 }}>
            <Card
              items={[
                {
                  name: '전송 OS',
                  renderItem: () => (
                    <Radio
                      id="submit"
                      onChange={(tab) => console.log('tab', tab)}
                      options={[
                        { value: 'all', name: '전체' },
                        { value: 'android', name: '안드로이드' },
                        { value: 'ios', name: 'iOS' },
                      ]}
                    />
                  ),
                  stretch: true,
                },
                {
                  name: '광고성 정보',
                  renderItem: () => (
                    <Checkbox label="광고성 정보" checked={false} onChange={() => {}} />
                  ),
                  stretch: true,
                },
                {
                  name: '발신번호',
                  renderItem: () => FETCHING_TEL,
                  stretch: true,
                },
                {
                  name: '발송 대상',
                  renderItem: () => (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                      <div>
                        <input type="radio" />
                        <label className="ml-1 mb-0">전체 회원</label>
                        <span className="ml-2 text-sm">
                          (발송가능/전체 회원수: n,nnn명/n,nnn명)
                        </span>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>
                          <input type="radio" />
                          <label className="ml-1 mb-0">회원등급 선택</label>
                        </div>
                        <Select className="w-24" size="xs">
                          <option value="" selected disabled>
                            등급1
                          </option>
                        </Select>
                        <Button size="xs" onClick={onManageGrade}>
                          회원등급관리
                        </Button>
                      </div>
                      <div className="flex items-center gap-2">
                        <div>
                          <input type="radio" />
                          <label className="ml-1 mb-0">대량 발송 그룹 선택</label>
                        </div>
                        <Select className="w-24" size="xs">
                          <option value="" selected disabled>
                            그룹명1
                          </option>
                        </Select>
                        <Button size="xs" onClick={onManageGroup}>
                          발송그룹관리
                        </Button>
                      </div>
                      <div>
                        <input type="radio" />
                        <label className="ml-1 mb-0">직접 첨부</label>
                      </div>
                      <div>
                        <input type="radio" />
                        <label className="ml-1 mb-0">직접 입력</label>
                        <div className="pl-3 mt-1">
                          <ReInput
                            size="xs"
                            className="w-full"
                            placeholder="휴대폰번호 입력 ,(콤마)로 구분하여 여러개 입력 가능"
                          />
                        </div>
                      </div>
                    </div>
                  ),
                  stretch: true,
                },
                {
                  name: '수신거부자',
                  renderItem: () => (
                    <Radio
                      id="reject"
                      onChange={(tab) => console.log('tab', tab)}
                      options={[
                        { value: 'except', name: '제외' },
                        { value: 'include', name: '포함' },
                      ]}
                    />
                  ),
                  stretch: true,
                },

                {
                  name: '발송타입',
                  renderItem: () => (
                    <div className="flex items-center gap-3">
                      <Radio
                        id="submitType"
                        onChange={(tab) => console.log('tab', tab)}
                        options={[
                          { name: '즉시발송', value: 'instant' },
                          { name: '예약발송', value: 'reservation' },
                        ]}
                      />
                      <ReInput
                        size="xs"
                        className="w-56"
                        type="datetime-local"
                        value={dayjs().format('YYYY-MM-DDTHH:mm')}
                      />
                    </div>
                  ),
                  stretch: true,
                },
              ]}
            />
            <div className="text-right">
              <Button isLoading={isSubmitting} onClick={onSubmit}>
                전송하기
              </Button>
            </div>
          </div>
          <div>
            <textarea
              style={{
                border: '1px solid #b4b4b4',
                padding: '20px 15px',
                fontSize: 16,
                background: '#f0f0f0',
                width: 271,
                wordBreak: 'break-all',
                minHeight: 360,
                resize: 'none',
              }}
              onChange={onChange}
              spellCheck={false}
              value={message}
              name="message"
              placeholder="메시지를 입력하세요."
              maxLength={90}
            />
            <div className="text-right">{message.length}/90bytes</div>
          </div>
          <div className="flex flex-col gap-1">
            {attachFiles.map((item, key) => (
              <div
                key={key}
                style={{ border: '1px solid #b4b4b4', padding: '20px 16px' }}
              >
                <div className="flex justify-between gap-7">
                  <div>{item.os} 이미지 첨부 2:1</div>
                  <Button size="sm" onClick={() => onDeleteFile(key)}>
                    파일찾기
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSendPushPage;
