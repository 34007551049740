import styled from 'styled-components';

export const Wrapper = styled.div`
  border-left: 1px solid #d4cece;
  border-top: 1px solid #d4cece;
`;

export const Inner = styled.div`
  @media all and (min-width: 1400px) {
    ${(props) => props.display && `display: ${props.display}`}
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #d4cece;
  @media all and (min-width: 1000px) {
    display: flex;
  }
`;

export const Title = styled.div`
  width: 100%;
  background-color: #e8e8e8;
  padding: 10px 15px;
  font-weight: bold;
  border-right: 1px solid #d4cece;
  border-bottom: 1px solid #d4cece;
  @media all and (min-width: 1000px) {
    width: 30%;
    border-bottom: none;
  }
  @media all and (min-width: 1400px) {
    width: ${(props) => props.width || '30%'};
  }
`;

export const Options = styled.div`
  ${(props) =>
    !props.target &&
    `
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
  `}
  width: 100%;
  padding: 10px 15px;
  border-right: 1px solid #d4cece;
  justify-content: center;
  @media all and (min-width: 1000px) {
    width: 70%;
    justify-content: left;
  }
  @media all and (min-width: 1400px) {
    width: ${(props) => props.width || '70%'};
  }
`;

export const Select = styled.select`
  height: 100%;
  width: 100%;
  @media all and (min-width: 1000px) {
    width: 20%;
  }
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  margin-top: 10px;
  &:focus {
    outline: none;
  }
  @media all and (min-width: 1000px) {
    margin: 0 10px;
    width: 50%;
  }
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

export const P = styled.p`
  margin: 0;
  margin-left: 6px;
  margin-right: 20px;
`;
