import React from 'react';
import * as style from './style';

const View = ({ item }) => {
  return (
    <style.Wrapper>
      <style.ItemOuter>
        <style.Item title="true">타이틀</style.Item>
        <style.Item>
          <style.Text>{item.name}</style.Text>
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">성별</style.Item>
        <style.Item style={{ display: 'flex' }}>
          <style.Text>{item.gender === 'M' ? '남성' : '여성'}</style.Text>
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">노출 활성화</style.Item>
        <style.Item style={{ display: 'flex' }}>
          <style.Text>{item.isActive === 1 ? '활성화' : '비활성화'}</style.Text>
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">노출 위치</style.Item>
        <style.Item>
          {item.isVisibleToAppMain ? (
            <style.Text>앱 {' > '} 메인</style.Text>
          ) : null}
          {item.isVisibleToAppCategory ? (
            <style.Text>앱 {' > '} 카테고리</style.Text>
          ) : null}
          {item.isVisibleToCafe24 ? (
            <style.Text>카페24 {' > '} 메인</style.Text>
          ) : null}
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">노출 기간</style.Item>
        <style.Item>
          {item.startedAt && (
            <style.Text>
              {item.startedAt.slice(0, 10)} {item.startedAt.slice(11, 19)}
            </style.Text>
          )}
          {item.endedAt && (
            <>
              <style.Text>~</style.Text>
              <style.Text>
                {item.endedAt.slice(0, 10)} {item.endedAt.slice(11, 19)}
              </style.Text>
            </>
          )}
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">상단 고정 배너</style.Item>
        <style.Item>
          <style.Img src={item.topBannerImageUrl} />
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">목록 배너</style.Item>
        <style.Item>
          <style.Img src={item.bannerImageUrl} />
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">랜딩 배너</style.Item>
        <style.Item>
          <style.Img src={item.landingImageUrl} />
        </style.Item>
      </style.ItemOuter>
    </style.Wrapper>
  );
};

export default View;
