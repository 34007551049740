import { Radio, ReModal, Button, RePagination, GridTable } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { ExportToCsv } from 'export-to-csv';
import { request, useObject } from 'services';
import { IModal } from 'types';
import { toast } from 'react-toastify';

interface IUserMessageResult {
  phoneNumber: string;
  status: -1 | 0 | 1;
  id: string;
  name: string;
  isSubmitting: boolean;
}

export interface Props extends IModal {
  id: number;
}
interface State {
  isExporting: boolean;
  list: IUserMessageResult[];
  page: number;
  total: number;
  selectedIdx: string[];
  status: null | -1 | 0 | 1;
  isSubmitting: boolean;
}

const UserMessageModal: FunctionComponent<Props> = ({ title, isOpen, onClose, id }) => {
  if (!isOpen) return null;

  const [
    { isExporting, list, total, page, selectedIdx, status, isSubmitting },
    setState,
  ] = useObject<State>({
    isExporting: false,
    list: [],
    page: 1,
    total: 0,
    selectedIdx: [],
    status: null,
    isSubmitting: false,
  });

  const get = async () => {
    try {
      const { count, data } = await request.get<
        any,
        {
          count: number;
          data: IUserMessageResult[];
        }
      >(`/commerce/user/message/${id}/result`, { params: { page, limit: 10, status } });
      setState({
        list: data.map((item) => ({ ...item, isSubmitting: false })),
        total: count,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const onExportCsv = async () => {
    setState({ isExporting: true });
    try {
      const { data } = await request.get<
        any,
        {
          count: number;
          data: IUserMessageResult[];
        }
      >(`/commerce/user/message/${id}/result`);
      const MESSAGE_STATUS = {
        '-1': '실패',
        '0': '대기',
        '1': '성공',
      };
      const csvExporter = new ExportToCsv({
        showLabels: true,
        filename: '메시지_발송결과_내역',
        headers: ['아이디', '이름', '연락처', '결과'],
      });
      csvExporter.generateCsv(
        data.map((item) => ({
          id: item.id,
          name: item.name,
          phoneNumber: item.phoneNumber,
          status: MESSAGE_STATUS[String(item.status)],
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isExporting: false });
    }
  };
  const onResend = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();

    setState({ isSubmitting: true });
    try {
      await request.post(`/commerce/user/message/${id}/resend`, {
        targetPhoneNumbers: list
          .filter((item) => selectedIdx.indexOf(item.id) !== -1)
          .map((item) => item.phoneNumber),
      });
      toast.success('성공적으로 재전송되었습니다.');
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isSubmitting: false });
    }
  };
  const onItemResend = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    index: number
  ) => {
    e.stopPropagation();
    if (!window.confirm('해당 유저에게 재전송하시겠습니까?')) return;
    const item = list[index];

    setState({
      list: [
        ...list.slice(0, index),
        { ...item, isSubmitting: true },
        ...list.slice(index + 1),
      ],
    });
    try {
      await request.post(`/commerce/user/message/${id}/resend`, {
        targetPhoneNumbers: [item.phoneNumber],
      });
      toast.success('성공적으로 재전송되었습니다.');
    } catch (err) {
      console.log(err);
    } finally {
      setState({
        list: [
          ...list.slice(0, index),
          { ...item, isSubmitting: false },
          ...list.slice(index + 1),
        ],
      });
    }
  };
  useEffect(() => {
    get();
  }, [page]);
  return (
    <ReModal title={title} isOpen={isOpen} onClose={onClose} maxWidth="max-w-xl">
      <Radio
        id="user-message-modal-status"
        value={status}
        onChange={(status) => setState({ status, page: 1 })}
        options={[
          { name: '전체', value: null },
          { name: '대기', value: 0 },
          { name: '성공', value: 1 },
          { name: '실패', value: -1 },
        ]}
      />
      <div className="mt-4 mb-2 flex justify-between items-center">
        <div className="flex items-center gap-4">
          <span>총 {total}건</span>
          <Button size="xs" theme="secondary" onClick={onResend}>
            선택 재전송
          </Button>
        </div>
        <div>
          <Button size="xs" isLoading={isExporting} onClick={onExportCsv}>
            전체 액셀 다운로드
          </Button>
        </div>
      </div>

      <GridTable
        columns={['연락처', '이름 (아이디)', '결과', '관리']}
        renderItem={(item, key) => (
          <div
            key={key}
            onClick={() =>
              setState({
                selectedIdx:
                  selectedIdx.indexOf(item.id) !== -1
                    ? selectedIdx.filter((id) => id !== item.id)
                    : [...selectedIdx, item.id],
              })
            }
          >
            <div>
              <input
                type="checkbox"
                onChange={() => {}}
                checked={selectedIdx.indexOf(item.id) !== -1}
              />
            </div>
            <div>{item.phoneNumber}</div>
            <div>
              {item.name} ({item.id})
            </div>
            <div>
              <span>
                {item.status === 1 && '성공'}
                {item.status === 0 && '대기'}
                {item.status === -1 && '실패'}
              </span>
            </div>
            <div>
              <Button size="xs" onClick={(e) => onItemResend(e, key)}>
                재전송
              </Button>
            </div>
          </div>
        )}
        list={list}
        isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
        onSelectAll={(checked) =>
          setState({ selectedIdx: checked ? list.map((item) => item.id) : [] })
        }
      />
      <RePagination
        pageSize={10}
        currentPage={page}
        onPageChange={(page) => setState({ page })}
        totalCount={total}
      />
    </ReModal>
  );
};

export default UserMessageModal;
