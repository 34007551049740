import React from 'react';
import * as style from './style';
import { Spinner } from 'components';

const Loading = () => (
  <style.Outer>
    <Spinner className="h-10 w-10 text-black" />
    <style.Text>loading.......</style.Text>
  </style.Outer>
);

export default Loading;
