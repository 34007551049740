import React, { FunctionComponent } from 'react';
import { ICardItem } from 'types';
import classnames from 'classnames';

export interface Props extends ICardItem {}
interface State {}

const CardItem: FunctionComponent<Props> = ({ stretch, name, renderItem }) => {
  return (
    <div className={classnames('flex', stretch ? 'w-full' : 'w-1/2')}>
      <div
        className="text-gray-500 bg-gray-100 text-xs w-32 flex items-center px-3"
        style={{ wordBreak: 'keep-all' }}
      >
        {name}
      </div>
      <div className="flex-1 flex flex-wrap p-3 items-center">{renderItem()}</div>
    </div>
  );
};

export default CardItem;
