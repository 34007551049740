import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: 5px 0;
  align-items: center;
`;

export const Text = styled.p`
  margin: 0 10px;
`;

export const Link = styled.p`
  margin: 0 5px;
  cursor: pointer;
  color: #69abf9;
`;

export const Button = styled.button`
  font-size: 1.2rem;
  background-color: white;
  color: black;
  border: 1px solid white;
  transition: 0.5s;
  &:active,
  &:hover {
    color: #69abf9;
  }
  &:focus {
    outline: none;
  }
`;
