import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #80808052;
  z-index: 1000;
`;

export const Inner = styled.div`
  background-color: white;
  width: 90%;
  height: 50%;
  border-radius: 10px;
  @media all and (min-width: 750px) {
    width: 70%;
  }
  @media all and (min-width: 1000px) {
    width: 50%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 15%;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
`;

export const Body = styled.div`
  width: 100%;
  height: 70%;
  padding: 10px 20px;
  overflow-y: auto;
`;

export const Bottom = styled.div`
  width: 100%;
  padding: 10px 20px;
  border-top: 1px solid #ddd;
`;

export const Button = styled.button`
  border: 1px solid #343a40;
  border-radius: 4px;
  padding: 7px 12px;
  color: ${(props) => props.color || '#343a40'};
  background-color: ${(props) => props.BC || 'white'};
  margin: 5px;
  &:hover {
    color: ${(props) => props.HC || 'white'};
    background-color: ${(props) => props.HBC || '#343a40'};
    border: 1px solid ${(props) => props.HC || '#343a40'};
  }
  &:focus {
    outline: none;
  }
`;
