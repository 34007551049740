import { Button, Card, GridTable, ReInput, RePagination, Select } from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { dayjsFormat, request, useObject } from 'services';

interface IPush {
  body: string;
  createdAt: string;
  data: string;
  sendResult: 'SUCCESS' | 'FAILED';
  title: string;
}

export interface Props {}
interface State {
  total: number;
  page: number;
  list: IPush[];
  pageSize: number;
  minDate: string;
  maxDate: string;
  isSearching: boolean;
}

const UserPush: FunctionComponent<Props> = () => {
  const [
    { total, page, list, pageSize, minDate, maxDate, isSearching },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    total: 0,
    page: 1,
    list: [],
    pageSize: 10,
    minDate: dayjsFormat(dayjs().add(-7, 'day')),
    maxDate: dayjsFormat(dayjs()),
    isSearching: false,
  });
  const { idx } = useParams<{ idx: string }>();
  const get = async () => {
    setState({ isSearching: true });
    try {
      const { data, count } = await request.get<any, { count: number; data: IPush[] }>(
        `/commerce/user/${idx}/push-history`,
        {
          params: {
            limit: pageSize,
            page,
            minDate: `${dayjs(minDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
            maxDate: `${dayjs(maxDate).format('YYYY-MM-DDT23:59:59')}.000Z`,
          },
        }
      );
      setState({ list: data, total: count });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isSearching: false });
    }
  };
  useEffect(() => {
    get();
  }, [minDate, maxDate, pageSize, page]);
  return (
    <div className="p-4">
      <Card
        items={[
          {
            name: '검색기간',
            renderItem: () => (
              <div className="flex items-center gap-3">
                <Button
                  size="xs"
                  onClick={() =>
                    setState({
                      minDate: dayjsFormat(dayjs()),
                      maxDate: dayjsFormat(dayjs()),
                    })
                  }
                >
                  오늘
                </Button>
                <Button
                  size="xs"
                  onClick={() =>
                    setState({
                      minDate: dayjsFormat(dayjs().add(-3, 'day')),
                      maxDate: dayjsFormat(dayjs()),
                    })
                  }
                >
                  3일
                </Button>
                <Button
                  size="xs"
                  onClick={() =>
                    setState({
                      minDate: dayjsFormat(dayjs().add(-7, 'day')),
                      maxDate: dayjsFormat(dayjs()),
                    })
                  }
                >
                  7일
                </Button>
                <div className="w-40">
                  <ReInput
                    size="xs"
                    type="date"
                    value={minDate}
                    onChange={onChange}
                    name="minDate"
                  />
                </div>
                {' ~ '}
                <div className="w-40">
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxDate}
                    name="maxDate"
                    onChange={onChange}
                  />
                </div>
              </div>
            ),
            stretch: true,
          },
        ]}
      />
      <div className="my-5 flex justify-center gap-3">
        <Button isLoading={isSearching} onClick={get}>
          검색
        </Button>
        <Button
          theme="secondary"
          onClick={() => resetState(['minDate', 'maxDate', 'pageSize', 'page'])}
        >
          초기화
        </Button>
      </div>
      <GridTable
        columns={['발송일시', '푸시', '발송결과']}
        list={list}
        noSelection
        header={
          <div className="flex items-center justify-end">
            <Select size="sm" value={pageSize} name="pageSize" onChange={onChange}>
              <option value={10}>10개씩 보기</option>
              <option value={20}>20개씩 보기</option>
            </Select>
          </div>
        }
        renderItem={(item, key) => (
          <div key={key}>
            <div>{dayjs(item.createdAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
            <div>
              <div className="hover:link">{item.title}</div>
            </div>
            <div>
              {item.sendResult === 'SUCCESS' && '발송성공'}
              {item.sendResult === 'FAILED' && '발송실패'}
            </div>
          </div>
        )}
      />
      <div className="flex justify-between items-center">
        <div className="pl-2 text-gray-500 tracking-widest">
          <span>총</span>
          <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
          <span>개</span>
        </div>
        <RePagination
          currentPage={page}
          onPageChange={(page) => setState({ page })}
          totalCount={total}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default UserPush;
