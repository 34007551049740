import styled from 'styled-components';

export const TitleBar = styled.div`
  display: flex;
  padding: 10px 15px;
  width: 100%;
  background-color: white;
  overflow-x: auto;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  position: sticky;
  top: 50px;
`;

export const TitleItem = styled.div`
  white-space: nowrap;
  font-weight: bold;
  padding: 10px 15px;
  border-right: 1px solid #bfb9b9;
  cursor: pointer;
  &:hover {
    color: #83a9d1;
  }
`;
