import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'api/axios';
import { LoadingContext } from 'context';
import * as style from './style';
import { Spinner } from 'components';

const ColorChangeModal = (props) => {
  const { onHide, targetIds } = props;

  const { isLoading, handleIsLoading } = useContext(LoadingContext);
  // const itemIds = useSelector((state) => state.selectItems.itemsId);
  const itemIds = targetIds;
  const [color, setColor] = useState('');

  const handleColorChange = async () => {
    try {
      handleIsLoading(true);
      await axios.patch('/api/items/color', {
        itemIds,
        color,
      });
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      PATCH - /api/items/color
      ${err}`);
      console.error(err);
    } finally {
      handleIsLoading(false);
      setColor('');
      onHide();
    }
  };

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>color 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading && (
          <style.LoadOuter>
            <Spinner className="h-10 w-10 text-black" />
            <p>loading ... </p>
          </style.LoadOuter>
        )}
        <style.Description>
          선택한 상품을 아래 작성한 color로 변경합니다.🎈
        </style.Description>
        <style.TextInput
          placeholder={'예) 블랙'}
          value={color}
          onChange={(e) => {
            setColor(e.target.value);
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={handleColorChange}>
          변경
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ColorChangeModal;
