import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios, { fetchingAPI } from 'api/axios';
import * as style from './style';

const ModifyNotice = ({ history }) => {
  const detailNoticeData = useSelector((state) => state.detailNotice.detailNoticeData);

  const [title, setTitle] = useState(detailNoticeData.title);
  const [isActive, setIsActive] = useState(String(Number(detailNoticeData.isActive)));
  const [importance, setImportance] = useState(
    String(Number(detailNoticeData.isFixedAtTop))
  );
  const [content, setContent] = useState(detailNoticeData.contents);

  const handleModifyNotice = () => {
    fetchingAPI
      .put(`/notice/${detailNoticeData.idx}`, {
        title,
        contents: content,
        isFixedAtTop: Boolean(Number(importance)),
        isActive: Boolean(Number(isActive)),
      })
      .then((res) => {
        if (res.data.success) {
          history.push('/content/noticeList');
        }
      })
      .catch((err) => {
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        PUT - /api/notice
        ${err}`);
        console.error(err);
      });
  };

  return (
    <style.Outer>
      <div>
        <h3>공지사항</h3>
      </div>
      <style.FormOuter>
        <style.FormBox>
          <Form.Group>
            <Form.Control
              style={style.TextAlign}
              type="text"
              placeholder="제목"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Form.Group>
          <Form.Group style={style.BoxStyle}>
            <div style={style.ItemWidth}>
              <Form.Label>활성화여부</Form.Label>
              <Form.Control
                style={style.TextAlign}
                as="select"
                defaultValue={isActive}
                onChange={(e) => setIsActive(e.target.value)}
              >
                <option value="1">활성화</option>
                <option value="0">비활성화</option>
              </Form.Control>
            </div>
            <div style={style.ItemWidth}>
              <Form.Label>중요여부</Form.Label>
              <Form.Control
                style={style.TextAlign}
                as="select"
                defaultValue={importance}
                onChange={(e) => setImportance(e.target.value)}
              >
                <option value="0">보통</option>
                <option value="1">중요</option>
              </Form.Control>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>내용</Form.Label>
            <Form.Control
              style={{ ...style.TextAlign, whiteSpace: 'pre-line' }}
              as="textarea"
              rows="10"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button variant="dark" onClick={handleModifyNotice}>
            수정
          </Button>
        </style.FormBox>
      </style.FormOuter>
    </style.Outer>
  );
};

export default withRouter(ModifyNotice);
