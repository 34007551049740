export enum Standard {
  'SALES' = 'SALES',
  'TRANSACTION' = 'TRANSACTION',
  'AGENCY_FEE' = 'AGENCY_FEE',
}

export type User = {
  admin_name: string;
  id: number;
};

export type Data = {
  priority: {
    totalCount: number;
    salesCount: number;
    transactionAmount: number;
    salesAmount: number;
    agencyFeeTotal: number;
    graph: {
      date: string;
      value: number;
    }[];
  };
  exhibition: {
    totalCount: number;
    salesCount: number;
    transactionAmount: number;
    salesAmount: number;
    agencyFeeTotal: number;
    graph: {
      date: string;
      value: number;
    }[];
  };
};
