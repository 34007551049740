import React from 'react';
import OptionCheckboxListBrands from './brands';
import OptionCheckboxListShops from './shops';
import OptionCheckboxListPromotions from './promotions';
import { ListsContainer } from './style';
import OptionCheckboxListExhibitions from './exhibitions';
import OptionCheckboxListMCategories from './mCategories';
import OptionCheckboxListWCategories from './wCategories';
import { useRecoilValueLoadable } from 'recoil';
import {
  brandsQuery,
  categoriesQuery,
  exhibitionsQuery,
  promotionsQuery,
  shopsQuery,
} from 'store';

const OptionCheckboxList = ({ searchOptionTableState, setSearchOptionTableState }) => {
  //처음 렌더시 필요한 정보를 받아옴
  // const [shops, setShops] = useState([]);
  const shops = useRecoilValueLoadable(shopsQuery);
  const brands = useRecoilValueLoadable(brandsQuery);
  const promotions = useRecoilValueLoadable(promotionsQuery);
  const exhibitions = useRecoilValueLoadable(exhibitionsQuery);
  const categories = useRecoilValueLoadable(categoriesQuery);

  return (
    <ListsContainer>
      <OptionCheckboxListShops
        shops={shops.state == 'hasValue' ? shops.getValue() : []}
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
      />
      <OptionCheckboxListBrands
        brands={brands.state == 'hasValue' ? brands.getValue() : []}
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
      />
      <OptionCheckboxListWCategories
        wCategories={
          categories.state == 'hasValue' ? categories.getValue().wCategories : []
        }
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
      />
      <OptionCheckboxListMCategories
        mCategories={
          categories.state == 'hasValue' ? categories.getValue().mCategories : []
        }
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
      />
      <OptionCheckboxListPromotions
        promotions={promotions.state == 'hasValue' ? promotions.getValue() : []}
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
      />
      <OptionCheckboxListExhibitions
        exhibitions={exhibitions.state == 'hasValue' ? exhibitions.getValue() : []}
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
      />
    </ListsContainer>
  );
};

export default OptionCheckboxList;
