import React, { FunctionComponent } from 'react';

export interface Props {
  title?: string;
}
interface State {}

const MenuTitle: FunctionComponent<Props> = ({ children, title }) => {
  return (
    <div className="bg-white py-3 px-7">
      {title ? <h1 className="font-bold mb-0 text-base">{title}</h1> : children}
    </div>
  );
};

export default MenuTitle;
