import React, { useState } from 'react';
import * as style from './style';
import Cafe24UploadTd from '../../components/UI/molecules/Cafe24UploadTd';
import { SimpleInput } from '../../components';
import { fetchingAPI } from '../../api/fetchingAPI';

export default function ProductAddReference() {
  const [isCafe24Active, setIsCafe24Active] = useState(false);
  const [isNaverActive, setIsNaverActive] = useState(false);
  const [priority, setPriority] = useState('');
  const [infoRef, setInfoRef] = useState('');
  const [imageRef, setImageRef] = useState('');
  const [descriptionRef, setDescriptionRef] = useState('');
  const [priceRef, setPriceRef] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const resetData = () => {
    setIsCafe24Active(false);
    setIsNaverActive(false);
    setPriority('');
    setInfoRef('');
    setImageRef('');
    setDescriptionRef('');
    setPriceRef('');
  };
  const sendData = () => {
    const invalidValues = [];
    if (isNaN(parseInt(priority))) invalidValues.push('우선순위');
    if (isNaN(parseInt(infoRef))) invalidValues.push('편집샵 상품 정보');
    if (isNaN(parseInt(imageRef))) invalidValues.push('이미지');
    if (isNaN(parseInt(descriptionRef))) invalidValues.push('상품 설명');
    if (isNaN(parseInt(priceRef))) invalidValues.push('가격 및 재고');
    if (invalidValues.length) {
      alert('유효하지 않은 값이 있습니다.\n\n' + `${invalidValues.join(', ')}`);
      return;
    }
    setIsLoading(true);
    fetchingAPI
      .post('/item/mix-match', {
        cafe24Upload: isCafe24Active,
        naverFeedUpload: isNaverActive,
        priority,
        infoRef,
        imageRef,
        descriptionRef,
        priceAndStockRef: priceRef,
      })
      .then(() => {
        setIsLoading(false);
        alert('상품 생성이 완료되었습니다.');
      })
      .catch(() => {
        setIsLoading(false);
        alert('상품 생성에 실패했습니다.');
      });
  };

  const optionTableData = [
    {
      Left: '앱 노출',
      Td: '상품 정보에 문제 없을 시 자동으로 노출이 활성화됩니다.',
    },
    {
      Left: '카페24 업로드',
      Td: (
        <style.TdContentWrapper
          onChange={(e) => {
            setIsCafe24Active(e.target.value === 'true');
          }}
        >
          <style.Label>
            <style.Radio
              name="isCafe24Active"
              value={true}
              checked={isCafe24Active === true}
              onChange={() => {}}
            />
            활성화
          </style.Label>
          <style.Label>
            <style.Radio
              name="isCafe24Active"
              value={false}
              checked={isCafe24Active === false}
              onChange={() => {}}
            />
            비활성화
          </style.Label>
        </style.TdContentWrapper>
      ),
    },
    {
      Left: '네이버 쇼핑 피드',
      Td: (
        <style.TdContentWrapper
          onChange={(e) => {
            setIsNaverActive(e.target.value === 'true');
          }}
        >
          <style.Label>
            <style.Radio
              name="isNaverActive"
              value={true}
              checked={isNaverActive === true}
              onChange={() => {}}
            />
            활성화
          </style.Label>
          <style.Label>
            <style.Radio
              name="isNaverActive"
              value={false}
              checked={isNaverActive === false}
              onChange={() => {}}
            />
            비활성화
          </style.Label>
        </style.TdContentWrapper>
      ),
    },
  ];

  const infoTableData = [
    {
      Left: '우선순위',
      Td: (
        <SimpleInput
          placeholder={'0 이상의 숫자를 입력하세요'}
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
        />
      ),
    },
    {
      Left: '편집샵',
      Td: 'FETCHING',
    },
    {
      Left: '편집샵 상품 정보',
      Td: (
        <style.TdContentWrapper>
          <SimpleInput
            placeholder={'페칭 코드를 입력하세요'}
            value={infoRef}
            onChange={(e) => setInfoRef(e.target.value)}
          />
          <style.Text>
            편집샵에서 수집한 브랜드, 카테고리, 상품명, 색상을 그대로 복사해서 넣습니다.
            <br />
            브랜드 및 카테고리 매핑 정보 또한 그대로 복사됩니다.
            <br />
            상품 생성이 완료된 이후에 각 페칭 카테고리, 상품명, 색상 등의 정보를
            수정하실수 있습니다.
          </style.Text>
        </style.TdContentWrapper>
      ),
    },
    {
      Left: '이미지',
      Td: (
        <SimpleInput
          placeholder={'페칭 코드를 입력하세요'}
          value={imageRef}
          onChange={(e) => setImageRef(e.target.value)}
        />
      ),
    },
    {
      Left: '상품 설명',
      Td: (
        <SimpleInput
          placeholder={'페칭 코드를 입력하세요'}
          value={descriptionRef}
          onChange={(e) => setDescriptionRef(e.target.value)}
        />
      ),
    },
    {
      Left: '가격 및 재고',
      Td: (
        <style.TdContentWrapper>
          <SimpleInput
            placeholder={'페칭 코드를 입력하세요'}
            value={priceRef}
            onChange={(e) => setPriceRef(e.target.value)}
          />
          <style.Text>
            위에 입력한 페칭코드 상품과 동일하게 가격과 재고가 업데이트되며,
            <br />
            상품 최종 업데이트 시간 역시 동일하게 표시됩니다.
          </style.Text>
        </style.TdContentWrapper>
      ),
    },
  ];

  return (
    <style.Wrapper>
      <style.Container>
        <style.TitleWrapper>
          <style.Title>조합형 상품 등록</style.Title>
        </style.TitleWrapper>
        <style.ContentWrapper>
          <style.ItemWrapper>
            <style.ItemTitle color="black">판매 정보</style.ItemTitle>
            <style.Item>
              <style.Table>
                {optionTableData.map((data, index) => (
                  <style.Tr key={index}>
                    <style.Left>{data.Left}</style.Left>
                    <style.Td>{data.Td}</style.Td>
                  </style.Tr>
                ))}
              </style.Table>
            </style.Item>
          </style.ItemWrapper>
          <style.ItemWrapper>
            <style.ItemTitle color="black">상품 정보</style.ItemTitle>
            <style.Item>
              <style.Table>
                {infoTableData.map((data, index) => (
                  <style.Tr key={index}>
                    <style.Left>{data.Left}</style.Left>
                    <style.Td>{data.Td}</style.Td>
                  </style.Tr>
                ))}
              </style.Table>
            </style.Item>
          </style.ItemWrapper>
        </style.ContentWrapper>
        <style.ContentWrapper>
          <style.NegativeButton onClick={resetData}>입력 초기화</style.NegativeButton>
          <style.PositiveButton onClick={sendData}>
            {isLoading ? '등록중...' : '등록하기'}
          </style.PositiveButton>
        </style.ContentWrapper>
      </style.Container>
    </style.Wrapper>
  );
}
