import React from 'react';
import type { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import instance from 'api/axios';
import axios from 'axios';
import { useObject } from 'services';
import classnames from 'classnames';
import { Spinner, Tooltip } from 'components';
import { ExternalLinkIcon, UploadIcon } from '@heroicons/react/solid';

export interface Props {
  value: string;
  onUpload: (base64: string) => void;
  /**
   * @param app
   * fetching-app S3에 올라가는 경우
   * @param commerce
   * fetching-commerce S3에 올라가는 경우
   */
  type: 'app' | 'commerce';
  path?: string;
}
interface State {
  isUploading: boolean;
}

const ImageUpload: FunctionComponent<Props> = ({ value, onUpload, type, path }) => {
  const [{ isUploading }, setState] = useObject<State>({ isUploading: false });

  const onClick = () => {
    if (isUploading) return;

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = () => {
      if (!input.files) return;
      const file = input.files[0];
      if (type === 'commerce') {
        commerceImageUpload(file);
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {
        const base64 = reader.result as string;
        appImageUpload(base64.split(',')[1]);
      };
      reader.onerror = function (err) {
        console.log(err);
        toast.error('이미지를 불러오는데 실패했습니다. 다시 시도해 주세요.');
      };
    };
    input.click();
  };

  const appImageUpload = async (base64: string) => {
    if (!path) {
      toast.info('경로가 필요합니다.');
      return;
    }
    setState({ isUploading: true });
    const { data } = await instance.post('/api/util/image', {
      path,
      image: base64,
    });
    if (data.success) onUpload(`${data.data.fpath}${data.data.fname}`);
    setState({ isUploading: false });
  };

  const commerceImageUpload = async (file: File) => {
    setState({ isUploading: true });
    try {
      const formData = new FormData();
      formData.append('file', file);
      const { data } = await axios.post<any, any>(
        '/utility/file-upload/private',
        formData,
        {
          baseURL: 'https://commerce.fetchingapp.co.kr',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      onUpload(data.fileUrl);
    } catch (err) {
      console.log(err);
      toast.error('이미지를 업로드하는데 실패했습니다. 개발팀에게 문의해 주세요.');
    } finally {
      setState({ isUploading: false });
    }
  };
  return (
    <div
      className={classnames(
        'inline-block rounded border border-gray-600 relative',
        { 'cursor-not-allowed': isUploading },
        { group: !!value }
      )}
    >
      {isUploading ? (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 w-full h-full flex items-center justify-center">
          <Spinner className="h-7 w-7 text-blue-600 cursor-not-allowed" />
        </div>
      ) : (
        !!value && (
          <div className="absolute w-full h-full hidden group-hover:flex z-10 items-center justify-center gap-2">
            <Tooltip position="left" content="업로드">
              <button
                onClick={onClick}
                className="bg-white border border-gray-700 rounded-full p-2"
              >
                <UploadIcon className="w-5 h-5" />
              </button>
            </Tooltip>
            <Tooltip position="right" content="열기">
              <button
                className="bg-white border border-gray-700 rounded-full p-2"
                onClick={() => window.open(value)}
              >
                <ExternalLinkIcon className="w-5 h-5" />
              </button>
            </Tooltip>
          </div>
        )
      )}

      <img
        src={value || 'https://via.placeholder.com/150'}
        alt=""
        className={classnames(
          'rounded border border-gray-600',
          !!value ? 'group-hover:opacity-70' : 'cursor-pointer',
          {
            'opacity-70 cursor-not-allowed': isUploading,
          }
        )}
        onClick={() => !value && onClick()}
      />
    </div>
  );
};

export default ImageUpload;
