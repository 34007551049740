import { Button, GridTable, ReMessageModal, RePagination } from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';

interface IInterest {
  createdAt: string;
  imageUrl: string;
  shopName: string;
  itemName: string;
  size: string;
  sizeStock: number;
  price: number;
  itemId: string;
}

export interface Props {}
interface State {
  list: IInterest[];
  page: number;
  total: number;
  pageSize: number;
  isOpen: boolean;
  phoneNumber: string;
}

const UserInterest: FunctionComponent<Props> = () => {
  const [{ list, page, total, pageSize, isOpen, phoneNumber }, setState] =
    useObject<State>({
      list: [],
      page: 1,
      total: 0,
      pageSize: 10,
      isOpen: false,
      phoneNumber: '',
    });
  const { idx } = useParams<{ idx: string }>();
  const get = async () => {
    try {
      const { data, count } = await request.get<
        any,
        { data: IInterest[]; count: number }
      >(`/commerce/user/${idx}/interest-item`, {
        params: {
          limit: pageSize,
          page,
        },
      });
      setState({ list: data, total: count });
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmitMessaging = async (index: number) => {
    setState({ isOpen: true });
  };
  const onSubmitEmail = async (index: number) => {
    toast.info('현재는 지원하지 않는 기능입니다.');
  };
  useEffect(() => {
    get();
  }, [pageSize, page]);
  return (
    <>
      <div className="p-4">
        <GridTable
          columns={['담은일자', '상품정보', '옵션', '옵션재고', '판매가', 'CRM']}
          list={list}
          noSelection
          renderItem={(item, key) => (
            <div key={key}>
              <div>{dayjs(item.createdAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
              <div>
                <div className="flex gap-3">
                  <img
                    className="max-w-[3.5rem] object-contain"
                    src={item.imageUrl}
                    alt="interest"
                  />
                  <div
                    onClick={() =>
                      window.open(`/product/productDetail/v2/${item.itemId}?mode=update`)
                    }
                    className="link"
                  >
                    <div>{item.shopName}</div>
                    <div className="mt-1">{item.itemName}</div>
                  </div>
                </div>
              </div>
              <div>{item.size}</div>
              <div>{item.sizeStock || 0}개</div>
              <div>{item.price}원</div>
              <div>
                <div className="flex flex-col gap-1 items-center">
                  <Button size="xs" onClick={() => onSubmitMessaging(key)}>
                    메시지 보내기
                  </Button>
                  <Button size="xs" onClick={() => onSubmitEmail(key)}>
                    이메일 보내기
                  </Button>
                </div>
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={total}
            pageSize={pageSize}
          />
        </div>
      </div>
      <ReMessageModal
        isOpen={isOpen}
        onClose={() => setState({ isOpen: false })}
        phoneNumber={phoneNumber}
      />
    </>
  );
};

export default UserInterest;
