import React from 'react';
import * as style from './style';
import dayjs from 'dayjs';

const today = dayjs().format('YYYY-MM-DD');

const CommerceUserRegisterTypeTd = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const { inflowDestination, registerType, minCreatedAt, maxCreatedAt } =
    searchOptionTableState;

  // date input 용 문자열 생성
  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  };

  // input date 처리
  const handleDateInput = (e, target) => {
    setSearchOptionTableState({
      ...searchOptionTableState,
      [target]: e.target.value,
    });
  };

  return (
    <style.Container>
      <style.Div
        onChange={(e) => {
          let value = e.target.value;
          if (value === 'null') {
            value = null;
          }
          setSearchOptionTableState({
            ...searchOptionTableState,
            inflowDestination: value,
          });
        }}
        widthPcnt={22}
      >
        <style.Label>
          <style.Radio
            value="null"
            checked={inflowDestination === null}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="PC_WEB"
            checked={inflowDestination === 'PC_WEB'}
            onChange={() => {}}
          />
          PC웹
        </style.Label>
        <style.Label>
          <style.Radio
            value="MOBILE_WEB"
            checked={inflowDestination === 'MOBILE_WEB'}
            onChange={() => {}}
          />
          모바일웹
        </style.Label>
        <style.Label>
          <style.Radio
            value="APP"
            checked={inflowDestination === 'APP'}
            onChange={() => {}}
          />
          앱
        </style.Label>
      </style.Div>
      <style.Left widthPcnt={11}>가입 유형</style.Left>
      <style.Div
        onChange={(e) => {
          let value = e.target.value;
          if (value === 'null') {
            value = null;
          }
          setSearchOptionTableState({
            ...searchOptionTableState,
            registerType: value,
          });
        }}
        widthPcnt={22}
      >
        <style.Margin9px />
        <style.Label>
          <style.Radio
            value="null"
            checked={registerType === null}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="FETCHING"
            checked={registerType === 'FETCHING'}
            onChange={() => {}}
          />
          페칭
        </style.Label>
        <style.Label>
          <style.Radio
            value="KAKAO"
            checked={registerType === 'KAKAO'}
            onChange={() => {}}
          />
          카카오
        </style.Label>
      </style.Div>
      <style.Left widthPcnt={11}>가입일</style.Left>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            isCafe24Active: e.target.value,
          });
        }}
        widthPcnt={22}
        noBorder
      >
        <style.Margin9px />
        <style.Label>
          <style.InputDate
            max={today}
            onChange={(e) => handleDateInput(e, 'minCreatedAt')}
            value={minCreatedAt}
          />
          ~
          <style.InputDate
            max={today}
            onChange={(e) => handleDateInput(e, 'maxCreatedAt')}
            value={maxCreatedAt}
          />
        </style.Label>
      </style.Div>
    </style.Container>
  );
};

export default CommerceUserRegisterTypeTd;
