import React from 'react';
import styled from 'styled-components';

const Pagination = ({ page = 1, total, num = 5, onChange, limit }) => {
  const totalPageNum = Math.floor(total / limit);
  const mid = parseInt(num / 2);
  let min = 1;
  let max = totalPageNum;
  const pageArr = [];

  if (page <= mid || page >= totalPageNum) {
    min = 1;
    max = totalPageNum;
  } else if (page === totalPageNum || page + mid > totalPageNum) {
    min = totalPageNum - mid * 2;
    max = totalPageNum;
  } else {
    min = page - mid;
    max = page + mid;
  }

  for (let i = min; i <= max; i++) {
    pageArr.push(i);
  }

  return (
    <ButtonWrapper>
      <ButtonIcon onClick={() => onChange(1)}> {`<`} </ButtonIcon>
      {pageArr.map((pg) => (
        <ButtonIcon
          key={pg}
          isActive={page === pg}
          onClick={() => onChange(pg)}
        >
          {pg}
        </ButtonIcon>
      ))}
      <ButtonIcon onClick={() => onChange(totalPageNum)}> {`>`} </ButtonIcon>
    </ButtonWrapper>
  );
};

export default Pagination;

const ButtonWrapper = styled.div`
  display: flex;
  margin: 16px 10px;
  justify-content: center;
`;

const ButtonIcon = styled.div`
  border: 1px solid #d1d1d1;
  padding: 4px;
  background-color: ${({ isActive }) => (isActive ? '#1a1a1a' : '#fff')};
  color: ${({ isActive }) => (isActive ? '#fff' : '#1a1a1a')};
  width: 30px;
  text-align: center;
  cursor: pointer;
  margin-right: 4px;
  &:hover {
    background-color: #1a1a1a;
    color: #fff;
  }
`;
