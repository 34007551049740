import { TableItem } from 'components/UI/molecules';
import React, { useRef, useEffect } from 'react';
import * as style from './style';

const ShopExposureSet = ({ shop, handleClinentHeight }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('노출 설정', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('노출 설정', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>노출 설정</style.Title>
      <style.BoxWrapper>
        <TableItem
          title="앱 노출 여부"
          value={shop?.isActive === 1 ? '노출' : '미노출'}
          valueColor={shop?.isActive === 1 ? 'green' : 'red'}
        />
        <TableItem
          title="카페24 업로드"
          value={
            shop?.isCafe24Uploading === 1
              ? '대상'
              : shop?.isCafe24Uploading === -1
              ? '미대상'
              : '일시정지'
          }
          valueColor={
            shop?.isCafe24Uploading === 1
              ? 'green'
              : shop?.isCafe24Uploading === -1
              ? 'red'
              : '#FF891C'
          }
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem
          title="페칭 결제 지원"
          value={shop?.isFetchingPay === 1 ? '지원' : '미지원'}
          valueColor={shop?.isFetchingPay === 1 ? 'green' : 'red'}
        />
        <TableItem
          title="네이버 업로드 지원"
          value={shop?.cafe24Uploadable === 1 ? '가능' : '불가능'}
          valueColor={shop?.cafe24Uploadable === 1 ? 'green' : 'red'}
        />
      </style.BoxWrapper>
    </style.Wrapper>
  );
};
export default ShopExposureSet;
