import { NumberStat, OrderCount, ShippingCount, Spinner } from 'components';
import dayjs from 'dayjs';
import React, { ReactNode, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { request, useObject } from 'services';
import { orderCountState, shippingCountState } from 'store';
import { priceFormat } from 'utils';
import classnames from 'classnames';
import { Chart, Tooltip, BarElement, Legend, CategoryScale, LinearScale } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

Chart.register(Tooltip, BarElement, Legend, CategoryScale, LinearScale);

const DashboardCard: React.FC<{ title: string; date?: string; extra?: ReactNode }> = ({
  children,
  title,
  date,
  extra,
}) => {
  return (
    <div className="bg-white rounded">
      <div className="p-4 border-b border-gray-200 flex items-center justify-between">
        <span className="font-bold text-2xl">{title}</span>
        {extra}
        <span className="text-gray-400">{date}</span>
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
};

const DashboardItem: React.FC<{ name: string }> = ({ name, children }) => {
  return (
    <div className="flex items-center justify-between">
      <div>{name}</div>
      <div>{children}</div>
    </div>
  );
};

const DashboardLink: React.FC<{ to: string }> = ({ to, children }) => {
  return (
    <Link to={to} className="text-black hover:text-black">
      {children}
    </Link>
  );
};

const DashboardDiff: React.FC<{
  from: number;
  to: number;
  reverse?: boolean;
  unit?: string;
}> = ({ from, to, reverse = false, unit }) => {
  const diff = to - from;
  return (
    <>
      <span
        className={classnames('mr-2', {
          'text-green-500': reverse ? diff < 0 : diff > 0,
          'text-gray-400': diff === 0,
          'text-red-600': reverse ? diff > 0 : diff < 0,
        })}
      >
        {diff > 0 && '+'}
        {priceFormat(diff)}
      </span>
      <NumberStat value={to} unit={unit} />
    </>
  );
};

interface SaleStat {
  refundAmount: number;
  saleAmount: number;
  transactionAmount: number;
}
interface DailyStat extends SaleStat {
  orderCount: number;
  signupCount: number;
}

interface GraphStat {
  date: string;
  value: number;
}

interface IDashboard {
  createdAt: string;
  dailyStats: {
    lastWeek: DailyStat;
    today: DailyStat;
  };
  graphStats: {
    orderCount: GraphStat[];
    saleAmount: GraphStat[];
    transactionAmount: GraphStat[];
    signupCount: GraphStat[];
  };
  saleStats: {
    recent7DayAverage: SaleStat;
    recent7DayTotal: SaleStat;
    recent30DayAverage: SaleStat;
    recent30DayTotal: SaleStat;
  };
}

interface State {
  tab: number;
}

const OrderDashboardPage = () => {
  const [{ tab }, setState] = useObject<State>({
    tab: 0,
  });
  const { data } = useSWR(
    '/commerce/order/dashboard',
    (url) =>
      request.get<any, IDashboard>(url, {
        headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
      }),
    { refreshInterval: 1000 * 60 * 60 }
  );
  const orderCount = useRecoilValue(orderCountState);
  const shippingCount = useRecoilValue(shippingCountState);
  const graphStat: Array<{ date: string; value: number }> = useMemo(() => {
    if (!data) return [];
    else if (tab === 0) return data.graphStats.transactionAmount;
    else if (tab === 1) return data.graphStats.saleAmount;
    else if (tab === 2) return data.graphStats.orderCount;
    else if (tab === 3) return data.graphStats.signupCount;
    return [];
  }, [tab, data]);
  const updatedAt: number = useMemo(() => new Date().getTime(), [orderCount]);
  return (
    <OrderCount>
      <ShippingCount>
        <div className="grid grid-cols-2 m-4 gap-4 relative">
          {!data && (
            <div className="absolute w-full h-full opacity-60 flex items-center justify-center bg-white">
              <Spinner className="h-10 w-10 text-black" />
            </div>
          )}
          <DashboardCard
            title={dayjs().format('M월 DD일 현황')}
            date={dayjs(data?.createdAt).format('HH:mm 업데이트')}
          >
            <div className="grid grid-rows-3 grid-flow-col gap-y-4 gap-x-10">
              <DashboardItem name="거래액">
                <DashboardDiff
                  to={data?.dailyStats.today.transactionAmount || 0}
                  from={data?.dailyStats.lastWeek.transactionAmount || 0}
                />
              </DashboardItem>
              <DashboardItem name="매출액">
                <DashboardDiff
                  to={data?.dailyStats.today.saleAmount || 0}
                  from={data?.dailyStats.lastWeek.saleAmount || 0}
                />
              </DashboardItem>
              <DashboardItem name="환불액">
                <DashboardDiff
                  to={data?.dailyStats.today.refundAmount || 0}
                  from={data?.dailyStats.lastWeek.refundAmount || 0}
                  reverse
                />
              </DashboardItem>
              <DashboardItem name="회원가입자수">
                <DashboardDiff
                  to={data?.dailyStats.today.signupCount || 0}
                  from={data?.dailyStats.lastWeek.signupCount || 0}
                  unit="명"
                />
              </DashboardItem>
              <DashboardItem name="주문건수">
                <DashboardDiff
                  to={data?.dailyStats.today.orderCount || 0}
                  from={data?.dailyStats.lastWeek.orderCount || 0}
                  unit="건"
                />
              </DashboardItem>
            </div>
            <div className="italic text-sm text-gray-400 mt-4">
              *빨강, 초록으로 표시된 숫자는 일주일 전 대비 증감을 나타내는 수치입니다.
            </div>
          </DashboardCard>

          <DashboardCard title="서비스 바로가기">
            <div className="space-y-4">
              <a
                href="https://sell.smartstore.naver.com/#/home/dashboard"
                className="block"
                target="_blank"
              >
                {'네이버스마트스토어 바로가기 >'}
              </a>
              <a
                href="https://center.shopping.naver.com/main"
                className="block"
                target="_blank"
              >
                {'네이버쇼핑 바로가기 >'}
              </a>
              <a
                href="https://analytics.google.com/analytics/web/#/report-home/a150062018w282973589p249180598"
                className="block"
                target="_blank"
              >
                {'구글 애널리틱스 바로가기 >'}
              </a>
              <a
                href="https://ads.google.com/aw/overview"
                className="block"
                target="_blank"
              >
                {'구글 애즈 바로가기 >'}
              </a>
            </div>
          </DashboardCard>

          <DashboardCard
            title="주문/배송"
            date={dayjs(updatedAt).format('HH:mm 업데이트')}
          >
            <div className="flex gap-10">
              <div className="flex-1 space-y-4">
                <DashboardItem name="입금전">
                  <DashboardLink to="/orders/before">
                    <NumberStat value={orderCount.beforeDeposit} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="신규주문">
                  <DashboardLink to="/orders/new">
                    <NumberStat value={orderCount.newOrder} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="발주대기">
                  <DashboardLink to="/orders/wait">
                    <NumberStat value={orderCount.waiting} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="선발주필요">
                  <DashboardLink to="/orders/pre">
                    <NumberStat value={orderCount.preRequired} unit="건" />
                  </DashboardLink>
                </DashboardItem>
              </div>
              <div className="flex-1 space-y-4">
                <DashboardItem name="주문완료">
                  <DashboardLink to="/orders/complete">
                    <NumberStat value={orderCount.complete} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="주문지연">
                  <DashboardLink to="/orders/delay">
                    <NumberStat value={orderCount.delay} unit="건" />
                  </DashboardLink>
                </DashboardItem>
              </div>
              <div className="flex-1 space-y-4">
                <DashboardItem name="배송시작">
                  <DashboardLink to="/orders/shipping?tab=1">
                    <NumberStat value={shippingCount.start} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="국내통관중">
                  <DashboardLink to="/orders/shipping?tab=3">
                    <NumberStat value={shippingCount.domesticCustoms} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="통관지연">
                  <DashboardLink to="/orders/shipping?tab=4">
                    <NumberStat value={shippingCount.customsDelay} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="국내배송중">
                  <DashboardLink to="/orders/shipping?tab=5">
                    <NumberStat value={shippingCount.inWaypoint} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="배송완료">
                  <DashboardLink to="/orders/shipping-complete">
                    <NumberStat value={orderCount.shippingComplete} unit="건" />
                  </DashboardLink>
                </DashboardItem>
              </div>
            </div>
          </DashboardCard>

          <DashboardCard title="요청" date={dayjs(updatedAt).format('HH:mm 업데이트')}>
            <div className="grid grid-cols-3 gap-x-10 gap-y-4">
              <div className="space-y-4">
                <DashboardItem name="취소">
                  <DashboardLink to="/orders/cancel">
                    <NumberStat value={orderCount.cancel} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="반품">
                  <DashboardLink to="/orders/return">
                    <NumberStat value={orderCount.return} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="교환">
                  <DashboardLink to="/orders/exchange">
                    <NumberStat value={orderCount.exchange} unit="건" />
                  </DashboardLink>
                </DashboardItem>
                <DashboardItem name="환불">
                  <DashboardLink to="/orders/refund">
                    <NumberStat value={orderCount.refund} unit="건" />
                  </DashboardLink>
                </DashboardItem>
              </div>
              <div></div>
            </div>
          </DashboardCard>

          <DashboardCard
            title="매출통계"
            date={dayjs(data?.createdAt).format('HH:mm 업데이트')}
            extra={
              <div className="flex gap-5 text-sm">
                <span
                  className={classnames('cursor-pointer', { 'text-gray-400': tab !== 0 })}
                  onClick={() => setState({ tab: 0 })}
                >
                  거래액
                </span>
                <span
                  className={classnames('cursor-pointer', { 'text-gray-400': tab !== 1 })}
                  onClick={() => setState({ tab: 1 })}
                >
                  매출액
                </span>
                <span
                  className={classnames('cursor-pointer', { 'text-gray-400': tab !== 2 })}
                  onClick={() => setState({ tab: 2 })}
                >
                  주문건수
                </span>
                <span
                  className={classnames('cursor-pointer', { 'text-gray-400': tab !== 3 })}
                  onClick={() => setState({ tab: 3 })}
                >
                  회원가입자수
                </span>
              </div>
            }
          >
            <Bar
              options={{
                plugins: { legend: { display: false }, title: { display: false } },
              }}
              data={{
                labels: graphStat.map((item) => item.date),
                datasets: [
                  {
                    data: graphStat.map((item) => item.value),
                    backgroundColor: 'rgba(0,0,0,0.6)',
                  },
                ],
              }}
            />
          </DashboardCard>

          <DashboardCard
            title="매출통계"
            date={dayjs(data?.createdAt).format('HH:mm 업데이트')}
          >
            <table className="w-full text-center">
              <thead>
                <tr>
                  <th></th>
                  <th>거래액</th>
                  <th>매출액</th>
                  <th>환불액</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-2">최근 7일 평균</td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent7DayAverage.transactionAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent7DayAverage.saleAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent7DayAverage.refundAmount)} 원
                  </td>
                </tr>
                <tr>
                  <td className="p-2">최근 7일 합계</td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent7DayTotal.transactionAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent7DayTotal.saleAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent7DayTotal.refundAmount)} 원
                  </td>
                </tr>
                <tr>
                  <td className="p-2">최근 30일 평균</td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent30DayAverage.transactionAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent30DayAverage.saleAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent30DayAverage.refundAmount)} 원
                  </td>
                </tr>
                <tr>
                  <td className="p-2">최근 30일 합계</td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent30DayTotal.transactionAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent30DayTotal.saleAmount)} 원
                  </td>
                  <td className="p-2">
                    {priceFormat(data?.saleStats.recent30DayTotal.refundAmount)} 원
                  </td>
                </tr>
              </tbody>
            </table>
          </DashboardCard>
        </div>
      </ShippingCount>
    </OrderCount>
  );
};

export default OrderDashboardPage;
