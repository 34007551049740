import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 40px;
`;

export const Wrapper = styled.div`
  width: 100%;
  @media all and (min-width: 1200px) {
    display: flex;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  @media all and (min-width: 1200px) {
    width: 40%;
    padding: 30px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  @media all and (min-width: 1200px) {
    width: 60%;
    padding: 30px;
    margin-top: 0px;
  }
`;

export const Select = styled.select`
  padding: 10px 15px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;

export const OptionBox = styled.div`
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  height: 200px;
  margin-top: 40px;
  overflow-y: auto;
  @media all and (min-width: 1200px) {
    height: 400px;
  }
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;

export const Textarea = styled.textarea`
  width: 100%;
  height: 300px;
  padding: 20px 30px;
  border: 1px solid #ddd;
  border-radius: 4px;
  &:focus {
    outline: none;
  }
  @media all and (min-width: 1200px) {
    height: 500px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px;
`;

export const Button = styled.button`
  width: 100px;
  height: 40px;
  text-decoration: none;
  border: none;
  padding: 10px 16px;
  border-radius: 8px;
  background-color: #ee6d66;
  color: #fff;
  cursor: pointer;
  font-family: 'Haas Grot Text R Web', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  &:focus {
    outline: none;
  }
`;
