import styled from 'styled-components';

export const Outer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 100px 0;
`;

export const FormOuter = styled.div`
  display: flex;
  justify-content: center;
`;

export const FormBox = styled.div`
  margin-top: 40px;
  width: 70%;
`;

export const TextAlign = {
  textAlign: 'center',
};

export const BoxStyle = {
  display: 'flex',
  justifyContent: 'space-around',
};

export const ItemWidth = {
  width: '40%',
};
