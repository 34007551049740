import React from 'react';
import { FiXCircle } from 'react-icons/fi';
import * as style from './style';

export default function Item({ id, name, handleDelete, url }) {
  return (
    <style.Wrapper>
      <style.Text>{name}</style.Text>
      {url && (
        <style.Link onClick={() => window.open(url)}>이동하기</style.Link>
      )}
      <style.Button onClick={() => handleDelete(id)}>
        <FiXCircle />
      </style.Button>
    </style.Wrapper>
  );
}
