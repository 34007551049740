import * as type from '../actions/types';

const initialState = {
  options: [],
  searchOption: {
    title: '',
    isActive: 'all',
    updateType: 'all',
    isVIP: 'all',
    appliedAt: 'all',
    shopId: 'all',
    discountType: 'all',
    assigneeId: 'all',
    startAt: '',
    endAt: '',
    applyStatus: 'all',
    displayedAt: 'all',
    isPrioritized: 'all',
  },
};

const promotion = (state = initialState, action) => {
  switch (action.type) {
    case type.ADD_PROMOTION_OPTION:
      return {
        ...state,
        options: state.options
          .filter((el) => el.id !== action.options.id)
          .concat([action.options]),
      };
    case type.DELETE_PROMOTION_OPTION:
      return {
        ...state,
        options: state.options.filter((el) => el.id !== action.options),
      };
    case type.ALL_DELETE_PROMOTION_OPTION:
      return {
        ...state,
        options: action.options,
      };
    case type.UPDATE_PROMOTION_SEARCH_OPTION:
      return {
        ...state,
        searchOption: {
          ...state.searchOption,
          [action.key]: action.value,
        },
      };
    case type.INIT_PROMOTION_SEARCH_OPTION:
      return {
        ...state,
        searchOption: {
          ...initialState.searchOption,
        },
      };
    default:
      return { ...initialState, ...state };
  }
};

export default promotion;
