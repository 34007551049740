import React from 'react';
import * as style from './style';

const SearchButtonBox = ({ handleSearch }) => {
  return (
    <style.ButtonWrapper>
      <style.Button color={'#3d3737'} onClick={handleSearch}>
        검색
      </style.Button>
      <style.Button onClick={() => window.history.go(0)}>초기화</style.Button>
    </style.ButtonWrapper>
  );
};
export default SearchButtonBox;
