export enum SearchType {
  SHOP_NAME = 'SHOP_NAME',
  CONTACT = 'CONTACT',
  TAXPAYER_ID_NUMBER = 'TAXPAYER_ID_NUMBER',
}

export enum StatusList {
  REQUEST = 'REQUEST',
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT',
}

export enum FromToType {
  REQUEST_DATE = 'REQUEST_DATE',
  PROCESS_DATE = 'PROCESS_DATE',
}

export type Shop = {
  acceptItemCount: number;
  // number
  // 승인 상품 수
  directPaySettleMethod: string;
  // string
  // Enum: "CPC" "CPS"
  // 직접결제 정책
  fetchingPaySettleMethod: string;

  // string
  // Enum: "CPC" "CPS"
  // 페칭결제 정책

  hostingType: string;

  // string
  // Enum: "CAFE24" "SMART_STORE" "ETC"
  // 호스팅타입

  isDirectPaySupport: boolean;

  // boolean
  // 직접결제 지원여부

  isFetchingPaySupport: boolean;

  // boolean
  // 페칭결제 지원여부

  itemCount: number;

  // number
  // 등록 상품 수

  lastRequestDate: string;

  // string
  // 요청일

  lastUpdateDate: string;

  // string
  // 처리일

  partnerId: string;

  // string
  // 파트너 아이디

  partnerScore: number;

  // number
  // 파트너 점수 (미사용)

  partnerShopId: number;

  // number
  // 샵 ID

  rejectItemCount: number;

  // number
  // 반려 상품 수

  requestItemCount: number;

  // number
  // 승인 대기 상품 수

  shopName: string;

  // string
  // 샵 이름

  shopUrl: string;

  // string
  // 샵 URL

  updateUserName: string;

  // string
  // 수정 담당자 이름
};
