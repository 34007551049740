// @ts-nocheck
import fetchingAPI from 'api/fetchingAPI';
import { PickIdModal, Table } from 'components';
import { MakeTable } from 'components/UI/atoms/Table';
import { triggerState } from 'pages/ManageMainContent';
import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';
import * as S from '../style';
const dict: any = {
  expireAt: '게시 마감 일시',
  startAt: '게시 시작 일시',
  backgroundColor: '타이틀',
  content: '타이틀',
  href: '링크',
};
type TextBandBanner = {
  expireAt?: string | null;
  startAt?: string | null;
  backgroundColor: string | null;
  content: string | null;
  href: string | null;
};
type Props = {
  mode: 'm' | 'w';
  layout: TextBandBanner;
};
const ManageTextBandBanner = ({ mode, layout }: Props) => {
  const history = useHistory();
  const layoutIdx = Number(history.location.pathname.split('/').pop());
  const setTrigger = useSetRecoilState(triggerState);
  const [finite, setFinite] = useState(true);
  const [data, setData] = useState<TextBandBanner>({
    expireAt: '',
    startAt: '',
    backgroundColor: '',
    content: '',
    href: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const initData = () => {
    const { content, backgroundColor, href, startAt, expireAt } = layout;
    setData({
      content,
      backgroundColor,
      href,
      startAt,
      expireAt,
    });
    !startAt && !expireAt ? setFinite(false) : setFinite(true);
  };
  const saveChanges = () => {
    const emptyFields = Object.entries(data)
      .filter(([k, v]) =>
        (k === 'startAt' || k === 'expireAt') && !finite ? false : v === '' || v === null
      )
      .reduce((acc: string[], [k]) => {
        acc.push(dict[k]);
        return acc;
      }, []);

    if (emptyFields.length) {
      alert(`${emptyFields.join(`\n`)}\n\n위 항목을 입력해주세요!`);
    } else {
      const gender = mode === 'm' ? 'men' : 'women';
      setIsLoading(false);
      fetchingAPI
        .postUIComponents(gender, [
          {
            idx: layoutIdx,
            requestType: 'UPDATE',
            ...data,
          },
        ])
        .then(({ data }) => {
          data?.success && alert('수정에 성공했습니다.');
          setTrigger((p) => p + 1);
        })
        .catch((e) => alert(`err in addCopmonent, ${e}`))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => initData(), []);

  const tables = [
    {
      title: '상세 설정',
      content: [
        {
          left: '게시 기간 설정',
          right: (
            <>
              <Table.Label>
                <Table.Radio
                  checked={!finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                영구
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                설정
              </Table.Label>
            </>
          ),
        },
        {
          left: '게시 일시',
          right: (
            <>
              {finite && (
                <>
                  <Table.InputTime
                    value={data.startAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, startAt: value }))
                    }
                  />
                  {' ~ '}
                  <Table.InputTime
                    style={{ marginLeft: '10px' }}
                    value={data.expireAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, expireAt: value }))
                    }
                  />
                </>
              )}
            </>
          ),
        },
        {
          left: '배경 컬러',
          right: (
            <>
              <Table.TextArea
                placeholder="컬러값은 HEX코드로 입력해주세요. 예) #000000"
                value={data.backgroundColor ?? ''}
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, backgroundColor: value }))
                }
              />
            </>
          ),
        },
        {
          left: '타이틀',
          right: (
            <>
              <Table.TextArea
                placeholder="최대 한글 30글자 으로 줄넘김 가능"
                value={data.content ?? ''}
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, content: value }))
                }
              />
            </>
          ),
        },
        {
          left: '링크',
          right: (
            <>
              <Table.TextArea
                value={data.href ?? ''}
                placeholder="/index.html 형식으로 fetching.co.kr를 제외해주세요"
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, href: value }))
                }
              />
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <S.ButtonContainer>
        <Button variant="warning" onClick={initData}>
          초기화
        </Button>{' '}
        <Button onClick={saveChanges}>
          {isLoading ? <Spinner animation="border" /> : '저장하기'}
        </Button>
      </S.ButtonContainer>
      <MakeTable data={tables[0]} tableRef={null} />
    </>
  );
};

export default ManageTextBandBanner;
