import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { selectExhibitionAction } from '../../../actions/action';
import * as style from './style';

const Table = ({ item, checkedItems, handleCheckItems, history }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const result = checkedItems.find((el) => el.id === item.id);
    if (result) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [checkedItems]);

  const handleClickTableItem = (e) => {
    if (e.target === e.currentTarget) {
      dispatch(selectExhibitionAction(item));
      history.push('/product/modify_exhibition_detail/' + item.id);
    }
  };

  return (
    <style.Outer>
      <style.Table>
        <style.TableItem width="5">
          <style.CheckBox
            checked={isChecked}
            value={item.id}
            onChange={(e) => {
              handleCheckItems(e, item);
            }}
          />
        </style.TableItem>
        <style.TableItem width="5">
          {item.gender === 'M' ? '남성' : '여성'}
        </style.TableItem>
        <style.TableItem width="10" onClick={handleClickTableItem}>
          {item.name}
        </style.TableItem>
        <style.TableItem
          width="10"
          isActive={item.isActive ? '활성화' : '비활성화'}
        >
          {item.isActive === 1 ? '활성화' : '비활성화'}
        </style.TableItem>
        <style.TableItem width="20">
          <style.Box>
            <style.Div>
              {item.startedAt && item.startedAt.slice(0, 10)}
            </style.Div>
            <style.Div>{item.endedAt && item.endedAt.slice(0, 10)}</style.Div>
            <style.Div>
              {!item.endedAt && !item.startedAt && '무제한'}
            </style.Div>
          </style.Box>
        </style.TableItem>
        <style.TableItem width="20">
          <style.Box>
            {item.isVisibleToAppCategory === 1 && (
              <style.Div>앱 {' > '} 카테고리</style.Div>
            )}
            {item.isVisibleToAppMain === 1 && (
              <style.Div>앱 {' > '} 메인</style.Div>
            )}
            {item.isVisibleToCafe24 === 1 && <style.Div>카페24</style.Div>}
          </style.Box>
        </style.TableItem>
        <style.TableItem width="30">
          <img src={item.bannerImageUrl} alt="배너 이미지" height="220px" />
        </style.TableItem>
      </style.Table>
    </style.Outer>
  );
};

export default withRouter(Table);
