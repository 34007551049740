import {
  Button,
  Card,
  GridTable,
  MenuTabs,
  Radio,
  ReInput,
  RePagination,
  UserMessageModal,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { dayjsFormat, request, useObject } from 'services';

interface IUserMessage {
  messageType: 'SMS' | 'MMS';
  sendAt: string;
  sentAt: string;
  content: string;
  title: string;
  totalCount: number;
  waitingCount: number;
  successCount: number;
  failedCount: number;
  id: number;
}

interface State {
  page: number;
  total: number;
  list: IUserMessage[];
  isOpen: boolean;
  isLoading: boolean;
  minimumDate: string;
  maximumDate: string;
  status: null | -1 | 0 | 1;
  targets: string;
  messageId: number;
}

const UsersMessagePage = () => {
  const [
    {
      page,
      total,
      list,
      isOpen,
      isLoading,
      minimumDate,
      maximumDate,
      status,
      targets,
      messageId,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    page: 1,
    total: 0,
    list: [],
    isOpen: false,
    isLoading: false,
    minimumDate: dayjsFormat(dayjs().add(-7, 'day')),
    maximumDate: dayjsFormat(dayjs()),
    status: null,
    targets: '',
    messageId: 0,
  });

  const get = async () => {
    setState({ isLoading: true });
    try {
      const { data, count } = await request.get<
        any,
        { count: number; data: IUserMessage[] }
      >('/commerce/user/messages', {
        params: {
          minimumDate: `${dayjs(minimumDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
          maximumDate: `${dayjs(maximumDate).format('YYYY-MM-DDT23:59:59')}.000Z`,
          status,
          targets,
          limit: 100,
          page,
        },
      });
      setState({ list: data, total: count, isLoading: false });
    } catch (err) {
      console.log(err);
      setState({ isLoading: false, list: [] });
    }
  };

  const onCancelSubmit = async (index: number) => {
    const item = list[index];
    if (!item.sendAt) {
      toast.info('발송예약시간이 있을 경우에만 전송이 가능합니다.');
      return;
    }
    if (!window.confirm('예약 메시지 전송을 취소하시겠습니까?')) return;

    try {
      await request.delete(`/commerce/user/message/${item.id}`);
      toast.success('전송이 취소되었습니다.');
      setState({
        list: [
          ...list.slice(0, index),
          { ...item, sendAt: '' },
          ...list.slice(index + 1),
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onDateClick = (date: string) => {
    let newMinDate = '';
    if (date === '오늘') newMinDate = dayjsFormat(dayjs());
    else if (date === '3일') newMinDate = dayjsFormat(dayjs().add(-3, 'day'));
    else if (date === '7일') newMinDate = dayjsFormat(dayjs().add(-7, 'day'));
    else if (date === '1개월') newMinDate = dayjsFormat(dayjs().add(-1, 'month'));
    else if (date === '3개월') newMinDate = dayjsFormat(dayjs().add(-3, 'month'));
    else if (date === '1년') newMinDate = dayjsFormat(dayjs().add(-1, 'year'));

    setState({ minimumDate: newMinDate, maximumDate: dayjsFormat(dayjs()) });
  };

  useEffect(() => {
    get();
  }, [status, minimumDate, maximumDate, page]);
  return (
    <>
      <MenuTabs
        options={[
          { name: '메시지 발송', to: '/users/send-message' },
          { name: '메시지 발송 내역', to: '/users/message' },
        ]}
      />

      <div className="wrapper">
        <Card
          items={[
            {
              name: '전송결과',
              renderItem: () => (
                <Radio
                  id="status"
                  onChange={(status) => setState({ status })}
                  options={[
                    { name: '전체', value: null },
                    { name: '대기', value: 0 },
                    { name: '성공', value: 1 },
                    { name: '실패', value: -1 },
                  ]}
                  value={status}
                />
              ),
              stretch: true,
            },
            {
              name: '수신번호',
              renderItem: () => (
                <ReInput
                  size="xs"
                  className="w-72"
                  placeholder="직접 검색 시 입력해주세요. (콤마로 다중 검색 가능)"
                  value={targets}
                  name="targets"
                  onChange={onChange}
                />
              ),
              stretch: true,
            },
            {
              name: '기간',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {['오늘', '3일', '7일', '1개월', '3개월', '1년'].map((item, key) => (
                    <Button
                      theme="ghost"
                      size="xs"
                      onClick={() => onDateClick(item)}
                      key={key}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={minimumDate}
                    name="minimumDate"
                    onChange={onChange}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={maximumDate}
                    name="maximumDate"
                    onChange={onChange}
                  />
                </div>
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-5 flex justify-center gap-3">
          <Button isLoading={isLoading} onClick={get}>
            검색
          </Button>
          <Button
            theme="secondary"
            onClick={() =>
              resetState(['maximumDate', 'minimumDate', 'status', 'targets', 'page'])
            }
          >
            초기화
          </Button>
        </div>
      </div>

      <div className="wrapper">
        <GridTable
          columns={[
            '아이디',
            '메시지타입',
            '발송예약시간',
            '발송시간',
            '메시지',
            '발송건수',
            '대기',
            '성공',
            '실패',
            '관리',
          ]}
          noSelection
          maxHeight=""
          list={list}
          renderItem={(item, key) => (
            <div key={key} onClick={() => setState({ isOpen: true, messageId: item.id })}>
              <div>{item.id}</div>
              <div>{item.messageType}</div>
              <div>
                {item.sendAt ? dayjs(item.sendAt).format('YYYY. MM. DD. HH:mm:ss') : ''}
              </div>
              <div>
                {item.sentAt ? dayjs(item.sentAt).format('YYYY. MM. DD. HH:mm:ss') : ''}
              </div>
              <div>
                {!!item.title && <div className="mb-2 text-lg">{item.title}</div>}
                <div>
                  {item.content.split('\n').map((content, key) => (
                    <div key={key} className="max-w-lg whitespace-normal">
                      {content}
                    </div>
                  ))}
                </div>
              </div>
              <div>{item.totalCount}</div>
              <div>{item.waitingCount}</div>
              <div>{item.successCount}</div>
              <div>{item.failedCount}</div>
              <div>
                <Button
                  size="xs"
                  theme="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onCancelSubmit(key);
                  }}
                >
                  발송취소
                </Button>
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center mt-2">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>건</span>
          </div>
          <RePagination
            pageSize={100}
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={total}
          />
        </div>
      </div>

      <UserMessageModal
        title="발송 결과"
        isOpen={isOpen}
        onClose={() => setState({ isOpen: false, messageId: 0 })}
        id={messageId}
      />
    </>
  );
};

export default UsersMessagePage;
