import {
  Button,
  Card,
  UserProfileAddress,
  ReInput,
  Select,
  Textarea,
  GridTable,
} from 'components';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { request, useObject } from 'services';
import dayjs from 'dayjs';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { CheckIcon } from '@heroicons/react/solid';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { addressListState } from 'store';

export interface Props {}
interface State {
  adminMemos: any[];
  content: string;
  deviceId: string;
  createdAt: string;
  id: string;
  profileImage: string;
  name: string;
  phoneNumber: string;
  englishFirstName: string;
  englishLastName: string;
  birthDate: string;
  type: 'USER' | 'VIP' | string;
  customsClearanceNumber: string;
  CUSTOMS_CLEARANCE_NUMBER: string;
  gender: string;
  emailAllowed: boolean;
  marketingPushAllowed: boolean;
  nightPushAllowed: boolean;
  pushAllowed: boolean;
  smsAllowed: boolean;
  likedBrands: string[];
  likedItems: number[];
  likedShops: string[];
  email: string;
  password: string;
  loginType: 'FETCHING' | 'KAKAO' | string;
  signupType: 'FETCHING' | 'KAKAO' | string;
  personalInfoCollectAllow: boolean;
  shoppingMallTermAllow: boolean;
  isPhoneChecked: boolean;
  isEmailChecked: boolean;
  EMAIL: string;
  PHONE_NUMBER: string;
  appInstall: boolean;
  firstUtmCampaign: string;
  firstUtmSource: string;
  signupUtmCampaign: string;
  signupUtmSource: string;
  isPhoneChecking: boolean;
  isEmailChecking: boolean;
  isMemoUpdating: boolean;
  isMemoRemoving: boolean;
  isMemoCreating: boolean;
  isUpdating: boolean;
  memoIdx: number;
  kakaoId: string;
  appleId: string;
  naverId: string;
}

const UserProfile: FunctionComponent<Props> = () => {
  const [
    {
      adminMemos,
      content,
      deviceId,
      createdAt,
      id,
      profileImage,
      name,
      phoneNumber,
      birthDate,
      englishLastName,
      englishFirstName,
      type,
      customsClearanceNumber,
      gender,
      smsAllowed,
      pushAllowed,
      emailAllowed,
      nightPushAllowed,
      marketingPushAllowed,
      likedShops,
      likedItems,
      likedBrands,
      email,
      password,
      loginType,
      signupType,
      shoppingMallTermAllow,
      personalInfoCollectAllow,
      isPhoneChecked,
      isEmailChecked,
      EMAIL,
      PHONE_NUMBER,
      CUSTOMS_CLEARANCE_NUMBER,
      appInstall,
      firstUtmSource,
      firstUtmCampaign,
      signupUtmSource,
      signupUtmCampaign,
      isUpdating,
      isMemoUpdating,
      isMemoRemoving,
      isPhoneChecking,
      isEmailChecking,
      isMemoCreating,
      memoIdx,
      kakaoId,
      naverId,
      appleId,
    },
    setState,
    onChange,
  ] = useObject<State>({
    adminMemos: [],
    content: '',
    deviceId: '',
    createdAt: '',
    id: '',
    profileImage: '',
    name: '',
    phoneNumber: '',
    birthDate: '',
    englishFirstName: '',
    englishLastName: '',
    type: '',
    customsClearanceNumber: '',
    gender: '',
    smsAllowed: false,
    pushAllowed: false,
    emailAllowed: false,
    nightPushAllowed: false,
    marketingPushAllowed: false,
    likedBrands: [],
    likedItems: [],
    likedShops: [],
    email: '',
    password: '',
    loginType: '',
    signupType: '',
    shoppingMallTermAllow: false,
    personalInfoCollectAllow: false,
    isPhoneChecked: true,
    isEmailChecked: true,
    EMAIL: '',
    PHONE_NUMBER: '',
    CUSTOMS_CLEARANCE_NUMBER: '',
    appInstall: false,
    firstUtmCampaign: '',
    firstUtmSource: '',
    signupUtmCampaign: '',
    signupUtmSource: '',
    isEmailChecking: false,
    isPhoneChecking: false,
    isMemoRemoving: false,
    isMemoUpdating: false,
    isMemoCreating: false,
    isUpdating: false,
    memoIdx: 0,
    kakaoId: '',
    naverId: '',
    appleId: '',
  });
  const { idx } = useParams<{ idx: string }>();
  const addressList = useRecoilValue(addressListState);
  const setAddressList = useSetRecoilState(addressListState);
  const resetAddressList = useResetRecoilState(addressListState);

  const get = async () => {
    try {
      const data = await request.get<any, any>(`/commerce/user/${idx}`);
      setState({
        deviceId: data.deviceId || '',
        createdAt: data.createdAt
          ? dayjs(data.createdAt).format('YYYY-MM-DD HH:mm:ss')
          : '',
        id: data.id,
        email: data.id,
        EMAIL: data.id,
        profileImage: data.profileImage || '',
        name: data.name || '',
        phoneNumber: data.phoneNumber || '',
        PHONE_NUMBER: data.phoneNumber || '',
        birthDate: data.birthDate ? dayjs(data.birthDate).format('YYYY-MM-DD') : '',
        customsClearanceNumber: data.customsClearanceNumber || '',
        CUSTOMS_CLEARANCE_NUMBER: data.customsClearanceNumber || '',
        type: data.type || '',
        gender: data.gender,
        emailAllowed: !!data.emailAllowed,
        smsAllowed: !!data.smsAllowed,
        pushAllowed: !!data.pushAllowed,
        nightPushAllowed: !!data.nightPushAllowed,
        marketingPushAllowed: !!data.marketingPushAllowed,
        likedBrands: Array.isArray(data.likedBrands) ? data.likedBrands : [],
        likedItems: Array.isArray(data.likedItems) ? data.likedItems : [],
        likedShops: Array.isArray(data.likedShops) ? data.likedShops : [],
        englishFirstName: data.englishFirstName || '',
        englishLastName: data.englishLastName || '',
        loginType: data.loginType || '',
        signupType: data.signupType || '',
        shoppingMallTermAllow: !!data.shoppingMallTermAllow,
        personalInfoCollectAllow: !!data.personalInfoCollectAllow,
        appInstall: data.signupInfo ? data.signupInfo.appInstall : false,
        firstUtmCampaign: data.signupInfo ? data.signupInfo.firstUtmCampaign : '',
        firstUtmSource: data.signupInfo ? data.signupInfo.firstUtmSource : '',
        signupUtmCampaign: data.signupInfo ? data.signupInfo.signupUtmCampaign : '',
        signupUtmSource: data.signupInfo ? data.signupInfo.signupUtmSource : '',
        naverId: data.auId ? data.auId.NAVER : '',
        kakaoId: data.auId ? data.auId.KAKAO : '',
        appleId: data.auId ? data.auId.APPLE : '',
      });
      setAddressList(
        Array.isArray(data.addresses)
          ? data.addresses.map((item) => ({ ...item, requestType: 'UPDATE' }))
          : []
      );
    } catch (err) {
      console.log(err);
    }
  };
  const update = async () => {
    if (!window.confirm('수정하시겠습니까?')) return;
    if (!isPhoneChecked && phoneNumber !== PHONE_NUMBER) {
      toast.info('전화번호 중복 확인을 해주세요.');
      return;
    }
    if (!isEmailChecked && EMAIL !== email) {
      toast.info('이메일 중복 확인을 해주세요.');
      return;
    }
    if (!isCustomsClearanceNumberChecked) {
      toast.info("개인통관고유부호는 앞자리 'p' 를 포함하여 14자리입니다.");
      return;
    }
    if (addressList.filter((item) => item.isDefault).length > 1) {
      toast.info('기본 배송지는 1개만 가능합니다.');
      return;
    }

    setState({ isUpdating: true });
    let payload: any = {
      addresses: addressList,
      customsClearanceNumber,
      emailAllowed,
      marketingPushAllowed,
      nightPushAllowed,
      pushAllowed,
      smsAllowed,
      birthDate,
      email,
      englishFirstName,
      englishLastName,
      name,
      phoneNumber,
      userType: type,
      gender,
      personalInfoCollectAllow,
      shoppingMallTermAllow,
    };
    if (password) payload.password = password;
    try {
      await request.put(`/commerce/user/${idx}`, payload);
      toast.success('수정되었습니다.');
    } catch (err) {
      console.dir(err);
    } finally {
      setState({ isUpdating: false });
    }
  };
  const getMemo = async () => {
    try {
      const { data } = await request.get<any, any>(`/commerce/user/${idx}/memo`);
      setState({ adminMemos: Array.isArray(data) ? data : [] });
    } catch (err) {
      console.log(err);
    }
  };
  const updateMemo = async (index: number) => {
    if (!window.confirm('수정하시겠습니까?')) return;
    const item = adminMemos[index];
    setState({ isMemoUpdating: true, memoIdx: item.idx });
    try {
      await request.put(`/commerce/user/${item.userId}/memo/${item.idx}`, {
        content: item.content,
      });
      toast.success('수정되었습니다.');
      getMemo();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isMemoUpdating: false, memoIdx: 0 });
    }
  };
  const deleteMemo = async (index: number) => {
    if (!window.confirm('삭제하시겠습니까?')) return;
    const item = adminMemos[index];

    setState({ isMemoRemoving: true, memoIdx: item.idx });
    try {
      await request.delete(`/commerce/user/${item.userId}/memo/${item.idx}`);
      toast.success('삭제되었습니다.');
      getMemo();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isMemoRemoving: false, memoIdx: 0 });
    }
  };
  const createMemo = async () => {
    if (!content) {
      toast.info('내용을 입력해주세요.');
      return;
    }

    setState({ isMemoCreating: true });
    try {
      await request.post(`/commerce/user/${idx}/memo`, { content });
      toast.success('등록되었습니다.');
      getMemo();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isMemoCreating: false });
    }
  };
  const onCheckEmail = async () => {
    setState({ isEmailChecking: true });
    try {
      const data = await request.get<any, { duplicated: boolean }>(
        `/commerce/user/id/check-duplicate`,
        {
          params: { id: email },
        }
      );
      if (data.duplicated) toast.info('중복된 이메일입니다.');
      else toast.success('중복되지 않는 이메일입니다.');
      setState({ isEmailChecked: !data.duplicated });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isEmailChecking: false });
    }
  };
  const onCheckPhone = async () => {
    if (phoneNumber.length !== 11) {
      toast.error("11자리의 번호로 '-' 없이 입력해 주세요.");
      return;
    }
    if (isNaN(Number(phoneNumber))) {
      toast.error('숫자로 입력해주세요.');
      return;
    }

    setState({ isPhoneChecking: true });
    try {
      const data = await request.get<any, { duplicated: boolean }>(
        `/commerce/user/phone/check-duplicate`,
        {
          params: { phoneNumber },
        }
      );
      if (data.duplicated) toast.info('중복된 번호입니다.');
      else toast.success('중복되지 않는 번호입니다.');
      setState({ isPhoneChecked: !data.duplicated });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isPhoneChecking: false });
    }
  };
  const isCustomsClearanceNumberChecked = useMemo(() => {
    let checked = true;
    if (!CUSTOMS_CLEARANCE_NUMBER && !customsClearanceNumber) return checked;
    else if (
      (!CUSTOMS_CLEARANCE_NUMBER && !!customsClearanceNumber) ||
      !!CUSTOMS_CLEARANCE_NUMBER
    ) {
      if (!customsClearanceNumber.length) checked = false;
      else if (customsClearanceNumber[0].toLowerCase() !== 'p') checked = false;
      else if (customsClearanceNumber.length !== 13) checked = false;
    }

    return checked;
  }, [customsClearanceNumber]);
  useEffect(() => {
    get();
    getMemo();
    return () => {
      resetAddressList();
    };
  }, []);
  return (
    <>
      <div className="p-4">
        <Card
          title="가입정보"
          items={[
            {
              name: '회원번호',
              renderItem: () => idx,
            },
            {
              name: '디바이스 ID',
              renderItem: () => deviceId,
            },
            {
              name: '가입정보',
              renderItem: () => (
                <div className="text-xs">
                  <div>
                    <span className="mr-1">
                      채널을 통해{' '}
                      <>
                        {signupType === 'FETCHING' && '페칭'}
                        {signupType === 'KAKAO' && '카카오'}
                      </>{' '}
                      가입
                    </span>
                    <span className="text-gray-400">({createdAt})</span>
                  </div>
                  <div>앱 설치여부 : {appInstall ? 'Y' : 'N'}</div>
                </div>
              ),
            },
            {
              name: '유입정보',
              renderItem: () => (
                <div className="text-xs">
                  <div>
                    최초: {firstUtmSource}의 {firstUtmCampaign}
                  </div>
                  <div>
                    가입직전: {signupUtmSource}의 {signupUtmCampaign}
                  </div>
                </div>
              ),
            },
          ]}
        />
        <Card
          title="회원정보"
          items={[
            {
              name: '아이디',
              renderItem: () => (
                <div className="text-xs">
                  <div>{id}</div>
                  {!!kakaoId && <div>Kakao: {kakaoId}</div>}
                  {!!naverId && <div>Naver: {naverId}</div>}
                  {!!appleId && <div>Apple: {appleId}</div>}
                </div>
              ),
            },
            {
              name: '프로필사진',
              renderItem: () => (
                <img
                  src={profileImage}
                  alt="profile"
                  className="w-14 h-14 rounded-full object-cover"
                />
              ),
            },
            {
              name: '이름',
              renderItem: () => (
                <ReInput
                  size="xs"
                  className="w-32"
                  value={name}
                  name="name"
                  onChange={onChange}
                />
              ),
            },
            {
              name: '전화번호',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <ReInput
                    className="w-40"
                    placeholder="01012345678"
                    value={phoneNumber}
                    size="xs"
                    name="phoneNumber"
                    onChange={(e) =>
                      setState({ phoneNumber: e.target.value, isPhoneChecked: false })
                    }
                  />
                  <Button isLoading={isPhoneChecking} size="xs" onClick={onCheckPhone}>
                    중복 확인
                  </Button>
                  {isPhoneChecked && (
                    <CheckIcon className="w-6 h-6 ml-1 text-green-400" />
                  )}
                </div>
              ),
            },
            {
              name: '영문성',
              renderItem: () => (
                <ReInput
                  className="w-20"
                  size="xs"
                  value={englishFirstName}
                  name="englishFirstName"
                  onChange={onChange}
                />
              ),
            },
            {
              name: '이메일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <ReInput
                    size="xs"
                    className="w-40"
                    value={email}
                    name="email"
                    onChange={(e) =>
                      setState({ email: e.target.value, isEmailChecked: false })
                    }
                  />
                  <Button isLoading={isEmailChecking} size="xs" onClick={onCheckEmail}>
                    중복 확인
                  </Button>
                  {isEmailChecked && (
                    <CheckIcon className="w-6 h-6 ml-1 text-green-400" />
                  )}
                </div>
              ),
            },
            {
              name: '영문이름',
              renderItem: () => (
                <ReInput
                  className="w-40"
                  size="xs"
                  value={englishLastName}
                  name="englishLastName"
                  onChange={onChange}
                />
              ),
            },
            {
              name: '비밀번호',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <ReInput size="xs" className="w-40" value={password} readOnly />
                  <Button
                    size="xs"
                    onClick={() =>
                      setState({ password: Math.random().toString(36).slice(2) })
                    }
                  >
                    임시 비밀번호 발급
                  </Button>
                </div>
              ),
            },
            {
              name: '생년월일',
              renderItem: () => (
                <ReInput
                  value={birthDate}
                  className="w-40"
                  size="xs"
                  type="date"
                  name="birthDate"
                  onChange={onChange}
                />
              ),
            },
            {
              name: '성별',
              renderItem: () => (
                <Select
                  value={gender}
                  className="w-20"
                  name="gender"
                  onChange={onChange}
                  size="xs"
                >
                  <option value="" disabled selected>
                    전체
                  </option>
                  <option value="M">남성</option>
                  <option value="W">여성</option>
                  <option value="ETC">그 외</option>
                </Select>
              ),
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Select
                  size="xs"
                  className="w-20"
                  value={type}
                  name="type"
                  onChange={onChange}
                >
                  <option value="USER">일반</option>
                  <option value="VIP">VIP</option>
                </Select>
              ),
            },
            {
              name: '개인통관고유부호',
              renderItem: () => (
                <div className="flex items-center">
                  <ReInput
                    className="w-40"
                    size="xs"
                    value={customsClearanceNumber}
                    name="customsClearanceNumber"
                    onChange={onChange}
                  />
                  {isCustomsClearanceNumberChecked && (
                    <CheckIcon className="w-6 h-6 ml-1 text-green-400" />
                  )}
                </div>
              ),
            },
            {
              name: '주소지 목록',
              renderItem: () => <UserProfileAddress />,
              stretch: true,
            },
          ]}
        />
        <Card
          title="수신동의"
          items={[
            {
              name: '개인정보 수집 및 이용동의',
              renderItem: () => (
                <Switch
                  checked={personalInfoCollectAllow}
                  onChange={(personalInfoCollectAllow) =>
                    setState({ personalInfoCollectAllow })
                  }
                />
              ),
            },
            {
              name: '쇼핑몰 이용약관 동의',
              renderItem: () => (
                <Switch
                  onChange={(shoppingMallTermAllow) =>
                    setState({ shoppingMallTermAllow })
                  }
                  checked={shoppingMallTermAllow}
                />
              ),
            },
            {
              name: '쇼핑몰개인정보 처리 위탁 동의 (미작동)',
              renderItem: () => (
                <Switch
                  onChange={(index) => console.log('index', index)}
                  checked={false}
                />
              ),
            },
            {
              name: '마케팅 푸시',
              renderItem: () => (
                <Switch
                  checked={marketingPushAllowed}
                  onChange={(marketingPushAllowed) => setState({ marketingPushAllowed })}
                />
              ),
            },
            {
              name: '이메일',
              renderItem: () => (
                <Switch
                  checked={emailAllowed}
                  onChange={(emailAllowed) => setState({ emailAllowed })}
                />
              ),
            },
            {
              name: '푸시',
              renderItem: () => (
                <Switch
                  checked={pushAllowed}
                  onChange={(pushAllowed) => setState({ pushAllowed })}
                />
              ),
            },
            {
              name: 'SMS',
              renderItem: () => (
                <Switch
                  checked={smsAllowed}
                  onChange={(smsAllowed) => setState({ smsAllowed })}
                />
              ),
            },
            {
              name: '야간푸시',
              renderItem: () => (
                <Switch
                  checked={nightPushAllowed}
                  onChange={(nightPushAllowed) => setState({ nightPushAllowed })}
                />
              ),
            },
          ]}
        />
        {/* 구현되면 다시 노출 */}
        {/* <Card
          title="활동내역"
          items={[
            {
              name: '진행 중 주문내역',
              renderItem: () => <span className="text-xs">1건</span>,
            },
            {
              name: '주문내역',
              renderItem: () => <span className="text-xs">3건</span>,
            },
            {
              name: '액션 총합',
              renderItem: () => <span className="text-xs">25</span>,
            },
            {
              name: '관심편집샵',
              renderItem: () => <span className="text-xs">{likedShops.length}개</span>,
            },
            {
              name: '관심상품',
              renderItem: () => <span className="text-xs">{likedItems.length}개</span>,
            },
            {
              name: '관심브랜드',
              renderItem: () => <span className="text-xs">{likedBrands.length}개</span>,
            },
          ]}
        /> */}
        <Card title="관리자메모">
          <GridTable
            columns={['작성일', '작성자', '내용', '관리']}
            list={adminMemos}
            noSelection
            renderItem={(item, key) => (
              <div key={key}>
                <div>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                <div>{item.creatorName}</div>
                <div>
                  <Textarea
                    value={item.content || ''}
                    onChange={({ target: { value } }) =>
                      setState({
                        adminMemos: [
                          ...adminMemos.slice(0, key),
                          {
                            ...adminMemos[key],
                            content: value,
                          },
                          ...adminMemos.slice(key + 1),
                        ],
                      })
                    }
                    rows={4}
                  />
                </div>
                <div>
                  <div className="inline-flex flex-col gap-2">
                    <Button
                      size="xs"
                      isLoading={isMemoUpdating && memoIdx === item.idx}
                      onClick={() => updateMemo(key)}
                    >
                      수정
                    </Button>
                    <Button
                      size="xs"
                      isLoading={isMemoRemoving && memoIdx === item.idx}
                      theme="danger"
                      onClick={() => deleteMemo(key)}
                    >
                      삭제
                    </Button>
                  </div>
                </div>
              </div>
            )}
          />
        </Card>
        <Card title="등록하기">
          <div style={{ border: '1px solid #b4b4b4', display: 'flex' }}>
            <div
              className="w-28 bg-gray-100 p-3 text-xs"
              style={{ borderRight: '1px solid #b4b4b4' }}
            >
              내용
            </div>
            <div className="flex-1 flex py-2 px-3 gap-2">
              <Textarea
                value={content}
                name="content"
                onChange={onChange}
                style={{ borderColor: '#989595', minHeight: 80 }}
              />
              <Button isLoading={isMemoCreating} onClick={createMemo}>
                등록
              </Button>
            </div>
          </div>
        </Card>
      </div>
      <div className="sticky bottom-0 z-[5] h-20 bg-white w-full flex items-center justify-center">
        <div className="flex gap-4">
          <Button isLoading={isUpdating} onClick={update}>
            수정 저장
          </Button>
          <Button theme="secondary" onClick={get}>
            수정 초기화
          </Button>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
