import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import axios from 'api/axios';
import * as style from './style';

export default function AddExhibition({ items, onChange }) {
  const [gender, setGender] = useState('MW');
  const [exhibitionList, setExhibitionList] = useState(items);
  const [exhibitionID, setExhibitionID] = useState(
    exhibitionList[0].exhibition_id,
  );

  useEffect(() => {
    let params = {};

    if (gender !== 'MW') {
      params = {
        gender,
      };
    } else {
      params = {};
    }
    axios({
      url: '/api/category/exhibition',
      method: 'GET',
      params,
    })
      .then((res) => {
        if (res.data.success) {
          setExhibitionList(res.data.data);
          setExhibitionID(res.data.data[0].exhibition_id);
          onChange(res.data.data[0].exhibition_id, gender);
        }
      })
      .catch((err) => console.error(err));
  }, [gender]);

  useEffect(() => {
    onChange(exhibitionID, gender);
  }, [exhibitionID]);

  return (
    <style.Wrapper>
      <Form.Control
        as="select"
        style={style.Item}
        value={gender}
        onChange={(e) => setGender(e.target.value)}
      >
        <option value="MW">성별 전체</option>
        <option value="M">남성</option>
        <option value="W">여성</option>
      </Form.Control>
      <Form.Control
        as="select"
        style={style.Item}
        value={exhibitionID}
        onChange={(e) => {
          if (e.target.value === '기획전 세부 컨텐츠') {
            alert('기획전 세부 컨텐츠를 선택하세요');
          } else {
            setExhibitionID(e.target.value);
          }
        }}
      >
        {exhibitionList.map((el, i) => (
          <option key={i} value={el.exhibition_id}>
            {el.name}
          </option>
        ))}
      </Form.Control>
    </style.Wrapper>
  );
}
