import React from 'react';
import * as style from './style';

const Checkbox = ({ text, onChange, isChecked }) => (
  <style.Container>
    <style.Checkbox checked={isChecked} onChange={onChange} />
    <style.Text>{text}</style.Text>
  </style.Container>
);
export default Checkbox;
