import React, { useCallback, useEffect, useState, useContext } from 'react';
import CanvasJSReact from 'assets/canvasjs.react';
import { newInflow } from 'chart';
import { LoadingContext } from 'context';
import fetchingLog from 'api/fetchingLog';
import * as style from './style';
import {
  Title,
  DateBox,
  PlatformCheckBox,
  SearchButtonBox,
  MoonLoading, 
  NumberDisplayTable
} from 'components';

const NewInflow = () => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [options, setOptions] = useState(newInflow);

  const [initial, setInitial] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [platform, setPlatfrom] = useState(['pc_web', 'mobile_web', 'ios', 'android']);
  const [totalValues, setTotalValues] = useState([]);

  const handlePlatformCheck = (e) => {
    if (e.target.checked) {
      const find = platform.find((el) => el === e.target.value);
      if (!find) {
        setPlatfrom([...platform, e.target.value]);
      }
    } else {
      const resultPlatform = platform.filter((el) => el !== e.target.value);
      setPlatfrom(resultPlatform);
    }
  };

  const setDate = (start, last) => {
    setStartDate(start);
    setLastDate(last);
  };

  const getResultDate = (year, month, date) => {
    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  const getThisMonth = () => {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    lastDay = getResultDate(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      lastDay.getDate(),
    );
    setStartDate(firstDay);
    setLastDate(lastDay);
    setInitial(true);
  };

  const handleSearch = useCallback(async () => {
    try {
      handleIsLoading(true);
      const params = {
        start: startDate,
        end: lastDate,
        platforms: platform,
      };
      const res = await fetchingLog.get('/api/inflow/new', {
        params,
      });

      const { data } = res;

      let totalFirst = 0;

      const result = data.map((el) => {
        totalFirst += Number(el.count)
        return { label: el.date, y: Number(el.count) }
      });

      handleIsLoading(false);

      setTotalValues([{
        name: '신규 유입 총합',
        value: totalFirst,
      }])

      setOptions({
        ...options,
        data: [
          {
            type: 'stackedColumn',
            name: '신규 유입 수',
            showInLegend: true,
            color: '#96a9bd',
            dataPoints: result,
          },
        ],
      });
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/inflow/new
      ${err}`);
      console.error(err);
    }
  }, [options, startDate, lastDate]);

  useEffect(() => {
    getThisMonth();
  }, []);

  useEffect(() => {
    if (initial) {
      handleSearch();
    }
  }, [initial]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <Title title={'신규 유입 수'} />
      <DateBox setDate={setDate} />
      <PlatformCheckBox
        platform={platform}
        handlePlatformCheck={(e) => handlePlatformCheck(e)}
      />
      <SearchButtonBox handleSearch={handleSearch} />
      <NumberDisplayTable values={totalValues} />
      <CanvasJSChart
        options={options}
      />
    </style.Container>
  );
};

export default NewInflow;
