import axios from 'axios';
import { AUTH_LINKS, captureException } from 'services';

const CONSOLE_COLOR = '#d9480f';

const instance = axios.create({
  baseURL: process.env.REACT_APP_FETCHING_SERVER_URL,
  withCredentials: true,
  headers: {
    Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
  },
});

instance.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
  };
  return config;
});

const instance2 = axios.create({
  baseURL:
    window.location.origin === 'https://admin.fetching.work'
      ? 'https://admin.fetchingapp.co.kr'
      : 'https://testapi.fetchingapp.co.kr',
  headers: {
    Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
  },
});
instance2.interceptors.request.use((config) => {
  // console.log(
  //   '%c%s',
  //   `color: ${CONSOLE_COLOR}; font-weight: bold`,
  //   `${config.method?.toUpperCase()} ${config.url}`
  // );
  // if (config.params) {
  //   console.log('%c%s', `color: ${CONSOLE_COLOR}`, 'req.query:');
  //   console.table(config.params);
  // }
  // if (config.data) {
  //   console.log('%c%s', `color: ${CONSOLE_COLOR}`, 'req.body:');
  //   console.table(config.data);
  // }
  return config;
});
instance2.interceptors.response.use(
  (res) => {
    const { data, config } = res;
    // console.log(
    //   '%c%s',
    //   `color: ${CONSOLE_COLOR}`,
    //   `${config.method?.toUpperCase()} ${config.url} | data:`
    // );
    // if (Array.isArray(data)) {
    //   console.log(data);
    // } else {
    //   console.table(data);
    // }
    return res;
  },
  (err) => {
    if (
      err.response.status === 401 &&
      AUTH_LINKS.indexOf(window.location.pathname) === -1
    ) {
      window.location.href = `/login?redirectUrl=${window.location.pathname}`;
    }
    captureException({ err });
    throw err;
  }
);

export default instance;

export { instance as axios };
export { instance2 as fetchingAPI };

export async function getCrawlerTargetList(shopId) {
  try {
    const res = await instance2.get('/crawler', {
      params: {
        shopId,
      },
    });
    const { data } = res;
    return data;
  } catch (err) {
    console.log(err);
  }
}

export async function handleCrawlerItems(body) {
  await instance2.post('/crawler/item', body);
}

export async function getShopList(params = {}) {
  try {
    const res = await instance.get('/api/shops', {
      params,
    });

    const result = res.data;

    return result;
  } catch (err) {
    if (err.response.status !== 401) {
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
      API 에러 발생 
      GET - /api/shops
      ${err}`);
      console.error(err);
    }
  }
}

export async function getShopPriceList() {
  try {
    const res = await instance2.get('/shop/price-rules');

    const result = res.data;

    return result;
  } catch (err) {
    if (err.response.status !== 401) {
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
      API 에러 발생 
      GET - /shop/price-rules
      ${err}`);
      console.error(err);
    }
  }
}

export async function getCategories() {
  try {
    const res = await instance.get('/api/categories');
    return res.data;
  } catch (err) {
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생 
    GET - /api/categories
    ${err}`);
    console.error(err);
  }
}

export async function getBrandList() {
  try {
    const res = await instance({
      url: '/api/brand',
      method: 'GET',
    });
    const { data } = res.data.data;
    return data;
  } catch (err) {
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생 
    GET - /api/brand
    ${err}`);
  }
}

export async function getUserList() {
  try {
    const res = await instance.get('/api/auth/userlist');
    const { data } = res.data;

    return data;
  } catch (err) {
    console.dir(err);
  }
}

export const getPromogionImgUrl = async (files, setImg) => {
  const axiosReq = async (b64Buffer) => {
    try {
      const res = await instance.post('/api/util/image', {
        path: 'promotions',
        image: b64Buffer,
      });
      const imgURL = res.data.data.fpath + res.data.data.fname;
      setImg(imgURL);
    } catch (err) {
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      POST - /api/util/image
      ${err}`);
      console.log(err);
    }
  };

  const getBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const result = reader.result.split(',');
      cb(result[1]);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  await getBase64(files[0], axiosReq);
};

export const getPromotions = async () => {
  try {
    return instance.get(`/api/promotions`);
  } catch (err) {
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생 
    GET - /api/promotions
    ${err}`);
    console.log(err);
  }
};

export const getFetchingAPIPromotion = async (id) => {
  try {
    const res = await instance2.get(`/promotion/${id}`, {
      params: {
        isExtended: true,
      },
    });
    const { data } = res;
    return data;
  } catch (err) {
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생 
    GET - /promotions/${id}
    ${err}`);
    console.log(err);
  }
};

export const modifyCountryItems = async (body) => {
  try {
    await instance.patch('/api/items/country', body);
  } catch (err) {
    console.log(err);
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생 
    PATCH - /api/items/country
    ${err} 
    `);
  }
};

export const getExhibitions = async () => {
  try {
    return await instance.get('/api/exhibitions/search');
  } catch (err) {
    console.log(err);
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    API 에러 발생 
    get - /api/exhibitions/search
    ${err} 
    `);
  }
};

export const modifyExhibition = async (data) => {
  try {
    return await instance.put('/api/exhibitions', data);
  } catch (err) {
    console.log(err);
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    API 에러 발생 
    get - /api/exhibitions/search
    ${err} 
    `);
  }
};

export const deleteExhibition = async (data) => {
  try {
    return await instance.delete('/api/exhibitions', { data });
  } catch (error) {
    console.log(error);
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생 
    get - /api/exhibitions/search
    ${error.message} 
    `);
  }
};

export const getCafe24Banners = (params) => {
  try {
    return instance.get(`/api/cafe24/banners`, { params });
  } catch (err) {
    console.log(err);
  }
};

export const deleteCafe24Banner = (bannerId) => {
  try {
    return instance.delete(`/api/cafe24/${bannerId}`);
  } catch (err) {
    console.log(err);
  }
};

export const updateCafe24Banners = (data) => {
  try {
    return instance.put('/api/cafe24/banners', data);
  } catch (err) {
    console.log(err);
  }
};

export const createCafe24Banners = (data) => {
  try {
    return instance.post('/api/cafe24/banners', data);
  } catch (err) {
    console.log(err);
  }
};

export const updateExhibitionImage = (b64Buffer) => {
  try {
    return instance.post(`/api/util/image`, {
      path: 'exhibitions/banner',
      image: b64Buffer,
    });
  } catch (err) {
    console.log(err);
  }
};
