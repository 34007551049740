import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px;
`;

export const CountBoxWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flex};
`;

export const SubTitle = styled.h3`
  margin-top: 30px;
  margin-bottom: 30px;
`;
