import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

export const Title = styled.h1`
  margin: 20px 15px;
`;

export const ButtonOuter = styled.div`
  width: 100%;
  padding: 10px;
  @media all and (min-width: 1200px) {
    width: 80%;
    display: flex;
    justify-content: space-between;
  }
`;

export const Button = styled.button`
  width: 100%;
  margin: 10px 0px;
  padding: 5px 0;
  border-radius: 5px;
  background-color: ${(props) => props.color};
  color: white;
  border: none;
  &:focus {
    outline: none;
  }
  @media all and (min-width: 1200px) {
    width: 49%;
  }
`;

export const FilterItem = {
  marginTop: '20px',
  width: '100%',
};

export const ButtonStyle = {
  marginTop: '20px',
  width: '100%',
  marginLeft: '10px',
  marginRight: '10px',
};

export const PromotionTableOuter = styled.div`
  display: grid;
  grid-template-columns: 5% 15% 10% 15% 5% 10% 15% 15% 5%;
  grid-column-gap: 6px;
  min-width: 1200px;
  text-align: center;
  align-items: center;
  border-bottom: 1px solid #e9ecef;
  background-color: #d4d8dc;
  color: whitesmoke;
  line-height: 50px;
`;

export const PromotionTableBody = styled.div`
  display: grid;
  grid-template-rows: auto;
  background-color: #fff;
  grid-row-gap: 12px;
`;

export const CheckBoxTableItem = styled.div``;

export const PromotionTableItem = styled.div`
  color: gray;
  font-weight: bold;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
  cursor: pointer;
`;

export const SortBox = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 100px;
  grid-column-gap: 10px;
  margin-bottom: 12px;
  padding: 12px;
  background-color: #fff;
`;
// renewal
export const Outer = styled.div`
  width: 100%;
  padding: 0 22px 100px 22px;
`;

export const ShadowContainer = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 22px 0;
  padding: 22px;
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  font-weight: 400;
  font-size: 24px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;
export const ResetButton = styled.button`
  border: none;
  margin: 10px;
  background: #000000;
  border-radius: 5px;
  font-family: AppleSDGothicNeoB00;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 10px 30px;
  color: black;

  background: #dddddd;
  border-radius: 5px;
`;
export const SearchButton = styled.button`
  border: none;
  margin: 10px;
  background: #000000;
  border-radius: 5px;
  font-family: AppleSDGothicNeoB00;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 10px 30px;
  color: #ff6f61;
`;
