import styled from 'styled-components';

export const Tab = styled.span<{ selected: boolean }>`
  font-size: 16px;
  line-height: 20px;
  color: ${({ selected }) => (selected ? `#000` : `#767676`)};
  font-weight: ${({ selected }) => (selected ? 600 : 500)};
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;
