import styled from 'styled-components';

export const Outer = styled.div`
  display: flex;
  justify-content: center;
  clear: both;
  width: 100%;
  padding: 200px 0;
`;

export const Inner = {
  width: '70%',
};

export const GroupOuter = {
  width: '100%',
  marginTop: '20px',
};

export const listItem1 = {
  width: '30%',
};

export const listItem2 = {
  width: '70%',
};
