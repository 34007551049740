import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 48px 48px 48px 48px;
`;

export const Container = styled.div`
  padding: 8px 8px 8px 8px;
  background-color: white;
  width: 100%;
`;

export const TitleWrapper = styled.div`
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 15px 20px;
  font-size: 1.2rem;
  color: #4f4e4e;
`;

export const Title = styled.strong`
  margin-right: auto;
`;

export const ContentWrapper = styled.div`
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 15px 20px;
  font-size: 1.2rem;
  color: #4f4e4e;
`;

export const ItemWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid #ddd;
`;

export const ItemTitle = styled.strong`
  display: block;
  text-align: left;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  overflow: auto;
  border-right: 1px solid #ddd;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  overflow: auto;
`;

export const Table = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #b4b4b4;
  border-bottom: none;
  width: 100%;
`;
export const Tr = styled.div`
  border-bottom: 0.5px solid #b4b4b4;
  display: flex;
  flex-wrap: wrap;
`;
export const Left = styled.div`
  width: 20%;
  padding: 13px 11px;
  background: #e4e4e4;
  border-right: 0.5px solid #b4b4b4;
  text-align: left;
`;
export const Td = styled.div`
  padding: 16px 16px;
  display: flex;
  align-items: center;
  width: 80%;
`;
export const TdContentWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

export const Text = styled.div`
  margin-top: 16px;
  text-align: left;
  color: gray;
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 6px 0 0;
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  margin: 0 16px 0 0;
`;

export const PositiveButton = styled.button`
  display: inline-flex;
  border: none;
  margin: 10px;
  background: #000000;
  border-radius: 5px;
  font-family: AppleSDGothicNeoB00, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 15px 30px;
  color: #ff6f61;
`;

export const NegativeButton = styled.button`
  display: inline-flex;
  border: none;
  margin: 10px;
  background: lightgrey;
  border-radius: 5px;
  font-family: AppleSDGothicNeoB00, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  padding: 15px 30px;
  color: #000000;
`;
