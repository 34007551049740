export const FORM = {
  INPUT: 'input',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  CALENDAR: 'calendar',
  RADIO: 'radio',
};

export const APPLY_STATUS = {
  NEW: { name: '신규', value: 'NEW' },
  UPDATING: { name: '업데이트 예정', value: 'UPDATING' },
  UPDATED: { name: '업데이트 완료', value: 'UPDATED' },
  NO_UPDATE_NEEDED: { name: '업데이트 불필요', value: 'NO_UPDATE_NEEDED' },
  TO_BE_TERMINATED: { name: '종료확인 예정', value: 'TO_BE_TERMINATED' },
  TERMINATED: { name: '종료확인 완료', value: 'TERMINATED' },
  TO_BE_APPLIED: { name: '적용 예정', value: 'TO_BE_APPLIED' },
  APPLIED: { name: '적용됨', value: 'APPLIED' },
  NOT_APPLY_TARGET: { name: '적용대상 아님', value: 'NOT_APPLY_TARGET' },
};
export const DISCOUNT_TYPE = {
  DISCOUNT_WITH_RATE: 'DISCOUNT_WITH_RATE',
  DISCOUNT_WITH_CONST: 'DISCOUNT_WITH_CONST',
  FREE_DELIVERY: 'FREE_DELIVERY',
};

export const DISPLAY_AT = [
  { field: 'isVisibleToAppCategory', name: '앱 모아보기' },
  { field: 'isVisibleToAppItemPage', name: '앱 자사몰 하단' },
  { field: 'isVisibleToCafe24', name: '웹 PC' },
  { field: 'isVisibleToCafe24Mobile', name: '웹 모바일' },
];
