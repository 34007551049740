import {
  Button,
  Card,
  GridTable,
  NumberStat,
  OpenLink,
  Spinner,
  UpdateInvoiceModal,
  ShippingTrackingModal,
  Check,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ORDER_CANCEL_REASON, ORDER_PAY_STATUS, request, useObject } from 'services';
import useSWR from 'swr';

interface IOrderExchange {
  exchangeInfo: {
    exchangeStatus: keyof typeof ORDER_PAY_STATUS;
    fetchingOrderNumber: string;
    orderExchangeNumber: string;
    orderedAt: string;
    requestedAt: string;
  };
  exchangeList: any[];
  requestList: any[];
  shippingInfo: {
    fetchingInvoice: string;
    fetchingShippingCode: string;
    fetchingShippingCompanyName: string;
    shopInvoice: string;
    shopShippingCode: string;
    shopShippingCompanyName: string;
  };
  imageList: Array<{
    imageUrl: string;
  }>;
}

interface State {
  isLoading: boolean;
  isChangeInvoiceOpen: boolean;
  data: IOrderExchange | undefined;
  invoice: string;
  shippingCode: string;
  invoiceType: 'fetching' | 'shop';
  isShippingInfoOpen: boolean;
}

const OrderExchangeDetailPage = () => {
  const { orderExchangeNumber } = useParams<{ orderExchangeNumber: string }>();
  const swr = useSWR(
    `/commerce/order/order-exchange/${orderExchangeNumber}`,
    (url) =>
      request.get<any, IOrderExchange>(url, {
        headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
      }),
    {
      refreshInterval: 1000 * 60 * 60,
    }
  );
  const [
    {
      isLoading,
      isChangeInvoiceOpen,
      data,
      invoice,
      shippingCode,
      invoiceType,
      isShippingInfoOpen,
    },
    setState,
  ] = useObject<State>({
    isLoading: false,
    isChangeInvoiceOpen: false,
    data: swr.data,
    invoice: '',
    shippingCode: '',
    invoiceType: 'fetching',
    isShippingInfoOpen: false,
  });

  const refresh = async () => {
    try {
      const data = await request.get<any, IOrderExchange>(
        `/commerce/order/order-exchange/${orderExchangeNumber}`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setState({ data });
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateStatus = async (exchangeStatus: keyof typeof ORDER_PAY_STATUS) => {
    let message = '교환 상태를 전환하시겠습니까?';
    if (exchangeStatus === 'ACCEPT') message = '교환 접수로 전환하시겠습니까?';
    else if (exchangeStatus === 'HOLD') message = '교환 보류로 전환하시겠습니까?';
    else if (exchangeStatus === 'REJECT') message = '교환 접수거부로 전환하시겠습니까?';

    if (!window.confirm(message)) return;

    setState({ isLoading: true });
    try {
      await request.put(
        `/commerce/order/order-exchange/status/${orderExchangeNumber}`,
        {
          exchangeStatus,
        },
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      toast.success('전환되었습니다.');
      refresh();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    setState({ data: swr.data });
  }, [swr.data]);

  if (!data) {
    return (
      <div className="min-h-screen bg-black p-4">
        <div
          style={{ minHeight: 'calc(100vh - 42px)' }}
          className="bg-white rounded p-4 flex items-center justify-center"
        >
          <Spinner className="h-5 w-5 text-black" />
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="min-h-screen bg-black p-4">
        <div style={{ minHeight: 'calc(100vh - 42px)' }} className="bg-white rounded p-4">
          <div className="wrapper-title">
            교환처리 - {ORDER_PAY_STATUS[data.exchangeInfo.exchangeStatus]}
          </div>
          <Card
            items={[
              {
                name: '주문번호',
                renderItem: () => (
                  <OpenLink url={`/order/${data.exchangeInfo.fetchingOrderNumber}`}>
                    {data.exchangeInfo.fetchingOrderNumber}
                  </OpenLink>
                ),
              },
              {
                name: '교환번호',
                renderItem: () => (
                  <span className="link">{data.exchangeInfo.orderExchangeNumber}</span>
                ),
              },
              {
                name: '주문일자',
                renderItem: () =>
                  dayjs(data.exchangeInfo.orderedAt).format('YYYY.MM.DD HH:mm:ss'),
              },
              {
                name: '신청일자',
                renderItem: () =>
                  dayjs(data.exchangeInfo.requestedAt).format('YYYY.MM.DD HH:mm:ss'),
              },
            ]}
          />
          <div className="mt-14">
            <div className="text-2xl font-bold">주문 상품</div>
            <div className="my-3 flex gap-2">
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('IN_PROGRESS')}
              >
                교환 접수
              </Button>
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('REJECT')}
              >
                교환 접수거부
              </Button>
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('HOLD')}
              >
                교환 보류
              </Button>
            </div>
            <GridTable
              columns={[
                '상품별 주문번호',
                '신청일 (접수일)',
                '판매처 (국가)',
                '상품명 / 옵션',
                '교환사유',
                '수량',
                '교환 수수료',
                '상태',
              ]}
              noSelection
              renderItem={(item, key) => (
                <div key={key}>
                  <div>
                    <OpenLink url={`/order/${item.fetchingOrderNumber}`}>
                      {item.itemOrderNumber}
                    </OpenLink>
                  </div>
                  <div>
                    <div>{dayjs(item.requestedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                    <div className="text-gray-400">
                      ({dayjs(item.statusUpdatedAt).format('YYYY.MM.DD HH:mm:ss')})
                    </div>
                  </div>
                  <div>
                    <div>{item.shopName}</div>
                    <div>({item.shopCountry})</div>
                  </div>
                  <div>
                    <div className="flex gap-3">
                      <img
                        className="max-w-[3.5rem] object-contain"
                        src={item.itemImageUrl}
                        alt={item.itemName}
                      />
                      <div className="flex-1">
                        <div>{item.brandName}</div>
                        <OpenLink
                          url={`/product/productDetail/v2/${Number(
                            item.itemId
                          )}?mode=update`}
                        >
                          {item.itemName}
                        </OpenLink>
                        <div className="text-red-400 mt-4">{`${item.originalSizeName} -> ${item.sizeName}`}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>{ORDER_CANCEL_REASON[item.reasonType]}</div>
                    {!!item.reasonDirectInput && (
                      <div>선택사유명: {item.reasonDirectInput}</div>
                    )}
                    {!!data.imageList.length && (
                      <div className="mt-2 flex flex-wrap gap-1">
                        {data.imageList.map(({ imageUrl }, imageIndex) => (
                          <img
                            src={imageUrl}
                            alt=""
                            className="max-w-[3.5rem] object-contain"
                            key={imageIndex}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div>{item.quantity}</div>
                  <div>
                    <NumberStat value={0} />
                  </div>
                  <div className="text-black font-medium">
                    {ORDER_PAY_STATUS[item.exchangeStatus]}
                  </div>
                </div>
              )}
              list={data.requestList}
            />
            {data.exchangeInfo.exchangeStatus !== 'REQUEST' && (
              <div className="mt-3">
                <div>페칭 택배사: {data.shippingInfo.fetchingShippingCompanyName}</div>
                <div>운송번호: {data.shippingInfo.fetchingInvoice}</div>
                <div className="my-3 flex gap-2">
                  <Button
                    size="sm"
                    theme="secondary"
                    onClick={() =>
                      setState({
                        isShippingInfoOpen: true,
                        invoice: data.shippingInfo.fetchingInvoice,
                        shippingCode: data.shippingInfo.fetchingShippingCode,
                      })
                    }
                  >
                    배송추적
                  </Button>
                  <Button
                    size="sm"
                    onClick={() =>
                      setState({
                        isChangeInvoiceOpen: true,
                        invoiceType: 'fetching',
                        invoice: data.shippingInfo.fetchingInvoice,
                        shippingCode: data.shippingInfo.fetchingShippingCode,
                      })
                    }
                  >
                    송장변경
                  </Button>
                </div>
              </div>
            )}
          </div>
          {data.exchangeInfo.exchangeStatus !== 'REQUEST' && (
            <div className="mt-14">
              <div className="text-2xl font-bold">교환 상품</div>
              <div className="mt-3">
                <GridTable
                  columns={[
                    '상품별 주문번호',
                    '신청일 (접수일)',
                    '판매처 (국가)',
                    '상품명 / 옵션',
                    '수량',
                    '차액 (미구현)',
                    '차액결제여부',
                    '상태',
                  ]}
                  noSelection
                  list={data.exchangeList}
                  renderItem={(item, key) => (
                    <div key={key}>
                      <div>
                        <OpenLink url={`/order/${item.fetchingOrderNumber}`}>
                          {item.fetchingOrderNumber}
                        </OpenLink>
                      </div>
                      <div>
                        <div>{dayjs(item.requestedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                        <div className="text-gray-400">
                          ({dayjs(item.statusUpdatedAt).format('YYYY.MM.DD HH:mm:ss')})
                        </div>
                      </div>
                      <div>
                        <div>{item.shopName}</div>
                        <div>({item.shopCountry})</div>
                      </div>
                      <div>
                        <div className="flex gap-3">
                          <img
                            className="max-w-[3.5rem] object-contain"
                            src={item.itemImageUrl}
                            alt={item.itemName}
                          />
                          <div className="flex-1">
                            <div>{item.brandName}</div>
                            <OpenLink
                              url={`/product/productDetail/v2/${Number(
                                item.itemId
                              )}?mode=update`}
                            >
                              {item.itemName}
                            </OpenLink>
                            <div className="text-red-400 mt-4">{item.sizeName}</div>
                          </div>
                        </div>
                      </div>
                      <div>{item.quantity}</div>
                      <div>
                        <NumberStat value={0} />
                      </div>
                      <div>
                        <Check checked={item.hasAdditionalPay} />
                      </div>
                      <div>
                        <span className="text-black font-medium">
                          {ORDER_PAY_STATUS[item.exchangeStatus]}
                        </span>
                      </div>
                    </div>
                  )}
                />
                <div className="mt-3">
                  <div>편집샵 택배사: {data.shippingInfo.shopShippingCompanyName}</div>
                  <div>운송번호: {data.shippingInfo.shopInvoice}</div>
                  <div className="my-3 flex gap-2">
                    <Button
                      size="sm"
                      theme="secondary"
                      onClick={() =>
                        setState({
                          isShippingInfoOpen: true,
                          invoice: data.shippingInfo.shopInvoice,
                          shippingCode: data.shippingInfo.shopShippingCode,
                        })
                      }
                    >
                      배송추적
                    </Button>
                    <Button
                      size="sm"
                      onClick={() =>
                        setState({
                          isChangeInvoiceOpen: true,
                          invoiceType: 'shop',
                          invoice: data.shippingInfo.shopInvoice,
                          shippingCode: data.shippingInfo.shopShippingCode,
                        })
                      }
                    >
                      송장변경
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <UpdateInvoiceModal
        isOpen={isChangeInvoiceOpen}
        onClose={() =>
          setState({ isChangeInvoiceOpen: false, shippingCode: '', invoice: '' })
        }
        apiUrl={`/commerce/order/order-exchange/shipping/${data.exchangeInfo.orderExchangeNumber}`}
        onSuccess={refresh}
        invoice={invoice}
        shippingCode={shippingCode}
        type={invoiceType}
      />
      <ShippingTrackingModal
        isOpen={isShippingInfoOpen}
        onClose={() =>
          setState({ isShippingInfoOpen: false, invoice: '', shippingCode: '' })
        }
        invoice={invoice}
        shippingCode={shippingCode}
      />
    </>
  );
};

export default OrderExchangeDetailPage;
