import styled from 'styled-components';

export const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  clear: both;
  @media all and (min-width: 800px) {
    flex-wrap: nowrap;
    clear: both;
  }
`;

export const CheckFormOuter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexWrap: 'wrap',
};

export const Margin10 = {
  margin: '10px',
};

export const Margin20 = {
  margin: '20px',
};

export const FormStyle = {
  margin: '10px',
  width: '50%',
};
