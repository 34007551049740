import { loginAction, logoutAction } from 'actions/action';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { request, useQuery, useUser } from 'services';
import type { IAdminUser } from 'types';
import useSWR from 'swr';
import { axios, fetchingAPI } from 'api/axios';

export interface Props {}
interface State {}

const Auth: FC<Props> = ({ children }) => {
  const { data } = useSWR(
    '/admin/my-info',
    (url) =>
      request.get<any, { info: IAdminUser }>(url, {
        headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
      }),
    {
      refreshInterval: 1000 * 60 * 60,
      focusThrottleInterval: 1000 * 60 * 60,
      dedupingInterval: 1000 * 60 * 60,
      errorRetryInterval: 1000 * 60 * 60,
    }
  );
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { setUser, user } = useUser();
  const { query } = useQuery<{ isLogout: string }>();

  useEffect(() => {
    if (!!data && !query.isLogout) {
      if (!user) {
        setUser(data.info);
        dispatch(loginAction(data.info));
      }
      if (pathname === '/login' && !!user?.id) push('/');
      request.defaults.validateStatus = (status: number) => {
        if (status === 401 && pathname !== '/login') {
          setUser(null);
          delete request.defaults.headers.common['Authorization'];
          delete axios.defaults.headers.common['Authorization'];
          delete fetchingAPI.defaults.headers.common['Authorization'];
          localStorage.removeItem('fetchingAPI_AUTH_TOKEN');
          dispatch(logoutAction());
          if (pathname !== '/login') push(`/login?redirectUrl=${pathname}`);
        }
        return status >= 200 && status < 300;
      };
    }
  }, [data]);
  return <>{children}</>;
};

export default Auth;
