import React from 'react';
import type { FC } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';
import classnames from 'classnames';

export interface Props extends TextareaAutosizeProps {
  onEnter?: () => void;
}

const AutosizeTextarea: FC<Props> = ({ className, onEnter, ...props }) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !!onEnter) {
      if (!e.shiftKey) onEnter();
    }
  };
  return (
    <TextareaAutosize
      {...props}
      className={classnames(
        'read-only:bg-gray-100 rounded border-gray-300 text-xs p-1',
        className
      )}
      onKeyDown={onKeyDown}
    />
  );
};

export default AutosizeTextarea;
