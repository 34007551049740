// @ts-nocheck
import fetchingAPI from 'api/fetchingAPI';
import { DropZoneImage, PickIdModal, Table } from 'components';
import { MakeTable } from 'components/UI/atoms/Table';
import { triggerState } from 'pages/ManageMainContent';
import { Item } from 'pages/ManageMainLayout/utils';
import React, { useState, useEffect } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as S from '../style';
import Category from './Category';
import { toast } from 'react-toastify';
const dict: any = {
  title: '타이틀',
  subtitle: '본문 텍스트',
  href: '링크',
  itemList: '상품 목록',
  startAt: '게시 시작 일시',
  expireAt: '게시 마감 일시',
};
type DesignComponentWithoutImage = {
  title: string | null;
  subtitle: string | null; // 개행은 문자열에 [개행] 포함 (여러 줄 대응) "어쩌구[개행]저쩌구"
  href: string | null;
  itemList?: Item[];
  startAt?: string | null;
  expireAt?: string | null;
  promotionId: number | null;
  exhibitionId: number | null;
  exhibitionTabId: number | null;
};
type Props = {
  mode: 'm' | 'w';
  layout: DesignComponentWithoutImage;
};

const ManageDesignComponentWithoutImage = ({ mode, layout }: Props) => {
  const history = useHistory();
  const layoutIdx = Number(history.location.pathname.split('/').pop());
  const setTrigger = useSetRecoilState(triggerState);

  const [finite, setFinite] = useState(true);
  const [data, setData] = useState<DesignComponentWithoutImage>({
    title: '',
    subtitle: '',
    href: '',
    itemList: [],
    startAt: '',
    expireAt: '',
    promotionId: null,
    exhibitionId: null,
    exhibitionTabId: null,
  });

  const modals = PickIdModal.usePickIdModal(
    {
      onSubmitExhibition: (exhibitionId, exhibitionTabId) => {
        setData((p) => ({ ...p, promotionId: null, exhibitionId, exhibitionTabId }));
      },
      onSubmitPromotion: (value) => {
        setData((p) => ({
          ...p,
          exhibitionId: null,
          exhibitionTabId: null,
          promotionId: value,
        }));
      },
    },
    {
      showTab: true,
      allowNoTab: true,
    }
  );

  const [isLoading, setIsLoading] = useState(false);
  const initData = () => {
    const {
      title,
      subtitle,
      href,
      itemList,
      startAt,
      expireAt,
      promotionId,
      exhibitionId,
      exhibitionTabId,
    } = layout;
    setData({
      title,
      subtitle,
      href,
      itemList,
      startAt,
      expireAt,
      promotionId,
      exhibitionId,
      exhibitionTabId,
    });
    !startAt && !expireAt ? setFinite(false) : setFinite(true);
  };
  const saveChanges = () => {
    const emptyFields = Object.entries(data)
      .filter(([k, v]) =>
        ((k === 'startAt' || k === 'expireAt') && !finite) ||
        k === 'promotionId' ||
        k === 'exhibitionId' ||
        k === 'exhibitionTabId'
          ? false
          : v === '' || v === null
      )
      .reduce((acc: string[], [k]) => {
        acc.push(dict[k]);
        return acc;
      }, []);

    if (emptyFields.length) {
      alert(`${emptyFields.join(`\n`)}\n\n위 항목을 입력해주세요!`);
    } else {
      const gender = mode === 'm' ? 'men' : 'women';
      setIsLoading(true);
      fetchingAPI
        .postUIComponents(gender, [
          {
            idx: layoutIdx,
            requestType: 'UPDATE',
            ...data,
            ...(data.exhibitionTabId && { exhibitionId: null }),
          },
        ])
        .then(({ data }) => {
          data?.success && alert('수정에 성공했습니다.');
          setTrigger((p) => p + 1);
        })
        .catch((e) => alert(`err in addCopmonent, ${e}`))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => initData(), []);

  const tables = [
    {
      title: '상품 운영 방식 설정',
      content: [
        {
          left: '상품 운영 방식 설정',
          right: (
            <>
              <Table.Label>
                <Table.Radio
                  checked={
                    !data.exhibitionId &&
                    !data.exhibitionTabId &&
                    data.promotionId === null
                  }
                  onChange={() => {
                    alert(
                      '텍스트형 컴포넌트에서는 반드시 상품 운영 방식을 설정해야 합니다.'
                    );
                    // setData(p => ({ ...p, promotionId: null, exhibitionId: null }));
                  }}
                />
                상품 없음
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={!!data.exhibitionTabId || !!data.exhibitionId}
                  onClick={() => modals.exhibition.toggle()}
                />
                기획전
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={data.promotionId !== null}
                  onChange={() => modals.promotion.toggle()}
                />
                프로모션
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={false}
                  onChange={() => alert('해당기능은 추후 추가 예정입니다.')}
                />
                그룹
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={false}
                  onChange={() => alert('해당기능은 추후 추가 예정입니다.')}
                />
                직접 설정
              </Table.Label>
            </>
          ),
        },
        {
          left: '상품 목록',
          right: (
            <>
              {(data.exhibitionId || data.exhibitionTabId) && (
                <>
                  <Link to={`/product/modify_exhibition_detail/${data.exhibitionId}`}>
                    기획전 상세 보기
                  </Link>
                  <button
                    className="p-2 hover:bg-gray-200"
                    onClick={() => {
                      navigator.clipboard
                        .writeText(
                          `/exhibition/${data.exhibitionId}?tab=${data.exhibitionTabId}`
                        )
                        .then(() => {
                          toast.info('복사되었습니다.');
                        })
                        .catch(() => {});
                    }}
                  >
                    {`/exhibition/${data.exhibitionId}?tab=${data.exhibitionTabId}`}
                  </button>
                </>
              )}
              {data.promotionId && (
                <Link to={`/product/modifyPromotion/${data.promotionId}`}>
                  프로모션 상세 보기
                </Link>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: '상세 설정',
      content: [
        {
          left: '게시 기간 설정',
          right: (
            <>
              <Table.Label>
                <Table.Radio
                  checked={!finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                영구
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={finite}
                  onChange={() => {
                    setFinite(!finite);
                    setData((p) => ({
                      ...p,
                      expireAt: null,
                      startAt: null,
                    }));
                  }}
                />
                설정
              </Table.Label>
            </>
          ),
        },
        {
          left: '게시 일시',
          right: (
            <>
              {finite && (
                <>
                  <Table.InputTime
                    value={data.startAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, startAt: value }))
                    }
                  />
                  {' ~ '}
                  <Table.InputTime
                    style={{ marginLeft: '10px' }}
                    value={data.expireAt ?? ''}
                    onChange={({ target: { value } }) =>
                      setData((p) => ({ ...p, expireAt: value }))
                    }
                  />
                </>
              )}
            </>
          ),
        },
        {
          left: '타이틀',
          right: (
            <>
              <Table.TextArea
                placeholder="최대 영문 14바이트 입력 가능합니다."
                value={data.title ?? ''}
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, title: value }))
                }
              />
            </>
          ),
        },
        {
          left: '본문 텍스트',
          right: (
            <>
              <Table.TextArea
                placeholder="최대 한글 15글자 입력 가능합니다."
                value={data.subtitle ?? ''}
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, subtitle: value }))
                }
              />
            </>
          ),
        },
        {
          left: '링크',
          right: (
            <>
              <Table.TextArea
                value={data.href ?? ''}
                placeholder="/index.html 형식으로 fetching.co.kr를 제외해주세요"
                onChange={({ target: { value } }) =>
                  setData((p) => ({ ...p, href: value }))
                }
              />
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <PickIdModal.Exhibition {...modals.exhibition} />
      <PickIdModal.Promotion {...modals.promotion} />
      <S.ButtonContainer>
        <Button variant="warning" onClick={initData}>
          초기화
        </Button>{' '}
        <Button onClick={saveChanges}>
          {isLoading ? <Spinner animation="border" /> : '저장하기'}
        </Button>
      </S.ButtonContainer>
      {tables.map((table) => (
        <MakeTable data={table} key={table.title} tableRef={null} />
      ))}
    </>
  );
};

ManageDesignComponentWithoutImage.Category = Category;

export default ManageDesignComponentWithoutImage;
