import React from 'react';
import * as style from './style';
import { arrowUp, arrowDown } from '../../../../assets/images';

const TopBottomButtons = () => {
  return (
    <style.Container>
      <style.Button
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <style.Img src={arrowUp} />
      </style.Button>
      <style.Button
        onClick={() => {
          window.scrollTo(0, 99999999);
        }}
      >
        <style.Img src={arrowDown} />
      </style.Button>
    </style.Container>
  );
};

export default TopBottomButtons;
