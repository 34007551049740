import React, { useState, useEffect } from 'react';
import * as style from './style';
import { FORM } from '../constants';

const Input = ({ value, onChange }) => (
  <div>
    <input
      style={{ width: '400px' }}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  </div>
);

const CheckBoxGroup = ({ items, onChange, state }) => {
  return items.map((item, index) => (
    <>
      <input
        key={index}
        type="checkbox"
        value={item.value}
        onChange={() => onChange(item.value)}
        checked={item.value === state}
      />
      {item.name}
    </>
  ));
};

const RadioGroup = ({ items, name, onChange, state }) => {
  return items.map((item, index) => (
    <style.Label key={index} label={item.name}>
      <style.Radio
        type="checkbox"
        value={item.value}
        name={name}
        onChange={() => onChange(item.value)}
        checked={item.value === state}
      />
      {item.name}
    </style.Label>
  ));
};

const SelectBox = ({ optionType, api, state, onChange, matchingKey }) => {
  const [options, setOptions] = useState([]);

  const getOptionData = async () => {
    if (optionType === 'api') {
      const data = await api();
      setOptions(
        data.map((d) => ({
          name: d[matchingKey.name],
          value: d[matchingKey.value],
        })),
      );
    }
  };

  useEffect(() => {
    getOptionData(optionType, api);
  }, [optionType]);

  return (
    <style.Select onChange={(e) => onChange(e.target.value)} value={state}>
      <option value='all'>전체</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </style.Select>
  );
};

const Calendar = ({ field, state, onChange }) => {
  return field.map((f, i) => (
    <React.Fragment key={f}>
      <style.InputDate value={state[i] || ''} onChange={(e) => onChange(f, e.target.value)} />
    </React.Fragment>
  ));
};

const getFormComponent = ({ type, columns, state, onChange }) => {
  switch (type) {
    case FORM.INPUT:
      return (
        <Input
          value={state[columns.field]}
          onChange={(value) => onChange(columns.field, value)}
        />
      );
    case FORM.CHECKBOX:
      return (
        <CheckBoxGroup
          name={columns.field}
          items={columns.items}
          state={state[columns.field]}
          onChange={(value) => onChange(columns.field, value)}
        />
      );
    case FORM.RADIO:
      return (
        <RadioGroup
          name={columns.field}
          items={columns.items}
          state={state[columns.field]}
          onChange={(value) => onChange(columns.field, value)}
        />
      );
    case FORM.SELECT:
      return (
        <SelectBox
          optionType={columns.optionType}
          api={columns.api}
          state={state[columns.field]}
          matchingKey={columns.matchingKey}
          onChange={(value) => onChange(columns.field, value)}
        />
      );
    case FORM.CALENDAR:
      return (
        <Calendar
          field={columns.field}
          state={columns.field.map(field => state[field])}
          onChange={(field, value) => onChange(field, value)}
        />
      );
    default:
      return <div></div>;
  }
};

const Row = ({ columns, total, state, onChange }) => {
  const components = getFormComponent({
    type: columns.formType,
    columns,
    state,
    onChange,
  });

  return (
    <>
      <style.Left width={10}>{columns.title}</style.Left>
      <style.Td width={(100 - total * 10) / total}>{components}</style.Td>
    </>
  );
};

const SearchOption = ({ template, state, onChange }) => {
  return (
    <style.Table>
      {template.map((row, index) => (
        <style.Tr key={index}>
          {row.map((columns, index) => (
            <Row
              key={index}
              columns={columns}
              total={row.length}
              state={state}
              onChange={onChange}
            />
          ))}
        </style.Tr>
      ))}
    </style.Table>
  );
};
export default SearchOption;
