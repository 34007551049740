import styled from 'styled-components';

export const Container = styled.div`
display:flex;
flex-direction:row;
height:100%;`;
export const Div = styled.div`
border-right:0.5px solid #B4B4B4;
display: flex;
flex-direction: row;
align-items: center;
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
margin: 0 6px 0 0;`;
export const Label = styled.label`
margin:0;    
margin-right: 16px;
`;