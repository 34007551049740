import { getPercent, request } from 'services';
import useSWR from 'swr/immutable';
import { NOrder } from 'types';

const EMAIL_SUBJECT = `상품의 원산지를 알고 싶습니다. I want to know the country of origin of the product.`;

const EMAIL_BODY = `

이 상품의 원산지를 알고 싶습니다. 
원산지 증명서를 보내주세요.

감사합니다.


I want to know the country of origin of this product.
Please send me a certificate of origin.

Thank you.`;

const FETCHING_ORDER_EMAIL = `orderfetching@gmail.com`;

export const getEmailHref = (mailTo: string, itemUrl: string) => {
  return `
mailto:${mailTo}?
bcc=${FETCHING_ORDER_EMAIL}&
subject=${EMAIL_SUBJECT}&
body=${encodeURIComponent(itemUrl + EMAIL_BODY)}
`;
};

export namespace RealtimePrice {
  export interface Payload {
    itemId: number;
    priceRule: number;
    originPrice: number;
    discountedPrice: number;
    deliveryMethod?: number;
    promotionFixedAmount?: number;
    promotionPercent?: number;
    dutyAndTax?: number;
    dutyAndTaxRate?: number;
    dutyRate?: number;
    taxRate?: number;
    educationalTax?: number;
    educationalTaxRate?: number;
    specialTax?: number;
    specialTaxRate?: number;
    deliveryFee?: number;
    waypointFee?: number;
    agencyFee?: number;
    agencyFeeRate?: number;
    referenceDate?: string;
    additionalFee?: number;
  }
  export interface Response {
    additionalPrice: number; // 추가 수수료
    agencyFee: number; // 페칭 수수료
    agencyFeeRate: number; // 페칭 수수료율
    calculatedDeliveryFee: number; // 배송비
    calculatedDeliveryFeeUser: number; // 이동 결제 배송비
    deductedVat: number; // 부가세 환급
    discountedPrice: number; // 할인가
    dutyAndTax: number; // 관부가세
    dutyAndTaxRate: number; // 관부가세율
    dutyAndTaxRateUser: number; // 이동 결제 관부가세율
    dutyAndTaxUser: number; // 이동 결제 관부가세
    dutyRateBeforeAdjust: number; // 실제 관세율
    dutyRateBeforeAdjustUser: number; // 이동 결제 실제 관세율
    educationalRate: number; // 교육세율
    educationalRateBeforeAdjust: number; // 실제 교육세율
    educationalRateBeforeAdjustUser: number; // 이동 결제 실제 교육세율
    educationalRateUser: number; // 이동 결제 교육세율
    educationalTax: number; // 교육세
    educationalTaxUser: number; // 이동 결제 교육세
    finalDiscountRate: number; // 결제 최종 할인률
    finalPrice: number; // 페칭 결제가
    fixedPrice: number; // 페칭 결제시 편집샵 결제 금액
    fixedPriceKor: number; // 페칭 결제시 편집샵 결제 금액 (원화)
    fixedRate: number; // 페칭 결제가에 적용된 프로모션 할인률
    isDutyFree: boolean; // 관세 면제 여부
    isDutyFreeUser: boolean; // 이동 결제 관세 면제 여부
    isTaxFree: boolean; // 부가세 면제 여부
    isTaxFreeUser: boolean; // 이동 결제 부가세 면제 여부
    itemId: number; // 페칭 코드
    originPrice: number; // 원가
    priceRule: number; // 국가별 편집샵 ID
    promotionIdList: number; // 페칭 결제가에 적용된 프로모션 ID 목록
    specialRate: number; // 특별소비세율
    specialRateBeforeAdjust: number; // 실제 특별소비세율
    specialRateBeforeAdjustUser: number; // 이동 결제 실제 특별소비세율
    specialRateUser: number; // 이동 결제 특별소비세율
    specialTax: number; // 특별소비세
    specialTaxUser: number; // 이동 결제 특별소비세
    taxRateBeforeAdjust: number; // 실제 부가세율
    taxRateBeforeAdjustUser: number; // 이동 결제 실제 부가세율
    totalPrice: number; // 편집샵 판매가
    totalPriceUser: number; // 이동 결제 편집샵 판매가
    userApplicablePrice: number; // 이동 결제시 편집샵 결제 금액
    userApplicablePriceKor: number; // 이동 결제시 편집샵 결제 금액 (원화)
    userPromotionIdList: number; // 편집샵 이동 결제가에 적용된 프로모션 ID 목록
    waypointFee: number; // 경유지 수수료
  }
}
export const useRealTimePrice = ({ itemId, ...price }: RealtimePrice.Payload) => {
  const { data, error, isValidating, mutate } = useSWR<RealtimePrice.Response>(
    () => (itemId ? [`/item/v3/${itemId}/calculate`, price] : null),
    (path, body) => request.post(path, body),
    {
      shouldRetryOnError: false,
      compare: () => false,
    }
  );

  return {
    isLoading: (!data && !error) || isValidating,
    data,
    error,
    reload: mutate,
  };
};

export const toRealTimePricePayload = (
  data: NOrder.ItemOrderDetail,
  priceRule: number,
  mode: 'init' | 'update',
  valueMode: 'RATE' | 'VALUE',
  deliveryMethod?: number
): RealtimePrice.Payload => {
  return {
    discountedPrice: data.originPrice - data.shopDiscount,
    itemId: data.itemId as unknown as number,
    originPrice: data.originPrice,
    priceRule,
    deliveryMethod,
    ...(mode == 'update' && {
      ...(valueMode == 'RATE' && {
        promotionPercent: Number((data.promotionDiscountPercent / 100).toFixed(2)),
        ...(data.isDutyAndTaxSeparately
          ? {
              dutyRate: Number((data.dutyPercent / 100).toFixed(2)),
              taxRate: Number((data.taxPercent / 100).toFixed(2)),
            }
          : {
              dutyAndTaxRate: Number((data.dutyAndTaxPercent / 100).toFixed(2)),
            }),
        educationalTaxRate: Number((data.educationalPercent / 100).toFixed(2)),
        specialTaxRate: Number((data.specialPercent / 100).toFixed(2)),
        agencyFeeRate: Number((data.fetchingFeePercent / 100).toFixed(2)),
      }),
      ...(valueMode == 'VALUE' && {
        promotionFixedAmount: data.promotionDiscount,
        dutyAndTax: data.dutyAndTax,
        educationalTax: data.educational,
        specialTax: data.special,
        agencyFee: data.fetchingFee,
      }),
      deliveryFee: data.deliveryFee,
      waypointFee: data.waypointFee,
      additionalFee: data.additionalFee,
    }),
  };
};

export const toNOrderItemOrderDetail = (
  data: RealtimePrice.Response
): Partial<NOrder.ItemOrderDetail> => {
  return {
    originPrice: data.originPrice,
    shopDiscount: data.originPrice - data.discountedPrice,
    promotionDiscount: data.discountedPrice - data.fixedPrice,
    promotionDiscountPercent: Math.round(data.fixedRate * 100),
    deductedVat: data.deductedVat,
    fixedPrice: data.fixedPrice,
    fixedPriceKor: data.fixedPriceKor,
    dutyAndTax: data.dutyAndTax,
    dutyAndTaxPercent: Math.round(data.dutyAndTaxRate * 100),
    dutyPercent: Math.round(data.dutyRateBeforeAdjust * 100),
    taxPercent: Math.round(data.taxRateBeforeAdjust * 100),
    educational: data.educationalTax,
    educationalPercent: Math.round(data.educationalRateBeforeAdjust * 100),
    special: data.specialTax,
    specialPercent: Math.round(data.specialRateBeforeAdjust * 100),
    deliveryFee: data.calculatedDeliveryFee,
    waypointFee: data.waypointFee,
    fetchingFee: data.agencyFee,
    fetchingFeePercent: Math.round(data.agencyFeeRate * 100),
    additionalFee: data.additionalPrice,
    finalPrice: data.finalPrice,
  };
};

export const getCouponDiscountedAmount = (
  finalPrice: number,
  couponDiscounted?: NOrder.TotalDiscount
) => {
  const { couponType, couponValue } = couponDiscounted?.detailList[0] ?? {};
  switch (couponType) {
    case 'FIXED':
      return couponValue ?? 0;
    case 'PERCENT':
      return Math.ceil((finalPrice * (couponValue ?? 0)) / 100);
    default:
      return 0;
  }
};

export const getCouponDescription = (couponDiscounted?: NOrder.TotalDiscount) => {
  const { couponType, couponValue } = couponDiscounted?.detailList[0] ?? {};
  if (couponType == 'PERCENT') {
    return ` (${couponValue}%)`;
  } else {
    return '';
  }
};

export enum PayAmountDetailType {
  ORIGIN_PRICE = 'ORIGIN_PRICE',
  SHOP_DISCOUNT = 'SHOP_DISCOUNT',
  PROMOTION_DISCOUNT = 'PROMOTION_DISCOUNT',
  SHOP_PRICE = 'SHOP_PRICE',
  SHOP_PRICE_KOR = 'SHOP_PRICE_KOR',
  DEDUCTED_VAT = 'DEDUCTED_VAT',
  DUTY_AND_TAX = 'DUTY_AND_TAX',
  EDUCATIONAL_TAX = 'EDUCATIONAL_TAX',
  SPECIAL_TAX = 'SPECIAL_TAX',
  DELIVERY_FEE = 'DELIVERY_FEE',
  WAYPOINT_FEE = 'WAYPOINT_FEE',
  FETCHING_FEE = 'FETCHING_FEE',
  ADDITIONAL_FEE = 'ADDITIONAL_FEE',
  FETCHING_PRICE = 'FETCHING_PRICE',
  DISCOUNTED_COUPON = 'DISCOUNTED_COUPON',
  DISCOUNTED_POINT = 'DISCOUNTED_POINT',
  PAY_AMOUNT = 'PAY_AMOUNT',
  ADDITIONAL_PAY = 'ADDITIONAL_PAY',
}
