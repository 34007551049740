import styled from 'styled-components';

export const StorePageOuter = styled.div`
  width: ${(props) => (props.isSideBar ? '80%' : '100%')};
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 100px 0;
`;

export const FormInner = styled.div`
  width: 100%;
  @media all and (min-width: 1070px) {
    width: 70%;
  }
`;

export const FormInfoBox = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
`;

export const ShopInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  @media all and (min-width: 670px) {
    flex-direction: row;
  }
`;

export const ShopInfoItem = styled.div`
  width: 100%;
  margin: 10px;
  @media all and (min-width: 670px) {
    width: 47%;
    margin: 10px;
  }
`;

export const DropZoneBox = styled.div`
  border: 1px solid #ced4da;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 1em;
  transition: 0.5s;
  margin-top: 20px;
  &:active,
  $:hover {
    background-color: #343b3f96;
    color: white;
    cursor: pointer;
  }
`;

export const MaginTop20 = {
  marginTop: '20px',
};

export const FormTitle = {
  fontWeight: 'bold',
};

export const ImageIcon = {
  width: '15px',
  height: '15px',
};
