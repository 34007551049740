import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';
import { MenuList } from 'components';
import ProductDetailV2 from 'pages/ProductDetail/v2';
import { useUser } from 'services';

const menu = [
  { link: '/product/productList', title: '상품 목록' },
  { link: '/product/productList/manage-group', title: '그룹 관리' },
  { link: '/product/productList/crawler', title: '신상 가져오기' },
  { link: '/product/productList/manageCatalog', title: '카탈로그 관리' },
  { link: '/product/nameKeyword', title: '상품 이름 키워드' },
];

const RouteList = [
  {
    path: '/product/productList',
    component: pages.ProductList,
    exact: true,
  },
  {
    path: '/product/productList/manage-group',
    component: pages.ManageGroup,
    exact: true,
  },
  {
    path: '/product/productList/manage-group/add-group',
    component: pages.AddProductGroup,
  },
  {
    path: '/product/productList/manage-group/modify-group/:id',
    component: pages.ModifyProductGroup,
  },
  {
    path: '/product/productList/crawler',
    component: pages.NewProductCrawler,
  },
  {
    path: '/product/productList/manageCatalog',
    component: pages.ManageCatalog,
  },
  {
    path: '/product/productDetail/v2/:productId',
    component: ProductDetailV2,
  },
  {
    path: '/product/productDetail/:productId',
    component: pages.ProductDetail,
  },
  {
    path: '/product/nameKeyword',
    component: pages.ProductNameKeyword,
  },
  {
    path: '/product/mixMatch',
    component: pages.ProductAddReference,
  },
];

const Product = () => {
  const { user } = useUser();
  return (
    <Switch>
      {RouteList.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          render={() => (
            <>
              <MenuList items={menu} />
              <route.component />
            </>
          )}
          exact={route.exact}
        />
      ))}
    </Switch>
  );
};

export default Product;
