import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import dotenv from 'dotenv';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as pages from 'pages';
import { Auth, Layout } from 'components';
import ManageContent from 'pages/ManageContent';
import ManageReview from 'pages/ManageReview';
import ManagePartner from 'pages/ManagePartner';

dotenv.config();

interface State {}

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Layout>
            <Route path="/" exact component={pages.Home} />
            <Route path="/login" exact component={pages.Login} />
            {/* <Route path="/signUp" exact component={pages.SignUp} /> */}
            <Route path="/changePassword" exact component={pages.ChangePassword} />
            <Auth>
              <Route path="/product" component={pages.ProductTab} />

              <Route path="/products" component={pages.ProductsPage} />

              {/* 콘텐트 */}
              <Route path="/content" component={pages.ContentTab} />
              <ManageContent />

              {/* 대시보드 */}
              <Route path="/dashboard/service" component={pages.DashboardService} />
              <Route path="/dashboard/marketing" component={pages.DashboardMarketing} />
              <Route path="/dashboard/md" component={pages.DashboardByMD} />
              <Route
                path="/dashboard/google-analytics"
                component={pages.GoogleAnalytics}
              />
              <Route path="/dashboard" component={pages.AnalysisTab} />

              {/* 리뷰 */}
              <ManageReview />

              {/* 파트너 */}
              <ManagePartner />

              <Route path="/orders" component={pages.Orders} exact />
              <Route path="/orders/dashboard" component={pages.OrderDashboard} />
              <Route path="/orders/new" component={pages.OrdersNew} />
              <Route path="/orders/wait" component={pages.OrdersWait} />
              <Route path="/orders/pre" component={pages.OrdersPre} />
              <Route path="/orders/complete" component={pages.OrdersComplete} />
              <Route path="/orders/delay-in-shop" component={pages.OrdersDelayInShop} />
              <Route path="/orders/delay" component={pages.OrdersDelay} />
              <Route path="/orders/shipping" component={pages.OrdersShipping} />
              <Route
                path="/orders/shipping-complete"
                component={pages.OrdersShippingComplete}
              />
              <Route path="/orders/cancel" component={pages.OrdersCancel} />
              <Route path="/orders/card-cancel" component={pages.OrdersCardCancel} />
              <Route path="/orders/check-payment" component={pages.OrdersCheckPayment} />
              <Route path="/orders/receipt" component={pages.OrdersReceipt} />
              <Route path="/orders/tax" component={pages.OrdersTax} />
              <Route path="/orders/before" component={pages.OrdersBefore} />
              <Route path="/orders/exchange" component={pages.OrdersExchange} exact />
              <Route
                path="/orders/customs-refund"
                component={pages.OrderCustomsRefund}
                exact
              />
              <Route path="/orders/old" component={pages.OrderManagement} exact />
              <Route
                path="/orders/exchange/:orderExchangeNumber"
                component={pages.OrderExchangeDetail}
                exact
              />
              <Route path="/orders/refund" component={pages.OrdersRefund} exact />
              <Route
                path="/orders/refund/:orderRefundNumber"
                component={pages.OrderRefundDetail}
              />
              <Route path="/orders/return" component={pages.OrdersReturn} exact />
              <Route
                path="/orders/return/:orderReturnNumber"
                component={pages.OrderReturnDetail}
              />
              <Route
                path="/order/:fetchingOrderNumber"
                component={pages.OrderDetail}
                exact
              />
              <Route path="/users" component={pages.Users} exact />
              <Route path="/users/grade" component={pages.UserGrade} exact />
              <Route path="/users/reserve" component={pages.UserReserve} exact />
              <Route path="/users/dormant" component={pages.UserDormant} exact />
              <Route path="/users/delete" component={pages.UserDelete} exact />
              <Route path="/users/auto-mailing" component={pages.UserAutoMailing} exact />
              <Route
                path="/users/auto-messaging"
                component={pages.UserAutoMessaging}
                exact
              />
              <Route path="/users/massive-mail" component={pages.UserMassiveMail} exact />
              <Route path="/users/mail" component={pages.UserMail} exact />
              <Route path="/users/message" component={pages.UserMessage} exact />
              <Route path="/users/send-message" component={pages.UserSendMessage} exact />
              <Route path="/users/push" component={pages.UserPush} exact />
              <Route path="/users/send-push" component={pages.UserSendPush} exact />
              <Route path="/users/register" component={pages.UserRegister} exact />
              <Route path="/users/sns" component={pages.UserRegister} exact />
              <Route path="/users/etc" component={pages.UserEtc} exact />
              <Route path="/user/:idx" component={pages.UserIdxPage} exact />
            </Auth>
          </Layout>
        </Switch>
      </BrowserRouter>
      <ToastContainer
        position="top-center"
        autoClose={1500}
        theme="colored"
        pauseOnFocusLoss={false}
      />
    </>
  );
};

export default App;
