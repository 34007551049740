import React, { useEffect, useState } from 'react';
import * as S from './style';
import { ExhibitionTable, Loading } from 'components';
import { getExhibitions } from 'api/axios';
import SearchTable from './SearchTable';
import { itemFilter, getParams } from './utils';
import { Alert } from 'antd';

const initialOption = {
  title: null,
  gender: null,
  isActive: null,
  adminName: null,
  displayedAt: null,
  start: null,
  end: null,
};

const ExhibitionList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(initialOption);
  const [items, setItems] = useState([]);
  const [allItems, setAllItems] = useState([]);

  const fetchAllItems = async () => {
    setIsLoading(true);
    try {
      const { data } = await getExhibitions();
      setAllItems(data);
      setItems(data);
    } catch (error) {
      alert(`err in fetchSearchExhibitions() in <ExhibitionList/>, ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllItems();
  }, []);

  useEffect(() => {
    setItems(allItems.filter((item) => itemFilter(item, getParams(options))));
  }, [allItems, options]);

  return (
    <S.Outer>
      {isLoading && <Loading />}
      <S.ShadowContainer>
        <Alert
          message={
            <>
              <a href="https://admin.fetching.co.kr/products/exhibition">새 어드민</a>에서
              개선된 기획전을 사용하세요.
            </>
          }
          type="warning"
          closable
        />
        <h2>기획전 목록</h2>

        <SearchTable options={options} setOptions={setOptions} />

        <div style={{ margin: '0 auto', display: 'block', textAlign: 'center' }}>
          <S.ManualButton
            onClick={() => {
              setOptions(initialOption);
              setItems([...items]);
            }}
          >
            초기화
          </S.ManualButton>
          <S.ManualButton
            style={{ background: 'black', color: 'white' }}
            onClick={() => {
              fetchAllItems();
            }}
          >
            새로고침
          </S.ManualButton>
        </div>
      </S.ShadowContainer>

      <S.ShadowContainer>
        <span>총 {items.length}개</span>
        <ExhibitionTable items={items} reload={fetchAllItems} />
      </S.ShadowContainer>
    </S.Outer>
  );
};

export default ExhibitionList;
