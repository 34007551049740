import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.strong`
  margin-right: 20px;
`;

export const Input = styled.input.attrs({
  type: 'text'
})`
  height: 35px;
`;
