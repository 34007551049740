import React from 'react';
import * as style from './style';

const PageButtonList = ({ buttonList, page, setPage }) => {
  return (
    <style.Container>
      {buttonList.map((buttonText, i) => {
        return (
          <style.Button
            BC={String(page) === String(buttonText) ? '#1A1A1A' : '#6d6b6b'}
            key={i}
            onClick={() => setPage(buttonText)}
          >
            {buttonText}
          </style.Button>
        );
      })}
    </style.Container>
  );
};
export default PageButtonList;
