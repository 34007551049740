import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
export const Select = styled.select`
  margin: 0 3px;
`;
export const Date = styled.input.attrs({ type: 'date', disabled: true })`
  margin: 0 6px;
`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;
export const Label = styled.label`
  margin: 0;
  margin-right: 16px;
`;
export const Div = styled.div`
  height: 100%;
  border-right: 0.5px solid #b4b4b4;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 6px 0 0;
`;
export const Margin9px = styled.div`
  width: 9px;
`;
