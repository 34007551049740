// @ts-nocheck
import { getPromotions } from 'api/axios';
import fetchingAPI from 'api/fetchingAPI';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

type Props = {
  show: boolean;
  onHide: () => void;
  onSubmit: (value: number, value?: number) => void;
  showTab?: boolean;
  allowNoTab?: boolean;
};
const Exhibition = ({ show, onHide, onSubmit, showTab, allowNoTab }: Props) => {
  const [id, setId] = useState<number | null>(null);
  const [tab, setTab] = useState<number | null>(null);
  const [genderFilter, setGenderFilter] = useState('');
  const exhibitions = useExhibitions();
  const tabs = useExhibitionTabs(showTab ? id : null);

  useEffect(() => {
    if (!show) {
      setId(null);
      setTab(null);
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>기획전 선택</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', gap: '10px', margin: '50px 0' }}>
          <Form.Control
            as="select"
            onChange={({ target: { value } }) => setGenderFilter(value)}
          >
            <option value="">전체</option>
            <option value="M">남성</option>
            <option value="W">여성</option>
            <option value="MW">남성, 여성</option>
          </Form.Control>
          <Form.Control
            as="select"
            value={id || ''}
            onChange={({ target: { value } }) => {
              setId(Number(value));
              setTab(null);
            }}
          >
            <option value="">기획전을 선택하세요.</option>
            {exhibitions
              .filter(({ gender }: { gender: string }) => gender.includes(genderFilter))
              .map(({ id, name }: { id: number; name: string }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
          </Form.Control>
          {!!showTab && (
            <Form.Control
              as="select"
              value={tab && tabs.map(({ id }) => id).includes(tab) ? tab : ''}
              onChange={({ target: { value } }) => setTab(value ? Number(value) : null)}
            >
              <option value="">기획전 상품 전체</option>
              {tabs.map(({ id, name }: { id: number; name: string }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
            </Form.Control>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (!id) {
              alert('기획전을 선택해주세요.');
            } else if (showTab && !allowNoTab && !tab) {
              alert('기획전 탭을 선택해주세요.');
            } else {
              onSubmit(id, typeof tab == 'number' ? tab : undefined);
            }
          }}
        >
          저장
        </Button>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Promotion = ({ show, onHide, onSubmit }: Props) => {
  const [id, setId] = useState<number | null>(null);
  const [shopFilter, setShopFilter] = useState('');
  const promotions = usePromotions();

  useEffect(() => {
    if (!show) {
      setId(null);
      setShopFilter('');
    }
  }, [show]);

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>프로모션 선택</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', gap: '10px', margin: '50px 0' }}>
          <Form.Control
            as="select"
            onChange={({ target: { value } }) => {
              setShopFilter(value);
              setId(null);
            }}
          >
            <option value="">편집샵 전체</option>
            {Array.from(new Set(promotions.map(({ shopName }) => shopName)))
              .sort()
              .map((shopName) => (
                <option key={shopName} value={shopName}>
                  {shopName}
                </option>
              ))}
          </Form.Control>
          <Form.Control
            as="select"
            onChange={({ target: { value } }) => setId(Number(value))}
          >
            <option value="">프로모션 선택</option>
            {promotions
              .filter(({ shopName }) => shopName?.includes(shopFilter))
              .sort()
              .map(({ id, name }: { id: number; name: string }) => (
                <option value={id} key={id}>
                  {name}
                </option>
              ))}
          </Form.Control>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (id !== null) {
              onSubmit(id);
            } else {
              alert('프로모션을 선택해주세요.');
            }
          }}
        >
          저장
        </Button>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const useExhibitions = (params?: object): any[] => {
  const [exhibitions, setExhibitions] = useState([]);
  useEffect(() => {
    fetchingAPI
      .getExhibitions(params)
      .then((res) => setExhibitions(res.data))
      .catch((err) => alert(`err in useExhibitions, ${err}`));
  }, [params]);
  return exhibitions;
};
const useExhibitionTabs = (exhibitionId: number | null, params?: object): any[] => {
  const [tabs, setTabs] = useState([]);
  useEffect(() => {
    exhibitionId &&
      fetchingAPI
        .getExhibitionCategories(exhibitionId)
        .then((res) => setTabs(res.data))
        .catch((err) => alert(`err in useExhibitionTabs, ${err}`));
  }, [exhibitionId, params]);
  return tabs;
};

const usePromotions = (params?: object): any[] => {
  const [promotions, setPromotions] = useState([]);
  useEffect(() => {
    getPromotions()
      .then((res) =>
        setPromotions(
          res?.data.map(
            ({ id, shopName, name }: { id: number; shopName: string; name: string }) => ({
              id,
              shopName,
              name,
            })
          )
        )
      )
      .catch((err) => alert(`err in usePromotions, ${err}`));
  }, []);
  return promotions;
};
const getShopNameSet = (promotions: any[]): string[] => {
  return Array.from(new Set(promotions.map(({ shopName }) => shopName))).sort();
};

const usePickIdModal = (
  {
    onSubmitExhibition = () => {},
    onSubmitPromotion = () => {},
  }: {
    onSubmitExhibition?: (exhibitionId: number, exhibitionTabId?: number) => void;
    onSubmitPromotion?: (value: number) => void;
  },
  {
    showTab = false,
    allowNoTab = false,
  }: {
    showTab?: boolean;
    allowNoTab?: boolean;
  } = {}
) => {
  const [modal, setModal] = useState({
    exhibition: false,
    promotion: false,
  });

  return {
    exhibition: {
      show: modal.exhibition,
      showTab,
      allowNoTab,
      toggle: () => setModal((p) => ({ ...p, exhibition: !modal.exhibition })),
      onHide: () => setModal({ exhibition: false, promotion: false }),
      onSubmit: (exhibitionId: number, exhibitionTabId?: number) => {
        onSubmitExhibition(exhibitionId, exhibitionTabId);
        setModal({ exhibition: false, promotion: false });
      },
    },
    promotion: {
      show: modal.promotion,
      toggle: () => setModal((p) => ({ ...p, promotion: !modal.promotion })),
      onHide: () => setModal({ exhibition: false, promotion: false }),
      onSubmit: (value: number) => {
        onSubmitPromotion(value);
        setModal({ exhibition: false, promotion: false });
      },
    },
  };
};

export default { Exhibition, Promotion, usePickIdModal };
