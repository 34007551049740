import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';
import { MenuList } from 'components';

const menu = [
  { link: '/product/shop/shopList', title: '편집샵 목록' },
  { link: '/product/shop/shopManagement', title: '편집샵 관리' },
];

const RouteList = [
  {
    path: '/product/shop/shopList',
    component: pages.ShopList,
    exact: true,
  },
  {
    path: '/product/shop/shopDetail/:id',
    component: pages.ShopDetail,
  },
];

const Shop = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => (
          <>
            <MenuList items={menu} />
            <route.component />
          </>
        )}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Shop;
