export const getPromotionName = (promotion: any): string => {
  const name = [
    promotion?.isAppliedWith || promotion?.promotionIsAppliedWith,
    promotion?.code || promotion?.promotionCode,
    promotion?.shopName,
    promotion?.name || promotion?.promotionName,
    (promotion?.typeValue || promotion.promotionTypeValue) > 1
      ? promotion?.typeValue || promotion.promotionTypeValue
      : `${Math.round((promotion?.typeValue || promotion.promotionTypeValue) * 100)}%`,
  ];

  return name.join(' - ');
};

export const getKRWPrice = (value: number | undefined | null) =>
  value ? `${Number(value).toLocaleString()} KRW` : '없음';

export type DeliveryMethod = { id: number; name: string; country: string };
