import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
`;

export const Title = styled.h1``;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media all and (min-width: 1400px) {
    flex-direction: row-reverse;
  }
`;

export const ShopOptionWrapper = styled.div`
  width: 100%;
  @media all and (min-width: 1400px) {
    width: 30%;
  }
`;

export const MappingFilterWrapper = styled.div`
  width: 100%;
  @media all and (min-width: 1400px) {
    width: 70%;
  }
`;

export const MappingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  @media all and (min-width: 1400px) {
    flex-direction: row-reverse;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  border: 1px solid #ddd;
  height: 700px;
`;

export const Outer = styled.div`
  width: 100%;
  margin: 5px;
  @media all and (min-width: 1400px) {
    width: ${(props) => props.width};
  }
`;

export const ButtonListWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media all and (min-width: 1400px) {
    width: 70%;
  }
`;

export const BrandSelect = styled.select`
  width: 100%;
  padding: 15px 20px;
`;

export const BrandOption = styled.option``;

export const TextInput = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }
  @media all and (min-width: 1400px) {
    width: 50%;
  }
`;
