import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 25px;
`;

export const Title = styled.h5``;

export const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
`;

export const TableTitle = styled.div`
  display: flex;
  align-items: center;
  width: 30%;
  background-color: #e8e8e8;
  justify-content: center;
  padding: 10px 15px;
  border-right: 1px solid #ddd;
  font-weight: bold;
  @media all and (min-width: 1000px) {
    width: 15%;
  }
`;

export const TableValue = styled.div`
  width: 70%;
  padding: 10px 15px;
  overflow: auto;
  @media all and (xomin-width: 1000px) {
    width: 85%;
  }
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  width: 50%;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 10px 15px;
  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  padding: 5px 20px;
  color: white;
  background-color: black;
  border-radius: 4px;
  border: none;
  font-weight: bold;
  &:focus {
    outline: none;
  }
`;

export const Img = styled.img`
  margin-top: 10px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border: 1px solid #ddd;
  background-color: white;
`;
