import React from 'react';
import * as style from './style';

const ResearchButton = ({ onClick }) => {
  return (
    <style.Wrapper>
      <style.Button onClick={onClick}>재검색</style.Button>
    </style.Wrapper>
  );
};
export default ResearchButton;
