import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 10px;
`;

export const CategoryName = styled.p`
  margin-left: 20px;
  font-weight: bold;
  cursor: pointer;
  color: gray;
  &:active,
  &:hover {
    color: #212529;
  }
`;
