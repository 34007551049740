import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  background-color: white;
`;

export const Outer = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  padding: 100px 0;
`;

export const HeaderOuter = styled.div`
  display: flex;
  width: 100%;
  background-color: #232324;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  flex-wrap: wrap;
  @media all and (min-width: 600px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const HeaderItem = styled.div`
  margin: 20px;
  display: flex;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 30px;
`;

export const BottomOuter = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #4f4e4e;
  padding: 15px 20px;
`;

export const Button = styled.div`
  background-color: ${(props) => props.background};
  color: #ffffff;
  padding: 15px;
  border: 1px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

export const Img = styled.img``;
