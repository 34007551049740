import axios from 'axios';
import { AUTH_LINKS, CONSOLE_COLOR } from 'services';

export const fetchingAPI = axios.create({
  baseURL:
    window.location.origin === 'https://admin.fetching.work'
      ? 'https://admin.fetchingapp.co.kr'
      : 'https://testapi.fetchingapp.co.kr',
  headers: {
    Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
  },
});
fetchingAPI.interceptors.request.use((config) => {
  // console.log(
  //   '%c%s',
  //   `color: ${CONSOLE_COLOR}; font-weight: bold`,
  //   `${config.method?.toUpperCase()} ${config.url}`
  // );
  // if (config.params) {
  //   console.log('%c%s', `color: ${CONSOLE_COLOR}`, 'req.query:');
  //   console.table(config.params);
  // }
  // if (config.data) {
  //   console.log('%c%s', `color: ${CONSOLE_COLOR}`, 'req.body:');
  //   console.table(config.data);
  // }
  return config;
});
fetchingAPI.interceptors.response.use(
  (res) => {
    const { data, config } = res;
    console.log(
      '%c%s',
      `color: ${CONSOLE_COLOR}`,
      `${config.method.toUpperCase()} ${config.url} | data:`
    );
    if (Array.isArray(data)) console.log(data);
    else console.table(data);
    return res;
  },
  (err) => {
    if (
      err.response.status === 401 &&
      AUTH_LINKS.indexOf(window.location.pathname) === -1
    ) {
      window.location.href = `/login?redirectUrl=${window.location.pathname}`;
    }
    throw err;
  }
);

export const getBrandList = async () => {
  try {
    const res = await fetchingAPI.get('/brand');
    const { data } = res;
    return data;
  } catch (err) {
    console.log('getBrandList error: ', err);
  }
};

async function getCrawlerTargetList(shopId) {
  try {
    const res = await fetchingAPI.get('/crawler', {
      params: {
        shopId,
      },
    });
    const { data } = res;
    return data;
  } catch (err) {
    console.log(err);
  }
}

function handleCrawlerItems(body) {
  return fetchingAPI.post('/crawler/item', body);
}

async function getFetchingAPIPromotion(id) {
  try {
    const res = await fetchingAPI.get(`/promotion/${id}`, {
      params: {
        isExtended: true,
      },
    });
    const { data } = res;
    return data;
  } catch (err) {
    alert(`
    📌 에러가 발생했습니다.! 
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생 
    GET - /promotions/${id}
    ${err}`);
    console.log(err);
  }
}

function removeExhibitionCategory(exhibitionId, categoryId) {
  return fetchingAPI.delete(`/exhibition/${exhibitionId}/categories/${categoryId}`);
}

function createProductInExhibitionCategory(exhibitionId, categoryId, payload) {
  return fetchingAPI.post(
    `/exhibition/${exhibitionId}/categories/${categoryId}`,
    payload
  );
}

function getItemsExhibitionCategory(exhibitionId, categoryId) {
  return fetchingAPI.get(`/exhibition/${exhibitionId}/categories/${categoryId}`, {
    params: { isExtended: true, limit: 500 },
  });
}

function getItemsInExhibitionCategory(exhibitionId, categoryId) {
  return fetchingAPI.get(`/exhibition/${exhibitionId}/categories/${categoryId}/ids`);
}

function createExhibitionCategory(exhibitionId, name) {
  return fetchingAPI.post(`/exhibition/${exhibitionId}/categories/`, { name });
}

function getExhibitionCategories(exhibitionId) {
  return fetchingAPI.get(`/exhibition/${exhibitionId}/categories/`);
}

function getExhibition(exhibitionId) {
  return fetchingAPI.get(`/exhibition/${exhibitionId}`, { params: { isExtended: true } });
}

function updateExhibition(exhibitionId, payload) {
  return fetchingAPI.put(`/exhibition/${exhibitionId}/`, payload);
}

function getItemsInExhibition(exhibitionId, params = {}) {
  return fetchingAPI.get(`/exhibition/${exhibitionId}/items`, {
    params,
  });
}

function getExhibitions(params) {
  return fetchingAPI.get(`/exhibition`, {
    params: { ...params, isExtended: true },
  });
}

function saveCustomItemsOrders(payload) {
  return fetchingAPI.post(`/custom-item-orders`, payload);
}

function updateCustomItemOrders(customItemsOrderId, payload) {
  return fetchingAPI.put(`/custom-item-orders/${customItemsOrderId}`, payload);
}

function createItem(payload) {
  return fetchingAPI.post(`/item`, payload);
}

function getPriceData(itemId) {
  return fetchingAPI.get(`/item/${itemId}/prices`);
}

function getProductGroups(params) {
  return fetchingAPI.get('/group', { params });
}

function deleteProductGroup(id) {
  return fetchingAPI.delete(`/group/${id}`);
}

function deleteProductGroupAutomation(id) {
  return fetchingAPI.delete(`/group/${id}/automation`);
}

function postNewProuctGroup(data) {
  return fetchingAPI.post('/group', data);
}

function getProductGroupInfo(id) {
  return fetchingAPI.get(`/group/${id}`);
}

function getProductGroupAutomation(id) {
  return fetchingAPI.get(`/group/${id}/automation`);
}

function getProductGroupItems(id, params) {
  return fetchingAPI.get(`/group/${id}/item`, { params });
}

function putProductGroupInfo(id, payload) {
  return fetchingAPI.put(`/group/${id}`, payload);
}

function putProductGroupAutomation(id, payload) {
  return fetchingAPI.put(`/group/${id}/automation`, payload);
}

function putProductGroupsInfo(payload) {
  return fetchingAPI.put('/group', payload);
}

function putProductToProductGroup(id, payload) {
  return fetchingAPI.put(`/group/${id}/items`, payload);
}

function deleteProductInProductGroup(id, payload) {
  return fetchingAPI.delete(`/group/${id}/items`, {
    params: { items: payload },
  });
}

function putPromotionInfo(payload) {
  return fetchingAPI.put(`/promotion/v2`, payload);
}

function postPromotion(payload) {
  return fetchingAPI.post(`/promotion/v2`, payload);
}

function putPromotion(id, payload) {
  return fetchingAPI.put(`/promotion/v2/${id}`, payload);
}

function postPromotionRule(id, payload) {
  fetchingAPI.post(`/promotion/v2/${id}/rule`, payload);
}

function deletePromotionRule(id, ruleId) {
  fetchingAPI.delete(`/promotion/v2/${id}/rule/${ruleId}`);
}

function getPromotion(id) {
  return fetchingAPI.get(`/promotion/v2/${id}`, {
    params: {
      isExtended: true,
    },
  });
}

function getItemsInPromotion(id, params) {
  return fetchingAPI.get(`/promotion/v2/${id}/items/`, {
    params,
  });
}

function getUIComponents(gender) {
  return fetchingAPI.get(`/ui-component/list/${gender}/test`);
}

function postUIComponents(gender, uiComponentList) {
  return fetchingAPI.post(`/ui-component/list/${gender}/test`, {
    uiComponentList,
  });
}

function mergeMainIntoTest(gender) {
  return fetchingAPI.post(`/ui-component/live-to-test/${gender}`, null);
}

function mergeTestIntoMain(gender) {
  return fetchingAPI.post(`/ui-component/test-to-live/${gender}`, null);
}

export default {
  getCrawlerTargetList,
  handleCrawlerItems,
  getFetchingAPIPromotion,
  removeExhibitionCategory,
  createExhibitionCategory,
  getItemsInExhibitionCategory,
  createProductInExhibitionCategory,
  getExhibitionCategories,
  getExhibition,
  updateExhibition,
  getItemsInExhibition,
  getExhibitions,
  saveCustomItemsOrders,
  createItem,
  getPriceData,
  getProductGroups,
  deleteProductGroup,
  postNewProuctGroup,
  getProductGroupInfo,
  getProductGroupAutomation,
  getProductGroupItems,
  putProductGroupInfo,
  putProductGroupAutomation,
  putProductGroupsInfo,
  deleteProductGroupAutomation,
  putProductToProductGroup,
  deleteProductInProductGroup,
  putPromotionInfo,
  putPromotion,
  postPromotion,
  getPromotion,
  getItemsInPromotion,
  postPromotionRule,
  deletePromotionRule,
  getUIComponents,
  postUIComponents,
  mergeMainIntoTest,
  mergeTestIntoMain,
  updateCustomItemOrders,
  getItemsExhibitionCategory,
};
