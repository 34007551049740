import styled from 'styled-components';

export const ItemWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Inner = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  height: 300px;
  overflow: auto;
  margin: 5px;
  @media all and (min-width: 1400px) {
    height: ${(props) => (props.height ? props.height : '300px')};
  }
`;

export const ItemTitle = styled.strong`
  margin-right: 15px;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBox = styled.input`
  min-width: 20px;
  min-height: 20px;
`;

export const ItemText = styled.p`
  margin: 0;
  padding: 7px 10px;
`;
