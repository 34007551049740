import {
  CallProductModalButtons,
  ChangeProductInfoGroup,
  ItemTableTr,
} from 'components/UI';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modals } from '..';
import * as style from './style';

const ItemTable = ({
  items,
  searchOptionTableState,
  setSearchOptionTableState,
  fetchItems,
  currentPage,
  totalCount,
  trimParams,
}) => {
  const history = useHistory();

  const [checkedItemIds, setCheckedItemIds] = useState([]);
  const [checkedItemCafe24Ids, setCheckedItemCafe24Ids] = useState([]);
  const [payload, setPayload] = useState({});
  const [showModal, setShowModal] = useState({
    PriorityChange: false,
    ItemShow: false,
    Exhibition: false,
    ColorChange: false,
    Promotion: false,
    OriginChange: false,
    Download: false,
    Cafe24Upload: false,
    ExhibitionTab: false,
    ProductGroup: false,
    targetIds: [],
  });

  const containChecked = useCallback(() => {
    setCheckedItemIds(checkedItemIds);
    setCheckedItemCafe24Ids(checkedItemCafe24Ids);
  }, [setCheckedItemIds, setCheckedItemCafe24Ids, checkedItemIds, checkedItemCafe24Ids]);

  useEffect(() => {
    setCheckedItemIds([]);
    setCheckedItemCafe24Ids([]);
    setPayload({});
  }, [items]);
  return (
    <>
      <Modals
        showModal={showModal}
        setShowModal={setShowModal}
        fetchItems={fetchItems}
        currentPage={currentPage}
        checkedItemIds={checkedItemIds}
        searchOptionTableState={searchOptionTableState}
        trimParams={trimParams}
        checkedItemCafe24Ids={checkedItemCafe24Ids}
        setCheckedItemIds={setCheckedItemIds}
        setCheckedItemCafe24Ids={setCheckedItemCafe24Ids}
      />
      <span style={{ fontSize: '12px', paddingBottom: '7px' }}>총 {totalCount}개</span>
      <style.Table>
        <style.Tr style={{ background: '#BEBEBE', padding: '13px 22px' }}>
          <ChangeProductInfoGroup
            showModal={showModal}
            setShowModal={setShowModal}
            checkedItemIds={checkedItemIds}
            searchOptionTableState={searchOptionTableState}
            trimParams={trimParams}
          />
          <CallProductModalButtons
            searchOptionTableState={searchOptionTableState}
            setSearchOptionTableState={setSearchOptionTableState}
            showModal={showModal}
            setShowModal={setShowModal}
            checkedItemIds={checkedItemIds}
            fetchItems={fetchItems}
            currentPage={currentPage}
            payload={payload}
            setPayload={setPayload}
            containChecked={containChecked}
          />
        </style.Tr>
        <style.Tr style={{ background: '#E4E4E4' }}>
          <style.flexibleTd
            widthPcnt={39}
            onClick={() => {
              if (checkedItemIds.length === items.length) {
                setCheckedItemIds([]);
                setCheckedItemCafe24Ids([]);
              } else {
                setCheckedItemIds(items.map((obj) => obj.id));
                setCheckedItemCafe24Ids(items.map((obj) => obj.cafe24Id));
              }
            }}
          >
            <style.Checkbox
              onChange={() => {
                if (checkedItemIds.length === items.length) {
                  setCheckedItemIds([]);
                  setCheckedItemCafe24Ids([]);
                } else {
                  setCheckedItemIds(items.map((obj) => obj.id));
                  setCheckedItemCafe24Ids(items.map((obj) => obj.cafe24Id));
                }
              }}
              checked={checkedItemIds.length === items.length}
            />
          </style.flexibleTd>
          {history.location.pathname.includes(
            '/product/productList/manage-group/modify-group/'
          ) ? (
            <style.flexibleTd widthPcnt={50}>상태</style.flexibleTd>
          ) : null}
          <style.flexibleTd widthPcnt={105}>페칭코드</style.flexibleTd>
          <style.flexibleTd widthPcnt={115}>편집샵 코드</style.flexibleTd>
          <style.flexibleTd widthPcnt={89}>이미지</style.flexibleTd>
          <style.flexibleTd widthPcnt={102}>브랜드</style.flexibleTd>
          {history?.location?.pathname === '/product/productList' ? (
            <style.flexibleTd widthPcnt={102}>편집샵(국가)</style.flexibleTd>
          ) : null}

          <style.flexibleTd widthPcnt={155}>상품명</style.flexibleTd>

          <style.flexibleTd widthPcnt={53}>재고</style.flexibleTd>
          <style.flexibleTd widthPcnt={97}>활성 상태</style.flexibleTd>
          <style.flexibleTd widthPcnt={106}>이동 후 결제</style.flexibleTd>
          <style.flexibleTd widthPcnt={105}>페칭 결제</style.flexibleTd>
          <style.flexibleTd widthPcnt={109}>상품 기본가</style.flexibleTd>
          <style.flexibleTd widthPcnt={83}>프로모션</style.flexibleTd>
          <style.flexibleTd widthPcnt={96}>편집샵 판매가</style.flexibleTd>
          <style.flexibleTd widthPcnt={96}>페칭 판매가</style.flexibleTd>
          <style.flexibleTd widthPcnt={108}>상품정보 업데이트</style.flexibleTd>

          <style.flexibleTd widthPcnt={65}>우선순위</style.flexibleTd>
          <style.flexibleTd widthPcnt={55}>조회수</style.flexibleTd>
          <style.flexibleTd widthPcnt={51}>좋아요</style.flexibleTd>
          <style.flexibleTd widthPcnt={63}>장바구니</style.flexibleTd>
          <style.flexibleTd widthPcnt={54} noBorder>
            판매수
          </style.flexibleTd>
        </style.Tr>
        {items.map((item, i) => (
          <ItemTableTr
            key={item.id}
            item={item}
            i={i}
            checkedItemIds={checkedItemIds}
            setCheckedItemIds={setCheckedItemIds}
            checkedItemCafe24Ids={checkedItemCafe24Ids}
            setCheckedItemCafe24Ids={setCheckedItemCafe24Ids}
            payload={payload}
            setPayload={setPayload}
          />
        ))}
      </style.Table>
    </>
  );
};

export default ItemTable;
