import styled from 'styled-components';

export const Outer = styled.div`
  width: 100%;
`;

export const Table = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 20px;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  white-space: nowrap;
  overflow: auto;
  cursor: pointer;
  &:active,
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const TableItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width}%;
  font-weight: bold;
  height: 150px;
  white-space: nowrap;
  overflow: auto;
  ${(props) => props.isActive === '활성화' && 'color: #FF6F61;'}
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 40px;
  height: 40px;
`;

export const Box = styled.div`
  text-align: center;
`;

export const Div = styled.div`
  margin-top: 10px;
`;
