import { Image } from 'antd';
import {
  Button,
  Card,
  UpdateInvoiceModal,
  Check,
  FormItem,
  GridTable,
  NumberStat,
  OpenLink,
  ReInput,
  ShippingTrackingModal,
  Spinner,
  Textarea,
} from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  fileUpload,
  ORDER_RETURN_STATUS,
  ORDER_REFUND_REASON,
  PAY_METHOD,
  request,
  useObject,
} from 'services';
import useSWR from 'swr';
import { copyText, priceFormat } from 'utils';

const TaxItem: FunctionComponent<{
  url: string;
  name: string;
  onUpload: () => void;
  isLoading: boolean;
}> = ({ url, name, onUpload, isLoading }) => {
  return (
    <div className="flex">
      <div className="w-52">
        <Check checked={!!url} />
        <span className="text-sm ml-1">{name}</span>
      </div>
      <div className="flex gap-5">
        <Button size="xs" theme="secondary" isLoading={isLoading} onClick={onUpload}>
          업로드
        </Button>
        {!!url && (
          <OpenLink url={url}>
            {url.length > 10 ? `${url.slice(0, 10)}...` : url}
          </OpenLink>
        )}
        {!!url && !url.includes('.pdf') && (
          <Image src={url} style={{ width: 40, height: 40 }} />
        )}
      </div>
    </div>
  );
};

interface IOrderReturn {
  dutyRefundInfo: {
    bankbookCopyFileUrl: string;
    invoiceFileUrl: string;
    refundMemo: string;
    residentRegistrationNumber: string;
    shopCancelFileUrl: string;
    shopDeliveryPage: string;
    importDeclarationFileUrl: string;
    orderRefundNumber: string;
    taxRefundStatus: keyof typeof ORDER_RETURN_STATUS;
  };
  payInfo: {
    deliveryFee: number;
    discountAmount: number;
    dutyAndTax: number;
    educationTax: number;
    itemAmount: number;
    payAmount: number;
    point: number;
    specialTax: number;
    totalTax: number;
  };
  refundInfo: {
    additionalPayAmount: number;
    couponDiscount: number;
    deliveryFee: number;
    itemAmount: number;
    naverCash: number;
    naverMileage: number;
    payMethod: keyof typeof PAY_METHOD;
    paymentFee: number;
    productAdditionalDiscount: number;
    refundAmount: number;
    refundPoint: number;
    userTypeDiscount: number;
  };
  returnInfo: {
    fetchingOrderNumber: string;
    orderReturnNumber: string;
    requestedAt: string;
    orderedAt: string;
    shippingCompanyName: string;
    invoice: string;
    shippingCode: string;
    returnStatus: keyof typeof ORDER_RETURN_STATUS;
    reasonDirectInput: string;
    reasonType: keyof typeof ORDER_REFUND_REASON;
    orderUserName: string;
    orderPhoneNumber: string;
    recipientName: string;
    recipientPhoneNumber: string;
  };
  returnItemList: any[];
  returnRequestList: any[];
  shopShippingList: Array<{
    invoice: string;
    invoiceFileUrl: string;
    returnShopNumber: string;
    shippingCode: string;
    shippingCompanyName: string;
    shopCancelFileUrl: string;
    shopDeliveryPage: string;
    shopId: number;
    shopName: string;
    shopUrl: string;
  }>;
  imageList: Array<{
    imageUrl: string;
  }>;
}

interface State {
  selectedRequestIdx: number[];
  selectedInProgressIdx: number[];
  isChangeInvoiceOpen: boolean;
  data: IOrderReturn | undefined;
  isLoading: boolean;
  invoice: string;
  shippingCode: string;
  invoiceType: 'fetching' | 'shop';
  returnShopNumber: string;
  isShippingInfoOpen: boolean;
  isUploading: boolean;
  isTaxCompleteChanging: boolean;
  refundMemo: string;
  isInitialFetched: boolean;
}

const OrderReturnDetailPage = () => {
  const { orderReturnNumber } = useParams<{ orderReturnNumber: string }>();
  const swr = useSWR(
    `/commerce/order/order-return/${orderReturnNumber}`,
    (url) => request.get<any, IOrderReturn>(url),
    { refreshInterval: 1000 * 60 * 60 }
  );
  const [
    {
      selectedRequestIdx,
      selectedInProgressIdx,
      isChangeInvoiceOpen,
      data,
      isLoading,
      invoice,
      shippingCode,
      invoiceType,
      returnShopNumber,
      isShippingInfoOpen,
      isUploading,
      isTaxCompleteChanging,
      refundMemo,
      isInitialFetched,
    },
    setState,
    onChange,
  ] = useObject<State>({
    selectedRequestIdx: [],
    selectedInProgressIdx: [],
    isChangeInvoiceOpen: false,
    data: swr.data,
    isLoading: false,
    invoice: '',
    shippingCode: '',
    invoiceType: 'fetching',
    returnShopNumber: '',
    isShippingInfoOpen: false,
    isUploading: false,
    isTaxCompleteChanging: false,
    refundMemo: '',
    isInitialFetched: false,
  });

  const refresh = async () => {
    try {
      const data = await request.get<any, IOrderReturn>(
        `/commerce/order/order-return/${orderReturnNumber}`
      );
      setState({ data });
    } catch (err) {
      console.log(err);
    }
  };

  const onUpdateStatus = async (
    status: keyof typeof ORDER_RETURN_STATUS,
    listType: 'request' | 'progress'
  ) => {
    let message = '반품 상태를 전환하시겠습니까?';
    if (status === 'ACCEPT') message = '반품 접수로 전환하시겠습니까?';
    else if (status === 'HOLD') message = '반품 보류로 전환하시겠습니까?';
    else if (status === 'REJECT') message = '반품 접수거부 및 철회로 전환하시겠습니까?';

    let data: any = {
      returnItemNumberList: [],
      status,
    };
    if (listType === 'request') data.returnItemNumberList = selectedRequestIdx;
    else if (listType === 'progress') data.returnItemNumberList = selectedInProgressIdx;

    if (!data.returnItemNumberList.length) {
      toast.info('상품을 최소 1개 이상 선택해주세요.');
      return;
    }
    if (!window.confirm(message)) return;
    setState({ isLoading: true });
    try {
      await request.put('/commerce/order/order-return/status', data);
      toast.success('전환되었습니다.');
      refresh();
      let state: Partial<State> = {
        isLoading: false,
      };
      if (listType === 'request') state.selectedRequestIdx = [];
      else if (listType === 'progress') state.selectedInProgressIdx = [];
      setState(state);
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };

  const onTaxChangeComplete = async () => {
    if (!data) return;
    if (!window.confirm('환급 완료로 전환하시겠습니까?')) return;

    setState({ isTaxCompleteChanging: true });
    try {
      await request.put(
        `/commerce/order/order-refund/tax/${data.dutyRefundInfo.orderRefundNumber}`,
        {
          bankbookCopyFileUrl: data.dutyRefundInfo.bankbookCopyFileUrl || null,
          importDeclarationFileUrl: data.dutyRefundInfo.importDeclarationFileUrl || null,
          refundedAt: `${dayjs().format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
          refundMemo,
          residentRegistrationNumber:
            data.dutyRefundInfo.residentRegistrationNumber || null,
          taxRefundStatus: 'ACCEPT',
        }
      );
      toast.success('환급 완료로 전환되었습니다.');
      refresh();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isTaxCompleteChanging: false });
    }
  };

  const onTaxFileUpload = (
    state: keyof IOrderReturn['dutyRefundInfo'],
    accept: string
  ) => {
    if (!data) return;

    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.onchange = async () => {
      if (!input.files) return;
      const file = input.files[0];
      setState({ isUploading: true });
      try {
        const url = await fileUpload(file);
        let payload: any = {
          bankbookCopyFileUrl: data.dutyRefundInfo.bankbookCopyFileUrl || null,
          importDeclarationFileUrl: data.dutyRefundInfo.importDeclarationFileUrl || null,
          refundedAt: null,
          refundMemo,
          taxRefundStatus: 'IN_PROGRESS',
        };
        payload[state] = url;
        await request.put(
          `/commerce/order/order-refund/tax/${data.dutyRefundInfo.orderRefundNumber}`,
          payload,
          {
            headers: {
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          }
        );
        refresh();
      } catch (err) {
        console.log(err);
      } finally {
        setState({ isUploading: false });
      }
    };
    input.click();
  };

  const onShopTaxFileUpload = (
    returnShopNumber: string,
    state: 'invoiceFileUrl' | 'returnLabelUrl'
  ) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async () => {
      if (!input.files) return;
      const file = input.files[0];
      setState({ isUploading: true });
      try {
        const url = await fileUpload(file);
        await request.put(
          `/commerce/order/order-return/shop/${returnShopNumber}/shipping`,
          { [state]: url }
        );
        refresh();
      } catch (err) {
        console.log(err);
      }
      setState({ isUploading: false });
    };
    input.click();
  };

  useEffect(() => {
    if (swr.data) {
      if (isInitialFetched)
        setState({
          refundMemo: swr.data.dutyRefundInfo.refundMemo,
          data: swr.data,
          isInitialFetched: false,
        });
      else setState({ data: swr.data });
    }
  }, [swr.data]);

  if (!data) {
    return (
      <div className="min-h-screen bg-black p-4">
        <div
          style={{ minHeight: 'calc(100vh - 42px)' }}
          className="bg-white rounded p-4 flex items-center justify-center"
        >
          <Spinner className="h-5 w-5 text-black" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="min-h-screen bg-black p-4">
        <div style={{ minHeight: 'calc(100vh - 42px)' }} className="bg-white rounded p-4">
          <div className="wrapper-title">
            반품처리 - {ORDER_RETURN_STATUS[data.returnInfo.returnStatus]}
          </div>
          <Card
            items={[
              {
                name: '주문자명',
                renderItem: () =>
                  `${data.returnInfo.orderUserName} (${data.returnInfo.orderPhoneNumber})`,
              },
              {
                name: '수령자명',
                renderItem: () =>
                  `${data.returnInfo.recipientName} (${data.returnInfo.recipientPhoneNumber})`,
              },
              {
                name: '주문번호',
                renderItem: () => (
                  <OpenLink url={`/order/${data.returnInfo.fetchingOrderNumber}`}>
                    {data.returnInfo.fetchingOrderNumber}
                  </OpenLink>
                ),
              },
              {
                name: '반품번호',
                renderItem: () => data.returnInfo.orderReturnNumber,
              },
              {
                name: '주문일자',
                renderItem: () =>
                  dayjs(data.returnInfo.orderedAt).format('YYYY.MM.DD HH:mm:ss'),
              },
              {
                name: '신청일자',
                renderItem: () =>
                  dayjs(data.returnInfo.requestedAt).format('YYYY.MM.DD HH:mm:ss'),
              },
            ]}
          />
          <div className="mt-14">
            <div className="wrapper-title">반품 요청상품</div>
            <div className="my-3 flex gap-2">
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('IN_PROGRESS', 'request')}
              >
                반품 접수
              </Button>
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('REJECT', 'request')}
              >
                반품 접수거부
              </Button>
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('HOLD', 'request')}
              >
                반품 보류
              </Button>
            </div>
            <GridTable
              columns={[
                '판매처 (국가)',
                '편집샵 계정',
                '상품별 주문번호',
                '신청일 (접수일)',
                '상품명 / 옵션',
                '반품사유',
                '수량',
                '취소수량',
                '비용',
                '금액',
                '세금',
              ]}
              renderItem={(item, key) => (
                <div
                  key={key}
                  onClick={() =>
                    setState({
                      selectedRequestIdx:
                        selectedRequestIdx.indexOf(item.returnItemNumber) !== -1
                          ? selectedRequestIdx.filter(
                              (returnItemNumber) =>
                                returnItemNumber !== item.returnItemNumber
                            )
                          : [...selectedRequestIdx, item.returnItemNumber],
                    })
                  }
                >
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={selectedRequestIdx.indexOf(item.returnItemNumber) !== -1}
                    />
                  </div>
                  <div aria-label="판매처 (국가)">
                    <div>{item.shopName}</div>
                    <div>({item.shopCountry})</div>
                  </div>
                  <div aria-label="편집샵 계정">
                    <div>{item.fetchingShopAccount}</div>
                    {!!item.vendorOrderNumber && (
                      <div>편집샵 주문번호 : {item.vendorOrderNumber}</div>
                    )}
                    {!!item.cardApprovalNumber && (
                      <div>카드 승인번호 : {item.cardApprovalNumber}</div>
                    )}
                    {!!item.firstInvoice && <div>운송장 번호 : {item.firstInvoice}</div>}
                  </div>
                  <div aria-label="상품별 주문번호">
                    <OpenLink url={`/order/${data.returnInfo.fetchingOrderNumber}`}>
                      {item.itemOrderNumber}
                    </OpenLink>
                  </div>
                  <div aria-label="신청일 (접수일)">
                    <div>{dayjs(item.requestedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                    {!!item.statusUpdatedAt && (
                      <div className="text-gray-400">
                        ({dayjs(item.statusUpdatedAt).format('YYYY.MM.DD HH:mm:ss')})
                      </div>
                    )}
                  </div>
                  <div aria-label="상품명 / 옵션">
                    <div className="flex gap-3">
                      <img
                        className="max-w-[3.5rem] object-contain"
                        src={item.itemImageUrl}
                        alt={item.itemName}
                      />
                      <div className="flex-1">
                        <div>{item.brandName}</div>
                        <OpenLink
                          url={`/product/productDetail/v2/${Number(
                            item.itemId
                          )}?mode=update`}
                        >
                          {item.itemName}
                        </OpenLink>
                        <div className="text-red-400 mt-4">{item.sizeName}</div>
                      </div>
                    </div>
                  </div>
                  <div aria-label="반품사유">
                    <div>{ORDER_REFUND_REASON[item.reasonType]}</div>
                    {!!item.reasonDirectInput && (
                      <div>선택사유명: {item.reasonDirectInput}</div>
                    )}
                    {!!data.imageList.length && (
                      <div className="mt-2 flex flex-wrap gap-1">
                        {data.imageList.map(({ imageUrl }, imageIndex) => (
                          <img
                            src={imageUrl}
                            alt=""
                            className="max-w-[3.5rem] object-contain"
                            key={imageIndex}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div aria-label="수량">{item.quantity}</div>
                  <div aria-label="취소수량">
                    <ReInput
                      size="xs"
                      type="number"
                      min={1}
                      defaultValue={1}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </div>
                  <div aria-label="비용">
                    <div className="space-y-4">
                      <FormItem label="반품배송비">
                        <NumberStat value={item.returnFee} />
                      </FormItem>
                      <FormItem label="상품환불비용">
                        <NumberStat value={item.refundAmount} />
                      </FormItem>
                    </div>
                  </div>
                  <div aria-label="금액">
                    <div className="space-y-4">
                      <FormItem label="주문금액">
                        <NumberStat value={item.originAmount} />
                      </FormItem>
                      <FormItem label="실결제금액">
                        <NumberStat value={item.payAmount} />
                      </FormItem>
                      <FormItem label="할인금액">
                        쿠폰할인 : <NumberStat value={item.couponDiscountAmount} />
                      </FormItem>
                    </div>
                  </div>
                  <div aria-label="세금">
                    <div className="space-y-4">
                      <FormItem label="관부가세">
                        <NumberStat value={item.duty + item.vat} />
                      </FormItem>
                      <FormItem label="특소세">
                        <NumberStat value={item.specialTax} />
                      </FormItem>
                      <FormItem label="교육세">
                        <NumberStat value={item.educationTax} />
                      </FormItem>
                    </div>
                  </div>
                </div>
              )}
              list={data.returnRequestList}
              isAllSelected={
                !!selectedRequestIdx.length &&
                selectedRequestIdx.length === data.returnRequestList.length
              }
              onSelectAll={(checked) =>
                setState({
                  selectedRequestIdx: checked
                    ? data.returnRequestList.map((item) => item.returnItemNumber)
                    : [],
                })
              }
            />
          </div>

          <div className="mt-14">
            <div className="wrapper-title">반품처리중 상품</div>
            <div className="my-3">
              <Button
                size="sm"
                isLoading={isLoading}
                onClick={() => onUpdateStatus('REJECT', 'progress')}
              >
                반품철회
              </Button>
            </div>
            <GridTable
              columns={[
                '상품별 주문번호',
                '신청일 (접수일)',
                '판매처 (국가)',
                '편집샵 계정',
                '상품명 / 옵션',
                '반품사유',
                '수량',
                '처리상태',
              ]}
              renderItem={(item, key) => (
                <div
                  key={key}
                  onClick={() =>
                    setState({
                      selectedInProgressIdx:
                        selectedInProgressIdx.indexOf(item.returnItemNumber) !== -1
                          ? selectedInProgressIdx.filter(
                              (returnItemNumber) =>
                                returnItemNumber !== item.returnItemNumber
                            )
                          : [...selectedInProgressIdx, item.returnItemNumber],
                    })
                  }
                >
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={
                        selectedInProgressIdx.indexOf(item.returnItemNumber) !== -1
                      }
                    />
                  </div>
                  <div aria-label="상품별 주문번호">
                    <OpenLink url={`/order/${data.returnInfo.fetchingOrderNumber}`}>
                      {item.itemOrderNumber}
                    </OpenLink>
                  </div>
                  <div aria-label="신청일 (접수일)">
                    <div>{dayjs(item.requestedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                    {!!item.statusUpdatedAt && (
                      <div className="text-gray-400">
                        ({dayjs(item.statusUpdatedAt).format('YYYY.MM.DD HH:mm:ss')})
                      </div>
                    )}
                  </div>
                  <div aria-label="판매처 (국가)">
                    <div>{item.shopName}</div>
                    <div>({item.shopCountry})</div>
                  </div>
                  <div aria-label="편집샵 계정">
                    <div>{item.fetchingShopAccount}</div>
                    {!!item.vendorOrderNumber && (
                      <div>편집샵 주문번호 : {item.vendorOrderNumber}</div>
                    )}
                    {!!item.cardApprovalNumber && (
                      <div>카드 승인번호 : {item.cardApprovalNumber}</div>
                    )}
                    {!!item.firstInvoice && <div>운송장 번호 : {item.firstInvoice}</div>}
                  </div>
                  <div aria-label="상품명 / 옵션">
                    <div className="flex gap-3">
                      <img
                        className="max-w-[3.5rem] object-contain"
                        src={item.itemImageUrl}
                        alt={item.itemName}
                      />
                      <div className="flex-1">
                        <div>{item.brandName}</div>
                        <OpenLink
                          url={`/product/productDetail/v2/${Number(
                            item.itemId
                          )}?mode=update`}
                        >
                          {item.itemName}
                        </OpenLink>
                        <div className="text-red-400 mt-4">{item.sizeName}</div>
                      </div>
                    </div>
                  </div>
                  <div aria-label="반품사유">
                    <div>{ORDER_REFUND_REASON[item.reasonType]}</div>
                    {!!item.reasonDirectInput && (
                      <div>선택사유명: {item.reasonDirectInput}</div>
                    )}
                    {!!data.imageList.length && (
                      <div className="mt-2 flex flex-wrap gap-1">
                        {data.imageList.map(({ imageUrl }, imageIndex) => (
                          <img
                            src={imageUrl}
                            alt=""
                            className="max-w-[3.5rem] object-contain"
                            key={imageIndex}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                  <div aria-label="수량">{item.quantity}</div>
                  <div aria-label="처리상태">
                    {ORDER_RETURN_STATUS[item.returnStatus]}
                  </div>
                </div>
              )}
              list={data.returnItemList}
              isAllSelected={
                !!selectedInProgressIdx.length &&
                selectedInProgressIdx.length === data.returnItemList.length
              }
              onSelectAll={(checked) =>
                setState({
                  selectedInProgressIdx: checked
                    ? data.returnItemList.map((item) => item.returnItemNumber)
                    : [],
                })
              }
            />
            <div className="flex gap-5 mt-4 flex-wrap">
              <div>
                <div className="font-semibold">페칭 택배사</div>
                <div className="mt-3">택배사: {data.returnInfo.shippingCompanyName}</div>
                <div className="mb-3">운송장번호: {data.returnInfo.invoice}</div>
                <div className="flex gap-1">
                  <Button
                    theme="secondary"
                    size="sm"
                    onClick={() =>
                      setState({
                        isShippingInfoOpen: true,
                        invoice: data.returnInfo.invoice,
                        shippingCode: data.returnInfo.shippingCode,
                      })
                    }
                  >
                    배송추적
                  </Button>
                  <Button
                    size="sm"
                    onClick={() =>
                      setState({
                        isChangeInvoiceOpen: true,
                        invoice: data.returnInfo.invoice,
                        shippingCode: data.returnInfo.shippingCode,
                        invoiceType: 'fetching',
                      })
                    }
                  >
                    송장변경
                  </Button>
                </div>
              </div>
              {data.shopShippingList.map((item, key) => (
                <div key={key}>
                  <div className="font-semibold">
                    <OpenLink url={item.shopUrl}>{item.shopName}</OpenLink> 택배사
                  </div>
                  <div className="mt-3">택배사: {item.shippingCompanyName}</div>
                  <div className="mb-3">운송장번호: {item.invoice}</div>
                  <div className="flex gap-1">
                    <Button
                      theme="secondary"
                      size="sm"
                      onClick={() =>
                        setState({
                          isShippingInfoOpen: true,
                          invoice: item.invoice,
                          shippingCode: item.shippingCode,
                        })
                      }
                    >
                      배송추적
                    </Button>
                    <Button
                      size="sm"
                      onClick={() =>
                        setState({
                          isChangeInvoiceOpen: true,
                          invoice: item.invoice,
                          shippingCode: item.shippingCode,
                          invoiceType: 'shop',
                          returnShopNumber: item.returnShopNumber,
                        })
                      }
                    >
                      송장변경
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-14">
            <div className="wrapper-title">결제 정보</div>
            <div className="mt-3">
              <GridTable
                columns={[
                  '상품구매금액',
                  '배송비',
                  '관부가세',
                  '특소세',
                  '교육세',
                  '할인혜택',
                  '적립헤택',
                  '총 실결제 금액',
                ]}
                noSelection
                list={[{}]}
                renderItem={(_, key) => (
                  <div key={key}>
                    <div>
                      <NumberStat value={data.payInfo.itemAmount} />
                    </div>
                    <div>
                      <NumberStat value={data.payInfo.deliveryFee} />
                    </div>
                    <div>
                      <NumberStat value={data.payInfo.dutyAndTax} />
                    </div>
                    <div>
                      <NumberStat value={data.payInfo.specialTax} />
                    </div>
                    <div>
                      <NumberStat value={data.payInfo.educationTax} />
                    </div>
                    <div>
                      <NumberStat value={data.payInfo.discountAmount} />
                    </div>
                    <div>
                      <NumberStat value={data.payInfo.point} />
                    </div>
                    <div className="font-bold">
                      <NumberStat value={data.payInfo.payAmount} />
                    </div>
                  </div>
                )}
              />
            </div>
          </div>

          {data.returnInfo.returnStatus === 'ACCEPT' && (
            <>
              <div className="wrapper-title mt-14">환불방식</div>
              <Card
                items={[
                  {
                    name: '환불금액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.refundAmount} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '환불금액상세',
                    renderItem: () => (
                      <div>
                        <div>
                          {priceFormat(data.refundInfo.itemAmount)}
                          <span className="text-gray-400 ml-1">(상품 구매 금액)</span>
                        </div>
                        <div>
                          - {priceFormat(data.refundInfo.couponDiscount)}
                          <span className="text-gray-400 ml-1">(쿠폰 할인)</span>
                        </div>
                        <div>
                          - {priceFormat(data.refundInfo.deliveryFee)}
                          <span className="text-gray-400 ml-1">(반품 배송비)</span>
                        </div>
                        <div>
                          - {priceFormat(data.refundInfo.productAdditionalDiscount)}
                          <span className="text-gray-400 ml-1">(상품별할인)</span>
                        </div>
                        <div>
                          - {priceFormat(data.refundInfo.userTypeDiscount)}
                          <span className="text-gray-400 ml-1">(회원등급할인)</span>
                        </div>
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '환불수단',
                    renderItem: () => PAY_METHOD[data.refundInfo.payMethod],
                    stretch: true,
                  },
                ]}
              />
              <div className="wrapper-title mt-14">환불금액 상세정보</div>
              <Card
                items={[
                  {
                    name: '상품구매금액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.itemAmount} />
                        <span className="ml-2">
                          <span className="text-gray-500">(차액결제 : </span>
                          <NumberStat value={data.refundInfo.additionalPayAmount} />)
                        </span>
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '반품배송비',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.deliveryFee} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '결제 수수료',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.paymentFee} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '상품별추가할인 취소액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.productAdditionalDiscount} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '회원등급할인 취소액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.userTypeDiscount} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '쿠폰할인 취소액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.couponDiscount} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '적립금 반환액',
                    renderItem: () => (
                      <div>
                        <NumberStat value={data.refundInfo.refundPoint} />
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '네이버 마일리지/캐쉬 반환액',
                    renderItem: () => (
                      <div>
                        <NumberStat
                          value={data.refundInfo.naverMileage + data.refundInfo.naverCash}
                        />
                        <span className="text-gray-500"> (네이버마일리지 </span>
                        <NumberStat value={data.refundInfo.naverMileage} />
                        <span className="text-gray-500"> / 네이버 캐쉬: </span>
                        <NumberStat value={data.refundInfo.naverCash} />
                        <span className="text-gray-500">)</span>
                      </div>
                    ),
                    stretch: true,
                  },
                ]}
              />
              <div className="wrapper-title mt-14">관세 환급</div>
              <Card
                items={[
                  {
                    name: '관세환급액',
                    renderItem: () => (
                      <div className="flex items-center gap-2">
                        <div>
                          <NumberStat value={data.payInfo.totalTax} />
                        </div>
                        <span className="text-gray-500">
                          {' '}
                          ({ORDER_RETURN_STATUS[data.dutyRefundInfo.taxRefundStatus]})
                        </span>
                        <Button
                          size="xs"
                          isLoading={isTaxCompleteChanging}
                          onClick={onTaxChangeComplete}
                        >
                          환급 완료로 전환
                        </Button>
                      </div>
                    ),
                    stretch: true,
                  },
                  {
                    name: '관부가세',
                    renderItem: () => <NumberStat value={data.payInfo.dutyAndTax} />,
                    stretch: true,
                  },
                  {
                    name: '특소세',
                    renderItem: () => <NumberStat value={data.payInfo.specialTax} />,
                    stretch: true,
                  },
                  {
                    name: '교육세',
                    renderItem: () => <NumberStat value={data.payInfo.educationTax} />,
                    stretch: true,
                  },
                ]}
              />
            </>
          )}
          <div className="mt-14">
            <div className="wrapper-title">메모</div>
            <div className="mt-3 flex gap-10">
              <div className="flex-1 relative">
                <Textarea
                  rows={8}
                  className="w-full p-1"
                  value={refundMemo}
                  name="refundMemo"
                  onChange={onChange}
                />
                <span className="absolute right-4 bottom-1">
                  <Button>asd</Button>
                </span>
              </div>
              <div className="flex-1">asd</div>
            </div>
          </div>
        </div>
      </div>
      <UpdateInvoiceModal
        isOpen={isChangeInvoiceOpen}
        onClose={() => setState({ isChangeInvoiceOpen: false })}
        apiUrl={
          invoiceType === 'fetching'
            ? `/commerce/order/order-return/fetching/${data.returnInfo.orderReturnNumber}/shipping`
            : `/commerce/order/order-return/shop/${returnShopNumber}/shipping`
        }
        invoice={invoice}
        shippingCode={shippingCode}
        onSuccess={refresh}
        type={invoiceType}
      />
      <ShippingTrackingModal
        isOpen={isShippingInfoOpen}
        onClose={() =>
          setState({ isShippingInfoOpen: false, invoice: '', shippingCode: '' })
        }
        invoice={invoice}
        shippingCode={shippingCode}
      />
    </>
  );
};

export default OrderReturnDetailPage;
