import React from 'react';
import * as style from './style';
import { reset } from '../../../../assets/images';
import { Spinner } from 'react-bootstrap';

const OptionCheckboxListPromotions = ({
  promotions,
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const { promotionIdList } = searchOptionTableState;

  return (
    <style.Container>
      <style.Title>
        <style.TitleSpan>프로모션</style.TitleSpan>
        <style.ResetButton
          src={reset}
          onClick={() => {
            setSearchOptionTableState({
              ...searchOptionTableState,
              promotionIdList: [],
            });
          }}
        />
      </style.Title>
      <style.Content>
        {promotions.length === 0 ?
          <Spinner
            style={{ margin: 'auto' }}
            color='black'
            animation='border'
          /> :
          <><style.CheckboxContainer>
            <style.Checkbox
              onClick={() => {
                if (promotions.length === promotionIdList.length) {
                  setSearchOptionTableState({
                    ...searchOptionTableState,
                    promotionIdList: [],
                  });
                } else {
                  setSearchOptionTableState({
                    ...searchOptionTableState,
                    promotionIdList: promotions.map(({ id }) => id),
                  });
                }
              }}
              checked={promotions.length === promotionIdList.length}
            />
            <style.Label>전체선택</style.Label>
          </style.CheckboxContainer>
            {promotions
              // .sort((a, b) => a.name > b.name ? 1 : -1)
              .map((obj, index) => (
                <style.CheckboxContainer key={index}>
                  <style.Checkbox
                    onClick={() => {
                      const promotionSet = new Set(promotionIdList);
                      if (promotionSet.has(obj.id)) {
                        promotionSet.delete(obj.id);
                        setSearchOptionTableState({
                          ...searchOptionTableState,
                          promotionIdList: [...promotionSet.values()],
                        });
                      } else {
                        setSearchOptionTableState({
                          ...searchOptionTableState,
                          promotionIdList: [...promotionSet.add(obj.id).values()],
                        });
                      }
                    }}
                    checked={promotionIdList.includes(obj.id)}
                  />
                  <style.Label>
                    {obj.shopName}-
                    {obj.gender === 'M' ? (
                      <span style={{ color: 'blue' }}>남</span>
                    ) : obj.gender === 'W' ? (
                      <span style={{ color: 'red' }}>여</span>
                    ) : (
                      <>
                        <span style={{ color: 'blue' }}>남</span>
                        <span style={{ color: 'red' }}>여</span>
                      </>
                    )}
                    -{obj.name}
                  </style.Label>
                </style.CheckboxContainer>
              ))}</>}
      </style.Content>
    </style.Container>
  );
};

export default OptionCheckboxListPromotions;
