import { PromotionAutomationModal } from 'components/templates';
import React, { useState } from 'react';
import * as S from './style';

const DiscountRule = ({ type, rule, setRuleKeyValue, onDelete }) => {

    const [showAutomationModal, setShowAutomationModal] = useState(false);

    return (
        <>
            {
                showAutomationModal &&
                <PromotionAutomationModal
                    setShow={setShowAutomationModal}
                    setAutomation={(value) => setRuleKeyValue('automation', value)}
                    data={rule.automation}
                />
            }
            <S.Container>
                <S.Input
                    type='number'
                    value={rule.typeValue || ''}
                    onChange={(e) => setRuleKeyValue('typeValue', Number(e.target.value))}
                    placeholder={type === 'DISCOUNT_WITH_RATE' ? '할인율 입력' : '할인금액 입력'}
                />
                <S.Input
                    value={rule.code || ''}
                    onChange={(e) => setRuleKeyValue('code', e.target.value)}
                    placeholder='코드 입력'
                />
                <S.Label><S.Radio checked={rule.automation === null} onChange={() => setRuleKeyValue('automation', null)} />수동 업데이트</S.Label>
                <S.Label><S.Radio checked={rule.automation !== null} onChange={() => setShowAutomationModal(true)} />자동 업데이트</S.Label>
                <S.A onClick={() => setShowAutomationModal(true)}>조건 설정하기</S.A>
                <S.ResetButton onClick={onDelete}>-</S.ResetButton>
            </S.Container>
        </>
    )
}

export default DiscountRule;

export const AutomationRule = ({ rule, setRuleKeyValue }) => {

    const [showAutomationModal, setShowAutomationModal] = useState(false);

    return (
        <>
            {
                showAutomationModal &&
                <PromotionAutomationModal
                    setShow={setShowAutomationModal}
                    setAutomation={(value) => setRuleKeyValue('automation', value)}
                    data={rule.automation}
                />
            }
            <S.Container style={{ marginBottom: '0' }}>
                <S.Label><S.Radio checked={rule.automation === null} onChange={() => setRuleKeyValue('automation', null)} />수동 업데이트</S.Label>
                <S.Label><S.Radio checked={rule.automation !== null} onChange={() => setShowAutomationModal(true)} />자동 업데이트</S.Label>
                <S.A onClick={() => setShowAutomationModal(true)}>조건 설정하기</S.A>
            </S.Container>
        </>
    );

}