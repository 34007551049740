import React from 'react';
import * as style from './style';

const PopularityTd = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  return (
    <style.Container>
      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              hasView: !searchOptionTableState.hasView,
            })
          }
          checked={searchOptionTableState.hasView}
          onChange={() => {}}
        />
        조회수 있음
      </style.Label>

      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              hasLike: !searchOptionTableState.hasLike,
            })
          }
          checked={searchOptionTableState.hasLike}
          onChange={() => {}}
        />
        좋아요 있음
      </style.Label>

      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              hasCart: !searchOptionTableState.hasCart,
            })
          }
          checked={searchOptionTableState.hasCart}
          onChange={() => {}}
        />
        장바구니 있음
      </style.Label>

      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              hasSales: !searchOptionTableState.hasSales,
            })
          }
          checked={searchOptionTableState.hasSales}
          onChange={() => {}}
        />
        판매수 있음
      </style.Label>
    </style.Container>
  );
};

export default PopularityTd;
