import { Button, Check, GridTable, NumberStat, OpenLink } from 'components';
import React, { Fragment, FunctionComponent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ORDER_PAY_STATUS, ORDER_REFUND_REASON, request, USER_TYPE } from 'services';
import useSWR from 'swr';
import classnames from 'classnames';
import dayjs from 'dayjs';

export interface Props {}
interface State {}

const OrderInfoReturn: FunctionComponent<Props> = () => {
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const { data } = useSWR('/commerce/order/order-return', (url) =>
    request.get<any, { data: any[]; count: number }>(url, {
      params: { fetchingOrderNumber },
      headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
    })
  );
  const { replace } = useHistory();
  return (
    <>
      <GridTable
        columns={[
          '반품번호 / 상품별 주문번호',
          '신청일',
          '주문자',
          '판매처 (국가 / 배송방식 / 관세조건)',
          '상품명 / 옵션',
          '반품사유',
          '배대지여부',
          '세금환급필요여부',
          '합산과세여부',
          '반품수출신고여부',
          '반품수수료',
          '반품수거운송장 / 해외배송사운송장',
          '상태',
          '관리자기능',
        ]}
        noSelection
        maxHeight="max-h-[512px]"
        list={data ? data.data : []}
        renderItem={(order, key) => (
          <div key={key}>
            <div aria-label="반품번호 / 상품별 주문번호" className="underline">
              <div className="mb-2">
                <OpenLink url={`/orders/return/${order.orderReturnNumber}`}>
                  {order.orderReturnNumber}
                </OpenLink>
              </div>
              {order.shopList.map((shop, shopIndex) => (
                <Fragment key={shopIndex}>
                  {shop.itemList.map((item, itemIndex) => (
                    <div key={itemIndex}>
                      <OpenLink url={`/order/${item.fetchingOrderNumber}?status=3&tab=1`}>
                        {item.itemOrderNumber}
                      </OpenLink>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
            <div aria-label="신청일">
              {dayjs(order.requestedAt).format('YYYY.MM.DD HH:mm:ss')}
            </div>
            <div aria-label="주문자">
              <span>{order.userName}</span>
              <div>{USER_TYPE[order.userType]}</div>
            </div>
            <div aria-label="판매처 (국가 / 배송방식 / 관세조건)">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div key={shopIndex} style={{ flex: shop.itemList.length }}>
                    {shop.shopName} ({shop.shopCountry} /{' '}
                    <span
                      className={classnames({
                        'text-fassto': shop.deliveryMethodName === '파스토',
                        'text-eldex': shop.deliveryMethodName === '엘덱스',
                      })}
                    >
                      {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                    </span>{' '}
                    /{' '}
                    <span>
                      {shop.isDdp === true && 'DDP'}
                      {shop.isDdp === false && 'DDU'}
                    </span>
                    )
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="상품명 / 옵션명">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    style={{ flex: shop.itemList.length }}
                    key={shopIndex}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <OpenLink
                          url={`/product/productDetail/v2/${Number(
                            item.itemId
                          )}?mode=update`}
                        >
                          {item.itemName}
                        </OpenLink>
                        <div className="text-red-400">{item.sizeName}</div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="반품사유">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        {ORDER_REFUND_REASON[item.reasonType]}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="배대지여부">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <Check checked={item.hasAdditionalDeliveryFee} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="세금환급필요여부">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <Check checked={item.needDutyRefund} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="합산과세여부">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <Check checked={item.isCombinedTax} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="반품수출신고여부">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <Check checked={item.needExportDeclaration} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="반품수수료">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <NumberStat value={item.returnFee} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="반품수거운송장 / 해외배송사운송장">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <div>
                          페칭 택배사 : {item.fetchingShippingCompanyName} (
                          {item.fetchingInvoice})
                        </div>
                        <div>
                          편집샵 택배사 : {item.shopShippingCompanyName} (
                          {item.shopInvoice})
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="상태">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    key={shopIndex}
                    style={{ flex: shop.itemList.length }}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <div className="link">{ORDER_PAY_STATUS[item.returnStatus]}</div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="관리자기능">
              <div className="flex flex-col gap-1 items-center">
                <Button
                  size="xs"
                  theme="primary"
                  onClick={() => window.open(`/orders/return/${order.orderReturnNumber}`)}
                >
                  관리
                </Button>
                <Button
                  size="xs"
                  onClick={() => replace(`/order/${fetchingOrderNumber}?tab=8`)}
                >
                  메모
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default OrderInfoReturn;
