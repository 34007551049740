import styled from 'styled-components';

export const MarginTop20 = {
  marginTop: '20px',
};

export const ImageIcon = {
  width: '15px',
  height: '15px',
};

export const Img = styled.img`
  width: 100%;
  height: 200px;
  object-fit: contain;
`;

export const DropZoneBox = styled.div`
  border: 1px solid #ced4da;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 1em;
  transition: 0.5s;
  margin-top: 20px;
  &:active,
  $:hover {
    background-color: #343b3f96;
    color: white;
    cursor: pointer;
  }
`;

export const CancleBtn = styled.button`
  margin: 10px 0;
  border: none;
  background-color: white;
  float: right;
`;
