import {
  Card,
  RePagination,
  Radio,
  Button,
  ReInput,
  GridTable,
  Select,
  Badge,
} from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { dayjsFormat, request, useObject } from 'services';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

interface IMessage {
  idx: number;
  message: string;
  body: string;
  data: string;
  sendResult: 'SUCCESS' | 'FAILED';
  createdAt: string;
  sendType: 'AUTO' | 'DIRECT';
  messageType: 'SMS' | 'KAKAO';
}

export interface Props {}
interface State {
  list: IMessage[];
  page: number;
  total: number;
  pageSize: number;
  minDate: string;
  maxDate: string;
  messageType: string;
  isSearching: boolean;
}

const UserMessage: FunctionComponent<Props> = () => {
  const [
    { list, page, total, minDate, maxDate, pageSize, messageType, isSearching },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    list: [],
    page: 1,
    total: 0,
    minDate: dayjsFormat(dayjs().add(-7, 'day')),
    maxDate: dayjsFormat(dayjs()),
    pageSize: 10,
    messageType: '',
    isSearching: false,
  });
  const { idx } = useParams<{ idx: string }>();

  const get = async () => {
    setState({ isSearching: true });
    try {
      const { data, count } = await request.get<any, { count: number; data: IMessage[] }>(
        `/commerce/user/${idx}/message-history`,
        {
          params: {
            limit: pageSize,
            page,
            minDate: `${dayjs(minDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`,
            maxDate: `${dayjs(maxDate).format('YYYY-MM-DDT23:59:59')}.000Z`,
          },
        }
      );
      setState({ list: data, total: count });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isSearching: false });
    }
  };
  useEffect(() => {
    get();
  }, [minDate, maxDate, pageSize, page]);
  return (
    <div className="p-4">
      <Card
        items={[
          {
            name: '메시지타입 (미작동)',
            renderItem: () => (
              <Radio
                id="messageType"
                value={messageType}
                onChange={(messageType) => setState({ messageType })}
                options={[
                  { name: '전체', value: '' },
                  { name: 'SMS', value: 'sms' },
                  { name: '카카오톡', value: 'kakao' },
                ]}
              />
            ),
            stretch: true,
          },
          {
            name: '검색기간',
            renderItem: () => (
              <div className="flex items-center gap-3">
                <Button
                  size="xs"
                  theme="ghost"
                  onClick={() =>
                    setState({
                      minDate: dayjsFormat(dayjs()),
                      maxDate: dayjsFormat(dayjs()),
                    })
                  }
                >
                  오늘
                </Button>
                <Button
                  theme="ghost"
                  size="xs"
                  onClick={() =>
                    setState({
                      minDate: dayjsFormat(dayjs().add(-3, 'day')),
                      maxDate: dayjsFormat(dayjs()),
                    })
                  }
                >
                  3일
                </Button>
                <Button
                  size="xs"
                  theme="ghost"
                  onClick={() =>
                    setState({
                      minDate: dayjsFormat(dayjs().add(-7, 'day')),
                      maxDate: dayjsFormat(dayjs()),
                    })
                  }
                >
                  7일
                </Button>
                <div className="w-40">
                  <ReInput
                    size="xs"
                    type="date"
                    onChange={onChange}
                    name="minDate"
                    value={minDate}
                  />
                </div>
                {' ~ '}
                <div className="w-40">
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxDate}
                    name="maxDate"
                    onChange={onChange}
                  />
                </div>
              </div>
            ),
            stretch: true,
          },
        ]}
      />
      <div className="my-5 flex justify-center gap-3">
        <Button isLoading={isSearching} onClick={get}>
          검색
        </Button>
        <Button
          theme="secondary"
          onClick={() => resetState(['pageSize', 'minDate', 'maxDate', 'page'])}
        >
          초기화
        </Button>
      </div>
      <GridTable
        columns={['발송타입', '발송일시', '메시지타입', '메시지', '발송결과']}
        list={list}
        noSelection
        header={
          <div className="flex items-center justify-end">
            <Select size="sm" value={pageSize} name="pageSize" onChange={onChange}>
              <option value={10}>10개씩 보기</option>
              <option value={20}>20개씩 보기</option>
            </Select>
          </div>
        }
        renderItem={(item, key) => (
          <div key={key}>
            <div>
              {item.sendType === 'AUTO' && '자동'}
              {item.sendType === 'DIRECT' && '수동'}
            </div>
            <div>{dayjs(item.createdAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
            <div>
              {item.messageType === 'SMS' && 'SMS'}
              {item.messageType === 'KAKAO' && '카카오'}
            </div>
            <div>{item.message}</div>
            <div>
              {item.sendResult === 'SUCCESS' && <Badge type="success">성공</Badge>}
              {item.sendResult === 'FAILED' && <Badge type="danger">실패</Badge>}
            </div>
          </div>
        )}
      />
      <div className="flex justify-between items-center">
        <div className="pl-2 text-gray-500 tracking-widest">
          <span>총</span>
          <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
          <span>개</span>
        </div>
        <RePagination
          currentPage={page}
          onPageChange={(page) => setState({ page })}
          totalCount={total}
          pageSize={pageSize}
        />
      </div>
    </div>
  );
};

export default UserMessage;
