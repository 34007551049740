import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
`;

export const Title = styled.div`
  width: 30%;
  background-color: #e8e8e8;
  text-align: center;
  padding: 10px 15px;
  border-right: 1px solid #ddd;
  font-weight: bold;
`;

export const Value = styled.div`
  width: 70%;
  padding: 10px 15px;
  ${(props) => props.color && `color: ${props.color};`}
`;
