import { Button, GridTable, OpenLink } from 'components';
import React, { FunctionComponent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ORDER_CANCEL_REASON, ORDER_PAY_STATUS, request, USER_TYPE } from 'services';
import useSWR from 'swr';
import classnames from 'classnames';
import dayjs from 'dayjs';

export interface Props {}
interface State {}

const OrderInfoExchange: FunctionComponent<Props> = () => {
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const { data } = useSWR('/commerce/order/order-exchange', (url) =>
    request.get<any, { data: any[]; count: number }>(url, {
      params: { fetchingOrderNumber },
      headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
    })
  );
  const { replace, location } = useHistory();
  return (
    <>
      <GridTable
        list={data ? data.data : []}
        columns={[
          '교환번호 / 상품별주문번호',
          '신청일',
          '주문자',
          '판매처 (국가 / 배송방식 / 관세조건)',
          '상품명 / 옵션 / 교환요청옵션',
          '교환사유',
          '수량',
          '차액발생여부',
          '차액결제여부',
          '상태 (처리일)',
          '관리자기능',
        ]}
        noSelection
        maxHeight="max-h-[512px]"
        renderItem={(item, key) => (
          <div key={key}>
            <div aria-label="교환번호 / 상품별주문번호">
              <OpenLink url={`/orders/exchange/${item.orderExchangeNumber}`}>
                {item.orderExchangeNumber}
              </OpenLink>
              <br />
              <OpenLink url={`/order/${item.fetchingOrderNumber}`}>
                {item.itemOrderNumber}
              </OpenLink>
            </div>
            <div aria-label="신청일">
              {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </div>
            <div aria-label="주문자">
              <span>{item.userName}</span>
              <div>{USER_TYPE[item.userType]}</div>
            </div>
            <div aria-label="판매처 (국가 / 배송방식 / 관세조건)">
              {item.shopName} ({item.shopCountry} /{' '}
              <span
                className={classnames({
                  'text-fassto': item.deliveryMethodName === '파스토',
                  'text-eldex': item.deliveryMethodName === '엘덱스',
                })}
              >
                {item.deliveryMethodName}[{item.deliveryMethodCountry}]
              </span>{' '}
              /{' '}
              <span>
                {item.isDdp === true && 'DDP'}
                {item.isDdp === false && 'DDU'}
              </span>
              )
            </div>
            <div aria-label="상품명 / 옵션 / 교환요청옵션">
              <div className="flex gap-3">
                <img
                  className="max-w-[3.5rem] object-contain"
                  src={item.itemImageUrl}
                  alt={item.itemName}
                />
                <div className="flex-1">
                  <div>{item.brandName}</div>
                  <OpenLink
                    url={`/product/productDetail/v2/${Number(item.itemId)}?mode=update`}
                  >
                    {item.itemName}
                  </OpenLink>
                  <div className="text-red-400 mt-4">{`${item.originalSizeName} -> ${item.sizeName}`}</div>
                </div>
              </div>
            </div>
            <div aria-label="교환사유">
              <div className="font-bold">{ORDER_CANCEL_REASON[item.reasonType]}</div>
              {!!item.reasonDirectInput && (
                <div>선택사유명: {item.reasonDirectInput}</div>
              )}
            </div>
            <div aria-label="수량">{item.quantity}</div>
            <div aria-label="차액발생여부"></div>
            <div aria-label="차액결제여부"></div>
            <div aria-label="상태" className="link">
              {ORDER_PAY_STATUS[item.exchangeStatus]}
            </div>
            <div aria-label="관리자기능">
              <div className="flex flex-col gap-1 items-center">
                <Button
                  size="xs"
                  theme="primary"
                  onClick={() =>
                    window.open(`/orders/exchange/${item.orderExchangeNumber}`)
                  }
                >
                  교환
                </Button>
                <Button
                  size="xs"
                  className="text-white bg-black py-1 px-2"
                  onClick={() => replace(`${location.pathname}?tab=8`)}
                >
                  메모
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default OrderInfoExchange;
