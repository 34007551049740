import axios from 'api/axios';
import { Button } from 'components';
import React from 'react';

interface State {}

const UsersEtcPage = () => {
  const onDownloadEmail = async () => {
    try {
      await axios.get(
        'https://9r7cwx81vi.execute-api.ap-northeast-2.amazonaws.com/default/community-email-to-excel',
        {
          withCredentials: true,
          headers: { 'Content-Type': ' application/json' },
        }
      );
      const anchor = document.createElement('a');
      anchor.href =
        'https://community-crawling.s3.ap-northeast-2.amazonaws.com//public/comminitu_crawling.xlsx';
      anchor.click();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="wrapper">
        <div className="wrapper-title">잡동사니</div>
      </div>
      <div className="wrapper">
        <Button theme="primary" onClick={onDownloadEmail}>
          수집한 이메일 다운로드
        </Button>
      </div>
    </>
  );
};

export default UsersEtcPage;
