import React from 'react';
import type { FunctionComponent, ReactNode } from 'react';

export interface Props {
  title: ReactNode;
  description?: string;
  content?: ReactNode;
}
interface State {}

const FormContainer: FunctionComponent<Props> = ({
  title,
  description,
  content,
  children,
}) => {
  return (
    <div className="flex gap-6">
      <div className="flex-1">
        <h4 className="font-bold text-lg text-gray-800">{title}</h4>
        {!!description && <p className="text-base text-gray-400">{description}</p>}
        {content}
      </div>
      <div className="flex-1 space-y-6">{children}</div>
    </div>
  );
};

export default FormContainer;
