import { fetchingAPI } from 'api/axios';
import { LoadingContext } from 'context';
import React, { useContext, useState } from 'react';
import * as style from './style';

const ChangeInfoGroup = ({
  showModal,
  setShowModal,
  checkedItemIds,
  searchOptionTableState,
  trimParams,
}) => {
  const [target, setTarget] = useState('checkedUserIds');
  const [modal, setModal] = useState('ChangeUserLevel');

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const batchProcessing = async () => {
    if (!checkedItemIds.length) return;

    setShowModal({
      ...showModal,
      [modal]: true,
      targetIds: checkedItemIds.map((id) => String(id)),
    });
    // if (target === 'checkedItemIds') {
    //   if (checkedItemIds.length === 0) {
    //     alert('하나 이상의 상품을 선택하세요.');
    //   } else {
    //     setShowModal({
    //       ...showModal,
    //       [modal]: true,
    //       targetIds: checkedItemIds.map((id) => String(id)),
    //     });
    //   }
    // } else if (target === 'allItemIds') {
    //   handleIsLoading(true);
    //   const pathname = window.location.pathname.split('/');
    //   const id = Number(pathname[pathname.length - 1]);
    //   const data = await fetchingAPI.get(
    //     id > 0 ? `/group/${id}/item/ids` : '/item/ids',
    //     {
    //       params: {
    //         ...trimParams(searchOptionTableState),
    //       },
    //     }
    //   );
    //   handleIsLoading(false);
    //   setShowModal({
    //     ...showModal,
    //     [modal]: true,
    //     targetIds: data.data,
    //   });
    // }
  };

  return (
    <style.Container>
      <style.Input
        onChange={(e) => {
          setTarget(e.target.value);
        }}
      >
        <option value="checkedUserIds">선택한 회원의</option>
      </style.Input>
      <style.Input
        onChange={(e) => {
          setModal(e.target.value);
        }}
      >
        <option value="ChangeUserLevel">회원등급 변경을</option>
        <option value="DeleteUser">회원탈퇴를</option>
      </style.Input>
      <style.Button onClick={batchProcessing}>일괄처리</style.Button>
    </style.Container>
  );
};

export default ChangeInfoGroup;
