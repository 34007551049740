import * as type from './types';

export const loginAction = (userData) => ({
  type: type.LOGIN_USER,
  userData,
  isLoggedIn: true,
});

export const logoutAction = () => ({
  type: type.LOGOUT_USER,
  userData: {},
  isLoggedIn: false,
});

export const detailNoticeAction = (detailNoticeData) => ({
  type: type.DETAIL_NOTICE,
  detailNoticeData,
});

export const selectShopBasicDataAction = (selectShopBasicData) => ({
  type: type.SELECT_SHOP_BASIC_DATA,
  selectShopBasicData,
});

export const selectItemAllAction = (data) => ({
  type: type.SELECT_ITEMS_ALL,
  itemsId: data,
});

export const removeSelectItemAllAction = () => ({
  type: type.REMOVE_ITEMS_ALL,
  itemsId: [],
});

export const addSelectItemAction = (data) => ({
  type: type.ADD_SELECT_ITEMS,
  itemsId: [data],
});

export const removeSelectItemAction = (itemsId) => ({
  type: type.REMOVE_SELECT_ITEMS,
  itemsId,
});

export const addExhibitionOptionAction = (data) => ({
  type: type.ADD_EXHIBITION_OPTION,
  options: data,
});

export const deleteExhibitionOptionAction = (data) => ({
  type: type.DELETE_EXHIBITION_OPTION,
  options: data,
});

export const allDeleteExhibitionOptionAction = () => ({
  type: type.ALL_DELETE_CAFE24_MAIN_OPTION,
  options: [],
});

export const addSelectNoticeAction = (data) => ({
  type: type.ADD_SELECT_NOTICE,
  noticeId: [data],
});

export const removeSelectNoticeAction = (data) => ({
  type: type.REMOVE_SELECT_NOTICE,
  noticeId: data,
});

export const removeSelectNoticeAllAction = () => ({
  type: type.REMOVE_SELECT_NOTICE_ALL,
  noticeId: [],
});

export const selectPromotionShopIdAction = (shopId) => ({
  type: type.SELECT_PROMOTIOM_SHOP_ID,
  shopId,
});

export const selectPromotionDataAction = (promotionData) => ({
  type: type.SELECT_PROMOTION_DATA,
  promotionData,
});

export const addSelectCategoryAction = (categoryId) => ({
  type: type.SELECT_CATEGORY_ID,
  categoryId,
});

export const removeSelectCategoryAction = (categoryId) => ({
  type: type.REMOVE_SELECT_CATEGORY,
  categoryId,
});

export const removeSelectAllCategoryAction = () => ({
  type: type.REMOVE_CATEGORY_ALL,
  categoryIds: [],
});

export const addSelectCategoryItemAction = (itemId) => ({
  type: type.CATEGORY_SELECT_ITEM_ID,
  itemId,
});

export const removeSelelctCategoryItemAction = (itemId) => ({
  type: type.CATEGORY_REMOVE_SELECT_ITEM,
  itemId,
});

export const selectAllCategoryItemsAction = (itemIds) => ({
  type: type.CATEGORY_SELECT_ALL_ITEMS,
  itemIds,
});

export const removeSelectAllCategoryAllItemAction = () => ({
  type: type.CATEGORY_REMOVE_SELECT_ITEMS_ALL,
  itemIds: [],
});

export const addPromotionOptionAction = (data) => ({
  type: type.ADD_PROMOTION_OPTION,
  options: data,
});

export const deletePromotionOptionAction = (data) => ({
  type: type.DELETE_PROMOTION_OPTION,
  options: data,
});

export const allDeletePromotionOptionAction = () => ({
  type: type.ALL_DELETE_PROMOTION_OPTION,
  options: [],
});

export const headerAction = (result) => ({
  type: type.HEARDER,
  header: result,
});

export const selectExhibitionAction = (result) => ({
  type: type.SELECT_EXHIBITION,
  selectItem: result,
});

export const currentSearchOption = (params) => ({
  type: type.EXIHIBITION_LAST_SEARCH_DATA,
  payload: {
    params,
  },
});

export const currentPageNum = (params, pageNum) => ({
  type: type.EXIHIBITION_CURRENT_PAGE_NUM,
  payload: {
    params,
    pageNum,
  },
});

export const updateSearchOption = (key, value) => ({
  type: type.UPDATE_PROMOTION_SEARCH_OPTION,
  key,
  value,
});

export const initSearchOption = () => ({
  type: type.INIT_PROMOTION_SEARCH_OPTION,
});
