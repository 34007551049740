import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  align-items: center;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
  margin: 10px;
`;

export const Item = styled.div`
  text-align: center;
  padding: 35px 45px;
  width: calc(100% / 3);
  word-break: break-all;
`;

export const Text = styled.p`
  margin: 0;
  color: ${(props) => props.color};
`;
