import styled from 'styled-components';

export const Wrapper = styled.div`
  color: aliceblue;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

export const Item = styled.div`
  cursor: pointer;
  transition: 0.5;
  font-size: 20px;
  white-space: nowrap;
  color: ${(props) => props.color || '#8e9194'};
  &:active,
  $:hover {
    color: #bec0c2;
  }
`;

export const IconImg = styled.img`
  width: 28px;
`;

export const Margin = styled.div`
  width: 11px;
`;
