import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-left: 0.5px solid #b4b4b4;
  border-top: 0.5px solid #b4b4b4;
`;

export const Thead = styled.thead`
  background: #bebebe;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  display: flex;
`;

export const Td = styled.td`
  border-right: 0.5px solid #b4b4b4;
  border-bottom: 0.5px solid #b4b4b4;
  width: ${({ w }) => w}%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 8px;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;
export const Radio = styled.input.attrs({ type: 'radio' })`
  margin-right: 4px;
`;
export const Label = styled.label`
  margin: 0;
  display: flex;
  align-items: center;
`;

export const ManualButton = styled.button`
  font-weight: bold;
  background: #498935;
  color: white;
  padding: 4px 7px;
  margin: 13px 10px;
  border: none;
  border-radius: 2px;
`;

export const InfoTd = styled(Td)`
  flex-direction: row;
  justify-content: space-evenly;
`;

export const Img = styled.img`
  width: 30%;
`;

export const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
`;

export const CalcButton = styled.button`
  padding: 5px 15px;
  font-weight: bold;
  color: #ff6f61;
  background: #000000;
  border: none;
  border-radius: 2px;
`;

export const Input = styled.input.attrs({
  placeholder: '편집샵 가격',
})`
  display: inline-flex;
  text-align: right;
  width: 100px;
`;

export const PromotionInput = styled.input.attrs({
  placeholder: '금액/할인률',
})`
  display: inline-flex;
  text-align: right;
  width: 60px;
`;

export const Row = styled.div`
  height: 20px;
  line-height: 18px;
  width: 100%;
  margin-bottom: 4px;

  @media (max-width: 1600px) {
    height: 40px;
    box-sizing: border-box;
    padding-bottom: 8px;
    border-bottom: 1px solid lightgray;
  }
`;

export const Name = styled.span`
  height: 20px;
  display: flex;
  float: left;

  @media (max-width: 1600px) {
    display: block;
    width: 100%;
  }
`;

export const Value = styled.span`
  height: 20px;
  display: flex;
  float: right;
  text-align: right;

  @media (max-width: 1600px) {
    display: block;
    width: 100%;
  }
`;

export const Description = styled.span`
  height: 20px;
  font-size: 8px;
  display: inline-block;
  text-align: right;
  width: 60%;

  @media (max-width: 1600px) {
    display: block;
    width: 100%;
  }
`;

export const CalculatorValue = styled.div`
  height: 20px;
  display: inline-block;
  text-align: right;
  width: 60%;

  @media (max-width: 1600px) {
    display: block;
    width: 100%;
  }
`;

export const CalculatorOption = styled.div`
  height: 20px;
  display: inline-block;
  width: 40%;
  padding-left: 4px;

  @media (max-width: 1600px) {
    display: block;
    width: 100%;
    text-align: right;
  }
`;

export const OptionAndTextGroup = styled.span`
  float: right;
`;
export const Notice = styled.span`
  color: #ff6f61;
`;
