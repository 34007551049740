import React, { useState } from 'react';
import * as style from './style';

const SearchBy = ({ searchOptionTableState, setSearchOptionTableState }) => {

    return (


        <style.Container>
            <style.Select onChange={(e) => {
                setSearchOptionTableState({
                    ...searchOptionTableState,
                    searchType: e.target.value,
                    searchKey: '',
                });
            }}>
                <option value='FETCHING_CODE'>페칭코드</option>
                <option value='CAFE24_CODE'>카페24코드</option>
                <option value='SHOP_CODE'>편집샵코드</option>
                <option value='ITEM_NAME'>상품명</option>
            </style.Select>
            <style.Input onChange={(e) => {
                setSearchOptionTableState({
                    ...searchOptionTableState,
                    searchKey: e.target.value,
                });
            }} value={searchOptionTableState.searchKey} />
            <style.Checkbox onChange={() => [
                setSearchOptionTableState({
                    ...searchOptionTableState,
                    needCount: !searchOptionTableState.needCount,
                })
            ]} checked={searchOptionTableState.needCount} />상품 개수 보기
        </style.Container>
    );
};

export default SearchBy;