import {
  Alert,
  Button,
  Card,
  GridTable,
  MenuTitle,
  NumberStat,
  OpenLink,
  OrderListTooltip,
  Radio,
  ReInput,
  ReMessageModal,
  RePagination,
  Select,
  Textarea,
} from 'components';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';
import {
  enumToList,
  ORDER_ADDITIONAL_PAY_TYPE,
  ORDER_DATE_LIST,
  ORDER_SEARCH_TYPE,
  ORDER_SORT,
  PATH,
  PAY_METHOD,
  request,
  SHOP_ORDER_STATUS,
  useObject,
  useOrderTable,
  useOrderTableDeliveryMethod,
  USER_TYPE,
} from 'services';
import { priceFormat } from 'utils';
import classnames from 'classnames';
import { Checkbox } from 'antd';

interface State {
  reason: string;
}

const OrdersWaitPage = () => {
  const [{ reason }, setState] = useObject<State>({
    reason: '',
  });
  const {
    searchType,
    searchText,
    onChangeCheckbox,
    onChangeOrder,
    onDateClick,
    minOrderDate,
    maxOrderDate,
    orderPath,
    payMethod,
    userType,
    total,
    page,
    limit,
    list,
    sort,
    isLoading,
    selectedIdx,
    setOrderState,
    resetOrderState,
    isMessageOpen,
    phoneNumber,
    memoList,
    isMemoLoading,
    getMemo,
    deliveryMethodIdList,
  } = useOrderTable();

  const [deliveryMethodIdOptions] = useOrderTableDeliveryMethod();

  const get = async () => {
    setOrderState({ isLoading: true });
    try {
      let params: any = {
        isAscending: sort === 'ORDER_DATE_ASC',
        limit,
        page,
        orderBy: 'ORDER_DATE',
        orderStatus: 'ORDER_WAITING',
      };
      if (!!searchText) {
        params.searchText = searchText;
        params.searchType = searchType;
      }
      if (!!orderPath) params.orderPath = orderPath;
      if (!!userType) params.userType = userType;
      if (!!minOrderDate)
        params.minOrderDate = `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
      if (!!maxOrderDate)
        params.maxOrderDate = `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
      if (!!deliveryMethodIdList.length)
        params.deliveryMethodIdList = deliveryMethodIdList;
      try {
        const { count, data } = await request.get<any, { count: number; data: any[] }>(
          '/commerce/order',
          {
            params,
            headers: {
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          }
        );
        setOrderState({ total: count, list: data, isLoading: false });
      } catch (err) {
        console.log(err);
        setOrderState({ isLoading: false });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const detailStatus = (
    orderIndex: number,
    shopIndex: number,
    itemIndex: number
  ): string => {
    const order = list[orderIndex];
    const shop = order.shopOrderList[shopIndex];
    const item = shop.itemOrderList[itemIndex];

    if (!item) return '';
    if (
      !!item.additionalPayInfo &&
      item.additionalPayInfo.additionalPayStatus !== 'PAID'
    ) {
      if (item.additionalPayInfo.needGuide) {
        return item.additionalPayInfo.type === 'PRODUCT_DIFFERENCE'
          ? '차액안내필요'
          : `차액안내필요(${ORDER_ADDITIONAL_PAY_TYPE[item.additionalPayInfo.type]})`;
      } else {
        return item.additionalPayInfo.type === 'PRODUCT_DIFFERENCE'
          ? '차액안내완료'
          : `차액안내완료(${ORDER_ADDITIONAL_PAY_TYPE[item.additionalPayInfo.type]})`;
      }
    }
    return '';
  };

  useEffect(() => {
    get();
  }, [minOrderDate, maxOrderDate, orderPath, payMethod, userType, page, limit, sort]);
  return (
    <>
      {/* <MenuTitle title="발주 대기 관리" /> */}
      <div className="m-4">
        <Alert
          type="info"
          list={[
            '발주 대기 항목은 차액 안내 또는 고객 요청 사항 반영중인 주문 내역을 의미합니다.',
          ]}
        />
      </div>
      <div className="wrapper">
        <div className="wrapper-title">발주 대기 관리</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Textarea.Autosize
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                    rows={1}
                    className="w-64"
                    placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문일',
              renderItem: () => (
                <div className="flex items-center gap-3">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문경로',
              renderItem: () => (
                <Radio
                  id="orderPath"
                  options={enumToList(PATH)}
                  isInitialExisted
                  value={orderPath}
                  onChange={(orderPath) => setOrderState({ orderPath })}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '결제수단',
              renderItem: () => (
                <Radio
                  id="payMethod"
                  value={payMethod}
                  isInitialExisted
                  onChange={(payMethod) => setOrderState({ payMethod })}
                  options={enumToList(PAY_METHOD)}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Radio
                  id="userType"
                  value={userType}
                  isInitialExisted
                  onChange={(userType) => setOrderState({ userType })}
                  options={enumToList(USER_TYPE)}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '발주 대기 사유',
              renderItem: () => (
                <Radio
                  id="reason"
                  value={reason}
                  onChange={(reason) => setState({ reason })}
                  options={[
                    { name: '전체', value: '' },
                    { name: '차액', value: 'diff' },
                    { name: '옵션변경차액', value: 'option_diff' },
                    { name: '상품추가차액', value: 'goods_diff' },
                  ]}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '배송 방식',
              renderItem: () => (
                <Checkbox.Group
                  disabled={isLoading}
                  options={deliveryMethodIdOptions}
                  value={deliveryMethodIdList}
                  onChange={(deliveryMethodIdList) =>
                    setOrderState({
                      deliveryMethodIdList: deliveryMethodIdList as number[],
                    })
                  }
                />
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-7 flex justify-center gap-3 text-lg">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          isLoading={isLoading}
          columns={[
            '주문번호',
            '주문일 (결제일)',
            '주문자',
            '판매처 (국가 / 관세조건 / 배송방식)',
            '주문상품',
            '수량',
            '상품금액',
            '총 결제금액',
            '결제수단',
            '주문상태',
            '관리자기능',
          ]}
          maxHeight=""
          header={
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <Select size="sm">
                  <option value="checkedOrderIds">선택한 주문의</option>
                </Select>
                <Select size="sm">
                  <option value="ChangeOrderState">주문상태를</option>
                </Select>
                <Button size="sm">{'일괄처리 >'}</Button>
              </div>
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          list={list}
          renderItem={(order, orderIndex) => (
            <Fragment key={orderIndex}>
              {order.shopOrderList
                .filter((shop) => shop.orderStatus === 'ORDER_WAITING')
                .map((shop, shopIndex) => (
                  <div
                    key={shopIndex}
                    onClick={() => onChangeCheckbox(shop.shopOrderNumber)}
                  >
                    <div aria-label="checkbox">
                      <input
                        type="checkbox"
                        onChange={() => {}}
                        checked={selectedIdx.indexOf(shop.shopOrderNumber) !== -1}
                      />
                    </div>
                    <div aria-label="주문번호">
                      <OpenLink url={`/order/${order.fetchingOrderNumber}`}>
                        {order.fetchingOrderNumber}
                      </OpenLink>
                    </div>
                    <div aria-label="주문일 (결제일)">
                      <div>{dayjs(order.orderedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                      {!!order.paidAt && (
                        <div className="text-gray-400">
                          ({dayjs(order.paidAt).format('YYYY.MM.DD HH:mm:ss')})
                        </div>
                      )}
                    </div>
                    <div aria-label="주문자">
                      <span>{order.userName}</span>
                      <div>{USER_TYPE[order.userType]}</div>
                    </div>
                    <div aria-label="판매처 (국가 / 관세조건 / 배송방식)">
                      <div>
                        {shop.shopName} ({shop.shopCountry} /{' '}
                        <span>
                          {shop.isDdp === true && 'DDP'}
                          {shop.isDdp === false && 'DDU'}
                        </span>
                        {' / '}
                        <span
                          className={classnames({
                            'text-fassto': shop.deliveryMethodName === '파스토',
                            'text-eldex': shop.deliveryMethodName === '엘덱스',
                          })}
                        >
                          {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                        </span>
                        )
                      </div>
                    </div>
                    <div aria-label="주문상품">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div key={itemIndex} className="flex-1 flex gap-3">
                            <img
                              className="max-w-[3.5rem] object-contain"
                              src={item.itemImageUrl}
                              alt={item.itemName}
                            />
                            <div className="flex-1">
                              <div>{item.brandName}</div>
                              <OpenLink
                                url={`/product/productDetail/v2/${Number(
                                  item.itemId
                                )}?mode=update`}
                              >
                                {item.itemName}
                              </OpenLink>
                              <div>{item.sizeName}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="수량">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div key={itemIndex} className="flex-1">
                            {item.quantity}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="상품금액">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div key={itemIndex} className="flex-1">
                            {priceFormat(item.itemAmount)} 원
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="총 결제금액">
                      <NumberStat value={shop.payAmount} />
                    </div>
                    <div aria-label="결제수단">{PAY_METHOD[order.payMethod]}</div>
                    <div aria-label="주문상태">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div className="flex-1" key={itemIndex}>
                            <div className="text-black font-medium">
                              {SHOP_ORDER_STATUS[shop.orderStatus]}
                            </div>
                            <div>{detailStatus(orderIndex, shopIndex, itemIndex)}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="관리자기능">
                      <div className="flex flex-col gap-1 items-center">
                        <Button
                          className="w-full"
                          size="xs"
                          onClick={(e) => {
                            e.stopPropagation();
                            window.open(`/order/${order.fetchingOrderNumber}`, '_blank');
                          }}
                          theme="primary"
                        >
                          관리
                        </Button>
                        <Button
                          size="xs"
                          className="w-full"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOrderState({
                              isMessageOpen: true,
                              phoneNumber: order.phoneNumber,
                            });
                          }}
                        >
                          메시지
                        </Button>
                        <OrderListTooltip
                          isLoading={isMemoLoading}
                          hasMemo={order.hasMemo}
                          list={memoList}
                          fetchingOrderNumber={order.fetchingOrderNumber}
                          onMouseLeave={() =>
                            setOrderState({ memoList: [], isMemoLoading: false })
                          }
                          onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </Fragment>
          )}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setOrderState({
              selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
            })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
      <ReMessageModal
        isOpen={isMessageOpen}
        onClose={() => setOrderState({ isMessageOpen: false, phoneNumber: '' })}
        phoneNumber={phoneNumber}
      />
    </>
  );
};

export default OrdersWaitPage;
