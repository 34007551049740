import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  clear: both;
  width: 100%;
`;

export const Item = {
  margin: '20px',
};
