import {
  Button,
  Card,
  Check,
  GridTable,
  MenuTitle,
  OpenLink,
  OrderCount,
  OrderListTooltip,
  Radio,
  ReInput,
  RePagination,
  Select,
  Tabs,
  Textarea,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  ORDER_CANCEL_REASON,
  ORDER_DATE_LIST,
  ORDER_PAY_STATUS,
  ORDER_SEARCH_TYPE,
  PATH,
  PAY_METHOD,
  request,
  useOrderTable,
  USER_TYPE,
  enumToList,
  ORDER_SORT,
} from 'services';
import { orderCountState } from 'store';
import classnames from 'classnames';

interface State {}

const OrdersExchangePage = () => {
  const { push } = useHistory();
  const {
    searchType,
    searchText,
    onDateClick,
    onChangeOrder,
    minOrderDate,
    maxOrderDate,
    page,
    total,
    limit,
    list,
    orderPath,
    userType,
    isLoading,
    payMethod,
    orderStatus,
    sort,
    resetOrderState,
    setOrderState,
    memoList,
    isMemoLoading,
    getMemo,
  } = useOrderTable();
  const orderCount = useRecoilValue(orderCountState);

  const get = async () => {
    setOrderState({ isLoading: true });
    let params: any = {
      isAscending: sort === 'ORDER_DATE_ASC',
      limit,
      page,
      orderBy: 'ORDER_DATE',
    };
    if (!!searchType) params.searchType = searchType;
    if (!!searchText) params.searchText = searchText;
    if (!!orderPath) params.orderPath = orderPath;
    if (!!payMethod) params.payMethod = payMethod;
    if (!!userType) params.userType = userType;
    if (!!orderStatus) params.orderStatus = orderStatus;
    if (!!minOrderDate)
      params.minOrderDate = `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
    if (!!maxOrderDate)
      params.maxOrderDate = `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
    try {
      const { data, count } = await request.get<any, { data: any[]; count: number }>(
        '/commerce/order/order-exchange',
        { params }
      );
      setOrderState({ total: count, list: data, isLoading: false });
    } catch (err) {
      setOrderState({ isLoading: false });
      console.dir(err);
    }
  };

  const onTabChange = (tab: number) => {
    if (tab === 0) push('/orders/return');
    else if (tab === 2) push('/orders/refund');
    else if (tab === 3) push('/orders/customs-refund');
  };

  useEffect(() => {
    get();
  }, [
    page,
    minOrderDate,
    maxOrderDate,
    limit,
    orderPath,
    userType,
    payMethod,
    sort,
    orderStatus,
  ]);
  return (
    <OrderCount>
      <MenuTitle>
        <Tabs
          onTabChange={onTabChange}
          value={1}
          options={[
            `반품 관리 (${orderCount.return})`,
            `교환 관리 (${orderCount.exchange})`,
            `환불 관리 (${orderCount.refund})`,
            '관세 환급 관리',
          ]}
        />
      </MenuTitle>
      <div className="wrapper">
        <div className="wrapper-title">교환 관리</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Textarea.Autosize
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                    rows={1}
                    className="w-64"
                    placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문경로',
              renderItem: () => (
                <Radio
                  options={enumToList(PATH)}
                  isInitialExisted
                  value={orderPath}
                  onChange={(orderPath) => setOrderState({ orderPath })}
                  id="orderPath"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '결제수단',
              renderItem: () => (
                <Radio
                  value={payMethod}
                  onChange={(payMethod) => setOrderState({ payMethod })}
                  options={enumToList(PAY_METHOD)}
                  isInitialExisted
                  id="payMethod"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Radio
                  options={enumToList(USER_TYPE)}
                  value={userType}
                  isInitialExisted
                  onChange={(userType) => setOrderState({ userType })}
                  id="userType"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '교환 상태',
              renderItem: () => (
                <Radio
                  isInitialExisted
                  options={enumToList(ORDER_PAY_STATUS)}
                  value={orderStatus}
                  onChange={(orderStatus) => setOrderState({ orderStatus })}
                  id="exchange"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-7 flex justify-center gap-3">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          isLoading={isLoading}
          columns={[
            '교환번호 / 상품별주문번호',
            '신청일',
            '주문자',
            '판매처 (국가 / 배송방식)',
            '상품명 / 옵션 / 교환요청옵션',
            '교환사유',
            '수량',
            '차액발생여부',
            '차액결제여부',
            '상태 (처리일)',
            '관리자기능',
          ]}
          maxHeight=""
          header={
            <div className="flex items-center justify-end">
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          list={list}
          noSelection
          renderItem={(item, key) => (
            <div key={key}>
              <div aria-label="교환번호 / 상품별주문번호">
                <OpenLink url={`/orders/exchange/${item.orderExchangeNumber}`}>
                  {item.orderExchangeNumber}
                </OpenLink>
                <br />
                <OpenLink url={`/order/${item.fetchingOrderNumber}`}>
                  {item.itemOrderNumber}
                </OpenLink>
              </div>
              <div aria-label="신청일">
                {dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}
              </div>
              <div aria-label="주문자">
                <span>{item.userName}</span>
                <div>{USER_TYPE[item.userType]}</div>
              </div>
              <div aria-label="판매처 (국가 / 배송방식)">
                {item.shopName} ({item.shopCountry} /{' '}
                <span
                  className={classnames({
                    'text-fassto': item.deliveryMethodName === '파스토',
                    'text-eldex': item.deliveryMethodName === '엘덱스',
                  })}
                >
                  {item.deliveryMethodName}[{item.deliveryMethodCountry}]
                </span>
                )
              </div>
              <div aria-label="상품명 / 옵션 / 교환요청옵션">
                <div className="flex gap-3">
                  <img
                    className="max-w-[3.5rem] object-contain"
                    src={item.itemImageUrl}
                    alt={item.itemName}
                  />
                  <div className="flex-1">
                    <div>{item.brandName}</div>
                    <OpenLink
                      url={`/product/productDetail/v2/${Number(item.itemId)}?mode=update`}
                    >
                      {item.itemName}
                    </OpenLink>
                    <div className="text-red-400 mt-4">{`${item.originalSizeName} -> ${item.sizeName}`}</div>
                  </div>
                </div>
              </div>
              <div aria-label="교환사유">
                <div className="font-bold">{ORDER_CANCEL_REASON[item.reasonType]}</div>
                {!!item.reasonDirectInput && (
                  <div>선택사유명: {item.reasonDirectInput}</div>
                )}
              </div>
              <div aria-label="수량">{item.quantity}</div>
              <div aria-label="차액발생여부">
                <Check checked={!!item.additionalPayStatus} />
              </div>
              <div aria-label="차액결제여부">
                <Check checked={item.hasAdditionalPay} />
              </div>
              <div aria-label="상태 (처리일)">
                <div className="font-medium text-black">
                  {ORDER_PAY_STATUS[item.exchangeStatus]}
                </div>
                {!!item.statusUpdatedAt && (
                  <div className="text-gray-400">
                    ({dayjs(item.statusUpdatedAt).format('YYYY.MM.DD HH:mm:ss')})
                  </div>
                )}
              </div>
              <div aria-label="관리자기능">
                <div className="flex flex-col gap-1 items-center">
                  <Button
                    size="xs"
                    theme="primary"
                    onClick={() =>
                      window.open(`/orders/exchange/${item.orderExchangeNumber}`)
                    }
                  >
                    교환
                  </Button>
                  <OrderListTooltip
                    isLoading={isMemoLoading}
                    list={memoList}
                    fetchingOrderNumber={item.fetchingOrderNumber}
                    onMouseLeave={() =>
                      setOrderState({ memoList: [], isMemoLoading: false })
                    }
                    onMouseEnter={() => getMemo(item.fetchingOrderNumber)}
                  />
                </div>
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
    </OrderCount>
  );
};

export default OrdersExchangePage;
