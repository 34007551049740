import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 25px;
`;

export const Title = styled.h5``;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
`;

export const ItemTitle = styled.div`
  width: 30%;
  background-color: #e8e8e8;
  text-align: center;
  padding: 10px 15px;
  border-right: 1px solid #ddd;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemValue = styled.div`
  width: 70%;
  padding: 10px 15px;
  overflow-x: auto;
  ${(props) => props.color && `color: ${props.color};`}
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: wrap;
  align-items: center;
  width: 500px;
`;

export const P = styled.p`
  margin: 0;
  margin-right: 10px;
  white-space: nowrap;
`;

export const CommissionInput = styled.input.attrs({
  placeholder: '페칭수수료 숫자로만 입력',
  type: 'number',
})`
  width: 200px;
`;
