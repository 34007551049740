import { ManageComponentContent, Outer, ShadowContainer } from 'components';
import ManageBandBanner from 'components/templates/ManageComponentContent/ManageBandBanner';
import ManagedItemList from 'components/templates/ManageComponentContent/ManagedItemList';
import ManageTimeDealComponent from 'components/templates/ManageComponentContent/ManageTimeDealComponent';
import { triggerState } from 'pages/ManageMainContent';
import { useGetLayouts, useMainLayout } from 'pages/ManageMainLayout/utils';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router';
import { useRecoilValue } from 'recoil';
import * as S from './style';
import { isManagedOnAdmin } from './utils';

const ManageMainComponent = ({ mode }: { mode: 'm' | 'w' }) => {
  const {
    state: [layouts, setLayouts],
    isLoading,
    reload,
  } = useMainLayout(mode);

  const history = useHistory();
  const location = useLocation();

  const idx = location.pathname.split('/').pop();
  const component = layouts.find((layout) => layout.idx + '' === idx);

  if (isLoading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Spinner animation="border" />
      </div>
    );
  }
  return (
    <Outer>
      <ShadowContainer style={S.ContainerRule}>
        <S.CardContainer>
          {layouts.map((layout) => (
            <S.ComponentCard
              onClick={() => {
                if (isManagedOnAdmin(layout.type)) {
                  history.push(`/content/main/${mode}/component/${layout.idx}`);
                  window.scrollTo(0, 0);
                }
              }}
              disabled={isManagedOnAdmin(layout.type)}
              inActive={!layout.isActive}
              selected={layout.idx + '' === idx}
              key={layout.idx}
            >
              {layout.name}
              {!layout.isActive && '(꺼짐)'}
            </S.ComponentCard>
          ))}
        </S.CardContainer>
        <S.EditorContainer>
          {/* 리팩토링필요 */}
          {component ? (
            <ManageComponentContent mode={mode} layout={component} />
          ) : (
            <h5>관리할 컴포넌트를 선택하세요.</h5>
          )}
        </S.EditorContainer>
      </ShadowContainer>
    </Outer>
  );
};

export default ManageMainComponent;
