import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { IModal, NOrder } from 'types';
import {
  enumToList,
  ORDER_REFUND_REASON,
  request,
  useObject,
  useOrderDetail,
} from 'services';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  FormItem,
  GridTable,
  Radio,
  ReInput,
  ReModal,
  Select,
  Textarea,
} from 'components';
import Switch from 'react-switch';

export interface Props extends IModal {
  returnOrderList: Array<
    Pick<
      NOrder.ItemOrderDetail,
      'itemName' | 'itemOrderNumber' | 'sizeName' | 'itemImageUrl'
    >
  >;
}
interface State {
  selectedIdx: string[];
  isLoading: boolean;
  sendPush: boolean;
  memoContent: string;
  messageContent: string;
  messageType: 'SMS' | 'KAKAO';
  list: Array<
    Pick<
      NOrder.ItemOrderDetail,
      'itemName' | 'itemOrderNumber' | 'sizeName' | 'itemImageUrl'
    >
  >;
  reasonType: keyof typeof ORDER_REFUND_REASON | '';
  reasonDirectInput: string;
  tempMessageContent: string;
}

const OrderReturnModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  returnOrderList,
}) => {
  if (!isOpen) return null;
  const [
    {
      selectedIdx,
      isLoading,
      sendPush,
      messageType,
      messageContent,
      memoContent,
      list,
      reasonType,
      reasonDirectInput,
      tempMessageContent,
    },
    setState,
    onChange,
  ] = useObject<State>({
    selectedIdx: returnOrderList.map((item) => item.itemOrderNumber),
    isLoading: false,
    sendPush: false,
    memoContent: '',
    messageContent: '',
    messageType: 'KAKAO',
    list: returnOrderList,
    reasonType: '',
    reasonDirectInput: '',
    tempMessageContent: '',
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const [orderState, setOrderState, { refreshOrderState }] = useOrderDetail();

  const getTemplate = async () => {
    const filter = list.filter(
      (item) => selectedIdx.indexOf(item.itemOrderNumber) !== -1
    );
    if (!filter.length) {
      toast.info('취소할 상품을 최소 1개 이상 선택해 주세요.');
      return;
    }
    if (!reasonType) {
      toast.info('반품사유를 선택해 주세요.');
      return;
    }

    try {
      const { content } = await request.post<any, { content: string }>(
        '/commerce/order/message/preview',
        {
          type: 'ORDER_REFUND_REQUEST',
          fetchingOrderNumber,
          itemOrderNumberList: list.map((item) => item.itemOrderNumber),
        }
      );
      setState({ messageContent: content });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    if (!selectedIdx.length) {
      toast.info('취소할 상품을 최소 1개 이상 선택해 주세요.');
      return;
    }
    if (!reasonType) {
      toast.info('반품사유를 선택해 주세요.');
      return;
    }

    if (!window.confirm('반품 요청으로 전환하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      let data: any = {
        fetchingOrderNumber,
        itemOrderNumberList: selectedIdx,
        reasonType,
        sendPush,
        memoContent,
      };
      if (sendPush) {
        data.messageType = messageType;
        data.messageContent =
          messageType === 'KAKAO' ? messageContent : tempMessageContent;
      }
      if (reasonType === 'DIRECT_INPUT') data.reasonDirectInput = reasonDirectInput;
      await request.post('/commerce/order/order-refund', data);
      refreshOrderState();
      toast.success('반품 요청으로 전환되었습니다.');
      onClose();
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    if (sendPush) getTemplate();
  }, [sendPush]);
  return (
    <ReModal
      title="반품 요청 전환"
      description={[
        <>
          복수 수량은 단일 항목으로 노출됩니다.
          <br />
          수량 일부 반품은 단일 항목을 취소처리해주세요.
        </>,
      ]}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-5xl"
    >
      <div className="flex gap-4">
        <div className="flex-1">
          <div className="text-sm font-bold mb-2">{selectedIdx.length}개 상품 선택</div>
          <div className="bg-gray-100 rounded border border-gray-400">
            <GridTable
              columns={['상품정보']}
              renderItem={(item, key) => (
                <div
                  key={key}
                  onClick={() =>
                    setState({
                      selectedIdx:
                        selectedIdx.indexOf(item.itemOrderNumber) !== -1
                          ? selectedIdx.filter(
                              (itemOrderNumber) =>
                                itemOrderNumber !== item.itemOrderNumber
                            )
                          : [...selectedIdx, item.itemOrderNumber],
                    })
                  }
                >
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={selectedIdx.indexOf(item.itemOrderNumber) !== -1}
                    />
                  </div>
                  <div>
                    <div className="flex gap-2">
                      <div>
                        <img
                          src={item.itemImageUrl}
                          className="h-16 w-16 object-contain"
                        />
                      </div>
                      <div>
                        <div className="text-black">{item.itemName}</div>
                        <div>{item.sizeName}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              list={list}
              isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
              onSelectAll={(checked) =>
                setState({
                  selectedIdx: checked ? list.map((item) => item.itemOrderNumber) : [],
                })
              }
            />
          </div>
        </div>
        <div className="flex-1 space-y-4">
          <FormItem label="반품사유">
            <Select value={reasonType} name="reasonType" onChange={onChange}>
              <option value="" selected disabled>
                전체
              </option>
              {enumToList(ORDER_REFUND_REASON).map((item, key) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </Select>
          </FormItem>
          {reasonType === 'DIRECT_INPUT' && (
            <FormItem label="교환사유 직접 입력">
              <ReInput
                id="교환사유 직접 입력"
                value={reasonDirectInput}
                name="reasonDirectInput"
                onChange={onChange}
                className="w-full"
              />
            </FormItem>
          )}
          <FormItem label="고객 알림 전달 여부">
            <Switch checked={sendPush} onChange={(sendPush) => setState({ sendPush })} />
          </FormItem>
          {sendPush && (
            <>
              <FormItem label="고객 알림 채널">
                <Radio
                  options={[
                    { name: '카카오', value: 'KAKAO' },
                    { name: 'SMS (메시지 수정 발송 시)', value: 'SMS' },
                  ]}
                  id="messageType"
                  value={messageType}
                  onChange={(messageType) =>
                    setState({
                      messageType,
                      tempMessageContent: messageType === 'SMS' ? messageContent : '',
                    })
                  }
                />
              </FormItem>
              <FormItem label="고객 알림 내용 확인">
                <Textarea
                  readOnly={messageType === 'KAKAO'}
                  rows={8}
                  value={tempMessageContent || messageContent}
                  name="tempMessageContent"
                  onChange={onChange}
                />
              </FormItem>
            </>
          )}
          <FormItem label="관리자 메모">
            <Textarea
              rows={4}
              value={memoContent}
              name="memoContent"
              onChange={onChange}
            />
          </FormItem>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Button size="sm" isLoading={isLoading} onClick={onSubmit}>
          반품 요청 전환
        </Button>
      </div>
    </ReModal>
  );
};

export default OrderReturnModal;
