import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Div = styled.div`
  border-right: 0.5px solid #b4b4b4;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin: 0 7px 0 0;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 20px;
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 6px 0 0;
`;
export const Select = styled.select`
  margin: 0 8px 0px 0px;
`;
