import { fetchingAPI } from 'api/axios';
import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { initialParams } from './helper';

export const useReviewList = () => {
  const [searchTrigger, setSearchTrigger] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState<Review.SearchParams>(initialParams);
  const [reviews, setReviews] = useState<Review.Review[]>([]);
  const [count, setCount] = useState(0);

  const search = (payload?: Partial<Review.SearchParams>) => {
    payload && setParams((p) => ({ ...p, ...payload }));
    setSearchTrigger((p) => p + 1);
  };

  const resetParams = () => {
    setParams(initialParams);
  };

  const load = async () => {
    try {
      setIsLoading(true);
      const data = await fetchingAPI.get(`/commerce/review`, {
        params: {
          ...params,
          startAt: params.startAt ? dayjs(params.startAt).toISOString() : null,
          endAt: params.endAt ? dayjs(params.endAt).toISOString() : null,
        },
      });
      setReviews(data.data?.data ?? 0);
      setCount(data.data?.count ?? 0);
    } catch (error) {
      toast.error('검색 실패');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchTrigger > 0) {
      load();
    }
  }, [searchTrigger]);

  return {
    isLoading,
    useParams: [params, setParams, resetParams] as [
      Review.SearchParams,
      Dispatch<SetStateAction<Review.SearchParams>>,
      () => void
    ],
    reviews,
    count,
    search,
  };
};

export const useCheckedIds = <T>() => {
  const [checked, setChecked] = useState<T[]>([]);

  const isChecked = (id: T) => checked.includes(id);
  const isAll = (ids: T[]) => ids.length === checked.length;

  const onToggle = (id: T) => () => {
    isChecked(id)
      ? setChecked((p) => p.filter((p) => p !== id))
      : setChecked((p) => [...p, id]);
  };
  const onAll = (ids: T[]) => () => {
    isAll(ids) ? setChecked([]) : setChecked(ids);
  };

  return {
    isChecked,
    isAll,
    onToggle,
    onAll,
    checked,
  };
};

export const useManageReview = () => {
  const useCheckedReviews = useCheckedIds<number>();
  const [isLoading, setIsLoading] = useState(false);

  const updateVisibility = async (state: boolean, id?: number | null) => {
    setIsLoading(true);
    try {
      await fetchingAPI.put(`/commerce/review/visibility`, {
        state,
        data: id ? [id] : useCheckedReviews.checked,
      });
      toast.success('수정 성공');
    } catch (error) {
      toast.error('수정 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const updateReviewReward = async (
    state: 'GRANT' | 'RETRIEVE',
    type: Review.Benefit,
    id?: number | null
  ) => {
    setIsLoading(true);
    try {
      await fetchingAPI.put(`/commerce/review/reward`, {
        state,
        type,
        data: id ? [id] : useCheckedReviews.checked,
      });
      toast.success('수정 성공');
    } catch (error) {
      toast.error('수정 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const updateBestReview = async (state: boolean) => {
    setIsLoading(true);
    try {
      await fetchingAPI.put(`/commerce/review/best`, {
        state,
        data: useCheckedReviews.checked,
      });
      toast.success('수정 성공');
    } catch (error) {
      toast.error('수정 실패');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    useCheckedReviews,
    isLoading,
    updateVisibility,
    updateReviewReward,
    updateBestReview,
  };
};
