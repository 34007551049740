import { fetchingAPI } from 'api/axios';
import { getItemDetailHistory } from 'api/fetchingLog';
import { isEmpty, throttle } from 'lodash';
import { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { toast } from 'react-toastify';
import { useObject } from 'services';

export const useScrollTabs = (tabCount: number) => {
  const refs = Array(tabCount)
    .fill(0)
    .map(() => useRef<HTMLDivElement | null>(null));

  const useCurrentTab = useState(0);
  const [currentTab, setCurrentTab] = useCurrentTab;

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100);

  useEffect(() => {
    const onScroll = throttle(() => {
      const scrollTop = document.documentElement.scrollTop + 120;
      const indexFromLast = [...refs.slice().reverse()].findIndex(
        (ref) => (ref.current?.offsetTop ?? 0) < scrollTop
      );
      setCurrentTab(tabCount - 1 - indexFromLast);
    });
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return {
    refs,
    scrollToRef,
    useCurrentTab,
  };
};

export const useItem = (itemId: string) => {
  const [item, setItem] = useState<Item.Item | null>(null);
  const [price, setPrice] = useState<[string, Item.PriceData][] | null>(null);
  const [history, setHistory] = useState<any>(null);
  const usePricingIndex = useState({
    shopIndex: 0,
    optionIndex: 0,
  });
  const [pricingIndex, setPricingIndex] = usePricingIndex;
  const { state, handler, trigger } = useTasks(item, price);
  const useDeliveryMethodState = useObject<{
    [priceRuleId: string]: number;
  }>({});
  const [deliveryMethodState, setDeliveryMethodState] = useDeliveryMethodState;
  const [isLoading, setIsLoading] = useState(false);

  const load = async () => {
    setItem(null);
    setIsLoading(true);
    await Promise.all([
      fetchingAPI.get(`/item/v2/${itemId}`).then((data) => {
        setItem(data.data);
      }),
      fetchingAPI.get(`/item/v2/${itemId}/options`).then((data) => {
        setPrice(data.data ? Object.entries(data.data) : null);
      }),
      getItemDetailHistory(itemId).then((data) => {
        setHistory(data);
      }),
    ])
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
        trigger();
      });
  };

  const onChange = (e: any) => {
    let { name, value, type } = e.target;

    if (type === 'radio') {
      value = value === 'true';
    }

    if (type === 'number') {
      value = Number(value);
    }

    if (name == 'searchKeywords') {
      value = value.split(',').map((value) => value.trim());
    }

    setItem((p: any) => ({ ...p, [name]: value }));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const itemPayload: ItemUpdatePayload = {
      color: item?.color ?? '',
      isDomesticDelivery: item?.isDomesticDelivery ?? false,
      isFetchingPayActive: item?.isFetchingPayActive ?? false,
      isSellable: item?.isSellable ?? false,
      isShopPayActive: item?.isShopPayActive ?? false,
      name: item?.name ?? '',
      searchKeywords: item?.searchKeywords?.filter(Boolean).length
        ? item?.searchKeywords?.filter(Boolean)
        : null,
      priority: item?.priority ?? 0,
      category: {
        add:
          state.categories
            ?.filter(
              (category) =>
                !item?.categories?.find((_category) => _category.id == category.id)
            )
            ?.map((category) => category.id) ?? [],
        remove:
          item?.categories
            ?.filter(
              (category) =>
                !state.categories?.find((_category) => _category.id == category.id)
            )
            ?.map((category) => category.id) ?? [],
      },
      exhibitions: {
        add:
          state.exhibitions
            ?.filter(
              (exhibition) =>
                !item?.exhibitions?.find(
                  (_exhibition) => _exhibition.exhibitionId == exhibition.exhibitionId
                )
            )
            ?.map((exhibition) => exhibition.exhibitionId) ?? [],
        remove:
          item?.exhibitions
            ?.filter(
              (exhibition) =>
                !state.exhibitions?.find(
                  (_exhibition) => _exhibition.exhibitionId == exhibition.exhibitionId
                )
            )
            ?.map((exhibition) => exhibition.exhibitionId) ?? [],
      },
      promotions: {
        add:
          state.allPromotions
            ?.filter(
              (promotion) =>
                !item?.allPromotions?.find(
                  (_promotion) => _promotion.promotionId == promotion.promotionId
                )
            )
            ?.map((promotion) => promotion.promotionId) ?? [],
        remove:
          item?.allPromotions
            ?.filter(
              (promotion) =>
                !state.allPromotions?.find(
                  (_promotion) => _promotion.promotionId == promotion.promotionId
                )
            )
            ?.map((promotion) => promotion.promotionId) ?? [],
      },
    };

    const optionsPayload: OptionUpdatePayload =
      state.price?.reduce((acc, [shopPriceRule, options], shopIndex) => {
        const changedOptions = options.filter(
          (option, optionIndex) =>
            price?.[shopIndex][1].options?.[optionIndex].agencyFeeRate !==
              option.agencyFeeRate ||
            price?.[shopIndex][1].options?.[optionIndex].isActive !== option.isActive
        );
        const changedDeliveryMethod =
          deliveryMethodState[shopPriceRule] &&
          deliveryMethodState[shopPriceRule] != price?.[shopIndex][1].deliveryMethod
            ? deliveryMethodState[shopPriceRule]
            : 0;
        return {
          ...acc,
          ...((!isEmpty(changedOptions) || changedDeliveryMethod) && {
            [shopPriceRule]: {
              options: changedOptions.length ? changedOptions : undefined,
              deliveryMethod: changedDeliveryMethod ? changedDeliveryMethod : undefined,
            },
          }),
        };
      }, {}) ?? {};

    try {
      setIsLoading(true);
      // item, 카테고리, 프로모션, 기획전
      await fetchingAPI.put(`/item/v2/${itemId}`, itemPayload);
      // 가격
      !isEmpty(optionsPayload) &&
        (await fetchingAPI.put(`/item/v2/${itemId}/options`, optionsPayload));
      await load();
      toast.success('수정 성공');
    } catch (error) {
      console.error(error);
      toast.error('수정 실패');
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    load();
  }, []);

  return {
    item,
    price,
    history,
    isLoading,
    reload: load,
    onChange,
    usePricingIndex,
    onSubmit,
    state,
    handler,
    useDeliveryMethodState,
  };
};

type PriceRule = { agencyFeeRate: number; name: string; isActive: boolean };

export const useTasks = (
  itemData: Item.Item | null,
  priceData: [string, Item.PriceData][] | null
) => {
  const [resetTrigger, setResetTrigger] = useState(0);

  const [categories, setCategories] = useState(itemData?.categories);
  const [exhibitions, setExhibitions] = useState(itemData?.exhibitions);
  const [allPromotions, setallPromotions] = useState(itemData?.allPromotions);
  const [price, setPrice] = useState<[string, PriceRule[]][] | null>(null);

  const trigger = () => setResetTrigger((p) => p + 1);

  const categoriesHandler =
    (mode: 'add' | 'delete') => (_categories: { id: number; name: string }[]) =>
      setCategories((p) => [
        ...(p?.filter((category) =>
          _categories.every((_category) => category.id != _category.id)
        ) ?? []),
        ...(mode == 'add' ? _categories : []),
      ]);

  const exhibitionsHandler =
    (mode: 'add' | 'delete') =>
    (_exhibition: {
      exhibitionId: number;
      gender: string;
      isActive: boolean;
      name: string;
    }) =>
      setExhibitions((p) => [
        ...(p?.filter(
          (exhibition) => exhibition.exhibitionId != _exhibition.exhibitionId
        ) ?? []),
        ...(mode == 'add' ? [_exhibition] : []),
      ]);

  const allPromotionsHandler =
    (mode: 'add' | 'delete') => (_promotions: Item.Promotion[]) =>
      setallPromotions((p) => [
        ...(p?.filter((promotion) =>
          _promotions.every(
            (_promotion) => _promotion.promotionId != promotion.promotionId
          )
        ) ?? []),
        ...(mode == 'add' ? _promotions : []),
      ]);

  const priceHandler =
    (pricingIndex: { shopIndex: number; optionIndex: number }) =>
    (payload: Partial<PriceRule>) =>
      setPrice(
        (p) =>
          p?.map(([shopPriceRule, options], shopIndex) => [
            shopPriceRule,
            shopIndex === pricingIndex.shopIndex
              ? options.map((option, optionIndex) => ({
                  ...option,
                  ...(optionIndex === pricingIndex.optionIndex && payload),
                }))
              : options,
          ]) ?? null
      );

  useEffect(() => {
    if (resetTrigger > 0) {
      setCategories(itemData?.categories);
      setExhibitions(itemData?.exhibitions);
      setallPromotions(itemData?.allPromotions);
      setPrice(
        priceData?.map(([shopPriceRule, { options }]) => [
          shopPriceRule,
          options?.map((pricing) => ({
            agencyFeeRate: pricing?.agencyFeeRate ?? 0,
            isActive: pricing?.isActive ?? false,
            name: pricing.name,
          })) ?? [],
        ]) ?? null
      );
    }
  }, [resetTrigger]);

  return {
    state: {
      categories,
      exhibitions,
      allPromotions,
      price,
    },
    handler: {
      categoriesHandler,
      exhibitionsHandler,
      allPromotionsHandler,
      priceHandler,
    },
    trigger,
  };
};

type ItemUpdatePayload = {
  color: string;
  isDomesticDelivery: boolean;
  isFetchingPayActive: boolean;
  isSellable: boolean;
  isShopPayActive: boolean;
  searchKeywords: string[] | null;
  name: string;
  priority: number;
  category: {
    add: number[];
    remove: number[];
  };
  exhibitions: {
    add: number[];
    remove: number[];
  };
  promotions: {
    add: number[];
    remove: number[];
  };
};

type OptionUpdatePayload = {
  [id: number]: {
    agencyFeeRate?: number;
    name?: string;
    isActive?: boolean;
  };
};
