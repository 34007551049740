import fetchingAPI from 'api/fetchingAPI';
import ProductList from 'pages/ModifyExhibitionDetail/ProductList';
import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useObject } from 'services';

const PromotionProductList = ({ id }) => {
  const [{ items, count, bookmark, limit, isVerify }, setState] = useObject({
    items: [],
    count: 0,
    bookmark: null,
    limit: 100,
    isVerify: true,
  });

  const fetchItems = async () => {
    try {
      const { data } = await fetchingAPI.getItemsInPromotion(id, {
        bookmark,
        limit: Number(limit),
        isVerify,
      });
      setState({
        bookmark: data.bookmark,
        items: bookmark ? [...items, ...data.data] : data.data,
        count: data.count,
      });
    } catch (error) {
      alert(`error in fetchItems, ${error.message}`);
    }
  };

  useEffect(() => {
    fetchItems();
  }, [limit, isVerify]);

  return (
    <>
      총{count}개 상품
      <select
        value={limit}
        onChange={(e) =>
          setState({ bookmark: null, limit: Number(e.target.value), items: [] })
        }
        style={{ margin: '10px' }}
      >
        <option value="100">100개씩 보기</option>
        <option value="200">200개씩 보기</option>
        <option value="300">300개씩 보기</option>
        <option value="500">500개씩 보기</option>
        <option value="1000">1000개씩 보기</option>
        <option value="3000">3000개씩 보기</option>
        <option value="5000">5000개씩 보기</option>
      </select>
      <ProductList
        items={items}
        tabs={[]}
        onSave={() => {}}
        isVerify={isVerify}
        setIsVerify={(isVerify) => setState({ isVerify, bookmark: null, items: [] })}
        onRefresh={() => {}}
      />
      <Button
        style={{ margin: '10px auto', display: 'block' }}
        onClick={() => fetchItems()}
      >
        더보기
      </Button>
    </>
  );
};

export default PromotionProductList;
