import { fetchingAPI } from 'api/axios';
import { Loading, SearchButtonGroup } from 'components';
import TopBottomButtons from 'components/UI/molecules/TopBottomButtons';
import { LoadingContext } from 'context';
import React, { useContext, useState } from 'react';
import CatalogSearchOption from './CatalogSearchOptionTable';
import CatalogTable from './CatalogSearchOptionTable/CatalogTable';
import Pagination from './CatalogSearchOptionTable/Pagination';
import * as style from './style';

const ManageCatalog = () => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const initialState = {
    searchKey: '',
    searchType: 'FETCHING_CODE',

    isCatalogueMatch: 'null',

    priceType: 'null',
    priceEnd: 'null',
    priceStart: 'null',

    priorityList: [],
    priorityString: '',

    shopIdList: [],
    brandIdList: [],
    categoryIdList: [],
    promotionIdList: [],
    exhibitionIdList: [],

    limit: 100,
    // page: 1,

    sort: 'null',
    sortWay: 'null',

    needCount: false,
  };
  const [searchOptionTableState, setSearchOptionTableState] =
    useState(initialState);
  const [catalogs, setCatalogs] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchItems = (page = 1) => {
    handleIsLoading(true);

    const trimParams = (params) => {
      const result = {};

      if (params.searchKey !== '') {
        result.searchType = params.searchType;
        result.searchKey = params.searchKey;
      }

      if (params.isCatalogueMatch !== 'null') {
        result.isCatalogueMatch = params.isCatalogueMatch;
      }

      if (params.priceType != 'null') {
        result.priceType = params.priceType;
        result.priceStart = params.priceStart;
        result.priceEnd = params.priceEnd;
      }

      if (params.priorityList.includes(-1)) {
        result.priorityList = params.priorityList
          .sort()
          .slice(1)
          .concat(params.priorityString.split(',').map((v) => Number(v)));
      } else if (params.priorityList.length > 0) {
        result.priorityList = params.priorityList;
      }

      if (params.shopIdList.length !== 0) {
        result.shopIdList = params.shopIdList;
      }
      if (params.brandIdList.length !== 0) {
        result.brandIdList = params.brandIdList;
      }
      if (params.categoryIdList.length !== 0) {
        result.categoryIdList = params.categoryIdList;
      }
      if (params.promotionIdList.length !== 0) {
        result.promotionIdList = params.promotionIdList;
      }
      if (params.exhibitionIdList.length !== 0) {
        result.exhibitionIdList = params.exhibitionIdList;
      }

      result.limit = params.limit;

      result.needCount = params.needCount;

      return result;
    };

    const params = trimParams(searchOptionTableState);

    params.page = page;

    fetchingAPI
      .get('/catalogue', {
        params: params,
      })
      .then((res) => {
        console.log('res', res);
        setCatalogs(res.data.list);
        setTotalCount(res.data.totalCount);
        setCurrentPage(page);
        handleIsLoading(false);
      })
      .catch((err) => {
        alert(`error in fetchItems(), /productList/catalogue, ${err.message}`);
        handleIsLoading(false);
      });
  };

  return (
    <style.Outer>
      {isLoading && <Loading />}
      <TopBottomButtons />
      <style.ShadowContainer>
        <style.PageTitle>카탈로그 관리</style.PageTitle>
        <CatalogSearchOption
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
        <SearchButtonGroup
          fetchItems={fetchItems}
          setSearchOptionTableState={setSearchOptionTableState}
          initialState={initialState}
          setItems={setCatalogs}
        />
      </style.ShadowContainer>
      <style.ShadowContainer>
        <CatalogTable
          catalogs={catalogs}
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
          fetchItems={fetchItems}
          currentPage={currentPage}
          totalCount={totalCount}
        />
        <Pagination fetchItems={fetchItems} currentPage={currentPage} />
      </style.ShadowContainer>
    </style.Outer>
  );
};

export default ManageCatalog;
