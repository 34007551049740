import { deleteExhibition } from 'api/axios';
import React, { useEffect, useState } from 'react';
import * as S from './style';
import * as Modal from '../ExhibitionListModal';

const ExhibitionTable = ({ items = [], reload = () => { } }) => {
  const [sortOption, setSortOption] = useState('name,0');
  const [sortedItems, setsortedItems] = useState(items);

  const sortItems = async (items, option, isDescending) => {
    const copy = items;
    await copy.sort((p, n) =>
      compareValue(
        p[option],
        n[option],
        ['view', 'like', 'cart', 'sales'].includes(option),
      ),
    );
    if (isDescending) {
      copy.reverse();
    }
    setsortedItems([...copy]);
  };

  useEffect(() => {
    sortItems(
      items,
      sortOption.split(',')[0],
      Boolean(Number(sortOption.split(',')[1])),
    );
    setCheckedItems([]);
  }, [items, sortOption]);

  const [checkedItems, setCheckedItems] = useState([]);
  const onChangeCheckAll = () => {
    checkedItems.length === items.length
      ? setCheckedItems([])
      : setCheckedItems([...items]);
  };
  const onChangeCheckItem = (item, checked) => {
    checked
      ? setCheckedItems((p) => {
        p.splice(p.indexOf(item), 1);
        return [...p];
      })
      : setCheckedItems((p) => [...p, item]);
  };

  useEffect(() => {
    setCheckedItems(checkeditemsMap(checkedItems, items));
  }, [items]);

  return (
    <S.Table>
      <S.Thead>
        <tr>
          <S.Th colSpan={0}>
            <ChangeInfoLeft
              items={items}
              checkedItems={checkedItems}
              reload={reload}
            />
            <ChangeInfoRight
              sortOption={sortOption}
              setSortOption={setSortOption}
              checkedItems={checkedItems}
              reload={reload}
            />
          </S.Th>
        </tr>
      </S.Thead>
      <S.Tbody>
        <S.Tr style={{ background: '#E4E4E4' }}>
          <S.Td w={38}>
            <S.Checkbox
              onChange={onChangeCheckAll}
              checked={checkedItems.length === items.length}
            />
          </S.Td>
          <S.Td w={107}>담당자</S.Td>
          <S.Td w={69}>활성상태</S.Td>
          <S.Td w={68}>성별</S.Td>
          <S.Td w={157}>노출위치</S.Td>
          <S.Td w={205}>타이틀</S.Td>
          <S.Td w={176}>기간</S.Td>
          <S.Td w={104}>상품수</S.Td>
          <S.Td w={194}>최종 업데이트</S.Td>
          <S.Td w={138}>거래액</S.Td>
          <S.Td w={108}>조회수</S.Td>
          <S.Td w={108}>좋아요</S.Td>
          <S.Td w={111}>장바구니</S.Td>
          <S.Td w={100}>판매수</S.Td>
        </S.Tr>
        {sortedItems.map((item, idx) => (
          <ExhibitionTableItem
            key={idx}
            item={item}
            checked={checkedItems.includes(item)}
            setChecked={onChangeCheckItem}
          />
        ))}
      </S.Tbody>
    </S.Table>
  );
};

export default ExhibitionTable;

const compareValue = (p, n, isNumber = false) => {
  if (isNumber) {
    p = Number(p);
  }
  if (isNumber) {
    n = Number(n);
  }
  if (p === null && n !== null) {
    return 1;
  } else if (p !== null && n === null) {
    return -1;
  } else if (p === null && n === null) {
    return 0;
  }
  return p > n ? 1 : p < n ? -1 : 0;
};

const ChangeInfoLeft = (props) => {
  const [payload, setPayload] = useState('checkedItems');
  const [mode, setMode] = useState('isActive');

  const onChangePayload = (e) => setPayload(e.target.value);
  const onChangeMode = (e) => setMode(e.target.value);

  const [modalOption, setModalOption] = useState(initialModalOption);
  const resetModalOption = () => {
    setModalOption(initialModalOption);
    props.reload();
  };
  const showModal = () => {
    if (props[payload].length > 0) {
      setModalOption({
        mode,
        payload: props[payload],
      });
    } else {
      alert('하나 이상의 기획전을 선택하세요');
    }
  };

  return (
    <>
      <Modal.ChangeActivity
        show={modalOption.mode === 'isActive'}
        onHide={resetModalOption}
        items={modalOption.payload}
      />
      <Modal.ChangeVisible
        show={modalOption.mode === 'displayedAt'}
        onHide={resetModalOption}
        items={modalOption.payload}
      />
      <Modal.ChangeAssignee
        show={modalOption.mode === 'assigneeId'}
        onHide={resetModalOption}
        items={modalOption.payload}
      />
      <div>
        <S.Select value={payload} onChange={onChangePayload}>
          <option value="checkedItems">선택한 기획전의</option>
          <option value="items">검색결과 전체 기획전의</option>
        </S.Select>
        <S.Select value={mode} onChange={onChangeMode}>
          <option value="isActive">활성상태를</option>
          <option value="displayedAt">노출위치를</option>
          <option value="assigneeId">담당자를</option>
        </S.Select>
        <S.ManualButton style={{ background: 'black' }} onClick={() => showModal()}>
          일괄변경
        </S.ManualButton>
      </div>
    </>
  );
};

const ChangeInfoRight = ({
  sortOption,
  setSortOption,
  checkedItems,
  reload,
}) => {
  const onClickDelete = () => {
    if (checkedItems.length > 0) {
      if (
        window.confirm(`
${checkedItems.length}개 기획전을 삭제합니다.
${checkedItems.map(({ name }) => name).join('\n')}
        `)
      ) {
        fetchDeleteExhibition(checkedItems).then(() => reload());
      }
    } else {
      alert('하나 이상의 기획전을 선택하세요.');
    }
  };

  return (
    <div>
      <a href="/product/addExhibition" target="_blank">
        <S.ManualButton style={{ background: '#0D9AE9' }}>
          기획전 추가
        </S.ManualButton>
      </a>
      <S.ManualButton style={{ background: '#FF6F61' }} onClick={onClickDelete}>
        삭제
      </S.ManualButton>
      <S.Select
        onChange={(e) => setSortOption(e.target.value)}
        value={sortOption}
      >
        <option value="name,0">이름순</option>
        <option value="adminName,0">담당자순</option>
        {/* <option value=','>거래액 높은순</option> */}
        <option value="view,1">조회수 높은순</option>
        <option value="like,1">좋아요 높은순</option>
        <option value="cart,1">장바구니 높은순</option>
        <option value="sales,1">판매수 높은순</option>
        <option value="updatedAt,0">최종 업데이트 최신순</option>
        <option value="updatedAt,1">최종 업데이트 오래된순</option>
        <option value="startedAt,0">시작일 빠른순</option>
        <option value="startedAt,1">시작일 느린순</option>
        <option value="endedAt,0">종료일 빠른순</option>
        <option value="endedAt,1">종료일 느린순</option>
        <option value="itemCount,1">상품수순</option>
      </S.Select>
    </div>
  );
};

const ExhibitionTableItem = ({ item, checked, setChecked }) => {
  const {
    isActive,

    gender,

    isVisibleToAppMain,
    isVisibleToAppCategory,
    isVisibleToCafe24,
    isVisibleToCafe24Mobile,
    exhibitionId,

    name,

    startedAt,
    endedAt,

    adminName,

    itemCount,

    updatedAt,

    //거래액

    view,

    like,

    cart,

    sales,
  } = item;

  return (
    <S.Tr style={{ height: '118px' }}>
      <S.Td w={38}>
        <S.Checkbox
          checked={checked}
          onChange={() => setChecked(item, checked)}
        />
      </S.Td>
      <S.Td w={107}>{adminName}</S.Td>
      <S.Td w={69}>
        {isActive ? (
          <span style={{ color: '#498935' }}>활성</span>
        ) : (
          <span style={{ color: '#FF0707' }}>비활성</span>
        )}
      </S.Td>
      <S.Td w={68}>{gender === 'M' ? '남성' : '여성'}</S.Td>
      <S.Td w={157}>
        {Boolean(isVisibleToAppMain) && <span>앱 메인</span>}
        {Boolean(isVisibleToAppCategory) && <span>앱 모아보기</span>}
        {Boolean(isVisibleToCafe24) && (
          <span>
            웹 PC{' '}
            <a
              href={webExhibitionDetail(exhibitionId)}
              target="_blank"
              rel="noreferrer"
            >
              이동
            </a>
          </span>
        )}
        {Boolean(isVisibleToCafe24Mobile) && (
          <span>
            웹 모바일{' '}
            <a
              href={webExhibitionDetail(exhibitionId, true)}
              target="_blank"
              rel="noreferrer"
            >
              이동
            </a>
          </span>
        )}
      </S.Td>
      <S.Td w={205}>
        <a
          href={`/product/modify_exhibition_detail/${exhibitionId}`}
          target="_blank"
          rel="noreferrer"
        >
          {name}
        </a>
      </S.Td>
      <S.Td w={176}>
        {startedAt && <span>{trimDate(startedAt)}</span>}
        {startedAt || endedAt ? '~' : ''}
        {endedAt ? <span>{trimDate(endedAt)}</span> : '상시진행'}
      </S.Td>
      <S.Td w={104}>
        {itemCount && Number(itemCount).toLocaleString('en') + '개'}
      </S.Td>
      {updatedAt && <S.Td w={194}>{trimDate(updatedAt, true)}</S.Td>}
      <S.Td w={138}></S.Td>
      <S.Td w={108}>{view && Number(view).toLocaleString('en')}</S.Td>
      <S.Td w={108}>{like && Number(like).toLocaleString('en')}</S.Td>
      <S.Td w={111}>{cart && Number(cart).toLocaleString('en')}</S.Td>
      <S.Td w={100}>{sales && Number(sales).toLocaleString('en')}</S.Td>
    </S.Tr>
  );
};

const trimDate = (ISOstring, detail = false) => {
  const date = new Date(ISOstring);
  date.setHours(date.getHours() - 9);
  return detail
    ? date.getFullYear() +
    '.' +
    (date.getMonth() + 1) +
    '.' +
    date.getDate() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes() +
    ':' +
    date.getSeconds()
    : date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
};

const webExhibitionDetail = (id, mobile = false) => {
  return `https://${mobile ? 'm.' : ''}fetching.co.kr/exhibition.html?id=${id}`;
};

const initialModalOption = {
  payload: [],
  mode: null,
};

const fetchDeleteExhibition = async (checkedItems) => {
  try {
    const { data } = await deleteExhibition(checkedItems);
    if (data.failed.length) {
      alert(`${data.failed.length}개 항목을 변경하는데 실패했습니다.`);
    } else {
      alert(`${data.success.length}개 항목을 변경했습니다.`);
    }
  } catch (error) {
    alert(
      `err in fetchDeleteExhibition(), in <ChangeInfoRight/>,${error.message}`,
    );
  }
};

const checkeditemsMap = (oldCheckedItems, newItems = []) => {
  const oldCheckedItemIds = oldCheckedItems.map(
    ({ exhibitionId }) => exhibitionId,
  );

  const result = [];

  newItems.forEach(
    (value) =>
      oldCheckedItemIds.includes(value.exhibitionId) && result.push(value),
  );

  return result;
};
