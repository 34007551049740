import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  addSelectItemAction,
  removeSelectItemAction,
  selectPromotionDataAction,
} from 'actions/action';
import { OverlayTrigger, Button } from 'react-bootstrap';
import { RenderTooltip } from 'components/UI';
import dayjs from 'dayjs';
import * as style from './style';
import { fetchingAPI } from 'api/axios';

const PromotionTableItem = ({
  promotion,
  shopList,
  handlePromotionModalShow,
  handleRefresh,
}) => {
  const dispatch = useDispatch();
  const promotionSelectItems = useSelector((state) => state.selectItems.itemsId);

  const [gender, setGender] = useState(
    promotion.gender === 'MW' ? '남녀 공용' : promotion.gender === 'M' ? '남성' : '여성'
  );
  const [isChecked, setIsChecked] = useState(false);
  const [currency, setCurrency] = useState('');
  const [status, setStatus] = useState(false);

  const handleSelectItems = (check) => {
    if (check) {
      dispatch(addSelectItemAction(promotion.id));
    } else {
      dispatch(removeSelectItemAction(promotion.id));
    }
  };

  const goToModifyPromotionPage = (e) => {
    if (e.target !== e.currentTarget) {
      return;
    }
    promotion.currency = currency;
    dispatch(selectPromotionDataAction(promotion));
    window.open(`/product/modifyPromotion/${promotion.id}`);
  };

  useEffect(() => {
    const shop = shopList.find((shop) => {
      return shop.id === promotion.shopId;
    });
    setCurrency(shop.currency);
  }, [shopList, promotion.shopId]);

  useEffect(() => {
    const isCheck = promotionSelectItems.indexOf(promotion.id);
    setIsChecked(isCheck === -1 ? false : true);
  }, [promotionSelectItems, promotion.id]);

  useEffect(() => {
    let result;
    if (!promotion.startedAt && !promotion.endedAt) {
      result = true;
    } else if (promotion.startedAt && !promotion.endedAt) {
      let today = new Date();
      today = today.setHours(today.getHours() + 9);
      const startedAt = new Date(`${promotion.startedAt}`);
      result = startedAt < today;
    } else {
      let today = new Date();
      today = today.setHours(today.getHours() + 9);
      const startedAt = new Date(`${promotion.startedAt}`);
      const endedAt = new Date(`${promotion.endedAt}`);
      result = startedAt < today && today < endedAt;
    }
    setStatus(result);
  }, []);

  const registerShopPromotion = async (promotion) => {
    const params = {
      shopPromotionId: promotion.id,
    };

    await fetchingAPI.post('/admin/shop-promotion-priority', params);

    handleRefresh();
  };

  const unregisterShopPromotion = async (promotion) => {
    await fetchingAPI.delete(`/admin/shop-promotion-priority/${promotion.id}`);
    handleRefresh();
  };

  const renderPromotionPeriod = () => {
    return (
      <div style={{ textAlign: 'left' }}>
        <p>
          시작일:{' '}
          {promotion.startedAt ? dayjs(promotion.startedAt).format('YYYY-MM-DD') : '없음'}
        </p>
        <p>
          종료일:
          {promotion.endedAt ? dayjs(promotion.endedAt).format('YYYY-MM-DD') : '무제한'}
        </p>
        <p>생성일: {dayjs(promotion.createdAt).format('YYYY-MM-DD')}</p>
      </div>
    );
  };

  return (
    <style.PromotionTableOuter onClick={goToModifyPromotionPage}>
      <style.CheckBoxTableItem>
        <style.Input
          checked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked);
            handleSelectItems(e.target.checked);
          }}
        />
      </style.CheckBoxTableItem>

      <style.PromotionTableItem>
        <style.Text color="gray">{promotion.shopName}</style.Text>
        <style.Text color="#968989" fontSizeUp={'1.12rem'}>
          {promotion.name}
        </style.Text>
      </style.PromotionTableItem>

      <style.PromotionTableItem>
        <style.Text color="#968989">
          담당자 : {promotion.assignee ? promotion.assignee : '없음'}
        </style.Text>
        <style.Text>
          {promotion.applyStatus === 'TO_BE_APPLIED' ? '적용 예정' : null}
          {promotion.applyStatus === 'APPLYING' ? '적용중' : null}
          {promotion.applyStatus === 'APPLIED' ? '적용 완료' : null}
          {promotion.applyStatus === 'TO_BE_UPDATED' ? '업데이트 예정' : null}
          {promotion.applyStatus === 'UPDATING' ? '업데이트 중' : null}
          {promotion.applyStatus === 'UPDATED' ? '업데이트 완료' : null}
        </style.Text>
        <style.Text color={'#565353'}>
          {promotion.updatedAt > '2021-02-18T16:00:00.000Z'
            ? promotion.updatedAt?.slice(0, 10) + ' ' + promotion.updatedAt?.slice(11, 19)
            : '업데이트 시간 없음'}
        </style.Text>
      </style.PromotionTableItem>
      <style.PromotionTableItem>{renderPromotionPeriod()}</style.PromotionTableItem>

      <style.PromotionTableItem>
        {promotion.isActive ? (
          <style.IsActivePromotion isActive="active">ON</style.IsActivePromotion>
        ) : (
          <style.IsActivePromotion>OFF</style.IsActivePromotion>
        )}
        {status && promotion.isApplied ? (
          <style.IsActivePromotion isActive="active">
            가격 반영 중
          </style.IsActivePromotion>
        ) : (
          <style.IsActivePromotion>가격 반영 안함</style.IsActivePromotion>
        )}
        {/* 최종 진행 변화 시각(로그) */}
      </style.PromotionTableItem>

      <style.PromotionTableItem>
        <style.Text>{gender} 프로모션</style.Text>
        <style.Text color="#968989">
          {promotion.type === 'DISCOUNT_WITH_RATE'
            ? `${Math.round(promotion.typeValue * 100)} % 할인`
            : null}
          {promotion.type === 'DISCOUNT_WITH_CONST'
            ? `${currency} ${promotion.typeValue} 가격할인`
            : null}
          {promotion.type === 'FREE_DELIVERY'
            ? `${currency} ${promotion.typeValue} 무료배송`
            : null}
        </style.Text>
        <style.Text fontSizeUp={'1.12rem'}>
          {promotion.code ? promotion.code : '코드 없음'}
        </style.Text>
        <style.LinkBox onClick={() => window.open(promotion.url)}>링크연결</style.LinkBox>
      </style.PromotionTableItem>

      <style.PromotionTableItem>
        {promotion.topBannerImageUrl ? (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<RenderTooltip />}
          >
            <style.Text
              onClick={() =>
                handlePromotionModalShow('상단 고정 배너', promotion.topBannerImageUrl)
              }
              color="green"
              hover="hover"
            >
              {'등록 > 앱 > 상단 고정 배너'}
            </style.Text>
          </OverlayTrigger>
        ) : (
          <style.Text color="gray"> {'미등록 > 앱 > 상단 고정 배너'}</style.Text>
        )}
        {promotion.bannerImageUrl ? (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<RenderTooltip />}
          >
            <style.Text
              onClick={() =>
                handlePromotionModalShow('배너 이미지', promotion.bannerImageUrl)
              }
              color="green"
              hover="hover"
            >
              {'등록 > 앱 > 목록 배너'}
            </style.Text>
          </OverlayTrigger>
        ) : (
          <style.Text color="gray">{'미등록 > 앱 > 목록 배너'}</style.Text>
        )}
        {promotion.landingImageUrl ? (
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<RenderTooltip />}
          >
            <style.Text
              onClick={() =>
                handlePromotionModalShow('랜딩 이미지', promotion.landingImageUrl)
              }
              color="green"
              hover="hover"
            >
              {'등록 > 앱 > 상세 랜딩 배너'}
            </style.Text>
          </OverlayTrigger>
        ) : (
          <style.Text color="gray">{'미등록 > 앱 > 상세 랜딩 배너'}</style.Text>
        )}
      </style.PromotionTableItem>

      <style.PromotionTableItem>
        {promotion.isVisibleToAppMain === 1 ? (
          <style.Text color="green">{`노출 앱 > ${gender} > 홈탭 > 세일탭 상단`}</style.Text>
        ) : (
          <style.Text color="gray">{`미노출 앱 > ${gender} > 홈탭 > 세일탭 상단`}</style.Text>
        )}
        {promotion.isVisibleToAppCategory === 1 ? (
          <style.Text color="green">{`노출 앱 > ${gender} > 카테고리탭 > 프로모션 목록`}</style.Text>
        ) : (
          <style.Text color="gray">{`미노출 앱 > ${gender} > 카테고리탭 > 프로모션 목록`}</style.Text>
        )}
      </style.PromotionTableItem>
      <style.PromotionTableItem>
        {promotion.isPriority ? (
          <Button variant="danger" onClick={() => unregisterShopPromotion(promotion)}>
            해제
          </Button>
        ) : (
          <Button variant="success" onClick={() => registerShopPromotion(promotion)}>
            등록
          </Button>
        )}
      </style.PromotionTableItem>
    </style.PromotionTableOuter>
  );
};

export default withRouter(PromotionTableItem);
