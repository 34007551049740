import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchingSymbol } from 'assets/images';
import { UserMenu } from 'components';
import View from './View';
import * as style from './style';
import { headerAction } from 'actions/action';
import { useUser } from 'services';

const ExhibitionDetail = (props) => {
  const dispatch = useDispatch();
  const { user } = useUser();
  const exhibition = useSelector((state) => state.exhibition);

  useEffect(() => {
    dispatch(headerAction(false));
  }, [dispatch]);

  return (
    <style.Wrapper>
      <style.HeaderOuter>
        <style.HeaderItem>
          <style.Img src={fetchingSymbol} alt="Fetching fetchingSymbol" width={50} />
          <style.Title>
            <div>기획전 정보</div>
          </style.Title>
        </style.HeaderItem>
        <style.HeaderItem>{!!user && <UserMenu />}</style.HeaderItem>
      </style.HeaderOuter>
      <style.Outer>
        <View item={exhibition.selectItem} />
      </style.Outer>
      <style.BottomOuter>
        <style.Button
          background="#69ABF9"
          onClick={() => {
            window.open('/product/modifyExhibition');
          }}
        >
          수정
        </style.Button>
        <style.Button
          background="#FF6F61"
          onClick={() => {
            dispatch(headerAction(true));
            props.history.goBack();
          }}
        >
          취소
        </style.Button>
      </style.BottomOuter>
    </style.Wrapper>
  );
};

export default withRouter(ExhibitionDetail);
