import React, { useEffect, useState } from 'react';
import PromotionTableItem from './PromotionTableItem';
import TableActionBar from './TableActionBar';
import {
  ActiveModal,
  DisplayStatusModal,
  AssineeModal,
  PriorityModal,
} from './ModifyModals';
import { fetchingAPI } from 'api/axios';
import FetchingAPI from 'api/fetchingAPI';
import * as style from './style';
import * as utils from '../utils';
import { toast } from 'react-toastify';

const PromotionList = ({ items, orderPromotion, handleRefresh }) => {
  const [selectedItem, setSelectedItems] = useState([]);
  const [sortOption, setSortOption] = useState('shopName,0');
  const [modifyOption, setModify] = useState({
    items: 'checked',
    action: 'isActive',
  });

  const [showModal, toggelModal] = useState(false);

  const onSelect = (id) => {
    const index = selectedItem.findIndex((item) => item === id);

    if (index > -1) {
      selectedItem.splice(index, 1);
      setSelectedItems([...selectedItem]);
    } else {
      setSelectedItems([...selectedItem, id]);
    }
  };

  const toggleAllSelection = (e) => {
    let selections = [];
    if (e.target.checked) {
      selections = items.map((item) => item.id);
    }
    setSelectedItems(selections);
  };

  const onDeletePromotion = async () => {
    if (selectedItem.length === 0) {
      return alert('선택된 프로모션이 없습니다.');
    } else if (
      window.confirm(
        '프로모션 삭제 누르면 절대 복구 불가하며 상품에 적용된 프로모션이 모두 삭제됩니다.\n\n' +
          selectedItem.length +
          '개 프로모션 삭제'
      )
    ) {
      await Promise.all(
        selectedItem.map((id) => fetchingAPI.delete(`/promotion/v2/${id}/`))
      ).catch((err) => alert(`err in /promotion/v2/{id}/, ${err.message}`));
      handleRefresh();
    }
  };

  const onModify = (value) => {
    if (value.items === 'checked' && selectedItem.length === 0) {
      return alert('선택된 프로모션이 없습니다.');
    }
    toggelModal('true');
    setModify(value);
  };

  const doSort = () => {
    if (sortOption) {
      const [type, sort] = sortOption.split(',');

      items.sort((a, b) => {
        let x, y;
        if (!utils.isNumber(a[type]) && typeof a[type] === 'string') {
          x = a[type] && a[type].toLowerCase();
          y = b[type] && b[type].toLowerCase();
          return sort === '1' ? utils.sort.desc(x, y) : utils.sort.asc(x, y);
        } else if (utils.isNumber(a[type])) {
          x = Number(a[type]);
          y = Number(b[type]);
          return sort === '1' ? y - x : x - y;
        }
      });
    }
    orderPromotion(items);
  };

  const updatePromotions = async (data) => {
    // data : <request-payload>

    const { items: target, action } = modifyOption;
    try {
      await FetchingAPI.putPromotionInfo(
        makePayload(
          target === 'checked' ? selectedItem : items.map(({ id }) => id),
          action,
          data
        )
      );
      toast.info('업데이트 되었습니다.');
    } catch (error) {
      alert(`err in updatePromotions, ${error.message}`);
    }
    handleRefresh();
  };

  const modalClose = () => {
    toggelModal(false);
  };

  const registerShopPromotion = async (promotion) => {
    const params = {
      shopPromotionId: promotion.id,
    };

    await fetchingAPI.post('/admin/shop-promotion-priority', params);

    handleRefresh();
  };

  const unregisterShopPromotion = async (promotion) => {
    await fetchingAPI.delete(`/admin/shop-promotion-priority/${promotion.id}`);
    handleRefresh();
  };

  useEffect(() => {
    doSort();
  }, [sortOption, items.length]);

  useEffect(() => {
    setSelectedItems([]);
  }, [orderPromotion]);

  return (
    <>
      <ActiveModal
        show={showModal && modifyOption.action === 'isActive'}
        onClose={modalClose}
        onOk={updatePromotions}
      />
      <DisplayStatusModal
        show={showModal && modifyOption.action === 'applyStatus'}
        onClose={modalClose}
        onOk={updatePromotions}
      />
      <AssineeModal
        show={showModal && modifyOption.action === 'assigneeId'}
        onClose={modalClose}
        onOk={updatePromotions}
      />
      <PriorityModal
        show={showModal && modifyOption.action === 'isPrioritized'}
        onClose={modalClose}
        onOk={updatePromotions}
      />
      <style.Table>
        <style.Thead>
          <tr>
            <style.Th colSpan={0}>
              <TableActionBar
                onDeletePromotion={onDeletePromotion}
                setSortOption={setSortOption}
                onModify={onModify}
                sortOption={sortOption}
              />
            </style.Th>
          </tr>
        </style.Thead>
        <style.Tbody>
          <style.Tr style={{ background: '#E4E4E4' }}>
            <style.Td w={38}>
              <style.Checkbox
                onClick={toggleAllSelection}
                checked={selectedItem.length === items.length}
              />
            </style.Td>
            <style.Td w={69}>활성상태</style.Td>
            <style.Td w={40}>중요 프로모션 여부</style.Td>
            <style.Td w={80}>업데이트 방식</style.Td>
            <style.Td w={69}>즐겨찾기</style.Td>
            <style.Td w={100}>편집샵</style.Td>
            <style.Td w={110}>타이틀</style.Td>
            <style.Td w={69}>할인율</style.Td>
            <style.Td w={100}>진행기간</style.Td>
            <style.Td w={80}>담당자</style.Td>
            <style.Td w={110}>업데이트 현황</style.Td>
            <style.Td w={69}>상품수</style.Td>
            <style.Td w={110}>노출위치</style.Td>
            <style.Td w={69}>조회수</style.Td>
            <style.Td w={69}>좋아요</style.Td>
            <style.Td w={69}>장바구니</style.Td>
            <style.Td w={69}>판매수</style.Td>
          </style.Tr>

          {items.map((item, idx) => (
            <PromotionTableItem
              key={idx}
              item={item}
              onSelect={onSelect}
              selectedItem={selectedItem}
              registerShopPromotion={registerShopPromotion}
              unregisterShopPromotion={unregisterShopPromotion}
            />
          ))}
        </style.Tbody>
      </style.Table>
    </>
  );
};

export default PromotionList;

const makePayload = (itemsIds = [], action, data) =>
  itemsIds.map((id) => ({
    promotionId: id,
    [action]: data,
  }));
