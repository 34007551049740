import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { RenderTooltip } from '../../atoms';
import * as style from './style';

const ShopTableItem = ({ shop }) => {
  const goToShopDetail = () => {
    window.open(`/product/shop/shopDetail/${shop.id}`);
  };

  return (
    <style.Wrapper>
      <style.Item>{shop.id}</style.Item>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 100, hide: 500 }}
        overlay={
          <RenderTooltip message={'클릭하면 상세 페이지로 이동합니다.'} />
        }
      >
        <style.ShopName onClick={goToShopDetail}>{shop.krName}</style.ShopName>
      </OverlayTrigger>
      <style.Item>{shop.priority}</style.Item>
      <style.Item color={shop.isActive === 1 ? 'green' : 'red'}>
        {shop.isActive === 1 ? '노출' : '미노출'}
      </style.Item>
      <style.Item
        color={
          shop.isCafe24Uploading === 1
            ? 'green'
            : shop.isCafe24Uploading === 0
            ? '#FF891C'
            : 'red'
        }
      >
        {shop.isCafe24Uploading === 1
          ? '대상'
          : shop.isCafe24Uploading === 0
          ? '일시중지'
          : '미대상'}
      </style.Item>
      <style.Item color={shop.isFetchingPay === 1 ? 'green' : 'red'}>
        {shop.isFetchingPay === 1 ? '지원' : '미지원'}
      </style.Item>
      <style.Item color={shop.cafe24Uploadable === 1 ? 'green' : 'red'}>
        {shop.cafe24Uploadable === 1 ? '가능' : '불가능'}
      </style.Item>
    </style.Wrapper>
  );
};
export default ShopTableItem;
