import React from 'react';
import { Modal } from 'react-bootstrap';

const Modals = ({ show, handleClose, title, children, actions }) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>{actions}</Modal.Footer>
    </Modal>
  );
};

export default Modals;
