import { StatusList } from 'pages/PartnerItem/types';
import React from 'react';
import { Action, PartnerItemRejectReasonType, SearchType } from './types';

export const searchTypeOptions: { value: SearchType; name: string }[] = [
  {
    value: SearchType.ITEM_NAME,
    name: '상품명',
  },
];

export const maxSizeQuantityOptions: { value: number; name: string }[] = [
  { value: 0, name: '재고 없음' },
  { value: 10, name: '재고 10개 미만' },
  { value: 50, name: '재고 50개 미만' },
];

export const maxSellDateOptions: { value: number; name: string }[] = [
  { value: 3, name: '3일 이내' },
  { value: 7, name: '7일 이내' },
  { value: 14, name: '14일 이내' },
  { value: 30, name: '130일 이내' },
];

export const actionOptions: { value: Action; name: string }[] = [
  { value: Action.ACCEPT, name: '승인' },
  { value: Action.REJECT, name: '반려' },
];

export const limitOptions: { value: number; name: string }[] = [
  { value: 50, name: '50개씩 보기' },
  { value: 100, name: '100개씩 보기' },
  { value: 200, name: '200개씩 보기' },
  { value: 300, name: '300개씩 보기' },
  { value: 500, name: '500개씩 보기' },
];

export const rejectReasonTypeOptions: {
  value: PartnerItemRejectReasonType;
  name: string;
}[] = [
  { value: PartnerItemRejectReasonType.CATEGORY, name: '카테고리 수정 필요' },
  { value: PartnerItemRejectReasonType.BRAND, name: '브랜드 수정 필요' },
  { value: PartnerItemRejectReasonType.IMAGE, name: '이미지 수정 필요' },
  { value: PartnerItemRejectReasonType.ITEM_NAME, name: '상품명 수정 필요' },
  { value: PartnerItemRejectReasonType.OPTION, name: '옵션명 수정 필요' },
  { value: PartnerItemRejectReasonType.QUANTITY, name: '재고 수정 필요' },
  { value: PartnerItemRejectReasonType.ETC, name: '기타' },
];

export const getFixedTdStyle = (left: number, width: number): React.CSSProperties => ({
  position: 'sticky',
  left,
  width,
  zIndex: 10,
});

export const getCategoryCasacaderOptions = (category: CommerceCategory[]) =>
  category.map((category) => ({
    value: category.id,
    label: category.name,
    children: category.children
      ? getCategoryCasacaderOptions(category.children)
      : undefined,
  }));
