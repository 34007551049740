import styled from 'styled-components';

export const Container = styled.div`
  padding: 50px;
`;

export const ChartWrapper = styled.div`
  width: 100%;
  margin: 100px 0;
  padding: 30px 50px;
  border: 1px solid #ddd;
`;
