import { Modal } from 'react-bootstrap';
import React, { useEffect, useRef, useState } from 'react';
import { fetchingAPI } from 'api/axios';
import { HeadTableData, BodyTableData } from './style';
import dayjs from 'dayjs';
import { useUser } from 'services';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const AdminMemoModal = ({ show, onHide, targetId }) => {
  const [memo, setMemo] = useState('');
  const [memos, setMemos] = useState([]);
  const [memoId, setMemoId] = useState(0);
  const textareaRef = useRef();
  const { user } = useUser();
  const { push, location } = useHistory();

  const handleHide = () => {
    if (memo) {
      if (window.confirm('작성을 중단하시겠습니까?')) onHide();
    } else {
      onHide();
    }
  };
  const getMemos = async () => {
    try {
      const { data } = await fetchingAPI.get(`/commerce/user/${targetId}/memo`);
      setMemos(data);
    } catch (err) {
      console.log(err);
    }
  };
  const updateMemo = async (index) => {
    if (!memo) return;

    try {
      if (memoId) {
        await fetchingAPI.put(`/commerce/user/${targetId}/memo/${memoId}`, {
          data: memo,
        });
        setMemos([
          ...memos.slice(0, index),
          { ...memos[index], content: memo },
          ...memos.slice(index + 1),
        ]);
      } else {
        await fetchingAPI.post(`/commerce/user/${targetId}/memo`, {
          data: memo,
        });
        getMemos();
      }
      setMemo('');
    } catch (err) {
      console.log(err);
    }
  };
  const deleteMemo = async (memoId, admin_id) => {
    if (!user) {
      toast.info('로그인 상태가 아닙니다.');
      push(`/login?redirectUrl=${location.pathname}`);
      return;
    }
    if (admin_id !== user.id) {
      alert('타인의 메모를 삭제할 수 없습니다.');
      return;
    }
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    try {
      await fetchingAPI.delete(`/commerce/user/${targetId}/memo/${memoId}`);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getMemos();
  }, []);
  return (
    <Modal
      show={show}
      onHide={handleHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        style={{
          height: 40,
          textAlign: 'center',
          padding: 9,
          background: '#000',
          color: '#FF6F61',
          fontSize: 16,
          fontWeight: 'bold',
        }}
      >
        관리자 메모
      </div>
      <div style={{ padding: 16 }}>
        <table
          style={{
            marginBottom: 44,
            width: '100%',
            border: '0.5px solid #b4b4b4',
          }}
        >
          <thead style={{ background: '#e4e4e4' }}>
            <tr>
              <HeadTableData>작성일</HeadTableData>
              <HeadTableData>작성자</HeadTableData>
              <HeadTableData>내용</HeadTableData>
              <HeadTableData>관리</HeadTableData>
            </tr>
          </thead>
          <tbody>
            {memos.map((item, key) => (
              <tr style={{ borderTop: '0.5px solid #b4b4b4' }} key={key}>
                <BodyTableData style={{ background: '#fafafa' }}>
                  {dayjs(item.createdAt).format('YYYY.MM.DD HH:mm:ss')}
                </BodyTableData>
                <BodyTableData>이름 (작업중)</BodyTableData>
                <BodyTableData style={{ textAlign: 'left' }}>
                  {item.content}
                </BodyTableData>
                <BodyTableData
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 7,
                    alignItems: 'center',
                  }}
                >
                  <button
                    onClick={() => {
                      if (item.userId !== user.id) {
                        alert('타인의 메모를 수정할 수 없습니다.');
                        return;
                      }
                      setMemo(item.content);
                      setMemoId(item.idx);
                      if (textareaRef.current) textareaRef.current.focus();
                    }}
                    style={{
                      background:
                        'linear-gradient(0deg, #498935, #498935), linear-gradient(0deg, #498935, #498935), #498935',
                      color: '#fff',
                      borderRadius: 1,
                      outline: 'none',
                      border: 'none',
                      width: 48,
                    }}
                  >
                    수정
                  </button>
                  <button
                    onClick={() => deleteMemo(item.idx, item.userId)}
                    style={{
                      background:
                        'linear-gradient(0deg, #FF0707, #FF0707), linear-gradient(0deg, #498935, #498935), #498935',
                      color: '#fff',
                      borderRadius: 1,
                      outline: 'none',
                      border: 'none',
                      width: 48,
                    }}
                  >
                    삭제
                  </button>
                </BodyTableData>
              </tr>
            ))}
          </tbody>
        </table>
        <h2 style={{ fontSize: 16, fontWeight: 'bold' }}>등록하기</h2>
        <div
          style={{
            display: 'flex',
            border: '0.5px solid #b4b4b4',
            marginBottom: 18,
          }}
        >
          <div
            style={{
              background: '#fafafa',
              fontSize: 12,
              width: 130,
              padding: 8,
              borderRight: '0.5px solid #b4b4b4',
            }}
          >
            내용
          </div>
          <div style={{ padding: '10px 6px', width: '100%' }}>
            <textarea
              ref={textareaRef}
              style={{
                width: '100%',
                resize: 'none',
                border: '0.5px solid #989595',
                minHeight: 80,
                padding: 4,
              }}
              autoFocus
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 8,
          }}
        >
          <button
            style={{
              padding: '8px 20px',
              border: 'none',
              outline: 'none',
              borderRadius: 5,
              background: '#000',
              color: '#FF6F61',
            }}
            onClick={updateMemo}
          >
            {!!memoId ? '수정' : '저장'}
          </button>
          <button
            style={{
              padding: '8px 20px',
              border: 'none',
              outline: 'none',
              borderRadius: 5,
              background: '#ddd',
            }}
            onClick={handleHide}
          >
            닫기
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AdminMemoModal;
