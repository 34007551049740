import {
  Button,
  Card,
  GridTable,
  Radio,
  ReInput,
  RePagination,
  Select,
  Tooltip,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { enumToList, ORDER_DATE_LIST, ORDER_SEARCH_TYPE, useOrderTable } from 'services';

interface State {}

const OrdersCardCancelPage = () => {
  const get = async () => {};
  const {
    searchType,
    searchText,
    onChangeOrder,
    onChangeCheckbox,
    onDateClick,
    setOrderState,
    isLoading,
    minOrderDate,
    maxOrderDate,
    page,
    total,
    limit,
    selectedIdx,
    list,
    resetOrderState,
  } = useOrderTable();

  useEffect(() => {
    get();
  }, [minOrderDate, maxOrderDate, page, limit]);
  return (
    <>
      <div className="wrapper">
        <div className="wrapper-title">카드취소 조회</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <ReInput
                    size="xs"
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '기간',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select size="xs" disabled={isLoading}>
                    <option value="">카드취소일</option>
                  </Select>
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '상품',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select size="xs" disabled={isLoading}>
                    <option value="" selected disabled>
                      상품명
                    </option>
                  </Select>
                  <ReInput size="xs" disabled={isLoading} />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문상태',
              renderItem: () => (
                <Radio
                  id="order"
                  options={[
                    { name: '전체', value: '' },
                    { name: '전체취소', value: '' },
                    { name: '부분취소', value: '' },
                  ]}
                  value=""
                  onChange={() => {}}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
          ]}
        />

        <div className="mt-7 flex justify-center gap-3">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>

      <div className="wrapper">
        <GridTable
          maxHeight=""
          columns={[
            '결제일(취소일)',
            '주문번호',
            '주문자',
            'TID(거래번호)',
            '취소금액',
            '취소구분',
            '취소처리자',
            '메모',
          ]}
          list={list}
          renderItem={(item, key) => (
            <div onClick={() => onChangeCheckbox(item.fetchingOrderNumber)} key={key}>
              <div aria-label="checkbox">
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={selectedIdx.indexOf(item.fetchingOrderNumber) !== -1}
                />
              </div>
              <div aria-label="결제일 (취소일)">
                <div>2021-09-14 17:02:10</div>
                <div>(2021-10-07 10:53:40)</div>
              </div>
              <div aria-label="주문번호">20210914-0000107</div>
              <div aria-label="주문자">
                <div>김성우</div>
                <div>[일반회원]</div>
              </div>
              <div aria-label="TID (거래번호)">1035896411</div>
              <div aria-label="취소금액">KRW 186,500</div>
              <div aria-label="취소구분">전체취소</div>
              <div aria-label="취소처리자">-</div>
              <div aria-label="메모">
                <Tooltip position="left" content={<div>관리자 메모 내용보기</div>}>
                  <Button size="xs">메모</Button>
                </Tooltip>
              </div>
            </div>
          )}
          isLoading={isLoading}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setOrderState({
              selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
            })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
    </>
  );
};

export default OrdersCardCancelPage;
