import React, { useEffect } from 'react';
import {
  Card,
  Radio,
  RePagination,
  Select,
  ReInput,
  Checkbox,
  Button,
  OpenLink,
  GridTable,
} from 'components';
import {
  enumToList,
  GENDER,
  getAge,
  INFLOW_ORIGIN_CAMPAIGN,
  INFLOW_ORIGIN_SOURCE,
  PATH,
  request,
  useObject,
  USER_TYPE,
} from 'services';
import type { IRequestResult, IUser } from 'types';
import { toast } from 'react-toastify';
import { ExportToCsv } from 'export-to-csv';
import dayjs from 'dayjs';

interface State {
  searchType: string;
  searchQuery: string;
  gender: string;
  type: string;
  inflowDestination: string;
  signupType: string;
  minBirthDate: string;
  maxBirthDate: string;
  minCreatedAt: string;
  maxCreatedAt: string;
  total: number;
  page: number;
  list: IUser[];
  inflowStandard: string;
  inflowOriginSource: string;
  inflowOriginCampaign: string;
  sort: string;
  pageSize: number;
  emailAllowed: boolean;
  smsAllowed: boolean;
  marketingPushAllowed: boolean;
  nightPushAllowed: boolean;
  pushAllowed: boolean;
  selectedIdx: number[];
  dealMethod: string;
  isLoading: boolean;
  level: string;
  isProcessing: boolean;
  isDownloading: boolean;
}

const UsersPage = () => {
  const [
    {
      searchType,
      searchQuery,
      gender,
      type,
      inflowDestination,
      signupType,
      minBirthDate,
      maxBirthDate,
      minCreatedAt,
      maxCreatedAt,
      page,
      total,
      list,
      inflowStandard,
      inflowOriginCampaign,
      inflowOriginSource,
      sort,
      pageSize,
      smsAllowed,
      pushAllowed,
      emailAllowed,
      nightPushAllowed,
      marketingPushAllowed,
      selectedIdx,
      dealMethod,
      isLoading,
      level,
      isProcessing,
      isDownloading,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    searchType: 'NAME',
    searchQuery: '',
    gender: '',
    type: '',
    inflowDestination: '',
    signupType: '',
    minBirthDate: '',
    maxBirthDate: '',
    minCreatedAt: '',
    maxCreatedAt: '',
    total: 0,
    page: 1,
    list: [],
    inflowStandard: '',
    inflowOriginSource: '',
    inflowOriginCampaign: '',
    sort: 'CREATED_AT',
    pageSize: 100,
    smsAllowed: false,
    pushAllowed: false,
    emailAllowed: false,
    nightPushAllowed: false,
    marketingPushAllowed: false,
    selectedIdx: [],
    dealMethod: 'ChangeUserLevel',
    isLoading: false,
    level: 'USER',
    isProcessing: false,
    isDownloading: false,
  });

  const get = async () => {
    setState({ isLoading: true });
    let params: any = {
      limit: pageSize,
      page,
      sort,
      smsAllowed,
      pushAllowed,
      emailAllowed,
      nightPushAllowed,
      marketingPushAllowed,
    };

    if (!!gender) params.gender = gender;
    if (!!inflowDestination) params.inflowDestination = inflowDestination;
    if (!!inflowOriginCampaign) params.inflowOriginCampaign = inflowOriginCampaign;
    if (!!inflowOriginSource) params.inflowOriginSource = inflowOriginSource;
    if (!!inflowStandard) params.inflowStandard = inflowStandard;
    if (!!searchQuery) {
      params.searchType = searchType;
      params.searchQuery = searchQuery;
    }
    if (!!signupType) params.signupType = signupType;
    if (!!type) params.userType = type;
    if (!!maxBirthDate) params.maxBirthDate = maxBirthDate;
    if (!!maxCreatedAt) params.maxCreatedAt = maxCreatedAt;
    if (!!minBirthDate) params.minBirthDate = minBirthDate;
    if (!!minCreatedAt) params.minCreatedAt = minCreatedAt;
    try {
      const { count, data } = await request.get<any, IRequestResult<IUser>>(
        '/commerce/user',
        { params }
      );
      setState({
        list: data,
        total: count,
        isLoading: false,
      });
    } catch (err) {
      console.dir(err);
      setState({ isLoading: false });
    }
  };
  const onDealAtOnce = async () => {
    if (!selectedIdx.length) {
      toast.info('회원을 선택해주세요.');
      return;
    }
    if (!window.confirm('정말 처리하시겠습니까?')) return;

    setState({ isProcessing: true });
    try {
      if (dealMethod === 'DeleteUser') {
        await request.delete('/commerce/user', {
          params: { userIdList: selectedIdx },
        });
      } else if (dealMethod === 'ChangeUserLevel') {
        await request.put('/commerce/user/level', { userIdList: selectedIdx, level });
      }
      toast.success('적용되었습니다.');
      get();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isProcessing: false });
    }
  };

  const onDownloadExcel = async () => {
    if (
      !window.confirm(
        '전체 회원에 대해 엑셀 추출하시겠습니까? 마케팅 수신 동의 및 SMS 수신 동의자에 한해서만 추출됩니다.'
      )
    )
      return;

    setState({ isDownloading: true });
    try {
      const { data } = await request.get<any, IRequestResult<IUser>>('/commerce/user', {
        params: { smsAllowed: true, marketingPushAllowed: true, limit: null },
      });
      const csvExporter = new ExportToCsv({
        showLabels: true,
        filename: `회원_목록_(${dayjs().format('YYYY-MM-DD HH:mm:ss')})`,
        headers: ['이름', '이메일', '핸드폰번호', '성별', '나이', '가입일'],
      });
      const getGenderText = (gender: string) => {
        if (gender === 'M') return '남성';
        if (gender === 'W') return '여성';
        return '';
      };
      csvExporter.generateCsv(
        data.map((item) => ({
          name: item.name,
          email: item.email,
          phoneNumber: item.phoneNumber,
          gender: getGenderText(item.gender),
          age: getAge(item.birthDate),
          createdAt: dayjs(item.createdAt).format('YYYY년 MM월 DD일 HH시 mm분'),
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isDownloading: false });
    }
  };

  useEffect(() => {
    get();
  }, [
    page,
    gender,
    type,
    inflowDestination,
    signupType,
    minBirthDate,
    minCreatedAt,
    maxBirthDate,
    maxCreatedAt,
    emailAllowed,
    smsAllowed,
    marketingPushAllowed,
    pushAllowed,
    nightPushAllowed,
    inflowOriginSource,
    inflowStandard,
    inflowOriginCampaign,
    sort,
  ]);
  return (
    <>
      <div className="wrapper">
        <div className="wrapper-title">회원목록</div>
        <Card
          items={[
            {
              name: '검색 기준',
              renderItem: () => (
                <div className="flex gap-2">
                  <Select
                    size="xs"
                    name="searchType"
                    onChange={onChange}
                    value={searchType}
                  >
                    <option value="NAME">이름</option>
                    <option value="ID">아이디</option>
                    <option value="AUID">AUID</option>
                    <option value="PHONE">전화번호</option>
                    <option value="EMAIL">이메일</option>
                    <option value="ENGLISH_NAME">영어 이름</option>
                    <option value="CUSTOMS_CLEARANCE_NUMBER">개인통관고유부호</option>
                  </Select>
                  <ReInput
                    size="xs"
                    value={searchQuery}
                    name="searchQuery"
                    onChange={onChange}
                    className="w-40"
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '성별',
              renderItem: () => (
                <Radio
                  value={gender}
                  onChange={(gender) => setState({ gender })}
                  options={enumToList(GENDER)}
                  isInitialExisted
                  id="gender"
                />
              ),
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Radio
                  value={type}
                  isInitialExisted
                  onChange={(type) => setState({ type })}
                  options={enumToList(USER_TYPE)}
                  id="userType"
                />
              ),
            },
            {
              name: '가입경로',
              renderItem: () => (
                <Radio
                  value={inflowDestination}
                  onChange={(inflowDestination) => setState({ inflowDestination })}
                  options={enumToList(PATH)}
                  isInitialExisted
                  id="inflowDestination"
                />
              ),
            },
            {
              name: '가입유형',
              renderItem: () => (
                <Radio
                  value={signupType}
                  onChange={(signupType) => setState({ signupType })}
                  options={[
                    { name: '전체', value: '' },
                    { name: '페칭', value: 'FETCHING' },
                    { name: '카카오', value: 'KAKAO' },
                  ]}
                  id="signupType"
                />
              ),
            },
            {
              name: '생년월일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={minBirthDate}
                    onChange={onChange}
                    name="minBirthDate"
                  />
                  <span>~</span>
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={maxBirthDate}
                    onChange={onChange}
                    name="maxBirthDate"
                  />
                </div>
              ),
            },
            {
              name: '가입일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={minCreatedAt}
                    onChange={onChange}
                    name="minCreatedAt"
                  />
                  <span>~</span>
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={maxCreatedAt}
                    onChange={onChange}
                    name="maxCreatedAt"
                  />
                </div>
              ),
            },
            {
              name: '유입경로',
              renderItem: () => (
                <div className="flex items-center gap-4">
                  <Radio
                    id="inflowStandard"
                    value={inflowStandard}
                    onChange={(inflowStandard) => setState({ inflowStandard })}
                    options={[
                      { name: '전체', value: '' },
                      { name: '최초', value: 'FIRST' },
                      { name: '가입 직전', value: 'LATEST' },
                    ]}
                  />
                  <Select
                    size="xs"
                    value={inflowOriginSource}
                    name="inflowOriginSource"
                    onChange={onChange}
                  >
                    <option value="">소스</option>
                    {enumToList(INFLOW_ORIGIN_SOURCE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Select
                    value={inflowOriginCampaign}
                    name="inflowOriginCampaign"
                    onChange={onChange}
                    size="xs"
                  >
                    <option value="">캠페인</option>
                    {enumToList(INFLOW_ORIGIN_CAMPAIGN).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </div>
              ),
              stretch: true,
            },
            {
              name: '수신동의',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Checkbox
                    checked={emailAllowed}
                    label="이메일"
                    onChange={(emailAllowed) => setState({ emailAllowed })}
                  />
                  <Checkbox
                    checked={smsAllowed}
                    label="SMS"
                    onChange={(smsAllowed) => setState({ smsAllowed })}
                  />
                  <Checkbox
                    checked={marketingPushAllowed}
                    label="마케팅"
                    onChange={(marketingPushAllowed) =>
                      setState({ marketingPushAllowed })
                    }
                  />
                  <Checkbox
                    checked={pushAllowed}
                    label="푸시"
                    onChange={(pushAllowed) => setState({ pushAllowed })}
                  />
                  <Checkbox
                    checked={nightPushAllowed}
                    label="야간푸시"
                    onChange={(nightPushAllowed) => setState({ nightPushAllowed })}
                  />
                </div>
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mb-7 flex justify-center gap-3">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          columns={[
            '회원번호',
            '이름(영문)',
            '아이디',
            '등급',
            '휴대전화',
            '이메일',
            '성별 (나이)',
            '가입일',
            '수신동의',
            '관리자메모',
          ]}
          isLoading={isLoading}
          header={
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <Select size="sm">
                  <option value="checkedUserIds">선택한 회원의</option>
                </Select>
                <Select
                  size="sm"
                  value={dealMethod}
                  name="dealMethod"
                  onChange={onChange}
                >
                  <option value="ChangeUserLevel">회원등급 변경을</option>
                  <option value="DeleteUser">회원탈퇴를</option>
                </Select>
                {dealMethod === 'ChangeUserLevel' && (
                  <Select size="sm" value={level} name="level" onChange={onChange}>
                    <option value="USER">일반으로</option>
                    <option value="VIP">VIP로</option>
                  </Select>
                )}
                <Button size="sm" isLoading={isProcessing} onClick={onDealAtOnce}>
                  일괄처리
                </Button>
                <Button
                  size="sm"
                  isLoading={isDownloading}
                  theme="primary"
                  onClick={onDownloadExcel}
                >
                  엑셀 다운
                </Button>
              </div>
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChange}>
                  <option value="NAME">이름순</option>
                  <option value="ACTION_COUNT">액션합계순</option>
                  <option value="CREATED_AT">가입일순</option>
                </Select>
                <Select size="sm" value={pageSize} name="pageSize" onChange={onChange}>
                  <option value={100}>100명씩 보기</option>
                  <option value={200}>200명씩 보기</option>
                  <option value={300}>300명씩 보기</option>
                </Select>
              </div>
            </div>
          }
          list={list}
          renderItem={(item, key) => (
            <div
              key={key}
              onClick={() =>
                setState({
                  selectedIdx:
                    selectedIdx.indexOf(item.idx) !== -1
                      ? selectedIdx.filter((index) => index !== item.idx)
                      : [...selectedIdx, item.idx],
                })
              }
            >
              <div>
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={selectedIdx.indexOf(item.idx) !== -1}
                />
              </div>
              <div>
                <OpenLink url={`/user/${item.idx}`}>{item.idx}</OpenLink>
              </div>
              <div>
                <div>
                  <OpenLink url={`/user/${item.idx}`}>{item.name}</OpenLink>
                </div>
                {!!item.englishFirstName && (
                  <div>({item.englishFirstName + item.englishLastName})</div>
                )}
              </div>
              <div>
                <OpenLink url={`/user/${item.idx}`}>{item.id}</OpenLink>
                <div className="flex gap-1 text-xs">
                  <span
                    className={!!item.auId.KAKAO ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    KAKAO
                  </span>
                  <span
                    className={!!item.auId.NAVER ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    NAVER
                  </span>
                  <span
                    className={!!item.auId.APPLE ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    APPLE
                  </span>
                </div>
              </div>
              <div>
                {item.type === 'VIP' ? (
                  <span className="text-yellow-500">VIP</span>
                ) : (
                  USER_TYPE[item.type]
                )}
              </div>
              <div>
                <OpenLink url={`/user/${item.idx}`}>{item.phoneNumber}</OpenLink>
              </div>
              <div>
                <OpenLink url={`/user/${item.idx}`}>{item.email}</OpenLink>
              </div>
              <div>
                {item.gender === 'W' && '여성'}
                {item.gender === 'M' && '남성'}
                {!!item.birthDate && ` (${getAge(item.birthDate)})`}
              </div>
              <div>{dayjs(item.createdAt).format('YYYY.MM.DD HH:mm')}</div>
              <div>
                <div className="flex gap-2 justify-center">
                  <span style={{ color: item.emailAllowed ? '#35256D' : '#999999' }}>
                    이메일
                  </span>
                  <span style={{ color: item.smsAllowed ? '#35256D' : '#999999' }}>
                    SMS
                  </span>
                  <span style={{ color: item.pushAllowed ? '#35256D' : '#999999' }}>
                    푸시
                  </span>
                  <span
                    style={{ color: item.marketingPushAllowed ? '#35256D' : '#999999' }}
                  >
                    마케팅푸시
                  </span>
                  <span style={{ color: item.nightPushAllowed ? '#35256D' : '#999999' }}>
                    야간푸시
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <Button
                    size="xs"
                    onClick={() =>
                      window.open(`/user/${item.idx}?tab=관리자 메모`, '_blank')
                    }
                  >
                    보기
                  </Button>
                </div>
              </div>
            </div>
          )}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setState({ selectedIdx: checked ? list.map((item) => item.idx) : [] })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>명</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={total}
            pageSize={pageSize}
          />
        </div>
      </div>
    </>
  );
};
export default UsersPage;
