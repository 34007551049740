import React from 'react';
import * as style from './style';

const CountyApplied = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const { gender } = searchOptionTableState;

  return (
    <style.Container>
      <style.Div
        onChange={(e) => {
          if (e.target.value === 'null') {
            setSearchOptionTableState({
              ...searchOptionTableState,
              gender: undefined,
            });
          } else {
            setSearchOptionTableState({
              ...searchOptionTableState,
              gender: e.target.value,
            });
          }
        }}
        widthPcnt={100}
        noBorder
      >
        <style.Label>
          <style.Radio
            name="gender"
            value="null"
            checked={!gender}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            name="gender"
            value="M"
            checked={gender === 'M'}
            onChange={() => {}}
          />
          남성
        </style.Label>
        <style.Label>
          <style.Radio
            name="gender"
            value="W"
            checked={gender === 'W'}
            onChange={() => {}}
          />
          여성
        </style.Label>
      </style.Div>
    </style.Container>
  );
};

export default CountyApplied;
