import styled from 'styled-components';

export const flexibleTd = styled.div`
  width: ${({ widthPcnt }) => widthPcnt}%;
  ${({ noBorder }) => (noBorder ? '' : 'border-right:0.5px solid #B4B4B4;')}

  overflow:auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  text-align: left;
  font-size: 12px;
  // height:110px;
`;

export const Tr = styled.div`
  border-bottom: 0.5px solid #b4b4b4;
  display: flex;
  justify-content: space-between;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })``;

export const Img = styled.img`
  height: 110px;
  width: 100%;
  object-fit: contain;
  &:hover {
    transform: scale(4);
    background: white;
    border: solid black 2px;
  }
`;
