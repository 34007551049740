import React, { useEffect, useState } from 'react';
import * as style from './style';
import { reset } from '../../../../assets/images';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { fetchingAPI } from 'api/axios';

const OptionCheckboxListShops = ({
  shops,
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const dict = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'N',
    'M',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const shopIdList = searchOptionTableState.shopIdList;
  const setShopIdList = (shopId) => {
    const loc = shopIdList.indexOf(shopId);
    let newshopList = [];
    if (loc === -1) {
      newshopList = [...shopIdList, shopId];
    } else {
      newshopList = [...shopIdList.slice(0, loc), ...shopIdList.slice(loc + 1)];
    }
    setSearchOptionTableState({
      ...searchOptionTableState,
      shopIdList: newshopList,
    });
  };

  const [shopList, setShopList] = useState({});

  const getShopPriceRule = async (shopId) => {
    try {
      const { data } = await fetchingAPI.get(
        `https://admin.fetchingapp.co.kr/shop/${shopId}/price-rule`
      );

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const result = {};

    for (let i = 0; i < dict.length; i++) {
      const list = shops
        .filter((obj) => obj.name[0] === dict[i])
        .map((obj) => {
          return obj;
        });

      result[dict[i]] = list;
    }

    setShopList(result);
  }, [shops]);

  return (
    <style.Container>
      <style.Title>
        <style.TitleSpan>편집샵</style.TitleSpan>
        <style.ResetButton
          src={reset}
          onClick={() => {
            setSearchOptionTableState({
              ...searchOptionTableState,
              shopIdList: [],
            });
          }}
        />
      </style.Title>
      <style.Content>
        <style.CheckboxContainer>
          <style.Checkbox
            onClick={() => {
              if (shopIdList.length === shops.length) {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  shopIdList: [],
                });
              } else {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  shopIdList: shops.map((obj) => obj.shopId),
                });
              }
            }}
            checked={shopIdList.length === shops.length}
          />
          <style.Label>전체선택</style.Label>
        </style.CheckboxContainer>
        <style.Initial>123</style.Initial>
        {shops
          .filter((obj) => !dict.includes(obj.name[0]))
          .map((obj, i) => (
            <style.CheckboxContainer key={`shopsContainer${obj.name}-${i}`}>
              <style.Checkbox
                key={`shopsContainerCheckbox${obj.name}`}
                onClick={() => setShopIdList(obj.shopId)}
                checked={shopIdList.includes(obj.shopId)}
              />
              <style.Label key={`shopsContainerLabel${obj.name}`}>{obj.name}</style.Label>
            </style.CheckboxContainer>
          ))}

        {Object.keys(shopList)?.map((el, i) => {
          return (
            <div key={el}>
              <style.Initial>{el}</style.Initial>
              {shopList?.[el]?.map((obj, j) => {
                return (
                  <div key={`shopsContainer${obj.name}-${i}`}>
                    <style.CheckboxContainer>
                      <style.Checkbox
                        key={`shopsContainerCheckbox${obj.name}`}
                        onClick={() => setShopIdList(obj.shopId)}
                        checked={shopIdList.includes(obj.shopId)}
                      />

                      <button
                        className="flex items-center"
                        onClick={async () => {
                          const result = [
                            ...shopList[el].slice(0, j),
                            {
                              ...shopList[el][j],
                              isDropdown: !shopList[el][j].isDropdown,
                              data:
                                shopList[el][j].data ||
                                (await getShopPriceRule(obj.shopId)),
                            },
                            ...shopList[el].slice(j + 1),
                          ];

                          const resultList = {
                            ...shopList,
                          };
                          resultList[el] = result;

                          setShopList(resultList);
                        }}
                      >
                        <style.Label key={`shopsContainerLabel${obj.name}`}>
                          {obj.name}
                        </style.Label>
                        {obj.isDropdown ? (
                          <IoIosArrowUp className=" mx-1 text-base" />
                        ) : (
                          <IoIosArrowDown className="mx-1 text-base" />
                        )}
                      </button>
                    </style.CheckboxContainer>
                    {obj.isDropdown ? (
                      <div>
                        {obj?.data?.map((el, i) => {
                          return (
                            <div key={i} className={'flex items-center pl-3 py-1'}>
                              <style.Checkbox
                                onClick={() => {
                                  if (
                                    searchOptionTableState?.priceRuleIdList?.includes(
                                      el.id
                                    )
                                  ) {
                                    const result =
                                      searchOptionTableState?.priceRuleIdList?.filter(
                                        (val) => {
                                          return val !== el.id;
                                        }
                                      );

                                    setSearchOptionTableState({
                                      ...searchOptionTableState,
                                      priceRuleIdList: result,
                                    });
                                  } else {
                                    setSearchOptionTableState({
                                      ...searchOptionTableState,
                                      priceRuleIdList: [
                                        ...searchOptionTableState.priceRuleIdList,
                                        el.id,
                                      ],
                                    });
                                  }
                                }}
                                checked={searchOptionTableState?.priceRuleIdList?.includes(
                                  el.id
                                )}
                              />
                              <p>
                                {obj.name} ({el.country})
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          );
        })}
      </style.Content>
    </style.Container>
  );
};

export default OptionCheckboxListShops;
