import styled from 'styled-components';

export const Anchor = styled.div`
  padding: 24px;
  color: black;

  :hover {
    border-bottom: 2px solid #3b82f6;
    color: #3b82f6;
    padding: 24px;
  }
`;

export const NavAnchor = styled.div`
  padding: 24px;
  border-bottom: 2px solid #3b82f6;
  color: #3b82f6;
  padding: 24px;
`;

export const Table = styled.table`
  width: 100%;
  tbody {
    tr {
      border-bottom: 1px solid #e5e7eb;
    }
    th {
      font-weight: 400;
      font-size: 14px;
      padding: 20px;
      color: #4b5563;
      width: 180px;
    }
    td {
      padding: 20px;
    }
  }
`;
