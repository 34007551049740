import { Loading, Outer, RePagination } from 'components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { fetcher } from 'pages/DashboardService';
import useSWR from 'swr';
import { getKey } from 'services';
import { Select, Input, Checkbox, Button as AntdButton, DatePicker, Radio } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import koKR from 'antd/lib/locale/ko_KR';
import { Anchor, NavAnchor, Table } from './style';
import { FromToType, SearchType, Shop, StatusList } from './types';
import moment from 'moment';
import {
  fromToOptions,
  fromToTypeOptions,
  handleStatus,
  searchTypeOptions,
  statusListOptions,
} from './helper';
import { useCheckedIds } from 'pages/ReviewList/hooks';

type Props = {};

const limit = 50;

const getErrorDescription = (error: any) => {
  switch (error?.response?.status) {
    case 404:
      return `서버에 정보가 없습니다. (${error?.response?.status})`;
    case 401:
    case 403:
      return `권한이 부족합니다. (${error?.response?.status})`;
    case 500:
      return `서버 내부 에러 (${error?.response?.status})`;
    default:
      return `잠시 후 다시 시도해주세요. (${error?.response?.status})`;
  }
};

const PartnerItem: React.FC<Props> = ({}) => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [fromToType, setFromToType] = useState<FromToType>(FromToType.REQUEST_DATE);
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<SearchType>(SearchType.SHOP_NAME);
  const [statusList, setStatusList] = useState<StatusList[]>([]);

  const [payload, setPayload] = useState<{
    minDate?: any;
    maxDate?: any;
    dateType?: any;
    searchText?: any;
    searchType?: any;
    statusList?: any;
  }>({});

  const reset = () => {
    setFrom(null);
    setTo(null);
    setSearchText('');
    setSearchType(SearchType.SHOP_NAME);
    setStatusList([]);
  };

  const getPayload = () => {
    setPayload({
      minDate: from && dayjs(from).toISOString(),
      maxDate: to && dayjs(to).toISOString(),
      dateType: (from || to) && fromToType,
      searchText,
      searchType: searchText ? searchType : null,
      statusList,
    });
    setPage(1);
  };

  const { data, error } = useSWR<{ count: number; data: Shop[] }>(
    getKey(`/partner/item/map/shop`, {
      limit,
      page,
      ...payload,
    }),
    fetcher,
    { shouldRetryOnError: false }
  );

  const { isChecked, isAll, onToggle, onAll, checked } = useCheckedIds<number>();
  const allShopIds = useMemo(() => data?.data.map((shop) => shop.partnerShopId), [data]);

  useEffect(() => {
    error && toast.error(getErrorDescription(error));
  }, [error]);

  return (
    <>
      {!data && !error && <Loading />}
      <Outer>
        <div style={{ padding: '32px 20px' }}>
          <div>
            <div
              style={{
                background: '#374151',
                padding: '24px 32px',
                fontWeight: 500,
              }}
            >
              <div>
                <h2
                  style={{
                    fontSize: '20px',
                    color: 'white',
                  }}
                >
                  상품 승인 관리
                </h2>
                <p
                  style={{
                    margin: '4px 0 0',
                    fontSize: '14px',
                    color: 'white',
                    opacity: '0.5',
                  }}
                >
                  페칭에 등록된 상품을 조회하고 상품 정보를 수정할 수 있습니다.
                </p>
              </div>
            </div>
            <div style={{ background: 'white', padding: '0 12px', display: 'flex' }}>
              <NavAnchor>파트너별 승인</NavAnchor>
              <Link to={'/partner/confirm/item'}>
                <Anchor>상품별 승인</Anchor>
              </Link>
            </div>
          </div>

          <div className="mt-5">
            <div className="bg-white p-6 font-bold" style={{ padding: '20px' }}>
              파트너 검색
            </div>
            <div style={{ background: '#F9FAFB' }}>
              <Table>
                <tbody>
                  <tr>
                    <th>검색어</th>
                    <td>
                      <div className="flex gap-4">
                        <Select
                          style={{ minWidth: 220 }}
                          onChange={setSearchType}
                          value={searchType}
                        >
                          {searchTypeOptions.map((option) => (
                            <Select.Option value={option.value} key={option.value}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <Input
                          placeholder="복수검색 시 쉼표( , ) 로 구분"
                          onChange={({ target: { value } }) => setSearchText(value)}
                          value={searchText}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>상품 승인 상태</th>
                    <td>
                      <Checkbox
                        checked={statusList.length === 3}
                        onChange={() => {
                          if (statusList.length === 3) {
                            setStatusList([]);
                          } else {
                            setStatusList([
                              StatusList.ACCEPT,
                              StatusList.REJECT,
                              StatusList.REQUEST,
                            ]);
                          }
                        }}
                      >
                        전체
                      </Checkbox>
                      {statusListOptions.map((option) => (
                        <Checkbox
                          key={option.name}
                          checked={statusList.includes(option.value)}
                          onChange={({ target: { checked } }) => {
                            handleStatus(setStatusList, option.value, checked);
                          }}
                        >
                          {option.name}
                        </Checkbox>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>기간</th>
                    <td>
                      <div className="flex gap-4">
                        <Select
                          style={{ minWidth: 220 }}
                          value={fromToType}
                          onChange={setFromToType}
                        >
                          {fromToTypeOptions.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <Radio.Group
                          onChange={({ target: { value } }) => {
                            const [from, to] = value.split(',');
                            setFrom(from);
                            setTo(to);
                          }}
                          value={[from, to].join(',')}
                        >
                          {fromToOptions.map((option) => (
                            <Radio.Button value={option.value} key={option.value}>
                              {option.name}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <DatePicker.RangePicker
                          allowEmpty={[true, true]}
                          value={[from ? moment(from) : null, to ? moment(to) : null]}
                          locale={koKR.DatePicker}
                          onChange={(_, [from, to]) => {
                            setFrom(from);
                            setTo(to);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div
                className="mx-auto flex gap-4"
                style={{ width: 'min-content', padding: 36 }}
              >
                <AntdButton size="large" onClick={reset}>
                  초기화
                </AntdButton>
                <AntdButton
                  size="large"
                  type="primary"
                  onClick={() => {
                    getPayload();
                    onAll([]);
                  }}
                >
                  검색
                </AntdButton>
              </div>
            </div>
          </div>

          <div>
            <div style={{ marginTop: 35 }}>
              <table className="w-full border-table">
                <thead>
                  <tr>
                    <th onClick={onAll(allShopIds ?? [])}>
                      <Checkbox checked={isAll(allShopIds ?? [])} />
                    </th>
                    <th>파트너 쇼핑몰명</th>
                    <th>계약방식</th>
                    <th>파트너 점수</th>
                    <th>등록 상품 수</th>
                    <th>승인 상품 수</th>
                    <th>승인 대기 상품 수</th>
                    <th>반려 상품 수</th>
                    <th>요청일</th>
                    <th>처리일</th>
                    <th>처리자</th>
                    <th>관리</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data?.data.map((shop) => (
                    <tr>
                      <td onClick={onToggle(shop.partnerShopId)}>
                        <Checkbox checked={isChecked(shop.partnerShopId)} />
                      </td>
                      <td>{shop.shopName}</td>
                      <td>
                        <span>
                          {shop.isDirectPaySupport &&
                            `이동 후 결제 ${shop.directPaySettleMethod}`}
                        </span>
                        <br />
                        <span>
                          {shop.isFetchingPaySupport &&
                            `페칭 결제 ${shop.fetchingPaySettleMethod}`}
                        </span>
                      </td>
                      <td>{shop.partnerScore}</td>
                      <td>{shop.itemCount}</td>
                      <td>
                        <Link
                          to={`/partner/confirm/item?statusList=${StatusList.ACCEPT}&partnerShopId=${shop.partnerShopId}`}
                          style={{ textDecoration: 'underline', color: 'black' }}
                          onClick={() => {
                            toast.info(
                              `${shop.shopName} 상품별 승인 페이지로 이동합니다. (상품 승인 상태 : 승인)`
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          {shop.acceptItemCount}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/partner/confirm/item?statusList=${StatusList.REQUEST}&partnerShopId=${shop.partnerShopId}`}
                          style={{ textDecoration: 'underline', color: '#EF4444' }}
                          onClick={() => {
                            toast.info(
                              `${shop.shopName} 상품별 승인 페이지로 이동합니다. (상품 승인 상태 : 승인대기)`
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          {shop.requestItemCount}
                        </Link>
                      </td>
                      <td>
                        <Link
                          to={`/partner/confirm/item?statusList=${StatusList.REJECT}&partnerShopId=${shop.partnerShopId}`}
                          style={{ textDecoration: 'underline', color: '#EF4444' }}
                          onClick={() => {
                            toast.info(
                              `${shop.shopName} 상품별 승인 페이지로 이동합니다. (상품 승인 상태 : 반려)`
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          {shop.rejectItemCount}
                        </Link>
                      </td>
                      <td>{dayjs(shop.lastRequestDate).format('YYYY.M.D HH:mm:ss')}</td>
                      <td>{dayjs(shop.lastUpdateDate).format('YYYY.M.D HH:mm:ss')}</td>
                      <td>{shop.updateUserName}</td>
                      <td>
                        <AntdButton
                          onClick={() => {
                            toast.info(
                              `${shop.shopName} 상품별 승인 페이지로 이동합니다.`
                            );
                            history.push(
                              `/partner/confirm/item?partnerShopId=${shop.partnerShopId}`
                            );
                            window.scrollTo(0, 0);
                          }}
                        >
                          상품관리
                        </AntdButton>
                        <div style={{ height: '10px' }}></div>
                        <AntdButton onClick={() => toast.info('기능 준비중입니다.')}>
                          메시지
                        </AntdButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between mt-10">
              <p>총 {data?.count ?? 0}개</p>
              <RePagination
                currentPage={page}
                totalCount={data?.count ?? 0}
                pageSize={limit}
                onPageChange={setPage}
              />
            </div>
          </div>
        </div>
      </Outer>
    </>
  );
};

export default PartnerItem;

const colorSet = {
  red: '#FF5747',
  blue: '#0D9AE9',
  green: '#498935',
};

const Card: FC<{ color: 'red' | 'blue' | 'green' }> = ({ children, color }) => (
  <div
    style={{
      border: `1px solid ${colorSet[color]}`,
      color: colorSet[color],
      width: 'fit-content',
      margin: '8px auto',
      padding: '4px 8px',
    }}
  >
    {children}
  </div>
);
