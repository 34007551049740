import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 25px;
`;

export const Title = styled.h5``;

export const BoxWrapper = styled.div`
  width: 100%;
  @media all and (min-width: 1000px) {
    display: flex;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid #ddd;
`;

export const ItemTitle = styled.div`
  width: 30%;
  background-color: #e8e8e8;
  text-align: center;
  padding: 10px 15px;
  border-right: 1px solid #ddd;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ItemValue = styled.div`
  width: 70%;
  padding: 10px 15px;
  overflow-x: auto;
  ${(props) => props.color && `color: ${props.color};`}
`;

export const Input = styled.input.attrs({
  type: 'text',
})`
  width: 100%;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  padding: 10px 15px;
  &:focus {
    outline: none;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: wrap;
  align-items: center;
  width: 300px;
  padding: 5px;
`;

export const P = styled.p`
  margin: 0;
  margin-right: 10px;
  white-space: nowrap;
`;
