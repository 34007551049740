import React, { useState } from 'react';
import { DropZoneImage, Table } from 'components';
import { MakeTable } from 'pages/AddPromotion/utils';
import { Button, Spinner } from 'react-bootstrap';
import * as S from '../style';

type Props = {
  state: [BandBannerProps, React.Dispatch<React.SetStateAction<BandBannerProps>>];
};
export type BandBannerProps = {
  name: string;
  type: BandBannerTypes;
  duration: boolean;
  start: null | string;
  end: null | string;
  backgroundColor: null | string;
  mobileImageUrl: null | string;
  pcImageUrl: null | string;
  title: null | string;
  href: null | string;
};

export enum BandBannerTypes {
  text = 'TEXT_BAND_BANNER',
  textRolling = 'TEXT_ROLLING_BAND_BANNER',
  image = 'IMAGE_BAND_BANNER',
}

const ManageBandBanner: React.FC<Props> = ({ state }) => {
  const [data, setData] = state;
  const onChange = (e: any) => {
    const { name, value } = e.target;
    if (name === 'duration') {
      setData((p) => ({ ...p, duration: !!Number(value) }));
    } else {
      setData((p) => ({ ...p, [name]: value }));
    }
  };
  const tables = [
    {
      title: '상세 설정',
      content: [
        {
          left: '배너 이름',
          right: (
            <Table.TextArea
              value={data.name}
              onChange={onChange}
              name="name"
              className="w-full"
              placeholder="어드민 관리용 이름"
            />
          ),
        },
        {
          left: '배너 타입',
          right: (
            <>
              <Table.Label>
                <Table.Radio
                  checked={data.type === BandBannerTypes.text}
                  value={BandBannerTypes.text}
                  name="type"
                  onChange={onChange}
                />
                텍스트형
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={data.type === BandBannerTypes.textRolling}
                  value={BandBannerTypes.textRolling}
                  name="type"
                  onChange={onChange}
                />
                텍스트롤링형
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={data.type === BandBannerTypes.image}
                  value={BandBannerTypes.image}
                  name="type"
                  onChange={onChange}
                />
                이미지형
              </Table.Label>
            </>
          ),
        },
        {
          left: '게시 기간 설정',
          right: (
            <>
              <Table.Label>
                <Table.Radio
                  checked={!data.duration}
                  onChange={onChange}
                  value="0"
                  name="duration"
                />
                영구
              </Table.Label>
              <Table.Label>
                <Table.Radio
                  checked={data.duration}
                  onChange={onChange}
                  value="1"
                  name="duration"
                />
                설정
              </Table.Label>
            </>
          ),
        },
        ...(data.duration
          ? [
              {
                left: '게시 일시',
                right: (
                  <>
                    <Table.InputTime
                      value={data.start ?? ''}
                      name="start"
                      onChange={onChange}
                    />
                    {' ~ '}
                    <Table.InputTime
                      style={{ marginLeft: '10px' }}
                      value={data.end ?? ''}
                      name="end"
                      onChange={onChange}
                    />
                  </>
                ),
              },
            ]
          : []),
        ...(data.type === BandBannerTypes.image
          ? [
              {
                left: '이미지',
                right: (
                  <>
                    <div className="w-full">
                      <h2 className="my-2">모바일</h2>
                      <div>
                        <DropZoneImage imageUrl={''} setImageUrl={() => {}} />
                      </div>
                      <h2 className="my-2">pc</h2>
                      <div>
                        <DropZoneImage imageUrl={''} setImageUrl={() => {}} />
                      </div>
                    </div>
                  </>
                ),
              },
            ]
          : [
              {
                left: '배경 컬러',
                right: (
                  <>
                    <Table.TextArea
                      placeholder="컬러값은 HEX코드로 입력해주세요. 예) #000000"
                      value={data.backgroundColor ?? ''}
                      onChange={onChange}
                      name="backgroundColor"
                    />
                  </>
                ),
              },
              {
                left: '타이틀',
                right: (
                  <>
                    <Table.TextArea
                      placeholder="최대 한글 30글자 으로 줄넘김 가능"
                      value={data.title ?? ''}
                      onChange={onChange}
                      name="title"
                    />
                  </>
                ),
              },
            ]),
        {
          left: '링크',
          right: (
            <>
              <Table.TextArea
                placeholder="/exhibition/13 형식으로 fetching.co.kr를 제외해주세요"
                value={data.href ?? ''}
                onChange={onChange}
                name="href"
              />
            </>
          ),
        },
      ],
    },
  ];
  console.log(data);

  return <MakeTable data={tables[0]} tableRef={null} />;
};

export default ManageBandBanner;
