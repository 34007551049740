import { axios } from 'api/axios';
import { toast } from 'react-toastify';
import { AUTH_LINKS } from 'services';

const getShopListFromAPI = async () => {
  try {
    const { data } = await axios.get('/api/shops');
    data.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
    setSessionStorage('shopList', data);
    return data;
  } catch (err) {
    alert(`
    📌 에러가 발생했습니다.!
    아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

    API 에러 발생
    GET - /api/shops
    ${err}`);
    console.error(err);
  }
};

const getUserListFromAPI = async () => {
  try {
    const { data } = await axios.get('/api/auth/userlist');
    data.data.sort((a, b) => {
      return a.admin_name < b.admin_name ? -1 : a.admin_name > b.admin_name ? 1 : 0;
    });
    setSessionStorage('userList', data.data);
    return data.data;
  } catch (err) {
    console.error(err);
    if (
      err.response.status === 401 &&
      AUTH_LINKS.indexOf(window.location.pathname) === -1
    ) {
      window.location.href = `/login?redirectUrl=${window.location.pathname}`;
    }
  }
};

const setSessionStorage = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

const getSessionStorage = (key) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(sessionStorage.getItem(key)) : null;
};

export const getUserList = async () => {
  return getSessionStorage('userList') || getUserListFromAPI();
};

export const getShopList = async () => {
  return getSessionStorage('shopList') || getShopListFromAPI();
};

export const sort = {
  asc: (x, y) => (x < y ? -1 : x > y ? 1 : 0),
  desc: (x, y) => (x < y ? 1 : x > y ? -1 : 0),
};

export const isNumber = (s) => {
  s += ''; // 문자열로 변환
  s = s.replace(/^\s*|\s*$/g, ''); // 좌우 공백 제거
  if (s == '' || isNaN(s)) {
    return false;
  }
  return true;
};
