import {
  Button,
  Card,
  Check,
  GridTable,
  MenuTitle,
  NumberStat,
  OpenLink,
  OrderCount,
  OrderListTooltip,
  Radio,
  ReInput,
  RePagination,
  Select,
  Tabs,
  Textarea,
} from 'components';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  enumToList,
  ORDER_DATE_LIST,
  ORDER_RETURN_STATUS,
  ORDER_REFUND_REASON,
  ORDER_SEARCH_TYPE,
  PATH,
  PAY_METHOD,
  request,
  useOrderTable,
  USER_TYPE,
  ORDER_SORT,
} from 'services';
import { orderCountState } from 'store';
import classnames from 'classnames';

interface State {}

const OrdersReturnPage = () => {
  const { push } = useHistory();
  const {
    searchText,
    searchType,
    setOrderState,
    onDateClick,
    onChangeOrder,
    minOrderDate,
    maxOrderDate,
    page,
    total,
    limit,
    list,
    orderPath,
    userType,
    isLoading,
    sort,
    payMethod,
    orderStatus,
    resetOrderState,
    memoList,
    isMemoLoading,
    getMemo,
  } = useOrderTable();
  const orderCount = useRecoilValue(orderCountState);

  const get = async () => {
    setOrderState({ isLoading: true });
    let params: any = {
      isAscending: sort === 'ORDER_DATE_ASC',
      limit,
      page,
      orderBy: 'ORDER_DATE',
    };
    if (!!searchType) params.searchType = searchType;
    if (!!searchText) params.searchText = searchText;
    if (!!orderPath) params.orderPath = orderPath;
    if (!!payMethod) params.payMethod = payMethod;
    if (!!userType) params.userType = userType;
    if (!!orderStatus) params.orderStatus = orderStatus;
    if (!!minOrderDate)
      params.minOrderDate = `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
    if (!!maxOrderDate)
      params.maxOrderDate = `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
    try {
      const { data, count } = await request.get<any, { count: number; data: any[] }>(
        '/commerce/order/order-return',
        {
          params,
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setOrderState({ total: count, list: data, isLoading: false });
    } catch (err) {
      setOrderState({ isLoading: false });
      console.dir(err);
    }
  };

  const onTabChange = (tab: number) => {
    if (tab === 1) push('/orders/exchange');
    else if (tab === 2) push('/orders/refund');
    else if (tab === 3) push('/orders/customs-refund');
  };

  useEffect(() => {
    get();
  }, [
    page,
    minOrderDate,
    maxOrderDate,
    limit,
    orderPath,
    userType,
    sort,
    payMethod,
    orderStatus,
  ]);
  return (
    <OrderCount>
      <MenuTitle>
        <Tabs
          onTabChange={onTabChange}
          value={0}
          options={[
            `반품 관리 (${orderCount.return})`,
            `교환 관리 (${orderCount.exchange})`,
            `환불 관리 (${orderCount.refund})`,
            '관세 환급 관리',
          ]}
        />
      </MenuTitle>
      <div className="wrapper">
        <div className="wrapper-title">반품 관리</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Textarea.Autosize
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                    rows={1}
                    className="w-64"
                    placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문경로',
              renderItem: () => (
                <Radio
                  options={enumToList(PATH)}
                  value={orderPath}
                  onChange={(orderPath) => setOrderState({ orderPath })}
                  id="orderPath"
                  isInitialExisted
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '결제수단',
              renderItem: () => (
                <Radio
                  value={payMethod}
                  isInitialExisted
                  onChange={(payMethod) => setOrderState({ payMethod })}
                  options={enumToList(PAY_METHOD)}
                  id="payMethod"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Radio
                  isInitialExisted
                  options={enumToList(USER_TYPE)}
                  value={userType}
                  onChange={(userType) => setOrderState({ userType })}
                  id="userType"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '반품 상태',
              renderItem: () => (
                <Radio
                  isInitialExisted
                  options={enumToList(ORDER_RETURN_STATUS)}
                  value={orderStatus}
                  onChange={(orderStatus) => setOrderState({ orderStatus })}
                  id="orderStatus"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-7 flex justify-center gap-3">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          isLoading={isLoading}
          noSelection
          columns={[
            '반품번호 / 상품별 주문번호',
            '신청일',
            '주문자',
            '판매처 (국가 / 배송방식)',
            '편집샵 계정',
            '상품명 / 옵션명',
            '반품사유',
            '배대지 여부',
            '세금환급필요여부',
            '합산과세여부',
            '반품수출신고여부',
            '반품수수료',
            '반품수거운송장 / 해외배송사운송장',
            '상태',
            '관리자기능',
          ]}
          maxHeight=""
          header={
            <div className="flex items-center justify-end">
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          list={list}
          renderItem={(order, key) => (
            <div key={key}>
              <div aria-label="반품번호 / 상품별 주문번호" className="underline">
                <div className="mb-2">
                  <OpenLink url={`/orders/return/${order.orderReturnNumber}`}>
                    {order.orderReturnNumber}
                  </OpenLink>
                </div>
                {order.shopList.map((shop, shopIndex) => (
                  <Fragment key={shopIndex}>
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        <OpenLink
                          url={`/order/${item.fetchingOrderNumber}?status=3&tab=1`}
                        >
                          {item.itemOrderNumber}
                        </OpenLink>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </div>
              <div aria-label="신청일">
                {dayjs(order.requestedAt).format('YYYY.MM.DD HH:mm:ss')}
              </div>
              <div aria-label="주문자">
                <span>{order.userName}</span>
                <div>{USER_TYPE[order.userType]}</div>
              </div>
              <div aria-label="판매처 (국가 / 배송방식)">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div key={shopIndex} className="flex-1">
                      {shop.shopName} ({shop.shopCountry} /{' '}
                      <span
                        className={classnames({
                          'text-fassto': shop.deliveryMethodName === '파스토',
                          'text-eldex': shop.deliveryMethodName === '엘덱스',
                        })}
                      >
                        {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                      </span>
                      )
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="편집샵 계정">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <div>{item.fetchingShopAccount}</div>
                          {!!item.vendorOrderNumber && (
                            <div>편집샵 주문번호 : {item.vendorOrderNumber}</div>
                          )}
                          {!!item.cardApprovalNumber && (
                            <div>카드 승인번호 : {item.cardApprovalNumber}</div>
                          )}
                          {!!item.firstInvoice && (
                            <div>운송장 번호 : {item.firstInvoice}</div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="상품명 / 옵션명">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                      key={shopIndex}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <OpenLink
                            url={`/product/productDetail/v2/${Number(
                              item.itemId
                            )}?mode=update`}
                          >
                            {item.itemName}
                          </OpenLink>
                          <div className="text-red-400">{item.sizeName}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="반품사유">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          {ORDER_REFUND_REASON[item.reasonType]}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="배대지 여부">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <Check checked={item.hasAdditionalDeliveryFee} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="세금환급필요여부">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <Check checked={item.needDutyRefund} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="합산과세여부">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <Check checked={item.isCombinedTax} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="반품수출신고여부">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <Check checked={item.needExportDeclaration} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="반품수수료">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <NumberStat value={item.returnFee} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="반품수거운송장 / 해외배송사운송장">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <div>
                            페칭 택배사 : {item.fetchingShippingCompanyName} (
                            {item.fetchingInvoice})
                          </div>
                          <div>
                            편집샵 택배사 : {item.shopShippingCompanyName} (
                            {item.shopInvoice})
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="상태">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1 text-black font-medium">
                          {ORDER_RETURN_STATUS[item.returnStatus]}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="관리자기능">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      key={shopIndex}
                      style={{ flex: shop.itemList.length }}
                      className="flex flex-col h-full space-y-4"
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div
                          key={itemIndex}
                          className="flex-1 flex flex-col gap-1 items-center"
                        >
                          <Button
                            size="xs"
                            theme="primary"
                            onClick={() =>
                              window.open(`/orders/return/${order.orderReturnNumber}`)
                            }
                          >
                            관리
                          </Button>
                          <OrderListTooltip
                            isLoading={isMemoLoading}
                            hasMemo={order.hasMemo}
                            list={memoList}
                            fetchingOrderNumber={order.fetchingOrderNumber}
                            onMouseLeave={() =>
                              setOrderState({ memoList: [], isMemoLoading: false })
                            }
                            onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                          />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
    </OrderCount>
  );
};

export default OrdersReturnPage;
