/* eslint-disable no-prototype-builtins */
import { InformationCircleIcon } from '@heroicons/react/outline';
import dayjs from 'dayjs';
import React from 'react';
import { Tooltip, OverlayTrigger, Popover } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { COMMERCE_URL } from 'services';

import * as style from './style';

const dateToString = (date) =>
  date.slice(0, 4) +
  '.' +
  date.slice(5, 7) +
  '.' +
  date.slice(8, 10) +
  ' ' +
  date.slice(11, 16);

const ItemTableTr = ({
  item,
  i,
  checkedItemIds,
  setCheckedItemIds,
  checkedItemCafe24Ids,
  setCheckedItemCafe24Ids,
  payload,
  setPayload,
}) => {
  const history = useHistory();
  let {
    id,
    referencedItemId,
    country,

    cafe24Id,
    shopName,
    shopCountry,

    designerStyleId,
    code,
    url,
    referencedItemUrl,

    imageUrl,

    brand,

    name,
    color,

    isInStock,

    isSellable,
    isFetchingPayActive,
    isShopPayActive,

    brandId,
    isCategoryMapped,
    isUpdatedInTime,
    isValid,
    isReplicated,

    discountedPrice,
    currency,
    discountRate,

    userPrice,
    fetchingPrice,
    shopPromotion,

    allPromotion,

    finalPrice,
    fetchingPromotion,

    updatedAt,
    isPriceProcessing,

    priority,
    updatePriorityAdminName,
    updatePriorityDate,
    viewCount,
    likeCount,
    cartCount,
    salesCount,

    updateExhibitionName,
    updateExhibitionAdminName,
    updateExhibitionDate,

    updateTimedealAdminName,
    updateTimedealDate,

    priceInfo: { shopPrice },
    groupTag,
  } = item;

  let updatePriorityDateString = null;
  if (updatePriorityDate) {
    updatePriorityDateString = dateToString(updatePriorityDate);
  }

  let updateExhibitionDateString = null;
  if (updateExhibitionDate) {
    updateExhibitionDateString = dateToString(updateExhibitionDate);
  }

  let updateTimedealDateString = null;
  if (updateTimedealDate) {
    updateTimedealDateString = dateToString(updateTimedealDate);
  }

  const PriorityToolTip = (props) => (
    <Tooltip id="admin-tooltip" {...props}>
      {updatePriorityAdminName}
      <br />
      {updatePriorityDateString}
    </Tooltip>
  );

  // 기획전 및 타임딜 추가에 대한 최근 수정정보
  const AddExhibitionTimedealToolTip = (props) => {
    const hasExhibitionUpdate = updateExhibitionName && updateExhibitionName !== '';
    const hasTimedealUpdate = updateTimedealDate;

    if (hasExhibitionUpdate || hasTimedealUpdate) {
      if (hasExhibitionUpdate && hasTimedealUpdate) {
        return (
          <Tooltip id="add-exhibition-admin-tooltip" {...props}>
            기획전 추가 [{updateExhibitionName}]
            <br />
            {updateExhibitionAdminName}
            <br />
            {updateExhibitionDateString}
            <br />
            <br />
            타임딜 추가
            <br />
            {updateTimedealAdminName}
            <br />
            {updateTimedealDateString}
          </Tooltip>
        );
      } else if (hasTimedealUpdate) {
        return (
          <Tooltip id="add-exhibition-admin-tooltip" {...props}>
            타임딜 추가
            <br />
            {updateTimedealAdminName}
            <br />
            {updateTimedealDateString}
          </Tooltip>
        );
      } else if (hasExhibitionUpdate) {
        return (
          <Tooltip id="add-exhibition-admin-tooltip" {...props}>
            기획전 추가 [{updateExhibitionName}]
            <br />
            {updateExhibitionAdminName}
            <br />
            {updateExhibitionDateString}
          </Tooltip>
        );
      }
    } else {
      return <div />;
    }
  };
  isPriceProcessing = Number(isPriceProcessing);
  isCategoryMapped = Number(isCategoryMapped);
  isInStock = Number(isInStock);

  if (shopPromotion !== null && shopPromotion.hasOwnProperty('isPromoted')) {
    shopPromotion.isPromoted = Number(shopPromotion.isPromoted);
  }
  if (fetchingPromotion !== null && fetchingPromotion.hasOwnProperty('isPromoted')) {
    fetchingPromotion.isPromoted = Number(fetchingPromotion.isPromoted);
  }

  isValid = Number(isValid);
  isUpdatedInTime = Number(isUpdatedInTime);
  allPromotion = JSON.parse(allPromotion);

  const productDetailLink = `/product/productDetail/v2`;
  const promotionDetailLink = `/product/modifyPromotion/`;

  const Overlay = (
    <Popover id="promotions popover" style={{ width: '500px', maxWidth: '500px' }}>
      <Popover.Title>적용가능 프로모션</Popover.Title>
      <Popover.Content>
        <table className="border-table" style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>프로모션명</th>
              <th>할인 내용</th>
              <th>코드</th>
            </tr>
          </thead>
          <tbody>
            {allPromotion?.map((promotion) => (
              <tr>
                <th>
                  <a
                    href={`/product/modifyPromotion/${promotion.promotionId}`}
                    target="_blank"
                    className="underline"
                  >
                    {promotion.promotionName}
                  </a>
                </th>
                <th>{promotion.promotionDescription}</th>
                <th>{promotion.promotionCode}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </Popover.Content>
    </Popover>
  );

  return (
    <style.Tr>
      <style.flexibleTd
        widthPcnt={39}
        onClick={() => {
          const checkedItemIdsSet = new Set(checkedItemIds);
          const checkedItemCafe24IdsSet = new Set(checkedItemCafe24Ids);
          if (checkedItemIdsSet.has(id)) {
            checkedItemIdsSet.delete(id);
            checkedItemCafe24IdsSet.delete(cafe24Id);
            setCheckedItemIds([...checkedItemIdsSet.values()]);
            setCheckedItemCafe24Ids([...checkedItemCafe24IdsSet.values()]);
          } else {
            checkedItemIdsSet.add(id);
            checkedItemCafe24IdsSet.add(cafe24Id);
            setCheckedItemIds([...checkedItemIdsSet.values()]);
            setCheckedItemCafe24Ids([...checkedItemCafe24IdsSet.values()]);
          }
        }}
      >
        <style.Checkbox
          onChange={() => {
            const checkedItemIdsSet = new Set(checkedItemIds);
            const checkedItemCafe24IdsSet = new Set(checkedItemCafe24Ids);
            if (checkedItemIdsSet.has(id)) {
              checkedItemIdsSet.delete(id);
              checkedItemCafe24IdsSet.delete(cafe24Id);
              setCheckedItemIds([...checkedItemIdsSet.values()]);
              setCheckedItemCafe24Ids([...checkedItemCafe24IdsSet.values()]);
            } else {
              checkedItemIdsSet.add(id);
              checkedItemCafe24IdsSet.add(cafe24Id);
              setCheckedItemIds([...checkedItemIdsSet.values()]);
              setCheckedItemCafe24Ids([...checkedItemCafe24IdsSet.values()]);
            }
          }}
          checked={checkedItemIds.includes(id)}
        />
        {i + 1}
      </style.flexibleTd>
      {history.location.pathname.includes(
        '/product/productList/manage-group/modify-group/'
      ) ? (
        <style.flexibleTd widthPcnt={50}>{groupTag}</style.flexibleTd>
      ) : null}
      <style.flexibleTd widthPcnt={105}>
        <a href={`${COMMERCE_URL}/product/${id}`} target="_blank">
          {id}
        </a>
        <span style={{ color: `${country === 'NONE' ? '#FF0707' : '#498935'}` }}>
          {country}
        </span>
        {isReplicated === '1' ? (
          <>
            원본 상품:{' '}
            <a
              href={`${productDetailLink}/${referencedItemId}?mode=update`}
              target="_blank"
              rel="noreferrer"
            >
              {referencedItemId}
            </a>
          </>
        ) : null}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={115}>
        {code}
        {code && (
          <a
            href={`https://link.fetching.co.kr/item/${id}/shop?doNotTrack=true`}
            target="_blank"
            rel="noreferrer"
          >
            {shopName} {shopCountry}
          </a>
        )}
        {isReplicated === '1' ? (
          <>
            <a href={referencedItemUrl} target="_blank" rel="noreferrer">
              원본 상품
            </a>
          </>
        ) : null}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={89} style={{ overflow: 'visible' }}>
        <style.Img src={imageUrl} />
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={102}>
        {brand}
        <br />
        {designerStyleId}
      </style.flexibleTd>
      {history?.location?.pathname === '/product/productList' ? (
        <style.flexibleTd widthPcnt={102}>
          {item?.priceRules?.map((el) => {
            return (
              <p>
                {el.name} ({el.country})
              </p>
            );
          })}
        </style.flexibleTd>
      ) : null}

      <style.flexibleTd widthPcnt={155}>
        <textarea
          style={{ width: '90%' }}
          value={payload[id] ? payload[id].name : name}
          onChange={(e) => onChangeItemInfo(e, id, 'name', setPayload)}
        />
        <input
          style={{ width: '90%' }}
          value={payload[id] ? payload[id].color : color}
          onChange={(e) => onChangeItemInfo(e, id, 'color', setPayload)}
          placeholder="컬러명"
        />
        <button
          onClick={() => {
            window.open(
              `${productDetailLink}/${id}?mode=update`,
              `${name} 상품 상세`,
              'width=800,height=1000,menubar=no,status=no,toolbar=no,menubar=no,location=no,scrollbars=no,left=10000'
            );
          }}
          style={{ color: '#2563EB' }}
        >
          상품상세
        </button>
        <button
          onClick={() => {
            window.open(
              `/product/productDetail/${id}?mode=create`,
              `${name} 상품 복사`,
              'width=960,height=1000,menubar=no,status=no,toolbar=no,menubar=no,location=no,scrollbars=no,left=10000'
            );
          }}
          style={{ color: '#2563EB' }}
        >
          상품복사
        </button>
      </style.flexibleTd>

      <style.flexibleTd
        widthPcnt={53}
        style={{ color: `${isInStock ? '#498935' : '#FF0707'}` }}
      >
        {isInStock ? '보유' : '품절'}
      </style.flexibleTd>

      <style.flexibleTd widthPcnt={97}>
        <span style={{ color: `${isSellable ? '#498935' : '#FF0707'}` }}>
          {isSellable ? '활성화' : '비활성화'}
        </span>
        <span style={{ color: '#9B9B9B', fontSize: '10px' }}>
          {brandId ? '' : '- 브랜드 매핑 X'}
        </span>
        <span style={{ color: '#9B9B9B', fontSize: '10px' }}>
          {isCategoryMapped ? '' : '- 카테고리 매핑 X'}
        </span>
        <span style={{ color: '#9B9B9B', fontSize: '10px' }}>
          {isUpdatedInTime ? '' : '- 상품정보 업데이트 X'}
        </span>
        <span style={{ color: '#9B9B9B', fontSize: '10px' }}>
          {isValid ? '' : '- 무효상품'}
        </span>
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={106}>
        <span style={{ color: `${isShopPayActive ? '#498935' : '#FF0707'}` }}>
          {isShopPayActive ? '활성화' : '비활성화'}
        </span>
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={105}>
        <span style={{ color: `${isFetchingPayActive ? '#498935' : '#FF0707'}` }}>
          {isFetchingPayActive ? '활성화' : '비활성화'}
        </span>
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={109}>
        <span>
          {discountedPrice.toLocaleString('en')}
          {currency}
        </span>
        <span style={{ color: '#FF6F61' }}>
          {discountRate > 0 && `${Math.round(discountRate * 100)}%할인`}
        </span>
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={83}>
        {allPromotion ? (
          <OverlayTrigger trigger="click" placement="right" overlay={Overlay}>
            <p style={{ color: '#498935' }}>
              적용가능 프로모션
              <span>
                <InformationCircleIcon className="w-5 h-5 inline-block" />
              </span>
            </p>
          </OverlayTrigger>
        ) : (
          <p style={{ color: '#FF0707' }}>없음</p>
        )}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={96}>
        {shopPrice.toLocaleString('en')}원
        {fetchingPromotion &&
          fetchingPromotion.map((promotion, index) => (
            <React.Fragment key={index}>
              <span style={{ color: '#498935' }}>
                {promotion.isPromoted !== 0 &&
                  promotion.promotionType === 'DISCOUNT_WITH_CONST' &&
                  `${promotion.promotionTypeValue} 프로모션`}
                {promotion.isPromoted !== 0 &&
                  promotion.promotionType === 'DISCOUNT_WITH_RATE' &&
                  `${Math.round(promotion.promotionTypeValue * 100)}% 프로모션`}
                {promotion.isPromoted !== 0 &&
                  promotion.promotionType === 'FREE_DELIVERY' &&
                  `무료배송 프로모션`}
              </span>
              {promotion.promotionCode && <span>{promotion.promotionCode}</span>}
              {promotion.promotionId && (
                <a
                  href={promotionDetailLink + promotion.promotionId}
                  target="_blank"
                  rel="noreferrer"
                >
                  프로모션 정보
                </a>
              )}
            </React.Fragment>
          ))}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={96}>
        {fetchingPrice.toLocaleString('en')}원
        {shopPromotion &&
          shopPromotion.map((promotion, index) => (
            <React.Fragment key={index}>
              <span style={{ color: '#498935' }}>
                {promotion.isPromoted !== 0 &&
                  promotion.promotionType === 'DISCOUNT_WITH_CONST' &&
                  `${promotion.promotionTypeValue} 프로모션`}
                {promotion.isPromoted !== 0 &&
                  promotion.promotionType === 'DISCOUNT_WITH_RATE' &&
                  `${Math.round(promotion.promotionTypeValue * 100)}% 프로모션`}
                {promotion.isPromoted !== 0 &&
                  promotion.promotionType === 'FREE_DELIVERY' &&
                  `무료배송 프로모션`}
              </span>
              {promotion.promotionCode && <span>{promotion.promotionCode}</span>}
              {promotion.promotionId && (
                <a
                  href={promotionDetailLink + promotion.promotionId}
                  target="_blank"
                  rel="noreferrer"
                >
                  프로모션 정보
                </a>
              )}
            </React.Fragment>
          ))}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={108}>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
          overlay={AddExhibitionTimedealToolTip}
        >
          <span>
            <span>{dayjs(updatedAt).format('YYYY.MM.DD HH:MM')}</span>
            <br />
            {isPriceProcessing === 1 && (
              <span style={{ color: '#DD9847' }}>가격 업데이트 중</span>
            )}
          </span>
        </OverlayTrigger>
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={65}>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 100 }}
          overlay={PriorityToolTip}
        >
          <span style={{ cursor: 'pointer', padding: '10px' }}>{priority}</span>
        </OverlayTrigger>
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={55}>{viewCount}</style.flexibleTd>
      <style.flexibleTd widthPcnt={51}>{likeCount}</style.flexibleTd>
      <style.flexibleTd widthPcnt={63}>{cartCount}</style.flexibleTd>
      <style.flexibleTd widthPcnt={54} noBorder>
        {salesCount}
      </style.flexibleTd>
    </style.Tr>
  );
};

export default ItemTableTr;

////////상품명, 컬러 변경 이벤트
const onChangeItemInfo = (e, id, target, setPayload) => {
  const { value } = e.target;

  setPayload((prev) => {
    if (!(id.toString() in prev)) {
      prev[id] = { id: id };
    }
    prev[id][target] = value;
    return { ...prev };
  });
};
////////
