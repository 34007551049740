import React, { useContext, useState } from 'react';
import {
  NameKeywordTable,
  Loading,
  SearchButtonGroup,
  TopBottomButtons,
  KeywordSearchOptionTable,
} from '../../components';
import * as style from './style';
import initialState from './initState';
import { trimParams } from '../ProductList/utils';
import Pagination from '../ManageCatalog/CatalogSearchOptionTable/Pagination';
import { LoadingContext } from '../../context';
import { fetchingAPI } from '../../api/axios';

const ProductNameKeyword = () => {
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [keywords, setKeywords] = useState([]);
  const [searchOptionTableState, setSearchOptionTableState] = useState(initialState);
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const fetchItems = async (page = 1, callback = () => {}) => {
    handleIsLoading(true);

    try {
      const { data } = await fetchingAPI.get('/keyword/name', {
        params: { ...searchOptionTableState, page },
      });
      setKeywords(data.data);
      setTotalCount(data.count);
      setCurrentPage(page);
    } catch (err) {
      alert(`error in fetchItems(), /productList, ${err.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  return (
    <style.Outer>
      {isLoading && <Loading />}
      <TopBottomButtons />
      <style.ShadowContainer>
        <style.PageTitle>상품 이름 키워드</style.PageTitle>
        <KeywordSearchOptionTable
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
        <SearchButtonGroup
          fetchItems={fetchItems}
          setSearchOptionTableState={setSearchOptionTableState}
          initialState={initialState}
          setItems={setKeywords}
        />
      </style.ShadowContainer>
      <style.ShadowContainer>
        <NameKeywordTable
          keywords={keywords}
          totalCount={totalCount}
          fetchItems={fetchItems}
          trimParams={trimParams}
        />
        <Pagination fetchItems={fetchItems} currentPage={currentPage} />
      </style.ShadowContainer>
    </style.Outer>
  );
};

export default ProductNameKeyword;
