import { Alert, GridTable, MenuTitle } from 'components';
import React from 'react';
import Switch from 'react-switch';

interface State {}

const settings = [
  { header: '신규가입' },
  { header: '가입해지' },
  { header: '아이디/비밀번호 안내' },
  { header: '신규번호 인증요청' },
  { header: '회원 인증확인' },
  { header: '신규주문 내역확인' },
  { header: '무통장 입금확인' },
  { header: '발송조치 (완전배송시)' },
];

const UsersAutoMailingPage = () => {
  return (
    <>
      <MenuTitle title="자동 메일 발송 설정" />
      <div className="m-4">
        <Alert
          type="info"
          list={[
            '자동 메일 발송은 현재 외부 솔루션 스티비(stibee)를 사용하고 있습니다.',
            <>
              자동 메일 수정 및 메일 발송은 스티비(stibee)에서 진행해주세요.{' '}
              <a href="https://stibee.com/login" target="_blank">
                스티비로 이동하기
              </a>
            </>,
          ]}
        />
      </div>

      <div className="wrapper">
        <div className="wrapper-title">자동 메일 발송</div>
        <GridTable
          columns={['자동 메일 트리거 항목', '고객', '공급사', '비고']}
          list={settings}
          noSelection
          maxHeight=""
          renderItem={(item, key) => (
            <div key={key}>
              <div className="text-sm px-6 py-3 bg-gray-200 text-gray-900">
                {item.header}
              </div>
              <div>
                <Switch checked={false} onChange={() => {}} />
              </div>
              <div>
                <Switch checked={false} onChange={() => {}} />
              </div>
              <div></div>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default UsersAutoMailingPage;
