import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

export const SaveFinModal = ({ show, handleClose }) => {
    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>저장 완료</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {`선택하신 상품의 변경사항을 모두 저장하였습니다. 
                최종 카탈로그 반영까지 최대 12시간이 소요될 수 있습니다.`
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    닫기
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export const DeleteCatalogModal = ({ show, handleClose }) => {
    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>카탈로그 삭제</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    `선택하신 상품의 카탈로그를 삭제합니다.
                    삭제된 카탈로그 정보는 복구할 수 없습니다.`
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    확인
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export const DeleteCatalogFinModal = ({ show, handleClose }) => {
    return (
        <Modal show={show}>
            <Modal.Header closeButton>
                <Modal.Title>삭제완료</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    `선택하신 상품의 카탈로그를 삭제했습니다.`
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    닫기
            </Button>
            </Modal.Footer>
        </Modal>
    );
}

export const ChangeIsImportFlagModal = ({ show, fetchImprotFlag, setModals, handleClose, checkedItemIdsLength }) => {

    const [is_impmort_flag, set_is_import_flag] = useState(false);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>배송유형 변경</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                선택하신 {checkedItemIdsLength}개 상품의 배송유형을
            <select onChange={(e) => {
                    set_is_import_flag(Boolean(e.target.value));
                }}>
                    <option value=''>해외배송</option>
                    <option value='true'>국내배송</option>
                </select>으로 변경합니다.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    fetchImprotFlag(is_impmort_flag);
                    setModals({
                        SaveFinModal: false,
                        DeleteCatalogModal: false,
                        DeleteCatalogFinModal: false,
                        ChangeIsImportFlagModal: false,
                        ChangeIsImportFlagFinModal: true,
                    });
                }}>
                    확인
        </Button>
            </Modal.Footer>
        </Modal>
    );
}

export const ChangeIsImportFlagFinModal = ({ show, handleClose }) => {

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>변경완료</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                선택하신 상품의 배송유형을 변경했습니다.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    닫기
        </Button>
            </Modal.Footer>
        </Modal>
    );
}