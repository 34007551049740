import React from 'react';
import * as style from './style';
import dayjs from 'dayjs';

const today = dayjs().format('YYYY-MM-DD');

const CommerceUserTypeTd = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const { gender, userType, minBirthDate, maxBirthDate } =
    searchOptionTableState;

  // date input 용 문자열 생성
  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  };

  // input date 처리
  const handleDateInput = (e, target) => {
    setSearchOptionTableState({
      ...searchOptionTableState,
      [target]: e.target.value,
    });
  };

  return (
    <style.Container>
      <style.Div
        onChange={(e) => {
          let value = e.target.value;
          if (value === 'null') {
            value = null;
          }
          setSearchOptionTableState({
            ...searchOptionTableState,
            gender: value,
          });
        }}
        widthPcnt={22}
      >
        <style.Label>
          <style.Radio
            value="null"
            checked={gender === null}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio value="M" checked={gender === 'M'} onChange={() => {}} />
          남성
        </style.Label>
        <style.Label>
          <style.Radio value="W" checked={gender === 'W'} onChange={() => {}} />
          여성
        </style.Label>
      </style.Div>
      <style.Left widthPcnt={11}>회원 등급</style.Left>
      <style.Div
        onChange={(e) => {
          let value = e.target.value;
          if (value === 'null') {
            value = null;
          }
          setSearchOptionTableState({
            ...searchOptionTableState,
            userType: value,
          });
        }}
        widthPcnt={22}
      >
        <style.Margin9px />
        <style.Label>
          <style.Radio
            value="null"
            checked={userType === null}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="USER"
            checked={userType === 'USER'}
            onChange={() => {}}
          />
          일반
        </style.Label>
        <style.Label>
          <style.Radio
            value="VIP"
            checked={userType === 'VIP'}
            onChange={() => {}}
          />
          VIP
        </style.Label>
      </style.Div>
      <style.Left widthPcnt={11}>생년월일</style.Left>
      <style.Div widthPcnt={22} noBorder>
        <style.Margin9px />
        <style.Label>
          <style.InputDate
            max={today}
            onChange={(e) => handleDateInput(e, 'minBirthDate')}
            value={minBirthDate}
          />
          ~
          <style.InputDate
            max={today}
            onChange={(e) => handleDateInput(e, 'maxBirthDate')}
            value={maxBirthDate}
          />
        </style.Label>
      </style.Div>
    </style.Container>
  );
};

export default CommerceUserTypeTd;
