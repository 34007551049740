import React, { useRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import * as style from './style';

const BrandRenderInfo = ({
  modifyBrand,
  setModifyBrand,
  handleRoundImgOnDrop,
  handleImgOnDrop,
  handleClinentHeight,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('앱 노출 정보', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('앱 노출 정보', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>앱 노출 정보</style.Title>
      <style.TableWrapper>
        <style.TableTitle> 정방형 로고 </style.TableTitle>
        <style.TableValue>
          <div>
            <style.Input
              value={modifyBrand.imageUrl}
              onChange={(e) => {
                setModifyBrand({
                  ...modifyBrand,
                  imageUrl: e.target.value,
                });
              }}
            />{' '}
            <Dropzone
              onDrop={handleRoundImgOnDrop}
              multiple={false}
              maxSize={10000000}
            >
              {({ getRootProps, getInputProps }) => (
                <span {...getRootProps()}>
                  <input {...getInputProps()} />
                  <style.Button>찾아보기</style.Button>
                </span>
              )}
            </Dropzone>
          </div>
          <style.RoundImg src={modifyBrand.imageUrl} />
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle>가로형 로고 </style.TableTitle>
        <style.TableValue>
          <div>
            <style.Input
              value={modifyBrand.bannerImageUrl}
              onChange={(e) => {
                setModifyBrand({
                  ...modifyBrand,
                  bannerImageUrl: e.target.value,
                });
              }}
            />{' '}
            <Dropzone
              onDrop={handleImgOnDrop}
              multiple={false}
              maxSize={10000000}
            >
              {({ getRootProps, getInputProps }) => (
                <span {...getRootProps()}>
                  <input {...getInputProps()} />
                  <style.Button>찾아보기</style.Button>
                </span>
              )}
            </Dropzone>
          </div>
          <style.Img src={modifyBrand.bannerImageUrl} />
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle>브랜드 설명</style.TableTitle>
        <style.TableValue>
          <style.Textarea
            value={modifyBrand.description}
            onChange={(e) => {
              setModifyBrand({
                ...modifyBrand,
                description: e.target.value,
              });
            }}
          />
        </style.TableValue>
      </style.TableWrapper>
    </style.Wrapper>
  );
};
export default BrandRenderInfo;
