import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';
import type { NumberFormatPropsBase } from 'react-number-format';

export interface Props extends Omit<NumberFormatPropsBase, 'size' | 'suffix' | 'prefix'> {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  align?: 'left' | 'right';
}
interface State {}

const InputNumber: FunctionComponent<Props> = ({
  size,
  disabled,
  readOnly,
  className,
  prefix,
  suffix,
  align,
  ...props
}) => {
  if (prefix || suffix) {
    return (
      <div
        className={classnames(
          'inline-flex justify-between items-center focus-within:ring-indigo-500 focus-within:border-indigo-500 border border-gray-300 rounded',
          {
            'bg-gray-100': disabled || readOnly,
            'bg-white': !disabled && !readOnly,
            'cursor-not-allowed': disabled,
            'cursor-default': readOnly,
            'text-xs p-1 rounded-sm': size === 'xs',
            'text-sm py-1 px-2 rounded': size === 'sm',
            'text-base py-2 px-3 rounded-md': size === 'md',
            'text-lg py-3 px-4 rounded-md': size === 'lg',
          },
          className
        )}
      >
        {prefix}
        <NumberFormat
          {...props}
          disabled={disabled}
          readOnly={readOnly}
          className={classnames(
            'border-0 focus:outline-none mx-1 flex-1 w-full',
            {
              'bg-gray-100': disabled || readOnly,
              'text-right': align === 'right',
            },
            className
          )}
          spellCheck={false}
        />
        {suffix}
      </div>
    );
  }
  return (
    <NumberFormat
      {...props}
      disabled={disabled}
      readOnly={readOnly}
      className={classnames(
        'focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300',
        {
          'bg-gray-100': disabled || readOnly,
          'bg-white': !disabled && !readOnly,
          'cursor-not-allowed': disabled,
          'cursor-default': readOnly,
          'text-xs p-1 rounded-sm': size === 'xs',
          'text-sm py-1 px-2 rounded': size === 'sm',
          'text-base py-2 px-3 rounded-md': size === 'md',
          'text-lg py-3 px-4 rounded-md': size === 'lg',
          'text-right': align === 'right',
        },
        className
      )}
      spellCheck={false}
    />
  );
};

InputNumber.defaultProps = {
  size: 'md',
};

export default InputNumber;
