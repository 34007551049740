/* eslint-disable no-prototype-builtins */
import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

import * as style from './style';

const dateToString = (date) =>
  date.slice(0, 4) +
  '.' +
  date.slice(5, 7) +
  '.' +
  date.slice(8, 10) +
  ' ' +
  date.slice(11, 16);

const NameKeywordTableTr = ({ item, i, checkedItemIds, setCheckedItemIds }) => {
  let { keyword, alias, createdBy, createdAt } = item;

  return (
    <style.Tr>
      <style.flexibleTd
        widthPcnt={20}
        onClick={() => {
          const checkedItemIdsSet = new Set(checkedItemIds);
          if (checkedItemIdsSet.has(keyword)) {
            checkedItemIdsSet.delete(keyword);
            setCheckedItemIds([...checkedItemIdsSet.values()]);
          } else {
            checkedItemIdsSet.add(keyword);
            setCheckedItemIds([...checkedItemIdsSet.values()]);
          }
        }}
      >
        <style.Checkbox
          onChange={() => {
            const checkedItemIdsSet = new Set(checkedItemIds);
            if (checkedItemIdsSet.has(keyword)) {
              checkedItemIdsSet.delete(keyword);
              setCheckedItemIds([...checkedItemIdsSet.values()]);
            } else {
              checkedItemIdsSet.add(keyword);
              setCheckedItemIds([...checkedItemIdsSet.values()]);
            }
          }}
          checked={checkedItemIds.includes(keyword)}
        />
        {i + 1}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={100}>{keyword}</style.flexibleTd>
      <style.flexibleTd widthPcnt={100}>{alias}</style.flexibleTd>
      <style.flexibleTd widthPcnt={50}>{createdBy}</style.flexibleTd>
      <style.flexibleTd widthPcnt={50}>{createdAt}</style.flexibleTd>
    </style.Tr>
  );
};

export default NameKeywordTableTr;
