import { modifyCountryItems } from 'api/axios';
import React, { useState } from 'react';
import * as style from './style';

const OriginChangeModal = ({ onHide, targetIds }) => {
  // const itemIds = useSelector((state) => state.selectItems.itemsId);
  const itemIds = targetIds;
  const [isLoading, setIsLoading] = useState(false);
  const [isRes, setIsRes] = useState(false);
  const [resMessage, setResMessage] = useState({
    title: '',
    description: '',
  });

  const handleChangeOrigin = async () => {
    try {
      setIsLoading(true);
      const body = {
        country: 'EU',
      };
      if (!itemIds.length) {
        alert('❗ 선택한 상품이 없습니다.');
      } else {
        body.itemIds = itemIds;
        await modifyCountryItems(body);
      }

      setResMessage({
        title: '변경 완료',
        description: '선택한 상품의 원산지가 EU로 설정되었습니다. 😊',
      });
    } catch (err) {
      console.log(err);
      setResMessage({
        title: '변경 실패',
        description: '선택한 상품의 원산지 설정 실패 😫',
      });
    } finally {
      setIsLoading(false);
      setIsRes(true);
    }
  };

  return (
    <style.Wrapper
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onHide();
        }
      }}
    >
      <style.Inner>
        <style.Header>
          <h3>원산지 수동 변경</h3>
        </style.Header>
        <style.Body>
          <p>{itemIds.length}개 항목의 원산지가 EU로 일괄 변경됩니다.</p>
          {/* <style.Select
            onChange={(e) => setSelected(e.target.value)}>
            <option value={0}>❗ 설정 대상을 선택하세요.</option>
            <option value={'checkedItems'}>✔ 체크된 상품만 요청하기</option>
            <option value={'allItems'}>❇ 검색 결과 전체 상품 요청하기</option>
          </style.Select> */}
          <style.Button
            MT="30px"
            BC="#343a40"
            HBC="white"
            color="white"
            HC="#5884b3"
            onClick={handleChangeOrigin}
          >
            원산지 변경하기
          </style.Button>
          {isRes && (
            <style.ResWrapper>
              <strong>{resMessage.title}</strong>
              <p>{resMessage.description}</p>
            </style.ResWrapper>
          )}
        </style.Body>
        <style.Bottom>
          <style.Button
            BC="#343a40"
            HBC="white"
            color="white"
            HC="#5884b3"
            style={{ float: 'right' }}
            onClick={onHide}
          >
            닫기
          </style.Button>
        </style.Bottom>
      </style.Inner>
    </style.Wrapper>
  );
};
export default OriginChangeModal;
