import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  min-width: 900px;
`;

export const Title = styled.div`
  width: calc(100% / 7);
  text-align: center;
  padding: 10px 15px;
  font-weight: bold;
  background-color: #e8e8e8;
  border-right: 1px solid #ddd;
`;

export const Inner = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: auto;
`;
