import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import _ from 'lodash';

import * as axios from 'api/axios';
import * as style from './style';

const LayoutOptions = [
  { value: 'ALL_CATEGORIES', label: 'MOBILE ONLY - ALL_CATEGORIES' },
  {
    value: 'EXHIBITION_OVERLAP_ITEM',
    label: 'MOBILE ONLY - EXHIBITION_OVERLAP_ITEM',
  },
  { value: 'ITEMS', label: 'PC ONLY - ITEMS' },
  { value: 'EXHIBITION', label: 'PC ONLY - EXHIBITION' },
  { value: 'BANNER', label: 'PC & MOBILE - BANNER' },
  { value: 'HOT_DEAL', label: 'PC & MOBILE - HOT_DEAL' },
  { value: 'EXHIBITION_ITEM', label: 'PC & MOBILE - EXHIBITION_ITEM' },
  { value: 'RANDOM_WIDGETS', label: 'PC & MOBILE - RANDOM_WIDGETS' },
  { value: 'PROMOTION_ITEM', label: 'PC & MOBILE - PROMOTION_ITEM' },
  { value: 'BRANDS', label: 'PC & MOBILE - BRANDS' },
  { value: 'BEST_KEYWORD', label: 'PC & MOBILE - BEST_KEYWORD' },
  { value: 'TIME_DEAL', label: 'PC & MOBILE - TIME_DEAL' },

  // { value: 'MULTI_LINE_ITEMS', label: 'MULTI_LINE_ITEMS' },
  // { value: 'AD', label: 'AD' },
  // { value: 'ITEMS', label: 'ITEMS' },
  // { value: 'WIDGET', label: 'WIDGET' },
];

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#9e9e9e',
    minWidth: '150px',
    minHeight: '30px',
    height: '30px',
    boxShadow: state.isFocused ? null : null,
  }),
  option: (base) => ({
    ...base,
    textAlign: 'left',
    fontSize: '12px',
  }),

  valueContainer: (provided) => ({
    ...provided,
    height: '30px',
    padding: '0 6px',
  }),

  input: (provided) => ({
    ...provided,
    margin: '0px',
    align: 'left',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '30px',
  }),
};

const BannerDetailList = ({
  banners,
  exhibitions,
  onRefresh,
  promotions,
  platformInfo,
}) => {
  const [originBannerList, setOriginBannerList] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [exhibitionList, setExhibitionList] = useState([]);
  const [promotionList, setPromotionList] = useState([]);

  const initData = () => {
    onRefresh();
  };

  const onSelect = (banner, field, { value }) => {
    const index = _.findIndex(bannerList, { id: banner.id });
    const newBanner = {
      ...banner,
      [field]: value,
    };

    bannerList.splice(index, 1, newBanner);
    setBannerList([...bannerList]);
  };

  const onChange = (banner, field, value) => {
    const index = _.findIndex(bannerList, { id: banner.id });
    const newBanner = {
      ...banner,
      [field]: field === 'order' ? Number(value) : value,
    };

    bannerList.splice(index, 1, newBanner);
    setBannerList([...bannerList]);
  };

  const uploadToServer = async (banner, b64Buffer) => {
    try {
      const { data } = await axios.updateExhibitionImage(b64Buffer);
      if (data.success) {
        const fileUrl = data.data.fpath + data.data.fname;
        onChange(banner, 'imgSrc', fileUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getBase64 = async (file, banner, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const result = reader.result.split(',');
      cb(banner, result[1]);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  };

  const handleOnDrop = (banner, files) => {
    getBase64(files[0], banner, uploadToServer);
  };

  useEffect(() => {
    setBannerList(banners);
    setOriginBannerList([...banners]);
  }, [banners]);

  useEffect(() => {
    setExhibitionList(exhibitions);
  }, [exhibitions]);

  useEffect(() => {
    if (promotions) {
      setPromotionList([...promotions]);
    }
  }, [promotions]);

  const onUpdate = async () => {
    const updateBanner = bannerList.filter((banner) => !banner.isNew);
    const newBanner = bannerList.filter((banner) => banner.isNew);

    const changedBanners = updateBanner.filter(
      (banner, idx) => !_.isEqual(banner, originBannerList[idx]),
    );

    console.log(changedBanners, newBanner);

    if (newBanner.length === 0 && changedBanners.length === 0) {
      return alert('변경된 사항이 없습니다.');
    }

    try {
      if (changedBanners.length > 0) {
        await updateCafe24Banner(changedBanners);
      }

      if (newBanner.length > 0) {
        await createCafe24Banner(newBanner);
      }

      alert('업데이트 되었습니다.');
    } catch (err) {
      console.error(err);
      alert('에러가 발생했습니다.');
    } finally {
      onRefresh();
    }
  };

  const updateCafe24Banner = async (changedBanners) =>
    await axios.updateCafe24Banners(changedBanners);

  const createCafe24Banner = async (newBanner) =>
    await axios.createCafe24Banners(newBanner);

  const onDelete = async (banner) => {
    if (banner.isNew) {
      const index = _.findIndex(bannerList, { id: banner.id });
      bannerList.splice(index, 1);
      setBannerList([...bannerList]);
    } else {
      try {
        const { status } = await axios.deleteCafe24Banner(banner.id);
        if (status === 200) {
          alert('삭제 되었습니다.');
        }
      } catch (err) {
        alert('에러가 발생했습니다.');
        console.error(err);
      } finally {
        onRefresh();
      }
    }
  };

  const createNew = () => {
    const emptyBanner = {
      id: new Date().getTime(),
      platform: platformInfo.platform,
      gender: platformInfo.gender,
      order: 0,
      layoutType: 'BANNER',
      exhibitionId: null,
      href: '',
      imgSrc: '',
      isNew: true,
    };
    setBannerList([...bannerList, emptyBanner]);
  };

  const getGender = (gender) => {
    switch (gender) {
      case 'M':
        return '남성';
      case 'W':
        return '여성';
      case 'MW':
        return '공용';
      default:
        return '';
    }
  };

  return (
    <div>
      <style.TableWrapper style={{ marginTop: '10px' }}>
        <style.Table>
          <style.Thead>
            <tr>
              <th>ID</th>
              <th>노출순서</th>
              <th>레이아웃</th>
              <th>기획전</th>
              <th>연결링크</th>
              <th>배너</th>
              <th>삭제</th>
            </tr>
          </style.Thead>
          <tbody>
            {bannerList &&
              bannerList.map((banner) => (
                <style.Tr key={banner.id}>
                  <style.Td align={'center'} width={'50px'}>
                    {banner.id}
                  </style.Td>
                  <style.Td align={'center'} width={'80px'}>
                    <style.Input
                      value={banner.order}
                      style={{ textAlign: 'center' }}
                      onChange={(e) =>
                        onChange(banner, 'order', e.target.value)
                      }
                    />
                  </style.Td>
                  <style.Td align={'center'}>
                    <Select
                      defaultValue={{
                        value: banner.layoutType,
                        label: banner.layoutType,
                      }}
                      styles={customStyles}
                      options={LayoutOptions}
                      onChange={(value) =>
                        onSelect(banner, 'layoutType', value)
                      }
                    />
                  </style.Td>
                  <style.Td align={'center'}>
                    {banner.layoutType !== 'PROMOTION_ITEM' ? (
                      <Select
                        defaultValue={{
                          value: banner.exhibitionId,
                          label: banner.exhibitionName,
                        }}
                        styles={customStyles}
                        options={exhibitionList.map(
                          ({ exhibitionId, name, gender }) => ({
                            value: exhibitionId,
                            label: `${getGender(gender)} - ${name}`,
                          }),
                        )}
                        onChange={(value) =>
                          onSelect(banner, 'exhibitionId', value)
                        }
                      />
                    ) : (
                      <Select
                        defaultValue={{
                          value: banner.exhibitionId,
                          label: banner.exhibitionName,
                        }}
                        styles={customStyles}
                        options={promotionList?.map(({ id, name, gender }) => ({
                          value: id,
                          label: `${getGender(gender)} - ${name}`,
                        }))}
                        onChange={(value) =>
                          onSelect(banner, 'exhibitionId', value)
                        }
                      />
                    )}
                  </style.Td>
                  <style.Td align={'center'}>
                    <style.Input
                      value={banner.href}
                      onChange={(e) => onChange(banner, 'href', e.target.value)}
                    />
                  </style.Td>
                  <style.Td align={'center'}>
                    <div style={{ marginBottom: '4px' }}>
                      <input
                        value={banner.imgSrc || ''}
                        type="text"
                        style={{ width: '340px' }}
                        onChange={() => {}}
                      />
                      <Dropzone
                        onDrop={(files) => handleOnDrop(banner, files)}
                        multiple={false}
                        maxSize={10000000}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <style.Button {...getRootProps()}>
                            <input {...getInputProps()} />
                            찾아보기
                          </style.Button>
                        )}
                      </Dropzone>
                    </div>
                    <img
                      src={banner.imgSrc}
                      width="400px"
                      style={{ maxHeight: '200px', objectFit: 'cover' }}
                    />
                  </style.Td>
                  <style.Td align={'center'}>
                    <Button
                      style={{ width: '100%' }}
                      onClick={() => onDelete(banner)}
                    >
                      삭제
                    </Button>
                  </style.Td>
                </style.Tr>
              ))}
          </tbody>
        </style.Table>
      </style.TableWrapper>
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <Button
          style={{ marginRight: '8px', background: '#0D9AE9', color: '#FFF' }}
          onClick={createNew}
        >
          추가
        </Button>
        <Button
          style={{ marginRight: '8px', background: '#000', color: '#FF6F61' }}
          onClick={onUpdate}
        >
          수정 저장
        </Button>
        <Button
          style={{ background: '#DDDDDD', color: '#000000' }}
          onClick={initData}
        >
          수정 초기화
        </Button>
      </div>
    </div>
  );
};

export default BannerDetailList;
