import { Button, Modal, Form } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'context';
import { fetchingAPI } from 'api/axios';
import { toast } from 'react-toastify';

const ExhibitionTabModal = ({ show, onHide, targetIds }) => {
  const { handleIsLoading } = useContext(LoadingContext);

  /////기획전 목록
  const [exhibitions, setExhibitions] = useState([]);
  /////////기획전 받아옴
  const fetchExhibitions = async () => {
    const { data } = await fetchingAPI.get('/exhibition', {
      params: { isExtended: true },
    });
    setExhibitions(data);
  };
  /////////
  useEffect(() => {
    if (show) {
      fetchExhibitions();
    }
  }, [show]);
  /////

  //////상품 기획전 정보 변경 적용
  const fetchOptions = async (data) => {
    data.forEach(async ({ method, exhibitionId, categoryId, disable }) => {
      if (disable) {
        return;
      }
      try {
        handleIsLoading(true);
        if (method === 'add') {
          if (categoryId === '-1') {
            ///카테고리에 상품추가
            toast.info('상품은 탭 안에만 추가 가능합니다.');
            return;
            // await addInCategory(targetIds, exhibitionId);
          } else {
            ///하부 카테고리에 상품 추가
            await addInTab(targetIds, exhibitionId, categoryId);
          }
        } else if (method === 'delete') {
          if (categoryId === '-1') {
            ///카테고리에 상품 삭제
            await deleteInCategory(targetIds, exhibitionId);
          } else {
            ///하부 카테고리에 상품삭제
            await deleteInTab(targetIds, exhibitionId, categoryId);
          }
        } else {
          throw new Error('Wrong method');
        }
        onHide();
      } catch (error) {
        alert(`err in fetchOptions(), ${error.message}`);
      } finally {
        handleIsLoading(false);
      }
    });
  };
  //////

  /////변경 옵션 상태
  const initialState = [
    {
      method: 'add',
      gender: 'M',
      exhibitionId: '-1',
      categoryId: '-1',
      disable: false,
    },
  ];
  const [options, setOptions] = useState(initialState);
  //////

  useEffect(() => {
    if (show) {
      setOptions(initialState);
    }
  }, [show]);
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>기획전 하부 카테고리 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        기획전 하부 카테고리를 변경합니다. +를 눌러서 옵션을 추가하세요.
        {options.map((v, i) => (
          <SetExhibitionTab
            key={i}
            index={i}
            exhibitions={exhibitions}
            setOptions={setOptions}
            {...v}
          />
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => fetchOptions(options)}>
          변경
        </Button>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

/////변경 옵션 컴포넌트
const SetExhibitionTab = ({
  index,
  exhibitions,
  setOptions,
  method,
  gender,
  exhibitionId,
  categoryId,
  disable,
}) => {
  const initialState = {
    method: 'add',
    gender: 'M',
    exhibitionId: '-1',
    categoryId: '-1',
    disable: false,
  };

  /////기획전 하부 카테고리 받아옴
  const [tabs, setTabs] = useState([]);
  const fetchTabs = async () => {
    const { data } = await fetchingAPI.get(`/exhibition/${exhibitionId}/categories/`);
    setTabs(data);
  };
  useEffect(() => {
    if (exhibitionId !== '-1') {
      fetchTabs();
    }
  }, [exhibitionId]);
  /////

  if (disable) {
    return <></>;
  } else {
    return (
      <div style={{ display: 'flex' }}>
        <Form.Control
          className="m-2"
          as="select"
          value={method}
          onChange={(e) => {
            const { value } = e.target;
            setOptions((prev) => {
              prev[index].method = value;
              return [...prev];
            });
          }}
        >
          <option value="add">추가</option>
          <option value="delete">삭제</option>
        </Form.Control>
        <Form.Control
          className="m-2"
          as="select"
          value={gender}
          onChange={(e) => {
            const { value } = e.target;
            setOptions((prev) => {
              prev[index].gender = value;
              prev[index].exhibitionId = '-1';
              prev[index].categoryId = '-1';
              return [...prev];
            });
          }}
        >
          <option value="M">남성</option>
          <option value="W">여성</option>
        </Form.Control>
        <Form.Control
          className="m-2"
          as="select"
          value={exhibitionId}
          onChange={(e) => {
            const { value } = e.target;
            setOptions((prev) => {
              prev[index].exhibitionId = value;
              prev[index].categoryId = '-1';
              return [...prev];
            });
          }}
        >
          <option value={'-1'}>기획전 선택</option>
          {exhibitions
            .filter((v) => v.gender === gender)
            .map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
        </Form.Control>
        <Form.Control
          className="m-2"
          as="select"
          value={categoryId}
          onChange={(e) => {
            const { value } = e.target;
            setOptions((prev) => {
              prev[index].categoryId = value;
              return [...prev];
            });
          }}
        >
          <option value={'-1'}>탭 선택 안함</option>
          {tabs.map(({ id, name }) => (
            <option key={id} value={id}>
              {name}
            </option>
          ))}
        </Form.Control>
        {index === 0 ? (
          <Button
            className="m-2"
            variant="success"
            onClick={() => {
              setOptions((prev) => [...prev, initialState]);
            }}
          >
            +
          </Button>
        ) : (
          <Button
            className="m-2"
            variant="danger"
            onClick={() => {
              setOptions((prev) => {
                prev[index].disable = true;
                return [...prev];
              });
            }}
          >
            -
          </Button>
        )}
      </div>
    );
  }
};
///////

///////////상품 카테고리에 추가, 삭제
const addInTab = async (fetchingIds, exhibitionId, categoryId) => {
  await fetchingAPI.put(
    `/exhibition/${exhibitionId}/categories/${categoryId}/item`,
    fetchingIds
  );
};
const deleteInTab = async (fetchingIds, exhibitionId, categoryId) => {
  await fetchingAPI.delete(`/exhibition/${exhibitionId}/categories/${categoryId}/item`, {
    data: fetchingIds,
  });
};
const deleteInCategory = async (fetchingIds, exhibitionId) => {
  await fetchingAPI.delete(`/exhibition/${exhibitionId}/items`, {
    data: fetchingIds,
  });
};
///////////

export default ExhibitionTabModal;
