export const LOGIN_USER = 'LOGIN_USER';

export const LOGOUT_USER = 'LOGOUT_USER';

export const DETAIL_NOTICE = 'DETAIL_NOTICE';

export const SELECT_SHOP_BASIC_DATA = 'SELECT_SHOP_BASIC_DATA';

export const SELECT_ITEMS_ALL = 'SELECT_ITEMS_ALL';

export const REMOVE_ITEMS_ALL = 'REMOVE_ITEMS_ALL';

export const ADD_SELECT_ITEMS = 'ADD_SELECT_ITEMS';

export const REMOVE_SELECT_ITEMS = 'REMOVE_SELECT_ITEMS';

export const ADD_EXHIBITION_OPTION = 'ADD_EXHIBITION_OPTION';

export const DELETE_EXHIBITION_OPTION = 'DELETE_EXHIBITION_OPTION';

export const ALL_DELETE_CAFE24_MAIN_OPTION = 'ALL_DELETE_CAFE24_MAIN_OPTION';

export const ADD_SELECT_NOTICE = 'ADD_SELECT_NOTICE';

export const REMOVE_SELECT_NOTICE = 'REMOVE_SELECT_NOTICE';

export const REMOVE_SELECT_NOTICE_ALL = 'REMOVE_SELECT_NOTICE_ALL';

export const SELECT_PROMOTIOM_SHOP_ID = 'SELECT_PROMOTIOM_SHOP_ID';

export const SELECT_PROMOTION_DATA = 'SELECT_PROMOTION_DATA';

export const SELECT_CATEGORY_ID = 'SELECT_CATEGORY_ID';

export const REMOVE_SELECT_CATEGORY = 'REMOVE_SELECT_CATEGORY';

export const REMOVE_CATEGORY_ALL = 'REMOVE_CATEGORY_ALL';

export const CATEGORY_SELECT_ITEM_ID = 'CATEGORY_SELECT_ITEM_ID';

export const CATEGORY_REMOVE_SELECT_ITEM = 'CATEGORY_REMOVE_SELECT_ITEM';

export const CATEGORY_REMOVE_SELECT_ITEMS_ALL =
  'CATEGORY_REMOVE_SELECT_ITEMS_ALL';

export const CATEGORY_SELECT_ALL_ITEMS = 'CATEGORY_SELECT_ALL_ITEMS';

export const ADD_PROMOTION_OPTION = 'ADD_PROMOTION_OPTION';

export const DELETE_PROMOTION_OPTION = 'DELETE_PROMOTION_OPTION';

export const ALL_DELETE_PROMOTION_OPTION = 'ALL_DELETE_PROMOTION_OPTION';

export const HEARDER = 'HEADER';

export const SELECT_EXHIBITION = 'SELECT_EXHIBITION';

export const EXIHIBITION_LAST_SEARCH_DATA = 'EXIHIBITION_LAST_SEARCH_DATA';

export const SET_INVALID_URLS = 'SET_INVALID_URLS';

export const RESET_INVALID_URLS = 'RESET_INVALID_URLS';

export const EXIHIBITION_CURRENT_PAGE_NUM = 'EXIHIBITION_CURRENT_PAGE_NUM';

export const UPDATE_PROMOTION_SEARCH_OPTION = 'UPDATE_PROMOTION_SEARCH_OPTION';
export const INIT_PROMOTION_SEARCH_OPTION = 'INIT_PROMOTION_SEARCH_OPTION';
