import React from 'react';
import Button from 'react-bootstrap/Button';

const DateButtonBox = ({
  handleToday,
  handleWeek,
  handle1Month,
  handel3Month,
  handleFullDate,
}) => (
  <div style={{ float: 'right' }}>
    <Button
      style={{ marginTop: '20px' }}
      variant="outline-secondary"
      onClick={handleToday}
    >
      오늘
    </Button>{' '}
    <Button
      style={{ marginTop: '20px' }}
      variant="outline-secondary"
      onClick={handleWeek}
    >
      일주일
    </Button>{' '}
    <Button
      style={{ marginTop: '20px' }}
      variant="outline-secondary"
      onClick={handle1Month}
    >
      1개월
    </Button>{' '}
    <Button
      style={{ marginTop: '20px' }}
      variant="outline-secondary"
      onClick={handel3Month}
    >
      3개월
    </Button>{' '}
    <Button
      style={{ marginTop: '20px' }}
      variant="outline-secondary"
      onClick={handleFullDate}
    >
      전체
    </Button>{' '}
  </div>
);

export default DateButtonBox;
