import React from 'react';
import * as style from './style';

const TableItem = ({ title, value, valueColor }) => {
  return (
    <style.Wrapper>
      <style.Title>{title}</style.Title>
      <style.Value color={valueColor}>{value}</style.Value>
    </style.Wrapper>
  );
};

export default TableItem;
