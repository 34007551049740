import { Spinner } from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useSWR from 'swr';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { DashboardCard, DashboardDiff } from '../DashboardService/components';
import koKR from 'antd/lib/locale/ko_KR';
// import { WeekData, MonthData, Graph, DayData } from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Channel, From, Marketing, MarketingGraph, Standard, To, UTM } from './types';
import { channels } from './helper';
import { DatePicker, Select } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import moment from 'moment';

dayjs.extend(weekOfYear);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions<any> = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (item) => {
          return Number(item.raw).toLocaleString();
        },
      },
    },
  },
};

const getLineChartData = (
  label: string,
  data?: { date: string; value: number }[]
): ChartData<'line', number[], string> => {
  return {
    labels: data?.map((data) => data.date) ?? [],
    datasets: [
      {
        label,
        data: data?.map((data) => data.value) ?? [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 6,
      },
    ],
  };
};

const request = axios.create({
  baseURL:
    window.location.origin === 'https://admin.fetching.work'
      ? 'https://admin.fetchingapp.co.kr'
      : 'https://testapi.fetchingapp.co.kr',
});
const fetcher = <T,>(url: string) => request.get<T>(url).then((res) => res.data);
const getErrorDescription = (error: any) => {
  switch (error?.response?.status) {
    case 404:
      return `서버에 정보가 없습니다. (${error?.response?.status})`;
    case 401:
    case 403:
      return `권한이 부족합니다. (${error?.response?.status})`;
    case 500:
      return `서버 내부 에러 (${error?.response?.status})`;
    default:
      return `잠시 후 다시 시도해주세요. (${error?.response?.status})`;
  }
};

const DashboardMarketing: React.FC = ({}) => {
  const [from, setFrom] = useState<From | null>(
    dayjs().subtract(7, 'd').format('YYYY-MM-DD')
  );
  const [to, setTo] = useState<To | null>(dayjs().format('YYYY-MM-DD'));
  const [standard, setStandard] = useState<Standard>('WEEK');

  const [channel, setChannel] = useState<Channel>('fb');
  const [utm, setUtm] = useState<UTM>(null);

  const { data: marketingData, error: marketingError } = useSWR<Marketing>(
    () =>
      `/dashboard/marketing?standard=${standard}${
        from ? `&minDate=${dayjs(from).toISOString()}` : ''
      }${to ? `&maxDate=${dayjs(to).toISOString()}` : ''}`,
    fetcher,
    { shouldRetryOnError: false }
  );
  const { data: graphData, error: graphError } = useSWR<MarketingGraph>(
    `/dashboard/marketing/graph?channel=${channel}${utm ? `&term=${utm}` : ''}`,
    fetcher,
    { shouldRetryOnError: false }
  );

  useEffect(() => {
    marketingError &&
      toast.error(
        `마케팅 정보를 가져오는데 실패했습니다. ${getErrorDescription(marketingError)}`
      );
  }, [marketingError]);

  useEffect(() => {
    graphError &&
      toast.error(
        `마케팅 그래프 정보를 가져오는데 실패했습니다. ${getErrorDescription(graphError)}`
      );
  }, [graphError]);

  console.log({ marketingData, graphData, from, to, standard, channel, utm });

  return (
    <div>
      <div className="grid grid-cols-2 m-4 gap-4 relative">
        {!((marketingData || marketingError) && (graphData || graphError)) && (
          <div className="absolute w-full h-full opacity-60 flex items-center justify-center bg-white">
            <Spinner className="h-10 w-10 text-black" />
          </div>
        )}
        <DashboardCard
          span={2}
          title={
            <div className="flex gap-5 items-center">
              <DatePicker.RangePicker
                allowEmpty={[true, true]}
                defaultValue={[moment(from), moment(to)]}
                locale={koKR.DatePicker}
                onChange={(_, [from, to]) => {
                  console.log({ _, from, to });

                  setFrom(from);
                  setTo(to);
                }}
              />
              <div style={{ width: 250, display: 'flex' }}>
                <Select
                  className="w-full"
                  onChange={(standard: Standard) => {
                    setStandard(standard);
                  }}
                  value={standard}
                >
                  <Select.Option value="WEEK">7일내 첫유입 기준</Select.Option>
                  <Select.Option value="RECENT">결제 직전 유입 기준</Select.Option>
                </Select>
              </div>
            </div>
          }
        >
          <div>
            <table className="w-full">
              <thead>
                <tr>
                  <th>채널</th>
                  <th className="text-center">유입수</th>
                  <th className="text-center">구매전환율</th>
                  <th className="text-center">전환금액</th>
                  <th className="text-center">광고비</th>
                  <th className="text-center">ROAS</th>
                </tr>
              </thead>
              <tbody>
                {marketingData?.data.map((channel) => (
                  <tr>
                    <td>{channel.channelName}</td>
                    <td className="text-center">
                      <DashboardDiff
                        from={channel.inflowCountBefore}
                        to={channel.inflowCount}
                        unit="명"
                      />
                    </td>
                    <td className="text-center">
                      <DashboardDiff
                        from={channel.conversionRateBefore}
                        to={channel.conversionRate}
                        unit="%"
                      />
                    </td>
                    <td className="text-center">
                      <DashboardDiff
                        from={channel.conversionAmountBefore}
                        to={channel.conversionAmount}
                      />
                    </td>
                    <td className="text-center">
                      <DashboardDiff
                        from={channel.marketingBudgetBefore}
                        to={channel.marketingBudget}
                      />
                    </td>
                    <td className="text-center">
                      <DashboardDiff
                        from={channel.roasBefore}
                        to={channel.roas}
                        unit="%"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="italic text-sm text-gray-400 mt-4">
            *빨강, 초록으로 표시된 숫자는 일주일 전 대비 증감을 나타내는 수치입니다.
          </div>
        </DashboardCard>
        <DashboardCard title="채널별 성과 추이" span={2}>
          <div>
            <div className="flex gap-5 items-center">
              <div style={{ width: 250 }}>
                <Select
                  className="w-full"
                  value={channel}
                  onChange={(channel) => {
                    setChannel(channel);
                  }}
                >
                  {channels.map((channel) => (
                    <Select.Option key={channel.id} value={channel.id}>
                      {channel.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ width: 250 }}>
                <Select
                  className="w-full"
                  value={utm}
                  onChange={(utm) => {
                    setUtm(utm);
                  }}
                >
                  <Select.Option value={null}>전체 (utm_term)</Select.Option>
                </Select>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10" style={{ marginTop: 80 }}>
              <div>
                <Line
                  options={options}
                  data={getLineChartData('전환 금액 추이', graphData?.conversionAmounts)}
                />
              </div>
              <div>
                <Line
                  options={options}
                  data={getLineChartData('ROAS 추이', graphData?.conversionAmounts)}
                />
              </div>
            </div>
          </div>
        </DashboardCard>
      </div>
    </div>
  );
};

export default DashboardMarketing;
