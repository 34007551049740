import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
`;

export const Inner = styled.div`
  width: 70%;
  @media all and (min-width: 800px) {
    width: 30%;
  }
`;
