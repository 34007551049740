import styled from 'styled-components';

export const BodyTableData = styled.td.attrs({ valign: 'top' })`
  border-right: 0.5px solid #b4b4b4;
  text-align: center;
  padding: 8px;
  word-break: break-all;
  vertical-align: top;
  &:last-child {
    border-right: 0;
  }
`;

export const HeadTableData = styled.td`
  border-right: 0.5px solid #b4b4b4;
  padding: 8px 30px;
  font-size: 12px;
  color: #989595;
  text-align: center;
  &:last-child {
    border-right: 0;
  }
`;
