import styled from "styled-components";

export const Container = styled.div`
display: flex;
margin-bottom: 10px;
align-items: center;
`;

export const Input = styled.input`
width: 120px;
height: 32px;
margin-right: 15px;
padding-left: 5px;
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 5px 0 0;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
  user-select: none;
`;

export const A = styled.span`
color: #007bff;
cursor: pointer;
&:hover{
  text-decoration: underline;
}

margin-right: 15px;
`;

export const ResetButton = styled.button`
  border: none;
  background: #dddddd;
  border-radius: 5px;
  padding: 5px 10px;
`;