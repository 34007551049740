import { message, Switch, TreeSelect } from 'antd';
import fetchingAPI from 'api/fetchingAPI';
import { DropZoneImage, Outer, ShadowContainer } from 'components';
import ManageBandBanner, {
  BandBannerProps,
  BandBannerTypes,
} from 'components/templates/ManageComponentContent/ManageBandBanner';
import { MakeTable } from 'pages/AddPromotion/utils';
import React, { FC, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import * as S from './style';

type Types =
  | 'DESIGN_COMPONENT_WITH_IMAGE'
  | 'DESIGN_COMPONENT_WITHOUT_IMAGE'
  | 'DESIGN_COMPONENT_WITH_IMAGE_CATEGORY'
  | 'DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY';
type Content = {
  name: string;
  title: string;
  subtitle: string;
  href: string;
  imageUrl?: string;
};
const dict: any = {
  name: '컴포넌트명',
  title: '타이틀',
  subtitle: '본문 텍스트',
  imageUrl: '이미지 첨부',
  href: '링크',
};
const isImageType = (type: Types): boolean =>
  ['DESIGN_COMPONENT_WITH_IMAGE', 'DESIGN_COMPONENT_WITH_IMAGE_CATEGORY'].includes(type);
const AddComponent = () => {
  const [type, setType] = useState<Types>('DESIGN_COMPONENT_WITH_IMAGE');
  const [content, setContent] = useState<Content>({
    name: '',
    title: '',
    subtitle: '',
    imageUrl: '',
    href: '',
  });

  const history = useHistory();

  const addComponent = () => {
    const emptyFields = Object.entries(content)
      .filter(([k, v]) => (k === 'imageUrl' || k === 'href' ? false : v === ''))
      .reduce((acc: string[], [k]) => {
        acc.push(dict[k]);
        return acc;
      }, []);

    if (emptyFields.length) {
      alert(`${emptyFields.join(`\n`)}\n\n위 항목을 입력해주세요!`);
    } else {
      const gender = history.location.pathname.includes('/m/') ? 'men' : 'women';
      fetchingAPI
        .postUIComponents(gender, [
          { ...content, type, requestType: 'CREATE', isActive: false },
        ])
        .then(() => {
          if (type === 'DESIGN_COMPONENT_WITHOUT_IMAGE') {
            alert(
              '텍스트형 컴포넌트는 상품을 반드시 설정해야 합니다. 컴포넌트 관리 탭에서 상품을 추가해주세요.'
            );
          }
        })
        .then(() => history.goBack())
        .catch((e) => alert(`err in addCopmonent, ${e}`));
    }
  };

  const tables = [
    {
      title: '레이아웃',
      content: [
        {
          left: '컴포넌트명*',
          right: (
            <>
              <S.TextArea
                placeholder="어드민용 컴포넌트 이름입니다."
                value={content.name}
                onChange={({ target: { value } }) =>
                  setContent((p) => ({ ...p, name: value }))
                }
              />
            </>
          ),
        },
        {
          left: '컴포넌트 타입*',
          right: (
            <>
              <S.Label>
                <S.Radio
                  checked={type === 'DESIGN_COMPONENT_WITH_IMAGE'}
                  onChange={() => {
                    setType('DESIGN_COMPONENT_WITH_IMAGE');
                    setContent({ ...content, imageUrl: '' });
                  }}
                />
                이미지형 컴포넌트
              </S.Label>
              <S.Label>
                <S.Radio
                  checked={type === 'DESIGN_COMPONENT_WITHOUT_IMAGE'}
                  onChange={() => {
                    setType('DESIGN_COMPONENT_WITHOUT_IMAGE');
                    setContent({ ...content, imageUrl: '' });
                  }}
                />
                텍스트형 컴포넌트
              </S.Label>
              <S.Label>
                <S.Radio
                  checked={type === 'DESIGN_COMPONENT_WITH_IMAGE_CATEGORY'}
                  onChange={() => {
                    setType('DESIGN_COMPONENT_WITH_IMAGE_CATEGORY');
                    setContent({ ...content, imageUrl: '' });
                  }}
                />
                이미지형 탭 컴포넌트
              </S.Label>
              <S.Label>
                <S.Radio
                  checked={type === 'DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY'}
                  onChange={() => {
                    setType('DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY');
                    setContent({ ...content, imageUrl: '' });
                  }}
                />
                텍스트형 탭 컴포넌트
              </S.Label>
            </>
          ),
        },
      ],
    },
    {
      title: '컨텐츠',
      content: [
        {
          left: '타이틀*',
          right: (
            <>
              <S.TextArea
                placeholder="최대 영문 14바이트 입력 가능합니다"
                value={content.title}
                onChange={({ target: { value } }) =>
                  setContent((p) => ({ ...p, title: value }))
                }
              />
            </>
          ),
        },
        {
          left: '본문 텍스트*',
          right: (
            <>
              <S.TextArea
                placeholder={
                  isImageType(type)
                    ? '최대 한글 30글자 (최대 60바이트) 입력 가능합니다. "[개행]"을 입력하여 줄넘김을 설정할 수 있습니다'
                    : '최대 한글 15글자 (최대 30바이트) 입력 가능합니다.'
                }
                value={content.subtitle}
                onChange={({ target: { value } }) =>
                  setContent((p) => ({ ...p, subtitle: value }))
                }
              />
            </>
          ),
        },
        {
          left: '이미지 첨부',
          right: (
            <>
              {isImageType(type) ? (
                <DropZoneImage
                  imageUrl={content.imageUrl}
                  setImageUrl={(v: string) => setContent((p) => ({ ...p, imageUrl: v }))}
                />
              ) : (
                '해당 없음'
              )}
            </>
          ),
        },
        {
          left: '링크',
          right: (
            <>
              <S.TextArea
                value={content.href}
                placeholder="/index.html 형식으로 fetching.co.kr를 제외해주세요"
                onChange={({ target: { value } }) =>
                  setContent((p) => ({ ...p, href: value }))
                }
              />
            </>
          ),
        },
      ],
    },
  ];

  console.table([content]);

  return (
    <Outer style={S.OuterStyle}>
      <ShadowContainer>
        <h2 className="mb-6 text-xl">컴포넌트 추가</h2>
        {tables.map((data) => (
          <MakeTable data={data} key={data.title} tableRef={null} />
        ))}
      </ShadowContainer>
      <ShadowContainer>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
          <Button variant="success" onClick={addComponent}>
            컴포넌트 생성
          </Button>
          <Button variant="danger" onClick={() => history.goBack()}>
            닫기
          </Button>
        </div>
      </ShadowContainer>
    </Outer>
  );
};

export default AddComponent;

export const AddBandBanner: React.FC<{ mode: 'm' | 'w' }> = ({ mode }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const state = useState<BandBannerProps>({
    name: '',
    type: BandBannerTypes.text,
    duration: false,
    start: null,
    end: null,
    backgroundColor: null,
    mobileImageUrl: null,
    pcImageUrl: null,
    title: null,
    href: null,
  });

  const ValidityCheck = (data: BandBannerProps) => {
    const {
      type,
      duration,
      start,
      end,
      backgroundColor,
      mobileImageUrl,
      pcImageUrl,
      title,
      href,
    } = data;
    if (duration && (!end || !start)) {
      alert('게시 기간을 설정해주세요.');
      return false;
    }
    if (
      (type === BandBannerTypes.text || type === BandBannerTypes.textRolling) &&
      !backgroundColor
    ) {
      alert('배경 컬러를 지정해주세요.');
      return false;
    }
    if (
      (type === BandBannerTypes.text || type === BandBannerTypes.textRolling) &&
      !title
    ) {
      alert('타이틀을 지정해주세요.');
      return false;
    }
    if (!href) {
      alert('링크를 지정해주세요.');
      return false;
    }
    if (type === BandBannerTypes.image && !(mobileImageUrl && pcImageUrl)) {
      alert('모바일, pc 이미지를 모두 지정해주세요.');
      return false;
    }
    // 임시 기능
    if (type !== BandBannerTypes.text) {
      alert('현재는 텍스트형 배너만 가능합니다.');
      return false;
    }
    return true;
  };

  const onAdd = async () => {
    if (!isLoading && ValidityCheck(state[0])) {
      setIsLoading(true);
      const res = await fetchingAPI.postUIComponents(mode === 'm' ? 'men' : 'women', [
        {
          ...getPayload(state[0]),
          requestType: 'CREATE',
          isActive: false,
        },
      ]);
      if (res.data?.success) {
        setIsLoading(false);
        alert('생성했습니다.');
        history.goBack();
      }
    }
  };
  return (
    <Outer style={S.OuterStyle}>
      <ShadowContainer>
        <h2 className="mb-6 text-xl">띠배너 추가</h2>
        <ManageBandBanner state={state} />
      </ShadowContainer>
      <ShadowContainer>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
          <Button variant="success" onClick={onAdd}>
            {isLoading ? <Spinner animation="border" /> : '컴포넌트 생성'}
          </Button>
          <Button variant="danger" onClick={() => history.goBack()}>
            닫기
          </Button>
        </div>
      </ShadowContainer>
    </Outer>
  );
};

const getPayload = (state: BandBannerProps) => ({
  name: state.name,
  type: state.type,
  ...(state.duration && { startAt: state.start, endAt: state.end }),
  ...((state.type === BandBannerTypes.text ||
    state.type === BandBannerTypes.textRolling) && {
    backgroundColor: state.backgroundColor,
    content: state.title,
  }),
  ...(state.type === BandBannerTypes.image && {
    mobileImageUrl: state.mobileImageUrl,
    pcImageUrl: state.pcImageUrl,
  }),
  href: state.href,
});
