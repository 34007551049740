import styled from 'styled-components';

export const Container = styled.div``;
export const Input = styled.select``;
export const Button = styled.button`
  margin-right: 10px;
  border: none;
  color: white;
  padding: 3px 10px;
  border-radius: 2px;
`;
