import React from 'react';
import * as style from './style';

const Tile = ({ title = '', description = '' }) => {
  return (
    <style.Wrapper>
      <style.Title>{title || ''}</style.Title>
      <style.TitleDescrip>{description}</style.TitleDescrip>
    </style.Wrapper>
  );
};
export default Tile;
