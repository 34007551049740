import styled from "styled-components";


export const Outer = styled.div`
  width: 100%;
  padding: 0 22px 100px 22px;
  `;
export const ShadowContainer = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 22px 0;
  padding: 22px;
  `;

export const TableContainer = styled.div`
display: flex;
flex-direction: column;
`;
export const TableElement = styled.div`
  padding:10px 20px;
  justify-items: start;
  align-items: center;
  display: grid;
  grid-template-columns: 80px 80px 110px 80px 2fr 3fr;  
  font: 13px;
  `;