import React from 'react';
import { FiAlertCircle, FiCheck } from 'react-icons/fi';
import * as style from './style';

const Progress = ({ progressData }) => {
  const { isActive, isFixedAtTop } = progressData;

  return (
    <style.ItemBox>
      {!!isActive ? (
        <style.Item isActive="active">
          <FiCheck />
          활성화
        </style.Item>
      ) : (
        <style.Item isActive="disable">
          <FiCheck />
          비활성화
        </style.Item>
      )}
      {!!isFixedAtTop ? (
        <style.Item isActive="alert">
          <FiAlertCircle /> 중요
        </style.Item>
      ) : (
        <style.Item isActive="disable">보통</style.Item>
      )}
    </style.ItemBox>
  );
};

export default Progress;
