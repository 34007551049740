import React from 'react';
import { BrandMapTableItem } from '../../molecules';
import * as style from './style';

const BrandMappingTable = ({
  items,
  selectedItems,
  handleCheckedItem,
  handleIsAllChecked,
}) => {
  const titleList = ['매핑여부', '편집샵 브랜드', '페칭 브랜드'];

  return (
    <style.TableWrapper>
      <style.TableTitle>
        <style.Checkbox onChange={handleIsAllChecked} />
        {titleList.map((title) => (
          <style.Title key={title}>{title}</style.Title>
        ))}
      </style.TableTitle>
      <style.TableItemWrapper>
        {items.map((item, i) => {
          const id = item.mapId || item.semiName;
          return (
            <BrandMapTableItem
              key={id + 'uniq' + i}
              id={id}
              item={item}
              onChangeIsChecked={handleCheckedItem}
              selectedItems={selectedItems}
            />
          );
        })}
      </style.TableItemWrapper>
    </style.TableWrapper>
  );
};
export default BrandMappingTable;
