import React, { useCallback, useEffect, useState, useRef, useContext } from 'react';
import { ModalContext, LoadingContext } from 'context';
import querystring from 'querystring';
import url from 'url';
import _ from 'lodash';
import {
  ClassificationModal,
  Loading,
  ProductInfo,
  ClassificationInfo,
  PriceInfo,
  SetDisplay,
} from 'components';
import axios from 'api/axios';
import fetchingAPIDef, { fetchingAPI } from 'api/fetchingAPI';
import { getItemDetailHistory } from 'api/fetchingLog';
import Header from './Header';
import * as style from './style';
import HeaderBanner from './HeaderBanner';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

const getUrlQuery = (field) =>
  querystring.parse(url.parse(window.location.href).query)[field];
const MODE = {
  CREATE: 'create',
  UPDATE: 'update',
};

export default function ProductDetail() {
  const itemId = Number(window.location.pathname.split('/')[3]);
  const mode = getUrlQuery('mode');

  const { modal, handleModalShow } = useContext(ModalContext);
  const { isLoading, handleIsLoading } = useContext(LoadingContext);
  const topRef = useRef(null);
  const displayRef = useRef(null);
  const classificationRef = useRef(null);
  const productRef = useRef(null);
  const priceRef = useRef(null);

  const [product, setProduct] = useState(null);
  const [prices, setPrices] = useState(null);
  const [agencyFeeRate, setAgencyFeeRate] = useState(null);
  const [curruntTarget, setCurrnetTarget] = useState('진열설정');
  const [isAutoRefresh, setIsAutoRefresh] = useState(false);
  const { replace } = useHistory();

  const [display, setDisplay] = useState({
    isVisibleApp: 0,
    isCafe24Uploaded: 0,
    isVerify: 0,
    isNaverUpload: 0,
  });

  const [productInfo, setProductInfo] = useState({
    itemPriority: '',
    itemName: '',
    itemDescription: '',
    images: null,
    color: '',
    gender: '',
    code: '',
    sizes: [
      {
        id: +new Date(),
        name: '',
        quantity: 0,
        needOptionPrice: false,
        optionPrice: 0,
      },
    ],
    history: {},
  });

  const [categoryState, setCategoryState] = useState({
    initial: [],
    deleteItems: [],
    addItems: [],
  });

  const [exhibitionState, setExhibitionState] = useState({
    initial: [],
    deleteItems: [],
    addItems: [],
  });

  const [promotionState, setPromotionState] = useState({
    initial: [],
    deleteItems: [],
    addItems: [],
  });

  const [category, setCategory] = useState(null);
  const [exhibitions, setExhibitions] = useState(null);
  const [shopPromotions, setShopPromotions] = useState(null);

  const handleAddCategoty = (addCategories) => {
    if (addCategories) {
      const result = [];
      addCategories.forEach((addEl) => {
        const index = category.findIndex(
          (el) => el.fetchingCategoryId === addEl.fetchingCategoryId
        );
        if (index === -1) {
          result.push(addEl);
        }
      });

      const addResult = [];
      addCategories.forEach((addEl) => {
        const index = categoryState.initial.findIndex(
          (el) => el.fetchingCategoryId === addEl.fetchingCategoryId
        );
        if (index === -1) {
          addResult.push(addEl);
        }
      });

      let deleteResult = categoryState.deleteItems;
      addCategories.forEach((addEl) => {
        const initialIndex = categoryState.initial.findIndex(
          (el) => el.fetchingCategoryId === addEl.fetchingCategoryId
        );

        const deleteIndex = categoryState.deleteItems.findIndex(
          (el) => el.fetchingCategoryId === addEl.fetchingCategoryId
        );

        if (initialIndex !== -1 && deleteIndex !== 1) {
          deleteResult = categoryState.deleteItems.filter(
            (el) => el.fetchingCategoryId !== addEl.fetchingCategoryId
          );
        }
      });

      setCategoryState({
        ...categoryState,
        addItems: [...categoryState.addItems, ...addResult],
        deleteItems: deleteResult,
      });

      setCategory([...category, ...result]);
    }
  };

  const handleAddShopPromotions = (addPromotions) => {
    if (addPromotions) {
      const result = [];
      addPromotions.forEach((addEl) => {
        const index = shopPromotions.findIndex((el) => el.shopPromotionId === addEl.id);
        if (index === -1) {
          result.push({
            shopPromotionId: addEl.id,
            name: addEl.name,
            isAppliedWith: addEl.isAppliedWith,
            adminMemo: addEl.adminMemo,
            code: addEl.code,
            url: addEl.url,
          });
        }
      });

      const addResult = [];
      addPromotions.forEach((addEl) => {
        const index = promotionState.initial.findIndex(
          (el) => el.shopPromotionId === addEl.id
        );
        if (index === -1) {
          addResult.push(addEl);
        }
      });

      let deleteResult = promotionState.deleteItems;
      addPromotions.forEach((addEl) => {
        const initialIndex = promotionState.initial.findIndex(
          (el) => el.shopPromotionId === addEl.id
        );

        const deleteIndex = promotionState.deleteItems.findIndex(
          (el) => el.shopPromotionId === addEl.id
        );

        if (initialIndex !== -1 && deleteIndex !== 1) {
          deleteResult = promotionState.deleteItems.filter(
            (el) => el.shopPromotionId !== addEl.id
          );
        }
      });

      setPromotionState({
        ...promotionState,
        addItems: [...promotionState.addItems, ...addResult],
        deleteItems: deleteResult,
      });

      setShopPromotions([...shopPromotions, ...result]);
    }
  };

  const handleAddExhibitions = (addExhibitions) => {
    if (addExhibitions) {
      const result = [];

      addExhibitions.forEach((addEl) => {
        const index = exhibitions.findIndex(
          (el) => el.exhibitionId === addEl.exhibitionId
        );
        if (index === -1) {
          result.push(addEl);
        }
      });

      const addResult = [];

      addExhibitions.forEach((addEl) => {
        const initialIndex = exhibitionState.initial.findIndex(
          (el) => el.exhibitionId === addEl.exhibitionId
        );
        const addIndex = exhibitionState.addItems.findIndex(
          (el) => el.exhibitionId === addEl.exhibitionId
        );

        if (initialIndex === -1 && addIndex === -1) {
          addResult.push(addEl);
        }
      });

      setExhibitionState({
        ...exhibitionState,
        addItems: [...exhibitionState.addItems, ...addResult],
      });

      setExhibitions([...exhibitions, ...result]);
    }
  };

  const handleDeleteCategory = (id) => {
    const result = category.filter((el) => el.fetchingCategoryId !== id);

    const deleteItem = categoryState.initial.find((el) => el.fetchingCategoryId === id);

    let items = categoryState.deleteItems;

    if (deleteItem) {
      items = categoryState.deleteItems.filter(
        (el) => el.fetchingCategoryId !== deleteItem.fetchingCategoryId
      );

      items.push(deleteItem);
    }

    const resultAddItems = categoryState.addItems.filter(
      (el) => el.fetchingCategoryId !== id
    );

    setCategoryState({
      ...categoryState,
      addItems: resultAddItems,
      deleteItems: items,
    });

    setCategory(result);
  };

  const handleDeleteExhibition = (id) => {
    const result = exhibitions.filter((el) => el.exhibitionId !== id);

    const deleteItem = exhibitionState.initial.find((el) => el.exhibitionId === id);

    let items = exhibitionState.deleteItems;

    if (deleteItem) {
      items = exhibitionState.deleteItems.filter((el) => el.exhibitionId !== id);

      items.push(deleteItem);
    }

    const resultAddItems = exhibitionState.addItems.filter(
      (el) => el.exhibitionId !== id
    );

    setExhibitionState({
      ...exhibitionState,
      addItems: resultAddItems,
      deleteItems: items,
    });

    setExhibitions(result);
  };

  const handleDeleteShopPromotion = (id) => {
    const result = shopPromotions.filter((el) => el.shopPromotionId !== id);

    const deleteItem = promotionState.initial.find((el) => el.shopPromotionId === id);

    let items = promotionState.deleteItems;

    if (deleteItem) {
      items = promotionState.deleteItems.filter((el) => el.id !== id);

      items.push(deleteItem);
    }

    const resultAddItems = promotionState.addItems.filter((el) => el.id !== id);

    setPromotionState({
      ...promotionState,
      addItems: resultAddItems,
      deleteItems: items,
    });

    setShopPromotions(result);
  };

  const handleChangeDisplay = (target, result) => {
    if (target === '앱 진열') {
      setDisplay({
        ...display,
        isVisibleApp: result,
      });
    } else if (target === '카페24 업로드') {
      setDisplay({
        ...display,
        isCafe24Uploaded: result,
      });
    } else if (target === '카페24 판매 & 진열') {
      setDisplay({
        ...display,
        isVerify: result,
      });
    } else {
      setDisplay({
        ...display,
        isNaverUpload: result,
      });
    }
  };

  const getProductData = useCallback(async () => {
    try {
      const res = await axios.get(`/api/items/detail/${itemId}`);
      const itemHistory = await getItemDetailHistory(itemId);
      const { data } = res;

      const images = data.imageMap.map((image) => {
        const result = {};
        result.url = image.itemImageUrl;
        return result;
      });

      setProduct(data);
      if (mode !== MODE.CREATE) {
        setDisplay({
          isVisibleApp: data.isVisibleApp,
          isCafe24Uploaded: data.isCafe24Uploaded,
          isVerify: data.isVerify,
          isNaverUpload: data.isNaverUpload,
        });

        setExhibitionState({
          ...exhibitionState,
          initial: data.exhibition,
        });

        setPromotionState({
          ...promotionState,
          initial: data.promotion,
        });

        setExhibitions(data.exhibition);
        setShopPromotions(data.promotion);
      }

      setProductInfo({
        ...productInfo,
        itemPriority: data.itemPriority,
        itemName: data.itemName,
        itemDescription: data.itemDescription,
        color: data.color || '',
        images,
        history: itemHistory,
      });

      setCategoryState({
        ...categoryState,
        initial: data.category,
      });

      setCategory(data.category);
    } catch (err) {
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      /api/items/detail/${itemId} 
      ${err}`);
      console.error(err);
    }
  }, [product]);

  const getPriceData = useCallback(async () => {
    const { data } = await fetchingAPIDef.getPriceData(itemId);
    setPrices(data);
  });

  const handleModifyItem = async () => {
    try {
      handleIsLoading(true);

      await axios.patch(`/api/items/${itemId}/display`, {
        item_id: itemId,
        isVisibleApp: display.isVisibleApp,
      });

      const payload = {
        name: productInfo.itemName,
        color: productInfo.color,
        priority: Number(productInfo.itemPriority),
      };

      if (agencyFeeRate != null) {
        payload.agencyFeeRate = agencyFeeRate;
      }

      await fetchingAPI.put(`/item/${itemId}`, payload);

      if (categoryState.addItems.length) {
        await addItemCategory(itemId);
      }

      if (categoryState.deleteItems.length) {
        await deleteItemCategory(itemId);
      }

      if (exhibitionState.addItems.length) {
        const result = exhibitionState.addItems.map((el) => el.exhibitionId);
        result.forEach(async (id) => {
          await axios.post('/api/item/exhibition', {
            itemIDs: [itemId],
            exhibitionID: Number(id),
          });
        });
      }

      if (exhibitionState.deleteItems.length) {
        const result = exhibitionState.deleteItems.map((el) => el.exhibitionId);
        result.forEach(async (id) => {
          await axios.delete('/api/item/exhibition', {
            data: {
              itemIDs: [itemId],
              exhibitionID: Number(id),
            },
          });
        });
      }

      if (promotionState.addItems.length) {
        const result = promotionState.addItems.map((el) => el.id);
        result.forEach(async (id) => {
          await axios.put(`/api/promotions/${id}/items/bulk`, [itemId]);
        });
      }

      if (promotionState.deleteItems.length) {
        const result = promotionState.deleteItems.map((el) => el.shopPromotionId);
        result.forEach(async (id) => {
          await axios.delete(`/api/promotions/${id}/items/bulk`, {
            data: [itemId],
          });
        });
      }
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      ProductDetail 수정 관련 api 확인 요망 (9개)
      ${err}`);
      console.error(err);
    } finally {
      setTimeout(() => {
        handleIsLoading(false);
        window.location.reload();
      }, 1000);
    }
  };

  const handleCafe24Upload = async () => {
    try {
      handleIsLoading(true);
      await axios.post('/api/util/cafe24-update', {
        itemIDs: [itemId],
        dataProcess: true,
      });
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      /api/util/cafe24-update
      ${err}`);
      console.error(err);
      alert(err);
    } finally {
      handleIsLoading(false);
    }
  };

  const onCancel = () => {
    replace(`/product/productList`);
  };

  const scrollToRef = (ref) => {
    window.scrollTo(0, ref.current.offsetTop - topRef.current.offsetTop);
  };

  const handleChangeCurrnetMenu = (target, ref) => {
    setCurrnetTarget(target);
    scrollToRef(ref);
  };

  const addItemCategory = async (id) => {
    const result = categoryState.addItems.map((el) => el.fetchingCategoryId);
    await axios.post('/api/item/category-maps/bulk', {
      itemIDs: [`${id}`],
      categories: result,
    });
  };

  const deleteItemCategory = async (id) => {
    const result = categoryState.deleteItems.map((el) => el.fetchingCategoryId);
    await axios.delete('/api/item/category-maps/bulk', {
      data: {
        itemIDs: [`${id}`],
        categories: result,
      },
    });
  };

  const onCreate = async () => {
    handleIsLoading(true);
    try {
      const params = {
        brand: product.shopBrandName,
        code: productInfo.code,
        discountedPrice: Number(prices[0].discountedPrice),
        gender: productInfo.gender || 'M',
        imageUrls: productInfo.images.map((image) => image.url),
        name: productInfo.itemName,
        originPrice: Number(prices[0].originPrice),
        shopId: 6,
        sizes: productInfo.sizes?.map((size) => ({
          name: size.name,
          quantity: size.quantity,
          optionPrice: size.optionPrice,
        })),
        url: '',
        color: productInfo.color,
        description: productInfo.itemDescription,
        replicaOf: isAutoRefresh ? product.fetchingCode : null,
      };

      const checkSize = _.findIndex(params.sizes, (size) => size.name === '');

      if (checkSize > -1) {
        return alert('사이즈 정보가 누락되었습니다.');
      }

      // create Item
      const { data } = await fetchingAPIDef.createItem(params);
      if (data) {
        // category update
        if (categoryState.addItems.length) {
          await addItemCategory(data.id);
        }

        if (categoryState.deleteItems.length) {
          await deleteItemCategory(data.id);
        }
      }

      toast.success('수정되었습니다.');
      handleIsLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      handleIsLoading(false);
    }
  };

  useEffect(() => {
    getProductData();
    getPriceData();
  }, []);

  return (
    <style.Wrapper>
      {isLoading && <Loading />}
      <ClassificationModal
        show={modal.categories}
        title="카테고리"
        onHide={(result) => {
          handleModalShow('카테고리', false);
          handleAddCategoty(result);
        }}
      />
      <ClassificationModal
        show={modal.exhibitions}
        title="기획전"
        onHide={(result) => {
          handleModalShow('기획전', false);
          handleAddExhibitions(result);
        }}
      />

      <ClassificationModal
        show={modal.promotions}
        title="프로모션"
        onHide={(result) => {
          handleModalShow('프로모션', false);
          handleAddShopPromotions(result);
        }}
      />

      <style.HeaderTitle mode={MODE.CREATE}>
        <h1>상품 {mode === MODE.CREATE ? '생성' : '수정'}</h1>
      </style.HeaderTitle>

      <style.Header>
        {mode !== MODE.CREATE && <HeaderBanner product={product} />}
        <Header
          product={product}
          handleChangeCurrnetMenu={handleChangeCurrnetMenu}
          curruntTarget={curruntTarget}
          refs={[displayRef, classificationRef, productRef, priceRef]}
          mode={mode}
        />
      </style.Header>

      <style.Content ref={topRef}>
        {mode !== MODE.CREATE && (
          <SetDisplay
            product={display}
            scrollRef={displayRef}
            handleChangeDisplay={handleChangeDisplay}
          />
        )}
        <ClassificationInfo
          product={product}
          scrollRef={classificationRef}
          category={category}
          exhibitions={exhibitions}
          shopPromotions={shopPromotions}
          handleDeleteCategory={handleDeleteCategory}
          handleDeleteExhibition={handleDeleteExhibition}
          handleDeleteShopPromotion={handleDeleteShopPromotion}
          mode={mode}
        />
        <ProductInfo
          product={product}
          scrollRef={productRef}
          productInfo={productInfo}
          setProductInfo={setProductInfo}
          mode={mode}
          isAutoRefresh={isAutoRefresh}
          setIsAutoRefresh={setIsAutoRefresh}
        />
        {product && prices ? (
          <PriceInfo
            product={product}
            prices={prices}
            agencyFeeRate={agencyFeeRate}
            setPrices={(value) => setPrices(value)}
            setAgencyFeeRate={(value) => setAgencyFeeRate(value)}
            scrollRef={priceRef}
            mode={mode}
          />
        ) : null}
      </style.Content>
      <style.Footer>
        {mode === MODE.CREATE ? (
          <>
            <style.Button color="#69ABF9" onClick={onCreate}>
              상품생성
            </style.Button>
            <style.Button color="#FF6F61" onClick={onCancel}>
              취소
            </style.Button>
          </>
        ) : (
          <>
            <style.Button color="#69ABF9" onClick={handleCafe24Upload}>
              카페24 동기화
            </style.Button>
            <style.Button color="#FF6F61" onClick={handleModifyItem}>
              수정하기
            </style.Button>
          </>
        )}
      </style.Footer>
    </style.Wrapper>
  );
}
