import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';

const RouteList = [
  {
    path: '/product/promotionManagement',
    component: pages.PromotionManagement,
    exact: true,
  },
  {
    path: '/product/addPromotion',
    component: pages.AddPromotion,
  },
  {
    path: '/product/modifyPromotion/:promotionId',
    component: pages.ModifyPromotion,
  },
];

const Promotion = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => <route.component />}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Promotion;
