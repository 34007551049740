import React, { useEffect } from 'react';
import type { FC } from 'react';
import { Button, Card, InputNumber, ReInput } from 'components';
import { request, useObject } from 'services';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IPurchase {
  cardApprovalNumber: string;
  fetchingShopAccount: string;
  fetchingShopAccountId: number;
  priceRuleId: number;
  shopCountry: string;
  shopId: number;
  shopName: string;
  shopOrderNumber: string;
  vendorOrderNumber: string;
}

export interface Props {}
interface State {
  shopOrderList: IPurchase[];
  isUpdating: boolean;
  taxPayAmount: number;
  taxPayNumber: string;
}

const PurchaseInfo: FC<Props> = () => {
  const [{ shopOrderList, isUpdating, taxPayAmount, taxPayNumber }, setState, onChange] =
    useObject<State>({
      shopOrderList: [],
      isUpdating: false,
      taxPayAmount: 0,
      taxPayNumber: '',
    });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();

  const get = async () => {
    try {
      const [{ taxPayAmount, taxPayNumber }, { shopOrderList }] = await Promise.all([
        request.get<any, { taxPayAmount: number; taxPayNumber: string }>(
          `/commerce/order/fetching-order/${fetchingOrderNumber}/tax`,
          {
            headers: {
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          }
        ),
        request.get<any, { shopOrderList: IPurchase[] }>(
          `/commerce/order/fetching-order/${fetchingOrderNumber}/purchase`,
          {
            headers: {
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          }
        ),
      ]);
      setState({ taxPayAmount, taxPayNumber, shopOrderList });
    } catch (err) {
      console.log(err);
    }
  };

  const update = async () => {
    if (!window.confirm('해당 정보들로 수정하시겠습니까?')) return;

    setState({ isUpdating: true });
    try {
      await Promise.all([
        request.put(
          `/commerce/order/fetching-order/${fetchingOrderNumber}/tax`,
          {
            taxPayAmount: taxPayAmount || 0,
            taxPayNumber: taxPayNumber || '',
          },
          {
            headers: {
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          }
        ),
        request.put(
          `/commerce/order/fetching-order/${fetchingOrderNumber}/purchase`,
          {
            shopOrderList: shopOrderList.map((item) => ({
              cardApprovalNumber: item.cardApprovalNumber || '',
              vendorOrderNumber: item.vendorOrderNumber || '',
              fetchingShopAccountDirectInput: item.fetchingShopAccount || '',
              shopOrderNumber: item.shopOrderNumber || '',
            })),
          },
          {
            headers: {
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          }
        ),
      ]);
      toast.success('수정되었습니다.');
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isUpdating: false });
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      {shopOrderList.map((shopOrder, key) => (
        <Card
          key={key}
          title={`${shopOrder.shopName} (${shopOrder.shopCountry})`}
          items={[
            {
              name: '판매처 주문번호',
              renderItem: () => (
                <ReInput
                  className="w-full"
                  value={shopOrder.vendorOrderNumber}
                  onChange={(e) =>
                    setState({
                      shopOrderList: [
                        ...shopOrderList.slice(0, key),
                        {
                          ...shopOrder,
                          vendorOrderNumber: e.target.value,
                        },
                        ...shopOrderList.slice(key + 1),
                      ],
                    })
                  }
                />
              ),
              stretch: true,
            },
            {
              name: '편집샵 계정정보',
              renderItem: () => (
                <ReInput
                  className="w-full"
                  value={shopOrder.fetchingShopAccount}
                  onChange={(e) =>
                    setState({
                      shopOrderList: [
                        ...shopOrderList.slice(0, key),
                        { ...shopOrder, fetchingShopAccount: e.target.value },
                        ...shopOrderList.slice(key + 1),
                      ],
                    })
                  }
                />
              ),
              stretch: true,
            },
            {
              name: '카드 승인번호',
              renderItem: () => (
                <ReInput
                  className="w-full"
                  value={shopOrder.cardApprovalNumber}
                  onChange={(e) =>
                    setState({
                      shopOrderList: [
                        ...shopOrderList.slice(0, key),
                        {
                          ...shopOrder,
                          cardApprovalNumber: e.target.value,
                        },
                        ...shopOrderList.slice(key + 1),
                      ],
                    })
                  }
                />
              ),
              stretch: true,
            },
          ]}
        />
      ))}

      <Card
        title="관부가세 수정"
        items={[
          {
            name: '전자납부번호',
            renderItem: () => (
              <ReInput
                className="w-full"
                value={taxPayNumber}
                name="taxPayNumber"
                onChange={onChange}
              />
            ),
            stretch: true,
          },
          {
            name: '관부가세액',
            renderItem: () => (
              <InputNumber
                className="w-full"
                value={taxPayAmount}
                name="taxPayAmount"
                onChange={onChange}
              />
            ),
            stretch: true,
          },
        ]}
      />
      <div className="flex items-center justify-center">
        <div className="flex gap-4">
          <Button isLoading={isUpdating} onClick={update}>
            수정 저장
          </Button>
          <Button theme="secondary" onClick={get}>
            수정 초기화
          </Button>
        </div>
      </div>
    </>
  );
};

export default PurchaseInfo;
