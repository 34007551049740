import styled from 'styled-components';

export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 5px 0 0;
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  margin: 0 20px 0 0;
  user-select: none;
`;
