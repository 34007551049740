import styled from 'styled-components';

export const Outer = styled.div`
  width: 100%;
  padding: 0 22px 100px 22px;
`;

export const ShadowContainer = styled.div`
  background: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 22px 0;
  padding: 22px;
`;

export const Table = styled.table`
  width: 100%;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  border: 0.5px solid #b4b4b4;
`;

export const LeftTd = styled.td`
  background: #f0f0f0;
  width: 10%;
  padding: 13px 11px;
`;
export const Td = styled.td`
  padding: 13px 15px;
  display: flex;
  align-items: center;
`;

export const TextArea = styled.textarea`
  margin-left: 10px;
  height: 22px;
  width: 60%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 25px;
`;
export const ModifyButton = styled.button`
  font-weight: bold;
  margin: 10px;
  border: none;
  color: #ff6f61;
  width: 110px;
  height: 45px;
  background: #000000;
  border-radius: 5px;
`;

export const ResetButton = styled.button`
  font-weight: bold;
  margin: 10px;
  border: none;
  width: 110px;
  height: 45px;
  background: #dddddd;
  border-radius: 5px;
`;
