import React from 'react';
import * as style from './style';

const SearchByTd = ({ searchOptionTableState, setSearchOptionTableState }) => {
  return (
    <style.Container>
      <style.Select
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            searchType: e.target.value,
          });
        }}
        value={searchOptionTableState.searchType}
      >
        <option value="FETCHING_CODE">페칭코드</option>
        <option value="CAFE24_CODE">카페24코드</option>
        <option value="CAFE24_CODE_ALT">카페24영문코드</option>
        <option value="ITEM_CODE">편집샵코드</option>
        <option value="ITEM_NAME">상품명</option>
        <option value="DESIGNER_CODE">상품디자이너ID</option>
      </style.Select>
      <style.Input
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            searchKey: e.target.value,
          });
        }}
        value={searchOptionTableState.searchKey}
      />
      <style.Label>
        <style.Checkbox
          onChange={() => [
            setSearchOptionTableState({
              ...searchOptionTableState,
              needCount: !searchOptionTableState.needCount,
            }),
          ]}
          checked={searchOptionTableState.needCount}
        />
        상품 개수 보기
      </style.Label>
      <style.Label>
        <style.Checkbox
          onChange={(e) => [
            setSearchOptionTableState({
              ...searchOptionTableState,
              isNaverAdMaterial: e.target.checked,
            }),
          ]}
          checked={searchOptionTableState.isNaverAdMaterial}
        />
        네이버 광고 소재 검색
      </style.Label>
    </style.Container>
  );
};

export default SearchByTd;
