import { MenuList } from 'components';
import ReviewList from 'pages/ReviewList';
import React from 'react';
import { Route, Switch } from 'react-router';

type Props = {};

const ManageReview: React.FC<Props> = ({}) => {
  const mainMenu = [{ link: `/review`, title: '리뷰목록' }];

  return (
    <>
      <Route path="/review">
        <MenuList items={mainMenu} />
      </Route>
      <Switch>
        <Route path="/review">
          <ReviewList />
        </Route>
      </Switch>
    </>
  );
};

export default ManageReview;
