import { DuplicateIcon } from '@heroicons/react/outline';
import { Button, FormItem, Radio, ReInput, ReModal, Textarea } from 'components';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import {
  COMMERCE_URL,
  ORDER_ADDITIONAL_PAY_TYPE,
  ORDER_WAIT_REASON,
  request,
  useObject,
  useOrderDetail,
} from 'services';
import type { IModal, NOrder } from 'types';
import Switch from 'react-switch';
import { copyText, priceFormat } from 'utils';
import queryString from 'query-string';
import { useParams } from 'react-router-dom';

export interface Props extends IModal {
  shopOrderNumber: string;
  additionalItemNumberList: string[];
}
interface State extends NOrder.Wait {
  orderWaitReason: keyof typeof ORDER_WAIT_REASON;
  sendPush: boolean;
  memoContent: string;
  messageContent: string;
  messageType: 'SMS' | 'KAKAO';
  isLoading: boolean;
  additionalPayList: any[];
  tempMessageContent: string;
}

const OrderWaitModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  shopOrderNumber,
  additionalItemNumberList,
}) => {
  if (!isOpen) return null;
  const [
    {
      shopCountry,
      shopId,
      shopName,
      additionalPayList,
      orderWaitReason,
      sendPush,
      memoContent,
      messageType,
      messageContent,
      isLoading,
      tempMessageContent,
    },
    setState,
    onChange,
  ] = useObject<State>({
    shopName: '',
    shopCountry: '',
    shopId: 0,
    additionalPayList: [],
    orderWaitReason: !!additionalItemNumberList.length ? 'DIFFERENCE_COST' : 'ETC',
    sendPush: false,
    memoContent: '',
    messageContent: '',
    messageType: 'KAKAO',
    isLoading: false,
    tempMessageContent: '',
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const [orderState, setOrderState, { refreshOrderState }] = useOrderDetail();

  const get = async () => {
    if (!shopOrderNumber) return;

    try {
      const data = await request.get<any, NOrder.Wait>(
        `/commerce/order/shop-order/${shopOrderNumber}/order-wait/before`
      );
      setState({
        shopCountry: data.shopCountry,
        shopName: data.shopName,
        shopId: data.shopId,
        additionalPayList: data.additionalPayList,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    if (!window.confirm('발주 대기로 전환하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      let data: any = {
        orderWaitReason,
        sendPush,
        memoContent,
        additionalPayLink: additionalPayLink || null,
        additionalItemNumberList: additionalPayList.map(
          (item) => item.additionalItemNumber
        ),
      };
      if (sendPush) {
        data.messageContent =
          messageType === 'KAKAO' ? messageContent : tempMessageContent;
        data.messageType = messageType;
      }
      await request.post(
        `/commerce/order/shop-order/${shopOrderNumber}/order-wait`,
        data
      );
      refreshOrderState();
      toast.success('발주 대기로 전환되었습니다.');
      onClose();
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };

  const getTemplate = async () => {
    try {
      const { content } = await request.post<any, { content: string }>(
        '/commerce/order/message/preview',
        {
          type: 'ORDER_WAIT',
          fetchingOrderNumber,
          additionalItemNumberList,
          additionalPayLink,
          orderWaitReason,
        }
      );
      setState({ messageContent: content });
    } catch (err) {
      console.log(err);
    }
  };

  const onBeforeClose = async () => {
    if (!window.confirm('작업을 중단하시겠습니까?')) return;
    if (!additionalPayList.length) {
      onClose();
      return;
    }

    try {
      await request.delete('/commerce/order/additional-pay', {
        params: {
          additionalItemNumberList: additionalPayList.map(
            (item) => item.additionalItemNumber
          ),
        },
      });
      refreshOrderState();
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  const additionalPayLink: string = useMemo(() => {
    if (!additionalItemNumberList.length) return '';
    return `${COMMERCE_URL}/order/additional?${queryString.stringify({
      additionalItemNumberList,
    })}`;
  }, [additionalItemNumberList]);

  useEffect(() => {
    get();

    window.onbeforeunload = function () {
      return '정말 새로고침하시겠습니까?';
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  useEffect(() => {
    if (sendPush) getTemplate();
  }, [sendPush]);
  return (
    <ReModal title="발주 대기 전환" isOpen={isOpen} onClose={onBeforeClose}>
      <div className="space-y-4">
        <FormItem label="판매처">
          {shopName}({shopCountry})
        </FormItem>
        <FormItem label="대기 사유 선택">
          <Radio
            options={[
              { name: '차액발생 (차액 및 상품추가 결제대기)', value: 'DIFFERENCE_COST' },
              { name: '기타', value: 'ETC' },
            ]}
            value={orderWaitReason}
            onChange={(orderWaitReason) => setState({ orderWaitReason })}
            id="reason"
          />
        </FormItem>
        {!!additionalPayList.length && (
          <FormItem label="차액 확인">
            <div className="flex flex-col gap-2 text-sm">
              {additionalPayList.map((item, key) => (
                <div key={key}>
                  {`(${ORDER_ADDITIONAL_PAY_TYPE[item.type]}) ${
                    item.itemName
                  } - ${priceFormat(item.price)}원`}
                </div>
              ))}
            </div>
          </FormItem>
        )}
        <FormItem label="차액결제링크">
          <ReInput
            id="차액결제링크"
            className="w-full"
            readOnly
            value={additionalPayLink}
            suffix={
              <DuplicateIcon
                className="ml-1 w-4 h-4 inline-block cursor-pointer"
                onClick={() => copyText(additionalPayLink)}
              />
            }
          />
        </FormItem>
        <FormItem label="고객 알림 전달 여부">
          <Switch checked={sendPush} onChange={(sendPush) => setState({ sendPush })} />
        </FormItem>
        {sendPush && (
          <>
            <FormItem label="고객 알림 채널">
              <Radio
                options={[
                  { name: '카카오', value: 'KAKAO' },
                  { name: 'SMS (메시지 수정 발송 시)', value: 'SMS' },
                ]}
                value={messageType}
                onChange={(messageType) =>
                  setState({
                    messageType,
                    tempMessageContent: messageType === 'SMS' ? messageContent : '',
                  })
                }
                id="push-channel"
              />
            </FormItem>
            <FormItem label="고객 알림 내용 확인">
              <Textarea
                readOnly={messageType === 'KAKAO'}
                rows={8}
                value={tempMessageContent || messageContent}
                name="tempMessageContent"
                onChange={onChange}
              />
            </FormItem>
          </>
        )}
        <FormItem label="관리자 메모">
          <Textarea rows={4} value={memoContent} name="memoContent" onChange={onChange} />
        </FormItem>
        <div className="flex justify-center">
          <Button onClick={onSubmit} isLoading={isLoading}>
            발주 대기로 전환하기
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default OrderWaitModal;
