import { getUserList } from 'api/axios';
import React, { useEffect, useState } from 'react';
import * as S from './style';

const GroupSearchOptionTable = ({ options = {}, setOptions = () => {} }) => {
  const { name, gender, assignee, isAutomated } = options;

  return (
    <S.Table>
      <S.Tbody>
        <SearchByTitle
          value={name}
          onChange={(value) => setOptions((p) => ({ ...p, name: value }))}
        />
        <SearchByAdmin
          value={assignee}
          onChange={(value) => setOptions((p) => ({ ...p, assignee: value }))}
        />
        <SearchByGender
          value={gender}
          onChange={(value) => setOptions((p) => ({ ...p, gender: value }))}
        />
        <SearchByClass
          value={isAutomated}
          onChange={(value) =>
            setOptions((p) => ({ ...p, isAutomated: value }))
          }
        />
      </S.Tbody>
    </S.Table>
  );
};

export default GroupSearchOptionTable;

const SearchByTitle = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>타이틀</S.LeftTd>
      <S.Td>
        <S.TextArea
          value={value || ''}
          onChange={(e) => onChange(e.target.value || null)}
        />
      </S.Td>
    </S.Tr>
  );
};

const SearchByGender = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>성별</S.LeftTd>
      <S.Td>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === null}
            onChange={() => onChange(null)}
          />
          전체
        </S.Label>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === 'M'}
            onChange={() => onChange('M')}
          />
          남성
        </S.Label>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === 'W'}
            onChange={() => onChange('W')}
          />
          여성
        </S.Label>
      </S.Td>
    </S.Tr>
  );
};

const SearchByClass = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>상품분류 방식</S.LeftTd>
      <S.Td>
        <S.Label>
          <S.Radio
            name="isAutomated"
            checked={value === null}
            onChange={() => onChange(null)}
          />
          전체
        </S.Label>
        <S.Label>
          <S.Radio
            name="isAutomated"
            checked={value === true}
            onChange={() => onChange(true)}
          />
          자동
        </S.Label>
        <S.Label>
          <S.Radio
            name="isAutomated"
            checked={value === false}
            onChange={() => onChange(false)}
          />
          수동
        </S.Label>
      </S.Td>
    </S.Tr>
  );
};
const SearchByAdmin = ({ value = null, onChange = () => {} }) => {
  const [adminNames, setAdminNames] = useState([]);

  const fetchAdminNames = async () => {
    const data = await getUserList();
    setAdminNames(data);
  };

  useEffect(() => {
    fetchAdminNames();
  }, []);

  return (
    <S.Tr>
      <S.LeftTd>담당자</S.LeftTd>
      <S.Td>
        <S.Select
          value={value || 'none'}
          onChange={(e) =>
            onChange(e.target.value !== 'none' ? e.target.value : null)
          }
        >
          <option value={'none'}>---</option>
          {adminNames.map(({ id, admin_name }) => (
            <option value={id} key={id}>
              {admin_name}
            </option>
          ))}
        </S.Select>
      </S.Td>
    </S.Tr>
  );
};
