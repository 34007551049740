import styled from 'styled-components';

export const Table = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #b4b4b4;
  border-bottom: none;
`;

export const Tr = styled.div`
  border-bottom: 0.5px solid #b4b4b4;
  display: flex;
  flex-wrap: wrap;
`;

export const Left = styled.div`
width:100px;
min-width: 100px;
  padding: 13px 11px;
  background: #e4e4e4;
  border-right: 0.5px solid #b4b4b4;
`;

export const Td = styled.div`
  width: calc(50% - 132px);
  padding: 0 16px;
  display: flex;
  align-items: center;
`;

export const showDetailButton = styled.div`
  width: 10%;
  // width:120px;
  background: #35256d;
  border: 0.5px solid #000000;
  color: white;
  padding: 1px 3px;
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 6px 0 0;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 16px;
`;

export const Select = styled.select`
  margin: 0 3px;
`;

export const InputDate = styled.input.attrs({ type: 'date' })`
  margin: 0 6px;
`;
