import { SET_INVALID_URLS } from 'actions/types';
import { fetchingAPI } from 'api/axios';
import { LoadingContext } from 'context';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as style from './style';

const CallProductModalButtons = ({
  searchOptionTableState,
  setSearchOptionTableState,
  showModal,
  setShowModal,
  checkedItemIds,
  fetchItems,
  currentPage,
  payload,
  setPayload,
  containChecked,
}) => {
  const { handleIsLoading } = useContext(LoadingContext);
  const dispatch = useDispatch();
  const { push } = useHistory();

  const handleCrawling = async () => {
    const result = window.confirm(
      `선택한 ${checkedItemIds.length}개의 상품을 개별 크롤링 하시겠습니까?`
    );
    if (result) {
      try {
        handleIsLoading(true);
        const res = await fetchingAPI.post('/crawler/item/', {
          code: checkedItemIds,
        });
        const invalidUrls = res.data.invalid;
        if (invalidUrls.length > 0) {
          alert(`${invalidUrls.length}개 상품을 개별 크롤링하는데 실패했습니다. (피드 사용 편집샵)
연결되는 페이지에서 신상 가져오기 해주세요!`);
          window.open('/product/productList/crawler');
          dispatch({ type: SET_INVALID_URLS, invalid: invalidUrls });
        }
      } catch (err) {
        alert(`
            📌 에러가 발생했습니다.! 
            아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
      
            API 에러 발생 
            GET - /api/items/crawl
            ${err}`);
      } finally {
        handleIsLoading(false);
        fetchItems(currentPage, containChecked);
      }
    }
  };

  const saveAll = async () => {
    const fetchPayload = Object.values(payload);
    if (fetchPayload.length === 0) {
      window.alert('하나 이상의 상품을 선택하세요.');
      return;
    }
    const result = window.confirm(
      `변경된 ${fetchPayload.length}개의 항목을 일괄저장할까요?`
    );
    if (result) {
      try {
        handleIsLoading(true);
        await fetchSaveAll(fetchPayload);
        setPayload({});
      } catch (error) {
        window.alert(`err in fetchSaveAll(), saveAll in ItemTableTr, ${error.message}`);
      } finally {
        handleIsLoading(false);
        fetchItems(currentPage, containChecked);
      }
    }
  };

  const disableItem = async () => {
    if (checkedItemIds.length === 0) {
      window.alert('하나 이상의 상품을 선택하세요.');
      return;
    }
    const result = window.confirm(
      `선택한 ${checkedItemIds.length}개의 상품을 비활성화 하시겠습니까?`
    );
    if (result) {
      try {
        handleIsLoading(true);
        await fetchingAPI.delete('/item', {
          params: { itemId: checkedItemIds },
        });
      } catch (err) {
        alert(`
            📌 에러가 발생했습니다.! 
            아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
      
            API 에러 발생 
            DELETE - /item
            ${err}`);
      } finally {
        handleIsLoading(false);
        fetchItems(currentPage, containChecked);
      }
    }
  };

  return (
    <style.Container>
      <style.Button
        style={{ background: '#6E61FF' }}
        onClick={() => push('/product/mixMatch')}
      >
        상품 등록
      </style.Button>
      <style.Button style={{ background: 'red' }} onClick={disableItem}>
        상품 비활성화
      </style.Button>
      <style.Button style={{ background: '#0D9AE9' }} onClick={saveAll}>
        일괄 저장
      </style.Button>
      <style.Button
        style={{ background: '#FF6F61' }}
        onClick={() => {
          setShowModal({
            ...showModal,
            Download: true,
          });
        }}
      >
        다운로드
      </style.Button>
      <style.Button
        style={{ background: '#498935' }}
        onClick={() => {
          if (!checkedItemIds.length) {
            alert('✅ 선택한 상품이 없습니다. 정보를 갱신할 상품을 선택하세요!');
          } else {
            handleCrawling();
          }
        }}
      >
        상품정보 업데이트
      </style.Button>
      <style.Input
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            limit: Number(e.target.value),
          });
        }}
      >
        {[100, 200, 300, 500, 1000, 3000, 5000].map((el) => (
          <option key={el} value={el}>
            {el}개씩 보기
          </option>
        ))}
      </style.Input>
    </style.Container>
  );
};

export default CallProductModalButtons;

const fetchSaveAll = async (payload) => {
  const { data } = await fetchingAPI.put('/item', payload);
  if (data.failed.length) {
    alert(`${data.failed.length}개의 항목에 적용 실패했습니다!..`);
  }
};
