import React, { useContext, useEffect, useState } from 'react';
import { LoadingContext } from 'context';
import { fetchingAPI } from 'api/axios';
import {
  ItemTable,
  Loading,
  SearchButtonGroup,
  SearchOptionTable,
  TopBottomButtons,
} from 'components';
import * as style from './style';
import Pagination from '../ManageCatalog/CatalogSearchOptionTable/Pagination';
import { trimParams } from './utils';
import initialState from './initState';

const ProductGroupProductList = ({ id }) => {
  const [searchOptionTableState, setSearchOptionTableState] = useState(initialState);
  const [totalCount, setTotalCount] = useState(0);
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const fetchItems = async (page = 1) => {
    handleIsLoading(true);

    const params = trimParams(searchOptionTableState);
    params.page = page;

    try {
      const { data } = await fetchingAPI.get(`/group/${id}/item`, {
        params: params,
      });

      setItems(data.data);
      setTotalCount(data.count);
      setCurrentPage(page);
    } catch (err) {
      alert(`error in fetchItems(), /productList, ${err.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <>
      {isLoading && <Loading />}
      <TopBottomButtons />

      <style.PageTitle>상품목록</style.PageTitle>
      <SearchOptionTable
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
      />
      <SearchButtonGroup
        fetchItems={fetchItems}
        setSearchOptionTableState={setSearchOptionTableState}
        initialState={initialState}
        setItems={setItems}
      />

      <ItemTable
        items={items}
        searchOptionTableState={searchOptionTableState}
        setSearchOptionTableState={setSearchOptionTableState}
        fetchItems={fetchItems}
        currentPage={currentPage}
        totalCount={totalCount}
        trimParams={trimParams}
      />
      <Pagination fetchItems={fetchItems} currentPage={currentPage} />
    </>
  );
};

export default ProductGroupProductList;
