import React from 'react';
import * as style from './style';

export default ({ platform, handlePlatformChecked }) => {
  const channel = [
    { name: '전체', value: 'TOTAL' },
    { name: '네이버', value: 'NAVER' },
    { name: '다음카카오', value: 'KAKAO' },
    { name: '구글', value: 'GOOGLE' },
    { name: '페이스북', value: 'FACEBOOK' },
    { name: '인스타그램', value: 'INSTAGRAM' },
    { name: '바이럴', value: 'VIRAL' },
    { name: '문자 & 메일', value: 'MESSAGE' },
    { name: '기타', value: 'ETC' },
  ];

  return (
    <style.Wrapper>
      <style.Item BC="#8080806b">
        <strong>채널</strong>
      </style.Item>
      {channel.map((value, i) => {
        const index = platform.findIndex((el) => {
          return el.name === value.name;
        });
        return (
          <style.Item key={i}>
            <style.CheckBox
              type="checkbox"
              value={JSON.stringify(value)}
              checked={index !== -1}
              onChange={handlePlatformChecked}
            />
            <style.Text>{value.name}</style.Text>
          </style.Item>
        );
      })}
    </style.Wrapper>
  );
};
