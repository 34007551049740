import React, { useEffect, useRef } from 'react';
import Dropzone from 'react-dropzone';
import * as style from './style';

const ShopRenderInfo = ({
  modifyShop,
  setModifyShop,
  handleOnDrop,
  handleClinentHeight,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('표시 정보', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('표시 정보', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>표시 정보</style.Title>
      <style.TableWrapper>
        <style.TableTitle> 편집샵 한글명 </style.TableTitle>
        <style.TableValue>
          <style.Input
            value={modifyShop.krName}
            onChange={(e) => {
              setModifyShop({
                ...modifyShop,
                krName: e.target.value,
              });
            }}
          />
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle> 편집샵 영문명 </style.TableTitle>
        <style.TableValue>
          <style.Input
            value={modifyShop.name}
            onChange={(e) => {
              setModifyShop({
                ...modifyShop,
                name: e.target.value.toLocaleUpperCase(),
              });
            }}
          />{' '}
          (영어 대문자로만 작성해주세요.)
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle> 편집샵 소개 </style.TableTitle>
        <style.TableValue>
          <style.Textarea
            value={modifyShop.description}
            onChange={(e) => {
              setModifyShop({
                ...modifyShop,
                description: e.target.value,
              });
            }}
          />
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle> 정방형 로고 </style.TableTitle>
        <style.TableValue>
          <div>
            <style.Input
              value={modifyShop.imgUrl}
              onChange={(e) => {
                setModifyShop({
                  ...modifyShop,
                  imgUrl: e.target.value,
                });
              }}
            />{' '}
            <Dropzone onDrop={handleOnDrop} multiple={false} maxSize={10000000}>
              {({ getRootProps, getInputProps }) => (
                <span {...getRootProps()}>
                  <input {...getInputProps()} />
                  <style.Button>찾아보기</style.Button>
                </span>
              )}
            </Dropzone>
          </div>
          <style.Img src={modifyShop.imgUrl} />
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle> 홈페이지 링크 </style.TableTitle>
        <style.TableValue>
          <style.Input
            value={modifyShop.url}
            onChange={(e) => {
              setModifyShop({
                ...modifyShop,
                url: e.target.value,
              });
            }}
          />
        </style.TableValue>
      </style.TableWrapper>
      <style.TableWrapper>
        <style.TableTitle> 주문조회 링크 </style.TableTitle>
        <style.TableValue>
          <style.Input
            value={modifyShop.deliveryUrl}
            onChange={(e) => {
              setModifyShop({
                ...modifyShop,
                deliveryUrl: e.target.value,
              });
            }}
          />
        </style.TableValue>
      </style.TableWrapper>
    </style.Wrapper>
  );
};
export default ShopRenderInfo;
