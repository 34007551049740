import React, { FC, DetailedHTMLProps, TextareaHTMLAttributes } from 'react';
import classnames from 'classnames';
import AutosizeTextarea from './Autosize';

export interface Props
  extends DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  width?: string;
  onEnter?: () => void;
}
interface ITextarea extends FC<Props> {
  Autosize: typeof AutosizeTextarea;
}
interface State {}

const Textarea: ITextarea = ({ width, className, readOnly, onEnter, ...props }) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !!onEnter) {
      if (!e.shiftKey) onEnter();
    }
  };
  return (
    <textarea
      {...props}
      readOnly={readOnly}
      className={classnames(
        'p-2 rounded border border-gray-300 text-sm',
        {
          'bg-gray-100': readOnly,
        },
        width,
        className
      )}
      onKeyDown={onKeyDown}
      spellCheck={false}
    />
  );
};

Textarea.Autosize = AutosizeTextarea;

Textarea.defaultProps = {
  width: 'w-full',
};

export default Textarea;
