import React, { FunctionComponent } from 'react';

export interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  label: string;
}
interface State {}

const Checkbox: FunctionComponent<Props> = ({ checked, onChange, label }) => {
  return (
    <label className="inline-flex items-center mb-0 cursor-pointer">
      <input
        onChange={(e) => onChange(e.target.checked)}
        type="checkbox"
        checked={checked}
      />
      <span className="ml-1.5 text-sm">{label}</span>
    </label>
  );
};

export default Checkbox;
