import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import * as style from './style';

const BrandShopFilterOptions = ({
  target,
  options,
  handleIsAppChecked = () => {},
  handleIsCafe24Upload = () => {},
  handleIsNaverFeed = () => {},
  handleIsFetchingPayment = () => {},
  handleCountry = () => {},
  handlePath = () => {},
  handleGender = () => {},
  handleShopType = () => {},
  handleNameType = () => {},
  handleName = () => {},
}) => {
  return (
    <style.Wrapper>
      <style.ItemWrapper>
        <style.Title width={target === '편집샵' ? '15%' : '30%'}>
          {target} 검색
        </style.Title>
        <style.Options width={target === '편집샵' ? '85%' : '70%'} target={target}>
          <style.Select onChange={handleNameType}>
            <option value={'K'}>{target} 한국명</option>
            <option value={'E'}>{target} 영어명</option>
          </style.Select>
          <DebounceInput
            className="focus:outline-none ml-1"
            value={options.name}
            onChange={handleName}
            debounceTimeout={300}
          />
        </style.Options>
      </style.ItemWrapper>
      <style.Inner display={target === '편집샵' ? 'flex' : 'column'}>
        <style.ItemWrapper>
          <style.Title>앱 노출 여부</style.Title>
          <style.Options>
            <style.Checkbox
              checked={options.isApp === '전체'}
              value={'전체'}
              onChange={handleIsAppChecked}
            />
            <style.P>전체</style.P>
            <style.Checkbox
              checked={options.isApp === '1'}
              value={'1'}
              onChange={handleIsAppChecked}
            />
            <style.P>노출</style.P>
            <style.Checkbox
              checked={options.isApp === '0'}
              value={'0'}
              onChange={handleIsAppChecked}
            />
            <style.P>미노출</style.P>
          </style.Options>
        </style.ItemWrapper>
        <style.ItemWrapper>
          <style.Title>카페24 업로드</style.Title>
          {target === '브랜드' ? (
            <style.Options>
              <style.Checkbox
                checked={options.isCafe24Upload === '전체'}
                value={'전체'}
                onChange={handleIsCafe24Upload}
              />
              <style.P>전체</style.P>
              <style.Checkbox
                checked={options.isCafe24Upload === '1'}
                value={'1'}
                onChange={handleIsCafe24Upload}
              />
              <style.P>대상</style.P>
              <style.Checkbox
                checked={options.isCafe24Upload === '0'}
                value={'0'}
                onChange={handleIsCafe24Upload}
              />
              <style.P>미대상</style.P>
            </style.Options>
          ) : (
            <style.Options>
              <style.Checkbox
                checked={options.isCafe24Upload === '전체'}
                value={'전체'}
                onChange={handleIsCafe24Upload}
              />
              <style.P>전체</style.P>
              <style.Checkbox
                checked={options.isCafe24Upload === '1'}
                value={'1'}
                onChange={handleIsCafe24Upload}
              />
              <style.P>대상</style.P>
              <style.Checkbox
                checked={options.isCafe24Upload === '-1'}
                value={'-1'}
                onChange={handleIsCafe24Upload}
              />
              <style.P>미대상</style.P>
              <style.Checkbox
                checked={options.isCafe24Upload === '0'}
                value={'0'}
                onChange={handleIsCafe24Upload}
              />
              <style.P>일시정지</style.P>
            </style.Options>
          )}
        </style.ItemWrapper>
      </style.Inner>
      {target === '브랜드' && (
        <style.ItemWrapper>
          <style.Title>네이버 피드 업로드</style.Title>
          <style.Options>
            <style.Checkbox
              checked={options.isNaverFeed === '전체'}
              value={'전체'}
              onChange={handleIsNaverFeed}
            />
            <style.P>전체</style.P>
            <style.Checkbox
              checked={options.isNaverFeed === '1'}
              value={'1'}
              onChange={handleIsNaverFeed}
            />
            <style.P>가능</style.P>
            <style.Checkbox
              checked={options.isNaverFeed === '0'}
              value={'0'}
              onChange={handleIsNaverFeed}
            />
            <style.P>불가능</style.P>
          </style.Options>
        </style.ItemWrapper>
      )}
      {target === '편집샵' && (
        <>
          <style.Inner display={target === '편집샵' ? 'flex' : 'column'}>
            <style.ItemWrapper>
              <style.Title>네이버 피드 업로드</style.Title>
              <style.Options>
                <style.Checkbox
                  checked={options.isNaverFeed === '전체'}
                  value={'전체'}
                  onChange={handleIsNaverFeed}
                />
                <style.P>전체</style.P>
                <style.Checkbox
                  checked={options.isNaverFeed === '1'}
                  value={'1'}
                  onChange={handleIsNaverFeed}
                />
                <style.P>가능</style.P>
                <style.Checkbox
                  checked={options.isNaverFeed === '0'}
                  value={'0'}
                  onChange={handleIsNaverFeed}
                />
                <style.P>불가능</style.P>
              </style.Options>
            </style.ItemWrapper>
            <style.ItemWrapper>
              <style.Title>페칭 결제 지원</style.Title>
              <style.Options>
                <style.Checkbox
                  checked={options.isFetchingPayment === '전체'}
                  value={'전체'}
                  onChange={handleIsFetchingPayment}
                />
                <style.P>전체</style.P>
                <style.Checkbox
                  checked={options.isFetchingPayment === '1'}
                  value={'1'}
                  onChange={handleIsFetchingPayment}
                />
                <style.P>지원</style.P>
                <style.Checkbox
                  checked={options.isFetchingPayment === '0'}
                  value={'0'}
                  onChange={handleIsFetchingPayment}
                />
                <style.P>미지원</style.P>
              </style.Options>
            </style.ItemWrapper>
          </style.Inner>
          <style.Inner display={target === '편집샵' ? 'flex' : 'column'}>
            <style.ItemWrapper>
              <style.Title>국가</style.Title>
              <style.Options>
                <style.Checkbox
                  checked={options.country === '전체'}
                  value={'전체'}
                  onChange={handleCountry}
                />
                <style.P>전체</style.P>
                <style.Checkbox
                  checked={options.country === '국내'}
                  value={'국내'}
                  onChange={handleCountry}
                />
                <style.P>국내</style.P>
                <style.Checkbox
                  checked={options.country === '해외'}
                  value={'해외'}
                  onChange={handleCountry}
                />
                <style.P>해외</style.P>
              </style.Options>
            </style.ItemWrapper>
            <style.ItemWrapper>
              <style.Title>상품 수집 경로</style.Title>
              <style.Options>
                <style.Checkbox
                  checked={options.path === '전체'}
                  value={'전체'}
                  onChange={handlePath}
                />
                <style.P>전체</style.P>
                <style.Checkbox
                  checked={options.path === 'FEED'}
                  value={'FEED'}
                  onChange={handlePath}
                />
                <style.P>피드</style.P>
                <style.Checkbox
                  checked={options.path === 'CRAWLING'}
                  value={'CRAWLING'}
                  onChange={handlePath}
                />
                <style.P>크롤링</style.P>
              </style.Options>
            </style.ItemWrapper>
          </style.Inner>
          <style.Inner display={target === '편집샵' ? 'flex' : 'column'}>
            <style.ItemWrapper>
              <style.Title>성별</style.Title>
              <style.Options>
                <style.Checkbox
                  checked={options.gender.length === 3}
                  value={'전체'}
                  onChange={handleGender}
                />
                <style.P>전체</style.P>
                <style.Checkbox
                  checked={options.gender.includes('M')}
                  value={'M'}
                  onChange={handleGender}
                />
                <style.P>남성</style.P>
                <style.Checkbox
                  checked={options.gender.includes('W')}
                  value={'W'}
                  onChange={handleGender}
                />
                <style.P>여성</style.P>
                <style.Checkbox
                  checked={options.gender.includes('MW')}
                  value={'MW'}
                  onChange={handleGender}
                />
                <style.P>남여공용</style.P>
              </style.Options>
            </style.ItemWrapper>
            <style.ItemWrapper>
              <style.Title>편집샵 유형</style.Title>
              <style.Options>
                <div style={{ display: 'flex' }}>
                  <style.Checkbox
                    checked={options.shopType.length === 4}
                    value={'전체'}
                    onChange={handleShopType}
                  />
                  <style.P>전체</style.P>
                  <style.Checkbox
                    checked={options.shopType.includes('해외편집샵')}
                    value={'해외편집샵'}
                    onChange={handleShopType}
                  />
                  <style.P>해외편집샵</style.P>
                  <style.Checkbox
                    checked={options.shopType.includes('국내편집샵')}
                    value={'국내편집샵'}
                    onChange={handleShopType}
                  />
                  <style.P>국내편집샵</style.P>
                  <style.Checkbox
                    checked={options.shopType.includes('구매대행')}
                    value={'구매대행'}
                    onChange={handleShopType}
                  />
                  <style.P>구매대행</style.P>
                  <style.Checkbox
                    checked={options.shopType.includes('병행수입')}
                    value={'병행수입'}
                    onChange={handleShopType}
                  />
                  <style.P>병행수입</style.P>
                </div>
              </style.Options>
            </style.ItemWrapper>
          </style.Inner>
        </>
      )}
    </style.Wrapper>
  );
};
export default BrandShopFilterOptions;
