import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'api/axios';

const PriorityChangeModal = (props) => {
  const { onHide, targetIds } = props;

  // const itemIds = useSelector((state) => state.selectItems.itemsId);
  const itemIds = targetIds;

  const [itemPriority, setItemPriority] = useState('0');
  const [priorityList, setPriorityList] = useState([]);

  const handlePriorityChange = async () => {
    try {
      await axios.patch('/api/items/priority', {
        itemIds: itemIds,
        priority: Number(itemPriority),
      });
      onHide(true);
    } catch (err) {
      if (err.response.status === 403) {
        alert('사용자에게 권한이 없습니다.');
      } else {
        console.error(err);
        alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      PATCH - /api/items/priority
      ${err}`);
        alert('다시 시도해 주세요');
      }
    }
  };

  useEffect(() => {
    const numberList = [];
    for (let i = 0; i <= 1000; i++) {
      numberList.push(i);
    }
    setPriorityList(priorityList.concat(numberList));
  }, [setPriorityList]);

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>우선순위 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          as="select"
          defaultValue={itemPriority}
          onChange={(e) => {
            setItemPriority(e.target.value);
          }}
        >
          {priorityList.map((el, i) => (
            <option key={i}>{el}</option>
          ))}
        </Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handlePriorityChange();
          }}
        >
          설정
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PriorityChangeModal;
