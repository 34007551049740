import styled from "styled-components";

export const Container = styled.div`
display:flex;
align-items:center;`;
export const Select = styled.select`
margin:0 8px 0px 0px;`;
export const Input = styled.textarea`
width:500px;
height:21px;
margin-right:20px;`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
margin: 0 7px 0 0;`;