import { toast } from 'react-toastify';

export const priceFormat = (value?: string | number): string => {
  if (!value) return '0';
  else if (typeof value === 'string') return Number(value).toLocaleString();
  else if (typeof value === 'number') return value.toLocaleString();
  else return '0';
};

export const copyText = (text: string, successMsg?: string): Promise<void> => {
  if (!text) return new Promise((resolve) => resolve());
  return new Promise((resolve, reject) => {
    window.navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(successMsg || '복사되었습니다.');
        resolve();
      })
      .catch(reject);
  });
};
