/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';

import * as style from './style';
import { fetchingAPI } from '../../../../api/fetchingAPI';

const dateToString = (date) =>
  date.slice(0, 4) +
  '.' +
  date.slice(5, 7) +
  '.' +
  date.slice(8, 10) +
  ' ' +
  date.slice(11, 16);

const CategoryMapTableTr = ({
  item: itemOriginal,
  i,
  checkedMaps,
  setCheckedMaps,
  checkedIds,
  setCheckedIds,
  categoryMap,
}) => {
  const [item, setItem] = useState(itemOriginal);
  const [isModificationFailed, setIsModificationFailed] = useState(false);

  const map = `${item.shopId} - ${item.gender} - ${item.categoryName}`;

  const [uploadable, setUploadable] = useState(false);

  const handleRemapping = (e) => {
    const categoryId = e.target.value;
    if (categoryId === 'null' && item.categoryId !== categoryId) {
      setItem({ ...item, categoryId: null });
    } else {
      setItem({ ...item, categoryId: e.target.value });
    }
    setUploadable(true);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      if (!uploadable) {
        return;
      }
      try {
        const { data } = await fetchingAPI.post('/category/map', [
          {
            shopId: item.shopId,
            categoryName: item.categoryName,
            gender: item.gender,
            categoryId: item.categoryId,
          },
        ]);
        setUploadable(false);
        setIsModificationFailed(false);
        if (data.filter((d) => d).length) {
          setItem(data[0]);
        } else {
          setItem({ ...item, createdBy: null, createdAt: null });
        }
      } catch {
        setUploadable(false);
        setIsModificationFailed(true);
        setItem(itemOriginal);
      }
    }, 3000);

    return () => clearTimeout(delayDebounceFn);
  }, [item]);
  return (
    <style.Tr>
      <style.flexibleTd
        widthPcnt={10}
        onClick={() => {
          const checkedMapSet = new Set(checkedMaps);
          if (checkedMapSet.has(map)) {
            checkedMapSet.delete(map);
            setCheckedMaps([...checkedMapSet.values()]);
          } else {
            checkedMapSet.add(map);
            setCheckedMaps([...checkedMapSet.values()]);
          }
          const checkedIdSet = new Set(checkedIds);
          if (checkedIdSet.has(item)) {
            checkedIdSet.delete(item);
            setCheckedIds([...checkedIdSet.values()]);
          } else {
            checkedIdSet.add(item);
            setCheckedIds([...checkedIdSet.values()]);
          }
        }}
      >
        <style.Checkbox
          onChange={() => {
            const checkedItemIdsSet = new Set(checkedMaps);
            if (checkedItemIdsSet.has(map)) {
              checkedItemIdsSet.delete(map);
              setCheckedMaps([...checkedItemIdsSet.values()]);
            } else {
              checkedItemIdsSet.add(map);
              setCheckedMaps([...checkedItemIdsSet.values()]);
            }
          }}
          checked={checkedMaps.includes(map)}
        />
        {i + 1}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={25}>
        {item.mapId ? '매칭됨' : '매칭안됨'}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={25}>{item.shopName}</style.flexibleTd>
      <style.flexibleTd widthPcnt={35}>
        {item.gender === 'W' ? '여성' : item.gender === 'M' ? '남성' : '기타'}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={100}>{item.categoryName}</style.flexibleTd>
      <style.flexibleTd widthPcnt={20}>
        <style.Input
          value={item.categoryId || ''}
          onChange={(e) => handleRemapping(e)}
          type="text"
        />
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={100}>
        <style.Select
          value={item.categoryId || 'null'}
          onChange={(e) => handleRemapping(e)}
        >
          <option value={'null'}>매핑 안됨</option>
          {Object.keys(categoryMap).map((id) => (
            <option value={id} key={id}>
              {categoryMap[id]}
            </option>
          ))}
        </style.Select>
        {isModificationFailed && '매핑 변경 실패. 다시 로그인 해주세요'}
      </style.flexibleTd>
      <style.flexibleTd widthPcnt={30}>
        {item.createdBy}
        <br />
        {item.createdAt ? dateToString(item.createdAt) : null}
      </style.flexibleTd>
    </style.Tr>
  );
};

export default CategoryMapTableTr;
