export const trimParams = (params) => {
  const result = {};

  if (params.searchKey !== '') {
    result.searchKey = params.searchKey;
    result.searchType = params.searchType;
  }

  if (params.isSellable !== 'null') {
    result.isSellable = params.isSellable;
  }
  if (params.isShopPayActive !== 'null') {
    result.isShopPayActive = params.isShopPayActive;
  }
  if (params.isFetchingPayActive !== 'null') {
    result.isFetchingPayActive = params.isFetchingPayActive;
  }

  if (params.priorityList.includes(-1)) {
    result.priorityList = params.priorityList
      .sort()
      .slice(1)
      .concat(params.priorityString.split(',').map((v) => Number(v)));
  } else if (params.priorityList.length > 0) {
    result.priorityList = params.priorityList;
  }

  if (params.maxPrioritySetAt !== null) {
    result.maxPrioritySetAt = params.maxPrioritySetAt;
  }
  if (params.minPrioritySetAt !== null) {
    result.minPrioritySetAt = params.minPrioritySetAt;
  }

  if (params.isShopDiscount !== 'null') {
    result.isShopDiscount = params.isShopDiscount;
  }
  if (params.hasPromotion !== 'null') {
    result.hasPromotion = params.hasPromotion;
  }
  if (params.hasExhibition !== 'null') {
    result.hasExhibition = params.hasExhibition;
  }

  result.sort = params.sort;
  result.sortWay = params.sortWay;
  if ([13, 14, 15, 16].includes(params.sort) && params.eventDuration) {
    result.eventDuration = params.eventDuration;
  }

  if (params.hasView) {
    result.hasView = true;
  }
  if (params.hasLike) {
    result.hasLike = true;
  }
  if (params.hasCart) {
    result.hasCart = true;
  }
  if (params.hasSales) {
    result.hasSales = true;
  }

  if (params.priceMode !== 'null') {
    switch (params.priceMode) {
      case 'basicPrice':
        result.minDiscountedPrice = params.minDiscountedPrice;
        result.maxDiscountedPrice = params.maxDiscountedPrice;
        break;
      case 'shopPrice':
        result.minTotalPrice = params.minTotalPrice;
        result.maxTotalPrice = params.maxTotalPrice;
        break;
      case 'fetchingPrice':
        result.minFinalPrice = params.minFinalPrice;
        result.maxFinalPrice = params.maxFinalPrice;
        break;
      default:
        break;
    }
  }

  if (params.country != 'null') {
    result.country = params.country;
  }

  if (params.shopIdList.length > 0) {
    result.shopIdList = params.shopIdList;
  }
  if (params.brandIdList.length > 0) {
    result.brandIdList = params.brandIdList;
  }
  if (params.categoryIdList.length > 0) {
    result.categoryIdList = params.categoryIdList;
  }
  if (params.promotionIdList.length > 0) {
    result.promotionIdList = params.promotionIdList;
  }
  if (params.exhibitionIdList.length > 0) {
    result.exhibitionIdList = params.exhibitionIdList;
  }
  if (params.priceRuleIdList.length > 0) {
    result.priceRuleIdList = params.priceRuleIdList;
  }

  if (params.needCount) {
    result.needCount = true;
  }

  result.isExtended = true;

  result.limit = params.limit;

  return result;
};
