export const initialInfoState = {
  basic: {
    isActive: false, // false | true
    shopId: null, // null | <number>
    priceRuleId: null, // null | <number>
    isVip: false, // false | true
    name: null, // null | string
    description: null, // null | string
    applyDescription: null, // null | string
    period: false, // false | true (flag for period; to be trimmed)
    startedAt: null, // null | <input-date-string> (to be trimmed to ISOString)
    endedAt: null, // null | <input-date-string> (to be trimmed to ISOString)
    urlWomen: null, // null | <string>
    urlMen: null, // null | <string>
  },
  discount: {
    state: {
      isUserApplicable: false, // false | true
      isFetchingApplicable: false, // false | true
      agencyFeeType: null, // null | <number>
      applyWithDiscountedPrice: false, // false | true
      canBeOverlapped: false, // false | true
      canBeOverlappedWithFreeDelivery: false, // false | true
      canBeOverlappedWithVip: false, // false | true
      canBeOverlappedWithVipFreeDelivery: false, // false | true
      type: null, // "DISCOUNT_WITH_CONST" | "DISCOUNT_WITH_RATE" | "FREE_DELIVERY"
      //rules : null | <discountInfo_rules>[]
    },
    rules: {
      state: {
        typeValue: null, // null | <number>
        code: null, // null | <string>
        automation: null, // null | <automation-setting-object>
      },
      automationSettingObject: {
        // key: null,
      },
    },
  },
  admin: {
    assigneeId: null, // null | <number>
    applyStatus: null, // null | "NEW" | "UPDATING" | "UPDATED" | "NO_UPDATE_NEEDED" | "TO_BE_TERMINATED" | "TERMINATED"
    adminMemo: null, // null | <string>
  },
  banner: {
    isVisibleToAppCategory: false, // false | true
    isVisibleToAppItemPage: false, // false | true
    isVisibleToAppMain: false, // false | true
    isVisibleToAppShopLink: false, // false | true
    bannerImageUrl: null, // null | <string>
    landingImageUrl: null, // null | <string>
    mainImageUrl: null, // null | <string>
    topBannerImageUrl: null, // null | <string>
    // bannerImageUrl:null,// null | <string>
  },
  sales: {
    view: null, // null | <number>
    like: null, // null | <number>
    cart: null, // null | <number>
    sales: null, // null | <number>
  },
};
