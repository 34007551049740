import { PencilAltIcon, TrashIcon } from '@heroicons/react/solid';
import { Button, Card, GridTable, RePagination, Textarea } from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';

interface IAdminMemo {
  idx: number;
  content: string;
  createdAt: string;
  createdBy: number;
  creatorName: string;
  userId: number;
}

export interface Props {}
interface State {
  content: string;
  list: IAdminMemo[];
  page: number;
  total: number;
  isCreating: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  memoIdx: number;
}

const UserAdminMemo: FunctionComponent<Props> = () => {
  const [
    { content, list, page, total, isCreating, memoIdx, isDeleting, isUpdating },
    setState,
    onChange,
  ] = useObject<State>({
    content: '',
    list: [],
    page: 1,
    total: 0,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    memoIdx: 0,
  });
  const { idx } = useParams<{ idx: string }>();
  const get = async () => {
    try {
      const { data, count } = await request.get<
        any,
        { count: number; data: IAdminMemo[] }
      >(`/commerce/user/${idx}/memo`, { params: { limit: 10, page } });
      setState({ list: data, total: count });
    } catch (err) {
      console.log(err);
    }
  };
  const addMemo = async () => {
    setState({ isCreating: true });
    try {
      await request.post(`/commerce/user/${idx}/memo`, { content });
      toast.success('등록되었습니다.');
      get();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isCreating: false });
    }
  };
  const updateMemo = async (index: number) => {
    if (!window.confirm('수정하시겠습니까?')) return;
    const item = list[index];
    setState({ isUpdating: true, memoIdx: item.idx });
    try {
      await request.put(`/commerce/user/${item.userId}/memo/${item.idx}`, {
        content: item.content,
      });
      toast.success('수정되었습니다.');
      get();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isUpdating: false, memoIdx: 0 });
    }
  };
  const removeMemo = async (index: number) => {
    if (!window.confirm('삭제하시겠습니까?')) return;
    const item = list[index];
    setState({ isDeleting: true, memoIdx: item.idx });
    try {
      await request.delete(`/commerce/user/${item.userId}/memo/${item.idx}`);
      toast.success('삭제되었습니다.');
      get();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isDeleting: false, memoIdx: 0 });
    }
  };
  useEffect(() => {
    get();
  }, [page]);
  return (
    <div className="p-4">
      <Card title="등록하기">
        <div className="flex" style={{ border: '1px solid #b4b4b4' }}>
          <div className="w-28 p-3" style={{ borderRight: '1px solid #b4b4b4' }}>
            내용
          </div>
          <div className="flex-1 py-2 px-3 flex gap-2">
            <Textarea value={content} name="content" onChange={onChange} rows={4} />
            <Button size="sm" className="w-16" isLoading={isCreating} onClick={addMemo}>
              등록
            </Button>
          </div>
        </div>
      </Card>
      <GridTable
        columns={['작성일', '작성자', '내용', '관리']}
        list={list}
        noSelection
        renderItem={(item, key) => (
          <div key={key}>
            <div>{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div>{item.creatorName}</div>
            <div>
              <Textarea
                value={item.content || ''}
                onChange={({ target: { value } }) =>
                  setState({
                    list: [
                      ...list.slice(0, key),
                      {
                        ...list[key],
                        content: value,
                      },
                      ...list.slice(key + 1),
                    ],
                  })
                }
                rows={4}
              />
            </div>
            <div>
              <div className="inline-flex flex-col gap-2">
                <Button
                  size="sm"
                  isLoading={isUpdating && memoIdx === item.idx}
                  onClick={() => updateMemo(key)}
                >
                  <PencilAltIcon className="w-5 h-5" />
                </Button>
                <Button
                  size="sm"
                  theme="danger"
                  isLoading={isDeleting && memoIdx === item.idx}
                  onClick={() => removeMemo(key)}
                >
                  <TrashIcon className="w-5 h-5" />
                </Button>
              </div>
            </div>
          </div>
        )}
      />
      <div className="flex justify-between items-center">
        <div className="pl-2 text-gray-500 tracking-widest">
          <span>총</span>
          <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
          <span>개</span>
        </div>
        <RePagination
          currentPage={page}
          onPageChange={(page) => setState({ page })}
          totalCount={total}
          pageSize={10}
        />
      </div>
    </div>
  );
};

export default UserAdminMemo;
