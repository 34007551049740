import React, { FunctionComponent } from 'react';

export interface Props {}
interface State {}

const Divider: FunctionComponent<Props> = () => {
  return <div className="my-5 h-px bg-gray-200" />;
};

export default Divider;
