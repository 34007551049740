import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { RenderTooltip } from 'components/UI';
import * as style from './style';

const CategoryMapTableItem = ({
  id,
  item,
  selectedItems,
  setModalShow,
  onChangeIsChecked,
  categories,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const gender = item.gender === 'W' ? '여성' : '남성';
  const getParentCategory = (target) =>
    categories.find((item) => item.id === target);

  const parent = getParentCategory(item.categoryID);

  let middle;
  if (parent) {
    middle = getParentCategory(parent.parent);
  }

  let result;
  if (middle) {
    result = getParentCategory(middle.parent);
  }

  const pathname = parent
    ? ` ${result ? `${result.name} > ${middle.name} > ${parent.name}` : ' '}`
    : item.name;

  const handleChecked = () => {
    const target = String(id).split(';');
    if (target.length > 1) {
      const shopID = Number(target[0]);
      const gender = target[1];
      const categoryName = target[2];
      return selectedItems.findIndex(
        (el) =>
          el.shopID === shopID &&
          el.gender === gender &&
          el.categoryName === categoryName,
      );
    }
    if (target.length === 1) {
      return selectedItems.findIndex((el) => el.mapId === id);
    }
  };

  useEffect(() => {
    setIsChecked(handleChecked());
  }, [selectedItems]);

  return (
    <style.Container>
      <style.Checkbox
        checked={isChecked !== -1}
        value={id}
        onChange={onChangeIsChecked}
      />
      <style.Item>{item.shopName}</style.Item>
      <style.Item>{gender}</style.Item>
      <style.Item>
        {item.categoryID ? (
          <style.Text color="black">매핑됨</style.Text>
        ) : (
          <style.Text color="red">매핑안됨</style.Text>
        )}
      </style.Item>
      <style.Item>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 500 }}
          overlay={<RenderTooltip />}
        >
          <style.TextButton onClick={() => setModalShow(item)}>
            {item.categoryName}
          </style.TextButton>
        </OverlayTrigger>
      </style.Item>
      <style.Item>{pathname}</style.Item>
    </style.Container>
  );
};
export default CategoryMapTableItem;
