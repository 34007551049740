import React, { useState, useContext } from 'react';
import { LoadingContext } from '../../../context';
import * as style from './style';
import axios from '../../../api/axios';
import { Loading } from '../../../components';

const Modal = ({ title, onHide, checkedItems, handleSearch }) => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);
  const [res, setRes] = useState(false);
  const [isActive, setIsActive] = useState('');
  const [isVisible, setIsVisible] = useState({
    appCategory: '0',
    appMain: '0',
    cafe24: '0',
  });

  const handleDelete = async (result) => {
    try {
      handleIsLoading(true);
      await axios({
        method: 'DELETE',
        url: '/api/exhibitions',
        data: result,
      });
      setRes(true);
    } catch (err) {
      handleIsLoading(false);
      alert('삭제되지 않았습니다. 다시 시도해 주세요');
      console.err(err);
    } finally {
      handleIsLoading(false);
    }
  };

  const handleIsActive = async (result) => {
    try {
      handleIsLoading(true);
      if (isActive === '') {
        alert('활성화 변경을 선택해 주세요');
        return;
      }
      await axios({
        method: 'PUT',
        url: '/api/exhibitions',
        data: result,
      });
      setRes(true);
    } catch (err) {
      handleIsLoading(false);
      alert('활성화 변경이 적용되지 않았습니다. 다시 시도해 주세요');
      console.log(err);
    } finally {
      handleIsLoading(false);
    }
  };

  const handlePosition = async (result) => {
    try {
      handleIsLoading(true);
      // FIXME: 추후 복수로 동작할 수 있도록 서버쪽 기능 수정 필요
      // await fetchingAPI.updateExhibition(result[0].id, result[0])
      await axios({
        method: 'PUT',
        url: '/api/exhibitions',
        data: result,
      });
      setRes(true);
    } catch (err) {
      handleIsLoading(false);
      alert('노출 위치 변경이 적용되지 않았습니다. 다시 시도해 주세요');
      console.log(err);
    } finally {
      handleIsLoading(false);
    }
  };

  const handleConfirm = () => {
    if (title === '선택 삭제') {
      const result = checkedItems.map((item) => {
        return {
          exhibitionId: item.exhibitionId,
          cafe24CategoryId: Number(item.cafe24CategoryId),
        };
      });

      handleDelete(result);
    }
    if (title === '활성화 변경') {
      const result = checkedItems.map((item) => {
        return {
          ...item,
          isActive: Number(isActive),
        };
      });
      handleIsActive(result);
    }
    if (title === '노출 위치 변경') {
      const result = checkedItems.map((item) => {
        return {
          ...item,
          isVisibleToAppMain: Number(isVisible.appMain),
          isVisibleToAppCategory: Number(isVisible.appCategory),
          isVisibleToCafe24: Number(isVisible.cafe24),
        };
      });
      handlePosition(result);
    }
  };

  return (
    <style.Outer
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onHide();
        }
      }}
    >
      {isLoading && <Loading />}
      <style.Inner>
        <style.Header>
          {title === '선택 삭제' ? '기획전 삭제' : title}
        </style.Header>
        {title === '선택 삭제' && !res && (
          <style.Text>선택한 기획전을 삭제하시겠습니까?</style.Text>
        )}
        {title === '선택 삭제' && res && <style.Text>삭제 성공!!</style.Text>}
        {title === '활성화 변경' && res && (
          <style.Text>
            {isActive === '1' ? '활성화' : '비활성화'} 성공!!
          </style.Text>
        )}
        {title === '노출 위치 변경' && res && (
          <style.Text>변경 성공!!</style.Text>
        )}
        {(title === '활성화 변경' || (title === '노출 위치 변경' && !res)) && (
          <style.Text>선택한 기획전을</style.Text>
        )}
        <style.Content>
          {checkedItems.map((item) => {
            return (
              <div key={item.exhibitionId}>
                {item.gender === 'M' ? '남성' : '여성'} {'>'}
                {item.name}
              </div>
            );
          })}
          <div></div>
        </style.Content>
        {title === '활성화 변경' && (
          <style.Text>아래 상태로 변경하겠습니다.</style.Text>
        )}
        {title === '노출 위치 변경' && (
          <style.Text>아래 노출 위치로 변경하겠습니다.</style.Text>
        )}
        {title === '활성화 변경' && !res && (
          <style.CheckBoxOuter direction="row">
            <style.CheckBox
              checked={isActive === '1'}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsActive('1');
                } else {
                  setIsActive('');
                }
              }}
            />
            <style.Span>활성화</style.Span>
            <style.CheckBox
              checked={isActive === '0'}
              onChange={(e) => {
                if (e.target.checked) {
                  setIsActive('0');
                } else {
                  setIsActive('');
                }
              }}
            />
            <style.Span>비활성화</style.Span>
          </style.CheckBoxOuter>
        )}
        {title === '노출 위치 변경' && !res && (
          <style.CheckBoxOuter direction="column">
            <div>
              <style.CheckBox
                checked={isVisible.appMain === '1'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsVisible({
                      ...isVisible,
                      appMain: '1',
                    });
                  } else {
                    setIsVisible({
                      ...isVisible,
                      appMain: '0',
                    });
                  }
                }}
              />
              <style.Span>앱 {'>'} 메인</style.Span>
            </div>
            <div>
              <style.CheckBox
                checked={isVisible.appCategory === '1'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsVisible({
                      ...isVisible,
                      appCategory: '1',
                    });
                  } else {
                    setIsVisible({
                      ...isVisible,
                      appCategory: '0',
                    });
                  }
                }}
              />
              <style.Span>앱 {'>'} 카테고리 </style.Span>
            </div>
            <div>
              <style.CheckBox
                checked={isVisible.cafe24 === '1'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setIsVisible({
                      ...isVisible,
                      cafe24: '1',
                    });
                  } else {
                    setIsVisible({
                      ...isVisible,
                      cafe24: '0',
                    });
                  }
                }}
              />
              <style.Span>카테고리24 {'>'} 메인</style.Span>
            </div>
          </style.CheckBoxOuter>
        )}
        {res ? (
          <style.ButtonOuter>
            <style.Button
              name="닫기"
              onClick={() => {
                onHide();
                handleSearch();
              }}
            >
              닫기
            </style.Button>
          </style.ButtonOuter>
        ) : (
          <style.ButtonOuter>
            <style.Button name="취소" onClick={onHide}>
              취소
            </style.Button>
            <style.Button onClick={handleConfirm} name="확인">
              확인
            </style.Button>
          </style.ButtonOuter>
        )}
      </style.Inner>
    </style.Outer>
  );
};

export default Modal;
