import { UserMenu } from 'components';
import React, { FunctionComponent, useMemo } from 'react';
import {
  CONTENTS_MENU_LIST,
  DASHBOARD_MENU_LIST,
  HEADER_MENU_LIST,
  HOME_MENU_LIST,
  IS_MAIN,
  PARTNER_MENU_LIST,
  PRODUCT_MENU_LIST,
  REVIEW_MENU_LIST,
  USER_MENU_LIST,
  useUser,
} from 'services';
import { Link, useHistory, matchPath } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { orderCountState } from 'store';
import classnames from 'classnames';
import type { ILink } from 'types';

export interface Props {}
interface State {}

const Layout: FunctionComponent<Props> = ({ children }) => {
  const {
    location: { pathname },
  } = useHistory();
  const matchOrder = matchPath<{ idx: string }>(pathname, {
    path: '/order/:idx',
    exact: true,
    strict: false,
  });
  const orderCount = useRecoilValue(orderCountState);

  const { user } = useUser();

  const ORDER_MENU_LIST: ILink[] = useMemo(
    () => [
      { to: '/orders/dashboard', name: '대시보드' },
      { to: '/orders', name: '전체 주문 조회' },
      { to: '/orders/before', name: `입금전 주문 관리 (${orderCount.beforeDeposit})` },
      { to: '/orders/new', name: `신규 주문 관리 (${orderCount.newOrder})` },
      { to: '/orders/wait', name: `발주 대기 관리 (${orderCount.waiting})` },
      { to: '/orders/pre', name: `선발주 필요 관리 (${orderCount.preRequired})` },
      { to: '/orders/delay', name: `발주 지연 (${orderCount.delay})` },
      { to: '/orders/complete', name: `주문 완료 관리 (${orderCount.complete})` },
      { to: '/orders/delay-in-shop', name: `발송 지연 (${orderCount.delayInShop})` },
      { to: '/orders/shipping', name: `배송 관리 (${orderCount.shipping})` },
      {
        to: '/orders/shipping-complete',
        name: `배송 완료/구매 확정 (${orderCount.shippingComplete})`,
      },
      {
        to: '/orders/return',
        name: `반품/교환/환불 관리 (${
          orderCount.return + orderCount.exchange + orderCount.refund
        })`,
      },
      { to: '/orders/cancel', name: `주문 취소 관리 (${orderCount.cancel})` },
      { to: '/orders/card-cancel', name: '카드취소 조회' },
      { to: '/orders/check-payment', name: '자동입금확인 관리' },
      { to: '/orders/receipt', name: '현금영수증 관리' },
      { to: '/orders/tax', name: '세금계산서 관리' },
      { to: '/orders/old', name: '(구) 발주' },
    ],
    [orderCount, user]
  );

  const selected = (to: string) => {
    if (to === '/orders/exchange') {
      if (pathname === '/orders/refund') return true;
      if (pathname === '/orders/return') return true;
      if (pathname === '/orders/customs-refund') return true;
    }
    if (to === '/users/dormant') {
      if (pathname === '/users/delete') return true;
    }
    if (to === '/users/send-message') {
      if (pathname === '/users/message') return true;
    }
    if (to === '/users/send-push') {
      if (pathname === '/users/push') return true;
    }
    if (to === '/dashboard/service') {
      if (pathname === '/') return true;
    }
    return to === pathname;
  };

  const sideBars: ILink[] = useMemo(() => {
    if (!user) return [];
    if (pathname === '/') return HOME_MENU_LIST;
    if (pathname.indexOf('product') !== -1) return PRODUCT_MENU_LIST;
    if (pathname.indexOf('orders') !== -1) return ORDER_MENU_LIST;

    if (pathname.indexOf('content') !== -1) return CONTENTS_MENU_LIST;
    if (pathname.indexOf('manage_order') !== -1)
      return [{ to: '/manage_order/order/order', name: '발주' }];
    if (pathname.indexOf('users') !== -1) return USER_MENU_LIST;
    if (pathname.indexOf('dashboard') !== -1) return DASHBOARD_MENU_LIST;
    if (pathname.indexOf('/review') !== -1) return REVIEW_MENU_LIST;
    if (pathname.indexOf('/partner') !== -1) return PARTNER_MENU_LIST;
    return [];
  }, [pathname, orderCount, user]);

  if (
    pathname.indexOf('/user/') !== -1 ||
    (pathname.indexOf('/order/') !== -1 && matchOrder?.params.idx) ||
    pathname.indexOf('/orders/exchange/') !== -1 ||
    pathname.indexOf('/orders/refund/') !== -1 ||
    pathname.indexOf('/orders/return/') !== -1
  )
    return <>{children}</>;

  return (
    <div>
      {/* 헤더 */}
      <header
        className={classnames(
          'fixed top-0 py-4 px-8 flex items-center w-full h-12 z-20',
          IS_MAIN ? 'bg-black' : 'bg-gray-700'
        )}
      >
        <Link
          to="/"
          className="no-underline text-red-400 font-semibold text-2xl hover:text-red-400 hover:no-underline"
        >
          FETCHING ADMIN
        </Link>
        {!!user && (
          <div className="p-1 hidden sm:flex items-center mr-auto">
            {HEADER_MENU_LIST.map((item) => (
              <Link
                to={item.to}
                key={item.to}
                className="no-underline hover:no-underline"
              >
                <span
                  className={classnames('text-lg mx-8 cursor-pointer hover:text-white', {
                    'text-white': pathname.indexOf(item.keyword) !== -1,
                    'text-gray-400': pathname.indexOf(item.keyword) === -1,
                  })}
                >
                  {item.name}
                </span>
              </Link>
            ))}
          </div>
        )}
        {!!user && <UserMenu />}
      </header>

      {/* 사이드바 */}
      {!!user && (
        <nav className="fixed flex flex-col items-center h-full scrollbar-hide w-44 bg-black top-12 z-10 left-0 overflow-auto">
          <ul className="w-full">
            {sideBars.map((item) => (
              <Link
                key={item.to}
                className="no-underline hover:no-underline"
                to={item.to}
              >
                <li
                  className={`py-2 px-4 w-full text-sm hover:bg-gray-900 ${
                    selected(item.to) ? 'text-white font-semibold' : 'text-gray-400'
                  }`}
                >
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>
        </nav>
      )}
      <div className={classnames('pt-12 min-h-screen bg-gray-100', { 'pl-44': !!user })}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
