import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import axios from 'api/axios';
import * as style from './style';
import { Button } from 'components';

const SignUp = ({ history }) => {
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSignUpSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !id || !password || !confirmPassword) {
      alert('빈칸을 모두 작성하세요');
    } else if (password !== confirmPassword) {
      alert('비밀번호가 일치하지 않습니다.');
    } else {
      setIsLoading(true);
      axios
        .post('/api/auth/join', {
          userID: id,
          userPW: password,
          userName: name,
          userMail: email,
        })
        .then((res) => {
          if (res.data.success) {
            history.push('/login');
          } else {
            alert('회원가입 실패!');
            setIsLoading(false);
          }
        })
        .catch((err) => {
          alert(`
          📌 에러가 발생했습니다.! 
          아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    
          API 에러 발생 
          POST - /api/auth/join
          ${err}`);
          console.error(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <style.Wrapper>
      <style.Inner>
        <Form>
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="text"
            placeholder="이름"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="text"
            placeholder="user ID"
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
          />
          
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="email"
            placeholder="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="password"
            placeholder="password confirm"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
          
          <Button
            className="w-full mt-4"
            isLoading={isLoading}
            onClick={handleSignUpSubmit}
          >
            가입
          </Button>
        </Form>
      </style.Inner>
    </style.Wrapper>
  );
};

export default withRouter(SignUp);
