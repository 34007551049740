import React, { useState } from 'react';
import * as style from './style';
import ImageBox from './ImageBox';

const View = ({
  state,
  handleTitle,
  handleIsActive,
  handleIsVisibleToAppMain,
  handleIsVisibleToAppCategory,
  handleIsVisibleToCafe24,
  handleUnlimited,
  handleImage,
  handleDate,
  handleTime,
}) => {
  const [isChecked, setIsChecked] = useState({
    term: !!(state.startedAt || state.endedAt),
    unlimited: !!(!state.startedAt && !state.endedAt),
  });

  return (
    <>
      <style.ItemOuter>
        <style.Item title="true">타이틀</style.Item>
        <style.Item>
          <style.Input value={state.name} onChange={handleTitle} />
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">성별</style.Item>
        <style.Item style={{ display: 'flex' }}>
          <style.Text>{state.gender === 'M' ? '남성' : '여성'}</style.Text>
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">노출 활성화</style.Item>
        <style.Item style={{ display: 'flex' }}>
          <style.Flex>
            <style.CheckBox
              checked={state.isActive === '1'}
              onChange={(e) => handleIsActive(e, '1')}
            />
            <style.Text>활성화</style.Text>
          </style.Flex>
          <style.Flex>
            <style.CheckBox
              checked={state.isActive === '0'}
              onChange={(e) => handleIsActive(e, '0')}
            />
            <style.Text>비활성화</style.Text>
          </style.Flex>
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">노출 위치</style.Item>
        <style.Item>
          <style.Flex style={style.Padding}>
            <style.CheckBox
              checked={state.isVisibleToAppMain === '1'}
              onChange={handleIsVisibleToAppMain}
            />
            <style.Text>
              앱{' > '}
              메인
            </style.Text>
          </style.Flex>
          <style.Flex style={style.Padding}>
            <style.CheckBox
              checked={state.isVisibleToAppCategory === '1'}
              onChange={handleIsVisibleToAppCategory}
            />
            <style.Text>
              앱{' > '}
              카테고리
            </style.Text>
          </style.Flex>
          <style.Flex style={style.Padding}>
            <style.CheckBox
              checked={state.isVisibleToCafe24 === '1'}
              onChange={handleIsVisibleToCafe24}
            />
            <style.Text>
              카페24
              {' > '}
              메인
            </style.Text>
          </style.Flex>
        </style.Item>
      </style.ItemOuter>
      <style.ItemOuter>
        <style.Item title="true">노출 기간</style.Item>
        <style.Item>
          <style.Flex style={style.Padding}>
            <style.CheckBox
              checked={isChecked.unlimited}
              onChange={(e) => {
                setIsChecked({
                  term: !e.target.checked,
                  unlimited: e.target.checked,
                });
                if (e.target.checked) {
                  handleUnlimited();
                }
              }}
            />
            <style.Text>무제한</style.Text>
          </style.Flex>
          <style.Flex style={style.Padding}>
            <style.CheckBox
              checked={isChecked.term}
              onChange={(e) =>
                setIsChecked({
                  term: e.target.checked,
                  unlimited: !e.target.checked,
                })
              }
            />
            <style.Text>기간 입력</style.Text>
          </style.Flex>
          {isChecked.term && (
            <>
              <style.Flex style={style.Padding}>
                <style.Date
                  value={state.startedAt}
                  onChange={(e) => handleDate(e, 'start')}
                />
                <style.Time
                  value={state.startTime}
                  onChange={(e) => handleTime(e, 'start')}
                />
              </style.Flex>
              <style.Flex style={style.Padding}>~</style.Flex>
              <style.Flex style={style.Padding}>
                <style.Date
                  value={state.endedAt}
                  onChange={(e) => handleDate(e, 'end')}
                />
                <style.Time
                  value={state.endTime}
                  onChange={(e) => handleTime(e, 'end')}
                />
              </style.Flex>
            </>
          )}
        </style.Item>
      </style.ItemOuter>
      <ImageBox
        title="상단 고정 배너"
        imageUrl={state.topBannerImageUrl}
        handleImage={handleImage}
      />
      <ImageBox
        title="목록 배너"
        imageUrl={state.bannerImageUrl}
        handleImage={handleImage}
      />
      <ImageBox
        title="랜딩 배너"
        imageUrl={state.landingImageUrl}
        handleImage={handleImage}
      />
    </>
  );
};

export default View;
