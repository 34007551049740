import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';
import { MenuList } from 'components';

const menu = [
  { link: '/dashboard/sales/totalTransactionAmount', title: '총 거래액' },
  { link: '/dashboard/sales/salesAnalysis', title: '매출 분석' },
];

const RouteList = [
  {
    path: '/dashboard/sales/totalTransactionAmount',
    component: pages.TotalTransactionAmount,
    exact: true,
  },
  {
    path: '/dashboard/sales/salesAnalysis',
    component: pages.SalesAnalysis,
  },
];

const Sales = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => (
          <>
            <MenuList items={menu} />
            <route.component />
          </>
        )}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Sales;
