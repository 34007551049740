import React, { createContext, useState } from 'react';

export const ModalContext = createContext({});

export const ModalProvider = ({ children }) => {
  const [modal, setModal] = useState({
    categories: false,
    exhibitions: false,
    promotions: false,
  });

  const handleModalShow = (target, value) => {
    if (target === '카테고리') {
      setModal({
        ...modal,
        categories: value,
      });
    } else if (target === '기획전') {
      setModal({
        ...modal,
        exhibitions: value,
      });
    } else if (target === '프로모션') {
      setModal({
        ...modal,
        promotions: value,
      });
    }
  };

  return (
    <ModalContext.Provider
      value={{
        modal: modal,
        handleModalShow,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};
