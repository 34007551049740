import styled from 'styled-components';

export const Outer = styled.div`
  width: ${(props) => (props.isSideBar ? '80%' : '100%')};
  display: flex;
  justify-content: center;
  padding: 100px 0;
`;

export const Inner = styled.div`
  margin-top: 30px;
  width: 80%;
`;

export const Title = styled.h2``;

export const FilterBoxOuter = styled.div`
  margin-top: 20px;
  border-left: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  border-bottom: 1px solid #c4c4c4;
  width: 100%;
`;

export const FilterBox = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #c4c4c4;
`;

export const ItemTitle = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-right: 1px solid #c4c4c4;
  padding: 15px 20px;
  white-space: nowrap;
  overflow: auto;
`;

export const Item = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  white-space: nowrap;
  overflow: auto;
`;

export const Div = styled.div`
  display: flex;
  padding: 15px 20px;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled.div`
  font-weight: bold;
`;

export const Input = styled.input`
  margin-left: 20px;
  @media all and (min-width: 600px) {
    width: 500px;
  }
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  margin-left: 20px;
  margin-right: 10px;
  cursor: pointer;
  @media all and (min-width: 600px) {
    width: 20px;
    height: 20px;
  }
`;

export const ItemText = styled.div`
  margin-right: 30px;
`;

export const SearchBox = styled.div`
  display: flex;
  width: 100%;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  ${(props) => props.width && 'width: 94px;'}
  height: 42px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  border: 1px;
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: auto;
`;

export const ButtonBar = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  background-color: #1a1a1a;
  padding: 15px 20px;
`;

export const TableTitle = styled.div`
  display: flex;
  width: 100%;
  background-color: #c4c4c4;
  padding: 15px 20px;
  white-space: nowrap;
  overflow: auto;
`;

export const TableItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width}%;
  font-weight: bold;
  white-space: nowrap;
  overflow: auto;
`;

export const AllCheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 30px;
  height: 30px;
`;
