import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as pages from 'pages';
import { MenuList } from 'components';

const menu = [
  { link: '/dashboard/inflow/inflowTotal', title: '총 유입수' },
  { link: '/dashboard/inflow/newInflow', title: '신규 유입수' },
  { link: '/dashboard/inflow/reInflow', title: '재 유입수' },
  { link: '/dashboard/inflow/inflowRate', title: '신규 및 재유입수' },
  { link: '/dashboard/inflow/originInflow', title: '순 유입수' },
  {
    link: '/dashboard/inflow/originInflowAverage',
    title: '순 유입당 평균 유입 횟수',
  },
  { link: '/dashboard/inflow/inflowByChannelRate', title: '채널별 유입 비율' },
  { link: '/dashboard/inflow/userEvent', title: '유저 이벤트' },
];

const RouteList = [
  {
    path: '/dashboard/inflow/inflowTotal',
    component: pages.InflowTotal,
    exact: true,
  },
  {
    path: '/dashboard/inflow/newInflow',
    component: pages.NewInflow,
  },
  {
    path: '/dashboard/inflow/reInflow',
    component: pages.ReInflow,
  },
  {
    path: '/dashboard/inflow/inflowRate',
    component: pages.InflowRate,
  },
  {
    path: '/dashboard/inflow/originInflow',
    component: pages.OriginInflow,
  },
  {
    path: '/dashboard/inflow/originInflowAverage',
    component: pages.OriginInflowAverage,
  },
  {
    path: '/dashboard/inflow/inflowByChannelRate',
    component: pages.InflowByChannelRate,
  },
  {
    path: '/dashboard/inflow/userEvent',
    component: pages.UserEvent,
  },
];

const Inflow = () => (
  <Switch>
    {RouteList.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        render={() => (
          <>
            <MenuList items={menu} />
            <route.component />
          </>
        )}
        exact={route.exact}
      />
    ))}
  </Switch>
);

export default Inflow;
