import React from 'react';
import * as style from './style';

const CatalogMatchingStatus = ({ searchOptionTableState, setSearchOptionTableState }) => {
    return (
        <style.Container>
            <style.Div onChange={(e) => {
                setSearchOptionTableState({
                    ...searchOptionTableState,
                    isCatalogueMatch: e.target.value,
                });
            }}>
                <style.Radio name='isCatalogueMatch' value='null' checked={searchOptionTableState.isCatalogueMatch === 'null'} /><style.Label>전체</style.Label>
                <style.Radio name='isCatalogueMatch' value='true' checked={searchOptionTableState.isCatalogueMatch === 'true'} /><style.Label>매칭됨</style.Label>
                <style.Radio name='isCatalogueMatch' value='false' checked={searchOptionTableState.isCatalogueMatch === 'false'} /><style.Label>매칭안됨</style.Label>
            </style.Div>
        </style.Container>
    );
};

export default CatalogMatchingStatus;