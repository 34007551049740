import styled from 'styled-components';

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  border: 1px solid #ddd;
  height: 700px;
  margin-top: 10px;
`;

export const TableTitle = styled.div`
  display: flex;
  min-width: 500px;
  background-color: #9e979799;
  align-items: center;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
  margin: 10px;
`;

export const Title = styled.div`
  font-weight: bold;
  text-align: center;
  padding: 5px 10px;
  width: calc(100% / 5);
`;

export const TableItemWrapper = styled.div`
  width: 100%;
  min-width: 500px;
  height: 650px;
  overflow: auto;
`;
