export const getThisMonth = () => {
  const today = new Date();
  let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  firstDay = getResultDate(
    firstDay.getFullYear(),
    firstDay.getMonth() + 1,
    firstDay.getDate(),
  );
  lastDay = getResultDate(
    lastDay.getFullYear(),
    lastDay.getMonth() + 1,
    lastDay.getDate(),
  );

  return [firstDay, lastDay];
};

const getResultDate = (year, month, date) => {
  const getMonth = month < 10 ? `0${month}` : `${month}`;
  const getDate = date < 10 ? `0${date}` : `${date}`;
  const resultDate = `${year}-${getMonth}-${getDate}`;

  return resultDate;
};

export const parseChartData = (result, byDate) => {
  result.APP_FETCHING = byDate.map((el) => ({
    label: el.date,
    y: el.ratio.APP_FETCHING,
  }));
  result.APP_NAVERPAY = byDate.map((el) => ({
    label: el.date,
    y: el.ratio.APP_NAVERPAY,
  }));
  result.APP_SHOP = byDate.map((el) => ({
    label: el.date,
    y: el.ratio.APP_SHOP,
  }));
  result.CAFE24_MOBILE = byDate.map((el) => ({
    label: el.date,
    y: el.ratio.CAFE24_MOBILE,
  }));
  result.CAFE24_PC = byDate.map((el) => ({
    label: el.date,
    y: el.ratio.CAFE24_PC,
  }));
  result.NAVERPAY = byDate.map((el) => ({
    label: el.date,
    y: el.ratio.NAVERPAY,
  }));
  result.SMARTSTORE = byDate.map((el) => ({
    label: el.date,
    y: el.ratio.SMARTSTORE,
  }));

  result.APP_FETCHING_COUNT = byDate.map((el) => ({
    label: el.date,
    y: el.sum.APP_FETCHING,
  }));
  result.APP_NAVERPAY_COUNT = byDate.map((el) => ({
    label: el.date,
    y: el.sum.APP_NAVERPAY,
  }));
  result.APP_SHOP_COUNT = byDate.map((el) => ({
    label: el.date,
    y: el.sum.APP_SHOP,
  }));
  result.CAFE24_MOBILE_COUNT = byDate.map((el) => ({
    label: el.date,
    y: el.sum.CAFE24_MOBILE,
  }));
  result.CAFE24_PC_COUNT = byDate.map((el) => ({
    label: el.date,
    y: el.sum.CAFE24_PC,
  }));
  result.NAVERPAY_COUNT = byDate.map((el) => ({
    label: el.date,
    y: el.sum.NAVERPAY,
  }));
  result.SMARTSTORE_COUNT = byDate.map((el) => ({
    label: el.date,
    y: el.sum.SMARTSTORE,
  }));

  return result;
};

export const convertChartData = (template, data) =>
  template.map((column, index) => ({
    ...column,
    dataPoints: data[index],
  }));

export const templateChartOptionsCount = [
  {
    type: 'stackedColumn',
    name: 'App 페칭 결제',
    showInLegend: true,
    color: '#641884',
  },
  {
    type: 'stackedColumn',
    name: 'App 네이버페이',
    showInLegend: true,
    color: '#23a089',
  },
  {
    type: 'stackedColumn',
    name: 'App 편집샵 결제',
    showInLegend: true,
    color: '#2f2d2d',
  },
  {
    type: 'stackedColumn',
    name: '카페24 모바일',
    showInLegend: true,
    color: '#d67e11',
  },
  {
    type: 'stackedColumn',
    name: '카페24 PC',
    showInLegend: true,
    color: '#204b79',
  },
  {
    type: 'stackedColumn',
    name: '네이버페이',
    showInLegend: true,
    color: '#409813',
  },
  {
    type: 'stackedColumn',
    name: '스마트스토어',
    showInLegend: true,
    color: '#C19881',
  },
];

export const templateChartOptionsRate = [
  {
    type: 'stackedColumn100',
    name: 'App 페칭 결제',
    showInLegend: true,
    color: '#641884',
  },
  {
    type: 'stackedColumn100',
    name: 'App 네이버페이',
    showInLegend: true,
    color: '#23a089',
  },
  {
    type: 'stackedColumn100',
    name: 'App 편집샵 결제',
    showInLegend: true,
    color: '#2f2d2d',
  },
  {
    type: 'stackedColumn100',
    name: '카페24 모바일',
    showInLegend: true,
    color: '#d67e11',
  },
  {
    type: 'stackedColumn100',
    name: '카페24 PC',
    showInLegend: true,
    color: '#204b79',
  },
  {
    type: 'stackedColumn100',
    name: '네이버페이',
    showInLegend: true,
    color: '#409813',
  },
  {
    type: 'stackedColumn100',
    name: '스마트스토어',
    showInLegend: true,
    color: '#C19881',
  },
];

export const templateChartPie = {
  type: 'pie',
  startAngle: 75,
  toolTipContent: '<b>{label}</b>: {y}%',
  showInLegend: 'true',
  legendText: '{label}',
  indexLabelFontSize: 16,
  indexLabel: '{label} - {y}%',
};
