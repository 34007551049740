import React from 'react';
import { Button } from 'react-bootstrap';
import dayjs from 'dayjs';
import * as style from './style';
import { DISCOUNT_TYPE, APPLY_STATUS, DISPLAY_AT } from '../constants';
import { Popover } from 'antd';

const PromotionTableItem = ({
  item,
  onSelect,
  selectedItem,
  unregisterShopPromotion,
  registerShopPromotion,
}) => {
  const {
    id,
    shopName,
    isActive,
    isPrioritized,
    isAutomated,
    isPriority,
    itemCount,
    like,
    sales,
    view,
    cart,
    assignee,
    applyStatus,
    name,
    rules,
    startedAt,
    endedAt,
    updatedAt,
    statusUpdatedAt,
    verifiedItemCount,
    typeValue,
    type,
  } = item;

  const renderPromotionPeriod = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <div>{startedAt ? dayjs(startedAt).format('YYYY-MM-DD') : '없음'} </div>
        <div>~</div>
        <div>{endedAt ? dayjs(endedAt).format('YYYY-MM-DD') : '상시진행'} </div>
      </div>
    );
  };

  const renderDisplyed = () => {
    return DISPLAY_AT.map((option) =>
      item[option.field] ? <div style={style.alignLeft}>{option.name}</div> : <div />
    );
  };

  const isChecked = selectedItem.findIndex((item) => item === id) > -1;

  return (
    <style.Tr style={{ height: '118px', fontSize: '12px' }}>
      <style.Td w={38}>
        <style.Checkbox onClick={() => onSelect(id)} checked={isChecked} />
      </style.Td>
      <style.Td w={69}>
        {isActive ? (
          <span style={{ color: '#498935' }}>ON</span>
        ) : (
          <span style={{ color: '#FF0707' }}>OFF</span>
        )}
      </style.Td>
      <style.Td w={40}>
        {isPrioritized ? (
          <span style={{ color: '#498935' }}>중요</span>
        ) : (
          <span style={{ color: '#FF0707' }}>null</span>
        )}
      </style.Td>
      <style.Td w={80}>
        {isAutomated ? (
          <span style={{ color: '#498935' }}>자동</span>
        ) : (
          <span style={{ color: '#FF0707' }}>수동</span>
        )}
      </style.Td>
      <style.Td w={69}>
        {isPriority ? (
          <Button variant="danger" onClick={() => unregisterShopPromotion(item)}>
            해제
          </Button>
        ) : (
          <Button variant="success" onClick={() => registerShopPromotion(item)}>
            등록
          </Button>
        )}
      </style.Td>
      <style.Td w={100}>
        <span style={style.alignLeft}>{shopName}</span>
      </style.Td>
      <style.Td w={110}>
        <Popover
          content={
            <div>
              {rules?.map((rule) => (
                <p key={rule.id}>코드 : {rule?.code ? rule.code : '해당 없음.'}</p>
              ))}
            </div>
          }
          title="적용 규칙"
        >
          <a
            href={`modifyPromotion/${id}`}
            target="_blank"
            rel="noreferrer"
            style={style.alignLeft}
          >
            {name}
          </a>
        </Popover>
      </style.Td>
      <style.Td w={69} style={{ justifyContent: 'flex-start' }}>
        {typeValue &&
          type === DISCOUNT_TYPE.DISCOUNT_WITH_RATE &&
          typeValue.map((value, index) => (
            <div key={index}>{`${String((value * 100).toFixed(0))}%`}</div>
          ))}
      </style.Td>
      <style.Td w={100}>{renderPromotionPeriod()}</style.Td>
      <style.Td w={80}>{assignee}</style.Td>
      <style.Td w={110}>
        <div>{APPLY_STATUS[applyStatus] && APPLY_STATUS[applyStatus].name}</div>
        <div>
          <span style={{ color: '#a4a4a4' }}>
            {dayjs(statusUpdatedAt).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        </div>
      </style.Td>
      <style.Td w={69}>
        <div>
          <span style={{ color: '#498935' }}>
            {verifiedItemCount && Number(verifiedItemCount).toLocaleString()}
          </span>
        </div>
        <div>{itemCount && Number(itemCount).toLocaleString()}</div>
      </style.Td>
      <style.Td w={110}>{renderDisplyed()}</style.Td>
      <style.Td w={69}>{view && Number(view).toLocaleString('en')}</style.Td>
      <style.Td w={69}>{like && Number(like).toLocaleString('en')}</style.Td>
      <style.Td w={69}>{cart && Number(cart).toLocaleString('en')}</style.Td>
      <style.Td w={69}>{sales && Number(sales).toLocaleString('en')}</style.Td>
    </style.Tr>
  );
};

export default PromotionTableItem;
