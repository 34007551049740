import { TableItem } from 'components/UI/molecules';
import React, { useRef, useEffect } from 'react';
import * as style from './style';

const ShopBasicInfo = ({
  shop,
  modifyShop,
  setModifyShop,
  handleClinentHeight,
}) => {
  const shopTypes = ['해외편집샵', '국내편집샵', '구매대행', '병행수입'];

  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('기본 정보', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('기본 정보', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>기본 정보</style.Title>
      <style.BoxWrapper>
        <TableItem title="편집샵 ID" value={shop?.id} />
        <TableItem title="우선순위" value={shop?.priority} />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem title="국가" value={shop?.country} />
        <TableItem
          title="언어"
          value={shop?.lang === 'KR' ? '한국어' : '영어'}
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <style.ItemWrapper>
          <style.ItemTitle>편집샵 유형</style.ItemTitle>
          <style.ItemValue>
            <select
              onChange={(e) => {
                setModifyShop({
                  ...modifyShop,
                  type: e.target.value,
                });
              }}
            >
              <option value={shop?.type}>{shop?.type}</option>
              {shopTypes
                .filter((el) => el !== shop?.type)
                ?.map((shopType) => {
                  return (
                    <option key={shopType} value={shopType}>
                      {shopType}
                    </option>
                  );
                })}
            </select>
          </style.ItemValue>
        </style.ItemWrapper>
        <TableItem
          title="인기 편집샵"
          value={shop?.isPrioritized === 1 ? '해당' : '미해당'}
          valueColor={shop?.isPrioritized === 1 ? 'green' : 'red'}
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem title="통화기준" value={shop?.currency} />
        <TableItem
          title="성별"
          value={
            shop?.gender === 'M'
              ? '남성'
              : shop?.gender === 'W'
              ? '여성'
              : '남여공용'
          }
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem
          title="상품 수집 경로"
          value={shop?.getDataFrom === 'CRAWLING' ? '크롤링' : '피드'}
        />
        <TableItem title="-" value="-" />
      </style.BoxWrapper>
    </style.Wrapper>
  );
};
export default ShopBasicInfo;
