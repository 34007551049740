import React from 'react';
import * as style from './style';

const CommerceUserAllowedTd = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  return (
    <style.Container>
      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              emailAllowed: !searchOptionTableState.emailAllowed,
            })
          }
          checked={searchOptionTableState.emailAllowed}
          onChange={() => {}}
        />
        이메일
      </style.Label>

      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              smsAllowed: !searchOptionTableState.smsAllowed,
            })
          }
          checked={searchOptionTableState.smsAllowed}
          onChange={() => {}}
        />
        SMS
      </style.Label>

      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              marketingPushAllowed:
                !searchOptionTableState.marketingPushAllowed,
            })
          }
          checked={searchOptionTableState.marketingPushAllowed}
          onChange={() => {}}
        />
        마케팅
      </style.Label>

      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              pushAllowed: !searchOptionTableState.pushAllowed,
            })
          }
          checked={searchOptionTableState.pushAllowed}
          onChange={() => {}}
        />
        푸시
      </style.Label>

      <style.Label>
        <style.Checkbox
          onClick={() =>
            setSearchOptionTableState({
              ...searchOptionTableState,
              nightPushAllowed: !searchOptionTableState.nightPushAllowed,
            })
          }
          checked={searchOptionTableState.nightPushAllowed}
          onChange={() => {}}
        />
        야간 푸시
      </style.Label>
    </style.Container>
  );
};

export default CommerceUserAllowedTd;
