import React from 'react';
import * as style from '../style';

const ButtonBar = ({ handleShowModal }) => (
  <style.ButtonBar>
    <style.Button
      backgroundColor="#35256D"
      color="#FFFFFF"
      onClick={() => handleShowModal('노출 위치 변경')}
    >
      노출 위치 변경 {'>'}
    </style.Button>
    <style.Button
      backgroundColor="#35256D"
      color="#FFFFFF"
      onClick={() => handleShowModal('활성화 변경')}
    >
      활성화 변경 {'>'}
    </style.Button>
    <style.Button
      backgroundColor="#FF6F61"
      color="#1A1A1A"
      onClick={() => handleShowModal('선택 삭제')}
    >
      선택 삭제
    </style.Button>
    <style.Button
      backgroundColor="#69ABF9"
      color="#1A1A1A"
      onClick={() => {
        window.open('/product/addExhibition');
      }}
    >
      기획전 추가 +
    </style.Button>
  </style.ButtonBar>
);

export default ButtonBar;
