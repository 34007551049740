import React from 'react';
import { IoMdRefresh } from 'react-icons/io';
import * as style from './style';

const Header = ({
  product,
  handleChangeCurrnetMenu,
  curruntTarget,
  refs: [displayRef, classificationRef, productRef, priceRef],
  mode,
}) => {
  return (
    <style.HeaderButtonWrapper>
      <div>
        {mode === 'update' && (
          <>
            <style.Button
              color="#69ABF9"
              onClick={() => window.open(product?.cafe24Url || '/')}
            >
              카페24 이동
            </style.Button>
            <style.Button
              color="#FF6F61"
              onClick={() =>
                window.open(
                  `https://link.fetching.co.kr/item/${product.fetchingCode}/shop?doNotTrack=true`
                )
              }
            >
              편집샵 이동
            </style.Button>
            <IoMdRefresh
              style={style.IconStyle}
              onClick={() => window.location.reload()}
            />
          </>
        )}
      </div>

      <style.MenuList>
        {mode === 'update' && (
          <style.Menu
            isActive={curruntTarget === '진열설정'}
            onClick={() => handleChangeCurrnetMenu('진열설정', displayRef)}
          >
            진열설정
          </style.Menu>
        )}

        <style.Menu
          isActive={curruntTarget === '분류정보'}
          onClick={() => handleChangeCurrnetMenu('분류정보', classificationRef)}
        >
          분류정보
        </style.Menu>
        <style.Menu
          isActive={curruntTarget === '상품정보'}
          onClick={() => handleChangeCurrnetMenu('상품정보', productRef)}
        >
          상품정보
        </style.Menu>
        <style.Menu
          isActive={curruntTarget === '가격정보'}
          onClick={() => handleChangeCurrnetMenu('가격정보', priceRef)}
        >
          가격정보
        </style.Menu>
      </style.MenuList>
    </style.HeaderButtonWrapper>
  );
};

export default Header;
