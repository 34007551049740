import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingContext } from 'context';
import {
  removeSelectAllCategoryAllItemAction,
  removeSelectAllCategoryAction,
} from 'actions/action';
import Form from 'react-bootstrap/Form';
import { IoIosSync, IoIosTrash } from 'react-icons/io';
import {
  CategoryProductsTable,
  PageButtonList,
  Title,
  DuplicateOption,
} from 'components';
import { axios, getCategories, getShopList } from 'api/axios';
import * as style from './style';
import { Spinner } from 'components';

const ShopCategoryMapping = () => {
  const dispatch = useDispatch();

  const itemIds = useSelector((state) => state.selectCategory.itemIds);

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [shopList, setShopList] = useState([]);
  const [categories, setCategories] = useState([]);

  const [duplicateOption, setDuplicateOption] = useState({
    categoryIdList: [],
  });

  const [shopId, setShopId] = useState(null);
  const [isMapped, setIsMapped] = useState('');
  const [gender, setGender] = useState('');
  const [keyword, setKeyword] = useState('');
  const [amountForPage, setAmountForPage] = useState('100');
  const [pageList, setPageList] = useState([1, 2, 3, 4, 5]);
  const [selectPage, setSelectPage] = useState(1);
  const [items, setItems] = useState([]);
  const [totalItemCount, setTotalItemCount] = useState(null);

  const handleCategoryChecked = (e) => {
    let categoryIdList = duplicateOption.categoryIdList;
    categoryIdList = categoryIdList.filter(
      (categoryId) => categoryId !== Number(e.target.value)
    );

    if (e.target.checked) {
      categoryIdList.push(Number(e.target.value));
      setDuplicateOption({
        ...duplicateOption,
        categoryIdList,
      });
    } else {
      setDuplicateOption({
        ...duplicateOption,
        categoryIdList,
      });
    }
  };

  const handleAllCategoryChecked = (e) => {
    if (e.target.checked) {
      const result = categories.map((category) => category.id);
      setDuplicateOption({
        ...duplicateOption,
        categoryIdList: result,
      });
    } else {
      setDuplicateOption({
        ...duplicateOption,
        categoryIdList: [],
      });
    }
  };

  const handleGetMappingRequired = useCallback(
    async (page) => {
      try {
        handleIsLoading(true);
        dispatch(removeSelectAllCategoryAction());
        dispatch(removeSelectAllCategoryAllItemAction());
        setItems([]);

        const params = {
          shopID: shopId,
          isMapped: isMapped ? Boolean(Number(isMapped)) : null,
          shopCategory: null,
          keyword: keyword.length ? keyword : null,
          page: Number(page),
          amountForPage: Number(amountForPage),
        };

        if (gender) {
          params.gender = gender;
        }

        const res = await axios.get('/api/item/mapping-required', {
          params,
        });

        setTotalItemCount(res.data.count);
        setItems(res.data.data);
        setSelectPage(page);
        handleIsLoading(false);
      } catch (err) {
        handleIsLoading(false);
        alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/item/mapping-required
      ${err}`);
        console.error(err);
      }
    },
    [selectPage, keyword, gender, shopId, isMapped, amountForPage]
  );

  const beforeSearchPage = () => {
    handleGetMappingRequired(`${pageList[0] - 1}`);
  };

  const nextSearchPage = () => {
    handleGetMappingRequired(`${pageList[pageList.length - 1] + 1}`);
  };

  const handleCategoryMapping = async () => {
    const categories = duplicateOption.categoryIdList;

    if (!categories.length) {
      alert('매핑할 카테고리를 선택해주세요.');
      return;
    }

    if (!itemIds.length) {
      alert('매핑할 상품을 선택해주세요.');
      return;
    }

    handleIsLoading(true);
    const res = await axios.post('/api/item/category-maps/bulk', {
      itemIDs: itemIds,
      categories,
    });
    if (res.status !== 204) {
      alert(res.status);
    }
    handleGetMappingRequired('1');
    handleIsLoading(false);
  };

  const handleDeleteCategoryMapping = async () => {
    const categories = duplicateOption.categoryIdList;

    if (!categories.length) {
      alert('삭제할 카테고리를 선택해주세요.');
      return;
    }

    if (!itemIds.length) {
      alert('삭제할 상품을 선택해주세요.');
      return;
    }

    handleIsLoading(true);
    const res = await axios.delete('/api/item/category-maps/bulk', {
      data: {
        itemIDs: itemIds,
        categories,
      },
    });
    if (res.status !== 204) {
      alert(res.status);
    }
    handleGetMappingRequired('1');
    handleIsLoading(false);
  };

  useEffect(() => {
    let initial = true;

    const fetchData = async () => {
      const resultCategories = await getCategories();
      const resultShopList = await getShopList();
      if (initial) {
        setCategories(resultCategories);
        setShopList(resultShopList);
        setShopId(resultShopList[0].id);
      }
      dispatch(removeSelectAllCategoryAction());
      dispatch(removeSelectAllCategoryAllItemAction());
    };

    fetchData();

    return () => {
      initial = false;
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      await handleGetMappingRequired('1');
    };
    if (shopId) {
      fetchData();
    }
  }, [shopId]);

  return (
    <style.Outer>
      <style.Inner>
        <Title
          title={'편집샵 개별 매핑'}
          description={'개별 상품마다 카테고리를 단독으로 매핑할 수 있습니다.'}
        />
        <style.ShopSelect
          onChange={(e) => {
            setShopId(e.target.value);
          }}
        >
          {shopList.map((shop) => {
            return (
              <option key={shop.id} value={shop.id}>
                {shop.krName}
              </option>
            );
          })}
        </style.ShopSelect>
        <style.CategoryMappingBox>
          <style.CategoryTable>
            <style.FormFilter>
              <Form.Control
                as="select"
                style={style.FormFilterItem}
                onChange={(e) => {
                  if (e.target.value === '매핑 여부') {
                    setIsMapped(null);
                  } else {
                    setIsMapped(e.target.value);
                  }
                }}
              >
                <option>매핑 여부</option>
                <option value={1}>매핑 함</option>
                <option value={0}>매핑 안함</option>
              </Form.Control>
              <Form.Control
                as="select"
                style={style.FormFilterItem}
                onChange={(e) => {
                  setGender(e.target.value);
                }}
              >
                <option value={''}>성별</option>
                <option value={'M'}>남</option>
                <option value={'W'}>여</option>
              </Form.Control>
              <Form.Control
                as="select"
                style={style.FormFilterItem}
                onChange={(e) => {
                  setAmountForPage(e.target.value);
                }}
              >
                <option value={100}>100개</option>
                <option value={300}>300개</option>
                <option value={500}>500개</option>
                <option value={1000}>1000개</option>
              </Form.Control>
            </style.FormFilter>
            <style.Input
              placeholder="검색어를 입력하세요"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <style.Button
              BC={'black'}
              color={'white'}
              onClick={() => handleGetMappingRequired('1')}
            >
              검색
            </style.Button>
            <p style={style.MarginTop20}>
              총 {totalItemCount}의 {items.length * Number(selectPage)} 개
            </p>
            <style.ButtonWrapper>
              <style.Button
                color={'white'}
                BC={'#FF4747'}
                onClick={handleDeleteCategoryMapping}
              >
                <IoIosTrash /> 매핑 일괄 삭제
              </style.Button>
              <style.P color={'#707070'}>
                모든 페칭 카테고리와의 매핑을 삭제합니다. (앱{' & '}카페24 미노출)
              </style.P>
            </style.ButtonWrapper>
            <style.ItemTable>
              {isLoading ? (
                <style.LoadingWrapper>
                  <Spinner className="h-20 w-20 text-black" />
                </style.LoadingWrapper>
              ) : (
                <CategoryProductsTable
                  items={items}
                  selectPage={selectPage}
                  category={categories}
                  amountForPage={amountForPage}
                />
              )}
            </style.ItemTable>
            <style.PageButtonWrapper>
              <style.PageButton
                BC={'gray'}
                onClick={() => {
                  const newPageList = [];
                  const end = pageList[0];
                  if (pageList[0] !== 1) {
                    for (let i = end - 1; i >= end - 5; i--) {
                      newPageList.push(i);
                    }
                    setPageList(newPageList.reverse());
                    beforeSearchPage();
                  }
                }}
              >
                {`<<`}
              </style.PageButton>
              <PageButtonList
                page={selectPage}
                buttonList={pageList}
                setPage={handleGetMappingRequired}
              />
              <style.PageButton
                BC={'gray'}
                onClick={() => {
                  const newPageList = [];
                  const start = pageList[pageList.length - 1];
                  for (let i = start + 1; i <= start + 5; i++) {
                    newPageList.push(i);
                  }
                  setPageList(newPageList);
                  nextSearchPage();
                }}
              >
                {`>>`}
              </style.PageButton>
            </style.PageButtonWrapper>
          </style.CategoryTable>
          <style.CategoryMappingButtonOuter>
            <IoIosSync style={style.MappingButton} />
            <style.Button
              color={'white'}
              BC={'#0085FF'}
              variant="outline-secondary"
              onClick={handleCategoryMapping}
            >
              매핑 하기
            </style.Button>
            <style.P color={'#707070'}>
              기존 매핑을 모두 초기화하고 선택한 페칭 카테고리로 새롭게 매핑합니다.
            </style.P>
          </style.CategoryMappingButtonOuter>
          <style.CategoryBox>
            <DuplicateOption
              title={'매핑 카테고리'}
              items={categories}
              onChange={handleCategoryChecked}
              duplicateOption={duplicateOption}
              handleAllChecked={handleAllCategoryChecked}
              height={'700px'}
            />
          </style.CategoryBox>
        </style.CategoryMappingBox>
      </style.Inner>
    </style.Outer>
  );
};

export default ShopCategoryMapping;
