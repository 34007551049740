export default {
  searchType: 'FETCHING_CODE',
  searchKey: '',

  isSellable: 'null',
  isFetchingPayActive: 'null',
  isShopPayActive: 'null',

  priorityList: [],
  priorityString: 'null', //임의추가
  maxPrioritySetAt: null,
  minPrioritySetAt: null,

  isShopDiscount: 'null',
  hasPromotion: 'null',
  hasExhibition: 'null',

  sort: 99,
  sortWay: 0,
  eventDuration: null,

  isNaverAdMaterial: false,

  hasView: false,
  hasLike: false,
  hasCart: false,
  hasSales: false,

  priceMode: 'null', //임의추가
  //
  o: 0,
  loooooooooo: 10000000000,
  //
  minDiscountedPrice: 0,
  maxDiscountedPrice: 10000000000,
  //
  maxTotalPrice: 0,
  minTotalPrice: 10000000000,
  //
  maxFinalPrice: 0,
  minFinalPrice: 10000000000,

  country: 'null',

  shopIdList: [],
  brandIdList: [],
  categoryIdList: [],
  promotionIdList: [],
  exhibitionIdList: [],

  priceRuleIdList: [],

  fetchingPay: 'null',
  gender: 'null',
  limit: 100,
  needCount: false,
  sale: 'null',
  screen: 'null',
};

export const automationDefaultOption = {
  isSellable: 'null',
  isShopPayActive: 'null',
  isFetchingPayActive: 'null',

  priorityList: [],
  priorityString: 'null', //임의추가
  maxPrioritySetAt: null,
  minPrioritySetAt: null,

  isShopDiscount: 'null',
  hasPromotion: 'null',
  hasExhibition: 'null',

  hasView: false,
  hasLike: false,
  hasCart: false,
  hasSales: false,

  priceMode: 'null', //임의추가
  //
  o: 0,
  loooooooooo: 10000000000,
  //
  minDiscountedPrice: 0,
  maxDiscountedPrice: 10000000000,
  //
  maxTotalPrice: 0,
  minTotalPrice: 10000000000,
  //
  maxFinalPrice: 0,
  minFinalPrice: 10000000000,

  country: 'null',

  shopIdList: [],
  brandIdList: [],
  categoryIdList: [],
  promotionIdList: [],
  exhibitionIdList: [],
  priceRuleIdList: [],

  keyword: '',

  fetchingPay: 'null',
  gender: 'null',
  limit: 100,
  needCount: false,
  sale: 'null',
  screen: 'null',
};

export const promotionAutomationDefaultOption = {
  isVerify: 'null',
  isCafe24Active: 'null',
  cafe24UpdateAble: 'null',

  priorityList: [],
  priorityString: 'null', //임의추가
  maxPrioritySetAt: null,
  minPrioritySetAt: null,

  isShopDiscount: 'null',
  hasPromotion: 'null',
  hasExhibition: 'null',

  hasView: false,
  hasLike: false,
  hasCart: false,
  hasSales: false,

  priceMode: 'null', //임의추가
  //
  o: 0,
  loooooooooo: 10000000000,
  //
  minDiscountedPrice: 0,
  maxDiscountedPrice: 10000000000,
  //
  maxTotalPrice: 0,
  minTotalPrice: 10000000000,
  //
  maxFinalPrice: 0,
  minFinalPrice: 10000000000,

  country: 'null',

  shopIdList: [],
  brandIdList: [],
  categoryIdList: [],
  promotionIdList: [],
  exhibitionIdList: [],
  priceRuleIdList: [],

  fetchingPay: 'null',
  gender: 'null',
  limit: 100,
  needCount: false,
  sale: 'null',
  screen: 'null',
};
