import React, { useState } from 'react';
import * as style from './style';
import SearchBy from './SearchBy';
import CatalogMatchingStatus from './CatalogMatchingStatus';
import PriceBy from './PriceBy';
import { OptionCheckboxList, PriorityTd } from 'components';

const CatalogSearchOption = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const [showDetail, setShowDetail] = useState(false);

  const tableData = [
    {
      Left: '검색기준',
      Td: (
        <SearchBy
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '카탈로그 매칭상태',
      Td: (
        <CatalogMatchingStatus
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '가격기준',
      Td: (
        <PriceBy
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '우선순위',
      Td: (
        <PriorityTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
  ];

  return (
    <>
      <style.Table>
        {tableData.map((data, index) => (
          <style.Tr key={index}>
            <style.Left>{data.Left}</style.Left>
            <style.Td>{data.Td}</style.Td>
          </style.Tr>
        ))}
        {showDetail && (
          <style.Tr>
            <style.Left>상세조건</style.Left>
            <style.Td>
              <OptionCheckboxList
                searchOptionTableState={searchOptionTableState}
                setSearchOptionTableState={setSearchOptionTableState}
              />
            </style.Td>
          </style.Tr>
        )}
      </style.Table>
      <style.showDetailButton onClick={() => setShowDetail(!showDetail)}>
        {showDetail ? '상세조건닫기' : '상세조건열기'}
      </style.showDetailButton>
    </>
  );
};

export default CatalogSearchOption;
