import React, { useEffect, useState } from 'react';
import * as S from './style';
import { getUserList } from 'api/axios';

const SearchTable = ({ options, setOptions = () => {} }) => {
  const { title, gender, isActive, adminName, displayedAt, start, end } =
    options;

  return (
    <S.Table>
      <S.Tbody>
        <SearchByTitle
          value={title}
          onChange={(value) => setOptions((p) => ({ ...p, title: value }))}
        />
        <SearchByGender
          value={gender}
          onChange={(value) => setOptions((p) => ({ ...p, gender: value }))}
        />
        <SearchByActive
          value={isActive}
          onChange={(value) => setOptions((p) => ({ ...p, isActive: value }))}
        />
        <SearchByVerified
          value={displayedAt}
          onChange={(value) =>
            setOptions((p) => ({ ...p, displayedAt: value }))
          }
        />
        <SearchByAdmin
          value={adminName}
          onChange={(value) => setOptions((p) => ({ ...p, adminName: value }))}
        />
        <SearchByPeriod
          value={{ start, end }}
          onChange={(start, end) => setOptions((p) => ({ ...p, start, end }))}
        />
      </S.Tbody>
    </S.Table>
  );
};

const SearchByGender = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>성별</S.LeftTd>
      <S.Td>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === null}
            onChange={() => onChange(null)}
          />
          전체
        </S.Label>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === 'M'}
            onChange={() => onChange('M')}
          />
          남성
        </S.Label>
        <S.Label>
          <S.Radio
            name="gender"
            checked={value === 'W'}
            onChange={() => onChange('W')}
          />
          여성
        </S.Label>
      </S.Td>
    </S.Tr>
  );
};

const SearchByTitle = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>타이틀</S.LeftTd>
      <S.Td>
        <S.TextArea
          value={value || ''}
          onChange={(e) => onChange(e.target.value || null)}
        />
      </S.Td>
    </S.Tr>
  );
};

const SearchByActive = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>활성상태</S.LeftTd>
      <S.Td>
        <S.Label>
          <S.Radio
            name="isActive"
            checked={value === null}
            onChange={() => onChange(null)}
          />
          전체
        </S.Label>
        <S.Label>
          <S.Radio
            name="isActive"
            checked={value === true}
            onChange={() => onChange(true)}
          />
          활성화
        </S.Label>
        <S.Label>
          <S.Radio
            name="isActive"
            checked={value === false}
            onChange={() => onChange(false)}
          />
          비활성화
        </S.Label>
      </S.Td>
    </S.Tr>
  );
};

const SearchByVerified = ({ value = null, onChange = () => {} }) => {
  return (
    <S.Tr>
      <S.LeftTd>노출 위치</S.LeftTd>
      <S.Td>
        <S.Label>
          <S.Radio
            name="displayedAt"
            checked={value === null}
            onChange={() => onChange(null)}
          />
          전체
        </S.Label>
        <S.Label>
          <S.Radio
            name="displayedAt"
            checked={value === 'isVisibleToAppMain'}
            onChange={() => onChange('isVisibleToAppMain')}
          />
          앱 메인
        </S.Label>
        <S.Label>
          <S.Radio
            name="displayedAt"
            checked={value === 'isVisibleToAppCategory'}
            onChange={() => onChange('isVisibleToAppCategory')}
          />
          앱 모아보기
        </S.Label>
        <S.Label>
          <S.Radio
            name="displayedAt"
            checked={value === 'isVisibleToCafe24'}
            onChange={() => onChange('isVisibleToCafe24')}
          />
          웹 pc
        </S.Label>
        <S.Label>
          <S.Radio
            name="displayedAt"
            checked={value === 'isVisibleToCafe24Mobile'}
            onChange={() => onChange('isVisibleToCafe24Mobile')}
          />
          웹 모바일
        </S.Label>
      </S.Td>
    </S.Tr>
  );
};

const SearchByAdmin = ({ value = null, onChange = () => {} }) => {
  const [adminNames, setAdminNames] = useState([]);

  const fetchAdminNames = async () => {
    const data = await getUserList();
    setAdminNames(data.map(({ admin_name }) => admin_name));
  };

  useEffect(() => {
    fetchAdminNames();
  }, []);

  return (
    <S.Tr>
      <S.LeftTd>담당자</S.LeftTd>
      <S.Td>
        <S.Select
          value={value || 'none'}
          onChange={(e) =>
            onChange(e.target.value !== 'none' ? e.target.value : null)
          }
        >
          <option value={'none'}>---</option>
          {adminNames.map((data) => (
            <option value={data} key={data}>
              {data}
            </option>
          ))}
        </S.Select>
      </S.Td>
    </S.Tr>
  );
};

const SearchByPeriod = ({
  value = { start: null, end: null },
  onChange = () => {},
}) => {
  const { start, end } = value;

  return (
    <S.Tr>
      <S.LeftTd>기간</S.LeftTd>
      <S.Td>
        <span>
          <S.InputDate
            value={start || ''}
            onChange={(e) => onChange(e.target.value, end)}
          />{' '}
          ~{' '}
          <S.InputDate
            value={end || ''}
            onChange={(e) => onChange(start, e.target.value)}
          />
        </span>
      </S.Td>
    </S.Tr>
  );
};
export default SearchTable;
