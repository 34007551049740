import { Button, Card, ReInput } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request, useObject, useOrderDetail, USER_TYPE } from 'services';

export interface Props {}
interface State {
  orderUserName: string;
  orderUserPhoneNumber: string;
  orderUserEmail: string;
  customsClearanceNumber: string;
  userType: string;
  isUpdating: boolean;
}

const OrderBuyerInfo: FunctionComponent<Props> = () => {
  const [orderState, setOrderState, { refreshOrderState }] = useOrderDetail();
  const [
    {
      orderUserName,
      orderUserPhoneNumber,
      orderUserEmail,
      customsClearanceNumber,
      userType,
      isUpdating,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    orderUserName: orderState.orderUserName,
    orderUserPhoneNumber: orderState.orderUserPhoneNumber,
    orderUserEmail: orderState.orderUserEmail,
    customsClearanceNumber: orderState.customsClearanceNumber,
    userType: orderState.userType,
    isUpdating: false,
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();

  const update = async () => {
    if (!window.confirm('주문자정보를 수정하시겠습니까?')) return;
    setState({ isUpdating: true });

    try {
      await request.put(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/order-user`,
        {
          orderUserName,
          phoneNumber: orderUserPhoneNumber,
          email: orderUserEmail,
          customsClearanceNumber,
        }
      );
      toast.success('수정되었습니다.');
      refreshOrderState();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isUpdating: false });
    }
  };

  useEffect(() => {
    if (orderState.isInitialFetched) {
      setState({
        orderUserName: orderState.orderUserName || '',
        orderUserPhoneNumber: orderState.orderUserPhoneNumber || '',
        orderUserEmail: orderState.orderUserEmail || '',
        customsClearanceNumber: orderState.customsClearanceNumber || '',
        userType: orderState.userType,
      });
    }
  }, [orderState.isInitialFetched]);
  return (
    <>
      <Card
        title="주문자 정보"
        items={[
          {
            name: '주문자ID',
            renderItem: () => (
              <span className="font-bold">{orderState.userStringId}</span>
            ),
            stretch: true,
          },
          {
            name: '주문자명',
            renderItem: () => (
              <ReInput
                size="xs"
                value={orderUserName}
                name="orderUserName"
                onChange={onChange}
              />
            ),
            stretch: true,
          },
          {
            name: '연락처',
            renderItem: () => (
              <ReInput
                size="xs"
                value={orderUserPhoneNumber}
                name="orderUserPhoneNumber"
                onChange={onChange}
              />
            ),
            stretch: true,
          },
          {
            name: '이메일',
            renderItem: () => (
              <ReInput
                size="xs"
                value={orderUserEmail}
                className="w-40"
                name="orderUserEmail"
                type="email"
                onChange={onChange}
              />
            ),
            stretch: true,
          },
          {
            name: '개인통관고유부호',
            renderItem: () => (
              <ReInput
                size="xs"
                value={customsClearanceNumber}
                name="customsClearanceNumber"
                onChange={onChange}
              />
            ),
            stretch: true,
          },
          {
            name: '회원등급',
            renderItem: () => USER_TYPE[userType],
            stretch: true,
          },
          {
            name: '구매자 IP',
            renderItem: () => orderState.orderIp,
            stretch: true,
          },
        ]}
      />
      <div className="flex justify-center">
        <div className="flex gap-3">
          <Button isLoading={isUpdating} onClick={update}>
            수정 저장
          </Button>
          <Button onClick={() => resetState()} theme="secondary">
            수정 초기화
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrderBuyerInfo;
