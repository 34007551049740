import { Tab } from './style';
import React, { FunctionComponent } from 'react';

export interface Props {
  value: number;
  onTabChange: (tab: number) => void;
  options: string[];
  gap?: number;
}
interface State {}

const Tabs: FunctionComponent<Props> = ({ value, onTabChange, options, gap = 20 }) => {
  return (
    <div className="flex" style={{ gap }}>
      {options.map((name, key) => (
        <Tab key={key} onClick={() => onTabChange(key)} selected={value === key}>
          {name}
        </Tab>
      ))}
    </div>
  );
};

export default Tabs;
