import styled from "styled-components";

export const Conatiner = styled.div`
display:flex;
justify-content: center;
align-items:center;
padding:26px 0`;
export const Wrapper = styled.div`
display:flex;
justify-content: center;
justify-content: space-around;
width:40%;
`;
export const PageButton = styled.button`
display: flex;
align-items: center;
justify-content: center;
background: #CACACA;
border:none;
border-radius: 1px;
width:20px;
height:20px;`;
export const NumberButton = styled.button`
background:none;
border:none;
color:${({ current }) => current ? 'black' : '#9B9B9B'};
`;