import React from 'react';
import * as style from './style';

const ScrollTitleBar = ({ data, onScroll }) => {
  return (
    <style.TitleBar>
      {data.map((el) => {
        return (
          <style.TitleItem key={el} onClick={() => onScroll(el)}>
            {el}
          </style.TitleItem>
        );
      })}
    </style.TitleBar>
  );
};
export default ScrollTitleBar;
