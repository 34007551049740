import { Spinner } from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useSWR from 'swr';
import {
  DashboardCard,
  DashboardDiff,
  DashboardItem,
} from '../DashboardService/components';
import koKR from 'antd/lib/locale/ko_KR';
import { toast } from 'react-toastify';
import { DatePicker, Select } from 'antd';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import moment from 'moment';
import { Data, Standard, User } from './types';
import { getKey } from 'services';
import { standardOptions } from './helper';
import { getUserList } from 'api/axios';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: any = {
  plugins: { legend: { display: false }, title: { display: false } },
};

const getLineChartData = (
  data?: { date: string; value: number }[]
): ChartData<'line', number[], string> => {
  return {
    labels: data?.map((data) => data.date) ?? [],
    datasets: [
      {
        data: data?.map((data) => data.value) ?? [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 6,
      },
    ],
  };
};

const fetcher = <T,>(key: string) => fetch(key).then((res) => res.json());
const getErrorDescription = (error: any) => {
  switch (error?.response?.status) {
    case 404:
      return `서버에 정보가 없습니다. (${error?.response?.status})`;
    case 401:
    case 403:
      return `권한이 부족합니다. (${error?.response?.status})`;
    case 500:
      return `서버 내부 에러 (${error?.response?.status})`;
    default:
      return `잠시 후 다시 시도해주세요. (${error?.response?.status})`;
  }
};

const DashboardByMD: React.FC = ({}) => {
  const [from, setFrom] = useState<string | null>(
    dayjs().subtract(7, 'd').format('YYYY-MM-DD')
  );
  const [to, setTo] = useState<string | null>(dayjs().format('YYYY-MM-DD'));
  const [user, setUser] = useState<number | null>(null);
  const [standard, setStandard] = useState<Standard>(Standard.SALES);

  const { data: users } = useSWR<User[]>(`/user`, getUserList);

  const { data, error } = useSWR<Data>(
    getKey('https://admin.fetchingapp.co.kr/dashboard/md', {
      adminId: user,
      minDate: from ? dayjs(from).toISOString() : null,
      maxDate: to ? dayjs(to).toISOString() : null,
      standard,
    }),
    fetcher
  );

  return (
    <div>
      <div className="grid grid-cols-2 m-4 gap-4 relative">
        {!(data || error) && (
          <div className="absolute w-full h-full opacity-60 flex items-center justify-center bg-white">
            <Spinner className="h-10 w-10 text-black" />
          </div>
        )}
        <DashboardCard
          span={2}
          title={
            <div className="flex gap-5 items-center">
              <DatePicker.RangePicker
                allowEmpty={[true, true]}
                defaultValue={[moment(from), moment(to)]}
                locale={koKR.DatePicker}
                onChange={(_, [from, to]) => {
                  setFrom(from);
                  setTo(to);
                }}
              />
              <div style={{ width: 250 }}>
                <Select className="w-full" onChange={setUser} value={user}>
                  <Select.Option value={null}>전체</Select.Option>
                  {users?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {option.admin_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ width: 250 }}>
                <Select
                  className="w-full"
                  onChange={(standard: Standard) => {
                    setStandard(standard);
                  }}
                  value={standard}
                >
                  {standardOptions.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          }
        ></DashboardCard>
        <DashboardCard title="관리상품 기준 성과">
          <div className="flex flex-col gap-5">
            <DashboardItem name="관리 상품 수" maxWidth={450}>
              <DashboardDiff to={data?.priority.totalCount ?? 0} unit="개" />
            </DashboardItem>
            <DashboardItem name="판매 상품 수" maxWidth={450}>
              <DashboardDiff to={data?.priority.salesCount ?? 0} unit="개" />
            </DashboardItem>
            <DashboardItem name="거래액" maxWidth={450}>
              <DashboardDiff to={data?.priority.salesAmount ?? 0} />
            </DashboardItem>
            <DashboardItem name="매출액" maxWidth={450}>
              <DashboardDiff to={data?.priority.transactionAmount ?? 0} />
            </DashboardItem>
            <DashboardItem name="발생수익" maxWidth={450}>
              <DashboardDiff to={data?.priority.agencyFeeTotal ?? 0} />
            </DashboardItem>
          </div>
        </DashboardCard>
        <DashboardCard title="관리상품 기준 성과 추이">
          <Line options={options} data={getLineChartData(data?.priority.graph ?? [])} />
        </DashboardCard>
        <DashboardCard title="기획전, 핫딜 기준 성과">
          <div className="flex flex-col gap-5">
            <DashboardItem name="관리 상품 수" maxWidth={450}>
              <DashboardDiff to={data?.exhibition.totalCount ?? 0} unit="개" />
            </DashboardItem>
            <DashboardItem name="판매 상품 수" maxWidth={450}>
              <DashboardDiff to={data?.exhibition.salesCount ?? 0} unit="개" />
            </DashboardItem>
            <DashboardItem name="거래액" maxWidth={450}>
              <DashboardDiff to={data?.exhibition.salesAmount ?? 0} />
            </DashboardItem>
            <DashboardItem name="매출액" maxWidth={450}>
              <DashboardDiff to={data?.exhibition.transactionAmount ?? 0} />
            </DashboardItem>
            <DashboardItem name="발생수익" maxWidth={450}>
              <DashboardDiff to={data?.exhibition.agencyFeeTotal ?? 0} />
            </DashboardItem>
          </div>
        </DashboardCard>
        <DashboardCard title="기획전, 핫딜 기준 성과 추이">
          <Line options={options} data={getLineChartData(data?.exhibition.graph ?? [])} />
        </DashboardCard>
      </div>
    </div>
  );
};

export default DashboardByMD;
