import React from 'react';
import * as style from './style';

const KeywordTd = ({ searchOptionTableState, setSearchOptionTableState }) => {
  return (
    <style.Container>
      <style.Input
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            keyword: e.target.value,
          });
        }}
        value={searchOptionTableState.keyword}
      />
    </style.Container>
  );
};

export default KeywordTd;
