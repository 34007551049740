import React from 'react';
import * as style from './style';

const Input = ({ title, value, onChange }) => {
  return (
    <style.Container>
      <style.Title>{title}</style.Title>
      <style.Input type="text" value={value} onChange={onChange} />
    </style.Container>
  );
};
export default Input;
