import React, { Fragment, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import SimpleImageSlider from 'react-simple-image-slider';
import { Button } from 'react-bootstrap';
import { ProductData, ItemHistory, SimpleInput } from 'components';

import * as style from './style';
import { DropZoneImageModifiable } from '../../UI/organisms/DropZoneImage';
import { fetchingAPI } from 'api/fetchingAPI';

const Form = ({ title, children, grid, color }) => (
  <style.ItemWrapper>
    <style.ItemTitle color={color}>{title}</style.ItemTitle>
    <style.Item column={grid}>{children}</style.Item>
  </style.ItemWrapper>
);

export default function ProductInfo({
  product,
  scrollRef,
  productInfo,
  setProductInfo,
  mode,
  isAutoRefresh,
  setIsAutoRefresh,
}) {
  const [isActive, setIsActive] = useState(true);

  const handleShowContent = () => {
    isActive ? setIsActive(false) : setIsActive(true);
  };

  const find = productInfo.images?.find((el) => el.url.startsWith('http://'));

  const setSize = (info, field, value) => {
    const checkNumberField = (field) =>
      ['quantity', 'optionPrice'].indexOf(field) > -1;

    if (checkNumberField(field) && isNaN(Number(value))) {
      return;
    }

    const index = productInfo.sizes.findIndex((size) => size.id === info.id);

    const newInfo = {
      ...info,
      [field]: checkNumberField(field) ? Number(value) : value,
    };
    productInfo.sizes.splice(index, 1, newInfo);

    setProductInfo({
      ...productInfo,
    });
  };

  const setProduct = (field, value) => {
    setProductInfo({
      ...productInfo,
      [field]: value,
    });
  };

  const addSize = () => {
    setProductInfo({
      ...productInfo,
      sizes: [
        ...productInfo.sizes,
        {
          id: +new Date(),
          name: '',
          quantity: 0,
          needOptionPrice: false,
          optionPrice: 0,
        },
      ],
    });
  };

  const deleteSize = (info) => {
    if (productInfo.sizes.length === 1) {
      return alert('최소 1개의 사이즈를 입력하셔야 합니다.');
    }
    const index = productInfo.sizes.findIndex((size) => size.id === info.id);
    productInfo.sizes.splice(index, 1);

    setProductInfo({
      ...productInfo,
    });
  };

  const formList = [
    {
      title: '우선순위',
      mode: ['update'],
      component: (
        <input
          value={productInfo.itemPriority}
          onChange={(e) => {
            setProductInfo({
              ...productInfo,
              itemPriority: e.target.value,
            });
          }}
        />
      ),
    },
    {
      title: '상품 중요도',
      mode: ['update'],
      component: <>test</>,
    },
    {
      title: '상품 데이터',
      mode: ['update'],
      component: (
        <>
          <ProductData
            title="조회수"
            total={product?.stat.app.visit + product?.stat.web.visit}
            app={product?.stat.app.visit}
            web={product?.stat.web.visit}
          />
          <ProductData
            title="위시리스트"
            total={product?.stat.app.like + product?.stat.web.wishlist}
            app={product?.stat.app.like}
            web={product?.stat.web.wishlist}
          />
          <ProductData
            title="구매수"
            total={product?.stat.app.sales + product?.stat.web.sales}
            app={product?.stat.app.sales}
            web={product?.stat.web.sales}
          />
          <ProductData
            title="편집샵 연결"
            total={product?.stat.app.go_to_shop + product?.stat.web.go_to_shop}
            app={product?.stat.app.go_to_shop}
            web={product?.stat.web.go_to_shop}
          />
          <ProductData title="카페24 장바구니" total={product?.stat.web.cart} />
          <ProductData title="앱 페칭결제 선택" />
        </>
      ),
    },
    {
      title: '편집샵',
      mode: ['update', 'create'],
      component: <>{mode === 'create' ? 'FETCHING' : product?.shopName}</>,
    },
    {
      title: '편집샵 브랜드',
      mode: ['update', 'create'],
      component: <>{product?.shopBrandName}</>,
    },
    {
      title: '페칭 브랜드',
      mode: ['update', 'create'],
      component: <>{product?.fetchingBrandName}</>,
    },
    {
      title: '편집샵 상품명',
      mode: ['update', 'create'],
      component: <>{product?.originName || '없음'}</>,
    },
    {
      title: '페칭 상품명',
      mode: ['update', 'create'],
      component: (
        <input
          value={productInfo.itemName}
          style={{ width: '300px' }}
          onChange={(e) => {
            setProductInfo({
              ...productInfo,
              itemName: e.target.value,
            });
          }}
        />
      ),
    },
    {
      title: '편집샵 코드',
      mode: ['create'],
      component: (
        <input
          value={productInfo.code}
          style={{ width: '300px' }}
          placeholder="편집샵 고유코드값 (ex: FCH000000001)"
          onChange={(e) => {
            setProductInfo({
              ...productInfo,
              code: e.target.value,
            });
          }}
        />
      ),
    },
    {
      title: '컬러',
      mode: ['update', 'create'],
      component: (
        <input
          value={productInfo.color}
          onChange={(e) => {
            setProductInfo({
              ...productInfo,
              color: e.target.value,
            });
          }}
        />
      ),
    },
    ,
    {
      title: '성별',
      mode: ['create'],
      component: (
        <>
          <div>
            <Button
              variant={productInfo?.gender === 'M' ? 'info' : 'light'}
              style={{ marginRight: '4px' }}
              onClick={() => setProduct('gender', 'M')}
            >
              남성
            </Button>
            <Button
              variant={productInfo?.gender === 'W' ? 'info' : 'light'}
              onClick={() => setProduct('gender', 'W')}
            >
              여성
            </Button>
          </div>
          <p style={{ color: 'red', paddingLeft: '10px' }}>
            성별은 구조적인 문제로 반드시 남/여 하나를 선택해야 합니다.
          </p>
        </>
      ),
    },
    {
      title: '사이즈',
      mode: ['create'],
      component: (
        <div>
          <style.SizeWrapper>
            <style.SizeComp>
              <div>사이즈</div>
              <div>재고</div>
              <div>옵션가</div>
            </style.SizeComp>

            {productInfo.sizes.map((info, index) => (
              <div
                key={index}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {'>'}

                <style.SizeComp style={{ marginRight: '6px' }}>
                  <input
                    placeholder="사이즈가 없는 경우 one-size"
                    onChange={(e) => setSize(info, 'name', e.target.value)}
                  />
                  <input
                    value={info.quantity}
                    placeholder="재고"
                    onChange={(e) => setSize(info, 'quantity', e.target.value)}
                  />
                  <div>
                    {info.needOptionPrice ? (
                      <div>
                        <input
                          value={info.optionPrice}
                          type="number"
                          onChange={(e) =>
                            setSize(info, 'optionPrice', e.target.value)
                          }
                        />
                        <style.Button
                          color="red"
                          onClick={() =>
                            setSize(info, 'needOptionPrice', false)
                          }
                          style={{ marginTop: '4px' }}
                        >
                          취소
                        </style.Button>
                      </div>
                    ) : (
                      <style.Button
                        onClick={() => setSize(info, 'needOptionPrice', true)}
                      >
                        옵션가 설정
                      </style.Button>
                    )}
                  </div>
                </style.SizeComp>
                <style.Button
                  color="red"
                  style={{ width: '29px', height: '29px' }}
                  onClick={() => deleteSize(info)}
                >
                  X
                </style.Button>
              </div>
            ))}
          </style.SizeWrapper>
          <style.Button onClick={addSize} color="#0B2F35">
            추가
          </style.Button>
        </div>
      ),
    },
    {
      title: '이미지',
      mode: ['update'],
      component: (
        <>
          {find || productInfo.images?.length === 1
            ? productInfo.images?.map((img, i) => (
              <a key={i} href={img.url}>
                이미지 확인
              </a>
            ))
            : productInfo.images?.length && (
              <SimpleImageSlider
                width={300}
                height={300}
                images={productInfo.images}
                showNavs
                showBullets
                slideDuration={0.5}
                navStyle={1}
              />
            )}
        </>
      ),
    },
    {
      title: '이미지',
      mode: ['create'],
      component: (
        <style.InputWrapper>
          {productInfo.images?.map((image, i) => {
            return (
              <Fragment key={i}>
                <DropZoneImageModifiable
                  imageUrl={image.url}
                  setImageUrl={(value) => {
                    const images = productInfo.images;
                    images[i] = { url: value };
                    setProduct('images', images);
                  }}
                />
                <style.Button
                  onClick={() => {
                    const images = productInfo.images.filter(
                      (image, j) => i !== j
                    );
                    setProduct('images', images);
                  }}
                  color="red"
                >
                  -
                </style.Button>
              </Fragment>
            );
          })}
          <style.Button
            onClick={(e) => {
              const images = productInfo.images;
              images.push({ url: '' });
              setProduct('images', images);
            }}
            color="#0B2F35"
          >
            추가
          </style.Button>
        </style.InputWrapper>
      ),
    },
    {
      title: '상품 설명',
      mode: ['update'],
      component: <>{product?.itemDescription}</>,
    },
    {
      title: '상품 설명',
      mode: ['create'],
      component: (
        <textarea
          onChange={(e) => {
            setProductInfo({
              ...productInfo,
              itemDescription: e.target.value,
            });
          }}
          value={productInfo?.itemDescription}
          rows={10}
          cols={150}
        ></textarea>
      ),
    },
    {
      title: '재고 및 상품 자동 갱신',
      mode: ['create'],
      component: (
        <input
          type="checkbox"
          onChange={(e) => setIsAutoRefresh(e.target.checked)}
          checked={isAutoRefresh}
        />
      ),
    },
    {
      title: '상품 수정 내역',
      mode: ['update'],
      component: <ItemHistory data={productInfo.history} />,
    },
  ];

  return (
    <style.Wrapper>
      <style.TitleWrapper ref={scrollRef}>
        <style.Title>상품 정보</style.Title>
        {isActive ? (
          <FiChevronUp style={style.Icon} onClick={handleShowContent} />
        ) : (
          <FiChevronDown style={style.Icon} onClick={handleShowContent} />
        )}
      </style.TitleWrapper>
      {isActive && (
        <style.Content>
          {formList
            .filter((list) => list.mode.indexOf(mode) > -1)
            .map(({ title, grid, component, color }, index) => (
              <Form key={index} title={title} grid={grid} color={color}>
                {component}
              </Form>
            ))}
        </style.Content>
      )}
    </style.Wrapper>
  );
}
