import {
  Button,
  Card,
  GridTable,
  MenuTabs,
  Radio,
  ReInput,
  UserMessageModal,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { dayjsFormat, useObject } from 'services';
import styled from 'styled-components';

const Content = styled.div`
  max-width: 630px;
  overflow: hidden;
  cursor: pointer;
  white-space: normal;
  line-height: 1.2;
  height: 3.6em;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  &:hover {
    display: block;
    height: unset;
  }
`;

interface State {
  page: number;
  total: number;
  list: Array<{
    os: string;
    reservationTime?: string;
    submitTime: string;
    content: string;
    count: number;
    wait?: number;
    success?: number;
    fail?: number;
    title?: string;
  }>;
  isOpen: boolean;
  isLoading: boolean;
  minDate: string;
  maxDate: string;
}

const UserPushPage = () => {
  const [{ page, total, list, isOpen, isLoading, minDate, maxDate }, setState, onChange] =
    useObject<State>({
      page: 1,
      total: 0,
      list: [
        {
          os: '전체',
          reservationTime: '2021. 11. 25. 21:22:44',
          submitTime: '2021. 11. 25. 21:22:44',
          content:
            '메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출메시지내용전체노출',
          count: 2124214,
          wait: 124,
          success: 235,
          fail: 235241,
        },
      ],
      isOpen: false,
      isLoading: false,
      minDate: dayjsFormat(dayjs().add(-7, 'day')),
      maxDate: dayjsFormat(dayjs()),
    });

  const get = async () => {};
  const onCancelSubmit = async (index: number) => {
    if (!window.confirm('대기중인 메시지 전체를 취소하시겠습니까?')) return;
  };
  const onDateClick = (date: string) => {
    let newMinDate = '';
    if (date === '오늘') newMinDate = dayjsFormat(dayjs());
    else if (date === '3일') newMinDate = dayjsFormat(dayjs().add(-3, 'day'));
    else if (date === '7일') newMinDate = dayjsFormat(dayjs().add(-7, 'day'));
    else if (date === '1개월') newMinDate = dayjsFormat(dayjs().add(-1, 'month'));
    else if (date === '3개월') newMinDate = dayjsFormat(dayjs().add(-3, 'month'));
    else if (date === '1년') newMinDate = dayjsFormat(dayjs().add(-1, 'year'));

    setState({ minDate: newMinDate, maxDate: dayjsFormat(dayjs()) });
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <MenuTabs
        options={[
          { name: '푸시 발송', to: '/users/send-push' },
          { name: '푸시 발송 내역', to: '/users/push' },
        ]}
      />

      <div className="wrapper">
        <div className="wrapper-title">푸시 발송내역 조회</div>
        <Card
          items={[
            {
              name: '전송결과',
              renderItem: () => (
                <Radio
                  id="tab"
                  onChange={(tab) => console.log('tab', tab)}
                  options={[
                    { name: '전체', value: 'all' },
                    { name: '대기', value: 'waiting' },
                    { name: '성공', value: 'success' },
                    { name: '실패', value: 'fail' },
                  ]}
                />
              ),
              stretch: true,
            },
            {
              name: '수신 아이디',
              renderItem: () => (
                <ReInput
                  size="xs"
                  className="w-64"
                  placeholder="직접 검색 시 입력해주세요. (콤마로 다중 검색 가능)"
                />
              ),
              stretch: true,
            },
            {
              name: '앱 OS',
              renderItem: () => (
                <Radio
                  id="app"
                  onChange={(tab) => console.log('tab', tab)}
                  options={[
                    { name: '전체', value: 'all' },
                    { name: '안드로이드', value: 'android' },
                    { name: 'iOS', value: 'ios' },
                  ]}
                />
              ),
              stretch: true,
            },
            {
              name: '기간',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {['오늘', '3일', '7일', '1개월', '3개월', '1년'].map((item, key) => (
                    <Button
                      theme="ghost"
                      size="xs"
                      onClick={() => onDateClick(item)}
                      key={key}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={minDate}
                    name="minDate"
                    onChange={onChange}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={maxDate}
                    name="maxDate"
                    onChange={onChange}
                  />
                </div>
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-5 flex justify-center gap-3">
          <Button isLoading={isLoading} onClick={get}>
            검색
          </Button>
          <Button theme="secondary">초기화</Button>
        </div>
      </div>

      <div className="wrapper">
        <GridTable
          columns={[
            '앱 OS',
            '발송예약시간',
            '발송시간',
            '푸시',
            '발송건수',
            '대기',
            '성공',
            '실패',
          ]}
          list={list}
          noSelection
          renderItem={(item, key) => (
            <div key={key}>
              <div>{item.os}</div>
              <div>{item.reservationTime}</div>
              <div>{item.submitTime}</div>
              <div>
                <Content>{item.content}</Content>
              </div>
              <div>{item.count}건</div>
              <div>
                <div>
                  <div>{item.wait}</div>
                  <Button onClick={() => onCancelSubmit(key)} size="xs">
                    발송취소
                  </Button>
                </div>
              </div>
              <div>
                <span onClick={() => setState({ isOpen: true })} className="link">
                  {item.success}
                </span>
              </div>
              <div>
                <span onClick={() => setState({ isOpen: true })} className="link">
                  {item.fail}
                </span>
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>건</span>
          </div>
        </div>
      </div>
      {isOpen && (
        <UserMessageModal
          title="푸시 발송 결과"
          isOpen={isOpen}
          onClose={() => setState({ isOpen: false })}
          id={0}
        />
      )}
    </>
  );
};

export default UserPushPage;
