import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import * as style from '../style';

const ImageBox = ({ title, imageUrl, handleImage }) => {
  const [imgUrl, setImgUrl] = useState(imageUrl ? imageUrl : '');

  useEffect(() => {
    handleImage(title, imgUrl);
  }, [imgUrl]);

  const handleOnDrop = (files) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post(`${process.env.REACT_APP_FETCHING_SERVER_URL}/api/util/image`, {
          path:
            title === '배너 이미지'
              ? 'exhibitions/banner'
              : 'exhibitions/landing',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setImgUrl(imgUrl);
          }
        })
        .catch((err) => console.log(err));
    };

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result.split(',');
        cb(result[1]);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };

  return (
    <style.ItemOuter>
      <style.Item title={'true'}>{title}</style.Item>
      <style.Item>
        <style.Flex style={{ padding: '15px 20px' }}>
          <style.Input value={imgUrl} onChange={() => {}} />
          <Dropzone onDrop={handleOnDrop} multiple={false} maxSize={10000000}>
            {({ getRootProps, getInputProps }) => (
              <style.Button {...getRootProps()}>
                <input {...getInputProps()} />
                찾아보기
              </style.Button>
            )}
          </Dropzone>
        </style.Flex>
        {imgUrl.length ? (
          <style.Flex>
            <div style={{ marginLeft: '20px' }}>
              <style.Img src={imgUrl} />
            </div>
            <div>
              <style.CancelButton
                onClick={() => {
                  setImgUrl('');
                }}
              >
                X
              </style.CancelButton>
            </div>
          </style.Flex>
        ) : null}
      </style.Item>
    </style.ItemOuter>
  );
};

export default ImageBox;
