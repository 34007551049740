import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { CheckboxGroup } from 'components';
import * as style from './style';

export default function SetDisplay({
  product,
  scrollRef,
  handleChangeDisplay,
}) {
  const [isActive, setIsActive] = useState(true);

  const handleShowContent = () => {
    isActive ? setIsActive(false) : setIsActive(true);
  };

  return (
    <style.Wrapper>
      <style.TitleWrapper ref={scrollRef}>
        <style.Title>진열 설정</style.Title>
        {isActive ? (
          <FiChevronUp style={style.Icon} onClick={handleShowContent} />
        ) : (
          <FiChevronDown style={style.Icon} onClick={handleShowContent} />
        )}
      </style.TitleWrapper>
      {isActive && (
        <style.Content>
          <style.ItemWrapper>
            <style.ItemTitle>앱 진열</style.ItemTitle>
            <style.Item>
              <CheckboxGroup
                isActive={product?.isVisibleApp}
                activeText="업로드됨"
                disableText="업로드안됨"
                target={'앱 진열'}
                handleChangeCheckbox={handleChangeDisplay}
              />
            </style.Item>
          </style.ItemWrapper>
          <style.ItemWrapper>
            <style.ItemTitle>카페24 업로드</style.ItemTitle>
            <style.Item>
              <CheckboxGroup
                isActive={product?.isCafe24Uploaded}
                activeText="업로드됨"
                disableText="업로드안됨"
                target={'카페24 업로드'}
                handleChangeCheckbox={handleChangeDisplay}
              />
            </style.Item>
          </style.ItemWrapper>
          <style.ItemWrapper>
            <style.ItemTitle>카페24 판매 {'&'} 진열</style.ItemTitle>
            <style.Item>
              <CheckboxGroup
                isActive={product?.isVerify}
                activeText="진열함"
                disableText="진열안함"
                target={'카페24 판매 & 진열'}
                handleChangeCheckbox={handleChangeDisplay}
              />
            </style.Item>
          </style.ItemWrapper>
          <style.ItemWrapper>
            <style.ItemTitle>네이버 연동</style.ItemTitle>
            <style.Item>
              <CheckboxGroup
                isActive={product?.isNaverUpload}
                activeText="연동함"
                disableText="연동안함"
                target={'네이버 연동'}
                handleChangeCheckbox={handleChangeDisplay}
              />
            </style.Item>
          </style.ItemWrapper>
        </style.Content>
      )}
    </style.Wrapper>
  );
}
