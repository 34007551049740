import React, { useEffect, useState } from 'react';
import * as style from './style';

export default function ItemHistory({ data }) {
  const [list, setList] = useState([]);
  const [headerTitle] = useState(['날짜', '이름', '타입', '설명']);

  useEffect(() => {
    const result = data?.data?.sort((a, b) => {
      if (a.itemUpdateDate > b.itemUpdateDate) {
        return 1;
      }
      if (a.itemUpdateDate < b.itemUpdateDate) {
        return -1;
      }
      return 0;
    });

    data?.data && setList(result);
  }, [data]);

  return (
    <style.Wrapper display={list.length ? 'block' : 'none'}>
      <style.TableHeader BC="#e5e5e547">
        {headerTitle.map((title) => (
          <style.TableItem key={title} FW="bold">
            {title}
          </style.TableItem>
        ))}
      </style.TableHeader>
      {list.map((el) => (
        <style.TableHeader key={el.itemUpdateHistoryId}>
          <style.TableItem>
            {`${el.itemUpdateDate.slice(0, 10)} 
              ${el.itemUpdateDate.slice(11, 19)}`}
          </style.TableItem>
          <style.TableItem>{el.adminName}</style.TableItem>
          <style.TableItem>{el.typeKorean}</style.TableItem>
          <style.TableItem>{el.itemUpdateContent}</style.TableItem>
        </style.TableHeader>
      ))}
    </style.Wrapper>
  );
}
