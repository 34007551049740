import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { useSetRecoilState } from 'recoil';
import { orderCountState } from 'store';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { request, useUser } from 'services';

export interface Props {}
interface State {}

const OrderCount: FunctionComponent<Props> = ({ children }) => {
  const setOrderCount = useSetRecoilState(orderCountState);
  const { user } = useUser();
  const { data } = useSWR(
    [
      '/commerce/order?orderStatus=BEFORE_DEPOSIT',
      '/commerce/order?orderStatus=NEW',
      '/commerce/order?orderStatus=ORDER_WAITING',
      '/commerce/order?orderStatus=PRE_ORDER_REQUIRED',
      '/commerce/order?orderStatus=ORDER_COMPLETE',
      '/commerce/order?orderStatus=ORDER_DELAY',
      '/commerce/order?orderStatus=ORDER_DELAY_IN_SHOP',
      `/commerce/order?deliveryManageMode=${true}`,
      '/commerce/order?orderStatus=SHIPPING_COMPLETE',
      '/commerce/order/order-exchange',
      '/commerce/order/order-return',
      '/commerce/order/order-refund',
      '/commerce/order/order-cancel',
    ],
    (...urls) =>
      Promise.all(
        urls.map((url, index) =>
          request.get<any, { count: number }>(url, {
            params: {
              minOrderDate: `${dayjs()
                .add(index === 5 ? -6 : -1, 'month')
                .format('YYYY-MM-DDT00:00:00')}.000Z`,
              countOnly: true,
            },
            headers: {
              isCount: true,
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          })
        )
      ),
    { refreshInterval: 1000 * 60 * 3 }
  );

  useEffect(() => {
    if (!data) return;
    setOrderCount({
      beforeDeposit: data[0].count,
      newOrder: data[1].count,
      waiting: data[2].count,
      preRequired: data[3].count,
      complete: data[4].count,
      delay: data[5].count,
      delayInShop: data[6].count,
      shipping: data[7].count,
      shippingComplete: data[8].count,
      exchange: data[9].count,
      return: data[10].count,
      refund: data[11].count,
      cancel: data[12].count,
    });
  }, [data]);

  return <>{children}</>;
};

export default OrderCount;
