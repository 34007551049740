import { Button, Card, Textarea } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { useObject } from 'services';

export interface Props {}
interface State {
  content: string;
}

const OrderAdditionalInfo: FunctionComponent<Props> = () => {
  const [{ content }, setState, onChange] = useObject<State>({ content: '' });
  const get = async () => {};
  const update = async () => {};
  const onReset = () => {};

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <Card
        title="추가 정보"
        items={[
          {
            name: '추가 정보',
            renderItem: () => (
              <Textarea rows={4} value={content} name="content" onChange={onChange} />
            ),
            stretch: true,
          },
        ]}
      />
      <div className="flex justify-center">
        <div className="flex gap-3">
          <Button onClick={update}>수정 저장</Button>
          <Button theme="secondary" onClick={onReset}>
            수정 초기화
          </Button>
        </div>
      </div>
    </>
  );
};

export default OrderAdditionalInfo;
