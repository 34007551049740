import React from 'react';
import * as style from './style';

export default function ProductData({ title, total, app, web }) {
  return (
    <style.Wrapper>
      <style.Item color="black">{title}</style.Item>
      <style.Item color="black" fontWeight="true">
        {isNaN(total) ? null : total}
      </style.Item>
      <style.Item color={app === undefined ? 'white' : 'black'}>
        {typeof app === 'number' ? `앱 - ${app}` : '.'}
      </style.Item>
      <style.Item color={web === undefined ? 'white' : 'black'}>
        {typeof web === 'number' ? `cafe24 - ${web}` : '.'}
      </style.Item>
    </style.Wrapper>
  );
}
