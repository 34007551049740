import { Loading } from 'components';
import React, { useState } from 'react';
import { HiX } from 'react-icons/hi';
import { toast } from 'react-toastify';
import { request } from 'services';
import styles from './style.module.css';

interface Props {
  fetchingOrderNumber?: string;
  payAmount?: number;
  onClose: () => void;
  orderAdditionalNumber?: string;
}

const DepositConfirmModal = ({
  fetchingOrderNumber,
  onClose,
  orderAdditionalNumber,
  ...props
}: Props): JSX.Element => {
  const titleStyle = 'text-black font-spoqa text-xs font-bold';

  const [isLoading, setIsLodading] = useState(false);

  const [state, setState] = useState({
    name: '',
    price: '',
    memo: '',
    payAmount: props.payAmount || 0,
    isAdditional: false,
  });

  const onDepositConfirm = async () => {
    if (
      !window.confirm(
        '입금 처리하시겠습니까? 무통장 차액 결제라면 "차액 결제 여부"를 꼭 체크해주세요.'
      )
    )
      return;
    let data: any = {
      name: state.name,
      amount: state.price.split(',').join(''),
      memo: state.memo,
    };
    if (state.isAdditional && !orderAdditionalNumber) {
      toast.info('무통장 차액결제를 처리할 수 없는 상태입니다. 개발팀에 문의바랍니다.', {
        autoClose: 5000,
      });
      return;
    }
    try {
      setIsLodading(true);
      await request.post(
        state.isAdditional
          ? `/commerce/order/additional-pay/${orderAdditionalNumber}/deposit-log`
          : `/commerce/order/fetching-order/${fetchingOrderNumber}/deposit-log`,
        data
      );
    } catch (err: any) {
      if (err.response.status === 400) {
        alert('입금이 확인되지 않아요 🥲');
      } else {
        alert('입금 처리에 실패했어요! 입금자명과 입금 금액을 확인해 주세요 🥲');
      }
    } finally {
      setIsLodading(false);
      onClose();
      window.location.reload();
    }
  };

  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          onClose();
        }
      }}
      className={`fixed flex justify-center items-center inset-0 z-20 ${styles.Wrapper}`}
    >
      {isLoading && <Loading />}
      <section className={`bg-white ${styles.Inner}`}>
        <div className={`bg-black py-2 flex ${styles.Header}`}>
          <p className={`font-spoqa text-sm ${styles.HeaderTitle}`}>무통장입금 처리</p>
          <div className="relative">
            <button
              onClick={onClose}
              className={`absolute text-white text-base h-full ${styles.Button}`}
            >
              <HiX />
            </button>
          </div>
        </div>
        <div
          style={{ paddingTop: 34, height: 400 }}
          className="px-5 w-full overflow-auto"
        >
          <strong className="text-black text-base">
            수동으로 무통장입금 처리를 진행합니다.
          </strong>
          <p className="text-xs">
            무통장 차액 결제인 경우는 "차액 결제 여부"를 체크해 주세요.
          </p>
          <div className="flex justify-between mt-3">
            <p className={titleStyle}>고객 주문 금액</p>
            <input
              className={`${styles.Input} font-spoqa`}
              value={state.payAmount}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) return;
                setState({ ...state, payAmount: Number(e.target.value) });
              }}
              type="text"
            />
          </div>
          <div className="flex justify-between mt-3">
            <p className={titleStyle}>고객 입금자명</p>
            <input
              value={state.name}
              onChange={(e) => {
                setState({
                  ...state,
                  name: e.target.value,
                });
              }}
              className={`${styles.Input} font-spoqa`}
              type="text"
              placeholder="입금자명을 입력해주세요."
            />
          </div>
          <div className="flex justify-between mt-3">
            <p className={titleStyle}>고객 입금 금액</p>
            <input
              value={state.price}
              onChange={(e) => {
                const result = e.target.value.replace(/[^0-9]/g, '');
                setState({
                  ...state,
                  price: Number(result).toLocaleString(),
                });
              }}
              className={`${styles.Input} font-spoqa`}
              type="text"
              placeholder="입금 금액을 입력해주세요."
            />
          </div>
          <div className="flex justify-between mt-3">
            <p className={titleStyle}>차액 결제 여부</p>
            <input
              type="checkbox"
              checked={state.isAdditional}
              onChange={(e) => setState({ ...state, isAdditional: e.target.checked })}
            />
          </div>
          <div className="flex justify-between mt-3 mb-5">
            <p className={titleStyle}>메모</p>
            <textarea
              onChange={(e) => {
                setState({
                  ...state,
                  memo: e.target.value,
                });
              }}
              className={`${styles.Input} ${styles.TextArea} font-spoqa`}
              placeholder="메모를 입력해주세요."
            ></textarea>
          </div>
          <button
            onClick={onDepositConfirm}
            className={`block m-auto bg-black h-[40px] font-spoqa text-sm ${styles.ResultButton}`}
          >
            무통장 입금 처리
          </button>
        </div>
      </section>
    </div>
  );
};

export default DepositConfirmModal;
