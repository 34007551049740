import { ReModal, Divider, FormContainer, Button, FormItem, ReInput } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { request, useObject } from 'services';
import type { IModal } from 'types';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { addressListState } from 'store';
import { toast } from 'react-toastify';
import Switch from 'react-switch';

export interface Props extends IModal {
  title: string;
  addressIndex: number;
  isAddressMode: boolean;
  isUpdate: boolean;
}
interface State {
  phoneNumber: string;
  recipientName: string;
  detailAddress: string;
  isDefault: boolean;
  isAddressMode: boolean;
  zipCode: string;
  streetNameAddress: string;
  lotNumberAddress: string;
  englishStreetNameAddress: string;
  englishLotNumberAddress: string;
  englishDetailAddress: string;
  englishCity: string;
  name: string;
  isTranslating: boolean;
}

const AddressModal: FunctionComponent<Props> = (props) => {
  const { onClose, title, isOpen, addressIndex, isUpdate } = props;
  if (!isOpen) return null;
  const addressList = useRecoilValue(addressListState);
  const item = addressList[addressIndex];
  const [
    {
      phoneNumber,
      recipientName,
      detailAddress,
      isDefault,
      isAddressMode,
      zipCode,
      streetNameAddress,
      lotNumberAddress,
      englishLotNumberAddress,
      englishStreetNameAddress,
      englishCity,
      englishDetailAddress,
      name,
      isTranslating,
    },
    setState,
    onChange,
  ] = useObject<State>({
    phoneNumber: isUpdate ? item.phoneNumber : '',
    recipientName: isUpdate ? item.recipientName : '',
    detailAddress: isUpdate ? item.detailAddress : '',
    isDefault: isUpdate ? item.isDefault : false,
    isAddressMode: props.isAddressMode,
    zipCode: isUpdate ? item.zipCode : '',
    streetNameAddress: isUpdate ? item.streetNameAddress : '',
    lotNumberAddress: isUpdate ? item.lotNumberAddress : '',
    englishStreetNameAddress: isUpdate ? item.englishStreetNameAddress : '',
    englishLotNumberAddress: isUpdate ? item.englishLotNumberAddress : '',
    englishCity: isUpdate ? item.englishCity : '',
    englishDetailAddress: isUpdate ? item.englishDetailAddress : '',
    name: isUpdate ? item.name : '',
    isTranslating: false,
  });
  const setAddressList = useSetRecoilState(addressListState);
  const open = () => {
    const element_layer = document.getElementById('daum');
    // @ts-ignore
    new daum.Postcode({
      oncomplete: function (data) {
        setState({
          isAddressMode: false,
          zipCode: data.zonecode,
          streetNameAddress: data.address,
          lotNumberAddress: data.jibunAddress,
          englishLotNumberAddress: data.jibunAddressEnglish,
          englishStreetNameAddress: data.addressEnglish,
          englishCity: data.sidoEnglish,
        });
      },
      width: '100%',
    }).embed(element_layer);
  };
  const onSubmit = () => {
    if (!phoneNumber) {
      toast.info('전화번호를 입력해주세요.');
      return;
    }
    if (!recipientName) {
      toast.info('수령자명을 입력해주세요.');
      return;
    }
    if (!name) {
      toast.info('배송지명을 입력해주세요.');
      return;
    }
    setAddressList(
      isUpdate
        ? [
            ...addressList.slice(0, addressIndex),
            {
              ...addressList[addressIndex],
              phoneNumber,
              recipientName,
              detailAddress,
              englishDetailAddress,
              name,
              isDefault,
              requestType: addressList[addressIndex].idx ? 'UPDATE' : 'CREATE',
            },
            ...addressList.slice(addressIndex + 1),
          ]
        : [
            ...addressList,
            {
              idx: 0,
              zipCode,
              englishCity,
              englishDetailAddress,
              englishLotNumberAddress,
              englishStreetNameAddress,
              recipientName,
              detailAddress,
              lotNumberAddress,
              streetNameAddress,
              isDefault,
              name,
              phoneNumber,
              requestType: 'CREATE',
            },
          ]
    );
    onClose();
  };
  const onRemove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;
    setAddressList([
      ...addressList.slice(0, addressIndex),
      { ...addressList[addressIndex], requestType: 'DELETE' },
      ...addressList.slice(addressIndex + 1),
    ]);
    onClose();
  };
  const onTranslate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!detailAddress) {
      toast.info('상세주소를 입력해 주세요.');
      return;
    }

    setState({ isTranslating: true });
    try {
      const { data } = await request.get('/util/translate', {
        params: { from: 'ko', to: 'en', text: detailAddress },
      });
      setState({ englishDetailAddress: data });
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isTranslating: false });
    }
  };
  useEffect(() => {
    if (isAddressMode) open();
  }, []);
  return (
    <ReModal
      title={title}
      isOpen={isOpen}
      maxWidth={isAddressMode ? 'max-w-md' : 'max-w-4xl'}
      onClose={onClose}
      isNotPadding={isAddressMode}
    >
      <div id="daum"></div>
      {!isAddressMode && (
        <>
          <FormContainer title="기본 주소">
            <FormItem label="우편번호">
              <ReInput id="우편번호" className="w-full" value={zipCode} readOnly />
            </FormItem>
            <FormItem label="도로명주소">
              <ReInput
                id="도로명주소"
                className="w-full"
                value={streetNameAddress}
                readOnly
              />
            </FormItem>
            <FormItem label="지번주소">
              <ReInput
                id="지번주소"
                className="w-full"
                value={lotNumberAddress}
                readOnly
              />
            </FormItem>
            <FormItem label="도로명주소 (영문)">
              <ReInput
                id="도로명주소 (영문)"
                className="w-full"
                value={englishStreetNameAddress}
                readOnly
              />
            </FormItem>
            <FormItem label="지번주소 (영문)">
              <ReInput className="w-full" value={englishLotNumberAddress} readOnly />
            </FormItem>
          </FormContainer>
          <Divider />
          <FormContainer title="상세정보">
            <FormItem required label="전화번호">
              <ReInput
                id="전화번호"
                className="w-full"
                name="phoneNumber"
                value={phoneNumber}
                onChange={onChange}
              />
            </FormItem>
            <FormItem required label="수령자명">
              <ReInput
                className="w-full"
                value={recipientName}
                name="recipientName"
                onChange={onChange}
              />
            </FormItem>
            <FormItem
              label="상세주소"
              action={
                <Button
                  isLoading={isTranslating}
                  className="ml-1"
                  size="xs"
                  theme="secondary"
                  onClick={onTranslate}
                >
                  번역
                </Button>
              }
            >
              <ReInput
                className="w-full"
                value={detailAddress}
                name="detailAddress"
                onChange={onChange}
              />
            </FormItem>
            <FormItem label="영문상세주소">
              <ReInput
                className="w-full"
                value={englishDetailAddress}
                name="englishDetailAddress"
                onChange={onChange}
                readOnly
              />
            </FormItem>
            <FormItem required label="배송지명">
              <ReInput className="w-full" value={name} name="name" onChange={onChange} />
            </FormItem>
            <FormItem label="기본 배송지로 설정">
              <Switch
                checked={isDefault}
                onChange={(isDefault) => setState({ isDefault })}
              />
            </FormItem>
            <div className="flex gap-2">
              <Button onClick={onSubmit}>{isUpdate ? '수정' : '추가'}</Button>
              {isUpdate && (
                <Button onClick={onRemove} theme="danger">
                  삭제
                </Button>
              )}
            </div>
          </FormContainer>
        </>
      )}
    </ReModal>
  );
};

export default AddressModal;
