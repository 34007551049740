import axios, { fetchingAPI, getUserList } from 'api/axios';
import { Loading } from 'components';
import { LoadingContext } from 'context';
import React, { useContext, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import * as S from './style';

/////date input 용 문자열 생성
const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();
  let hour = (d.getHours() + '').padStart(2, '0');
  let min = (d.getMinutes() + '').padStart(2, '0');

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-') + 'T' + hour + ':' + min;
};
///////

const AddExhibition = () => {
  const emptyData = {
    name: '',
    description: '',
    gender: null,
    isActive: null,
    isVisibleToCafe24: null,
    isVisibleToAppMain: null,
    isVisibleToAppCategory: null,
    isVisibleToCafe24Mobile: null,
    endedAt: null,
    startedAt: null,
    assigneeId: null,

    bannerImageUrl: null,
    landingImageUrl: null,
    mainImageUrl: null,
    topBannerImageUrl: null,

    duration: 0,
  };
  const [data, setData] = useState(emptyData);
  const [userList, setUserList] = useState([]);

  ////// api 관련
  const fetchAdminList = async () => {
    const users = await getUserList();
    setUserList(users);
  };
  ///////

  const postData = async (handleIsLoading) => {
    const {
      name,
      description,
      topBannerImageUrl,
      bannerImageUrl,
      landingImageUrl,
      mainImageUrl,
      gender,
      isActive,
      isVisibleToCafe24,
      isVisibleToCafe24Mobile,
      isVisibleToAppMain,
      isVisibleToAppCategory,
      assigneeId,
      startedAt,
      endedAt,
    } = data;

    const payload = {
      name,
      description,
      topBannerImageUrl,
      bannerImageUrl,
      landingImageUrl,
      mainImageUrl,
      gender,
      isActive,
      isVisibleToCafe24,
      isVisibleToCafe24Mobile,
      isVisibleToAppMain,
      isVisibleToAppCategory,
      assigneeId,
      startedAt,
      endedAt,
    };

    try {
      handleIsLoading(true);
      ///저장 요청
      await fetchingAPI.post('/exhibition', payload);
      ///창 닫는 로직
      window.close();
    } catch (error) {
      alert(`err in putData(), AddExhibitionDetail, ${error.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  const resetData = () => {
    setData(emptyData);
  };

  useEffect(() => {
    fetchAdminList();
  }, []);
  ///////

  //////input date 처리
  const handleDateInput = (e, target) => {
    const date = new Date(e.target.value + ':00');
    const ISOString = date.toISOString();
    setData({
      ...data,
      [target]: ISOString,
    });
  };
  /////////

  /////////사진 업로드
  const handleOnDrop = (files, target) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post(`${process.env.REACT_APP_FETCHING_SERVER_URL}/api/util/image`, {
          path: 'exhibitions/banner',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setData({ ...data, [target]: imgUrl });
          }
        })
        .catch((err) => console.log(err));
    };

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result.split(',');
        cb(result[1]);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };
  const DropzoneImage = ({ target, targetString }) => (
    <div>
      <input style={{ width: '250px' }} value={target || ''} onChange={() => {}} />
      <Dropzone
        onDrop={(files) => handleOnDrop(files, targetString)}
        multiple={false}
        maxSize={10000000}
      >
        {({ getRootProps, getInputProps }) => (
          <S.Button {...getRootProps()}>
            <input {...getInputProps()} />
            찾아보기
          </S.Button>
        )}
      </Dropzone>
      {target ? (
        <>
          <S.Button
            style={{ marginLeft: '10px' }}
            onClick={() => {
              setData({ ...data, [targetString]: null });
            }}
          >
            X
          </S.Button>
          <br />
          <div>
            <img src={target} style={{ maxWidth: '400px', marginTop: '13px' }} />
          </div>
          <div></div>
        </>
      ) : null}
    </div>
  );
  /////////

  ///////loading context
  const { isLoading, handleIsLoading } = useContext(LoadingContext);
  ///////

  const {
    name,
    description,
    gender,
    isActive,
    isVisibleToCafe24,
    isVisibleToAppMain,
    isVisibleToAppCategory,
    isVisibleToCafe24Mobile,

    bannerImageUrl,
    landingImageUrl,
    mainImageUrl,
    topBannerImageUrl,

    endedAt,
    startedAt,

    assigneeId,

    duration,
  } = data;

  console.log(data);

  return (
    <>
      {isLoading && <Loading />}
      <S.Outer>
        <S.ShadowContainer>
          <h2>기획전 추가</h2>
          <S.H4>기본 정보</S.H4>
          <S.Table>
            <S.Tbody>
              <S.Tr>
                <S.LeftTd>타이틀</S.LeftTd>
                <S.Td>
                  <S.TextArea
                    value={name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>설명</S.LeftTd>
                <S.Td>
                  <S.TextArea
                    value={description}
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                  />
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>성별</S.LeftTd>
                <S.Td>
                  <S.Label>
                    <S.Radio
                      name="gender"
                      checked={gender === 'M'}
                      onClick={() => setData({ ...data, gender: 'M' })}
                    />
                    남성
                  </S.Label>
                  <S.Label>
                    <S.Radio
                      name="gender"
                      checked={gender === 'W'}
                      onClick={() => setData({ ...data, gender: 'W' })}
                    />
                    여성
                  </S.Label>
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>활성상태</S.LeftTd>
                <S.Td>
                  <S.Label>
                    <S.Radio
                      name="isActive"
                      checked={isActive === 1}
                      onClick={() => setData({ ...data, isActive: 1 })}
                    />
                    활성화
                  </S.Label>
                  <S.Label>
                    <S.Radio
                      name="isActive"
                      checked={isActive === 0}
                      onClick={() => setData({ ...data, isActive: 0 })}
                    />
                    비활성화
                  </S.Label>
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>노출 위치</S.LeftTd>
                <S.Td>
                  <S.Label>
                    <S.Checkbox
                      checked={isVisibleToAppMain === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToAppMain: (isVisibleToAppMain + 1) % 2,
                        })
                      }
                    />
                    앱 메인
                  </S.Label>
                  <S.Label>
                    <S.Checkbox
                      checked={isVisibleToAppCategory === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToAppCategory: (isVisibleToAppCategory + 1) % 2,
                        })
                      }
                    />
                    앱 카테고리
                  </S.Label>
                  <S.Label>
                    <S.Checkbox
                      checked={isVisibleToCafe24 === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToCafe24: (isVisibleToCafe24 + 1) % 2,
                        })
                      }
                    />
                    PC 웹 기획전
                  </S.Label>
                  <S.Label>
                    <S.Checkbox
                      checked={isVisibleToCafe24Mobile === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToCafe24Mobile: (isVisibleToCafe24Mobile + 1) % 2,
                        })
                      }
                    />
                    모바일 웹 기획전
                  </S.Label>
                  <S.DescSpan>
                    (PC/모바일 웹 메인은 “웹 메인진열" 탭에서 설정해주세요.)
                  </S.DescSpan>
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>기간</S.LeftTd>
                <S.Td>
                  <S.Label>
                    <S.Radio
                      name="duration"
                      checked={duration === 0}
                      onChange={() =>
                        setData({
                          ...data,
                          startedAt: null,
                          endedAt: null,
                          duration: 0,
                        })
                      }
                    />
                    상시진행
                  </S.Label>
                  <S.Label>
                    <S.Radio
                      name="duration"
                      checked={duration === 1}
                      onChange={() => setData({ ...data, duration: 1 })}
                    />
                    기간설정
                  </S.Label>
                  <S.InputDate
                    disabled={!duration}
                    onChange={(e) => handleDateInput(e, 'startedAt')}
                    value={startedAt && formatDate(startedAt)}
                  />
                  <span style={{ padding: '0 10px' }}>~</span>
                  <S.InputDate
                    disabled={!duration}
                    onChange={(e) => handleDateInput(e, 'endedAt')}
                    value={endedAt && formatDate(endedAt)}
                  />
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>담당자</S.LeftTd>
                <S.Td>
                  <select
                    value={assigneeId}
                    onChange={(e) => {
                      setData({ ...data, assigneeId: e.target.value });
                    }}
                  >
                    {assigneeId === null && <option selected>담당자 정보 없음</option>}
                    {userList.map((user) => {
                      return (
                        <option key={user.id} value={user.id}>
                          {user.admin_name}
                        </option>
                      );
                    })}
                  </select>
                </S.Td>
              </S.Tr>
            </S.Tbody>
          </S.Table>

          <S.H4>배너</S.H4>
          <S.Table>
            <S.Tbody>
              <S.Tr>
                <S.LeftTd>앱 메인 가로형</S.LeftTd>
                <S.Td>
                  <DropzoneImage
                    target={topBannerImageUrl}
                    targetString="topBannerImageUrl"
                  />
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>
                  앱 기획전 목록
                  <br />
                  (모바일 웹 기획전 목록)
                </S.LeftTd>
                <S.Td>
                  <DropzoneImage target={bannerImageUrl} targetString="bannerImageUrl" />
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>
                  앱 기획전 상세
                  <br />
                  (모바일 웹 기획전 상세)
                </S.LeftTd>
                <S.Td>
                  <DropzoneImage
                    target={landingImageUrl}
                    targetString="landingImageUrl"
                  />
                </S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>PC 웹 기획전 목록</S.LeftTd>
                <S.Td></S.Td>
              </S.Tr>
              <S.Tr>
                <S.LeftTd>PC 웹 기획전 상세</S.LeftTd>
                <S.Td>
                  <DropzoneImage target={mainImageUrl} targetString="mainImageUrl" />
                </S.Td>
              </S.Tr>
            </S.Tbody>
          </S.Table>

          <S.ButtonsContainer>
            <S.ModifyButton onClick={() => postData(handleIsLoading)}>
              저장
            </S.ModifyButton>
            <S.ResetButton onClick={resetData}>초기화</S.ResetButton>
          </S.ButtonsContainer>
        </S.ShadowContainer>
      </S.Outer>
    </>
  );
};

export default AddExhibition;
