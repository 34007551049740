import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { CategoryProduct } from '../../molecules';
import {
  removeSelectAllCategoryAllItemAction,
  selectAllCategoryItemsAction,
} from 'actions/action';

const CategoryProductsTable = ({
  items,
  category,
  amountForPage,
  selectPage,
}) => {
  const dispatch = useDispatch();
  const itemIds = useSelector((state) => state.selectCategory.itemIds);

  const [isAllChecked, setIsAllChecked] = useState(false);

  useEffect(() => {
    if (itemIds.length === Number(amountForPage)) {
      setIsAllChecked(true);
    } else {
      setIsAllChecked(false);
    }
  }, [itemIds, amountForPage]);

  return (
    <Table
      striped
      bordered
      hover
      size="sm"
      style={{ minWidth: 500, overflow: 'auto' }}
    >
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={(e) => {
                if (e.target.checked) {
                  const itemIds = items.map((el) => String(el.idx));
                  dispatch(selectAllCategoryItemsAction(itemIds));
                } else {
                  dispatch(removeSelectAllCategoryAllItemAction());
                }
              }}
            />
          </th>
          <th>이미지</th>
          <th>성별 / 브랜드명 / 상품명</th>
          <th>매핑 상태</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => {
          let mappingStatus = [];
          item.categories.forEach((itemEl) => {
            mappingStatus.push(
              category.find((categoryEl) => categoryEl.id === itemEl),
            );
          });
          return (
            <CategoryProduct
              key={item.idx}
              item={item}
              index={i}
              count={items.length * Number(selectPage) - amountForPage}
              selectPage={selectPage}
              mappingStatus={mappingStatus}
            />
          );
        })}
      </tbody>
    </Table>
  );
};

export default CategoryProductsTable;
