import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { RefObject, ChangeEvent, DependencyList } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { authState } from 'store';
import { range, request } from 'services';
import useSWR from 'swr';
import { toast } from 'react-toastify';

export function useObject<T>(
  initialObject: T
): [
  T,
  (obj: Partial<T>, callback?: (state: T) => void) => void,
  (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void,
  (keys?: Array<keyof T>) => void
] {
  const [state, setState] = useState<T>(initialObject);
  const callbackRef = useRef<(state: T) => void>();
  const isFirstCallbackCall = useRef<boolean>(true);
  const onChange = useCallback(
    (obj: Partial<T>, callback?: (state: T) => void) => {
      callbackRef.current = callback;
      setState((prevState) => ({ ...prevState, ...obj }));
    },
    [state]
  );
  const onEventChange = useCallback(
    ({
      target: { name, value },
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void =>
      setState((prevState) => ({ ...prevState, [name]: value })),
    [state]
  );
  const arrayToObject = (keys: Array<keyof T>): T => {
    if (!keys.length) return initialObject;
    const initial: any = {};
    keys.reduce((acc, cur) => (initial[cur] = initialObject[cur]), initial);
    return initial;
  };
  const resetState = (keys?: Array<keyof T>) =>
    keys
      ? setState((prevState) => ({ ...prevState, ...arrayToObject(keys) }))
      : setState(initialObject);
  useEffect(() => {
    if (isFirstCallbackCall.current) {
      isFirstCallbackCall.current = false;
      return;
    }
    callbackRef.current?.(state);
  }, [state]);
  return [state, onChange, onEventChange, resetState];
}

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage,
}) => {
  const DOTS = '...';

  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);
    const totalPageNumbers = siblingCount + 5;

    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

export function useQuery<T>(): {
  query: Partial<T>;
  updateQuery: (payload: Partial<T>) => void;
} {
  const location = useLocation();
  const { replace } = useHistory();
  const query = queryString.parse(location.search) as unknown as Partial<T>;
  const updateQuery = (payload: Partial<T>) => {
    const url = queryString.stringify({ ...query, ...payload });
    replace(`${location.pathname}?${url}`);
  };
  return useMemo(() => {
    return { query, updateQuery };
  }, [location]);
}

export const useUser = () => {
  const user = useRecoilValue(authState);
  const setUser = useSetRecoilState(authState);
  const resetUser = useResetRecoilState(authState);
  return { user, setUser, resetUser };
};

export function useOnClickOutside<T extends HTMLElement>(
  ref: RefObject<T>,
  handler: (event: MouseEvent | TouchEvent) => void,
  elementId?: string
): void {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      const el = ref?.current;
      if (!el || el.contains(event.target as Node)) {
        return;
      } else if (event.target && (event.target as any).id === elementId) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mouseup', listener);
    return () => {
      document.removeEventListener('mouseup', listener);
    };
  }, [ref, handler]);
}

export const useToggle = (initValue = false): [boolean, (value?: boolean) => void] => {
  const [state, setState] = useState(initValue);
  return [state, (value) => setState((p) => (typeof value == 'boolean' ? value : !p))];
};

export const useExhibition = (): [
  { exhibitions?: any[]; treeData?: any; onLoadData?: any },
  boolean
] => {
  const [treeData, setTreeData] = useState<any[]>([]);
  const { data, error } = useSWR<any>(`/exhibition?isExtended=true`, (key) =>
    request.get(key).then((exhibitions: any) => ({
      exhibitions,
      treeData: [
        {
          id: 'M',
          pId: null,
          value: 'M',
          title: '남성',
          isLeaf: false,
        },
        ...exhibitions
          .filter((exhibition: any) => exhibition.gender.includes('M'))
          .map((exhibition: any) => ({
            id: exhibition.id,
            pId: 'M',
            value: exhibition.id,
            title: exhibition.name,
            isActive: !!exhibition.isActive,
            isLeaf: false,
          })),
        {
          id: 'W',
          pId: null,
          value: 'W',
          title: '여성',
          isLeaf: false,
        },
        ...exhibitions
          .filter((exhibition: any) => exhibition.gender.includes('W'))
          .map((exhibition: any) => ({
            id: exhibition.id,
            pId: 'W',
            value: exhibition.id,
            title: exhibition.name,
            isActive: !!exhibition.isActive,
            isLeaf: false,
          })),
      ],
    }))
  );

  const onLoadData = async ({ id: pId }: any) => {
    try {
      const data: any[] = await request.get(`/exhibition/${pId}/categories`);
      setTreeData([
        ...treeData,
        ...data.map((data) => ({
          ...data,
          id: `${pId},${data.id}`,
          pId,
          value: `${pId},${data.id}`,
          title: `${data.name}`,
          isLeaf: true,
        })),
      ]);
    } catch (error) {}
  };

  return [
    { ...data, onLoadData, treeData: [...(data?.treeData ?? []), ...treeData] },
    !error && !data,
  ];
};

export const useComponentDidUpdate = (cb: Function, state: DependencyList) => {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) cb();
    else mounted.current = true;
  }, state);
};

export const useCopyText = () => {
  const copy = async (text: string, message?: string) => {
    if (!navigator || !navigator.clipboard) return;

    try {
      await navigator.clipboard.writeText(text);
      console.log('text', text);
      toast.success(message || '복사되었습니다.');
    } catch (err) {
      toast.error('복사에 실패했습니다. 다시 시도해주세요.');
    }
  };

  return copy;
};
