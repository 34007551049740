import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from 'api/axios';

import * as style from './style';
import { useUser } from 'services';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const ChangePasswordPage = (props) => {
  const { user } = useUser();

  const { push } = useHistory();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleChangePassword = () => {
    if (!user) {
      toast.info('로그인 상태가 아닙니다.');
      push('/login');
      return;
    }

    if (password.indexOf(' ') !== -1) {
      alert('공백은 입력할 수 없습니다 😢');
    } else if (password !== confirmPassword) {
      alert('비밀번호가 일치하지 않습니다.😣');
    } else {
      axios
        .post('/api/auth/useredit', {
          userID: user.id,
          userPW: password,
          userName: user.name,
          userMail: user.email,
        })
        .then((res) => {
          if (res.data.success) {
            alert('비밀번호를 변경했습니다.😄');
            props.history.push('/');
          } else {
            alert('비밀번호 변경에 실패했습니다. 다시 시도해 주세요 😣');
          }
        })
        .catch((err) => {
          alert(`
          📌 에러가 발생했습니다.! 
          아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    
          API 에러 발생 
          POST - /api/auth/useredit
          ${err}`);
          console.error(err);
        });
    }
  };

  return (
    <style.Wrapper>
      <style.Inner>
        <Form>
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="password"
            placeholder="password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <Form.Control
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            type="password"
            placeholder="confirm password"
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
          />
          <Button
            variant="dark"
            style={{
              marginTop: '20px',
              textAlign: 'center',
              width: '100%',
            }}
            onClick={handleChangePassword}
          >
            비밀번호 변경
          </Button>
        </Form>
      </style.Inner>
    </style.Wrapper>
  );
};

export default withRouter(ChangePasswordPage);
