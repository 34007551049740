import React from 'react';
import type { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';
import { DebounceInput } from 'react-debounce-input';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    'size' | 'prefix'
  > {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  onEnter?: () => void;
  prefix?: ReactNode;
  suffix?: ReactNode;
  align?: 'left' | 'right';
  debounce?: boolean;
  width?: number;
}
interface State {}

const ReInput: FunctionComponent<Props> = ({
  size,
  type,
  disabled,
  readOnly,
  className,
  onEnter,
  prefix,
  suffix,
  align,
  debounce,
  width,
  ...props
}) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !!onEnter) onEnter();
  };
  if (prefix || suffix) {
    return (
      <div
        className={classnames(
          'inline-flex justify-between items-center focus-within:ring-indigo-500 focus-within:border-indigo-500 border border-gray-300 rounded',
          {
            'bg-gray-100': disabled || readOnly,
            'bg-white': !disabled && !readOnly,
            'cursor-not-allowed': disabled,
            'cursor-default': readOnly,
            'text-xs p-1 rounded-sm': size === 'xs',
            'text-sm py-1 px-2 rounded': size === 'sm',
            'text-base py-2 px-3 rounded-md': size === 'md',
            'text-lg py-3 px-4 rounded-md': size === 'lg',
          },
          className
        )}
        style={{ width }}
      >
        {prefix}
        {!!debounce ? (
          <DebounceInput
            {...(props as any)}
            debounceTimeout={1500}
            disabled={disabled}
            readOnly={readOnly}
            className={classnames('border-0 focus:outline-none mx-1 flex-1 w-full', {
              'text-right': align === 'right',
              'bg-gray-100': disabled || readOnly,
            })}
            onKeyDown={onKeyDown}
            type={type}
            spellCheck={false}
          />
        ) : (
          <input
            {...props}
            disabled={disabled}
            readOnly={readOnly}
            className={classnames('border-0 focus:outline-none mx-1 flex-1 w-full', {
              'text-right': align === 'right',
              'bg-gray-100': disabled || readOnly,
            })}
            onKeyDown={onKeyDown}
            type={type}
            spellCheck={false}
          />
        )}
        {suffix}
      </div>
    );
  }
  return (
    <input
      {...props}
      disabled={disabled}
      readOnly={readOnly}
      className={classnames(
        'focus:ring-indigo-500 focus:border-indigo-500 block border-gray-300',
        {
          'bg-gray-100': disabled || readOnly,
          'bg-white': !disabled && !readOnly,
          'cursor-not-allowed': disabled,
          'cursor-default': readOnly,
          'text-xs p-1 rounded-sm': size === 'xs',
          'text-sm py-1 px-2 rounded': size === 'sm',
          'text-base py-2 px-3 rounded-md': size === 'md',
          'text-lg py-3 px-4 rounded-md': size === 'lg',
          'text-right': align === 'right',
        },
        className
      )}
      onKeyDown={onKeyDown}
      type={type}
      spellCheck={false}
    />
  );
};

ReInput.defaultProps = {
  size: 'md',
  type: 'text',
  align: 'left',
};

export default ReInput;
