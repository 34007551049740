import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 5vw;
  bottom: 5vw;
`;

export const Button = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 50px;
  background: black;
  margin-top: 17px;
`;
export const Img = styled.img`
  width: 40%;
  margin: auto;
`;
