import React from 'react';
import type { FunctionComponent } from 'react';
import classnames from 'classnames';

export interface Props {
  type: 'success' | 'info' | 'danger';
}
interface State {}

const Badge: FunctionComponent<Props> = ({ children, type }) => {
  return (
    <span
      className={classnames(
        'px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full',
        {
          'bg-green-100 text-green-800': type === 'success',
          'bg-gray-200 text-gray-800': type === 'info',
          'bg-red-200 text-red-700': type === 'danger',
        }
      )}
    >
      {children}
    </span>
  );
};

export default Badge;
