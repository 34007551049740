import styled from 'styled-components';

export const Outer = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  padding: 100px 0;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const CardOuter = {
  width: '70%',
  height: '100%',
};

export const Body = {
  overflowY: 'scroll',
};

export const ButtonStyle = {
  marginRight: '10px',
};
