export default {
  categoryId: null,
  categoryName: null,
  filterChecked: false,
  gender: null,
  isMatched: null,
  limit: 50,
  page: 1,
  shopId: null,
  minModifiedDate: null,
  maxModifiedDate: null,
};
