import { DuplicateIcon } from '@heroicons/react/outline';
import { Button, Card, InputNumber, ReInput, ReModal, Textarea } from 'components';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';
import type { IDaum } from 'types';
import { useParams } from 'react-router-dom';
import { copyText } from 'utils';

export interface Props {}
interface State {
  recipientName: string;
  phoneNumber: string;
  customsClearanceNumber: string;
  zipCode: string;
  address: string;
  addressDetail: string;
  deliveryMemo: string;
  isAddressOpen: boolean;
  englishAddress: string;
  englishAddressDetail: string;
  englishRecipientName: string;
  isTranslating: boolean;
  isUpdating: boolean;
  englishDeliveryMemo: string;
}

const OrderRecipientInfo: FunctionComponent<Props> = () => {
  const [
    {
      recipientName,
      phoneNumber,
      customsClearanceNumber,
      zipCode,
      address,
      addressDetail,
      deliveryMemo,
      isAddressOpen,
      englishAddress,
      englishAddressDetail,
      englishRecipientName,
      isTranslating,
      isUpdating,
      englishDeliveryMemo,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    recipientName: '',
    phoneNumber: '',
    customsClearanceNumber: '',
    zipCode: '',
    address: '',
    addressDetail: '',
    deliveryMemo: '',
    isAddressOpen: false,
    englishAddress: '',
    englishAddressDetail: '',
    englishRecipientName: '',
    isTranslating: false,
    isUpdating: false,
    englishDeliveryMemo: '',
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();

  const get = async () => {
    try {
      const { deliveryInfo } = await request.get<any, { deliveryInfo: any }>(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/delivery`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setState({
        address: deliveryInfo.address || '',
        addressDetail: deliveryInfo.addressDetail || '',
        customsClearanceNumber: deliveryInfo.customsClearanceNumber || '',
        deliveryMemo: deliveryInfo.deliveryMemo || '',
        englishAddress: deliveryInfo.englishAddress || '',
        englishAddressDetail: deliveryInfo.englishAddressDetail || '',
        englishDeliveryMemo: deliveryInfo.englishDeliveryMemo || '',
        englishRecipientName: deliveryInfo.englishRecipientName || '',
        recipientName: deliveryInfo.recipientName || '',
        zipCode: deliveryInfo.zipCode || '',
        phoneNumber: deliveryInfo.phoneNumber || '',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const update = async () => {
    setState({ isUpdating: true });
    try {
      await request.put(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/delivery`,
        {
          address,
          addressDetail,
          englishAddress,
          englishAddressDetail,
          englishDeliveryMemo,
          englishRecipientName,
          zipCode,
          recipientName,
          customsClearanceNumber,
          deliveryMemo,
          phoneNumber,
        },
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      toast.success('배송정보가 수정되었습니다.');
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isUpdating: false });
    }
  };

  const openMap = () => {
    const element_layer = document.getElementById('daum');
    // @ts-ignore
    new daum.Postcode({
      oncomplete: function (data: IDaum) {
        setState({
          isAddressOpen: false,
          englishAddress: data.jibunAddressEnglish,
          zipCode: data.zonecode,
          address: data.address,
        });
      },
      autoMappingJibun: true,
      width: '100%',
    }).embed(element_layer);
  };

  const onTranslate = async () => {
    if (!recipientName || !addressDetail) {
      toast.info('수령자명, 상세주소를 입력해주세요.');
      return;
    }

    setState({ isTranslating: true });
    let promises = [
      request.get('/util/translate/name', {
        params: { text: recipientName || '' },
        headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
      }),
      request.get('/util/translate', {
        params: { from: 'ko', to: 'en', text: addressDetail || '' },
        headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
      }),
    ];
    if (!!deliveryMemo)
      promises.push(
        request.get('/util/translate', {
          params: { from: 'ko', to: 'en', text: deliveryMemo || '' },
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        })
      );
    try {
      const result = await Promise.all(promises);
      let state: Partial<State> = {
        englishRecipientName: result[0].data || '',
        englishAddressDetail: result[1].data || '',
      };
      if (!!result[2]) state.englishDeliveryMemo = result[2].data || '';
      setState(state);
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isTranslating: false });
    }
  };

  /**
   * @example
   * - 5 : ['537-5', 'Sinsa-dong', 'Gangnam-gu', 'Seoul', 'Korea']
   * - 6 : ['578-9', 'Baekhyeon-dong', 'Bundang-gu', 'Seongnam-si', 'Gyeonggi-do', 'Korea']
   * - 7 : ['2615-9', '2(i)-ri', 'Hagwi', 'Aewol-eup', 'Jeju-si', 'Jeju-do', 'Korea']
   *
   * 첫 번쨰 인자는 우편번호, 마지막 인자는 Korea로 고정이라서 제외해서 계산.
   * @returns
   * 영문 행정구역
   */
  const englishDistrict: string = useMemo(() => {
    if (!englishAddress) return '';
    const split = englishAddress.split(', ');

    if (split.length === 5) return split[3];
    else if (split.length === 6) return split.slice(3, 5).join(', ');
    else if (split.length === 7) return split.slice(4, 6).join(', ');
    else return '';
  }, [englishAddress]);

  /**
   * @returns
   * 영문 시군구
   */
  const englishCity: string = useMemo(() => {
    if (!englishAddress) return '';
    const split = englishAddress.split(', ');

    if (split.length === 5 || split.length === 6) return split.slice(1, 3).join(', ');
    else if (split.length === 7) return split.slice(1, 4).join(', ');
    else return '';
  }, [englishAddress]);

  useEffect(() => {
    if (isAddressOpen) openMap();
  }, [isAddressOpen]);

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <Card
        title="수령자 정보"
        items={[
          {
            name: '수령자명',
            renderItem: () => (
              <ReInput
                size="xs"
                value={recipientName}
                name="recipientName"
                onChange={onChange}
              />
            ),
          },
          {
            name: '수령자명 (영문)',
            renderItem: () => <ReInput size="xs" value={englishRecipientName} readOnly />,
          },
          {
            name: '연락처',
            renderItem: () => (
              <InputNumber
                size="xs"
                format="###-####-####"
                value={phoneNumber}
                name="phoneNumber"
                onChange={onChange}
              />
            ),
          },
          {
            name: '개인통관고유부호',
            renderItem: () => (
              <ReInput
                size="xs"
                value={customsClearanceNumber}
                name="customsClearanceNumber"
                onChange={onChange}
              />
            ),
          },
          {
            name: '배송지 주소',
            renderItem: () => (
              <div className="flex flex-col gap-2">
                <ReInput
                  size="xs"
                  readOnly
                  value={zipCode}
                  name="zipCode"
                  onChange={onChange}
                  onClick={() => setState({ isAddressOpen: true })}
                />
                <ReInput
                  size="xs"
                  readOnly
                  className="w-56"
                  value={address}
                  name="address"
                  onChange={onChange}
                  onClick={() => setState({ isAddressOpen: true })}
                />
                <ReInput
                  size="xs"
                  className="w-56"
                  value={addressDetail}
                  name="addressDetail"
                  onChange={onChange}
                />
              </div>
            ),
          },
          {
            name: '배송지 주소 (영문)',
            renderItem: () => (
              <div className="space-y-2">
                <ReInput
                  size="xs"
                  readOnly
                  placeholder="영문주소"
                  className="w-96"
                  value={englishAddress}
                  suffix={
                    <DuplicateIcon
                      onClick={() => copyText(englishAddress)}
                      className="w-4 h-4 cursor-pointer"
                    />
                  }
                />
                <ReInput
                  size="xs"
                  value={englishDistrict}
                  className="w-96"
                  readOnly
                  placeholder="도/특별시/광역시"
                  suffix={
                    <DuplicateIcon
                      onClick={() => copyText(englishDistrict)}
                      className="w-4 h-4 cursor-pointer"
                    />
                  }
                />
                <ReInput
                  size="xs"
                  value={englishCity}
                  readOnly
                  className="w-96"
                  placeholder="시 이름"
                  suffix={
                    <DuplicateIcon
                      onClick={() => copyText(englishCity)}
                      className="w-4 h-4 cursor-pointer"
                    />
                  }
                />
                <ReInput
                  size="xs"
                  readOnly
                  placeholder="영문상세주소"
                  className="w-96"
                  value={englishAddressDetail}
                  suffix={
                    <DuplicateIcon
                      onClick={() => copyText(englishAddressDetail)}
                      className="w-4 h-4 cursor-pointer"
                    />
                  }
                />
                <div>
                  <ReInput
                    size="xs"
                    value={zipCode}
                    readOnly
                    placeholder="우편번호"
                    suffix={
                      <DuplicateIcon
                        className="w-4 h-4 cursor-pointer"
                        onClick={() => copyText(zipCode)}
                      />
                    }
                  />
                </div>
              </div>
            ),
          },
          {
            name: '배송메시지',
            renderItem: () => (
              <Textarea
                value={deliveryMemo}
                name="deliveryMemo"
                onChange={onChange}
                rows={4}
              />
            ),
          },
          {
            name: '배송메시지 (영문)',
            renderItem: () => <Textarea rows={4} readOnly value={englishDeliveryMemo} />,
          },
        ]}
      />
      <div className="flex justify-center">
        <div className="flex gap-3">
          <Button onClick={onTranslate} isLoading={isTranslating}>
            번역
          </Button>
          <Button onClick={update} isLoading={isUpdating}>
            수정 저장
          </Button>
          <Button theme="secondary" onClick={get}>
            수정 초기화
          </Button>
        </div>
      </div>
      <ReModal
        isNotPadding
        title="주소 검색"
        maxWidth="max-w-sm"
        isOpen={isAddressOpen}
        onClose={() => setState({ isAddressOpen: false })}
      >
        <div id="daum" />
      </ReModal>
    </>
  );
};

export default OrderRecipientInfo;
