import { Card, GridTable, NumberStat, Tooltip } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import {
  ORDER_DISCOUNT_TYPE,
  ORDER_PAY_INFO_STATUS,
  PAY_METHOD,
  request,
  useObject,
} from 'services';
import { useParams } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { priceFormat } from 'utils';
import dayjs from 'dayjs';

export interface Props {}
interface State {
  firstPayInfo: {
    deliveryFee: number;
    discountAmount: number;
    itemAmount: number;
    needPayAmount: number;
    payAmount: number;
    point: number;
    deliveryFeeDetail: Array<{
      shopName: string;
      deliveryFee: number;
    }>;
    discountDetail: Array<{
      type: string;
      value: number;
    }>;
  };
  lastPayInfo: {
    deliveryFee: number;
    discountAmount: number;
    itemAmount: number;
    needPayAmount: number;
    payAmount: number;
    point: number;
    deliveryFeeDetail: Array<{
      shopName: string;
      deliveryFee: number;
    }>;
    discountDetail: Array<{
      type: string;
      value: number;
    }>;
  };
  payHistory: Array<{
    createdAt: string;
    payMethod: keyof typeof PAY_METHOD;
    deliveryFee: number;
    itemAmount: number;
    payAmount: number;
    discountAmount: number;
    refundAmount: number;
    relatedItemOrders: number[];
    type: keyof typeof ORDER_PAY_INFO_STATUS;
  }>;
}

const CardItem = ({ children }) => (
  <div className="flex items-center justify-end gap-1 w-full">{children}</div>
);

const OrderPayInfo: FunctionComponent<Props> = () => {
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const [{ firstPayInfo, lastPayInfo, payHistory }, setState] = useObject<State>({
    firstPayInfo: {
      deliveryFee: 0,
      discountAmount: 0,
      itemAmount: 0,
      needPayAmount: 0,
      payAmount: 0,
      point: 0,
      deliveryFeeDetail: [],
      discountDetail: [],
    },
    lastPayInfo: {
      deliveryFee: 0,
      discountAmount: 0,
      itemAmount: 0,
      needPayAmount: 0,
      payAmount: 0,
      point: 0,
      deliveryFeeDetail: [],
      discountDetail: [],
    },
    payHistory: [],
  });

  const get = async () => {
    try {
      const data = await request.get<any, State>(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/pay-info`,
        {
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setState({
        firstPayInfo: data.firstPayInfo,
        lastPayInfo: data.lastPayInfo,
        payHistory: data.payHistory,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <>
      <div className="flex gap-4">
        <div className="flex-1">
          <Card
            title="최종 결제 정보"
            items={[
              {
                name: '상품구매금액',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={lastPayInfo.itemAmount} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '배송비 편집샵',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={lastPayInfo.deliveryFee} />
                    </div>
                    <Tooltip
                      padding={false}
                      content={
                        <table className="whitespace-nowrap">
                          <thead className="bg-gray-100 text-gray-500 py-2 px-3">
                            <tr>
                              <th className="py-2 px-3">편집샵명</th>
                              <th className="py-2 px-3 text-right">결제금액</th>
                            </tr>
                          </thead>
                          <tbody className="font-normal">
                            {lastPayInfo.deliveryFeeDetail.map((item, key) => (
                              <tr key={key}>
                                <td className="py-2 px-3">{item.shopName}</td>
                                <td className="py-2 px-3 text-right">
                                  {priceFormat(item.deliveryFee)} 원
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      }
                      position="top"
                    >
                      <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                    </Tooltip>
                  </CardItem>
                ),
              },
              {
                name: '결제예정금액',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={lastPayInfo.needPayAmount} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '적립혜택',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={lastPayInfo.point} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '총 실결제금액',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={lastPayInfo.payAmount} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '할인혜택',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={lastPayInfo.discountAmount} />
                    </div>
                    <Tooltip
                      padding={false}
                      content={
                        <table className="whitespace-nowrap">
                          <thead className="bg-gray-100 text-gray-500 py-2 px-3">
                            <tr>
                              <th className="py-2 px-3">유형</th>
                              <th className="py-2 px-3 text-right">금액</th>
                            </tr>
                          </thead>
                          <tbody className="font-normal">
                            {lastPayInfo.discountDetail.map((item, key) => (
                              <tr key={key}>
                                <td className="py-2 px-3">
                                  {ORDER_DISCOUNT_TYPE[item.type]}
                                </td>
                                <td className="py-2 px-3 text-right">
                                  {priceFormat(item.value)} 원
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      }
                      position="top"
                    >
                      <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                    </Tooltip>
                  </CardItem>
                ),
              },
            ]}
          />
        </div>
        <div className="flex-1">
          <Card
            title="최초 결제 정보"
            items={[
              {
                name: '상품구매금액',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={firstPayInfo.itemAmount} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '배송비 편집샵',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={firstPayInfo.deliveryFee} />
                    </div>
                    <Tooltip
                      padding={false}
                      content={
                        <table className="whitespace-nowrap">
                          <thead className="bg-gray-100 text-gray-500 py-2 px-3">
                            <tr>
                              <th className="py-2 px-3">편집샵명</th>
                              <th className="py-2 px-3 text-right">결제금액</th>
                            </tr>
                          </thead>
                          <tbody className="font-normal">
                            {firstPayInfo.deliveryFeeDetail.map((item, key) => (
                              <tr key={key}>
                                <td className="py-2 px-3">{item.shopName}</td>
                                <td className="py-2 px-3 text-right">
                                  {priceFormat(item.deliveryFee)} 원
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      }
                      position="left"
                    >
                      <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                    </Tooltip>
                  </CardItem>
                ),
              },
              {
                name: '결제예정금액',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={firstPayInfo.needPayAmount} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '적립혜택',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={firstPayInfo.point} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '총 실결제금액',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={firstPayInfo.payAmount} />
                    </div>
                  </CardItem>
                ),
              },
              {
                name: '할인혜택',
                renderItem: () => (
                  <CardItem>
                    <div>
                      <NumberStat value={firstPayInfo.discountAmount} />
                    </div>
                    <Tooltip
                      content={
                        <table className="whitespace-nowrap">
                          <thead className="bg-gray-100 text-gray-500 py-2 px-3">
                            <tr>
                              <th className="py-2 px-3">유형</th>
                              <th className="py-2 px-3 text-right">금액</th>
                            </tr>
                          </thead>
                          <tbody className="font-normal">
                            {firstPayInfo.discountDetail.map((item, key) => (
                              <tr key={key}>
                                <td className="py-2 px-3">
                                  {ORDER_DISCOUNT_TYPE[item.type]}
                                </td>
                                <td className="py-2 px-3 text-right">
                                  {priceFormat(item.value)} 원
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      }
                      padding={false}
                      position="left"
                    >
                      <InformationCircleIcon className="w-5 h-5 text-gray-400" />
                    </Tooltip>
                  </CardItem>
                ),
              },
            ]}
          />
        </div>
      </div>
      <div className="text-sm font-semibold mt-7 mb-2">결제 히스토리</div>
      <GridTable
        columns={[
          '일자',
          '구분',
          '결제(환불)수단',
          '입금정보',
          '총상품가액',
          '취소상품주문번호',
          '배송비',
          '주문금액',
          '할인금액',
          '실결제금액',
          '매출액',
          '할인금액환원액',
          '예치금환원',
          '적립금환원',
          '환불/취소 금액',
          '교환반품수수료',
          '관부가세',
        ]}
        noSelection
        list={payHistory}
        renderItem={(item, key) => (
          <div key={key}>
            <div aria-label="일자">
              {dayjs(item.createdAt).format('YYYY. MM. DD. HH:mm:ss')}
            </div>
            <div aria-label="구분">{ORDER_PAY_INFO_STATUS[item.type]}</div>
            <div aria-label="결제(환불)수딘">{PAY_METHOD[item.payMethod]}</div>
            <div aria-label="입금정보"></div>
            <div aria-label="총상품가액">{priceFormat(item.itemAmount)} 원</div>
            <div aria-label="취소상품주문번호">
              {item.relatedItemOrders.map((itemOrderNumber) => (
                <a
                  href={`/order/${fetchingOrderNumber}?tab=1&status=1&highlight=${itemOrderNumber}`}
                >
                  {itemOrderNumber}
                </a>
              ))}
            </div>
            <div aria-label="배송비">{priceFormat(item.deliveryFee)} 원</div>
            <div aria-label="주문금액"></div>
            <div aria-label="할인금액">{priceFormat(item.discountAmount)} 원</div>
            <div aria-label="실결제금액">{priceFormat(item.payAmount)} 원</div>
            <div aria-label="매출액"></div>
            <div aria-label="할인금액환원액"></div>
            <div aria-label="예치금환원"></div>
            <div aria-label="적립금환원"></div>
            <div aria-label="환불/취소 금액">
              {!!item.refundAmount && `${priceFormat(item.refundAmount)} 원`}
            </div>
            <div aria-label="교환반품수수료"></div>
            <div aria-label="관부가세"></div>
          </div>
        )}
      />
    </>
  );
};

export default OrderPayInfo;
