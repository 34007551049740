import { BigBannerItem, MainLayout, saveLayouts } from 'pages/ManageMainLayout/utils';
import React, { useState } from 'react';
import * as S from './style';
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { triggerState } from 'pages/ManageMainContent';
import dayjs from 'dayjs';

type Props = {
  mode: 'm' | 'w';
  layout: MainLayout;
};

const ManageBigBanner = ({ mode, layout }: Props) => {
  const history = useHistory();

  const [bigBannerItems, setBigBannerItems] = useState<BigBannerItem[]>(
    layout.bigBannerList ?? []
  );

  const setTrigger = useSetRecoilState(triggerState);

  const resetChanges = () => {
    if (window.confirm('모든 변경사항을 초기화하시겠습니까?')) {
      setBigBannerItems(layout.bigBannerList ?? []);
    }
  };

  const saveChanges = () => {
    if (window.confirm('변경사항을 저장하시겠습니까?')) {
      saveLayouts(mode, [
        {
          ...layout,
          requestType: 'UPDATE',
          bigBannerList: [
            ...bigBannerItems.map((item): any => ({ ...item, requestType: 'UPDATE' })),
            ...(layout.bigBannerList ?? [])
              .filter(
                (bigBanner): any =>
                  !bigBannerItems.map(({ idx }) => idx).includes(bigBanner.idx)
              )
              .map((bigBanner): any => ({ ...bigBanner, requestType: 'DELETE' })),
          ],
        },
      ]).then(() => setTrigger((p) => p + 1));
    }
  };

  const goUp = (index: number) => {
    if (index > 0) {
      setBigBannerItems((p) => {
        [p[index - 1], p[index]] = [p[index], p[index - 1]];
        return p.map((bigBanner, order) => ({ ...bigBanner, order }));
      });
    }
  };

  const goDown = (index: number) => {
    if (index < bigBannerItems.length - 1) {
      setBigBannerItems((p) => {
        [p[index + 1], p[index]] = [p[index], p[index + 1]];
        return p.map((bigBanner, order) => ({ ...bigBanner, order }));
      });
    }
  };

  const deleteItem = (index: number) => {
    if (window.confirm(`${bigBannerItems[index].title} 배너를 삭제하시겠습니까?`)) {
      setBigBannerItems((p) => p.filter((_, bigBannerIndex) => bigBannerIndex !== index));
    }
  };

  const toggleVisibility = (index: number) => {
    if (window.confirm('활성 상태를 변경하시겠습니까?')) {
      setBigBannerItems((p) =>
        p.map((bigBanner, bigBannerIndex) =>
          bigBannerIndex === index
            ? { ...bigBanner, isVisible: !bigBanner.isVisible }
            : bigBanner
        )
      );
    }
  };

  return (
    <>
      <p>대배너는 최대 5개까지 게시할 수 있습니다.</p>
      <S.ButtonContainer>
        <Button variant="warning" onClick={resetChanges}>
          초기화
        </Button>{' '}
        <Button onClick={saveChanges}>저장하기</Button>{' '}
        <Link to={`/content/main/${mode}/component/${layout.idx}/add`}>
          <Button variant="outline-dark">대배너 추가</Button>
        </Link>
      </S.ButtonContainer>
      <S.BigBannerTable style={{ background: 'black', color: 'white' }}>
        <span>노출 순서</span>
        <span>삭제</span>
        <span>순서 변경</span>
        <span>게시 상태</span>
        <span>배너</span>
        <span>게시 기간</span>
        <span>최근 수정</span>
      </S.BigBannerTable>
      {bigBannerItems.map((data, index) => (
        <S.BigBannerTableItem key={data.idx}>
          <span>{data.order}</span>
          <Button variant="danger" onClick={() => deleteItem(index)}>
            삭제
          </Button>
          <div>
            <Button variant="secondary" onClick={() => goUp(index)}>
              👆
            </Button>{' '}
            <Button variant="secondary" onClick={() => goDown(index)}>
              👇
            </Button>
          </div>
          {data.isVisible ? (
            <Button variant="outline-success" onClick={() => toggleVisibility(index)}>
              켜짐
            </Button>
          ) : (
            <Button variant="outline-danger" onClick={() => toggleVisibility(index)}>
              꺼짐
            </Button>
          )}
          <div>
            <S.PreviewBanner src={data.mobileImageUrl} alt="" />
            <Link to={history.location.pathname + `/modify/${data.idx}`}>
              {data.title}
            </Link>
          </div>
          {!data.startAt && !data.expireAt ? (
            <span>영구</span>
          ) : (
            <div>
              <span>{dayjs(data.startAt).format('YYYY.MM.DD HH:MM')}</span>~
              <span>{dayjs(data.expireAt).format('YYYY.MM.DD HH:MM')}</span>
            </div>
          )}
          <span>{dayjs(data.updatedAt).format('YYYY.MM.DD HH:MM')}</span>
        </S.BigBannerTableItem>
      ))}
    </>
  );
};

export default ManageBigBanner;
