import { FORM, DISCOUNT_TYPE, APPLY_STATUS } from './constants';
import * as utils from './utils';

export const searchTemplate = [
  [{ title: '타이틀', field: 'title', formType: FORM.INPUT }],
  [
    {
      title: '활성상태',
      field: 'isActive',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: 'ON', value: 1 },
        { name: 'OFF', value: 0 },
      ],
    },
    {
      title: '업데이트 방식',
      field: 'updateType',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: '수동', value: 0 },
        { name: '자동', value: 1 },
      ],
    },
  ],
  [
    {
      title: 'VIP 여부',
      field: 'isVIP',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: '일반', value: 0 },
        { name: 'VIP', value: 1 },
      ],
    },
    {
      title: '가격반영 대상',
      field: 'appliedAt',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: '편집샵판매가', value: 'isUserApplicable' },
        { name: '페칭판매가', value: 'isFetchingApplicable' },
      ],
    },
  ],
  [
    {
      title: '편집샵',
      field: 'shopId',
      formType: FORM.SELECT,
      optionType: 'api',
      api: utils.getShopList,
      matchingKey: { value: 'id', name: 'name' },
    },
    {
      title: '할인 타입',
      field: 'discountType',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: '% 할인', value: DISCOUNT_TYPE.DISCOUNT_WITH_RATE },
        { name: '고정가격 할인', value: DISCOUNT_TYPE.DISCOUNT_WITH_CONST },
        { name: '무료배송', value: DISCOUNT_TYPE.FREE_DELIVERY },
      ],
    },
  ],
  [
    {
      title: '담당자',
      field: 'assigneeId',
      formType: FORM.SELECT,
      optionType: 'api',
      api: utils.getUserList,
      matchingKey: { value: 'id', name: 'admin_name' },
    },
    {
      title: '진행기간',
      field: ['startedAt', 'endedAt'],
      formType: FORM.CALENDAR,
    },
  ],
  [
    {
      title: '업데이트 현황',
      field: 'applyStatus',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: '신규', value: APPLY_STATUS.NEW.value },
        { name: '업데이트 예정', value: APPLY_STATUS.UPDATING.value },
        { name: '업데이트 완료', value: APPLY_STATUS.UPDATED.value },
        { name: '업데이트 불필요', value: APPLY_STATUS.NO_UPDATE_NEEDED.value },
        { name: '종료확인 예정', value: APPLY_STATUS.TO_BE_TERMINATED.value },
        { name: '종료확인 완료', value: APPLY_STATUS.TERMINATED.value },
      ],
    },
    {
      title: '노출 위치',
      field: 'displayedAt',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: '앱 모아보기', value: 'isVisibleToAppCategory' },
        { name: '앱 자사몰 하단', value: 'isVisibleToAppItemPage' },
        { name: '앱 PC', value: 'isVisibleToCafe24' },
        { name: '웹 모바일', value: 'isVisibleToCafe24Mobile' },
      ],
    },
  ],
  [
    {
      title: '중요 프로모션 여부',
      field: 'isPrioritized',
      formType: FORM.RADIO,
      items: [
        { name: '전체', value: 'all' },
        { name: 'null', value: 0 },
        { name: '중요', value: 1 },
      ],
    },
  ],
];
