import styled from 'styled-components';

export const Table = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #b4b4b4;
  border-bottom: none;
`;
export const Tr = styled.div`
  border-bottom: 0.5px solid #b4b4b4;
  display: flex;
  flex-wrap: wrap;
`;
export const Left = styled.div`
  display: flex;
  width: 15%;
  padding: 13px 11px;
  background: #e4e4e4;
  border-right: 0.5px solid #b4b4b4;
`;
export const Td = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  width: 35%;
`;

export const InputDate = styled.input.attrs({ type: 'date' })`
  margin: 0 6px;
`;
export const Div = styled.div`
  width: ${({ widthPcnt }) => widthPcnt}%;
  border-right: 0.5px solid #b4b4b4;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ noBorder }) => (noBorder ? 'border:none;' : '')}
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 6px 0 0;
`;
export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 16px;
`;
export const CheckBox = styled.input.attrs({ type: 'checkbox' })`
  min-width: 20px;
  min-height: 20px;
`;
