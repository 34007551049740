import React, { useEffect, useState } from 'react';
import { BrandShopFilterOptions, PageButtonList, ShopTable, Title } from 'components';
import * as style from './style';
import axios from 'api/axios';

export default () => {
  const [totalCount, setTotalCount] = useState(0);
  const [buttonList, setButtonList] = useState([]);

  const [shops, setShops] = useState([]);
  const [searchOptions, setSearchOptions] = useState({
    nameType: 'K',
    name: '',
    isApp: '전체',
    isCafe24Upload: '전체',
    isNaverFeed: '전체',
    isFetchingPayment: '전체',
    country: '전체',
    gender: ['M', 'W', 'MW'],
    path: '전체',
    shopType: ['해외편집샵', '국내편집샵', '구매대행', '병행수입'],
    limit: '10',
    page: 1,
  });

  const handleNameType = (e) => {
    setSearchOptions({
      ...searchOptions,
      nameType: e.target.value,
    });
  };

  const handleShopName = (e) => {
    setSearchOptions({
      ...searchOptions,
      name: e.target.value,
    });
  };

  const handleIsAppChecked = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        isApp: e.target.value,
      });
    }
  };

  const handleIsCafe24Upload = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        isCafe24Upload: e.target.value,
      });
    }
  };

  const handleIsNaverFeed = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        isNaverFeed: e.target.value,
      });
    }
  };

  const handleIsFetchingPayment = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        isFetchingPayment: e.target.value,
      });
    }
  };

  const handleCountry = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        country: e.target.value,
      });
    }
  };

  const handlePath = (e) => {
    if (e.target.checked) {
      setSearchOptions({
        ...searchOptions,
        path: e.target.value,
      });
    }
  };

  const handleGender = (e) => {
    let result = searchOptions.gender;
    result = result.filter((el) => el !== e.target.value);

    if (e.target.checked) {
      if (e.target.value === '전체') {
        setSearchOptions({
          ...searchOptions,
          gender: ['M', 'W', 'MW'],
        });
      } else {
        result.push(e.target.value);
        setSearchOptions({
          ...searchOptions,
          gender: result,
        });
      }
    } else {
      if (result.length) {
        setSearchOptions({
          ...searchOptions,
          gender: result,
        });
      } else {
        setSearchOptions({
          ...searchOptions,
          gender: ['M', 'W', 'MW'],
        });
      }
    }
  };

  const handleShopType = (e) => {
    let result = searchOptions.shopType;
    result = result.filter((el) => el !== e.target.value);
    if (e.target.checked) {
      if (e.target.value === '전체') {
        setSearchOptions({
          ...searchOptions,
          shopType: ['해외편집샵', '국내편집샵', '구매대행', '병행수입'],
        });
      } else {
        result.push(e.target.value);
        setSearchOptions({
          ...searchOptions,
          shopType: result,
        });
      }
    } else {
      if (result.length) {
        setSearchOptions({
          ...searchOptions,
          shopType: result,
        });
      } else {
        setSearchOptions({
          ...searchOptions,
          shopType: ['해외편집샵', '국내편집샵', '구매대행', '병행수입'],
        });
      }
    }
  };

  const handleParams = () => {
    const params = {
      v2: true,
      limit: Number(searchOptions.limit),
      page: searchOptions.page,
      gender: searchOptions.gender,
      type: searchOptions.shopType,
    };
    if (searchOptions.name) {
      searchOptions.nameType === 'K'
        ? (params.krName = searchOptions.name)
        : (params.name = searchOptions.name);
    }
    searchOptions.isApp !== '전체' &&
      (params.isActive = Boolean(Number(searchOptions.isApp)));
    searchOptions.isCafe24Upload !== '전체' &&
      (params.isCafe24Uploading = Number(searchOptions.isCafe24Upload));
    searchOptions.isFetchingPayment !== '전체' &&
      (params.isFetchingPay = Boolean(Number(searchOptions.isFetchingPayment)));
    searchOptions.isNaverFeed !== '전체' &&
      (params.cafe24Uploadable = Boolean(Number(searchOptions.isNaverFeed)));
    searchOptions.country !== '전체' && (params.country = searchOptions.country);
    searchOptions.path !== '전체' && (params.getDataFrom = searchOptions.path);

    return params;
  };

  useEffect(() => {
    let initial = true;

    const params = handleParams();

    axios
      .get('/api/shops', { params })
      .then((res) => {
        if (initial) {
          const result = res.data.data.sort(function (a, b) {
            if (a.krName > b.krName) {
              return 1;
            }
            if (a.krName < b.krName) {
              return -1;
            }
            return 0;
          });
          setShops(result);
          setTotalCount(res.data.totalCount);
          let pageList = [];
          for (let i = 1; i <= res.data.totalPage; i++) {
            pageList.push(i);
          }
          setButtonList(pageList);
        }
      })
      .catch((err) => {
        if (err.response.status !== 401) {
          alert(`
          📌 에러가 발생했습니다.! 
          아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
      
          API 에러 발생 
          GET - /api/shops
          ${err}`);
          console.error(err);
        }
      });

    return () => {
      initial = false;
    };
  }, [searchOptions]);

  return (
    <style.Container>
      <Title title="편집샵 목록" description="페칭이 취급하는 전체 편집샵 목록입니다." />
      <BrandShopFilterOptions
        target="편집샵"
        targetList={shops}
        options={searchOptions}
        handleIsAppChecked={handleIsAppChecked}
        handleIsCafe24Upload={handleIsCafe24Upload}
        handleIsNaverFeed={handleIsNaverFeed}
        handleIsFetchingPayment={handleIsFetchingPayment}
        handleCountry={handleCountry}
        handlePath={handlePath}
        handleGender={handleGender}
        handleShopType={handleShopType}
        handleNameType={handleNameType}
        handleName={handleShopName}
      />
      <div style={{ padding: 30 }} />
      <strong>[총 {totalCount}개]</strong>
      <style.Bar>
        <style.Select
          onChange={(e) => {
            setSearchOptions({
              ...searchOptions,
              page: 1,
              limit: e.target.value,
            });
          }}
        >
          <option value={'10'}>10개</option>
          <option value={'50'}>50개</option>
          <option value={'100'}>100개</option>
        </style.Select>
      </style.Bar>
      <ShopTable shops={shops} />
      <style.ButtonListWrapper>
        <PageButtonList
          buttonList={buttonList}
          page={searchOptions.page}
          setPage={(value) => {
            setSearchOptions({
              ...searchOptions,
              page: value,
            });
          }}
        />
      </style.ButtonListWrapper>
    </style.Container>
  );
};
