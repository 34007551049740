import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import fetchingAPI from 'api/fetchingAPI';

const ProductGroupModal = ({ show, onHide, targetIds }) => {
  const [productGroups, setProductGroups] = useState([]);

  const [id, setId] = useState(null);
  const [method, setMethod] = useState('add');

  const fetchProductGroups = async () => {
    try {
      const { data } = await fetchingAPI.getProductGroups({ limit: 500 });
      setProductGroups(data.data ?? []);
    } catch (error) {
      alert(
        `err in fetchProductGroups(), in <ProductGroupModal/>, ${error.message}`,
      );
    }
  };

  const changeInfo = (id, targetIds, method) => {
    if (method === 'add') {
      putItems(id, toNumber(targetIds));
    } else if (method === 'delete') {
      deleteItems(id, toNumber(targetIds));
    } else {
      alert('error');
    }
  };

  useEffect(() => {
    if (show) {
      fetchProductGroups();
    }
  }, [show]);

  useEffect(() => {
    setId(null);
    setMethod('add');
  }, [show]);

  const onChangeId = (e) => {
    setId(Number(e.target.value));
  };

  const onChangeMethod = (e) => {
    setMethod(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>상품 그룹 정보 일괄변경</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{targetIds.length}개의 상품을 선택했습니다.</h4>
        <p>
          <select value={id || 'none'} onChange={onChangeId}>
            <option value="none">선택하세요</option>
            {productGroups.map(({ id, name }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </select>
          상품 그룹에
          <select value={method} onChange={onChangeMethod}>
            <option value="add">추가</option>
            <option value="delete">삭제</option>
          </select>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            changeInfo(id, targetIds, method);
            onHide();
          }}
        >
          변경
        </Button>
        <Button variant="secondary" onClick={onHide}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductGroupModal;

const putItems = async (id, payload) => {
  try {
    await fetchingAPI.putProductToProductGroup(id, payload);
    alert(`상품 그룹에 ${payload.length}개 상품을 추가했습니다.`);
  } catch (error) {
    alert(`err in putItems(), in <ProductGroupModal/>, ${error.message}`);
  }
};

const deleteItems = async (id, payload) => {
  try {
    await fetchingAPI.deleteProductInProductGroup(id, payload);
    alert(`상품 그룹에 ${payload.length}개 상품을 삭제했습니다.`);
  } catch (error) {
    alert(`err in deleteItems(), in <ProductGroupModal/>, ${error.message}`);
  }
};

const toNumber = (arr) => arr.map((value) => Number(value));
