import classnames from 'classnames';
import { NumberStat } from 'components';
import React from 'react';
import { priceFormat } from 'utils';

export const DashboardCard: React.FC<{
  title: string | JSX.Element;
  date?: string;
  span?: number;
}> = ({ children, title, date, span = 1 }) => {
  return (
    <div
      className="bg-white rounded span"
      style={{ gridColumn: `span ${span} / span ${span}` }}
    >
      <div className="p-4 border-b border-gray-200 flex items-center justify-between">
        <span className="font-bold text-2xl">{title}</span>
        <span className="text-gray-400">{date}</span>
      </div>
      <div className="p-4">{children}</div>
    </div>
  );
};

export const DashboardItem: React.FC<{ name: string; maxWidth?: number }> = ({
  name,
  children,
  maxWidth,
}) => {
  return (
    <div className="flex items-center justify-between" style={{ maxWidth }}>
      <div>{name}</div>
      <div>{children}</div>
    </div>
  );
};

export const DashboardDiff: React.FC<{
  from?: number;
  to: number;
  reverse?: boolean;
  unit?: string;
}> = ({ from, to, reverse = false, unit }) => {
  const diff = to - (from ?? 0);
  return (
    <>
      {typeof from === 'number' && (
        <span
          className={classnames('mr-2', {
            'text-green-500': reverse ? diff < 0 : diff > 0,
            'text-gray-400': diff === 0,
            'text-red-600': reverse ? diff > 0 : diff < 0,
          })}
        >
          {diff > 0 && '+'}
          {priceFormat(diff)}
        </span>
      )}
      <NumberStat value={to} unit={unit} />
    </>
  );
};

export const Counter: React.FC<{ increament: () => void; decreament: () => void }> = ({
  children,
  increament,
  decreament,
}) => (
  <div className="flex select-none">
    <button className="w-7 h-7" onClick={decreament}>
      {'<'}
    </button>
    <div className="px-8">{children}</div>
    <button className="w-7 h-7" onClick={increament}>
      {'>'}
    </button>
  </div>
);
