import React, { useEffect, useState } from 'react';
import { Modals } from 'components';
import { Button } from 'react-bootstrap';
import * as style from './style';
import * as utils from '../utils';
import { APPLY_STATUS } from '../constants';

export const ActiveModal = ({ show, onClose, onOk }) => {
  const [selectedActive, setActive] = useState(true);
  const onSubmit = () => {
    onOk(selectedActive);
    onClose();
  };

  useEffect(() => {
    show && setActive(true);
  }, [show]);

  return (
    <Modals
      title="활성상태 변경"
      show={show}
      handleClose={onClose}
      actions={
        <>
          <Button onClick={onSubmit}>수정</Button>
          <Button onClick={onClose}>취소</Button>
        </>
      }
    >
      <div>
        <style.Select onChange={(e) => setActive(Boolean(Number(e.target.value)))}>
          <option value="1">ON</option>
          <option value="0">OFF</option>
        </style.Select>
      </div>
    </Modals>
  );
};

export const AssineeModal = ({ show, onClose, onOk }) => {
  const [selectedUser, setUser] = useState(null);
  const [userList, setUserList] = useState([]);
  const onSubmit = () => {
    if (selectedUser === null) {
      alert('담당자를 선택하세요.');
    } else {
      onOk(selectedUser);
      onClose();
    }
  };

  const getUserList = async () => {
    const data = await utils.getUserList();
    setUserList(
      data.map((d) => ({
        value: d.id,
        name: d.admin_name,
      }))
    );
  };

  useEffect(() => {
    if (show) {
      getUserList();
      setUser(null);
    }
  }, [show]);

  return (
    <Modals
      title="담당자 변경"
      show={show}
      handleClose={onClose}
      actions={
        <>
          <Button onClick={onSubmit}>수정</Button>
          <Button onClick={onClose}>취소</Button>
        </>
      }
    >
      <div>
        <style.Select
          onChange={(e) =>
            setUser(e.target.value === 'unset' ? null : Number(e.target.value))
          }
          value={selectedUser ?? 'unset'}
        >
          <option value={'unset'}>담당자</option>
          {userList?.map((user) => (
            <option key={user.value} value={user.value}>
              {user.name}
            </option>
          ))}
        </style.Select>
      </div>
    </Modals>
  );
};

export const DisplayStatusModal = ({ show, onClose, onOk }) => {
  const [selectedDisplay, setDisplay] = useState(null);
  const onSubmit = () => {
    if (selectedDisplay === null) {
      alert('업데니트 현황을 선택하세요');
    } else {
      onOk(selectedDisplay);
      onClose();
    }
  };
  useEffect(() => {
    show && setDisplay(null);
  }, [show]);

  return (
    <Modals
      title="업데이트 현황 변경"
      show={show}
      handleClose={onClose}
      actions={
        <>
          <Button onClick={onSubmit}>수정</Button>
          <Button onClick={onClose}>취소</Button>
        </>
      }
    >
      <div>
        <style.Select
          onChange={(e) => setDisplay(e.target.value === 'none' ? null : e.target.value)}
          value={selectedDisplay || 'none'}
        >
          <option value={'none'}>업데이트 현황</option>
          <option value={APPLY_STATUS.NEW.value}>신규</option>
          <option value={APPLY_STATUS.UPDATING.value}>업데이트 예정</option>
          <option value={APPLY_STATUS.UPDATED.value}>업데이트 완료</option>
          <option value={APPLY_STATUS.NO_UPDATE_NEEDED.value}>업데이트 불필요</option>
          <option value={APPLY_STATUS.TO_BE_TERMINATED.value}>종료확인 예정</option>
          <option value={APPLY_STATUS.TERMINATED.value}>종료확인 완료</option>
        </style.Select>
      </div>
    </Modals>
  );
};

export const PriorityModal = ({ show, onClose, onOk }) => {
  const [selectedActive, setActive] = useState(true);
  const onSubmit = () => {
    onOk(selectedActive);
    onClose();
  };

  useEffect(() => {
    show && setActive(true);
  }, [show]);

  return (
    <Modals
      title="중요"
      show={show}
      handleClose={onClose}
      actions={
        <>
          <Button onClick={onSubmit}>수정</Button>
          <Button onClick={onClose}>취소</Button>
        </>
      }
    >
      <div>
        <style.Select onChange={(e) => setActive(Boolean(Number(e.target.value)))}>
          <option value="1">ON</option>
          <option value="0">OFF</option>
        </style.Select>
      </div>
    </Modals>
  );
};
