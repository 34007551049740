import { Loading, Outer, RePagination } from 'components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { fetcher } from 'pages/DashboardService';
import useSWR from 'swr';
import { getKey, request } from 'services';
import {
  Select,
  Input,
  Checkbox,
  Button as AntdButton,
  Modal as AntdModal,
  DatePicker,
  Radio,
  Cascader,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import koKR from 'antd/lib/locale/ko_KR';
import { Anchor, NavAnchor, Table } from '../PartnerItem/style';
import { FromToType, StatusList } from 'pages/PartnerItem/types';
import {
  Action,
  Modal,
  PartnerItemRejectReasonType,
  PartnerItemSizeList,
  SearchType,
  Shop,
} from './types';
import { useCheckedIds } from 'pages/ReviewList/hooks';
import {
  actionOptions,
  getCategoryCasacaderOptions,
  getFixedTdStyle,
  limitOptions,
  maxSellDateOptions,
  maxSizeQuantityOptions,
  rejectReasonTypeOptions,
  searchTypeOptions,
} from './helper';
import {
  fromToOptions,
  fromToTypeOptions,
  handleStatus,
  statusListOptions,
} from 'pages/PartnerItem/helper';
import moment from 'moment';
import Fuse from 'fuse.js';

type Props = {};

const getErrorDescription = (error: any) => {
  switch (error?.response?.status) {
    case 404:
      return `서버에 정보가 없습니다. (${error?.response?.status})`;
    case 401:
    case 403:
      return `권한이 부족합니다. (${error?.response?.status})`;
    case 500:
      return `서버 내부 에러 (${error?.response?.status})`;
    default:
      return `잠시 후 다시 시도해주세요. (${error?.response?.status})`;
  }
};

const PartnerItem: React.FC<Props> = ({}) => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [fromToType, setFromToType] = useState<FromToType>(FromToType.REQUEST_DATE);
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);
  const [searchText, setSearchText] = useState('');
  const [searchType, setSearchType] = useState<SearchType>(SearchType.ITEM_NAME);
  const [statusList, setStatusList] = useState<StatusList[]>(
    new URLSearchParams(history.location.search).get('statusList')
      ? [new URLSearchParams(history.location.search).get('statusList') as StatusList]
      : []
  );
  const [partnerShopId, setPartnerShopId] = useState<number | null>(
    new URLSearchParams(history.location.search).get('partnerShopId')
      ? Number(new URLSearchParams(history.location.search).get('partnerShopId'))
      : null
  );
  const [fetchingCategoryId, setFetchingCategoryId] = useState<number[]>([]);
  const [brandId, setBrandId] = useState<number | null>(null);
  const [maxSellDate, setMaxSellDate] = useState<number | null>(null);
  const [maxSizeQuantity, setMaxSizeQuantity] = useState<number | null>(null);
  const [noBrandOnly, setNoBrandOnly] = useState<boolean>(false);
  const [noCategoryOnly, setNoCategoryOnly] = useState<boolean>(false);

  const [payload, setPayload] = useState<{
    brandId?: any;
    fetchingCategoryId?: any;
    maxSellDate?: any;
    minSizeQuantity?: any;
    maxSizeQuantity?: any;
    noBrandOnly?: any;
    partnerShopId?: any;
    noCategoryOnly?: any;
    minDate?: any;
    maxDate?: any;
    dateType?: any;
    searchText?: any;
    searchType?: any;
    statusList?: any;
  }>({
    statusList,
    partnerShopId,
  });

  const reset = () => {
    setFromToType(FromToType.REQUEST_DATE);
    setPartnerShopId(null);
    setFetchingCategoryId([]);
    setBrandId(null);
    setMaxSellDate(null);
    setMaxSizeQuantity(null);
    setNoBrandOnly(false);
    setNoCategoryOnly(false);
    setFrom(null);
    setTo(null);
    setSearchText('');
    setSearchType(SearchType.ITEM_NAME);
    setStatusList([]);
  };

  const getPayload = () => {
    setPayload({
      partnerShopId,
      fetchingCategoryId: fetchingCategoryId?.length
        ? [...fetchingCategoryId].pop()
        : null,
      brandId,
      maxSellDate: maxSellDate ? dayjs().add(maxSellDate, 'day').toISOString() : null,
      ...(maxSizeQuantity === Infinity ? { minSizeQuantity: 1 } : { maxSizeQuantity }),
      noBrandOnly,
      noCategoryOnly,
      minDate: from && dayjs(from).toISOString(),
      maxDate: to && dayjs(to).toISOString(),
      dateType: (from || to) && fromToType,
      searchText,
      searchType: searchText ? searchType : null,
      statusList,
    });
    setPage(1);
  };

  const { data, error, mutate } = useSWR<{ count: number; data: Shop[] }>(
    getKey(`/partner/item/map/item`, {
      limit,
      page,
      ...payload,
    }),
    fetcher,
    { shouldRetryOnError: false }
  );

  const { data: brands } = useSWR<{ data: Brand[] }>(`/brand`, fetcher, {
    shouldRetryOnError: false,
  });

  const { data: categories } = useSWR<{
    category: CommerceCategory[];
  }>(
    `https://commerce.fetchingapp.co.kr/category`,
    (key) => fetch(key).then((res) => res.json()),
    {
      shouldRetryOnError: false,
    }
  );
  const categoryCasacaderOptions = useMemo(
    () => getCategoryCasacaderOptions(categories?.category ?? []),
    [categories]
  );

  const { data: partners } = useSWR<{ data: Partner.Shop[] }>(
    `/partner/shop?limit=500`,
    fetcher,
    { shouldRetryOnError: false }
  );

  const { isChecked, isAll, onToggle, onAll, checked } = useCheckedIds<number>();
  const allItemIds = useMemo(() => data?.data.map((shop) => shop.itemId), [data]);

  const [action, setAction] = useState<Action | null>(null);
  const onAccept = async (target?: number) => {
    if (!target && !checked.length) {
      toast.info('하나 이상의 상품을 선택해주세요.');
      return;
    }
    try {
      setAction(Action.ACCEPT);
      const itemList = data?.data
        .filter((shop) => (target ? [target] : checked).includes(shop.itemId))
        .map((shop) => ({
          partnerShopId: shop.partnerShopId,
          productNo: shop.productNo,
          status: 'ACCEPT',
        }));
      const res: any = await request.post(`/partner/item/map/item/bulk`, {
        itemList,
      });
      if (!res.success) {
        throw new Error('');
      }
      await mutate();
      setModal(null);
      toast.info(`${itemList?.length}개 상품을 승인했습니다.`);
    } catch (error) {
      toast.error('상품 승인에 실패했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setAction(null);
    }
  };
  const onReject = async (
    rejectReasonType: PartnerItemRejectReasonType,
    rejectReason: string,
    target?: number | null
  ) => {
    if (!target && !checked.length) {
      toast.info('하나 이상의 상품을 선택해주세요.');
      return;
    }
    try {
      setAction(Action.REJECT);
      const itemList = data?.data
        .filter((shop) => (target ? [target] : checked).includes(shop.itemId))
        .map((shop) => ({
          partnerShopId: shop.partnerShopId,
          productNo: shop.productNo,
          status: 'REJECT',
          rejectReason,
          rejectReasonType,
        }));
      const res: any = await request.post(`/partner/item/map/item/bulk`, {
        itemList,
      });
      if (!res.success) {
        throw new Error('');
      }
      await mutate();
      setModal(null);
      toast.info(`${itemList?.length}개 상품을 반려했습니다.`);
    } catch (error) {
      toast.error('상품 반려에 실패했습니다. 잠시 후 다시 시도해주세요.');
    } finally {
      setAction(null);
    }
  };

  const [modal, setModal] = useState<Modal | null>(null);
  const [actionTarget, setActionTarget] = useState<Shop | null>(null);
  const [rejectReasonType, setRejectReasonType] = useState<PartnerItemRejectReasonType>(
    PartnerItemRejectReasonType.CATEGORY
  );
  const [rejectReason, setRejectReason] = useState('');
  const { data: sizeList } = useSWR<PartnerItemSizeList>(
    actionTarget && modal === Modal.OPTION
      ? `/partner/${actionTarget?.partnerShopId}/item/${actionTarget?.productNo}/size`
      : null,
    fetcher
  );

  useEffect(() => {
    error && toast.error(getErrorDescription(error));
  }, [error]);

  useEffect(() => {
    if (modal === null) {
      setActionTarget(null);
      setRejectReasonType(PartnerItemRejectReasonType.CATEGORY);
      setRejectReason('');
    }
  }, [modal]);

  return (
    <>
      {!data && !error && <Loading />}
      <Outer>
        <div style={{ padding: '32px 20px' }}>
          <div>
            <div
              style={{
                background: '#374151',
                padding: '24px 32px',
                fontWeight: 500,
              }}
            >
              <div>
                <h2
                  style={{
                    fontSize: '20px',
                    color: 'white',
                  }}
                >
                  상품 승인 관리
                </h2>
                <p
                  style={{
                    margin: '4px 0 0',
                    fontSize: '14px',
                    color: 'white',
                    opacity: '0.5',
                  }}
                >
                  페칭에 등록된 상품을 조회하고 상품 정보를 수정할 수 있습니다.
                </p>
              </div>
            </div>
            <div style={{ background: 'white', padding: '0 12px', display: 'flex' }}>
              <Link to="/partner/confirm/shop">
                <Anchor>파트너별 승인</Anchor>
              </Link>
              <NavAnchor>상품별 승인</NavAnchor>
            </div>
          </div>

          <div className="mt-5">
            <div className="bg-white p-6 font-bold" style={{ padding: '20px' }}>
              상품 검색
            </div>
            <div style={{ background: '#F9FAFB' }}>
              <Table>
                <tbody>
                  <tr>
                    <th>검색어</th>
                    <td>
                      <div className="flex gap-4">
                        <Select
                          style={{ minWidth: 220 }}
                          onChange={setSearchType}
                          value={searchType}
                        >
                          {searchTypeOptions.map((option) => (
                            <Select.Option value={option.value} key={option.value}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <Input
                          placeholder="복수검색 시 쉼표( , ) 로 구분"
                          onChange={({ target: { value } }) => setSearchText(value)}
                          value={searchText}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>상품 승인 상태</th>
                    <td>
                      {statusListOptions.map((option) => (
                        <Checkbox
                          key={option.name}
                          checked={statusList.includes(option.value)}
                          onChange={({ target: { checked } }) => {
                            handleStatus(setStatusList, option.value, checked);
                          }}
                        >
                          {option.name}
                        </Checkbox>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <th>기간</th>
                    <td>
                      <div className="flex gap-4">
                        <Select
                          style={{ minWidth: 220 }}
                          value={fromToType}
                          onChange={setFromToType}
                        >
                          {fromToTypeOptions.map((option) => (
                            <Select.Option key={option.value} value={option.value}>
                              {option.name}
                            </Select.Option>
                          ))}
                        </Select>
                        <Radio.Group
                          onChange={({ target: { value } }) => {
                            const [from, to] = value.split(',');
                            setFrom(from);
                            setTo(to);
                          }}
                          value={[from, to].join(',')}
                        >
                          {fromToOptions.map((option) => (
                            <Radio.Button value={option.value} key={option.value}>
                              {option.name}
                            </Radio.Button>
                          ))}
                        </Radio.Group>
                      </div>
                      <div style={{ marginTop: '10px' }}>
                        <DatePicker.RangePicker
                          allowEmpty={[true, true]}
                          value={[from ? moment(from) : null, to ? moment(to) : null]}
                          locale={koKR.DatePicker}
                          onChange={(_, [from, to]) => {
                            setFrom(from);
                            setTo(to);
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>상세 검색</th>
                    <td>
                      <div>
                        <Select
                          showSearch
                          allowClear
                          placeholder="파트너 쇼핑몰 선택"
                          style={{ minWidth: 400 }}
                          value={partnerShopId}
                          onChange={setPartnerShopId}
                          filterOption={(input, option: any) =>
                            new Fuse([option.search], {
                              keys: ['shopName', 'companyName'],
                            }).search(input)?.length > 0
                          }
                        >
                          {partners?.data.map((partner) => (
                            <Select.Option
                              key={partner.partnerShopId}
                              value={partner.partnerShopId}
                            >
                              {partner.shopName}
                            </Select.Option>
                          ))}
                        </Select>
                        <div className="flex gap-4 mt-3">
                          <Cascader
                            style={{ minWidth: 400 }}
                            value={fetchingCategoryId}
                            options={categoryCasacaderOptions}
                            onChange={(categories) => {
                              setFetchingCategoryId(categories as any);
                            }}
                            placeholder="카테고리 선택"
                            changeOnSelect
                          />
                          <Checkbox
                            checked={noCategoryOnly}
                            onChange={({ target: { checked } }) =>
                              setNoCategoryOnly(checked)
                            }
                          >
                            미분류 보기
                          </Checkbox>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <Select
                            showSearch
                            allowClear
                            placeholder="브랜드 선택"
                            style={{ minWidth: 400 }}
                            value={brandId}
                            onChange={setBrandId}
                            filterOption={(input, option: any) =>
                              new Fuse([option.search], {
                                keys: ['name', 'krName'],
                              }).search(input)?.length > 0
                            }
                          >
                            {brands?.data.map((brand) => (
                              <Select.Option
                                key={brand.id}
                                value={brand.id}
                                search={brand}
                              >
                                {brand.name} ({brand.krName})
                              </Select.Option>
                            ))}
                          </Select>
                          <Checkbox
                            checked={noBrandOnly}
                            onChange={({ target: { checked } }) =>
                              setNoBrandOnly(checked)
                            }
                          >
                            미분류 보기
                          </Checkbox>
                        </div>
                        <div className="flex gap-4 mt-3">
                          <Select
                            allowClear
                            placeholder="재고 선택"
                            style={{ minWidth: 192 }}
                            value={maxSizeQuantity}
                            onChange={setMaxSizeQuantity}
                          >
                            <Select.Option value={Infinity}>재고 있음</Select.Option>
                            {maxSizeQuantityOptions.map((option) => (
                              <Select.Option key={option.value} value={option.value}>
                                {option.name}
                              </Select.Option>
                            ))}
                          </Select>
                          <Select
                            allowClear
                            placeholder="남은 판매기간"
                            style={{ minWidth: 192 }}
                            value={maxSellDate}
                            onChange={setMaxSellDate}
                          >
                            {maxSellDateOptions.map((option) => (
                              <Select.Option key={option.value} value={option.value}>
                                {option.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div
                className="mx-auto flex gap-4"
                style={{ width: 'min-content', padding: 36 }}
              >
                <AntdButton size="large" onClick={reset}>
                  초기화
                </AntdButton>
                <AntdButton
                  size="large"
                  type="primary"
                  onClick={() => {
                    getPayload();
                    onAll([])();
                  }}
                >
                  검색
                </AntdButton>
              </div>
            </div>
          </div>

          <div style={{ marginTop: 40 }}>
            <div className="bg-white p-6 font-bold" style={{ padding: '20px' }}>
              상품 리스트 (총 {data?.count ?? 0}개)
            </div>
            <div
              className="flex justify-between"
              style={{ padding: '20px', background: '#F9FAFB' }}
            >
              <Select
                style={{ minWidth: 192 }}
                value={action}
                onChange={(value) => {
                  switch (value) {
                    case Action.ACCEPT:
                      onAccept();
                      break;
                    case Action.REJECT:
                      setModal(Modal.REJECT);
                      break;
                    default:
                      break;
                  }
                }}
                loading={[Action.ACCEPT, Action.REJECT].includes(action as any)}
              >
                <Select.Option value={null}>승인상태 변경</Select.Option>
                {actionOptions.map((option) => (
                  <Select.Option value={option.value}>{option.name}</Select.Option>
                ))}
              </Select>
              <Select style={{ minWidth: 192 }} value={limit} onChange={setLimit}>
                {limitOptions.map((option) => (
                  <Select.Option value={option.value}>{option.name}</Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ overflowX: 'scroll' }}>
              <table className="w-full border-table" style={{ minWidth: 2600 }}>
                <thead>
                  <tr>
                    <th style={getFixedTdStyle(0, 40)} onClick={onAll(allItemIds ?? [])}>
                      <Checkbox checked={isAll(allItemIds ?? [])} />
                    </th>
                    <th style={getFixedTdStyle(40, 130)}>파트너 쇼핑몰명</th>
                    <th style={getFixedTdStyle(40 + 130, 60)}>상태</th>
                    <th style={getFixedTdStyle(40 + 130 + 60, 80)}>관리</th>
                    <th>페칭 상품코드</th>
                    <th>이미지</th>
                    <th>상품명</th>
                    <th>옵션 갯수 및 가격 확인</th>
                    <th>카테고리</th>
                    <th>브랜드</th>
                    <th>판매상태</th>
                    <th>노출상태</th>
                    <th>재고량</th>
                    <th>상품가</th>
                    <th>이동후 결제가</th>
                    <th>페칭 판매가</th>
                    <th>상품 등록일</th>
                    <th>판매 시작일</th>
                    <th>판매 종료일</th>
                    <th>요청일</th>
                    <th>처리일</th>
                    <th>처리자</th>
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {data?.data.map((shop) => (
                    <TableRow
                      key={shop.itemId}
                      onToggle={onToggle}
                      isChecked={isChecked}
                      shop={shop}
                      setActionTarget={setActionTarget}
                      setModal={setModal}
                      onAccept={onAccept}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between mt-10">
              <p>총 {data?.count ?? 0}개</p>
              <RePagination
                currentPage={page}
                totalCount={data?.count ?? 0}
                pageSize={limit}
                onPageChange={setPage}
              />
            </div>
          </div>
        </div>
      </Outer>
      <AntdModal
        title="상품 반려"
        visible={modal == Modal.REJECT}
        confirmLoading={[Action.REJECT, Action.TARGET_REJECT].includes(action as any)}
        onOk={() => onReject(rejectReasonType, rejectReason, actionTarget?.itemId)}
        onCancel={() => setModal(null)}
        okText="저장"
        cancelText="닫기"
      >
        <div>
          <p style={{ color: '#6B7280', fontSize: '12px' }}>
            {actionTarget ? '1' : checked.length}개의 상품을 반려합니다. 반려 사유를
            선택해주세요.
          </p>
          <div style={{ marginTop: 40 }}>
            <Select
              className="w-full"
              value={rejectReasonType}
              onChange={setRejectReasonType}
            >
              {rejectReasonTypeOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
            <Input.TextArea
              style={{ marginTop: 20 }}
              placeholder="파트너에게 전달될 사유입니다. 상세히 작성해주세요."
              rows={4}
              value={rejectReason}
              onChange={({ target: { value } }) => {
                setRejectReason(value);
              }}
            />
          </div>
        </div>
      </AntdModal>
      <AntdModal
        title="반려 사유"
        visible={modal == Modal.REJECT_REASON}
        onCancel={() => setModal(null)}
        cancelText="닫기"
        footer={null}
      >
        <div>
          <div
            style={{ border: '1px solid #D4D4D8', borderRadius: '4px', padding: '6px' }}
          >
            {
              rejectReasonTypeOptions.find(
                (option) => option.value == actionTarget?.rejectReasonType
              )?.name
            }
          </div>
          <Input.TextArea
            readOnly
            style={{ marginTop: 20 }}
            value={actionTarget?.rejectReason}
            rows={4}
          />
        </div>
      </AntdModal>
      <AntdModal
        title="옵션 확인"
        visible={modal === Modal.OPTION}
        onCancel={() => setModal(null)}
        footer={null}
        width={1000}
      >
        <div>
          <p style={{ color: '#6B7280', fontSize: '12px' }}>
            관부가세가 옵션으로 운영되고 있는지 반드시 확인해주세요.
          </p>
          <div style={{ marginTop: '51px' }}>
            <h1 style={{ color: '#4B5563' }}>
              옵션목록 (총{' '}
              <span style={{ color: 'blue' }}>{sizeList?.sizeList.length ?? 0}개</span>)
            </h1>
            <table className="w-full border-table mt-6" style={{ marginTop: '24px' }}>
              <thead>
                <tr>
                  <th>옵션명</th>
                  <th>옵션 재고</th>
                  <th>금액</th>
                  <th>사용여부</th>
                </tr>
              </thead>
              <tbody>
                {sizeList?.sizeList.map((size) => (
                  <tr>
                    <td>{size.sizeName}</td>
                    <td>{size.quantity?.toLocaleString()}</td>
                    <td>{size.price?.toLocaleString()}원</td>
                    <td>
                      {size.isActive && <Y />}
                      {!size.isActive && <N />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </AntdModal>
    </>
  );
};

export default PartnerItem;

const TableRow: React.FC<{
  onToggle: any;
  isChecked: any;
  shop: Shop;
  setActionTarget: any;
  setModal: any;
  onAccept: any;
}> = ({ onToggle, isChecked, shop, setActionTarget, setModal, onAccept }) => {
  return (
    <tr>
      <td onClick={onToggle(shop.itemId)} style={getFixedTdStyle(0, 40)}>
        <Checkbox checked={isChecked(shop.itemId)} />
      </td>
      <td style={getFixedTdStyle(40, 130)}>{shop.shopName}</td>
      <td style={getFixedTdStyle(40 + 130, 60)}>
        {shop.status === StatusList.REQUEST && (
          <span style={{ color: '#1F2937' }}>승인 대기</span>
        )}
        {shop.status === StatusList.ACCEPT && (
          <span style={{ color: '#2563EB' }}>승인</span>
        )}
        {shop.status === StatusList.REJECT && (
          <span style={{ color: '#EF4444' }}>반려</span>
        )}
      </td>
      <td style={getFixedTdStyle(40 + 130 + 60, 80)}>
        <div className="fle gap-4">
          {shop.status === StatusList.REQUEST && (
            <AntdButton onClick={() => onAccept(shop.itemId)}>승인</AntdButton>
          )}
          {(shop.status === StatusList.REQUEST || shop.status === StatusList.ACCEPT) && (
            <AntdButton
              onClick={() => {
                setActionTarget(shop);
                setModal(Modal.REJECT);
              }}
            >
              반려
            </AntdButton>
          )}
          {shop.status === StatusList.REJECT && (
            <AntdButton
              onClick={() => {
                setActionTarget(shop);
                setModal(Modal.REJECT_REASON);
              }}
            >
              사유보기
            </AntdButton>
          )}
        </div>
      </td>
      <td>
        <a
          href={`https://fetching.co.kr/product/${shop.itemId}`}
          target="_blank"
          style={{ textDecoration: 'underline', color: 'black' }}
        >
          {shop.itemId}
        </a>
      </td>
      <td>
        <img
          src={shop.imageUrl}
          alt={shop.itemName}
          width={60}
          height={60}
          style={{ objectFit: 'contain', margin: '0 auto' }}
        />
      </td>
      <td style={{ maxWidth: 300, textAlign: 'start', padding: '0 10px' }}>
        <p
          style={{
            whiteSpace: 'pre',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          {shop.itemName}
        </p>
        <p
          style={{
            fontSize: '12px',
            color: '#9CA3AF',
            whiteSpace: 'pre',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        >
          -size: {shop.sizeList.map((size) => size.sizeName).join(', ')}
        </p>
      </td>
      <td>
        <AntdButton
          onClick={() => {
            setActionTarget(shop);
            setModal(Modal.OPTION);
          }}
        >
          옵션 확인
        </AntdButton>
      </td>
      <td style={{ maxWidth: 300 }}>
        {shop.categoryList.map((c, i) => (
          <div key={c.fetchingCategoryId + ',' + i}>{c.fetchingCategoryName}</div>
        ))}
      </td>
      <td>{shop.brandName}</td>
      <td>
        {shop.isSellable && <Y />}
        {!shop.isSellable && <N />}
      </td>
      <td>
        {shop.isShow && <Y />}
        {!shop.isShow && <N />}
      </td>
      <td>{shop.sizeQuantity?.toLocaleString()}개</td>
      <td>{shop.originPrice?.toLocaleString()}원</td>
      <td>{shop.shopPrice?.toLocaleString()}원</td>
      <td>{shop.fetchingPrice?.toLocaleString()}원</td>
      <td>{shop.createdAt && dayjs(shop.createdAt).format('YYYY. MM. DD. hh:mm')}</td>
      <td>
        {shop.sellStartDate && dayjs(shop.sellStartDate).format('YYYY. MM. DD. hh:mm')}
      </td>
      <td>{shop.sellEndDate && dayjs(shop.sellEndDate).format('YYYY. MM. DD. hh:mm')}</td>
      <td>{shop.requestDate && dayjs(shop.requestDate).format('YYYY. MM. DD. hh:mm')}</td>
      <td>{shop.processDate && dayjs(shop.processDate).format('YYYY. MM. DD. hh:mm')}</td>
      <td>{shop.updateUserName}</td>
    </tr>
  );
};

const Y = () => <span style={{ color: 'blue' }}>Y</span>;
const N = () => <span style={{ color: 'red' }}>N</span>;
