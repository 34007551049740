import { PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { getBrandList } from 'api/fetchingAPI';
import {
  Loading,
  Outer,
  Portal,
  RePagination,
  ShadowContainer,
  Spinner,
} from 'components';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  benefitNameMap,
  benefitOptions,
  benefitValueOptions,
  filterOptions,
  formatGroupLabel,
  getOptions,
  groupBrandsByAlphabet,
  searchFilterOptions,
  selectStyle,
  sortOptions,
  useFetch,
} from './helper';
import Select from 'react-select';
import dayjs from 'dayjs';
import ReactStars from 'react-stars';
import { DebounceInput } from 'react-debounce-input';
import { useCheckedIds, useManageReview, useReviewList } from './hooks';
import { Button, Modal } from 'react-bootstrap';
import { Form, Input, Button as AButton, Rate, Divider, Upload } from 'antd';
import { request } from 'services';
import { toast } from 'react-toastify';

type Props = {};

const ReviewList: React.FC<Props> = ({}) => {
  // 브랜드 아이디로 검색
  const [brands] = useFetch(getBrandList);

  // 모달 관련
  const [isOpen, setIsOpen] = useState({
    benefit: false,
    benefitBack: false,
    images: false,
    writeReview: false,
  });
  const [modalTarget, setModalTarget] = useState<number | null>(null);
  const [benefitOption, setBenefitOption] = useState<Review.Benefit>('POINT_TEXT');
  const [benefitLogs, setBenefitLogs] = useState<
    { amount: number; createdAt: string; type: Review.Benefit }[]
  >([]);
  const {
    isChecked: isModalChecked,
    isAll: isAllModal,
    onAll: onModalAll,
    onToggle: onToggleModal,
    checked: checkedModalLogs,
  } = useCheckedIds<Review.Benefit>();
  const [images, setImages] = useState<string[]>([]);
  const [reviewItem, setReviewItem] = useState<{
    imageUrl: string;
    name: string;
    brand: string;
    id: string;
  } | null>(null);

  // 리뷰 목록
  const { reviews, useParams, isLoading, count, search } = useReviewList();
  const {
    useCheckedReviews,
    updateVisibility,
    updateReviewReward,
    updateBestReview,
    isLoading: isPending,
  } = useManageReview();
  const { isChecked, isAll, onAll, onToggle } = useCheckedReviews;
  const [params, setParams, resetParams] = useParams;

  const { startAt, endAt, page, searchQuery, sort, searchType, brandId } = params;

  // 체크
  const allIds = useMemo(() => reviews.map((review) => review.idx), [reviews]);
  const allBenefitLogIds = useMemo(
    () => benefitLogs.map((log) => log.type),
    [benefitLogs]
  );

  const brandOptions = useMemo(
    () => (brands ? getOptions(groupBrandsByAlphabet(brands.data)) : []),
    [brands]
  );

  useEffect(() => {
    setBenefitOption('POINT_TEXT');
  }, [isOpen.benefit]);

  useEffect(() => {
    onModalAll([]);
  }, [isOpen.benefitBack]);

  return (
    <>
      {isLoading && <Loading />}
      <Outer>
        <ShadowContainer>
          <h2 className="text-2xl font-bold py-3 border-b">리뷰 목록</h2>
          <div>
            <div>
              <div className="flex gap-2 mt-5">
                <div className="flex items-center gap-2">
                  <div style={{ minWidth: '360px' }}>
                    <Select
                      defaultValue={{ label: '브랜드 전체', value: null }}
                      options={brandOptions}
                      value={
                        brandId === null
                          ? { label: '브랜드 전체', value: null }
                          : undefined
                      }
                      formatGroupLabel={formatGroupLabel}
                      onChange={({ value }) =>
                        setParams((p) => ({ ...p, brandId: value }))
                      }
                      styles={selectStyle}
                    />
                  </div>
                  <div style={{ minWidth: '150px' }}>
                    <Select
                      options={filterOptions}
                      isMulti
                      value={filterOptions.filter((option) => params[option.value])}
                      formatGroupLabel={formatGroupLabel}
                      onChange={(_: any, action: any) => {
                        switch (action.action) {
                          case 'select-option':
                            setParams((p) => ({ ...p, [action.option.value]: true }));
                            break;
                          case 'remove-value':
                            setParams((p) => ({
                              ...p,
                              [action.removedValue.value]: null,
                            }));
                            break;
                          case 'clear':
                            setParams((p) => ({
                              ...p,
                              isBest: null,
                              isDeactivated: null,
                              isPhotoReview: null,
                              isRewardCompleted: null,
                              isRewardPending: null,
                            }));
                            break;
                          default:
                            break;
                        }
                      }}
                      styles={selectStyle}
                      defaultInputValue="필터"
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <input
                    type="date"
                    value={startAt ?? ''}
                    max={endAt ?? undefined}
                    onChange={(e) => {
                      e.persist();
                      setParams((p) => ({ ...p, startAt: e.target.value }));
                    }}
                  />
                  <span className="my-auto"> ~ </span>
                  <input
                    type="date"
                    value={endAt ?? ''}
                    min={startAt ?? undefined}
                    onChange={(e) => {
                      e.persist();
                      setParams((p) => ({ ...p, endAt: e.target.value }));
                    }}
                  />
                  <div style={{ minWidth: '150px' }}>
                    <Select
                      defaultValue={searchFilterOptions[0]}
                      value={searchFilterOptions.find(
                        (option) => option.value == searchType
                      )}
                      options={searchFilterOptions}
                      formatGroupLabel={formatGroupLabel}
                      onChange={({ value }) =>
                        setParams((p) => ({ ...p, searchType: value }))
                      }
                      styles={selectStyle}
                    />
                  </div>
                  <div className="relative flex">
                    <DebounceInput
                      type="text"
                      value={searchQuery ?? ''}
                      onChange={(e) => {
                        setParams((p) => ({ ...p, searchQuery: e.target.value || null }));
                      }}
                      style={{ width: 200 }}
                    />
                    <button
                      className="absolute p-2"
                      style={{ inset: 0, left: 'auto' }}
                      onClick={() => search({ page: 1 })}
                    >
                      <SearchIcon className="w-4 h-4" />
                    </button>
                  </div>
                  <button
                    className="bg-black px-3 py-2 text-white"
                    onClick={() => search({ page: 1 })}
                  >
                    검색
                  </button>
                  <button className="bg-black px-3 py-2 text-white" onClick={resetParams}>
                    초기화
                  </button>
                </div>
              </div>
              <div className="flex justify-between" style={{ marginTop: 20 }}>
                <div className="flex gap-2">
                  <button
                    className="bg-black px-3 py-2 text-white"
                    onClick={async () => {
                      if (window.confirm('미노출로 변경하시겠습니까?')) {
                        await updateVisibility(false);
                        search();
                      }
                    }}
                  >
                    미노출
                  </button>
                  <button
                    className="bg-black px-3 py-2 text-white"
                    onClick={async () => {
                      if (window.confirm('노출로 변경하시겠습니까?')) {
                        await updateVisibility(true);
                        search();
                      }
                    }}
                  >
                    노출
                  </button>
                  <button
                    className="bg-black px-3 py-2 text-white"
                    onClick={() => {
                      setIsOpen((p) => ({ ...p, benefit: true }));
                      setModalTarget(null);
                    }}
                  >
                    혜택지급
                  </button>
                  <button
                    className="bg-black px-3 py-2 text-white"
                    onClick={async () => {
                      if (window.confirm('베스트 리뷰로 선정하시겠습니까?')) {
                        await updateBestReview(true);
                        search();
                      }
                    }}
                  >
                    베스트리뷰 선정
                  </button>
                  <button
                    className="bg-black px-3 py-2 text-white"
                    onClick={async () => {
                      if (window.confirm('베스트 리뷰 선정을 취소하시겠습니까?')) {
                        await updateBestReview(false);
                        search();
                      }
                    }}
                  >
                    베스트리뷰 취소
                  </button>
                </div>
                <div className="flex gap-5">
                  <button
                    className="bg-black px-3 py-2 text-white"
                    onClick={() => {
                      setIsOpen((p) => ({ ...p, writeReview: true }));
                    }}
                  >
                    리뷰 수기 작성
                  </button>
                  <div style={{ minWidth: '150px' }}>
                    <Select
                      defaultValue={sortOptions[0]}
                      value={sortOptions.find((option) => option.value == sort)}
                      options={sortOptions}
                      formatGroupLabel={formatGroupLabel}
                      onChange={({ value }) => setParams((p) => ({ ...p, sort: value }))}
                      styles={selectStyle}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <table className="w-full border-table">
                <thead>
                  <tr>
                    <th className="cursor-pointer" onClick={onAll(allIds)}>
                      <input
                        type="checkbox"
                        name=""
                        checked={isAll(allIds)}
                        className="w-6 h-6 cursor-pointer"
                      />
                    </th>
                    <th>번호</th>
                    <th>작성일</th>
                    <th>고객정보</th>
                    <th>브랜드</th>
                    <th>상품명</th>
                    <th>리뷰상태</th>
                    <th>평점</th>
                    <th>리뷰내용</th>
                    <th>조회수</th>
                    <th>도움이됐어요</th>
                    <th>지급상태</th>
                  </tr>
                </thead>
                <tbody>
                  {reviews.map((review) => (
                    <tr>
                      <td onClick={onToggle(review.idx)} className="cursor-pointer">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          checked={isChecked(review.idx)}
                          className="w-6 h-6 cursor-pointer"
                        />
                      </td>
                      <td>{review.idx}</td>
                      <td>
                        {dayjs(review.createdAt).add(9, 'hour').format('YYYY-MM-DD')}
                      </td>
                      <td>
                        <div
                          onClick={() => {
                            window.open(`/user/${review.userIdx}`);
                          }}
                          className="hover:underline"
                          style={{ cursor: 'pointer' }}
                        >
                          <p>{review.userName}</p>
                          <p>{review.userId}</p>
                          <p>{review.phoneNumber}</p>
                        </div>
                        <button
                          className="p-1 hover:underline"
                          style={{ border: '1px solid black', cursor: 'pointer' }}
                          onClick={() =>
                            window.open(`/order/${review.fetchingOrderNumber}`)
                          }
                        >
                          주문정보
                        </button>
                      </td>
                      <td
                        style={{ width: 120, cursor: 'pointer' }}
                        className="hover:underline"
                        onClick={() => {
                          window.open(`/product/brand/brandDetail/${review.brandId}`);
                        }}
                      >
                        {review.brandName}
                      </td>
                      <td
                        style={{ width: 160, cursor: 'pointer' }}
                        onClick={() => {
                          window.open(
                            `/product/productDetail/v2/${review.itemId}?mode=update`,
                            `${review.itemName} 상품 상세`,
                            'width=800,height=1000,menubar=no,status=no,toolbar=no,menubar=no,location=no,scrollbars=no,left=10000'
                          );
                        }}
                        className="hover:underline"
                      >
                        {review.itemName}
                      </td>
                      <td>
                        {!review?.rewardLog?.length && <Card color="red">지급 대기</Card>}
                        {!review.isActive && <Card color="red">미노출</Card>}
                        {!!review?.rewardLog?.length && (
                          <Card color="blue">지급 완료</Card>
                        )}
                        {!!review.isBest && <Card color="green">베스트리뷰</Card>}
                      </td>
                      <td>
                        <div className="flex justify-center">
                          <ReactStars value={review.rating} edit={false} half />
                        </div>
                      </td>
                      <td style={{ maxWidth: 400 }}>
                        <div className="flex px-2">
                          {!!review.imageList?.length && (
                            <img
                              src={review.imageList[0]}
                              alt=""
                              style={{
                                width: 100,
                                minWidth: 100,
                                height: 100,
                                objectFit: 'contain',
                                marginRight: 8,
                              }}
                              onClick={() => {
                                setImages(review.imageList);
                                setIsOpen((p) => ({ ...p, images: true }));
                              }}
                            />
                          )}
                          <p
                            style={{
                              width: '100%',
                              textAlign: 'left',
                              maxHeight: '100px',
                              overflowY: 'scroll',
                            }}
                          >
                            {review.body}
                          </p>
                        </div>
                      </td>
                      <td>{review.views}</td>
                      <td>{review.likes}</td>
                      <td>
                        <div>
                          <button
                            className="p-1"
                            style={{ border: '1px solid black' }}
                            onClick={() => {
                              setIsOpen((p) => ({ ...p, benefit: true }));
                              setModalTarget(review.idx);
                            }}
                          >
                            혜택 지급
                          </button>
                        </div>
                        <div className="mt-1">
                          <button
                            className="p-1"
                            style={{ border: '1px solid black' }}
                            onClick={() => {
                              setIsOpen((p) => ({ ...p, benefitBack: true }));
                              setModalTarget(review.idx);
                              setBenefitLogs(review.rewardLog);
                            }}
                          >
                            혜택 회수
                          </button>
                        </div>
                        {review.rewardLog.map((log) => (
                          <p style={{ color: '#AAA', fontSize: 10, margin: '6px 0' }}>{`${
                            log.amount
                          }P (${dayjs(log.createdAt)
                            .add(9, 'hour')
                            .format('YY/MM/DD HH:mm')})`}</p>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-between mt-10">
              <p>총 {count}개</p>
              <RePagination
                currentPage={page}
                totalCount={count}
                pageSize={params.limit}
                onPageChange={(page) => {
                  window.scrollTo(0, 0);
                  search({ page });
                }}
              />
            </div>
          </div>
        </ShadowContainer>
      </Outer>
      <Portal elementId="modal-root">
        <Modal
          show={isOpen.benefit}
          onHide={() => {
            setIsOpen({ ...isOpen, benefit: false });
            setModalTarget(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>혜택지급</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex gap-5">
              <div style={{ width: 120 }}>
                <Select
                  defaultValue={benefitOptions[0]}
                  value={benefitOptions[0]}
                  options={benefitOptions}
                  formatGroupLabel={formatGroupLabel}
                  styles={selectStyle}
                />
              </div>
              <div style={{ width: 200 }}>
                <Select
                  defaultValue={benefitValueOptions[0]}
                  value={benefitValueOptions.find(
                    (option) => option.value === benefitOption
                  )}
                  options={benefitValueOptions}
                  formatGroupLabel={formatGroupLabel}
                  onChange={({ value }) => setBenefitOption(value)}
                  styles={selectStyle}
                />
              </div>
              <p className="my-auto">을 지급합니다.</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={async () => {
                await updateReviewReward('GRANT', benefitOption, modalTarget);
                setIsOpen((p) => ({ ...p, benefit: false }));
                search();
              }}
            >
              {isPending ? <Spinner /> : '저장'}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isOpen.benefitBack}
          onHide={() => {
            setIsOpen({ ...isOpen, benefitBack: false });
            setModalTarget(null);
          }}
          centered
        >
          <Modal.Header>
            <Modal.Title>혜택회수</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <table className="border-table" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th className="cursor-pointer" onClick={onModalAll(allBenefitLogIds)}>
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="w-6 h-6"
                        checked={isAllModal(allBenefitLogIds)}
                      />
                    </th>
                    <th>혜택내역</th>
                    <th>지급날짜</th>
                  </tr>
                </thead>
                <tbody>
                  {benefitLogs.map((benefit) => (
                    <tr>
                      <td
                        className="cursor-pointer"
                        onClick={onToggleModal(benefit.type)}
                      >
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="w-6 h-6"
                          checked={isModalChecked(benefit.type)}
                        />
                      </td>
                      <td>{benefitNameMap[benefit.type]}</td>
                      <td>
                        {dayjs(benefit.createdAt).add(9, 'hour').format('YYYY-MM-DD')}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={async () => {
                await Promise.all(
                  checkedModalLogs.map((type) =>
                    updateReviewReward('RETRIEVE', type, modalTarget)
                  )
                );
                setIsOpen((p) => ({ ...p, benefitBack: false }));
                search();
              }}
            >
              회수
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isOpen.images}
          onHide={() => {
            setIsOpen({ ...isOpen, images: false });
            setImages([]);
          }}
        >
          <Modal.Header>
            <Modal.Title>리뷰 이미지</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="grid grid-cols-2 gap-5">
              {images.map((src) => (
                <img src={src} alt="" />
              ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                setIsOpen({ ...isOpen, images: false });
                setImages([]);
              }}
            >
              닫기
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={isOpen.writeReview}
          onHide={() => {
            setIsOpen({ ...isOpen, writeReview: false });
            setReviewItem(null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>상품후기</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Form
                layout="inline"
                onFinish={async ({ id }) => {
                  try {
                    const {
                      data: [item],
                    } = await request.get(`/item/v3`, {
                      params: {
                        searchType: 'FETCHING_CODE',
                        searchText: id,
                      },
                    });
                    if (!item) {
                      throw new Error('');
                    }
                    setReviewItem(item);
                  } catch (error) {
                    toast.error('상품을 찾을 수 없습니다.');
                  }
                }}
                autoComplete="none"
              >
                <Form.Item
                  name="id"
                  rules={[{ required: true, message: '입력해주세요' }]}
                >
                  <Input placeholder="페칭 코드" />
                </Form.Item>
                <AButton htmlType="submit" type="primary">
                  불러오기
                </AButton>
              </Form>
              <Divider />
              {reviewItem ? (
                <div className="flex gap-5">
                  <img
                    src={reviewItem.imageUrl}
                    style={{
                      objectFit: 'contain',
                      display: 'inline-block',
                      width: 80,
                      height: 80,
                    }}
                  />
                  <div>
                    <p>{reviewItem.brand}</p>
                    <p>{reviewItem.name}</p>
                  </div>
                </div>
              ) : (
                <p className="text-sm text-gray-400">리뷰를 작성할 상품을 불러오세요.</p>
              )}
              <Divider />
              <Form
                layout="vertical"
                onFinish={async (form) => {
                  console.log(form);
                  if (!reviewItem) {
                    toast.error('상품을 선택하세요.');
                    return;
                  }
                  try {
                    await request.post(`/commerce/review`, {
                      body: form.content,
                      itemId: reviewItem.id,
                      rating: form.score,
                      title: '',
                      imageList: form.images?.fileList.map(
                        (file: any) => file.response.fileUrl
                      ),
                    });
                    toast.success('등록 완료');
                    setIsOpen({ ...isOpen, writeReview: false });
                    setReviewItem(null);
                  } catch (error) {
                    toast.error('업로드 실패');
                  }
                }}
              >
                <Form.Item
                  name="score"
                  label="상품은 어떠셨나요?"
                  rules={[{ required: true, message: '필수 입니다.' }]}
                >
                  <Rate />
                </Form.Item>
                <Form.Item
                  name="content"
                  label="자세한 리뷰를 작성해 주세요!"
                  rules={[{ required: true, message: '필수 입니다.' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item name="images">
                  <Upload
                    listType="picture-card"
                    action="https://admin.fetchingapp.co.kr/util/file-upload"
                    method="POST"
                    onPreview={({ response: { fileUrl } }: any) => {
                      setIsOpen((p) => ({ ...p, images: true }));
                      setImages([fileUrl]);
                    }}
                  >
                    <button style={{ width: 40, height: 40, border: '1px olid black' }}>
                      <PlusIcon />
                    </button>
                  </Upload>
                </Form.Item>
                <p className="mb-10">'네이버 구매자' 로 등록됩니다.</p>
                <AButton htmlType="submit" type="primary">
                  저장
                </AButton>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </Portal>
    </>
  );
};

export default ReviewList;

const colorSet = {
  red: '#FF5747',
  blue: '#0D9AE9',
  green: '#498935',
};

const Card: FC<{ color: 'red' | 'blue' | 'green' }> = ({ children, color }) => (
  <div
    style={{
      border: `1px solid ${colorSet[color]}`,
      color: colorSet[color],
      width: 'fit-content',
      margin: '8px auto',
      padding: '4px 8px',
    }}
  >
    {children}
  </div>
);
