import React, { useContext, useEffect, useState, useCallback } from 'react';
import Dropzone from 'react-dropzone';

import axios, { getUserList } from 'api/axios';
import fetchingAPI from 'api/fetchingAPI';
import { Loading } from 'components';
import { LoadingContext } from 'context';
import * as style from './style';
import SetTabItemModal from './SetTabItemModal';
import ProductList from './ProductList';
import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { SelectorIcon } from '@heroicons/react/solid';
import { request } from 'services';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

// date input 용 문자열 생성
const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();
  let hour = (d.getHours() + '').padStart(2, '0');
  let min = (d.getMinutes() + '').padStart(2, '0');

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-') + 'T' + hour + ':' + min;
};

const emptyData = {
  name: '',
  description: null,
  gender: null,
  isActive: null,
  isVisibleToCafe24: null,
  isVisibleToAppMain: null,
  bannerImageUrl: null,
  cafe24CategoryId: null,
  cart: null,
  endedAt: null,
  exhibitionId: null,
  isActiveOnApp: null,
  isVisibleToAppCategory: null,
  itemCount: null,
  landingImageUrl: null,
  like: null,
  mainImageUrl: null,
  sales: null,
  startedAt: null,
  topBannerImageUrl: null,
  view: null,
  duration: 0,
};

const ModifyExhibitionDetail = () => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);
  const [data, setData] = useState(emptyData);
  const [items, setItems] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [userList, setUserList] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [showSetTab, setShowSetTab] = useState({
    showModal: false,
    exhibitionId: null,
    id: null,
  });
  const [landingImageUrl, setLandingImageUrl] = useState('');
  const [mainImageUrl, setMainImageUrl] = useState('');
  const [topBannerImageUrl, setTopBannerImageUrl] = useState('');
  const [bannerImageUrl, setBannerImageUrl] = useState('');
  const [type, setType] = useState('exhibition');
  const [currentTabId, setCurrentTabId] = useState(0);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const setImgData = (target, url) => {
    const urls = {
      landingImageUrl: setLandingImageUrl,
      mainImageUrl: setMainImageUrl,
      topBannerImageUrl: setTopBannerImageUrl,
      bannerImageUrl: setBannerImageUrl,
    };

    urls[target](url);
  };

  // api 관련
  const fetchExhiibitionDetail = async (exhibitionId) => {
    try {
      const { data } = await fetchingAPI.getExhibition(exhibitionId);
      if (data && (data.startedAt || data.endedAt)) {
        setData((prev) => ({ ...prev, ...data, duration: 1 }));
      } else {
        setData((prev) => ({ ...prev, ...data }));
      }

      setLandingImageUrl(data.landingImageUrl);
      setMainImageUrl(data.mainImageUrl);
      setTopBannerImageUrl(data.topBannerImageUrl);
      setBannerImageUrl(data.bannerImageUrl);
      const pathname = window.location.pathname.split('/');
      const id = pathname[pathname.length - 1];
      getItemsInExhibition(id, data.totalItemCount);
    } catch (error) {
      toast.error(`정보를 불러오는데 실패했습니다. `);
    }
  };

  const getItemsInExhibition = async (exhibitionId, itemCount) => {
    const params = {
      limit: itemCount,
      isExtended: true,
      isVerify,
      // needCount: true,
      // page,
    };
    const { data } = await fetchingAPI.getItemsInExhibition(exhibitionId, params);
    setItems(data.data);
    setTotal(data.count);
  };

  const fetchAdminList = async () => {
    const users = await getUserList();
    setUserList(users);
  };

  const fetchTabs = async (exhibitionId) => {
    const tabs = await fetchingAPI.getExhibitionCategories(exhibitionId);
    setTabs(tabs.data.map((item) => ({ ...item, checked: false })));
  };

  const onChangeTab = async (checked, index, tabId) => {
    setTabs([
      ...tabs.slice(0, index).map((item) => ({ ...item, checked: false })),
      { ...tabs[index], checked },
      ...tabs.slice(index + 1).map((item) => ({ ...item, checked: false })),
    ]);
    setType('exhibition_tab');
    setCurrentTabId(tabId);
    const categories = await fetchingAPI.getItemsExhibitionCategory(exhibitionId, tabId);
    setItems(categories.data.data);
  };

  const putData = async (exhibitionId, handleIsLoading) => {
    try {
      handleIsLoading(true);

      const params = {
        assigneeId: data.assigneeId,
        startedAt: data.startedAt,
        description: data.description,
        endedAt: data.endedAt,
        gender: data.gender,
        isActive: data.isActive,
        isActiveOnApp: data.isActiveOnApp,
        isVisibleToAppCategory: data.isVisibleToAppCategory,
        isVisibleToAppMain: data.isVisibleToAppMain,
        isVisibleToCafe24: data.isVisibleToCafe24,
        isVisibleToCafe24Mobile: data.isVisibleToCafe24Mobile,
        name: data.name,
        topBannerImageUrl,
        landingImageUrl,
        mainImageUrl,
        bannerImageUrl,
      };

      await fetchingAPI.updateExhibition(exhibitionId, params);
      fetchExhiibitionDetail(exhibitionId);
      fetchAdminList(exhibitionId);
      fetchTabs(exhibitionId);
    } catch (error) {
      alert(`err in putData(), ModifyExhibitionDetail, ${error.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  const onRemoveCategory = async (id) => {
    if (!window.confirm(`${name} 카테고리를 삭제할까요? (즉시 반영됩니다.)`)) return;

    try {
      await fetchingAPI.removeExhibitionCategory(exhibitionId, id);
      fetchTabs(exhibitionId);
    } catch (error) {
      alert(`err in removeExhibitionCategory(), ${error.message}`);
    }
  };

  const resetData = () => {
    const pathname = window.location.pathname.split('/');
    const id = pathname[pathname.length - 1];
    fetchExhiibitionDetail(id);
    fetchAdminList(id);
    fetchTabs(id);
  };

  useEffect(() => {
    const pathname = window.location.pathname.split('/');
    const id = pathname[pathname.length - 1];
    fetchExhiibitionDetail(id);
    fetchAdminList(id);
    fetchTabs(id);
  }, []);

  // input date 처리
  const handleDateInput = (e, target) => {
    const date = new Date(e.target.value + ':00');
    const ISOString = date.toISOString();
    setData({
      ...data,
      [target]: ISOString,
    });
  };

  // 사진 업로드
  const handleOnDrop = (files, target) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post(`${process.env.REACT_APP_FETCHING_SERVER_URL}/api/util/image`, {
          path: 'exhibitions/banner',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setImgData(target, imgUrl);
          }
        })
        .catch((err) => console.log(err));
    };

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result.split(',');
        cb(result[1]);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };

  const DropzoneImage = useCallback(
    ({ target, targetString }) => (
      <div>
        <input style={{ width: '250px' }} value={target || ''} onChange={() => {}} />
        <Dropzone
          onDrop={(files) => handleOnDrop(files, targetString)}
          multiple={false}
          maxSize={20000000}
        >
          {({ getRootProps, getInputProps }) => (
            <style.Button {...getRootProps()}>
              <input {...getInputProps()} />
              찾아보기
            </style.Button>
          )}
        </Dropzone>
        {target ? (
          <>
            <style.Button
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setImgData(targetString, null);
              }}
            >
              X
            </style.Button>
            <br />
            <div>
              <img src={target} style={{ maxWidth: '400px', marginTop: '13px' }} />
            </div>
            <div></div>
          </>
        ) : null}
      </div>
    ),
    [data.bannerImageUrl, data.landingImageUrl, data.mainImageUrl, data.topBannerImageUrl]
  );

  // 기획전 하부 카테고리 생성 요청
  const createTab = async (exhibitionId, name) => {
    try {
      handleIsLoading(true);
      await fetchingAPI.createExhibitionCategory(exhibitionId, name);
      fetchTabs(exhibitionId);
    } catch (error) {
      alert(`err in createTab, ${error.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  const saveOrder = async (list) => {
    if (!window.confirm('현재 상태로 정렬을 저장하시겠습니까?')) return;
    try {
      const orders = list.map((li) => li.id);
      let payload = {
        type,
        value: data.exhibitionId,
        orders: orders.join(','),
      };
      if (currentTabId) payload.value = currentTabId;
      await fetchingAPI.saveCustomItemsOrders(payload);
      toast.success('변경되었습니다.');
    } catch (error) {
      alert(`err in saveItemOrder, ${error.message}`);
    }
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newTabs = reorder(tabs, result.source.index, result.destination.index);

    try {
      await request.put(`/exhibition/${exhibitionId}/categories/order`, {
        data: newTabs.map((item) => item.id),
      });
      setTabs(newTabs);
    } catch (err) {
      console.log(err);
    }
  };

  const {
    name,
    description,
    gender,
    isActive,
    isVisibleToCafe24,
    isVisibleToAppMain,
    isVisibleToAppCategory,
    isVisibleToCafe24Mobile,
    cart,
    endedAt,
    startedAt,
    assigneeId,
    exhibitionId,
    like,
    sales,
    view,
    duration,
  } = data;

  useEffect(() => {
    items?.length && getItemsInExhibition(exhibitionId, null);
  }, [isVerify]);

  return (
    <>
      {isLoading && <Loading />}

      <SetTabItemModal
        show={showSetTab}
        onHide={() => {
          setShowSetTab({ showModal: false, exhibitionId: null, id: null });
        }}
        handleIsLoading={handleIsLoading}
        data={data}
        fetchTabs={fetchTabs}
      />

      <style.Outer>
        <style.ShadowContainer>
          <h2>기획전 수정</h2>
          <style.H4>기본 정보</style.H4>
          <style.Table>
            <style.Tbody>
              <style.Tr>
                <style.LeftTd>타이틀</style.LeftTd>
                <style.Td>
                  <style.TextArea
                    value={name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                  />
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>설명</style.LeftTd>
                <style.Td>
                  <style.TextArea
                    value={description}
                    onChange={(e) => setData({ ...data, description: e.target.value })}
                  />
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>성별</style.LeftTd>
                <style.Td>
                  <style.Label>
                    <style.Radio
                      name="gender"
                      checked={gender === 'M'}
                      onClick={() => setData({ ...data, gender: 'M' })}
                    />
                    남성
                  </style.Label>
                  <style.Label>
                    <style.Radio
                      name="gender"
                      checked={gender === 'W'}
                      onClick={() => setData({ ...data, gender: 'W' })}
                    />
                    여성
                  </style.Label>
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>활성상태</style.LeftTd>
                <style.Td>
                  <style.Label>
                    <style.Radio
                      name="isActive"
                      checked={isActive === 1}
                      onClick={() => setData({ ...data, isActive: 1 })}
                    />
                    활성화
                  </style.Label>
                  <style.Label>
                    <style.Radio
                      name="isActive"
                      checked={isActive === 0}
                      onClick={() => setData({ ...data, isActive: 0 })}
                    />
                    비활성화
                  </style.Label>
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>노출 위치</style.LeftTd>
                <style.Td>
                  <style.Label>
                    <style.Checkbox
                      checked={isVisibleToAppMain === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToAppMain: (isVisibleToAppMain + 1) % 2,
                        })
                      }
                    />
                    앱 메인
                  </style.Label>
                  <style.Label>
                    <style.Checkbox
                      checked={isVisibleToAppCategory === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToAppCategory: (isVisibleToAppCategory + 1) % 2,
                        })
                      }
                    />
                    앱 카테고리
                  </style.Label>
                  <style.Label>
                    <style.Checkbox
                      checked={isVisibleToCafe24 === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToCafe24: (isVisibleToCafe24 + 1) % 2,
                        })
                      }
                    />
                    PC 웹 기획전
                  </style.Label>
                  <style.Label>
                    <style.Checkbox
                      checked={isVisibleToCafe24Mobile === 1}
                      onChange={() =>
                        setData({
                          ...data,
                          isVisibleToCafe24Mobile: (isVisibleToCafe24Mobile + 1) % 2,
                        })
                      }
                    />
                    모바일 웹 기획전
                  </style.Label>
                  <style.DescSpan>
                    (PC/모바일 웹 메인은 “웹 메인진열" 탭에서 설정해주세요.)
                  </style.DescSpan>
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>기간</style.LeftTd>
                <style.Td>
                  <style.Label>
                    <style.Radio
                      name="duration"
                      checked={duration === 0}
                      onChange={() =>
                        setData({
                          ...data,
                          startedAt: null,
                          endedAt: null,
                          duration: 0,
                        })
                      }
                    />
                    상시진행
                  </style.Label>
                  <style.Label>
                    <style.Radio
                      name="duration"
                      checked={duration === 1}
                      onChange={() => setData({ ...data, duration: 1 })}
                    />
                    기간설정
                  </style.Label>
                  <style.InputDate
                    disabled={!duration}
                    onChange={(e) => handleDateInput(e, 'startedAt')}
                    value={startedAt && formatDate(startedAt)}
                  />
                  <span style={{ padding: '0 10px' }}>~</span>
                  <style.InputDate
                    disabled={!duration}
                    onChange={(e) => handleDateInput(e, 'endedAt')}
                    value={endedAt && formatDate(endedAt)}
                  />
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>담당자</style.LeftTd>
                <style.Td>
                  <select
                    value={assigneeId}
                    onChange={(e) => {
                      setData({ ...data, assigneeId: e.target.value });
                    }}
                  >
                    {assigneeId === null && <option selected>담당자 정보 없음</option>}
                    {userList?.map((user, key) => {
                      return (
                        <option key={key} value={user.id}>
                          {user.admin_name}
                        </option>
                      );
                    })}
                  </select>
                </style.Td>
              </style.Tr>
            </style.Tbody>
          </style.Table>

          <style.H4>배너</style.H4>
          <style.Table>
            <style.Tbody>
              <style.Tr>
                <style.LeftTd>앱 메인 가로형</style.LeftTd>
                <style.Td>
                  <DropzoneImage
                    target={topBannerImageUrl}
                    targetString="topBannerImageUrl"
                  />
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>
                  앱 기획전 목록
                  <br />
                  (모바일 웹 기획전 목록)
                </style.LeftTd>
                <style.Td>
                  <DropzoneImage target={bannerImageUrl} targetString="bannerImageUrl" />
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>
                  앱 기획전 상세
                  <br />
                  (모바일 웹 기획전 상세)
                </style.LeftTd>
                <style.Td>
                  <DropzoneImage
                    target={landingImageUrl}
                    targetString="landingImageUrl"
                  />
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>PC 웹 기획전 목록</style.LeftTd>
                <style.Td></style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>PC 웹 기획전 상세</style.LeftTd>
                <style.Td>
                  <DropzoneImage target={mainImageUrl} targetString="mainImageUrl" />
                </style.Td>
              </style.Tr>
            </style.Tbody>
          </style.Table>

          <style.H4>상품관리</style.H4>
          <style.Table>
            <style.Tbody>
              <style.Tr>
                <style.LeftTd>최종 업데이트</style.LeftTd>
                <style.Td>날짜</style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>거래액</style.LeftTd>
                <style.Td>
                  <style.InfoSpan>{}원</style.InfoSpan>
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>성과</style.LeftTd>
                <style.Td>
                  <style.InfoSpan>조회수 : {view}</style.InfoSpan>
                  <style.InfoSpan>좋아요 : {like}</style.InfoSpan>
                  <style.InfoSpan>장바구니 : {cart}</style.InfoSpan>
                  <style.InfoSpan>판매수 : {sales}</style.InfoSpan>
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>탭</style.LeftTd>
                <style.Td>
                  <style.SetTabContainer>
                    <div className="flex items-center justify-between">
                      <style.Button
                        onClick={() => {
                          let result = prompt(
                            '추가할 탭의 이름을 입력하세요. (즉시 반영됩니다.)'
                          );
                          if (result) createTab(exhibitionId, result);
                        }}
                      >
                        +
                      </style.Button>
                    </div>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="tab-drop">
                        {(drop) => (
                          <div
                            {...drop.droppableProps}
                            className="space-y-1 pb-6"
                            ref={drop.innerRef}
                          >
                            {tabs.map((obj, key) => (
                              <Draggable key={key} draggableId={String(key)} index={key}>
                                {(drag) => (
                                  <div
                                    className="flex items-center gap-1"
                                    ref={drag.innerRef}
                                    {...drag.draggableProps}
                                  >
                                    <span {...drag.dragHandleProps}>
                                      <SelectorIcon className="w-5 h-5 cursor-move" />
                                    </span>
                                    <input value={obj.name} className="flex-1" />
                                    <style.Button
                                      onClick={() =>
                                        setShowSetTab({
                                          showModal: true,
                                          exhibitionId,
                                          id: obj.id,
                                          name,
                                        })
                                      }
                                    >
                                      상품 추가
                                    </style.Button>
                                    <style.Button
                                      className="ml-1"
                                      onClick={() => onRemoveCategory(obj.id)}
                                    >
                                      -
                                    </style.Button>
                                    <input
                                      type="radio"
                                      a
                                      checked={obj.checked}
                                      onChange={(e) =>
                                        onChangeTab(e.target.checked, key, obj.id)
                                      }
                                      classNme="ml-1 w-5 h-5 cursor-pointer"
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </style.SetTabContainer>
                </style.Td>
              </style.Tr>
              <style.Tr>
                <style.LeftTd>미리보기</style.LeftTd>
                <style.Td>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://fetching.co.kr/exhibition.html?id=${exhibitionId}&testMode=true`}
                  >
                    링크
                  </a>
                </style.Td>
              </style.Tr>
            </style.Tbody>
          </style.Table>

          <style.ButtonsContainer>
            <style.ModifyButton onClick={() => putData(exhibitionId, handleIsLoading)}>
              수정 저장
            </style.ModifyButton>
            <style.ResetButton onClick={resetData}>수정 초기화</style.ResetButton>
          </style.ButtonsContainer>

          <ProductList
            items={items}
            tabs={tabs}
            onSave={saveOrder}
            isVerify={isVerify}
            setIsVerify={(val) => setIsVerify(val)}
            onRefresh={() => getItemsInExhibition(exhibitionId)}
          />

          {/* <RePagination
            pageSize={50}
            currentPage={page}
            onPageChange={(value) => setPage(value)}
            totalCount={total}
          /> */}
        </style.ShadowContainer>
      </style.Outer>
    </>
  );
};

export default ModifyExhibitionDetail;
