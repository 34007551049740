import styled from 'styled-components';

export const DateBoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  clear: both;
  margin-left: 10px;
  text-align: center;
  @media all and (min-width: 800px) {
    clear: both;
    display: flex;
    justify-content: center;
    // margin-top: 20px;
    flex-wrap: wrap;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  // margin-top: 10px;
`;

export const Select = styled.select`
  margin: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

export const Input = styled.input`
  border: 1px solid #ddd;
  border-radius: 4px;
`;

export const EventDurationWrapper = styled.span`
  padding: 0 20px;
`;

export const EventDurationText = styled.span`
  padding-left: 5px;
`;
