import React, { useState, useEffect } from 'react';
import * as style from './style';

const CountTotalBox = ({ data }) => {
  const [totalData, setTotalData] = useState({});

  switch (totalData.type) {
    case 'INFLOW':
      totalData.type = '유입';
      break;
    case 'VIEW':
      totalData.type = '상품 조회';
      break;
    case 'LIKE':
      totalData.type = '상품 좋아요';
      break;
    case 'SEARCH':
      totalData.type = '검색';
      break;
    case 'BRAND':
      totalData.type = '브랜드 상세';
      break;
    case 'SHOP':
      totalData.type = '편집샵 상세';
      break;
    case 'PROMOTION':
      totalData.type = '프로모션 상세';
      break;
    case 'EXHIBITION':
      totalData.type = '기획전 상세';
      break;
    case 'SHARE':
      totalData.type = '공유';
      break;
    case 'SHARE_KAKAO':
      totalData.type = '카카오톡 공유';
      break;
    case 'SHARE_KAKAO_ACTUAL':
      totalData.type = '카카오톡 공유 완료';
      break;
    case 'CART':
      totalData.type = '장바구니';
      break;
    case 'LIKE_BRAND':
      totalData.type = '브랜드 좋아요';
      break;
    case 'LIKE_SHOP':
      totalData.type = '편집샵 좋아요';
      break;
    case 'VIEW_APP':
      totalData.type = '앱 상품 조회';
      break;
    case 'GO_TO_SHOP':
      totalData.type = '편집샵 이동';
      break;
    case 'GO_TO_FETCHING_PURCHASE':
      totalData.type = 'APP 페칭 결제 선택';
      break;
    case 'SHOP_PURCHASE':
      totalData.type = 'APP 편집샵 이동 결제';
      break;
    case 'FETCHING_PURCHASE':
      totalData.type = 'WEB 페칭 결제';
      break;
    case 'FETCHING_APP_PURCHASE':
      totalData.type = 'APP 페칭 결제';
      break;
    case 'FETCHING_MOBILE_PURCHASE':
      totalData.type = '모바일 WEB 페칭 결제';
      break;
    case 'NAVER_PAY_PURCHASE':
      totalData.type = 'WEB 네이버페이 결제';
      break;
    case 'NAVER_PAY_APP_PURCHASE':
      totalData.type = 'APP 네이버페이 결제';
      break;
    case 'SMARTSTORE_PURCHASE':
      totalData.type = '스마트 스토어 결제';
      break;
    default:
      break;
  }

  useEffect(() => {
    setTotalData(JSON.parse(JSON.stringify(data)));
  }, [data]);

  return (
    <style.Container>
      <style.Total>
        <style.Text color={'white'}>{totalData.type}</style.Text>
        <style.Text color={'white'}>{totalData.total}</style.Text>
      </style.Total>
      <style.CountWrapper>
        <style.Count>
          <style.Text color={'black'}>APP</style.Text>
          <style.Text color={'black'}>{totalData.app}</style.Text>
        </style.Count>
        <style.Pin />
        <style.Count>
          <style.Text color={'black'}>WEB</style.Text>
          <style.Text color={'black'}>{totalData.web}</style.Text>
        </style.Count>
      </style.CountWrapper>
    </style.Container>
  );
};
export default CountTotalBox;
