import { SET_INVALID_URLS } from 'actions/types';
import { fetchingAPI } from 'api/axios';
import { LoadingContext } from 'context';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as style from './style';

const CallCatetoryMapModalButtons = ({
  searchOptionTableState,
  setSearchOptionTableState,
  checkedMaps,
  currentPage,
  checkedIds,
  fetchItems,
}) => {
  const { handleIsLoading } = useContext(LoadingContext);
  const dispatch = useDispatch();

  const disableMapping = async () => {
    console.log(checkedIds);
    if (checkedIds.length === 0) {
      window.alert('하나 이상의 상품을 선택하세요.');
      return;
    }

    const result = window.confirm(
      `선택한 카테고리 ${checkedIds.length}개를 매핑 불필요 카테고리로 설정하시겠습니까??`
    );
    if (result) {
      try {
        handleIsLoading(true);
        await fetchingAPI.post('/category/map/ignore/', checkedIds);
      } catch (err) {
        alert(`
            📌 에러가 발생했습니다.!
            아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

            API 에러 발생
            POST - /category/map/ignore/
            ${err}`);
      } finally {
        handleIsLoading(false);
        fetchItems(currentPage);
      }
    }
  };

  const deleteMapping = async () => {
    if (checkedIds.length === 0) {
      window.alert('하나 이상의 상품을 선택하세요.');
      return;
    }

    const filteredMaps = checkedIds.filter((map) => map.mapId);
    if (filteredMaps.length === 0) {
      window.alert('선택된 상품 중 매핑된 상품이 없습니다..');
      return;
    }
    const result = window.confirm(
      `선택한 카테고리 중 매핑된 ${filteredMaps.length}개의 매핑 데이터를 삭제하시겠습니까?`
    );
    if (result) {
      try {
        handleIsLoading(true);
        await fetchingAPI.delete('/category/map', {
          params: { map: filteredMaps },
        });
      } catch (err) {
        alert(`
            📌 에러가 발생했습니다.!
            아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

            API 에러 발생
            DELETE - /item
            ${err}`);
      } finally {
        handleIsLoading(false);
        fetchItems(currentPage);
      }
    }
  };

  return (
    <style.Container>
      <style.Button style={{ background: '#FFB661' }} onClick={disableMapping}>
        매핑 불필요
      </style.Button>
      <style.Button style={{ background: '#FF6F61' }} onClick={deleteMapping}>
        매핑 제거
      </style.Button>
      <style.Input
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            limit: Number(e.target.value),
          });
        }}
      >
        {[50, 100, 200, 300, 500].map((el) => (
          <option key={el} value={el}>
            {el}개씩 보기
          </option>
        ))}
      </style.Input>
    </style.Container>
  );
};

export default CallCatetoryMapModalButtons;
