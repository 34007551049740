import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://log-factory-dev.fetching.work',
  withCredentials: true,
});

export default instance;

export { instance as fetchingLog };

export const getItemDetailHistory = async (itemId) => {
  try {
    const res = await instance.get(`/api/item-update/history/${itemId}`);
    const { data } = res;
    return data;
  } catch (err) {
    console.log('getItemDetailHistory :', err);
  }
};
