import { getPromogionImgUrl } from 'api/axios';
import React from 'react';
import Dropzone from 'react-dropzone';
import * as S from './style';

const DropZoneImage = ({ imageUrl, setImageUrl }) => {
  const onDropDropzone = async (files) => {
    await getPromogionImgUrl(files, setImageUrl);
  };

  return (
    <div>
      <input
        style={{ width: '250px' }}
        value={imageUrl || ''}
        onChange={({ target: { value } }) => setImageUrl(value)} />
      <Dropzone onDrop={onDropDropzone} multiple={false} maxSize={20000000}>
        {({ getRootProps, getInputProps }) => (
          <S.Button {...getRootProps()}>
            <input {...getInputProps()} />
            찾아보기
          </S.Button>
        )}
      </Dropzone>
      {imageUrl ? (
        <>
          <S.Button
            style={{ marginLeft: '10px' }}
            onClick={() => {
              setImageUrl(null);
            }}
          >
            X
          </S.Button>
          <br />
          <div>
            <img
              src={imageUrl}
              style={{ maxWidth: '400px', marginTop: '13px' }}
            />
          </div>
          <div></div>
        </>
      ) : null}
    </div>
  );
};

export default DropZoneImage;

export const DropZoneImageModifiable = ({ imageUrl, setImageUrl }) => {
  const onDropDropzone = async (files) => {
    await getPromogionImgUrl(files, setImageUrl);
  };

  return (
    <div>
      <input
        style={{ width: '250px' }}
        value={imageUrl || ''}
        onChange={(e) => setImageUrl(e.target.value)}
      />
      <Dropzone onDrop={onDropDropzone} multiple={false} maxSize={20000000}>
        {({ getRootProps, getInputProps }) => (
          <S.Button {...getRootProps()}>
            <input {...getInputProps()} />
            찾아보기
          </S.Button>
        )}
      </Dropzone>
      <S.Button
        onClick={() => {
          window.open(imageUrl, '_blank').focus();
        }}
      >
        미리보기
      </S.Button>
    </div>
  );
};
