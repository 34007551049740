import { GridTable, NumberStat } from 'components';
import React, { Fragment, FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import {
  ORDER_PAY_STATUS,
  request,
  ORDER_CANCEL_STATUS,
  ORDER_CANCEL_REASON,
  useQuery,
} from 'services';
import useSWR from 'swr';
import classnames from 'classnames';

export interface Props {}
interface State {}

const OrderInfoCancel: FunctionComponent<Props> = () => {
  const { fetchingOrderNumber } = useParams<{
    fetchingOrderNumber: string;
  }>();
  const { data } = useSWR('/commerce/order/order-cancel', (url) =>
    request.get<any, { data: any[]; count: number }>(url, {
      params: { fetchingOrderNumber },
      headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
    })
  );
  const {
    query: { highlight },
  } = useQuery<{ highlight?: string }>();

  return (
    <>
      <GridTable
        columns={[
          '품목별 주문번호 (취소번호) / 편집샵 주문번호',
          '판매처 (국가 / 배송방식 / 관세조건)',
          '원산지 / 상품명 / 옵션명',
          '수량',
          '주문금액 / 환불금액',
          '상태 (사유)',
          '취소처리',
          '환불상태',
        ]}
        noSelection
        maxHeight="max-h-[512px]"
        list={!!data ? data.data : []}
        renderItem={(shop, shopIndex) => (
          <Fragment key={shopIndex}>
            {shop.itemList.map((item, itemIndex) => (
              <div
                key={itemIndex}
                style={
                  item.itemOrderNumber == highlight ? { background: '#ff0' } : undefined
                }
              >
                <div aria-label="품목별 주문번호">
                  <div>
                    {item.itemOrderNumber} ({item.cancelItemNumber})
                  </div>
                  <div>{item.shopOrderNumber}</div>
                </div>
                <div aria-label="판매처 (국가 / 배송방식 / 관세조건)">
                  {item.shopName} ({item.shopCountry} /{' '}
                  <span
                    className={classnames({
                      'text-fassto': item.deliveryMethodName === '파스토',
                      'text-eldex': item.deliveryMethodName === '엘덱스',
                    })}
                  >
                    {item.deliveryMethodName}[{item.deliveryMethodCountry}]
                  </span>{' '}
                  /{' '}
                  <span>
                    {shop.isDdp === true && 'DDP'}
                    {shop.isDdp === false && 'DDU'}
                  </span>
                  )
                </div>
                <div aria-label="원산지 / 상품명 / 옵션명">
                  <div className="flex gap-2">
                    <img
                      src={item.itemImageUrl}
                      alt={item.itemName}
                      className="max-w-[3.5rem] object-contain"
                    />
                    <div className="flex-1">
                      <div>{item.itemCountry}</div>
                      <div className="text-black">{item.itemName}</div>
                      <div>{item.sizeName}</div>
                    </div>
                  </div>
                </div>
                <div aria-label="수량">
                  <span className="text-black text-lg">{item.quantity}</span>
                </div>
                <div aria-label="주문금액 / 환불금액">
                  <NumberStat value={item.payAmount} /> /{'  '}
                  <NumberStat value={item.refundAmount} />
                </div>
                <div aria-label="상태 (사유)">
                  <span className="font-bold">
                    {ORDER_CANCEL_STATUS[item.cancelStatus]}
                  </span>
                  <div>({ORDER_CANCEL_REASON[item.reasonType]})</div>
                </div>
                <div aria-label="취소처리">
                  <div className="font-bold">{ORDER_PAY_STATUS[item.cancelStatus]}</div>
                </div>
                <div aria-label="환불상태">
                  <span className="font-bold">
                    {!!item.refundInfo
                      ? ORDER_PAY_STATUS[item.refundInfo.refundStatus]
                      : ''}
                  </span>
                </div>
              </div>
            ))}
          </Fragment>
        )}
      />
    </>
  );
};

export default OrderInfoCancel;
