import React, { useEffect, useState, useContext } from 'react';
import {
  BrandBasicInfo,
  BrandNameInfo,
  BrandRenderInfo,
  MoonLoading,
  BrandCafe24RenderInfo,
} from 'components';
import { LoadingContext } from 'context';
import * as style from './style';
import axios from 'api/axios';
import { request } from 'services';

export default () => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const pathNmae = window.location.pathname.split('/');
  const brandId = pathNmae[pathNmae.length - 1];

  const [clientHeight, setClinentHeight] = useState({
    '기본 정보': 0,
    브랜드명: 0,
    '앱 노출 정보': 0,
    '카페24 노출 정보': 0,
  });

  const [brand, setBrand] = useState(null);
  const [semiName, setSemiName] = useState([]);
  const [modifyBrand, setModifyBrand] = useState({
    name: '',
    enName: '',
    krName: '',
    imageUrl: '',
    bannerImageUrl: '',
    backgroundImageUrl: '',
    description: '',
    semiName: [],
  });

  const handleModifyBrand = async () => {
    try {
      handleIsLoading(true);
      const result = JSON.parse(JSON.stringify(modifyBrand));

      const semiNameList = result.semiName;

      const addSemiNames = [];
      const deleteSemiNames = [];

      semiNameList.forEach((el) => {
        const find = semiName.findIndex((b) => b.idx === el.idx);
        if (find === -1) {
          addSemiNames.push({
            brandId: Number(el.brand_id),
            semiName: el.semi_name,
          });
        }
      });

      semiName.forEach((el) => {
        const find = semiNameList.findIndex((b) => b.idx === el.idx);
        if (find === -1) {
          deleteSemiNames.push(el.idx);
        }
      });

      try {
        await axios.delete('/api/brand/map', {
          data: {
            data: deleteSemiNames,
          },
        });
      } catch (err) {
        handleIsLoading(false);
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        DELETE - /api/brand/map
        ${err}`);
      }

      try {
        await axios.post('/api/brand/map', {
          data: addSemiNames,
        });
      } catch (err) {
        handleIsLoading(false);
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        POST - /api/brand/map
        ${err}`);
      }

      delete result.semiName;

      try {
        await axios.put(`/api/brand/${brandId}`, result);
      } catch (err) {
        handleIsLoading(false);
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        PUT - /api/brand/${brandId}
        ${err}`);
      }
    } catch (err) {
      handleIsLoading(false);
    } finally {
      handleIsLoading(false);
      window.location.reload();
    }
  };

  const handleSemiNameDelete = (id) => {
    const semiName = modifyBrand.semiName;
    const result = semiName.filter((el) => {
      return el.idx !== id;
    });
    setModifyBrand({
      ...modifyBrand,
      semiName: result,
    });
  };

  const handleAddSemiName = (value) => {
    const result = modifyBrand.semiName;
    const find = result.findIndex((el) => el.semi_name === value.trim());
    if (find !== -1) {
      alert('이미 같은 검색명이 존재합니다.');
      return;
    }
    result.push({
      idx: value.trim(),
      brand_id: brandId,
      semi_name: value.trim(),
    });
    setModifyBrand({
      ...modifyBrand,
      semiName: result,
    });
  };

  const handleRoundImgOnDrop = (files) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post('/api/util/image', {
          path: 'shops',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setModifyBrand({
              ...modifyBrand,
              imageUrl: imgUrl,
            });
          }
        })
        .catch((err) => {
          alert(`
          📌 에러가 발생했습니다.! 
          아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    
          API 에러 발생 
          POST - /api/util/image
          ${err}`);
          console.log(err);
        });
    };

    const getBase64 = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result.split(',');
        cb(result[1]);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };

  const handleImgOnDrop = (files) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post('/api/util/image', {
          path: 'shops',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setModifyBrand({
              ...modifyBrand,
              bannerImageUrl: imgUrl,
            });
          }
        })
        .catch((err) => {
          alert(`
          📌 에러가 발생했습니다.! 
          아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    
          API 에러 발생 
          POST - /api/util/image
          ${err}`);
          console.log(err);
        });
    };

    const getBase64 = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result.split(',');
        cb(result[1]);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };

  const handleBackgroundImgOnDrop = (files) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post('/api/util/image', {
          path: 'shops',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setModifyBrand({
              ...modifyBrand,
              backgroundImageUrl: imgUrl,
            });
          }
        })
        .catch((err) => {
          alert(`
          📌 에러가 발생했습니다.! 
          아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
    
          API 에러 발생 
          POST - /api/util/image
          ${err}`);
          console.log(err);
        });
    };

    const getBase64 = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const result = reader.result.split(',');
        cb(result[1]);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };

  const handleScroll = (target) => {
    if (target === '기본 정보') {
      window.scrollTo(0, 0);
    }
    if (target === '브랜드명') {
      const result = clientHeight['기본 정보'];
      window.scrollTo(0, result);
    }
    if (target === '앱 노출 정보') {
      const result = clientHeight['기본 정보'] + clientHeight['브랜드명'];
      window.scrollTo(0, result);
    }
    if (target === '카페24 노출 정보') {
      const result =
        clientHeight['기본 정보'] +
        clientHeight['브랜드명'] +
        clientHeight['앱 노출 정보'];
      window.scrollTo(0, result);
    }
  };

  const handleClinentHeight = (key, value) => {
    clientHeight[key] = value;
  };

  const handleRemoveBrand = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    try {
      await request.delete(`/brand/${brandId}`);
      window.alert('삭제되었습니다.');
      window.close();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let initial = true;
    axios
      .get(`/api/brand/${brandId}`)
      .then((res) => {
        if (initial) {
          setBrand(res.data.data);
        }
      })
      .catch((err) => {
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        GET - /api/brand/${brandId}
        ${err}`);
      });
    return () => {
      initial = false;
    };
  }, []);

  useEffect(() => {
    let initial = true;
    axios
      .get(`/api/brand/${brandId}/semi`)
      .then((res) => {
        if (initial) {
          setSemiName(res.data);
        }
      })
      .catch((err) => {
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        GET - /api/brand/${brandId}/semi
        ${err}`);
      });
    return () => {
      initial = false;
    };
  }, []);

  useEffect(() => {
    const result = JSON.parse(JSON.stringify(semiName));
    setModifyBrand({
      name: brand?.main_name || '',
      enName: brand?.brand_name || '',
      krName: brand?.brand_name_kor || '',
      imageUrl: brand?.image_url || '',
      backgroundImageUrl: brand?.background_image_url || '',
      bannerImageUrl: brand?.banner_image_url || '',
      description: brand?.description || '',
      semiName: result,
    });
  }, [brand, semiName]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <style.Header>
        <style.Title>브랜드 정보</style.Title>
        <style.ShopName>
          {brand?.main_name} | {brand?.brand_name}
        </style.ShopName>
        <style.TitleBar>
          <style.TitleItem onClick={() => handleScroll('기본 정보')}>
            기본 정보
          </style.TitleItem>
          <style.TitleItem onClick={() => handleScroll('브랜드명')}>
            브랜드명
          </style.TitleItem>
          <style.TitleItem onClick={() => handleScroll('앱 노출 정보')}>
            앱 노출 정보
          </style.TitleItem>
          <style.TitleItem onClick={() => handleScroll('콘텐츠 정보')}>
            콘텐츠 정보
          </style.TitleItem>
        </style.TitleBar>
      </style.Header>
      <style.Content>
        <BrandBasicInfo brand={brand} handleClinentHeight={handleClinentHeight} />
        <BrandNameInfo
          modifyBrand={modifyBrand}
          setModifyBrand={setModifyBrand}
          handleSemiNameDelete={handleSemiNameDelete}
          handleAddSemiName={handleAddSemiName}
          handleClinentHeight={handleClinentHeight}
        />
        <BrandRenderInfo
          brand={brand}
          modifyBrand={modifyBrand}
          setModifyBrand={(val) => setModifyBrand(val)}
          handleRoundImgOnDrop={handleRoundImgOnDrop}
          handleImgOnDrop={handleImgOnDrop}
          handleClinentHeight={handleClinentHeight}
        />
        <BrandCafe24RenderInfo
          modifyBrand={modifyBrand}
          setModifyBrand={(val) => setModifyBrand(val)}
          handleImgOnDrop={handleBackgroundImgOnDrop}
          handleClinentHeight={handleClinentHeight}
        />
      </style.Content>
      <style.Footer>
        <style.Button BC={'#0D9AE9'} onClick={handleModifyBrand}>
          수정
        </style.Button>
        <style.Button BC={'#FF6F61'} onClick={handleRemoveBrand}>
          삭제
        </style.Button>
      </style.Footer>
    </style.Container>
  );
};
