import styled from 'styled-components';

export const Table = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid #b4b4b4;
  border-bottom: none;
`;
export const Tr = styled.div`
  border-bottom: 0.5px solid #b4b4b4;
  display: flex;
  flex-wrap: wrap;
`;
export const Left = styled.div`
  width: 10%;
  padding: 13px 11px;
  background: #e4e4e4;
  border-right: 0.5px solid #b4b4b4;
`;
export const Td = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  width: 90%;
`;
export const showDetailButton = styled.div`
  width: 10%;
  // width:120px;
  background: #35256d;
  border: 0.5px solid #000000;
  color: white;
  padding: 1px 3px;
`;
