import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 10px 30px;
  margin: 50px;
`;

export const Button = styled.button`
  padding: 10px 16px;
  border-radius: 4px;
  border: none;
  background-color: ${(props) => props.color};
  color: ${(props) => (props.color ? 'white' : 'black')};
  &:active,
  &:hover {
    background-color: gray;
  }
  &:focus {
    outline: none;
  }
`;
