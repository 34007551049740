import styled from 'styled-components';

export const Button = styled.button`
  border: none;
  height: 25px;
  background: #3d3d3d;
  margin-right: 4px;
  color: #ff6f61;
  padding: 0 5px;
`;
