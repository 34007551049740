import { Button, MenuTitle } from 'components';
import React from 'react';

interface State {}

const UsersMailPage = () => {
  return (
    <>
      <MenuTitle title="메일 발송" />
      <div className="wrapper">
        <div className="wrapper-title">메일 발송</div>
        <ul className="my-3 list-inside list-disc">
          <li>메일 발송은 현재 외부 솔루션 스티비(stibee)를 사용하고 있습니다.</li>
          <li>자동 메일 수정 및 메일 발송은 스티비(stibee)에서 진행해주세요.</li>
          <li>어드민 직접 발송 구현은 현재 미정입니다.</li>
        </ul>
        <a href="https://stibee.com/login" target="_blank">
          <Button>스티비로 이동하기</Button>
        </a>
      </div>
    </>
  );
};

export default UsersMailPage;
