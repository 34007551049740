import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { LoadingProvider, ModalProvider } from './context';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { RecoilRoot } from 'recoil';

Sentry.init({
  dsn: 'https://ec65d9da94c747c483281f2495587904@o395466.ingest.sentry.io/5974749',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  attachStacktrace: true,
  maxBreadcrumbs: 50,
  beforeSend: (event) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }
    return event;
  },
});

const persistor = persistStore(store);
const auth_token = new URLSearchParams(window.location.search).get('auth_token'); // 신규어드민에서 이동 시 대응 이전 완료하면 지워야 함.
auth_token && localStorage.setItem('fetchingAPI_AUTH_TOKEN', auth_token);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LoadingProvider>
        <ModalProvider>
          <RecoilRoot>
            <App />
          </RecoilRoot>
        </ModalProvider>
      </LoadingProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
