import { fetchingAPI } from 'api/axios';
import { atom, selector } from 'recoil';
import type { IAddress, IAdminUser, IPriceRule, IShop, NOrder } from 'types';

export const authState = atom<IAdminUser | null>({
  key: 'authState',
  default: null,
});

export const addressState = atom<IAddress>({
  key: 'addressState',
  default: {
    idx: 0,
    isDefault: false,
    zipCode: '',
    detailAddress: '',
    lotNumberAddress: '',
    streetNameAddress: '',
    englishDetailAddress: '',
    englishLotNumberAddress: '',
    englishStreetNameAddress: '',
    name: '',
    englishCity: '',
    recipientName: '',
    phoneNumber: '',
  },
});

export const addressListState = atom<IAddress[]>({
  key: 'addressListState',
  default: [],
});

export const orderDetailState = atom<NOrder.Detail>({
  key: 'orderDetailState',
  default: {
    address: '',
    addressDetail: '',
    customsClearanceNumber: '',
    cancelInfo: null,
    createdAt: '',
    orderPath: '',
    orderStatus: '',
    orderIp: '',
    userStringId: '',
    orderUserEmail: '',
    orderUserName: '',
    orderUserPhoneNumber: '',
    orderedAt: '',
    shopOrderList: [],
    userId: 0,
    userName: '',
    userType: '',
    recipientName: '',
    payAmount: 0,
    phoneNumber: '',
    itemTotalAmount: 0,
    totalDeliveryFee: 0,
    totalDiscount: 0,
    totalDiscountDetail: [],
    expectedPayAmount: 0,
    expectedPoint: 0,
    zipCode: '',
    deliveryMemo: '',
    totalDutyAndTax: 0,
    englishAddress: '',
    englishAddressDetail: '',
    englishDeliveryMemo: '',
    englishRecipientName: '',
    totalAdditionalFee: 0,
    isInitialFetched: false,
    payMethod: '',
  },
});

export const orderCountState = atom<NOrder.Count>({
  key: 'orderCountState',
  default: {
    beforeDeposit: 0,
    newOrder: 0,
    waiting: 0,
    preRequired: 0,
    complete: 0,
    delay: 0,
    delayInShop: 0,
    shipping: 0,
    shippingComplete: 0,
    exchange: 0,
    return: 0,
    refund: 0,
    cancel: 0,
  },
});

export const shippingCountState = atom<NOrder.ShippingCount>({
  key: 'shippingCountState',
  default: {
    prepare: 0,
    inWaypoint: 0,
    domesticCustoms: 0,
    customsDelay: 0,
    start: 0,
  },
});

export const shopsQuery = selector({
  key: 'shopsQuery',
  get: async () => fetchingAPI.get('/shop?isExtended=true').then((res) => res.data.data),
});
export const brandsQuery = selector({
  key: 'brandsQuery',
  get: async () => fetchingAPI.get('/brand?isExtended=true').then((res) => res.data.data),
});
export const promotionsQuery = selector({
  key: 'promotionsQuery',
  get: async () =>
    fetchingAPI
      .get('/promotion/v2?sort=SHOP_NAME&isExtended=true')
      .then((res) => res.data),
});
export const exhibitionsQuery = selector({
  key: 'exhibitionsQuery',
  get: async () => fetchingAPI.get('/exhibition?isExtended=true').then((res) => res.data),
});
export const categoriesQuery = selector({
  key: 'categoriesQuery',
  get: async () =>
    fetchingAPI.get('/category?isExtended=true').then((res) => ({
      wCategories: res.data.data.filter(
        (obj) => [14, 15, 16, 17].includes(obj.parentId) && obj.depth === 2
      ),
      mCategories: res.data.data.filter(
        (obj) => [18, 19, 20, 21].includes(obj.parentId) && obj.depth === 2
      ),
    })),
});

export const shopListState = atom<IShop[]>({
  key: 'shopListState',
  default: [],
});

export const priceRuleState = atom<IPriceRule[]>({
  key: 'priceRuleState',
  default: [],
});
