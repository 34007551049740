import React, { createContext, useState, useCallback } from 'react';

export const LoadingContext = createContext({});

export const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleIsLoading = useCallback((value) => {
    setIsLoading(value);
  }, []);

  return (
    <LoadingContext.Provider
      value={{
        isLoading: isLoading,
        handleIsLoading,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};
