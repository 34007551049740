import {
  Button,
  Card,
  GridTable,
  MenuTitle,
  OrderCount,
  Radio,
  ReInput,
  RePagination,
  ResidentNumberModal,
  Select,
  Tabs,
} from 'components';
import { useHistory } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
  enumToList,
  ORDER_DATE_LIST,
  ORDER_SEARCH_TYPE,
  ORDER_SORT,
  useObject,
  useOrderTable,
} from 'services';
import { DuplicateIcon } from '@heroicons/react/outline';
import { useRecoilValue } from 'recoil';
import { orderCountState } from 'store';

interface State {
  isResidentNumberOpen: boolean;
}

const OrderCustomsRefundPage = () => {
  const [{ isResidentNumberOpen }, setState] = useObject<State>({
    isResidentNumberOpen: false,
  });
  const orderCount = useRecoilValue(orderCountState);
  const { push } = useHistory();
  const onTabChange = (tab: number) => {
    if (tab === 0) push('/orders/return');
    else if (tab === 1) push('/orders/exchange');
    else if (tab === 2) push('/orders/refund');
  };
  const {
    searchText,
    searchType,
    selectedIdx,
    onDateClick,
    onChangeOrder,
    onChangeCheckbox,
    setOrderState,
    minOrderDate,
    maxOrderDate,
    orderStatus,
    list,
    isLoading,
    total,
    sort,
    limit,
    page,
    resetOrderState,
  } = useOrderTable();
  const get = async () => {};
  useEffect(() => {
    get();
  }, [minOrderDate, maxOrderDate, orderStatus, sort, limit, page]);
  return (
    <OrderCount>
      <MenuTitle>
        <Tabs
          onTabChange={onTabChange}
          value={3}
          options={[
            `반품 관리 (${orderCount.return})`,
            `교환 관리 (${orderCount.exchange})`,
            `환불 관리 (${orderCount.refund})`,
            '관세 환급 관리',
          ]}
        />
      </MenuTitle>
      <div className="wrapper">
        <div className="wrapper-title">관세 환급 관리</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <ReInput
                    size="xs"
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    onEnter={get}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '상태',
              renderItem: () => (
                <Radio
                  id="orderStatus"
                  value={orderStatus}
                  options={[
                    { name: '전체', value: '' },
                    { name: '완료', value: 'complete' },
                    { name: '미완료', value: 'incomplete' },
                  ]}
                  onChange={(orderStatus) => setOrderState({ orderStatus })}
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-7 flex justify-center gap-3">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          isLoading={isLoading}
          columns={[
            '환불번호 / 주문번호',
            '환불접수일',
            '주문자',
            '연락처',
            '실 결제금액',
            '세금',
            '환불금액',
            '수입신고서',
            '통장사본',
            '주민등록번호',
            '반송장 및 인보이스 캡쳐',
            '배송조회페이지 캡쳐',
            '승인 취소 캡쳐',
            '관리자기능',
          ]}
          maxHeight=""
          header={
            <div className="flex items-center justify-end">
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          list={list}
          renderItem={(item, key) => (
            <div key={key} onClick={() => onChangeCheckbox(item.fetchingOrderNumber)}>
              <div aria-label="checkbox">
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={selectedIdx.indexOf(item.fetchingOrderNumber) !== -1}
                />
              </div>
              <div aria-label="환불번호 / 주문번호">
                <div className="link">RF20211001-0000001</div>
                <div>20211001-0000001</div>
              </div>
              <div aria-label="환불접수일">2021.09.02 15:58:32</div>
              <div aria-label="주문자">
                <div className="link">주문자명</div>
                <div>일반회원</div>
              </div>
              <div aria-label="연락처">010-1234-5678</div>
              <div aria-label="실 결제금액">nnn,nnn,nnn원</div>
              <div aria-label="세금">
                <div>관세: nnn,nnn,nnn원</div>
                <div>특소세:</div>
                <div>교육세:</div>
                <div>부가세:</div>
              </div>
              <div aria-label="환불금액">nnn,nnn,nnn원</div>
              <div aria-label="수입신고서">
                <div>완료</div>
                <Button size="xs">업로드</Button>
                <div>파일명</div>
              </div>
              <div aria-label="통장사본">
                <div>완료</div>
                <Button size="xs">업로드</Button>
                <div>파일명</div>
              </div>
              <div aria-label="주민등록번호">
                <div>미완료</div>
                <Button
                  onClick={() => setState({ isResidentNumberOpen: true })}
                  size="xs"
                >
                  등록
                </Button>
                <DuplicateIcon className="w-5 h-5 cursor-pointer" />
              </div>
              <div aria-label="반송장 및 인보이스 캡쳐">
                <div>완료</div>
                <Button size="xs">업로드</Button>
                <div>파일명</div>
              </div>
              <div aria-label="배송조회페이지 캡쳐">
                <div>완료</div>
                <Button size="xs">업로드</Button>
                <div>파일명</div>
              </div>
              <div aria-label="승인 취소 캡쳐">
                <div>완료</div>
                <Button size="xs">업로드</Button>
                <div>파일명</div>
              </div>
              <div aria-label="관리자기능">
                <div className="flex flex-col items-center gap-2">
                  <Button size="xs" className="w-14">
                    메시지
                  </Button>
                  <Button size="xs">메모</Button>
                </div>
              </div>
            </div>
          )}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setOrderState({
              selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
            })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
      <ResidentNumberModal
        isOpen={isResidentNumberOpen}
        onClose={() => setState({ isResidentNumberOpen: false })}
        onComplete={get}
      />
    </OrderCount>
  );
};

export default OrderCustomsRefundPage;
