import React, { useEffect, useState } from 'react';
import { Title, CheckBox, MessageModal, Loading } from 'components';
import { getCrawlerTargetList, getShopList, handleCrawlerItems } from 'api/axios';
import * as style from './style';
import { useDispatch, useSelector } from 'react-redux';
import { RESET_INVALID_URLS } from 'actions/types';
import { Spinner } from 'components';

export default () => {
  const dispatch = useDispatch();
  const invalidUrls = useSelector((state) => state.invalidUrls);

  const [isLoading, setIsLoading] = useState(false);
  const [resState, setResState] = useState('');
  const [isTargetLoading, setIsTargetLoading] = useState(false);
  const [shops, setShops] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [option, setOption] = useState({
    shopId: '',
    targetId: '',
    url: '',
  });

  const fetchData = async (initial) => {
    const shopList = await getShopList({
      hasCrawler: true,
    });
    if (initial) {
      const result = shopList.sort((a, b) => {
        if (a.krName < b.krName) {
          return -1;
        }
        if (a.krName > b.krName) {
          return 1;
        }

        return 0;
      });
      setShops(result);
    }
  };

  const handleSelecedShop = async (shopId) => {
    setOption({
      ...option,
      shopId,
    });
    if (shopId) {
      setIsTargetLoading(true);
      const result = await getCrawlerTargetList(shopId);
      setTargetList(result);

      setIsTargetLoading(false);
    }
  };

  const handleCrawler = async () => {
    // eslint-disable-next-line no-restricted-syntax
    try {
      for (const key in option) {
        if (!option[key]) {
          alert('편집샵 선택, 카테고리 선택, 링크를 모두 입력하셔야 합니다.');
          return;
        }
      }
      const url = option.url.trim().split(',');
      const result = url
        .map((el) => {
          if (el) {
            return {
              shopId: option.shopId,
              targetId: option.targetId,
              url: el.trim(),
            };
          }
        })
        .filter((el) => el !== undefined);
      setIsLoading(true);
      await handleCrawlerItems({ url: result });
      setResState('요청 성공');
      dispatch({ type: RESET_INVALID_URLS });
    } catch (err) {
      console.log(err);
      setResState('요청 실패');
    } finally {
      setIsLoading(false);
    }
  };

  const onHide = () => {
    if (resState === '요청 성공') {
      setOption({
        shopId: '',
        targetId: '',
        url: '',
      });
      setTargetList([]);
    }
    setResState('');
  };

  useEffect(() => {
    let initial = true;

    fetchData(initial);

    if (invalidUrls.redirect) {
      setOption({
        ...option,
        url: invalidUrls.invalid.join(','),
      });
    }

    return () => {
      initial = false;
      // dispatch({ type: RESET_INVALID_URLS });
    };
  }, []);

  return (
    <style.Container>
      {isLoading && <Loading />}
      {resState && (
        <MessageModal
          onHide={onHide}
          title={resState}
          description={
            resState === '요청 성공'
              ? '요청하신 상품을 수집중입니다'
              : '요청하신 상품 수집 요청이 실패했습니다. 개발팀에 문의해주세요.'
          }
        />
      )}
      <Title
        title="신상 가져오기"
        description="상품 목록에서 검색되지 않는 편집샵의 상품을 실시간으로 가져올 수 있는 기능입니다."
      />
      <style.Wrapper>
        <style.SelectWrapper>
          <style.Select
            value={option.shopId}
            onChange={(e) => handleSelecedShop(e.target.value)}
          >
            <option value="">어떤 편집샵의 상품인가요? 편집샵을 선택해주세요.</option>
            {shops.map((shop) => (
              <option key={shop.id} value={shop.id}>
                {shop.krName}
              </option>
            ))}
          </style.Select>
          <style.OptionBox>
            {isTargetLoading && (
              <style.LoadingWrapper>
                <Spinner className="h-20 w-20 text-black" />
              </style.LoadingWrapper>
            )}
            {targetList.length && option.shopId ? (
              <>
                {targetList.map((el) => (
                  <CheckBox
                    key={el.id}
                    isChecked={Number(option.targetId) === el.id}
                    onChange={() => {
                      setOption({
                        ...option,
                        targetId: el.id,
                      });
                    }}
                    text={`${el.id} , ${el.gender === 'M' ? '남성' : '여성'} > ${
                      el.category
                    }`}
                  />
                ))}
              </>
            ) : null}
            {!targetList.length && !isTargetLoading ? (
              <style.LoadingWrapper>
                <p>카테고리가 없습니다. 다른 편집샵을 선택해 주세요.</p>
              </style.LoadingWrapper>
            ) : null}
          </style.OptionBox>
        </style.SelectWrapper>
        <style.InputWrapper>
          <style.Textarea
            placeholder="가져올 상품의 링크를 입력하세요. 복수 입력의 경우 쉼표로 구분해주세요."
            value={option.url}
            onChange={(e) =>
              setOption({
                ...option,
                url: e.target.value,
              })
            }
          />
        </style.InputWrapper>
      </style.Wrapper>
      <style.ButtonWrapper onClick={handleCrawler}>
        <style.Button>가져오기</style.Button>
      </style.ButtonWrapper>
    </style.Container>
  );
};
