import { Spinner } from 'components';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import useSWR from 'swr';
import isoWeek from 'dayjs/plugin/isoWeek';

import { DashboardCard, Counter, DashboardItem, DashboardDiff } from './components';
import { WeekData, MonthData, Graph, DayData } from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
dayjs.extend(isoWeek);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const options: ChartOptions<any> = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (item) => {
          return Number(item.raw).toLocaleString();
        },
      },
    },
  },
};

const getLineChartData = (
  label: string,
  data?: { date: string; value: number }[]
): ChartData<'line', number[], string> => {
  return {
    labels: data?.map((data) => data.date) ?? [],
    datasets: [
      {
        label,
        data: data?.map((data) => data.value) ?? [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        pointRadius: 6,
      },
    ],
  };
};

const request = axios.create({
  baseURL:
    window.location.origin === 'https://admin.fetching.work'
      ? 'https://admin.fetchingapp.co.kr'
      : 'https://testapi.fetchingapp.co.kr',
});
export const fetcher = <T,>(url: string) => request.get<T>(url).then((res) => res.data);
const getErrorDescription = (error: any) => {
  switch (error?.response?.status) {
    case 404:
      return `서버에 정보가 없습니다. (${error?.response?.status})`;
    case 401:
    case 403:
      return `권한이 부족합니다. (${error?.response?.status})`;
    case 500:
      return `서버 내부 에러 (${error?.response?.status})`;
    default:
      return `잠시 후 다시 시도해주세요. (${error?.response?.status})`;
  }
};

const DAY_OFFSET = 0;
const WEEK_OFFSET = 0;
const MONTH_OFFSET = 0;

const DashboardService: React.FC = ({}) => {
  const [dayView, setDayView] = useState(DAY_OFFSET);
  const [weekView, setWeekView] = useState(WEEK_OFFSET);
  const [monthView, setMonthView] = useState(MONTH_OFFSET);

  const { data: dayData, error: dayError } = useSWR<DayData>(
    () => `/dashboard/daily/${dayjs().add(dayView, 'day').format('YYYY-MM-DD')}`,
    fetcher,
    { shouldRetryOnError: false }
  );
  const { data: weekData, error: weekError } = useSWR<WeekData>(
    () =>
      `/dashboard/weekly/${dayjs().add(weekView, 'week').year()}-W${dayjs()
        .add(weekView, 'week')
        .isoWeek()
        .toString()
        .padStart(2, '0')}`,
    fetcher,
    { shouldRetryOnError: false }
  );
  const { data: monthData, error: monthError } = useSWR<MonthData>(
    () => `/dashboard/monthly/${dayjs().add(monthView, 'month').format('YYYY-MM')}`,
    fetcher,
    { shouldRetryOnError: false }
  );
  const { data: graphData, error: graphError } = useSWR<Graph>(
    `/dashboard/graph`,
    fetcher,
    { shouldRetryOnError: false }
  );

  useEffect(() => {
    dayError &&
      toast.error(
        `${dayjs()
          .add(dayView, 'day')
          .format('M월DD일')} 일간 정보를 가져오는데 실패했습니다. ${getErrorDescription(
          dayError
        )}`
      );
  }, [dayError]);

  useEffect(() => {
    weekError &&
      toast.error(
        `${dayjs().add(weekView, 'week').day(1).format('M월DD일')} ~ ${
          weekView < 0
            ? dayjs()
                .add(weekView + 1, 'week')
                .day(0)
                .format('M월DD일')
            : dayjs().format('M월DD일')
        } 주간 정보를 가져오는데 실패했습니다. ${getErrorDescription(weekError)}`
      );
  }, [weekError]);

  useEffect(() => {
    monthError &&
      toast.error(
        `${dayjs()
          .add(monthView, 'month')
          .format('M월')} 월간 정보를 가져오는데 실패했습니다. ${getErrorDescription(
          monthError
        )}`
      );
  }, [monthError]);

  useEffect(() => {
    graphError &&
      toast.error(
        `그래프 정보를 가져오는데 실패했습니다. ${getErrorDescription(graphError)}`
      );
  }, [graphError]);

  console.log({
    data: {
      dayData,
      weekData,
      monthData,
      graphData,
    },
    error: {
      dayError,
      weekError,
      monthError,
      graphError,
    },
  });

  return (
    <div>
      <div className="grid grid-cols-2 m-4 gap-4 relative">
        {!(
          (dayData || dayError) &&
          (weekData || weekError) &&
          (monthData || monthError) &&
          (graphData || graphError)
        ) && (
          <div className="absolute w-full h-full opacity-60 flex items-center justify-center bg-white">
            <Spinner className="h-10 w-10 text-black" />
          </div>
        )}
        <DashboardCard
          span={2}
          title={
            <Counter
              increament={() => setDayView((p) => (p < DAY_OFFSET ? p + 1 : DAY_OFFSET))}
              decreament={() => setDayView((p) => p - 1)}
            >
              {dayjs().add(dayView, 'day').format('M월DD일')}
            </Counter>
          }
          date={dayjs(dayData?.createdAt).format('HH:mm 업데이트')}
        >
          <div
            className="grid grid-flow-col gap-y-4 gap-x-10"
            style={{
              gridTemplateRows: 'repeat(4, minmax(0, 1fr))',
              gridTemplateColumns: '1fr 1fr 1px 1fr 1fr',
            }}
          >
            <DashboardItem name="DAU">
              <DashboardDiff
                to={dayData?.dau ?? 0}
                from={dayData?.dauBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="회원가입자수">
              <DashboardDiff
                to={dayData?.register ?? 0}
                from={dayData?.registerBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="주문건수">
              <DashboardDiff
                to={dayData?.purchase ?? 0}
                from={dayData?.purchaseBefore ?? 0}
                unit="건"
              />
            </DashboardItem>
            <DashboardItem name="구매전환율">
              <DashboardDiff
                to={dayData?.purchaseConversionRate ?? 0}
                from={dayData?.purchaseConversionRateBefore ?? 0}
                unit="%"
              />
            </DashboardItem>
            <DashboardItem name="거래액">
              <DashboardDiff
                to={dayData?.purchaseAmount ?? 0}
                from={dayData?.purchaseAmountBefore ?? 0}
              />
            </DashboardItem>
            <DashboardItem name="매출액">
              <DashboardDiff
                to={dayData?.salesAmount ?? 0}
                from={dayData?.salesAmountBefore ?? 0}
              />
            </DashboardItem>
            <span></span>
            <span></span>
            <div style={{ gridRow: `span 4 / span 4`, background: '#E0E0E0' }}></div>
            {/* <DashboardItem name="신규 사용자수">
              <DashboardDiff
                to={dayData?.newUsers ?? 0}
                from={dayData?.newUsersBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="기획전 사용자수">
              <DashboardDiff
                to={dayData?.exhibitionUsers ?? 0}
                from={dayData?.exhibitionUsersBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="기획전 주문건수">
              <DashboardDiff
                to={dayData?.exhibitionPurchases ?? 0}
                from={dayData?.exhibitionPurchasesBefore ?? 0}
                unit="건"
              />
            </DashboardItem>
            <DashboardItem name="기획전 구매전환율">
              <DashboardDiff
                to={dayData?.exhibitionPurchaseConversionRate ?? 0}
                from={dayData?.exhibitionPurchaseConversionRateBefore ?? 0}
                unit="%"
              />
            </DashboardItem>
            <DashboardItem name="누적 구매 전환율">
              <DashboardDiff
                to={dayData?.cumulativePurchaseConversionRate ?? 0}
                unit="%"
              />
            </DashboardItem>
            <DashboardItem name="누적 회원가입 전환율">
              <DashboardDiff
                to={dayData?.cumulativeRegisterConversionRate ?? 0}
                unit="%"
              />
            </DashboardItem> */}
          </div>
          <div className="italic text-sm text-gray-400 mt-4">
            *빨강, 초록으로 표시된 숫자는 일주일 전 대비 증감을 나타내는 수치입니다.
          </div>
        </DashboardCard>
        <DashboardCard
          title={
            <Counter
              increament={() =>
                setWeekView((p) => (p < WEEK_OFFSET ? p + 1 : WEEK_OFFSET))
              }
              decreament={() => setWeekView((p) => p - 1)}
            >
              {dayjs().add(weekView, 'week').day(1).format('M월DD일')}
              {' ~ '}
              {weekView < 0
                ? dayjs()
                    .add(weekView + 1, 'week')
                    .day(0)
                    .format('M월DD일')
                : dayjs().format('M월DD일')}
            </Counter>
          }
          date={dayjs(weekData?.createdAt).format('HH:mm 업데이트')}
        >
          <div
            className="grid grid-flow-col gap-y-4 gap-x-10"
            style={{ gridTemplateRows: 'repeat(3, minmax(0, 1fr))' }}
          >
            <DashboardItem name="WAU">
              <DashboardDiff
                to={weekData?.wau ?? 0}
                from={weekData?.wauBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="회원가입자수">
              <DashboardDiff
                to={weekData?.register ?? 0}
                from={weekData?.registerBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="주문건수">
              <DashboardDiff
                to={weekData?.purchase ?? 0}
                from={weekData?.purchaseBefore ?? 0}
                unit="건"
              />
            </DashboardItem>
            <DashboardItem name="거래액">
              <DashboardDiff
                to={weekData?.purchaseAmount ?? 0}
                from={weekData?.purchaseAmountBefore ?? 0}
              />
            </DashboardItem>
            <DashboardItem name="매출액">
              <DashboardDiff
                to={weekData?.salesAmount ?? 0}
                from={weekData?.salesAmountBefore ?? 0}
              />
            </DashboardItem>
            <DashboardItem name="구매전환율">
              <DashboardDiff
                to={weekData?.purchaseConversionRate ?? 0}
                from={weekData?.purchaseConversionRateBefore ?? 0}
                unit="%"
              />
            </DashboardItem>
          </div>
          <div className="italic text-sm text-gray-400 mt-4">
            *빨강, 초록으로 표시된 숫자는 일주일 전 대비 증감을 나타내는 수치입니다.
          </div>
        </DashboardCard>
        <DashboardCard
          title={
            <Counter
              increament={() =>
                setMonthView((p) => (p < MONTH_OFFSET ? p + 1 : MONTH_OFFSET))
              }
              decreament={() => setMonthView((p) => p - 1)}
            >
              {dayjs().add(monthView, 'month').format('M월')}
            </Counter>
          }
          date={dayjs(monthData?.createdAt).format('HH:mm 업데이트')}
        >
          <div
            className="grid grid-flow-col gap-y-4 gap-x-10"
            style={{ gridTemplateRows: 'repeat(3, minmax(0, 1fr))' }}
          >
            <DashboardItem name="MAU">
              <DashboardDiff
                to={monthData?.mau ?? 0}
                from={monthData?.mauBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="회원가입자수">
              <DashboardDiff
                to={monthData?.register ?? 0}
                from={monthData?.registerBefore ?? 0}
                unit="명"
              />
            </DashboardItem>
            <DashboardItem name="주문건수">
              <DashboardDiff
                to={monthData?.purchase ?? 0}
                from={monthData?.purchaseBefore ?? 0}
                unit="건"
              />
            </DashboardItem>
            <DashboardItem name="거래액">
              <DashboardDiff
                to={monthData?.purchaseAmount ?? 0}
                from={monthData?.purchaseAmountBefore ?? 0}
              />
            </DashboardItem>
            <DashboardItem name="매출액">
              <DashboardDiff
                to={monthData?.salesAmount ?? 0}
                from={monthData?.salesAmountBefore ?? 0}
              />
            </DashboardItem>
            <DashboardItem name="구매전환율">
              <DashboardDiff
                to={monthData?.purchaseConversionRate ?? 0}
                from={monthData?.purchaseConversionRateBefore ?? 0}
                unit="%"
              />
            </DashboardItem>
          </div>
          <div className="italic text-sm text-gray-400 mt-4">
            *빨강, 초록으로 표시된 숫자는 일주일 전 대비 증감을 나타내는 수치입니다.
          </div>
        </DashboardCard>
        <DashboardCard title="DAU 추이 최근 28일" date={dayjs().format('HH:mm 업데이트')}>
          <Line
            options={options}
            data={getLineChartData('DAU 추이 최근 28일', graphData?.dau)}
          />
        </DashboardCard>
        <DashboardCard
          title="거래액 추이 최근 28일"
          date={dayjs(graphData?.createdAt).format('HH:mm 업데이트')}
        >
          <Line
            options={options}
            data={getLineChartData('거래액 추이 최근 28일', graphData?.salesAmount)}
          />
        </DashboardCard>
      </div>
    </div>
  );
};

export default DashboardService;
