import styled from "styled-components";

export const BigBannerTable = styled.div`
display: grid;
grid-template-columns: 100px 100px 100px 100px 3fr 2fr 1fr;
padding:10px 20px;
justify-items: start;
align-items: center;
`;

export const BigBannerTableItem = styled(BigBannerTable)`
border-bottom:1px solid black;
`;

export const PreviewBanner = styled.img`
width: 200px;
margin-right: 10px;
`;

export const ButtonContainer = styled.div`
display: flex;
justify-content: flex-end;
gap: 10px;
margin-bottom: 10px;
`;

export const OuterStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 400px',
    columnGap: '20px',
}