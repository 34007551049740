import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 25px;
`;

export const Title = styled.h5``;

export const BoxWrapper = styled.div`
  width: 100%;
  @media all and (min-width: 1000px) {
    display: flex;
  }
`;
