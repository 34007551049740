import React from 'react';

import { Button } from 'react-bootstrap';
import * as style from './style';

const BannerList = ({ items, goPage }) => {
  return (
    <style.TableWrapper>
      <style.Table>
        <style.Thead>
          <tr>
            <th>서비스</th>
            <th>플랫폼</th>
            <th>성별</th>
            <th>페이지</th>
            <th>설정</th>
          </tr>
        </style.Thead>
        <tbody>
          {items.length > 0 &&
            items.map((item) => (
              <style.Tr key={item.id}>
                <style.Td align={'center'} width={'15%'}>
                  {item.service}
                </style.Td>
                <style.Td align={'center'} width={'15%'}>
                  {item.platform}
                </style.Td>
                <style.Td
                  align={'center'}
                  width={'15%'}
                  color={item.gender === 'M' ? '#0D9AE9' : '#F0898A'}
                >
                  {item.gender === 'M' ? '남성' : '여성'}
                </style.Td>
                <style.Td width={'25%'}>{item.page}</style.Td>
                <style.Td align={'center'} width={'10%'}>
                  <Button
                    style={{ width: '100%' }}
                    onClick={() => goPage(item.platform, item.gender)}
                  >
                    설정
                  </Button>
                </style.Td>
              </style.Tr>
            ))}
        </tbody>
      </style.Table>
    </style.TableWrapper>
  );
};

export default BannerList;
