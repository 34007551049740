import React, { FunctionComponent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ILink } from 'types';

const Tab = styled.span<{ selected: boolean }>`
  font-size: 16px;
  font-weight: 600;
  color: ${({ selected }) => (selected ? `#000` : `#767676`)};
`;

export interface Props {
  options: ILink[];
}
interface State {}

const MenuTabs: FunctionComponent<Props> = ({ options }) => {
  const { pathname, search } = useLocation();
  return (
    <div
      style={{ backgroundColor: '#fff', padding: '15px 30px', display: 'flex', gap: 20 }}
    >
      {options.map((item, key) => (
        <Link to={item.to} style={{ textDecoration: 'none' }}>
          <Tab selected={item.to === pathname + search} key={key}>
            {item.name}
          </Tab>
        </Link>
      ))}
    </div>
  );
};

export default MenuTabs;
