import React, { useState, useEffect, useContext } from 'react';
import {
  Loading,
  SearchButtonGroup,
  TopBottomButtons,
  CategoryMapSearchOptionTable,
  CategoryMapTable,
} from 'components';
import { LoadingContext } from 'context';
import * as style from './style';
import { fetchingAPI } from '../../api/fetchingAPI';
import initialState from './initState';
import { trimParams } from '../ProductList/utils';
import Pagination from '../ManageCatalog/CatalogSearchOptionTable/Pagination';
import { Alert } from 'antd';

export default () => {
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [mappings, setMappings] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchOptionTableState, setSearchOptionTableState] = useState(initialState);
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const fetchItems = async (page = 1, callback = () => {}) => {
    handleIsLoading(true);

    try {
      const { data } = await fetchingAPI.get('/category/map', {
        params: { ...searchOptionTableState, page },
      });
      setMappings(data.data);
      setTotalCount(data.count);
      setCurrentPage(page);
    } catch (err) {
      alert(`error in fetchItems(), /productList, ${err.message}`);
    } finally {
      handleIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    const {
      data: { data },
    } = await fetchingAPI.get('/category');
    const categoryMap = Object.fromEntries(
      data.map((category) => [`${category.id}`, category])
    );

    const getParentName = (category) => {
      if (!category.parentId) {
        return '';
      }
      const parent = categoryMap[`${category.parentId}`];
      return `${getParentName(parent)}${parent.name} > `;
    };

    setCategories(
      data
        .filter((data) => data.depth > 1)
        .map((data) => ({ id: data.id, name: getParentName(data) + data.name }))
    );
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <style.Outer>
      {isLoading && <Loading />}
      <TopBottomButtons />
      <style.ShadowContainer>
        <Alert
          message={
            <>
              <a href="https://admin.fetching.co.kr/products/category?tab=1">새 어드민</a>
              에서 개선된 카테고리 매핑을 사용하세요.
            </>
          }
          type="warning"
          closable
        />
        <style.PageTitle>샵별 카테고리 매칭</style.PageTitle>
        <CategoryMapSearchOptionTable
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
          categories={categories}
        />
        <SearchButtonGroup
          fetchItems={fetchItems}
          setSearchOptionTableState={setSearchOptionTableState}
          initialState={initialState}
          setItems={setMappings}
        />
      </style.ShadowContainer>
      <style.ShadowContainer>
        <CategoryMapTable
          maps={mappings}
          categoryMap={Object.fromEntries(
            categories.map((category) => [category.id, category.name])
          )}
          totalCount={totalCount}
          fetchItems={fetchItems}
          trimParams={trimParams}
          currentPage={currentPage}
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
        <Pagination fetchItems={fetchItems} currentPage={currentPage} />
      </style.ShadowContainer>
    </style.Outer>
  );
};
