import React from 'react';
import * as style from './style';

export default function DuplicateOption({
  title,
  items,
  onChange,
  duplicateOption,
  handleAllChecked,
  height,
}) {
  const getParentCategory = (target) =>
    items.find((item) => item.id === target);

  return (
    <style.ItemWrapper>
      {title === '브랜드' && (
        <style.Inner height={height}>
          <style.ItemTitle>{title}</style.ItemTitle>
          <style.Item>
            <style.ItemText>전체선택</style.ItemText>
            <style.CheckBox type="checkbox" onChange={handleAllChecked} />
          </style.Item>
          {items.map((item) => {
            const isChecked = duplicateOption.brandIdList.find(
              (id) => id === item.brand_id,
            );
            return (
              <style.Item key={item.brand_id}>
                <style.CheckBox
                  type="checkbox"
                  checked={Boolean(isChecked)}
                  value={item.brand_id}
                  onChange={onChange}
                />
                <style.ItemText>{item.brand_name}</style.ItemText>
              </style.Item>
            );
          })}
        </style.Inner>
      )}
      {title === '편집샵' && (
        <style.Inner height={height}>
          <style.ItemTitle>{title}</style.ItemTitle>
          <style.Item>
            <style.ItemText>전체선택</style.ItemText>
            <style.CheckBox type="checkbox" onChange={handleAllChecked} />
          </style.Item>
          {items.map((item) => {
            const isChecked = duplicateOption.shopIdList.find(
              (id) => id === item.id,
            );
            return (
              <style.Item key={item.id}>
                <style.CheckBox
                  type="checkbox"
                  checked={Boolean(isChecked)}
                  value={item.id}
                  onChange={onChange}
                />
                <style.ItemText>
                  {item.krName} ({item.currency})
                </style.ItemText>
              </style.Item>
            );
          })}
        </style.Inner>
      )}
      {title === '프로모션' && (
        <style.Inner>
          <style.ItemTitle>{title}</style.ItemTitle>
          <style.Item>
            <style.ItemText>전체선택</style.ItemText>
            <style.CheckBox type="checkbox" onChange={handleAllChecked} />
          </style.Item>
          {items.map((item) => {
            const isChecked = duplicateOption.promotionIdList.find(
              (id) => id === item.id,
            );
            return (
              <style.Item key={item.id}>
                <style.CheckBox
                  type="checkbox"
                  checked={Boolean(isChecked)}
                  value={item.id}
                  onChange={(e) => onChange(e, item)}
                />
                <style.ItemText>
                  {`${item.shopName} - ${item.name} - ${item.isAppliedWith}- ${item?.code}`}
                </style.ItemText>
              </style.Item>
            );
          })}
        </style.Inner>
      )}
      {title === '카테고리' && (
        <style.Inner height={height}>
          <style.ItemTitle>{title}</style.ItemTitle>
          <style.Item>
            <style.ItemText>전체선택</style.ItemText>
            <style.CheckBox type="checkbox" onChange={handleAllChecked} />
          </style.Item>
          {items.map((item) => {
            const isChecked = duplicateOption.categoryIdList.find(
              (id) => id === item.id,
            );
            const parent = getParentCategory(item.parent);

            let result;
            if (parent) {
              result = getParentCategory(parent.parent);
            }

            const pathname = parent
              ? ` ${result ? `${result.name} > ` : ' '} ${parent.name} > ${
                  item.name
                }`
              : item.name;

            return (
              <style.Item key={item.id}>
                <style.CheckBox
                  type="checkbox"
                  checked={Boolean(isChecked)}
                  value={item.id}
                  onChange={(e) => onChange(e, pathname)}
                />
                <style.ItemText>{pathname}</style.ItemText>
              </style.Item>
            );
          })}
        </style.Inner>
      )}
      {title === '매핑 카테고리' && (
        <style.Inner height={height}>
          <style.ItemTitle>{title}</style.ItemTitle>
          <style.Item>
            <style.ItemText>전체선택</style.ItemText>
            <style.CheckBox type="checkbox" onChange={handleAllChecked} />
          </style.Item>
          {items.map((item) => {
            const isChecked = duplicateOption.categoryIdList.find(
              (id) => id === item.id,
            );
            const parent = getParentCategory(item.parent);

            let result;
            if (parent) {
              result = getParentCategory(parent.parent);
            }

            const pathname = parent
              ? ` ${result ? `${result.name} > ` : ' '} ${parent.name} > ${
                  item.name
                }`
              : item.name;

            return item.depth > 1 ? (
              <style.Item key={item.id}>
                <style.CheckBox
                  type="checkbox"
                  checked={Boolean(isChecked)}
                  value={item.id}
                  onChange={(e) => onChange(e, pathname)}
                />
                <style.ItemText>{pathname}</style.ItemText>
              </style.Item>
            ) : null;
          })}
        </style.Inner>
      )}
    </style.ItemWrapper>
  );
}
