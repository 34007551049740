import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addSelectCategoryAction,
  removeSelectCategoryAction,
} from 'actions/action';

const SubCategory = ({ subItem }) => {
  const dispatch = useDispatch();
  const categoryIds = useSelector((state) => state.selectCategory.categoryIds);

  const [isChecked, setIsChecked] = useState('');

  useEffect(() => {
    const result = categoryIds.indexOf(String(subItem.idx));
    if (result === -1) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  }, [categoryIds, subItem.idx]);

  return (
    <div style={{ marginLeft: '40px' }}>
      <input
        type="checkbox"
        value={subItem.idx}
        checked={isChecked}
        onChange={(e) => {
          if (e.target.checked) {
            dispatch(addSelectCategoryAction(e.target.value));
          } else {
            dispatch(removeSelectCategoryAction(e.target.value));
          }
        }}
      />
      {subItem.fetching_category_name}
    </div>
  );
};

export default SubCategory;
