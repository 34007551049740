import React from 'react';
import * as style from './style';

const HeaderBanner = ({ product }) => (
  <style.Inner>
    <div>
      <style.HeaderItem>
        <style.ItemText>페칭코드</style.ItemText>
        <style.ItemText>{product?.fetchingCode}</style.ItemText>
      </style.HeaderItem>
      <style.HeaderItem>
        <style.ItemText>편집샵 코드</style.ItemText>
        <style.ItemText>{product?.shopCode}</style.ItemText>
      </style.HeaderItem>
      <style.HeaderItem>
        <style.ItemText>카페24 코드</style.ItemText>
        <style.ItemText>{product?.cafe24Code}</style.ItemText>
      </style.HeaderItem>
    </div>
    <div>
      <style.HeaderItem>
        <style.ItemText>업데이트 상태</style.ItemText>
        <style.ItemText>
          {product?.dbUpdatedAt ? (
            new Date(
              new Date(product.dbUpdatedAt).getTime() + 60 * 60 * 24 * 1000,
            ) > new Date() ? (
              <div>정상 ✅</div>
            ) : (
              <div>24시간 업데이트 기준 ❌</div>
            )
          ) : null}
        </style.ItemText>
      </style.HeaderItem>
      <style.HeaderItem>
        <style.ItemText>최종 DB 업데이트</style.ItemText>
        <style.ItemText>
          {product?.dbUpdatedAt && (
            <div>
              {product.dbUpdatedAt.slice(0, 10)}{' '}
              {product.dbUpdatedAt.slice(11, 19)}
            </div>
          )}
        </style.ItemText>
      </style.HeaderItem>
      <style.HeaderItem>
        <style.ItemText>최종카페24 업데이트</style.ItemText>
        <style.ItemText>
          {product?.cafe24UpdatedAt && (
            <div>
              {product.cafe24UpdatedAt.slice(0, 10)}{' '}
              {product.cafe24UpdatedAt.slice(11, 19)}
            </div>
          )}
        </style.ItemText>
      </style.HeaderItem>
    </div>
  </style.Inner>
);

export default HeaderBanner;
