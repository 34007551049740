import React from 'react';
import fetchingAPI from 'api/fetchingAPI';
import * as S from './AddPromotionStyle';
import { initialInfoState } from './initialState';
import { toast } from 'react-toastify';

export const getISOString = (yyyy_mm_dd) => new Date(yyyy_mm_dd).toISOString();

export const getInputDateString = (ISOString) => {
  const date = new Date(ISOString);
  return (
    date.getUTCFullYear() +
    '-' +
    (date.getUTCMonth() + 1 >= 10
      ? date.getUTCMonth() + 1
      : '0' + (date.getUTCMonth() + 1)) +
    '-' +
    (date.getUTCDate() >= 10 ? date.getUTCDate() : '0' + date.getUTCDate())
  );
};

export const nullCheck = (basicInfo, discountInfo, adminInfo, rules) => {
  return (
    basicInfo.priceRuleId !== null &&
    basicInfo.name !== null &&
    basicInfo.description !== null &&
    (discountInfo.isFetchingApplicable === false
      ? discountInfo.isUserApplicable === true
      : true) &&
    discountInfo.type !== null &&
    adminInfo.assigneeId !== null &&
    adminInfo.applyStatus !== null &&
    (['DISCOUNT_WITH_RATE', 'DISCOUNT_WITH_CONST'].includes(discountInfo.type)
      ? rules.length > 0
      : true) &&
    !(
      ['DISCOUNT_WITH_RATE', 'DISCOUNT_WITH_CONST'].includes(discountInfo.type) &&
      (basicInfo.urlMen === null ||
        basicInfo.urlMen === '' ||
        basicInfo.urlWomen === null ||
        basicInfo.urlWomen === '') &&
      rules.length > rules.filter(({ code }) => code !== null).length
    )
  );
};

export const getPayload = (basicInfo, discountInfo, adminInfo, manageBanner, rules) => {
  const payload = {
    ...basicInfo,
    shopId: basicInfo.priceRuleId,
    ...discountInfo,
    ...adminInfo,
    ...manageBanner,
  };

  delete payload.period;
  payload.startedAt && (payload.startedAt = getISOString(payload.startedAt));
  payload.endedAt && (payload.endedAt = getISOString(payload.endedAt));

  discountInfo.type === 'DISCOUNT_WITH_RATE' &&
    (payload.rules = rules.map((v) => ({ ...v, typeValue: v.typeValue / 100 })));
  discountInfo.type === 'DISCOUNT_WITH_CONST' && (payload.rules = rules);
  discountInfo.type === 'FREE_DELIVERY' && (payload.rules = rules);

  return payload;
};

export const MakeTable = ({ data, tableRef }) => (
  <>
    <S.H4 ref={tableRef}>{data.title}</S.H4>
    <S.Table>
      <S.Tbody>
        {data.content.map((row) => (
          <MakeRow row={row} key={row.id || row.left} />
        ))}
      </S.Tbody>
    </S.Table>
  </>
);

export const MakeRow = ({ row }) => (
  <S.Tr>
    <S.LeftTd>{row.left}</S.LeftTd>
    <S.Td>{row.right}</S.Td>
  </S.Tr>
);

export const addPromotion = async (
  basicInfo,
  discountInfo,
  adminInfo,
  manageBanner,
  rules,
  applyWithDiscountedPrice
) => {
  if (nullCheck(basicInfo, discountInfo, adminInfo, rules)) {
    try {
      await fetchingAPI.postPromotion({
        ...getPayload(basicInfo, discountInfo, adminInfo, manageBanner, rules),
        applyWithDiscountedPrice,
      });
      window.alert('프로모션이 생성되었습니다.');
      window.close();
    } catch (error) {
      console.dir(error);
      toast.error(`error in postPromotion, ${error.message}`);
    }
  } else {
    toast.warn(
      "필수항목(*)을 빠짐없이 입력해주세요. \n\n- 할인 타입이 '% 할인' 또는 '고정가격 할인'인 경우, \n   남여링크 또는 할인코드를 입력해야 합니다."
    );
  }
};

export const modifyPromotion = async (
  id,
  basicInfo,
  discountInfo,
  adminInfo,
  manageBanner,
  rules,
  applyWithDiscountedPrice
) => {
  if (nullCheck(basicInfo, discountInfo, adminInfo, rules)) {
    try {
      const oldRules = rules.filter(({ id }) =>
        getRules()
          .map(({ id }) => id)
          .includes(id)
      );
      const newRules = rules.filter(
        ({ id }) =>
          !getRules()
            .map(({ id }) => id)
            .includes(id)
      );
      const deleteRules = getRules().filter(
        ({ id }) => !rules.map(({ id }) => id).includes(id)
      );
      await Promise.all(
        deleteRules.map((rule) => fetchingAPI.deletePromotionRule(id, rule.id))
      );
      await fetchingAPI.putPromotion(id, {
        ...getPayload(basicInfo, discountInfo, adminInfo, manageBanner, oldRules),
        applyWithDiscountedPrice,
      });
      await Promise.all(newRules.map((rule) => fetchingAPI.postPromotionRule(id, rule)));
      return true;
    } catch (error) {
      toast.error(`error in modifyPromotion, ${error.message}`);
      return false;
    }
  } else {
    toast.warn(
      "필수항목(*)을 빠짐없이 입력해주세요. \n\n- 할인 타입이 '% 할인' 또는 '고정가격 할인'인 경우, \n   남여링크 또는 할인코드를 입력해야 합니다."
    );
    return false;
  }
};

export const getPromotionInfo = async (id) => {
  try {
    const { data } = await fetchingAPI.getPromotion(id);
    storeRules(data.rules);
    return distributeData(trimFetchedData(data));
  } catch (error) {
    toast.error(`err in getPromotionInfo, ${error.message}`);
  }
};

const distributeData = (data) => ({
  basicInfo: mapValue(data, initialInfoState.basic),
  discountInfo: mapValue(data, initialInfoState.discount.state),
  adminInfo: mapValue(data, initialInfoState.admin),
  manageBanner: mapValue(data, initialInfoState.banner),
  rules: data.rules,
  salesInfo: mapValue(data, initialInfoState.sales),
  applyWithDiscountedPrice: data.applyWithDiscountedPrice,
});

const mapValue = (from, to) => {
  const copy = { ...to };
  Object.keys(to).forEach((k) => (copy[k] = from[k]));
  return copy;
};

const trimFetchedData = (data) => {
  const copy = { ...data };
  copy.period = data.startedAt || data.endedAt ? true : false;
  data.startedAt && (copy.startedAt = getInputDateString(data.startedAt));
  data.endedAt && (copy.endedAt = getInputDateString(data.endedAt));
  data.type === 'DISCOUNT_WITH_RATE' &&
    (copy.rules = data.rules.map((rule) => ({
      ...rule,
      typeValue: rule.typeValue * 100,
    })));
  copy.isVip = Boolean(data.isVip);
  copy.canBeOverlapped = Boolean(data.canBeOverlapped);
  copy.canBeOverlappedWithFreeDelivery = Boolean(data.canBeOverlappedWithFreeDelivery);
  copy.canBeOverlappedWithVip = Boolean(data.canBeOverlappedWithVip);
  copy.canBeOverlappedWithVipFreeDelivery = Boolean(
    data.canBeOverlappedWithVipFreeDelivery
  );
  !(copy.rules?.filter((v) => v).length > 0) && (copy.rules = []);
  copy.rules &&
    copy.rules.forEach(({ automation }, i) => {
      (automation?.minDiscountedPrice || automation?.maxDiscountedPrice) &&
        (copy.rules[i].automation.priceMode = 'basicPrice');
      (automation?.minTotalPrice || automation?.maxTotalPrice) &&
        (copy.rules[i].automation.priceMode = 'shopPrice');
      (automation?.minFinalPrice || automation?.maxFinalPrice) &&
        (copy.rules[i].automation.priceMode = 'fetchingPrice');
    });
  return copy;
};

const storeRules = (rules) => {
  window.sessionStorage.setItem('rules', JSON.stringify(rules ?? []));
};

const getRules = () => {
  const json = window.sessionStorage.getItem('rules');
  return JSON.parse(json);
};

export const mapDiscountType = (code) =>
  ({
    DISCOUNT_WITH_RATE: '% 할인',
    DISCOUNT_WITH_CONST: '고정가격 할인',
    FREE_DELIVERY: '무료배송',
  }[code]);
