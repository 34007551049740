import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px;
  display: ${(props) => props.display};
`;

export const TableHeader = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  ${(props) => props.BC && `background-color: ${props.BC};`}
`;

export const TableItem = styled.div`
  width: calc(100% / 4);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  text-align: center;
  padding: 5px;
  ${(props) => props.FW && `font-weight: ${props.FW};`}
`;
