import {
  Button,
  Card,
  GridTable,
  MenuTitle,
  NumberStat,
  OpenLink,
  OrderCount,
  OrderListTooltip,
  Radio,
  ReInput,
  RePagination,
  Select,
  Tabs,
  Textarea,
} from 'components';
import dayjs from 'dayjs';
import React, { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  enumToList,
  ORDER_DATE_LIST,
  ORDER_RETURN_STATUS,
  ORDER_SEARCH_TYPE,
  ORDER_SORT,
  PATH,
  PAY_METHOD,
  request,
  useOrderTable,
  USER_TYPE,
} from 'services';
import { orderCountState } from 'store';
import { priceFormat } from 'utils';
import classnames from 'classnames';

interface State {}

const OrdersRefundPage = () => {
  const { push } = useHistory();
  const {
    searchText,
    searchType,
    setOrderState,
    onDateClick,
    onChangeOrder,
    minOrderDate,
    maxOrderDate,
    page,
    total,
    limit,
    list,
    orderPath,
    userType,
    isLoading,
    payMethod,
    orderStatus,
    sort,
    resetOrderState,
    memoList,
    isMemoLoading,
    getMemo,
  } = useOrderTable();
  const orderCount = useRecoilValue(orderCountState);

  const get = async () => {
    setOrderState({ isLoading: true });
    let params: any = {
      limit,
      page,
      orderBy: 'ORDER_DATE',
      isAscending: sort === 'ORDER_DATE_ASC',
    };
    if (!!searchType) params.searchType = searchType;
    if (!!searchText) params.searchText = searchText;
    if (!!orderPath) params.orderPath = orderPath;
    if (!!orderStatus) params.orderStatus = orderStatus;
    if (!!payMethod) params.payMethod = payMethod;
    if (!!userType) params.userType = userType;
    if (!!minOrderDate)
      params.minOrderDate = `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
    if (!!maxOrderDate)
      params.maxOrderDate = `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
    try {
      const { data, count } = await request.get<any, { data: any[]; count: number }>(
        '/commerce/order/order-refund',
        {
          params,
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setOrderState({ total: count, list: data, isLoading: false });
    } catch (err) {
      setOrderState({ isLoading: false });
      console.dir(err);
    }
  };

  const onTabChange = (tab: number) => {
    if (tab === 0) push('/orders/return');
    else if (tab === 1) push('/orders/exchange');
    else if (tab === 3) push('/orders/customs-refund');
  };

  useEffect(() => {
    get();
  }, [
    page,
    minOrderDate,
    maxOrderDate,
    limit,
    orderPath,
    userType,
    payMethod,
    orderStatus,
    sort,
  ]);
  return (
    <OrderCount>
      <MenuTitle>
        <Tabs
          onTabChange={onTabChange}
          value={2}
          options={[
            `반품 관리 (${orderCount.return})`,
            `교환 관리 (${orderCount.exchange})`,
            `환불 관리 (${orderCount.refund})`,
            '관세 환급 관리',
          ]}
        />
      </MenuTitle>
      <div className="wrapper">
        <div className="wrapper-title">환불 관리</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Textarea.Autosize
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                    rows={1}
                    className="w-64"
                    placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문경로',
              renderItem: () => (
                <Radio
                  options={enumToList(PATH)}
                  value={orderPath}
                  onChange={(orderPath) => setOrderState({ orderPath })}
                  id="orderPath"
                  isInitialExisted
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '결제수단',
              renderItem: () => (
                <Radio
                  value={payMethod}
                  isInitialExisted
                  onChange={(payMethod) => setOrderState({ payMethod })}
                  options={enumToList(PAY_METHOD)}
                  id="payMethod"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Radio
                  options={enumToList(USER_TYPE)}
                  value={userType}
                  isInitialExisted
                  onChange={(userType) => setOrderState({ userType })}
                  id="userType"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '환불 상태',
              renderItem: () => (
                <Radio
                  isInitialExisted
                  options={enumToList(ORDER_RETURN_STATUS)}
                  value={orderStatus}
                  onChange={(orderStatus) => setOrderState({ orderStatus })}
                  id="orderStatus"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-7 flex justify-center gap-3">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          isLoading={isLoading}
          columns={[
            '환불번호 / 상품별주문번호',
            '환불접수일',
            '상태 (처리일)',
            '주문자',
            '총 환불액 (적립금)',
            '판매처 (국가 / 배송방식)',
            '상품 / 옵션명',
            '총 수량',
            '세금환불금액 (상태)',
            '상품실환불액',
            '반품 수수료',
            '관리자기능',
          ]}
          noSelection
          header={
            <div className="flex items-center justify-end">
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          maxHeight=""
          list={list}
          renderItem={(order, key) => (
            <div key={key}>
              <div aria-label="환불번호 / 상품별주문번호">
                <div className="mb-2">
                  <OpenLink url={`/orders/refund/${order.orderRefundNumber}`}>
                    {order.orderRefundNumber}
                  </OpenLink>
                </div>
                {order.shopList.map((shop, shopIndex) => (
                  <Fragment key={shopIndex}>
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        <OpenLink
                          url={`/order/${order.fetchingOrderNumber}?status=4&tab=1`}
                        >
                          {item.itemOrderNumber}
                        </OpenLink>
                      </div>
                    ))}
                  </Fragment>
                ))}
              </div>
              <div aria-label="환불접수일">
                {dayjs(order.createdAt).format('YYYY.MM.DD HH:mm:ss')}
              </div>
              <div aria-label="상태 (처리일)">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                      key={shopIndex}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <div className="font-medium text-black">
                            {ORDER_RETURN_STATUS[item.refundStatus]}
                          </div>
                          {!!item.statusUpdatedAt && (
                            <div className="text-gray-400">
                              ({dayjs(item.statusUpdatedAt).format('YYYY.MM.DD HH:mm:ss')}
                              )
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="주문자">
                <span>{order.userName}</span>
                <div>{USER_TYPE[order.userType]}</div>
              </div>
              <div aria-label="총 환불액 (적립금)">
                <NumberStat value={order.refundAmount} />
                <div>({priceFormat(order.refundPoint)})</div>
              </div>
              <div aria-label="판매처 (국가 / 배송방식)">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div key={shopIndex} style={{ flex: shop.itemList.length }}>
                      {shop.shopName} ({shop.shopCountry} /{' '}
                      <span
                        className={classnames({
                          'text-fassto': shop.deliveryMethodName === '파스토',
                          'text-eldex': shop.deliveryMethodName === '엘덱스',
                        })}
                      >
                        {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                      </span>
                      )
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="상품 / 옵션명">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                      key={shopIndex}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <OpenLink
                            url={`/product/productDetail/v2/${Number(
                              item.itemId
                            )}?mode=update`}
                          >
                            {item.itemName}
                          </OpenLink>
                          <div>{item.sizeName}</div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="총 수량">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                      key={shopIndex}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          {item.quantity}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="세금환불금액 (상태)">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                      key={shopIndex}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <div>
                            <span>관부가세 : {priceFormat(item.vat + item.duty)}원</span>
                          </div>
                          <div>
                            <span>특소세 : {priceFormat(item.specialTax)}원</span>
                          </div>
                          <div>
                            <span>교육세 : {priceFormat(item.educationTax)}원</span>
                          </div>
                          <div className="mt-2">
                            {item.needTaxRefund ? '환급필요' : '환급불필요'}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="상품실환금액">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                      key={shopIndex}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <NumberStat value={item.refundAmount} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="반품 수수료">
                <div className="flex flex-col h-full space-y-4">
                  {order.shopList.map((shop, shopIndex) => (
                    <div
                      className="flex flex-col h-full space-y-4"
                      style={{ flex: shop.itemList.length }}
                      key={shopIndex}
                    >
                      {shop.itemList.map((item, itemIndex) => (
                        <div key={itemIndex} className="flex-1">
                          <NumberStat value={item.returnFee} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="관리자기능">
                <div className="flex flex-col h-full gap-1 items-center">
                  <Button
                    size="xs"
                    theme="primary"
                    onClick={() =>
                      window.open(`/orders/refund/${order.orderRefundNumber}`)
                    }
                    className="w-full"
                  >
                    관리
                  </Button>
                  <OrderListTooltip
                    isLoading={isMemoLoading}
                    hasMemo={order.hasMemo}
                    list={memoList}
                    fetchingOrderNumber={order.fetchingOrderNumber}
                    onMouseLeave={() =>
                      setOrderState({ memoList: [], isMemoLoading: false })
                    }
                    onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                  />
                </div>
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
    </OrderCount>
  );
};

export default OrdersRefundPage;
