import {
  Button,
  Card,
  GridTable,
  InputNumber,
  ReInput,
  RePagination,
  Select,
  Tabs,
} from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';

interface IPoint {
  adminId: number;
  auId: {
    APPLE: string;
    KAKAO: string;
    NAVER: string;
  };
  createdAt: string;
  deletedAt: string;
  description: string;
  expireAt: string;
  userEnglishFirstName: string;
  userEnglishLastName: string;
  idx: number;
  orderId: number;
  pointAdd: number;
  pointDeduct: number;
  pointRemain: number;
  userType: 'USER' | 'VIP';
  startAt: string;
  typeName: string;
  typeDescription: string;
}

export interface Props {
  available: number;
  unavailable: number;
  used: number;
  total: number;
  onRefresh: () => void;
}
interface State {
  tab: number;
  list: IPoint[];
  count: number;
  point: string;
  description: string;
  pointType: 'plus' | 'minus';
  columns: string[];
  page: number;
  pageSize: number;
  isDeleting: boolean;
  pointIdx: number;
  isSubmitting: boolean;
}

const UserReserve: FunctionComponent<Props> = ({
  available,
  unavailable,
  used,
  total,
  onRefresh,
}) => {
  const [
    {
      tab,
      list,
      count,
      point,
      description,
      pointType,
      page,
      pageSize,
      isSubmitting,
      isDeleting,
      pointIdx,
    },
    setState,
    onChange,
  ] = useObject<State>({
    tab: 0,
    list: [],
    count: 0,
    point: '',
    description: '',
    pointType: 'plus',
    columns: [
      '상세 내용',
      '적립금 유형',
      '일자',
      '적립(+)',
      '차감(-)',
      '잔액',
      '관련주문',
      '삭제',
    ],
    page: 1,
    pageSize: 10,
    isDeleting: false,
    pointIdx: 0,
    isSubmitting: false,
  });
  const { idx } = useParams<{ idx: string }>();

  const get = async () => {
    try {
      const data = await request.get<any, { count: number; data: IPoint[] }>(
        '/commerce/point',
        {
          params: {
            available: !tab,
            limit: pageSize,
            page,
            userId: idx,
          },
        }
      );
      setState({ list: data.data || [], count: data.count });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteItem = async (idx: number) => {
    if (!window.confirm('삭제하시겠습니까?')) return;

    setState({ isDeleting: true, pointIdx: idx });
    try {
      await request.delete(`/commerce/point/${idx}`);
      get();
      onRefresh();
      toast.success('삭제되었습니다.');
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isDeleting: false, pointIdx: 0 });
    }
  };
  const onPlusMinus = async () => {
    if (!point) {
      toast.info('적립금을 입력해 주세요.');
      return;
    }

    let data: any = {
      type: 'DIRECT_ADD',
      userId: Number(idx),
      description,
    };
    if (pointType === 'plus') data.pointAdd = Number(point);
    else data.pointDeduct = Number(point);
    setState({ isSubmitting: true });
    try {
      await request.post('/commerce/point', data);
      toast.success('생성되었습니다.');
      setState({ point: '', description: '' });
      get();
      onRefresh();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isSubmitting: false });
    }
  };
  const columns = useMemo(() => {
    if (tab === 0)
      return [
        '상세 내용',
        '적립금 유형',
        '일자',
        '적립(+)',
        '차감(-)',
        '잔액',
        '관련주문',
        '삭제',
      ];
    else if (tab === 1)
      return ['관련 주문', '미가용 적립금', '사용가능예정일', '날짜', '내용'];
    else return [];
  }, [tab]);

  useEffect(() => {
    get();
  }, [tab, page, pageSize]);
  return (
    <div>
      <div className="p-4">
        <Card
          title="적립금 정보"
          items={[
            {
              name: '총 적립금',
              renderItem: () => `${total}원`,
            },
            {
              name: '사용된 적립금',
              renderItem: () => `${used}원`,
            },
            {
              name: '사용가능 적립금',
              renderItem: () => <span className="font-bold">{available}원</span>,
            },
            {
              name: '미가용 적립금',
              renderItem: () => `${unavailable}원`,
            },
          ]}
        />
        <Card title="적립금 지급/차감">
          <div className="flex gap-2">
            <div className="flex-1">
              <GridTable
                columns={['증감여부', '적립금', '내용']}
                list={[{}]}
                noSelection
                renderItem={(item, key) => (
                  <div key={key}>
                    <div>
                      <Select
                        size="xs"
                        value={pointType}
                        name="pointType"
                        onChange={onChange}
                      >
                        <option value="plus">(+)적립금 증액</option>
                        <option value="minus">(-)적립금 감액</option>
                      </Select>
                    </div>
                    <div>
                      <InputNumber
                        value={point}
                        name="point"
                        size="xs"
                        className="w-full"
                        isNumericString
                        onChange={onChange}
                      />
                    </div>
                    <div>
                      <ReInput
                        value={description}
                        className="w-full"
                        name="description"
                        onChange={onChange}
                        size="xs"
                      />
                    </div>
                  </div>
                )}
              />
            </div>
            <Button isLoading={isSubmitting} size="sm" onClick={onPlusMinus}>
              적립금 지급/차감
            </Button>
          </div>
        </Card>
        <div className="mb-7">
          <Tabs
            value={tab}
            onTabChange={(tab) => setState({ tab, page: 1 })}
            options={['적립금 내역', '미가용 적립금 내역']}
          />
        </div>
        <GridTable
          columns={columns}
          list={list}
          noSelection
          renderItem={(item, key) => (
            <div key={key}>
              {tab === 0 && (
                <>
                  <div>{item.typeDescription}</div>
                  <div>{item.typeName}</div>
                  <div>{dayjs(item.createdAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
                  <div>+ {item.pointAdd}원</div>
                  <div>- {item.pointDeduct}원</div>
                  <div>{item.pointRemain}원</div>
                  <div>{item.orderId}</div>
                  <div>
                    {item.deletedAt ? (
                      <div>
                        <div>삭제됨</div>
                        <div>{dayjs(item.deletedAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
                      </div>
                    ) : (
                      <Button
                        size="xs"
                        isLoading={isDeleting && item.idx === pointIdx}
                        onClick={() => deleteItem(item.idx)}
                      >
                        삭제
                      </Button>
                    )}
                  </div>
                </>
              )}
              {tab === 1 && (
                <>
                  <div>{item.orderId}</div>
                  <div>{item.pointRemain}</div>
                  <div>{item.startAt}</div>
                  <div>{item.createdAt}</div>
                  <div>{item.description}</div>
                </>
              )}
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{count}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={count}
            pageSize={pageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default UserReserve;
