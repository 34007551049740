import React, { useState } from 'react';
import * as style from './style';

/////date input 용 문자열 생성
const formatDate = (date) => {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
};
///////

const PriorityTd = ({ searchOptionTableState, setSearchOptionTableState }) => {
  const { priorityList, maxPrioritySetAt, minPrioritySetAt } =
    searchOptionTableState;
  const setPriority = (priority) => {
    const loc = priorityList.indexOf(priority);
    let newPriorityList = [];
    if (loc === -1) {
      newPriorityList = [...priorityList, priority];
    } else {
      newPriorityList = [
        ...priorityList.slice(0, loc),
        ...priorityList.slice(loc + 1),
      ];
    }
    setSearchOptionTableState({
      ...searchOptionTableState,
      priorityList: newPriorityList,
    });
  };

  const [inputText, setInputText] = useState(false);

  //////input date 처리
  const handleDateInput = (e, target) => {
    const date = new Date(e.target.value);
    const ISOString = date.toISOString();
    setSearchOptionTableState({
      ...searchOptionTableState,
      [target]: ISOString,
    });
  };
  /////////

  return (
    <style.Container>
      <style.Label>
        변경일
        <style.InputDate
          onChange={(e) => handleDateInput(e, 'minPrioritySetAt')}
          value={minPrioritySetAt ? formatDate(minPrioritySetAt) : '0000-00-00'}
        />
        ~
        <style.InputDate
          onChange={(e) => handleDateInput(e, 'maxPrioritySetAt')}
          value={maxPrioritySetAt ? formatDate(maxPrioritySetAt) : '0000-00-00'}
        />
      </style.Label>
      {[
        [0, '신상'],
        [1, '팔림'],
        [2, '커뮤니티'],
        [3, '페칭인기 핫딜'],
        [4, '페칭인기'],
        [5, 'MD픽'],
        [6, '자동픽 성과 O'],
        [7, '자동픽 성과 ing'],
        [8, '자동픽 성과 X'],
        [9, '수동픽 성과 X'],
      ].map(([v, description = null]) => (
        <style.Label key={v}>
          <style.Checkbox
            checked={priorityList.includes(v)}
            onClick={() => {
              setPriority(v);
            }}
            onChange={() => {}}
          />
          {v}
          {description ? ` - ${description}` : null}
        </style.Label>
      ))}
    </style.Container>
  );
};

export default PriorityTd;
