import { CheckIcon } from '@heroicons/react/solid';
import { Button, GridTable, ReInput, ReModal, Select } from 'components';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';
import { IItem, IModal } from 'types';
import { priceFormat } from 'utils';

export interface Props extends IModal {
  onSelect: (list: IItem[]) => void;
}
interface State {
  isSearching: boolean;
  list: IItem[];
  searchType: string;
  searchKey: string;
  selectedIdx: number[];
  isLoading: boolean;
}

const SearchItemModal: FunctionComponent<Props> = ({ isOpen, onClose, onSelect }) => {
  if (!isOpen) return null;

  const [
    { isSearching, list, searchKey, searchType, selectedIdx, isLoading },
    setState,
    onChange,
  ] = useObject<State>({
    isSearching: false,
    list: [],
    searchType: 'FETCHING_CODE',
    searchKey: '',
    selectedIdx: [],
    isLoading: false,
  });
  const get = async () => {
    setState({ isSearching: true });
    let params: any = {
      limit: 5,
      isExtended: true,
    };
    if (!!searchType) params.searchType = searchType;
    if (!!searchKey) params.searchKey = searchKey;
    try {
      const { data } = await request.get<any, { data: IItem[] }>('/item', { params });
      setState({ list: data });
      if (!data.length) toast.info('올바른 코드를 입력해주세요.');
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isSearching: false });
    }
  };
  const onComplete = async () => {
    const filter = list.filter(({ id }) => selectedIdx.indexOf(id) !== -1);

    if (!filter.length) {
      toast.info('최소 1개의 상품을 선택해주세요.');
      return;
    }

    setState({ isLoading: true });
    try {
      const promises = await Promise.all(
        filter.map((item) =>
          request.get<any, any>('/item/size/list', {
            params: { itemId: item.id, itemOrderNumber: item.code },
          })
        )
      );
      const sizeMap = filter.map((item, index) => ({
        ...item,
        itemSizeList: promises[index].itemSizeList,
        sizeName: '',
        quantity: 0,
        additionalPrice: 0,
        itemMax: 0,
      }));
      onSelect(sizeMap);
      onClose();
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };
  return (
    <ReModal maxWidth="max-w-5xl" title="상품 검색" isOpen={isOpen} onClose={onClose}>
      <div className="flex items-center gap-3">
        <Select onChange={onChange} value={searchType} name="searchType">
          <option value="FETCHING_CODE">페칭코드</option>
          <option value="ITEM_NAME">상품명</option>
          <option value="DESIGNER_CODE">상품디자이너ID</option>
          <option value="ITEM_CODE">편집샵코드</option>
        </Select>
        <ReInput
          autoFocus
          value={searchKey}
          name="searchKey"
          onChange={onChange}
          onEnter={get}
          placeholder="Enter (최대 5개)"
        />
        <Button isLoading={isSearching} onClick={get}>
          검색
        </Button>
      </div>
      <div className="mt-4">
        <GridTable
          list={list}
          columns={[
            '페칭코드',
            '상품',
            '활성화 여부',
            '재고 여부',
            '편집샵판매가',
            '페칭판매가',
          ]}
          maxHeight=""
          renderItem={(item, key) => (
            <div
              key={key}
              onClick={() =>
                setState({
                  selectedIdx:
                    selectedIdx.indexOf(item.id) !== -1
                      ? selectedIdx.filter((id) => id !== item.id)
                      : [...selectedIdx, item.id],
                })
              }
            >
              <div>
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={selectedIdx.indexOf(item.id) !== -1}
                />
              </div>
              <div aria-label="페칭코드">{item.id}</div>
              <div aria-label="상품">
                <div className="flex gap-1 group cursor-pointer">
                  <img
                    src={item.imageUrl}
                    alt="item"
                    className="max-w-[3.5rem] object-contain"
                  />
                  <div className="group-hover:underline text-left">
                    <div>
                      {item.shopName} ({item.shopCountry})
                    </div>
                    <div>{item.name}</div>
                    <div>{item.brand}</div>
                  </div>
                </div>
              </div>
              <div aria-label="활성화 여부">
                {!!item.isVerified && <CheckIcon className="w-7 h-7 text-green-500" />}
              </div>
              <div aria-label="재고 여부">
                {!!Number(item.isInStock) && (
                  <CheckIcon className="w-7 h-7 text-green-500" />
                )}
              </div>
              <div aria-label="편집샵판매가">
                <div>{priceFormat(item.userPrice)}원</div>
                {!!item.shopPromotion &&
                  item.shopPromotion.map(
                    ({ promotionType, promotionTypeValue, promotionId }, key) => (
                      <div
                        key={key}
                        className="link text-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(`/product/modifyPromotion/${promotionId}`);
                        }}
                      >
                        <span>
                          {promotionType === 'DISCOUNT_WITH_CONST' && promotionTypeValue}
                          {promotionType === 'DISCOUNT_WITH_RATE' &&
                            `${Math.round(promotionTypeValue * 100)}%`}
                          {promotionType === 'FREE_DELIVERY' && '무료배송'}
                        </span>
                        <span> 프로모션</span>
                      </div>
                    )
                  )}
              </div>
              <div aria-label="페칭판매가">
                <div>{priceFormat(item.finalPrice)}원</div>
                {!!item.fetchingPromotion &&
                  item.fetchingPromotion.map(
                    ({ promotionType, promotionTypeValue, promotionId }, key) => (
                      <div
                        key={key}
                        className="link text-sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(`/product/modifyPromotion/${promotionId}`);
                        }}
                      >
                        <span>
                          {promotionType === 'DISCOUNT_WITH_CONST' && promotionTypeValue}
                          {promotionType === 'DISCOUNT_WITH_RATE' &&
                            `${Math.round(promotionTypeValue * 100)}%`}
                          {promotionType === 'FREE_DELIVERY' && '무료배송'}
                        </span>
                        <span> 프로모션</span>
                      </div>
                    )
                  )}
              </div>
            </div>
          )}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setState({ selectedIdx: checked ? list.map((item) => item.id) : [] })
          }
        />
        {!!list.length && (
          <div className="flex justify-center mt-4">
            <Button isLoading={isLoading} onClick={onComplete}>
              선택 완료
            </Button>
          </div>
        )}
      </div>
    </ReModal>
  );
};

export default SearchItemModal;
