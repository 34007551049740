import {
  Button,
  Card,
  GridTable,
  MenuTabs,
  OpenLink,
  ReInput,
  RePagination,
  Select,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { dayjsFormat, getAge, request, useObject } from 'services';
import { IRequestResult, IUser } from 'types';

interface State {
  page: number;
  total: number;
  list: IUser[];
  pageSize: number;
  isLoading: boolean;
  minLeaveAt: string;
  maxLeaveAt: string;
  searchQuery: string;
}

const UsersDeletePage = () => {
  const [
    { list, page, total, isLoading, pageSize, minLeaveAt, maxLeaveAt, searchQuery },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    page: 1,
    total: 0,
    list: [],
    isLoading: false,
    pageSize: 10,
    minLeaveAt: '',
    maxLeaveAt: '',
    searchQuery: '',
  });
  const get = async () => {
    setState({ isLoading: true });
    let params: any = {
      userStatus: 'LEAVE',
    };
    if (!!minLeaveAt) params.minLeaveAt = minLeaveAt;
    if (!!maxLeaveAt) params.maxLeaveAt = maxLeaveAt;
    if (!!searchQuery) {
      params.searchQuery = searchQuery;
      params.searchType = 'ID';
    }
    try {
      const { count, data } = await request.get<any, IRequestResult<IUser>>(
        `/commerce/user`,
        { params }
      );
      setState({ total: count, list: data, isLoading: false });
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };
  const onDateClick = (date: string) => {
    let newMinDate = '';
    if (date === '오늘') newMinDate = dayjsFormat(dayjs());
    else if (date === '3일') newMinDate = dayjsFormat(dayjs().add(-3, 'day'));
    else if (date === '7일') newMinDate = dayjsFormat(dayjs().add(-7, 'day'));
    else if (date === '1개월') newMinDate = dayjsFormat(dayjs().add(-1, 'month'));
    else if (date === '3개월') newMinDate = dayjsFormat(dayjs().add(-3, 'month'));
    else if (date === '1년') newMinDate = dayjsFormat(dayjs().add(-1, 'year'));

    setState({ minLeaveAt: newMinDate, maxLeaveAt: dayjsFormat(dayjs()) });
  };
  useEffect(() => {
    get();
  }, [minLeaveAt, maxLeaveAt, page]);
  return (
    <>
      <MenuTabs
        options={[
          { to: '/users/dormant', name: '휴면 회원 관리' },
          { to: '/users/delete', name: '탈퇴 회원 관리' },
        ]}
      />
      <div className="wrapper">
        <div className="wrapper-title">탈퇴 회원 관리</div>
        <Card
          items={[
            {
              name: '아이디',
              renderItem: () => (
                <ReInput
                  size="xs"
                  className="w-40"
                  value={searchQuery}
                  name="searchQuery"
                  onChange={onChange}
                  onEnter={get}
                />
              ),
              stretch: true,
            },
            {
              name: '기간',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {['오늘', '3일', '7일', '1개월', '3개월', '1년'].map((item, key) => (
                    <Button
                      theme="ghost"
                      size="xs"
                      onClick={() => onDateClick(item)}
                      key={key}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={minLeaveAt}
                    name="minLeaveAt"
                    onChange={onChange}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={maxLeaveAt}
                    name="maxLeaveAt"
                    onChange={onChange}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '탈퇴 사유',
              renderItem: () => (
                <Select size="xs" className="w-72">
                  <option value="">전체</option>
                  <option>상품종류가 부족하다</option>
                  <option>상품가격이 비싸다</option>
                  <option>상품가격에 비해 품질이 떨어진다</option>
                  <option>배송이 느리다</option>
                  <option>반품/교환이 불만이다</option>
                  <option>상담원 고객응대 서비스가 불만이다</option>
                  <option>쇼핑몰 혜택이 부족하다 (쿠폰, 적립금, 할인 등)</option>
                  <option>이용빈도가 낮다</option>
                  <option>개인정보 유출이 염려된다</option>
                  <option>기타</option>
                </Select>
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-5 flex justify-center gap-3">
          <Button isLoading={isLoading} onClick={get}>
            검색
          </Button>
          <Button
            theme="secondary"
            onClick={() => resetState(['minLeaveAt', 'maxLeaveAt', 'searchQuery'])}
          >
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          columns={['이름(영문)', '아이디', '회원 탈퇴일', '탈퇴 유형', '탈퇴 사유']}
          list={list}
          noSelection
          renderItem={(item, key) => (
            <div key={key}>
              <div>
                <div>
                  <OpenLink url={`/user/${item.idx}`}>{item.name}</OpenLink>
                </div>
                {!!item.englishFirstName && (
                  <div>({item.englishFirstName + item.englishLastName})</div>
                )}
              </div>
              <div>
                <OpenLink url={`/user/${item.idx}`}>{item.id}</OpenLink>
                <div className="flex gap-1 text-xs">
                  <span
                    className={!!item.auId.KAKAO ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    KAKAO
                  </span>
                  <span
                    className={!!item.auId.NAVER ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    NAVER
                  </span>
                  <span
                    className={!!item.auId.APPLE ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    APPLE
                  </span>
                </div>
              </div>
              <div>{dayjs(item.deletedAt).format('YYYY. MM. DD. HH:mm:ss')}</div>
              <div>
                {item.gender === 'W' ? '여성' : ''}
                {item.gender === 'M' ? '남성' : ''} ({getAge(item.birthDate)})
              </div>
              <div>
                기타 (직접입력내용노출직접입력내용노출직접입력내용노출직접입력내용노출)
              </div>
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={total}
            pageSize={pageSize}
          />
        </div>
      </div>
    </>
  );
};

export default UsersDeletePage;
