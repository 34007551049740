// 무료배송 조건의 기준
export enum DeliveryFeeCriteria {
  ORIGIN_PRICE = 'ORIGIN_PRICE', // 정상 판매가 기준
  DISCOUNT_PRICE = 'DISCOUNT_PRICE', // 할인가 기준
}

// 배송비 부여 형식
export enum DeliveryFeeType {
  FIXED = 'FIXED', // 고정 금액 배송비
  PERCENT = 'PERCENT', // 구매 금액의 퍼센트 배송비 부과
  FREE = 'FREE', // 배송비 무료
}

// 휴일 지정값
export enum HolidayType {
  SUN = 'SUN',
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  HOLI = 'HOLI', //(공휴일)
}

// 호스팅 타입
export enum PartnerHostingType {
  CAFE24 = 'CAFE24',
  SMART_STORE = 'SMART_STORE',
  ETC = 'ETC',
}

// 수수료 정산 방식
export enum PaySettleMathod {
  // 고객이 판매자의 제품 상세페이지로 이동하는 수에 비례해 수수료가 과금되는 정산 방식.
  // 예: 현재 수수료는 클릭당 nnn원 입니다.
  CPC = 'CPC',
  // 구매가 발생한 제품 매출에 비례해 수수료가 과금되는 정산 방식.
  // 예: 현재 수수료는 매출의 n%입니다.
  CPS = 'CPS',
}

// 파트너샵 상태
export enum PartnerShopStatus {
  REQUEST = 'REQUEST', // 가입 신청 상태
  REJECT = 'REJECT', // 가입 거부 상태
  ACTIVE = 'ACTIVE', // 샵 활성화 상태
  STOP = 'STOP', // 정지 상태
}

// 입점 거절 타입
export enum PartnerShopRejectReasonType {
  TAXPAYER = 'TAXPAYER', // 사업자정보 오류
  MAIL_ORDER_BUSINESS = 'MAIL_ORDER_BUSINESS', // 통신판매업정보 오류
  BUSINESS_ITEM_AND_TYPE = 'BUSINESS_ITEM_AND_TYPE', // 업태, 업종 확인불가
  IMAGE = 'IMAGE', // 필수 이미지 미첨부
  ETC = 'ETC', // 기타
}

export type Shop = {
  businessItem: string;
  businessType: string;
  companyName: string;
  createdAt: string;
  directPaySettleMethod: string;
  fetchingPaySettleMethod: string;
  hostingType: string;
  isDirectPaySupport: boolean;
  isFetchingPaySupport: boolean;
  mailOrderBusinessReportNumber: string;
  mailOrderImage: string;
  partnerId: string;
  partnerShopId: number;
  rejectReason: string;
  rejectReasonType: string;
  representativeName: string;
  shopName: string;
  shopUrl: string;
  status: string;
  taxpayerIdentificationImageUrl: string;
  taxpayerIdentificationNumber: string;
  updatedAt: string;
  updateUserName: string;
};
