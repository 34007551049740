import styled from 'styled-components';

export const Outer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

export const Inner = styled.div`
  width: 70%;
  @media all and (min-width: 800px) {
    width: 25%;
  }
`;

export const loginItem = {
  marginTop: '20px',
  textAlign: 'center',
  width: '100%',
};
