import React from 'react';
import * as style from './style';
import { Spinner } from 'components';

const Loading = () => (
  <style.Outer>
    <Spinner className="w-20 h-20 text-black" />
  </style.Outer>
);

export default Loading;
