import React, { useState, useEffect } from 'react';
import * as style from './style';
import * as axios from 'api/axios';
import { Loading } from 'components';
import BannerDetailInfo from './BannerDetailInfo';
import BannerDetailList from './BannerDetailList';

const BannerDetail = ({ platformInfo }) => {
  const [cafe24Banners, setCafe24Banners] = useState([]);
  const [exhibitions, setExhibitions] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { platform, gender } = platformInfo;

  const getCafe24Banners = async () => {
    const { data } = await axios.getCafe24Banners({
      platform,
      gender,
    });

    setCafe24Banners(data);
  };

  const getExhibitions = async () => {
    const { data } = await axios.getExhibitions();
    setExhibitions(data);
  };

  const getPromotionList = async () => {
    const { data } = await axios.getPromotions();
    setPromotions(data);
  };

  const init = async () => {
    setIsLoading(true);
    await getCafe24Banners();
    await getExhibitions();
    await getPromotionList();
    setIsLoading(false);
  };

  const onRefetch = () => {
    init();
  };

  useEffect(() => {
    init();
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <style.Container>
      <style.Title style={{ textAlign: 'center' }}>전시설정</style.Title>

      <style.SectionTitle>기본정보</style.SectionTitle>
      <BannerDetailInfo platformInfo={platformInfo} />

      <style.SectionTitle>전시정보</style.SectionTitle>
      <BannerDetailList
        banners={cafe24Banners}
        exhibitions={exhibitions}
        promotions={promotions}
        onRefresh={onRefetch}
        platformInfo={platformInfo}
      />
    </style.Container>
  );
};

export default BannerDetail;
