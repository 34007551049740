import {
  Button,
  Card,
  GridTable,
  MenuTabs,
  OpenLink,
  ReInput,
  RePagination,
  Select,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { dayjsFormat, getAge, request, useObject } from 'services';
import { IRequestResult, IUser } from 'types';

interface State {
  list: IUser[];
  page: number;
  total: number;
  isLoading: boolean;
  pageSize: number;
  searchType: string;
  searchQuery: string;
  minDormantAt: string;
  maxDormantAt: string;
}

const UsersDormantPage = () => {
  const [
    {
      list,
      page,
      total,
      isLoading,
      pageSize,
      searchType,
      searchQuery,
      minDormantAt,
      maxDormantAt,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    list: [],
    page: 1,
    total: 0,
    isLoading: false,
    pageSize: 10,
    searchQuery: '',
    searchType: 'ID',
    minDormantAt: '',
    maxDormantAt: '',
  });
  const get = async () => {
    setState({ isLoading: true });
    let params: any = {
      userStatus: 'DORMANT',
    };
    if (!!minDormantAt) params.minDormantAt = minDormantAt;
    if (!!maxDormantAt) params.maxDormantAt = maxDormantAt;
    if (!!searchQuery) {
      params.searchQuery = searchQuery;
      params.searchType = searchType;
    }
    try {
      const { count, data } = await request.get<any, IRequestResult<IUser>>(
        '/commerce/user',
        { params }
      );
      setState({ total: count, list: data, isLoading: false });
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };

  const onDateClick = (date: string) => {
    let newMinDate = '';
    if (date === '오늘') newMinDate = dayjsFormat(dayjs());
    else if (date === '3일') newMinDate = dayjsFormat(dayjs().add(-3, 'day'));
    else if (date === '7일') newMinDate = dayjsFormat(dayjs().add(-7, 'day'));
    else if (date === '1개월') newMinDate = dayjsFormat(dayjs().add(-1, 'month'));
    else if (date === '3개월') newMinDate = dayjsFormat(dayjs().add(-3, 'month'));
    else if (date === '1년') newMinDate = dayjsFormat(dayjs().add(-1, 'year'));

    setState({ minDormantAt: newMinDate, maxDormantAt: dayjsFormat(dayjs()) });
  };

  useEffect(() => {
    get();
  }, [minDormantAt, maxDormantAt, page]);
  return (
    <>
      <MenuTabs
        options={[
          { to: '/users/dormant', name: '휴면 회원 관리' },
          { to: '/users/delete', name: '탈퇴 회원 관리' },
        ]}
      />
      <div className="wrapper">
        <div className="wrapper-title">휴면 회원 관리</div>
        <Card
          items={[
            {
              name: '회원 정보',
              renderItem: () => (
                <div className="flex gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChange}
                  >
                    <option value="ID">아이디</option>
                    <option value="NAME">이름</option>
                    <option value="PHONE">휴대폰 번호</option>
                    <option value="EMAIL">이메일</option>
                  </Select>
                  <ReInput
                    size="xs"
                    className="w-40"
                    value={searchQuery}
                    name="searchQuery"
                    onChange={onChange}
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '휴면 처리일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {['오늘', '3일', '7일', '1개월', '3개월', '1년'].map((item, key) => (
                    <Button
                      theme="ghost"
                      size="xs"
                      onClick={() => onDateClick(item)}
                      key={key}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={minDormantAt}
                    name="minDormantAt"
                    onChange={onChange}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    className="w-40"
                    type="date"
                    value={maxDormantAt}
                    name="maxDormantAt"
                    onChange={onChange}
                  />
                </div>
              ),
              stretch: true,
            },
          ]}
        />
        <div className="flex justify-center gap-3">
          <Button isLoading={isLoading} onClick={get}>
            검색
          </Button>
          <Button
            theme="secondary"
            onClick={() =>
              resetState(['maxDormantAt', 'minDormantAt', 'searchQuery', 'searchType'])
            }
          >
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          columns={[
            '휴면 처리일',
            '이름(영문)',
            '아이디',
            '등급',
            '휴대전화',
            '이메일',
            '성별 (나이)',
            '가입일',
          ]}
          noSelection
          list={list}
          renderItem={(item, key) => (
            <div key={key}>
              <div>{dayjs(item.dormantAt).format('YYYY. MM. DD. HH:mm:ss')}</div>
              <div>
                <div>
                  <OpenLink url={`/user/${item.idx}`}>{item.name}</OpenLink>
                </div>
                {!!item.englishFirstName && (
                  <div>({item.englishFirstName + item.englishLastName})</div>
                )}
              </div>
              <div>
                <OpenLink url={`/user/${item.idx}`}>{item.id}</OpenLink>
                <div className="flex gap-1 text-xs">
                  <span
                    className={!!item.auId.KAKAO ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    KAKAO
                  </span>
                  <span
                    className={!!item.auId.NAVER ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    NAVER
                  </span>
                  <span
                    className={!!item.auId.APPLE ? 'text-indigo-500' : 'text-gray-400'}
                  >
                    APPLE
                  </span>
                </div>
              </div>
              <div>
                {item.type === 'VIP' ? (
                  <span className="text-yellow-500">VIP</span>
                ) : (
                  '일반'
                )}
              </div>
              <div>{item.phoneNumber}</div>
              <div>{item.email}</div>
              <div>
                {item.gender === 'W' ? '여성' : ''}
                {item.gender === 'M' ? '남성' : ''} ({getAge(item.birthDate)})
              </div>
              <div>{dayjs(item.createdAt).format('YYYY. MM. DD. HH:mm:ss')}</div>
            </div>
          )}
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>명</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={total}
            pageSize={pageSize}
          />
        </div>
      </div>
    </>
  );
};

export default UsersDormantPage;
