import React, { useState } from 'react';
import * as S from './style';
import * as Modal from '../GroupListModal';
import fetchingAPI from 'api/fetchingAPI';

const GroupTable = ({
  items = [],
  reload = () => { },
  options = {},
  setOptions = () => { },
}) => {
  const [checkedItems, setCheckedItems] = useState([]);
  const onChangeCheckAll = () => {
    checkedItems.length === items.length
      ? setCheckedItems([])
      : setCheckedItems([...items]);
  };
  const onChangeCheckItem = (item, checked) => {
    checked
      ? setCheckedItems((p) => {
        p.splice(p.map(({ id }) => id).indexOf(item.id), 1);
        return [...p];
      })
      : setCheckedItems((p) => [...p, item]);
  };

  return (
    <S.Table>
      <S.Thead>
        <tr>
          <S.Th colSpan={0}>
            <ChangeInfoLeft
              items={items}
              checkedItems={checkedItems}
              reload={() => reload(options)}
            />
            <ChangeInfoRight
              checkedItems={checkedItems}
              reload={reload}
              options={options}
              setOptions={setOptions}
            />
          </S.Th>
        </tr>
      </S.Thead>
      <S.Tbody>
        <S.Tr style={{ background: '#E4E4E4' }}>
          <S.Td w={53}>
            <S.Checkbox
              onChange={onChangeCheckAll}
              checked={checkedItems.length === items.length}
            />
          </S.Td>
          <S.Td w={93}>관리자</S.Td>
          <S.Td w={94}>성별</S.Td>
          <S.Td w={94}>상품분류</S.Td>
          <S.Td w={474}>그룹명</S.Td>
          <S.Td w={111}>상품수</S.Td>
          <S.Td w={206}>최종 업데이트</S.Td>
        </S.Tr>
        {items.map((item) => (
          <GroupTableItem
            key={item.id}
            item={item}
            checked={checkedItems.map(({ id }) => id).includes(item.id)}
            setChecked={onChangeCheckItem}
          />
        ))}
      </S.Tbody>
    </S.Table>
  );
};

export default GroupTable;

const ChangeInfoLeft = (props) => {
  const [payload, setPayload] = useState('checkedItems');
  const [mode, setMode] = useState('gender');

  const onChangePayload = (e) => setPayload(e.target.value);
  const onChangeMode = (e) => setMode(e.target.value);

  const [modalOption, setModalOption] = useState(initialModalOption);
  const resetModalOption = () => {
    setModalOption(initialModalOption);
    props.reload();
  };
  const showModal = () => {
    if (props[payload].length > 0) {
      setModalOption({
        mode,
        payload: props[payload],
      });
    } else {
      alert('하나 이상의 그룹을 선택하세요');
    }
  };

  return (
    <>
      <Modal.ChangeGender
        show={modalOption.mode === 'gender'}
        onHide={resetModalOption}
        items={modalOption.payload}
      />
      <Modal.ChangeAssignee
        show={modalOption.mode === 'assignee'}
        onHide={resetModalOption}
        items={modalOption.payload}
      />
      <div>
        <S.Select value={payload} onChange={onChangePayload}>
          <option value="checkedItems">선택한 그룹의</option>
          {/* <option value='items'>검색결과 전체 그룹의</option> */}
        </S.Select>
        <S.Select value={mode} onChange={onChangeMode}>
          <option value="gender">성별을</option>
          <option value="assignee">담당자를</option>
        </S.Select>
        <S.ManualButton style={{ background: 'black' }} onClick={showModal}>
          일괄변경
        </S.ManualButton>
      </div>
    </>
  );
};

const ChangeInfoRight = ({
  checkedItems = [],
  reload = () => { },
  options = {},
  setOptions = () => { },
}) => {
  const { limit, sort } = options;

  const onClickDelete = () => {
    if (checkedItems.length > 0) {
      if (
        window.confirm(`
${checkedItems.length}개 그룹을 삭제합니다.
${checkedItems.map(({ name }) => name).join('\n')}
        `)
      ) {
        fetchDeleteProductsGroup(checkedItems).then(() => reload(options));
      }
    } else {
      alert('하나 이상의 그룹을 선택하세요.');
    }
  };

  const onChangeLimit = (limit) =>
    setOptions((p) => ({ ...p, limit: Number(limit) }));
  const onChangeSort = (sort) => setOptions((p) => ({ ...p, sort }));

  return (
    <div>
      <a href="/product/productList/manage-group/add-group" target="_blank">
        <S.ManualButton style={{ background: '#0D9AE9' }}>
          그룹 추가
        </S.ManualButton>
      </a>
      <S.ManualButton style={{ background: '#FF6F61' }} onClick={onClickDelete}>
        그룹 삭제
      </S.ManualButton>
      <S.Select
        onChange={(e) => onChangeLimit(e.target.value)}
        value={String(limit)}
      >
        <option value="50">50개씩 보기</option>
        <option value="100">100개씩 보기</option>
        <option value="200">200개씩 보기</option>
      </S.Select>
      <S.Select onChange={(e) => onChangeSort(e.target.value)} value={sort}>
        <option value="NAME">이름순</option>
        <option value="ASSIGNEE">담당자순</option>
        <option value="UPDATE">최종 업데이트 최신순</option>
      </S.Select>
    </div>
  );
};

const GroupTableItem = ({ item, checked, setChecked }) => {
  const { id, assignee, gender, isAutomated, name, count, updatedAt } = item;

  return (
    <S.Tr style={{ height: '60px' }}>
      <S.Td w={53}>
        <S.Checkbox
          checked={checked}
          onChange={() => setChecked(item, checked)}
        />
      </S.Td>
      <S.Td w={93}>{assignee}</S.Td>
      <S.Td w={94}>
        {gender === 'MW' ? '전체' : gender === 'M' ? '남성' : '여성'}
      </S.Td>
      <S.Td w={94}>
        {isAutomated === 1 ? (
          <span style={{ color: '#498935' }}>자동</span>
        ) : (
          <span style={{ color: '#FF0707' }}>수동</span>
        )}
      </S.Td>
      <S.Td w={474}>
        <a
          href={`/product/productList/manage-group/modify-group/${id}`}
          target="_blank"
          rel="noreferrer"
          style={{ alignSelf: 'flex-start', paddingLeft: '8px' }}
        >
          {name}
        </a>
      </S.Td>
      <S.Td w={111}>{count}</S.Td>
      <S.Td w={206}>{updatedAt && trimDate(updatedAt, true)}</S.Td>
    </S.Tr>
  );
};

const trimDate = (ISOstring, detail = false) => {
  const date = new Date(ISOstring);
  date.setHours(date.getHours() - 9);
  return detail
    ? date.getFullYear() +
    '.' +
    (date.getMonth() + 1) +
    '.' +
    date.getDate() +
    ' ' +
    date.getHours() +
    ':' +
    date.getMinutes() +
    ':' +
    date.getSeconds()
    : date.getFullYear() + '.' + (date.getMonth() + 1) + '.' + date.getDate();
};

const initialModalOption = {
  payload: [],
  mode: null,
};

const fetchDeleteProductsGroup = async (checkedItems) => {
  try {
    checkedItems.forEach(async ({ id }) => await fetchingAPI.deleteProductGroup(id));
  } catch (error) {
    alert(`err in fetchDeleteProductsGroup(), in <ChangeInfoRight/>,${error.message}`);
  }
};
