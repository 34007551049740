import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { useSetRecoilState } from 'recoil';
import { shippingCountState } from 'store';
import useSWR from 'swr';
import dayjs from 'dayjs';
import { request } from 'services';

export interface Props {}
interface State {}

const ShippingCount: FunctionComponent<Props> = ({ children }) => {
  const setShippingCount = useSetRecoilState(shippingCountState);
  const { data } = useSWR(
    [
      '/commerce/order?orderStatus=PRODUCT_PREPARE',
      '/commerce/order?orderStatus=WAYPOINT_ARRIVAL',
      '/commerce/order?orderStatus=DOMESTIC_CUSTOMS_CLEARANCE',
      '/commerce/order?orderStatus=CUSTOMS_CLEARANCE_DELAY',
      '/commerce/order?orderStatus=SHIPPING_START',
    ],
    (...urls) =>
      Promise.all(
        urls.map((url) =>
          request.get<any, { count: number }>(url, {
            params: {
              minOrderDate: `${dayjs()
                .add(-1, 'month')
                .format('YYYY-MM-DDT00:00:00')}.000Z`,
              deliveryManageMode: true,
            },
            headers: {
              isCount: true,
              Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
            },
          })
        )
      ),
    { refreshInterval: 1000 * 60 * 3 }
  );

  useEffect(() => {
    if (!data) return;
    setShippingCount({
      prepare: data[0].count,
      inWaypoint: data[1].count,
      domesticCustoms: data[2].count,
      customsDelay: data[3].count,
      start: data[4].count,
    });
  }, [data]);
  return <>{children}</>;
};

export default ShippingCount;
