import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import type { IModal, NOrder } from 'types';
import {
  Button,
  FormItem,
  InputNumber,
  NumberStat,
  Radio,
  ReInput,
  ReModal,
} from 'components';
import Switch from 'react-switch';
import { request, TAX_PAY_ISSUE, useObject } from 'services';
import { toast } from 'react-toastify';

export interface Props extends IModal {
  itemOrderNumber: string;
  onComplete?: () => void;
}
interface State {
  duty: number;
  educationTax: number;
  isCombinedTax: boolean;
  specialTax: number;
  vat: number;
  additionalTax: number;
  isLoading: boolean;
  totalTax: number;
  taxPaid: boolean;
  paymentNumber: string;
  taxPayIssue: keyof typeof TAX_PAY_ISSUE;
}

const UpdateCustomDutyModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  itemOrderNumber,
  onComplete,
}) => {
  if (!isOpen) return null;
  const [
    {
      duty,
      educationTax,
      isCombinedTax,
      specialTax,
      vat,
      additionalTax,
      isLoading,
      totalTax,
      taxPaid,
      paymentNumber,
      taxPayIssue,
    },
    setState,
    onChange,
  ] = useObject<State>({
    duty: 0,
    educationTax: 0,
    isCombinedTax: false,
    specialTax: 0,
    vat: 0,
    additionalTax: 0,
    isLoading: false,
    totalTax: 0,
    taxPaid: true,
    paymentNumber: '',
    taxPayIssue: 'NONE',
  });
  const get = async () => {
    if (!itemOrderNumber) return;

    try {
      const { taxInfo } = await request.get<any, { taxInfo: NOrder.ItemOrderTax }>(
        `/commerce/order/item-order/tax/${itemOrderNumber}`
      );
      setState({
        duty: taxInfo.duty,
        additionalTax: taxInfo.additionalTax,
        educationTax: taxInfo.educationTax,
        isCombinedTax: taxInfo.isCombinedTax,
        specialTax: taxInfo.specialTax,
        totalTax: taxInfo.totalTax,
        vat: taxInfo.vat,
        paymentNumber: taxInfo.paymentNumber,
        taxPaid: taxInfo.taxPaid,
        taxPayIssue: taxInfo.taxPayIssue,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    if (!window.confirm('관부가세를 업데이트하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      await request.put(`/commerce/order/item-order/tax/${itemOrderNumber}`, {
        additionalTax,
        duty,
        educationTax,
        isCombinedTax,
        paymentNumber,
        specialTax,
        taxPaid,
        taxPayIssue,
        totalTax,
        vat,
      });
      toast.success('관부가세가 업데이트되었습니다.');
      onClose();
      if (!!onComplete) onComplete();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <ReModal
      title="관부가세 업데이트"
      isOpen={isOpen}
      onClose={onClose}
      description={
        <>
          관세청의 관부가세 납부 정보를 업데이트합니다.
          <br />
          실제 관세청에서 납부한 정보를 기입해주세요.
        </>
      }
    >
      <div className="space-y-4">
        <FormItem label="총 세액" readOnly>
          <NumberStat value={totalTax} />
        </FormItem>
        <FormItem label="관세">
          <InputNumber
            align="right"
            suffix="원"
            value={duty}
            name="duty"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="교육세">
          <InputNumber
            align="right"
            suffix="원"
            value={educationTax}
            name="educationTax"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="특소세">
          <InputNumber
            align="right"
            suffix="원"
            value={specialTax}
            name="specialTax"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="부가세">
          <InputNumber
            align="right"
            suffix="원"
            value={vat}
            name="vat"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="기타">
          <InputNumber
            placeholder="추가 세액이 있다면 입력해주세요."
            suffix="원"
            className="w-full"
            align="right"
            value={additionalTax}
            name="additionalTax"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="합산과세여부">
          <Switch
            checked={isCombinedTax}
            onChange={(isCombinedTax) => setState({ isCombinedTax })}
          />
        </FormItem>
        <FormItem label="전자납부번호">
          <ReInput
            className="w-full"
            value={paymentNumber}
            name="paymentNumber"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="납부여부">
          <Switch
            checked={taxPaid}
            onChange={(taxPaid) => setState({ taxPaid, taxPayIssue: 'NONE' })}
          />
        </FormItem>
        {!taxPaid && (
          <FormItem label="이슈체크">
            <Radio
              id="taxPayIssue"
              value={taxPayIssue}
              options={[
                { name: '기납부내역', value: 'ALREADY_PAID' },
                { name: '기타', value: 'ETC' },
              ]}
              onChange={(taxPayIssue) => setState({ taxPayIssue })}
            />
          </FormItem>
        )}
        <div className="flex justify-center">
          <Button isLoading={isLoading} onClick={onSubmit}>
            관부가세 업데이트
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default UpdateCustomDutyModal;
