import { fetchingAPI } from 'api/axios';
import { LoadingContext } from 'context';
import JSZip from 'jszip';
import { downloadFile, makeSmartStoreExcel } from 'pages/ProductList/utils';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { getItemImage, getSmartStoreFormat } from 'services/items';
import { saveAs } from 'file-saver';
import styled from 'styled-components';
import { toast } from 'react-toastify';

const Margin = styled.div`
  height: 10px;
`;

const DownloadInfoModal = ({
  show,
  onHide,
  checkedItemIds,
  searchOptionTableState,
  trimParams,
  checkedItemCafe24Ids,
}) => {
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const getAllFetchingCode = async () => {
    try {
      const data = window.location.href.includes('/manage-group/modify-group/')
        ? await fetchingAPI.get(
            `/group/${
              window.location.href.split('/manage-group/modify-group/')[1]
            }/item/ids`,
            {
              params: {
                ...trimParams(searchOptionTableState),
              },
            }
          )
        : await fetchingAPI.get('/item/ids', {
            params: {
              ...trimParams(searchOptionTableState),
            },
          });
      return data.data.join('\n');
    } catch (err) {
      toast.error('에러가 발생했습니다. 계속 발생한다면 개발팀에게 문의바랍니다.');
      console.log(err);
    }
  };
  const getAllCafe24Id = async () => {
    try {
      const data = await fetchingAPI.get('/item/cafe24-ids', {
        params: {
          ...trimParams(searchOptionTableState),
        },
      });
      return data.data.join('\n');
    } catch (err) {
      toast.error('에러가 발생했습니다. 계속 발생한다면 개발팀에게 문의바랍니다.');
      console.log(err);
    }
  };
  const getAllShopURL = async () => {
    try {
      const data = await fetchingAPI.get('/item/urls', {
        params: {
          ...trimParams(searchOptionTableState),
        },
      });
      return data.data.join('\n');
    } catch (err) {
      toast.error('에러가 발생했습니다. 계속 발생한다면 개발팀에게 문의바랍니다.');
      console.log(err);
    }
  };
  async function download(callback, fileName) {
    if (isLoading) return;

    handleIsLoading(true);

    try {
      const contents = await callback();

      downloadFile({ contents, fileName });
    } catch (e) {
      toast.error('에러가 발생했습니다. 계속 발생한다면 개발팀에게 문의바랍니다.');
      console.log(e);
    }

    handleIsLoading(false);
  }

  async function downloadCheckedSmartStore(itemIds) {
    if (isLoading) return;

    handleIsLoading(true);

    try {
      const items = await getSmartStoreFormat(itemIds);
      const workbook = makeSmartStoreExcel(items);
      const buffer = await workbook.xlsx.writeBuffer();

      const zip = new JSZip();
      zip.file('smart_store.xlsx', buffer);

      const imagesUrl = items
        .filter(({ categoryId }) => categoryId)
        .map(({ imageUrl }) => imageUrl);
      const images = await getItemImage(imagesUrl);
      images.forEach(({ imageUrl, data }) => {
        const splits = imageUrl.split('/');
        const name = splits[splits.length - 1];
        zip.file(name, data);
      });

      const blob = await zip.generateAsync({ type: 'blob' });
      saveAs(blob, 'smart_store.zip');
    } catch (e) {
      toast.error('에러가 발생했습니다. 계속 발생한다면 개발팀에게 문의바랍니다.');
      console.log(e);
    }

    handleIsLoading(false);
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>다운로드 관련 기능</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Button
          variant="outline-dark"
          onClick={() => {
            download(getAllFetchingCode, 'all_fetching_id.txt');
          }}
        >
          검색내역 전체 페칭코드 다운로드
        </Button>
        <br />
        <Margin />
        {!window.location.href.includes('/manage-group/modify-group/') && (
          <Button
            variant="outline-dark"
            onClick={() => {
              download(getAllCafe24Id, 'all_cafe24id.txt');
            }}
          >
            검색내역 전체 카페24 상품번호 다운로드
          </Button>
        )}
        <br />
        <Margin />
        {!window.location.href.includes('/manage-group/modify-group/') && (
          <Button
            variant="outline-dark"
            onClick={() => {
              download(getAllShopURL, 'all_shop_url.txt');
            }}
          >
            검색내역 전체 편집샵 URL 다운로드
          </Button>
        )}
        <br />
        <Margin />
        <Button
          variant="outline-dark"
          onClick={() => {
            download(
              () => checkedItemIds.map((v) => String(v)).join('\n'),
              'checked_fetching_id.txt'
            );
            navigator.clipboard.writeText(
              checkedItemIds.map((v) => String(v)).join('\n')
            );
          }}
        >
          체크된 상품 페칭코드 다운로드
        </Button>
        <br />
        <Margin />
        <Button
          variant="outline-dark"
          onClick={() => {
            download(
              () => checkedItemCafe24Ids.map((v) => String(v)).join('\n'),
              'checked_cafe24id.txt'
            );
            navigator.clipboard.writeText(checkedItemCafe24Ids.join('\n'));
          }}
        >
          체크된 상품 카페24 상품번호 다운로드
        </Button>
        <br />
        <Margin />
        <Button
          variant="outline-dark"
          onClick={() => {
            downloadCheckedSmartStore(checkedItemIds);
          }}
        >
          체크된 상품 스마트스토어 엑셀업로드 양식 받기
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          닫기
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DownloadInfoModal;
