// @ts-nocheck
import { getPromotions } from 'api/axios';
import fetchingAPI from 'api/fetchingAPI';
import { PickIdModal } from 'components';
import { MakeTable } from 'components/UI/atoms/Table';
import { triggerState } from 'pages/ManageMainContent';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import * as S from '../style';

type Props = {
  mode: 'm' | 'w';
  layout: {
    idx?: number;
    promotions: number[] | null;
  };
};
const ManageSelectShops = ({ mode, layout }: Props) => {
  const layoutIdx = layout.idx;
  const promotions = usePromotions();
  const setTrigger = useSetRecoilState(triggerState);
  //리팩토링 필요
  const [data, setData] = useState<{ promotions: number[] | null }>({
    promotions: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  const initData = () => {
    const { promotions } = layout;
    setData({ promotions });
  };

  const saveChanges = () => {
    //리팩터링 필요
    if (data.promotions?.length === 0) {
      alert('하나이상의 프로모션을 선택하세요.');
    } else {
      const gender = mode === 'm' ? 'men' : 'women';
      setIsLoading(true);
      fetchingAPI
        .postUIComponents(gender, [
          {
            idx: layoutIdx,
            requestType: 'UPDATE',
            ...data,
          },
        ])
        .then(({ data }) => {
          data?.success && alert('수정에 성공했습니다.');
          setTrigger((p) => p + 1);
        })
        .catch((e) => alert(`err in saveChanges, ${e.message}`))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const modal = PickIdModal.usePickIdModal({
    onSubmitPromotion: (value) => {
      setData((p) => ({ ...p, promotions: [...(p.promotions ?? []), value] }));
    },
  });

  const tables = [
    {
      title: '편집샵 프로모션',
      content: [
        {
          left: '프로모션 관리',
          right: (
            <>
              <PickIdModal.Promotion {...modal.promotion} />
              <Button variant="success" onClick={modal.promotion.toggle}>
                추가
              </Button>
            </>
          ),
        },
        {
          left: '프로모션 목록',
          right: (
            <>
              <ul style={{ margin: '0 0 0 10px' }}>
                {data.promotions?.map((id, i) => (
                  <li key={id}>
                    <Link to={`/product/modifyPromotion/${id}`} key={`link${id}`}>
                      {promotions.length > 0 &&
                        promotions?.find((p) => p.id === id).shopName}{' '}
                      -{' '}
                      {promotions.length > 0 && promotions?.find((p) => p.id === id).name}
                    </Link>{' '}
                    <Button
                      variant="danger"
                      onClick={() => {
                        setData((p) => {
                          if (p.promotions === null || p.promotions.length === 0) {
                            const copy: number[] = [];
                            return {
                              ...p,
                              promotions: copy,
                            };
                          } else {
                            const copy: number[] = [...p.promotions];
                            copy.splice(i, 1);
                            return {
                              ...p,
                              promotions: copy,
                            };
                          }
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </li>
                ))}
              </ul>
            </>
          ),
        },
      ],
    },
  ];

  return (
    <>
      <S.ButtonContainer>
        <Button variant="warning" onClick={initData}>
          초기화
        </Button>{' '}
        <Button onClick={saveChanges}>
          {isLoading ? <Spinner animation="border" /> : '저장하기'}
        </Button>
      </S.ButtonContainer>
      <MakeTable data={tables[0]} tableRef={undefined} />
    </>
  );
};

export default ManageSelectShops;

const usePromotions = (): any[] => {
  const [promotions, setPromotions] = useState([]);
  useEffect(() => {
    getPromotions()
      .then((data: any) => setPromotions(data.data))
      .catch((e) => alert(`err in usePormosion, ${e.message}`));
  }, []);
  return promotions;
};
