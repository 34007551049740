import React from 'react';
import * as style from './style';

const PriceBy = ({ searchOptionTableState, setSearchOptionTableState }) => {

    const { priceType, priceStart, priceEnd } = searchOptionTableState;

    return (
        <style.Container>
            <style.Select onChange={(e) => {
                setSearchOptionTableState({
                    ...searchOptionTableState,
                    priceType: e.target.value,
                    priceStart: '',
                    priceEnd: '',
                });
            }}>
                <option value='null'>가격 기준을 선택하세요</option>
                <option value='FINAL_PRICE'>페칭판매가</option>
                <option value='MAX_PRICE'>최대할인가</option>
            </style.Select>
            <style.Input disabled={priceType === 'null'} onChange={(e) => {
                setSearchOptionTableState({
                    ...searchOptionTableState,
                    priceStart: e.target.value,
                });
            }} value={priceStart} />
            ~
            <style.Input disabled={priceType === 'null'} onChange={(e) => {
                setSearchOptionTableState({
                    ...searchOptionTableState,
                    priceEnd: e.target.value,
                });
            }} value={priceEnd} />
        </style.Container>
    );
};

export default PriceBy;