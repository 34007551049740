import { Alert, Button, FormItem, ReModal, Select } from 'components';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { request, useObject, SHOP_ORDER_STATUS, useOrderDetail } from 'services';
import { IModal } from 'types';
import { priceFormat } from 'utils';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const Price: React.FunctionComponent<{ name: string }> = ({ name, children }) => {
  return (
    <div>
      <span className="text-sm">{name}</span>
      <span className="ml-1 text-lg">{children}</span>
      <span className="ml-1 text-sm">원</span>
    </div>
  );
};

interface ItemSize {
  sizeName: string;
  quantity: number;
  optionalPrice: number;
  optionalDirectPrice: number;
  optionalFetchingPrice: number;
}
export interface Props extends IModal {
  itemId: number;
  itemOrderNumber: string;
  orderStatus: keyof typeof SHOP_ORDER_STATUS | '';
  currentSizeName: string;
  onMoveWait: (additionalItemNumberList: string[]) => void;
}
interface State {
  itemSizeList: ItemSize[];
  index: number | '';
  couponDiscount: number;
  deliveryFee: number;
  dutyAndTax: number;
  item: number;
  total: number;
  isLoading: boolean;
}

const ChangeItemOptionModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  itemId,
  itemOrderNumber,
  orderStatus,
  currentSizeName,
  onMoveWait,
}) => {
  if (!isOpen) return null;
  const [
    {
      itemSizeList,
      index,
      couponDiscount,
      deliveryFee,
      dutyAndTax,
      item,
      total,
      isLoading,
    },
    setState,
    onChange,
  ] = useObject<State>({
    itemSizeList: [],
    index: '',
    couponDiscount: 0,
    deliveryFee: 0,
    dutyAndTax: 0,
    item: 0,
    total: 0,
    isLoading: false,
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const [orderState, setOrderState, { refreshOrderState }] = useOrderDetail();

  const get = async () => {
    try {
      const { itemSizeList } = await request.get<any, { itemSizeList: ItemSize[] }>(
        '/item/size/list',
        {
          params: {
            itemId,
            itemOrderNumber,
          },
        }
      );
      setState({ itemSizeList });
    } catch (err) {
      console.log(err);
    }
  };

  const onChangeSize = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    const key = Number(e.target.value);
    if (currentSizeName === itemSizeList[key].sizeName) {
      toast.info('동일한 옵션입니다. 다른 옵션을 선택해주세요.');
      return;
    }
    try {
      const { couponDiscount, deliveryFee, dutyAndTax, item, total } = await request.post<
        any,
        {
          couponDiscount: number;
          deliveryFee: number;
          dutyAndTax: number;
          item: number;
          total: number;
        }
      >(`/commerce/order/item-order/change-size/difference`, {
        itemOrderList: [{ itemOrderNumber, sizeName: itemSizeList[key].sizeName }],
      });
      setState({ index: key, couponDiscount, deliveryFee, dutyAndTax, item, total });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    if (index === '') {
      toast.info('변경할 옵션을 선택해주세요.');
      return;
    }
    if (!window.confirm('상품 옵션을 변경하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      await request.put('/commerce/order/item-order/size', {
        itemOrderList: [{ itemOrderNumber, sizeName: itemSizeList[index].sizeName }],
      });
      toast.success('상품 옵션이 변경되었습니다.');
      if (total > 0) {
        const { success, additionalItemNumberList } = await request.post<
          any,
          { success: boolean; additionalItemNumberList: string[] }
        >('/commerce/order/additional-pay', {
          fetchingOrderNumber,
          itemOrderList: [{ amount: total, itemOrderNumber }],
        });
        onMoveWait(additionalItemNumberList);
      } else {
        onClose();
      }
      refreshOrderState();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isLoading: false });
    }
  };

  const isChangable = useMemo(() => {
    return (
      orderStatus === 'BEFORE_DEPOSIT' ||
      orderStatus === 'ORDER_AVAILABLE' ||
      orderStatus === 'ORDER_WAITING' ||
      orderStatus === 'PRE_ORDER_REQUIRED'
    );
  }, [orderStatus]);

  useEffect(() => {
    get();
  }, []);
  return (
    <ReModal
      title="상품 옵션 변경"
      description="선택한 상품의 옵션을 변경합니다."
      isOpen={isOpen}
      maxWidth="max-w-xl"
      onClose={() => {
        if (window.confirm('작업을 중단하시겠습니까?')) onClose();
      }}
    >
      <div className="space-y-4">
        {orderStatus === 'ORDER_COMPLETE' && (
          <Alert
            list={[
              '주문완료 후에는 판매처에서 가능여부를 반드시 확인해야합니다.',
              '실시간 상담 상태가 아니라면 변경을 지양합니다.',
            ]}
          />
        )}
        <FormItem label="주문상태" readOnly>
          {orderStatus ? SHOP_ORDER_STATUS[orderStatus] : ''}
        </FormItem>
        <FormItem label="현재 옵션" readOnly>
          {currentSizeName}
        </FormItem>
        <FormItem
          label="변경 옵션"
          action={
            <Button theme="secondary" className="ml-1" size="xs">
              재고 및 가격 확인
            </Button>
          }
        >
          <Select value={index} name="index" onChange={onChangeSize}>
            <option value="" disabled selected>
              선택
            </option>
            {itemSizeList.map((item, key) => (
              <option key={key} disabled={!item.quantity} value={key}>{`${
                item.sizeName
              } (${
                item.quantity
                  ? `${priceFormat(item.optionalFetchingPrice)}원`
                  : '재고 없음'
              })`}</option>
            ))}
          </Select>
        </FormItem>
        <FormItem label="변경차액" readOnly>
          <div className="mb-1">
            <span>총</span>
            <span
              className={classnames('text-2xl ml-1', {
                'text-green-500': total > 0,
                'text-red-600': total < 0,
              })}
            >
              {priceFormat(total)}
            </span>
            <span className="ml-1">원</span>
          </div>
          <Price name="상품">{priceFormat(item)}</Price>
          <Price name="세금">{priceFormat(dutyAndTax)}</Price>
          <Price name="쿠폰">{priceFormat(couponDiscount)}</Price>
          <Price name="배송비">{priceFormat(deliveryFee)}</Price>
        </FormItem>
        <FormItem label="변경 가능 여부">
          <div className="text-red-500">
            {isChangable
              ? `변경 가능 ${total > 0 ? '(차액결제필요)' : ''}`
              : '변경 불가능'}
          </div>
        </FormItem>
        <div className="flex justify-center">
          <Button isLoading={isLoading} onClick={onSubmit}>
            {!!total ? '차액 안내하기' : '상품 옵션 변경하기'}
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default ChangeItemOptionModal;
