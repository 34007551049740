import styled from 'styled-components';

export const Wrapper = styled.ul`
  list-style: none;
  padding: 10px 13px;
  border-right: 1px solid #ddd;
  min-width: 120px;
`;

export const Item = styled.li`
  color: ${(props) => props.color};
  ${(props) =>
    props.fontWeight &&
    `
    font-size: 1.2rem;
    font-weight: bold;
  `}
`;
