import React, { useEffect, useState } from 'react';
import { NameKeywordsModals } from '..';
import * as style from './style';
import { NameKeywordTableTr } from '../../UI/organisms';

const NameKeywordTable = ({ keywords, totalCount, fetchItems }) => {
  const [checkedKeywords, setCheckedKeywords] = useState([]);
  const [payload, setPayload] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setCheckedKeywords([]);
    setPayload({});
  }, [keywords]);

  return (
    <>
      <NameKeywordsModals
        showModal={showModal}
        setShowModal={setShowModal}
        fetchItems={fetchItems}
      />
      <span style={{ fontSize: '12px', paddingBottom: '7px' }}>총 {totalCount}개</span>
      <style.Table>
        <style.Tr style={{ background: '#BEBEBE', padding: '13px 22px' }}>
          <style.Button
            style={{ background: '#498935' }}
            onClick={() => {
              setShowModal(true);
            }}
          >
            매핑 생성
          </style.Button>
        </style.Tr>
        <style.Tr style={{ background: '#E4E4E4' }}>
          <style.flexibleTd
            widthPcnt={20}
            onClick={() => {
              if (checkedKeywords.length === keywords.length) {
                setCheckedKeywords([]);
              } else {
                setCheckedKeywords(keywords.map((obj) => obj.id));
              }
            }}
          >
            <style.Checkbox
              onChange={() => {
                if (checkedKeywords.length === keywords.length) {
                  setCheckedKeywords([]);
                } else {
                  setCheckedKeywords(keywords.map((obj) => obj.keyword));
                }
              }}
              checked={checkedKeywords.length === keywords.length}
            />
          </style.flexibleTd>

          <style.flexibleTd widthPcnt={100}>키워드</style.flexibleTd>
          <style.flexibleTd widthPcnt={100}>바꿀 문장</style.flexibleTd>
          <style.flexibleTd widthPcnt={50}>생성자</style.flexibleTd>
          <style.flexibleTd widthPcnt={50}>생성 시간</style.flexibleTd>
        </style.Tr>
        {keywords.map((keyword, i) => (
          <NameKeywordTableTr
            key={keyword.keyword}
            item={keyword}
            i={i}
            checkedItemIds={checkedKeywords}
            setCheckedItemIds={setCheckedKeywords}
            payload={payload}
            setPayload={setPayload}
          />
        ))}
      </style.Table>
    </>
  );
};

export default NameKeywordTable;
