import { PlusIcon } from '@heroicons/react/outline';
import { RefreshIcon } from '@heroicons/react/solid';
import Switch from 'react-switch';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ClassificationModal, ItemHistory, Loading, Portal } from 'components';
import dayjs from 'dayjs';
import { useScrollTabs, useItem } from './hooks';
import { TableLeft, TableRight, TableDivider, Table } from './components';
import { ModalContext } from 'context';
import Item from 'components/UI/molecules/Item';
import { DeliveryMethod, getKRWPrice, getPromotionName } from './utils';
import { DebounceInput } from 'react-debounce-input';
import { COMMERCE_URL, request } from 'services';
import { Carousel, Dropdown, Menu, Select } from 'antd';
import useSWR from 'swr';

const tabs = ['진열설정', '분류정보', '상품정보', '가격정보'];

const ProductDetailV2: FC = () => {
  const history = useHistory();
  const {
    refs,
    useCurrentTab: [currentTab],
    scrollToRef,
  } = useScrollTabs(4);

  const { modal, handleModalShow } = useContext<any>(ModalContext);

  const {
    item,
    price,
    history: itemHistory,
    isLoading,
    reload,
    onChange,
    usePricingIndex,
    useDeliveryMethodState,
    onSubmit,
    state,
    handler,
  } = useItem(history.location.pathname.split('/').slice(-1)[0]);

  const [pricingIndex, setPricingIndex] = usePricingIndex;

  const {
    id,
    updatedAt,
    shopCode,
    url,

    isDomesticDelivery,
    isSellable,
    isFetchingPayActive,
    isShopPayActive,
    isNaverFeedTarget,

    shopCategoryName,
    categories,
    categoryKeywords,
    searchKeywords,
    exhibitions,
    allPromotions,

    priority,
    score,

    viewCount,
    likeCount,
    salesCount,
    // 이동 후 결제 추가 예정
    cartCount,

    shopName,
    shopBrandName,
    brandName,
    originName,
    name,
    color,
    imageUrl,
    imageUrls,
    description,

    shopId,
  } = item ?? {};

  const { country, options, deliveryMethod, currency, isInPriceProcessingQueue } =
    price?.[pricingIndex.shopIndex]?.[1] ?? {};

  const {
    isActive,
    isSellable: isOptionSellable,
    name: optionName,
    quantity,
    originPrice,
    discountedPrice,
    promotions,
    fixedPrice,
    fixedPriceKor,
    deductedVat,
    dutyAndTax,
    specialTax,
    educationalTax,
    deliveryFee,
    totalPrice,
    agencyFee,
    agencyFeeRate,
    waypointFee,
    additionalPrice,
    finalPrice,
  } = options?.[pricingIndex.optionIndex] ?? {};

  const { data: availableDeliveryMethods } = useSWR<{
    data: DeliveryMethod[];
  }>(
    `/shop/${shopId}/price-rule/${price?.[pricingIndex.shopIndex]?.[0]}/delivery-methods`,
    request.get
  );
  const [deliveryMethodState, setDeliveryMethodState] = useDeliveryMethodState;

  return (
    <>
      {isLoading && <Loading />}
      <div className="bg-white z-20 absolute inset-0" style={{ bottom: 'auto' }}>
        <div className="px-7">
          {/*  */}
          <div className="pt-12">
            <h1 className="text-2xl font-bold">상품상세정보</h1>
          </div>
          {/*  */}
          <div className="mt-10 flex justify-end gap-2">
            <button className="p-3 border" onClick={() => reload()}>
              <RefreshIcon className="w-5 h-5" />
            </button>
            <button
              className="p-3 bg-black text-white"
              onClick={() => window.open(`${COMMERCE_URL}/product/${id}`)}
            >
              페칭 상세
            </button>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu
                  items={price?.map(([priceRule, itemData]) => ({
                    label: `${itemData.country} ${getKRWPrice(
                      itemData.options?.[0].finalPrice
                    )}`,
                    key: priceRule,
                    onClick: () => {
                      window.open(`${url}?priceRule=${priceRule}`);
                    },
                  }))}
                />
              }
            >
              <button className="p-3 bg-black text-white">편집샵 상세</button>
            </Dropdown>
          </div>
          {/*  */}
          <div
            className="mt-5 bg-gray-100 border border-gray-200 grid grid-cols-2 gap-y-4"
            style={{ padding: 32 }}
          >
            <div className="flex items-center">
              <h3 className="w-28 font-bold">페칭코드</h3>
              <p>{id}</p>
            </div>
            <div className="flex items-center">
              <h3 className="w-28 font-bold">업데이트 시간</h3>
              <p>{dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>
            </div>
            <div className="flex items-center">
              <h3 className="w-28 font-bold">편집샵 코드</h3>
              <p>{shopCode}</p>
            </div>
          </div>
          {/*  */}
          <nav className="mt-5 sticky top-0 bg-white z-10">
            <ul className="flex border-b">
              {tabs.map((tab, i) => (
                <li key={tab}>
                  <button
                    style={{
                      width: 148,
                      padding: 16,
                      ...(i === currentTab && { borderBottom: '2px solid black' }),
                    }}
                    type="button"
                    onClick={() => {
                      scrollToRef(refs[i]);
                    }}
                  >
                    {tab}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
          {/*  */}
          <div className="mt-10" ref={refs[0]}>
            <h2 className="font-bold">진열설정</h2>
            <Table>
              <TableLeft>제품 활성</TableLeft>
              <TableRight>
                <div className="flex gap-4">
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isSellable"
                      checked={isSellable}
                      onChange={onChange}
                      value="true"
                    />
                    <span className="ml-1">활성화</span>
                  </label>
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isSellable"
                      checked={!isSellable}
                      onChange={onChange}
                      value="false"
                    />
                    <span className="ml-1">비활성화</span>
                  </label>
                </div>
              </TableRight>
              <TableDivider />
              <TableLeft>이동 후 결제 활성</TableLeft>
              <TableRight>
                <div className="flex gap-4">
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isShopPayActive"
                      checked={isShopPayActive}
                      onChange={onChange}
                      value="true"
                    />
                    <span className="ml-1">활성화</span>
                  </label>
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isShopPayActive"
                      checked={!isShopPayActive}
                      onChange={onChange}
                      value="false"
                    />
                    <span className="ml-1">비활성화</span>
                  </label>
                </div>
              </TableRight>
              <TableDivider />
              <TableLeft>페칭 결제 활성</TableLeft>
              <TableRight>
                <div className="flex gap-4">
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isFetchingPayActive"
                      data-value={true}
                      checked={isFetchingPayActive}
                      onChange={onChange}
                      value="true"
                    />
                    <span className="ml-1">활성화</span>
                  </label>
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isFetchingPayActive"
                      data-value={true}
                      checked={!isFetchingPayActive}
                      onChange={onChange}
                      value="false"
                    />
                    <span className="ml-1">비활성화</span>
                  </label>
                </div>
              </TableRight>
              <TableDivider />
              <TableLeft>네이버쇼핑 피드</TableLeft>
              <TableRight>
                <div className="flex gap-4">
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isNaverFeedTarget"
                      checked={isNaverFeedTarget}
                      onChange={onChange}
                      value="true"
                    />
                    <span className="ml-1">대상</span>
                  </label>
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isNaverFeedTarget"
                      checked={!isNaverFeedTarget}
                      onChange={onChange}
                      value="false"
                    />
                    <span className="ml-1">비대상</span>
                  </label>
                </div>
              </TableRight>
            </Table>
          </div>
          {/*  */}
          <div className="mt-14" ref={refs[1]}>
            <h2 className="font-bold">분류정보</h2>
            <Table>
              <TableLeft>편집샵 카테고리</TableLeft>
              <TableRight>
                <p>{shopCategoryName}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>페칭 카테고리</TableLeft>
              <TableRight>
                {state.categories?.map((category) => (
                  <Item
                    key={category.id}
                    id={category.id}
                    name={category.name}
                    handleDelete={() => handler.categoriesHandler('delete')([category])}
                    url={undefined}
                  />
                ))}
                <button
                  className="px-3 py-2 border border-black flex items-center"
                  onClick={() => {
                    handleModalShow('카테고리', true);
                  }}
                >
                  <PlusIcon className="w-4 h-4" />
                  <span className="ml-1">추가</span>
                </button>
              </TableRight>
              <TableDivider />
              <TableLeft>카테고리 키워드</TableLeft>
              <TableRight>
                <p>{categoryKeywords?.join(', ')}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>검색 키워드</TableLeft>
              <TableRight>
                <DebounceInput
                  placeholder="쉼표로 구분해 입력해주세요."
                  name="searchKeywords"
                  element="textarea"
                  className="w-full"
                  rows={4}
                  onChange={onChange}
                  value={
                    typeof searchKeywords == 'object'
                      ? searchKeywords?.join(', ')
                      : searchKeywords ?? ''
                  }
                />
              </TableRight>
              <TableDivider />
              <TableLeft>기획전</TableLeft>
              <TableRight>
                {state.exhibitions?.map((exhibition) => (
                  <Item
                    key={exhibition.exhibitionId}
                    id={exhibition.exhibitionId}
                    name={exhibition.name}
                    handleDelete={() => handler.exhibitionsHandler('delete')(exhibition)}
                    url={undefined}
                  />
                ))}
                <button
                  onClick={() => {
                    handleModalShow('기획전', true);
                  }}
                  className="px-3 py-2 border border-black flex items-center"
                >
                  <PlusIcon className="w-4 h-4" />
                  <span className="ml-1">추가</span>
                </button>
              </TableRight>
              <TableDivider />
              <TableLeft>편집샵 프로모션</TableLeft>
              <TableRight>
                {state.allPromotions?.map((promotion) => (
                  <Item
                    key={promotion.promotionId}
                    id={promotion.promotionId}
                    name={getPromotionName(promotion)}
                    handleDelete={() =>
                      handler.allPromotionsHandler('delete')([promotion])
                    }
                    url={undefined}
                  />
                ))}
                <button
                  onClick={() => {
                    handleModalShow('프로모션', true);
                  }}
                  className="px-3 py-2 border border-black flex items-center"
                >
                  <PlusIcon className="w-4 h-4" />
                  <span className="ml-1">추가</span>
                </button>
              </TableRight>
            </Table>
          </div>
          {/*  */}
          <div className="mt-14" ref={refs[2]}>
            <h2 className="font-bold">상품정보</h2>
            <Table>
              <TableLeft>배송</TableLeft>
              <TableRight>
                <div className="flex gap-4">
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isDomesticDelivery"
                      checked={isDomesticDelivery}
                      onChange={onChange}
                      value="true"
                    />
                    <span className="ml-1">국내배송</span>
                  </label>
                  <label className="m-0">
                    <input
                      type="radio"
                      name="isDomesticDelivery"
                      checked={!isDomesticDelivery}
                      onChange={onChange}
                      value="false"
                    />
                    <span className="ml-1">해외배송</span>
                  </label>
                </div>
              </TableRight>
              <TableDivider />
              <TableLeft>우선순위</TableLeft>
              <TableRight>
                <DebounceInput
                  type="number"
                  value={priority}
                  name="priority"
                  onChange={onChange}
                />
              </TableRight>
              <TableDivider />
              <TableLeft>상품 중요도</TableLeft>
              <TableRight>
                <p>{score}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>상품 데이터</TableLeft>
              <TableRight>
                <div className="flex gap-6 items-center">
                  <div className="flex items-center">
                    <h4>조회수</h4>
                    <p className="ml-1">{viewCount}</p>
                  </div>
                  <div className="flex items-center">
                    <h4>위시리스트</h4>
                    <p className="ml-1">{likeCount}</p>
                  </div>
                  <div className="flex items-center">
                    <h4>구매수</h4>
                    <p className="ml-1">{salesCount}</p>
                  </div>
                  <div className="flex items-center">
                    <h4>이동후결제</h4>
                    <p className="ml-1">(추가 예정)</p>
                  </div>
                  <div className="flex items-center">
                    <h4>장바구니</h4>
                    <p className="ml-1">{cartCount}</p>
                  </div>
                </div>
              </TableRight>
              <TableDivider />
              <TableLeft>편집샵</TableLeft>
              <TableRight>
                <p>{shopName}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>편집샵 브랜드</TableLeft>
              <TableRight>
                <p>{shopBrandName}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>페칭 브랜드</TableLeft>
              <TableRight>
                <p>{brandName}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>편집샵 상품명</TableLeft>
              <TableRight>
                <p>{originName}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>페칭 상품명</TableLeft>
              <TableRight>
                <DebounceInput
                  element="textarea"
                  name="name"
                  value={name}
                  onChange={onChange}
                  id=""
                  className="w-full"
                  rows={4}
                />
              </TableRight>
              <TableDivider />
              <TableLeft>컬러</TableLeft>
              <TableRight>
                <DebounceInput
                  type="text"
                  name="color"
                  onChange={onChange}
                  value={color}
                />
              </TableRight>
              <TableDivider />
              <TableLeft>이미지</TableLeft>
              <TableRight>
                <div style={{ width: 480, height: 480 }}>
                  <Carousel draggable arrows style={{ height: 480 }}>
                    {[...(imageUrl ? [imageUrl] : []), ...(imageUrls ?? [])].map(
                      (src, i) => (
                        <div key={i}>
                          <img
                            src={src}
                            style={{ height: 480, width: 480, objectFit: 'contain' }}
                          />
                        </div>
                      )
                    )}
                  </Carousel>
                </div>
              </TableRight>
              <TableDivider />
              <TableLeft>상품 설명</TableLeft>
              <TableRight>
                <p>{description}</p>
              </TableRight>
              <TableDivider />
              <TableLeft>상품 수정 내역</TableLeft>
              <TableRight>
                <ItemHistory data={itemHistory} />
              </TableRight>
            </Table>
          </div>
          {/*  */}
          <div className="mt-14" ref={refs[3]}>
            {!price?.length && (
              <h2 className="font-bold">판매 가능한 가격 정보가 없습니다.</h2>
            )}
            {!!price?.length && (
              <>
                <h2 className="font-bold">가격정보</h2>
                <div className="mt-4 flex">
                  {price?.map(([shopPriceRule, pricing], i) => (
                    <button
                      key={shopPriceRule}
                      onClick={() => setPricingIndex((p) => ({ ...p, shopIndex: i }))}
                      className={`
                    p-3 
                    ${
                      pricingIndex.shopIndex === i
                        ? 'bg-black text-white'
                        : 'border border-black'
                    }
                  `}
                    >
                      {pricing.country}
                    </button>
                  ))}
                  <span className="bg-black" style={{ width: 1, margin: '0 20px' }} />
                  <select
                    name=""
                    id=""
                    onChange={(e) => {
                      e.persist();
                      setPricingIndex((p) => ({
                        ...p,
                        optionIndex: Number(e?.target?.value ?? 0),
                      }));
                    }}
                    value={pricingIndex.optionIndex + ''}
                  >
                    {options?.map((option, i) => (
                      <option key={option.name} value={i + ''}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div style={{ marginTop: 20 }}>
                  <Select
                    value={
                      deliveryMethodState[price[pricingIndex.shopIndex][0]] ||
                      deliveryMethod
                    }
                    onChange={(value) => {
                      setDeliveryMethodState({
                        [price[pricingIndex.shopIndex][0]]: value,
                      });
                    }}
                    placeholder="배송 유형 선택"
                    style={{ width: 280 }}
                    options={availableDeliveryMethods?.data.map((method) => ({
                      label: method.name,
                      value: method.id,
                    }))}
                  />
                </div>
                <Table>
                  <div
                    className="bg-black text-white p-4 flex justify-between"
                    style={{ gridColumn: '1 / span 2' }}
                  >
                    <div className="flex items-center text-lg">
                      <p>{country}</p>
                      <span className="h-5 bg-gray-100 mx-2" style={{ width: 1 }} />
                      <p>{optionName}</p>
                      {isInPriceProcessingQueue && (
                        <span className="text-red-500 ml-4">가격 계산 중입니다.</span>
                      )}
                    </div>
                    <div className="flex gap-5">
                      <div className="flex items-center">
                        <h3>현재상태</h3>
                        <p
                          className={`
                        ${!!quantity ? 'text-blue-500' : 'text-red-500'} 
                        ml-1
                      `}
                        >
                          {!!quantity ? '재고' : '품절'}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <h3>상품 개별 활성화</h3>
                        <span className="ml-1">
                          <Switch
                            checked={
                              !!state?.price?.[pricingIndex.shopIndex]?.[1]?.[
                                pricingIndex.optionIndex
                              ].isActive
                            }
                            onChange={(value) => {
                              handler.priceHandler(pricingIndex)({ isActive: value });
                            }}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            width={36}
                            height={20}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <TableLeft>상품 원가</TableLeft>
                  <TableRight>
                    <p>
                      {originPrice} {currency}
                    </p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>편집샵 할인</TableLeft>
                  <TableRight>
                    <p>
                      {(originPrice ?? 0) - (discountedPrice ?? 0)} {currency} (
                      {Math.round(
                        100 - (100 * (discountedPrice ?? 0)) / (originPrice ?? 1)
                      )}
                      %)
                    </p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>편집샵 프로모션</TableLeft>
                  <TableRight>
                    <p>
                      {(fixedPrice ?? 0) - (discountedPrice ?? 0)} {currency}
                    </p>
                    {promotions && (
                      <div
                        style={{
                          margin: '0 10px',
                          border: '1px solid #ddd',
                          borderRadius: '4px',
                          padding: '5px 10px',
                        }}
                      >
                        {promotions.map((promotion) => (
                          <p>{`${promotion?.isAppliedWith}-${
                            promotion?.code || '코드 없음'
                          }-${promotion?.name}`}</p>
                        ))}
                      </div>
                    )}
                  </TableRight>
                  <TableDivider />
                  <TableLeft>편집샵 상품가</TableLeft>
                  <TableRight>
                    <p>
                      {fixedPrice} {currency}
                    </p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>환율 계산</TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(fixedPriceKor)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>부가세 환급</TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(-1 * (deductedVat ?? 0))}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>관부가세</TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(dutyAndTax)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>특소세</TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(specialTax)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>교육세</TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(educationalTax)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>배송비</TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(deliveryFee)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft style={{ background: 'black', color: 'white' }}>
                    편집샵 판매가
                  </TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(totalPrice)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>페칭 수수료</TableLeft>
                  <TableRight>
                    <div>
                      {`+ ${getKRWPrice(agencyFee)} ( `}
                      <DebounceInput
                        type="number"
                        value={Math.round(
                          Number(
                            state?.price?.[pricingIndex.shopIndex]?.[1]?.[
                              pricingIndex.optionIndex
                            ]?.agencyFeeRate
                          ) * 100 ?? 0
                        )}
                        onChange={(e) => {
                          handler.priceHandler(pricingIndex)({
                            agencyFeeRate: Number(e.target.value) / 100,
                          });
                        }}
                      />
                      {' % )'}
                      {/* 페칭 수수료 변동 시 표시 */}
                      {state?.price?.[pricingIndex.shopIndex]?.[1]?.[
                        pricingIndex.optionIndex
                      ]?.agencyFeeRate !== agencyFeeRate && (
                        <span className="text-red-500 ml-4">
                          {getKRWPrice(
                            Math.round(
                              (totalPrice ?? 0) *
                                Number(
                                  state?.price?.[pricingIndex.shopIndex]?.[1]?.[
                                    pricingIndex.optionIndex
                                  ]?.agencyFeeRate
                                )
                            )
                          )}
                        </span>
                      )}
                    </div>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>경유지 수수료</TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(waypointFee)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft>
                    추가 수수료 <span className="text-xs">(배대지 비용 등)</span>
                  </TableLeft>
                  <TableRight>
                    <p>{getKRWPrice(additionalPrice)}</p>
                  </TableRight>
                  <TableDivider />
                  <TableLeft style={{ background: 'black', color: 'white' }}>
                    페칭 판매가
                  </TableLeft>
                  <TableRight>
                    <p>
                      {getKRWPrice(finalPrice)}
                      {/* 페칭 판매가 변동 시 표시 */}
                      {state?.price?.[pricingIndex.shopIndex]?.[1]?.[
                        pricingIndex.optionIndex
                      ]?.agencyFeeRate !== agencyFeeRate && (
                        <span className="text-red-500 ml-4">
                          {getKRWPrice(
                            Math.round(
                              (totalPrice ?? 0) *
                                Number(
                                  state?.price?.[pricingIndex.shopIndex]?.[1]?.[
                                    pricingIndex.optionIndex
                                  ]?.agencyFeeRate
                                ) +
                                (additionalPrice ?? 0) +
                                (totalPrice ?? 0)
                            )
                          )}
                        </span>
                      )}
                    </p>
                  </TableRight>
                </Table>
              </>
            )}
          </div>
        </div>
        <div style={{ paddingTop: 300 }}></div>
        <div
          className="bg-gray-300 flex items-center justify-center fixed"
          style={{ padding: 30, left: 0, right: 0, bottom: 0 }}
        >
          <button
            className="bg-black text-white "
            style={{ padding: '12px 40px' }}
            onClick={onSubmit}
          >
            수정하기
          </button>
        </div>
      </div>
      <Portal elementId="modal-root">
        <ClassificationModal
          show={modal.categories}
          title="카테고리"
          onHide={(_categories) => {
            handleModalShow('카테고리', false);
            _categories &&
              handler.categoriesHandler('add')(
                _categories.map((_category) => ({
                  id: _category.fetchingCategoryId,
                  name: _category.fetchingCategoryPath,
                }))
              );
          }}
        />
        <ClassificationModal
          show={modal.exhibitions}
          title="기획전"
          onHide={(result) => {
            handleModalShow('기획전', false);
            !!result?.length &&
              handler.exhibitionsHandler('add')({
                exhibitionId: result[0].exhibitionId,
                gender: result[0].gender,
                isActive: false,
                name: result[0].name,
              });
          }}
        />
        <ClassificationModal
          show={modal.promotions}
          title="프로모션"
          onHide={(_promotions) => {
            handleModalShow('프로모션', false);
            _promotions &&
              handler.allPromotionsHandler('add')(
                _promotions.map((_promotion) => ({
                  ..._promotion,
                  promotionId: _promotion.id,
                  promotionName: _promotion.name,
                }))
              );
          }}
        />
      </Portal>
    </>
  );
};

export default ProductDetailV2;
