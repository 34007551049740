import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  border: 0.5px solid #b4b4b4;
`;

export const LeftTd = styled.td`
  background: #f0f0f0;
  width: 10%;
  padding: 13px 11px;
`;
export const Td = styled.td`
  padding: 13px 15px;
  display: flex;
  align-items: center;
`;

export const TextArea = styled.textarea`
  height: 22px;
  width: 60%;
`;

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin: 0 5px 0 0;
`;
export const Radio = styled.input.attrs({ type: 'radio' })`
  margin: 0 5px 0 0;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 0 20px 0 0;
  user-select: none;
`;

export const InputDate = styled.input.attrs({ type: 'date' })``;

export const Select = styled.select``;
