import {
  Button,
  Card,
  Check,
  GridTable,
  MenuTabs,
  OpenLink,
  OrderCount,
  OrderListTooltip,
  Radio,
  ReInput,
  ReMessageModal,
  RePagination,
  Select,
  ShippingCount,
  ShippingTrackingModal,
  Textarea,
  UpdateCustomDutyModal,
  UpdateInvoiceModal,
  UpdateShippingStatusModal,
} from 'components';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import {
  DELIVERY_STATUS,
  enumToList,
  ORDER_DATE_LIST,
  ORDER_SEARCH_TYPE,
  ORDER_SORT,
  PATH,
  PAY_METHOD,
  request,
  TAX_PAY_ISSUE,
  useCopyText,
  useObject,
  useOrderTable,
  useOrderTableDeliveryMethod,
  usePriceRule,
  useQuery,
  USER_TYPE,
  useShop,
} from 'services';
import { shippingCountState } from 'store';
import { IPriceRule, IShop, NOrder } from 'types';
import Fuse from 'fuse.js';
import { Checkbox, message } from 'antd';
import classnames from 'classnames';

interface State {
  isUpdateShippingOpen: boolean;
  shopOrderNumber: string;
  orderStatus: keyof typeof DELIVERY_STATUS;
  isUpdateDutyOpen: boolean;
  itemOrderNumber: string;
  shopSearchText: string;
  selectedOrderNumber: string[];
  isShippingInfoOpen: boolean;
  invoice: string;
  shippingCode: string;
  isChangeInvoiceOpen: boolean;
  isWaypoint: boolean;
}

const OrdersShippingPage = () => {
  const [
    {
      isUpdateShippingOpen,
      shopOrderNumber,
      orderStatus,
      isUpdateDutyOpen,
      itemOrderNumber,
      shopSearchText,
      selectedOrderNumber,
      isShippingInfoOpen,
      invoice,
      shippingCode,
      isChangeInvoiceOpen,
      isWaypoint,
    },
    setState,
    onChange,
    resetState,
  ] = useObject<State>({
    isUpdateShippingOpen: false,
    shopOrderNumber: '',
    orderStatus: 'PRODUCT_PREPARE',
    isUpdateDutyOpen: false,
    itemOrderNumber: '',
    shopSearchText: '',
    selectedOrderNumber: [],
    isShippingInfoOpen: false,
    invoice: '',
    shippingCode: '',
    isChangeInvoiceOpen: false,
    isWaypoint: false,
  });
  const { location } = useHistory();
  const copyText = useCopyText();
  const { query } = useQuery<{ tab: string }>();
  const {
    selectedIdx,
    setOrderState,
    searchType,
    searchText,
    onChangeOrder,
    onDateClick,
    onChangeCheckbox,
    resetOrderState,
    minOrderDate,
    maxOrderDate,
    page,
    total,
    list,
    limit,
    orderPath,
    userType,
    isLoading,
    payMethod,
    sort,
    isMessageOpen,
    phoneNumber,
    memoList,
    isMemoLoading,
    getMemo,
    shopIdList,
    priceRuleIdList,
    deliveryMethodIdList,
  } = useOrderTable();

  const [deliveryMethodIdOptions] = useOrderTableDeliveryMethod();

  const [shopList] = useShop();
  const [priceRuleList] = usePriceRule();
  const shippingCount = useRecoilValue(shippingCountState);

  const get = async () => {
    setOrderState({ isLoading: true });
    try {
      let params: any = {
        isAscending: sort === 'ORDER_DATE_ASC',
        limit,
        page,
        orderBy: 'ORDER_DATE',
        deliveryManageMode: true,
      };
      if (!!searchText) {
        params.searchText = searchText;
        params.searchType = searchType;
      }
      if (!!orderPath) params.orderPath = orderPath;
      if (!!payMethod) params.payMethod = payMethod;
      if (!!userType) params.userType = userType;
      if (!!minOrderDate)
        params.minOrderDate = `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
      if (!!maxOrderDate)
        params.maxOrderDate = `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
      if (!!shopIdList.length) params.shopIdList = shopIdList;
      if (!!priceRuleIdList.length) params.priceRuleIdList = priceRuleIdList;
      if (!!deliveryMethodIdList.length)
        params.deliveryMethodIdList = deliveryMethodIdList;
      if (query.tab === '1') params.orderStatus = 'PRODUCT_PREPARE';
      else if (query.tab === '2') params.orderStatus = 'WAYPOINT_ARRIVAL';
      else if (query.tab === '3') params.orderStatus = 'DOMESTIC_CUSTOMS_CLEARANCE';
      else if (query.tab === '4') params.orderStatus = 'CUSTOMS_CLEARANCE_DELAY';
      else if (query.tab === '5') params.orderStatus = 'SHIPPING_START';
      const { count, data } = await request.get<any, { count: number; data: any[] }>(
        '/commerce/order',
        {
          params,
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setOrderState({ total: count, list: data, isLoading: false });
    } catch (err) {
      setOrderState({ isLoading: false });
      console.log(err);
    }
  };

  const orderLink = (orderIndex: number) => {
    const order = list[orderIndex];
    const shopOrderList: NOrder.Detail['shopOrderList'] = order.shopOrderList;

    let url = `/order/${order.fetchingOrderNumber}`;

    if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.cancelInfo))
    )
      url += '?status=1&tab=1';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.returnInfo))
    )
      url += '?status=3&tab=1';
    else if (
      shopOrderList.some((shop) => shop.itemOrderList.some((item) => !!item.refundInfo))
    )
      url += '?status=4&tab=1';
    else url += '?status=0&tab=1';

    window.open(url);
  };

  const onBatchCopyOrderNumber = async () => {
    if (!selectedIdx.length) {
      toast.info('최소 1개의 주문을 선택해주세요.');
      return;
    }
    await copyText(selectedIdx.join('\n'));
  };

  const searchShopList: IShop[] = useMemo(() => {
    if (!shopSearchText) return shopList;

    const fuse = new Fuse(shopList, {
      keys: ['name'],
      includeScore: true,
    });
    return fuse
      .search(shopSearchText)
      .filter((item) => item.score! <= 0.33)
      .map(({ item }) => item);
  }, [shopSearchText, shopList.length]);

  const searchPriceRuleList: IPriceRule[] = useMemo(() => {
    if (!shopIdList.length) return [];

    return priceRuleList.filter((item) => shopIdList.indexOf(item.shopId) !== -1);
  }, [shopIdList]);

  const title: string = useMemo(() => {
    if (query.tab === '1') return '출고 중';
    else if (query.tab === '2') return '경유지 도착';
    else if (query.tab === '3') return '국내 통관 중';
    else if (query.tab === '4') return '통관 지연';
    else if (query.tab === '5') return '국내 배송 시작';
    else return '배송 관리';
  }, [query.tab]);

  useEffect(() => {
    get();
  }, [
    page,
    minOrderDate,
    maxOrderDate,
    limit,
    orderPath,
    userType,
    payMethod,
    sort,
    location.search,
    shopIdList.length,
    priceRuleIdList.length,
  ]);
  return (
    <OrderCount>
      <ShippingCount>
        <MenuTabs
          options={[
            { to: '/orders/shipping', name: '배송 관리' },
            { to: '/orders/shipping?tab=1', name: `출고 중 (${shippingCount.prepare})` },
            {
              to: '/orders/shipping?tab=2',
              name: `경유지 도착 (${shippingCount.inWaypoint})`,
            },
            {
              to: '/orders/shipping?tab=3',
              name: `국내 통관 중 (${shippingCount.domesticCustoms})`,
            },
            {
              to: '/orders/shipping?tab=4',
              name: `통관 지연 (${shippingCount.customsDelay})`,
            },
            {
              to: '/orders/shipping?tab=5',
              name: `국내 배송 시작 (${shippingCount.start})`,
            },
          ]}
        />
        <div className="wrapper">
          <div className="wrapper-title">{title}</div>
          <Card
            items={[
              {
                name: '검색어',
                renderItem: () => (
                  <div className="flex items-center gap-2">
                    <Select
                      size="xs"
                      value={searchType}
                      name="searchType"
                      onChange={onChangeOrder}
                      disabled={isLoading}
                    >
                      <option value="">전체</option>
                      {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                        <option key={key} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                    <Textarea.Autosize
                      value={searchText}
                      name="searchText"
                      onChange={onChangeOrder}
                      disabled={isLoading}
                      rows={1}
                      className="w-64"
                      placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
                      onEnter={get}
                    />
                  </div>
                ),
                stretch: true,
              },
              {
                name: '편집샵/국가별',
                renderItem: () => (
                  <div className="flex gap-2">
                    <div className="w-56 p-1 rounded border">
                      <div className="p-1">
                        <input
                          className="w-full border-gray-300 rounded p-1 text-xs"
                          placeholder="편집샵 검색"
                          value={shopSearchText}
                          name="shopSearchText"
                          onChange={onChange}
                        />
                      </div>
                      <div className="overflow-auto pl-1" style={{ height: 144 }}>
                        {searchShopList.map((item, key) => (
                          <label
                            key={key}
                            className="flex items-center gap-2 cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              disabled={isLoading}
                              checked={shopIdList.indexOf(item.shopId) !== -1}
                              onChange={() =>
                                setOrderState({
                                  shopIdList:
                                    shopIdList.indexOf(item.shopId) === -1
                                      ? [...shopIdList, item.shopId]
                                      : shopIdList.filter((id) => id !== item.shopId),
                                  priceRuleIdList:
                                    shopIdList.indexOf(item.shopId) === -1
                                      ? [
                                          ...priceRuleIdList,
                                          ...priceRuleList
                                            .filter(
                                              (priceRule) =>
                                                priceRule.shopId === item.shopId
                                            )
                                            .map((priceRule) => priceRule.priceRuleId),
                                        ]
                                      : priceRuleIdList.filter(
                                          (id) =>
                                            priceRuleList
                                              .filter(
                                                (priceRule) =>
                                                  priceRule.shopId === item.shopId
                                              )
                                              .map((priceRule) => priceRule.priceRuleId)
                                              .indexOf(id) === -1
                                        ),
                                })
                              }
                            />
                            <span className="text-gray-600 text-xs">{item.name}</span>
                          </label>
                        ))}
                      </div>
                    </div>

                    <div className="w-40 p-1 rounded border h-full">
                      <div className="p-1 text-xs text-gray-600">편집샵별 국가 선택</div>
                      <div className="overflow-auto pl-1 mt-1">
                        {searchPriceRuleList.map((item, key) => (
                          <label
                            key={key}
                            className="flex items-center gap-2 cursor-pointer"
                          >
                            <input
                              type="checkbox"
                              disabled={isLoading}
                              checked={priceRuleIdList.indexOf(item.priceRuleId) !== -1}
                              onChange={() =>
                                setOrderState({
                                  priceRuleIdList:
                                    priceRuleIdList.indexOf(item.priceRuleId) === -1
                                      ? [...priceRuleIdList, item.priceRuleId]
                                      : priceRuleIdList.filter(
                                          (id) => id !== item.priceRuleId
                                        ),
                                })
                              }
                            />
                            <span className="text-gray-600 text-xs">
                              {item.name} ({item.country})
                            </span>
                          </label>
                        ))}
                      </div>
                    </div>
                  </div>
                ),
                stretch: true,
              },
              {
                name: '주문일',
                renderItem: () => (
                  <div className="flex items-center gap-2">
                    {ORDER_DATE_LIST.map((item, key) => (
                      <Button
                        size="xs"
                        theme="ghost"
                        onClick={() => onDateClick(item)}
                        key={key}
                        disabled={isLoading}
                      >
                        {item}
                      </Button>
                    ))}
                    <ReInput
                      size="xs"
                      type="date"
                      value={minOrderDate}
                      name="minOrderDate"
                      onChange={onChangeOrder}
                      disabled={isLoading}
                    />
                    {' ~ '}
                    <ReInput
                      size="xs"
                      type="date"
                      value={maxOrderDate}
                      name="maxOrderDate"
                      onChange={onChangeOrder}
                      disabled={isLoading}
                    />
                  </div>
                ),
                stretch: true,
              },
              {
                name: '주문경로',
                renderItem: () => (
                  <Radio
                    id="orderPath"
                    options={enumToList(PATH)}
                    isInitialExisted
                    value={orderPath}
                    onChange={(orderPath) => setOrderState({ orderPath })}
                    disabled={isLoading}
                  />
                ),
                stretch: true,
              },
              {
                name: '결제수단',
                renderItem: () => (
                  <Radio
                    id="payMethod"
                    value={payMethod}
                    isInitialExisted
                    onChange={(payMethod) => setOrderState({ payMethod })}
                    options={enumToList(PAY_METHOD)}
                    disabled={isLoading}
                  />
                ),
                stretch: true,
              },
              {
                name: '회원등급',
                renderItem: () => (
                  <Radio
                    id="userType"
                    value={userType}
                    onChange={(userType) => setOrderState({ userType })}
                    options={enumToList(USER_TYPE)}
                    isInitialExisted
                    disabled={isLoading}
                  />
                ),
                stretch: true,
              },
              {
                name: '배송 방식',
                renderItem: () => (
                  <Checkbox.Group
                    disabled={isLoading}
                    options={deliveryMethodIdOptions}
                    value={deliveryMethodIdList}
                    onChange={(deliveryMethodIdList) =>
                      setOrderState({
                        deliveryMethodIdList: deliveryMethodIdList as number[],
                      })
                    }
                  />
                ),
                stretch: true,
              },
            ]}
          />
          <div className="mt-7 flex justify-center gap-3 text-lg">
            <Button onClick={get} isLoading={isLoading}>
              검색
            </Button>
            <Button
              theme="secondary"
              onClick={() => {
                resetOrderState();
                resetState();
              }}
            >
              초기화
            </Button>
          </div>
        </div>
        <div className="wrapper">
          <GridTable
            isLoading={isLoading}
            columns={[
              '주문번호',
              '주문일 (결제일)',
              '주문자',
              '배송정보',
              '판매처 (국가 / 배송방식)',
              '택배사 / 운송장 번호',
              '주문상품',
              '수량',
              '관세업무',
              '배송상태',
              '관리자기능',
            ]}
            maxHeight=""
            header={
              <div className="flex items-center justify-between">
                <div className="flex gap-2">
                  <Button size="sm" onClick={onBatchCopyOrderNumber}>
                    주문번호 일괄 복사
                  </Button>
                </div>
                <div className="flex gap-2">
                  <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                    {enumToList(ORDER_SORT).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                    <option value={50}>50개씩 보기</option>
                    <option value={100}>100개씩 보기</option>
                    <option value={200}>200개씩 보기</option>
                  </Select>
                </div>
              </div>
            }
            list={list}
            renderItem={(order, orderIndex) => (
              <Fragment key={orderIndex}>
                {order.shopOrderList.map((shop, shopIndex) => (
                  <div
                    key={shopIndex}
                    onClick={() => onChangeCheckbox(order.fetchingOrderNumber)}
                  >
                    <div aria-label="checkbox">
                      <input
                        type="checkbox"
                        onChange={() => {}}
                        checked={selectedIdx.indexOf(order.fetchingOrderNumber) !== -1}
                      />
                    </div>
                    <div aria-label="주문번호">
                      <span
                        className="link"
                        onClick={(e) => {
                          e.stopPropagation();
                          orderLink(orderIndex);
                        }}
                      >
                        {order.fetchingOrderNumber}
                      </span>
                    </div>
                    <div aria-label="주문일 (결제일)">
                      <div>{dayjs(order.orderedAt).format('YYYY.MM.DD HH:mm:ss')}</div>
                      {!!order.paidAt && (
                        <div className="text-gray-400">
                          ({dayjs(order.paidAt).format('YYYY.MM.DD HH:mm:ss')})
                        </div>
                      )}
                    </div>
                    <div aria-label="주문자">
                      <span>{order.userName}</span>
                      <div>{USER_TYPE[order.userType]}</div>
                    </div>
                    <div aria-label="배송정보">
                      <div>{order.recipientName}</div>
                      <div>{order.address}</div>
                      <div>{order.addressDetail}</div>
                      <div className="mt-2">{order.customsClearanceNumber}</div>
                      <div>{order.phoneNumber}</div>
                    </div>
                    <div aria-label="판매처 (국가 / 배송방식)">
                      <div>{shop.shopName}</div>
                      <div>
                        ({shop.shopCountry} /{' '}
                        <span
                          className={classnames({
                            'text-fassto': shop.deliveryMethodName === '파스토',
                            'text-eldex': shop.deliveryMethodName === '엘덱스',
                          })}
                        >
                          {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                        </span>
                        )
                      </div>
                      <div>
                        <button
                          className="hover:bg-gray-300 duration-300"
                          onClick={(e) => {
                            navigator.clipboard
                              .writeText(shop.vendorOrderNumber)
                              .then(() => {
                                message.info('복사되었습니다.');
                              })
                              .catch(() => {});
                          }}
                        >
                          {shop.vendorOrderNumber}
                        </button>
                      </div>
                    </div>
                    <div aria-label="택배사 / 운송장 번호">
                      <div>
                        {shop.itemOrderList[0].shippingCompanyName ||
                          shop.itemOrderList[0].waypointShippingCompanyName}{' '}
                        /{' '}
                        {shop.itemOrderList[0].invoice ||
                          shop.itemOrderList[0].waypointInvoice}
                      </div>
                      <div className="mt-1">
                        <Button
                          size="xs"
                          onClick={() =>
                            setState({
                              isChangeInvoiceOpen: true,
                              itemOrderNumber: shop.itemOrderList[0].itemOrderNumber,
                              invoice:
                                shop.itemOrderList[0].invoice ||
                                shop.itemOrderList[0].waypointInvoice,
                              shippingCode:
                                shop.itemOrderList[0].shippingCode ||
                                shop.itemOrderList[0].waypointShippingCode,
                              isWaypoint: !!shop.itemOrderList[0].waypointInvoice,
                            })
                          }
                        >
                          {!!shop.itemOrderList[0].invoice ||
                          !!shop.itemOrderList[0].waypointInvoice
                            ? '송장변경'
                            : '송장등록'}
                        </Button>
                        <Button
                          size="xs"
                          style={{ marginLeft: 4 }}
                          theme="secondary"
                          onClick={() =>
                            setState({
                              isShippingInfoOpen: true,
                              invoice:
                                shop.itemOrderList[0].invoice ||
                                shop.itemOrderList[0].waypointInvoice,
                              shippingCode:
                                shop.itemOrderList[0].shippingCode ||
                                shop.itemOrderList[0].waypointShippingCode,
                            })
                          }
                        >
                          배송추적
                        </Button>
                      </div>
                      <div className="mt-1">
                        <Button
                          size="xs"
                          theme="secondary"
                          onClick={() =>
                            setState({
                              isUpdateShippingOpen: true,
                              shopOrderNumber: shop.shopOrderNumber,
                              orderStatus: shop.orderStatus,
                            })
                          }
                        >
                          배송상태 변경
                        </Button>
                      </div>
                    </div>
                    <div aria-label="주문상품">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div key={itemIndex} className="flex-1 flex gap-3">
                            <img
                              className="max-w-[3.5rem] object-contain"
                              src={item.itemImageUrl}
                              alt={item.itemName}
                            />
                            <div className="flex-1">
                              <div>{item.brandName}</div>
                              <OpenLink
                                url={`/product/productDetail/v2/${Number(
                                  item.itemId
                                )}?mode=update`}
                              >
                                {item.itemName}
                              </OpenLink>
                              <div>{item.sizeName}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="수량">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div key={itemIndex} className="flex-1">
                            {item.quantity}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="관세업무">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div key={itemIndex} className="flex-1">
                            <div>전자납부번호 : {item.taxPaymentNumber}</div>
                            <div>납부결과 : {!!item.taxPaid ? '납부완료' : '미납'}</div>
                            <div>납부이슈 : {TAX_PAY_ISSUE[item.taxPayIssue]}</div>
                            <div>
                              <Button
                                size="xs"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setState({
                                    isUpdateDutyOpen: true,
                                    itemOrderNumber: item.itemOrderNumber,
                                  });
                                }}
                              >
                                납부정보 입력
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="배송상태">
                      <div className="flex flex-col h-full space-y-4">
                        {shop.itemOrderList.map((item, itemIndex) => (
                          <div key={itemIndex} className="flex-1">
                            {DELIVERY_STATUS[shop.orderStatus]}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div aria-label="관리자기능">
                      <div className="flex flex-col h-full gap-2 items-center">
                        <Button
                          size="xs"
                          className="w-full"
                          theme="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            orderLink(orderIndex);
                          }}
                        >
                          관리
                        </Button>
                        <Button
                          size="xs"
                          className="w-full"
                          onClick={(e) => {
                            e.stopPropagation();
                            setOrderState({
                              isMessageOpen: true,
                              phoneNumber: order.phoneNumber,
                            });
                          }}
                        >
                          메시지
                        </Button>
                        <OrderListTooltip
                          isLoading={isMemoLoading}
                          hasMemo={order.hasMemo}
                          list={memoList}
                          fetchingOrderNumber={order.fetchingOrderNumber}
                          onMouseLeave={() =>
                            setOrderState({ memoList: [], isMemoLoading: false })
                          }
                          onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Fragment>
            )}
            isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
            onSelectAll={(checked) =>
              setOrderState({
                selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
              })
            }
          />
          <div className="flex justify-between items-center">
            <div className="pl-2 text-gray-500 tracking-widest">
              <span>총</span>
              <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
              <span>개</span>
            </div>
            <RePagination
              currentPage={page}
              onPageChange={(page) => setOrderState({ page })}
              totalCount={total}
              pageSize={limit}
            />
          </div>
        </div>
        <UpdateShippingStatusModal
          isOpen={isUpdateShippingOpen}
          onClose={() =>
            setState({
              isUpdateShippingOpen: false,
              shopOrderNumber: '',
              orderStatus: 'PRODUCT_PREPARE',
            })
          }
          shopOrderNumber={shopOrderNumber}
          onSuccess={get}
          orderStatus={orderStatus}
        />
        <UpdateCustomDutyModal
          isOpen={isUpdateDutyOpen}
          onClose={() => setState({ isUpdateDutyOpen: false, itemOrderNumber: '' })}
          itemOrderNumber={itemOrderNumber}
          onComplete={get}
        />
        <ReMessageModal
          isOpen={isMessageOpen}
          onClose={() => setOrderState({ isMessageOpen: false, phoneNumber: '' })}
          phoneNumber={phoneNumber}
        />
        <ShippingTrackingModal
          isOpen={isShippingInfoOpen}
          onClose={() =>
            setState({ isShippingInfoOpen: false, invoice: '', shippingCode: '' })
          }
          invoice={invoice}
          shippingCode={shippingCode}
        />
        <UpdateInvoiceModal
          isOpen={isChangeInvoiceOpen}
          onSuccess={get}
          onClose={() =>
            setState({
              isChangeInvoiceOpen: false,
              itemOrderNumber: '',
              invoice: '',
              shippingCode: '',
              isWaypoint: false,
            })
          }
          apiUrl={`/commerce/order/item-order/${itemOrderNumber}/invoice`}
          invoice={invoice}
          shippingCode={shippingCode}
          isWaypoint={isWaypoint}
        />
      </ShippingCount>
    </OrderCount>
  );
};

export default OrdersShippingPage;
