import { Button, CouponModal, GridTable, RePagination } from 'components';
import dayjs from 'dayjs';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { request, useObject } from 'services';

interface ICoupon {
  createdAt: string;
  discountType: 'PERCENT' | 'FIXED';
  discountValue: number;
  methods:
    | 'CARD'
    | 'KAKAOPAY'
    | 'NAVERPAY'
    | 'DEPOSIT'
    | 'BANK_TRANSFER'
    | 'VIRTUAL_BANK_ACCOUNT'
    | 'ESCROW_BANK_TRANSFER'
    | 'ESCROW_VIRTUAL_BANK_ACCOUNT';
  name: string;
  priceMin: number;
  uuid: string;
  deletedAt: string;
  expireAt: string;
  pointValue: number;
  priceMax: number;
  usedAt: string;
  couponId: number;
  userCouponId: number;
}

export interface Props {
  deleted: number;
  available: number;
  unused: number;
  total: number;
  onRefresh: () => void;
}
interface State {
  coupons: ICoupon[];
  page: number;
  count: number;
  pageSize: number;
  isOpen: boolean;
  isDeleting: boolean;
  couponIdx: number;
}

const CouponLength: FunctionComponent<{ name: string; length: number }> = ({
  name,
  length,
}) => {
  return (
    <div className="font-semibold text-lg">
      {name} <span className="text-blue-500 text-2xl ml-2 mr-1">{length}</span>건
    </div>
  );
};

const UserCoupon: FunctionComponent<Props> = ({
  deleted,
  unused,
  available,
  total,
  onRefresh,
}) => {
  const [{ coupons, page, count, pageSize, isOpen, isDeleting, couponIdx }, setState] =
    useObject<State>({
      coupons: [],
      page: 1,
      count: 3,
      pageSize: 10,
      isOpen: false,
      isDeleting: false,
      couponIdx: 0,
    });
  const { idx } = useParams<{ idx: string }>();

  const get = async () => {
    try {
      const data = await request.get<any, { count: number; data: ICoupon[] }>(
        `/commerce/user/${idx}/coupons`,
        {
          params: {
            limit: pageSize,
            page,
          },
        }
      );
      setState({ count: data.count || 0, coupons: data.data || [] });
    } catch (err) {
      console.log(err);
    }
  };
  const deleteCoupon = async (userCouponId: number) => {
    if (!window.confirm('삭제하시겠습니까?')) return;

    setState({ isDeleting: true, couponIdx: userCouponId });
    try {
      await request.delete('/commerce/coupon/user-coupon/bulk', {
        params: { userCouponIdList: [userCouponId] },
      });
      toast.success('삭제되었습니다.');
      get();
      onRefresh();
    } catch (err) {
      console.dir(err);
    }
  };
  useEffect(() => {
    get();
  }, [page, pageSize]);
  return (
    <>
      <div className="p-4">
        <div className="flex gap-4">
          <CouponLength name="발급된 쿠폰" length={total} />
          <CouponLength name="사용가능 쿠폰" length={available} />
          <CouponLength name="미사용 쿠폰" length={unused} />
          <CouponLength name="삭제된 쿠폰" length={deleted} />
          <Button size="sm" onClick={() => setState({ isOpen: true })}>
            쿠폰 발행
          </Button>
        </div>
        <div className="mt-14">
          <GridTable
            columns={[
              '발급일자 / 사용가능기간',
              '쿠폰번호',
              '쿠폰명',
              '사용가능 기준금액',
              '사용가능 결제수단',
              '할인액(률)',
              '적립액',
              '사용일',
              '삭제',
            ]}
            noSelection
            list={coupons}
            renderItem={(item, key) => (
              <div key={key}>
                <div>
                  <div>발행: {dayjs(item.createdAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
                  <div>사용가능: {item.expireAt}</div>
                </div>
                <div>{item.uuid}</div>
                <div>{item.name}</div>
                <div>{item.priceMin}원</div>
                <div>{item.methods}</div>
                <div>
                  {item.discountValue}
                  {item.discountType === 'FIXED' && '원'}
                  {item.discountType === 'PERCENT' && '%'}
                </div>
                <div>{item.pointValue || 0}원</div>
                <div>
                  {!!item.usedAt && dayjs(item.usedAt).format('YYYY.MM.DD. HH:mm:ss')}
                </div>
                <div>
                  {item.deletedAt ? (
                    <div>
                      <div>삭제됨</div>
                      <div>{dayjs(item.deletedAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
                    </div>
                  ) : (
                    <Button
                      size="xs"
                      isLoading={isDeleting && item.userCouponId === couponIdx}
                      onClick={() => deleteCoupon(item.userCouponId)}
                    >
                      삭제
                    </Button>
                  )}
                </div>
              </div>
            )}
          />
        </div>
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setState({ page })}
            totalCount={total}
            pageSize={pageSize}
          />
        </div>
      </div>
      <CouponModal
        isOpen={isOpen}
        onClose={() => setState({ isOpen: false })}
        onRefresh={() => {
          get();
          onRefresh();
        }}
      />
    </>
  );
};

export default UserCoupon;
