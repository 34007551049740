import fetchingAPI from 'api/fetchingAPI';
import { triggerState } from 'pages/ManageMainContent';
import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

export const getGender = (mode: 'm' | 'w') => (mode === 'm' ? '남성' : '여성');
const getFullGender = (mode: 'm' | 'w') => (mode === 'm' ? 'men' : 'women');

export type BigBannerItem = {
  requestType?: 'NONE' | 'UPDATE' | 'CREATE' | 'DELETE';
  idx: number;
  title: string;
  subtitle: string;
  imageUrl: string;
  mobileImageUrl: string;
  pcImageUrl: string;
  href: string;
  createdAt?: string | null; //ISOString
  updatedAt?: string | null; //ISOString
  deletedAt?: string | null; //ISOString
  startAt?: string | null; //2021-09-01 21:35:44,
  expireAt?: string | null; //2021-09-01 21:35:44,
  order: number;
  isVisible: boolean;
  uiComponentId?: number; //무시하면 됨.
};
export type Item = {
  idx: number;
  uiComponentId: number;
  itemId: number;
  createdAt?: string | null; //ISOString
  updatedAt?: string | null; //ISOString
  deletedAt?: string | null; //ISOString
  startAt?: string | null; //2021-09-01 21:35:44,
  expireAt?: string | null; //2021-09-01 21:35:44,
  order: number;
  isVisible: boolean;
};
export type MainLayout = {
  idx: number;
  type: Main.Type;
  gender: 'M' | 'W';
  fixed: boolean;
  name: string;
  order: number;
  title: string | null;
  subtitle: string | null;
  content: any;
  backgroundColor: string | null;
  href: string | null;
  imageUrl: string | null;
  mobileImageUrl: string | null;
  pcImageUrl: string | null;
  createdAt?: string | null; //2021-09-01
  updatedAt?: string | null; //2021-09-01
  startAt?: string | null; //2021-09-01 21:35:44,
  deletedAt?: string | null; //ISOString
  expireAt?: string | null; //2021-09-01 21:35:44,
  exhibitionId: number | null;
  exhibitionTabId: number | null;
  promotionId: number | null;
  promotions: number[] | null;
  immutable: boolean;
  isActive: boolean;
  itemList?: Item[];
  bigBannerList?: BigBannerItem[];
  showcaseId?: number;
};
export namespace Main {
  export type Type =
    | 'BIG_BANNER'
    | 'MAIN_CATEGORIES'
    | 'RECOMMEND_CATEGORY'
    | 'TEXT_BAND_BANNER'
    | 'IMAGE_BAND_BANNER'
    | 'PROMOTION_INFO'
    | 'FETCHING_DEAL'
    | 'DESIGN_COMPONENT_WITH_IMAGE'
    | 'DESIGN_COMPONENT_WITHOUT_IMAGE'
    | 'RECOMMEND_BRAND'
    | 'SELECT_SHOPS'
    | 'FETCHING_RANKING'
    | 'BRAND_RANKING'
    | 'TIME_DEAL'
    | 'SHOWCASE'
    | 'REVIEW'
    | 'NEW_IN'
    | 'DESIGN_COMPONENT_WITHOUT_IMAGE_CATEGORY'
    | 'DESIGN_COMPONENT_WITH_IMAGE_CATEGORY'
    | 'NEW_EXHIBITION'
    | 'POPULAR_BRANDS';
}
export const useGetLayouts = (mode: 'm' | 'w', trigger?: any): MainLayout[] => {
  const [data, setData] = useState<MainLayout[]>([]);

  useEffect(() => {
    fetchingAPI
      .getUIComponents(getFullGender(mode))
      .then((data: any) => setData(data.data.list))
      .catch(() => setData([]));
  }, [mode, trigger]);

  return data;
};
type Orderable = { order: number };
export const setOrder = <T extends Orderable>(layouts: T[]): T[] =>
  [...layouts].sort((a, b) => a.order - b.order).map((v, i) => ({ ...v, order: i }));
export const orderDecreament = <T extends Orderable>(layout: T): T => ({
  ...layout,
  order: layout.order - 1,
});
export const orderIncreament = <T extends Orderable>(layout: T): T => ({
  ...layout,
  order: layout.order + 1,
});

type SaveLayoutsProps = (MainLayout & { requestType: 'UPDATE' | 'CREATE' | 'DELETE' })[];
export const saveLayouts = async (mode: 'm' | 'w', payload: SaveLayoutsProps) => {
  try {
    const { data } = await fetchingAPI.postUIComponents(getFullGender(mode), payload);
    if (data.success) {
      alert('성공적으로 수정했습니다.');
    } else {
      alert('수정에 실패했습니다.');
      window.location.reload();
    }
  } catch (error) {
    alert(`err in saveLayouts, ${error}`);
  }
};

export const useMainLayout = (
  gender: 'm' | 'w'
): {
  state: [MainLayout[], React.Dispatch<React.SetStateAction<MainLayout[]>>];
  reload: () => Promise<void>;
  isLoading: boolean;
} => {
  const trigger = useRecoilValue(triggerState);
  const [data, setData] = useState<MainLayout[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const load = async () => {
    if (!isLoading) {
      setIsLoading(true);
      const data = await fetchingAPI.getUIComponents(getFullGender(gender));
      setData(
        data.data?.list
          ?.sort((a, b) => a.order - b.order)
          ?.map((v, i) => ({ ...v, order: i })) ?? []
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load();
  }, [gender, trigger]);

  return { state: [data, setData], reload: load, isLoading };
};
