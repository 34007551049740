import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 150;
  height: 100vh;
  width: 100%;
`;

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 170px;
`;

export const Title = styled.div`
  width: 100%;
  padding: 15px 20px;
  background-color: #333030;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
`;

export const ShopName = styled.div`
  width: 100%;
  padding: 15px 20px;
  background-color: #eee7e7;

  font-weight: bold;
`;

export const TitleBar = styled.div`
  display: flex;
  padding: 10px 15px;
  width: 100%;
  background-color: white;
  overflow-x: auto;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
`;

export const TitleItem = styled.div`
  white-space: nowrap;
  font-weight: bold;
  padding: 10px 15px;
  border-right: 1px solid #bfb9b9;
  cursor: pointer;
  &:hover {
    color: #83a9d1;
  }
`;

export const Button = styled.button`
  padding: 10px 50px;
  border: none;
  background-color: ${(props) => props.BC};
  color: white;
  font-weight: bold;
  &:hover {
    background-color: #53bb9c;
  }
  &:focus {
    outline: none;
  }
`;

export const Content = styled.div`
  overflow-y: auto;
  padding-top: 170px;
  padding-bottom: 78px;
  background-color: white;
  z-index: 150;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #5d5858;
  padding: 15px 20px;
  height: 78px;
`;
