import React from 'react';
import type { FunctionComponent } from 'react';
import { IModal, NOrder } from 'types';
import { Button, FormItem, ReInput, ReModal, Select } from 'components';
import {
  enumToList,
  ORDER_EXCHANGE_REASON,
  request,
  useObject,
  useOrderDetail,
} from 'services';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface Props extends IModal {
  exchangeOrderList: Array<Pick<NOrder.ItemOrderDetail, 'itemName' | 'itemOrderNumber'>>;
}
interface State {
  reasonType: keyof typeof ORDER_EXCHANGE_REASON | '';
  isLoading: boolean;
  reasonDirectInput: string;
}

const OrderExchangeModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  exchangeOrderList,
}) => {
  if (!isOpen) return null;
  const [{ reasonType, isLoading, reasonDirectInput }, setState, onChange] =
    useObject<State>({
      reasonType: '',
      isLoading: false,
      reasonDirectInput: '',
    });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const [orderState, setOrderState, { refreshOrderState }] = useOrderDetail();

  const onSubmit = async () => {
    if (!reasonType) {
      toast.info('교환 사유를 선택해 주세요.');
      return;
    }
    if (!window.confirm('해당 상품을 교환 요청으로 전환하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      let data: any = {
        fetchingOrderNumber,
        itemOrderList: exchangeOrderList,
        reasonType,
      };
      if (reasonType === 'DIRECT_INPUT') data.reasonDirectInput = reasonDirectInput;
      await request.post('/commerce/order/order-exchange', data);
      refreshOrderState();
      toast.success('교환 요청으로 전환되었습니다.');
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isLoading: false });
    }
  };

  return (
    <ReModal
      title="교환 요청 전환"
      description="교환 사유를 선택해주세요."
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="space-y-4">
        <FormItem label="상품명" readOnly>
          {exchangeOrderList.map((item, key) => (
            <div key={key}>{item.itemName}</div>
          ))}
        </FormItem>
        <FormItem label="교환 사유 선택">
          <Select value={reasonType} name="reasonType" onChange={onChange}>
            <option value="" selected disabled>
              선택
            </option>
            {enumToList(ORDER_EXCHANGE_REASON).map((item, key) => (
              <option key={key} value={item.value}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormItem>
        {reasonType === 'DIRECT_INPUT' && (
          <FormItem label="교환사유 직접 입력">
            <ReInput
              id="교환사유 직접 입력"
              value={reasonDirectInput}
              name="reasonDirectInput"
              onChange={onChange}
              className="w-full"
            />
          </FormItem>
        )}
        <div className="flex justify-center">
          <Button size="sm" isLoading={isLoading} onClick={onSubmit}>
            교환 요청 전환
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default OrderExchangeModal;
