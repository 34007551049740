import React from "react";

import { Table } from "./style";

export default ({ values }) => (
  <Table>
    <tr>
      {
        values.map(({ name, value }) => (<td>
          {name}
          <br/>
          {value}
        </td>))
      }
    </tr>
  </Table>
)
