import { Button, FormItem, Radio, ReInput, ReModal, Textarea } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { request, useObject, useOrderDetail } from 'services';
import { IModal } from 'types';

export interface Props extends IModal {
  shopOrderNumber: string;
  shopId: number;
}
interface State {
  isLoading: boolean;
  sendPush: boolean;
  cardApprovalNumber: string;
  vendorOrderNumber: string;
  memoContent: string;
  messageContent: string;
  messageType: 'SMS' | 'KAKAO';
  customShopId: string;
  accountType: 'CURRENT' | 'DIRECT';
  shopIdList: Array<{
    name: string;
    value: number;
  }>;
  tempMessageContent: string;
}

const OrderCompleteModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  shopOrderNumber,
  shopId,
}) => {
  if (!isOpen) return null;
  const [
    {
      isLoading,
      sendPush,
      cardApprovalNumber,
      vendorOrderNumber,
      messageType,
      messageContent,
      memoContent,
      customShopId,
      accountType,
      shopIdList,
      tempMessageContent,
    },
    setState,
    onChange,
  ] = useObject<State>({
    isLoading: false,
    sendPush: true,
    cardApprovalNumber: '',
    vendorOrderNumber: '',
    memoContent: '',
    messageContent: '',
    messageType: 'KAKAO',
    customShopId: '',
    accountType: 'CURRENT',
    shopIdList: [],
    tempMessageContent: '',
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const [orderState, setOrderState, { refreshOrderState }] = useOrderDetail();

  const get = async () => {
    try {
      const { data } = await request.get<
        any,
        { data: Array<{ idx: number; id: string }> }
      >('/shop/fetching/account', { params: { shopId } });
      setState({
        shopIdList: data
          .filter((item) => !/\+/.test(item.id))
          .map((item) => ({ name: item.id, value: item.idx })),
        accountType: !!data.length ? 'CURRENT' : 'DIRECT',
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getTemplate = async () => {
    try {
      const { content } = await request.post<any, { content: string }>(
        '/commerce/order/message/preview',
        {
          fetchingOrderNumber,
          type: 'ORDER_COMPLETE',
          shopOrderNumber,
        }
      );
      setState({ messageContent: content });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    if (!window.confirm('발주 완료로 전환하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      let data: any = {
        sendPush,
        cardApprovalNumber,
        fetchingShopAccountId: accountType === 'DIRECT' ? null : accountType,
        memoContent,
        vendorOrderNumber,
        fetchingShopAccountDirectInput: accountType === 'DIRECT' ? customShopId : null,
      };
      if (sendPush) {
        data.messageType = messageType;
        data.messageContent =
          messageType === 'KAKAO' ? messageContent : tempMessageContent;
      }
      await request.post(
        `/commerce/order/shop-order/${shopOrderNumber}/order-complete`,
        data
      );
      refreshOrderState();
      toast.success(
        '발주 완료로 전환되었습니다. 해당 주문 정보는 주문 정보 탭에서 확인 가능합니다.'
      );
      onClose();
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (sendPush) getTemplate();
  }, [sendPush]);
  return (
    <ReModal
      title="발주 완료 전환"
      isOpen={isOpen}
      onClose={() => {
        if (window.confirm('작업을 중단하시겠습니까?')) onClose();
      }}
    >
      <div className="space-y-4">
        <FormItem label="계정정보">
          <Radio
            mode="vertical"
            options={[...shopIdList, { name: '직접 입력', value: 'DIRECT' }]}
            value={accountType}
            onChange={(accountType) => setState({ accountType })}
            id="accountType"
          />
          {accountType === 'DIRECT' && (
            <div className="mt-1">
              <ReInput
                placeholder="계정명 직접 입력"
                size="xs"
                className="w-full"
                type="email"
                value={customShopId}
                name="customShopId"
                onChange={onChange}
              />
            </div>
          )}
        </FormItem>
        <FormItem label="판매처 주문번호">
          <ReInput
            id="판매처 주문번호"
            placeholder="주문번호 입력"
            className="w-full"
            value={vendorOrderNumber}
            name="vendorOrderNumber"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="승인번호">
          <ReInput
            id="승인번호"
            className="w-full"
            placeholder="카드사 승인번호"
            value={cardApprovalNumber}
            name="cardApprovalNumber"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="고객 알림 전달 여부">
          <Switch checked={sendPush} onChange={(sendPush) => setState({ sendPush })} />
        </FormItem>
        {sendPush && (
          <>
            <FormItem label="고객 알림 채널">
              <Radio
                id="messageType"
                options={[
                  { name: '카카오', value: 'KAKAO' },
                  { name: 'SMS (메시지 수정 발송 시)', value: 'SMS' },
                ]}
                value={messageType}
                onChange={(messageType) =>
                  setState({
                    messageType,
                    tempMessageContent: messageType === 'SMS' ? messageContent : '',
                  })
                }
              />
            </FormItem>
            <FormItem label="고객 알림 내용 확인">
              <Textarea
                readOnly={messageType === 'KAKAO'}
                rows={8}
                value={tempMessageContent || messageContent}
                name="tempMessageContent"
                onChange={onChange}
              />
            </FormItem>
          </>
        )}
        <FormItem label="관리자 메모">
          <Textarea rows={4} value={memoContent} name="memoContent" onChange={onChange} />
        </FormItem>
        <div className="flex justify-center">
          <Button onClick={onSubmit} isLoading={isLoading}>
            발주 완료로 전환하기
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default OrderCompleteModal;
