import {
  Button,
  Card,
  GridTable,
  MenuTitle,
  NumberStat,
  OpenLink,
  OrderCount,
  OrderListTooltip,
  Radio,
  ReInput,
  RePagination,
  Select,
  Textarea,
} from 'components';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  enumToList,
  ORDER_CANCEL_REASON,
  ORDER_CANCEL_STATUS,
  ORDER_DATE_LIST,
  ORDER_SEARCH_TYPE,
  ORDER_SORT,
  PATH,
  PAY_METHOD,
  request,
  useObject,
  useOrderTable,
  useOrderTableDeliveryMethod,
  USER_TYPE,
} from 'services';
import { priceFormat } from 'utils';
import classnames from 'classnames';
import { Checkbox } from 'antd';

interface State {
  cancelReason: string;
}

const OrdersCancelPage = () => {
  const [{ cancelReason }, setState] = useObject<State>({
    cancelReason: '',
  });
  const {
    searchText,
    selectedIdx,
    setOrderState,
    searchType,
    resetOrderState,
    onDateClick,
    onChangeCheckbox,
    onChangeOrder,
    minOrderDate,
    maxOrderDate,
    orderPath,
    payMethod,
    userType,
    total,
    page,
    limit,
    list,
    sort,
    isLoading,
    memoList,
    isMemoLoading,
    getMemo,
    minCancelDate,
    maxCancelDate,
    deliveryMethodIdList,
  } = useOrderTable();

  const [deliveryMethodIdOptions] = useOrderTableDeliveryMethod();

  const get = async () => {
    setOrderState({ isLoading: true });
    let params: any = {
      limit,
      page,
      orderBy: 'ORDER_DATE',
      isAscending: sort === 'ORDER_DATE_ASC',
    };
    if (!!searchText) params.searchText = searchText;
    if (!!searchType) params.searchType = searchType;
    if (!!orderPath) params.orderPath = orderPath;
    if (!!cancelReason) params.cancelReasonType = cancelReason;
    if (!!payMethod) params.payMethod = payMethod;
    if (!!userType) params.userType = userType;
    if (!!minOrderDate)
      params.minOrderDate = `${dayjs(minOrderDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
    if (!!maxOrderDate)
      params.maxOrderDate = `${dayjs(maxOrderDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
    if (!!minCancelDate)
      params.minCancelDate = `${dayjs(minCancelDate).format('YYYY-MM-DDTHH:mm:ss')}.000Z`;
    if (!!maxCancelDate)
      params.maxCancelDate = `${dayjs(maxCancelDate).format('YYYY-MM-DDT23:59:59')}.000Z`;
    if (!!deliveryMethodIdList.length) params.deliveryMethodIdList = deliveryMethodIdList;
    try {
      const { data, count } = await request.get<any, { data: any[]; count: number }>(
        'commerce/order/order-cancel',
        {
          params,
          headers: {
            Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
          },
        }
      );
      setOrderState({ total: count, list: data, isLoading: false });
    } catch (err) {
      setOrderState({ isLoading: false });
      console.log(err);
    }
  };

  const onAllCancel = async () => {
    if (!selectedIdx.length) {
      toast.info('취소할 주문을 선택해주세요.');
      return;
    }
    if (!window.confirm('일괄 취소하시겠습니까?')) return;
  };

  useEffect(() => {
    get();
  }, [
    minOrderDate,
    maxOrderDate,
    orderPath,
    payMethod,
    userType,
    page,
    limit,
    sort,
    cancelReason,
    minCancelDate,
    maxCancelDate,
  ]);
  return (
    <OrderCount>
      <MenuTitle title="주문취소" />
      <div className="wrapper">
        <div className="wrapper-title">주문 취소 관리</div>
        <Card
          items={[
            {
              name: '검색어',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  <Select
                    size="xs"
                    value={searchType}
                    name="searchType"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  >
                    <option value="">전체</option>
                    {enumToList(ORDER_SEARCH_TYPE).map((item, key) => (
                      <option key={key} value={item.value}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                  <Textarea.Autosize
                    value={searchText}
                    name="searchText"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                    rows={1}
                    className="w-64"
                    placeholder="쉼표(,) 다중 검색, 개행 다중 검색 가능"
                    onEnter={get}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      onClick={() => onDateClick(item)}
                      key={key}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minOrderDate}
                    name="minOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxOrderDate}
                    name="maxOrderDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '취소일',
              renderItem: () => (
                <div className="flex items-center gap-2">
                  {ORDER_DATE_LIST.map((item, key) => (
                    <Button
                      size="xs"
                      theme="ghost"
                      key={key}
                      onClick={() => onDateClick(item, 'cancel')}
                      disabled={isLoading}
                    >
                      {item}
                    </Button>
                  ))}
                  <ReInput
                    size="xs"
                    type="date"
                    value={minCancelDate}
                    name="minCancelDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                  {' ~ '}
                  <ReInput
                    size="xs"
                    type="date"
                    value={maxCancelDate}
                    name="maxCancelDate"
                    onChange={onChangeOrder}
                    disabled={isLoading}
                  />
                </div>
              ),
              stretch: true,
            },
            {
              name: '주문경로',
              renderItem: () => (
                <Radio
                  isInitialExisted
                  options={enumToList(PATH)}
                  value={orderPath}
                  onChange={(orderPath) => setOrderState({ orderPath })}
                  id="orderPath"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '결제수단',
              renderItem: () => (
                <Radio
                  value={payMethod}
                  onChange={(payMethod) => setOrderState({ payMethod })}
                  options={enumToList(PAY_METHOD)}
                  id="payMethod"
                  isInitialExisted
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '회원등급',
              renderItem: () => (
                <Radio
                  value={userType}
                  isInitialExisted
                  onChange={(userType) => setOrderState({ userType })}
                  options={enumToList(USER_TYPE)}
                  id="userType"
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '취소 사유',
              renderItem: () => (
                <Radio
                  value={cancelReason}
                  onChange={(cancelReason) => setState({ cancelReason })}
                  options={enumToList(ORDER_CANCEL_REASON)}
                  id="cancelReason"
                  isInitialExisted
                  disabled={isLoading}
                />
              ),
              stretch: true,
            },
            {
              name: '배송 방식',
              renderItem: () => (
                <Checkbox.Group
                  disabled={isLoading}
                  options={deliveryMethodIdOptions}
                  value={deliveryMethodIdList}
                  onChange={(deliveryMethodIdList) =>
                    setOrderState({
                      deliveryMethodIdList: deliveryMethodIdList as number[],
                    })
                  }
                />
              ),
              stretch: true,
            },
          ]}
        />
        <div className="mt-7 flex justify-center gap-3 text-lg">
          <Button onClick={get} isLoading={isLoading}>
            검색
          </Button>
          <Button theme="secondary" onClick={() => resetOrderState()}>
            초기화
          </Button>
        </div>
      </div>
      <div className="wrapper">
        <GridTable
          isLoading={isLoading}
          columns={[
            '주문번호',
            '취소일',
            '주문자',
            '판매처 (국가 / 배송방식)',
            '주문상품',
            '수량',
            '결제금액',
            '총 결제금액',
            '결제수단',
            '취소상태 (사유)',
            '관리자기능',
          ]}
          maxHeight=""
          header={
            <div className="flex items-center justify-between">
              <div className="flex gap-2">
                <Select size="sm">
                  <option value="checkedOrderIds">선택한 주문의</option>
                </Select>
                <Select size="sm">
                  <option value="ChangeOrderState">주문상태를</option>
                </Select>
                <Button size="sm" onClick={onAllCancel}>
                  {'일괄처리 >'}
                </Button>
              </div>
              <div className="flex gap-2">
                <Select size="sm" value={sort} name="sort" onChange={onChangeOrder}>
                  {enumToList(ORDER_SORT).map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select size="sm" value={limit} name="limit" onChange={onChangeOrder}>
                  <option value={50}>50개씩 보기</option>
                  <option value={100}>100개씩 보기</option>
                  <option value={200}>200개씩 보기</option>
                </Select>
              </div>
            </div>
          }
          list={list}
          renderItem={(order, orderIndex) => (
            <div
              key={orderIndex}
              onClick={() => onChangeCheckbox(order.fetchingOrderNumber)}
            >
              <div aria-label="checkbox">
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={selectedIdx.indexOf(order.fetchingOrderNumber) !== -1}
                />
              </div>
              <div aria-label="주문번호">
                <OpenLink url={`/order/${order.fetchingOrderNumber}`}>
                  {order.fetchingOrderNumber}
                </OpenLink>
              </div>
              <div aria-label="취소일">
                {dayjs(order.createdAt).format('YYYY.MM.DD HH:mm:ss')}
              </div>
              <div aria-label="주문자">
                <span>{order.userName}</span>
                <div>{USER_TYPE[order.userType]}</div>
              </div>
              <div aria-label="판매처 (국가 / 배송방식)">
                {order.shopName} ({order.shopCountry} /{' '}
                <span
                  className={classnames({
                    'text-fassto': order.deliveryMethodName === '파스토',
                    'text-eldex': order.deliveryMethodName === '엘덱스',
                  })}
                >
                  {order.deliveryMethodName}[{order.deliveryMethodCountry}]
                </span>
                )
              </div>
              <div aria-label="주문상품">
                <div className="flex flex-col h-full space-y-4">
                  {order.itemList.map((item, itemIndex) => (
                    <div key={itemIndex} className="flex-1 flex gap-3">
                      <img
                        className="max-w-[3.5rem] object-contain"
                        src={item.itemImageUrl}
                        alt={item.itemName}
                      />
                      <div className="flex-1">
                        <div>{item.brandName}</div>
                        <OpenLink
                          url={`/product/productDetail/v2/${Number(
                            item.itemId
                          )}?mode=update`}
                        >
                          {item.itemName}
                        </OpenLink>
                        <div>{item.sizeName}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="수량">
                <div className="flex flex-col h-full space-y-4">
                  {order.itemList.map((item, itemIndex) => (
                    <div key={itemIndex} className="flex-1">
                      {item.quantity}
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="결제금액">
                <div className="flex flex-col h-full space-y-4">
                  {order.itemList.map((item, itemIndex) => (
                    <div key={itemIndex} className="flex-1">
                      {priceFormat(item.payAmount)} 원
                    </div>
                  ))}
                </div>
              </div>
              <div aria-label="총 결제금액">
                <NumberStat value={order.payAmount} />
              </div>
              <div aria-label="결제수단">{PAY_METHOD[order.payMethod]}</div>
              <div aria-label="취소상태 (사유)">
                <div>{ORDER_CANCEL_STATUS[order.cancelStatus]}</div>
                <div>({ORDER_CANCEL_REASON[order.reasonType]})</div>
              </div>
              <div aria-label="관리자기능">
                <div className="flex flex-col gap-1 items-center">
                  <Button
                    size="xs"
                    className="w-full"
                    theme="primary"
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(
                        `/order/${order.fetchingOrderNumber}?status=1&tab=1`,
                        '_blank'
                      );
                    }}
                  >
                    관리
                  </Button>
                  <Button
                    size="xs"
                    className="w-full"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOrderState({
                        isMessageOpen: true,
                        phoneNumber: order.phoneNumber,
                      });
                    }}
                  >
                    메시지
                  </Button>
                  <OrderListTooltip
                    hasMemo={order.hasMemo}
                    isLoading={isMemoLoading}
                    list={memoList}
                    fetchingOrderNumber={order.fetchingOrderNumber}
                    onMouseLeave={() =>
                      setOrderState({ memoList: [], isMemoLoading: false })
                    }
                    onMouseEnter={() => getMemo(order.fetchingOrderNumber)}
                  />
                </div>
              </div>
            </div>
          )}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setOrderState({
              selectedIdx: checked ? list.map((item) => item.fetchingOrderNumber) : [],
            })
          }
        />
        <div className="flex justify-between items-center">
          <div className="pl-2 text-gray-500 tracking-widest">
            <span>총</span>
            <span className="font-semibold text-xl ml-1 text-gray-800">{total}</span>
            <span>개</span>
          </div>
          <RePagination
            currentPage={page}
            onPageChange={(page) => setOrderState({ page })}
            totalCount={total}
            pageSize={limit}
          />
        </div>
      </div>
    </OrderCount>
  );
};

export default OrdersCancelPage;
