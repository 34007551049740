import styled from 'styled-components';

export const Outer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 105;
`;

export const Text = styled.p``;

export const loader = {
  color: 'black',
};
