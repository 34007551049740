import {
  SearchByTd,
  SelectStatusTd,
  PriorityTd,
  DiscountStatusTd,
  SortByTd,
  PopularityTd,
  PriceByTd,
  OptionCheckboxList,
  CountyApplied,
  SavedOption,
  KeywordTd,
} from 'components/UI';
import React, { useState } from 'react';
import * as style from './style';

const SearchOptionTable = ({ searchOptionTableState, setSearchOptionTableState }) => {
  const [showDetail, setShowDetail] = useState(false);

  const tableData = [
    {
      Left: '필터 저장',
      Td: (
        <SavedOption
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '검색기준',
      Td: (
        <SearchByTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '활성/노출 상태',
      Td: (
        <SelectStatusTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '우선순위',
      Td: (
        <PriorityTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '자체할인',
      Td: (
        <DiscountStatusTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '정렬 기준',
      Td: (
        <SortByTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '인기도',
      Td: (
        <PopularityTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '가격 기준',
      Td: (
        <PriceByTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '원산지 적용',
      Td: (
        <CountyApplied
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
  ];

  return (
    <>
      <style.Table>
        {tableData.map((data, index) => (
          <style.Tr key={index}>
            <style.Left>{data.Left}</style.Left>
            <style.Td>{data.Td}</style.Td>
          </style.Tr>
        ))}
        {showDetail && (
          <style.Tr>
            <style.Left>상세조건</style.Left>
            <style.Td>
              <OptionCheckboxList
                searchOptionTableState={searchOptionTableState}
                setSearchOptionTableState={setSearchOptionTableState}
              />
            </style.Td>
          </style.Tr>
        )}
      </style.Table>
      <style.showDetailButton onClick={() => setShowDetail(!showDetail)}>
        {showDetail ? '상세조건닫기' : '상세조건열기'}
      </style.showDetailButton>
    </>
  );
};

export default SearchOptionTable;

export const ProductGroupOptionTable = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const tableData = [
    {
      Left: '필터 저장',
      Td: (
        <SavedOption
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '활성/노출 상태',
      Td: (
        <SelectStatusTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '우선순위',
      Td: (
        <PriorityTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '자체할인',
      Td: (
        <DiscountStatusTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '인기도',
      Td: (
        <PopularityTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '가격 기준',
      Td: (
        <PriceByTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '원산지 적용',
      Td: (
        <CountyApplied
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '상세조건',
      Td: (
        <OptionCheckboxList
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '키워드',
      Td: (
        <KeywordTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
  ];

  return (
    <style.Table>
      {tableData.map((data, index) => (
        <style.Tr key={index}>
          <style.Left>{data.Left}</style.Left>
          <style.Td>{data.Td}</style.Td>
        </style.Tr>
      ))}
    </style.Table>
  );
};

export const PromotionAutomationConfigTable = ({
  searchOptionTableState,
  setSearchOptionTableState,
}) => {
  const tableData = [
    {
      Left: '필터 저장',
      Td: (
        <SavedOption
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '활성/노출 상태',
      Td: (
        <SelectStatusTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '우선순위',
      Td: (
        <PriorityTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '자체할인',
      Td: (
        <DiscountStatusTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '인기도',
      Td: (
        <PopularityTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '가격 기준',
      Td: (
        <PriceByTd
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '원산지 적용',
      Td: (
        <CountyApplied
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
    {
      Left: '상세조건',
      Td: (
        <OptionCheckboxList
          searchOptionTableState={searchOptionTableState}
          setSearchOptionTableState={setSearchOptionTableState}
        />
      ),
    },
  ];

  return (
    <style.Table>
      {tableData.map((data, index) => (
        <style.Tr key={index}>
          <style.Left>{data.Left}</style.Left>
          <style.Td>{data.Td}</style.Td>
        </style.Tr>
      ))}
    </style.Table>
  );
};
