import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 150;
  height: 100vh;
  width: 100%;
`;

export const Header = styled.header`
  position: sticky;
  width: 100%;
  background-color: #000000;
  top: 0;
  color: white;
`;

export const HeaderTitle = styled.header`
  position: sticky;
  width: 100%;
  background-color: ${({ mode }) =>
    mode === 'create' ? '#ff6f61' : '#4f4e4e'};
  top: 0;
  color: white;
  padding: 12px 24px;
`;

export const Inner = styled.div`
  @media all and (min-width: 1000px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const HeaderItem = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ItemText = styled.div`
  margin: 0 10px;
  padding: 7px 0;
`;

export const HeaderButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #1a1a1a;
  background-color: #e5e5e5;
  padding: 10px 15px;
  @media all and (min-width: 1000px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

export const MenuList = styled.ul`
  display: flex;
  list-style: none;
  align-items: center;
  height: 100%;
  margin-top: 20px;
  @media all and (min-width: 1000px) {
    margin: 0 0;
  }
`;

export const Menu = styled.li`
  padding: 15px 20px;
  border-right: 1px solid #d0cbcb;
  cursor: pointer;
  ${(props) => props.isActive && `color: #69ABF9;`}
`;

export const Content = styled.div`
  overflow-y: auto;
  padding-bottom: 78px;
`;

export const Footer = styled.footer`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  height: 78px;
  width: 100%;
  background-color: #c4c4c4;
`;

export const Button = styled.button`
  background-color: ${(props) => props.color};
  padding: 5px 10px;
  color: white;
  border: 1px solid ${(props) => props.color};
  border-radius: 5px;
  margin: 0 10px;
  &:focus {
    outline: none;
  }
`;

export const IconStyle = {
  width: '26px',
  height: '26px',
  cursor: 'pointer',
};
