import { FC, CSSProperties } from 'react';
import SimpleImageSlider from 'react-simple-image-slider';
import React from 'react';

export const Table: FC = ({ children }) => (
  <div className="border mt-4 grid text-sm" style={{ gridTemplateColumns: '200px 1fr' }}>
    {children}
  </div>
);

export const TableLeft: FC<{ style?: CSSProperties }> = ({ children, style }) => (
  <div className="bg-gray-100 p-4 font-bold" style={style}>
    {children}
  </div>
);

export const TableDivider = () => (
  <div className="h-px bg-gray-200" style={{ gridColumn: '1 / span 2' }} />
);

export const TableRight: FC = ({ children }) => <div className="p-4">{children}</div>;

export const Images: FC<{ images: string[] }> = ({ images }) => {
  return (
    <>
      {images.length > 0 &&
        (images.some((image) => image?.startsWith('http://')) ? (
          images.map((image) => (
            <a href={image} key={image}>
              이미지 확인
            </a>
          ))
        ) : (
          <SimpleImageSlider
            width={300}
            height={300}
            images={images.map((url: any) => ({ url }))}
            showNavs
            showBullets
            slideDuration={0.5}
            navStyle={1}
          />
        ))}
    </>
  );
};
