import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.color || '#767676'};
  text-align: center;
  padding: 10px 40px;
  margin: 5px 10px;
`;

export const Title = styled.p`
  color: ${(props) => props.textColor || 'white'};
  margin: 0;
`;

export const Count = styled.p`
  color: ${(props) => props.textColor || 'white'};
  margin: 0;
`;
