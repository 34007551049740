import styled from 'styled-components';

export const Container = styled.div`
  border: 0.5px solid #b4b4b4;
  display: flex;
  width: 16.3%;
  flex-direction: column;
`;

export const Title = styled.div`
  display: flex;
  padding: 2px 4px;
  justify-content: space-between;
  background: #e4e4e4;
  font-weight: bold;
  font-size: 12px;
  align-items: center;
`;

export const TitleSpan = styled.span`
  width: 70px;
`;

export const ResetButton = styled.img``;

export const Content = styled.div`
  height: 275px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const CheckboxContainer = styled.div`
  padding: 5px 0;
  display: flex;
  align-items: center;
`;
export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin: 0 3px;
`;
export const Label = styled.label`
  margin: 0;
  font-size: 12px;
  display: inline-block;
  cursor: pointer;
`;

export const ListsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  padding: 13px 0;
`;

export const Initial = styled.span`
  font-weight: bold;
  padding-left: 2px;
`;
