import React, { useEffect, useState } from 'react';
import * as style from './style';
import { Input } from '../../UI/atoms/SimpleInput/style';
import * as S from '../../UI/organisms/GroupSearchOptionTable/style';
import { fetchingAPI } from '../../../api/fetchingAPI';
import { GenderFilter } from '../../UI';

const CategoryMapSearchOptionTable = ({
  searchOptionTableState,
  setSearchOptionTableState,
  categories,
}) => {
  const [shops, setShops] = useState([]);

  const fetchShops = async () => {
    const {
      data: { data },
    } = await fetchingAPI.get('/shop?isExtended=true');
    setShops(data);
  };

  const handleDateInput = (e, target) => {
    const date = new Date(e.target.value);
    const ISOString = date.toISOString();
    setSearchOptionTableState({
      ...searchOptionTableState,
      [target]: ISOString,
    });
  };

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  };

  useEffect(() => {
    fetchShops();
  }, []);

  const tableData = [
    {
      Left: '편집샵',
      Td: (
        <>
          <S.Select
            value={searchOptionTableState.shopId || 'none'}
            onChange={(e) => {
              if (e.target.value === 'none') {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  shopId: undefined,
                });
              } else {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  shopId: e.target.value,
                });
              }
            }}
          >
            <option value={'none'}>---</option>
            {shops
              ? shops
                  .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                  .map(({ shopId, name }) => (
                    <option value={shopId} key={shopId}>
                      {name}
                    </option>
                  ))
              : null}
          </S.Select>
        </>
      ),
    },
    {
      Left: '편집샵 카테고리명',
      Td: (
        <Input
          onChange={(e) => {
            setSearchOptionTableState({
              ...searchOptionTableState,
              categoryName: e.target.value,
            });
          }}
        />
      ),
    },
    {
      Left: '매칭 여부',
      Td: (
        <>
          <style.Div
            onChange={(e) => {
              if (e.target.value === 'on') {
                return;
              }
              if (e.target.value === 'null') {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  isMatched: undefined,
                });
              } else {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  isMatched: e.target.value,
                });
              }
            }}
            widthPcnt={100}
            noBorder
          >
            <style.Label>
              <style.Radio
                name="isMatched"
                value="null"
                checked={!searchOptionTableState.isMatched}
                onChange={() => {}}
              />
              전체
            </style.Label>
            <style.Label>
              <style.Radio
                name="isMatched"
                value="true"
                checked={searchOptionTableState.isMatched === 'true'}
                onChange={() => {}}
              />
              매칭됨
            </style.Label>
            <style.Label>
              <style.Radio
                name="isMatched"
                value="false"
                checked={searchOptionTableState.isMatched === 'false'}
                onChange={() => {}}
              />
              매칭안됨
            </style.Label>
            <style.Label>
              <style.CheckBox
                onChange={(e) =>
                  (searchOptionTableState.filterChecked = e.target.checked)
                }
              />{' '}
              매칭 불필요 제외
            </style.Label>
          </style.Div>
        </>
      ),
    },
    {
      Left: '최종 수정 시각',
      Td: (
        <>
          <style.InputDate
            onChange={(e) => handleDateInput(e, 'minModifiedDate')}
            value={
              searchOptionTableState.minModifiedDate
                ? formatDate(searchOptionTableState.minModifiedDate)
                : '0000-00-00'
            }
          />
          ~
          <style.InputDate
            onChange={(e) => handleDateInput(e, 'maxModifiedDate')}
            value={
              searchOptionTableState.maxModifiedDate
                ? formatDate(searchOptionTableState.maxModifiedDate)
                : '0000-00-00'
            }
          />
        </>
      ),
    },
    {
      Left: '성별',
      Td: (
        <GenderFilter
          setSearchOptionTableState={setSearchOptionTableState}
          searchOptionTableState={searchOptionTableState}
        />
      ),
    },
    {
      Left: '페칭 카테고리',
      Td: (
        <>
          <S.Select
            value={searchOptionTableState.categoryId || 'none'}
            onChange={(e) => {
              if (e.target.value === 'none') {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  categoryId: undefined,
                });
              } else {
                setSearchOptionTableState({
                  ...searchOptionTableState,
                  categoryId: e.target.value,
                });
              }
            }}
          >
            <option value={'none'}>---</option>
            {categories
              ? categories.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))
              : null}
          </S.Select>
        </>
      ),
    },
  ];

  return (
    <>
      <style.Table>
        {tableData.map((data, index) => {
          if (index >= 3) {
            return null;
          }
          return (
            <style.Tr key={index}>
              <style.Left>{data.Left}</style.Left>
              <style.Td>{data.Td}</style.Td>
              <style.Left>{tableData[index + 3].Left}</style.Left>
              <style.Td>{tableData[index + 3].Td}</style.Td>
            </style.Tr>
          );
        })}
      </style.Table>
    </>
  );
};

export default CategoryMapSearchOptionTable;
