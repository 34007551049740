import styled from 'styled-components';

export const Conatiner = styled.div`
  width: 100%;
  @media all and (min-width: 1200px) {
    width: 80%;
    display: flex;
    justify-content: space-around;
  }
`;

export const OptionWrraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  @media all and (min-width: 1200px) {
    display: flex;
  }
`;

export const FilterItem = {
  marginTop: '20px',
  width: '100%',
};
