export const totalInflow = {
  animationEnabled: true,
  exportEnabled: true,
  axisY: {
    includeZero: true,
  },
  toolTip: {
    shared: true,
    reversed: true,
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
};

export const newInflow = {
  animationEnabled: true,
  exportEnabled: true,
  axisY: {
    includeZero: true,
  },
  toolTip: {
    shared: true,
    reversed: true,
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
};

export const reInflow = {
  animationEnabled: true,
  exportEnabled: true,
  axisY: {
    includeZero: true,
  },
  toolTip: {
    shared: true,
    reversed: true,
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
};

export const originInflow = {
  animationEnabled: true,
  exportEnabled: true,
  axisY: {
    includeZero: true,
  },
  toolTip: {
    shared: true,
    reversed: true,
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
};

export const originInflowAverage = {
  animationEnabled: true,
  exportEnabled: true,
  axisY: {
    includeZero: true,
  },
  toolTip: {
    shared: true,
    reversed: true,
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
};

export const inflowRate = {
  animationEnabled: true,
  title: {
    text: '신규 유입 및 재방문 비율',
  },
  axisY: {
    includeZero: true,
    suffix: '%',
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
  toolTip: {
    shared: true,
  },
};

export const inflowByChannelRate = {
  title: {
    text: '선택 기한 상세 비율',
  },
  animationEnabled: true,
  axisY: {
    includeZero: true,
    suffix: '%',
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
  toolTip: {
    shared: true,
  },
};

export const inflowByChannelCount = {
  title: {
    text: '선택 기한 상세 유입수',
  },
  animationEnabled: true,
  exportEnabled: true,
  axisY: {
    includeZero: true,
  },
  toolTip: {
    shared: true,
    reversed: true,
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
};

export const totalTransactionAmountRate = {
  title: {
    text: '선택 기한 상세 비율',
  },
  animationEnabled: true,
  axisY: {
    includeZero: true,
    suffix: '%',
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
  toolTip: {
    shared: true,
  },
};

export const totalTransactionAmount = {
  title: {
    text: '선택 기한 상세 거래액',
  },
  animationEnabled: true,
  exportEnabled: true,
  axisY: {
    includeZero: true,
  },
  toolTip: {
    shared: true,
    reversed: true,
  },
  legend: {
    verticalAlign: 'center',
    horizontalAlign: 'right',
    reversed: true,
    cursor: 'pointer',
  },
};
