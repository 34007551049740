import {
  Button,
  FormItem,
  GridTable,
  Radio,
  ReInput,
  ReModal,
  Select,
  Textarea,
} from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import {
  enumToList,
  ORDER_CANCEL_REASON,
  request,
  useObject,
  useOrderDetail,
} from 'services';
import { IModal } from 'types';
import Switch from 'react-switch';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface Props extends IModal {
  cancelOrderList: Array<{
    itemOrderNumber: string;
    orderCancelReason: string;
    quantity: number;
    sizeName: string;
    itemName: string;
    itemImageUrl: string;
  }>;
}
interface State {
  selectedIdx: string[];
  isLoading: boolean;
  sendPush: boolean;
  memoContent: string;
  messageContent: string;
  messageType: 'SMS' | 'KAKAO';
  list: Array<{
    itemOrderNumber: string;
    orderCancelReason: string;
    quantity: number;
    sizeName: string;
    itemName: string;
    itemImageUrl: string;
  }>;
  tempMessageContent: string;
}

const OrderCancelModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  cancelOrderList,
}) => {
  if (!isOpen) return null;
  const [
    {
      selectedIdx,
      isLoading,
      sendPush,
      messageType,
      messageContent,
      memoContent,
      list,
      tempMessageContent,
    },
    setState,
    onChange,
  ] = useObject<State>({
    selectedIdx: cancelOrderList.map((item) => item.itemOrderNumber),
    isLoading: false,
    sendPush: false,
    memoContent: '',
    messageContent: '',
    messageType: 'KAKAO',
    list: cancelOrderList,
    tempMessageContent: '',
  });
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const [{ payMethod }, setOrderState, { refreshOrderState }] = useOrderDetail();

  const getTemplate = async () => {
    const filter = list.filter(
      (item) => selectedIdx.indexOf(item.itemOrderNumber) !== -1
    );
    if (!filter.length) {
      toast.info('취소할 상품을 최소 1개 이상 선택해 주세요.');
      return;
    }
    if (filter.some((item) => !item.orderCancelReason || !item.quantity)) {
      toast.info('모든 상품의 사유와 수량을 입력해주세요');
      return;
    }
    try {
      const { content } = await request.post<any, { content: string }>(
        '/commerce/order/message/preview',
        {
          type: 'ORDER_CANCEL',
          fetchingOrderNumber,
          cancelOrderList: filter,
        }
      );
      setState({ messageContent: content });
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async () => {
    if (!selectedIdx.length) {
      toast.info('취소할 상품을 선택해주세요.');
      return;
    }
    if (
      list.some(
        (item) =>
          selectedIdx.indexOf(item.itemOrderNumber) !== -1 &&
          item.orderCancelReason === ''
      )
    ) {
      toast.info('선택한 상품의 취소 사유를 선택해주세요.');
      return;
    }
    if (!window.confirm('주문을 취소 처리하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      let data: any = {
        cancelOrderList: list
          .filter((item) => selectedIdx.indexOf(item.itemOrderNumber) !== -1)
          .map((item) => ({
            itemOrderNumber: item.itemOrderNumber,
            orderCancelReason: item.orderCancelReason,
            quantity: item.quantity,
          })),
        sendPush,
        memoContent,
      };
      if (sendPush) {
        data.messageContent =
          messageType === 'KAKAO' ? messageContent : tempMessageContent;
        data.messageType = messageType;
      }
      await request.post(
        `/commerce/order/fetching-order/${fetchingOrderNumber}/order-cancel`,
        data
      );
      refreshOrderState();
      if (payMethod === 'DEPOSIT')
        toast.success('주문이 취소되었습니다. 무통장입금의 경우 직접 환불이 필요합니다.');
      else toast.success('주문이 취소되었습니다.');
      onClose();
    } catch (err) {
      console.dir(err);
      setState({ isLoading: false });
    }
  };
  useEffect(() => {
    if (sendPush) getTemplate();
  }, [sendPush]);
  return (
    <ReModal
      title="주문 취소 처리"
      description={
        <>
          복수 수량은 단일 항목으로 노출됩니다. <br /> 수량 일부 취소는 단일 항목을
          취소처리해주세요.
        </>
      }
      isOpen={isOpen}
      onClose={() => {
        if (window.confirm('작업을 중단하시겠습니까?')) onClose();
      }}
      maxWidth="max-w-5xl"
    >
      <div className="flex gap-4">
        <div className="flex-1">
          <div className="text-sm font-bold mb-2">{selectedIdx.length}개 상품 선택</div>
          <div className="bg-gray-100 rounded border border-gray-400">
            <GridTable
              columns={['상품정보']}
              renderItem={(item, key) => (
                <div
                  key={key}
                  onClick={() =>
                    setState({
                      selectedIdx:
                        selectedIdx.indexOf(item.itemOrderNumber) !== -1
                          ? selectedIdx.filter(
                              (itemOrderNumber) =>
                                itemOrderNumber !== item.itemOrderNumber
                            )
                          : [...selectedIdx, item.itemOrderNumber],
                    })
                  }
                >
                  <div>
                    <input
                      type="checkbox"
                      onChange={() => {}}
                      checked={selectedIdx.indexOf(item.itemOrderNumber) !== -1}
                    />
                  </div>
                  <div>
                    <div className="flex gap-2">
                      <div>
                        <img
                          src={item.itemImageUrl}
                          className="h-16 w-16 object-contain"
                        />
                      </div>
                      <div>
                        <div className="text-black">{item.itemName}</div>
                        <div>{item.sizeName}</div>
                        <div className="flex gap-1 items-center">
                          <Select
                            size="xs"
                            onChange={(e) =>
                              setState({
                                list: [
                                  ...list.slice(0, key),
                                  {
                                    ...item,
                                    orderCancelReason: e.target.value as string,
                                  },
                                  ...list.slice(key + 1),
                                ],
                              })
                            }
                            onClick={(e) => e.stopPropagation()}
                            value={item.orderCancelReason || ''}
                          >
                            <option selected disabled value="">
                              취소 사유 선택
                            </option>
                            {enumToList(ORDER_CANCEL_REASON)
                              .slice(0, -2)
                              .map((item, key) => (
                                <option key={key} value={item.value}>
                                  {item.name}
                                </option>
                              ))}
                          </Select>
                          <ReInput
                            type="number"
                            min={1}
                            size="xs"
                            suffix={`/ ${cancelOrderList[key].quantity}`}
                            className="w-20"
                            max={cancelOrderList[key].quantity}
                            value={item.quantity}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) =>
                              setState({
                                list: [
                                  ...list.slice(0, key),
                                  { ...list[key], quantity: Number(e.target.value) },
                                  ...list.slice(key + 1),
                                ],
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              list={list}
              isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
              onSelectAll={(checked) =>
                setState({
                  selectedIdx: checked ? list.map((item) => item.itemOrderNumber) : [],
                })
              }
            />
          </div>
        </div>
        <div className="flex-1 space-y-4">
          <FormItem label="고객 알림 전달 여부">
            <Switch checked={sendPush} onChange={(sendPush) => setState({ sendPush })} />
          </FormItem>
          {sendPush && (
            <>
              <FormItem label="고객 알림 채널">
                <Radio
                  options={[
                    { name: '카카오', value: 'KAKAO' },
                    { name: 'SMS (메시지 수정 발송 시)', value: 'SMS' },
                  ]}
                  id="messageType"
                  value={messageType}
                  onChange={(messageType) =>
                    setState({
                      messageType,
                      tempMessageContent: messageType === 'SMS' ? messageContent : '',
                    })
                  }
                />
              </FormItem>
              <FormItem label="고객 알림 내용 확인">
                <Textarea
                  readOnly={messageType === 'KAKAO'}
                  rows={8}
                  value={tempMessageContent || messageContent}
                  name="tempMessageContent"
                  onChange={onChange}
                />
              </FormItem>
            </>
          )}
          <FormItem label="관리자 메모">
            <Textarea
              rows={4}
              value={memoContent}
              name="memoContent"
              onChange={onChange}
            />
          </FormItem>
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Button onClick={onSubmit} isLoading={isLoading}>
          주문 취소하기
        </Button>
      </div>
    </ReModal>
  );
};

export default OrderCancelModal;
