import { TableItem } from 'components/UI/molecules';
import React, { useRef, useEffect } from 'react';
import * as style from './style';

const ShopShippingInfo = ({ shop, handleClinentHeight }) => {
  const currency = {
    KRW: '원',
    GBP: '파운드',
    USD: '달러',
    EUR: '유로',
  };

  const result = currency[shop?.currency];

  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('배송 정보', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('배송 정보', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);

  return (
    <style.Wrapper ref={ref}>
      <style.Title>배송 정보</style.Title>
      <style.BoxWrapper>
        <TableItem
          title="해외배송"
          value={shop?.isDeliveryOtherCountry === 1 ? '지원' : '미지원'}
          valueColor={shop?.isDeliveryOtherCountry === 1 ? 'green' : 'red'}
        />
        <TableItem title="-" value={'-'} />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem
          title="해외 배송비 최대가"
          value={`${shop?.deliveryFee} ${result}`}
        />
        <TableItem
          title="해외 배송비 최소가"
          value={`${shop?.minimumDeliveryFee} ${result}`}
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem
          title="무료 해외 배송"
          value={shop?.hasFreeDeliveryService === 1 ? '지원' : '미지원'}
          valueColor={shop?.hasFreeDeliveryService === 1 ? 'green' : 'red'}
        />
        <TableItem
          title="무료 해외배송 결제가"
          value={`${shop?.minimumPriceForFreeDelivery} ${result}`}
        />
      </style.BoxWrapper>
    </style.Wrapper>
  );
};
export default ShopShippingInfo;
