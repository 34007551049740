import { Button, GridTable, ReModal } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { request, useObject } from 'services';
import { IModal, IResponse } from 'types';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';

interface ICoupon {
  name: string;
  idx: number;
  createdAt: string;
  privateCode: string;
}

export interface Props extends IModal {
  onRefresh: () => void;
}
interface State {
  isLoading: boolean;
  list: ICoupon[];
  selectedIdx: number[];
}

const CouponModal: FunctionComponent<Props> = ({ isOpen, onClose, onRefresh }) => {
  if (!isOpen) return null;
  const [{ isLoading, list, selectedIdx }, setState] = useObject<State>({
    isLoading: false,
    list: [],
    selectedIdx: [],
  });
  const { idx } = useParams<{ idx: string }>();
  const get = async () => {
    try {
      const { data } = await request.get<any, IResponse<ICoupon>>('/commerce/coupon');
      setState({ list: data });
    } catch (err) {
      console.log(err);
    }
  };
  const onPublishCoupon = async () => {
    if (!selectedIdx.length) {
      toast.info('발행할 쿠폰을 선택해주세요.');
      return;
    }
    if (!window.confirm('발행하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      await request.post('/commerce/coupon/user-coupon/bulk', {
        couponIdList: selectedIdx,
        userIdList: [Number(idx)],
      });
      toast.success('발행되었습니다.');
      onRefresh();
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isLoading: false });
    }
  };
  useEffect(() => {
    get();
  }, []);
  return (
    <ReModal
      title="쿠폰 발행"
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-3xl"
      description="발행할 쿠폰 선택 후 [발행하기] 버튼을 선택해주세요."
    >
      <div className="text-right mt-8">
        <Button size="xs" onClick={() => toast.info('현재 쿠폰 수동 생성만 가능합니다.')}>
          {'신규 쿠폰 생성 >'}
        </Button>
      </div>
      <div className="mt-3">
        <GridTable
          maxHeight=""
          columns={['쿠폰번호', '쿠폰명', '쿠폰생성일']}
          renderItem={(item, key) => (
            <div
              key={key}
              onClick={() =>
                setState({
                  selectedIdx:
                    selectedIdx.indexOf(item.idx) !== -1
                      ? selectedIdx.filter((idx) => idx !== item.idx)
                      : [...selectedIdx, item.idx],
                })
              }
            >
              <div>
                <input
                  type="checkbox"
                  onChange={() => {}}
                  checked={selectedIdx.indexOf(item.idx) !== -1}
                />
              </div>
              <div>
                <span className="link">{item.privateCode}</span>
              </div>
              <div>
                <span className="link">{item.name}</span>
              </div>
              <div>{dayjs(item.createdAt).format('YYYY.MM.DD. HH:mm:ss')}</div>
            </div>
          )}
          list={list}
          isAllSelected={!!selectedIdx.length && selectedIdx.length === list.length}
          onSelectAll={(checked) =>
            setState({ selectedIdx: checked ? list.map((item) => item.idx) : [] })
          }
        />
        <div className="text-center mt-4">
          <Button onClick={onPublishCoupon} isLoading={isLoading}>
            발행하기
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default CouponModal;
