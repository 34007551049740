import { Channel } from './types';

export const selectStyle = {
  control: (style) => ({
    ...style,
    borderRadius: 0,
  }),
};

export const channels: { name: string; id: Channel }[] = [
  { id: 'fb', name: '페이스북/인스타그램' },
  { id: 'nshopping', name: '네이버 쇼핑' },
  { id: 'nfeed', name: '네이버 피드' },
  { id: 'nsearch', name: '네이버 파워링크' },
  { id: 'nbrand', name: '네이버 브랜드 광고' },
  { id: 'shoppinghow', name: '다음 쇼핑하우' },
  { id: 'piclick', name: '픽클릭' },
  { id: 'gads', name: '구글 키워드' },
  { id: 'gdn', name: '구글 GDN' },
];
