import styled from "styled-components";

export const Outer = styled.div`
  width: 100%;
  padding: 0 22px 100px 22px;
`;

export const PageTitle = styled.div`
  display: flex;
  align-items: center;
  height: 65px;
  font-weight: 400;
  font-size: 24px;
`;

export const Inner = styled.div`
  @media all and (min-width: 1400px) {
    display: flex;
    justify-content: center;
    margin: 50px;
    flex-wrap: wrap;
  }
`;

export const SearchWrapperLeft = styled.div`
  @media all and (min-width: 1400px) {
    width: 65%;
  }
`;

export const SearchWrapperRight = styled.div`
  @media all and (min-width: 1400px) {
    width: 35%;
  }
`;

export const SearchButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
`;

export const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: white;
`;

export const SearchButton = {
  margin: "10px",
};

export const ShadowContainer = styled.div`
background: #FFFFFF;
box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.25);
border-radius: 5px;
margin:22px 0;
padding:22px;
`;

export const GreyWallpaper = styled.div`
position:fixed;
z-index:-10000;
width:100vw;
height:100vh;
background: #EFEFEF;`;