import dayjs from 'dayjs';
import { FromToType, SearchType, StatusList } from './types';

export const searchTypeOptions: { value: SearchType; name: string }[] = [
  {
    value: SearchType.CONTACT,
    name: '파트너 연락처',
  },
  {
    value: SearchType.SHOP_NAME,
    name: '파트너 쇼핑몰명',
  },
  {
    value: SearchType.TAXPAYER_ID_NUMBER,
    name: '파트너 사업자등록번호',
  },
];

export const fromToTypeOptions: { value: FromToType; name: string }[] = [
  { value: FromToType.PROCESS_DATE, name: '처리일' },
  { value: FromToType.REQUEST_DATE, name: '요청일' },
];

export const handleStatus = (
  setStatusList: React.Dispatch<React.SetStateAction<StatusList[]>>,
  state: StatusList,
  checked: boolean
) => {
  if (checked) {
    setStatusList((p) => [...p, state]);
  } else {
    setStatusList((p) => p.filter((s) => s !== state));
  }
};

export const statusListOptions: { name: string; value: StatusList }[] = [
  { name: '승인대기', value: StatusList.REQUEST },
  { name: '승인', value: StatusList.ACCEPT },
  { name: '반려', value: StatusList.REJECT },
];

export const fromToOptions: { value: string; name: string }[] = [
  {
    name: '오늘',
    value: [dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')].join(','),
  },
  {
    name: '1주일',
    value: [
      dayjs().subtract(7, 'd').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ].join(','),
  },
  {
    name: '1개월',
    value: [
      dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ].join(','),
  },
  {
    name: '3개월',
    value: [
      dayjs().subtract(3, 'month').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ].join(','),
  },
  {
    name: '6개월',
    value: [
      dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ].join(','),
  },
  {
    name: '1년',
    value: [
      dayjs().subtract(1, 'y').format('YYYY-MM-DD'),
      dayjs().format('YYYY-MM-DD'),
    ].join(','),
  },
];
