import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 40px;
`;

export const Title = styled.h1``;

export const TitleDescrip = styled.p`
  color: #4d4a4a;
`;
