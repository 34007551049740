import { Button, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { Label, Radio } from './style';
import { fetchingAPI } from 'api/axios';

const ChangeUserLevelModal = ({ show, onHide, targetIds, fetchItems }) => {
  const [type, setType] = useState('USER');
  const [isSucceed, setIsSucceed] = useState(false);
  const changeUserLevels = async () => {
    try {
      if (isSucceed) {
        onHide();
        fetchItems();
      } else {
        await fetchingAPI.put('/commerce/user/level', {
          ids: targetIds,
          data: type,
        });
        setIsSucceed(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        style={{
          background: '#000',
          padding: '8px 0',
          textAlign: 'center',
          color: '#FF6F61',
          height: 40,
          fontWeight: 'bold',
          fontSize: 16,
        }}
      >
        회원등급 변경
      </div>
      <div style={{ padding: '16px 0 8px', textAlign: 'center' }}>
        {isSucceed
          ? '등급이 변경되었습니다.'
          : '선택한 회원의 등급을 아래와 같이 변경합니다.'}
      </div>
      {!isSucceed && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: 8 }}>
          <Label>
            <Radio checked={type === 'USER'} onChange={() => setType('USER')} />{' '}
            일반
          </Label>
          <Label>
            <Radio checked={type === 'VIP'} onChange={() => setType('VIP')} />{' '}
            VIP
          </Label>
        </div>
      )}
      <div style={{ textAlign: 'center', marginBottom: 16 }}>
        <button
          style={{
            width: 50,
            background: '#000',
            color: '#fff',
            borderRadius: 5,
            border: 'none',
            outline: 'none',
          }}
          onClick={changeUserLevels}
        >
          확인
        </button>
      </div>
    </Modal>
  );
};

export default ChangeUserLevelModal;
