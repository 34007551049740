import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { SimpleInput } from '../../UI';
import { fetchingAPI } from '../../../api/fetchingAPI';

const AddNameKeywordModal = (props) => {
  const { onHide, showModal } = props;

  const [keywords, setKeywords] = useState('');
  const [alias, setAlias] = useState('');
  const [priorityList, setPriorityList] = useState([]);

  const handle = async () => {
    try {
      await fetchingAPI.put(
        '/keyword/name',
        keywords.split(',').map((keyword) => ({
          keyword,
          alias,
        }))
      );
      onHide();
    } catch (err) {
      if (err.response.status === 401) {
        alert('사용자에게 권한이 없습니다.');
      } else {
        console.error(err);
        alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      PUT - /keyword/name
      ${err}`);
        alert('다시 시도해 주세요');
      }
    }
  };

  useEffect(() => {
    const numberList = [];
    for (let i = 0; i <= 1000; i++) {
      numberList.push(i);
    }
    setPriorityList(priorityList.concat(numberList));
  }, [setPriorityList]);

  return (
    <>
      <Modal {...props} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>매핑 추가</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          키워드
          <SimpleInput
            placeholder={'개별 키워드 당 최대 128자'}
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
          대체 문장
          <SimpleInput
            placeholder={'최대 128자'}
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handle}
            style={{
              background: '#007bff',
              color: '#fff',
              borderRadius: 4,
              padding: '8px 12px',
            }}
          >
            설정
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddNameKeywordModal;
