import React from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Progress } from 'components';
import axios, { fetchingAPI } from 'api/axios';
import * as style from './style';

const DetailNotice = ({ history }) => {
  const detailNoticeData = useSelector((state) => state.detailNotice.detailNoticeData);

  return (
    <style.Outer>
      <Card style={style.CardOuter}>
        <Card.Header as="h5">{detailNoticeData.title}</Card.Header>
        <Card.Body style={style.Body}>
          <Card.Text style={{ whiteSpace: 'pre-line' }}>
            {detailNoticeData.contents}
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-muted">
          <style.Right>
            <Progress progressData={detailNoticeData} />
          </style.Right>
          <style.Right>
            <Button
              style={style.ButtonStyle}
              variant="secondary"
              onClick={() => alert('기능 없음')}
            >
              삭제
            </Button>
            <Button
              style={style.ButtonStyle}
              variant="dark"
              onClick={() => history.push('/content/noticeList/modifyNotice')}
            >
              수정
            </Button>
          </style.Right>
        </Card.Footer>
      </Card>
    </style.Outer>
  );
};

export default withRouter(DetailNotice);
