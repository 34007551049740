import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';

const RenderTooltip = ({ ...props }, ref) => (
  <Tooltip id="button-tooltip" ref={ref} {...props}>
    {props.message || '클릭하면 이미지를 볼 수 있습니다! 👀 '}
  </Tooltip>
);

export default React.forwardRef(RenderTooltip);
