import _ from 'lodash';
import ExcelJS from 'exceljs';

export function deleteEmptyFields(target, ...fields) {
  const copiedTarget = _.cloneDeep(target);

  fields
    .filter((field) => _.isEmpty(copiedTarget[field]))
    .forEach((field) => {
      delete copiedTarget[field];
    });

  return copiedTarget;
}

export function parseIntegerFields(target, ...fields) {
  const copiedTarget = _.cloneDeep(target);

  fields
    .filter((field) => !_.isEmpty(copiedTarget[field]))
    .forEach((field) => {
      copiedTarget[field] = Number(copiedTarget[field]);
    });

  return copiedTarget;
}

export function parseBooleanFields(target, ...fields) {
  const copiedTarget = _.cloneDeep(target);

  fields
    .filter((field) => _.isInteger(copiedTarget[field]))
    .forEach((field) => {
      copiedTarget[field] = Boolean(copiedTarget[field]);
    });

  return copiedTarget;
}

export function parseIntegerArrayFields(target, ...fields) {
  const copiedTarget = _.cloneDeep(target);

  fields
    .filter((field) => copiedTarget[field][0] !== '')
    .forEach((field) => {
      copiedTarget[field] = copiedTarget[field].map(Number);
    });

  fields
    .filter((field) => copiedTarget[field][0] === '' || _.isEmpty(copiedTarget[field]))
    .forEach((field) => {
      delete copiedTarget[field];
    });

  return copiedTarget;
}

export function makeSearchQuery(searchOption) {
  const query = deleteEmptyFields(
    searchOption,
    'searchKey',
    'searchType',
    'isVerified',
    'exhibitionId',
    'isCafe24Active',
    'isDiscounted',
    'isPromoted',
    'priority',
    'minimumPrice',
    'maximumPrice'
  );

  delete query.sort;
  delete query.sortWay;
  delete query.newArrivalPeriod;

  const parsedIntegerQuery = parseIntegerFields(
    query,
    'isVerified',
    'exhibitionId',
    'isCafe24Active',
    'isDiscounted',
    'isPromoted',
    'priority',
    'minimumPrice',
    'maximumPrice'
  );
  const parsedBooleanQuery = parseBooleanFields(
    parsedIntegerQuery,
    'isVerified',
    'isCafe24Active',
    'isDiscounted',
    'isPromoted'
  );
  const finalQuery = parseIntegerArrayFields(
    parsedBooleanQuery,
    'shopIdList',
    'categoryIdList',
    'brandIdList',
    'promotionIdList'
  );

  if (_.isEmpty(searchOption.searchKey)) {
    delete finalQuery.searchKey;
    delete finalQuery.searchType;
  }

  if (!_.isEmpty(searchOption.searchKey)) {
    if (_.isEmpty(searchOption.searchType)) {
      // eslint-disable-next-line no-alert
      throw new Error('상품 타입을 선택하세요.');
    }
  }

  if (_.isEmpty(searchOption.periodType)) {
    delete finalQuery.periodType;
    delete finalQuery.firstDate;
    delete finalQuery.lastDate;
  } else {
    finalQuery.periodType = Number(finalQuery.periodType);
    finalQuery.firstDate = new Date(finalQuery.firstDate).toISOString();
    finalQuery.lastDate = new Date(finalQuery.lastDate).toISOString();
  }

  return finalQuery;
}

export function downloadFile({ contents, fileName }) {
  const elem = document.createElement('a');
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([bom, contents], { type: 'text/plain;charset=utf-8' });
  elem.href = window.URL.createObjectURL(blob);
  elem.download = `${fileName}`;
  document.body.appendChild(elem);
  elem.click();
  document.body.removeChild(elem);
}

function makeSmartStoreDescription({ description, images }) {
  const headerImage =
    '<img src="https://fetching-cafe24.s3.ap-northeast-2.amazonaws.com/description/top.png" alt="" />';
  const footerImage =
    '<img src="https://fetching-cafe24.s3.ap-northeast-2.amazonaws.com/description/bottom.png" alt="" />';
  const descriptionTitle =
    '<h2 style="font-family:AppleSDGothicNeo-ExtraBold;margin-top:100px;margin-bottom:28px;font-size:20px;line-height:30px;">상품 정보</h2>';
  const descriptionWrapperOpen =
    '<div style="font-family:AppleSDGothicNeo-Regular;font-size:16px;line-height:24px;max-width:1000px;margin: 0px 15px;text-align:center">';
  const descriptionWrapperClose = '</div>';
  const descriptionHTML = description.split('\n').join('<br>');
  const imagesWrapperOpen = '<div style="text-align:center;">';
  const imagesWrapperClose = '</div>';
  const imagesHTML = images.map((url) => `<img src="${url}" alt="" />`).join('');
  const cautionMessage =
    '<strong style="display:block;margin-top:100px;color:#B90000;">해당 상품은 프로모션이 적용되어 주문 이후 취소 및 반품이 불가능합니다.<br><br>개인고유통관부호 미제출 시 배송이 지연될 수 있으니 반드시 주문할 때 제출해주시기 바랍니다.</strong>';

  return (
    headerImage +
    descriptionWrapperOpen +
    cautionMessage +
    descriptionTitle +
    descriptionHTML +
    descriptionWrapperClose +
    imagesWrapperOpen +
    imagesHTML +
    imagesWrapperClose +
    footerImage
  );
}

function getSmartStoreExcelHeaders() {
  const headers = [
    '상품상태',
    '카테고리ID',
    '상품명',
    '판매가',
    '재고수량',
    'A/S 안내내용',
    'A/S 전화번호',
    '대표 이미지 파일명',
    '추가 이미지 파일명',
    '상품 상세정보',
    '판매자 상품코드',
    '판매자 바코드',
    '제조사',
    '브랜드',
    '제조일자',
    '유효일자',
    '부가세',
    '미성년자 구매',
    '구매평 노출여부',
    '원산지 코드',
    '수입사',
    '복수원산지 여부',
    '원산지 직접입력',
    '배송방법',
    '배송비 유형',
    '기본배송비',
    '배송비 결제방식',
    '조건부무료-상품판매가합계',
    '수량별부과-수량',
    '반품배송비',
    '교환배송비',
    '지역별 차등배송비 정보',
    '별도설치비',
    '판매자 특이사항',
    '즉시할인 값',
    '즉시할인 단위',
    '복수구매할인 조건 값',
    '복수구매할인 조건 단위',
    '복수구매할인 값',
    '복수구매할인 단위',
    '상품구매시 포인트 지급 값',
    '상품구매시 포인트 지급 단위',
    '텍스트리뷰 작성시 지급 포인트',
    '포토/동영상 리뷰 작성시 지급 포인트',
    '한달사용 텍스트리뷰 작성시 지급 포인트',
    '한달사용 포토/동영상리뷰 작성시 지급 포인트',
    '톡톡친구/스토어찜고객 리뷰 작성시 지급 포인트',
    '무이자 할부 개월',
    '사은품',
    '옵션형태',
    '옵션명',
    '옵션값',
    '옵션가',
    '옵션 재고수량',
    '추가상품명',
    '추가상품값',
    '추가상품가',
    '추가상품 재고수량',
    '상품정보제공고시 품명',
    '상품정보제공고시 모델명',
    '상품정보제공고시 인증허가사항',
    '상품정보제공고시 제조자',
    '스토어찜회원 전용여부',
    '문화비 소득공제',
    'ISBN',
    '독립출판',
  ];
  return headers;
}

function getSmartStoreItemName({ name, brand, gender, categoryName }) {
  const itemName = `${brand} ${
    gender === 'W' ? '여성' : '남성'
  } ${categoryName} - ${name}`;
  return itemName.replace(/\\|\*|\?/gi, '');
}

export function makeSmartStoreExcel(items) {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('시트1');
  const headers = getSmartStoreExcelHeaders();

  sheet.columns = headers.map((title) => ({ header: title }));
  items
    .filter(({ categoryId }) => categoryId)
    .forEach((item, i) => {
      const imageUrls = item.imageUrl.split('/');
      const description = makeSmartStoreDescription({
        description: item.description,
        images: item.images,
      });
      const name = getSmartStoreItemName({
        name: item.name,
        brand: item.brand,
        gender: item.gender,
        categoryName: item.categoryName,
      });

      const row = sheet.getRow(i + 2);
      row.getCell(1).value = '신상품';
      row.getCell(2).value = item.categoryId;
      row.getCell(3).value = name;
      row.getCell(4).value = item.price.toString();
      row.getCell(5).value = '1';
      row.getCell(6).value =
        '유상 A/S 서비스를 제공합니다. 031-717-8045 또는 톡톡으로 문의 필수';
      row.getCell(7).value = '031-717-8045';
      row.getCell(8).value = imageUrls[imageUrls.length - 1];
      row.getCell(10).value = description;
      row.getCell(11).value = item.idx.toString();
      row.getCell(14).value = item.brand;
      row.getCell(17).value = '면세상품';
      row.getCell(18).value = 'Y';
      row.getCell(19).value = 'Y';
      row.getCell(20).value = '03';
      row.getCell(22).value = 'N';
      row.getCell(24).value = '택배‚ 소포‚ 등기';
      row.getCell(25).value = '무료';
      row.getCell(26).value = '0';
      row.getCell(27).value = '선결제';
      row.getCell(30).value = '100000';
      row.getCell(31).value = '100000';
      row.getCell(34).value = '개인 고유 통관 부호 필수';
      row.getCell(50).value = '단독형';
      row.getCell(51).value = '사이즈';
      row.getCell(52).value = item.sizes.map(({ name: sizeName }) => sizeName).join(',');
      row.getCell(53).value = item.sizes
        .map(({ optionalPrice }) => optionalPrice || 0)
        .join(',');
      row.getCell(54).value = item.sizes.map(({ quantity }) => quantity).join(',');
      row.getCell(63).value = 'N';
      row.commit();
    });
  return workbook;
}

export const trimParams = (params) => {
  const result = {};

  if (params.searchKey !== '') {
    result.searchKey = params.searchKey;
    result.searchType = params.searchType;
  }

  if (params.isSellable !== 'null') {
    result.isSellable = params.isSellable;
  }
  if (params.isFetchingPayActive !== 'null') {
    result.isFetchingPayActive = params.isFetchingPayActive;
  }
  if (params.isShopPayActive !== 'null') {
    result.isShopPayActive = params.isShopPayActive;
  }

  if (params.priorityList.includes(-1)) {
    result.priorityList = params.priorityList
      .sort()
      .slice(1)
      .concat(params.priorityString.split(',').map((v) => Number(v)));
  } else if (params.priorityList.length > 0) {
    result.priorityList = params.priorityList;
  }

  if (params.maxPrioritySetAt !== null) {
    result.maxPrioritySetAt = params.maxPrioritySetAt;
  }
  if (params.minPrioritySetAt !== null) {
    result.minPrioritySetAt = params.minPrioritySetAt;
  }

  if (params.isShopDiscount !== 'null') {
    result.isShopDiscount = params.isShopDiscount;
  }
  if (params.hasPromotion !== 'null') {
    result.hasPromotion = params.hasPromotion;
  }
  if (params.hasExhibition !== 'null') {
    result.hasExhibition = params.hasExhibition;
  }

  result.sort = params.sort;
  result.sortWay = params.sortWay;
  if ([13, 14, 15, 16].includes(params.sort) && params.eventDuration) {
    result.eventDuration = params.eventDuration;
  }

  if (params.hasView) {
    result.hasView = true;
  }
  if (params.hasLike) {
    result.hasLike = true;
  }
  if (params.hasCart) {
    result.hasCart = true;
  }
  if (params.hasSales) {
    result.hasSales = true;
  }

  if (params.priceMode !== 'null') {
    switch (params.priceMode) {
      case 'basicPrice':
        result.minDiscountedPrice = params.minDiscountedPrice;
        result.maxDiscountedPrice = params.maxDiscountedPrice;
        break;
      case 'shopPrice':
        result.minTotalPrice = params.minTotalPrice;
        result.maxTotalPrice = params.maxTotalPrice;
        break;
      case 'fetchingPrice':
        result.minFinalPrice = params.minFinalPrice;
        result.maxFinalPrice = params.maxFinalPrice;
        break;
      default:
        break;
    }
  }

  if (params.country != 'null') {
    result.country = params.country;
  }

  if (params.shopIdList.length > 0) {
    result.shopIdList = params.shopIdList;
  }
  if (params.brandIdList.length > 0) {
    result.brandIdList = params.brandIdList;
  }
  if (params.categoryIdList.length > 0) {
    result.categoryIdList = params.categoryIdList;
  }
  if (params.promotionIdList.length > 0) {
    result.promotionIdList = params.promotionIdList;
  }
  if (params.exhibitionIdList.length > 0) {
    result.exhibitionIdList = params.exhibitionIdList;
  }
  if (params.priceRuleIdList.length > 0) {
    result.priceRuleIdList = params.priceRuleIdList;
  }

  if (params.isNaverAdMaterial) {
    result.isNaverAdMaterial = true;
  }

  if (params.needCount) {
    result.needCount = true;
  }

  result.isExtended = true;

  result.limit = params.limit;

  return result;
};

export const trimAutomationParams = (params) => {
  const result = {};

  if (params.isSellable !== 'null') {
    result.isSellable = params.isSellable;
  }
  if (params.isShopPayActive !== 'null') {
    result.isShopPayActive = params.isShopPayActive;
  }
  if (params.isFetchingPayActive !== 'null') {
    result.isFetchingPayActive = params.isFetchingPayActive;
  }

  if (params.priorityList.includes(-1)) {
    result.priorityList = params.priorityList
      .sort()
      .slice(1)
      .concat(params.priorityString.split(',').map((v) => Number(v)));
  } else if (params.priorityList.length > 0) {
    result.priorityList = params.priorityList;
  }

  if (params.maxPrioritySetAt !== null) {
    result.maxPrioritySetAt = params.maxPrioritySetAt;
  }
  if (params.minPrioritySetAt !== null) {
    result.minPrioritySetAt = params.minPrioritySetAt;
  }

  if (params.isShopDiscount !== 'null') {
    result.isShopDiscount = params.isShopDiscount;
  }
  if (params.hasPromotion !== 'null') {
    result.hasPromotion = params.hasPromotion;
  }
  if (params.hasExhibition !== 'null') {
    result.hasExhibition = params.hasExhibition;
  }

  if (params.hasView) {
    result.hasView = true;
  }
  if (params.hasLike) {
    result.hasLike = true;
  }
  if (params.hasCart) {
    result.hasCart = true;
  }
  if (params.hasSales) {
    result.hasSales = true;
  }

  if (params.priceMode !== 'null') {
    switch (params.priceMode) {
      case 'basicPrice':
        result.minDiscountedPrice = params.minDiscountedPrice;
        result.maxDiscountedPrice = params.maxDiscountedPrice;
        break;
      case 'shopPrice':
        result.minTotalPrice = params.minTotalPrice;
        result.maxTotalPrice = params.maxTotalPrice;
        break;
      case 'fetchingPrice':
        result.minFinalPrice = params.minFinalPrice;
        result.maxFinalPrice = params.maxFinalPrice;
        break;
      default:
        break;
    }
  }

  if (params.country != 'null') {
    result.country = params.country;
  }

  if (params.shopIdList.length > 0) {
    result.shopIdList = params.shopIdList;
  }
  if (params.priceRuleIdList) {
    result.priceRuleIdList = params.priceRuleIdList;
  }
  if (params.brandIdList.length > 0) {
    result.brandIdList = params.brandIdList;
  }
  if (params.categoryIdList.length > 0) {
    result.categoryIdList = params.categoryIdList;
  }
  if (params.promotionIdList.length > 0) {
    result.promotionIdList = params.promotionIdList;
  }
  if (params.exhibitionIdList.length > 0) {
    result.exhibitionIdList = params.exhibitionIdList;
  }

  if (params.isNaverAdMaterial) {
    result.isNaverAdMaterial = true;
  }

  if (params.needCount) {
    result.needCount = true;
  }

  if (params.keyword) {
    result.keyword = params.keyword;
  }

  return result;
};
