import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { IModal } from 'types';
import { Button, Checkbox, FormItem, Radio, ReModal, Select } from 'components';
import axios from 'api/axios';
import { EXHIBITION_DISCOUNT_TYPE, request, useObject } from 'services';
import { PlusIcon, XIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';

export interface Props extends IModal {
  targetIds: string[];
}
interface State {
  options: Array<{
    type: 'add' | 'delete';
    shopId: number;
    gender: string;
    promotionId: number;
    promotionRules: any[];
    promotionList: any[];
    promotionRuleIds: number[];
    discountType: keyof typeof EXHIBITION_DISCOUNT_TYPE | null;
    shopCurrency: string;
  }>;
  isSubmitting: boolean;
  shopList: any[];
}

const PromotionModal: FunctionComponent<Props> = ({ isOpen, onClose, targetIds }) => {
  if (!isOpen) return null;
  const [{ options, isSubmitting, shopList }, setState] = useObject<State>({
    options: [
      {
        type: 'add',
        shopId: 0,
        gender: '',
        promotionId: 0,
        promotionList: [],
        promotionRules: [],
        promotionRuleIds: [],
        discountType: null,
        shopCurrency: '',
      },
    ],
    isSubmitting: false,
    shopList: [],
  });

  const get = async () => {
    try {
      const { data } = await axios.get('/api/shops');
      setState({
        shopList: data
          .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          })
          .map((option) => ({ ...option, value: option.id })),
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getPromotionByShopId = async (key: number, shopId: string) => {
    if (!shopId) return;

    try {
      const data = await request.get<any, any[]>('/promotion/v2', {
        params: {
          shopId: Number(shopId),
          isExtended: true,
        },
      });

      if (!data.length) {
        toast.info('프로모션이 존재하지 않는 편집샵입니다.');
        return;
      }

      setState({
        options: [
          ...options.slice(0, key),
          {
            ...options[key],
            shopId: Number(shopId),
            promotionList: data,
            promotionRuleIds: [],
            promotionRules: [],
            promotionId: 0,
            gender: '',
            shopCurrency: '',
            discountType: null,
          },
          ...options.slice(key + 1),
        ],
      });
    } catch (err) {
      console.log(err);
    }
  };
  const onSubmit = async () => {
    if (!window.confirm('적용하시겠습니까?')) return;

    setState({ isSubmitting: true });

    let addPromotions: number[] = [];
    let deletePromotions: number[] = [];
    try {
      options.forEach((item) => {
        if (item.type === 'add') addPromotions.push(...item.promotionRuleIds);
        else if (item.type === 'delete') deletePromotions.push(...item.promotionRuleIds);
      });
      await Promise.all([
        ...addPromotions.map((id) =>
          axios.put(`/api/promotions/${id}/items/bulk`, targetIds.map(Number))
        ),
        ...deletePromotions.map((id) =>
          axios.delete(`/api/promotions/${id}/items/bulk`, {
            data: targetIds.map(Number),
          })
        ),
      ]);
      toast.success('프로모션이 업데이트되었습니다.');
      onClose();
    } catch (err: any) {
      console.dir(err);
      setState({ isSubmitting: false });
      if (err.response.status === 400) {
        toast.error('편집샵 등의 정보가 일치하지 않습니다. 확인 부탁드립니다.');
      } else {
        toast.error('에러가 발생했습니다. 개발 팀에 보고 부탁드립니다.');
      }
    }
  };

  const discountLabel = ({ discountType, typeValue, shopCurrency }) => {
    if (discountType === 'FREE_DELIVERY') return `${typeValue} ${shopCurrency} 이상`;
    else if (discountType === 'DISCOUNT_WITH_RATE') return `${typeValue * 100}%`;
    return `${typeValue} ${shopCurrency}`;
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <ReModal
      isOpen={isOpen}
      onClose={onClose}
      maxWidth="max-w-4xl"
      title="프로모션 업데이트"
    >
      <div className="text-right">
        <Button
          size="sm"
          theme="secondary"
          onClick={() =>
            setState({
              options: [
                ...options,
                {
                  type: 'add',
                  shopId: 0,
                  gender: '',
                  promotionList: [],
                  promotionRules: [],
                  promotionId: 0,
                  promotionRuleIds: [],
                  discountType: null,
                  shopCurrency: '',
                },
              ],
            })
          }
        >
          <PlusIcon className="w-5 h-5" />
        </Button>
      </div>

      <div className="grid grid-cols-2 gap-4 my-4">
        {options.map((option, key) => (
          <div
            key={key}
            className="border border-black rounded p-4 space-y-4 group relative"
          >
            {options.length > 1 && (
              <button
                onClick={() =>
                  setState({ options: options.filter((_, index) => index !== key) })
                }
                className="absolute top-4 right-4 hidden group-hover:block"
              >
                <XIcon className="text-black w-5 h-5" />
              </button>
            )}
            <FormItem label="유형">
              <Radio
                value={option.type}
                options={[
                  { name: '추가', value: 'add' },
                  { name: '삭제', value: 'delete' },
                ]}
                onChange={(type) =>
                  setState({
                    options: [
                      ...options.slice(0, key),
                      { ...options[key], type },
                      ...options.slice(key + 1),
                    ],
                  })
                }
                id={key}
              />
            </FormItem>
            <FormItem label="편집샵">
              <Select
                value={option.shopId}
                onChange={(e) => getPromotionByShopId(key, e.target.value)}
                size="sm"
              >
                <option value={0}>선택</option>
                {shopList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormItem>
            <FormItem label="성별">
              <Radio
                value={option.gender}
                options={[
                  { name: '선택', value: '' },
                  { name: '남성', value: 'M' },
                  { name: '여성', value: 'W' },
                  { name: '둘다', value: 'MW' },
                ]}
                onChange={(gender) =>
                  setState({
                    options: [
                      ...options.slice(0, key),
                      {
                        ...options[key],
                        gender,
                        promotionId: 0,
                        promotionRules: [],
                        shopCurrency: '',
                        discountType: null,
                      },
                      ...options.slice(key + 1),
                    ],
                  })
                }
                id={key}
              />
            </FormItem>
            <FormItem label="프로모션">
              <Select
                size="sm"
                value={option.promotionId}
                onChange={(e) =>
                  setState({
                    options: [
                      ...options.slice(0, key),
                      {
                        ...options[key],
                        promotionId: Number(e.target.value),
                        promotionRules: option.promotionList.find(
                          (promotion) => promotion.id === Number(e.target.value)
                        ).rules,
                        shopCurrency:
                          option.promotionList.find(
                            (promotion) => promotion.id === Number(e.target.value)
                          ).shopCurrency || '',
                        discountType:
                          option.promotionList.find(
                            (promotion) => promotion.id === Number(e.target.value)
                          ).type || null,
                      },
                      ...options.slice(key + 1),
                    ],
                  })
                }
                disabled={!option.gender || !option.shopId}
              >
                <option value={0} disabled selected>
                  선택 (편집샵과 성별 선택 후)
                </option>
                {option.promotionList
                  .filter((promotion) => promotion.gender === option.gender)
                  .map((promotion, index) => (
                    <option value={promotion.id} key={index}>
                      {(promotion.isActive ? 'ON' : 'OFF') + ' / '}
                      {promotion.shopId !== promotion.priceRuleId &&
                        promotion.shopCountry + ' / '}
                      {promotion.isAppliedWith}
                      {promotion.code ? ' / ' + promotion.code : ' / '}
                      {discountLabel({
                        discountType: promotion.type,
                        shopCurrency: promotion.shopCurrency,
                        typeValue: Array.isArray(promotion.typeValue)
                          ? promotion.typeValue[0]
                          : 0,
                      })}
                      {' / ' + promotion.name}
                    </option>
                  ))}
              </Select>
            </FormItem>
            <FormItem label="할인율">
              <div className="flex flex-wrap gap-2">
                <Checkbox
                  checked={
                    !!option.promotionRules.length &&
                    option.promotionRuleIds.length === option.promotionRules.length
                  }
                  label="전체"
                  onChange={(checked) =>
                    setState({
                      options: [
                        ...options.slice(0, key),
                        {
                          ...options[key],
                          promotionRuleIds: checked
                            ? options[key].promotionRules.map((promotion) => promotion.id)
                            : [],
                        },
                        ...options.slice(key + 1),
                      ],
                    })
                  }
                />
                {option.promotionRules.map((item, index) => (
                  <Checkbox
                    key={index}
                    checked={option.promotionRuleIds.indexOf(item.id) !== -1}
                    onChange={(checked) =>
                      setState({
                        options: [
                          ...options.slice(0, key),
                          {
                            ...options[key],
                            promotionRuleIds: checked
                              ? [...options[key].promotionRuleIds, item.id]
                              : options[key].promotionRuleIds.filter(
                                  (id) => id !== item.id
                                ),
                          },
                          ...options.slice(key + 1),
                        ],
                      })
                    }
                    label={discountLabel({
                      typeValue: item.typeValue || 0,
                      discountType: option.discountType,
                      shopCurrency: option.shopCurrency,
                    })}
                  />
                ))}
              </div>
            </FormItem>
          </div>
        ))}
      </div>

      <div className="text-right">
        <Button theme="primary" onClick={onSubmit} isLoading={isSubmitting}>
          적용
        </Button>
      </div>
    </ReModal>
  );
};

export default PromotionModal;
