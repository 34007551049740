import React from 'react';
import * as style from '../style';

const Postion = ({ state, handleCheckbox }) => {
  return (
    <style.FilterBox>
      <style.ItemTitle>
        <style.TitleText>노출 위치</style.TitleText>
      </style.ItemTitle>
      <style.Item style={{ flexDirection: 'column' }}>
        <style.Div>
          <style.CheckBox
            checked={state.isVisibleToAppMain === '1'}
            onChange={(e) =>
              handleCheckbox('isVisibleToAppMain', e.target.checked)
            }
          />
          <style.ItemText>
            앱{' > '}
            메인
          </style.ItemText>
        </style.Div>
        <style.Div>
          <style.CheckBox
            checked={state.isVisibleToAppCategory === '1'}
            onChange={(e) =>
              handleCheckbox('isVisibleToAppCategory', e.target.checked)
            }
          />
          <style.ItemText>
            앱{' > '}
            카테고리
          </style.ItemText>
        </style.Div>
        <style.Div>
          <style.CheckBox
            checked={state.isVisibleToCafe24 === '1'}
            onChange={(e) =>
              handleCheckbox('isVisibleToCafe24', e.target.checked)
            }
          />
          <style.ItemText>
            카페24
            {' > '}
            메인
          </style.ItemText>
        </style.Div>
      </style.Item>
    </style.FilterBox>
  );
};

export default Postion;
