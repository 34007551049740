import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { TiArrowUpOutline } from 'react-icons/ti';
import { IoIosImages } from 'react-icons/io';
import Dropzone from 'react-dropzone';
import axios from 'api/axios';
import * as style from './style';

const ModifyShopInfo = ({ history }) => {
  const selectShopBasicInfoData = useSelector(
    (state) => state.selectShopData.selectShopBasicData,
  );

  const [koreaShopName, setKoreaShopName] = useState(
    selectShopBasicInfoData.krName,
  );
  const [englishShopName, setEnglishShopName] = useState(
    selectShopBasicInfoData.name,
  );
  const [shopUrl, setShopUrl] = useState(selectShopBasicInfoData.url);
  const [imgUrl, setImgUrl] = useState(selectShopBasicInfoData.imageUrl);
  const [currency, setCurrency] = useState(selectShopBasicInfoData.currency);
  const [country, setCountry] = useState(selectShopBasicInfoData.country);
  const [language, setLanguage] = useState(selectShopBasicInfoData.lang);
  const [shopType, setShopType] = useState(selectShopBasicInfoData.type);
  const [selectShippingCountry, setSelectShippingCountry] = useState(
    selectShopBasicInfoData.deliveryCountry,
  );
  const [shippingCost, setShippingCost] = useState(
    selectShopBasicInfoData.deliveryFee,
  );
  const [deliveryPeriod, setDeliveryPeriod] = useState(
    !selectShopBasicInfoData.deliveryPeriod && '',
  );
  const [overseasShippingProgress, setOverseasShippingProgress] = useState(
    selectShopBasicInfoData.isDeliveryOtherCountry,
  );
  const [freeOverseasShippingProgress, setFreeOverseasShippingProgress] =
    useState(selectShopBasicInfoData.hasFreeDeliveryService);
  const [overseasFreeMinAmount, setOverseasFreeMinAmount] = useState(
    selectShopBasicInfoData.minimumPriceForFreeDelivery,
  );
  const [priceIncludesVAT, setPriceIncludesVAT] = useState(
    selectShopBasicInfoData.isDutyAndTaxSeparately,
  );
  const [ddpService, setDdpService] = useState(
    selectShopBasicInfoData.isDdpService,
  );
  const [shopMinimumTax_USD, setShopMinimumTax_USD] = useState(
    selectShopBasicInfoData.minimumTaxUSD,
  );

  const handleModifySelectBasicInfo = async () => {
    const body = {
      shopId: selectShopBasicInfoData.id,
      name: englishShopName,
      krName: koreaShopName,
      url: shopUrl,
      imageUrl: imgUrl,
      isDdpService: ddpService !== null && Boolean(parseInt(ddpService)),
      minimumTaxUSD: Number(shopMinimumTax_USD),
      hasFreeDeliveryService: Boolean(Number(freeOverseasShippingProgress)),
      minimumPriceForFreeDelivery: Number(overseasFreeMinAmount),
      isDeliveryOtherCountry: Boolean(Number(overseasShippingProgress)),
      deliveryFee: Number(shippingCost),
      deliveryPeriod,
      deliveryCountry: selectShippingCountry,
      isDutyAndTaxSeparately: Boolean(parseInt(priceIncludesVAT)),
      currency,
      country,
      lang: language,
      type: shopType,
    };
    console.log(body);
    try {
      await axios.patch(`/api/shops/${selectShopBasicInfoData.id}`, body);
      // props.history.push(`/storeInfoManagement/basicInfo/${selectShopBasicInfoData.id}`);
      history.push('/shop/shopManagement');
    } catch (err) {
      console.error(err);
    }
  };

  const handleOnDrop = (files) => {
    const axiosReq = (b64Buffer) => {
      axios
        .post('/api/util/image', {
          path: 'nShopImage',
          image: b64Buffer,
        })
        .then((res) => {
          if (res.data.success) {
            const imgUrl = res.data.data.fpath + res.data.data.fname;
            setImgUrl(imgUrl);
          }
        })
        .catch((err) => console.log(err));
    };

    const getBase64 = (file, cb) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    };

    getBase64(files[0], axiosReq);
  };

  return (
    <style.StorePageOuter>
      <style.FormInner>
        <style.FormInfoBox>
          <Form.Label style={style.FormTitle}>편집샵 기본 정보</Form.Label>
          <style.ShopInfoBox>
            <style.ShopInfoItem>
              <Form.Label style={style.MaginTop20}>한글이름</Form.Label>
              <Form.Control
                type="text"
                placeholder="한글 이름"
                value={koreaShopName}
                onChange={(e) => setKoreaShopName(e.target.value)}
              />
              <Form.Label style={style.MaginTop20}>영문이름</Form.Label>
              <Form.Control
                type="text"
                placeholder="영문 이름"
                value={englishShopName}
                onChange={(e) => setEnglishShopName(e.target.value)}
              />
              <Form.Label style={style.MaginTop20}>URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="shop URL"
                value={shopUrl}
                onChange={(e) => setShopUrl(e.target.value)}
              />
              <Form.Label style={style.MaginTop20}>Image URL</Form.Label>
              <Form.Control
                type="text"
                placeholder="Image URL"
                value={imgUrl}
                onChange={(e) => setImgUrl(e.target.value)}
              />
              <div style={style.MaginTop20}>
                <TiArrowUpOutline />
              </div>
              <Dropzone
                onDrop={handleOnDrop}
                multiple={false}
                maxSize={10000000}
              >
                {({ getRootProps, getInputProps }) => (
                  <style.DropZoneBox {...getRootProps()}>
                    <input {...getInputProps()} />
                    <IoIosImages style={style.ImageIcon} />
                    이미지 파일 업로드
                  </style.DropZoneBox>
                )}
              </Dropzone>
            </style.ShopInfoItem>
            <style.ShopInfoItem>
              <Form.Label style={style.MaginTop20}>통화</Form.Label>
              <Form.Control
                as="select"
                defaultValue={currency}
                onChange={(e) => {
                  setCurrency(e.target.value);
                }}
              >
                <option value="KRW">KRW</option>
                <option value="USD">USD</option>
                <option value="GBP">GBP</option>
                <option value="EUR">EUR</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>국가설정</Form.Label>
              <Form.Control
                as="select"
                defaultValue={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              >
                <option value="KR">한국</option>
                <option value="GB">영국</option>
                <option value="DE">독일</option>
                <option value="NC">프랑스</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>편집샵 언어</Form.Label>
              <Form.Control
                as="select"
                defaultValue={language}
                onChange={(e) => {
                  setLanguage(e.target.value);
                }}
              >
                <option value="en">영어</option>
                <option value="kr">한국어</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>편집샵 타입</Form.Label>
              <Form.Control
                as="select"
                defaultValue={shopType}
                onChange={(e) => setShopType(e.target.value)}
              >
                <option value="해외편집샵">해외편집샵</option>
                <option value="국내편집샵">국내편집샵</option>
                <option value="구매대행">구매대행</option>
              </Form.Control>
            </style.ShopInfoItem>
          </style.ShopInfoBox>
        </style.FormInfoBox>
        <style.FormInfoBox>
          <Form.Label style={style.FormTitle}>편집샵 배송 정보</Form.Label>
          <style.ShopInfoBox>
            <style.ShopInfoItem>
              <Form.Label style={style.MaginTop20}>배송 국가 선택</Form.Label>
              <Form.Control
                as="select"
                defaultValue={selectShippingCountry}
                onChange={(e) => {
                  setSelectShippingCountry(e.target.value);
                }}
              >
                <option value="GB">영국</option>
                <option value="DE">독일</option>
                <option value="US">미국</option>
                <option value="ES">스페인</option>
                <option value="FR">프랑스</option>
                <option value="HK">홍콩</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>직배송 비용</Form.Label>
              <Form.Control
                type="text"
                placeholder="직배송 비용 예) ($ 생략) 40"
                value={shippingCost}
                onChange={(e) => setShippingCost(e.target.value)}
              />
              <Form.Label style={style.MaginTop20}>직배송 기간</Form.Label>
              <Form.Control
                type="text"
                placeholder="직배송 기간 예) 최소 x일 ~ 최대 x일"
                value={deliveryPeriod}
                onChange={(e) => setDeliveryPeriod(e.target.value)}
              />
            </style.ShopInfoItem>
            <style.ShopInfoItem>
              <Form.Label style={style.MaginTop20}>해외 직배송 여부</Form.Label>
              <Form.Control
                as="select"
                defaultValue={overseasShippingProgress}
                onChange={(e) => setOverseasShippingProgress(e.target.value)}
              >
                <option disabled>해외 직배송 여부</option>
                <option>예</option>
                <option>아니오</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>
                무료 해외 직배송 여부
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue={freeOverseasShippingProgress}
                onChange={(e) => {
                  setFreeOverseasShippingProgress(e.target.value);
                }}
              >
                <option value={1}>예</option>
                <option value={0}>아니오</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>
                무료 해외 직배송 최소 금액
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="무료 해외 직배송 최소 금액 예) $300"
                value={overseasFreeMinAmount}
                onChange={(e) => setOverseasFreeMinAmount(e.target.value)}
              />
            </style.ShopInfoItem>
          </style.ShopInfoBox>
        </style.FormInfoBox>
        <style.FormInfoBox>
          <Form.Label style={style.FormTitle}>관/부가세 정보</Form.Label>
          <style.ShopInfoBox>
            <style.ShopInfoItem>
              <Form.Label style={style.MaginTop20}>
                가격 부가세포함 여부
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue={priceIncludesVAT}
                onChange={(e) => {
                  setPriceIncludesVAT(e.target.value);
                }}
              >
                <option value={1}>예</option>
                <option value={0}>아니오</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>ddp 서비스 여부</Form.Label>
              <Form.Control
                as="select"
                value={ddpService}
                onChange={(e) => {
                  setDdpService(e.target.value);
                }}
              >
                <option value={1}>예</option>
                <option value={0}>아니오</option>
              </Form.Control>
              <Form.Label style={style.MaginTop20}>과세 기준</Form.Label>
              <Form.Control
                type="text"
                value={shopMinimumTax_USD}
                placeholder="과세 기준"
                onChange={(e) => {
                  setShopMinimumTax_USD(e.target.value);
                }}
              />
            </style.ShopInfoItem>
          </style.ShopInfoBox>
        </style.FormInfoBox>
        <Button variant="dark" onClick={handleModifySelectBasicInfo}>
          수정 완료
        </Button>
      </style.FormInner>
    </style.StorePageOuter>
  );
};

export default withRouter(ModifyShopInfo);
