import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`;
const Input = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  width: 0px;
  height: 0px;
`;
const Span = styled.span`
  position: absolute;
  cursor: pointer;
`;

export interface Props {
  checked: boolean;
}
interface State {}

const Switch: FunctionComponent<Props> = ({ checked }) => {
  return (
    <Label>
      <Input checked={checked} />
      <Span></Span>
    </Label>
  );
};

export default Switch;
