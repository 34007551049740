import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import axios from 'api/axios';

const ItemShowModal = (props) => {
  const { onHide, targetIds } = props;
  // const itemIds = useSelector((state) => state.selectItems.itemsId);
  const itemIds = targetIds;

  const [isShow, setIsShow] = useState('1');

  const handleChangeItemShow = () => {
    const resultItemIds = itemIds.map((item) => String(item));
    const body = {
      itemIDs: resultItemIds,
      itemShow: Boolean(Number(isShow)),
    };
    axios
      .put('/api/item/itemedit', body)
      .then((res) => {
        if (res.data.success) {
          onHide(true);
        } else {
          alert('다시 시도');
        }
      })
      .catch((err) => {
        alert(`
        📌 에러가 발생했습니다.! 
        아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.
  
        API 에러 발생 
        PUT - /api/item/itemedit
        ${err}`);
        console.error(err);
      });
  };

  const ModalLoading = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
    height: '100px',
  };

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>상품 활성화 변경</Modal.Title>
      </Modal.Header>
      <Modal.Body style={ModalLoading}>
        <Form.Control
          as="select"
          onChange={(e) => {
            setIsShow(e.target.value);
          }}
        >
          <option value={1}>상품 활성화</option>
          <option value={0}>상품 비활성화</option>
        </Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            handleChangeItemShow();
          }}
        >
          설정
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemShowModal;
