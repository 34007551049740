import { Button, FormItem, ReModal, Select } from 'components';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import {
  enumToList,
  ORDER_CANCEL_REASON,
  request,
  SHOP_ORDER_STATUS,
  useObject,
  useOrderDetail,
} from 'services';
import { IModal } from 'types';

export interface Props extends IModal {
  itemOrderNumber: string;
  itemName: string;
  orderStatus: string;
}
interface State {
  orderCancelReason: keyof typeof ORDER_CANCEL_REASON | '';
  isLoading: boolean;
}

const RemoveItemModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  itemName,
  itemOrderNumber,
  orderStatus,
}) => {
  if (!isOpen) return null;

  const [{ isLoading, orderCancelReason }, setState, onChange] = useObject<State>({
    isLoading: false,
    orderCancelReason: '',
  });
  const [{ payMethod }, setOrderState, { refreshOrderState }] = useOrderDetail();

  const onSubmit = async () => {
    if (!orderCancelReason) {
      toast.info('삭제 사유를 선택해 주세요.');
      return;
    }
    if (!window.confirm('상품을 삭제하시겠습니까?')) return;

    setState({ isLoading: true });
    try {
      await request.post(`/commerce/order/item-order/${itemOrderNumber}/order-remove`, {
        orderCancelReason,
      });
      refreshOrderState();
      if (payMethod === 'DEPOSIT')
        toast.success('상품이 삭제되었습니다. 무통장입금의 경우 직접 환불이 필요합니다.');
      else toast.success('상품이 삭제되었습니다.');
      onClose();
    } catch (err) {
      console.log(err);
      setState({ isLoading: false });
    }
  };
  return (
    <ReModal
      title="상품 삭제"
      description="선택한 상품을 삭제합니다."
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="space-y-4">
        <FormItem label="주문상태" readOnly>
          {SHOP_ORDER_STATUS[orderStatus]}
        </FormItem>
        <FormItem label="상품명" readOnly>
          {itemName}
        </FormItem>
        <FormItem label="삭제 사유 선택" required>
          <Select value={orderCancelReason} name="orderCancelReason" onChange={onChange}>
            <option value="" disabled selected>
              선택
            </option>
            {enumToList(ORDER_CANCEL_REASON).map((item, key) => (
              <option value={item.value} key={key}>
                {item.name}
              </option>
            ))}
          </Select>
        </FormItem>
        <div className="flex justify-center">
          <Button isLoading={isLoading} onClick={onSubmit}>
            상품 삭제하기
          </Button>
        </div>
      </div>
    </ReModal>
  );
};

export default RemoveItemModal;
