import React, { useEffect } from 'react';
import type { FunctionComponent } from 'react';
import { IModal } from 'types';
import {
  Button,
  Divider,
  FormContainer,
  FormItem,
  ImageUpload,
  ReInput,
  ReModal,
  Textarea,
} from 'components';
import { request, useObject } from 'services';
import Switch from 'react-switch';
import { toast } from 'react-toastify';

export interface Props extends IModal {
  id: number;
}
interface IBrand {
  backgroundImageUrl: string;
  bannerImageUrl: string;
  cafe24BrandCode: string;
  description: string;
  imageUrl: string;
  isActive: boolean;
  isNaverUpload: boolean;
  krName: string;
  mainName: string;
  mobileBackgroundImageUrl: string;
  name: string;
  pcBackgroundImageUrl: string;
  priority: number;
  webBannerImageUrl: string;
}
interface State extends IBrand {
  isUpdating: boolean;
  isDeleting: boolean;
}

const BrandModal: FunctionComponent<Props> = ({ isOpen, onClose, id }) => {
  if (!isOpen) return null;
  const [
    {
      bannerImageUrl,
      backgroundImageUrl,
      cafe24BrandCode,
      description,
      imageUrl,
      isUpdating,
      isNaverUpload,
      isActive,
      mainName,
      mobileBackgroundImageUrl,
      name,
      priority,
      pcBackgroundImageUrl,
      webBannerImageUrl,
      krName,
      isDeleting,
    },
    setState,
    onChange,
  ] = useObject<State>({
    backgroundImageUrl: '',
    bannerImageUrl: '',
    cafe24BrandCode: '',
    description: '',
    imageUrl: '',
    isActive: false,
    isNaverUpload: false,
    krName: '',
    mainName: '',
    mobileBackgroundImageUrl: '',
    name: '',
    pcBackgroundImageUrl: '',
    priority: 0,
    webBannerImageUrl: '',
    isUpdating: false,
    isDeleting: false,
  });

  const get = async () => {
    if (!id) return;

    try {
      const data = await request.get<any, IBrand>(`/brand/${id}`);
      setState({
        backgroundImageUrl: data.backgroundImageUrl,
        bannerImageUrl: data.bannerImageUrl,
        cafe24BrandCode: data.cafe24BrandCode,
        description: data.description,
        imageUrl: data.imageUrl,
        isActive: data.isActive,
        isNaverUpload: data.isNaverUpload,
        krName: data.krName,
        mainName: data.mainName,
        mobileBackgroundImageUrl: data.mobileBackgroundImageUrl,
        name: data.name,
        pcBackgroundImageUrl: data.pcBackgroundImageUrl,
        priority: data.priority,
        webBannerImageUrl: data.webBannerImageUrl,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const remove = async () => {
    if (!window.confirm('정말 삭제하시겠습니까?')) return;

    setState({ isDeleting: true });
    try {
      await request.delete(`/brand/${id}`);
    } catch (err) {
      console.log(err);
    } finally {
      setState({ isDeleting: false });
    }
  };

  const update = async () => {
    if (!window.confirm(`${id ? '수정' : '추가'}하시겠습니까?`)) return;

    const data: IBrand = {
      backgroundImageUrl,
      bannerImageUrl,
      krName,
      name,
      mainName,
      mobileBackgroundImageUrl,
      imageUrl,
      webBannerImageUrl,
      pcBackgroundImageUrl,
      cafe24BrandCode,
      priority,
      description,
      isActive,
      isNaverUpload,
    };
    setState({ isUpdating: true });
    try {
      if (id) {
        await request.put(`/brand/${id}`, data);
      } else {
        await request.post('/brand', data);
      }
      toast.success(`${id ? '수정' : '추가'}되었습니다.`);
      onClose();
    } catch (err) {
      console.log(err);
      setState({ isUpdating: false });
    }
  };

  useEffect(() => {
    get();
  }, []);
  return (
    <ReModal
      title={`브랜드 ${id ? '수정' : '추가'}`}
      maxWidth="max-w-4xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <FormContainer title="필수 정보">
        <FormItem required label="표시명">
          <ReInput
            id="표시명"
            value={mainName}
            name="mainName"
            onChange={onChange}
            autoFocus
          />
        </FormItem>
        <FormItem required label="한글명">
          <ReInput id="한글명" value={krName} name="krName" onChange={onChange} />
        </FormItem>
        <FormItem required label="영문명">
          <ReInput id="영문명" value={name} name="name" onChange={onChange} />
        </FormItem>
        <FormItem required label="활성화 여부">
          <Switch
            id="활성화 여부"
            checked={isActive}
            onChange={(isActive) => setState({ isActive })}
          />
        </FormItem>
        <FormItem required label="로고 이미지">
          <ImageUpload
            value={imageUrl}
            type="app"
            path="brands"
            onUpload={(imageUrl) => setState({ imageUrl })}
          />
        </FormItem>
      </FormContainer>
      <Divider />
      <FormContainer title="추가 정보">
        <FormItem label="카페24 브랜드 코드">
          <ReInput
            id="카페24 브랜드 코드"
            value={cafe24BrandCode}
            name="cafe24BrandCode"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="네이버 피드 업로드 여부">
          <Switch
            id="네이버 피드 업로드 여부"
            checked={isNaverUpload}
            onChange={(isNaverUpload) => setState({ isNaverUpload })}
          />
        </FormItem>
        <FormItem label="우선순위 (낮을수록 우선)">
          <ReInput
            type="number"
            min={0}
            value={priority}
            name="priority"
            onChange={onChange}
          />
        </FormItem>
        <FormItem label="배경 이미지">
          <ImageUpload
            path="shops"
            value={backgroundImageUrl}
            onUpload={(backgroundImageUrl) => setState({ backgroundImageUrl })}
            type="app"
          />
        </FormItem>
        <FormItem label="배너 이미지">
          <ImageUpload
            path="brand-banner"
            value={bannerImageUrl}
            onUpload={(bannerImageUrl) => setState({ bannerImageUrl })}
            type="app"
          />
        </FormItem>
        <FormItem label="모바일용 배경 이미지">
          <ImageUpload
            value={mobileBackgroundImageUrl}
            onUpload={(mobileBackgroundImageUrl) =>
              setState({ mobileBackgroundImageUrl })
            }
            path="brands"
            type="app"
          />
        </FormItem>
        <FormItem label="PC용 배경 이미지">
          <ImageUpload
            value={pcBackgroundImageUrl}
            onUpload={(pcBackgroundImageUrl) => setState({ pcBackgroundImageUrl })}
            path="brands"
            type="app"
          />
        </FormItem>
        <FormItem label="설명">
          <Textarea
            id="설명"
            value={description}
            name="description"
            onChange={onChange}
            rows={5}
          />
        </FormItem>
      </FormContainer>
      <div className="flex justify-end gap-2">
        {!!id && (
          <Button
            isLoading={isDeleting}
            disabled={isDeleting}
            theme="danger"
            onClick={remove}
          >
            삭제
          </Button>
        )}
        <Button onClick={update} isLoading={isUpdating}>
          {id ? '수정' : '추가'}
        </Button>
      </div>
    </ReModal>
  );
};

export default BrandModal;
