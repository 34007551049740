import React, { FunctionComponent } from 'react';
import { usePagination } from 'services';
import classnames from 'classnames';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

export interface Props {
  currentPage: number;
  totalCount: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  siblingCount?: number;
}
interface State {}

const RePagination: FunctionComponent<Props> = ({
  currentPage,
  totalCount,
  pageSize,
  onPageChange,
  siblingCount = 1,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPage === 0 || !paginationRange) return null;

  if (paginationRange.length < 2) {
    return (
      <ul className="inline-flex rounded-md border bg-white border-gray-300 divide-x divide-gray-300 text-sm font-medium">
        <li className="p-2 inline-flex items-center cursor-not-allowed">
          <ChevronLeftIcon className="w-5 h-5" />
        </li>
        <li className="px-3 py-2 inline-flex items-center cursor-pointer bg-blue-50">
          1
        </li>
        <li className="p-2 inline-flex items-center cursor-not-allowed">
          <ChevronRightIcon className="w-5 h-5" />
        </li>
      </ul>
    );
  }

  return (
    <ul className="inline-flex rounded-md border bg-white border-gray-300 divide-x divide-gray-300 text-sm font-medium select-none">
      <li
        className={classnames(
          'p-2 inline-flex items-center select-none',
          currentPage === 1 ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-blue-50'
        )}
        onClick={() => {
          if (currentPage === 1) return;
          onPageChange(currentPage - 1);
        }}
      >
        <ChevronLeftIcon className="w-5 h-5" />
      </li>
      {paginationRange.map((pageNumber, key) => {
        if (pageNumber === '...') {
          return (
            <li key={key} className="p-2 inline-flex items-center">
              &#8230;
            </li>
          );
        }

        return (
          <li
            className={classnames(
              'px-3 py-2 inline-flex items-center cursor-pointer hover:bg-blue-50 select-none',
              {
                'bg-blue-100': pageNumber === currentPage,
              }
            )}
            key={key}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames(
          'p-2 inline-flex items-center select-none',
          totalCount < currentPage * pageSize
            ? 'cursor-not-allowed'
            : 'cursor-pointer hover:bg-blue-50'
        )}
        onClick={() => {
          if (totalCount < currentPage * pageSize) return;
          onPageChange(currentPage + 1);
        }}
      >
        <ChevronRightIcon className="w-5 h-5" />
      </li>
    </ul>
  );
};

export default RePagination;
