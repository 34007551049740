import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import axios from 'api/axios';
import * as style from './style';

const ShopSaleInfo = ({ history }) => {
  const selectShopInfoData = useSelector(
    (state) => state.selectShopData.selectShopBasicData,
  );

  const [shopSaleInfo, setShopSaleInfo] = useState({});

  useEffect(() => {
    axios
      .get(`/api/shop/${selectShopInfoData.id}/sale`)
      .then((res) => {
        setShopSaleInfo(res.data.data[0]);
      })
      .catch((err) => console.error(err));
  }, [selectShopInfoData.shop_id]);

  const getDate = (inputDate) => {
    const result = new Date(inputDate);
    const year = result.getFullYear();
    const month = result.getMonth() + 1;
    const date = result.getDate();

    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  const handleDeleteShopSaleInfo = () => {
    axios
      .delete(`/api/shop/${selectShopInfoData.shop_id}/sale`)
      .then((res) => {
        if (res.data.success) {
          window.location.reload();
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <style.Outer>
      <Card className="text-center" style={style.Inner}>
        <Card.Header>{selectShopInfoData.shop_name}</Card.Header>
        <Card.Body>
          <ListGroup style={style.GroupOuter} horizontal>
            <ListGroup.Item variant="secondary" style={style.listItem1}>
              세일 상태
            </ListGroup.Item>
            <ListGroup.Item style={style.listItem2}>
              {shopSaleInfo.is_sale === 1 ? '세일중' : '세일 안함'}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup style={style.GroupOuter} horizontal>
            <ListGroup.Item variant="secondary" style={style.listItem1}>
              세일 이름
            </ListGroup.Item>
            <ListGroup.Item style={style.listItem2}>
              {shopSaleInfo.sale_name}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup style={style.GroupOuter} horizontal>
            <ListGroup.Item variant="secondary" style={style.listItem1}>
              세일 설명
            </ListGroup.Item>
            <ListGroup.Item style={style.listItem2}>
              {shopSaleInfo.sale_description}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup style={style.GroupOuter} horizontal>
            <ListGroup.Item variant="secondary" style={style.listItem1}>
              최소 세일률
            </ListGroup.Item>
            <ListGroup.Item style={style.listItem2}>
              {shopSaleInfo.sale_rate_minimum &&
                String(shopSaleInfo.sale_rate_minimum * 100)}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup style={style.GroupOuter} horizontal>
            <ListGroup.Item variant="secondary" style={style.listItem1}>
              최대 세일률
            </ListGroup.Item>
            <ListGroup.Item style={style.listItem2}>
              {shopSaleInfo.sale_rate_maximum &&
                String(shopSaleInfo.sale_rate_maximum * 100)}
            </ListGroup.Item>
          </ListGroup>
          <ListGroup style={style.GroupOuter} horizontal>
            <ListGroup.Item variant="secondary" style={style.listItem1}>
              세일 기간
            </ListGroup.Item>
            <ListGroup.Item style={style.listItem2}>
              {shopSaleInfo.sale_start_date && shopSaleInfo.sale_start_date && (
                <>
                  {getDate(shopSaleInfo.sale_start_date)} ~{' '}
                  {getDate(shopSaleInfo.sale_end_date)}
                </>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
        <Card.Footer className="text-muted">
          <Button
            variant="dark"
            onClick={() => {
              history.push(`/shop/modifyShopSaleInfo/${selectShopInfoData.id}`);
            }}
          >
            수정
          </Button>{' '}
          <Button variant="dark" onClick={handleDeleteShopSaleInfo}>
            삭제
          </Button>
        </Card.Footer>
      </Card>
    </style.Outer>
  );
};

export default withRouter(ShopSaleInfo);
