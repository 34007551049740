import React from 'react';
import { AddNameKeywordModal } from 'components';
import * as style from './style';

const NameKeywordsModals = ({ showModal, setShowModal, fetchItems, currentPage }) => {
  return (
    <style.Container>
      <AddNameKeywordModal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          fetchItems(currentPage);
        }}
      />
    </style.Container>
  );
};
export default NameKeywordsModals;
