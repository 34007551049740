import { OpenLink } from 'components';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IS_MAIN, PATH, request, useObject, useOrderDetail, useQuery } from 'services';
import { NOrder } from 'types';
import OrderAdditionalInfo from './AdditionalInfo';
import OrderAdminMemo from './AdminMemo';
import OrderBuyerInfo from './BuyerInfo';
import OrderInfo from './Info';
import OrderPayInfo from './PayInfo';
import OrderPayMethod from './PayMethod';
import PlacingOrderInfo from './PlacingOrderInfo';
import OrderReceipt from './Receipt';
import OrderRecipientInfo from './RecipientInfo';
import OrderRefundInfo from './RefundInfo';
import OrderTax from './Tax';
import OrderPurchaseInfo from './PurchaseInfo';
import useSWR from 'swr/immutable';
import classnames from 'classnames';
import { useGetAdminMemo } from './AdminMemo/helper';
import dayjs from 'dayjs';

const Item: FunctionComponent<{ name: string }> = ({ name, children }) => {
  return (
    <div className="flex items-center">
      <div className="bg-gray-100 px-3 flex items-center h-full">{name}</div>
      <div className="p-2">{children}</div>
    </div>
  );
};

interface State {
  tab: number;
  isInitialFetched: boolean;
}

const OrderDetailPage = () => {
  const { query, updateQuery } = useQuery<{ tab: string; highlight?: string }>();
  const [{ tab, isInitialFetched }, setState] = useObject<State>({
    tab: query.tab ? Number(query.tab) : 0,
    isInitialFetched: true,
  });
  const [
    { userId, orderPath, orderedAt, orderUserName, orderUserPhoneNumber },
    setOrderState,
    { refreshOrderState },
  ] = useOrderDetail();
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const { data, error } = useSWR<any>(
    `/commerce/order/fetching-order/${fetchingOrderNumber}`,
    (url) =>
      request(url, {
        params: {
          orderMode:
            !query.tab || query.tab === '0'
              ? true
              : query.tab === '1'
              ? false
              : undefined,
        },
        headers: {
          Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '',
        },
      }),
    { refreshInterval: 1000 * 60 * 60 }
  );

  const { data: memoList } = useGetAdminMemo({
    fetchingOrderNumber,
    itemOrderNumber: '',
    shopOrderNumber: '',
  });
  const manualMemos = memoList?.filter((memo) => !memo.isAuto);

  const TABS = [
    '발주정보',
    '주문정보',
    '매입정보',
    '결제정보',
    '결제수단',
    // '환불정보',
    // '현금영수증',
    // '세금계산서',
    '주문자정보',
    '수령자정보',
    '추가정보',
    <div
      style={{
        color: manualMemos?.length ? 'red' : '',
      }}
    >
      관리자메모 {!!manualMemos?.length && `(${manualMemos.length})`}
    </div>,
  ];

  useEffect(() => {
    if (!data) return;

    const fetchingOrder: NOrder.Detail = data.fetchingOrder;

    let orderState: Partial<NOrder.Detail> = {
      cancelInfo: fetchingOrder.cancelInfo,
      orderPath: fetchingOrder.orderPath,
      orderStatus: fetchingOrder.orderStatus,
      orderUserName: fetchingOrder.orderUserName,
      orderUserEmail: fetchingOrder.orderUserEmail,
      orderUserPhoneNumber: fetchingOrder.orderUserPhoneNumber,
      userType: fetchingOrder.userType,
      userId: fetchingOrder.userId,
      userName: fetchingOrder.userName,
      payAmount: fetchingOrder.payAmount,
      payMethod: fetchingOrder.payMethod,
      phoneNumber: fetchingOrder.phoneNumber,
      orderedAt: dayjs(fetchingOrder.orderedAt).format('YYYY.MM.DD HH:mm:ss'),
      itemTotalAmount: fetchingOrder.itemTotalAmount,
      expectedPayAmount: fetchingOrder.expectedPayAmount,
      expectedPoint: fetchingOrder.expectedPoint,
      totalDeliveryFee: fetchingOrder.totalDeliveryFee,
      totalDiscount: fetchingOrder.totalDiscount,
      totalDiscountDetail: fetchingOrder.totalDiscountDetail,
      totalDutyAndTax: fetchingOrder.totalDutyAndTax,
      totalAdditionalFee: fetchingOrder.totalAdditionalFee,
      orderIp: fetchingOrder.orderIp,
      userStringId: fetchingOrder.userStringId,
    };

    if (isInitialFetched) {
      orderState = {
        ...orderState,
        shopOrderList: fetchingOrder.shopOrderList.map((shop) => ({
          ...shop,
          itemOrderList: shop.itemOrderList.map((item) => ({
            ...item,
            originPrice: item.realtimePrice[shop.shopCountry][0].rawValue,
            shopDiscount: item.realtimePrice[shop.shopCountry][1].rawValue,
            promotionDiscount: item.realtimePrice[shop.shopCountry][2].rawValue,
            promotionDiscountPercent:
              item.realtimePrice[shop.shopCountry][1].rawValue === 0
                ? 0
                : Math.floor(
                    (item.realtimePrice[shop.shopCountry][2].rawValue /
                      item.realtimePrice[shop.shopCountry][1].rawValue) *
                      100
                  ),
            fetchingFee: item.realtimePrice[shop.shopCountry][7].rawValue,
            fetchingFeePercent: item.realtimePrice[shop.shopCountry][7].percent || 0,
            deliveryFee: item.realtimePrice[shop.shopCountry][6].rawValue,
            additionalFee: item.realtimePrice[shop.shopCountry][8].rawValue,
            isAdditionalUpdate: false,
            currentShopCountry: shop.shopCountry,
          })),
        })),
        recipientName: fetchingOrder.recipientName || '',
        address: fetchingOrder.address || '',
        addressDetail: fetchingOrder.addressDetail || '',
        customsClearanceNumber: fetchingOrder.customsClearanceNumber || '',
        englishAddress: fetchingOrder.englishAddress || '',
        englishAddressDetail: fetchingOrder.englishAddressDetail || '',
        englishDeliveryMemo: fetchingOrder.englishDeliveryMemo || '',
        englishRecipientName: fetchingOrder.englishRecipientName || '',
        zipCode: fetchingOrder.zipCode || '',
        deliveryMemo: fetchingOrder.deliveryMemo || '',
        isInitialFetched: true,
      };
    }

    setOrderState(orderState);
  }, [data]);

  useEffect(() => {
    if (!!query.tab) setState({ tab: Number(query.tab) });
  }, [query.tab]);

  return (
    <div
      className={classnames(
        'min-h-screen relative p-4',
        IS_MAIN ? 'bg-black' : 'bg-gray-700'
      )}
    >
      <div className="bg-white rounded p-4" style={{ minHeight: 'calc(100vh - 48px)' }}>
        <div className="text-xl font-bold mb-2">주문상세정보</div>
        <div className="flex mt-4 text-sm gap-3" style={{ border: '1px solid #b4b4b4' }}>
          <Item name="주문자명">
            <OpenLink url={`/user/${userId}`}>{orderUserName}</OpenLink>
          </Item>
          <Item name="주문자 연락처">{orderUserPhoneNumber}</Item>
          <Item name="주문 채널">{PATH[orderPath]}</Item>
          <Item name="주문번호">{fetchingOrderNumber}</Item>
          <Item name="주문일자">{orderedAt}</Item>
        </div>

        <div className="py-8">
          <ul className="flex">
            {TABS.map((item, key) => (
              <li
                key={key}
                className={`border-l border-t border-b border-gray-400 cursor-pointer py-2 px-3 last:border-r last:border-gray-400 ${
                  key === tab ? 'font-bold bg-gray-300' : 'font-normal bg-white'
                }`}
                onClick={() => {
                  setState({ tab: key });
                  updateQuery({ tab: String(key), highlight: undefined });
                  if (key === 0 || key === 1) {
                    setOrderState({ shopOrderList: [] });
                  }
                  refreshOrderState(key === 0 ? true : key === 1 ? false : undefined);
                }}
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        {tab === 0 && (
          <PlacingOrderInfo
            paidAt={data?.fetchingOrder?.paidAt}
            payAmount={data?.fetchingOrder?.payAmount}
            payMethod={data?.fetchingOrder?.payMethod}
          />
        )}
        {tab === 1 && <OrderInfo />}
        {tab === 2 && <OrderPurchaseInfo />}
        {tab === 3 && <OrderPayInfo />}
        {tab === 4 && <OrderPayMethod />}
        {/* {tab === 4 && <OrderRefundInfo />}
        {tab === 5 && <OrderReceipt />}
        {tab === 6 && <OrderTax />} */}
        {tab === 5 && <OrderBuyerInfo />}
        {tab === 6 && <OrderRecipientInfo />}
        {tab === 7 && <OrderAdditionalInfo />}
        {tab === 8 && <OrderAdminMemo />}
      </div>
    </div>
  );
};

export default OrderDetailPage;
