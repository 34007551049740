import React, { useState, useContext, useEffect } from 'react';
import { LoadingContext } from 'context';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'api/axios';
import * as style from './style';
import { Spinner } from 'components';

const Cafe24UploadModal = (props) => {
  const { show, onHide, searchParams, targetIds } = props;

  // const itemIds = useSelector((state) => state.selectItems.itemsId);
  const itemIds = targetIds;
  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [isRes, setIsRes] = useState(false);
  const [target, setTarget] = useState('checked');
  const [failedItems, setFailedItems] = useState('');

  useEffect(() => {
    setIsRes(false);
    setTarget('checked');
    setFailedItems('');
  }, [show]);

  const handleCafe24Upload = async () => {
    try {
      if (target) {
        // if (target === 'total') {
        //   alert(`
        //   미완성 기능입니다! 😭
        //   "✔ 체크된 상품만 요청하기"를 선택해 주세요!
        //   `);
        //   // handleIsLoading(true);
        //   // const res = await axios.post('/api/util/cafe24-update', {
        //   //   searchParams,
        //   // });
        //   // const result = res.data.failed.itemIDs;
        //   // if (result.length) {
        //   //   setIsRes(true);
        //   //   setFailedItems(result.join(','));
        //   // }
        // } else {
        if (itemIds.length > 1000) {
          alert(`
            한 번에 최대 1000개의 상품만 요청 가능합니다.
            상품 개수 조정 후 다시 시도해주세요.
            `);
          return;
        } else if (!itemIds.length) {
          alert(`
            체크된 상품이 없습니다.
            상품 선택 후 다시 시도해 주세요.
            `);
          return;
        } else {
          handleIsLoading(true);
          const itemIDs = itemIds.map((itemId) => Number(itemId));
          const res = await axios.post('/api/util/cafe24-update', {
            itemIDs,
          });
          const result = res.data.failed.itemIDs;
          if (result.length) {
            setFailedItems(result.join(','));
          }
          setIsRes(true);
          handleIsLoading(false);
        }
        // }
      } else {
        alert(`
        업데이트 대상을 선택 후 다시 시도해주세요.
        (체크된 상품만인지, 검색 결과 전체 상품인지)
        `);
        return;
      }
    } catch (err) {
      handleIsLoading(false);
      alert(`
      ❌ 요청 실패
      에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      POST - /api/util/cafe24-update

      ${JSON.stringify(searchParams)}

      ${err}`);
      console.error(err);
    }
  };

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>카페24 업로드</Modal.Title>
      </Modal.Header>
      {isRes && !isLoading ? (
        <Modal.Body>
          <strong>요청 성공 ✅ </strong>
          {failedItems && (
            <div style={style.MarginTop}>
              <strong>
                단, 아래 상품들은 카페 24 업로드 가능 상품에 해당하지 않으므로 요청에
                실패하였습니다.
              </strong>
              <p style={style.MarginTop}>페칭코드: {failedItems} </p>
            </div>
          )}
        </Modal.Body>
      ) : (
        <Modal.Body>
          {isLoading && (
            <style.LoadOuter>
              <Spinner clasaName="h-10 w-10 text-black" />
              <p>loading ... </p>
            </style.LoadOuter>
          )}
          <strong>현재 어드민에 표시된 상품 정보로 카페24에 즉시 업로드 됩니다.</strong>
          <div>
            (단, 카페24 웹사이트까지 반영은 1000개 기준으로
            <strong>최장 3시간 가까이 소요(개당 10초)</strong>될 수 있습니다.)
          </div>
          <br />
          <br />
          <strong>⚠ 한번에 최대 1000개의 상품만 요청 가능합니다.</strong>
          {/* <Form.Group>
            <Form.Control
              as="select"
              onChange={(e) => setTarget(e.target.value)}
            >
              <option value={''}>❗ 업로드 대상을 선택하세요</option>
              <option value={'checked'}>✔ 체크된 상품만 요청하기</option>
              <option value={'total'}>✳ 검색 결과 전체 상품 요청하기</option>
            </Form.Control>
          </Form.Group> */}
        </Modal.Body>
      )}
      <Modal.Footer>
        {isRes ? (
          <Button variant="outline-dark" onClick={onHide}>
            닫기
          </Button>
        ) : (
          <Button variant="outline-dark" onClick={handleCafe24Upload}>
            카페24 업로드 요청
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default Cafe24UploadModal;
