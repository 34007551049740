import React from 'react';
import * as pages from 'pages';

const ContentTab = () => {
  return (
    <>
      <pages.Notice />
    </>
  );
};

export default ContentTab;
