import React from 'react';
import * as style from './style';

const SelectStatusTd = ({ searchOptionTableState, setSearchOptionTableState }) => {
  const { isSellable, isFetchingPayActive, isShopPayActive } = searchOptionTableState;

  return (
    <style.Container>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            isSellable: e.target.value,
          });
        }}
        widthPcnt={22}
      >
        <style.Label>
          <style.Radio value="null" checked={isSellable === 'null'} onChange={() => {}} />
          전체
        </style.Label>
        <style.Label>
          <style.Radio value="true" checked={isSellable === 'true'} onChange={() => {}} />
          활성
        </style.Label>
        <style.Label>
          <style.Radio
            value="false"
            checked={isSellable === 'false'}
            onChange={() => {}}
          />
          비활성
        </style.Label>
      </style.Div>
      <style.Left widthPcnt={11}>이동 후 결제 활성상태</style.Left>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            isShopPayActive: e.target.value,
          });
        }}
        widthPcnt={22}
      >
        <style.Margin9px />
        <style.Label>
          <style.Radio
            value="null"
            checked={isShopPayActive === 'null'}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="true"
            checked={isShopPayActive === 'true'}
            onChange={() => {}}
          />
          활성
        </style.Label>
        <style.Label>
          <style.Radio
            value="false"
            checked={isShopPayActive === 'false'}
            onChange={() => {}}
          />
          비활성
        </style.Label>
      </style.Div>
      <style.Left widthPcnt={11}>페칭 결제 활성상태</style.Left>
      <style.Div
        onChange={(e) => {
          setSearchOptionTableState({
            ...searchOptionTableState,
            isFetchingPayActive: e.target.value,
          });
        }}
        widthPcnt={22}
        noBorder
      >
        <style.Margin9px />
        <style.Label>
          <style.Radio
            value="null"
            checked={isFetchingPayActive === 'null'}
            onChange={() => {}}
          />
          전체
        </style.Label>
        <style.Label>
          <style.Radio
            value="true"
            checked={isFetchingPayActive === 'true'}
            onChange={() => {}}
          />
          활성
        </style.Label>
        <style.Label>
          <style.Radio
            value="false"
            checked={isFetchingPayActive === 'false'}
            onChange={() => {}}
          />
          비활성
        </style.Label>
      </style.Div>
    </style.Container>
  );
};

export default SelectStatusTd;
