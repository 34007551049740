import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  headerAction,
  currentSearchOption,
  currentPageNum,
} from 'actions/action';
import fetchingAPI from 'api/fetchingAPI';
import Table from './Table';
import ButtonBar from './ButtonBar';
import Modal from './Modal';
import Postion from './Positon';
import Pagination from './Pagination';
import * as style from './style';
import _ from 'lodash';

const DISPLAY_AT = {
  isVisibleToAppMain: 'APP_MAIN',
  isVisibleToAppCategory: 'APP_CATEGORY',
  isVisibleToCafe24: 'CAFE24_MAIN',
  isVisibleToCafe24Tab: 'CAFE24_TAB',
};

const ExhibitionManagement = () => {
  const dispatch = useDispatch();
  const main = useSelector((state) => state.main);

  const { searchOption, pageNum } = useSelector((state) => state.exhibition);

  const [checkedItems, setCheckedItems] = useState([]);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState([]);
  const [modal, setModal] = useState({
    isShowModal: false,
    title: '',
  });
  const limit = 10;

  const [state, setState] = useState({
    title: '',
    gender: '',
    isActive: '',
    isVisibleToAppMain: '',
    isVisibleToAppCategory: '',
    isVisibleToCafe24: '',
  });

  const handleCheckItems = (e, item) => {
    if (e.target.checked) {
      const filterItmes = checkedItems.filter(
        (el) => el.exhibitionId !== item.exhibitionId,
      );
      const result = [...filterItmes, item];
      setCheckedItems(result);
    } else {
      const result = checkedItems.filter(
        (el) => el.exhibitionId !== item.exhibitionId,
      );
      setCheckedItems(result);
    }
  };

  const handleCheckbox = (field, checked) => {
    setState({
      ...state,
      [field]: checked ? '1' : '0',
    });
  };

  const handleShowModal = (target) => {
    if (checkedItems.length) {
      setModal({
        ...modal,
        isShowModal: true,
        title: target,
      });
    } else {
      alert('변경할 기획전을 선택해 주세요');
    }
  };

  const setSearchParams = () => {
    let params = {};
    const checkList = {
      title: state.title,
      gender: state.gender,
      isActive: state.isActive,
      isVisibleToAppMain: state.isVisibleToAppMain,
      isVisibleToAppCategory: state.isVisibleToAppCategory,
      isVisibleToCafe24: state.isVisibleToCafe24,
    };

    const tempArr = [];
    Object.keys(checkList).forEach((field) => {
      if (
        [
          'isVisibleToAppMain',
          'isVisibleToAppCategory',
          'isVisibleToCafe24',
        ].indexOf(field) > -1
      ) {
        if (state[field]) {
          tempArr.push(DISPLAY_AT[field]);
        }
      } else if (checkList[field].length) {
        params[field] = state[field];
      }
    });

    if (tempArr.length > 0) {
      params.displayAt = tempArr.join(',');
    }

    return params;
  };

  const handleSearch = async () => {
    try {
      const check = _.isEqual(searchOption, setSearchParams());
      const params = check ? searchOption : setSearchParams();

      dispatch(currentSearchOption(params));
      if (!check) {
        setPage(1);
        dispatch(currentPageNum(params, 1));
      }

      const { data } = await fetchingAPI.getExhibitions(params);
      setItems(data);
    } catch (err) {
      console.error(err);
    }
  };

  const onChangePageNum = (page) => {
    const params = setSearchParams();
    setPage(page);
    dispatch(currentPageNum(params, page));
  };

  const initSearchOption = () => {
    const params = {
      title: '',
      gender: '',
      isActive: '',
      isVisibleToAppMain: '',
      isVisibleToAppCategory: '',
      isVisibleToCafe24: '',
    };
    setState(params);
    dispatch(currentSearchOption({}));
  };

  const setDefaultState = () => {
    return {
      title: searchOption.title || '',
      gender: searchOption.gender || '',
      isActive: searchOption.isActive ? String(searchOption.isActive) : '',
      isVisibleToAppCategory: searchOption.isVisibleToAppCategory
        ? String(searchOption.isVisibleToAppCategory)
        : '',
      isVisibleToAppMain: searchOption.isVisibleToAppMain
        ? String(searchOption.isVisibleToAppMain)
        : '',
      isVisibleToCafe24: searchOption.isVisibleToCafe24
        ? String(searchOption.isVisibleToCafe24)
        : '',
    };
  };

  const renderCheckbox = ({ section, title, field, value }) => {
    return (
      <>
        <style.ItemTitle>
          <style.TitleText>{section}</style.TitleText>
        </style.ItemTitle>
        <style.Item>
          {title.map((name, index) => {
            return (
              <>
                <style.CheckBox
                  key={index}
                  checked={state[field] === value[index]}
                  onChange={() => {
                    setState({
                      ...state,
                      [field]: value[index],
                    });
                  }}
                />
                <style.ItemText>{name}</style.ItemText>
              </>
            );
          })}
        </style.Item>
      </>
    );
  };

  useEffect(() => {
    if (main.header) {
      setState(setDefaultState());
      dispatch(headerAction(false));
    }
  }, [main.header]);

  useEffect(() => {
    if (pageNum) {
      setPage(pageNum);
    }
    if (searchOption) {
      setState({
        ...state,
        ...searchOption,
      });
    }
    handleSearch();
  }, []);

  return (
    <style.Outer>
      {modal.isShowModal && (
        <Modal
          checkedItems={checkedItems}
          title={modal.title}
          handleSearch={handleSearch}
          onHide={() => {
            setModal({
              ...modal,
              isShowModal: false,
            });
            setCheckedItems([]);
          }}
        />
      )}
      <style.Inner>
        <style.Title>기획전 관리</style.Title>
        <style.FilterBoxOuter>
          <style.FilterBox>
            <style.ItemTitle>
              <style.TitleText>타이틀</style.TitleText>
            </style.ItemTitle>
            <style.Item>
              <style.Input
                type="text"
                value={state.title}
                onChange={(e) => {
                  setState({
                    ...state,
                    title: e.target.value,
                  });
                }}
              />
            </style.Item>
          </style.FilterBox>
          <style.FilterBox>
            {renderCheckbox({
              section: '성별',
              title: ['전체', '남성', '여성'],
              field: 'gender',
              value: ['', 'M', 'W'],
            })}
          </style.FilterBox>
          <style.FilterBox>
            {renderCheckbox({
              section: '노출활성화',
              title: ['전체', '활성화', '비활성화'],
              field: 'isActive',
              value: ['', '1', '0'],
            })}
          </style.FilterBox>
          <Postion state={state} handleCheckbox={handleCheckbox} />
        </style.FilterBoxOuter>

        <style.SearchBox>
          <style.Button
            backgroundColor="#1A1A1A"
            color="white"
            width="true"
            onClick={() => handleSearch()}
          >
            검색
          </style.Button>
          <style.Button
            backgroundColor="#E5E5E5"
            color="#1A1A1A"
            width="true"
            onClick={() => {
              initSearchOption();
              onChangePageNum(1);
            }}
          >
            초기화
          </style.Button>
        </style.SearchBox>
        <ButtonBar handleShowModal={handleShowModal} />
        <style.TableTitle>
          <style.TableItem width="5">
            <style.AllCheckBox
              onChange={(e) => {
                if (e.target.checked) {
                  setCheckedItems(items);
                } else {
                  setCheckedItems([]);
                }
              }}
            />
          </style.TableItem>
          <style.TableItem width="5">성별</style.TableItem>
          <style.TableItem width="10">타이틀</style.TableItem>
          <style.TableItem width="10">노출 활성화</style.TableItem>
          <style.TableItem width="20">노출 기간</style.TableItem>
          <style.TableItem width="20">노출 위치</style.TableItem>
          <style.TableItem width="30">배너 이미지</style.TableItem>
        </style.TableTitle>
        {items &&
          items
            .slice((page - 1) * limit, limit * page)
            .map((item) => (
              <Table
                key={item.id}
                item={item}
                handleCheckItems={handleCheckItems}
                checkedItems={checkedItems}
              />
            ))}

        <Pagination
          page={page}
          num={5}
          limit={limit}
          total={items.length}
          onChange={onChangePageNum}
        />
      </style.Inner>
    </style.Outer>
  );
};

export default withRouter(ExhibitionManagement);
