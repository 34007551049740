import styled from 'styled-components';

export const Wrapper = styled.div`
  border: 1px solid #ddd;
  margin-top: 20px;
  @media all and (min-width: 1200px) {
    display: flex;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  ${(props) => props.BC && `background-color: ${props.BC};`}
  @media all and (min-width: 1200px) {
    justify-content: space-around;
    width: calc(100% / 7);
  }
`;

export const Text = styled.p`
  margin: 0 10px;
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 20px;
  height: 20px;
`;
