import React, { useCallback, useEffect, useState, useContext } from 'react';
import CanvasJSReact from 'assets/canvasjs.react';
import { reInflow } from 'chart';
import { LoadingContext } from 'context';
import {Title, DateBox, Input, PlatformCheckBox, SearchButtonBox, MoonLoading, NumberDisplayTable} from 'components';
import fetchingLog from 'api/fetchingLog';
import * as style from './style';

const ReInflow = () => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const { isLoading, handleIsLoading } = useContext(LoadingContext);

  const [options, setOptions] = useState(reInflow);

  const [initial, setInitial] = useState(false);
  const [term, setTerm] = useState('7');
  const [startDate, setStartDate] = useState('');
  const [lastDate, setLastDate] = useState('');
  const [platform, setPlatfrom] = useState(['pc_web', 'mobile_web', 'ios', 'android']);
  const [totalValues, setTotalValues] = useState([]);

  const handlePlatformCheck = (e) => {
    if (e.target.checked) {
      const find = platform.find((el) => el === e.target.value);
      if (!find) {
        setPlatfrom([...platform, e.target.value]);
      }
    } else {
      const resultPlatform = platform.filter((el) => el !== e.target.value);
      setPlatfrom(resultPlatform);
    }
  };

  const onChange = (e) => {
    setTerm(e.target.value);
  };

  const setDate = (start, last) => {
    setStartDate(start);
    setLastDate(last);
  };

  const getResultDate = (year, month, date) => {
    const getMonth = month < 10 ? `0${month}` : `${month}`;
    const getDate = date < 10 ? `0${date}` : `${date}`;
    const resultDate = `${year}-${getMonth}-${getDate}`;

    return resultDate;
  };

  const getThisMonth = () => {
    let today = new Date();
    let firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    firstDay = getResultDate(
      firstDay.getFullYear(),
      firstDay.getMonth() + 1,
      firstDay.getDate(),
    );
    lastDay = getResultDate(
      lastDay.getFullYear(),
      lastDay.getMonth() + 1,
      lastDay.getDate(),
    );
    setStartDate(firstDay);
    setLastDate(lastDay);
    setInitial(true);
  };

  const handleSearch = useCallback(async () => {
    try {
      handleIsLoading(true);
      let in_return = [];
      let not_return = [];

      const params = {
        start: startDate,
        end: lastDate,
        platforms: platform,
        within: term,
      };

      let totalReturn = 0, totalNotReturn = 0;

      const res = await fetchingLog.get('/api/inflow/revisit', {
        params,
      });
      const { data } = res;

      in_return = data.map((el) => {
        totalReturn += Number(el.return)
        return { label: el.date, y: Number(el.return) }
      });

      not_return = data.map((el) => {
        totalNotReturn += Number(el.not_return)
        return {
          label: el.date,
          y: Number(el.not_return),
        }
      });

      handleIsLoading(false);

      setTotalValues([{
        name: '재유입 총합',
        value: totalReturn + totalNotReturn,
      }, {
        name: '기간 내 재유입',
        value: totalReturn,
      }, {
        name: '기간 외 재유입',
        value: totalNotReturn,
      }])

      setOptions({
        ...options,
        data: [
          {
            type: 'stackedColumn',
            name: '기간 내 재방문',
            showInLegend: true,
            color: '#88c395',
            dataPoints: in_return,
          },
          {
            type: 'stackedColumn',
            name: '기간 외 재방문',
            showInLegend: true,
            color: '#a98bb7',
            dataPoints: not_return,
          },
        ],
      });
    } catch (err) {
      handleIsLoading(false);
      alert(`
      📌 에러가 발생했습니다.! 
      아래를 복사해서 버그 접수 채널에 스레드를 달아주세요.

      API 에러 발생 
      GET - /api/inflow/revisit'
      ${err}`);
      console.error(err);
    }
  }, [options, startDate, lastDate]);

  useEffect(() => {
    getThisMonth();
  }, []);

  useEffect(() => {
    if (initial) {
      handleSearch();
    }
  }, [initial]);

  return (
    <style.Container>
      {isLoading && <MoonLoading />}
      <Title title={'재 유입수'} />
      <style.TitleDescrip>
        신규유입 이후에 재유입 기준일 이내 다시 방문한 횟수
      </style.TitleDescrip>
      <Input title="재유입 기준" value={term} onChange={onChange} />
      <DateBox setDate={setDate} />
      <PlatformCheckBox
        platform={platform}
        handlePlatformCheck={(e) => handlePlatformCheck(e)}
      />
      <SearchButtonBox handleSearch={handleSearch} />
      <NumberDisplayTable values={totalValues} />
      <CanvasJSChart
        options={options}
      />
    </style.Container>
  );
};

export default ReInflow;
