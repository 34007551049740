import { Button, GridTable, NumberStat, OpenLink } from 'components';
import React, { Fragment, FunctionComponent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ORDER_PAY_STATUS, request, USER_TYPE } from 'services';
import useSWR from 'swr';
import { priceFormat } from 'utils';
import classnames from 'classnames';
import dayjs from 'dayjs';

export interface Props {}
interface State {}

const OrderInfoRefund: FunctionComponent<Props> = () => {
  const { fetchingOrderNumber } = useParams<{ fetchingOrderNumber: string }>();
  const { data } = useSWR('/commerce/order/order-refund', (url) =>
    request.get<any, { data: any[]; count: number }>(url, {
      params: { fetchingOrderNumber },
      headers: { Authorization: localStorage.getItem('fetchingAPI_AUTH_TOKEN') || '' },
    })
  );
  const { replace } = useHistory();
  return (
    <>
      <GridTable
        columns={[
          '환불번호 / 상품별주문번호',
          '환불접수일',
          '주문자',
          '총 환불액 (적립금)',
          '판매처 (국가 / 배송방식 / 관세조건)',
          '상품',
          '총 수량',
          '세금환불금액 (상태)',
          '상품금액',
          '반품 수수료',
          '상태 (처리일)',
          '관리자기능',
        ]}
        list={data ? data.data : []}
        noSelection
        maxHeight="max-h-[512px]"
        renderItem={(order, key) => (
          <div key={key}>
            <div aria-label="환불번호 / 상품별주문번호">
              <div className="mb-2">
                <OpenLink url={`/orders/refund/${order.orderRefundNumber}`}>
                  {order.orderRefundNumber}
                </OpenLink>
              </div>
              {order.shopList.map((shop, shopIndex) => (
                <Fragment key={shopIndex}>
                  {shop.itemList.map((item, itemIndex) => (
                    <div key={itemIndex}>
                      <OpenLink
                        url={`/order/${order.fetchingOrderNumber}?status=4&tab=1`}
                      >
                        {item.itemOrderNumber}
                      </OpenLink>
                    </div>
                  ))}
                </Fragment>
              ))}
            </div>
            <div aria-label="환불접수일">
              {dayjs(order.createdAt).format('YYYY.MM.DD HH:mm:ss')}
            </div>
            <div aria-label="주문자">
              <span>{order.userName}</span>
              <div>{USER_TYPE[order.userType]}</div>
            </div>
            <div aria-label="총 환불액 (적립금)">
              <NumberStat value={order.refundAmount} />
              <div>({priceFormat(order.refundPoint)})</div>
            </div>
            <div aria-label="판매처 (국가 / 배송방식 / 관세조건)">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div key={shopIndex} style={{ flex: shop.itemList.length }}>
                    {shop.shopName} ({shop.shopCountry} /{' '}
                    <span
                      className={classnames({
                        'text-fassto': shop.deliveryMethodName === '파스토',
                        'text-eldex': shop.deliveryMethodName === '엘덱스',
                      })}
                    >
                      {shop.deliveryMethodName}[{shop.deliveryMethodCountry}]
                    </span>{' '}
                    /{' '}
                    <span>
                      {shop.isDdp === true && 'DDP'}
                      {shop.isDdp === false && 'DDU'}
                    </span>
                    )
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="상품">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    style={{ flex: shop.itemList.length }}
                    key={shopIndex}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <OpenLink
                          url={`/product/productDetail/v2/${Number(
                            item.itemId
                          )}?mode=update`}
                        >
                          {item.itemName}
                        </OpenLink>
                        <div className="text-red-400">{item.sizeName}</div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="총 수량">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    style={{ flex: shop.itemList.length }}
                    key={shopIndex}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        {item.quantity}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="세금환불금액 (상태)">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    style={{ flex: shop.itemList.length }}
                    key={shopIndex}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <div>
                          <span>관부가세 : </span>
                          <NumberStat value={item.vat + item.duty} />
                        </div>
                        <div>
                          <span>특소세 : </span>
                          <NumberStat value={item.specialTax} />
                        </div>
                        <div>
                          <span>교육세 : </span>
                          <NumberStat value={item.educationTax} />
                        </div>
                        <div className="mt-2">
                          {item.needTaxRefund ? '환급필요' : '환급불필요'}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="상품실환금액">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    style={{ flex: shop.itemList.length }}
                    key={shopIndex}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <NumberStat value={item.refundAmount} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="반품 수수료">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    style={{ flex: shop.itemList.length }}
                    key={shopIndex}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <NumberStat value={item.returnFee} />
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="상태 (처리일)">
              <div className="flex flex-col h-full space-y-4">
                {order.shopList.map((shop, shopIndex) => (
                  <div
                    className="flex flex-col h-full space-y-4"
                    style={{ flex: shop.itemList.length }}
                    key={shopIndex}
                  >
                    {shop.itemList.map((item, itemIndex) => (
                      <div key={itemIndex} className="flex-1">
                        <div>{ORDER_PAY_STATUS[item.refundStatus]}</div>
                        {!!item.statusUpdatedAt && (
                          <div className="text-gray-400">
                            ({dayjs(item.statusUpdatedAt).format('YYYY.MM.DD HH:mm:ss')})
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <div aria-label="관리자기능">
              <div className="flex flex-col gap-1 items-center">
                <Button
                  size="xs"
                  theme="primary"
                  onClick={() => window.open(`/orders/refund/${order.orderRefundNumber}`)}
                >
                  관리
                </Button>
                <Button
                  size="xs"
                  onClick={() => replace(`/order/${order.fetchingOrderNumber}?tab=8`)}
                >
                  메모
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
};

export default OrderInfoRefund;
