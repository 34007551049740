import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  align-items: center;
`;

export const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 25px;
  height: 25px;
  margin: 10px;
`;

export const Item = styled.div`
  text-align: center;
  padding: 5px 10px;
  width: calc(100% / 5);
  word-break: break-all;
`;

export const TextButton = styled.p`
  margin: 0;
  cursor: pointer;
  &:hover {
    color: #83c2e6;
  }
`;

export const Text = styled.p`
  margin: 0;
  color: ${(props) => props.color};
`;
