import { ChevronDownIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import React, { FunctionComponent } from 'react';

export interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.SelectHTMLAttributes<HTMLSelectElement>,
      HTMLSelectElement
    >,
    'size'
  > {
  size?: 'xs' | 'sm' | 'md' | 'lg';
}
interface State {}

const Select: FunctionComponent<Props> = ({ size, className, children, ...props }) => {
  return (
    <div className={classnames('relative', className)}>
      <ChevronDownIcon
        className={classnames('w-5 h-5 absolute right-3 text-gray-400 z-[5]', {
          'top-1': size === 'xs',
          'top-2': size === 'sm',
          'top-3': size === 'md' || size === 'lg',
        })}
      />
      <select
        {...props}
        className={classnames(
          'appearance-none bg-white border w-full border-gray-300 rounded pr-10',
          {
            'text-xs py-1 pl-2': size === 'xs',
            'text-sm py-2 pl-3': size === 'sm',
            'text-base py-2 pl-3': size === 'md',
            'text-lg py-2 pl-3': size === 'lg',
          }
        )}
      >
        {children}
      </select>
    </div>
  );
};

Select.defaultProps = {
  size: 'md',
};

export default Select;
