import React, { useEffect, useState } from 'react';
import * as style from './style';

export default function CheckboxGroup({
  isActive,
  activeText,
  disableText,
  target,
  handleChangeCheckbox,
}) {
  const [isChecked, setIsChecked] = useState(isActive);

  useEffect(() => {
    setIsChecked(isActive);
  }, [isActive]);

  useEffect(() => {
    handleChangeCheckbox(target, isChecked);
  }, [isChecked]);

  return (
    <style.Wrapper>
      <style.Checkbox
        onChange={() => setIsChecked(1)}
        checked={isChecked !== undefined && isChecked}
      />
      <style.Text>{activeText}</style.Text>
      <style.Checkbox
        onChange={() => setIsChecked(0)}
        checked={isChecked !== undefined && !isChecked}
      />
      <style.Text>{disableText}</style.Text>
    </style.Wrapper>
  );
}
