import React from 'react';
import * as style from './style';

const MessageModal = ({ onHide, title, description }) => (
  <style.Wrapper
    onClick={(e) => {
      if (e.target === e.currentTarget) {
        onHide();
      }
    }}
  >
    <style.Inner>
      <style.Header>
        <h3>알림</h3>
      </style.Header>
      <style.Body>
        <style.Title color={title === '요청 성공' ? 'green' : 'red'}>
          {title}
        </style.Title>
        <style.Description>{description}</style.Description>
      </style.Body>
      <style.Bottom>
        <style.Button
          BC="#343a40"
          HBC="white"
          color="white"
          HC="#5884b3"
          style={{ float: 'right' }}
          onClick={onHide}
        >
          닫기
        </style.Button>
      </style.Bottom>
    </style.Inner>
  </style.Wrapper>
);
export default MessageModal;
