import { TableItem } from 'components/UI/molecules';
import React, { useRef, useEffect } from 'react';
import * as style from './style';

const BrandBasicInfo = ({ brand, handleClinentHeight }) => {
  const ref = useRef();

  useEffect(() => {
    if (ref?.current?.clientHeight) {
      handleClinentHeight('기본 정보', ref?.current?.clientHeight);
    }
  }, [ref]);

  const reSize = () => {
    handleClinentHeight('기본 정보', ref?.current?.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', reSize);

    return () => {
      window.removeEventListener('resize', reSize);
    };
  }, []);
  return (
    <style.Wrapper ref={ref}>
      <style.Title>기본 정보</style.Title>
      <style.BoxWrapper>
        <TableItem title="브랜드 ID" value={brand?.brand_id} />
        <TableItem
          title="앱 노출 여부"
          value={brand?.is_active === 1 ? '노출' : '미노출'}
          valueColor={brand?.is_active === 1 ? 'green' : 'red'}
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem title="우선순위" value={brand?.priority} />
        <TableItem
          title="카페24 업로드"
          value={brand?.cafe24_brand_code !== null ? '대상' : '미대상'}
          valueColor={brand?.cafe24_brand_code !== null ? 'green' : 'red'}
        />
      </style.BoxWrapper>
      <style.BoxWrapper>
        <TableItem
          title="카페24 브랜드 코드"
          value={brand?.cafe24_brand_code}
        />
        <TableItem
          title="네이버 피드 업로드"
          value={brand?.is_naver_upload === 1 ? '대상' : '미대상'}
          valueColor={brand?.is_naver_upload === 1 ? 'green' : 'red'}
        />
      </style.BoxWrapper>
    </style.Wrapper>
  );
};
export default BrandBasicInfo;
